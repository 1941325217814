    <div class="row">
                        <div class="col-lg-6" id="ngos">
                        </div>
                         <div class="col-lg-6" id="mp">
                                         <!--<div echarts [options]="chartOption" (mouseover)="onChartClick($event, 'chartClick')"  class="demo-chart"></div>
                                      <div id="mp"></div>  -->
                           </div>

    </div>

        <div class="row">
                        <div class="col-lg-6" id="area">
                                            <h4>Thermatic Area</h4>
                        </div>
                                    <div class="col-lg-6" id="donor">
                                             <h4>Funding</h4>
                                             <div *ngFor="let item of funding">

                                             </div>
                                         <!--<div echarts [options]="chartOption" (mouseover)="onChartClick($event, 'chartClick')"  class="demo-chart"></div>
                                      <div id="mp"></div>  -->
                                        </div>

    </div>