

<section class="page-title bg-coordination">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Government  Coordinating Bodies</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="">
    <div class="container">
        <div class="col-lg-8 text-start">
            <div class="sections section-top">
                <h5 id="our-team" id="coordination-for-better-delivery" class="mt-6 content-title ">

                </h5>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    Nigeria operates a federal system of government which is
                    split into three tiers, namely: the federal (central), state and local governments. This implies that all humanitarian and development actors in the country are
                    expected to align with and navigate the complex coordination mechanisms at the aforementioned tiers of governments. NIF supports its members by engaging with the relevant government
                    MDAs (Ministries, Departments and Agencies) to foster understanding and cooperation, and enable them carryout their activities effectively.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="">
    <div class="container">
     <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Federal Ministry of Finance, Budget and National Planning (MFBNP)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    The Federal Ministry of Finance, Budget and National Planning was originally established as National Planning Commission in 1992.
                    The Ministry has the mandate to determine and advise the Government of the Federation on matters relating to National Development and overall
                    management of the national economy. The Ministry has eleven (11) departments under the Budget and National Planning arm, including the International
                    Cooperation Department (ICD). The ICD manages multilateral and bilateral economic cooperation, including development aid and technical assistance programmes.
                    It is saddled with the responsibility of registration, monitoring and evaluation of International Organisations providing development and humanitarian aid services.
                    For more information, visit <a href="http://nationalplanning.gov.ng/" class="text-color" target="_blank" rel="noopener noreferrer"> visit here.</a>
                </p>

                <p>
                   <b> Honourable Minister:
                    <a href="https://en.wikipedia.org/wiki/Zainab_Ahmed" target="_blank" rel="noopener noreferrer">Zainab S. Ahmed.</a> <br>
                    Honourable Minister of State: Prince Clem Agba.
                    </b>
                </p>
            </div>
        </div>
    </div>
</section>

<section class="">
    <div class="container">
     <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    National Humanitarian Coordination Committee (NHCC)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    The NHCC is the highest body constituted to oversee humanitarian actions in the country and advise the
                    President of Nigeria on humanitarian-related issues. The committee is chaired by the Minister, Federal Ministry of
                    Humanitarian Affairs, Disaster Management and Social Development, with the National Security Adviser (NSA) as co-chairman.
                    Specifically, the committee will, among others, provide a national vision for humanitarian actions, advise on coordination between the
                    security services and humanitarian actors, and settle disputes that may arise from interactions between security services and the humanitarian community.
                </p>

                <p>
                    Members of the committee include Chairman, Nigeria Governors Forum; Ministers of Defence, Interior, Justice; Minister of State Budget and Planning; Chiefs of Defense Staff,
                    Army Staff, Naval Staff, Air Staff; and the Inspector General of Police.
                </p>

                <p>
                    Others are the Governors of Borno, Adamawa and Yobe states; Commandant General, Nigeria Security and Civil Defence Corps; Comptroller General, Nigeria Customs Service;
                    United Nations Resident Coordinator; Permanent Secretary, FMHDSD (serving as the secretariat),  and any other nominated by Chairman of the Committee.
                </p>


            </div>
        </div>
    </div>
</section>


<section class="">
    <div class="container">
     <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Federal Ministry of Humanitarian Affairs, Disaster Management and Social Development (FMHDSD)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    The FMHDSD is the statutory body of Nigeria's central government established in August 2019 to develop humanitarian policies and provide
                    effective coordination of national and international interventions, while ensuring a strategic and effective emergency management.
                    The ministry further manages the formulation and implementation of social inclusion and protection programmes in Nigeria.
                </p>

                <p>
                    There are six (6) government agencies currently attached to the ministry including the North East Development Commission (NEDC).
                    For more information, visit <a href="https://www.fmhds.gov.ng/" class="text-color" target="_blank" rel="noopener noreferrer">here.</a>
                </p>
                <p>
                   <b> Honourable Minister:
                    <a href="https://en.wikipedia.org/wiki/Sadiya_Umar_Farouq" target="_blank" rel="noopener noreferrer">Sadiya Umar Farouq.</a> <br>
                    </b>
                </p>
            </div>
        </div>
    </div>
</section>

<section class="">
    <div class="container">
     <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    National Humanitarian Coordination Committee Technical Working Group (NHCTWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This is a 27-member Technical Working Group (TWG)
                    <a href="https://fmic.gov.ng/27-man-national-humanitarian-coordination-working-group-set-to-support-nhcc/" class="text-color" target="_blank" rel="noopener noreferrer">inaugurated by the Minister of Humanitarian Affair, </a>
                    Disaster Management and Social Development, Sadiya Umar Farouq, on 1 September 2020 to provide technical
                    support to the National Humanitarian Coordination Committee (NHCC). The group is chaired by the Permanent Secretary, FMHDSD.
                </p>

                <p>
                    Members of the TWG include Commissioners in charge of Humanitarian issues in Borno, Adamawa and Yobe; heads of NEMA, NEDC and NCFRMI; representatives of Operations of the Armed Forces, the European Union Commision,
                    United States Agency for International Development, United Nations Office for the Coordination of Humanitarian Affairs and Nigeria INGO Forum.
                </p>

            </div>
        </div>
    </div>
</section>


<section class="">
    <div class="container">
             <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3">
            <div class="sections section-top">
                <span class="our-work-title">
                    Borno State Agency for the Coordination of Development and Humanitarian Response (BASDHR)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    The BASDHR was established in December 2019 to coordinate and regulate the activities of
                    international and national NGOs, as well as other development partners in Borno state.
                    The Agency, among other things, is responsible for reviewing and approving workplans of all humanitarian
                    and development actors in line with the state government's stabilization and recovery
                    objectives across the 27 local government areas.
                </p>

                <p>
                    In addition, NIF, along with the BASDHR, coordinates with other relevant government bodies
                    like the Ministry of 3Rs (Resettlement, Rehabilitation and Reconstruction),
                    High Level Task Force (HLTF), State Emergency Management Agency (SEMA), State Economic and Financial Crimes Commission
                    (EFCC), Nigeria Immigration Service (NIS), among others to improve relationships, ensure compliance and resolve challenges.
                    Also, the coordination is extended to LGAs by working with the Chief Executives and LGA officials to resolve lower level or camp issues, e.g.
                    local content, distribution of food, etc.
                </p>

            </div>
        </div>
    </div>
</section>



