import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PublicService, OptionService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { AppFilter } from '../../models';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-careers-detail',
	templateUrl: './careers.component.html',
	styleUrls: [ './careers.component.scss' ]
})
export class CareersDetailComponent implements OnInit {
	isExpire: boolean;
	postType = environment.postType;
	baseUrl: string = environment.API_SERVER;
	formSubmitAttempt: boolean;
	pending: boolean;
	success: any;
	error: any;
	types = [];
	postId: string;
	post: any;
	filePending: boolean;
	detail;
	filter: AppFilter;
	relatedPosts: Array<any> = [];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private postSvc: PublicService,
		private title: Title
	) {
		this.filter = new AppFilter({ sidx: '-createdat', records: '', sord: 'des' });
		// let filter = this.filterComposer(null);
		this.filter.search = false;
		// this.filter.filters = JSON.stringify(filter);
		this.route.params.subscribe((res) => {
			this.postId = res['id'];
			if (this.postId) {
				let filter = this.filterComposer(null);
				this.filter.filters = JSON.stringify(filter);
				this.getPost();
			}
		});
	}
	viewFile(post) {
		let src = `${environment.API_SERVER}/public/assets?media=${post['path']}`;
		// this.modalService
		// 	.open(new FileViewerModal('View Document', { src: src }, ModalSize.Tiny))
		// 	.onApprove((eq) => {})
		// 	.onDeny(() => {});
	}
	downloadFile(post) {
		let url = `${environment.API_SERVER}/public/assets?media=${post['path']}`;
		window.open(url, '_blank');
	}

	getPost() {
		this.pending = true;
		this.postSvc.getAllOpportunity({ param: this.filter }).subscribe(
			(res) => {
				this.pending = false;
				this.post = res['opportunitys'].length ? res['opportunitys'][0] : null;
				this.title.setTitle(`${res['opportunitys'][0]['name']} | Nigeria INGO Forum`);
				if (new Date().valueOf() > new Date(res['opportunitys'][0].deadline).valueOf()) {
					this.isExpire = true;
				} else {
					this.isExpire = false;
				}
				this.getPage(1);
				this.incrementView();
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}

	incrementView() {
		this.pending = true;
		this.postSvc.incrementOpportunity({ id: this.postId, param: { type: 'view' } }).subscribe(
			(res) => {},
			(error) => {
				this.error = error.error.split('=').pop();
			}
		);
	}

	//group query
	filterComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};

		if (this.postId) {
			type['$eq'] = this.postId;
			mongofilter['slug'] = type;
		}

		if (query !== null) {
			let c1: { [id: string]: any } = {};
			let c2: { [id: string]: any } = {};
			//mobile
			name['$regex'] = query;
			name['$options'] = 'i';
			c1['name'] = name;

			description['$regex'] = query;
			description['$options'] = 'i';
			c2['contactname'] = description;

			let oroperator: { [id: string]: any }[] = [];
			oroperator = [ c1, c2 ];
			mongofilter['$or'] = oroperator;
		}

		return mongofilter;
	}

	getPage(page) {
		let filter = new AppFilter({ sidx: 'name', records: '', rows: 4 });
		let search = this.filterComposerSlider(null);
		filter.search = false;
		filter.filters = JSON.stringify(search);
		this.pending = true;
		this.postSvc.getAllOpportunity({ param: filter }).subscribe(
			(res) => {
				this.pending = false;
				this.relatedPosts = res['opportunitys']
					? res['opportunitys'].filter((opp) => opp.id != this.post.id)
					: [];
				// .map((resource) => {
				// 	return {
				// 		image: `${this.baseUrl}/public/assets?media=${resource.image}`,
				// 		name: resource.name,
				// 		type: resource.resource_type_id,
				// 		id: resource.id
				// 	};
				// });
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}

	filterComposerSlider(query) {
		let mongofilter = [];
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let categoryType: { [id: string]: any } = {};
		let thematicType: { [id: string]: any } = {};
		let regionType: { [id: string]: any } = {};
		let languageType: { [id: string]: any } = {};
		let resourceType: { [id: string]: any } = {};
		let approvalStatus: { [id: string]: any } = {};
		let obj = this.post;
		let lookup: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		let unwind: { [id: string]: any } = {};
		let facet: { [id: string]: any } = {};
		let sort: { [id: string]: any } = {};
		for (const key in obj) {
			switch (key) {
				case 'post_type_id':
					resourceType['posttypeid'] = { $in: [ obj[key] ] };
					break;
				case 'job_type_id':
					resourceType['jobtypeid'] = { $in: [ obj[key] ] };
					break;
				case 'theme_id':
					console.log(obj[key]);
					resourceType['themeid'] = { $in: [ obj[key] ] };
					break;
				case 'job_family_id':
					resourceType['jobfamilyid'] = { $in: [ obj[key] ] };
					break;
				case 'job_level_id':
					resourceType['joblevelid'] = { $in: [ obj[key] ] };
					break;
			}
		}
		let oroperator: { [id: string]: any }[] = [];
		let approval: { [id: string]: any }[] = [];
		let andoperator: { [id: string]: any }[] = [];
		oroperator = [ resourceType, categoryType, thematicType, regionType, languageType ];
		match['$or'] = oroperator;
		match['status'] = 'APPROVED';
		return match;
	}
	ngOnInit(): void {}
}
