import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Teams } from './mock';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
@Component({
	selector: 'app-dashboard',
	templateUrl: './about.component.html',
	styleUrls: [ './about.component.scss' ]
})
export class DashboardComponent implements OnInit, AfterViewInit {
	teams = Teams;
	src
	private fragment: string;
	donors: Array<any> = [
		{ src: '/assets/images/donors/ECHO-Logo.png', href: 'https://ec.europa.eu/echo/' },
		{ src: '/assets/images/donors/SDC-Logo.png', href: 'https://www.eda.admin.ch/sdc' },
		{
			src: '/assets/images/donors/UKAID-Logo.png',
			href: 'https://www.gov.uk/government/organisations/foreign-commonwealth-development-office'
		},
		{
			src: '/assets/images/donors/USAID-Logo.png',
			href: 'https://www.usaid.gov/who-we-are/organization/bureaus/bureau-humanitarian-assistance'
		},
		{ src: '/assets/images/donors/NIF-Contributions-Logo.png', href: '/members' }
	];
	committerTeam = [
		{
			Name: 'Arie Claassens',
			Position: 'Country Director',
			Location: '(standing seat as host of NIF)',
			Team: 'Localisation & Development',
			src: '/assets/images/members/immap.png',
			alt: 'crs.png',
			link: 'https://immap.org/nigeria/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Charles Usie',
			Position: 'Country Director',
			Location: 'Steering Committee Chair',
			Team: 'North-East',
			src: '/assets/images/members/plan.png',
			alt: 'PLAN',
			link: 'https://www.intersos.org/en/what-we-do/nigeria/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Dane Fredenburg',
			Position: 'Deputy Country Representative',
			Location: 'Abuja',
			Team: 'Country Office',
			src: '/assets/images/members/crs.png',
			alt: 'CRS',
			link: 'https://nigeria.mercycorps.org/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Hussaini Abdu',
			Position: 'Country Director',
			Location: 'Abuja',
			Team: 'Country Office',
			src: '/assets/images/members/care.png',
			alt: 'CARE',
			link: 'https://www.sfcg.org/nigeria/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Mercy Alidri',
			Position: 'Deputy Director of Programmes',
			Location: 'Abuja',
			Team: 'Country Office',
			src: '/assets/images/members/irc.png',
			alt: 'IRC',
			link: 'https://www.sfcg.org/nigeria/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		}
	];

	constructor(private activatedRoute: ActivatedRoute, 
		private sanitizer:DomSanitizer,
		private title: Title) {
		this.title.setTitle('About | Nigeria INGO Forum');
		this.src=	sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiNGYzMzVkOTEtN2IwNC00NmU2LWI0YjktODdiZWNhNDZmZWQ1IiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9&pageName=ReportSectiond4052e84529b0d4b048b");
	}

	ngOnInit(): void {
		// this.activatedRoute.fragment.subscribe((fragment) => fragment);
		// this.activatedRoute.fragment.subscribe((res) => {
		// 	console.log(res);
		// 	if (res) {
		// 		var element = document.getElementById(`${res.split('/')[0]}`);
		// 		console.log(element);
		// 		element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
		// 	}
		// });
	}

	ngAfterViewInit(): void {
	}
}
