
<section class="page-title bg-1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <span class="text-white">Our blog</span>
          <h1 class="text-capitalize mb-4 text-lg">Blog articles</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section blog-wrap">
    <div class="container">
        <div class="row">
	<div class="col-lg-4 col-md-6 mb-5 col-sm-6">
		<div class="blog-item-content">
			<img src="/assets/images/blog/blog_1.jpg" alt="" class="img-fluid">

			<div class="blog-item-content-content mt-3">
				<span class="text-sm text-color text-uppercase font-weight-bold">January 3, 2019</span>

				<h3 class="mb-3"><a href="blog-single.html">Save The Child For New World</a></h3>
				<p class="mb-4">Non illo quas blanditiis repellendus laboriosam minima animi. Consectetur accusantium pariatur repudiandae!</p>

				<a href="blog-single.html" class="btn btn-small btn-main rounded">Learn More</a>
			</div>
		</div>
	</div>

	<div class="col-lg-4 col-md-6 mb-5 col-sm-6">
		<div class="blog-item-content">
			<img src="/assets/images/blog/blog_2.jpg" alt="" class="img-fluid">

			<div class="blog-item-content-content mt-3">
				<span class="text-sm text-color text-uppercase font-weight-bold">January 3, 2019</span>

				<h3 class="mb-3"><a href="blog-single.html">Shelter For Homeless Child</a></h3>
				<p class="mb-4">Non illo quas blanditiis repellendus laboriosam minima animi. Consectetur accusantium pariatur repudiandae!</p>

				<a href="blog-single.html" class="btn btn-small btn-main rounded">Learn More</a>
			</div>
		</div>
	</div>

	<div class="col-lg-4 col-md-6 mb-5 col-sm-6">
		<div class="blog-item-content">
			<img src="/assets/images/blog/blog_3.jpg" alt="" class="img-fluid">

			<div class="blog-item-content-content mt-3">
				<span class="text-sm text-color text-uppercase font-weight-bold">January 3, 2019</span>

				<h3 class="mb-3"><a href="blog-single.html">Pure Water Is More Essential</a></h3>
				<p class="mb-4">Non illo quas blanditiis repellendus laboriosam minima animi. Consectetur accusantium pariatur repudiandae!</p>

				<a href="blog-single.html" class="btn btn-small btn-main rounded">Learn More</a>
			</div>
		</div>
	</div>
	<div class="col-lg-4 col-md-6 mb-5 col-sm-6">
		<div class="blog-item-content">
			<img src="/assets/images/blog/blog_5.jpg" alt="" class="img-fluid">

			<div class="blog-item-content-content mt-3">
				<span class="text-sm text-color text-uppercase font-weight-bold">January 3, 2019</span>

				<h3 class="mb-3"><a href="blog-single.html">Medicine For Poor Children</a></h3>
				<p class="mb-4">Non illo quas blanditiis repellendus laboriosam minima animi. Consectetur accusantium pariatur repudiandae!</p>

				<a href="blog-single.html" class="btn btn-small btn-main rounded">Learn More</a>
			</div>
		</div>
	</div>

	<div class="col-lg-4 col-md-6 mb-5 col-sm-6">
		<div class="blog-item-content">
			<img src="/assets/images/blog/blog_2.jpg" alt="" class="img-fluid">

			<div class="blog-item-content-content mt-3">
				<span class="text-sm text-color text-uppercase font-weight-bold">January 3, 2019</span>

				<h3 class="mb-3"><a href="blog-single.html">Help the Orphan Children</a></h3>
				<p class="mb-4">Non illo quas blanditiis repellendus laboriosam minima animi. Consectetur accusantium pariatur repudiandae!</p>

				<a href="blog-single.html" class="btn btn-small btn-main rounded">Learn More</a>
			</div>
		</div>
	</div>
	<div class="col-lg-4 col-md-6 mb-5 col-sm-6">
		<div class="blog-item-content">
			<img src="/assets/images/blog/blog_4.jpg" alt="" class="img-fluid">

			<div class="blog-item-content-content mt-3">
				<span class="text-sm text-color text-uppercase font-weight-bold">January 3, 2019</span>

				<h3 class="mb-3"><a href="blog-single.html">Education For Poor Children</a></h3>
				<p class="mb-4">Non illo quas blanditiis repellendus laboriosam minima animi. Consectetur accusantium pariatur repudiandae!</p>

				<a href="blog-single.html" class="btn btn-small btn-main rounded">Learn More</a>
			</div>
		</div>
	</div>
</div>

        <div class="row justify-content-center mt-5">
            <div class="col-lg-6 text-center">
            	<nav class="pagination py-2 d-inline-block">
                    <div class="nav-links">
                        <a class="page-numbers current" href="#">1</a>
                        <a class="page-numbers" href="#">2</a>
                        <a class="page-numbers" href="#">3</a>
                        <a class="page-numbers" href="#"><i class="icofont-long-arrow-right"></i></a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</section>