<!-- <p>humanitarian-coordination-architecture works!</p> -->

<section class="page-title bg-coordination">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Humanitarian Coordination Architecture</h1>
                </div>
            </div>
        </div>
    </div>
</section>



<section >
    <div class="container">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Humanitarian Country Team (HCT)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This is the highest-level and strategic decision-making and oversight
                    forum for humanitarian interventions in Nigeria established and led by the
                    Humanitarian Coordinator. The composition of the HCT includes representatives from the UN,
                    INGOs, National NGOs, Red Cross/Red Crescent Movement and Donors. As the centrepiece of the
                    humanitarian coordination architecture in Nigeria, the HCT provides a platform for timely, effective and
                    efficient decision making, thereby contributing to longer-term recovery while alleviating human suffering and protecting the lives,
                    livelihoods and dignity of populations in need.
                </p>
                <!-- <p>&infin; <em>NGO Representation: NIF Secretariat, ACF, ACTED, INSO, MCN, SCI and SI. Representatives are elected on yearly basis.</em></p> -->
            </div>
        </div>
    </div>
</section>

<!-- <section >

    <div class="container">
        <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Operational Humanitarian Country Team (OHCT)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This platform was set up in 2016 to provide increased operational guidance
                    and decision making in the complex humanitarian emergency of the North-East.
                    The OHCT meets bi-weekly and links the strategic decision making made at the Federal level with the
                    operational decision making and delivery of humanitarian assistance to the
                    affected population in the field. The OHCT, chaired by the Deputy Humanitarian Coordinator (DHC),
                    serves as an effective body for providing improved coordination and quality of the humanitarian response in North-East Nigeria.
                </p>
                <p>&infin; <em>NGO Representation: NIF Secretariat, CRS, INSO, IRC, MCN, NRC, SCI. Representatives are elected on yearly basis.</em></p>
            </div>
        </div>
    </div>
</section> -->


<section >
    <div class="container">
        <hr class="col-lg-12 col-md-6 col-sm-12">

        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Inter-Sector Coordination Group (ISCG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This is the technical arm supporting the Humanitarian Coordinator and the HCT in
                    fulfilling their responsibilities as outlined in the Inter-Agency Standing Committee (IASC)
                    guidelines. Guided by the HCT, inter-sector coordination provides a platform for sectors
                    to work together to advance the delivery of assistance to affected people effectively and efficiently.
                    It does this by encouraging synergies between sectors, ensuring roles and responsibilities are
                    clearly defined, closing potential gaps and eliminating duplication.
                </p>
                <!-- <p>&infin; <em>NGO Representation: CAID and INTERSOS. Representatives are recipients of NHF funds and elected every two years.</em></p> -->
            </div>
        </div>
    </div>
</section>



<section >
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Nigeria Humanitarian Fund (NHF)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This board - made up of representatives from UN, NGOs and donors -
                    provides strategic support to the Humanitarian Coordinator
                    in ensuring decisions on NHF funding are inclusive of the views
                    across the humanitarian community. The NHF, managed by OCHA, is a
                    country-based pooled fund (CBPF) launched in February 2017 to promote
                    inclusiveness and partnerships in the allocation, management and use of
                    humanitarian fund to deliver the most urgent and flexible relief aid.
                    For more information, <a class="text-color" target="_blank" href="https://www.unocha.org/nigeria/about-nhf">visit here.</a>
                </p>
                <!-- <p>&infin; <em>INGO Representation: NIF Secretariat, ACF, FHI 360, NRC. Representatives are elected on yearly basis.</em></p> -->
            </div>
        </div>
    </div>
</section>



<section >
    <div class="container">
 <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Humanitarian Hubs Taskforce
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    Humanitarian hubs support inter-agency and multi-sectorial delivery of
                    assistance in hard-to-reach conflict-affected areas by enabling humanitarian aid workers stay
                    longer in deep field locations. Humanitarian hubs provide a common base of operations for
                    humanitarian organisations present in selected areas with critical enablers,
                    including security telecommunications systems (very high frequency – VHF – and high
                    frequency – HF – systems, and satellite phones) and internet connectivity, office space, accommodation,
                    transportation and logistics support and storage space.
                    Furthermore, the hubs provide a unified framework for coordinating humanitarian assistance in the critical sectors including CCCM,
                    Nutrition, Health and Protection. There are currently six (6) humanitarian hubs located in Maiduguri (Red Roof), Bama, Gwoza, Dikwa,
                    Ngala and Monguno. For more information, <a class="text-color" target="_blank" href="https://www.humanitarianresponse.info/en/operations/nigeria/humanitarian-hubs">visit here.</a>
                </p>
                <!-- <p>&infin; <em>INGO Representation: NIF Secretariat, FHI 360 and Plan. Representatives are elected on yearly basis.</em></p> -->
            </div>
        </div>
    </div>
</section>

<section >
    <div class="container">
 <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Cash Working Group (CWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This group was established to provide a platform for the strategic and operational engagement
                    with cash and voucher partners providing relief and recovery services in the North-East.
                    The CWG works to promote synergy and harmonisation of approaches, advocate for scale up of Cash and
                    Voucher Assistance (CVA) and promote the linkage of humanitarian cash assistance to existing social protection
                    system in North-East Nigeria. OCHA and CRS (on behalf of NIF) co-lead the CWG.
                    For more information, <a class="text-color" target="_blank" href="https://www.humanitarianresponse.info/en/operations/nigeria/cash-working-group">visit here.</a>
                </p>
                <!-- <p>&infin; <em>INGO Representation: INGOs implementing CVA in the North-East.</em></p> -->
            </div>
        </div>
    </div>
</section>


<section >
    <div class="container">
 <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Access Working Group (AWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This platform serves as an advisory body to the strategic leadership of
                    the humanitarian community in promoting principled access to the hundreds of
                    thousands of people in dire need of humanitarian assistance, facilitating and enabling
                    information collection and analysis, as well as aiding in the operationalisation of plans to
                    stabilise living conditions for millions of affected people. OCHA and NIF co-chair the AWG.
                </p>
                <!-- <p>&infin; <em>NGO Representation: NIF Secretariat (co-lead), INSO, IRC and Plan. Representatives are elected on yearly basis.</em></p> -->
            </div>
        </div>
    </div>
</section>



<!-- <section class="mt-5">
    <div class="container">
        <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Community Engagement / Accountability to Affected Population (CE/AAP) Working Group
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This group was established to promote an accountable action and a
                    right-based approach in planning and delivering relief and services to
                    the millions of people affected by the North-East crisis. It also facilitates
                    inclusive approaches around gender, age, race, disability and other vulnerability into all
                    aspects of humanitarian response. For more information,
                    <a href="https://www.humanitarianresponse.info/en/operations/nigeria/accountability-affected-population" target="_blank" class="text-color">visit here. </a>
                    <b>This group is inactive.</b>
                </p>

            </div>
        </div>
    </div>
</section> -->

<section class="mt-5">
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    UNHAS Steering Committee
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This committee provides advisory guidance to the operations of the UN
                    Humanitarian Air Service (UNHAS), whose fixed-wing flights and helicopter services
                    have greatly facilitated the delivery of life-saving assistance to remote areas.
                    Taking into consideration that about 80 percent of Borno State is considered high or
                    very high risk for humanitarian actors, UNHAS has gained an ever-important role in the
                    transportation of aid workers and life-saving relief items to locations outside Maiduguri,
                    the capital of Borno. For more information,
                    <a href="https://logcluster.org/sector/ngr16a" target="_blank" rel="noopener noreferrer"></a> visit here.
                </p>
                <!-- <p>&infin; <em>NGO Representation: NIF Secretariat and FHI 360. Representatives are elected on yearly basis.</em></p> -->
            </div>
        </div>
    </div>
</section>


<section class="mt-5" >
    <div class="container">
 <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Civil-Military Coordination (CMCoord) Forum – <em>Abuja/Maiduguri</em>
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This forum provides a platform for the humanitarian
                    community to interface with the Nigerian military on security issues
                    in the North-East and to an extend Nigeria. Regular meetings are held to find
                    common ground on security issues, as well as share updates, challenges and key decisions
                    affecting both parties. The forum operates at the Abuja and Maiduguri levels respectfully.
                </p>
                <!-- <p>&infin; <em>NGO Representation: NIF Secretariat, Action Aid, INSO and MCN (Maiduguri). Representatives are elected on yearly basis.</em></p>
                <p>&infin; <em>INGO Representation: NIF Secretariat and INSO (Abuja)</em></p> -->
            </div>
        </div>
    </div>
</section>




<section class="mt-5">
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Local Government Coordination Working Group (LGCWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    The LCWG serves as the bridge between the national and local authorities,
                    and humanitarian actors including the UN, international and national NGOs.
                    There are regular coordination meetings which ensure that information about the
                    efforts and challenges of humanitarian operations at the local level are elevated in a timely fashion.
                    For more information, visit state-level coordination for
                    <a href="https://www.humanitarianresponse.info/en/operations/nigeria/borno-state-coordination" class="text-color" target="_blank" rel="noopener noreferrer">Borno,</a>
                    <a href="https://www.humanitarianresponse.info/en/operations/nigeria/adamawa-state-coordination" class="text-color" target="_blank" rel="noopener noreferrer">Adamawa</a>
                    and
                    <a href="https://www.humanitarianresponse.info/en/operations/nigeria/yobe-state-coordination" class="text-color" target="_blank" rel="noopener noreferrer">Yobe.</a>
                </p>
                <!-- <p>&infin; <em>NGO Representation: All INGOs with presence in respective field levels</em></p> -->
            </div>
        </div>
    </div>
</section>


<section class="mt-5">
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">

        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Humanitarian Communication Working Group (HCWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This is a group of media/information/advocacy personnel drawn
                    from UN, INGOs, ICRC, etc. to strengthen information sharing on the humanitarian
                    situation in the North-East with other stakeholders and the general public.
                    The group also ensures a mutual understanding of operational realities for humanitarian actors,
                    donors and stakeholders through common humanitarian messaging and other channels.
                    The HCWG supports and advises the HCT on communication issues, act as the focal body for
                    coordination of media visits and enhances interaction with various media through collaborative communication actions and campaigns.
                </p>
                <!-- <p>&infin; <em>NGO Representation: Open to all INGOs implementing humanitarian response in the North-East</em></p> -->
            </div>
        </div>
    </div>
</section>


<section class="mt-5">
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Information Management Working Group (IMWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    This group serves as a resource unit for the harmonisation of
                    quality data and information exchange for the humanitarian community.
                    The unit ensures that up-to-date and relevant information on the efforts
                    and progress of the humanitarian community is captured in a presentable format to
                    support improved decision making in emergency preparedness and response.
                    The IMWG further facilitates collective humanitarian needs assessment (HNO),
                    the development of humanitarian response plan (HRP) and supports the timely dissemination of
                    relevant information to the humanitarian community and public at large.
                </p>
                <!-- <p>&infin; <em>NGO Representation: iMMAP, ACTED (REACH Initiative) </em></p> -->
            </div>
        </div>
    </div>
</section>



<section class="mt-5">
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Assessment and Analysis Working Group (AAWG)
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    The AAWG is a multi-sector, multi-stakeholder group that brings
                    together a core group of humanitarian specialists for the purpose of
                    rapid and in-depth needs assessments and harmonised data collection and
                    analysis of crisis-affected areas. The activities of this group inform Inter-Sector
                    Coordination Group (ISCG)'s strategic and operational decisions related to the humanitarian
                    situation in Nigeria. For more information,
                    <a href="https://www.humanitarianresponse.info/es/operations/nigeria/assessment-and-analysis-working-group" class="text-color" target="_blank" rel="noopener noreferrer">visit here.</a>
                </p>
                <!-- <p>&infin; <em>NGO Representation: NIF Secretariat, ACTED (REACH Initiative) - co-lead, iMMAP</em></p> -->
            </div>
        </div>
    </div>
</section>

<section class="mt-5">
    <div class="container">
         <hr class="col-lg-12 col-md-6 col-sm-12">
        <div class="row justify-content-start pl-3 mt-5">
            <div class="sections section-top">
                <span class="our-work-title">
                    Sectors Working Groups in Nigeria
                </span>
            </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">

                <img src="{{ chart }}" class="my-5 px-20" alt="Chart Goes Here" srcset="">


                <p>
                    The Humanitarian Sectors Working Groups (also known as Clusters)
                    bring together humanitarian organisations comprising of the UN, I/NGOs,
                    government, MSF, etc. along the respective sectors of humanitarian action
                    as shown in the chart. These sectors are designated by the Inter-Agency
                    Standing Committee (IASC) and have clear responsibilities for coordination.
                </p>

                <p>
                    Each of the clusters serves the following functions:

                </p>
                <ul id="brown">
                        <li>Supporting service delivery by providing a platform for agreement on approaches and elimination of duplication.</li>
                        <li>Informing strategic decision-making of the HC/HCT for the humanitarian response through coordination of needs assessment, gap analysis and prioritization.</li>
                        <li>Planning and strategy development including sectoral plans, adherence to standards and funding needs.</li>
                        <li>Advocacy to address identified concerns on behalf of cluster participants and the affected population.</li>
                        <li>Monitoring and reporting on the cluster strategy and results; recommending corrective action where necessary.</li>
                        <li>Contingency planning/preparedness/national capacity building where needed and where capacity exists within the cluster.</li>
                    </ul>

                <p>
                    In Nigeria’s response, the sectors are led by a sector lead appointed by the
                    leading agency in the sector and co-led by the government and International NGOs.
                    This allows for sharing of relevant and timely information on the response capacity and
                    gaps while contributing to the improvement in its overall quality. NIF engages with some
                    of the sectors such as the Cash Working Group and Protection Working Group to advocate for
                    INGOs and ensure their perspective is taken into consideration in decision-making processes.
                    For more information,
                    <a href="https://www.humanitarianresponse.info/en/operations/nigeria" class="text-color" target="_blank" rel="noopener noreferrer">visit here. </a>
                </p>

            </div>
        </div>
    </div>
</section>


