<section class="page-title bg-membership">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <h1 class="text-capitalize mb-4 text-lg">Multimedia</h1>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="section">

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8 col-md-10">
				<div class="section-title text-center">
					<span class="text-color">Multimedia</span>
					<h2 class="mt-3 mb-4 position-relative content-title">Surf through our videos and images to learn more about what we do.</h2>
				</div>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="gallery-wrap">
			<div class="row  justify-content-center">
				<div class="col-lg-4">
					<div class="gallery-item">
						 <!-- <a href="/assets/images/gallery/1.jpg" class="gallery-popup">
							<img src="/assets/images/gallery/1.jpg" alt="" class="img-fluid w-100">
						</a> -->
						<div  class="gallery-popup" style="position:relative;padding-bottom:56.25%;height:0;overflow:hidden">
							<iframe width="887" height="499" src="https://www.youtube.com/embed/cUVPLa3nWis" style="position:absolute;top:0;left:0;width:100%;height:100%;border:0" title="Making a Difference: INGOs in North-East" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="gallery-item">
						 <!-- <a href="/assets/images/gallery/1.jpg" class="gallery-popup">
							<img src="/assets/images/gallery/1.jpg" alt="" class="img-fluid w-100">
						</a> -->
						<div  class="gallery-popup" style="position:relative;padding-bottom:56.25%;height:0;overflow:hidden">
							<iframe src="https://www.youtube.com/embed/UIBwtI0K7UQ" style="position:absolute;top:0;left:0;width:100%;height:100%;border:0" allowfullscreen="" title="YouTube Video"></iframe>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="gallery-item">
							<div  class="gallery-popup" style="position:relative;padding-bottom:56.25%;height:0;overflow:hidden">
							<iframe src="https://www.youtube.com/embed/KzmJa4wm5ZM" style="position:absolute;top:0;left:0;width:100%;height:100%;border:0" allowfullscreen="" title="YouTube Video"></iframe>
						</div>
					</div>
				</div>
				<!-- <iframe width="853" height="480" src="https://www.youtube.com/embed/KzmJa4wm5ZM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div><iframe width="853" height="480" src="https://www.youtube.com/embed/UIBwtI0K7UQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
		</div>
	</div>
	</div>
	</section>
