import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BlogService {
	selectedBlog: any = '';
	apiUrl = environment.API_SERVER;

	private annouce: Subject<any> = new Subject<any>();
	actionAdded$ = this.annouce.asObservable();

	annouceActionAdded(ann) {
		this.annouce.next(ann);
	}

	constructor(private _httpClient: HttpClient) {}
	uploadFile(payload) {
		return this._httpClient.post(this.apiUrl + '/v1/blog/upload', payload.body);
	}

	addBlog(payload) {
		return this._httpClient.post(this.apiUrl + '/v1/blog', payload.body);
	}

	getAllBlog(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/v1/blog`, { params: payload.param });
	}

	deleteBlog(payload): Observable<any> {
		return this._httpClient.delete(this.apiUrl + `/v1/blog/${payload.id}`);
	}

	approvalMultiBlog(payload): Observable<any> {
		return this._httpClient.post(this.apiUrl + `/v1/blog/approval`, payload.body);
	}

	approvalBlog(payload): Observable<any> {
		return this._httpClient.post(this.apiUrl + `/v1/blog/${payload.id}/approval`, payload.body);
	}

	editBlog(payload): Observable<any> {
		return this._httpClient.patch(this.apiUrl + `/v1/blog/${payload.id}`, payload.body);
	}

	getBlogById(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/v1/blog/${payload.id}`);
	}
}
