
<section class="page-title bg-career">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <h1 class="text-capitalize mb-4 text-lg">Careers</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-6">
                <p>
                    We invite you to join our team of motivated,
                    dedicated, and diverse individuals working to make
                    changes and create lasting impact in the lives of
                    those we serve.
                </p>

                <p>
                    In collaboration with our national counterparts, we work with the government,
                    the UN, donors and most importantly, the vulnerable communities to ensure a better
                    aid delivery in Nigeria. NIF attracts and retains exemplary talents, each bringing
                    something special to make this happen and for them to reach their full potential.
                </p>

                <p>
                    Find and apply for an open position that matches your skills, experience, and interests below.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="section pt-0">
    <div class="container">
        <div class="row">


            <div class="col-12 ">
                <div class="row">
                    <div class="col-12">
                    <div class="input-group mb-3">
                    <input type="text" class="form-control"  [formControl]="search"  placeholder="Search opportunity...">
                    <span class="input-group-text" id="basic-addon2">Search</span>
                    </div>
                    </div>
                </div>
            </div>

                     <div class="col-lg-12 col-md-12 mb-5 alert alert-warning text-center" role="alert"  *ngIf="!pending && (careers && careers.length < 1)">
                       <h3>
                           <!-- <i class="icofont-error "></i> -->
                            No Record Found</h3>
                    </div>
                        <div class="col-12">
                            <hr/>
                        </div>

                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="d-flex justify-content-center" *ngIf="pending">
                            <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                    <div *ngFor="let s of careers | paginate: { itemsPerPage: rows, currentPage: currentPage,totalItems: totalItems}" class="row row-cols-1 row-cols-sm-2 row-cols-md-4 mb-5">

				        <div class="col-1 align-vertically" *ngIf="s.organization_id.logo == ''">
                               <i class="icofont-briefcase" style="font-size: 56px"></i>
                            </div>
                                <div class="col-1 align-vertically" *ngIf="s.organization_id.logo !== ''">
                                            <img class="img-thumbnail"   src="{{baseUrl}}/public/assets?media={{s.organization_id.logo}}" >
                            </div>
                        <div class="col-7">
                              <h5 class="mb-1"><a  routerLink="/careers/{{s.slug}}">{{s?.name}} </a></h5>
                              <div class="mb-2">{{s?.post_type_id?.name}} &nbsp;  &nbsp; <span *ngIf="s.location">| {{s.location}}</span> &nbsp;  &nbsp; <span *ngIf="s.job_type_id.name !== ''">| &nbsp;  &nbsp;{{s?.job_type_id?.name}}</span></div>
                              <!-- <div class="mb-2">[ {{s?.category_id?.name}} | {{s?.published_at | date}}  ]</div> -->
                        </div>
                            <div class="col-2">
                              <h5 class="mb-1 text-red">Deadline</h5>
                              <div class="mb-2"> {{s.deadline | date}}</div>
                              <!-- <div class="mb-2">[ {{s?.category_id?.name}} | {{s?.published_at | date}}  ]</div> -->
                        </div>
                         <div class="col-1">
                              <h5 class="mb-1 text-red">Views</h5>
                              <div class="mb-2"> {{s.view_count}}</div>
                              <!-- <div class="mb-2">[ {{s?.category_id?.name}} | {{s?.published_at | date}}  ]</div> -->
                        </div>
                        <div class="col-1 align-vertically">
                            <h5> <a routerLink="/careers/{{s.slug}}" class="text-color">Apply <i class="fas fa-forward"></i></a></h5>
                        </div>
                          <div class="col-12">
                         <hr/>
                          </div>
                    </div>


                </div>
        </div>
         <div class="row mt-5">
            <div class="col-lg-8">
             <nav class="pagination py-2 d-inline-block">
                <pagination-template #p="paginationApi"
                           (pageChange)="nextPage($event)"
                     >
                    <div class="nav-links">
                        <div class="page-numbers"  class="pagination-previous" [class.disabled]="p.isFirstPage()">
                            <a class="page-numbers" *ngIf="!p.isFirstPage()" (click)="p.previous()"><i class="icofont-long-arrow-left"></i> </a>
                        </div>
                        <div  *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <a  class="page-numbers" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </a>
                            <div  *ngIf="p.getCurrent() === page.value">
                                <a  class="page-numbers current" >{{ page.label }}</a>
                            </div>
                        </div>

                        <div class="pagination-next" [class.disabled]="p.isLastPage()">
                            <a  class="page-numbers" *ngIf="!p.isLastPage()" (click)="p.next()"> <i class="icofont-long-arrow-right"></i> </a>
                        </div>
                        </div>
                </pagination-template>
               </nav>
            </div>
        </div>
    </div>
</section>
