import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
	selector: 'app-members-detail',
	templateUrl: './members-detail.component.html',
	styleUrls: [ './members-detail.component.scss' ]
})
export class MembersDetailComponent implements OnInit {
	pending: boolean;

	organization: any = {
		name: 'NIGERIA INGO FORUM ',
		acronymn: 'NIF',
		created_at: 1623677820364
	};
	constructor() {}

	ngOnInit(): void {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
