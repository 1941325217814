<!-- <section class="page-title bg-about">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="block text-center">
					<span class="text-white">INGO projects matrix</span>
					<h1 class="text-capitalize mb-4 text-lg">Dashboard </h1>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section class=" about-page">
	<div class="container">
<iframe width="100%" height="900px" [src]="src"></iframe>
	</div>
</section>
