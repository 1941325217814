
<section class="page-title bg-publication">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Leaflets</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row">
                     <div class="col-lg-12 col-md-12 mb-5 alert alert-warning text-center" role="alert"  *ngIf="!pending && (reports && reports.length < 1)">
                       <h3>
                           <!-- <i class="icofont-error "></i> -->
                            No Record Found</h3>
                    </div>
                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="d-flex justify-content-center" *ngIf="pending">
                            <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                    <div *ngFor="let s of reports | paginate: { itemsPerPage: rows, currentPage: currentPage,totalItems: totalItems}" class="row row-cols-1 row-cols-sm-2 row-cols-md-4 mb-5">
                        <div class="col col-lg-3 sections">
                            <img *ngIf="s.image" src="{{baseUrl}}/public/assets?media={{s?.image}}"  alt="" class="img-thumbnail">
                            <!-- <img src="{{ s.image }}" class="img-thumbnail" alt="..."> -->
                        </div>

                        <div class="col-sm">
                     <h3 class="mb-1"><a  routerLink="/publications/{{s.slug}}">{{s?.name}}</a></h3>
                              <div class="mb-2">[ {{s?.category_id?.name}} | {{s?.published_at | date}}  ]</div>
                            <p>
                                {{ s?.summary | truncate:'350' }} <br>
                                <a routerLink="/publications/{{s.slug}}" class="text-color">read more <i class="fas fa-forward"></i></a>
                            </p>
                        </div>
                    </div>


                </div>
        </div>
         <div class="row mt-5">
            <div class="col-lg-8">
             <nav class="pagination py-2 d-inline-block">
                <pagination-template #p="paginationApi"
                           (pageChange)="nextPage($event)"
                     >
                    <div class="nav-links">
                        <div class="page-numbers"  class="pagination-previous" [class.disabled]="p.isFirstPage()">
                            <a class="page-numbers" *ngIf="!p.isFirstPage()" (click)="p.previous()"><i class="icofont-long-arrow-left"></i> </a>
                        </div>
                        <div  *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <a  class="page-numbers" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </a>
                            <div  *ngIf="p.getCurrent() === page.value">
                                <a  class="page-numbers current" >{{ page.label }}</a>
                            </div>
                        </div>

                        <div class="pagination-next" [class.disabled]="p.isLastPage()">
                            <a  class="page-numbers" *ngIf="!p.isLastPage()" (click)="p.next()"> <i class="icofont-long-arrow-right"></i> </a>
                        </div>
                        </div>
                </pagination-template>
               </nav>
            </div>
        </div>
    </div>
</section>