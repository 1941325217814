<section class="page-title bg-contact">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <span class="text-white">Contact Us</span>
                    <h1 class="text-capitalize mb-4 text-lg">Get in Touch</h1>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6">
                <div class="contact-content">
                    <h2 class="mb-5">We’d love to hear from you! <br>Give us call, send us a message.</h2>
                    <ul class="address-block list-unstyled">
                        <li>
                            <h6 class="mb-2">Abuja Office</h6>
                            No. 2 Yabo Close, Off Jere Street, Garki II, Abuja - FCT, Nigeria
                        </li>

                        <li>
                            <h6 class="mb-2">Maiduguri Office</h6>
                            No. 8 Shehu Lamido Way, Maiduguri, Borno State
                        </li>


                    </ul>

                    <ul class="social-icons list-inline mt-5">
                        <li>
                            <h6 class="mb-3">Find us on social media</h6>
                        </li>

                        <li class="list-inline-item">
                            <a href="http://www.twiiter.com"><i class="icofont-twitter mr-2"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="http://www.youtube.com"><i class="icofont-youtube-play mr-2"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://web.facebook.com/NigeriaINGOForum/?_rdc=1&_rdr"><i class="icofont-facebook mr-2"></i></a>
                        </li>
                    </ul>

                    <div class="google-map mt-5 mt-lg-0">
                        <div id="map"></div>
                    </div>
                </div>
            </div>
                <div class="col-lg-6 col-sm-12 col-md-6">
                   <p>NIF is committed to being accountable to its stakeholders, especially the people we serve. An
                        important part of our commitment is ensuring that we share relevant and critical information
                        transparently.
                        We would do our best to meet your information request, but where we are unable to do so, we
                        would provide reasonable justification.
                        You can get more details by reading our Information Disclosure Policy.
                    </p>
                    <p>
                        You may get in touch with us through the contact form below.
                    </p>
                    <h5 class="mt-5">Media Contacts:</h5>
                    For questions about media, please contact our media and advocacy team.
                    <a href="#" class="text-color" target="_blank" rel="noopener noreferrer"><span>media and advocacy
                            team.</span></a>

                    <h5 class="mt-5">Job Enquiries:</h5>
                    <p>We will not respond to unsolicited job or internship
                        applications or enquiries. Please regularly visit our
                        <a href="#" class="text-color" target="_blank" rel="noopener noreferrer">Career Page</a> or
                        <a href="#" class="text-color" target="_blank" rel="noopener noreferrer">NGOWorld Job Site</a>
                        for job listing.
                    </p>

                    <h5 class="mt-5">Funding & Activities Enquiries:</h5>

                    <p>
                        We strive to provide information about <a class="text-color" href="#" target="_blank"
                            rel="noopener noreferrer">our members, where they work and what they do.</a>
                        You will also find detailed information about our work in our <a class="text-color" href="#"
                            target="_blank" rel="noopener noreferrer">Annual Reports. </a>
                        However, due to volume of enquiries and capacity constraints,
                        we may not be able to respond to your request beyond what is already provided on our websites.
                    </p>

                    <h5 class="mt-5">Students / Volunteering Enquiries:</h5>

                    <p>
                        Unfortunately, NIF is unable to accommodate volunteers at the moment.
                        All opportunities, including internship, are advertised through regular channels.
                        We regret that we may not respond to all general requests for detailed information
                        from students because of time and capacity.
                    </p>

                    <p>Before contacting us below, please ensure you read our <a class="text-color" href="#">Frequently
                            Asked Questions</a>.</p>

                </div>
            </div>
        </div>
</section>

<!-- contact form start -->
<section class="contact-wrap section-bottom">
    <div class="container">
        <div class="row">
                      <div class="col-lg-12 col-md-12 mb-5 alert alert-success text-center" role="alert"  *ngIf="success">
                       <h3> {{success}}</h3>
                    </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="d-flex justify-content-center" *ngIf="pending">
                            <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                <form *ngIf="!success" [formGroup]="contactForm" id="contact-form" class="contact__form">
                    <span class="text-color">Send a message</span>
                    <h3 class="text-md mb-5">Contact Form</h3>
                    <!-- form message -->
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-success contact__msg" style="display: none" role="alert">
                                Your message was sent successfully.
                            </div>
                        </div>
                    </div>
                    <!-- end message -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Your Name *</label>
                                <input formControlName="name" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Your Email *</label>
                                <input formControlName="email" type="email" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Your Subject *</label>
                                <input formControlName="subject" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Your Phone Number *</label>
                                <input formControlName="phone" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <label>Your Message *</label>
                        <textarea formControlName="message" class="form-control" rows="6"></textarea>
                    </div>
                    <button class="btn btn-main rounded" name="submit" type="submit" (click)="submitForm()">Send Message</button>
                </form>
            </div>
        </div>
    </div>
</section>