<section class="page-title bg-membership">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Membership</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-8 text-start">
                <div class="sections section-top">
                    <h3 id="our-team" id="coordination-for-better-delivery" class="mt-6 content-title ">

                    </h3>
                </div>
            </div>
        </div>
<section class="container">
        <div class="row">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    NIF is composed of full-time members and
                    observers and will continue to remain open to new members
                    throughout its lifetime.
                </p>
            </div>
        </div>
</section>

        <section class="sections">
            <div class="container">
                <div class="row " id="who-is-eligible">
                    <div class="col-lg-8 text-start">
                        <h4>
                            Who is eligible?
                        </h4>
                    </div>
                </div>

                <div class="row justify-content-start">
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <p>
                            Any organisation interested in joining us
                            either as a member or an observer must meet the following requirements:
                        </p>
                        <ul id="brown">
                            <li>Must have international status, with presence in Nigeria and other parts of the world.
                            </li>
                            <li>Operates independently of any government and must be non-profit, non-partisan,
                                non-political and non-proselytizing.</li>
                            <li>Must have physical presence in Nigeria and be legally eligible to work in the country
                                (or in the process of becoming legally established).</li>
                            <li>Clearly demonstrates a commitment to abide by NIF Statutes of Governance and Nigerian
                                law.</li>
                            <li>Provides humanitarian, recovery or development-oriented assistance in line with the
                                Humanitarian Principles, Do No Harm, the Code of Conduct of the Red Cross/Red Crescent
                                Movement and NGOs in Disaster Relief.</li>

                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <hr>

        <section class="sections">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-8 text-start mt-5">
                        <h4>
                            Why should I join NIF?
                        </h4>
                    </div>
                </div>

                <div class="row justify-content-start">
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <p>
                            Joining us comes with individual and institutional benefits.
                            You will have the opportunity to connect, advocate, share resources, partake in
                            decision-making, improve capacity, among others.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 text-start mt-2">
                        <h5>
                            CONNECTING
                        </h5>

                        <ul id="brown">
                            <li>Country directors meeting (plenary assembly) and hangout</li>
                            <li>Humanitarian Managers Platform</li>
                            <li>HR, finance and logistics meetings and get-together</li>
                            <li>Annual general assembly for members and partners including donors</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 text-start mt-2">
                        <h5>
                            RESOURCE SHARING
                        </h5>

                        <ul id="brown">
                            <li>Receive regular updates from NIF on emerging issues, achievements and challenges within
                                humanitarian/development communities</li>
                            <li>Receive first-hand updates on funding and job opportunities.</li>
                            <li>Share reports, initiatives, jobs, tenders, events, achievements, opinions, etc. with
                                others.</li>
                            <li>Learn about NIF members through interactive information management platform</li>
                        </ul>
                    </div>

                    <div class="col-lg-6 text-start mt-2">
                        <h5>
                            ADVOCACY
                        </h5>

                        <ul id="brown">
                            <li>Opportunities to share opinion, shape joint positions, and participate in high-level
                                advocacies</li>
                            <li>Strategic engagements with community leaders and civil society regulators</li>
                            <li>Get informed on regulatory, bureaucratic and operational issues affecting civil
                                societies</li>
                        </ul>
                    </div>

                    <div class="col-lg-6 text-start mt-2">
                        <h5>
                            DECISION SHARING
                        </h5>

                        <ul  id="brown">
                            <li>Opportunities to have a say in decision-making processes at the highest level through
                                NIF's representation in coordination platforms</li>
                            <li>Keep informed of critical steps before, during and after decision-making processes</li>
                        </ul>
                    </div>

                    <div class="col-lg-12 text-start mt-2">
                        <h5>
                            SERVICES
                        </h5>

                        <ul id="brown">
                            <li>Provision of guidance on key government regulatory and bureaucratic processes including
                                registration, visa issuance, tax compliance, etc.</li>
                            <li>Protection of member interest at all times with government agencies and other critical
                                stakeholders</li>
                            <li>Access to capacity development initiatives for staff and organisations.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <hr>
        <section class="sections">
            <div class="container" id="how-can-i-join-nif-as-an-observer">
                <div class="row">
                    <div class="col-lg-8 text-start mt-5">
                        <h4>
                            How can I join NIF as an observer?
                        </h4>
                    </div>
                </div>

                <div class="row justify-content-start">
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <p>
                            We are committed to creating a transparent and strong platform for
                            engagement with key stakeholders. To ensure diversity of views and perspectives and
                            respect the independence of non-traditional INGOs, organisations can be part of us as an
                            observer.
                        </p>
                             <p>
                        <span class="our-work-title">
                            Observers may include:
                        </span>
                             </p>
                        <ul id="brown">
                            <li>International humanitarian organizations.</li>
                            <li>International research, capacity building and think-tank institutes.</li>
                        </ul>
                        <p>
                            Observers are required to respect all aspects of NIF Statute of Governance and Operations
                            and
                            required to respect the independence of NIF and its members. Observers are eligible to
                            receive communications from
                            NIF but are not afforded voting rights within the membership.
                        </p>

                        <p>
                            To become an observer, please request for membership below. You are expected to sign, upload
                            and submit all required documents along with your application.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <hr>

        <section class="sections" >
     <div class="container" id="become-a-member">
            <div class="card text-dark bg-light  mb-3 text-center">
                <div class="card-body px-2">
                    <div class="container">
                        <div class="row no-gutters">
                            <div class="col-lg-12 text-start">
                                <h4>
                                    Become a member
                                </h4>
                            </div>
                        </div>

                        <div class="row justify-content-start">
                            <div class="col-lg-12 col-md-6 col-sm-12">
                                <p>
                                    Complete our application form online.
                                    All required documents must be duly signed, uploaded
                                    and submitted online along with the application form.
                                </p>

                                <p>
                                    Acceptance into our membership either as a full-time member or an
                                    observer is subject to a review and a unanimous vote by the Steering
                                    Committee or a two-thirds votes of country directors. This process may take
                                    5 days to 4 weeks.
                                </p>

                                <p>
                                    Acceptance or rejection of your membership will be communicated in writing to you.
                                </p>

                                <a type="button" class="btn btn-outline-success" routerLink="/join">Request For Membership</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <hr>
        <section class="sections">
            <div class="container" id="how-long-can-i-be-a-member">
                <div class="row no-gutters">
                    <div class="col-lg-8 text-start mt-5">
                        <h4>
                            How long can I be a member or an observer?
                        </h4>
                    </div>
                </div>
                <div class="row justify-content-start">
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <p>
                            Our membership is for a renewable period of a year.
                            Existing members and observers must pay annual membership fees in the first three
                            (3) months of receiving the annual request payment form from us. Once the due date elapsed,
                            your membership status will become inactive and will be required to go through the
                            membership process again.
                        </p>

                        <p>
                            <a class="text-color" routerLink="/contact-us" target="_blank"
                                rel="noopener noreferrer">Contact us </a>
                            to request for Standard Operating Procedure
                            (SOP) for NIF membership fee.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <hr>
        <section class="sections">
            <div class="container" id="grounds-to-discontinue-membership">
                <div class="row">
                    <div class="col-lg-8 text-start mt-5">
                        <h4>
                            Grounds to discontinue my membership
                        </h4>
                    </div>
                </div>
                <div class="row justify-content-start">
                    <div class="col-lg-12 col-md-6 col-sm-12">
                        <p>
                            The following grounds apply for discontinuation of NIF membership:
                        </p>
                        <ul id="brown">
                            <li>You cease operations in Nigeria beyond six (6) months;</li>
                            <li>You formally notify the Secretariat Director of intention to withdraw and it is
                                acknowledged;</li>
                            <li>A majority vote for exclusion from NIF based on:
                                <ul>
                                    <li type="i">Activities not in line with SOGs or are detrimental to NIF;</li>
                                    <li type="i">No longer able to meet membership criteria; and</li>
                                    <li type="i">Unable to participate in any NIF activity for at least two (2) months
                                    </li>
                                </ul>
                            </li>
                            <li>You failed to attend three (3) consecutive regularly scheduled Country Director Plenary
                                meetings without valid justification;</li>
                            <li>Failure to provide basic information used to support NIF advocacy and accountability
                                efforts.</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
        <hr>

    </div>
</section>

<!-- <section class="section">
    <div class="container" id="who-are-our-members">
        <div class="row " id="members">
            <div class="col-lg-8 text-start">
                <h4>
                    Our Members
                </h4>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-lg-12">
                <div class="clients-wrap">
                    <a href="#" *ngFor="let member of members">
                        <img src="{{member.src}}" alt="" class="img-fluid">
                    </a>
                </div>
            </div>
            <div class="col-lg-12 text-center">
                <a routerLink="/members" class="btn btn-main animated fadeInUp rounded">More Members</a>
            </div>
        </div>
    </div>
</section> -->