import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OptionService {
	apiUrl = environment.API_SERVER;

	constructor(private _httpClient: HttpClient) {}
	uploadFile(payload) {
		return this._httpClient.post(this.apiUrl + '/v1/post/upload', payload.body);
	}

	getAllOption(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/option`, { params: payload.param });
	}
}
