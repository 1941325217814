import { Component, OnInit, AfterContentInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
declare var $: any;
import { BlogService, PublicService } from 'src/app/services';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AppFilter } from 'src/app/models';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { Memebers, Supporter } from './our-members-mock';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: [ './home.component.scss' ]
})
export class HomeComponent implements OnInit, AfterViewInit {
	@ViewChild('el') span: ElementRef;
	baseUrl = environment.API_SERVER;
	members: Array<any> = [];
	supporters = Supporter;
	seletedValue = 'null';
	filter: AppFilter;
	advert = {
		image: './assets/images/info.gif',
		link: 'https://resources.ngoworld.ng/explore/resource/detail/0e02eedd-aaf6-4799-8b92-f428e4e85a39'
	};
	keyMembershipFigure = {
		members: 58,
		observers: 9,
		reporting_members: 29,
		projects: 148,
		states: 16,
		lgas: 177
	};
	subForm: FormGroup = this.fb.group({
		first_name: [ '' ],
		last_name: [ '' ],
		email: [ '' ],
		organization_name: [ '' ],
		organization_type: [ 'null' ],
		job_title: [ '' ],
		country: [ '' ]
	});

	create() {
		if (this.subForm.valid) {
			// send to API
		}
	}

	posts: Array<any> = [];
	publications: Array<any> = [];
	medias: Array<any> = [
		{ src: '/assets/images/gallery/1.jpg' },
		{ src: '/assets/images/gallery/2.jpg' },
		{ src: '/assets/images/gallery/3.jpg' },
		{ src: '/assets/images/gallery/4.jpg' }
	];

	donors: Array<any> = [
		{
			src: '/assets/images/donors/ECHO-Logo.png',
			alt: 'ECHO',
			link: 'https://ec.europa.eu/echo/'
		},
		{
			src: '/assets/images/donors/NIF-Contributions-Logo.png',
			alt: 'NIF',
			link: 'https://www.ingoforum.ng/'
		},
		{
			src: '/assets/images/donors/SDC-Logo.png',
			alt: 'SDC',
			link: 'https://www.eda.admin.ch/sdc'
		},
		{
			src: '/assets/images/donors/UKAID-Logo.png',
			alt: 'FCDO (UKAid)',
			link: 'https://www.gov.uk/government/organisations/foreign-commonwealth-development-office'
		},
		// {
		// 	src: '/assets/images/donors/USAID-Logo.png',
		// 	alt: 'OFDA (USAID)',
		// 	link: 'https://www.usaid.gov/who-we-are/organization/bureaus/bureau-humanitarian-assistance'
		// }
	];
	organizationTypes: Array<any> = [
		{ id: 'Academic Institution', name: 'Academic Institution' },
		{ id: 'Government MDA', name: 'Government MDA' },
		{ id: 'INGO', name: 'INGO' },
		{ id: 'National CSO', name: 'National CSO' },
		{ id: 'International Humanitarian Organisation', name: 'International Humanitarian Organisation' },
		{ id: 'Media', name: 'Media' },
		{ id: 'Private Sector', name: 'Private Sector' },
		{ id: 'Intergovernmental Organisation', name: 'Intergovernmental Organisation' },
		{ id: 'Think Tank / Policy Institute', name: 'Think Tank / Policy Institute' },
		{ id: 'United Nations', name: 'United Nations' },
		{ id: 'None', name: 'None' },
		{ id: 'Others', name: 'Others' }
	];
	statics: Array<any> = [];

	covids: Array<any> = [
		{
			name: 'Confirmed Cases',
			id: 'confirmed',
			count: '141,447',
			class: 'confirmed'
		},
		{
			name: 'Active Cases',
			id: 'active',
			count: '23,998',
			class: 'active'
		},
		{
			name: 'Recoveries',
			id: 'recoveries',
			count: '115,755',
			class: 'recovery'
		},
		{
			name: 'Deaths',
			id: 'dealths',
			count: '1,694',
			class: 'death'
		}
	];
	blogs: Array<any> = [];
	isMember: boolean;
	subPending: boolean;
	subSuccess: any;
	subError: any;
	// publications:Array<any>=[];
	constructor(private title: Title, private publicSvc: PublicService, private fb: FormBuilder) {
		this.title.setTitle(` Nigeria INGO Forum | Coordination for better delivery`);
		this.getNews();
		this.getPublication();
		document.getElementsByTagName('videoId')[0];
		document.onended = function() {
			var video: any = document.getElementById('videoId');
			video.play();
		};

		this.getMembers();
		this.getPageSections();
	}
	subscribe() {
		if (this.subForm.valid) {
			this.subPending = true;
			this.publicSvc
				.subscribeNewsLetter({
					body: {
						list_ids: [ '39c407bc-caf4-4d87-80f7-39904492ee48' ],
						contacts: [
							{
								...this.subForm.value,
								...{
									custom_fields: {
										e3_T: this.subForm.value.job_title,
										e1_T: this.subForm.value.organization_name,
										e2_T: this.subForm.value.organization_type
									}
								}
							}
						]
					}
				})
				.subscribe(
					(res) => {
						this.subPending = false;
						this.subSuccess = 'Thank you for your subscription';
					},
					(error) => {
						this.subPending = false;
						this.subError = 'Oops! something went wrong, please try again';
					}
				);
		}
	}
	getPageSections() {
		let filter = new AppFilter({ sidx: 'name', rows: 3000 });
		let sear = this.filterComposer(null);
		filter.search = false;
		filter.filters = JSON.stringify(sear);
		this.publicSvc.getPageSections({ param: filter }).subscribe(
			(res) => {
				if (res) {
					let covid = res['setting'].covids;
					let covids = this.covids;
					for (let i = 0; i < covids.length; i++) {
						let cov = covids[i];
						covids[i].count = covid[cov.id] ? covid[cov.id] : covids[i].count;
					}
					this.covids = covids;
					this.advert = res['setting']['advert'];
					// this.keyMembershipFigure = res['setting']['key_membership_figure'];
					//58,9,29,148,16,177
				}
			},
			(error) => {}
		);
	}

	getMembers() {
		let filter = new AppFilter({ sidx: 'name', rows: 3000 });
		let sear = this.filterComposer(null);
		filter.search = false;
		filter.filters = JSON.stringify(sear);
		this.publicSvc.getOrganization({ param: filter }).subscribe(
			(res) => {
				this.members = res.sort(function(a, b) {
					var nameA = a.name.toUpperCase(); // ignore upper and lowercase
					var nameB = b.name.toUpperCase(); // ignore upper and lowercase
					if (nameA < nameB) {
						return -1; //nameA comes first
					}
					if (nameA > nameB) {
						return 1; // nameB comes first
					}
					return 0; // names must be equal
				});

				const newMembers = [...this.members];
				let filteredMembers = newMembers.filter(el => el.logo !== "");
				this.members = filteredMembers;
				this.isMember = true;
				setTimeout(() => {
					$('.member-slider').slick({
						slidesToShow: 6,
						// slidesToScroll: 2,
						infinite: true,
						dots: true,
						arrows: true,
						autoplay: true,
						autoplaySpeed: 6000,
						responsive: [
							{
								breakpoint: 1024,
								settings: {
									slidesToShow: 6,
									slidesToScroll: 3,
									infinite: true,
									dots: true,
									arrows: true
								}
							},
							{
								breakpoint: 900,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 2
								}
							},
							{
								breakpoint: 600,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1
								}
							},
							{
								breakpoint: 480,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					});
				}, 2000);
			},
			(error) => {
				// this.error = error.error.split('=').pop();
			}
		);
	}

	getBlogs() {
		let filter = new AppFilter({ sidx: '-createdat', rows: 4 });
		this.publicSvc.getAllBlog({ param: filter }).subscribe((res) => {
			// setTimeout(() => {
			this.blogs = res['resources'] ? res['resources'] : [];
			// }, 13000);
		});
	}

	getNews() {
		let filter = new AppFilter({ sidx: 'publishedat', rows: 3 });
		this.publicSvc.getAllNews({ param: filter }).subscribe((res) => {
			// setTimeout(() => {
			this.posts = res['posts'] ? res['posts'] : [];
			// }, 13000);
		});
	}

	getPublication() {
		let filter = new AppFilter({ sidx: 'publishedat', rows: 3 });
		this.publicSvc.getAllPublication({ param: filter }).subscribe((res) => {
			// setTimeout(() => {
			this.publications = res['publications'] ? res['publications'] : [];
			// }, 13000);
		});
	}

	openModal() {
		// $('#exampleModal').modal('toggle');
	}

	ngOnInit(): void {
		$('.gallery-wrap').each(function() {
			$(this).find('.gallery-popup').magnificPopup({
				type: 'image',
				gallery: {
					enabled: true
				}
			});
		});
	}
	ngAfterViewInit() {
		// dynamic counter
		$('.counter-stat').counterUp({
			delay: 10,
			time: 1000
		});

		//scroll

		$('.team-slider').slick({
			slidesToShow: 6,
			// slidesToScroll: 2,
			infinite: true,
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: true
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('.member-slider').slick({
			slidesToShow: 6,
			// slidesToScroll: 2,
			infinite: true,
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: true
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('supporters-slider').slick({
			slidesToShow: 4,
			// slidesToScroll: 2,
			infinite: true,
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: true
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		// var video = document.getElementById('videoId');

		// if (video.muted) {
		// 	video.muted = false;
		// } else {
		// 	debugger;
		// 	video.muted = true;
		// 	video.play();
		// }
		// window.addEventListener('load', async () => {
		// 	let video: any = document.querySelector('video[muted][autoplay]');
		// 	try {
		// 		await video.play();
		// 	} catch (err) {
		// 		video.controls = true;
		// 	}
		// });mongo --username 'root' --password 'Eiagc12' --authenticationDatabase 'admin'
	}

	filterComposer(query) {
		let mongofilter = [];
		let name: { [id: string]: any } = {};
		let lookup: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		match['status'] = 'APPROVED';
		match['type'] = '0f96de31-66c4-4d42-a29a-6ed2a6c560cf';

		return match;
	}
}
