import { Component, OnInit, HostListener } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {
	innerWidth: number;
	@HostListener('window:resize', [ '$event' ])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}
	constructor() {
		this.innerWidth = window.innerWidth;
	}

	ngOnInit(): void {}
}
