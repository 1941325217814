

<section class="page-title bg-coordination">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">INGO Coordination Platforms</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row" >
        <div class="col-lg-8 text-start">
            <div class="sections section-top">
                <h5 id="our-team" id="coordination-for-better-delivery" class="mt-6 content-title ">

                </h5>
            </div>
        </div>
        </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    To better respond to the strategic and operational needs of our members in Nigeria,
                    especially in the north-east, we currently adopt a three-tier structure under the
                    guidance of an elected Steering Committee. This is further strengthened by various
                    coordination platforms.
                </p>
            </div>
        </div>
    </div>
</section>

<section >
    <div class="container">

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <table class="table">
                    <tbody>
                        <tr>
                            <!-- <td colspan="2">Larry the Bird</td> -->
                            <td>
                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            NIF Platforms
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">
                                        <span class="our-work-title">
                                            Contacts
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="mb-5">
                            <!-- <td colspan="2">Larry the Bird</td> -->
                            <td>
                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            Plenary (or Country Directors) Group
                                        </span>
                                        <p>
                                            This is a group of country directors who serve as the senior decision-making
                                            facility for us. This group meets monthly to deliberate on issues and take
                                            strategic decisions.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">
                                        <span class="our-work-title">
                                            Camilla Corradin
                                        </span>

                                        <p>
                                            ccorradin@ingoforum.ng Director<br/> Nigeria INGO Forum
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <!-- second row -->
                        <tr>
                            <!-- <td colspan="2">Larry the Bird</td> -->
                            <td>
                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            Humanitarian Managers Group
                                        </span>

                                        <p>
                                            This is a body of humanitarian managers and some country directors
                                            based in the north-east who serve to strengthen quality and coordination of
                                            the humanitarian response in the north-east and meets bi-weekly.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">
                                        <!-- <span class="our-work-title">
                                            Yasmine Chawaf
                                        </span>
                                        <p>
                                            ychawaf@ingoforum.ng Deputy Director, North-East<br/>
                                            Nigeria INGO Forum
                                        </p> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <!-- third row -->
                        <tr>
                            <!-- <td colspan="2">Larry the Bird</td> -->
                            <td>

                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            Human Resources Working Group (HRWG)
                                        </span>
                                        <p>
                                            This group exists to discuss welfare, ensure the quality of professionalism
                                            in recruitment and sustainability in the use of human talent through coordination
                                            and information sharing on best practices. Meeting is once in every two months.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">
                                        <span class="our-work-title">
                                            Favour Etim Udi
                                        </span>

                                        <p>
                                            fudi@ingoforum.ng Senior Program Officer, <br/> Nigeria INGO Forum
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- fourth row -->
                        <tr>
                            <!-- <td colspan="2">Larry the Bird</td> -->
                            <td>

                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            Finance Working Group (FWG)
                                        </span>
                                        <p>
                                            This group's primary function is navigating the regulatory environment
                                            around complex tax and payment issues in Nigeria. Meeting is once in every two months.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">
                                        <span class="our-work-title">
                                            Favour Etim Udi
                                        </span>

                                        <p>
                                            fudi@ingoforum.ng Senior Program Officer, <br/> Nigeria INGO Forum
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- fifth row -->

                        <tr>
                            <!-- <td colspan="2">Larry the Bird</td> -->
                            <td>

                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            Logistics Working Group (LWG)
                                        </span>
                                        <p>
                                            This body tackles common operational challenges relating to the
                                            importation of essential goods, services and medical supplies in the humanitarian response,
                                            while sharing information on potential for collaboration and pooled resources.
                                            Meeting is once in every two months.
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">
                                        <span class="our-work-title">
                                            Favour Etim Udi
                                        </span>

                                        <p>
                                            fudi@ingoforum.ng Senior Program Officer, <br/> Nigeria INGO Forum
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- six row -->

                        <!-- <tr>
                            <td>

                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            Other Platforms
                                        </span>

                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="container">
                                    <div class="col-lg-8 text-start">

                                    </div>
                                </div>
                            </td>
                        </tr> -->

                        <!-- seven row -->

                        <!-- <tr>
                            <td>

                                <div class="container">
                                    <div class="col-lg-12 text-start">
                                        <span class="our-work-title">
                                            INSO Roundtable
                                        </span>
                                        <p>
                                            The International NGO Safety Organisation (INSO) hosts its members in regular roundtable discussions. To learn more about INSO, kindly

                                            <a class="text-color" routerLink="/contact-us"
                            target="_blank" rel="noopener noreferrer">visit here. </a>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>

                            </td>
                        </tr> -->

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

