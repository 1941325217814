import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
@Component({
	selector: 'app-maps',
	templateUrl: './maps.component.html',
	styleUrls: [ './maps.component.scss' ]
})
export class MapsComponent implements OnInit, AfterViewInit {
	src
	private fragment: string;
	constructor(private activatedRoute: ActivatedRoute, 
		private sanitizer:DomSanitizer,
		private title: Title) {
		this.title.setTitle('Reports | Nigeria INGO Forum');
		this.src=	sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/view?r=eyJrIjoiODk5NDkwNTctZmFjZi00N2E3LThmOWItMTU1MTNhODFlOGMwIiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9&pageName=ReportSection4a1f4a02068a4a203203");
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}
}
