
<section class="page-title bg-career">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <h1 class="text-capitalize mb-4 text-lg">Opportunity Details</h1>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="section pt-2">
    <div class="container">
        <div class="row">
         <div class="col-lg-12 col-md-12 mb-5 alert alert-warning text-center" role="alert"  *ngIf="isExpire">
                       <h3>
                          <!-- <i class="icofont-error "></i>-->
                            This opportunity has expired</h3>
                    </div> 


                     <div class="col-lg-12 col-md-12 mb-5 alert alert-warning text-center" role="alert"  *ngIf="!pending && !post">
                       <h3>
                           <!-- <i class="icofont-error "></i> -->
                            No Record Found</h3>
                    </div>

                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="d-flex justify-content-center" *ngIf="pending">
                            <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="row">
                                <div class="col-12">
                                    <div class="description" ><h5 > <a  routerLink="/careers" style="color:#8a8787"><i class="icofont-double-left"></i> Back to Opportunities</a>  </h5></div>
                            </div>
                            <div class="col-12">
                                    <div class="mb-4 mt-3" >
                                    <div class="description" ><h3>  <strong>Opportunity Title : </strong><span class="title text-success">     {{post?.name}}</span></h3></div>

                                </div>
                            </div>
                            <div class="col-12">
                                <hr/>
                            </div>
                            <div class="col-3">
                                <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Post Type
                                    <span class="badge badge-success badge-pill">{{post?.post_type_id?.name}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Reference-ID
                                    <span class="badge badge-success badge-pill">{{post?.reference_id}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Theme
                                    <span  >
                                    <span class="badge badge-success badge-pill" *ngFor="let theme of post?.theme_id">
                                        {{theme?.name}}
                                    </span>
                                    </span>
                                </li>
                                <li  class="list-group-item d-flex justify-content-between align-items-center">
                                    Location
                                     <span class="badge badge-success badge-pill" id="word">{{post?.location}}</span>
                                </li>
                                    	<ng-template [ngIf]="post?.post_type_id?.id == postType">
                                     <li class="list-group-item d-flex justify-content-between align-items-center" >
                                   Job Family
                                    <span class="badge badge-success badge-pill" id="word">{{post?.job_family_id?.name}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                   Job Type
                                    <span class="badge badge-success badge-pill" id="word">{{post?.job_type_id?.name}}</span>
                                </li>
                                     <li class="list-group-item d-flex justify-content-between align-items-center">
                                  Job Level
                                    <span class="badge badge-success badge-pill" id="word">{{post?.job_level_id?.name}}</span>
                                </li>
                                     <li class="list-group-item d-flex justify-content-between align-items-center">
                                  Job Experience
                                    <span class="badge badge-success badge-pill" id="word">{{post?.experience_required}}</span>
                                </li>
                                    	</ng-template>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                   Duration
                                    <span class="badge badge-success badge-pill" id="word">{{post?.duration}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                   Deadline
                                    <span class="badge badge-success badge-pill" id="word">{{post?.deadline | date: 'longDate'}}</span>
                                </li>
                                     <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Start Date
                                    <span class="badge badge-success badge-pill">{{post?.expected_start_date | date: 'longDate'}}</span>
                                </li>
                                </ul>
                            </div>
                            <div class="col-9">
                                 <!-- <h5 class="mb-1">{{post?.name}}</h5> -->
                            <div class="description mb-1" style="color:#8a8787">Post Views {{post?.view_count}}</div>
                                     <div class="mb-4" >
                                    <div class="description" > <strong>Travels Required</strong></div>
                                   {{post?.travels_required}}
                                </div>
                                    <div class="mb-4" >
                                    <div class="description" ><strong> Description</strong></div>
                                    <div [innerHtml]="post?.description"></div>
                            </div>
                            <div class="mb-4" >
                                    <div class="description" > <strong>How to Apply</strong></div>
                                <div [innerHtml]="post?.method_of_application | safeHtml"></div>
                            </div>
                            </div>
                                 <!-- <div class="col-3">
                                <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                     <i class="icofont-briefcase" *ngIf="post?.organization_id?.logo == ''" style="font-size: 56px"></i>
                                 <img class="img-thumbnail" *ngIf="post?.organization_id?.logo !== ''"  src="{{baseUrl}}/public/assets?media={{post.organization_id.logo}}" >
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Organization Name
                                    <span class="badge badge-pill">{{post?.organization_id?.name}}</span>
                                </li>
                                 <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Website
                                    <span class="badge  badge-pill"><a href="{{post?.organization_id?.website}}" target="_blank">{{post?.organization_id?.website}}</a></span>
                                </li>
                                </ul>
                                 </div> -->
                        </div>

											<div class="row mt-3 mb-3"  *ngFor="let file of post?.attachments">
                                                <div class="col-lg-1 file-name">
                                                        <i class="icofont-file-pdf big" *ngIf="file.extension == '.pdf'" ></i>
                                                        <i class="icofont-file-pdf big" *ngIf="file.extension == '.txt'" ></i>
                                                        <i class="icofont-image big" *ngIf="file.extension == '.jpg'" ></i>
                                                        <i class="icofont-image big" *ngIf="file.extension == '.jpeg'" ></i>
                                                        <i class="icofont-image big" *ngIf="file.extension == '.png'" ></i>
                                                    </div>
                                                        <div class="col-lg-11 file-name">
                                                                {{file?.name}}
                                                                <button class="btn btn-main animated fadeInUp rounded" (click)="downloadFile(file)">	Download</button>
                                                            </div>
                                                </div>

									<div class="share-option mt-5 clearfix">
												<ul class="list-inline">
													<li class="list-inline-item"> <strong>Share:</strong> </li>
													<li class="list-inline-item"><a href="https://www.facebook.com/sharer.php?u=https://www.ingoforum.ng/careers/{{post?.slug}}" target="_blank"><i class="icofont-facebook mr-2" ></i>Facebook</a></li>
													<li class="list-inline-item"><a href="https://www.twitter.com/share?url=https://www.ingoforum.ng/careers/{{post?.slug}}" target="_blank"><i class="icofont-twitter mr-2"></i>Twitter</a></li>
													<!-- <li class="list-inline-item"><a href="https://blog.ingoforum.ng/blog/{{blog?.slug}}" target="_blank"><i class="icofont-pinterest mr-2"></i>Pinterest</a></li> -->
													<li class="list-inline-item"><a href="https://www.linkedin.com/shareArticle?mini=true&?url=https://www.ingoforum.ng/careers/{{post?.slug}}" target="_blank"><i class="icofont-linkedin mr-2"></i>Linkedin</a></li>
												</ul>
											</div>

                </div>
        </div>
    </div>
</section>
<section class="section related">
    <div class="container">
        <div class="row justify-content-center">
			<div class="col-lg-7 text-center">
				<div class="section-title" >
					<h2 class="mt-3" > Related Opportunities</h2>
				</div>
			</div>
		</div>
          <div class="row">
            <div class="col-lg-4" *ngFor="let test of relatedPosts">
                <div class="card">
                <div class="card-body">
                    <div class="row">
                         <div class="col-1 align-vertically" *ngIf="test.organization_id.logo == ''">
                               <i class="icofont-briefcase" style="font-size: 26px"></i>
                            </div>
                                <div class="col-1 align-vertically" *ngIf="test.organization_id.logo !== ''">
                                            <img class="img-thumbnail"   src="{{baseUrl}}/public/assets?media={{test.organization_id.logo}}" >
                            </div>
                        <div class="col-8">
                              <h5 class="mb-1"><a  routerLink="/careers/{{test.slug}}">{{test?.name}} </a></h5>
                               <div class="mb-2">{{test?.organization_id?.name}}</div>
                              <div class="mb-2"> <span *ngIf="test.location">{{test.location}} &nbsp;  </span><span *ngIf="test.job_type_id?.name !== ''">| &nbsp;  {{test?.job_type_id?.name}}</span></div>
                              <!-- <div class="mb-2">[ {{test?.category_id?.name}} | {{test?.published_at | date}}  ]</div> -->
                        </div>
                        <div class="col-2 align-vertically">
                            <h5> <a routerLink="/careers/{{test.slug}}" class="text-color">Apply <i class="fas fa-forward"></i></a></h5>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>
