import { Component, OnInit } from '@angular/core';
import { AppFilter } from '../../../models';
import { PublicService, OptionService } from '../../../services';
import { BlogService } from 'src/app/services';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-news-detail',
	templateUrl: './news-detail.component.html',
	styleUrls: [ './news-detail.component.scss' ]
})
export class NewsDetailComponent implements OnInit {
	baseUrl = environment.API_SERVER;
	publication: any;
	tags: Array<any> = [ 'fun', 'event' ];
	categories: Array<any> = [];
	latest: Array<any> = [];
	filter: AppFilter;
	pending: boolean;
	error: any;
	success: any;
	totalItems: number;
	currentPage: number;
	rows: number;
	categoryId: string;
	tagId: string;
	publicationId: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private optionSvc: OptionService,
		private publicationSvc: PublicService,
		private title: Title
	) {
		this.filter = new AppFilter({ sidx: '-createdat' });
		this.filter.search = false;
		this.activatedRoute.params.subscribe((res) => {
			this.publicationId = res['slug'];
			if (this.publicationId) {
				this.searchRecords(this.publicationId);
			}
		});
		// this.getPage(1);

		// this.getLatests();
		// this.getOptionTypes();
	}
	incrementView(type) {
		this.pending = true;
		this.publicationSvc.incrementPost({ id: this.publicationId, param: { type: type } }).subscribe(
			(res) => {},
			(error) => {
				this.error = error.error.split('=').pop();
			}
		);
	}

	getBlog() {
		this.pending = true;
		this.publicationSvc.getAllNews({ slug: this.publicationId }).subscribe(
			(res) => {
				this.pending = false;
				this.publication = res;
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}
	getLatests() {
		let filter = new AppFilter({ sidx: '-createdat', rows: 3 });
		this.publicationSvc.getAllPublication({ params: this.filter }).subscribe(
			(res) => {
				this.pending = false;
				this.latest = res['resources'];
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}
	getCategories() {}

	getPage(page) {
		this.filter.page = page;
		this.pending = true;
		this.publicationSvc.getAllNews({ param: this.filter }).subscribe(
			(res) => {
				this.pending = false;
				this.publication = res['posts'] ? res['posts'][0] : null;
				this.title.setTitle(`${res['posts'][0]['name']} | Nigeria INGO Forum`);
				this.incrementView('view');
			},
			(error) => {
				this.pending = false;
				this.error = error.error.split('=').pop();
			}
		);
	}

	//handle option search
	searchRecords(search) {
		let filter = this.filterComposer(search);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}
	//handle sorting records
	typeRecords(search) {
		this.categoryId = search;
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	typeTags(search) {
		this.tagId = search;
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	//handle sorting records
	sortRecords(search) {
		this.filter.sord = search;
		this.getPage(1);
	}

	//handles rows display
	displayRecords(row) {
		this.filter['rows'] = row;
		this.filter.page = 1;
		this.getPage(1);
	}
	downloadFile(attachment) {
		let url = `${environment.API_SERVER}/public/assets?media=${attachment['path']}`;
		window.open(url, '_blank');
		this.incrementView('download');
	}

	nextPage(page: number) {
		//get and assign current page
		this.currentPage = page;
		this.getPage(this.currentPage);
	}

	//group query
	filterComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};

		if (this.categoryId) {
			type['$eq'] = this.categoryId;
			mongofilter['categoryid'] = type;
		}

		if (this.tagId) {
			// type['$eq'] = this.categoryId;
			mongofilter['tags'] = this.tagId;
		}
		// bson.M{"$lookup": bson.M{"from": "subjectcategory", "localField": "category", "foreignField": "_id", "as": "category"}},
		// bson.M{"$unwind": "$category"},

		if (query !== null) {
			type['$eq'] = query;
			mongofilter['slug'] = type;
		}

		return mongofilter;
	}

	ngOnInit(): void {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
	getOptionTypes() {
		let filter = new AppFilter({ sidx: '-order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'blog_category';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.optionSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.categories = res.options;
		});
	}
}
