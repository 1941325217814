<section class="section 404 border-top">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-10">
				<div class="error-content text-center">
					<p class="mb-4">Terms and Conditions.</p>
				</div>
			</div>
		</div>
	</div>
</section>