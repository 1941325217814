export * from './team/team.component';
export * from './about/about.component';
export * from './blog/blog.component';
export * from './home/home.component';
export * from './contact/contact.component';
export * from './careers/careers.component';
export * from './careers-detail/careers-detail.component';
export * from './our-work/our-work.component';
export * from './membership/membership.component';
export * from './ingo-coordination-platforms/ingo-coordination-platforms.component';
export * from './humanitarian-coordination-architecture/humanitarian-coordination-architecture.component';
export * from './government-coordinating-bodies/government-coordinating-bodies.component';
export * from './news/news.component';
export * from './intiatives/intiatives.component';
export * from './plrcap/plrcap.component';
export * from './news/news-detail/news-detail.component';
export * from './members/members.component';
export * from './members-detail/members-detail.component';
export * from './gallary/gallary.component';
export * from './media-contacts/media-contacts.component';
export * from './publications/publications.component';
export * from './publications/components/reports/reports.component';
export * from './maps/maps.component';
export * from './publications/components/leaflets/leaflets.component';
export * from './publications/components/newsletters/newsletters.component';
export * from './publications/components/reports/reports.component';
export * from './publications/components/snapshots/snapshots.component';
export * from './publications/components/publication-detail/publication-detail.component';
export * from './register/register.component';
export * from './gallary/gallary.component';
export * from './terms/terms.component';
export * from './privacy/privacy.component';
export * from './faq/faq.component';
export * from './not-found/not-found.component';
export * from './invoice/invoice.component';
export * from './members-detail/presence';
export * from "./dashboard/about.component"
