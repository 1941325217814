import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-intiatives',
	templateUrl: './intiatives.component.html',
	styleUrls: [ './intiatives.component.scss' ]
})
export class IntiativesComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Iniatives | Nigeria INGO Forum');
	}

	ngOnInit(): void {}
}
