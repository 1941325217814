import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PublicService {
	selectedResource: any = '';
	apiUrl = environment.API_SERVER;

	private annouce: Subject<any> = new Subject<any>();
	actionAdded$ = this.annouce.asObservable();

	annouceActionAdded(ann) {
		this.annouce.next(ann);
	}

	constructor(private _httpClient: HttpClient) {}
	subscribeNewsLetter(payload): Observable<any> {
		return this._httpClient.post(this.apiUrl + `/public/newsletter`, payload.body);
	}
	getInvoice(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/invoice`, {
			params: payload.params
		});
	}
	getPageSections(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/sections`, {
			params: payload.param
		});
	}
	incrementOpportunity(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/increment-opportunity/${payload.id}`, {
			params: payload.param
		});
	}
	incrementPublication(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/increment-publication/${payload.id}`, {
			params: payload.param
		});
	}
	incrementPost(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/increment-post/${payload.id}`, {
			params: payload.param
		});
	}
	uploadFile(payload) {
		return this._httpClient.post(this.apiUrl + '/v1/resource/upload', payload.body);
	}

	contactUs(payload) {
		return this._httpClient.post(this.apiUrl + '/public/contact-us', payload.body);
	}
	getAllBlog(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/resource`, { params: payload.param });
	}

	getAllOpportunity(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/opportunity`, { params: payload.param });
	}

	getAllNews(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/post`, { params: payload.param });
	}

	getAllPublication(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/publication`, { params: payload.param });
	}

	getAllResource(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/resource`, { params: payload.param });
	}

	getResourceById(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/resource/${payload.id}`);
	}

	getLession(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/v1/course/resource/${payload.id}`);
	}
	enrolLession(payload): Observable<any> {
		return this._httpClient.post(this.apiUrl + `/v1/course`, payload.body);
	}

	getAllOption(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/option`, { params: payload.param });
	}
	getOrganization(payload): Observable<any> {
		return this._httpClient.get(this.apiUrl + `/public/organization`, { params: payload.param });
	}
}
