<section class="page-title bg-membership">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <!-- <span class="text-white">Our Work</span> -->
          <h1 class="text-capitalize mb-4 text-lg">Members</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <section class="sections">
      <div class="card mb-3 border-0 mt-3" style="max-width: 180rem">
        <div class="card-body">
          <div class="container" id="who-are-our-members">
            <div class="row">
              <div class="col-lg-8 text-start">
                <h4 class="our-work-title">Who are our members?</h4>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col-lg-12 col-md-6 col-sm-12">
                <p class="">
                  We currently have 58 INGOs as our members and nine (9)
                  international humanitarian organisations as observers
                  implementing diverse
                  <a
                    href="https://resources.ngoworld.ng/"
                    class="text-color"
                    target="_blank"
                    rel="noopener noreferrer"
                    >interventions</a
                  >
                  in the field of humanitarian, recovery and development across
                  Nigeria.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <p>
                For further questions or enquiries, please see our
                <a class="text-color" routerLink="/faq" target="_blank" rel="noopener noreferrer">Frequently Ask
                    Questions </a>
                or you can
                <a class="text-color" routerLink="/contact-us" target="_blank" rel="noopener noreferrer">contact us.
                </a>

            </p> -->
    </section>
    <div class="row no-gutters text-center">
      <div class="col-lg-12" *ngIf="pending">
        <div class="d-flex justify-content-center">
          <div
            class="spinner-grow"
            style="width: 5rem; height: 5rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6" *ngFor="let member of members">
        <div class="feature-inner">
          <!-- <a routerLink="/members/{{member.acronym }}" > -->
          <a href="{{ member.webiste }}" target="_blank">
            <img
              src="{{ baseUrl }}/public/assets?media={{ member.logo }}"
              alt="member"
              class="img-fluid host-logo"
            />
          </a>
          <!-- <p>Oct 2019 - Oct-2020</p> -->
        </div>
      </div>
    </div>
  </div>
</section>
