<section class="page-title bg-about">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <span class="text-white">who we are</span>
          <h1 class="text-capitalize mb-4 text-lg">About Us</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section about-page">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 text-center">
        <div class="section-title">
          <h3 id="who-we-are" class="mt-1 content-title">Who we are</h3>
          <p>
            NIF is an alliance of international NGOs providing humanitarian and
            development assistance in Nigeria.
          </p>
          <p>
            We exist to facilitate members' collective action in the principled
            and effective delivery of much needed assistance to save and improve
            the lives of poor and vulnerable people.
          </p>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-home"></i> -->
          <h4>Our Objectives</h4>
          <!-- <p>Work Since 1987 with Pride!!</p> -->

          <ul id="brown">
            <li>
              Foster cooperation, coalition-building and collaboration of
              international NGOs
            </li>
            <li>
              Promote and protect principled and effective humanitarian action
              and development interventions
            </li>
            <li>
              Advance the rights of affected populations and perspectives of
              operational actors
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-web"></i> -->
          <h4>Our Principles</h4>
          <p>
            As a body of non-profit, non-partisan organisations, NIF and its
            members promote and are guided by:
          </p>
          <ul id="brown">
            <li>
              Respect for humanitarian principles of humanity, impartiality,
              neutrality, and independence
            </li>
            <li>
              Integration of Do No Harm principle and protection sensitive
              approach to all internal and external engagements
            </li>
            <li>
              Accountability to the affected and the most vulnerable populations
              and programme beneficiaries in Nigeria. We ensure their voice and
              perspectives are central to decision-making
            </li>
            <li>
              Respect for Federal and State Laws of Nigeria, as well as other
              regulations and practice of International Humanitarian Law and
              International Human Rights Law
            </li>
            <li>
              Maintain a high level of integrity and professionalism across all
              spheres of conduct
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-users"></i> -->
          <h4>Our History</h4>
          <p>
            With the emergence of massive humanitarian needs in north-east
            Nigeria, which required an increased presence of humanitarian
            organisations, including international NGOs, a group of INGO country
            directors felt the need for more synergy within INGOs and improved
            coordination with other actors.
          </p>
          <p>
            Founded in 2014, NIF is an alliance of international NGOs providing
            humanitarian and development assistance in Nigeria. Starting with 14
            members in 2014, we have now grown to fifty four (54) members and
            nine (9) observers. All our members and observers have physical
            presence in Nigeria and are legally operating in the country. With
            the scale-up as a result of the increased needs and corresponding
            global, political, and financial attention that north-east Nigeria
            received since 2016, it is now more important than ever to have a
            collective voice when addressing critical challenges. While our
            members are international NGOs, NIF commits to strengthening
            relationships, linkages, and collaborative efforts where appropriate
            with existing national NGO platforms and entities.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feature pt-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 text-center mt-5">
        <div class="section-title governance-header">
          <!-- <span class="h6 text-color">Our volunteer</span> -->
          <h3
            class="mt-1 content-title governance-header"
            id="governance-and-structure"
          >
            Governance and Structure
          </h3>
          <p class="governance-header">
            NIF currently adopts a three-tier structure - national, north-east
            and localisation offices - under the overall leadership and guidance
            of the Country Directors (or "Plenary") Group and an elected
            Steering Committee. The structure is further explained below:
          </p>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-home"></i> -->
          <h4>Secretariat</h4>
          <p>
            There are currently twenty one (21) staff, including interns, based
            in Abuja and Maiduguri who administer and facilitate NIF affairs,
            led by the Secretariat Director. An elected Steering Committee Chair
            supports the Director in leading and overall facilitation of
            members’ interests.
          </p>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-web"></i> -->
          <h4>Planary Group</h4>
          <p>
            A senior decision-making facility for NIF that acts as a forum for
            information exchange, communication, consensus building and overall
            guidance on INGO Forum activities.
          </p>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-users"></i> -->
          <h4>Steering Committee (SC)</h4>
          <p>
            An elected body of five (5) representatives comprising of senior
            decision makers (Country Directors, Deputy Country Directors or
            other strategic decision-making authority) who strategically guide
            the Secretariat on key decisions and workplans, and agreed
            priorities. It acts as first resort representation for NIF at
            high-level external engagements and as last resort for taking
            decisions in the best interest of the Forum and its membership.
          </p>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-users"></i> -->
          <h4>Humanitarian Platform</h4>
          <p>
            A devolved body of Humanitarian Leads and some Country Directors or
            Heads of Missions based in Maiduguri who act as an operational space
            for dialogue, information sharing and guiding the Forum's activities
            in north-east Nigeria. The platform advises the SC and Plenary Group
            on humanitarian issues and priorities.
          </p>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="feature-inner">
          <!-- <i class="icofont-users"></i> -->
          <h4>Working Group</h4>
          <p>
            This includes permanent and ad hoc groups that support the
            functional coordination of NIF members and observers. Permanent
            groups include Humanitarian Platform Task Team (HPTT), Human
            Resources Working Group (HRWG), Logistics Working Group (LWG) and
            Finance Working Group (FWG). Ad hoc groups and committees are set up
            based on needs.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!--  Section Services Start -->
<section class="section team">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 text-center">
        <div class="section-title">
          <h2 id="our-team" class="mt-3 content-title">Secretariat Team</h2>
          <p>
            The Secretariat consists of 21 full time staff and interns in both
            its Abuja and Maiduguri offices
          </p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12 team-slider">
        <div *ngFor="let team of teams">
          <div class="team-item-wrap">
            <div class="team-item position-relative">
              <img src="{{ team.IMAGE }}" alt="" class="img-fluid w-100" />
              <div class="team-img-hover">
                <ul class="team-social list-inline">
                  <li class="list-inline-item">
                    <a
                      href="{{ team?.Twitter ? team?.Twitter : '#' }}"
                      target="_blank"
                      class="twitter"
                      ><i class="icofont-twitter" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="{{ team?.Skype ? team?.Skype : '#' }}"
                      target="_blank"
                      class="skype"
                      ><i class="icofont-skype" aria-hidden="true"></i
                    ></a>
                  </li>

                  <li class="list-inline-item">
                    <a
                      href="{{ team?.LinkedIn ? team?.LinkedIn : '#' }}"
                      target="_blank"
                      class="linkedin"
                      ><i class="icofont-linkedin" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="team-item-content">
              <h4 class="mt-3 mb-0 text-capitalize">{{ team?.Name }}</h4>
              <p>{{ team?.Position }}</p>
              <p>{{ team?.Location }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
			<div class="col-lg-12">
				<div class="text-center">
					<a href="volunteer.html" class="btn btn-main rounded">Become a volunteer</a>
				</div>
			</div>
		</div> -->
  </div>
</section>

<!--  Section Services Start -->
<section class="section committee">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 text-center">
        <div class="section-title">
          <h2 class="mt-3 content-title">Steering Committee</h2>
          <p>
            A body of four (4) elected representatives and a host agency
            representative form NIF SC
          </p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12 commitee-slider">
        <div *ngFor="let team of committerTeam">
          <div class="team-item-wrap">
            <div class="team-item position-relative">
              <img
                src="{{ team.src }}"
                alt=""
                class="img-fluid w-80 border-design"
              />
              <div class="team-img-hover">
                <ul class="team-social list-inline">
                  <li class="list-inline-item">
                    <a href="{{ team.twitter }}" class="twitter"
                      ><i class="icofont-twitter" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a href="{{ team.facebook }}" class="facebook"
                      ><i class="icofont-facebook" aria-hidden="true"></i
                    ></a>
                  </li>

                  <li class="list-inline-item">
                    <a href="{{ team.link }}" class="linkedin"
                      ><i class="icofont-globe" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="team-item-content">
              <h4 class="mt-3 mb-0 text-capitalize">{{ team?.Name }}</h4>
              <p>
                {{ team?.Position }}<br />

                <span
                  *ngIf="
                    team.Name == 'Paul Byars' || team.Name == 'Arie Claassens'
                  "
                  >{{ team?.Extra }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
			<div class="col-lg-12">
				<div class="text-center">
					<a href="volunteer.html" class="btn btn-main rounded">Become a volunteer</a>
				</div>
			</div>
		</div> -->
  </div>
</section>

<!--  Section Services End -->
<section class="section" id="hosting-and-funding">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 text-center">
        <div class="section-title">
          <!-- <span class="h6 text-color">Hosting & Founding</span> -->
          <h2 class="mt-3 content-title">Hosting & Funding</h2>
          <!-- <p>A body of four (4) elected representatives and a host agency representative form NIF SC</p> -->
        </div>
      </div>
    </div>

    <!-- <div class="row ">
			<div class="col-lg-5 col-md-12 text-center">
				<img src="/assets/images/members/immap.png" alt="iMMAP" class="img-fluid host-logo">
					 <img src="/assets/images/team/volunteer-img1.1.jpg" alt="" class="img-fluid w-100"> 
				<p class="text-description">
					An international not-for-profit organization that provides information management services to
					humanitarian and development actors.<br/>
					Nov 2020 - Present
				</p>
			</div>
			<div class="col-lg-7 col-md-12">
				<p>
					NIF is a non-corporate entity that derives its legal and administrative status through its host
					agency. The host agency for the Forum rotates among members on a periodic basis - mostly on a
					two-year basis. Since inception in 2014, NIF has undergone hosting by five (5) different members.
					The current host agency is <a href="https://immap.org" target="_blank">iMMAP</a>.
				</p>
			</div>
		</div> -->
    <!-- <hr/> -->
    <div class="row no-gutters text-center">
      <div class="col-lg-3 col-md-6">
        <div class="feature-inner">
          <a
            href="https://www.nrc.no/countries/africa/nigeria/"
            target="_blank"
          >
            <img
              src="/assets/images/members/nrc.png"
              alt="iMMAP"
              class="img-fluid host-logo"
            />
            <p>Oct 2019 - Oct-2020</p>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="feature-inner">
          <a href="https://nigeria.mercycorps.org/" target="_blank">
            <img
              src="/assets/images/members/mcn.png"
              alt="MCN"
              class="img-fluid host-logo"
            />
            <p>May 2017 - Sep-2019</p>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="feature-inner">
          <a href="https://nigeria.oxfam.org/" target="_blank">
            <img
              src="/assets/images/members/oxfam.png"
              alt="Oxfam"
              class="img-fluid host-logo"
            />
            <p>May 2016 – Feb 2017</p>
          </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="feature-inner">
          <a
            href="https://www.actionagainsthunger.org/countries/africa/nigeria"
            target="_blank"
          >
            <img
              src="/assets/images/members/acf.png"
              alt="ACN"
              class="img-fluid host-logo"
            />
            <p>Oct 2014 – Apr 2016</p>
          </a>
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-5">
      <div class="col-md-12">
        <p>
          Our programmes and activities would not be possible without the
          generous support of our partners, members, and observers. We currently
          receive funding from four donor institutions.
        </p>
      </div>
      <div class="col-md-12">
        <div class="member-wrap">
          <div *ngFor="let member of donors">
            <div class="members-item-wrap">
              <div class="team-item position-relative">
                <a href="{{ member.href }}" target="_blank">
                  <img src="{{ member?.src }}" alt="" class="img-fluid" />
                </a>
              </div>
            </div>
            <!-- <a href="{{member.href}}" target="_blank">Visit Website</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section>
	<div class="container">
		<div class="row">
			<div class="col-lg-12">
				<div class="section-divider"></div>
			</div>
		</div>
	</div>
</section> -->
