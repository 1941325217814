export const Teams = [
	// {
	// 	Name: 'Camilla Corradin',
	// 	Position: 'Director',
	// 	Location: 'Abuja',
	// 	Team: 'Country Office',
	// 	Skype:"https://skype.com/camilla.corradin",
	// 	LinkedIn:"https://linkedin.com/in/camillacorradin",
	// 	Twiiter:"https://www.twitter/CamiCorradin",
	// 	IMAGE: '/assets/images/team/camilla.png'
	// },
	// {
	// 	Name: 'Yasmine Chawaf',
	// 	Position: 'Deputy Director, North-East',
	// 	Location: 'Maiduguri',
	// 	Team: 'North-East',
	// 	IMAGE: '/assets/images/team/Yasmine.jpg'
	// },
	{
		Name: 'Musa Baba',
		Position: 'Deputy Director Abuja',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/Musa Baba.jpg'
	},
	{
		Name: 'Lynn van Beek ',
		Position: 'Policy Advisor and Director A.I',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/lyn.jpg',
		LinkedIn: 'https://www.linkedin.com/in/lynn-van-beek/'
	},
	// {
	// 	Name: 'Elise Baudot Queguiner',
	// 	Position: 'Senior Consultant PLRCAP',
	// 	Location: 'Remote',
	// 	Team: 'Localisation & Development',
	// 	IMAGE: '/assets/images/team/elise.png'
	// },
	{
		Name: 'Abubakar Abdullahi Suleiman',
		Position: 'Government Liaison Advisor',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/abubakar.png'
	},
	{
		Name: 'Ademilola Adesanya',
		Position: 'Media and Communication Adviser',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/ademilola.png'
	},
	{
		Name: 'Ridwan Mahmud',
		Position: 'Access Advisor',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/ridwan.jpg',
		LinkedIn: "https://www.linkedin.com/in/ridwan-mahmud/",
		Skype:"https://skype.com/ridwan12131",
		Twitter: "rid1_mahmud",
	},
	// {
	// 	Name: 'Oni Oluwashina Abayomi',
	// 	Position: 'Information Management Specialist',
	// 	Location: 'Abuja',
	// 	Team: 'Country Office',
	// 	LinkedIn:"https://www.linkedin.com/in/oluwashina-abayomi-oni-a0aa211a5/",
	// 	Skype:"https://skype.com/Shina Oni",
	// 	IMAGE: '/assets/images/team/Oni_Oluwashina_Abayomi.jpg'
	// },
	{
		Name: 'Confidence Obayuwana',
		Position: 'Government Liaison Coordinator',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/confidence.png'
	},
	// {
	// 	Name: 'Favour Etim Udi',
	// 	Position: 'Senior Programme Officer',
	// 	Location: 'Abuja',
	// 	Team: 'Country Office',
	// 	IMAGE: '/assets/images/team/favour_udi2.png',
	// 	LinkedIn: "https://www.linkedin.com/in/favour-udi-5393b2222",
	// 	Skype: "https://skype.com/Favour.Udi",
	// 	Twitter: "Favourudi1",
	// },
	{
		Name: 'Ndubuisi Ezenwa Kanu',
		Position: 'Strategic Development and Partnership Coordinator, PLRCAP',
		Location: 'Abuja',
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/Ndubuisi.jpg',
		LinkedIn:"http://linkedin.com/in/ndubuisi-kanu-2b5a7227/",
		Twitter:"https://www.twitter.com/endymann3",
		Skype:"live:ndubuisi.kanu1",
	},
	// {
	// 	Name: 'Ada James Emmanuel',
	// 	Position: 'Project Manager, PLRCAP',
	// 	Location: 'Abuja',
	// 	LinkedIn:"https://www.linkedin.com/in/ada-james-787265136/",
	// 	Twitter:"https://www.twitter.com/JEA_approach",
	// 	Team: 'Localisation & Development',
	// 	IMAGE: '/assets/images/team/James_Ada.jpeg'
	// },
	// {
	// 	Name: 'Gozie',
	// 	Position: 'MEAL Coordinator, PLRCAP',
	// 	Location: 'Abuja',
	// 	LinkedIn:"",
	// 	Twitter:"",
	// 	Team: '',
	// 	IMAGE: '/assets/images/team/Gozie.jpeg'
	// },
	// {
	// 	Name: 'Mustapha Gajerima Lawan',
	// 	Position: 'Access Officer',
	// 	Location: 'Maiduguri',
	// 	Team: 'North-East',
	// 	IMAGE: '/assets/images/team/mustaha.png'
	// },
	{
		Name: 'Fatima Abubakar Sadiq',
		Position: 'Administrative & Logistics Technical Assistant',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/FatimaAbubakar.jpg',
		LinkedIn:"https://www.linkedin.com/in/fatima-abubakar-59818018b",
		Twitter:"https://www.twitter.com/SitruBanat/Status/1530066238428282880?t=KM5DXSOdisholPectpn7bQ&s=19",
		Skype:"live:ummiabubakar89",
	},
	{
		Name: 'Miebaka Peters',
		Position: 'Operations Coordinator',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/miebaka.png'
	},
	{
		Name: 'Theola Ehinomhen Amiokhabhor',
		Position: 'Communications Assistant',
		Location: 'Abuja',
		LinkedIn:"https://ng.linkedin.com/in/theola-monday",
		Skype:"https://skype.com/Theola Monday",
		Twitter:"https://www.twitter.com/thetheolamonday",
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/Theola.jpg'
	},
	{
		Name: 'Delores-Joan Idumesaro',
		Position: 'Learning Support Officer, PLRCAP',
		Location: 'Abuja',
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/Delores-Joan_Idumesaro.jpg'
	},
	// {
	// 	Name: 'Elisa Capponi',
	// 	Position: 'Deputy Director North-East',
	// 	Location: 'Abuja',
	// 	// Team: 'Localisation & Development',
	// 	IMAGE: '/assets/images/team/Elisa.jpg'
	// },
	{
		Name: 'Constance Ufuoma Edesiri',
		Position: 'PLRCAP Intern',
		Location: 'Abuja',
		// Team: 'Localisation & Development',
		LinkedIn:"https://www.linkedin.com/in/constance-edesiri-b1b4b0171",
		IMAGE: '/assets/images/team/Constance.jpg'
	},
	{
		Name: 'Gozie Obiora Okafor',
		Position: 'MEAL Coordinator, PLRCAP',
		Location: 'Abuja',
		// Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/gozie-photo.jpg'
	},

	// {
	// 	Name: 'Upon Recruitment',
	// 	Position: 'PLRCAP Intern',
	// 	Location: 'Abuja',
	// 	Team: 'Localisation & Development',
	// 	IMAGE: '/assets/images/team/volunteer-img1.1.jpg'
	// },
	{
		Name: 'Iliasu Akande',
		Position: 'Driver',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/iliasu_akande.jpeg'
	},
	{
		Name: 'David Amos',
		Position: 'Information Management Specialist, Abuja',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/David Amos.png'
	},
	{
		Name: 'Esther Jolomi',
		Position: 'Administrative Technical Assistant, Abuja',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/Esther Jolomi.png'
	},
	{
		Name: 'Hassan Adamu',
		Position: 'Driver',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/Hassan Adamu.png'
	}
];
