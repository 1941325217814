import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-humanitarian-coordination-architecture',
	templateUrl: './humanitarian-coordination-architecture.component.html',
	styleUrls: [ './humanitarian-coordination-architecture.component.scss' ]
})
export class HumanitarianCoordinationArchitectureComponent implements OnInit {
	chart = '/assets/images/other-img/sector Chart.png';
	constructor(private title: Title) {
		this.title.setTitle('Humanitarian Coordination Architecture | Nigeria INGO Forum');
	}

	ngOnInit(): void {}
}
