export const Memebers = [
	{
		src: '/assets/images/members/aan.png',
		alt: 'acf_aah.png',
		link: 'https://nigeria.actionaid.org/'
	},
	{
		src: '/assets/images/members/acf.png',
		alt: 'actionaid.png',
		link: 'https://www.actionagainsthunger.org/countries/africa/nigeria'
	},
	{
		src: '/assets/images/members/acted.png',
		alt: 'acted.png',
		link: 'https://www.acted.org/en/countries/nigeria/'
	},
	{
		src: '/assets/images/members/alert.png',
		alt: 'alert.png',
		link: 'https://www.international-alert.org/where-we-work/nigeria'
	},
	{
		src: '/assets/images/members/alima.png',
		alt: 'alima.png',
		link: 'https://alima.ngo/en/where-we-work/nigeria/'
	},
	{
		src: '/assets/images/members/bbc-media.png',
		alt: 'bbc-media.png',
		link: 'https://www.bbc.co.uk/mediaaction/'
	},
	{
		src: '/assets/images/members/cafod.png',
		alt: 'cafod.png',
		link: 'https://cafod.org.uk/About-us/Where-we-work/Africa/Nigeria'
	},
	{
		src: '/assets/images/members/caid.png',
		alt: 'caid.png',
		link: 'https://www.christianaid.org/nigeria/'
	},
	{
		src: '/assets/images/members/care.png',
		alt: 'care.png',
		link: 'https://www.care-international.org/where-we-work/nigeria'
	},
	{
		src: '/assets/images/members/cbm.png',
		alt: 'cbm.png',
		link: 'https://www.cbm.org/'
	},
	{
		src: '/assets/images/members/civic.png',
		alt: 'civic.png',
		link: 'https://civiliansinconflict.org/our-work/where-we-work/nigeria/'
	},
	{
		src: '/assets/images/members/coopi.png',
		alt: 'coopi.png',
		link: 'https://www.coopi.org/en/country/nigeria.html'
	},
	{
		src: '/assets/images/members/crs.png',
		alt: 'crs.png',
		link: 'https://www.crs.org/our-work-overseas/where-we-work/nigeria'
	},
	{
		src: '/assets/images/members/drc.png',
		alt: 'DRC',
		link: 'https://drc.ngo/where-we-work/central-and-west-africa/nigeria'
	},
	{
		src: '/assets/images/members/eai.png',
		alt: 'EAI',
		link: 'https://www.equalaccess.org/our-work/countries/nigeria/'
	},
	{
		src: '/assets/images/members/ehealth.png',
		alt: 'eHealth Africa',
		link: 'https://www.ehealthafrica.org/'
	},
	{
		src: '/assets/images/members/fhi360.png',
		alt: 'FHI360',
		link: 'https://www.fhi360.org/countries/nigeria'
	},
	{
		src: '/assets/images/members/hai.png',
		alt: 'HAI',
		link: 'https://www.heartlandalliance.org/program/sub-saharan-africa/nigeria/'
	},
	{
		src: '/assets/images/members/hd-centre.png',
		alt: 'HD Centre',
		link: 'https://www.hdcentre.org/activities/jos-plateau-state-nigeria/'
	},
	{
		src: '/assets/images/members/hki.png',
		alt: 'HKI',
		link: 'https://www.hki.org/'
	},
	{
		src: '/assets/images/members/ieda.png',
		alt: 'IEDA Relief',
		link: 'https://iedarelief.org/'
	},
	{
		src: '/assets/images/members/immap.png',
		alt: 'iMMAP',
		link: 'https://immap.org/nigeria/'
	},
	{
		src: '/assets/images/members/imc.png',
		alt: 'IMC',
		link: 'https://internationalmedicalcorps.org/country/nigeria/'
	},
	{
		src: '/assets/images/members/inso.png',
		alt: 'INSO',
		link: 'https://www.ngosafety.org/'
	},
	{
		src: '/assets/images/members/intersos.png',
		alt: 'INTERSOS',
		link: 'https://www.intersos.org/en/what-we-do/nigeria/'
	},
	{
		src: '/assets/images/members/irc.png',
		alt: 'IRC',
		link: 'https://www.rescue.org/country/nigeria'
	},
	{
		src: '/assets/images/members/jrs.png',
		alt: 'JRS',
		link: 'https://jrs.net/en/home/'
	},
	{
		src: '/assets/images/members/mag.png',
		alt: 'MAG',
		link: 'https://www.maginternational.org/what-we-do/where-we-work/mag-in-nigeria/'
	},
	{
		src: '/assets/images/members/malteser.png',
		alt: 'Malteser',
		link: 'https://www.malteser-international.org/en/our-work/africa/nigeria.html'
	},
	{
		src: '/assets/images/members/mcn.png',
		alt: 'MCN',
		link: 'https://nigeria.mercycorps.org/'
	},
	{
		src: '/assets/images/members/mdm.png',
		alt: 'MDM',
		link: 'http://www.medecinsdumonde.org/en/countries/afrique/nigeria'
	},
	{
		src: '/assets/images/members/mentor.png',
		alt: 'Mentor',
		link: 'https://www.thementorinitiative.org/'
	},
	// {
	// 	src:'/assets/images/members/',
	// 	alt:'Mercycorps',
	// 	link:'https://nigeria.mercycorps.org/'
	// },
	{
		src: '/assets/images/members/msion.png',
		alt: 'MSION',
		link: 'https://www.mariestopes.org.ng/'
	},
	{
		src: '/assets/images/members/nca.png',
		alt: 'NCA',
		link: 'https://www.kirkensnodhjelp.no/en/where-we-work/nigeria2/'
	},
	{
		src: '/assets/images/members/nrc.png',
		alt: 'NRC',
		link: 'https://www.nrc.no/countries/africa/nigeria/'
	},
	{
		src: '/assets/images/members/nutrition.png',
		alt: 'NI (Nutrition)',
		link: 'https://www.nutritionintl.org/our-work/our-global-projects/africa/nigeria/'
	},
	{
		src: '/assets/images/members/oxfam.png',
		alt: 'Oxfam',
		link: 'https://nigeria.oxfam.org/'
	},
	{
		src: '/assets/images/members/pact.png',
		alt: 'Pact',
		link: 'https://www.pactworld.org/country/nigeria'
	},
	{
		src: '/assets/images/members/plan.png',
		alt: 'Plan',
		link: 'https://plan-international.org/nigeria'
	},
	{
		src: '/assets/images/members/pui.png',
		alt: 'PUI',
		link: 'https://www.premiere-urgence.org/en/mission/nigeria/'
	},
	{
		src: '/assets/images/members/rhdi.png',
		alt: 'RHDI',
		link: 'http://www.reconnecthdi.org/'
	},
	{
		src: '/assets/images/members/sc.png',
		alt: 'SC',
		link: 'https://www.street-child.co.uk/nigeria'
	},
	{
		src: '/assets/images/members/sci.png',
		alt: 'SCI',
		link: 'https://www.savethechildren.org/us/where-we-work/nigeria'
	},
	{
		src: '/assets/images/members/sfcg.png',
		alt: 'SFCG',
		link: 'https://www.sfcg.org/nigeria/'
	},

	{
		src: '/assets/images/members/si.png',
		alt: 'Solidarites (SI)',
		link: 'https://www.solidarites.org/en/missions/nigeria/'
	},
	{
		src: '/assets/images/members/sif.png',
		alt: 'SIF',
		link: 'https://www.secours-islamique.org/mission-sif-nigeria'
	},
	{
		src: '/assets/images/members/sos-cv.png',
		alt: 'SOS CV',
		link: 'https://www.sosvillages-nigeria.org/'
	},
	{
		src: '/assets/images/members/tdh.png',
		alt: 'TDH',
		link: 'https://www.tdh.ch/en/our-interventions/nigeria'
	},
	{
		src: '/assets/images/members/tf.png',
		alt: 'Tearfund (TF)',
		link: 'https://www.tearfund.org/'
	},
	{
		src: '/assets/images/members/twb.png',
		alt: 'TWB',
		link: 'https://translatorswithoutborders.org/twb-response-nigeria/'
	},
	{
		src: '/assets/images/members/vso.png',
		alt: 'VSO',
		link: 'https://www.vsointernational.org/our-work/where-we-work/nigeria'
	},
	{
		src: '/assets/images/members/wan.png',
		alt: 'Wateraid (WAN)',
		link: 'http://www.wateraid.org/ng'
	},
	{
		src: '/assets/images/members/wfwi.png',
		alt: 'WfWI',
		link: 'https://www.womenforwomen.org/where-we-work/nigeria'
	},
	{
		src: '/assets/images/members/zoa.png',
		alt: 'ZOA',
		link: 'https://www.zoa-international.com/files/nigeria/'
	}
];

export const Supporter = [
	{
		src: '/assets/images/donors/ECHO-Logo.png',
		alt: 'donors/ECHO-Logo.png'
	},
	{
		src: '/assets/images/donors/NIF-Contributions-Logo.png',
		alt: 'NIF-Contributions-Logo.png'
	},
	{
		src: '/assets/images/donors/SDC-Logo.png',
		alt: 'SDC-Logo.png'
	},
	{
		src: '/assets/images/donors/USAID-Logo.png',
		alt: 'USAID-Logo.png'
	},
	{
		src: '/assets/images/donors/UKAID-Logo.png',
		alt: 'UKAID-Logo.png'
	}
];
