import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit {
	title = 'ingoforum-static';
	public cookie_name = null;
	private all_cookies: any = '';

	constructor(private cookieService: CookieService, public router: Router) {
		this.cookie_name = this.cookieService.get('nif_website');
	}

	setCookie() {
		const daysToExpire = new Date(2147483647 * 1000);
		this.cookieService.set('nif_website', 'NIF_Nigeria_INGO_Forum', { expires: daysToExpire });
		this.cookie_name = this.cookieService.get('nif_website');
	}

	deleteCookie() {
		this.cookieService.delete('nif_website');
	}

	deleteAll() {
		this.cookieService.deleteAll();
	}

	ngOnInit(): void {
		this.cookie_name = this.cookieService.get('nif_website');
		// this.all_cookies = this.cookieService.getAll(); // get all cookies object
	}
}
