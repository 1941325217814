

<section class="page-title bg-1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <span class="text-white">News details</span>
          <h1 class="text-capitalize mb-4 text-lg">A place where start new life with peace</h1>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="section blog-wrap">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
	<div class="col-lg-12 mb-5">
		<div class="single-blog-item">
			<img src="images/blog/image-5.jpg" alt="" class="img-fluid w-100">

			<div class="blog-item-content mt-3">
				<h2 class="mt-3"><a href="blog-single.html">A place where start new life with peace</a></h2>

				<div class="blog-item-meta py-1 px-2 mb-4">
					<span class="text-muted mr-3"><i class="icofont-comment mr-2"></i>5 Comments</span>
					<span class="text-black mr-3"><i class="icofont-calendar mr-1"></i> 28th January</span>
					<span class="text-black mr-3"><i class="icofont-clock-time mr-1"></i> 2 min Read</span>
				</div>

				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus natus, consectetur? Illum libero vel nihil nisi quae, voluptatem, sapiente necessitatibus distinctio voluptates, iusto qui. Laboriosam autem, nam voluptate in beatae.</p>

				<h3 class="quote">A brand for a company is like a reputation for a person. You earn reputation by trying to do hard things well.</h3>

				<p class="lead mb-4 font-weight-normal text-black">The same is true as we experience the emotional sensation of stress from our first instances of social rejection ridicule. We quickly learn to fear and thus automatically.</p>

				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste, rerum beatae repellat tenetur incidunt quisquam libero dolores laudantium. Nesciunt quis itaque quidem, voluptatem autem eos animi laborum iusto expedita sapiente.</p>

				<div class="share-option mt-5 clearfix">
				    <ul class="list-inline">
				        <li class="list-inline-item"> <strong>Share:</strong> </li>
				        <li class="list-inline-item"><a href="#" target="_blank"><i class="icofont-facebook mr-2" ></i>Facebook</a></li>
				        <li class="list-inline-item"><a href="#" target="_blank"><i class="icofont-twitter mr-2"></i>Twitter</a></li>
				        <li class="list-inline-item"><a href="#" target="_blank"><i class="icofont-pinterest mr-2"></i>Pinterest</a></li>
				        <li class="list-inline-item"><a href="#" target="_blank"><i class="icofont-linkedin mr-2"></i>Linkedin</a></li>
				    </ul>
			    </div>
			</div>
		</div>
	</div>

	<div class="col-lg-12 mb-5">
		<div class="comment-area">
			<h3 class="mb-4">2 Comments</h3>
			<ul class="comment-tree list-unstyled">
				<li class="mb-5">
					<div class="comment-area-box">
						<img alt="" src="images/blog/gallery-5.jpg" class="img-fluid float-left mr-3 mt-2">

						<h5 class="mb-1">Micle harison</h5>
						<span class="date-comm">June 7, 2019 </span>

						<div class="comment-meta mt-4 mt-lg-0 mt-md-0 float-lg-right">
							<a href="#"><i class="icofont-reply mr-2 text-muted"></i>Reply</a>
						</div>

						<div class="comment-content mt-3">
							<p>Some consultants are employed indirectly by the client via a consultancy staffing company. </p>
						</div>
					</div>
				</li>

				<li>
					<div class="comment-area-box">
						<img alt="" src="images/blog/gallery-5.jpg" class="mt-2 img-fluid float-left mr-3">

						<h5 class="mb-1">John Doe</h5>
						<span class="date-comm">June 7, 2019</span>


						<div class="comment-meta mt-4 mt-lg-0 mt-md-0 float-lg-right">
							<a href="#"><i class="icofont-reply mr-2 text-muted"></i>Reply</a>
						</div>

						<div class="comment-content mt-3">
							<p>Some consultants are employed indirectly by the client via a consultancy staffing company. </p>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="col-lg-12">
		<form class="comment-form" id="comment-form">
			<h3 class="mb-4">Leave  a comment</h3>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<input class="form-control" type="text" name="name" id="name" placeholder="Name:">
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<input class="form-control" type="text" name="mail" id="mail" placeholder="Email:">
					</div>
				</div>
			</div>

			<textarea class="form-control mb-3" name="comment" id="comment" cols="30" rows="5" placeholder="Comment"></textarea>

			<input class="btn btn-main rounded" type="submit" name="submit-contact" id="submit_contact" value="Submit Message">
		</form>
	</div>
</div>
            </div>
            <div class="col-lg-4">
                <div class="sidebar-wrap mt-5 mt-lg-0">
	<div class="sidebar-widget search p-4 mb-3 ">
		<input type="text" class="form-control" placeholder="search">
		<a href="#"><i class="icofont-search"></i></a>
	</div>

	<div class="sidebar-widget latest-post p-4 mb-3">
		<h5>Latest Posts</h5>

        <div class="border-bottom py-3">
            <span class="text-sm text-muted">03 Mar 2018</span>
            <h5 class="my-2"><a href="#">Thoughtful living in los Angeles</a></h5>
        </div>

        <div class="border-bottom py-3">
            <span class="text-sm text-muted">03 Mar 2018</span>
            <h5 class="my-2"><a href="#">Vivamus molestie gravida turpis.</a></h5>
        </div>

        <div class="py-3">
            <span class="text-sm text-muted">03 Mar 2018</span>
            <h5 class="my-2"><a href="#">Fusce lobortis lorem at ipsum semper sagittis</a></h5>
        </div>
	</div>

	<div class="sidebar-widget categories p-4 mb-3">
		<h5 class="mb-4">Caregory</h5>
		<ul class="list-unstyled">
			<li><a href="#"><i class="icofont-folder mr-3"></i>Education</a></li>
			<li><a href="#"><i class="icofont-folder mr-3"></i>Water</a></li>
			<li><a href="#"><i class="icofont-folder mr-3"></i>Food</a></li>
			<li><a href="#"><i class="icofont-folder mr-3"></i>Medicine</a></li>
			<li><a href="#"><i class="icofont-folder mr-3"></i>Shelter</a></li>
		</ul>
	</div>
	<div class="sidebar-widget tags p-4 mb-3">
		<h5 class="mb-4">Tags</h5>
		<a href="#">Web</a>
		<a href="#">agency</a>
		<a href="#">company</a>
		<a href="#">creative</a>
		<a href="#">html</a>
		<a href="#">Marketing</a>
		<a href="#">Social Media</a>
		<a href="#">Branding</a>
	</div>

</div>
            </div>
        </div>
    </div>
</section>

