import { Component, OnInit } from '@angular/core';
import { AppFilter } from '../../../../models';
import { PublicService, OptionService } from '../../../../services';
import { BlogService } from 'src/app/services';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-leaflets',
	templateUrl: './leaflets.component.html',
	styleUrls: [ './leaflets.component.scss' ]
})
export class LeafletsComponent implements OnInit {
	baseUrl = environment.API_SERVER;
	reports: Array<any> = [];
	tags: Array<any> = [ 'fun', 'event' ];
	categories: Array<any> = [];
	latest: Array<any> = [];
	filter: AppFilter;
	pending: boolean;
	error: any;
	success: any;
	totalItems: number;
	currentPage: number;
	rows: number;
	categoryId: string = 'd9532688-9e18-4046-bc99-06d8db7b73c1';
	tagId: string;
	search = new FormControl();

	constructor(private optionSvc: OptionService, private blogSvc: PublicService, private title: Title) {
		this.title.setTitle('Leaflets | Nigeria INGO Forum');
		window.scrollTo({ top: 0, behavior: 'smooth' });
		this.filter = new AppFilter({ sidx: 'publishedat', rows: 10 });
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.filter.search = false;
		this.getPage(1);
		// this.getLatests();
		// this.getOptionTypes();
		this.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchText) => {
			this.searchRecords(searchText);
		});
	}

	getBlogs() {}
	getLatests() {
		let filter = new AppFilter({ sidx: 'publishedat', rows: 3 });
		this.blogSvc.getAllPublication({ params: this.filter }).subscribe(
			(res) => {
				this.latest = res['resources'];
			},
			(error) => {
				this.error = error.error.split('=').pop();
			}
		);
	}
	getCategories() {}

	getPage(page) {
		this.filter.page = page;
		this.pending = true;
		this.blogSvc
			.getAllPublication({ param: this.filter })
			.pipe(
				tap((res) => {
					this.totalItems = res.count;
					this.currentPage = page;
					this.rows = this.filter.rows;
				})
			)
			.subscribe(
				(res) => {
					// setTimeout(() => {
					this.reports = res['publications'] ? res['publications'] : [];
					this.pending = false;

					window.scrollTo({ top: 0, behavior: 'smooth' });
					// }, 13000);
				},
				(error) => {
					this.pending = false;
					this.error = error.error.split('=').pop();
				}
			);
	}

	//handle option search
	searchRecords(search) {
		let filter = this.filterComposer(search);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}
	//handle sorting records
	typeRecords(search) {
		this.categoryId = search;
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	typeTags(search) {
		this.tagId = search;
		let filter = this.filterComposer(null);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	//handle sorting records
	sortRecords(search) {
		this.filter.sord = search;
		this.getPage(1);
	}

	//handles rows display
	displayRecords(row) {
		this.filter['rows'] = row;
		this.filter.page = 1;
		this.getPage(1);
	}

	nextPage(page: number) {
		//get and assign current page
		this.currentPage = page;
		this.getPage(this.currentPage);
	}

	//group query
	filterComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};

		if (this.categoryId) {
			type['$eq'] = this.categoryId;
			mongofilter['publicationtypeid'] = type;
		}

		if (this.tagId) {
			// type['$eq'] = this.categoryId;
			mongofilter['tags'] = this.tagId;
		}
		// bson.M{"$lookup": bson.M{"from": "subjectcategory", "localField": "category", "foreignField": "_id", "as": "category"}},
		// bson.M{"$unwind": "$category"},

		if (query !== null) {
			let c1: { [id: string]: any } = {};
			let c2: { [id: string]: any } = {};
			//mobile
			name['$regex'] = query;
			name['$options'] = 'i';
			c1['name'] = name;

			description['$regex'] = query;
			description['$options'] = 'i';
			c2['summary'] = description;

			let oroperator: { [id: string]: any }[] = [];
			oroperator = [ c1, c2 ];
			mongofilter['$or'] = oroperator;
		}

		return mongofilter;
	}

	ngOnInit(): void {}

	getOptionTypes() {
		let filter = new AppFilter({ sidx: '-order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'blog_category';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.optionSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.categories = res.options;
		});
	}
}
