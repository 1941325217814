import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-government-coordinating-bodies',
	templateUrl: './government-coordinating-bodies.component.html',
	styleUrls: [ './government-coordinating-bodies.component.scss' ]
})
export class GovernmentCoordinatingBodiesComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Government Coordinating Bodies | Nigeria INGO Forum');
	}

	ngOnInit(): void {}
}
