<header class="navigation">
	<div class="header-top " *ngIf="innerWidth > 990">
		<div class="container">
			<div class="row justify-content-between align-items-center">
				<div class="col-lg-5 col-md-4 text-center text-lg-left text-md-left">
					<!-- <div class="header-top-info">
						<a><strong>Our Services: </strong></a>
						<a href="https://resources.ngoworld.ng" target="_blank"><i
								class="icofont-library mr-2"></i><span>Resources</span></a>
						<a href="https://community.ngoworld.ng" target="_blank"><i
								class="icofont-people mr-2"></i>
								<span>Online Community</span></a>
						<a href="https://jobs.ngoworld.ng" target="_blank">
							<i class="icofont-search-job  mr-2"></i><span>Jobs</span></a>
					</div> -->
				</div>
				<div class="col-lg-7 col-md-8">
					<div class="header-top-right text-center text-lg-right text-md-right mt-3 mt-lg-0">
						<a class="top-social" href="https://facebook.com/NigeriaINGOForum" target="_blank"><i class="icofont-facebook"></i></a>
						<a class="top-social" href="https://twitter.com/INGOforum_ng" target="_blank"><i class="icofont-twitter"></i></a>

						<a class="top-social" href="https://www.youtube.com/channel/UCn6-HlqYBJgJnzTAOfGYA4g" target="_blank"><i
								class="icofont-youtube-play"></i></a>
													<a routerLink="our-members" class="top-btn">Our Members</a>
								<a routerLink="/join" class="top-btn">Become a Member</a>
						<!-- <a href="https://blog.ingoforum.ng" target="_blank" class="top-btn">Blog</a> -->

					</div>
				</div>
			</div>
		</div>
	</div>
	<nav class="navbar navbar-expand-lg navigation" id="navbar">
		<div class="container">
			<a class="navbar-brand" href="index.html">
				<img src="/assets/images/NIF-Logo-Full.svg" alt="" class="img-fluid">
			</a>

			<button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
				data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="ti-menu"></span>
			</button>

			<div class="collapse navbar-collapse text-center" id="navbarsExample09">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item active">
						<a class="nav-link" routerLinkActive="active"  routerLink="/">Home <span class="sr-only">(current)</span></a>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" routerLink="/about-us" id="dropdown03"
							data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About<span
								class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown07">
							<li><a class="dropdown-item" routerLink="/about-us" fragment="who-we-are/">Who We Are</a>
							</li>
							<li><a class="dropdown-item" routerLink="/about-us"
									fragment="governance-and-structure/">Governance & Structure</a></li>
							<li><a class="dropdown-item" routerLink="/about-us" fragment="our-team/">Our Team</a></li>
							<li><a class="dropdown-item" routerLink="/about-us" fragment="hosting-and-funding/">Hosting
									& Funding</a></li>
							<li><a class="dropdown-item" routerLink="/contact-us">Contact Us</a></li>
							<!-- <li><a class="dropdown-item" routerLink="/careers">Careers </a></li> -->
						</ul>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLink="our-work" id="dropdown03" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Our Work<span class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown03">
							<li><a class="dropdown-item" routerLink="our-work"
									fragment="coordination-for-better-delivery/">Coordination for Better Delivery</a>
							</li>
							<li><a class="dropdown-item" routerLink="/initiatives/plrcap" >PLRCAP</a></li>
						</ul>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" routerLink="membership" data-toggle="dropdown"> Membership  </a>
						<ul class="dropdown-menu">
						  <li><a class="dropdown-item" routerLinkActive="active" routerLink="our-members"> NIF Members </a>
							<!-- <ul class="submenu dropdown-menu">
								<li><a class="dropdown-item" routerLink="/membership" fragment="who-are-our-members"> Who are our members?</a></li>
							 </ul> -->
						</li>
						  <li><a class="dropdown-item" href="#"> NIF Membership FAQs </a>
							 <ul class="submenu dropdown-menu">
								<li><a class="dropdown-item" routerLink="/membership" fragment="who-is-eligible/"> Who is eligible?</a></li>
								<li><a class="dropdown-item" routerLink="/membership" fragment="why-should-i-join-nif/"> Why should I join NIF?</a></li>
								<li><a class="dropdown-item" routerLink="/membership" fragment="how-can-i-join-nif-as-an-observer/"> How can I join NIF as an observer? </a></li>
								<li><a class="dropdown-item" routerLink="/membership" fragment="how-long-can-i-be-a-member/"> How long can I be a member/observer?</a></li>
								<li><a class="dropdown-item" routerLink="/membership"
									fragment="grounds-to-discontinue-membership/"> Grounds to discontinue my membership</a></li>
							 </ul>
						  </li>						 
						</ul>
					</li>

					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" routerLinkActive="active" id="dropdown05" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Coordination<span class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown05">
							<li><a class="dropdown-item" routerLink="ingo-coordination-platforms">INGO Coordination
									Platforms</a></li>
							<li><a class="dropdown-item" routerLink="humanitarian-coordination-architecture">
									Humanitarian Coordination Architecture</a></li>
							<li><a class="dropdown-item" routerLink="government-coordinating-bodies">Government
									Coordinating Bodies</a></li>
						</ul>
					</li>
					<!-- mongorestore --archive="ingoforumdump" --nsFrom='ingo.*' --nsTo='ingoforum.*' -->
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#" id="dropdown06" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Media<span class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown06">
							<li><a class="dropdown-item" routerLink="news">News</a></li>
							<li><a class="dropdown-item" routerLink="publications">Publications</a></li>
							<li><a class="dropdown-item" routerLink="multimedia">Multimedia</a></li>
							<li><a class="dropdown-item" routerLink="media-contacts">Media Contacts</a></li>
						</ul>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#" id="dropdown06" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Resources<span class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown06">
							<li><a class="dropdown-item" href="#footer">Maps/Infographics</a></li>
							<li><a class="dropdown-item" href="#footer">Dashboards</a></li>
						</ul>
					</li>
					<!-- <li class="nav-item"><a class="nav-link" href="contact.html">Galleries</a></li> -->
					<!-- <li class="nav-item active">
						<a class="nav-link"  href="https://data.ingoforum.ng" target="_blank"
						fragment="who-is-eligible/">Member Interactive 3W</a>
					</li> -->

						<!--Top menu  -->
						 <ng-template [ngIf]="innerWidth < 990" >
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#" id="dropdown038" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Our Services<span class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown038">
										<li><a class="dropdown-item"  href="https://resources.ngoworld.ng" target="_blank"><i
								class="icofont-library mr-2"></i><span>Resources</span></a></li>
					<li><a class="dropdown-item" href="https://community.ngoworld.ng" target="_blank"><i
								class="icofont-people mr-2"></i>
								<span>Online Community</span></a></li>
						<li><a class="dropdown-item"  href="https://jobs.ngoworld.ng" target="_blank">
							<i class="icofont-search-job  mr-2"></i><span>Jobs</span></a>
							</li>
						</ul>
					</li>
										<!-- mongorestore --archive="ingoforumdump" --nsFrom='ingo.*' --nsTo='ingoforum.*' -->
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" routerLinkActive="active" href="#" id="dropdown0776" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">Social Platforms<span class="ml-1"> </span></a>
						<ul class="dropdown-menu" aria-labelledby="dropdown0776">

							<li><a class="dropdown-item" href="https://facebook.com/NigeriaINGOForum" target="_blank"><i class="icofont-facebook"></i></a></li>
							<li><a class="dropdown-item" href="https://twitter.com/INGOforum_ng" target="_blank"><i class="icofont-twitter"></i></a></li>
						<li><a  class="dropdown-item"  href="https://www.youtube.com/channel/UCn6-HlqYBJgJnzTAOfGYA4g" target="_blank">
								<i class="icofont-youtube-play"></i></a></li>
						</ul>
					</li>
		       	<li class="nav-item active">
						<a class="nav-link"  routerLink="our-members">Our Members</a>
					</li>
						<!-- <li class="nav-item active">
							<a class="nav-link"  routerLink="join">Our Members</a>
					</li> -->
					<li class="nav-item active">
						<a class="nav-link"  href="https://blo.ingoforum.ng" target="_blank">Blog</a>
					</li>
					</ng-template>
				</ul>
			</div>
		</div>
	</nav>

</header>

<!-- Header Close -->