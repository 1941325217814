import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { statesJson } from './state';
import { USA } from './usa';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import * as papa from 'papaparse';
declare var d3: any;
declare var dc: any;
declare var numeral: any;
declare var csv: any;
declare var crossfilter: any;
declare var $: any;
// declare var papa: any;
const projection = d3.geoAlbers();
// declare var stateCord = { y: 14, x: 7, z: 2000 };
echarts.registerMap('Nigeria', statesJson as any);
@Component({
	selector: 'app-members-presence',
	templateUrl: './presence.html',
	styleUrls: [ './members-detail.component.scss' ]
})
export class MembersPresenceComponent implements OnInit {
	pending: boolean;

	organization: any = {
		name: 'NIGERIA INGO FORUM ',
		acronymn: 'NIF',
		created_at: 1623677820364
	};
	projectTable;
	recordLinks;
	year = 2019;
	stateDetails: any;

	chartOption: EChartsOption = {
		tooltip: {
			trigger: 'item',
			showDelay: 0,
			transitionDuration: 0.2
		},
		// visualMap: {
		// 	left: 'right',
		// 	min: 0,
		// 	max: 38000000,
		// 	inRange: {
		// 		color: [ '#e0e1e2', '#3caa07' ]
		// 	},
		// 	text: [ 'High', 'Low' ],
		// 	calculable: true
		// },
		// toolbox: {
		// 	show: true,
		// 	//orient: 'vertical',
		// 	left: 'left',
		// 	top: 'top',
		// 	feature: {
		// 		dataView: { readOnly: false },
		// 		restore: {},
		// 		saveAsImage: {}
		// 	}
		// },
		series: [
			{
				name: 'Nigeria PopEstimates',
				type: 'map',
				map: 'Nigeria',
				selectedMode: 'multiple',
				select: {
					itemStyle: {
						areaColor: '#3caa07'
					}
				},
				// projection: {
				// 	project: function(point) {
				// 		return projection(point);
				// 	},
				// 	unproject: function(point) {
				// 		return projection.invert(point);
				// 	}
				// },
				emphasis: {
					label: {
						show: true
					},
					itemStyle: {
						areaColor: '#3caa07'
					}
				},
				data: [
					{ name: 'Akwa Ibom', value: 0 },
					{ name: 'Adamawa', value: 1, selected: true },
					{ name: 'Anambra', value: 0 },
					{ name: 'Bauchi', value: 0 },
					{ name: 'Bayelsa', value: 0 },
					{ name: 'Benue', value: 0 },
					{ name: 'Borno', value: 0 },
					{ name: 'Cross River', value: 0 },
					{ name: 'Delta', value: 0 },
					{ name: 'Ebonyi', value: 0 },
					{ name: 'Edo', value: 0 },
					{ name: 'Ekiti', value: 0 },
					{ name: 'Enugu', value: 0 },
					{ name: 'Federal Capital Territory', value: 0 },
					{ name: 'Gombe', value: 0 },
					{ name: 'Imo', value: 0 },
					{ name: 'Jigawa', value: 0 },
					{ name: 'Kaduna', value: 0 },
					{ name: 'Kano', value: 0 },
					{ name: 'Katsina', value: 0 },
					{ name: 'Kebbi', value: 0 },
					{ name: 'Kogi', value: 0 },
					{ name: 'Kwara', value: 0 },
					{ name: 'Lagos', value: 0 },
					{ name: 'Nasarawa', value: 1 },
					{ name: 'Niger', value: 0 },
					{ name: 'Ogun', value: 0 },
					{ name: 'Ondo', value: 0 },
					{ name: 'Osun', value: 0 },
					{ name: 'Oyo', value: 0 },
					{ name: 'Plateau', value: 3 },
					{ name: 'Rivers', value: 0 },
					{ name: 'Sokoto', value: 0 },
					{ name: 'Taraba', value: 0 },
					{ name: 'Yobe', value: 0 },
					{ name: 'Zamfara', value: 0 }
				]
			}
		]
	};
	funding;
	constructor(private http: HttpClient) {
		this.getRecords();
	}
	onChartClick(event, model) {
		console.log(event);
		console.log(model);
	}
	getRecords() {
		this.http.get(`https://api.ingoforum.ng/public/dashboard/data?year=${this.year}`).subscribe((res) => {
			this.recordLinks = res['data'];
			console.log(res);
			this.getChartRecords();
		});
	}
	getChartRecords() {
		// var jsonData = papa.parse(`https://api.ingoforum.ng/public/download?media=${this.recordLinks.state_summary}`, {
		// 	download: true,
		// 	skipEmptyLines: true,
		// 	step: function(row) {
		// 		console.log('Row:', row.data);
		// 	},
		// 	complete: function() {
		// 		console.log('All done!');
		// 	}
		// 	// {
		// 	// 		header: true
		// 	// 		// download: true
		// 	// 		// rest of config ...
		// });
		// console.log(jsonData);

		this.http
			.get(`https://api.ingoforum.ng/public/assets?media=${this.recordLinks.matrix}`, {
				responseType: 'text'
			})
			.subscribe((data) => {
				let array = [];

				var jsonData = $.csv.toObjects(data);
				// console.log(
				// 	jsonData.filter(
				// 		(org) =>
				// 			org['REPORTING ORGANISATION'] == 'ACTED - Agency for Technical Cooperation and Development'
				// 	)
				// );

				// $.each(jsonData, function(index, value) {
				// 	//console.log(index + '== '+value);
				// 	let key = value['States'];
				// 	let ingo = value['# of INGOs'];
				// 	let proj = value['# of Projects'];
				// 	let lgas = value['# of LGAs'];
				// 	let bene = value['Beneficiaries Reached'];
				// 	array[key] =
				// 		'State: ' +
				// 		key +
				// 		'\nNo of LGAs: ' +
				// 		lgas +
				// 		'\nNo of INGOs: ' +
				// 		ingo +
				// 		'\nNo of Projects: ' +
				// 		proj +
				// 		'\nNo of beneficiaries reached: ' +
				// 		numeral(bene).format('0,0');
				// });
				// this.stateDetails = array;
				let dd = jsonData.filter(
					(org) => org['REPORTING ORGANISATION'] == 'ACTED - Agency for Technical Cooperation and Development'
				);
				console.log(dd);
				this.funding = dd;
				this.drawChart(dd);
			});
	}

	ngOnInit(): void {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	drawChart(csv) {
		let mapH = 300;
		let mapW = 600;
		var usChart = dc.geoChoroplethChart('#mp'); //nigeria-map-chart
		var thematicChart = dc.pieChart('#area'); //thematic
		var ngosChart = dc.rowChart('#ngos');
		var donorChart = dc.pieChart('#donor');
		// d3.csv(file_matrix).then(function(ada) {
		var self = this;
		var data = crossfilter(csv);
		var all = data.groupAll();

		var stateDimension = data.dimension(function(d) {
			return d['STATE'];
		});
		var orgDimension = data.dimension(function(d) {
			return d['REPORTING ORGANISATION'];
		});
		var statesGroup = stateDimension.group();
		var stateCord = { y: 18, x: 5.5, z: 1700 };

		var thematicDimension = data.dimension(function(d) {
			return d['THEMATIC AREA'];
		});

		let cord = stateCord;

		statesJson.features = statesJson.features.filter(function(d) {
			return d.properties.admin0Name == 'Nigeria';
		});

		var thematicGroup = thematicDimension.group().reduce(
			function(p, d) {
				// d['BENEFICIARIES REACHED'] = JSON.parse(d['BENEFICIARIES REACHED']);
				if (d['PROJECT NAME'] in p.projects) {
					p.projects[d['PROJECT NAME']] += d['PROJECT NAME'];
					p.beneReached += d['BENEFICIARIES REACHED'];
				} else {
					p.projects[d['PROJECT NAME']] = d['PROJECT NAME'];
					p.projectCount++;
				}
				return p;
			},
			function(p, d) {
				p.projects[d['PROJECT NAME']]--;
				if (p.projects[d['PROJECT NAME']] === 0) {
					delete p.projects[d['PROJECT NAME']];
					p.projectCount--;
				}
				return p;
			},
			function() {
				return {
					projectCount: 0,
					projects: {}
				};
			}
		);

		var donorsDimension = data.dimension(function(d) {
			return d['PROJECT DONOR'];
		});
		var donorsGroup = donorsDimension.group().reduceSum(function(d) {
			return d['# of Projects'];
		});
		//Draw donor
		console.log(donorsDimension);
		donorChart
			.width(350)
			.height(320)
			.dimension(donorsDimension)
			.group(donorsGroup)
			.innerRadius(80)
			.colors([
				'#7e7db0',
				'#01fced',
				'#4287f5',
				'#fa2fba',
				'#f54542',
				'#e1dcf6',
				'#99f542',
				'#3b8f81',
				'#42f5bc',
				'#f5b8a2',
				'#42bcf5',
				'#cbeda8',
				'#bf42f5',
				'#9aaeee',
				'#f542c2',
				'#01fc71',
				'#f5424e',
				'#54f0e8',
				'#8f3b40',
				'#a5aff0'
			])
			.colorDomain([ 0, 20 ])
			.colorAccessor(function(d, i) {
				return i % 20;
			})
			.renderLabel(true)
			.label(function(p) {
				//   console.log(p.key +" - "+ p.value);
				//if(p.key ==''){
				// return p.key +" - "+ p.value;
				//}
			})
			.title(function(d) {
				console.log(d);
				return 'Donor: ' + d.key + '\nNo of Projects: ' + d.value;

				//console.log(stateDetails[d.key]);
			})
			.legend(dc.legend(true));
		var lgaOrg = data.dimension(function(d) {
			return d['LGA'];
		}); // +' / ' +d["STATE"]+' / ' +d["LGA"];

		var lgaOrg_group = lgaOrg.group().reduce(
			function(p, d) {
				// d['BENEFICIARIES REACHED'] = JSON.parse(d['BENEFICIARIES REACHED']);
				if (d['PROJECT NAME'] in p.projects) {
					p.projects[d['PROJECT NAME']] += d['PROJECT NAME'];
					p.beneReached += d['BENEFICIARIES REACHED'];
				} else {
					p.projects[d['PROJECT NAME']] = d['PROJECT NAME'];
					p.projectCount++;
				}
				return p;
			},
			function(p, d) {
				p.projects[d['PROJECT NAME']]--;
				if (p.projects[d['PROJECT NAME']] === 0) {
					delete p.projects[d['PROJECT NAME']];
					p.projectCount--;
				}
				return p;
			},
			function() {
				return {
					projectCount: 0,
					projects: {}
				};
			}
		);

		let ngoH = 250;
		let ngoW = 350;
		//Draw lga
		ngosChart
			.width(ngoW)
			.height(ngoH) // assign a dynamic height  ergp.all().length
			.dimension(lgaOrg) //ngosDimension
			.group(lgaOrg_group) //ngosGroup
			.elasticX(true)
			// .elasticY(true)
			.renderLabel(true)
			.valueAccessor(function(d) {
				return d.value.projectCount;
			})
			.label(function(p) {
				//console.log(p);
				//return p.key +" - "+ p.value;
				return p.key + ' - ' + p.value.projectCount;
			})
			.data(function(group) {
				return group.top(900);
			})
			.colors([ '#3CAA07' ]) //#3182bd'
			.colorDomain([ 0, 0 ])
			.colorAccessor(function(d, i) {
				return 1;
			});

		//Draw thematic
		thematicChart
			.width(250)
			.height(250)
			.dimension(thematicDimension)
			.group(thematicGroup)
			.innerRadius(80)
			.colors([
				'#7e7db0',
				'#01fced',
				'#4287f5',
				'#fa2fba',
				'#f54542',
				'#e1dcf6',
				'#99f542',
				'#3b8f81',
				'#42f5bc',
				'#f5b8a2',
				'#42bcf5',
				'#cbeda8',
				'#bf42f5',
				'#9aaeee',
				'#f542c2',
				'#01fc71',
				'#f5424e',
				'#54f0e8',
				'#8f3b40',
				'#a5aff0'
			])
			.colorDomain([ 0, 20 ])
			.colorAccessor(function(d, i) {
				return i % 20;
			})
			.renderLabel(true)
			.valueAccessor(function(d) {
				return d.value.projectCount;
			})
			.label(function(p) {
				//console.log(p.key +" - "+ p.value);
				//if(p.key ==''){
				// return p.key +" - "+ p.value;
				//}
			})
			.title(function(p) {
				return 'Thematic Area: ' + p.key + '\nNo of Projects: ' + p.value.projectCount;
				//console.log(stateDetails[d.key]);
			});

		//Draw map
		usChart
			.width(mapW)
			.height(mapH)
			.dimension(stateDimension)
			.group(statesGroup) //#86b36f #bec4bb #3CAA07
			.colors([ '#a2a89e', '#3CAA07' ])
			.colorDomain([ 0, 1 ])
			.colorAccessor(function(d) {
				return d > 0 ? 1 : 0;
			})
			.overlayGeoJson(statesJson.features, 'states', function(d) {
				return d.properties.name;
			})
			.projection(d3.geoMercator().center([ cord.y, cord.x ]).scale(cord.z))
			.valueAccessor(function(kv) {
				//console.log(kv);
				return kv.value;
			});

		dc.renderAll();
		// });
	}
}
