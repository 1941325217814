import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import {
	HomeComponent,
	AboutComponent,
	ContactComponent,
	CareersComponent,
	OurWorkComponent,
	MembershipComponent,
	IngoCoordinationPlatformsComponent,
	HumanitarianCoordinationArchitectureComponent,
	GovernmentCoordinatingBodiesComponent,
	BlogComponent,
	NewsComponent,
	MediaContactsComponent,
	PublicationsComponent,
	LeafletsComponent,
	NewslettersComponent,
	SnapshotsComponent,
	GallaryComponent,
	MembersComponent,
	MembersDetailComponent,
	RegisterComponent,
	PublicationDetailComponent,
	NewsDetailComponent,
	PlrcapComponent,
	IntiativesComponent,
	CareersDetailComponent,
	FaqComponent,
	PrivacyComponent,
	TermsComponent,
	NotFoundComponent,
	InvoiceComponent,
	DashboardComponent,
	ReportsComponent,
	MembersPresenceComponent,
	MapsComponent,
} from './pages';

const routerOptions: ExtraOptions = {
	// anchorScrolling: 'enabled',
	useHash: false,
	onSameUrlNavigation: 'reload'
	// scrollPositionRestoration: 'enabled',
	// scrollOffset: [ 0, 64 ],
	// enableTracing: true
};

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'about-us',
		component: AboutComponent
	},
	{
		path: 'invoice',
		component: InvoiceComponent
	},
	{
		path: 'join',
		component: RegisterComponent
	},
	{
		path: 'media',
		component: HomeComponent
	},
	{
		path: 'membership',
		component: MembershipComponent
	},
	{
		path: 'contact-us',
		component: ContactComponent
	},
	{
		path: 'careers',
		component: CareersComponent
	},
	{
		path: 'careers/:id',
		component: CareersDetailComponent
	},
	{
		path: 'our-work',
		component: OurWorkComponent
	},
	{
		path: 'ingo-coordination-platforms',
		component: IngoCoordinationPlatformsComponent
	},
	{
		path: 'initiatives',
		component: IntiativesComponent,
		children: [ { path: 'plrcap', component: PlrcapComponent } ]
	},
	{
		path: 'humanitarian-coordination-architecture',
		component: HumanitarianCoordinationArchitectureComponent
	},
	{
		path: 'government-coordinating-bodies',
		component: GovernmentCoordinatingBodiesComponent
	},
	{
		path: 'news',
		component: NewsComponent
	},
	{
		path: 'news/:slug',
		component: NewsDetailComponent
	},
	{
		path: 'media-contacts',
		component: MediaContactsComponent
	},
	{
		path: 'publications',
		component: PublicationsComponent
	},
	{
		path: 'publications/reports',
		component: ReportsComponent
	},
	{
		path: 'publications/leaflets',
		component: LeafletsComponent
	},
	{
		path: 'publications/newsletters',
		component: NewslettersComponent
	},
	{
		path: 'publications/snapshots',
		component: SnapshotsComponent
	},
	{
		path: 'publications/:slug',
		component: PublicationDetailComponent
	},
	{
		path: 'multimedia',
		component: GallaryComponent
	},
	{
		path: 'our-members',
		component: MembersComponent
	},
	{
		path: 'members/:id',
		component: MembersDetailComponent
	},
	{
		path: 'dashboard',
		component: DashboardComponent
	},
	{
		path: '2022',
		component: MapsComponent
	},
	{
		path: 'blog',
		component: BlogComponent
	},
	{
		path: 'terms-and-conditions',
		component: TermsComponent
	},
	{
		path: 'faq',
		component: FaqComponent
	},
	{
		path: 'privacy',
		component: PrivacyComponent
	},
	{
		path: '**',
		component: NotFoundComponent
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, routerOptions) //ExtraOptions Anchor text
	],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
