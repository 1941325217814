import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from '../../services';
@Component({
	selector: 'app-invoice',
	templateUrl: './not-found.component.html',
	styleUrls: [ './not-found.component.scss' ]
})
export class InvoiceComponent implements OnInit {
	pending: boolean;
	error: any;
	success: any;
	invoiceId;
	template;
	constructor(private blogSvc: PublicService, private route: ActivatedRoute) {
		this.route.queryParams.subscribe((res) => {
			this.invoiceId = res.ref;
			this.getInvoice();
		});
	}
	print() {
		window.print();
	}
	getInvoice() {
		this.pending = true;
		this.blogSvc.getInvoice({ params: { ref: this.invoiceId } }).subscribe((res) => {
			this.template = res;
			this.pending = false;
		});
	}

	ngOnInit(): void {}
}
