import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-gallary',
	templateUrl: './gallary.component.html',
	styleUrls: [ './gallary.component.scss' ]
})
export class GallaryComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Gallary | Nigeria INGO Forum');
	}

	ngOnInit(): void {
		$('.gallery-wrap').each(function() {
			$(this).find('.gallery-popup').magnificPopup({
				type: 'image',
				gallery: {
					enabled: true
				}
			});
		});
	}
}
