<section class="page-title bg-our-work">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">PLRCAP
</h1>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="sections">
    <div class="container"  id="our-initiative" >
               <div class="row no-gutters">
        <div class="col-lg-8 text-start">
            <div class="sections section-top">
                <h3 id="our-team"class="mt-5 content-title ">
                    Promoting Local Response Capacity and Partnership. (PLRCAP)
                </h3>
            </div>
        </div>
               </div>


  <div class="row no-gutters">
        <div class="col-lg-12 text-start">
            <div class="sections section-top">
                <span class="our-work-title margin-bottom-1">
                    About PLRCAP
                </span>
                <p class="f-bold plrcap-subtitle">Capacity Strengthening for Sustainability </p>
                <p> PLRCAP (Promoting Local Response Capacity and Partnership) accompanies Nigerian local
                     and national civil society organizations in their organizational growth and development.
                     Through its NGO Support Hub, including the Hub’s digital learning tools, communities of practice,
                     information and visibility databases, Nigerian NGOs can elaborate and progress organizational development plans,
                    access technical assistance, learn and share with peers and experts, find partners and increase their visibility. 
                    In addition to member support functions, the Hub runs specialized learning journeys, as developed with members 
                    and partners, as well hosts its NGO expert webinar series, every two weeks, sparking peer learning and dialogue
                    on key topics for Nigerian civil society.  To ensure its effectiveness and relevance the Hub, with its partners
                    carries out a series of research and knowledge sharing activities, including mappings and reviews of Nigeria’s
                    civil society, identifying challenges and success strategies. 
                    Founded in 2019, PLRCAP is an initiative of the Nigerian INGO Forum (NIF) and legally hosted by iMMAP, Nigeria.
                    It is grateful for the support of its donors, including SDC, ECHO, FCDO, and USAID.  It actively supports the humanitarian localization efforts in Nigeria.  
                </p>
                <p>More information about our programmes: </p>
                <p class="f-bold plrcap-subtitle">Hub Digital Learning Tools</p>
                <p>Our selection of resources facilitates digital learning and education for National and Local NGOs in Nigeria. 
                    These tools are designed to enhance the learning experience, promote engagement, and provide interactive and 
                    collaborative learning opportunities for NGO hub members. 
                </p>
                <ul>
                    <li>
                        <span class="f-bold">Organizational Self-Assessment Tools</span>
                        <p>The Hub will provide a range of tools to enable members to understand their development needs, chart a prioritized course of action and measure progress. 
                        There are a wide range of organizational assessment tools available for organizations of all sizes, sectoral focus areas and stages of development. 
                        The Hub will enable access to a range of these tools, and through its volunteers and coaches guide its members to the tool best fitting their needs.
                         Members will be guided on the effective use of the tool, including through tips on engaging staff and stakeholders in assessment exercises and effective follow up. </p>
                    </li>
                    <li>
                        <span class="f-bold">Learning Dashboards</span>
                        <p>Hub members will in addition to having organizational assessment tools, have access to private learning dashboards, where diagnostic results can be stored, 
                            organizational objectives articulated, and prioritized action plans and improvements tracked.  
                        </p>
                    </li>
                    <li>
                        <span class="f-bold">Curated Library</span>
                        <p>To enable relevant learning in a sustainable and cost-efficient manner, peer sharing and the vast array of learning opportunities and data available online will be maximized. 
                            Building off the courses and resources collected over the past three years, a curated library of resources organized topically will be made available to all members.</p>
                    </li>
                </ul>
                <p class="f-bold plrcap-subtitle">Communities of Practice</p>
                <p>The NGO hub will feature platforms and spaces where hub members come together to collaborate, share knowledge, 
                    and learn from one another. Our hub forums provide a virtual gathering place for members to engage in discussions, 
                    ask questions, seek advice, and exchange ideas related to their specific area of practice.
                </p>
                <ul>
                    <li>
                        <span class="f-bold">NGO Expert Dialogue Series</span>
                        <p>An initiative aimed at advancing the growth and impact of Nigerian NGOs through a series of interactive online sessions. The series takes place every two weeks and brings
                             together Nigerian and international thought leaders to exchange ideas on critical topics to strengthening Nigerian NGO institutional capacity and advance the Nigerian 
                             humanitarian agenda. For a list of all upcoming NGO expert dialogue Series events </p>
                    </li>
                    <li>
                        <span class="f-bold">Peer Discussion Channels</span>
                        <p>Peer learning and sharing is a critical component of the Hub. Topical online communities of practice are open to all hub members on which advice can be sought, challenges
                             brainstormed, and lessons learned shared. These communities of practice are animated by topical experts, regularly holding on-line peer sharing opportunities and expert
                              webinars to engage members in learning and sharing. Short on and off-line challenge accelerators are organized to collectively engage thinking on key challenge areas. 
                              These online communities are constructed so that input can be easily shared and received on smart phones. 
                        </p>
                    </li>
                </ul>
                <p class="f-bold plrcap-subtitle">Learning Journeys</p>
                <p>We believe in the premise that organizational development is a continual learning journey, unique to each organization. Furthermore, a range of cost-effective support opportunities
                     needs to be made available to all staff and volunteers of an organization when needed and for as long as needed, to guide and accompany an organization in its growth. 
                     A few of our current courses include:
                </p>
                <ul>
                    <li>
                        <span class="f-bold">Organizational Development Planning</span>
                        <p>The Hub collects and adapts a series of organizational diagnostic tools as per size, stage of development, ambitions, and operational focus of the Hub NGO members. 
                            The use of these tools will be facilitated by capacity strengthening experts, ‘Hub Coaches’, guiding the organizations in their analysis and capturing the results in the 
                            Hub learning dashboards. NGOs will then be accompanied in developing strategic organizational development plans and measurement frameworks to monitor progress of 
                            organizational capacity, strengthening and reinforcing the organizations and positioning them for donor funding.
                        </p>
                    </li>
                    <li>
                        <span class="f-bold">Humanitarian Principles and humanitarian access course</span>
                        <p>Through the Hub, national, local, and community-based entities will receive training on principled humanitarian action and humanitarian access– also geared towards improving
                             access to the most vulnerable. 
                            In line with FCDO Nigeria’s HARP objectives to strengthen the humanitarian space and improve the protection environment for vulnerable populations, this project is aimed at
                             strengthening the technical capacity of local actors on Humanitarian Access and Principles to improve reach to affected populations especially in hard-to-reach and extremely
                              hard-to-reach areas.
                            
                        </p>
                    </li>
                    <li>
                        <span class="f-bold">Emergency and Transitional Programming “master class and on-line dialogues”</span>
                        <p>A 20 hour “master class” on emergency and transition programming, set up as a series of online learning and discussion sessions, where international and national experts 
                            share their insights.

                        </p>
                    </li>
                </ul>
                <p>We are currently busy developing more courses for 2023 and 2024.  </p>
                <p class="f-bold plrcap-subtitle">Visibility Platform</p>
                <p>In addition to learning, the Hub looks to facilitate its members visibility and partnerships opportunities. Publicly available Member Profile Pages will enable Hub Members to 
                    showcase their work, competences and accountability profiles. Member profiles will include basic information on each organization, including mission, sectoral focus areas and
                     activities to date, key documents, registration details, structure, and policy overview, as well as an overview of funding and partnerships to date including organizational, 
                     programmatic and/or due diligence assessment undergone. The profile pages will be searchable including with an interactive map interface to identify quickly who is working where.  
                     Each Hub member will be responsible for the veracity of their information and keeping their profiles up to date. Where possible information will be triangulated against NGO mappings
                      and feedback received from partner organizations and network coordination bodies. 

                </p>
                <p class="f-bold plrcap-subtitle">Research Work</p>
                <p>Building off the courses and resources collected over the past three years, a curated library of resources organized topically is available to all members. The resources are sourced
                     and catalogued by the PLRCAP team and its partners.  Peer ‘likes’, feedback and recommendations as well as short summaries and proposed self-guided learning journeys prepared by 
                     the PLRCAP team will help organizations best navigate the materials. 
                    Some of our research include:
                </p>
                    <ul>
                        <li>Organizational sustainability review</li>
                        <li>WLO funding and capacities </li>
                        <li>(SWOT) analysis of local and national NGOs and CBOs working in the four LGAs in Northeastern Nigeria </li>
                        <li>NIF Community Mapping Report (NE Nigeria) February 2022</li>
                    </ul>
                <p class="f-bold plrcap-subtitle mt-5">IF YOU WANT TO JOIN AND SUPPORT US</p>
                <span class="f-bold">To join the Hub as a member NGO </span>
                <ul>
                    <li>
                        PLRCAP supports local/ national NGOs working in Nigerian humanitarian contexts on an application basis. We work with
                            both small emerging organisations just beginning their organisational journeys to long-standing organisations and 
                            networks with large programming portfolios. 
                    </li>
                    <li>
                        The NGO support hub serves both nascent and advanced NGOs who meet basic accountability standards and have an active 
                        operational portfolio. After a simple capacity assessment process, NGOs are onboarded as Hub members able to benefit 
                        from a suite of digital tools and free support opportunities. You can reach out to us via the contacts below to  get more information on joining the Hub.
                    </li>
                </ul>
                <span class="f-bold">To become one of our PLRCAP experts: </span>
                <ul>
                    <li>
                        In complement to the digital tools and free resources of the Hub, critical to the Hub’s success is its mentors and experts,
                         (Hub Coaches) who accompany the members throughout their learning journeys. 
                    </li>
                    <li>
                        Hub Coaches primarily sourced in Nigeria, are chosen based on proven expertise in advising non-for-profit organizations,
                         social enterprises, and small business, struggling in resource poor and complex environments. Coaches work together with
                          the PLRCAP team to offer expert advice across a range of topics both through on-line training sessions, as well as one on
                           one and small group mentorship and learning sessions. Mentorship opportunities will be offered through the Hub’s resources,
                            with specific courses developed based on need and available funding. Sessions will be held primarily online with in-person
                             events organized as per funding availability and membership demand. The Hub will also catalogue the learning and training
                              opportunities of its partner organizations, enabling information sharing among members on available learning and training
                               opportunities. Hub Coaches primarily sourced in Nigeria, are chosen based on proven expertise in advising non-for-profit
                                organizations, social enterprises, and small business, struggling in resource poor and complex environments.
                    </li>
                    <li>
                        To complement the Coaches, volunteers and interns, interested in working with civil society will be on-boarded as Hub Guides.
                         Assisting the Coaches in their support efforts and helping to on-board and guide new members on Hub offerings. 
                    </li>
                    <li>
                        If you would like to apply to become one of our PLRCAP Coaches or mentors or trainers, please submit your application
                    </li>
                    <li>
                        Also, if you are interested in volunteering,
                    </li>
                </ul>

                <!-- <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Key Figures</th>
                        <th scope="col">Xyz</th>
                        <th scope="col">Xyz</th>
                        <th scope="col">Xyz</th>
                        <th scope="col">Xyz</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"></th>
                        <td>Professionals</td>
                        <td>CSOs</td>
                        <td>Facilitators</td>
                        <td>Mentors</td>
                      </tr>
                    </tbody>
                  </table> -->
            </div>
        </div>
    </div>

    </div>
</section>
<!-- Section Intro END -->

<!-- <section class="section bg-light">
	<div class="container">
		<div class="row ">
			<div class="col-lg-12 text-center">
				<div class=" mb-12 mb-lg-0">
					<h2 class="mt-3 mb-12 position-relative plrcap-color">News & Updates</h2>
				</div>
			</div>
		</div>
        <div class="row ">
            <div class="col-md-4">
                <div class="card">
                    <img src="..." class="card-img-top" alt="...">
                    <div class="card-body">
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <img src="..." class="card-img-top" alt="...">
                    <div class="card-body">
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <img src="..." class="card-img-top" alt="...">
                    <div class="card-body">
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="section plrcap-bg">
	<div class="container">
		<div class="row ">
			<div class="col-lg-12 text-center">
				<div class=" mb-12 mb-lg-0">
					<h2 class="mt-3 mb-12 position-relative text-white">Key Figures</h2>
				</div>
			</div>
		</div>
		<div class="counter-section text-center">
			<div class="row">
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat  text-white">68</span>
						<p class="text-white">NNGOs Reached</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat font-weight-bold text-white">6</span>
						<p  class="text-white">State Based NNGO networks</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="counter-item-2 mb-5 mb-lg-0 pt-5">
						<span class="counter-stat  text-white">29</span>
						<p  class="text-white">Facilitators</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="counter-item-2 pt-5">
						<span class="counter-stat text-white">550</span>
						<p  class="text-white">Mentorship Hours</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- footer Start -->


<footer class="section-section-bg section ingoforum-footer">
	<div class="container">
		<div class="row">

			<div class="col-lg-2 col-md-6 col-sm-6">
				<div class="widget footer-widget">
					<h4 class="text-capitalize mb-4 content-title  text-white" >Quick Links</h4>

					<ul class="list-unstyled footer-menu content-body">
						<li><a routerLink="/about-us" fragment="who-we-are/">Job Offers</a></li>
					</ul>
				</div>
			</div>

			<div class="col-lg-3 col-md-6 col-sm-6">
				<div class="footer-widget widget">
					<h4 class="text-capitalize mb-4 content-title text-white">Connect With Us</h4>
					<p>2 Yabo Close, Off Jere Street, <br> Garki II, Abuja - FCT, Nigeria</p>

					<ul class="list-unstyled footer-menu content-body mt-4">
						<!-- <li><a href="#"><i class="icofont-phone"></i>+234 912 017 7947</a></li> -->
						<li><a href="#"><i class="icofont-email"></i>plrcap@ingoforum.ng</a></li>
					</ul>
					<ul class="list-inline content-body footer-socials">
						<li class="list-inline-item"><a   target="_blank"  href="https://www.facebook.com/NigeriaINGOForum"><i class="icofont-facebook"></i></a></li>
						<li class="list-inline-item"><a target="_blank" href="https://twitter.com/INGOforum_ng"><i
									class="icofont-twitter"></i></a></li>
						<li class="list-inline-item"><a target="_blank"
								href="https://www.youtube.com/channel/UCn6-HlqYBJgJnzTAOfGYA4g"><i
									class="icofont-youtube-play"></i></a></li>
						<li class="list-inline-item"><a href="https://www.pinterest.com/ingoforum/"><i class="icofont-linkedin"></i></a></li>
					</ul>
					<!-- <h4 class="text-capitalize mb-4 text-white">Subscribe Us</h4>
					<p class="mb-4">Subscribe to get latest news article and resources  </p>

					<form action="#" class="sub-form">
						<input type="text" class="form-control mb-4" placeholder="Subscribe Now ...">
						<a href="#" class="btn btn-main btn-small rounded">subscribe</a>
					</form> -->
				</div>
			</div>


		</div>

	</div>
</footer>

<div class="intro">
	<div class="container">
		<div class="row">
			<!-- <div class="col-lg-6">
				<h3 class="text-capitalize my-4 ">Hosted By</h3>
				<div class="clients-wrap">
					<a href="https://immap.org/nigeria/" target="_blank" rel="noopener noreferrer">
						<img src="/assets/images/members/immap.png" alt="iMMAP" class="img-fluid">
					</a>
				</div>
			</div> -->
			<div class="col-lg-6">
				<h3 class="text-capitalize my-4">Funded By</h3>
				<div class="clients-wrap funded-by ">
					<a href="#" *ngFor="let member of donors">
						<div class="members-item-wrap">
							<div class="team-item position-relative">
															<a href="{{member.link }}" class="link" target="_blank" rel="noopener noreferrer">
								<img src="{{member?.src}}" alt="" class="img-fluid">
								</a>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="intro reserved" style="background-color: rgba(0, 0, 0, 0.05);">
	<div class="container">
		<div class="text-center p-4" >
			<a routerLink="/terms-and-conditions">Terms of Use</a>  | 		<a routerLink="/privacy"> Privacy Notice</a> <br>
			Nigeria INGO Forum. All rights reserved © {{ currentYear }} Copyright
		  </div>
	</div>
</div>
