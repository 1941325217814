import { Injectable } from '@angular/core';
// import { Token } from '../../interfaces/token.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private userSubject: BehaviorSubject<any>;
	public user: Observable<any>;
	token: any;
	apiUrl: string = environment.API_SERVER;

	constructor(private _httpClient: HttpClient, private router: Router) {
		this.userSubject = new BehaviorSubject<any>(localStorage.getItem('jwt'));
		this.user = this.userSubject.asObservable();
	}

	public get userValue(): any {
		return this.userSubject.value;
	}

	public register(payload): Observable<any> {
		return this._httpClient.post<any>(this.apiUrl + '/register', payload.body);
	}

	emailVerification(payload): Observable<any> {
		return this._httpClient.put(`${this.apiUrl}/v1/auth/email_verification/?token=${payload.token}`, payload.body);
	}

	userVerification(payload): Observable<any> {
		return this._httpClient.put(`${this.apiUrl}/v1/auth/user_invitation/?token=${payload.token}`, payload.body);
	}

	confirmRegistration(payload): Observable<any> {
		return this._httpClient.get(`${this.apiUrl}/confirm-registration?token=${payload.body}`);
	}

	forgotPassword(payload): Observable<any> {
		return this._httpClient.get(`${this.apiUrl}/forgot-password?username=${payload.username}`);
	}

	resetPassword(payload): Observable<any> {
		return this._httpClient.post(`${this.apiUrl}/reset-password/`, payload.body);
	}

	resendCode(payload): Observable<any> {
		return this._httpClient.post(`${this.apiUrl}/resend-code`, payload.body);
	}

	activate(payload): Observable<any> {
		return this._httpClient.post(`${this.apiUrl}/activate-account`, payload.body);
	}

	checkEmail(payload): Observable<any> {
		return this._httpClient.get(`${this.apiUrl}/check-email/${payload.email}`);
	}

	checkUsername(payload): Observable<any> {
		return this._httpClient.get(`${this.apiUrl}/check-username/${payload.username}`);
	}
	public login(payload): Observable<any> {
		return this._httpClient.post<any>(this.apiUrl + '/login', payload.body).pipe(
			map((user) => {
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem('jwt', user['token']);
				// localStorage.setItem('token', JSON.stringify(user));
				this.userSubject.next(user);
				return user;
			})
		);
	}
	public logout() {
		// remove user from local storage and set current user to null
		localStorage.removeItem('jwt');
		this.userSubject.next(null);
		this.router.navigate([ '/' ]);
	}

	getToken() {
		// if (!this.token) {
		// 	// this.token = JSON.parse(localStorage.getItem('authentication')['token']);
		// 	this.token = this.userValue['token'];
		// }
		return localStorage.getItem('jwt');
	}

	forgetToken() {
		this.token = null;
		localStorage.removeItem('authentication');
	}
}
