<app-header></app-header>
<router-outlet></router-outlet>
<div class="row cookie" *ngIf="!cookie_name">
	<div class="col-sm-12 button-fixed">
        <div class="p-2 bg-custom text-white">
        <p>
   We use cookies to improve your online experience and support our mission. <a style="color: #0cc8f5;">Read more about it here</a>. By using our sites, you agree to our use of cookies.
             <button type="button" (click)="setCookie()" class="btn btn-light btn-sm ">Accept Cookies</button>
                </p>

        </div>
	  </div>
</div>
<app-footer *ngIf="router.url !=='/initiatives/plrcap'"></app-footer>