import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { DataTablesModule } from 'angular-datatables';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { BlogComponent } from './pages/blog/blog.component';
import { TeamComponent } from './pages/team/team.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DetailComponent } from './pages/blog/detail/detail.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { CareersComponent } from './pages/careers/careers.component';
import { CareersDetailComponent } from './pages/careers-detail/careers-detail.component';
import { OurWorkComponent } from './pages/our-work/our-work.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { IngoCoordinationPlatformsComponent } from './pages/ingo-coordination-platforms/ingo-coordination-platforms.component';
import { HumanitarianCoordinationArchitectureComponent } from './pages/humanitarian-coordination-architecture/humanitarian-coordination-architecture.component';
import { GovernmentCoordinatingBodiesComponent } from './pages/government-coordinating-bodies/government-coordinating-bodies.component';
import { NewsComponent } from './pages/news/news.component';
import { MediaContactsComponent } from './pages/media-contacts/media-contacts.component';
import { PublicationsComponent } from './pages/publications/publications.component';
import { ReportsComponent } from './pages/publications/components/reports/reports.component';
import { NewslettersComponent } from './pages/publications/components/newsletters/newsletters.component';
import { SnapshotsComponent } from './pages/publications/components/snapshots/snapshots.component';
import { LeafletsComponent } from './pages/publications/components/leaflets/leaflets.component';
import { GallaryComponent } from './pages/gallary/gallary.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { MembersPresenceComponent } from './pages/members-detail/presence';
import { MembersComponent } from './pages/members/members.component';
import { MembersDetailComponent } from './pages/members-detail/members-detail.component';
import { RegisterComponent } from './pages/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PublicationDetailComponent } from './pages/publications/components/publication-detail/publication-detail.component';
import { DashboardComponent } from "./pages";
import { MapsComponent } from "./pages/maps/maps.component";
import { NgxPaginationModule } from 'ngx-pagination';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { PlrcapComponent } from './pages/plrcap/plrcap.component';
import { IntiativesComponent } from './pages/intiatives/intiatives.component';
import { TruncatePipe } from './pipes/truncate';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { FaqComponent } from './pages/faq/faq.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CookieService } from 'ngx-cookie-service';
import { SafeHtmlPipe } from './pipes/sanitize';
@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		AboutComponent,
		BlogComponent,
		TeamComponent,
		ContactComponent,
		DetailComponent,
		HeaderComponent,
		FooterComponent,
		CareersComponent,
		CareersDetailComponent,
		OurWorkComponent,
		MembershipComponent,
		IngoCoordinationPlatformsComponent,
		HumanitarianCoordinationArchitectureComponent,
		GovernmentCoordinatingBodiesComponent,
		NewsComponent,
		MediaContactsComponent,
		PublicationsComponent,
		ReportsComponent,
		NewslettersComponent,
		SnapshotsComponent,
		LeafletsComponent,
		GallaryComponent,
		MembersComponent,
		MembersDetailComponent,
		RegisterComponent,
		PublicationDetailComponent,
		NewsDetailComponent,
		PlrcapComponent,
		IntiativesComponent,
		//Pipe
		TruncatePipe,
		TermsComponent,
		PrivacyComponent,
		FaqComponent,
		NotFoundComponent,
		InvoiceComponent,
		SafeHtmlPipe,
		MembersPresenceComponent,
		DashboardComponent,
		MapsComponent,

	],
	imports: [
		BrowserModule,
		CommonModule,
		DataTablesModule,
		AppRoutingModule,
		HttpClientModule,
		ScullyLibModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPaginationModule,
		ImageViewerModule,
		NgxEchartsModule.forRoot({ echarts })
		// CookieModule.forRoot()
	],
	providers: [ Meta, CookieService ],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
