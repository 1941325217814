
<section class="page-title bg-publication">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Member Detail</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row">

                <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="d-flex justify-content-center" *ngIf="pending">
                            <div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
        <div class="row">
                     <div class="col col-lg-3 member">
							<h4>ORGANISATION TYPE </h4>
							International NGO

							<h4>HEADQUARTERS </h4>
							France

							<h4>STAFF STRENGTH </h4>
							50
                        </div>

                        <div class="col-sm-9">
                                <h4 class="mb-1"><a  routerLink="/publications/{{organization?.acronymn}}">{{organization?.name}} ({{organization?.acronymn}})</a></h4>
                                <div class="mb-4">Member Since {{organization?.created_at | date}}  </div>
								<h4 class="mb-1">Profile </h4>
								 <p>
                                    NIF exists to provide a collective platform for international NGOs to
                                    coordinate actions, exchange information and advocate for a
                                    principled and effective delivery of much needed assistance to
                                    affected population in Nigeria.
							    </p>
							    <h4 class="mt-2">OPERATIONAL PRESENCE </h4>
							<hr/>
							<app-members-presence></app-members-presence>
								<h4>POSTS </h4>
							<h4>CONTACT </h4>
                        </div>
        </div>

                </div>
        </div>

    </div>
</section>