import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-ingo-coordination-platforms',
	templateUrl: './ingo-coordination-platforms.component.html',
	styleUrls: [ './ingo-coordination-platforms.component.scss' ]
})
export class IngoCoordinationPlatformsComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Ingo Coordination Platforms | Nigeria INGO Forum');
	}

	ngOnInit(): void {}
}
