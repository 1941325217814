export  const statesJson = {
	type: 'FeatureCollection',
	crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
	features: [
		{
			type: 'Feature',
			properties: {
				name: 'Abia',
				admin1Pcod: 'NG001',
				admin1RefN: 'Abia',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 4.695135,
				Shape_Area: 0.396543
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 7.386810307000076, 6.036674993000076 ],
							[ 7.387286103000065, 6.036048758000049 ],
							[ 7.388858308000067, 6.036198957000067 ],
							[ 7.392545963000032, 6.035476920000065 ],
							[ 7.396972560000052, 6.034389046000058 ],
							[ 7.400671440000053, 6.031083010000032 ],
							[ 7.401421344000028, 6.02813311500006 ],
							[ 7.401171575000035, 6.017093758000044 ],
							[ 7.404417919000025, 6.012404418000074 ],
							[ 7.415839538000057, 6.006217950000064 ],
							[ 7.430592102000048, 6.006217990000039 ],
							[ 7.431148016000066, 6.006420018000028 ],
							[ 7.431351395000036, 6.008329485000047 ],
							[ 7.433186885000055, 6.009814017000053 ],
							[ 7.43716226600003, 6.008607143000063 ],
							[ 7.451531297000031, 6.013832119000028 ],
							[ 7.461525213000073, 6.014783822000027 ],
							[ 7.475801725000053, 6.012404450000076 ],
							[ 7.493886141000075, 6.016687393000041 ],
							[ 7.510541782000075, 6.021446280000021 ],
							[ 7.52005992200003, 6.02001858400007 ],
							[ 7.53069840400002, 6.012799714000039 ],
							[ 7.536556849000021, 6.008112341000071 ],
							[ 7.542301275000057, 6.000847251000039 ],
							[ 7.547191706000035, 5.994943128000045 ],
							[ 7.553372531000036, 5.98147156400006 ],
							[ 7.552927325000041, 5.966341516000057 ],
							[ 7.546111651000047, 5.964138568000067 ],
							[ 7.540604877000021, 5.959317859000066 ],
							[ 7.540256563000071, 5.954148658000065 ],
							[ 7.542506520000074, 5.944190923000065 ],
							[ 7.547552210000049, 5.933350197000038 ],
							[ 7.53957179300005, 5.907232320000048 ],
							[ 7.518661551000037, 5.908042847000047 ],
							[ 7.49757730400006, 5.907660092000071 ],
							[ 7.498110587000042, 5.900822522000055 ],
							[ 7.500358344000063, 5.891603284000041 ],
							[ 7.520278598000061, 5.883932648000041 ],
							[ 7.526187758000049, 5.879896027000029 ],
							[ 7.527319673000022, 5.873256096000034 ],
							[ 7.526968648000036, 5.868825104000052 ],
							[ 7.524774164000064, 5.865124816000048 ],
							[ 7.52257916800005, 5.861424641000042 ],
							[ 7.522592537000037, 5.858102446000032 ],
							[ 7.527053056000057, 5.847784641000032 ],
							[ 7.53150061100007, 5.840788977000045 ],
							[ 7.532985728000028, 5.837841899000068 ],
							[ 7.535199275000025, 5.836743299000034 ],
							[ 7.539614244000063, 5.837868446000073 ],
							[ 7.544391577000056, 5.840471337000054 ],
							[ 7.551944996000032, 5.843462884000076 ],
							[ 7.552491313000075, 5.843480587000045 ],
							[ 7.56669762100006, 5.843938843000046 ],
							[ 7.570980526000028, 5.841083097000023 ],
							[ 7.584781440000029, 5.837275942000076 ],
							[ 7.595514055000024, 5.834386472000062 ],
							[ 7.605917072000068, 5.834066854000071 ],
							[ 7.612911476000022, 5.834831736000069 ],
							[ 7.622487368000066, 5.834498747000055 ],
							[ 7.626171728000031, 5.834143555000026 ],
							[ 7.630965567000032, 5.832315816000062 ],
							[ 7.650026825000054, 5.824476664000031 ],
							[ 7.657116623000036, 5.823951305000037 ],
							[ 7.683954931000073, 5.822346219000053 ],
							[ 7.70423048400005, 5.81919235600003 ],
							[ 7.713078744000029, 5.818000859000051 ],
							[ 7.728976790000047, 5.81586078600003 ],
							[ 7.756578414000046, 5.813005388000022 ],
							[ 7.764192295000043, 5.813957255000048 ],
							[ 7.780373146000045, 5.806818903000021 ],
							[ 7.786321889000021, 5.804915420000043 ],
							[ 7.792984109000031, 5.79159071600003 ],
							[ 7.793935864000048, 5.777313690000028 ],
							[ 7.796790871000042, 5.761609056000054 ],
							[ 7.803930087000026, 5.744953138000028 ],
							[ 7.815351161000024, 5.735910923000063 ],
							[ 7.820585659000074, 5.727820810000026 ],
							[ 7.830579626000031, 5.723062066000068 ],
							[ 7.840573117000076, 5.710688564000066 ],
							[ 7.861988669000027, 5.697363869000071 ],
							[ 7.870554614000071, 5.693556846000035 ],
							[ 7.886259341000027, 5.687846200000024 ],
							[ 7.890100026000027, 5.684799704000056 ],
							[ 7.893288673000029, 5.682270450000033 ],
							[ 7.897023982000064, 5.677111088000061 ],
							[ 7.901621535000061, 5.670236130000035 ],
							[ 7.906209269000044, 5.665658941000061 ],
							[ 7.91079631100007, 5.661083265000059 ],
							[ 7.915388335000046, 5.655356841000071 ],
							[ 7.91769669200005, 5.649620060000075 ],
							[ 7.916587107000055, 5.642717769000058 ],
							[ 7.914336413000058, 5.635811822000051 ],
							[ 7.914361854000049, 5.63006402600007 ],
							[ 7.914372024000045, 5.627766178000059 ],
							[ 7.913272764000055, 5.618565040000021 ],
							[ 7.913289148000047, 5.615117075000057 ],
							[ 7.912205738000068, 5.609528041000033 ],
							[ 7.90637079000004, 5.603640823000035 ],
							[ 7.902698386000054, 5.601045796000051 ],
							[ 7.901973512000041, 5.597352288000025 ],
							[ 7.909987048000062, 5.588133485000071 ],
							[ 7.909990006000044, 5.587515895000024 ],
							[ 7.907749435000028, 5.578310060000035 ],
							[ 7.906092062000027, 5.570636730000047 ],
							[ 7.90457888800006, 5.568675557000063 ],
							[ 7.90597993800003, 5.564770260000046 ],
							[ 7.906691912000042, 5.559915087000036 ],
							[ 7.906727687000057, 5.551868015000025 ],
							[ 7.90791166300005, 5.542678758000022 ],
							[ 7.907942866000042, 5.535781823000036 ],
							[ 7.914861313000074, 5.520872066000038 ],
							[ 7.920620984000038, 5.509404238000059 ],
							[ 7.921783130000051, 5.504810766000048 ],
							[ 7.924092691000055, 5.499074904000054 ],
							[ 7.926406684000028, 5.492188919000057 ],
							[ 7.928716093000048, 5.48645302500006 ],
							[ 7.931040556000028, 5.477268150000043 ],
							[ 7.934503304000032, 5.46923786800005 ],
							[ 7.939105949000066, 5.461213108000038 ],
							[ 7.943693411000027, 5.456635998000024 ],
							[ 7.947137926000039, 5.452054980000071 ],
							[ 7.950584057000071, 5.447473081000055 ],
							[ 7.954035053000041, 5.44174100500004 ],
							[ 7.956337648000044, 5.437153810000041 ],
							[ 7.958647941000038, 5.431417999000075 ],
							[ 7.959815993000063, 5.425675828000067 ],
							[ 7.964424154000028, 5.416502040000069 ],
							[ 7.969032085000038, 5.407328172000064 ],
							[ 7.972493342000064, 5.399298216000034 ],
							[ 7.973661056000026, 5.393556123000053 ],
							[ 7.978248386000075, 5.388978998000027 ],
							[ 7.980556910000075, 5.383243055000037 ],
							[ 7.984012816000075, 5.376361835000068 ],
							[ 7.986322870000038, 5.370625006000068 ],
							[ 7.986339130000033, 5.367177011000024 ],
							[ 7.987501126000041, 5.362585014000047 ],
							[ 7.986369969000066, 5.360281014000066 ],
							[ 7.985238846000072, 5.357976862000044 ],
							[ 7.982965742000033, 5.35566811800004 ],
							[ 7.977264179000031, 5.354492152000034 ],
							[ 7.972698011000034, 5.354471272000069 ],
							[ 7.968125797000027, 5.355598907000058 ],
							[ 7.962418785000068, 5.355573115000027 ],
							[ 7.957847076000064, 5.356701919000045 ],
							[ 7.94985723700006, 5.356665165000038 ],
							[ 7.941861249000056, 5.357778085000064 ],
							[ 7.933869691000041, 5.35774084600007 ],
							[ 7.920155871000077, 5.361125961000027 ],
							[ 7.912165347000041, 5.36108923200004 ],
							[ 7.90188119000004, 5.363340797000035 ],
							[ 7.890454902000045, 5.365587176000076 ],
							[ 7.880370181000046, 5.366795550000063 ],
							[ 7.877982882000026, 5.367052017000049 ],
							[ 7.876112163000073, 5.368900775000043 ],
							[ 7.882703750000076, 5.382002331000024 ],
							[ 7.878330683000058, 5.385394439000038 ],
							[ 7.875004689000036, 5.390552099000047 ],
							[ 7.872156290000021, 5.400441082000043 ],
							[ 7.871191943000042, 5.402336134000052 ],
							[ 7.86826824000002, 5.40808290800004 ],
							[ 7.867661173000045, 5.411540935000062 ],
							[ 7.866485372000056, 5.414418153000042 ],
							[ 7.865297523000038, 5.418448045000048 ],
							[ 7.863560923000023, 5.420166014000074 ],
							[ 7.86125615800006, 5.421300936000023 ],
							[ 7.858384317000059, 5.421855027000049 ],
							[ 7.856084126000042, 5.422412869000027 ],
							[ 7.853218018000064, 5.422390024000038 ],
							[ 7.850350784000057, 5.422367153000039 ],
							[ 7.838315973000022, 5.421692802000052 ],
							[ 7.821098612000071, 5.423284971000044 ],
							[ 7.80901209700005, 5.427803955000059 ],
							[ 7.798606198000073, 5.436378009000066 ],
							[ 7.792839312000069, 5.439794022000058 ],
							[ 7.788779021000039, 5.444377920000022 ],
							[ 7.787598342000024, 5.447832077000044 ],
							[ 7.786410665000062, 5.451861841000039 ],
							[ 7.784661821000043, 5.454733795000038 ],
							[ 7.784059753000065, 5.457613933000061 ],
							[ 7.782861978000028, 5.462799034000057 ],
							[ 7.781096055000035, 5.46740191300006 ],
							[ 7.779921099000035, 5.470277740000029 ],
							[ 7.778172117000054, 5.473149791000026 ],
							[ 7.775856133000048, 5.475440074000062 ],
							[ 7.774117721000039, 5.477157055000021 ],
							[ 7.768929274000072, 5.480000977000032 ],
							[ 7.765461015000028, 5.48285912800003 ],
							[ 7.763133109000023, 5.486302801000022 ],
							[ 7.761395711000034, 5.488019873000042 ],
							[ 7.760225859000059, 5.490318740000021 ],
							[ 7.759629970000049, 5.492622813000025 ],
							[ 7.760156868000024, 5.497243832000038 ],
							[ 7.759550317000048, 5.500701954000021 ],
							[ 7.760077222000064, 5.505323954000062 ],
							[ 7.758901961000049, 5.508200136000028 ],
							[ 7.757159108000053, 5.510495210000045 ],
							[ 7.754848091000042, 5.51220803800004 ],
							[ 7.751385314000061, 5.514488159000052 ],
							[ 7.748500766000063, 5.516195725000046 ],
							[ 7.745628029000045, 5.516749779000065 ],
							[ 7.742755837000061, 5.517303961000039 ],
							[ 7.734149941000055, 5.517811861000041 ],
							[ 7.729569145000028, 5.517197130000056 ],
							[ 7.72670768100005, 5.51659684200007 ],
							[ 7.72328522600003, 5.514838189000045 ],
							[ 7.719288740000025, 5.513073972000029 ],
							[ 7.717000798000072, 5.512478859000055 ],
							[ 7.713567041000033, 5.511874179000074 ],
							[ 7.710693714000058, 5.51242780900003 ],
							[ 7.71122710700007, 5.516471878000061 ],
							[ 7.71062605700007, 5.519352895000054 ],
							[ 7.707765159000076, 5.518753015000073 ],
							[ 7.704908579000062, 5.517574790000026 ],
							[ 7.70194088900007, 5.517550974000073 ],
							[ 7.695939132000035, 5.518277608000062 ],
							[ 7.694026670000028, 5.516333055000075 ],
							[ 7.692904251000073, 5.514016165000044 ],
							[ 7.691780173000041, 5.511697728000058 ],
							[ 7.691235008000035, 5.508808115000022 ],
							[ 7.690116944000067, 5.505912762000037 ],
							[ 7.691304173000049, 5.501883034000059 ],
							[ 7.693046826000057, 5.499587994000024 ],
							[ 7.694784048000031, 5.497869987000058 ],
							[ 7.697673555000051, 5.495584948000044 ],
							[ 7.699989599000048, 5.493295179000029 ],
							[ 7.702318126000023, 5.48985103800004 ],
							[ 7.704072047000068, 5.48640301000006 ],
							[ 7.704680222000036, 5.482944983000039 ],
							[ 7.704714963000072, 5.479482236000024 ],
							[ 7.703614247000075, 5.474855834000039 ],
							[ 7.703653856000074, 5.47081619000005 ],
							[ 7.703115181000044, 5.467348997000045 ],
							[ 7.703143964000049, 5.464464123000027 ],
							[ 7.703166176000025, 5.462154879000025 ],
							[ 7.703194959000029, 5.459270003000029 ],
							[ 7.70267910900003, 5.453494103000025 ],
							[ 7.701567109000052, 5.450022184000034 ],
							[ 7.70101597200005, 5.447709047000046 ],
							[ 7.699899137000045, 5.444815148000032 ],
							[ 7.699938767000049, 5.440774944000054 ],
							[ 7.700534946000062, 5.438470835000032 ],
							[ 7.700563732000035, 5.43558497600003 ],
							[ 7.700030002000062, 5.431541007000021 ],
							[ 7.697731201000067, 5.432099839000045 ],
							[ 7.695414955000047, 5.434390042000075 ],
							[ 7.693110132000072, 5.435524867000026 ],
							[ 7.691372643000022, 5.437243034000062 ],
							[ 7.689073778000022, 5.437800940000045 ],
							[ 7.685628200000053, 5.43835120600005 ],
							[ 7.682176027000025, 5.43947703300006 ],
							[ 7.678157063000072, 5.440022046000024 ],
							[ 7.675851580000028, 5.441156908000039 ],
							[ 7.674121142000047, 5.442296956000064 ],
							[ 7.671810166000057, 5.444009835000031 ],
							[ 7.669499167000026, 5.445723108000038 ],
							[ 7.66776151800002, 5.447441143000049 ],
							[ 7.663176232000069, 5.447402898000064 ],
							[ 7.660308807000035, 5.447380942000052 ],
							[ 7.656886173000032, 5.445620954000049 ],
							[ 7.655248310000047, 5.444222893000074 ],
							[ 7.653473996000059, 5.442708015000051 ],
							[ 7.651240689000076, 5.440418688000022 ],
							[ 7.64893277300007, 5.438054047000037 ],
							[ 7.646656989000064, 5.436304151000058 ],
							[ 7.642666675000044, 5.433962898000061 ],
							[ 7.640964291000046, 5.432219041000053 ],
							[ 7.639260786000023, 5.430472913000074 ],
							[ 7.638136745000054, 5.428155006000054 ],
							[ 7.636446002000071, 5.425255804000074 ],
							[ 7.63476634400007, 5.421203083000023 ],
							[ 7.63421567000006, 5.418889992000061 ],
							[ 7.634262111000055, 5.414272751000055 ],
							[ 7.634284197000056, 5.411963913000022 ],
							[ 7.633746281000072, 5.408496889000048 ],
							[ 7.633201152000026, 5.405607179000071 ],
							[ 7.632089382000061, 5.402134910000029 ],
							[ 7.631544297000062, 5.399244770000053 ],
							[ 7.630993674000024, 5.396932080000056 ],
							[ 7.629869769000038, 5.394614292000028 ],
							[ 7.62875196300007, 5.391718887000025 ],
							[ 7.627623125000071, 5.389978952000035 ],
							[ 7.626493178000032, 5.388237898000057 ],
							[ 7.625362668000037, 5.386496969000063 ],
							[ 7.624234416000036, 5.384757044000025 ],
							[ 7.623682737000024, 5.382443912000042 ],
							[ 7.624279854000065, 5.380139795000048 ],
							[ 7.626016811000056, 5.378423220000059 ],
							[ 7.628315960000066, 5.377863860000048 ],
							[ 7.631183405000058, 5.37788818100006 ],
							[ 7.633470928000065, 5.378482854000026 ],
							[ 7.636893955000062, 5.38024179100006 ],
							[ 7.639742615000046, 5.381997148000039 ],
							[ 7.641642711000031, 5.38366587400003 ],
							[ 7.644403230000023, 5.384600634000037 ],
							[ 7.650654474000021, 5.384992863000036 ],
							[ 7.655806592000033, 5.384273593000046 ],
							[ 7.662426939000056, 5.384297912000022 ],
							[ 7.667942727000025, 5.384687297000028 ],
							[ 7.671622537000076, 5.384331493000047 ],
							[ 7.675673508000045, 5.382869672000027 ],
							[ 7.677594726000052, 5.38121641400005 ],
							[ 7.677607003000048, 5.379993956000021 ],
							[ 7.677056121000021, 5.377680778000069 ],
							[ 7.676528758000075, 5.373060169000041 ],
							[ 7.675989927000046, 5.369593071000054 ],
							[ 7.673742878000041, 5.364956900000038 ],
							[ 7.672624835000022, 5.36206190300004 ],
							[ 7.670939033000025, 5.35858582700007 ],
							[ 7.669821012000057, 5.355690970000069 ],
							[ 7.668140736000055, 5.351636853000059 ],
							[ 7.667591076000065, 5.349325201000056 ],
							[ 7.666473130000043, 5.346429776000036 ],
							[ 7.665349156000048, 5.344111946000055 ],
							[ 7.663645957000028, 5.342367205000073 ],
							[ 7.662517112000046, 5.340625820000071 ],
							[ 7.660807893000026, 5.339457959000072 ],
							[ 7.658549109000035, 5.335977016000072 ],
							[ 7.656283755000061, 5.333073098000057 ],
							[ 7.654581760000042, 5.331327891000058 ],
							[ 7.652317037000046, 5.328423951000048 ],
							[ 7.65061395500004, 5.326679296000066 ],
							[ 7.648910909000051, 5.324934059000043 ],
							[ 7.645482841000046, 5.323750980000057 ],
							[ 7.64147497700003, 5.323142071000063 ],
							[ 7.638045821000048, 5.321959936000042 ],
							[ 7.634605630000067, 5.321931822000067 ],
							[ 7.630598933000044, 5.321321946000069 ],
							[ 7.628889900000047, 5.320155195000041 ],
							[ 7.62546689900006, 5.318396047000022 ],
							[ 7.622032871000044, 5.317791050000039 ],
							[ 7.618012880000038, 5.318335116000071 ],
							[ 7.615707968000038, 5.319470881000029 ],
							[ 7.612835993000033, 5.320025022000038 ],
							[ 7.609962882000048, 5.32057913500006 ],
							[ 7.607642375000069, 5.323445801000048 ],
							[ 7.606465946000071, 5.326322063000021 ],
							[ 7.603020148000041, 5.326870972000052 ],
							[ 7.601316752000059, 5.325126214000022 ],
							[ 7.59903631800006, 5.323953188000075 ],
							[ 7.596764799000027, 5.321627198000044 ],
							[ 7.595057008000026, 5.320457904000023 ],
							[ 7.591633610000031, 5.318698844000039 ],
							[ 7.587619723000046, 5.31866694200005 ],
							[ 7.583015866000039, 5.320361179000031 ],
							[ 7.581852140000024, 5.322083022000072 ],
							[ 7.578973931000064, 5.323214068000027 ],
							[ 7.575521937000076, 5.324339888000054 ],
							[ 7.572082865000027, 5.324313170000039 ],
							[ 7.568091988000049, 5.321971936000068 ],
							[ 7.56638315500004, 5.320804040000041 ],
							[ 7.564691795000044, 5.317903934000071 ],
							[ 7.562449819000051, 5.312692147000064 ],
							[ 7.560764730000074, 5.309215961000064 ],
							[ 7.559055985000043, 5.308046771000022 ],
							[ 7.556207149000045, 5.306293035000067 ],
							[ 7.551620249000052, 5.30625578300004 ],
							[ 7.549321429000031, 5.306815088000064 ],
							[ 7.546436992000054, 5.308523197000056 ],
							[ 7.543559334000065, 5.309654243000068 ],
							[ 7.540698108000072, 5.309052894000047 ],
							[ 7.537848226000051, 5.307299158000035 ],
							[ 7.535015956000052, 5.30381303200005 ],
							[ 7.534471206000035, 5.300922884000045 ],
							[ 7.535635089000039, 5.299201043000039 ],
							[ 7.538529697000058, 5.296339095000064 ],
							[ 7.540279143000021, 5.293467044000067 ],
							[ 7.543169133000049, 5.291182088000028 ],
							[ 7.545491070000026, 5.288314834000062 ],
							[ 7.547227981000049, 5.286598221000077 ],
							[ 7.549555507000036, 5.28315404600005 ],
							[ 7.551299154000048, 5.280858966000039 ],
							[ 7.55189486300003, 5.278554936000035 ],
							[ 7.553071011000043, 5.275679121000053 ],
							[ 7.553099265000071, 5.272792826000057 ],
							[ 7.55198695200005, 5.269320995000044 ],
							[ 7.549722291000023, 5.266417945000057 ],
							[ 7.548030740000058, 5.263517884000066 ],
							[ 7.545766150000077, 5.260613975000069 ],
							[ 7.54349591700003, 5.258286948000034 ],
							[ 7.542372428000022, 5.25596999000004 ],
							[ 7.541839004000053, 5.251924926000072 ],
							[ 7.541873001000056, 5.248463160000028 ],
							[ 7.541901894000034, 5.245576847000052 ],
							[ 7.541930228000069, 5.242691958000023 ],
							[ 7.541390611000054, 5.239223934000051 ],
							[ 7.54141383800004, 5.236916073000032 ],
							[ 7.542028222000056, 5.232881113000076 ],
							[ 7.541489186000035, 5.229413942000065 ],
							[ 7.541518078000024, 5.226528203000044 ],
							[ 7.541552061000061, 5.223065840000061 ],
							[ 7.541030040000066, 5.217866848000028 ],
							[ 7.54050915800002, 5.212668007000048 ],
							[ 7.540543165000031, 5.20920607000005 ],
							[ 7.540572066000038, 5.206319772000029 ],
							[ 7.540033079000068, 5.202853152000046 ],
							[ 7.540072761000033, 5.198812909000026 ],
							[ 7.539523010000039, 5.196500789000027 ],
							[ 7.540123766000022, 5.193620167000063 ],
							[ 7.539574026000025, 5.191307047000066 ],
							[ 7.539041295000061, 5.187262972000042 ],
							[ 7.533421826000051, 5.175673963000065 ],
							[ 7.528978678000044, 5.16121004200005 ],
							[ 7.527884006000022, 5.156005894000032 ],
							[ 7.529046889000028, 5.154283991000057 ],
							[ 7.530807087000028, 5.15025902900004 ],
							[ 7.531415211000024, 5.146801026000048 ],
							[ 7.532591102000026, 5.143924210000023 ],
							[ 7.533772075000059, 5.14047098900005 ],
							[ 7.533233252000059, 5.137003926000034 ],
							[ 7.533284901000059, 5.131810177000034 ],
							[ 7.533312728000055, 5.128923869000062 ],
							[ 7.533933266000076, 5.124311939000052 ],
							[ 7.533966754000062, 5.12085006500007 ],
							[ 7.533444985000074, 5.115651118000073 ],
							[ 7.534063821000075, 5.111039180000034 ],
							[ 7.534661041000049, 5.108735097000022 ],
							[ 7.536415290000036, 5.105287075000035 ],
							[ 7.538170081000032, 5.101838102000045 ],
							[ 7.539912918000027, 5.099544063000053 ],
							[ 7.541077266000059, 5.097821217000046 ],
							[ 7.54282517200005, 5.09495017100005 ],
							[ 7.544569094000053, 5.092655188000037 ],
							[ 7.54631074100007, 5.090361096000038 ],
							[ 7.546906724000053, 5.088057081000045 ],
							[ 7.545795048000059, 5.08458519200002 ],
							[ 7.544672082000034, 5.082266821000076 ],
							[ 7.542996709000022, 5.077637236000044 ],
							[ 7.541885103000027, 5.07416485400006 ],
							[ 7.54133495800005, 5.071852224000054 ],
							[ 7.540216044000033, 5.068956859000025 ],
							[ 7.537952123000025, 5.066052881000076 ],
							[ 7.535682032000068, 5.063725917000056 ],
							[ 7.532269834000033, 5.060812994000059 ],
							[ 7.529425920000051, 5.058481217000065 ],
							[ 7.525997891000031, 5.057299124000053 ],
							[ 7.524289296000063, 5.056130887000052 ],
							[ 7.520286861000045, 5.054945007000072 ],
							[ 7.518901964000065, 5.053178801000058 ],
							[ 7.525101949000032, 5.050471310000034 ],
							[ 7.531024827000067, 5.048933520000048 ],
							[ 7.530072607000022, 5.04758246800003 ],
							[ 7.528321639000069, 5.044921129000045 ],
							[ 7.528949023000052, 5.038576639000041 ],
							[ 7.527912822000076, 5.037692100000072 ],
							[ 7.525625338000054, 5.037096954000049 ],
							[ 7.522722189000035, 5.036539099000038 ],
							[ 7.520499534000066, 5.036615355000038 ],
							[ 7.517972272000065, 5.037302045000047 ],
							[ 7.515445774000057, 5.038217556000063 ],
							[ 7.512274626000021, 5.036597227000073 ],
							[ 7.509670261000053, 5.034508663000054 ],
							[ 7.511933116000023, 5.030059812000047 ],
							[ 7.51197363700004, 5.026020052000035 ],
							[ 7.509726226000055, 5.021385210000062 ],
							[ 7.508607695000023, 5.018489816000056 ],
							[ 7.507490304000044, 5.015594997000051 ],
							[ 7.506365535000043, 5.013278050000054 ],
							[ 7.504658329000051, 5.012110206000045 ],
							[ 7.491908771000055, 5.007781027000021 ],
							[ 7.490524657000037, 5.006178856000076 ],
							[ 7.489138671000035, 5.003661606000037 ],
							[ 7.487753073000022, 5.001602172000048 ],
							[ 7.485214278000058, 4.998169447000066 ],
							[ 7.483599384000058, 4.996338380000054 ],
							[ 7.480381708000039, 4.996567230000039 ],
							[ 7.477856322000036, 4.997940565000022 ],
							[ 7.474635123000041, 4.99725389300005 ],
							[ 7.471143247000043, 4.995911145000036 ],
							[ 7.465689644000065, 4.986772593000069 ],
							[ 7.467803142000037, 4.984066079000058 ],
							[ 7.468256488000065, 4.980971362000048 ],
							[ 7.467827083000032, 4.978315273000021 ],
							[ 7.46258202000007, 4.973333267000044 ],
							[ 7.463192906000074, 4.972528952000062 ],
							[ 7.466083111000046, 4.970242982000059 ],
							[ 7.469545917000062, 4.967963208000072 ],
							[ 7.47302402400004, 4.965508097000054 ],
							[ 7.47756623500004, 4.965527092000059 ],
							[ 7.480208315000027, 4.965538116000062 ],
							[ 7.481094109000026, 4.964214664000053 ],
							[ 7.482131434000053, 4.960928083000056 ],
							[ 7.483953706000023, 4.960577039000043 ],
							[ 7.486248108000041, 4.960595128000023 ],
							[ 7.48911398100006, 4.960618036000028 ],
							[ 7.491418717000045, 4.959482213000058 ],
							[ 7.492044108000073, 4.954292794000025 ],
							[ 7.491505949000043, 4.950826151000058 ],
							[ 7.491546117000041, 4.946785956000042 ],
							[ 7.490800193000041, 4.945568051000066 ],
							[ 7.489898474000029, 4.944349924000051 ],
							[ 7.489401086000044, 4.942880559000059 ],
							[ 7.488982718000045, 4.940661505000037 ],
							[ 7.488173894000056, 4.939833166000028 ],
							[ 7.487044857000058, 4.938093208000055 ],
							[ 7.484201036000059, 4.935760990000063 ],
							[ 7.482492011000033, 4.934593178000057 ],
							[ 7.480789877000063, 4.932847980000076 ],
							[ 7.478721020000023, 4.919539925000038 ],
							[ 7.478094792000036, 4.915512057000058 ],
							[ 7.477131710000037, 4.908759560000021 ],
							[ 7.480892846000074, 4.906262179000066 ],
							[ 7.485747957000058, 4.903185676000021 ],
							[ 7.487530675000073, 4.897884570000031 ],
							[ 7.487119389000043, 4.890804779000064 ],
							[ 7.480714535000061, 4.88268964100007 ],
							[ 7.481891325000049, 4.879758855000034 ],
							[ 7.484212835000051, 4.876893018000032 ],
							[ 7.485383042000024, 4.874592808000045 ],
							[ 7.486545806000038, 4.872870897000041 ],
							[ 7.487715980000075, 4.870572087000028 ],
							[ 7.48888501600004, 4.86827278800007 ],
							[ 7.490055160000054, 4.865973970000027 ],
							[ 7.491219, 4.864252123000028 ],
							[ 7.493530003000046, 4.862538812000025 ],
							[ 7.495839840000031, 4.860826 ],
							[ 7.498723807000033, 4.859118002000059 ],
							[ 7.499338029000057, 4.855082993000053 ],
							[ 7.498798823000072, 4.85161591800005 ],
							[ 7.497669439000049, 4.849874985000042 ],
							[ 7.497135984000067, 4.845830931000023 ],
							[ 7.498889889000054, 4.842381917000068 ],
							[ 7.500639160000048, 4.839510929000028 ],
							[ 7.500667789000033, 4.836625082000069 ],
							[ 7.500690675000044, 4.834317211000041 ],
							[ 7.499560784000039, 4.83257578100006 ],
							[ 7.497859039000048, 4.830831009000065 ],
							[ 7.496729148000043, 4.829091070000061 ],
							[ 7.494447050000076, 4.827918036000028 ],
							[ 7.493318330000022, 4.826177091000034 ],
							[ 7.491614951000031, 4.824431857000036 ],
							[ 7.488712479000071, 4.821804539000027 ],
							[ 7.487278079000021, 4.824673666000024 ],
							[ 7.486754781000059, 4.827636728000073 ],
							[ 7.478563308000048, 4.833039730000053 ],
							[ 7.474380297000039, 4.836699974000055 ],
							[ 7.471591680000074, 4.842277075000027 ],
							[ 7.466188149000061, 4.845414665000021 ],
							[ 7.460362964000069, 4.845418940000059 ],
							[ 7.455207862000066, 4.845066058000043 ],
							[ 7.446667422000075, 4.845414667000057 ],
							[ 7.436501139000029, 4.85400009600005 ],
							[ 7.429212, 4.862449156000025 ],
							[ 7.41635997000003, 4.866746893000027 ],
							[ 7.406683996000027, 4.865890997000065 ],
							[ 7.392834273000062, 4.861804929000073 ],
							[ 7.373506930000076, 4.862891198000057 ],
							[ 7.36202012900003, 4.865313057000037 ],
							[ 7.360141155000065, 4.866603847000022 ],
							[ 7.352412161000075, 4.871914831000026 ],
							[ 7.340910983000072, 4.872939158000065 ],
							[ 7.334217052000042, 4.873313445000065 ],
							[ 7.330787195000028, 4.873486032000073 ],
							[ 7.326064473000031, 4.876126728000031 ],
							[ 7.319818782000027, 4.879619170000069 ],
							[ 7.307785975000058, 4.875533092000069 ],
							[ 7.294890932000044, 4.875168784000039 ],
							[ 7.281527865000044, 4.873876091000056 ],
							[ 7.271389996000039, 4.87302401900007 ],
							[ 7.257046244000037, 4.865211945000055 ],
							[ 7.240856242000064, 4.856948850000038 ],
							[ 7.230735835000075, 4.857962156000042 ],
							[ 7.215074901000037, 4.857152948000021 ],
							[ 7.205420049000054, 4.858627821000027 ],
							[ 7.191196027000046, 4.863869173000069 ],
							[ 7.185287879000043, 4.872307803000069 ],
							[ 7.173843050000073, 4.879391191000025 ],
							[ 7.164652734000072, 4.881329083000026 ],
							[ 7.155928217000053, 4.883728960000042 ],
							[ 7.14724123600007, 4.890325056000052 ],
							[ 7.143746796000073, 4.895613216000072 ],
							[ 7.14042908600004, 4.900634755000056 ],
							[ 7.143767811000032, 4.913196095000046 ],
							[ 7.150316264000026, 4.92433307500005 ],
							[ 7.159626095000021, 4.935448156000064 ],
							[ 7.168517167000061, 4.944752175000076 ],
							[ 7.181393214000025, 4.949262105000059 ],
							[ 7.194867654000063, 4.952993595000066 ],
							[ 7.194955196000024, 4.955389817000025 ],
							[ 7.193580190000034, 4.966442547000042 ],
							[ 7.191341890000047, 4.97395186500006 ],
							[ 7.189118438000037, 4.978364684000042 ],
							[ 7.185130754000056, 4.982331742000042 ],
							[ 7.192285994000031, 4.982275019000042 ],
							[ 7.207004955000059, 4.980760138000051 ],
							[ 7.218953196000029, 4.978333959000054 ],
							[ 7.235092176000023, 4.981004206000023 ],
							[ 7.241197141000043, 4.994009025000025 ],
							[ 7.245464843000036, 5.007494908000069 ],
							[ 7.248316772000067, 5.017261954000048 ],
							[ 7.251686063000022, 5.033086824000065 ],
							[ 7.25137617200005, 5.049406077000071 ],
							[ 7.252894020000042, 5.064311989000032 ],
							[ 7.255775846000063, 5.077343013000075 ],
							[ 7.256930871000066, 5.079692402000035 ],
							[ 7.26422229800005, 5.094525825000062 ],
							[ 7.270400856000037, 5.102309113000047 ],
							[ 7.271354097000028, 5.10350992900004 ],
							[ 7.278698861000066, 5.116788897000049 ],
							[ 7.278979186000072, 5.11954071200006 ],
							[ 7.280203562000054, 5.131552860000056 ],
							[ 7.26863177000007, 5.145095371000025 ],
							[ 7.265211399000066, 5.14909805800005 ],
							[ 7.264797044000034, 5.152110324000034 ],
							[ 7.26598989300004, 5.153723604000049 ],
							[ 7.268188123000073, 5.154336898000054 ],
							[ 7.272980053000026, 5.156570075000047 ],
							[ 7.277958929000022, 5.161617325000066 ],
							[ 7.283131473000026, 5.168072099000028 ],
							[ 7.284319109000023, 5.170890920000033 ],
							[ 7.283450996000056, 5.185355224000034 ],
							[ 7.280816690000051, 5.192376057000047 ],
							[ 7.277994398000033, 5.196783528000026 ],
							[ 7.27497949800005, 5.199582666000026 ],
							[ 7.272367580000036, 5.201780613000039 ],
							[ 7.263917422000077, 5.215610564000031 ],
							[ 7.264590858000076, 5.219205401000067 ],
							[ 7.265255025000044, 5.222081702000025 ],
							[ 7.26624251800007, 5.226107584000033 ],
							[ 7.266306457000042, 5.228129437000064 ],
							[ 7.266388483000071, 5.230728574000068 ],
							[ 7.266766411000049, 5.23361114100004 ],
							[ 7.266848458000027, 5.236210426000071 ],
							[ 7.266949584000031, 5.239419484000052 ],
							[ 7.267606787000034, 5.242113618000076 ],
							[ 7.269765910000046, 5.246843332000026 ],
							[ 7.271567957000059, 5.249406819000058 ],
							[ 7.273684067000033, 5.252831005000075 ],
							[ 7.27606809200006, 5.25567146800006 ],
							[ 7.276797264000038, 5.260026457000038 ],
							[ 7.277811889000077, 5.27291445700007 ],
							[ 7.278711713000064, 5.276065808000055 ],
							[ 7.279310360000068, 5.278070953000054 ],
							[ 7.28078707800006, 5.28150465300007 ],
							[ 7.282274723000057, 5.28580002800004 ],
							[ 7.283740885000043, 5.288373036000053 ],
							[ 7.284923655000057, 5.291235491000066 ],
							[ 7.286687350000022, 5.294667254000046 ],
							[ 7.287859524000055, 5.296668575000069 ],
							[ 7.288608052000029, 5.298303374000056 ],
							[ 7.287346276000051, 5.303270972000064 ],
							[ 7.28732469800002, 5.307904007000047 ],
							[ 7.289610983000046, 5.312547995000045 ],
							[ 7.292040635000035, 5.315009600000053 ],
							[ 7.291588828000044, 5.319604876000028 ],
							[ 7.29162879200004, 5.322762072000046 ],
							[ 7.29166127600007, 5.325344980000068 ],
							[ 7.292263519000073, 5.327637233000075 ],
							[ 7.293152673000066, 5.329927011000052 ],
							[ 7.294328165000024, 5.332215311000027 ],
							[ 7.296074031000046, 5.334212356000023 ],
							[ 7.297532437000029, 5.336211738000031 ],
							[ 7.298704511000039, 5.338212463000048 ],
							[ 7.299880081000026, 5.340500874000043 ],
							[ 7.301066322000054, 5.343649828000025 ],
							[ 7.301665183000068, 5.345654932000059 ],
							[ 7.303522179000026, 5.348239414000034 ],
							[ 7.303461585000036, 5.351670253000066 ],
							[ 7.304350313000043, 5.35396 ],
							[ 7.305711777000056, 5.358143337000058 ],
							[ 7.308726228000069, 5.35995772800004 ],
							[ 7.311897697000063, 5.361371101000032 ],
							[ 7.313356871000053, 5.363369994000038 ],
							[ 7.314532320000069, 5.365658206000035 ],
							[ 7.31570778300005, 5.367946154000037 ],
							[ 7.316600366000046, 5.370523412000068 ],
							[ 7.318077526000025, 5.373957098000062 ],
							[ 7.31926721800005, 5.377393695000023 ],
							[ 7.320152896000025, 5.379396418000056 ],
							[ 7.321912745000077, 5.382541717000038 ],
							[ 7.322224582000047, 5.384548723000023 ],
							[ 7.322536996000053, 5.386555712000074 ],
							[ 7.322576642000058, 5.389712747000033 ],
							[ 7.322619777000057, 5.393157061000068 ],
							[ 7.322649014000035, 5.395452976000058 ],
							[ 7.322122582000077, 5.399187993000055 ],
							[ 7.321574713000075, 5.40120078700005 ],
							[ 7.321908546000031, 5.404929589000062 ],
							[ 7.322800985000072, 5.407506994000073 ],
							[ 7.323980401000028, 5.410081879000074 ],
							[ 7.326324500000055, 5.41408440400005 ],
							[ 7.327496423000071, 5.416085308000049 ],
							[ 7.32867562000007, 5.418660602000045 ],
							[ 7.32956464800003, 5.420950431000051 ],
							[ 7.331327976000068, 5.424382680000065 ],
							[ 7.333367286000055, 5.426951870000039 ],
							[ 7.335130659000072, 5.430384081000057 ],
							[ 7.336299494000059, 5.432098400000029 ],
							[ 7.338041416000067, 5.433808397000064 ],
							[ 7.339496114000042, 5.435520598000039 ],
							[ 7.341238086000033, 5.437230580000062 ],
							[ 7.343560186000047, 5.439510770000027 ],
							[ 7.34703316100007, 5.442069974000049 ],
							[ 7.349050549000026, 5.442917429000033 ],
							[ 7.351347378000071, 5.443188644000031 ],
							[ 7.353945750000037, 5.444605833000026 ],
							[ 7.355974249000042, 5.446313925000027 ],
							[ 7.357436092000057, 5.448600316000068 ],
							[ 7.358891493000044, 5.450312165000071 ],
							[ 7.360357169000054, 5.452885121000065 ],
							[ 7.361246473000051, 5.45517542500005 ],
							[ 7.361558311000067, 5.45718239200005 ],
							[ 7.362443856000027, 5.459185561000027 ],
							[ 7.363626367000052, 5.462047589000065 ],
							[ 7.364247411000065, 5.465774520000025 ],
							[ 7.366572766000047, 5.468341837000025 ],
							[ 7.368601453000053, 5.470050282000045 ],
							[ 7.370636918000059, 5.472332532000053 ],
							[ 7.373539896000068, 5.475182570000072 ],
							[ 7.374995197000032, 5.476894925000067 ],
							[ 7.376170544000047, 5.479182778000052 ],
							[ 7.377647291000073, 5.482616908000068 ],
							[ 7.378540031000057, 5.485194168000021 ],
							[ 7.379142232000049, 5.487485836000076 ],
							[ 7.379763196000056, 5.491213291000065 ],
							[ 7.381228883000063, 5.49378630700005 ],
							[ 7.382408135000048, 5.496361289000049 ],
							[ 7.383598254000049, 5.499797364000074 ],
							[ 7.384766687000024, 5.501511501000039 ],
							[ 7.385655984000039, 5.503801889000044 ],
							[ 7.38713264200004, 5.507235976000061 ],
							[ 7.388032556000041, 5.510386898000036 ],
							[ 7.390909559000022, 5.511228152000058 ],
							[ 7.392644485000062, 5.512364454000021 ],
							[ 7.393820349000066, 5.51465226800002 ],
							[ 7.39615357100007, 5.517793653000069 ],
							[ 7.398192449000021, 5.520362875000046 ],
							[ 7.400220999000055, 5.522070861000032 ],
							[ 7.401679590000072, 5.524070216000041 ],
							[ 7.402851719000068, 5.52607101600006 ],
							[ 7.404317981000077, 5.528644623000048 ],
							[ 7.406669123000029, 5.533220739000058 ],
							[ 7.407274838000035, 5.535799485000041 ],
							[ 7.407586994000042, 5.537806536000062 ],
							[ 7.408795508000026, 5.542677898000022 ],
							[ 7.408533546000058, 5.544688668000049 ],
							[ 7.407996568000044, 5.547562546000051 ],
							[ 7.408312276000061, 5.549856725000041 ],
							[ 7.409498717000076, 5.553005725000048 ],
							[ 7.411531058000037, 5.555001185000037 ],
							[ 7.414143535000051, 5.557566176000023 ],
							[ 7.415888848000066, 5.55956362400002 ],
							[ 7.417355061000023, 5.562136618000068 ],
							[ 7.417093427000054, 5.56414752400002 ],
							[ 7.416272949000074, 5.567310280000072 ],
							[ 7.41517361700005, 5.571049699000071 ],
							[ 7.414919922000024, 5.573634588000061 ],
							[ 7.415235124000048, 5.575928628000042 ],
							[ 7.417270615000064, 5.57821090300007 ],
							[ 7.419865521000077, 5.579340908000063 ],
							[ 7.422467005000044, 5.581045169000049 ],
							[ 7.422786106000046, 5.583626314000071 ],
							[ 7.425965090000034, 5.585613231000025 ],
							[ 7.426280944000041, 5.587907244000064 ],
							[ 7.426622004000023, 5.592210737000073 ],
							[ 7.427235099000029, 5.595363574000032 ],
							[ 7.427851464000071, 5.598803518000068 ],
							[ 7.428457452000032, 5.601382798000031 ],
							[ 7.429064002000075, 5.603961931000072 ],
							[ 7.430239260000064, 5.606249745000071 ],
							[ 7.432285979000028, 5.609393174000047 ],
							[ 7.429513702000065, 5.616874722000034 ],
							[ 7.42898335700005, 5.620322749000024 ],
							[ 7.428460665000046, 5.624344869000026 ],
							[ 7.427633042000025, 5.626933514000029 ],
							[ 7.426508167000065, 5.628663500000073 ],
							[ 7.425684365000052, 5.63153982800003 ],
							[ 7.422574550000036, 5.635005534000072 ],
							[ 7.421467688000064, 5.638170279000065 ],
							[ 7.420632866000062, 5.640185354000039 ],
							[ 7.419798610000043, 5.642200022000054 ],
							[ 7.418691703000036, 5.645364757000038 ],
							[ 7.417584202000057, 5.648530061000031 ],
							[ 7.417903900000056, 5.651111077000053 ],
							[ 7.417932904000054, 5.653407109000057 ],
							[ 7.417678875000036, 5.655991991000064 ],
							[ 7.416575177000027, 5.659443865000071 ],
							[ 7.41631402400003, 5.661454637000077 ],
							[ 7.415196374000061, 5.663758697000048 ],
							[ 7.413795370000059, 5.666351728000052 ],
							[ 7.412394920000054, 5.668944349000071 ],
							[ 7.410994172000073, 5.671537390000026 ],
							[ 7.410163055000055, 5.673839142000077 ],
							[ 7.40819588100004, 5.677010006000046 ],
							[ 7.40507517900005, 5.679615009000031 ],
							[ 7.40280006100005, 5.681065506000039 ],
							[ 7.400804181000069, 5.681940578000024 ],
							[ 7.398253042000022, 5.684254122000027 ],
							[ 7.395698635000031, 5.686281244000043 ],
							[ 7.393147436000049, 5.688594753000075 ],
							[ 7.39144192200007, 5.689754497000024 ],
							[ 7.389743444000032, 5.69148874900003 ],
							[ 7.387203331000023, 5.694663474000038 ],
							[ 7.385791721000032, 5.696395438000025 ],
							[ 7.384001354000077, 5.698827696000023 ],
							[ 7.382979043000034, 5.700719770000035 ],
							[ 7.382155293000039, 5.703596142000038 ],
							[ 7.381607578000057, 5.705608877000031 ],
							[ 7.385134342000072, 5.702656717000025 ],
							[ 7.388898174000076, 5.701730898000051 ],
							[ 7.391340971000034, 5.701930157000049 ],
							[ 7.392837594000071, 5.703633272000047 ],
							[ 7.394144327000049, 5.705712516000062 ],
							[ 7.394872630000066, 5.708085811000046 ],
							[ 7.396840913000062, 5.70866103000003 ],
							[ 7.399729277000063, 5.710363442000073 ],
							[ 7.400301015000025, 5.712711869000032 ],
							[ 7.400393031000021, 5.717534537000063 ],
							[ 7.40014590100003, 5.720693510000046 ],
							[ 7.399050349000049, 5.724719586000049 ],
							[ 7.397954460000051, 5.728745490000051 ],
							[ 7.397148479000066, 5.733056551000061 ],
							[ 7.396331849000035, 5.736506486000053 ],
							[ 7.396099682000056, 5.740813266000032 ],
							[ 7.395293634000041, 5.745124304000058 ],
							[ 7.395036129000061, 5.747422184000072 ],
							[ 7.394785976000037, 5.750294173000043 ],
							[ 7.394531716000074, 5.752879178000057 ],
							[ 7.394280981000065, 5.75575116300007 ],
							[ 7.394629457000065, 5.760628215000054 ],
							[ 7.394970329000046, 5.764931156000046 ],
							[ 7.394447907000028, 5.768953359000022 ],
							[ 7.393630895000058, 5.772403300000065 ],
							[ 7.394251345000043, 5.776130263000027 ],
							[ 7.393997064000075, 5.778715118000036 ],
							[ 7.394309631000056, 5.780722184000069 ],
							[ 7.394352931000071, 5.784166275000075 ],
							[ 7.393557947000033, 5.789338131000022 ],
							[ 7.393318635000071, 5.793071202000021 ],
							[ 7.393641543000058, 5.795938927000066 ],
							[ 7.393394597000054, 5.799098036000032 ],
							[ 7.392875085000071, 5.803407227000037 ],
							[ 7.392631952000045, 5.806853315000069 ],
							[ 7.392374387000075, 5.809151188000044 ],
							[ 7.39298782700007, 5.812304041000061 ],
							[ 7.393042001000026, 5.816609382000024 ],
							[ 7.392791784000053, 5.819481362000033 ],
							[ 7.392265440000074, 5.823216018000039 ],
							[ 7.391735574000052, 5.826663953000036 ],
							[ 7.392649611000024, 5.83096318500003 ],
							[ 7.392399382000065, 5.83383516400005 ],
							[ 7.391854779000028, 5.836134883000057 ],
							[ 7.391310186000055, 5.83843515600006 ],
							[ 7.391049045000045, 5.840446047000057 ],
							[ 7.389964189000068, 5.845333147000076 ],
							[ 7.389423376000025, 5.847919991000026 ],
							[ 7.388312891000055, 5.850798062000024 ],
							[ 7.387209458000029, 5.854249959000072 ],
							[ 7.386686846000032, 5.858271582000043 ],
							[ 7.38643273200006, 5.860856574000024 ],
							[ 7.38646183000003, 5.863153018000048 ],
							[ 7.386788270000068, 5.866307720000066 ],
							[ 7.387100318000023, 5.868314765000036 ],
							[ 7.387415895000061, 5.870608806000064 ],
							[ 7.388311772000066, 5.873472684000035 ],
							[ 7.388624091000054, 5.875480154000059 ],
							[ 7.389445902000034, 5.879099788000076 ],
							[ 7.389560282000048, 5.881501150000076 ],
							[ 7.391029720000063, 5.884361212000044 ],
							[ 7.391345612000066, 5.886655381000025 ],
							[ 7.392535676000023, 5.890091373000075 ],
							[ 7.393707242000062, 5.892092170000069 ],
							[ 7.394879354000068, 5.894093521000059 ],
							[ 7.396083917000055, 5.898677860000021 ],
							[ 7.396396044000028, 5.900684913000021 ],
							[ 7.396443465000061, 5.904415562000054 ],
							[ 7.395909694000068, 5.907576514000027 ],
							[ 7.394222266000043, 5.910171455000068 ],
							[ 7.391950628000075, 5.91190913500003 ],
							[ 7.387947935000057, 5.912797897000075 ],
							[ 7.385055931000068, 5.910808580000037 ],
							[ 7.382457799000065, 5.909391355000025 ],
							[ 7.380436485000075, 5.908257426000034 ],
							[ 7.378415482000037, 5.90712306100005 ],
							[ 7.376680971000042, 5.905986846000076 ],
							[ 7.373803179000049, 5.905145650000065 ],
							[ 7.371215686000028, 5.904589706000024 ],
							[ 7.369202030000054, 5.904029415000025 ],
							[ 7.367187826000077, 5.903469091000034 ],
							[ 7.362891653000077, 5.903785731000028 ],
							[ 7.358896278000032, 5.905248706000066 ],
							[ 7.355757149000056, 5.906418379000058 ],
							[ 7.35346781800007, 5.906721168000047 ],
							[ 7.350031356000045, 5.907031508000046 ],
							[ 7.345731337000075, 5.907061096000064 ],
							[ 7.343448717000058, 5.907937945000072 ],
							[ 7.340890346000037, 5.909677973000043 ],
							[ 7.338603915000022, 5.91026779300006 ],
							[ 7.335178747000043, 5.911439360000031 ],
							[ 7.33116866000006, 5.911754114000075 ],
							[ 7.328022409000027, 5.912349702000029 ],
							[ 7.324872947000074, 5.912658711000063 ],
							[ 7.322866008000062, 5.91267252800003 ],
							[ 7.320003045000021, 5.91297914300003 ],
							[ 7.317999852000071, 5.913280062000069 ],
							[ 7.31542338600002, 5.913584700000058 ],
							[ 7.31227737100005, 5.914180301000044 ],
							[ 7.30970089300007, 5.914485028000058 ],
							[ 7.307701461000022, 5.915072890000033 ],
							[ 7.305411757000059, 5.915375673000028 ],
							[ 7.302838726000061, 5.915967479000074 ],
							[ 7.300262505000035, 5.916272159000073 ],
							[ 7.29596237100003, 5.916301735000047 ],
							[ 7.293385844000056, 5.916606920000049 ],
							[ 7.29023978500004, 5.917202462000034 ],
							[ 7.287953778000031, 5.91779226400007 ],
							[ 7.284521172000041, 5.918389822000051 ],
							[ 7.283199515000035, 5.918990324000049 ],
							[ 7.269452607000062, 5.919945513000073 ],
							[ 7.266761561000067, 5.92228276700007 ],
							[ 7.266066196000054, 5.927650760000063 ],
							[ 7.265750939000043, 5.930810153000039 ],
							[ 7.264627458000064, 5.932304381000051 ],
							[ 7.26321946500002, 5.93432328800003 ],
							[ 7.262671603000058, 5.936335961000054 ],
							[ 7.260983948000046, 5.938931012000069 ],
							[ 7.25641590400005, 5.950538607000055 ],
							[ 7.254512400000067, 5.965291071000024 ],
							[ 7.259271255000044, 5.977664556000036 ],
							[ 7.264029873000027, 5.991465022000057 ],
							[ 7.27259575000005, 5.997651584000039 ],
							[ 7.283065735000037, 5.99860334300007 ],
							[ 7.301625355000056, 5.998127398000065 ],
							[ 7.318281539000054, 5.998603412000023 ],
							[ 7.322564487000022, 6.003838514000051 ],
							[ 7.333034455000075, 6.03144020600007 ],
							[ 7.338269349000029, 6.036199057000033 ],
							[ 7.339062708000029, 6.042149551000023 ],
							[ 7.361587933000067, 6.040482047000069 ],
							[ 7.376340347000053, 6.040006130000052 ],
							[ 7.38479316400003, 6.037316737000026 ],
							[ 7.386810307000076, 6.036674993000076 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Adamawa',
				admin1Pcod: 'NG002',
				admin1RefN: 'Adamawa',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 11.525443,
				Shape_Area: 3.113007
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 13.621289244000025, 10.948234284000023 ],
							[ 13.625918586000068, 10.948224018000076 ],
							[ 13.625918801000068, 10.948224018000076 ],
							[ 13.626769333000027, 10.948011551000036 ],
							[ 13.63054287600005, 10.947068902000069 ],
							[ 13.640953835000062, 10.945901819000028 ],
							[ 13.648082453000029, 10.945885556000064 ],
							[ 13.651371440000048, 10.945878053000058 ],
							[ 13.658308997000063, 10.944717291000075 ],
							[ 13.659538195000039, 10.944714434000048 ],
							[ 13.664097038000023, 10.944703839000056 ],
							[ 13.669883949000052, 10.944691459000069 ],
							[ 13.669884256000046, 10.944691458000023 ],
							[ 13.676028415000076, 10.94467727500006 ],
							[ 13.688402022000048, 10.944648713000049 ],
							[ 13.692224932000045, 10.945583558000067 ],
							[ 13.690960403000076, 10.934847921000028 ],
							[ 13.690100837000045, 10.919789437000077 ],
							[ 13.689670289000048, 10.904299986000069 ],
							[ 13.689240641000026, 10.893112433000056 ],
							[ 13.683215820000044, 10.878913691000037 ],
							[ 13.678914094000049, 10.872459478000053 ],
							[ 13.675471091000077, 10.863424437000049 ],
							[ 13.671599468000068, 10.854387969000072 ],
							[ 13.665144713000075, 10.843631702000039 ],
							[ 13.660841623000067, 10.836747265000042 ],
							[ 13.653958039000031, 10.830293593000022 ],
							[ 13.650515846000076, 10.829432687000065 ],
							[ 13.644061689000068, 10.824700302000053 ],
							[ 13.639758825000058, 10.818676243000027 ],
							[ 13.636317405000057, 10.81179148800004 ],
							[ 13.633305550000046, 10.805768140000055 ],
							[ 13.63029374000007, 10.804477862000056 ],
							[ 13.627712349000035, 10.797592999000074 ],
							[ 13.619966958000077, 10.784684961000039 ],
							[ 13.614804623000055, 10.777800548000073 ],
							[ 13.610931230000062, 10.774358845000052 ],
							[ 13.607489108000038, 10.762310712000044 ],
							[ 13.605767713000034, 10.751984406000076 ],
							[ 13.602111373000071, 10.733483395000064 ],
							[ 13.597377612000059, 10.714982148000047 ],
							[ 13.591784023000059, 10.701643894000028 ],
							[ 13.58834226700003, 10.693037986000036 ],
							[ 13.578876600000058, 10.682711874000063 ],
							[ 13.559944732000076, 10.666792040000075 ],
							[ 13.549618509000027, 10.65861684500004 ],
							[ 13.545315778000031, 10.652592796000022 ],
							[ 13.542458164000038, 10.645924598000022 ],
							[ 13.542467963000036, 10.645787807000033 ],
							[ 13.542722664000053, 10.641880133000029 ],
							[ 13.544169311000076, 10.636155727000073 ],
							[ 13.54352814300006, 10.63346045000003 ],
							[ 13.539528193000024, 10.629304587000036 ],
							[ 13.533986046000052, 10.615113313000052 ],
							[ 13.532408058000044, 10.608275135000042 ],
							[ 13.529826550000053, 10.598379355000077 ],
							[ 13.530686149000076, 10.584610788000077 ],
							[ 13.534343355000033, 10.571273007000059 ],
							[ 13.534773952000023, 10.554922137000062 ],
							[ 13.539076314000056, 10.539863620000062 ],
							[ 13.541658828000038, 10.531257355000037 ],
							[ 13.547682106000025, 10.525664244000041 ],
							[ 13.550694708000037, 10.522222581000051 ],
							[ 13.555170678000025, 10.508790283000053 ],
							[ 13.554920266000067, 10.507344522000039 ],
							[ 13.554646868000077, 10.503551324000057 ],
							[ 13.554305983000063, 10.500033153000061 ],
							[ 13.552891878000025, 10.493739631000039 ],
							[ 13.549796676000028, 10.483504087000028 ],
							[ 13.549723202000052, 10.483486085000038 ],
							[ 13.546711351000056, 10.475736621000067 ],
							[ 13.543934646000025, 10.472166479000066 ],
							[ 13.539663056000052, 10.470416758000056 ],
							[ 13.532000453000023, 10.464503581000031 ],
							[ 13.528305998000064, 10.46034383500006 ],
							[ 13.524617824000075, 10.455089968000038 ],
							[ 13.521865712000022, 10.441331667000043 ],
							[ 13.520575202000032, 10.432727009000075 ],
							[ 13.520145371000069, 10.423260820000053 ],
							[ 13.521256590000064, 10.415479075000064 ],
							[ 13.521865757000057, 10.411213024000062 ],
							[ 13.523157004000041, 10.398735919000046 ],
							[ 13.523157149000042, 10.390784544000041 ],
							[ 13.521005574000071, 10.385827299000027 ],
							[ 13.514121492000072, 10.376791921000063 ],
							[ 13.512400499000023, 10.374210104000042 ],
							[ 13.509387506000053, 10.368186795000042 ],
							[ 13.507667129000026, 10.360872313000073 ],
							[ 13.507237795000037, 10.35269730400006 ],
							[ 13.507237659000054, 10.341939678000074 ],
							[ 13.510249618000046, 10.333765100000051 ],
							[ 13.511109839000028, 10.322148214000038 ],
							[ 13.509388233000038, 10.309670495000034 ],
							[ 13.505085380000025, 10.294611213000053 ],
							[ 13.501642978000064, 10.282993557000054 ],
							[ 13.501243828000042, 10.282423333000054 ],
							[ 13.493794227000024, 10.255882203000056 ],
							[ 13.491961149000076, 10.241931874000045 ],
							[ 13.485856429000023, 10.218170332000057 ],
							[ 13.48334628300006, 10.216556931000071 ],
							[ 13.475876858000049, 10.216647377000072 ],
							[ 13.47084658600005, 10.212598610000043 ],
							[ 13.469702374000065, 10.209332962000076 ],
							[ 13.467405409000037, 10.202777164000054 ],
							[ 13.465644431000044, 10.19457925200004 ],
							[ 13.461875932000055, 10.190331385000036 ],
							[ 13.461629104000053, 10.189195731000041 ],
							[ 13.461439228000074, 10.189020479000021 ],
							[ 13.461424240000042, 10.188876745000073 ],
							[ 13.453814120000061, 10.177461571000038 ],
							[ 13.447864116000062, 10.166026298000077 ],
							[ 13.44449447900007, 10.161957200000074 ],
							[ 13.440285059000075, 10.15707625500005 ],
							[ 13.433516086000054, 10.146472476000042 ],
							[ 13.42922672900005, 10.135016986000039 ],
							[ 13.429146468000056, 10.128442585000073 ],
							[ 13.42825655200005, 10.123521714000049 ],
							[ 13.418249802000048, 10.11953178300007 ],
							[ 13.406563900000037, 10.113917758000071 ],
							[ 13.395737919000055, 10.110758918000045 ],
							[ 13.380833126000027, 10.113401524000039 ],
							[ 13.367538068000044, 10.111914935000073 ],
							[ 13.360041142000057, 10.109537623000051 ],
							[ 13.351744179000036, 10.109635345000072 ],
							[ 13.336819485000035, 10.110632576000057 ],
							[ 13.328522490000069, 10.110729777000074 ],
							[ 13.322646062000047, 10.105045076000067 ],
							[ 13.320879044000037, 10.096024606000071 ],
							[ 13.312562843000023, 10.094477844000039 ],
							[ 13.286745912000072, 10.070304234000048 ],
							[ 13.282841502000053, 10.061382546000061 ],
							[ 13.280220817000043, 10.055393968000033 ],
							[ 13.27876542100006, 10.051939989000061 ],
							[ 13.272741478000057, 10.045916784000042 ],
							[ 13.258973561000062, 10.032578292000039 ],
							[ 13.251658724000038, 10.027415546000043 ],
							[ 13.247355403000029, 10.013646117000064 ],
							[ 13.247499013000038, 10.012593417000062 ],
							[ 13.250938304000044, 10.00478012800005 ],
							[ 13.255847601000028, 9.998970193000048 ],
							[ 13.261615118000066, 9.995616059000042 ],
							[ 13.269041400000049, 9.992242684000075 ],
							[ 13.273159526000029, 9.989729347000036 ],
							[ 13.278858917000036, 9.980622259000029 ],
							[ 13.284558023000045, 9.971515078000039 ],
							[ 13.28279260100004, 9.962494372000037 ],
							[ 13.277642104000051, 9.947759344000076 ],
							[ 13.272530715000073, 9.936311497000077 ],
							[ 13.26994613200003, 9.928122089000055 ],
							[ 13.263253962000022, 9.923267772000031 ],
							[ 13.252377154000044, 9.915173806000041 ],
							[ 13.252195203000042, 9.915215433000071 ],
							[ 13.252088368000045, 9.915115298000046 ],
							[ 13.238320804000068, 9.909521823000034 ],
							[ 13.230575423000062, 9.900055837000025 ],
							[ 13.225412529000039, 9.887578120000057 ],
							[ 13.227993742000024, 9.877251886000067 ],
							[ 13.226702137000075, 9.869936734000021 ],
							[ 13.23057525400003, 9.867355111000052 ],
							[ 13.234017755000025, 9.858750434000058 ],
							[ 13.234877491000077, 9.850145382000051 ],
							[ 13.237459132000026, 9.84498239100003 ],
							[ 13.239180812000029, 9.842830509000066 ],
							[ 13.242622169000072, 9.841970497000034 ],
							[ 13.250798143000054, 9.838527745000022 ],
							[ 13.25897317700003, 9.833364636000056 ],
							[ 13.266287244000068, 9.834225690000039 ],
							[ 13.271020960000044, 9.829062471000043 ],
							[ 13.280485621000025, 9.823468052000067 ],
							[ 13.288660518000029, 9.817874802000063 ],
							[ 13.294255347000046, 9.809700003000046 ],
							[ 13.296629606000067, 9.804158118000032 ],
							[ 13.297201112000039, 9.796671175000029 ],
							[ 13.291863162000027, 9.789998852000053 ],
							[ 13.287859853000043, 9.782993374000057 ],
							[ 13.283522712000035, 9.772317964000024 ],
							[ 13.276182708000022, 9.764310680000051 ],
							[ 13.269723868000028, 9.760252182000045 ],
							[ 13.264841252000053, 9.751300785000069 ],
							[ 13.26150068000004, 9.733955517000027 ],
							[ 13.260169493000035, 9.725612381000076 ],
							[ 13.259111834000066, 9.717942506000043 ],
							[ 13.260503248000077, 9.711601200000075 ],
							[ 13.257900630000051, 9.70650398500004 ],
							[ 13.254499663000047, 9.698923829000023 ],
							[ 13.251829951000047, 9.68924882500005 ],
							[ 13.25549933700006, 9.674903691000054 ],
							[ 13.254499039000052, 9.668899623000073 ],
							[ 13.245158609000043, 9.661894059000076 ],
							[ 13.239486843000066, 9.649216554000077 ],
							[ 13.243490056000041, 9.639541682000072 ],
							[ 13.243156209000063, 9.633203286000025 ],
							[ 13.239820395000038, 9.626530918000071 ],
							[ 13.241822126000045, 9.619858843000031 ],
							[ 13.241154944000073, 9.613854306000064 ],
							[ 13.247159731000067, 9.604179543000043 ],
							[ 13.24482460300004, 9.595171970000024 ],
							[ 13.236817964000068, 9.583162056000049 ],
							[ 13.235149045000071, 9.568817189000072 ],
							[ 13.229479077000065, 9.563479705000077 ],
							[ 13.226476079000065, 9.553804334000063 ],
							[ 13.22013749000007, 9.542462120000039 ],
							[ 13.215800099000035, 9.541461126000058 ],
							[ 13.204124421000074, 9.544463327000074 ],
							[ 13.201582371000029, 9.541871836000041 ],
							[ 13.18944536500004, 9.528783848000046 ],
							[ 13.182439372000033, 9.527115954000067 ],
							[ 13.17743550800003, 9.523112266000055 ],
							[ 13.163513020000039, 9.52357863800006 ],
							[ 13.157085693000056, 9.523779904000037 ],
							[ 13.157051250000052, 9.523766181000042 ],
							[ 13.148241729000063, 9.520255314000053 ],
							[ 13.135495373000026, 9.516852221000022 ],
							[ 13.122057159000065, 9.516106797000077 ],
							[ 13.113050075000046, 9.516440641000031 ],
							[ 13.105041950000043, 9.517774700000075 ],
							[ 13.10003808600004, 9.516106704000038 ],
							[ 13.089696853000021, 9.511770014000035 ],
							[ 13.072682838000048, 9.513438116000032 ],
							[ 13.059338401000048, 9.509434902000066 ],
							[ 13.047328632000074, 9.509768498000028 ],
							[ 13.042657980000058, 9.504430768000077 ],
							[ 13.031982336000056, 9.498092811000049 ],
							[ 13.021306454000069, 9.492421386000046 ],
							[ 13.010964906000027, 9.482746175000045 ],
							[ 13.00162414600004, 9.474739889000034 ],
							[ 12.99194977600007, 9.474072686000056 ],
							[ 12.984944815000063, 9.471070212000029 ],
							[ 12.976270659000022, 9.470402541000055 ],
							[ 12.957253993000052, 9.451721446000022 ],
							[ 12.941574523000043, 9.443380307000041 ],
							[ 12.932568098000047, 9.434039023000025 ],
							[ 12.92756379900004, 9.424031064000076 ],
							[ 12.916553942000064, 9.417025378000062 ],
							[ 12.898873245000061, 9.410019838000039 ],
							[ 12.872518445000026, 9.396342431000051 ],
							[ 12.864179043000036, 9.393339233000063 ],
							[ 12.855503949000024, 9.384331664000058 ],
							[ 12.856839008000065, 9.379327786000033 ],
							[ 12.855170860000044, 9.373322243000075 ],
							[ 12.856838810000056, 9.363314783000021 ],
							[ 12.861843207000049, 9.359978578000039 ],
							[ 12.874187032000066, 9.358644393000077 ],
							[ 12.881526371000064, 9.359978910000052 ],
							[ 12.885861847000058, 9.358644275000074 ],
							[ 12.894869562000054, 9.357976868000037 ],
							[ 12.902877305000061, 9.352305559000058 ],
							[ 12.903543165000031, 9.340296072000058 ],
							[ 12.902542593000021, 9.331621990000031 ],
							[ 12.900207520000038, 9.320278838000036 ],
							[ 12.901904718000026, 9.311436637000043 ],
							[ 12.902209668000069, 9.305267454000045 ],
							[ 12.902209154000047, 9.299595957000065 ],
							[ 12.901209059000053, 9.293924307000054 ],
							[ 12.900875147000022, 9.286251310000068 ],
							[ 12.900875149000058, 9.281580647000055 ],
							[ 12.900541756000052, 9.272573401000045 ],
							[ 12.904544626000074, 9.266235115000029 ],
							[ 12.905127557000071, 9.257654425000055 ],
							[ 12.905075274000069, 9.246211947000063 ],
							[ 12.90531155900004, 9.24245538100007 ],
							[ 12.904544639000051, 9.238878963000047 ],
							[ 12.904544260000023, 9.233541431000049 ],
							[ 12.901543097000058, 9.229871669000033 ],
							[ 12.898205227000062, 9.226536037000074 ],
							[ 12.893869007000035, 9.222866187000022 ],
							[ 12.892201073000024, 9.219530371000076 ],
							[ 12.890867745000037, 9.211189185000023 ],
							[ 12.890199363000022, 9.203183428000045 ],
							[ 12.886863529000038, 9.193508183000063 ],
							[ 12.882192051000061, 9.187169833000041 ],
							[ 12.88219239600005, 9.180164069000057 ],
							[ 12.883527924000077, 9.17616083300004 ],
							[ 12.883527701000048, 9.170489314000065 ],
							[ 12.880858563000061, 9.164484769000069 ],
							[ 12.87952415500007, 9.155143829000053 ],
							[ 12.879858002000049, 9.148137915000063 ],
							[ 12.883194467000067, 9.145134803000076 ],
							[ 12.886863882000057, 9.141132409000022 ],
							[ 12.888865899000052, 9.136794928000029 ],
							[ 12.888531488000069, 9.132124902000044 ],
							[ 12.889532354000039, 9.128455203000044 ],
							[ 12.891867393000041, 9.12345130500006 ],
							[ 12.891868017000036, 9.117445753000027 ],
							[ 12.890199966000068, 9.112775683000052 ],
							[ 12.888703314000054, 9.110281501000031 ],
							[ 12.888636050000059, 9.110145529000022 ],
							[ 12.888598162000051, 9.110057857000072 ],
							[ 12.888575292000041, 9.10999204500007 ],
							[ 12.888567832000035, 9.109984791000045 ],
							[ 12.888492898000038, 9.109886492000044 ],
							[ 12.888397506000047, 9.109771872000067 ],
							[ 12.886196115000075, 9.106103131000054 ],
							[ 12.886863799000025, 9.095427743000073 ],
							[ 12.886529761000077, 9.086419848000048 ],
							[ 12.883193579000022, 9.07841387600007 ],
							[ 12.878190263000022, 9.069406517000061 ],
							[ 12.875854166000067, 9.066070282000055 ],
							[ 12.87385243600005, 9.05406100700003 ],
							[ 12.874520164000046, 9.051057718000038 ],
							[ 12.877855950000026, 9.043051612000056 ],
							[ 12.87819057300004, 9.035378242000036 ],
							[ 12.878013800000076, 9.030106377000038 ],
							[ 12.872782618000031, 9.020885425000074 ],
							[ 12.868115307000039, 9.012887073000059 ],
							[ 12.862291300000038, 9.004891169000075 ],
							[ 12.855293701000051, 8.993465271000048 ],
							[ 12.849449194000044, 8.980893011000035 ],
							[ 12.840819293000038, 8.966685380000058 ],
							[ 12.833111645000031, 8.95434079100005 ],
							[ 12.828348418000076, 8.951009717000034 ],
							[ 12.826378153000064, 8.949684045000026 ],
							[ 12.821638017000055, 8.947347821000051 ],
							[ 12.820467134000069, 8.945457178000026 ],
							[ 12.814234534000036, 8.939617868000028 ],
							[ 12.803595960000052, 8.929194442000039 ],
							[ 12.799010519000035, 8.927475149000031 ],
							[ 12.790306463000036, 8.917194152000036 ],
							[ 12.790234154000075, 8.901182069000072 ],
							[ 12.795714947000022, 8.89598869200006 ],
							[ 12.800944595000033, 8.891045333000022 ],
							[ 12.801589419000038, 8.885493102000055 ],
							[ 12.796861938000063, 8.876752982000028 ],
							[ 12.789733215000069, 8.86059762800005 ],
							[ 12.788406910000049, 8.852358723000066 ],
							[ 12.789436387000023, 8.845515191000061 ],
							[ 12.796379633000072, 8.831478294000021 ],
							[ 12.799044711000022, 8.82928922900004 ],
							[ 12.800667782000062, 8.82280460000004 ],
							[ 12.807158080000022, 8.807938589000059 ],
							[ 12.808011718000046, 8.797262973000045 ],
							[ 12.808708897000031, 8.789677384000072 ],
							[ 12.801483847000043, 8.773220844000036 ],
							[ 12.795864956000059, 8.76639831600005 ],
							[ 12.790290825000056, 8.75883097600007 ],
							[ 12.783863541000073, 8.753483663000054 ],
							[ 12.772308870000074, 8.754534614000022 ],
							[ 12.75762321600007, 8.757550461000051 ],
							[ 12.746052114000065, 8.757685810000055 ],
							[ 12.731346750000057, 8.75348354700003 ],
							[ 12.722412506000069, 8.748335733000033 ],
							[ 12.713214081000046, 8.731075146000023 ],
							[ 12.713492723000059, 8.718824279000046 ],
							[ 12.714542129000051, 8.702018623000072 ],
							[ 12.712441655000077, 8.689415061000034 ],
							[ 12.696687698000062, 8.67576114600007 ],
							[ 12.689335116000052, 8.663157560000059 ],
							[ 12.688522035000062, 8.658283267000058 ],
							[ 12.677057567000077, 8.64277370700006 ],
							[ 12.667825845000038, 8.639562569000077 ],
							[ 12.661001985000041, 8.641970663000052 ],
							[ 12.645750105000047, 8.642773547000047 ],
							[ 12.638524869000037, 8.637556214000028 ],
							[ 12.62674942600006, 8.627856317000067 ],
							[ 12.614040690000024, 8.616684006000071 ],
							[ 12.601597361000074, 8.608255470000074 ],
							[ 12.585140892000027, 8.596214295000038 ],
							[ 12.575108079000074, 8.596615860000043 ],
							[ 12.567481504000057, 8.600228389000051 ],
							[ 12.551827160000073, 8.605044285000076 ],
							[ 12.541596555000069, 8.60909166600004 ],
							[ 12.533889095000063, 8.612742398000023 ],
							[ 12.526537029000053, 8.61274252000004 ],
							[ 12.517083726000067, 8.614843361000055 ],
							[ 12.50973255100007, 8.616944285000045 ],
							[ 12.48977526300007, 8.622195238000074 ],
							[ 12.481374165000034, 8.622195341000065 ],
							[ 12.471920987000033, 8.615893243000073 ],
							[ 12.467719738000028, 8.606440645000021 ],
							[ 12.469143803000065, 8.590595133000022 ],
							[ 12.463123659000075, 8.581362747000071 ],
							[ 12.455496250000067, 8.575743540000076 ],
							[ 12.448673449000069, 8.56771653800007 ],
							[ 12.445060253000065, 8.560491546000037 ],
							[ 12.441849368000021, 8.553266442000051 ],
							[ 12.439039681000054, 8.541225461000067 ],
							[ 12.437836564000065, 8.530387879000045 ],
							[ 12.433420871000067, 8.515938837000022 ],
							[ 12.429005665000034, 8.510319629000037 ],
							[ 12.42181892900004, 8.500240392000023 ],
							[ 12.417948358000046, 8.49524103400006 ],
							[ 12.413590912000075, 8.485184522000054 ],
							[ 12.407147783000028, 8.480460056000027 ],
							[ 12.405774789000077, 8.477936539000041 ],
							[ 12.396775787000024, 8.456750598000042 ],
							[ 12.386660175000031, 8.450588181000057 ],
							[ 12.379117173000054, 8.450657498000055 ],
							[ 12.364065814000071, 8.454548723000073 ],
							[ 12.349037120000048, 8.460941362000028 ],
							[ 12.347904399000072, 8.462357792000034 ],
							[ 12.341858882000054, 8.461721436000062 ],
							[ 12.33213801100004, 8.463062162000028 ],
							[ 12.322751962000041, 8.466749145000051 ],
							[ 12.312360306000073, 8.467419504000077 ],
							[ 12.304651424000042, 8.464067489000058 ],
							[ 12.289232641000069, 8.453676345000076 ],
							[ 12.279177047000076, 8.446972889000051 ],
							[ 12.274232150000046, 8.445372655000028 ],
							[ 12.267780262000031, 8.443285038000056 ],
							[ 12.261411444000032, 8.443620681000027 ],
							[ 12.253702196000063, 8.44227975900003 ],
							[ 12.246998304000044, 8.439933752000059 ],
							[ 12.240629573000035, 8.434905107000077 ],
							[ 12.234595513000045, 8.421497327000054 ],
							[ 12.230908773000067, 8.405073077000054 ],
							[ 12.225880090000032, 8.378256748000069 ],
							[ 12.226885548000041, 8.367530768000051 ],
							[ 12.22822646000003, 8.353787394000051 ],
							[ 12.233254258000045, 8.341720610000039 ],
							[ 12.238282181000045, 8.326636225000072 ],
							[ 12.242975614000045, 8.31356431100005 ],
							[ 12.244986181000058, 8.303843501000074 ],
							[ 12.245610265000039, 8.301763387000051 ],
							[ 12.245861481000077, 8.298607722000042 ],
							[ 12.250472487000025, 8.271518795000077 ],
							[ 12.252991034000047, 8.253887192000036 ],
							[ 12.25589717500003, 8.237610688000075 ],
							[ 12.258609681000053, 8.21658788700006 ],
							[ 12.258609924000041, 8.196921367000073 ],
							[ 12.258609810000053, 8.17861175400003 ],
							[ 12.257253126000023, 8.171830236000062 ],
							[ 12.253092432000074, 8.168015623000031 ],
							[ 12.251857777000055, 8.165741973000024 ],
							[ 12.248170653000045, 8.163730724000061 ],
							[ 12.245825030000049, 8.161719195000046 ],
							[ 12.244819182000072, 8.158368042000063 ],
							[ 12.242472484000075, 8.153675194000073 ],
							[ 12.242472914000075, 8.150658476000046 ],
							[ 12.24046164400005, 8.147306560000061 ],
							[ 12.239456587000063, 8.144289939000032 ],
							[ 12.235768215000064, 8.139931563000061 ],
							[ 12.230070328000068, 8.13456930500007 ],
							[ 12.227054167000063, 8.129205773000024 ],
							[ 12.220684981000034, 8.122837094000033 ],
							[ 12.219008533000022, 8.117138779000072 ],
							[ 12.214316875000065, 8.110770285000058 ],
							[ 12.210628457000041, 8.104400583000029 ],
							[ 12.209288142000048, 8.098702397000068 ],
							[ 12.207277716000021, 8.092669550000039 ],
							[ 12.206271048000076, 8.090322532000073 ],
							[ 12.206941716000074, 8.08529485300005 ],
							[ 12.210294281000074, 8.073898375000056 ],
							[ 12.213645738000025, 8.063172389000044 ],
							[ 12.214986980000049, 8.055461747000038 ],
							[ 12.215657247000024, 8.04976378300006 ],
							[ 12.214986898000063, 8.045406470000046 ],
							[ 12.214315835000036, 8.034010032000026 ],
							[ 12.214149015000032, 8.027305527000067 ],
							[ 12.214819242000033, 8.023282915000038 ],
							[ 12.219955008000056, 8.015176780000047 ],
							[ 12.219955522000078, 8.006361130000073 ],
							[ 12.221311626000045, 7.997544879000031 ],
							[ 12.220633428000042, 7.988050498000064 ],
							[ 12.210461787000042, 7.970418537000057 ],
							[ 12.203002255000058, 7.964315070000055 ],
							[ 12.198254496000061, 7.956855251000036 ],
							[ 12.196898156000032, 7.946004981000044 ],
							[ 12.189438819000031, 7.938545172000033 ],
							[ 12.179265437000026, 7.930407635000051 ],
							[ 12.172484377000046, 7.920913304000067 ],
							[ 12.167737662000036, 7.909384602000046 ],
							[ 12.162312780000036, 7.902603225000064 ],
							[ 12.154852786000049, 7.889040002000058 ],
							[ 12.144001986000035, 7.877511626000057 ],
							[ 12.11619766900003, 7.853776089000064 ],
							[ 12.097209931000066, 7.838178663000065 ],
							[ 12.089072191000071, 7.818511987000022 ],
							[ 12.082968765000032, 7.805627316000027 ],
							[ 12.077543278000064, 7.802236426000036 ],
							[ 12.067371425000033, 7.787995306000028 ],
							[ 12.067371101000049, 7.779857755000023 ],
							[ 12.064657905000047, 7.773754214000064 ],
							[ 12.060590063000063, 7.769007250000072 ],
							[ 12.060589833000051, 7.75883489000006 ],
							[ 12.057198387000028, 7.750018573000034 ],
							[ 12.047026444000039, 7.741202851000025 ],
							[ 12.041601369000034, 7.72967443400006 ],
							[ 12.037531643000023, 7.707294895000075 ],
							[ 12.037531596000065, 7.681525258000022 ],
							[ 12.032107236000058, 7.667962122000063 ],
							[ 12.021934569000052, 7.661858411000026 ],
							[ 12.019222240000033, 7.651686554000037 ],
							[ 12.020578302000047, 7.644905201000029 ],
							[ 12.023968668000066, 7.629307149000056 ],
							[ 12.024647773000027, 7.610997177000058 ],
							[ 12.026004051000029, 7.58251481800005 ],
							[ 12.028037889000075, 7.558779781000055 ],
							[ 12.032107412000073, 7.535044269000025 ],
							[ 12.036853647000044, 7.529618852000056 ],
							[ 12.039566729000057, 7.522837178000032 ],
							[ 12.012440931000071, 7.508595927000044 ],
							[ 12.007693005000021, 7.508595850000063 ],
							[ 11.997521223000035, 7.502492878000055 ],
							[ 11.988705538000033, 7.497745396000028 ],
							[ 11.985216064000042, 7.498819328000025 ],
							[ 11.974464216000058, 7.499102132000075 ],
							[ 11.956153821000044, 7.49299856500005 ],
							[ 11.941234849000068, 7.476723302000039 ],
							[ 11.924959174000037, 7.453665679000039 ],
							[ 11.903258538000046, 7.43332089200004 ],
							[ 11.892407242000047, 7.419079900000042 ],
							[ 11.889016882000021, 7.41636759000005 ],
							[ 11.867994434000025, 7.394666068000049 ],
							[ 11.853993262000074, 7.378666047000024 ],
							[ 11.850172128000054, 7.387070206000033 ],
							[ 11.844437337000045, 7.398524859000077 ],
							[ 11.831790738000052, 7.416860162000035 ],
							[ 11.822579166000025, 7.427178748000074 ],
							[ 11.816824062000023, 7.434056772000076 ],
							[ 11.809917703000053, 7.442081466000047 ],
							[ 11.804748693000022, 7.446797335000042 ],
							[ 11.80174147200006, 7.449540670000033 ],
							[ 11.796086314000036, 7.454699540000036 ],
							[ 11.791494390000025, 7.462719517000039 ],
							[ 11.785744578000049, 7.470741145000034 ],
							[ 11.776533199000028, 7.481059937000055 ],
							[ 11.771935236000047, 7.487935567000022 ],
							[ 11.767421880000029, 7.513117376000025 ],
							[ 11.766300908000062, 7.521129199000029 ],
							[ 11.76517577900006, 7.527996898000026 ],
							[ 11.762919544000056, 7.540587900000048 ],
							[ 11.762951060000034, 7.547452529000054 ],
							[ 11.764166774000046, 7.560035738000067 ],
							[ 11.765366785000026, 7.56918606000005 ],
							[ 11.766549934000068, 7.574904379000031 ],
							[ 11.768425233000073, 7.579620814000066 ],
							[ 11.767821538000021, 7.582570629000031 ],
							[ 11.768077692000077, 7.591182131000039 ],
							[ 11.768231295000021, 7.59634980200002 ],
							[ 11.768292709000036, 7.598398293000059 ],
							[ 11.766521215000068, 7.603140796000048 ],
							[ 11.766279285000053, 7.612719889000061 ],
							[ 11.766106728000068, 7.614924419000033 ],
							[ 11.769108951000021, 7.627530006000029 ],
							[ 11.772692665000022, 7.651549411000076 ],
							[ 11.771598905000076, 7.665281681000067 ],
							[ 11.769363164000026, 7.682449305000034 ],
							[ 11.764786953000055, 7.693901120000021 ],
							[ 11.757984973000077, 7.724809161000053 ],
							[ 11.754545465000035, 7.73168225400002 ],
							[ 11.747658623000063, 7.744284256000071 ],
							[ 11.73959426600004, 7.752311598000063 ],
							[ 11.734990983000046, 7.758042812000042 ],
							[ 11.714232514000059, 7.774108911000042 ],
							[ 11.711933399000031, 7.777546420000021 ],
							[ 11.703868667000052, 7.785574345000043 ],
							[ 11.687712471000054, 7.795908385000075 ],
							[ 11.683115102000045, 7.802783868000063 ],
							[ 11.679679602000022, 7.810800896000046 ],
							[ 11.676244509000071, 7.818818143000044 ],
							[ 11.671657713000059, 7.827981934000036 ],
							[ 11.656700922000027, 7.847467048000055 ],
							[ 11.650967606000052, 7.858921457000065 ],
							[ 11.646368775000042, 7.865797016000045 ],
							[ 11.636000437000064, 7.876118059000021 ],
							[ 11.624464044000035, 7.884153818000073 ],
							[ 11.611759137000035, 7.889903613000058 ],
							[ 11.601369023000075, 7.895648461000064 ],
							[ 11.595591390000038, 7.897949575000041 ],
							[ 11.578267878000077, 7.905998684000053 ],
							[ 11.570166517000075, 7.906017273000032 ],
							[ 11.563221927000029, 7.906032989000039 ],
							[ 11.555585031000021, 7.903788603000066 ],
							[ 11.551552960000038, 7.903331729000058 ],
							[ 11.549972522000076, 7.901862038000047 ],
							[ 11.544662305000031, 7.896922236000023 ],
							[ 11.541168174000063, 7.892353544000059 ],
							[ 11.538826847000053, 7.886638086000062 ],
							[ 11.537647974000038, 7.882064378000052 ],
							[ 11.535290865000036, 7.872916092000025 ],
							[ 11.532944662000034, 7.866056797000056 ],
							[ 11.529430601000058, 7.856911070000024 ],
							[ 11.528251364000027, 7.852337330000069 ],
							[ 11.525904849000028, 7.845477691000042 ],
							[ 11.51655, 7.824904426000046 ],
							[ 11.514193791000025, 7.815756257000032 ],
							[ 11.507206806000056, 7.806619067000042 ],
							[ 11.500230710000039, 7.799769924000032 ],
							[ 11.494437901000026, 7.798639165000054 ],
							[ 11.489813755000057, 7.799793747000024 ],
							[ 11.482190392000064, 7.810238782000056 ],
							[ 11.480612645000065, 7.81240093100007 ],
							[ 11.472526408000022, 7.815851346000045 ],
							[ 11.465640751000024, 7.828453572000058 ],
							[ 11.462184697000055, 7.831893862000072 ],
							[ 11.457582322000064, 7.837624924000067 ],
							[ 11.454130376000023, 7.842209233000062 ],
							[ 11.44376668600006, 7.853675426000052 ],
							[ 11.438022689000036, 7.862841675000027 ],
							[ 11.434565475000056, 7.866281963000063 ],
							[ 11.426511889000039, 7.876597826000022 ],
							[ 11.420772419000059, 7.886908160000075 ],
							[ 11.411566558000061, 7.898370870000065 ],
							[ 11.40813627600005, 7.907532177000064 ],
							[ 11.403544349000072, 7.915552109000032 ],
							[ 11.400109022000038, 7.923568689000035 ],
							[ 11.393233286000054, 7.938459043000023 ],
							[ 11.382875244000047, 7.951067794000039 ],
							[ 11.372522242000059, 7.964821732000075 ],
							[ 11.366777542000023, 7.973988505000023 ],
							[ 11.365630852000038, 7.976279302000023 ],
							[ 11.363352851000059, 7.984293408000042 ],
							[ 11.358754970000064, 7.991169533000061 ],
							[ 11.35645114700003, 7.993462924000028 ],
							[ 11.354151817000059, 7.996900942000025 ],
							[ 11.351863801000036, 8.002626526000029 ],
							[ 11.350749230000076, 8.011782582000023 ],
							[ 11.34613494000007, 8.01522546800004 ],
							[ 11.341521140000054, 8.018668274000049 ],
							[ 11.341537496000058, 8.022101341000052 ],
							[ 11.342721133000055, 8.027819715000021 ],
							[ 11.34736162300004, 8.03009699200004 ],
							[ 11.350823462000051, 8.027800503000037 ],
							[ 11.354289814000026, 8.026648619000071 ],
							[ 11.354316514000061, 8.032369565000067 ],
							[ 11.352012387000059, 8.034663203000036 ],
							[ 11.347382545000073, 8.034673567000027 ],
							[ 11.346236119000025, 8.036964311000077 ],
							[ 11.34625703100005, 8.041541181000071 ],
							[ 11.349739956000064, 8.043821275000028 ],
							[ 11.35207080400005, 8.047248874000047 ],
							[ 11.354401714000062, 8.050676457000066 ],
							[ 11.357895005000046, 8.055244352000045 ],
							[ 11.362566762000029, 8.06438729000007 ],
							[ 11.371857613000032, 8.071230831000037 ],
							[ 11.376518998000051, 8.078084880000063 ],
							[ 11.380013414000075, 8.082653980000032 ],
							[ 11.383499877000077, 8.08607756300006 ],
							[ 11.390476379000063, 8.092926864000049 ],
							[ 11.400930574000029, 8.100912159000075 ],
							[ 11.407900719000054, 8.106617914000026 ],
							[ 11.418364605000022, 8.116890866000062 ],
							[ 11.426498680000066, 8.123737240000025 ],
							[ 11.433480382000027, 8.131730094000034 ],
							[ 11.439288187000045, 8.136294314000054 ],
							[ 11.448584331000063, 8.144282264000026 ],
							[ 11.456718466000041, 8.15112768900002 ],
							[ 11.461368730000061, 8.155693920000033 ],
							[ 11.469508270000063, 8.163684837000062 ],
							[ 11.477636603000064, 8.169386860000031 ],
							[ 11.485775095000065, 8.177377701000069 ],
							[ 11.49623368400006, 8.186507175000031 ],
							[ 11.504372405000026, 8.194497027000068 ],
							[ 11.510191043000077, 8.201349395000022 ],
							[ 11.514842062000071, 8.205915515000072 ],
							[ 11.522986140000057, 8.215049875000034 ],
							[ 11.528815067000039, 8.224189807000073 ],
							[ 11.538127946000031, 8.235609928000031 ],
							[ 11.546277301000032, 8.245888669000067 ],
							[ 11.549776072000043, 8.251601329000039 ],
							[ 11.554442617000063, 8.259599704000038 ],
							[ 11.561450061000073, 8.273314395000057 ],
							[ 11.56613277200006, 8.284745090000058 ],
							[ 11.57313534900004, 8.297314608000022 ],
							[ 11.577797173000022, 8.304168609000044 ],
							[ 11.580138397000042, 8.309885134000069 ],
							[ 11.584815763000051, 8.320171237000068 ],
							[ 11.589499255000021, 8.331602168000074 ],
							[ 11.595327182000062, 8.340742155000044 ],
							[ 11.598826505000034, 8.346455459000026 ],
							[ 11.601167748000023, 8.352170990000047 ],
							[ 11.615135114000054, 8.369300974000055 ],
							[ 11.618623701000047, 8.372725409000054 ],
							[ 11.622112356000059, 8.376150089000021 ],
							[ 11.628797457000076, 8.384893494000039 ],
							[ 11.633764995000035, 8.393285674000026 ],
							[ 11.637285475000056, 8.403575973000045 ],
							[ 11.640783227000043, 8.409288325000034 ],
							[ 11.645450391000054, 8.417286914000044 ],
							[ 11.651231971000072, 8.426352480000048 ],
							[ 11.651279385000066, 8.426426840000033 ],
							[ 11.65593165100006, 8.430993112000067 ],
							[ 11.662896399000033, 8.43555364100007 ],
							[ 11.668709521000039, 8.441261393000048 ],
							[ 11.674528385000031, 8.448112551000065 ],
							[ 11.683814005000045, 8.453812614000071 ],
							[ 11.689617113000054, 8.457231639000042 ],
							[ 11.698891375000073, 8.460642955000026 ],
							[ 11.709323796000035, 8.464051147000021 ],
							[ 11.716274490000046, 8.465179356000021 ],
							[ 11.724391785000023, 8.468593456000065 ],
							[ 11.730189220000057, 8.470868938000024 ],
							[ 11.736002825000071, 8.476575903000025 ],
							[ 11.748786945000063, 8.487988568000048 ],
							[ 11.756920802000025, 8.494834965000052 ],
							[ 11.76622191000007, 8.503966251000065 ],
							[ 11.780157931000076, 8.51423270500004 ],
							[ 11.785981986000024, 8.522228117000054 ],
							[ 11.790654054000072, 8.531371169000067 ],
							[ 11.796404067000026, 8.546234050000066 ],
							[ 11.795369270000037, 8.549666277000028 ],
							[ 11.791955177000034, 8.562260765000076 ],
							[ 11.789677757000049, 8.570275331000062 ],
							[ 11.786241321000034, 8.57829189000006 ],
							[ 11.779387373000077, 8.597758396000074 ],
							[ 11.779424615000039, 8.605767293000042 ],
							[ 11.779806061000045, 8.609372136000047 ],
							[ 11.780635127000039, 8.617206631000045 ],
							[ 11.782981752000069, 8.624065285000029 ],
							[ 11.78416519700005, 8.629783538000027 ],
							[ 11.788848143000052, 8.641214386000058 ],
							[ 11.789455421000071, 8.643571977000022 ],
							[ 11.79120524800004, 8.650363038000023 ],
							[ 11.797056319000035, 8.664079573000038 ],
							[ 11.809860947000061, 8.680067941000061 ],
							[ 11.813370949000046, 8.68806922400006 ],
							[ 11.818069620000074, 8.702932347000058 ],
							[ 11.826250271000049, 8.720076667000058 ],
							[ 11.82859729200004, 8.726936410000064 ],
							[ 11.830975595000041, 8.740660944000069 ],
							[ 11.861358647000031, 8.803520306000053 ],
							[ 11.874137794000035, 8.813788466000062 ],
							[ 11.877647652000064, 8.821789659000046 ],
							[ 11.891641532000051, 8.844640722000065 ],
							[ 11.899802061000059, 8.857208037000021 ],
							[ 11.903332912000053, 8.869786242000032 ],
							[ 11.903384929000026, 8.881227679000062 ],
							[ 11.903432915000053, 8.891525362000039 ],
							[ 11.902323825000053, 8.901824802000021 ],
							[ 11.900078081000061, 8.916704376000041 ],
							[ 11.898961989000043, 8.925860625000041 ],
							[ 11.896684638000067, 8.933874291000052 ],
							[ 11.89326587100004, 8.945323743000074 ],
							[ 11.885216693000075, 8.956783998000049 ],
							[ 11.880640048000032, 8.968236152000031 ],
							[ 11.876047379000056, 8.976255326000057 ],
							[ 11.864547788000039, 8.992300162000049 ],
							[ 11.856493934000071, 9.002615994000053 ],
							[ 11.848407524000038, 9.006067515000041 ],
							[ 11.84377839800004, 9.006077806000064 ],
							[ 11.831057674000022, 9.008395448000044 ],
							[ 11.825280873000054, 9.01069624400003 ],
							[ 11.81932239300005, 9.012020134000068 ],
							[ 11.804469148000067, 9.015320618000032 ],
							[ 11.799850676000062, 9.017620107000027 ],
							[ 11.791758598000058, 9.01992720800007 ],
							[ 11.785987860000034, 9.023372809000023 ],
							[ 11.776749854000059, 9.027970121000067 ],
							[ 11.767516913000065, 9.033712648000062 ],
							[ 11.758300577000057, 9.042886724000027 ],
							[ 11.752534840000067, 9.049715033000041 ],
							[ 11.749007254000048, 9.050438695000025 ],
							[ 11.74208666800007, 9.051857771000073 ],
							[ 11.735176825000053, 9.053277210000033 ],
							[ 11.729636164000055, 9.061677888000077 ],
							[ 11.722637185000053, 9.064823014000069 ],
							[ 11.715636374000042, 9.067968593000046 ],
							[ 11.710347158000047, 9.071190735000073 ],
							[ 11.706756539000025, 9.074490775000072 ],
							[ 11.704806233000056, 9.079591601000061 ],
							[ 11.702846793000049, 9.084694723000041 ],
							[ 11.700886715000024, 9.089797299000054 ],
							[ 11.698927156000025, 9.094899267000073 ],
							[ 11.696887034000042, 9.101727665000055 ],
							[ 11.694935874000066, 9.106829565000055 ],
							[ 11.696306973000048, 9.113812704000054 ],
							[ 11.696066003000055, 9.118991945000062 ],
							[ 11.69573710800006, 9.125897641000051 ],
							[ 11.695397382000067, 9.132802876000028 ],
							[ 11.69686673700005, 9.138058881000063 ],
							[ 11.698466326000073, 9.14384005900007 ],
							[ 11.69978618500005, 9.148571048000065 ],
							[ 11.701156388000072, 9.155553032000057 ],
							[ 11.702615649000052, 9.160809657000073 ],
							[ 11.701876393000077, 9.176346914000021 ],
							[ 11.701660974000049, 9.180823539000073 ],
							[ 11.699506983000049, 9.190080416000058 ],
							[ 11.699087335000058, 9.198713151000049 ],
							[ 11.698845786000049, 9.203891800000065 ],
							[ 11.700067774000047, 9.210051502000056 ],
							[ 11.696290086000033, 9.215721107000036 ],
							[ 11.694565, 9.218311123000035 ],
							[ 11.69309058500005, 9.220095746000027 ],
							[ 11.687405643000034, 9.229547256000046 ],
							[ 11.683629174000032, 9.23406103800005 ],
							[ 11.68165022900007, 9.236426277000021 ],
							[ 11.679227010000034, 9.240657210000052 ],
							[ 11.677057192000063, 9.244445614000028 ],
							[ 11.670535408000035, 9.253109790000053 ],
							[ 11.670155390000048, 9.253614633000041 ],
							[ 11.665579082000022, 9.265067258000045 ],
							[ 11.663301304000072, 9.273080969000034 ],
							[ 11.660807653000063, 9.278209709000066 ],
							[ 11.656383650000066, 9.285502543000064 ],
							[ 11.653616663000037, 9.290092388000062 ],
							[ 11.650736120000033, 9.294888732000061 ],
							[ 11.64884756500004, 9.298032945000045 ],
							[ 11.647746827000049, 9.305400101000032 ],
							[ 11.640401659000077, 9.31492206300004 ],
							[ 11.639060262000044, 9.318730235000032 ],
							[ 11.635526385000048, 9.322432578000075 ],
							[ 11.629496696000047, 9.328800156000057 ],
							[ 11.622245569000029, 9.337124752000022 ],
							[ 11.616707102000021, 9.345526925000058 ],
							[ 11.613046770000039, 9.350551686000074 ],
							[ 11.603675586000065, 9.367430628000022 ],
							[ 11.601716033000059, 9.372533703000045 ],
							[ 11.596256451000045, 9.379207480000048 ],
							[ 11.593187185000033, 9.378504616000043 ],
							[ 11.586242964000064, 9.378521122000052 ],
							[ 11.576989044000072, 9.379686093000032 ],
							[ 11.564263194000034, 9.380859138000062 ],
							[ 11.557318930000065, 9.380875672000059 ],
							[ 11.549238437000042, 9.385470401000021 ],
							[ 11.540976686000022, 9.392592174000072 ],
							[ 11.536564818000045, 9.398085842000057 ],
							[ 11.534528533000071, 9.403206569000076 ],
							[ 11.530007368000042, 9.403909493000072 ],
							[ 11.525925795000035, 9.407815676000041 ],
							[ 11.520966312000041, 9.413883072000033 ],
							[ 11.515505781000059, 9.420557909000024 ],
							[ 11.511756136000031, 9.427309720000039 ],
							[ 11.506306639000059, 9.433984894000048 ],
							[ 11.497427261000041, 9.440506904000074 ],
							[ 11.493516828000054, 9.450711193000075 ],
							[ 11.489846894000038, 9.455735878000041 ],
							[ 11.484306430000061, 9.464138246000061 ],
							[ 11.479106998000077, 9.465633403000027 ],
							[ 11.469977379000056, 9.477333747000046 ],
							[ 11.466225752000071, 9.484086226000045 ],
							[ 11.458895748000032, 9.494136624000021 ],
							[ 11.455146597000066, 9.500888964000069 ],
							[ 11.451486461000059, 9.505914884000049 ],
							[ 11.445696677000058, 9.519493946000068 ],
							[ 11.441865765000045, 9.527972446000035 ],
							[ 11.441616342000032, 9.533151430000032 ],
							[ 11.436155983000049, 9.539826155000071 ],
							[ 11.429598748000046, 9.549625590000062 ],
							[ 11.433946865000053, 9.552126847000068 ],
							[ 11.441893610000022, 9.560431446000052 ],
							[ 11.442859586000054, 9.569734305000054 ],
							[ 11.444465870000045, 9.576152655000044 ],
							[ 11.447883383000033, 9.579132324000057 ],
							[ 11.452939066000056, 9.584775642000068 ],
							[ 11.461077948000025, 9.592766056000073 ],
							[ 11.468048048000071, 9.598470415000065 ],
							[ 11.473844778000057, 9.600746223000044 ],
							[ 11.484278713000037, 9.604154868000023 ],
							[ 11.491222143000073, 9.604138612000042 ],
							[ 11.501655759000073, 9.607546751000029 ],
							[ 11.506284573000073, 9.607536273000051 ],
							[ 11.515543879000063, 9.607515133000049 ],
							[ 11.527323051000053, 9.60823713700006 ],
							[ 11.545625594000057, 9.605158867000057 ],
							[ 11.562986482000042, 9.605118472000072 ],
							[ 11.57109336700006, 9.606244289000074 ],
							[ 11.578043227000023, 9.60737234100003 ],
							[ 11.583835516000022, 9.608503288000065 ],
							[ 11.588470220000033, 9.609637168000063 ],
							[ 11.598887210000044, 9.60961317500005 ],
							[ 11.608162110000023, 9.613024779000057 ],
							[ 11.615117053000063, 9.615296416000035 ],
							[ 11.624403090000044, 9.620996688000048 ],
							[ 11.632525702000066, 9.625553946000025 ],
							[ 11.636013976000072, 9.628978925000069 ],
							[ 11.64528858400007, 9.632390155000053 ],
							[ 11.654569454000068, 9.636945983000032 ],
							[ 11.660382285000026, 9.642653354000061 ],
							[ 11.663881507000042, 9.648366013000043 ],
							[ 11.67201523600005, 9.655212623000068 ],
							[ 11.677817480000044, 9.65863119900007 ],
							[ 11.683625991000042, 9.663194492000059 ],
							[ 11.688261139000076, 9.664328824000052 ],
							[ 11.696383256000047, 9.668886366000038 ],
							[ 11.699039222000067, 9.671813908000047 ],
							[ 11.700855266000076, 9.673814589000074 ],
							[ 11.70686558400007, 9.676787602000047 ],
							[ 11.711619205000034, 9.680097471000067 ],
							[ 11.716515564000076, 9.683506831000045 ],
							[ 11.717346916000054, 9.684037553000053 ],
							[ 11.732924261000051, 9.690083766000043 ],
							[ 11.750403427000037, 9.69571733600003 ],
							[ 11.759548955000071, 9.697295806000056 ],
							[ 11.772902262000059, 9.706252998000025 ],
							[ 11.774005977000058, 9.708401097000035 ],
							[ 11.784703003000061, 9.729219165000075 ],
							[ 11.801367268000035, 9.736503719000041 ],
							[ 11.811478173000069, 9.75536459500006 ],
							[ 11.817141277000076, 9.762731987000052 ],
							[ 11.820669867000049, 9.764712238000072 ],
							[ 11.823396674000037, 9.76605044300004 ],
							[ 11.827629994000063, 9.768128658000023 ],
							[ 11.833433254000056, 9.771548173000042 ],
							[ 11.83575335300003, 9.772687093000059 ],
							[ 11.840398583000024, 9.776108607000026 ],
							[ 11.847374638000076, 9.782957988000021 ],
							[ 11.850863614000048, 9.78638148400006 ],
							[ 11.85319331200003, 9.789809381000055 ],
							[ 11.856681839000032, 9.793233972000053 ],
							[ 11.857850102000043, 9.795519728000045 ],
							[ 11.857860692000031, 9.797807677000037 ],
							[ 11.854462874000035, 9.813834273000055 ],
							[ 11.853325702000063, 9.818412889000058 ],
							[ 11.849880295000048, 9.824141730000065 ],
							[ 11.848738637000054, 9.827576628000031 ],
							[ 11.840700084000048, 9.841324806000046 ],
							[ 11.837286816000073, 9.853919004000034 ],
							[ 11.836149890000058, 9.858498680000025 ],
							[ 11.836171957000033, 9.863074534000077 ],
							[ 11.83619783100005, 9.868795219000049 ],
							[ 11.839738816000022, 9.883661296000071 ],
							[ 11.843243600000051, 9.890518180000072 ],
							[ 11.844410933000063, 9.89280397400006 ],
							[ 11.846747366000045, 9.897376147000045 ],
							[ 11.847920315000067, 9.90080561700006 ],
							[ 11.850267384000063, 9.90766512600004 ],
							[ 11.851440398000022, 9.911094582000032 ],
							[ 11.852623986000026, 9.916813095000066 ],
							[ 11.853808184000059, 9.922531043000049 ],
							[ 11.854986282000027, 9.927104730000053 ],
							[ 11.853945692000025, 9.952279126000064 ],
							[ 11.852820389000044, 9.959146745000055 ],
							[ 11.851693880000028, 9.966013792000069 ],
							[ 11.850585186000046, 9.976313539000046 ],
							[ 11.850605737000024, 9.980891076000034 ],
							[ 11.85062683600006, 9.985466904000077 ],
							[ 11.85299010500006, 9.995759080000028 ],
							[ 11.855346773000065, 10.004907562000028 ],
							[ 11.854205164000064, 10.008342991000063 ],
							[ 11.851921893000053, 10.015213299000038 ],
							[ 11.849639113000023, 10.022083011000063 ],
							[ 11.847345136000058, 10.026664812000035 ],
							[ 11.845056632000023, 10.032390813000063 ],
							[ 11.843910447000042, 10.034681406000061 ],
							[ 11.843921539000064, 10.036969873000032 ],
							[ 11.843942566000067, 10.04154683400003 ],
							[ 11.843390308000039, 10.044590047000042 ],
							[ 11.847430005000035, 10.044971470000064 ],
							[ 11.853217169000061, 10.044957950000025 ],
							[ 11.855256111000074, 10.044204777000061 ],
							[ 11.857408330000055, 10.04487133300006 ],
							[ 11.861324547000038, 10.046083649000025 ],
							[ 11.865954397000053, 10.046073217000071 ],
							[ 11.871735365000063, 10.044915179000043 ],
							[ 11.872929332000069, 10.044715642000028 ],
							[ 11.878675334000036, 10.04375536200007 ],
							[ 11.886766303000059, 10.041448375000073 ],
							[ 11.891390981000029, 10.040293943000052 ],
							[ 11.905263117000061, 10.036829795000074 ],
							[ 11.912197066000033, 10.03452567100004 ],
							[ 11.91573332300004, 10.033517969000059 ],
							[ 11.919665062000036, 10.032396345000052 ],
							[ 11.925273118000064, 10.032337337000058 ],
							[ 11.929002513000057, 10.032297075000031 ],
							[ 11.935350280000023, 10.032230351000067 ],
							[ 11.938810606000061, 10.031222456000023 ],
							[ 11.943424889000028, 10.029877605000024 ],
							[ 11.952674127000023, 10.027567833000035 ],
							[ 11.959099873000071, 10.026975488000062 ],
							[ 11.972961227000042, 10.030912236000063 ],
							[ 11.976074133000054, 10.030544196000051 ],
							[ 11.999241816000051, 10.024606748000053 ],
							[ 12.00005808800006, 10.025534741000058 ],
							[ 12.000968728000032, 10.026570018000029 ],
							[ 12.003766625000026, 10.029977272000053 ],
							[ 12.007273998000073, 10.035436729000025 ],
							[ 12.00975995400006, 10.042962460000069 ],
							[ 12.011201670000048, 10.049810679000075 ],
							[ 12.013650779000045, 10.052877716000069 ],
							[ 12.046500643000059, 10.047806551000065 ],
							[ 12.066222882000034, 10.046271560000037 ],
							[ 12.078005518000055, 10.047545648000039 ],
							[ 12.090157810000051, 10.05156028500005 ],
							[ 12.09677971800005, 10.056649859000061 ],
							[ 12.100637900000038, 10.062448496000059 ],
							[ 12.100699442000064, 10.069650942000067 ],
							[ 12.101148257000034, 10.081652102000021 ],
							[ 12.100176949000058, 10.089549178000027 ],
							[ 12.101286026000025, 10.097771825000052 ],
							[ 12.104807280000045, 10.104602229000022 ],
							[ 12.109047634000035, 10.114513518000024 ],
							[ 12.131253359000027, 10.139536465000049 ],
							[ 12.167688506000047, 10.167071951000025 ],
							[ 12.167834070000026, 10.167181959000061 ],
							[ 12.168394348000049, 10.167914633000066 ],
							[ 12.170931898000049, 10.171232982000049 ],
							[ 12.171585972000059, 10.172088498000051 ],
							[ 12.174425167000038, 10.175802156000032 ],
							[ 12.175987832000033, 10.177117588000044 ],
							[ 12.179032928000026, 10.179680910000059 ],
							[ 12.179145178000056, 10.179824558000064 ],
							[ 12.179221204000044, 10.179839170000037 ],
							[ 12.179346949000035, 10.179863338000075 ],
							[ 12.188015333000067, 10.18689130000007 ],
							[ 12.188131807000048, 10.187027282000031 ],
							[ 12.193191628000022, 10.192934558000047 ],
							[ 12.19339410200007, 10.193131593000032 ],
							[ 12.194520455000031, 10.194227686000033 ],
							[ 12.194845216000033, 10.194543722000049 ],
							[ 12.196025182000028, 10.194966134000026 ],
							[ 12.209235767000052, 10.199695344000077 ],
							[ 12.217682294000042, 10.204695234000042 ],
							[ 12.22224514100003, 10.207265867000046 ],
							[ 12.22406720400005, 10.208292387000029 ],
							[ 12.226330356000062, 10.209287316000029 ],
							[ 12.231430597000042, 10.210049773000037 ],
							[ 12.231460608000077, 10.21005425900006 ],
							[ 12.23660172600006, 10.212038617000076 ],
							[ 12.236855859000059, 10.212178289000065 ],
							[ 12.238040499000022, 10.21282936800003 ],
							[ 12.242780242000038, 10.215434332000029 ],
							[ 12.24444918100005, 10.216910157000029 ],
							[ 12.245052548000046, 10.217443708000076 ],
							[ 12.245907574000057, 10.218056037000053 ],
							[ 12.248971578000067, 10.220250332000035 ],
							[ 12.251373786000045, 10.225476748000062 ],
							[ 12.251479808000056, 10.225707416000034 ],
							[ 12.251663649000022, 10.226043989000061 ],
							[ 12.255228832000057, 10.232571079000024 ],
							[ 12.255230457000039, 10.232574053000064 ],
							[ 12.25523240900003, 10.232577210000045 ],
							[ 12.258350040000039, 10.237619848000065 ],
							[ 12.25871743700003, 10.239688809000029 ],
							[ 12.258781789000068, 10.240051204000054 ],
							[ 12.259388400000034, 10.241971496000076 ],
							[ 12.260891369000035, 10.246729310000035 ],
							[ 12.260838736000039, 10.247118780000051 ],
							[ 12.260829050000041, 10.24719045300003 ],
							[ 12.260873289000074, 10.247252963000051 ],
							[ 12.260873393000054, 10.247253110000031 ],
							[ 12.261574006000046, 10.249973520000026 ],
							[ 12.261911585000064, 10.250960060000068 ],
							[ 12.26274744400007, 10.253402768000058 ],
							[ 12.263936975000036, 10.26026525900005 ],
							[ 12.26393768500003, 10.260422105000032 ],
							[ 12.263949813000067, 10.263086569000052 ],
							[ 12.265131135000047, 10.26827141800004 ],
							[ 12.269797815000061, 10.276270063000027 ],
							[ 12.270965683000043, 10.278555633000053 ],
							[ 12.275795566000056, 10.283972924000068 ],
							[ 12.279109840000046, 10.28769027900006 ],
							[ 12.282249334000028, 10.291284519000044 ],
							[ 12.282261830000039, 10.291298826000059 ],
							[ 12.286092205000045, 10.295684018000031 ],
							[ 12.289169687000026, 10.29970929600006 ],
							[ 12.28918402000005, 10.299728042000027 ],
							[ 12.289584682000054, 10.300252100000023 ],
							[ 12.29236965900003, 10.304076414000065 ],
							[ 12.292989815000055, 10.304928009000037 ],
							[ 12.295408791000057, 10.30824973700004 ],
							[ 12.302369611000074, 10.311664575000066 ],
							[ 12.308254974000022, 10.313587613000038 ],
							[ 12.312801168000021, 10.315073079000058 ],
							[ 12.319760103000021, 10.315630049000049 ],
							[ 12.32902585000005, 10.319613634000063 ],
							[ 12.335970498000052, 10.319597423000062 ],
							[ 12.340514882000036, 10.319586514000036 ],
							[ 12.344072762000053, 10.319577973000037 ],
							[ 12.353063786000064, 10.318371767000031 ],
							[ 12.353086638000036, 10.318368701000054 ],
							[ 12.36142288700006, 10.317250337000075 ],
							[ 12.371844170000031, 10.318371012000057 ],
							[ 12.372755771000072, 10.318256039000062 ],
							[ 12.375273152000034, 10.317938541000046 ],
							[ 12.379519301000073, 10.317404325000041 ],
							[ 12.379522576000056, 10.317403913000021 ],
							[ 12.381099092000056, 10.317205568000077 ],
							[ 12.381336708000049, 10.317204817000061 ],
							[ 12.385728421000067, 10.317190942000025 ],
							[ 12.38905724600005, 10.317184970000028 ],
							[ 12.389057281000021, 10.317184970000028 ],
							[ 12.389260683000032, 10.317184605000023 ],
							[ 12.390479360000029, 10.317482637000069 ],
							[ 12.393369808000045, 10.318189508000046 ],
							[ 12.393915496000034, 10.318223065000041 ],
							[ 12.396072552000021, 10.318355711000038 ],
							[ 12.402350426000055, 10.318739893000043 ],
							[ 12.402350776000048, 10.318739914000048 ],
							[ 12.404258415000072, 10.318640943000048 ],
							[ 12.411195569000029, 10.318281034000051 ],
							[ 12.420067114000062, 10.317285716000072 ],
							[ 12.420076383000037, 10.317284676000043 ],
							[ 12.421606863000022, 10.31711296800006 ],
							[ 12.421606935000057, 10.317112932000043 ],
							[ 12.423917002000053, 10.315962587000058 ],
							[ 12.428773418000048, 10.315951986000073 ],
							[ 12.430861604000029, 10.315947428000072 ],
							[ 12.435490963000063, 10.315937253000072 ],
							[ 12.438076936000073, 10.316296043000023 ],
							[ 12.438082099000042, 10.316296760000057 ],
							[ 12.443598322000071, 10.317062107000027 ],
							[ 12.449395671000048, 10.319337914000073 ],
							[ 12.45287373900004, 10.320473489000051 ],
							[ 12.458785748000025, 10.323025673000075 ],
							[ 12.459561554000061, 10.323360585000046 ],
							[ 12.45956185600005, 10.323360715000035 ],
							[ 12.462167328000021, 10.32515055600004 ],
							[ 12.46557118100003, 10.327488847000041 ],
							[ 12.465973046000045, 10.327634672000045 ],
							[ 12.467943883000032, 10.328349830000036 ],
							[ 12.469434130000025, 10.329022284000075 ],
							[ 12.471858574000066, 10.330116283000052 ],
							[ 12.476078643000051, 10.333007137000038 ],
							[ 12.478335541000035, 10.332622129000072 ],
							[ 12.478819999000052, 10.332539485000041 ],
							[ 12.48334987000004, 10.334630031000074 ],
							[ 12.487362429000029, 10.336944631000051 ],
							[ 12.487362460000043, 10.336944649000031 ],
							[ 12.493460491000064, 10.337542613000039 ],
							[ 12.499253155000076, 10.338674543000025 ],
							[ 12.502110509000033, 10.339875914000061 ],
							[ 12.507370815000058, 10.342087602000049 ],
							[ 12.513174356000036, 10.345507758000053 ],
							[ 12.519930589000069, 10.350494191000053 ],
							[ 12.521148167000035, 10.350651654000046 ],
							[ 12.522234163000064, 10.35079210400005 ],
							[ 12.526562420000062, 10.354643668000051 ],
							[ 12.526562624000064, 10.35464376300007 ],
							[ 12.527154672000052, 10.354917918000069 ],
							[ 12.531629710000061, 10.35699014100004 ],
							[ 12.537003481000056, 10.358981677000031 ],
							[ 12.537542752000036, 10.359181533000026 ],
							[ 12.539439032000075, 10.360733523000022 ],
							[ 12.54451328600004, 10.364886494000075 ],
							[ 12.54915859700003, 10.368308141000057 ],
							[ 12.554961816000059, 10.371728220000023 ],
							[ 12.557990782000047, 10.374835263000023 ],
							[ 12.559620639000059, 10.376506910000046 ],
							[ 12.559630571000071, 10.376581376000047 ],
							[ 12.560563770000044, 10.383578346000036 ],
							[ 12.560564774000056, 10.383585875000051 ],
							[ 12.560812588000033, 10.385443939000027 ],
							[ 12.561985889000027, 10.388873944000068 ],
							[ 12.560848992000047, 10.393453505000025 ],
							[ 12.56207097600003, 10.407180917000062 ],
							[ 12.563849977000075, 10.416615192000052 ],
							[ 12.563303961000031, 10.419861298000058 ],
							[ 12.562421156000028, 10.427965814000061 ],
							[ 12.562191311000049, 10.432600918000048 ],
							[ 12.562019501000066, 10.436065693000046 ],
							[ 12.562062943000058, 10.440486601000032 ],
							[ 12.56208033200005, 10.442256167000039 ],
							[ 12.56262706800004, 10.448918218000074 ],
							[ 12.564589415000057, 10.452709212000059 ],
							[ 12.566777353000077, 10.453979720000063 ],
							[ 12.56681853200007, 10.454192075000037 ],
							[ 12.577679965000073, 10.460662329000058 ],
							[ 12.599330549000058, 10.466205646000049 ],
							[ 12.599936230000026, 10.466334796000069 ],
							[ 12.606018059000064, 10.467631625000024 ],
							[ 12.606820225000035, 10.466580235000038 ],
							[ 12.609999285000072, 10.458140425000067 ],
							[ 12.61285993000007, 10.452260065000075 ],
							[ 12.612779097000043, 10.448620111000025 ],
							[ 12.616183708000051, 10.449667131000069 ],
							[ 12.616862630000071, 10.449875920000068 ],
							[ 12.618983704000073, 10.450528213000041 ],
							[ 12.625938937000058, 10.452800684000067 ],
							[ 12.628249320000066, 10.451651851000065 ],
							[ 12.636307312000042, 10.442479089000074 ],
							[ 12.639759443000059, 10.437894635000021 ],
							[ 12.644362560000047, 10.438298015000044 ],
							[ 12.652494511000043, 10.439009440000063 ],
							[ 12.652495455000064, 10.439009523000038 ],
							[ 12.654913837000038, 10.442566099000032 ],
							[ 12.657156824000026, 10.445864730000039 ],
							[ 12.658714114000077, 10.451472370000033 ],
							[ 12.66046108200004, 10.452784718000032 ],
							[ 12.661274810000066, 10.454756614000075 ],
							[ 12.662249999000039, 10.457119779000038 ],
							[ 12.664240150000069, 10.458710804000077 ],
							[ 12.66532200100005, 10.459575688000029 ],
							[ 12.666143692000048, 10.461580778000041 ],
							[ 12.667664069000068, 10.465290798000069 ],
							[ 12.670010972000057, 10.472150688000056 ],
							[ 12.671334775000048, 10.477228852000053 ],
							[ 12.672492281000075, 10.481672978000063 ],
							[ 12.673155453000049, 10.483491540000045 ],
							[ 12.673680061000027, 10.48493012800003 ],
							[ 12.676632643000062, 10.488622713000041 ],
							[ 12.677118305000022, 10.489230096000028 ],
							[ 12.679362703000038, 10.492037675000063 ],
							[ 12.682852857000057, 10.496148892000065 ],
							[ 12.686341833000029, 10.499573543000054 ],
							[ 12.689855959000056, 10.508717540000021 ],
							[ 12.692186872000036, 10.51214576700005 ],
							[ 12.693506180000043, 10.514729058000057 ],
							[ 12.694521470000041, 10.516717061000065 ],
							[ 12.695695325000031, 10.520147199000064 ],
							[ 12.698031179000054, 10.524718457000063 ],
							[ 12.700019545000032, 10.528126083000075 ],
							[ 12.702698206000036, 10.532716726000046 ],
							[ 12.704051259000039, 10.53470713400003 ],
							[ 12.70502822800006, 10.53614430500005 ],
							[ 12.708885643000031, 10.539929396000048 ],
							[ 12.709679834000042, 10.540708696000024 ],
							[ 12.713173218000065, 10.54527844100005 ],
							[ 12.714678795000054, 10.547737048000045 ],
							[ 12.716671563000034, 10.550991238000051 ],
							[ 12.721327263000035, 10.556701408000038 ],
							[ 12.722500353000044, 10.56013088900005 ],
							[ 12.725027405000048, 10.563435731000027 ],
							[ 12.725994080000021, 10.564699934000032 ],
							[ 12.72690409300003, 10.566038293000076 ],
							[ 12.728324547000057, 10.568127360000062 ],
							[ 12.729851559000053, 10.570124014000044 ],
							[ 12.731818418000046, 10.572695791000058 ],
							[ 12.732538189000024, 10.573402535000071 ],
							[ 12.735306298000069, 10.576120543000059 ],
							[ 12.740583370000024, 10.578191448000041 ],
							[ 12.741103954000039, 10.578395743000044 ],
							[ 12.744580901000063, 10.579531437000071 ],
							[ 12.749888784000063, 10.580642613000066 ],
							[ 12.760800859000028, 10.582926995000037 ],
							[ 12.763747982000041, 10.582190843000035 ],
							[ 12.765425171000061, 10.58177190400005 ],
							[ 12.769203770000047, 10.579890998000053 ],
							[ 12.770044417000065, 10.579472542000076 ],
							[ 12.770708207000041, 10.579306880000047 ],
							[ 12.774668686000041, 10.578318464000063 ],
							[ 12.776977121000073, 10.577169309000055 ],
							[ 12.780450229000053, 10.577160996000032 ],
							[ 12.788552645000038, 10.577142613000035 ],
							[ 12.798963391000029, 10.575974204000033 ],
							[ 12.804721550000068, 10.576082855000038 ],
							[ 12.810500145000049, 10.576191892000054 ],
							[ 12.810500309000076, 10.576191893000043 ],
							[ 12.816324348000023, 10.575935274000074 ],
							[ 12.819888821000063, 10.575423138000076 ],
							[ 12.824421490000077, 10.574771894000037 ],
							[ 12.825755546000039, 10.574768896000023 ],
							[ 12.832522701000073, 10.574753688000044 ],
							[ 12.84246392600005, 10.572097671000051 ],
							[ 12.844958427000051, 10.574209237000048 ],
							[ 12.844958667000071, 10.574209440000061 ],
							[ 12.846181383000044, 10.574448200000063 ],
							[ 12.847568955000042, 10.574719152000057 ],
							[ 12.853516724000031, 10.574705777000077 ],
							[ 12.855609634000075, 10.57470107100005 ],
							[ 12.858971538000048, 10.574693256000046 ],
							[ 12.863772538000035, 10.57468209600006 ],
							[ 12.868332747000068, 10.574220561000061 ],
							[ 12.875340318000042, 10.573511331000077 ],
							[ 12.888055662000056, 10.570050342000059 ],
							[ 12.890400727000042, 10.569271035000043 ],
							[ 12.898456162000059, 10.566594070000065 ],
							[ 12.902138205000028, 10.565775946000031 ],
							[ 12.90886309800004, 10.564281723000022 ],
							[ 12.917121508000037, 10.562218982000047 ],
							[ 12.918111474000057, 10.561971714000038 ],
							[ 12.920680370000071, 10.56101202900004 ],
							[ 12.927355355000032, 10.558518396000068 ],
							[ 12.934287823000034, 10.556214462000071 ],
							[ 12.940069669000025, 10.555056748000027 ],
							[ 12.940070152000033, 10.555056570000033 ],
							[ 12.940271426000038, 10.554981368000028 ],
							[ 12.958557632000065, 10.548149326000043 ],
							[ 12.964725351000027, 10.545079856000029 ],
							[ 12.965485186000024, 10.544701711000073 ],
							[ 12.968498067000041, 10.542988230000049 ],
							[ 12.973566402000074, 10.54010577400004 ],
							[ 12.974765820000073, 10.539508832000024 ],
							[ 12.981540859000063, 10.536136942000041 ],
							[ 12.982804414000043, 10.535508079000067 ],
							[ 12.982804627000064, 10.535507973000051 ],
							[ 12.98307062300006, 10.535331438000071 ],
							[ 12.98626474200006, 10.533211576000042 ],
							[ 12.992031108000049, 10.528621495000039 ],
							[ 12.997386094000035, 10.525069366000025 ],
							[ 13.00217337600003, 10.518694154000059 ],
							[ 13.002730665000058, 10.51795201300007 ],
							[ 13.01133868900007, 10.507376814000054 ],
							[ 13.014554223000061, 10.503426441000045 ],
							[ 13.024618101000044, 10.484685 ],
							[ 13.024639508000064, 10.484656102000031 ],
							[ 13.032207718000052, 10.47443940200003 ],
							[ 13.052586610000048, 10.459819264000032 ],
							[ 13.060260265000068, 10.457194422000043 ],
							[ 13.078184126000053, 10.452762805000077 ],
							[ 13.093521131000045, 10.446665075000055 ],
							[ 13.104607664000071, 10.443154640000046 ],
							[ 13.11398248200004, 10.439662877000046 ],
							[ 13.121693798000024, 10.440424026000073 ],
							[ 13.139030414000047, 10.432222178000075 ],
							[ 13.145528730000024, 10.429147864000072 ],
							[ 13.15745051500005, 10.423932614000023 ],
							[ 13.166566063000062, 10.422767220000026 ],
							[ 13.171977996000066, 10.422075322000069 ],
							[ 13.184833046000051, 10.423624056000051 ],
							[ 13.19077401100003, 10.419322223000052 ],
							[ 13.196705049000059, 10.414173473000062 ],
							[ 13.20527168600006, 10.414923161000047 ],
							[ 13.208743560000073, 10.419118342000047 ],
							[ 13.21312043000006, 10.427537796000024 ],
							[ 13.213157997000053, 10.42760497300003 ],
							[ 13.213301388000048, 10.428231850000031 ],
							[ 13.217105648000029, 10.439692254000022 ],
							[ 13.217596048000075, 10.444425344000024 ],
							[ 13.219425830000034, 10.454567567000026 ],
							[ 13.224669048000067, 10.463823946000048 ],
							[ 13.226456012000028, 10.465205347000051 ],
							[ 13.228987548000077, 10.467162323000025 ],
							[ 13.230000064000023, 10.46920515100004 ],
							[ 13.23077051000007, 10.471305163000068 ],
							[ 13.231211377000022, 10.472834817000034 ],
							[ 13.233360912000023, 10.480292957000074 ],
							[ 13.235098146000041, 10.48715908500003 ],
							[ 13.236623780000059, 10.490092780000055 ],
							[ 13.238119134000044, 10.492400253000028 ],
							[ 13.238169931000073, 10.492531917000065 ],
							[ 13.239374016000056, 10.494619076000049 ],
							[ 13.240211133000059, 10.497366475000035 ],
							[ 13.241672054000048, 10.502161007000041 ],
							[ 13.242563701000051, 10.505087220000064 ],
							[ 13.241894213000023, 10.508363005000035 ],
							[ 13.24011069200003, 10.517254059000038 ],
							[ 13.238638629000036, 10.52252323700003 ],
							[ 13.235493946000076, 10.536098573000061 ],
							[ 13.235581282000055, 10.543570926000029 ],
							[ 13.235261498000057, 10.544802468000057 ],
							[ 13.234956799000031, 10.55027685500005 ],
							[ 13.235093182000071, 10.561946549000027 ],
							[ 13.235542812000062, 10.568871214000069 ],
							[ 13.235615278000068, 10.575070735000054 ],
							[ 13.236433615000067, 10.581991176000031 ],
							[ 13.237200809000058, 10.58453548500006 ],
							[ 13.243202074000067, 10.593220200000076 ],
							[ 13.247694655000032, 10.599004322000042 ],
							[ 13.259381148000045, 10.614160882000022 ],
							[ 13.259381373000053, 10.61416117400006 ],
							[ 13.259860526000068, 10.615098893000038 ],
							[ 13.260960332000025, 10.617251253000063 ],
							[ 13.262662907000049, 10.620168941000031 ],
							[ 13.264665244000071, 10.623600883000051 ],
							[ 13.26558981900007, 10.625185576000035 ],
							[ 13.265589885000054, 10.625185688000045 ],
							[ 13.265627337000069, 10.625249880000069 ],
							[ 13.268264553000051, 10.63105175100003 ],
							[ 13.270303249000051, 10.635536879000028 ],
							[ 13.273818664000032, 10.644682209000052 ],
							[ 13.275771871000074, 10.649134896000021 ],
							[ 13.275774640000066, 10.649141207000071 ],
							[ 13.276961176000043, 10.651846130000024 ],
							[ 13.278061754000078, 10.654356236000069 ],
							[ 13.279349892000027, 10.65729411500007 ],
							[ 13.279590006000035, 10.657841628000028 ],
							[ 13.280836688000022, 10.660684339000056 ],
							[ 13.283182789000023, 10.667544368000051 ],
							[ 13.287849810000068, 10.67554268300006 ],
							[ 13.290988930000026, 10.680666820000056 ],
							[ 13.291349376000028, 10.681255194000073 ],
							[ 13.29601003700003, 10.688109203000067 ],
							[ 13.297412178000059, 10.689828861000024 ],
							[ 13.300666440000043, 10.693820057000039 ],
							[ 13.302626350000025, 10.697020548000069 ],
							[ 13.304165276000049, 10.699533582000072 ],
							[ 13.305322856000032, 10.701915790000044 ],
							[ 13.30690908300005, 10.705180121000069 ],
							[ 13.306909153000049, 10.705180348000056 ],
							[ 13.308305678000067, 10.709716466000032 ],
							[ 13.308754174000057, 10.711173248000023 ],
							[ 13.308754194000073, 10.711173312000028 ],
							[ 13.311245585000052, 10.713461128000063 ],
							[ 13.311798615000043, 10.715075910000053 ],
							[ 13.311799165000025, 10.715077517000054 ],
							[ 13.312346812000044, 10.716676580000069 ],
							[ 13.314744179000058, 10.723249296000063 ],
							[ 13.315702434000059, 10.725877026000035 ],
							[ 13.31704508100006, 10.729671487000076 ],
							[ 13.317065061000051, 10.729727952000076 ],
							[ 13.318030406000048, 10.732858884000052 ],
							[ 13.319385949000036, 10.737255357000038 ],
							[ 13.320945960000074, 10.744299065000064 ],
							[ 13.322931803000074, 10.753265476000024 ],
							[ 13.322945272000027, 10.756104108000045 ],
							[ 13.322958946000028, 10.75898647300005 ],
							[ 13.322958949000054, 10.758986509000067 ],
							[ 13.325305337000032, 10.765846224000029 ],
							[ 13.326536510000039, 10.770111299000064 ],
							[ 13.328001525000047, 10.775186461000033 ],
							[ 13.328542368000058, 10.777287443000034 ],
							[ 13.328834707000055, 10.778423076000024 ],
							[ 13.330585640000038, 10.784100435000028 ],
							[ 13.332360928000071, 10.789856766000071 ],
							[ 13.333809968000025, 10.794091680000065 ],
							[ 13.334381357000041, 10.795761601000038 ],
							[ 13.334381381000071, 10.795761953000067 ],
							[ 13.334397719000037, 10.79599876900005 ],
							[ 13.334905457000048, 10.800878733000047 ],
							[ 13.334962165000036, 10.805615896000063 ],
							[ 13.334603267000034, 10.813171017000059 ],
							[ 13.334788443000036, 10.814162454000041 ],
							[ 13.334788757000069, 10.814164133000077 ],
							[ 13.334794560000034, 10.815339013000028 ],
							[ 13.334800892000032, 10.816821074000075 ],
							[ 13.33480787700006, 10.818455787000062 ],
							[ 13.334826859000032, 10.822620124000025 ],
							[ 13.334855686000026, 10.828753637000034 ],
							[ 13.334965628000077, 10.830797466000035 ],
							[ 13.335113041000056, 10.833537902000046 ],
							[ 13.335116965000054, 10.833611356000063 ],
							[ 13.335283975000038, 10.836737578000054 ],
							[ 13.335600467000063, 10.841685016000042 ],
							[ 13.336300994000055, 10.84655269600006 ],
							[ 13.336376188000031, 10.847075190000055 ],
							[ 13.336376176000044, 10.84707536600007 ],
							[ 13.336160655000072, 10.850271224000039 ],
							[ 13.336160635000056, 10.850271478000025 ],
							[ 13.336160755000037, 10.850277541000025 ],
							[ 13.336160756000027, 10.850277625000047 ],
							[ 13.336210073000075, 10.852775845000053 ],
							[ 13.336223016000076, 10.854413278000038 ],
							[ 13.336224184000059, 10.854561029000024 ],
							[ 13.336247834000062, 10.857552506000047 ],
							[ 13.336301502000026, 10.859646595000072 ],
							[ 13.336180669000044, 10.864831946000038 ],
							[ 13.336180676000026, 10.864833551000061 ],
							[ 13.336180676000026, 10.864833578000059 ],
							[ 13.336198403000026, 10.868795126000066 ],
							[ 13.336140564000061, 10.871034146000056 ],
							[ 13.336046841000041, 10.874662321000073 ],
							[ 13.335932618000072, 10.879064412000048 ],
							[ 13.33593260400005, 10.879064559000028 ],
							[ 13.335714931000041, 10.881416512000044 ],
							[ 13.334700106000071, 10.885858519000067 ],
							[ 13.334439814000064, 10.890000107000049 ],
							[ 13.335214485000051, 10.895173400000033 ],
							[ 13.335309848000065, 10.89581012900004 ],
							[ 13.335390034000056, 10.899450500000057 ],
							[ 13.335634664000054, 10.903167475000032 ],
							[ 13.335939028000041, 10.907784833000051 ],
							[ 13.335940716000039, 10.907810443000074 ],
							[ 13.33594329500005, 10.907847504000074 ],
							[ 13.336172751000049, 10.911144445000048 ],
							[ 13.336411544000043, 10.914562004000061 ],
							[ 13.336970058000077, 10.920900609000057 ],
							[ 13.337746464000077, 10.921989074000066 ],
							[ 13.339447568000026, 10.924373899000045 ],
							[ 13.340913195000041, 10.926430523000022 ],
							[ 13.343418130000032, 10.928276079000057 ],
							[ 13.34574423600003, 10.930559373000051 ],
							[ 13.35037926900003, 10.931692221000048 ],
							[ 13.352522152000063, 10.931156885000064 ],
							[ 13.355002808000052, 10.930537167000068 ],
							[ 13.358470153000042, 10.929384930000026 ],
							[ 13.363089023000043, 10.927086718000055 ],
							[ 13.365191881000044, 10.926040372000045 ],
							[ 13.366789054000037, 10.92404977700005 ],
							[ 13.37037433200004, 10.917806084000063 ],
							[ 13.372901272000036, 10.910541267000042 ],
							[ 13.376984166000057, 10.902224468000043 ],
							[ 13.38060654800006, 10.899080959000059 ],
							[ 13.392556615000046, 10.892738061000045 ],
							[ 13.409740765000038, 10.886849358000063 ],
							[ 13.426395229000036, 10.880449352000028 ],
							[ 13.437323609000032, 10.876184375000037 ],
							[ 13.444094704000065, 10.874036077000028 ],
							[ 13.450349273000029, 10.872410664000029 ],
							[ 13.454538033000063, 10.872877132000042 ],
							[ 13.457674843000063, 10.872839417000023 ],
							[ 13.458838203000028, 10.873002003000067 ],
							[ 13.464483985000072, 10.873791035000067 ],
							[ 13.487570493000021, 10.880230514000061 ],
							[ 13.502311979000069, 10.888320371000077 ],
							[ 13.524451293000027, 10.902520899000024 ],
							[ 13.549368766000043, 10.931183442000076 ],
							[ 13.549369467000076, 10.931184248000022 ],
							[ 13.549450405000073, 10.931237284000076 ],
							[ 13.550673609000057, 10.933034802000066 ],
							[ 13.551781928000025, 10.934663494000063 ],
							[ 13.556063356000038, 10.936765907000051 ],
							[ 13.55642180500007, 10.936941925000042 ],
							[ 13.563387027000033, 10.94150281800006 ],
							[ 13.564445833000036, 10.942022319000046 ],
							[ 13.565707036000049, 10.942641126000069 ],
							[ 13.572668306000025, 10.946058204000053 ],
							[ 13.573770929000034, 10.946599556000024 ],
							[ 13.577307328000074, 10.948335811000049 ],
							[ 13.585414395000043, 10.949461265000025 ],
							[ 13.58667592300003, 10.949458126000025 ],
							[ 13.590043948000073, 10.949449744000049 ],
							[ 13.596988319000047, 10.949434334000046 ],
							[ 13.603933813000026, 10.94941876200005 ],
							[ 13.613192558000037, 10.949398035000058 ],
							[ 13.61319261500006, 10.949398033000023 ],
							[ 13.613192969000067, 10.949398032000033 ],
							[ 13.614438692000022, 10.949218973000029 ],
							[ 13.621289244000025, 10.948234284000023 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Akwa Ibom',
				admin1Pcod: 'NG003',
				admin1RefN: 'Akwa Ibom',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 5.26383,
				Shape_Area: 0.549476
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.344815101000052, 4.611400596000067 ],
							[ 8.34495653700003, 4.609612492000053 ],
							[ 8.344681901000058, 4.609199050000029 ],
							[ 8.344958248000069, 4.608649754000055 ],
							[ 8.34496198100004, 4.606861142000071 ],
							[ 8.344827803000044, 4.605210315000022 ],
							[ 8.344553906000044, 4.604383927000072 ],
							[ 8.344557657000053, 4.60259579600006 ],
							[ 8.344284008000045, 4.601632137000024 ],
							[ 8.343459976000076, 4.600805257000047 ],
							[ 8.341808080000021, 4.600664577000032 ],
							[ 8.340570383000056, 4.599561695000034 ],
							[ 8.339061681000032, 4.596669678000069 ],
							[ 8.337963855000055, 4.595291643000053 ],
							[ 8.337413593000065, 4.594740367000043 ],
							[ 8.335766073000059, 4.592811604000076 ],
							[ 8.336593612000058, 4.591987605000043 ],
							[ 8.339624245000039, 4.591305732000023 ],
							[ 8.339901139000062, 4.590755939000076 ],
							[ 8.339765757000066, 4.589792747000047 ],
							[ 8.338940699000034, 4.589241030000039 ],
							[ 8.337563659000068, 4.589100367000071 ],
							[ 8.336187344000052, 4.588547715000061 ],
							[ 8.333984568000062, 4.588543415000061 ],
							[ 8.332192453000062, 4.589227702000073 ],
							[ 8.331091875000027, 4.58908798300007 ],
							[ 8.33067697000007, 4.589499946000046 ],
							[ 8.329303862000074, 4.588121415000046 ],
							[ 8.326963295000041, 4.587566396000057 ],
							[ 8.325450970000077, 4.586738129000025 ],
							[ 8.323111793000066, 4.586045722000051 ],
							[ 8.321596270000043, 4.586042883000061 ],
							[ 8.319942627000046, 4.586589798000034 ],
							[ 8.318014960000028, 4.586723781000046 ],
							[ 8.315396528000065, 4.587543949000064 ],
							[ 8.314705803000038, 4.588505245000022 ],
							[ 8.313875108000047, 4.590704914000071 ],
							[ 8.31373297600004, 4.592493024000021 ],
							[ 8.313868645000071, 4.593869174000076 ],
							[ 8.312901537000073, 4.595380313000021 ],
							[ 8.312898658000051, 4.59661815100003 ],
							[ 8.313585461000059, 4.597169889000043 ],
							[ 8.313584436000042, 4.59772016200003 ],
							[ 8.309573274000059, 4.606103898000072 ],
							[ 8.309156281000071, 4.607616421000046 ],
							[ 8.309152615000073, 4.609267205000037 ],
							[ 8.309564675000047, 4.610093103000054 ],
							[ 8.311903209000036, 4.611198469000044 ],
							[ 8.313554569000075, 4.611339561000023 ],
							[ 8.316309797000031, 4.610794523000038 ],
							[ 8.318926612000041, 4.610937124000031 ],
							[ 8.319751943000028, 4.611351466000031 ],
							[ 8.320716022000056, 4.611491167000054 ],
							[ 8.321952877000058, 4.612731462000056 ],
							[ 8.322363040000027, 4.61369559700006 ],
							[ 8.322494272000029, 4.616584769000042 ],
							[ 8.322211512000024, 4.620298362000028 ],
							[ 8.321377640000037, 4.623598592000064 ],
							[ 8.321511530000066, 4.625662300000045 ],
							[ 8.32247041100004, 4.627727470000025 ],
							[ 8.323433776000059, 4.628279702000043 ],
							[ 8.324670713000046, 4.629520414000069 ],
							[ 8.325629370000058, 4.631723402000034 ],
							[ 8.326590688000067, 4.633100999000021 ],
							[ 8.327138128000058, 4.634614971000076 ],
							[ 8.328512275000037, 4.636131319000071 ],
							[ 8.330438374000039, 4.636685362000037 ],
							[ 8.332229744000074, 4.636551378000036 ],
							[ 8.333747889000051, 4.634903425000061 ],
							[ 8.335959332000073, 4.631193160000066 ],
							[ 8.338169170000072, 4.628033637000044 ],
							[ 8.339963680000039, 4.626111058000049 ],
							[ 8.340792901000043, 4.62501241700005 ],
							[ 8.341760709000027, 4.623088406000022 ],
							[ 8.343294325000045, 4.614286415000038 ],
							[ 8.344815101000052, 4.611400596000067 ]
						]
					],
					[
						[
							[ 7.71062605700007, 5.519352895000054 ],
							[ 7.71122710700007, 5.516471878000061 ],
							[ 7.710693714000058, 5.51242780900003 ],
							[ 7.713567041000033, 5.511874179000074 ],
							[ 7.717000798000072, 5.512478859000055 ],
							[ 7.719288740000025, 5.513073972000029 ],
							[ 7.72328522600003, 5.514838189000045 ],
							[ 7.72670768100005, 5.51659684200007 ],
							[ 7.729569145000028, 5.517197130000056 ],
							[ 7.734149941000055, 5.517811861000041 ],
							[ 7.742755837000061, 5.517303961000039 ],
							[ 7.745628029000045, 5.516749779000065 ],
							[ 7.748500766000063, 5.516195725000046 ],
							[ 7.751385314000061, 5.514488159000052 ],
							[ 7.754848091000042, 5.51220803800004 ],
							[ 7.757159108000053, 5.510495210000045 ],
							[ 7.758901961000049, 5.508200136000028 ],
							[ 7.760077222000064, 5.505323954000062 ],
							[ 7.759550317000048, 5.500701954000021 ],
							[ 7.760156868000024, 5.497243832000038 ],
							[ 7.759629970000049, 5.492622813000025 ],
							[ 7.760225859000059, 5.490318740000021 ],
							[ 7.761395711000034, 5.488019873000042 ],
							[ 7.763133109000023, 5.486302801000022 ],
							[ 7.765461015000028, 5.48285912800003 ],
							[ 7.768929274000072, 5.480000977000032 ],
							[ 7.774117721000039, 5.477157055000021 ],
							[ 7.775856133000048, 5.475440074000062 ],
							[ 7.778172117000054, 5.473149791000026 ],
							[ 7.779921099000035, 5.470277740000029 ],
							[ 7.781096055000035, 5.46740191300006 ],
							[ 7.782861978000028, 5.462799034000057 ],
							[ 7.784059753000065, 5.457613933000061 ],
							[ 7.784661821000043, 5.454733795000038 ],
							[ 7.786410665000062, 5.451861841000039 ],
							[ 7.787598342000024, 5.447832077000044 ],
							[ 7.788779021000039, 5.444377920000022 ],
							[ 7.792839312000069, 5.439794022000058 ],
							[ 7.798606198000073, 5.436378009000066 ],
							[ 7.80901209700005, 5.427803955000059 ],
							[ 7.821098612000071, 5.423284971000044 ],
							[ 7.838315973000022, 5.421692802000052 ],
							[ 7.850350784000057, 5.422367153000039 ],
							[ 7.853218018000064, 5.422390024000038 ],
							[ 7.856084126000042, 5.422412869000027 ],
							[ 7.858384317000059, 5.421855027000049 ],
							[ 7.86125615800006, 5.421300936000023 ],
							[ 7.863560923000023, 5.420166014000074 ],
							[ 7.865297523000038, 5.418448045000048 ],
							[ 7.866485372000056, 5.414418153000042 ],
							[ 7.867661173000045, 5.411540935000062 ],
							[ 7.86826824000002, 5.40808290800004 ],
							[ 7.871191943000042, 5.402336134000052 ],
							[ 7.872156290000021, 5.400441082000043 ],
							[ 7.875004689000036, 5.390552099000047 ],
							[ 7.878330683000058, 5.385394439000038 ],
							[ 7.882703750000076, 5.382002331000024 ],
							[ 7.876112163000073, 5.368900775000043 ],
							[ 7.877982882000026, 5.367052017000049 ],
							[ 7.880370181000046, 5.366795550000063 ],
							[ 7.881334648000063, 5.36259509000007 ],
							[ 7.884815074000073, 5.35858206000006 ],
							[ 7.888289723000071, 5.355147856000031 ],
							[ 7.891764310000042, 5.35171321100006 ],
							[ 7.896386398000061, 5.348288062000051 ],
							[ 7.900422158000026, 5.346012102000032 ],
							[ 7.903317130000062, 5.343149108000034 ],
							[ 7.904481095000051, 5.341426821000027 ],
							[ 7.903936190000024, 5.338537238000072 ],
							[ 7.901671694000072, 5.33563287100003 ],
							[ 7.899390244000074, 5.334459741000046 ],
							[ 7.896533910000073, 5.333283006000045 ],
							[ 7.893662257000074, 5.333837097000071 ],
							[ 7.889636827000061, 5.334959054000024 ],
							[ 7.887338353000075, 5.335516968000036 ],
							[ 7.882172185000059, 5.336051947000044 ],
							[ 7.878726158000063, 5.336602179000067 ],
							[ 7.875853882000058, 5.337155850000045 ],
							[ 7.872963908000031, 5.339440876000026 ],
							[ 7.870653954000034, 5.341154166000024 ],
							[ 7.868342838000046, 5.342866011000069 ],
							[ 7.865476221000051, 5.342843962000074 ],
							[ 7.862031235000075, 5.343392911000024 ],
							[ 7.859164067000052, 5.343368996000038 ],
							[ 7.856290578000028, 5.34392404700003 ],
							[ 7.851704891000054, 5.343885904000047 ],
							[ 7.848831962000077, 5.344439917000045 ],
							[ 7.845976836000034, 5.343263087000025 ],
							[ 7.841986271000053, 5.340921895000065 ],
							[ 7.840874421000024, 5.337450964000027 ],
							[ 7.842617055000062, 5.335155907000058 ],
							[ 7.844353925000064, 5.33343788600007 ],
							[ 7.846665075000033, 5.33172604400005 ],
							[ 7.849249826000062, 5.329489712000054 ],
							[ 7.851291961000072, 5.327723024000022 ],
							[ 7.85418085200007, 5.325438018000057 ],
							[ 7.85592391800003, 5.323143046000041 ],
							[ 7.857093765000059, 5.320844142000055 ],
							[ 7.85768988600006, 5.318541048000043 ],
							[ 7.860000886000023, 5.316827695000029 ],
							[ 7.862885050000045, 5.315118816000052 ],
							[ 7.865183987000023, 5.314560927000059 ],
							[ 7.867472060000068, 5.315155971000024 ],
							[ 7.869759009000063, 5.315752977000045 ],
							[ 7.872041397000032, 5.316925019000053 ],
							[ 7.874896954000064, 5.318101944000034 ],
							[ 7.879489155000044, 5.31756204800007 ],
							[ 7.882356126000047, 5.317584970000041 ],
							[ 7.885801965000041, 5.31703611000006 ],
							[ 7.888667826000074, 5.31705898000007 ],
							[ 7.892114203000062, 5.316510211000036 ],
							[ 7.89557138300006, 5.314805906000061 ],
							[ 7.900181071000077, 5.312534796000023 ],
							[ 7.901913014000058, 5.31139521800003 ],
							[ 7.904796919000034, 5.309687137000026 ],
							[ 7.907107087000043, 5.307973927000035 ],
							[ 7.909412146000022, 5.306838011000025 ],
							[ 7.912880998000048, 5.303979913000035 ],
							[ 7.916361069000061, 5.299969261000058 ],
							[ 7.919829803000027, 5.297111077000068 ],
							[ 7.922725342000035, 5.294249076000028 ],
							[ 7.926206362000073, 5.290236906000075 ],
							[ 7.929696845000024, 5.285070916000052 ],
							[ 7.931457782000052, 5.281044886000075 ],
							[ 7.933796837000045, 5.276446881000027 ],
							[ 7.93497166700007, 5.273570971000026 ],
							[ 7.935573953000073, 5.270689018000041 ],
							[ 7.93560314900003, 5.267804113000068 ],
							[ 7.936199227000031, 5.265501019000055 ],
							[ 7.936083823000047, 5.262585597000054 ],
							[ 7.936598925000055, 5.26005310000005 ],
							[ 7.937766776000046, 5.254311096000038 ],
							[ 7.940071207000074, 5.249722984000073 ],
							[ 7.943515527000045, 5.245141975000024 ],
							[ 7.948098077000054, 5.241714085000069 ],
							[ 7.95382214600005, 5.238293210000052 ],
							[ 7.961823244000072, 5.23603102800007 ],
							[ 7.966410436000047, 5.231453884000075 ],
							[ 7.968719280000073, 5.225717990000021 ],
							[ 7.969827719000023, 5.220265383000026 ],
							[ 7.973348246000057, 5.211946019000038 ],
							[ 7.976094038000042, 5.208294437000063 ],
							[ 7.984367476000045, 5.205776190000051 ],
							[ 7.992694983000035, 5.206701739000039 ],
							[ 8.003335028000038, 5.219193045000054 ],
							[ 8.006573901000024, 5.225669864000054 ],
							[ 8.009812468000064, 5.238161079000065 ],
							[ 8.016752377000046, 5.246951104000061 ],
							[ 8.026467429000036, 5.247876208000037 ],
							[ 8.034794792000071, 5.243249978000051 ],
							[ 8.055150103000074, 5.217342435000035 ],
							[ 8.061627526000052, 5.206239255000071 ],
							[ 8.064713719000054, 5.200066309000022 ],
							[ 8.065576714000031, 5.199131296000076 ],
							[ 8.066832717000068, 5.196375695000029 ],
							[ 8.068587935000039, 5.193621234000034 ],
							[ 8.071099189000051, 5.188360672000044 ],
							[ 8.072605081000063, 5.185354869000037 ],
							[ 8.073363072000063, 5.181845508000038 ],
							[ 8.073875508000071, 5.176830642000027 ],
							[ 8.072883432000026, 5.174069229000054 ],
							[ 8.070893642000044, 5.170803678000027 ],
							[ 8.068158019000066, 5.166031328000031 ],
							[ 8.066420097000048, 5.162013999000067 ],
							[ 8.065684375000046, 5.156745126000033 ],
							[ 8.065447886000072, 5.151477623000062 ],
							[ 8.064817521000066, 5.145511089000024 ],
							[ 8.069683288000022, 5.135974906000058 ],
							[ 8.074797606000061, 5.131106878000026 ],
							[ 8.081890905000023, 5.127406085000075 ],
							[ 8.095214979000048, 5.115192414000035 ],
							[ 8.099286070000062, 5.107050438000044 ],
							[ 8.10039609200004, 5.081142905000036 ],
							[ 8.100026091000075, 5.062637312000049 ],
							[ 8.098175186000049, 5.043021676000023 ],
							[ 8.100026138000032, 5.033398607000038 ],
							[ 8.102616245000036, 5.023776013000031 ],
							[ 8.115570168000033, 5.002679834000048 ],
							[ 8.12223270000004, 4.994167358000027 ],
							[ 8.125563354000064, 4.981954055000074 ],
							[ 8.128153576000045, 4.968999882000048 ],
							[ 8.136296166000022, 4.957896692000077 ],
							[ 8.149249969000039, 4.939391588000035 ],
							[ 8.153121180000028, 4.938528079000037 ],
							[ 8.171456115000069, 4.933469795000065 ],
							[ 8.176268386000061, 4.929768538000076 ],
							[ 8.197363919000054, 4.913484126000071 ],
							[ 8.207357647000038, 4.908302289000062 ],
							[ 8.215499875000035, 4.897199132000026 ],
							[ 8.220311347000063, 4.882024761000025 ],
							[ 8.222901529000069, 4.872772238000039 ],
							[ 8.230303849000052, 4.860188493000067 ],
							[ 8.240281275000029, 4.854086426000038 ],
							[ 8.245797402000051, 4.852643992000026 ],
							[ 8.250850876000072, 4.848344338000061 ],
							[ 8.253651610000077, 4.845960589000072 ],
							[ 8.262337439000021, 4.838610641000059 ],
							[ 8.266182910000055, 4.835324752000076 ],
							[ 8.268738650000046, 4.829077246000054 ],
							[ 8.265699404000031, 4.826258176000067 ],
							[ 8.265151950000075, 4.824743767000029 ],
							[ 8.263364616000047, 4.822951755000076 ],
							[ 8.262093836000076, 4.822949185000027 ],
							[ 8.252440406000062, 4.814089406000051 ],
							[ 8.25419131600006, 4.813822339000069 ],
							[ 8.256189939000024, 4.812572346000024 ],
							[ 8.257689750000054, 4.811070580000035 ],
							[ 8.260192166000024, 4.807564328000069 ],
							[ 8.260946063000063, 4.804806957000039 ],
							[ 8.263203796000028, 4.798792086000049 ],
							[ 8.266207787000042, 4.793781925000076 ],
							[ 8.268710502000033, 4.790024813000059 ],
							[ 8.270710419000068, 4.788022321000028 ],
							[ 8.273708778000071, 4.785771064000073 ],
							[ 8.275458042000025, 4.78426964800002 ],
							[ 8.276560652000057, 4.783089543000074 ],
							[ 8.280540217000066, 4.781029657000033 ],
							[ 8.283911232000037, 4.77963016700005 ],
							[ 8.288122871000041, 4.77788159000005 ],
							[ 8.29101739500004, 4.777199744000029 ],
							[ 8.291569424000045, 4.776650896000035 ],
							[ 8.292123081000057, 4.77555131500003 ],
							[ 8.292265876000044, 4.773212412000021 ],
							[ 8.292679906000046, 4.77280090000005 ],
							[ 8.296814035000068, 4.772121412000047 ],
							[ 8.29833024800007, 4.771574487000066 ],
							[ 8.299433501000067, 4.770888824000053 ],
							[ 8.299986062000073, 4.770339464000074 ],
							[ 8.300263119000022, 4.769515031000026 ],
							[ 8.300404552000032, 4.767864724000049 ],
							[ 8.310204713000076, 4.758392340000057 ],
							[ 8.311033510000073, 4.757431023000038 ],
							[ 8.312001509000027, 4.75536919700005 ],
							[ 8.312969355000064, 4.753995413000041 ],
							[ 8.316420750000077, 4.750288022000063 ],
							[ 8.317527007000024, 4.748227144000055 ],
							[ 8.317530892000036, 4.746438522000062 ],
							[ 8.316708324000047, 4.744373325000026 ],
							[ 8.315609885000072, 4.743133073000024 ],
							[ 8.314101088000029, 4.74037886800005 ],
							[ 8.313279852000051, 4.737625602000037 ],
							[ 8.313150170000029, 4.733911065000029 ],
							[ 8.314120261000028, 4.731299425000032 ],
							[ 8.314262426000028, 4.729511284000068 ],
							[ 8.314677408000023, 4.728548975000024 ],
							[ 8.31509681700004, 4.725798617000066 ],
							[ 8.315373296000075, 4.725248844000021 ],
							[ 8.315100457000028, 4.724147839000068 ],
							[ 8.314275646000056, 4.723320951000062 ],
							[ 8.313586970000074, 4.723044406000042 ],
							[ 8.31283314500007, 4.72304292900003 ],
							[ 8.311797182000021, 4.723315722000052 ],
							[ 8.310693799000035, 4.723863558000062 ],
							[ 8.309865096000067, 4.72482487700006 ],
							[ 8.309169932000032, 4.727712608000047 ],
							[ 8.307646533000025, 4.731286051000041 ],
							[ 8.306201230000056, 4.73368460100005 ],
							[ 8.305298945000061, 4.734583374000067 ],
							[ 8.304743505000033, 4.736095401000057 ],
							[ 8.30377683200004, 4.737743858000044 ],
							[ 8.30349852300003, 4.739256838000074 ],
							[ 8.302666801000044, 4.741180927000073 ],
							[ 8.301971599000069, 4.744343762000028 ],
							[ 8.301418018000049, 4.745443341000055 ],
							[ 8.301004600000056, 4.745854864000023 ],
							[ 8.300176558000032, 4.746128097000053 ],
							[ 8.298799236000036, 4.746125727000049 ],
							[ 8.297973382000066, 4.745849111000041 ],
							[ 8.297424061000072, 4.74529698200007 ],
							[ 8.296874188000061, 4.744745704000024 ],
							[ 8.297564756000043, 4.744334664000064 ],
							[ 8.299905840000065, 4.743927001000031 ],
							[ 8.300458118000051, 4.74351504200007 ],
							[ 8.30198761500003, 4.73677777000006 ],
							[ 8.303231124000035, 4.734991520000051 ],
							[ 8.303631129000053, 4.733638788000064 ],
							[ 8.304340352000054, 4.731967449000024 ],
							[ 8.305447711000056, 4.729630935000046 ],
							[ 8.305999648000068, 4.72908213900007 ],
							[ 8.306553138000027, 4.727707359000021 ],
							[ 8.306832255000074, 4.726057031000039 ],
							[ 8.307937718000062, 4.724408627000059 ],
							[ 8.308630007000033, 4.722759230000065 ],
							[ 8.309872350000035, 4.721248628000069 ],
							[ 8.312768737000056, 4.719603521000067 ],
							[ 8.313321219000045, 4.719054216000075 ],
							[ 8.312497194000059, 4.717814453000074 ],
							[ 8.310985398000071, 4.716435930000046 ],
							[ 8.310160651000047, 4.715883725000026 ],
							[ 8.309056994000059, 4.716294283000025 ],
							[ 8.307262177000041, 4.718354273000045 ],
							[ 8.306434495000076, 4.718765275000067 ],
							[ 8.305745884000032, 4.718763833000025 ],
							[ 8.305196903000024, 4.718349915000033 ],
							[ 8.304369756000028, 4.71848581200004 ],
							[ 8.303544723000073, 4.717796319000058 ],
							[ 8.302856634000022, 4.717520256000057 ],
							[ 8.301345920000074, 4.715590976000044 ],
							[ 8.297076311000069, 4.715719684000021 ],
							[ 8.296250245000067, 4.715580948000024 ],
							[ 8.295289923000041, 4.713927732000059 ],
							[ 8.295293607000076, 4.712001779000047 ],
							[ 8.295848760000069, 4.710352420000049 ],
							[ 8.295712353000056, 4.709664313000076 ],
							[ 8.295299731000057, 4.709388267000065 ],
							[ 8.294058933000031, 4.710073486000056 ],
							[ 8.293370075000041, 4.710209849000023 ],
							[ 8.290611134000073, 4.711992267000028 ],
							[ 8.288131926000062, 4.712124851000056 ],
							[ 8.287305568000022, 4.711847766000062 ],
							[ 8.286344495000037, 4.710607971000059 ],
							[ 8.285656204000077, 4.710744395000063 ],
							[ 8.285240845000033, 4.711293724000029 ],
							[ 8.284851894000042, 4.713591104000045 ],
							[ 8.284519455000066, 4.714283443000056 ],
							[ 8.283497835000048, 4.714986798000041 ],
							[ 8.28316484000004, 4.715679153000053 ],
							[ 8.27975374500005, 4.717451094000069 ],
							[ 8.278023677000022, 4.716791182000065 ],
							[ 8.277674696000076, 4.716452587000049 ],
							[ 8.277686279000022, 4.715993382000022 ],
							[ 8.278351740000062, 4.71575449200003 ],
							[ 8.279044190000036, 4.716087358000038 ],
							[ 8.280420323000044, 4.716065909000065 ],
							[ 8.281445222000059, 4.715706343000022 ],
							[ 8.283476894000046, 4.713612572000045 ],
							[ 8.284148420000065, 4.712571182000033 ],
							[ 8.284098660000041, 4.710699093000073 ],
							[ 8.283729731000051, 4.709914645000026 ],
							[ 8.283733511000037, 4.708263867000028 ],
							[ 8.284422903000063, 4.707852831000025 ],
							[ 8.285249766000049, 4.707854750000024 ],
							[ 8.28648651900005, 4.708682567000039 ],
							[ 8.287588029000062, 4.70909737300002 ],
							[ 8.288548591000051, 4.710887882000065 ],
							[ 8.288960658000065, 4.711163508000027 ],
							[ 8.29061408900003, 4.71075435900002 ],
							[ 8.292132503000062, 4.709519399000044 ],
							[ 8.292683637000039, 4.70938297500004 ],
							[ 8.293373286000076, 4.708834169000056 ],
							[ 8.294751258000076, 4.708424115000071 ],
							[ 8.29640388200005, 4.708427458000074 ],
							[ 8.296953962000032, 4.708841318000054 ],
							[ 8.297228094000047, 4.70952989400007 ],
							[ 8.29667187900003, 4.711729546000072 ],
							[ 8.296669252000072, 4.713105227000028 ],
							[ 8.29694364900007, 4.713655975000052 ],
							[ 8.298870911000051, 4.713797591000059 ],
							[ 8.299835463000022, 4.713524346000042 ],
							[ 8.301213233000055, 4.713527194000051 ],
							[ 8.302862934000075, 4.714493723000032 ],
							[ 8.304512242000044, 4.71656032900006 ],
							[ 8.306164884000054, 4.716563683000061 ],
							[ 8.307267127000046, 4.716015785000025 ],
							[ 8.309337676000041, 4.714093683000044 ],
							[ 8.310440221000022, 4.713683102000061 ],
							[ 8.314429193000024, 4.716030110000077 ],
							[ 8.31594467900004, 4.716032958000028 ],
							[ 8.316910050000047, 4.715622453000037 ],
							[ 8.317737355000077, 4.714798481000059 ],
							[ 8.320639855000024, 4.710402027000043 ],
							[ 8.32201957500007, 4.709029208000061 ],
							[ 8.32257069700006, 4.708892812000045 ],
							[ 8.323398525000073, 4.708069310000042 ],
							[ 8.323813188000031, 4.707244409000054 ],
							[ 8.323815574000037, 4.706556775000024 ],
							[ 8.324231008000027, 4.705319429000042 ],
							[ 8.324372310000058, 4.703668609000033 ],
							[ 8.324252346000037, 4.695827034000047 ],
							[ 8.325496567000073, 4.693215888000054 ],
							[ 8.325502526000037, 4.690877399000044 ],
							[ 8.324817817000053, 4.689087369000049 ],
							[ 8.324820339000041, 4.687711688000036 ],
							[ 8.325374500000066, 4.686199682000051 ],
							[ 8.325378647000036, 4.68454882900005 ],
							[ 8.324694894000061, 4.681933407000031 ],
							[ 8.324697230000027, 4.680970671000068 ],
							[ 8.324974969000039, 4.680008372000032 ],
							[ 8.32497907000004, 4.678082493000034 ],
							[ 8.324434397000061, 4.675330184000074 ],
							[ 8.324582378000059, 4.670652846000053 ],
							[ 8.324451483000075, 4.66693736000002 ],
							[ 8.324456389000034, 4.664875042000062 ],
							[ 8.324182240000027, 4.664186486000062 ],
							[ 8.323358367000026, 4.663221865000025 ],
							[ 8.322258030000057, 4.662531846000036 ],
							[ 8.320469176000074, 4.661840449000067 ],
							[ 8.319780120000075, 4.661839033000035 ],
							[ 8.318542389000072, 4.661286352000047 ],
							[ 8.317440774000033, 4.661283937000064 ],
							[ 8.316474780000021, 4.66210744600005 ],
							[ 8.316059071000041, 4.663207530000022 ],
							[ 8.316056006000053, 4.664858312000035 ],
							[ 8.315499162000037, 4.667195842000069 ],
							[ 8.315634568000064, 4.668434140000045 ],
							[ 8.315220700000054, 4.668846105000057 ],
							[ 8.31466471300007, 4.671320889000071 ],
							[ 8.313003691000063, 4.675307252000039 ],
							[ 8.310931321000055, 4.678329460000043 ],
							[ 8.30803318300002, 4.681075117000034 ],
							[ 8.304861100000039, 4.682719736000024 ],
							[ 8.301276944000051, 4.684088195000072 ],
							[ 8.297417691000021, 4.68586870200005 ],
							[ 8.293696579000027, 4.687098995000042 ],
							[ 8.285428848000038, 4.689146050000033 ],
							[ 8.282672705000039, 4.689552803000026 ],
							[ 8.280883022000069, 4.689548997000031 ],
							[ 8.276752472000055, 4.688852755000028 ],
							[ 8.269458703000055, 4.686361800000043 ],
							[ 8.263952513000049, 4.685524971000063 ],
							[ 8.259961153000063, 4.684141143000033 ],
							[ 8.257072314000027, 4.682621953000023 ],
							[ 8.255009745000052, 4.681379326000069 ],
							[ 8.251847201000032, 4.679034214000069 ],
							[ 8.24744873800006, 4.675035440000045 ],
							[ 8.246213874000034, 4.673382322000066 ],
							[ 8.245117324000034, 4.671178823000048 ],
							[ 8.244845292000036, 4.669527545000051 ],
							[ 8.243337902000064, 4.666223045000038 ],
							[ 8.24182713700003, 4.66429379300007 ],
							[ 8.239349351000044, 4.663875606000033 ],
							[ 8.237145288000022, 4.66400862200004 ],
							[ 8.228739459000053, 4.66632983300002 ],
							[ 8.226395650000029, 4.667425153000067 ],
							[ 8.225154015000044, 4.668522806000055 ],
							[ 8.224872838000067, 4.670998538000049 ],
							[ 8.225146500000051, 4.67182445900005 ],
							[ 8.226382386000068, 4.673478150000051 ],
							[ 8.22679260700005, 4.674854283000059 ],
							[ 8.227341430000024, 4.675543291000054 ],
							[ 8.227337967000039, 4.677194146000033 ],
							[ 8.227748796000071, 4.678020484000058 ],
							[ 8.22788074500005, 4.680497168000045 ],
							[ 8.228292702000033, 4.681323082000063 ],
							[ 8.228290402000027, 4.682148472000051 ],
							[ 8.227601284000059, 4.682422185000064 ],
							[ 8.226639070000033, 4.681594824000058 ],
							[ 8.22608738200006, 4.681731203000027 ],
							[ 8.225259965000021, 4.682554690000075 ],
							[ 8.224845092000066, 4.683379219000074 ],
							[ 8.224842232000071, 4.684204607000026 ],
							[ 8.224428243000034, 4.684616525000024 ],
							[ 8.223597373000075, 4.686540587000025 ],
							[ 8.222906801000022, 4.687227236000069 ],
							[ 8.222356518000026, 4.687225840000053 ],
							[ 8.222082852000028, 4.686399936000043 ],
							[ 8.222085728000025, 4.685299374000067 ],
							[ 8.22263917500004, 4.684062492000066 ],
							[ 8.222641778000025, 4.683099758000026 ],
							[ 8.221954398000037, 4.68254759000007 ],
							[ 8.221128433000047, 4.682408830000043 ],
							[ 8.220854757000041, 4.681582425000045 ],
							[ 8.221682741000052, 4.681034124000064 ],
							[ 8.224990901000069, 4.679802910000035 ],
							[ 8.225680602000068, 4.678978909000023 ],
							[ 8.225823483000056, 4.677053454000031 ],
							[ 8.224449904000039, 4.67512462600007 ],
							[ 8.223354320000055, 4.672783864000053 ],
							[ 8.223217800000043, 4.671957975000055 ],
							[ 8.222806117000061, 4.671544576000031 ],
							[ 8.222117316000038, 4.671405333000052 ],
							[ 8.221153368000046, 4.671678523000026 ],
							[ 8.220462839000049, 4.672364741000024 ],
							[ 8.219911715000023, 4.672501130000057 ],
							[ 8.219359181000073, 4.673050444000069 ],
							[ 8.218666001000031, 4.674974920000068 ],
							[ 8.218385913000077, 4.677175049000027 ],
							[ 8.217692446000058, 4.67923736900002 ],
							[ 8.217002124000032, 4.680336454000042 ],
							[ 8.216585252000073, 4.681573417000038 ],
							[ 8.216171239000062, 4.68198540000003 ],
							[ 8.215760157000034, 4.68129680100003 ],
							[ 8.215347350000059, 4.680882910000037 ],
							[ 8.213691636000021, 4.68211745900004 ],
							[ 8.212450795000052, 4.682802696000067 ],
							[ 8.210522882000021, 4.682798375000061 ],
							[ 8.209973489000049, 4.682384533000061 ],
							[ 8.211215216000028, 4.681286785000054 ],
							[ 8.212178920000042, 4.681151395000029 ],
							[ 8.21424947600002, 4.67936751000002 ],
							[ 8.214803785000072, 4.677992814000049 ],
							[ 8.214944999000068, 4.676617653000051 ],
							[ 8.215221572000075, 4.676067807000038 ],
							[ 8.215087106000055, 4.674554354000065 ],
							[ 8.212334745000021, 4.673723224000071 ],
							[ 8.208616149000022, 4.673715108000067 ],
							[ 8.206641080000054, 4.673179317000063 ],
							[ 8.205037892000064, 4.672744281000064 ],
							[ 8.204073971000071, 4.672742325000058 ],
							[ 8.203107673000034, 4.673565884000027 ],
							[ 8.202691911000045, 4.674527659000034 ],
							[ 8.20296492500006, 4.675903796000057 ],
							[ 8.202687148000052, 4.676728711000067 ],
							[ 8.202135135000049, 4.677277593000042 ],
							[ 8.201446602000033, 4.677276640000059 ],
							[ 8.201173588000074, 4.676175121000028 ],
							[ 8.200347050000062, 4.676311006000049 ],
							[ 8.199657623000064, 4.67672206900005 ],
							[ 8.198831682000048, 4.676582778000068 ],
							[ 8.198833477000051, 4.675757387000033 ],
							[ 8.199524053000061, 4.675071265000042 ],
							[ 8.200488341000039, 4.67466065800005 ],
							[ 8.200972486000069, 4.674042697000061 ],
							[ 8.202006066000024, 4.673288372000059 ],
							[ 8.202147326000045, 4.671912642000052 ],
							[ 8.201462934000062, 4.670260910000025 ],
							[ 8.201055753000048, 4.66778376000002 ],
							[ 8.200370822000025, 4.666131470000039 ],
							[ 8.200375828000062, 4.664342846000068 ],
							[ 8.200857984000038, 4.663862728000026 ],
							[ 8.202019404000055, 4.664537838000058 ],
							[ 8.202373069000032, 4.665253033000056 ],
							[ 8.204933972000049, 4.66673208800006 ],
							[ 8.205313698000055, 4.669175114000041 ],
							[ 8.205995597000026, 4.670939808000071 ],
							[ 8.210413070000072, 4.670830212000055 ],
							[ 8.212342098000022, 4.670284242000037 ],
							[ 8.213995121000039, 4.67028760900007 ],
							[ 8.214684243000022, 4.669738727000038 ],
							[ 8.215373038000052, 4.669877984000038 ],
							[ 8.217853695000031, 4.669470297000032 ],
							[ 8.222126283000023, 4.667691256000069 ],
							[ 8.222542015000045, 4.666453858000068 ],
							[ 8.221168743000021, 4.664937982000026 ],
							[ 8.22116935400004, 4.664387690000069 ],
							[ 8.221721581000054, 4.663976201000025 ],
							[ 8.222685788000035, 4.663840784000058 ],
							[ 8.223649125000065, 4.664117808000071 ],
							[ 8.224474162000035, 4.664669489000062 ],
							[ 8.225989165000044, 4.664810687000056 ],
							[ 8.226815957000042, 4.664536916000031 ],
							[ 8.227920439000059, 4.663438820000067 ],
							[ 8.227783316000057, 4.662888532000068 ],
							[ 8.227095678000069, 4.662474127000053 ],
							[ 8.226547446000041, 4.661785119000058 ],
							[ 8.224759091000067, 4.660680749000051 ],
							[ 8.223658366000052, 4.660265957000036 ],
							[ 8.222421374000021, 4.659163029000069 ],
							[ 8.221870318000072, 4.659023802000036 ],
							[ 8.221456626000077, 4.659298444000058 ],
							[ 8.220768991000057, 4.659159202000069 ],
							[ 8.220633336000049, 4.657920847000071 ],
							[ 8.221326735000048, 4.656134140000063 ],
							[ 8.221746497000026, 4.653245887000026 ],
							[ 8.223059402000047, 4.651116855000055 ],
							[ 8.224371135000069, 4.64953710900005 ],
							[ 8.224786812000048, 4.64857532700006 ],
							[ 8.224925958000028, 4.647887704000027 ],
							[ 8.224794331000055, 4.645273671000041 ],
							[ 8.22534655100003, 4.644587021000063 ],
							[ 8.225626250000062, 4.643074506000062 ],
							[ 8.225492603000021, 4.641148109000028 ],
							[ 8.226318193000054, 4.641150013000072 ],
							[ 8.227279716000055, 4.642528094000056 ],
							[ 8.22823697900003, 4.645143505000021 ],
							[ 8.228646022000021, 4.647070409000037 ],
							[ 8.228643430000034, 4.648308264000036 ],
							[ 8.227812087000075, 4.650507438000034 ],
							[ 8.225946691000047, 4.653461486000026 ],
							[ 8.225049197000033, 4.654490918000022 ],
							[ 8.224218117000021, 4.656277636000027 ],
							[ 8.224213508000048, 4.658203609000054 ],
							[ 8.224488307000058, 4.658754839000039 ],
							[ 8.226000694000049, 4.659858198000052 ],
							[ 8.228201618000071, 4.660413291000054 ],
							[ 8.228890379000063, 4.660827142000073 ],
							[ 8.232470568000053, 4.661109951000071 ],
							[ 8.233021672000064, 4.660698442000069 ],
							[ 8.233434764000037, 4.660974487000033 ],
							[ 8.234536930000047, 4.660701778000032 ],
							[ 8.236601596000071, 4.660843383000042 ],
							[ 8.238392832000045, 4.660572013000035 ],
							[ 8.241284255000039, 4.660715616000061 ],
							[ 8.243210928000053, 4.661545294000064 ],
							[ 8.244585106000045, 4.662648660000059 ],
							[ 8.245273618000056, 4.662925262000044 ],
							[ 8.248156639000058, 4.666645543000072 ],
							[ 8.249116058000027, 4.668848528000069 ],
							[ 8.251447791000032, 4.672842968000054 ],
							[ 8.255569483000045, 4.677253706000045 ],
							[ 8.256189583000037, 4.677461122000068 ],
							[ 8.257631475000039, 4.679046196000058 ],
							[ 8.259969463000061, 4.680564393000054 ],
							[ 8.260932853000043, 4.68084144900007 ],
							[ 8.263412431000063, 4.680708847000062 ],
							[ 8.263825544000042, 4.680434729000069 ],
							[ 8.263972350000074, 4.676445460000025 ],
							[ 8.264387955000075, 4.67548320700007 ],
							[ 8.265561339000044, 4.674179042000048 ],
							[ 8.266872423000052, 4.672874892000038 ],
							[ 8.266737726000031, 4.671361457000046 ],
							[ 8.266879017000065, 4.669848430000059 ],
							[ 8.26743037500006, 4.669574717000046 ],
							[ 8.268532566000033, 4.669576654000025 ],
							[ 8.27142625700003, 4.668894739000052 ],
							[ 8.272115861000032, 4.668345898000041 ],
							[ 8.272531435000076, 4.667383654000048 ],
							[ 8.273083898000039, 4.666834815000072 ],
							[ 8.273771298000042, 4.667111361000025 ],
							[ 8.273356299000056, 4.668073200000038 ],
							[ 8.27335437000005, 4.668761261000043 ],
							[ 8.272111774000052, 4.670134523000058 ],
							[ 8.27114675200005, 4.670682887000055 ],
							[ 8.269356881000022, 4.670541326000034 ],
							[ 8.268667270000037, 4.671090162000041 ],
							[ 8.268386906000046, 4.673015561000057 ],
							[ 8.268110396000054, 4.673565373000031 ],
							[ 8.266177026000037, 4.676037312000062 ],
							[ 8.265622887000063, 4.677136913000027 ],
							[ 8.265755662000061, 4.679613157000063 ],
							[ 8.266166585000065, 4.680714627000043 ],
							[ 8.266852653000058, 4.681679230000043 ],
							[ 8.267952968000031, 4.682644405000076 ],
							[ 8.269329208000045, 4.682784569000034 ],
							[ 8.269879511000056, 4.683061144000021 ],
							[ 8.273460209000064, 4.682930956000064 ],
							[ 8.274974746000055, 4.68334677100006 ],
							[ 8.27910627600005, 4.683217494000075 ],
							[ 8.284618167000076, 4.681990649000056 ],
							[ 8.287372330000039, 4.681996343000037 ],
							[ 8.291645319000054, 4.680491921000055 ],
							[ 8.292334651000033, 4.680080388000022 ],
							[ 8.293852601000026, 4.67843289800004 ],
							[ 8.294680452000023, 4.677884080000069 ],
							[ 8.297435943000039, 4.677476883000054 ],
							[ 8.298263477000035, 4.676790709000045 ],
							[ 8.298265667000067, 4.676240494000069 ],
							[ 8.297853296000028, 4.67555193000004 ],
							[ 8.293043149000027, 4.671139762000053 ],
							[ 8.291392586000029, 4.670448786000065 ],
							[ 8.289463900000044, 4.670582302000071 ],
							[ 8.288225079000028, 4.670029664000026 ],
							[ 8.287536561000024, 4.670028170000023 ],
							[ 8.28588570900007, 4.669199470000024 ],
							[ 8.285061657000028, 4.668372609000073 ],
							[ 8.284377971000026, 4.666170111000042 ],
							[ 8.284381712000027, 4.664244156000052 ],
							[ 8.284796449000055, 4.663694880000037 ],
							[ 8.287278298000047, 4.662323928000035 ],
							[ 8.288796218000073, 4.660951633000025 ],
							[ 8.288936618000037, 4.659851042000071 ],
							[ 8.288112541000032, 4.65874906800002 ],
							[ 8.286462006000022, 4.657782542000064 ],
							[ 8.285910375000071, 4.657919364000065 ],
							[ 8.28425395000005, 4.659704192000049 ],
							[ 8.283561689000067, 4.661353619000067 ],
							[ 8.282458198000029, 4.662314391000052 ],
							[ 8.281631185000037, 4.662725430000023 ],
							[ 8.279152004000025, 4.662720181000054 ],
							[ 8.277224734000072, 4.662441244000036 ],
							[ 8.275437347000036, 4.661336902000073 ],
							[ 8.274063857000044, 4.659270785000047 ],
							[ 8.273932428000023, 4.656656716000043 ],
							[ 8.27420889900003, 4.656106973000021 ],
							[ 8.275450917000057, 4.655008803000044 ],
							[ 8.279170940000029, 4.654190993000043 ],
							[ 8.279723091000051, 4.653504389000034 ],
							[ 8.279726871000037, 4.651853534000054 ],
							[ 8.278902841000047, 4.650751113000069 ],
							[ 8.277802637000036, 4.650336283000058 ],
							[ 8.276838473000055, 4.650197023000032 ],
							[ 8.275324111000032, 4.650331491000031 ],
							[ 8.273396609000031, 4.649914703000036 ],
							[ 8.272707857000057, 4.650050601000032 ],
							[ 8.270506076000061, 4.649358299000028 ],
							[ 8.269681805000062, 4.648393629000054 ],
							[ 8.269684805000054, 4.646880601000021 ],
							[ 8.270237242000064, 4.646331358000054 ],
							[ 8.271198093000066, 4.647571550000066 ],
							[ 8.272435174000066, 4.648674517000075 ],
							[ 8.275052259000063, 4.648817507000047 ],
							[ 8.276980537000043, 4.648546239000041 ],
							[ 8.278631244000053, 4.649099787000068 ],
							[ 8.280008202000033, 4.649102733000063 ],
							[ 8.280971577000059, 4.649654837000071 ],
							[ 8.281795309000074, 4.650619499000072 ],
							[ 8.28193086400006, 4.651720539000053 ],
							[ 8.281512078000048, 4.654058420000069 ],
							[ 8.281097397000053, 4.65515804100005 ],
							[ 8.280130516000042, 4.656118871000047 ],
							[ 8.27930460500005, 4.656254789000059 ],
							[ 8.278478666000069, 4.655840429000023 ],
							[ 8.276827120000064, 4.655699234000053 ],
							[ 8.275447118000045, 4.656659584000067 ],
							[ 8.275305868000032, 4.658172615000069 ],
							[ 8.275579380000067, 4.65913632500002 ],
							[ 8.275853134000045, 4.659687016000021 ],
							[ 8.276540552000029, 4.660238731000049 ],
							[ 8.278605546000051, 4.660793341000044 ],
							[ 8.280533917000071, 4.660797155000068 ],
							[ 8.282049141000073, 4.660524821000024 ],
							[ 8.282738714000061, 4.659976034000067 ],
							[ 8.283846795000045, 4.657227062000061 ],
							[ 8.284813117000056, 4.656266219000031 ],
							[ 8.28550159100007, 4.655992525000045 ],
							[ 8.286465469000063, 4.655994415000066 ],
							[ 8.288530476000062, 4.656823644000042 ],
							[ 8.290178565000076, 4.658477795000067 ],
							[ 8.290727504000074, 4.659441956000023 ],
							[ 8.290585274000023, 4.66150519200005 ],
							[ 8.289894874000026, 4.662191913000072 ],
							[ 8.286586826000075, 4.663560415000063 ],
							[ 8.28548226700002, 4.664796813000066 ],
							[ 8.285479296000062, 4.666034722000063 ],
							[ 8.28588944300003, 4.667273515000034 ],
							[ 8.286577443000056, 4.667825212000025 ],
							[ 8.287128555000038, 4.667963989000043 ],
							[ 8.291810733000034, 4.667836201000057 ],
							[ 8.292911558000071, 4.668250582000042 ],
							[ 8.294423923000068, 4.669491781000033 ],
							[ 8.295248306000076, 4.670456395000031 ],
							[ 8.296486060000063, 4.671284681000031 ],
							[ 8.29827146100007, 4.67348905700004 ],
							[ 8.298958910000067, 4.673765629000059 ],
							[ 8.299921005000044, 4.674730293000039 ],
							[ 8.30019485400004, 4.675556639000035 ],
							[ 8.300191641000026, 4.676657201000069 ],
							[ 8.299637633000032, 4.678031897000039 ],
							[ 8.295778153000072, 4.679949788000044 ],
							[ 8.295363678000058, 4.680361730000072 ],
							[ 8.295500074000074, 4.681049837000046 ],
							[ 8.297291888000075, 4.680503356000031 ],
							[ 8.298119448000023, 4.679817182000022 ],
							[ 8.301703543000031, 4.678173561000051 ],
							[ 8.302945276000059, 4.677212699000052 ],
							[ 8.304324209000072, 4.676527992000047 ],
							[ 8.306253699000024, 4.675981526000044 ],
							[ 8.308045419000052, 4.675159466000025 ],
							[ 8.309563849000028, 4.673511986000051 ],
							[ 8.310117795000053, 4.672137275000068 ],
							[ 8.310120721000033, 4.671174540000038 ],
							[ 8.309847128000058, 4.67021087400002 ],
							[ 8.309852320000061, 4.667734610000025 ],
							[ 8.309577957000045, 4.667183890000047 ],
							[ 8.309582947000024, 4.665120572000035 ],
							[ 8.309175248000031, 4.662230468000075 ],
							[ 8.309181273000036, 4.659616951000032 ],
							[ 8.309735217000025, 4.65824223900006 ],
							[ 8.311530299000026, 4.656182328000057 ],
							[ 8.311944928000059, 4.655357847000062 ],
							[ 8.312495975000047, 4.655220985000028 ],
							[ 8.315116900000021, 4.653025118000073 ],
							[ 8.316634228000055, 4.652203074000056 ],
							[ 8.319118784000068, 4.649869446000025 ],
							[ 8.320082856000056, 4.649458448000075 ],
							[ 8.321669310000061, 4.648017397000046 ],
							[ 8.322289351000052, 4.647949674000074 ],
							[ 8.324358176000032, 4.646715657000072 ],
							[ 8.327393621000056, 4.643969991000063 ],
							[ 8.327944894000041, 4.643696316000046 ],
							[ 8.328399768000054, 4.642972641000028 ],
							[ 8.32851641600007, 4.642184483000051 ],
							[ 8.328638178000062, 4.641359314000056 ],
							[ 8.326528648000021, 4.638136925000026 ],
							[ 8.325481187000037, 4.636537537000038 ],
							[ 8.324657672000058, 4.635710746000029 ],
							[ 8.323422182000058, 4.633369468000069 ],
							[ 8.322186629000043, 4.631991835000065 ],
							[ 8.321088670000051, 4.630063531000076 ],
							[ 8.32040124100007, 4.629511825000066 ],
							[ 8.318895100000077, 4.625244118000069 ],
							[ 8.318414632000042, 4.624624270000027 ],
							[ 8.318217313000048, 4.620703207000076 ],
							[ 8.318632923000052, 4.619328519000021 ],
							[ 8.318920148000075, 4.613826319000054 ],
							[ 8.318370694000066, 4.61313722400007 ],
							[ 8.316856439000048, 4.612996559000067 ],
							[ 8.315478592000034, 4.613543970000023 ],
							[ 8.314101196000024, 4.613541601000065 ],
							[ 8.313550982000038, 4.613265019000039 ],
							[ 8.310796761000063, 4.613259818000074 ],
							[ 8.31010912000005, 4.612845939000067 ],
							[ 8.307911164000075, 4.61050270800007 ],
							[ 8.30694683400003, 4.610500801000057 ],
							[ 8.30639485100005, 4.611462622000033 ],
							[ 8.306523592000076, 4.61531496300006 ],
							[ 8.305968371000063, 4.617102172000045 ],
							[ 8.30431274700004, 4.618611846000022 ],
							[ 8.302522577000047, 4.61874582400003 ],
							[ 8.30169698800006, 4.618469216000051 ],
							[ 8.300734465000062, 4.617504127000075 ],
							[ 8.29991128000006, 4.61626435200003 ],
							[ 8.298811942000043, 4.615437046000068 ],
							[ 8.298123478000036, 4.615160471000024 ],
							[ 8.296333342000025, 4.615294420000055 ],
							[ 8.295782305000046, 4.61515567500004 ],
							[ 8.294408678000025, 4.613777605000053 ],
							[ 8.293997729000068, 4.61267566500004 ],
							[ 8.294000407000055, 4.611575599000048 ],
							[ 8.294827248000047, 4.610889413000052 ],
							[ 8.29854468800005, 4.611171710000065 ],
							[ 8.297858947000066, 4.610069745000033 ],
							[ 8.295520725000074, 4.608827091000023 ],
							[ 8.29428215400003, 4.608824693000031 ],
							[ 8.293041115000051, 4.609234808000053 ],
							[ 8.291526885000053, 4.60909416800007 ],
							[ 8.29083901000007, 4.60881758000005 ],
							[ 8.289326905000053, 4.607576864000066 ],
							[ 8.288501617000065, 4.607162027000072 ],
							[ 8.287262232000046, 4.607297432000053 ],
							[ 8.28574449000007, 4.608394622000048 ],
							[ 8.286286091000022, 4.612522585000022 ],
							[ 8.285734336000075, 4.613347047000047 ],
							[ 8.28518303900006, 4.613346113000034 ],
							[ 8.284776729000043, 4.610456461000069 ],
							[ 8.284228394000024, 4.609217171000068 ],
							[ 8.284231335000072, 4.607703659000038 ],
							[ 8.285887010000067, 4.606194020000032 ],
							[ 8.287264608000044, 4.605783919000032 ],
							[ 8.288780210000027, 4.60578730800006 ],
							[ 8.291942607000067, 4.607994554000072 ],
							[ 8.296486600000037, 4.607728462000068 ],
							[ 8.298550787000067, 4.608558190000053 ],
							[ 8.300199275000068, 4.610212355000044 ],
							[ 8.300335161000021, 4.611450680000075 ],
							[ 8.299368688000072, 4.612274143000036 ],
							[ 8.297303457000055, 4.612269887000025 ],
							[ 8.296064276000038, 4.61199232000007 ],
							[ 8.295511948000069, 4.612541194000073 ],
							[ 8.29551149200006, 4.613367070000038 ],
							[ 8.298127195000063, 4.613509689000068 ],
							[ 8.299227572000063, 4.613787185000035 ],
							[ 8.300878806000071, 4.614891076000049 ],
							[ 8.30142687700004, 4.615992568000024 ],
							[ 8.302389107000067, 4.616819882000073 ],
							[ 8.303766517000042, 4.616822239000044 ],
							[ 8.304318286000068, 4.616273426000021 ],
							[ 8.304457531000025, 4.61544850000007 ],
							[ 8.304328242000054, 4.611596102000021 ],
							[ 8.306398425000054, 4.609261489000062 ],
							[ 8.307093386000076, 4.606373809000047 ],
							[ 8.30737504800004, 4.603623391000042 ],
							[ 8.30848983900006, 4.597710100000029 ],
							[ 8.308493454000029, 4.595784214000048 ],
							[ 8.307674204000023, 4.592756251000026 ],
							[ 8.305338654000025, 4.590137493000043 ],
							[ 8.304653191000057, 4.588898194000024 ],
							[ 8.302317686000038, 4.586279416000025 ],
							[ 8.301219997000032, 4.584626651000065 ],
							[ 8.299982212000032, 4.583661568000025 ],
							[ 8.298196975000053, 4.58159396800005 ],
							[ 8.297371733000034, 4.581180095000036 ],
							[ 8.293800471000054, 4.576908121000031 ],
							[ 8.291318140000044, 4.57855416600006 ],
							[ 8.289940079000075, 4.578963763000047 ],
							[ 8.287871252000059, 4.580335618000049 ],
							[ 8.286632510000061, 4.580470578000075 ],
							[ 8.283737367000072, 4.581977840000036 ],
							[ 8.282909448000055, 4.582664019000049 ],
							[ 8.278907490000051, 4.586645138000051 ],
							[ 8.277387378000071, 4.588981151000041 ],
							[ 8.277522866000027, 4.589806575000068 ],
							[ 8.277107963000049, 4.590768809000053 ],
							[ 8.275865343000021, 4.592004295000038 ],
							[ 8.274624652000057, 4.592826870000067 ],
							[ 8.273385321000035, 4.593236921000027 ],
							[ 8.272558967000066, 4.593098128000065 ],
							[ 8.270492449000074, 4.593231212000035 ],
							[ 8.268015672000047, 4.592538398000045 ],
							[ 8.266640567000024, 4.591710588000069 ],
							[ 8.264301194000041, 4.591155039000057 ],
							[ 8.263473770000076, 4.59184121200002 ],
							[ 8.263469622000059, 4.593354704000035 ],
							[ 8.264017018000061, 4.594731352000053 ],
							[ 8.264016197000046, 4.595418899000038 ],
							[ 8.26442701600007, 4.596245272000033 ],
							[ 8.266488324000022, 4.598175544000071 ],
							[ 8.267036286000064, 4.59927751400005 ],
							[ 8.267034393000074, 4.600515353000048 ],
							[ 8.266342422000037, 4.602026923000039 ],
							[ 8.264685857000075, 4.603674373000047 ],
							[ 8.260964379000029, 4.605455405000043 ],
							[ 8.259448795000026, 4.60572722400002 ],
							[ 8.255868828000075, 4.605582229000049 ],
							[ 8.255386545000022, 4.60592551700006 ],
							[ 8.254628866000076, 4.605717188000028 ],
							[ 8.252701088000038, 4.60612631500004 ],
							[ 8.249533599000074, 4.605981826000061 ],
							[ 8.246640925000065, 4.606388581000033 ],
							[ 8.24540152000003, 4.606248390000076 ],
							[ 8.242370854000058, 4.606792467000048 ],
							[ 8.239476178000075, 4.608161902000063 ],
							[ 8.238511854000024, 4.608160036000072 ],
							[ 8.23754950700004, 4.607470030000059 ],
							[ 8.235348901000066, 4.606640299000048 ],
							[ 8.232180554000024, 4.606908827000041 ],
							[ 8.231492750000029, 4.606357082000045 ],
							[ 8.231357627000023, 4.605393925000044 ],
							[ 8.231771537000043, 4.604981906000035 ],
							[ 8.232736426000031, 4.604708691000042 ],
							[ 8.234526819000052, 4.604712457000062 ],
							[ 8.236453184000027, 4.605267074000039 ],
							[ 8.238243297000054, 4.605408169000043 ],
							[ 8.240722659000028, 4.605138300000021 ],
							[ 8.243754154000044, 4.603906634000055 ],
							[ 8.24540827900006, 4.603497043000061 ],
							[ 8.252017146000071, 4.603648651000071 ],
							[ 8.253669579000075, 4.60351419400007 ],
							[ 8.254014748000031, 4.603171349000036 ],
							[ 8.254358524000054, 4.60351610500004 ],
							[ 8.255046919000051, 4.603517014000033 ],
							[ 8.256149195000035, 4.603106467000032 ],
							[ 8.257112672000062, 4.603521307000051 ],
							[ 8.259315538000067, 4.603525663000028 ],
							[ 8.262346133000051, 4.602982019000024 ],
							[ 8.263588007000067, 4.601883869000062 ],
							[ 8.264556184000071, 4.600510154000062 ],
							[ 8.264835367000046, 4.598309984000025 ],
							[ 8.263737810000066, 4.59665682800005 ],
							[ 8.261815034000051, 4.594589271000075 ],
							[ 8.260579823000057, 4.59238575300003 ],
							[ 8.260306399000058, 4.59142210400006 ],
							[ 8.260311112000068, 4.589358782000033 ],
							[ 8.259898909000071, 4.588945404000071 ],
							[ 8.258248483000045, 4.588116188000072 ],
							[ 8.256184791000067, 4.587699420000035 ],
							[ 8.254535771000064, 4.586182586000064 ],
							[ 8.253709738000055, 4.585906034000061 ],
							[ 8.251778414000057, 4.58727741000007 ],
							[ 8.249983624000038, 4.589474657000039 ],
							[ 8.249572844000056, 4.588648804000059 ],
							[ 8.250401667000062, 4.587274542000046 ],
							[ 8.250540743000045, 4.58658740900006 ],
							[ 8.251093956000034, 4.585625656000047 ],
							[ 8.251784285000042, 4.584938989000023 ],
							[ 8.252611417000026, 4.584390651000035 ],
							[ 8.253851866000048, 4.583980062000023 ],
							[ 8.255090628000062, 4.584120259000031 ],
							[ 8.25742800200004, 4.585638532000075 ],
							[ 8.25935183100006, 4.587706078000053 ],
							[ 8.260176750000028, 4.587982652000051 ],
							[ 8.261003362000054, 4.58798457100005 ],
							[ 8.263759849000053, 4.58675192100003 ],
							[ 8.265414122000038, 4.585654764000026 ],
							[ 8.267481694000026, 4.584971435000057 ],
							[ 8.268305519000023, 4.585798265000051 ],
							[ 8.268303881000065, 4.586623675000055 ],
							[ 8.26775073400006, 4.587860574000047 ],
							[ 8.267747721000035, 4.589098467000042 ],
							[ 8.268296226000075, 4.590200418000052 ],
							[ 8.269120908000048, 4.590889919000062 ],
							[ 8.271046437000052, 4.591856946000064 ],
							[ 8.272148396000034, 4.591858872000046 ],
							[ 8.273801581000043, 4.591312415000061 ],
							[ 8.275182976000053, 4.589801778000037 ],
							[ 8.277257840000061, 4.58540391400004 ],
							[ 8.277811779000046, 4.583754073000023 ],
							[ 8.277954050000062, 4.581690783000056 ],
							[ 8.276029649000066, 4.58044908100004 ],
							[ 8.275065669000071, 4.580309391000071 ],
							[ 8.272041267000077, 4.578102105000028 ],
							[ 8.27053182800006, 4.575898184000039 ],
							[ 8.270396328000061, 4.574521999000069 ],
							[ 8.269711266000058, 4.573145399000055 ],
							[ 8.268612118000021, 4.572042474000057 ],
							[ 8.263658276000058, 4.570381624000049 ],
							[ 8.262281613000027, 4.570653422000021 ],
							[ 8.261452833000021, 4.571477435000077 ],
							[ 8.26020442500004, 4.575602066000044 ],
							[ 8.259927976000029, 4.576151355000036 ],
							[ 8.259376476000057, 4.576562867000064 ],
							[ 8.258136615000069, 4.576423145000035 ],
							[ 8.255798478000031, 4.575592516000029 ],
							[ 8.254835890000038, 4.574765189000061 ],
							[ 8.254150626000069, 4.573525905000054 ],
							[ 8.252913561000071, 4.572285155000031 ],
							[ 8.251675678000026, 4.571732503000021 ],
							[ 8.250298728000075, 4.571866981000028 ],
							[ 8.249470478000035, 4.572415812000031 ],
							[ 8.248644762000026, 4.57255123300007 ],
							[ 8.245472767000024, 4.574470976000043 ],
							[ 8.243465209000021, 4.574944230000028 ],
							[ 8.242351489000043, 4.577130815000032 ],
							[ 8.241624964000039, 4.578207872000064 ],
							[ 8.242431358000033, 4.579829673000063 ],
							[ 8.242430797000054, 4.580379966000066 ],
							[ 8.241809720000049, 4.580722356000024 ],
							[ 8.241193734000035, 4.579414371000041 ],
							[ 8.240094936000048, 4.578174124000043 ],
							[ 8.239545679000059, 4.577209958000026 ],
							[ 8.239273745000048, 4.575833809000073 ],
							[ 8.239276871000072, 4.574595934000058 ],
							[ 8.241480965000051, 4.574187750000021 ],
							[ 8.243936237000071, 4.573233798000047 ],
							[ 8.244650998000054, 4.572956068000053 ],
							[ 8.247958944000061, 4.571036835000029 ],
							[ 8.249201866000021, 4.569663986000023 ],
							[ 8.25085526600003, 4.568979268000021 ],
							[ 8.251682388000063, 4.568981190000045 ],
							[ 8.252370159000066, 4.569257741000058 ],
							[ 8.253880590000051, 4.571186528000055 ],
							[ 8.256215918000066, 4.573392392000073 ],
							[ 8.257313646000057, 4.575183401000061 ],
							[ 8.258277903000021, 4.575185292000072 ],
							[ 8.258691730000066, 4.574773298000025 ],
							[ 8.259525500000052, 4.571473576000074 ],
							[ 8.259529094000072, 4.569685 ],
							[ 8.258980922000035, 4.568446170000072 ],
							[ 8.257881249000036, 4.567618350000032 ],
							[ 8.256504874000029, 4.567203020000022 ],
							[ 8.255406335000032, 4.566100130000052 ],
							[ 8.25416873000006, 4.565409660000057 ],
							[ 8.248770666000041, 4.565643738000063 ],
							[ 8.248420456000076, 4.565990160000069 ],
							[ 8.246317907000048, 4.566218857000024 ],
							[ 8.244114984000021, 4.566351416000032 ],
							[ 8.243012265000061, 4.566486861000044 ],
							[ 8.24080933700003, 4.566620364000073 ],
							[ 8.23915566200003, 4.567166800000052 ],
							[ 8.236397526000076, 4.568674566000027 ],
							[ 8.234468210000045, 4.569358343000033 ],
							[ 8.233918121000045, 4.569356909000021 ],
							[ 8.232266694000032, 4.569078423000065 ],
							[ 8.230340240000032, 4.568386567000061 ],
							[ 8.228965886000026, 4.567283143000054 ],
							[ 8.22924209100006, 4.566596031000074 ],
							[ 8.229930988000035, 4.566596988000072 ],
							[ 8.230343972000071, 4.566873073000068 ],
							[ 8.232133610000062, 4.566877387000034 ],
							[ 8.234749815000043, 4.566882617000033 ],
							[ 8.237231199000064, 4.565787306000061 ],
							[ 8.238196548000076, 4.56496381900007 ],
							[ 8.241366499000037, 4.564007266000033 ],
							[ 8.242605203000039, 4.563872331000027 ],
							[ 8.243018193000069, 4.564148402000058 ],
							[ 8.245221104000052, 4.56401538700004 ],
							[ 8.246598015000075, 4.564156085000036 ],
							[ 8.248663828000076, 4.564022543000021 ],
							[ 8.249766264000073, 4.563749769000026 ],
							[ 8.251833192000049, 4.563066009000067 ],
							[ 8.254196061000073, 4.563295599000071 ],
							[ 8.254658236000068, 4.563144605000048 ],
							[ 8.255549282000061, 4.563761710000051 ],
							[ 8.257198481000046, 4.565415871000027 ],
							[ 8.260359790000052, 4.567623114000071 ],
							[ 8.26104811600004, 4.567625049000071 ],
							[ 8.261736188000043, 4.568038966000074 ],
							[ 8.265039634000061, 4.568871043000058 ],
							[ 8.26669135800006, 4.569012165000061 ],
							[ 8.269580165000036, 4.570118412000056 ],
							[ 8.271366829000044, 4.571635232000062 ],
							[ 8.272189836000052, 4.572875018000047 ],
							[ 8.272596313000065, 4.576177599000061 ],
							[ 8.273142604000043, 4.577829371000064 ],
							[ 8.27424346600003, 4.578657147000058 ],
							[ 8.277410699000029, 4.578525523000053 ],
							[ 8.278925081000068, 4.578803997000023 ],
							[ 8.280024265000065, 4.579631808000045 ],
							[ 8.280849181000065, 4.579908371000045 ],
							[ 8.282227255000066, 4.579498791000049 ],
							[ 8.283607752000023, 4.578125466000074 ],
							[ 8.284023196000021, 4.577163226000039 ],
							[ 8.284851081000056, 4.576202387000023 ],
							[ 8.287195240000074, 4.574831477000032 ],
							[ 8.291465931000062, 4.573876864000056 ],
							[ 8.29339598100006, 4.573055273000023 ],
							[ 8.294634984000027, 4.573058138000022 ],
							[ 8.296702433000064, 4.572374328000024 ],
							[ 8.297529199000053, 4.571687689000044 ],
							[ 8.297533207000072, 4.570174715000064 ],
							[ 8.294511124000053, 4.566729534000046 ],
							[ 8.291766019000022, 4.562459453000031 ],
							[ 8.291493658000036, 4.561496255000066 ],
							[ 8.290944069000034, 4.560944538000058 ],
							[ 8.289705044000073, 4.560391921000075 ],
							[ 8.288744092000059, 4.559289456000045 ],
							[ 8.287099609000052, 4.555709369000056 ],
							[ 8.284493725000061, 4.551301933000047 ],
							[ 8.284219718000031, 4.550338281000052 ],
							[ 8.284222390000025, 4.548962595000035 ],
							[ 8.284499868000069, 4.548138170000072 ],
							[ 8.285879973000021, 4.546902163000027 ],
							[ 8.286021404000053, 4.545251870000072 ],
							[ 8.283966187000033, 4.54057072300003 ],
							[ 8.283281373000023, 4.538505559000043 ],
							[ 8.281087802000059, 4.534512043000063 ],
							[ 8.279851060000055, 4.533409119000055 ],
							[ 8.278062629000033, 4.532579903000055 ],
							[ 8.273243666000042, 4.532294753000031 ],
							[ 8.270213914000067, 4.53242635600003 ],
							[ 8.268837936000068, 4.532148384000038 ],
							[ 8.267186103000029, 4.532145026000023 ],
							[ 8.264981444000057, 4.532691008000029 ],
							[ 8.263466989000051, 4.532825462000062 ],
							[ 8.261260941000046, 4.533783923000044 ],
							[ 8.259746480000047, 4.533918362000065 ],
							[ 8.256577389000029, 4.534874927000033 ],
							[ 8.250654936000046, 4.535688408000055 ],
							[ 8.246659047000037, 4.536780347000047 ],
							[ 8.245970777000025, 4.536778923000043 ],
							[ 8.244041644000049, 4.537462696000034 ],
							[ 8.240047420000053, 4.538004882000052 ],
							[ 8.238944770000046, 4.538414972000055 ],
							[ 8.237016470000071, 4.538686297000027 ],
							[ 8.236327342000038, 4.539097783000045 ],
							[ 8.235501409000051, 4.539095883000073 ],
							[ 8.234398750000025, 4.539506441000071 ],
							[ 8.233297519000075, 4.539504050000062 ],
							[ 8.232331945000055, 4.539914613000064 ],
							[ 8.230955965000021, 4.539911747000076 ],
							[ 8.230128045000072, 4.540322760000038 ],
							[ 8.227786752000043, 4.540593128000069 ],
							[ 8.227098469000055, 4.54086684300006 ],
							[ 8.225858452000068, 4.540863969000043 ],
							[ 8.220899665000047, 4.54209185000002 ],
							[ 8.217868969000051, 4.542360770000073 ],
							[ 8.217180105000068, 4.542634021000026 ],
							[ 8.215802974000042, 4.54263116900006 ],
							[ 8.208502562000035, 4.543991550000044 ],
							[ 8.207401297000047, 4.543989191000037 ],
							[ 8.205197346000034, 4.544397373000038 ],
							[ 8.199827071000072, 4.544110790000047 ],
							[ 8.193080126000041, 4.544646254000043 ],
							[ 8.19225302500007, 4.544919948000029 ],
							[ 8.186606179000023, 4.545458304000022 ],
							[ 8.179721038000025, 4.545718185000055 ],
							[ 8.176139900000067, 4.546123512000065 ],
							[ 8.166639282000062, 4.54610298800003 ],
							[ 8.159302027000024, 4.545763333000025 ],
							[ 8.158974800000067, 4.544134636000024 ],
							[ 8.153811543000074, 4.543519005000064 ],
							[ 8.142910757000038, 4.544007767000039 ],
							[ 8.133559396000066, 4.542664526000067 ],
							[ 8.125006700000029, 4.542664541000022 ],
							[ 8.114060408000057, 4.541980288000047 ],
							[ 8.104497238000022, 4.543696887000067 ],
							[ 8.092508418000023, 4.54334878800006 ],
							[ 8.083856367000067, 4.543529997000064 ],
							[ 8.075256614000068, 4.543460849000041 ],
							[ 8.067193037000038, 4.542664521000063 ],
							[ 8.057614428000022, 4.541980275000071 ],
							[ 8.04769318700005, 4.540612238000051 ],
							[ 8.036291286000051, 4.540837769000063 ],
							[ 8.029973059000042, 4.541941145000067 ],
							[ 8.023061501000029, 4.54026985400003 ],
							[ 8.015194164000036, 4.540954106000072 ],
							[ 8.009911440000053, 4.541202067000029 ],
							[ 8.000188132000062, 4.538815023000041 ],
							[ 7.987561933000052, 4.539866915000061 ],
							[ 7.983378809000044, 4.53582285300007 ],
							[ 7.973457794000069, 4.535138585000027 ],
							[ 7.956695186000047, 4.535822873000029 ],
							[ 7.94882652900003, 4.53479625500006 ],
							[ 7.936556725000059, 4.537146077000045 ],
							[ 7.929683109000052, 4.536512840000057 ],
							[ 7.928985832000023, 4.533428198000024 ],
							[ 7.917353988000059, 4.530691138000066 ],
							[ 7.909143826000047, 4.531033527000034 ],
							[ 7.897170794000033, 4.528980721000039 ],
							[ 7.878012747000071, 4.525559909000037 ],
							[ 7.862276373000043, 4.526586046000034 ],
							[ 7.849517049000042, 4.525476923000042 ],
							[ 7.824886114000037, 4.52297022700003 ],
							[ 7.807140785000058, 4.519939891000035 ],
							[ 7.79797906500005, 4.518712047000065 ],
							[ 7.785371033000047, 4.518033016000061 ],
							[ 7.769924845000048, 4.514444818000072 ],
							[ 7.763629724000054, 4.51323983900005 ],
							[ 7.752752832000056, 4.511421209000048 ],
							[ 7.743596860000025, 4.509614975000034 ],
							[ 7.736148793000041, 4.508977895000044 ],
							[ 7.729286198000068, 4.507191180000063 ],
							[ 7.722423119000041, 4.505404 ],
							[ 7.714414028000022, 4.503608220000046 ],
							[ 7.70639200100004, 4.502965930000073 ],
							[ 7.69664493700003, 4.50288822300007 ],
							[ 7.690928695000025, 4.501110090000054 ],
							[ 7.68350487400005, 4.498164166000038 ],
							[ 7.677203176000035, 4.497536172000025 ],
							[ 7.664629981000076, 4.493393904000072 ],
							[ 7.661032939000052, 4.489958297000044 ],
							[ 7.654248551000023, 4.485718229000042 ],
							[ 7.64534419000006, 4.481478209000045 ],
							[ 7.634319815000026, 4.475753814000029 ],
							[ 7.62647511800003, 4.473845958000027 ],
							[ 7.623083261000033, 4.471725441000046 ],
							[ 7.615874860000076, 4.472149843000068 ],
							[ 7.608242363000045, 4.470029841000041 ],
							[ 7.601477413000055, 4.467884063000042 ],
							[ 7.597398291000047, 4.464844716000073 ],
							[ 7.595991411000057, 4.467421069000068 ],
							[ 7.593930454000031, 4.477109445000053 ],
							[ 7.592693426000039, 4.484324453000056 ],
							[ 7.590631859000041, 4.490302559000042 ],
							[ 7.587333648000026, 4.494837760000053 ],
							[ 7.580324988000029, 4.502052306000053 ],
							[ 7.573728837000033, 4.505969059000051 ],
							[ 7.568478177000031, 4.507408146000046 ],
							[ 7.559268567000061, 4.510947258000044 ],
							[ 7.552378161000036, 4.514445286000068 ],
							[ 7.546547744000065, 4.520805829000039 ],
							[ 7.541653349000057, 4.528679836000038 ],
							[ 7.539834274000043, 4.530477977000032 ],
							[ 7.537518237000029, 4.53276777800005 ],
							[ 7.53635508800005, 4.534490102000063 ],
							[ 7.534617196000056, 4.536207184000034 ],
							[ 7.532874170000071, 4.538502224000069 ],
							[ 7.53113055700004, 4.540795808000041 ],
							[ 7.529382368000029, 4.543667778000042 ],
							[ 7.52764495200006, 4.545384865000074 ],
							[ 7.526474937000046, 4.547684171000071 ],
							[ 7.523956079000072, 4.550619614000027 ],
							[ 7.524617913000043, 4.556251054000029 ],
							[ 7.527268540000023, 4.560557364000033 ],
							[ 7.529918042000077, 4.561220174000027 ],
							[ 7.532568660000038, 4.562876221000067 ],
							[ 7.534931953000068, 4.562181013000043 ],
							[ 7.53663487700004, 4.563926227000024 ],
							[ 7.538910940000051, 4.565676214000064 ],
							[ 7.541727338000044, 4.570892824000055 ],
							[ 7.542843647000041, 4.573788168000021 ],
							[ 7.543962222000062, 4.57668303600002 ],
							[ 7.545081370000048, 4.579576994000035 ],
							[ 7.54447318800004, 4.583034999000063 ],
							[ 7.543303156000036, 4.585334764000038 ],
							[ 7.542139315000043, 4.587057121000043 ],
							[ 7.539244120000035, 4.58991913400007 ],
							[ 7.537507104000042, 4.591636157000039 ],
							[ 7.535758152000028, 4.59450818800002 ],
							[ 7.534026195000024, 4.59564778500004 ],
							[ 7.532856059000039, 4.597947108000028 ],
							[ 7.531687033000026, 4.600245951000034 ],
							[ 7.530517970000062, 4.602546180000047 ],
							[ 7.529347792000067, 4.604845066000053 ],
							[ 7.528746235000028, 4.607726065000065 ],
							[ 7.528150328000038, 4.610029248000046 ],
							[ 7.527553308000051, 4.612332851000076 ],
							[ 7.526377951000029, 4.615210043000047 ],
							[ 7.525202031000049, 4.618084897000074 ],
							[ 7.524324804000059, 4.622284371000035 ],
							[ 7.521090483000023, 4.626502967000022 ],
							[ 7.519696512000053, 4.631034365000062 ],
							[ 7.519870572000059, 4.640446207000025 ],
							[ 7.52318217100003, 4.649509447000071 ],
							[ 7.523182349000024, 4.657178417000068 ],
							[ 7.520742187000053, 4.662581421000027 ],
							[ 7.518476485000065, 4.669727333000026 ],
							[ 7.517953110000064, 4.676873690000036 ],
							[ 7.520742091000045, 4.684716691000062 ],
							[ 7.525622435000059, 4.690816869000059 ],
							[ 7.527896955000074, 4.693136226000036 ],
							[ 7.530728941000064, 4.69662186100004 ],
							[ 7.53414112300004, 4.699534862000064 ],
							[ 7.536983988000031, 4.701867134000054 ],
							[ 7.53799744500003, 4.705980320000037 ],
							[ 7.540467824000075, 4.708141793000038 ],
							[ 7.542005488000029, 4.711906438000028 ],
							[ 7.545165440000062, 4.719104772000037 ],
							[ 7.547098214000073, 4.726191008000058 ],
							[ 7.545487678000029, 4.732310750000067 ],
							[ 7.543748824000033, 4.734041218000073 ],
							[ 7.541322010000044, 4.736161242000037 ],
							[ 7.536468937000052, 4.739396550000038 ],
							[ 7.530993419000026, 4.743906041000059 ],
							[ 7.526483997000071, 4.748737372000051 ],
							[ 7.523907441000063, 4.753246819000026 ],
							[ 7.523907048000069, 4.75614881000007 ],
							[ 7.524229474000037, 4.763231744000052 ],
							[ 7.52519557200003, 4.769351502000063 ],
							[ 7.525195343000064, 4.779658279000046 ],
							[ 7.521463563000054, 4.788544146000049 ],
							[ 7.516999367000039, 4.793008372000031 ],
							[ 7.510680361000027, 4.800349716000028 ],
							[ 7.506598243000042, 4.804257378000045 ],
							[ 7.501562138000054, 4.807817927000031 ],
							[ 7.498609290000047, 4.810249329000044 ],
							[ 7.496282067000038, 4.812717903000021 ],
							[ 7.494011146000048, 4.814063084000054 ],
							[ 7.492273965000038, 4.815780181000036 ],
							[ 7.49006634400007, 4.819096090000073 ],
							[ 7.488712479000071, 4.821804539000027 ],
							[ 7.491614951000031, 4.824431857000036 ],
							[ 7.493318330000022, 4.826177091000034 ],
							[ 7.494447050000076, 4.827918036000028 ],
							[ 7.496729148000043, 4.829091070000061 ],
							[ 7.497859039000048, 4.830831009000065 ],
							[ 7.499560784000039, 4.83257578100006 ],
							[ 7.500690675000044, 4.834317211000041 ],
							[ 7.500667789000033, 4.836625082000069 ],
							[ 7.500639160000048, 4.839510929000028 ],
							[ 7.498889889000054, 4.842381917000068 ],
							[ 7.497135984000067, 4.845830931000023 ],
							[ 7.497669439000049, 4.849874985000042 ],
							[ 7.498798823000072, 4.85161591800005 ],
							[ 7.499338029000057, 4.855082993000053 ],
							[ 7.498723807000033, 4.859118002000059 ],
							[ 7.495839840000031, 4.860826 ],
							[ 7.493530003000046, 4.862538812000025 ],
							[ 7.491219, 4.864252123000028 ],
							[ 7.490055160000054, 4.865973970000027 ],
							[ 7.48888501600004, 4.86827278800007 ],
							[ 7.487715980000075, 4.870572087000028 ],
							[ 7.486545806000038, 4.872870897000041 ],
							[ 7.485383042000024, 4.874592808000045 ],
							[ 7.484212835000051, 4.876893018000032 ],
							[ 7.481891325000049, 4.879758855000034 ],
							[ 7.480714535000061, 4.88268964100007 ],
							[ 7.487119389000043, 4.890804779000064 ],
							[ 7.487530675000073, 4.897884570000031 ],
							[ 7.485747957000058, 4.903185676000021 ],
							[ 7.480892846000074, 4.906262179000066 ],
							[ 7.477131710000037, 4.908759560000021 ],
							[ 7.478094792000036, 4.915512057000058 ],
							[ 7.478721020000023, 4.919539925000038 ],
							[ 7.480789877000063, 4.932847980000076 ],
							[ 7.482492011000033, 4.934593178000057 ],
							[ 7.484201036000059, 4.935760990000063 ],
							[ 7.487044857000058, 4.938093208000055 ],
							[ 7.488173894000056, 4.939833166000028 ],
							[ 7.488982718000045, 4.940661505000037 ],
							[ 7.489401086000044, 4.942880559000059 ],
							[ 7.489898474000029, 4.944349924000051 ],
							[ 7.490800193000041, 4.945568051000066 ],
							[ 7.491546117000041, 4.946785956000042 ],
							[ 7.491505949000043, 4.950826151000058 ],
							[ 7.492044108000073, 4.954292794000025 ],
							[ 7.491418717000045, 4.959482213000058 ],
							[ 7.48911398100006, 4.960618036000028 ],
							[ 7.486248108000041, 4.960595128000023 ],
							[ 7.483953706000023, 4.960577039000043 ],
							[ 7.482131434000053, 4.960928083000056 ],
							[ 7.481094109000026, 4.964214664000053 ],
							[ 7.480208315000027, 4.965538116000062 ],
							[ 7.47756623500004, 4.965527092000059 ],
							[ 7.47302402400004, 4.965508097000054 ],
							[ 7.469545917000062, 4.967963208000072 ],
							[ 7.466083111000046, 4.970242982000059 ],
							[ 7.463192906000074, 4.972528952000062 ],
							[ 7.46258202000007, 4.973333267000044 ],
							[ 7.467827083000032, 4.978315273000021 ],
							[ 7.468256488000065, 4.980971362000048 ],
							[ 7.467803142000037, 4.984066079000058 ],
							[ 7.465689644000065, 4.986772593000069 ],
							[ 7.471143247000043, 4.995911145000036 ],
							[ 7.474635123000041, 4.99725389300005 ],
							[ 7.477856322000036, 4.997940565000022 ],
							[ 7.480381708000039, 4.996567230000039 ],
							[ 7.483599384000058, 4.996338380000054 ],
							[ 7.485214278000058, 4.998169447000066 ],
							[ 7.487753073000022, 5.001602172000048 ],
							[ 7.489138671000035, 5.003661606000037 ],
							[ 7.490524657000037, 5.006178856000076 ],
							[ 7.491908771000055, 5.007781027000021 ],
							[ 7.504658329000051, 5.012110206000045 ],
							[ 7.506365535000043, 5.013278050000054 ],
							[ 7.507490304000044, 5.015594997000051 ],
							[ 7.508607695000023, 5.018489816000056 ],
							[ 7.509726226000055, 5.021385210000062 ],
							[ 7.51197363700004, 5.026020052000035 ],
							[ 7.511933116000023, 5.030059812000047 ],
							[ 7.509670261000053, 5.034508663000054 ],
							[ 7.512274626000021, 5.036597227000073 ],
							[ 7.515445774000057, 5.038217556000063 ],
							[ 7.517972272000065, 5.037302045000047 ],
							[ 7.520499534000066, 5.036615355000038 ],
							[ 7.522722189000035, 5.036539099000038 ],
							[ 7.525625338000054, 5.037096954000049 ],
							[ 7.527912822000076, 5.037692100000072 ],
							[ 7.528949023000052, 5.038576639000041 ],
							[ 7.528321639000069, 5.044921129000045 ],
							[ 7.530072607000022, 5.04758246800003 ],
							[ 7.531024827000067, 5.048933520000048 ],
							[ 7.525101949000032, 5.050471310000034 ],
							[ 7.518901964000065, 5.053178801000058 ],
							[ 7.520286861000045, 5.054945007000072 ],
							[ 7.524289296000063, 5.056130887000052 ],
							[ 7.525997891000031, 5.057299124000053 ],
							[ 7.529425920000051, 5.058481217000065 ],
							[ 7.532269834000033, 5.060812994000059 ],
							[ 7.535682032000068, 5.063725917000056 ],
							[ 7.537952123000025, 5.066052881000076 ],
							[ 7.540216044000033, 5.068956859000025 ],
							[ 7.54133495800005, 5.071852224000054 ],
							[ 7.541885103000027, 5.07416485400006 ],
							[ 7.542996709000022, 5.077637236000044 ],
							[ 7.544672082000034, 5.082266821000076 ],
							[ 7.545795048000059, 5.08458519200002 ],
							[ 7.546906724000053, 5.088057081000045 ],
							[ 7.54631074100007, 5.090361096000038 ],
							[ 7.544569094000053, 5.092655188000037 ],
							[ 7.54282517200005, 5.09495017100005 ],
							[ 7.541077266000059, 5.097821217000046 ],
							[ 7.539912918000027, 5.099544063000053 ],
							[ 7.538170081000032, 5.101838102000045 ],
							[ 7.536415290000036, 5.105287075000035 ],
							[ 7.534661041000049, 5.108735097000022 ],
							[ 7.534063821000075, 5.111039180000034 ],
							[ 7.533444985000074, 5.115651118000073 ],
							[ 7.533966754000062, 5.12085006500007 ],
							[ 7.533933266000076, 5.124311939000052 ],
							[ 7.533312728000055, 5.128923869000062 ],
							[ 7.533284901000059, 5.131810177000034 ],
							[ 7.533233252000059, 5.137003926000034 ],
							[ 7.533772075000059, 5.14047098900005 ],
							[ 7.532591102000026, 5.143924210000023 ],
							[ 7.531415211000024, 5.146801026000048 ],
							[ 7.530807087000028, 5.15025902900004 ],
							[ 7.529046889000028, 5.154283991000057 ],
							[ 7.527884006000022, 5.156005894000032 ],
							[ 7.528978678000044, 5.16121004200005 ],
							[ 7.533421826000051, 5.175673963000065 ],
							[ 7.539041295000061, 5.187262972000042 ],
							[ 7.539574026000025, 5.191307047000066 ],
							[ 7.540123766000022, 5.193620167000063 ],
							[ 7.539523010000039, 5.196500789000027 ],
							[ 7.540072761000033, 5.198812909000026 ],
							[ 7.540033079000068, 5.202853152000046 ],
							[ 7.540572066000038, 5.206319772000029 ],
							[ 7.540543165000031, 5.20920607000005 ],
							[ 7.54050915800002, 5.212668007000048 ],
							[ 7.541030040000066, 5.217866848000028 ],
							[ 7.541552061000061, 5.223065840000061 ],
							[ 7.541518078000024, 5.226528203000044 ],
							[ 7.541489186000035, 5.229413942000065 ],
							[ 7.542028222000056, 5.232881113000076 ],
							[ 7.54141383800004, 5.236916073000032 ],
							[ 7.541390611000054, 5.239223934000051 ],
							[ 7.541930228000069, 5.242691958000023 ],
							[ 7.541901894000034, 5.245576847000052 ],
							[ 7.541873001000056, 5.248463160000028 ],
							[ 7.541839004000053, 5.251924926000072 ],
							[ 7.542372428000022, 5.25596999000004 ],
							[ 7.54349591700003, 5.258286948000034 ],
							[ 7.545766150000077, 5.260613975000069 ],
							[ 7.548030740000058, 5.263517884000066 ],
							[ 7.549722291000023, 5.266417945000057 ],
							[ 7.55198695200005, 5.269320995000044 ],
							[ 7.553099265000071, 5.272792826000057 ],
							[ 7.553071011000043, 5.275679121000053 ],
							[ 7.55189486300003, 5.278554936000035 ],
							[ 7.551299154000048, 5.280858966000039 ],
							[ 7.549555507000036, 5.28315404600005 ],
							[ 7.547227981000049, 5.286598221000077 ],
							[ 7.545491070000026, 5.288314834000062 ],
							[ 7.543169133000049, 5.291182088000028 ],
							[ 7.540279143000021, 5.293467044000067 ],
							[ 7.538529697000058, 5.296339095000064 ],
							[ 7.535635089000039, 5.299201043000039 ],
							[ 7.534471206000035, 5.300922884000045 ],
							[ 7.535015956000052, 5.30381303200005 ],
							[ 7.537848226000051, 5.307299158000035 ],
							[ 7.540698108000072, 5.309052894000047 ],
							[ 7.543559334000065, 5.309654243000068 ],
							[ 7.546436992000054, 5.308523197000056 ],
							[ 7.549321429000031, 5.306815088000064 ],
							[ 7.551620249000052, 5.30625578300004 ],
							[ 7.556207149000045, 5.306293035000067 ],
							[ 7.559055985000043, 5.308046771000022 ],
							[ 7.560764730000074, 5.309215961000064 ],
							[ 7.562449819000051, 5.312692147000064 ],
							[ 7.564691795000044, 5.317903934000071 ],
							[ 7.56638315500004, 5.320804040000041 ],
							[ 7.568091988000049, 5.321971936000068 ],
							[ 7.572082865000027, 5.324313170000039 ],
							[ 7.575521937000076, 5.324339888000054 ],
							[ 7.578973931000064, 5.323214068000027 ],
							[ 7.581852140000024, 5.322083022000072 ],
							[ 7.583015866000039, 5.320361179000031 ],
							[ 7.587619723000046, 5.31866694200005 ],
							[ 7.591633610000031, 5.318698844000039 ],
							[ 7.595057008000026, 5.320457904000023 ],
							[ 7.596764799000027, 5.321627198000044 ],
							[ 7.59903631800006, 5.323953188000075 ],
							[ 7.601316752000059, 5.325126214000022 ],
							[ 7.603020148000041, 5.326870972000052 ],
							[ 7.606465946000071, 5.326322063000021 ],
							[ 7.607642375000069, 5.323445801000048 ],
							[ 7.609962882000048, 5.32057913500006 ],
							[ 7.612835993000033, 5.320025022000038 ],
							[ 7.615707968000038, 5.319470881000029 ],
							[ 7.618012880000038, 5.318335116000071 ],
							[ 7.622032871000044, 5.317791050000039 ],
							[ 7.62546689900006, 5.318396047000022 ],
							[ 7.628889900000047, 5.320155195000041 ],
							[ 7.630598933000044, 5.321321946000069 ],
							[ 7.634605630000067, 5.321931822000067 ],
							[ 7.638045821000048, 5.321959936000042 ],
							[ 7.64147497700003, 5.323142071000063 ],
							[ 7.645482841000046, 5.323750980000057 ],
							[ 7.648910909000051, 5.324934059000043 ],
							[ 7.65061395500004, 5.326679296000066 ],
							[ 7.652317037000046, 5.328423951000048 ],
							[ 7.654581760000042, 5.331327891000058 ],
							[ 7.656283755000061, 5.333073098000057 ],
							[ 7.658549109000035, 5.335977016000072 ],
							[ 7.660807893000026, 5.339457959000072 ],
							[ 7.662517112000046, 5.340625820000071 ],
							[ 7.663645957000028, 5.342367205000073 ],
							[ 7.665349156000048, 5.344111946000055 ],
							[ 7.666473130000043, 5.346429776000036 ],
							[ 7.667591076000065, 5.349325201000056 ],
							[ 7.668140736000055, 5.351636853000059 ],
							[ 7.669821012000057, 5.355690970000069 ],
							[ 7.670939033000025, 5.35858582700007 ],
							[ 7.672624835000022, 5.36206190300004 ],
							[ 7.673742878000041, 5.364956900000038 ],
							[ 7.675989927000046, 5.369593071000054 ],
							[ 7.676528758000075, 5.373060169000041 ],
							[ 7.677056121000021, 5.377680778000069 ],
							[ 7.677607003000048, 5.379993956000021 ],
							[ 7.677594726000052, 5.38121641400005 ],
							[ 7.675673508000045, 5.382869672000027 ],
							[ 7.671622537000076, 5.384331493000047 ],
							[ 7.667942727000025, 5.384687297000028 ],
							[ 7.662426939000056, 5.384297912000022 ],
							[ 7.655806592000033, 5.384273593000046 ],
							[ 7.650654474000021, 5.384992863000036 ],
							[ 7.644403230000023, 5.384600634000037 ],
							[ 7.641642711000031, 5.38366587400003 ],
							[ 7.639742615000046, 5.381997148000039 ],
							[ 7.636893955000062, 5.38024179100006 ],
							[ 7.633470928000065, 5.378482854000026 ],
							[ 7.631183405000058, 5.37788818100006 ],
							[ 7.628315960000066, 5.377863860000048 ],
							[ 7.626016811000056, 5.378423220000059 ],
							[ 7.624279854000065, 5.380139795000048 ],
							[ 7.623682737000024, 5.382443912000042 ],
							[ 7.624234416000036, 5.384757044000025 ],
							[ 7.625362668000037, 5.386496969000063 ],
							[ 7.626493178000032, 5.388237898000057 ],
							[ 7.627623125000071, 5.389978952000035 ],
							[ 7.62875196300007, 5.391718887000025 ],
							[ 7.629869769000038, 5.394614292000028 ],
							[ 7.630993674000024, 5.396932080000056 ],
							[ 7.631544297000062, 5.399244770000053 ],
							[ 7.632089382000061, 5.402134910000029 ],
							[ 7.633201152000026, 5.405607179000071 ],
							[ 7.633746281000072, 5.408496889000048 ],
							[ 7.634284197000056, 5.411963913000022 ],
							[ 7.634262111000055, 5.414272751000055 ],
							[ 7.63421567000006, 5.418889992000061 ],
							[ 7.63476634400007, 5.421203083000023 ],
							[ 7.636446002000071, 5.425255804000074 ],
							[ 7.638136745000054, 5.428155006000054 ],
							[ 7.639260786000023, 5.430472913000074 ],
							[ 7.640964291000046, 5.432219041000053 ],
							[ 7.642666675000044, 5.433962898000061 ],
							[ 7.646656989000064, 5.436304151000058 ],
							[ 7.64893277300007, 5.438054047000037 ],
							[ 7.651240689000076, 5.440418688000022 ],
							[ 7.653473996000059, 5.442708015000051 ],
							[ 7.655248310000047, 5.444222893000074 ],
							[ 7.656886173000032, 5.445620954000049 ],
							[ 7.660308807000035, 5.447380942000052 ],
							[ 7.663176232000069, 5.447402898000064 ],
							[ 7.66776151800002, 5.447441143000049 ],
							[ 7.669499167000026, 5.445723108000038 ],
							[ 7.671810166000057, 5.444009835000031 ],
							[ 7.674121142000047, 5.442296956000064 ],
							[ 7.675851580000028, 5.441156908000039 ],
							[ 7.678157063000072, 5.440022046000024 ],
							[ 7.682176027000025, 5.43947703300006 ],
							[ 7.685628200000053, 5.43835120600005 ],
							[ 7.689073778000022, 5.437800940000045 ],
							[ 7.691372643000022, 5.437243034000062 ],
							[ 7.693110132000072, 5.435524867000026 ],
							[ 7.695414955000047, 5.434390042000075 ],
							[ 7.697731201000067, 5.432099839000045 ],
							[ 7.700030002000062, 5.431541007000021 ],
							[ 7.700563732000035, 5.43558497600003 ],
							[ 7.700534946000062, 5.438470835000032 ],
							[ 7.699938767000049, 5.440774944000054 ],
							[ 7.699899137000045, 5.444815148000032 ],
							[ 7.70101597200005, 5.447709047000046 ],
							[ 7.701567109000052, 5.450022184000034 ],
							[ 7.70267910900003, 5.453494103000025 ],
							[ 7.703194959000029, 5.459270003000029 ],
							[ 7.703166176000025, 5.462154879000025 ],
							[ 7.703143964000049, 5.464464123000027 ],
							[ 7.703115181000044, 5.467348997000045 ],
							[ 7.703653856000074, 5.47081619000005 ],
							[ 7.703614247000075, 5.474855834000039 ],
							[ 7.704714963000072, 5.479482236000024 ],
							[ 7.704680222000036, 5.482944983000039 ],
							[ 7.704072047000068, 5.48640301000006 ],
							[ 7.702318126000023, 5.48985103800004 ],
							[ 7.699989599000048, 5.493295179000029 ],
							[ 7.697673555000051, 5.495584948000044 ],
							[ 7.694784048000031, 5.497869987000058 ],
							[ 7.693046826000057, 5.499587994000024 ],
							[ 7.691304173000049, 5.501883034000059 ],
							[ 7.690116944000067, 5.505912762000037 ],
							[ 7.691235008000035, 5.508808115000022 ],
							[ 7.691780173000041, 5.511697728000058 ],
							[ 7.692904251000073, 5.514016165000044 ],
							[ 7.694026670000028, 5.516333055000075 ],
							[ 7.695939132000035, 5.518277608000062 ],
							[ 7.70194088900007, 5.517550974000073 ],
							[ 7.704908579000062, 5.517574790000026 ],
							[ 7.707765159000076, 5.518753015000073 ],
							[ 7.71062605700007, 5.519352895000054 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Anambra',
				admin1Pcod: 'NG004',
				admin1RefN: 'Anambra',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 3.59596,
				Shape_Area: 0.392661
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.932539394000059, 6.71089829400006 ],
							[ 6.931667806000064, 6.698696598000026 ],
							[ 6.943869988000074, 6.706104761000063 ],
							[ 6.956507799000065, 6.710027345000071 ],
							[ 6.968709353000065, 6.711334323000074 ],
							[ 6.982655051000052, 6.713077483000063 ],
							[ 6.990934974000027, 6.713077481000028 ],
							[ 7.007494461000022, 6.708719805000044 ],
							[ 7.01664639300003, 6.706104736000043 ],
							[ 7.031898399000056, 6.696517346000064 ],
							[ 7.046279468000023, 6.687366084000075 ],
							[ 7.050637780000045, 6.68257243100004 ],
							[ 7.051172501000053, 6.682665409000037 ],
							[ 7.053705578000063, 6.689406579000035 ],
							[ 7.055682625000031, 6.696468307000032 ],
							[ 7.057693574000041, 6.697150668000063 ],
							[ 7.060386889000029, 6.695821831000046 ],
							[ 7.06510355000006, 6.692824863000055 ],
							[ 7.068487961000073, 6.687805995000076 ],
							[ 7.069753642000023, 6.685555692000037 ],
							[ 7.070247782000024, 6.685623298000053 ],
							[ 7.090293857000063, 6.677343031000021 ],
							[ 7.101624439000034, 6.675600191000058 ],
							[ 7.116877191000071, 6.666448697000021 ],
							[ 7.129078863000075, 6.659475804000067 ],
							[ 7.132565537000062, 6.652067663000025 ],
							[ 7.131693949000066, 6.643351511000049 ],
							[ 7.128642964000051, 6.635507593000057 ],
							[ 7.124720942000067, 6.625920174000044 ],
							[ 7.117748642000038, 6.615461401000061 ],
							[ 7.115569573000073, 6.603259628000046 ],
							[ 7.109468375000063, 6.593236367000031 ],
							[ 7.092037194000056, 6.575804765000044 ],
							[ 7.088551141000039, 6.568832514000064 ],
							[ 7.084193176000042, 6.562295389000042 ],
							[ 7.07809209800007, 6.557501900000034 ],
							[ 7.063275424000039, 6.554887376000067 ],
							[ 7.055431315000021, 6.545299889000034 ],
							[ 7.048458488000051, 6.527432393000026 ],
							[ 7.046279296000023, 6.512179937000042 ],
							[ 7.046279534000064, 6.500849774000073 ],
							[ 7.048458446000041, 6.485161357000038 ],
							[ 7.049659200000065, 6.474356678000049 ],
							[ 7.04540836700005, 6.471216269000024 ],
							[ 7.036256294000054, 6.473830641000063 ],
							[ 7.033206060000055, 6.466858477000073 ],
							[ 7.032770149000044, 6.456835158000047 ],
							[ 7.032770250000056, 6.45160566800007 ],
							[ 7.02971976200007, 6.447247925000056 ],
							[ 7.027468291000048, 6.44356334500003 ],
							[ 7.029937764000067, 6.442890240000054 ],
							[ 7.038653374000035, 6.44027509600005 ],
							[ 7.064800695000031, 6.437224748000062 ],
							[ 7.088769092000064, 6.430252163000034 ],
							[ 7.096612877000041, 6.422408098000062 ],
							[ 7.115351573000055, 6.40149013100006 ],
							[ 7.127989697000032, 6.386673554000026 ],
							[ 7.13583365900007, 6.372728214000063 ],
							[ 7.141934860000049, 6.359654957000032 ],
							[ 7.147382198000059, 6.343094887000063 ],
							[ 7.15086840400005, 6.334379242000068 ],
							[ 7.154354659000035, 6.32130567400003 ],
							[ 7.159148139000024, 6.31476877700004 ],
							[ 7.162199144000056, 6.304745706000062 ],
							[ 7.160283178000043, 6.303468697000028 ],
							[ 7.150432687000034, 6.296901629000047 ],
							[ 7.142588660000058, 6.289057341000046 ],
							[ 7.145202978000043, 6.274676266000029 ],
							[ 7.149125043000026, 6.262474604000033 ],
							[ 7.146946453000055, 6.252451446000066 ],
							[ 7.146946298000046, 6.244607002000066 ],
							[ 7.147720337000067, 6.242456490000052 ],
							[ 7.150997620000055, 6.232611678000069 ],
							[ 7.15699803800004, 6.230040052000049 ],
							[ 7.172753243000045, 6.233979195000074 ],
							[ 7.18929633700003, 6.239493291000031 ],
							[ 7.19953672500003, 6.234767014000056 ],
							[ 7.203475839000021, 6.218224035000048 ],
							[ 7.204263785000023, 6.208770757000025 ],
							[ 7.208989987000052, 6.202468801000066 ],
							[ 7.218442882000033, 6.196166959000038 ],
							[ 7.223957403000043, 6.191440122000074 ],
							[ 7.22474534600002, 6.178836377000039 ],
							[ 7.221594272000061, 6.157567064000034 ],
							[ 7.222338921000073, 6.152850509000075 ],
							[ 7.219868881000025, 6.145625624000047 ],
							[ 7.217209553000032, 6.140911699000071 ],
							[ 7.215539829000022, 6.13956057300004 ],
							[ 7.214882506000038, 6.136871101000054 ],
							[ 7.215220958000032, 6.136201177000032 ],
							[ 7.217253605000053, 6.13184598600003 ],
							[ 7.220313498000053, 6.123130662000051 ],
							[ 7.22369025200004, 6.11811039600002 ],
							[ 7.229060675000028, 6.116793306000034 ],
							[ 7.24034550600004, 6.113837523000029 ],
							[ 7.248377822000066, 6.108726078000075 ],
							[ 7.249721012000066, 6.10648778500007 ],
							[ 7.254803285000037, 6.104719616000068 ],
							[ 7.259596892000047, 6.087724243000025 ],
							[ 7.263083362000032, 6.070728243000076 ],
							[ 7.268748767000034, 6.060705196000072 ],
							[ 7.27310662900004, 6.057219036000049 ],
							[ 7.289230234000058, 6.048939287000053 ],
							[ 7.30186843000007, 6.045017253000026 ],
							[ 7.310148180000056, 6.045017309000059 ],
							[ 7.328451034000068, 6.042402309000067 ],
							[ 7.339062708000029, 6.042149551000023 ],
							[ 7.338269349000029, 6.036199057000033 ],
							[ 7.333034455000075, 6.03144020600007 ],
							[ 7.322564487000022, 6.003838514000051 ],
							[ 7.318281539000054, 5.998603412000023 ],
							[ 7.301625355000056, 5.998127398000065 ],
							[ 7.283065735000037, 5.99860334300007 ],
							[ 7.27259575000005, 5.997651584000039 ],
							[ 7.264029873000027, 5.991465022000057 ],
							[ 7.259271255000044, 5.977664556000036 ],
							[ 7.254512400000067, 5.965291071000024 ],
							[ 7.25641590400005, 5.950538607000055 ],
							[ 7.260983948000046, 5.938931012000069 ],
							[ 7.259231650000061, 5.936359878000076 ],
							[ 7.258751946000075, 5.936006330000055 ],
							[ 7.258012096000073, 5.930633905000036 ],
							[ 7.257011851000073, 5.929621998000073 ],
							[ 7.251315195000075, 5.929930499000022 ],
							[ 7.24511943300007, 5.93129058300002 ],
							[ 7.24053628300004, 5.931609224000056 ],
							[ 7.237959899000032, 5.931913872000052 ],
							[ 7.235960533000025, 5.932501768000066 ],
							[ 7.232240626000021, 5.933101098000066 ],
							[ 7.228797557000064, 5.932838027000059 ],
							[ 7.226500008000073, 5.932566612000073 ],
							[ 7.223056669000073, 5.932303912000066 ],
							[ 7.220468934000053, 5.931747474000076 ],
							[ 7.21701825100007, 5.930910162000032 ],
							[ 7.215004405000059, 5.930349854000042 ],
							[ 7.212119695000069, 5.928934536000042 ],
							[ 7.209517582000046, 5.927230376000068 ],
							[ 7.207779538000068, 5.925806933000047 ],
							[ 7.206038139000043, 5.924097077000056 ],
							[ 7.202844695000067, 5.920961891000047 ],
							[ 7.201786613000024, 5.918901979000054 ],
							[ 7.200787189000039, 5.916957378000063 ],
							[ 7.199427520000029, 5.914527191000047 ],
							[ 7.188068514000065, 5.915858451000076 ],
							[ 7.182052270000042, 5.913142848000064 ],
							[ 7.17342977800007, 5.906905658000028 ],
							[ 7.172570131000043, 5.907392931000061 ],
							[ 7.170574146000035, 5.908268006000071 ],
							[ 7.168295301000057, 5.909431467000047 ],
							[ 7.164876866000043, 5.911177106000025 ],
							[ 7.162304479000056, 5.911769352000022 ],
							[ 7.159161590000053, 5.912652024000067 ],
							[ 7.156592257000057, 5.91353035700007 ],
							[ 7.154596755000057, 5.91440536600004 ],
							[ 7.152031032000025, 5.915571224000075 ],
							[ 7.14917906200003, 5.916739003000032 ],
							[ 7.146323163000034, 5.917619628000068 ],
							[ 7.142893674000049, 5.918504245000065 ],
							[ 7.138314215000037, 5.919109863000074 ],
							[ 7.135741730000063, 5.919702052000048 ],
							[ 7.132874927000046, 5.919721608000032 ],
							[ 7.130581425000059, 5.919737296000051 ],
							[ 7.128574730000025, 5.91975119600005 ],
							[ 7.12657495600007, 5.920339048000073 ],
							[ 7.124572149000073, 5.92064005900005 ],
							[ 7.121142857000052, 5.921524472000044 ],
							[ 7.118852953000044, 5.921827346000043 ],
							[ 7.116570523000064, 5.922704208000027 ],
							[ 7.112095743000054, 5.924706149000031 ],
							[ 7.10894152000003, 5.929221721000033 ],
							[ 7.106586113000048, 5.931223830000022 ],
							[ 7.103229117000069, 5.932549293000022 ],
							[ 7.101219094000044, 5.932538859000033 ],
							[ 7.097553441000059, 5.928826926000056 ],
							[ 7.095514696000066, 5.927449570000022 ],
							[ 7.093985067000062, 5.92773915500004 ],
							[ 7.091404994000072, 5.927756787000021 ],
							[ 7.088828508000063, 5.928061514000035 ],
							[ 7.085678994000034, 5.928370446000031 ],
							[ 7.083106064000049, 5.928962263000074 ],
							[ 7.080533143000025, 5.929553933000022 ],
							[ 7.077960492000045, 5.930145714000048 ],
							[ 7.07595729600007, 5.930446586000073 ],
							[ 7.07308712300005, 5.930179118000069 ],
							[ 7.070797403000029, 5.930481841000073 ],
							[ 7.068220599000028, 5.930787143000032 ],
							[ 7.065357550000044, 5.931093728000064 ],
							[ 7.062781295000036, 5.93139842100004 ],
							[ 7.060201193000069, 5.931415964000053 ],
							[ 7.056444716000044, 5.930613533000042 ],
							[ 7.053009060000022, 5.929456692000031 ],
							[ 7.047820229000024, 5.927195999000048 ],
							[ 7.045501277000028, 5.925202789000025 ],
							[ 7.043472694000059, 5.923494859000073 ],
							[ 7.041157619000046, 5.921788198000058 ],
							[ 7.039412612000035, 5.919791246000045 ],
							[ 7.037663504000022, 5.917507150000063 ],
							[ 7.035634999000024, 5.915799177000054 ],
							[ 7.03389684800004, 5.914375755000037 ],
							[ 7.031001218000029, 5.912099312000066 ],
							[ 7.025525684000058, 5.909841125000071 ],
							[ 7.020931180000048, 5.909298873000068 ],
							[ 7.017770897000048, 5.908746231000066 ],
							[ 7.014613578000024, 5.908481134000056 ],
							[ 7.009156345000065, 5.907657658000062 ],
							[ 7.00311197700006, 5.905193281000038 ],
							[ 6.999913641000035, 5.902267946000052 ],
							[ 6.998175691000029, 5.900844631000041 ],
							[ 6.996720733000075, 5.899132801000064 ],
							[ 6.994971712000051, 5.896848221000027 ],
							[ 6.993331375000025, 5.898157070000025 ],
							[ 6.986794996000071, 5.900771682000027 ],
							[ 6.97766621300002, 5.901716137000051 ],
							[ 6.970478995000065, 5.893391763000068 ],
							[ 6.96830869300004, 5.888088911000068 ],
							[ 6.952597411000056, 5.865515415000061 ],
							[ 6.946461216000046, 5.861512856000047 ],
							[ 6.94119487200004, 5.859278738000057 ],
							[ 6.932834734000039, 5.859672396000065 ],
							[ 6.930217902000038, 5.855689451000046 ],
							[ 6.921930196000062, 5.843297310000025 ],
							[ 6.91587977000006, 5.824304127000062 ],
							[ 6.915580713000054, 5.823798144000023 ],
							[ 6.911744570000053, 5.821933757000068 ],
							[ 6.908863059000055, 5.820805569000072 ],
							[ 6.907081442000049, 5.819555076000029 ],
							[ 6.903023802000064, 5.820749923000051 ],
							[ 6.897500911000066, 5.823690187000068 ],
							[ 6.893246056000066, 5.827062155000021 ],
							[ 6.891106408000041, 5.830870872000048 ],
							[ 6.888555479000047, 5.832554359000028 ],
							[ 6.886873399000024, 5.830421816000069 ],
							[ 6.885619920000067, 5.827442699000073 ],
							[ 6.885637033000023, 5.824470784000027 ],
							[ 6.886498832000029, 5.821928508000042 ],
							[ 6.890100382000071, 5.806800589000034 ],
							[ 6.889758212000061, 5.806298724000044 ],
							[ 6.889439004000053, 5.803717671000072 ],
							[ 6.888818824000055, 5.799990664000063 ],
							[ 6.888198361000036, 5.796263654000029 ],
							[ 6.886427454000057, 5.792257303000042 ],
							[ 6.885502387000031, 5.787097473000074 ],
							[ 6.885437140000022, 5.781931468000039 ],
							[ 6.885110852000025, 5.778776139000058 ],
							[ 6.884486759000026, 5.774762133000024 ],
							[ 6.884740691000047, 5.772177185000032 ],
							[ 6.884399878000067, 5.76787416600007 ],
							[ 6.884077037000054, 5.765006117000041 ],
							[ 6.883739368000022, 5.760990098000036 ],
							[ 6.88369950200007, 5.757833035000033 ],
							[ 6.883652781000023, 5.754102277000072 ],
							[ 6.883200226000042, 5.752176898000073 ],
							[ 6.884149104000073, 5.750146461000043 ],
							[ 6.884791364000023, 5.747325817000046 ],
							[ 6.884802207000064, 5.745443107000028 ],
							[ 6.88355746700006, 5.744180636000067 ],
							[ 6.87672016700003, 5.744140966000032 ],
							[ 6.874352422000072, 5.744120125000052 ],
							[ 6.872349237000037, 5.744421008000074 ],
							[ 6.870070450000071, 5.745585006000056 ],
							[ 6.868074320000062, 5.746460006000063 ],
							[ 6.865512266000053, 5.747912401000065 ],
							[ 6.863240810000036, 5.74965048100006 ],
							[ 6.861538970000026, 5.751097154000036 ],
							[ 6.860123502000022, 5.752541959000041 ],
							[ 6.858712259000072, 5.754273881000074 ],
							[ 6.855881558000021, 5.757163466000065 ],
							[ 6.853617062000069, 5.759475755000039 ],
							[ 6.85106984500004, 5.762076316000048 ],
							[ 6.849901296000041, 5.763119541000037 ],
							[ 6.846519917000023, 5.756932064000068 ],
							[ 6.84295979500007, 5.754222062000053 ],
							[ 6.836696521000022, 5.755081416000053 ],
							[ 6.83610552600004, 5.754879957000071 ],
							[ 6.836272563000023, 5.755614837000053 ],
							[ 6.828137737000077, 5.766234428000075 ],
							[ 6.819149725000045, 5.774247751000075 ],
							[ 6.814211457000056, 5.777803482000024 ],
							[ 6.809638469000049, 5.780314477000047 ],
							[ 6.802055334000045, 5.778885245000026 ],
							[ 6.793809097000064, 5.772606795000058 ],
							[ 6.742266052000048, 5.734919547000061 ],
							[ 6.695837938000068, 5.708676653000055 ],
							[ 6.669886308000059, 5.696277190000046 ],
							[ 6.671160460000067, 5.704226035000033 ],
							[ 6.661208439000063, 5.701823435000051 ],
							[ 6.652403696000022, 5.699925016000066 ],
							[ 6.648362530000043, 5.699899025000036 ],
							[ 6.643575156000054, 5.701710124000044 ],
							[ 6.632492589000037, 5.711216274000037 ],
							[ 6.627666925000028, 5.718920954000055 ],
							[ 6.626148363000027, 5.723635495000053 ],
							[ 6.633620147000045, 5.730035796000038 ],
							[ 6.64363002500005, 5.747325379000074 ],
							[ 6.654868205000071, 5.771530105000068 ],
							[ 6.654832784000064, 5.779374091000022 ],
							[ 6.651957546000062, 5.798600159000046 ],
							[ 6.650763381000047, 5.817113447000054 ],
							[ 6.657929892000027, 5.840404461000048 ],
							[ 6.660915718000069, 5.856529203000036 ],
							[ 6.661483310000051, 5.857277318000058 ],
							[ 6.670752555000035, 5.876863872000058 ],
							[ 6.673657674000026, 5.882408030000022 ],
							[ 6.678410282000073, 5.886490458000026 ],
							[ 6.678992042000061, 5.887015395000049 ],
							[ 6.67942905700005, 5.888180738000074 ],
							[ 6.688315906000071, 5.896851006000077 ],
							[ 6.697762055000055, 5.913572759000033 ],
							[ 6.703824931000042, 5.926799820000042 ],
							[ 6.708522742000071, 5.940709176000041 ],
							[ 6.71321303600007, 5.95669797000005 ],
							[ 6.715875235000055, 5.969895905000044 ],
							[ 6.716505887000039, 5.980997150000064 ],
							[ 6.719857347000072, 5.992121182000062 ],
							[ 6.724527769000076, 6.012269978000063 ],
							[ 6.726493782000034, 6.028930117000073 ],
							[ 6.725058084000068, 6.046256078000056 ],
							[ 6.716826974000071, 6.062137177000068 ],
							[ 6.709276120000027, 6.078023883000071 ],
							[ 6.713315993000037, 6.087072808000073 ],
							[ 6.722111218000066, 6.097548017000065 ],
							[ 6.737713803000076, 6.105999876000055 ],
							[ 6.749240949000068, 6.113724220000051 ],
							[ 6.755329970000048, 6.120709925000028 ],
							[ 6.755804128000023, 6.123312503000022 ],
							[ 6.756189289000076, 6.125429188000055 ],
							[ 6.757986118000076, 6.135294916000021 ],
							[ 6.760651216000042, 6.147799950000035 ],
							[ 6.764783864000037, 6.155804660000058 ],
							[ 6.766044970000053, 6.158247 ],
							[ 6.769402045000049, 6.16798400700003 ],
							[ 6.767301, 6.181836076000025 ],
							[ 6.762889290000032, 6.185385698000061 ],
							[ 6.753652130000035, 6.192818227000032 ],
							[ 6.742698245000042, 6.209370203000049 ],
							[ 6.735824150000042, 6.225956026000063 ],
							[ 6.731006011000034, 6.239092778000042 ],
							[ 6.726152927000044, 6.259857111000031 ],
							[ 6.725022284000033, 6.265763216000039 ],
							[ 6.723369132000073, 6.274396858000046 ],
							[ 6.721268270000053, 6.288249049000058 ],
							[ 6.719175951000068, 6.300021153000046 ],
							[ 6.718440888000032, 6.312497153000038 ],
							[ 6.717024898000034, 6.324967916000048 ],
							[ 6.714923785000053, 6.338820046000023 ],
							[ 6.712820165000039, 6.353365884000027 ],
							[ 6.707312043000059, 6.368576951000023 ],
							[ 6.699773846000028, 6.381689966000067 ],
							[ 6.697680877000039, 6.393462056000033 ],
							[ 6.694902806000073, 6.40661476300005 ],
							[ 6.692131024000048, 6.418381129000068 ],
							[ 6.688670228000035, 6.432221807000076 ],
							[ 6.685224016000063, 6.442594927000073 ],
							[ 6.68176513800006, 6.455741800000055 ],
							[ 6.67386379900006, 6.468333132000055 ],
							[ 6.671113580000053, 6.484836704000031 ],
							[ 6.668921030000035, 6.501234142000044 ],
							[ 6.664701088000072, 6.532356273000062 ],
							[ 6.683923278000066, 6.543120783000063 ],
							[ 6.717043026000056, 6.55009354300006 ],
							[ 6.743190251000044, 6.557501917000025 ],
							[ 6.76410807600007, 6.563602927000034 ],
							[ 6.793305883000073, 6.572318566000035 ],
							[ 6.808122609000065, 6.580162482000048 ],
							[ 6.80899378600003, 6.598030072000029 ],
							[ 6.808993724000061, 6.615897213000039 ],
							[ 6.80507183900005, 6.636815088000048 ],
							[ 6.802893090000055, 6.656425402000025 ],
							[ 6.800713994000034, 6.669934741000077 ],
							[ 6.798970612000062, 6.679521902000033 ],
							[ 6.800278270000035, 6.693467270000042 ],
							[ 6.806379261000075, 6.703054547000022 ],
							[ 6.819452883000054, 6.712641765000058 ],
							[ 6.833833697000046, 6.722228887000028 ],
							[ 6.84211338800003, 6.730508781000026 ],
							[ 6.853008387000045, 6.744454018000056 ],
							[ 6.859980975000042, 6.753170054000066 ],
							[ 6.868260994000025, 6.75534870000007 ],
							[ 6.878283991000046, 6.760578111000029 ],
							[ 6.883077673000059, 6.768422577000024 ],
							[ 6.88482102100005, 6.772780437000051 ],
							[ 6.896804837000047, 6.792390711000053 ],
							[ 6.912057266000033, 6.800670509000042 ],
							[ 6.923387972000057, 6.801977957000076 ],
							[ 6.931231952000076, 6.788032918000056 ],
							[ 6.932072656000059, 6.767693572000042 ],
							[ 6.933411167000031, 6.73530239400003 ],
							[ 6.932975178000049, 6.722664876000067 ],
							[ 6.932539394000059, 6.71089829400006 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Bauchi',
				admin1Pcod: 'NG005',
				admin1RefN: 'Bauchi',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 13.952005,
				Shape_Area: 4.011018
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 10.75125057200006, 12.461476795000067 ],
							[ 10.756147286000044, 12.391912148000074 ],
							[ 10.757374030000051, 12.375164975000075 ],
							[ 10.759832844000073, 12.369649358000061 ],
							[ 10.772905311000045, 12.340320340000062 ],
							[ 10.771476931000052, 12.315858470000023 ],
							[ 10.769991295000068, 12.279806824000048 ],
							[ 10.775008693000075, 12.235996847000024 ],
							[ 10.789224056000023, 12.201157972000033 ],
							[ 10.800815192000073, 12.165042444000051 ],
							[ 10.801942587000042, 12.128977861000067 ],
							[ 10.803121842000053, 12.103215492000061 ],
							[ 10.81068239800004, 12.046513708000077 ],
							[ 10.813161278000052, 12.01945719500003 ],
							[ 10.811809019000066, 12.010448766000025 ],
							[ 10.803920473000062, 12.001472113000034 ],
							[ 10.799883514000044, 11.978310580000027 ],
							[ 10.799755082000047, 11.952554271000054 ],
							[ 10.809905350000065, 11.922802081000043 ],
							[ 10.811398706000034, 11.920929748000049 ],
							[ 10.814746338000077, 11.916733491000059 ],
							[ 10.815753959000062, 11.915470410000069 ],
							[ 10.818574967000075, 11.906512737000071 ],
							[ 10.820359942000039, 11.902950832000045 ],
							[ 10.820440719000032, 11.902789644000052 ],
							[ 10.820084932000043, 11.90091416000007 ],
							[ 10.819983735000051, 11.899696836000032 ],
							[ 10.820075959000064, 11.899134324000045 ],
							[ 10.820453334000035, 11.898570397000071 ],
							[ 10.821686220000061, 11.898002295000026 ],
							[ 10.824400440000034, 11.897124722000058 ],
							[ 10.826160680000044, 11.895177886000056 ],
							[ 10.828867463000051, 11.893291036000051 ],
							[ 10.827925277000077, 11.892110323000054 ],
							[ 10.825635516000034, 11.885765354000057 ],
							[ 10.822249361000047, 11.877308707000054 ],
							[ 10.818371055000057, 11.866029441000023 ],
							[ 10.819298203000073, 11.860376049000024 ],
							[ 10.821662572000037, 11.855656893000059 ],
							[ 10.825449864000063, 11.849048169000071 ],
							[ 10.832587715000045, 11.843834841000046 ],
							[ 10.838789016000021, 11.84239171300004 ],
							[ 10.84594581500005, 11.840944293000064 ],
							[ 10.849763858000074, 11.840454669000053 ],
							[ 10.856430973000045, 11.836655144000076 ],
							[ 10.867870843000048, 11.832361456000058 ],
							[ 10.880251224000062, 11.825237672000071 ],
							[ 10.890842156000076, 11.822047051000027 ],
							[ 10.894555758000024, 11.820928078000065 ],
							[ 10.900754507000045, 11.819013855000037 ],
							[ 10.904074932000071, 11.814759906000063 ],
							[ 10.905938091000053, 11.805805842000041 ],
							[ 10.90538556000007, 11.791686465000055 ],
							[ 10.902470881000056, 11.782286209000063 ],
							[ 10.90243616600003, 11.775696203000052 ],
							[ 10.905268833000036, 11.769562148000034 ],
							[ 10.911916630000064, 11.762466594000045 ],
							[ 10.916664088000061, 11.75726389600004 ],
							[ 10.917349883000043, 11.751611452000077 ],
							[ 10.916277746000048, 11.729491446000054 ],
							[ 10.915690556000072, 11.708781397000052 ],
							[ 10.914939368000034, 11.702195052000036 ],
							[ 10.913949851000041, 11.69560937500006 ],
							[ 10.912482512000054, 11.689026688000069 ],
							[ 10.912917414000049, 11.681021725000051 ],
							[ 10.913360357000045, 11.674428639000041 ],
							[ 10.916192045000059, 11.668294457000059 ],
							[ 10.918576102000031, 11.664844096000024 ],
							[ 10.919510903000059, 11.664040344000057 ],
							[ 10.920479659000023, 11.662190588000044 ],
							[ 10.921050216000026, 11.658619611000063 ],
							[ 10.922989987000051, 11.65600037400003 ],
							[ 10.924539565000032, 11.651120294000066 ],
							[ 10.925110067000048, 11.647549882000021 ],
							[ 10.924519716000077, 11.644169681000051 ],
							[ 10.925089701000047, 11.640610008000067 ],
							[ 10.925470332000032, 11.635919597000054 ],
							[ 10.925849796000023, 11.631230321000032 ],
							[ 10.92642027200003, 11.627659901000072 ],
							[ 10.92564006300006, 11.623160259000031 ],
							[ 10.926210530000048, 11.619589838000024 ],
							[ 10.927559725000037, 11.61359036500005 ],
							[ 10.926969405000023, 11.610210160000065 ],
							[ 10.927149800000052, 11.604399797000042 ],
							[ 10.927330140000038, 11.598580397000035 ],
							[ 10.927900532000024, 11.595009967000067 ],
							[ 10.928279932000066, 11.590319545000057 ],
							[ 10.928660457000035, 11.585630245000061 ],
							[ 10.930199337000033, 11.580750745000046 ],
							[ 10.930780631000061, 11.577190413000039 ],
							[ 10.932130097000027, 11.571180166000033 ],
							[ 10.932499681000024, 11.56649033900004 ],
							[ 10.931719522000037, 11.561989579000056 ],
							[ 10.930930252000053, 11.557490549000022 ],
							[ 10.931300398000076, 11.552800160000061 ],
							[ 10.931879856000023, 11.549229665000041 ],
							[ 10.931090608000034, 11.544730057000038 ],
							[ 10.931670036000071, 11.54115956000004 ],
							[ 10.929909616000032, 11.537980366000056 ],
							[ 10.929119780000065, 11.533470015000034 ],
							[ 10.928330579000033, 11.528969841000048 ],
							[ 10.92773988600004, 11.525600359000066 ],
							[ 10.928319897000051, 11.522029861000021 ],
							[ 10.928890232000072, 11.518469574000051 ],
							[ 10.929660099000046, 11.516030365000063 ],
							[ 10.929270055000075, 11.513770089000047 ],
							[ 10.929650482000056, 11.509080189000031 ],
							[ 10.930029771000022, 11.504389757000069 ],
							[ 10.930600041000048, 11.500829464000049 ],
							[ 10.934673136000072, 11.495612968000046 ],
							[ 10.934170581000046, 11.49095365200003 ],
							[ 10.933675829000038, 11.486357594000026 ],
							[ 10.935610046000022, 11.481499693000046 ],
							[ 10.935020433000034, 11.478120022000041 ],
							[ 10.93559005000003, 11.474559719000069 ],
							[ 10.934800284000062, 11.470049935000077 ],
							[ 10.934209620000047, 11.466679319000036 ],
							[ 10.933429666000052, 11.462180218000071 ],
							[ 10.931162097000026, 11.459398011000076 ],
							[ 10.930074453000032, 11.446376686000065 ],
							[ 10.929817467000021, 11.44328570700003 ],
							[ 10.926375785000062, 11.42956803900006 ],
							[ 10.920556316000045, 11.411709790000032 ],
							[ 10.914262457000063, 11.394323960000065 ],
							[ 10.909942478000062, 11.389167823000037 ],
							[ 10.902288269000053, 11.384970071000055 ],
							[ 10.889864341000077, 11.380797031000043 ],
							[ 10.875068546000023, 11.378988868000022 ],
							[ 10.869177596000043, 11.377773851000029 ],
							[ 10.866485406000038, 11.375045922000027 ],
							[ 10.863545216000034, 11.372065550000059 ],
							[ 10.861263991000044, 11.369753631000037 ],
							[ 10.857199420000029, 11.369199948000073 ],
							[ 10.853309419000027, 11.367520373000048 ],
							[ 10.850380159000053, 11.364520049000021 ],
							[ 10.850370417000022, 11.357579991000023 ],
							[ 10.855609714000025, 11.353260262000049 ],
							[ 10.85851004500006, 11.349309841000036 ],
							[ 10.861809507000032, 11.347620276000043 ],
							[ 10.866080480000051, 11.344619487000045 ],
							[ 10.870425159000035, 11.344041625000045 ],
							[ 10.875189983000041, 11.339598587000069 ],
							[ 10.879480510000064, 11.338970052000036 ],
							[ 10.887249785000051, 11.335399809000023 ],
							[ 10.890359915000033, 11.332579803000044 ],
							[ 10.894820071000026, 11.330699827000046 ],
							[ 10.899089945000071, 11.327690178000068 ],
							[ 10.903559753000025, 11.325810637000075 ],
							[ 10.908029509000073, 11.323929895000049 ],
							[ 10.911330214000031, 11.322239879000051 ],
							[ 10.91439635200004, 11.318954309000048 ],
							[ 10.922516935000033, 11.317763074000027 ],
							[ 10.926670069000068, 11.313969467000049 ],
							[ 10.931130344000053, 11.312080587000025 ],
							[ 10.934430271000053, 11.310390367000025 ],
							[ 10.938130303000037, 11.310950216000037 ],
							[ 10.94163024900007, 11.310379890000036 ],
							[ 10.943559414000049, 11.307760529000063 ],
							[ 10.946669428000064, 11.304940026000054 ],
							[ 10.949970364000023, 11.303250234000075 ],
							[ 10.953470217000074, 11.302679815000033 ],
							[ 10.956959745000063, 11.302109952000023 ],
							[ 10.965700189000074, 11.297220408000044 ],
							[ 10.973469992000048, 11.293649468000069 ],
							[ 10.978130551000049, 11.292889568000021 ],
							[ 10.984379831000069, 11.296590990000027 ],
							[ 10.986970732000032, 11.295096216000047 ],
							[ 10.991746167000031, 11.295036141000026 ],
							[ 10.995439694000027, 11.295880210000064 ],
							[ 10.999139676000027, 11.296440007000058 ],
							[ 11.002829319000057, 11.296990232000041 ],
							[ 11.007315551000033, 11.295865316000061 ],
							[ 11.011630312000023, 11.29478367300004 ],
							[ 11.022173859000077, 11.292345923000028 ],
							[ 11.023275241000022, 11.285419535000074 ],
							[ 11.019844767000052, 11.277661254000066 ],
							[ 11.013649660000056, 11.263651850000031 ],
							[ 11.000440380000043, 11.236169949000043 ],
							[ 10.984993248000023, 11.21908465000007 ],
							[ 10.970719391000046, 11.201984559000039 ],
							[ 10.951748979000058, 11.18494312200005 ],
							[ 10.949586092000061, 11.182710784000051 ],
							[ 10.936313655000049, 11.169010057000037 ],
							[ 10.924461328000064, 11.158791649000023 ],
							[ 10.904365748000032, 11.14637381600005 ],
							[ 10.897173639000073, 11.132639829000027 ],
							[ 10.874583366000024, 11.106428383000036 ],
							[ 10.874420841000074, 11.105867002000025 ],
							[ 10.870926655000062, 11.09380038200004 ],
							[ 10.870060046000049, 11.092145267000035 ],
							[ 10.866634463000025, 11.088556517000029 ],
							[ 10.86161743200006, 11.086384337000027 ],
							[ 10.860675893000064, 11.084832407000022 ],
							[ 10.854758442000048, 11.082297390000065 ],
							[ 10.851632454000026, 11.080311110000025 ],
							[ 10.849206665000054, 11.078769946000023 ],
							[ 10.843479996000042, 11.077385903000049 ],
							[ 10.836810570000068, 11.077890217000061 ],
							[ 10.829277503000071, 11.080681961000039 ],
							[ 10.82376541800005, 11.085429357000066 ],
							[ 10.822697504000075, 11.086348258000044 ],
							[ 10.809939169000074, 11.101487384000052 ],
							[ 10.807864088000031, 11.103155859000026 ],
							[ 10.804117289000033, 11.106169875000035 ],
							[ 10.793634285000053, 11.114366714000028 ],
							[ 10.779591690000075, 11.119152889000077 ],
							[ 10.769010667000032, 11.118135610000024 ],
							[ 10.761953471000027, 11.117071923000026 ],
							[ 10.73237911800004, 11.096710380000047 ],
							[ 10.720526946000064, 11.086491648000049 ],
							[ 10.712209431000076, 11.077380100000028 ],
							[ 10.701483031000066, 11.062539285000071 ],
							[ 10.688335134000056, 11.040816397000071 ],
							[ 10.67766952900007, 11.031735641000068 ],
							[ 10.670490206000068, 11.019152645000077 ],
							[ 10.658723893000058, 11.016998456000067 ],
							[ 10.648155445000043, 11.017132028000049 ],
							[ 10.641072995000059, 11.013765368000065 ],
							[ 10.630407066000032, 11.004682673000048 ],
							[ 10.618556238000053, 10.994465733000027 ],
							[ 10.610226862000047, 10.984202639000046 ],
							[ 10.602949970000054, 10.96240533200006 ],
							[ 10.615489121000053, 10.926531839000063 ],
							[ 10.628272327000047, 10.913697137000042 ],
							[ 10.634615172000053, 10.903776059000052 ],
							[ 10.638669267000068, 10.897435983000037 ],
							[ 10.645520168000076, 10.878916590000074 ],
							[ 10.642976642000065, 10.860515646000067 ],
							[ 10.629999091000059, 10.854919222000035 ],
							[ 10.617764429000033, 10.858255106000058 ],
							[ 10.610171293000064, 10.867843479000044 ],
							[ 10.606733167000073, 10.875951957000041 ],
							[ 10.599773348000042, 10.884104978000039 ],
							[ 10.593951114000049, 10.888786517000028 ],
							[ 10.589277996000021, 10.891150345000028 ],
							[ 10.585755261000031, 10.891194243000029 ],
							[ 10.579859676000069, 10.888965492000068 ],
							[ 10.576300767000021, 10.885554290000073 ],
							[ 10.571445663000077, 10.870638791000033 ],
							[ 10.568975342000044, 10.859149115000037 ],
							[ 10.564107920000026, 10.843081448000021 ],
							[ 10.559265142000072, 10.829317821000075 ],
							[ 10.551976371000023, 10.80636895300006 ],
							[ 10.55065518400005, 10.792561591000037 ],
							[ 10.544029127000044, 10.781991436000055 ],
							[ 10.543870415000072, 10.781225329000051 ],
							[ 10.539553020000028, 10.773710794000067 ],
							[ 10.537638142000048, 10.771365357000036 ],
							[ 10.532866266000042, 10.769031186000063 ],
							[ 10.531493481000041, 10.768494424000039 ],
							[ 10.526629584000034, 10.763833160000047 ],
							[ 10.524259685000061, 10.761432464000052 ],
							[ 10.510170835000054, 10.740079153000067 ],
							[ 10.501793009000039, 10.725208050000049 ],
							[ 10.495824668000068, 10.716066131000048 ],
							[ 10.481477548000043, 10.692054768000048 ],
							[ 10.47868107000005, 10.687846800000045 ],
							[ 10.486557391000076, 10.682739507000065 ],
							[ 10.493204405000029, 10.679419151000047 ],
							[ 10.503181234000067, 10.676085752000063 ],
							[ 10.514791846000037, 10.665335445000039 ],
							[ 10.542110551000064, 10.628996739000058 ],
							[ 10.553619652000066, 10.595192370000063 ],
							[ 10.553559645000064, 10.581196480000074 ],
							[ 10.553527220000035, 10.573633487000052 ],
							[ 10.558776173000069, 10.560891129000026 ],
							[ 10.566887024000039, 10.550419946000034 ],
							[ 10.577321051000069, 10.537614647000055 ],
							[ 10.588965385000051, 10.528250630000059 ],
							[ 10.616939717000037, 10.508310498000071 ],
							[ 10.639189895000072, 10.50226893100006 ],
							[ 10.660314312000025, 10.500848635000068 ],
							[ 10.691982319000033, 10.496991210000033 ],
							[ 10.704875246000029, 10.494523828000069 ],
							[ 10.728274226000053, 10.486162251000053 ],
							[ 10.74937428800007, 10.482438923000075 ],
							[ 10.765764121000075, 10.47762317400003 ],
							[ 10.779782148000038, 10.470533600000067 ],
							[ 10.78491116500004, 10.46793907600005 ],
							[ 10.793504046000066, 10.459906597000042 ],
							[ 10.799287647000028, 10.451645171000052 ],
							[ 10.809185533000061, 10.434306933000073 ],
							[ 10.825754718000042, 10.420228679000047 ],
							[ 10.835104173000047, 10.402119417000051 ],
							[ 10.835403480000025, 10.399551459000065 ],
							[ 10.835072661000027, 10.395102730000076 ],
							[ 10.834033691000059, 10.381135805000042 ],
							[ 10.833864192000021, 10.365008313000033 ],
							[ 10.833729903000062, 10.352337625000075 ],
							[ 10.833607714000038, 10.340818453000054 ],
							[ 10.831162597000059, 10.331632592000062 ],
							[ 10.829841630000033, 10.317824085000041 ],
							[ 10.828570281000054, 10.308624483000074 ],
							[ 10.826063403000035, 10.293679250000025 ],
							[ 10.831813092000061, 10.282085241000061 ],
							[ 10.845745297000065, 10.266931792000037 ],
							[ 10.857159818000071, 10.235681753000051 ],
							[ 10.851166821000049, 10.224235437000061 ],
							[ 10.839326813000071, 10.215169927000034 ],
							[ 10.835629403000041, 10.204093893000049 ],
							[ 10.831428979000066, 10.198451128000045 ],
							[ 10.830290854000054, 10.196922052000048 ],
							[ 10.823796066000057, 10.188199092000048 ],
							[ 10.819883375000074, 10.181147750000036 ],
							[ 10.817226432000041, 10.169484957000066 ],
							[ 10.818640488000028, 10.157335986000021 ],
							[ 10.818917421000037, 10.144064714000024 ],
							[ 10.817753682000046, 10.141707337000071 ],
							[ 10.815174905000049, 10.12883376700006 ],
							[ 10.811818293000044, 10.122956056000021 ],
							[ 10.806177122000065, 10.115284114000076 ],
							[ 10.796998293000058, 10.110430849000068 ],
							[ 10.790633170000035, 10.109702843000036 ],
							[ 10.780173181000066, 10.110619589000066 ],
							[ 10.770280823000064, 10.11213114800006 ],
							[ 10.760685975000058, 10.113011538000023 ],
							[ 10.749928227000055, 10.114559443000076 ],
							[ 10.740652056000044, 10.11434441800003 ],
							[ 10.730204452000066, 10.114681068000039 ],
							[ 10.718596313000035, 10.114992284000039 ],
							[ 10.700672160000067, 10.112256047000074 ],
							[ 10.693126790000065, 10.097456086000022 ],
							[ 10.684512985000026, 10.080558802000041 ],
							[ 10.689924364000035, 10.071404378000068 ],
							[ 10.695251731000042, 10.066306851000036 ],
							[ 10.714216089000047, 10.051123747000076 ],
							[ 10.717476174000069, 10.049853048000045 ],
							[ 10.727671742000041, 10.041537533000053 ],
							[ 10.740003412000021, 10.034282468000072 ],
							[ 10.751164589000041, 10.027581238000039 ],
							[ 10.763509049000049, 10.019747821000067 ],
							[ 10.775262019000024, 10.012479640000038 ],
							[ 10.788445397000032, 10.006125230000066 ],
							[ 10.794776855000066, 10.000296401000071 ],
							[ 10.802899217000061, 9.990977369000063 ],
							[ 10.812171832000047, 9.979338556000073 ],
							[ 10.824990310000032, 9.969960349000075 ],
							[ 10.841357427000048, 9.962840147000065 ],
							[ 10.857723364000037, 9.955719778000059 ],
							[ 10.865881979000051, 9.949856779000072 ],
							[ 10.875178272000028, 9.940522264000037 ],
							[ 10.906676273000073, 9.920537937000063 ],
							[ 10.928464603000066, 9.909826442000053 ],
							[ 10.933035883000059, 9.901292037000076 ],
							[ 10.936200580000047, 9.883492682000053 ],
							[ 10.936535682000056, 9.882932069000049 ],
							[ 10.940967625000042, 9.875522199000045 ],
							[ 10.945603016000064, 9.867488499000046 ],
							[ 10.946005712000044, 9.863270942000042 ],
							[ 10.944686945000058, 9.855267974000071 ],
							[ 10.944209757000067, 9.845574404000047 ],
							[ 10.943521156000031, 9.837276551000059 ],
							[ 10.944345450000071, 9.831948087000058 ],
							[ 10.950120039000069, 9.805401001000064 ],
							[ 10.950881090000053, 9.796717440000066 ],
							[ 10.950486597000065, 9.791399918000025 ],
							[ 10.951311649000047, 9.788903017000052 ],
							[ 10.960371567000038, 9.774857585000063 ],
							[ 10.961153074000038, 9.765796402000035 ],
							[ 10.959460638000053, 9.760042168000041 ],
							[ 10.95359482300006, 9.750192872000071 ],
							[ 10.950155191000022, 9.737432972000022 ],
							[ 10.949824335000073, 9.736205690000077 ],
							[ 10.949846054000034, 9.735155082000063 ],
							[ 10.950038754000047, 9.725933158000032 ],
							[ 10.950280412000041, 9.714368139000044 ],
							[ 10.950426377000042, 9.707380182000065 ],
							[ 10.950101093000058, 9.695192601000031 ],
							[ 10.951502866000055, 9.683623137000041 ],
							[ 10.957796237000025, 9.659987629000057 ],
							[ 10.960938488000068, 9.648460295000064 ],
							[ 10.966554890000054, 9.629448834000073 ],
							[ 10.969091509000066, 9.61906721500003 ],
							[ 10.971616859000051, 9.609264292000034 ],
							[ 10.973549847000072, 9.600029009000025 ],
							[ 10.97489368600003, 9.592890513000043 ],
							[ 10.973056620000023, 9.591659406000076 ],
							[ 10.94555652300005, 9.593882549000057 ],
							[ 10.933345622000047, 9.598523251000074 ],
							[ 10.924635962000025, 9.601591925000037 ],
							[ 10.914216097000065, 9.604584510000052 ],
							[ 10.905507269000054, 9.607652725000037 ],
							[ 10.900136935000035, 9.612600538000038 ],
							[ 10.888006442000062, 9.615514722000057 ],
							[ 10.875965961000077, 9.616703974000075 ],
							[ 10.870927015000063, 9.614747228000056 ],
							[ 10.865716683000073, 9.616242224000075 ],
							[ 10.860506888000032, 9.617737718000058 ],
							[ 10.855375985000023, 9.617508112000053 ],
							[ 10.84854578900007, 9.617201045000058 ],
							[ 10.838215826000066, 9.618465269000069 ],
							[ 10.829747160000068, 9.616354941000054 ],
							[ 10.81949624300006, 9.61589417600004 ],
							[ 10.809247073000051, 9.615433692000067 ],
							[ 10.80078719200003, 9.61332208400006 ],
							[ 10.78890726800006, 9.611057345000063 ],
							[ 10.783786753000072, 9.610826696000061 ],
							[ 10.778736987000059, 9.608870449000051 ],
							[ 10.770366441000021, 9.605033086000049 ],
							[ 10.762146252000036, 9.597742760000074 ],
							[ 10.757276638000064, 9.592333860000053 ],
							[ 10.754016629000034, 9.588727190000043 ],
							[ 10.750766933000023, 9.585120442000061 ],
							[ 10.744187166000074, 9.579632998000022 ],
							[ 10.739227190000065, 9.575950600000056 ],
							[ 10.73434624500004, 9.570541448000029 ],
							[ 10.73117755100003, 9.565208383000027 ],
							[ 10.722797132000039, 9.561371134000069 ],
							[ 10.716047131000039, 9.559337762000041 ],
							[ 10.709377346000053, 9.555576431000077 ],
							[ 10.704417489000036, 9.551892989000066 ],
							[ 10.684326263000059, 9.542339250000055 ],
							[ 10.677576817000045, 9.540306303000023 ],
							[ 10.672445990000028, 9.540075289000072 ],
							[ 10.663746941000056, 9.543143416000021 ],
							[ 10.656827100000044, 9.544562383000027 ],
							[ 10.651616914000044, 9.546058883000057 ],
							[ 10.646407259000057, 9.547554176000062 ],
							[ 10.639406191000035, 9.55069949500006 ],
							[ 10.634196419000034, 9.552194617000055 ],
							[ 10.625486518000059, 9.555263588000059 ],
							[ 10.618577106000032, 9.556682327000033 ],
							[ 10.61336717000006, 9.55817885700003 ],
							[ 10.608065710000062, 9.561400343000059 ],
							[ 10.602865906000034, 9.562895532000027 ],
							[ 10.589106816000026, 9.564007755000034 ],
							[ 10.580735912000023, 9.560171191000052 ],
							[ 10.575776167000072, 9.556488286000047 ],
							[ 10.569106204000036, 9.552727836000031 ],
							[ 10.562516122000034, 9.547241165000059 ],
							[ 10.557556154000054, 9.543556887000022 ],
							[ 10.552517103000071, 9.541600318000064 ],
							[ 10.547556759000031, 9.537917054000047 ],
							[ 10.540967255000055, 9.532430551000061 ],
							[ 10.536248355000055, 9.527194976000033 ],
							[ 10.534465789000024, 9.525217246000068 ],
							[ 10.529505852000057, 9.521533736000038 ],
							[ 10.521126876000039, 9.51769753700006 ],
							[ 10.516327067000077, 9.510561109000037 ],
							[ 10.512009500000033, 9.511150106000059 ],
							[ 10.509909858000071, 9.514918172000023 ],
							[ 10.507630209000069, 9.522539126000026 ],
							[ 10.503600383000048, 9.526221243000066 ],
							[ 10.499670182000045, 9.527977097000075 ],
							[ 10.495469826000033, 9.535513089000062 ],
							[ 10.491269869000064, 9.543047898000054 ],
							[ 10.488900150000063, 9.552594877000047 ],
							[ 10.482950408000022, 9.556192377000059 ],
							[ 10.47902036000005, 9.557948005000071 ],
							[ 10.465379663000022, 9.561204131000068 ],
							[ 10.45933988400003, 9.566727395000044 ],
							[ 10.453399881000053, 9.570323819000066 ],
							[ 10.449459853000064, 9.572079743000074 ],
							[ 10.439669864000052, 9.575506241000028 ],
							[ 10.429789309000057, 9.580859190000069 ],
							[ 10.425850191000052, 9.582614858000056 ],
							[ 10.423839653000073, 9.584454598000036 ],
							[ 10.416150169000048, 9.584114039000042 ],
							[ 10.410200334000024, 9.587711484000067 ],
							[ 10.404259508000052, 9.591308817000026 ],
							[ 10.398400578000064, 9.592977680000047 ],
							[ 10.392539907000071, 9.594649290000064 ],
							[ 10.382749354000055, 9.598074846000031 ],
							[ 10.372870191000061, 9.603427939000028 ],
							[ 10.364910381000072, 9.608865797000021 ],
							[ 10.360969491000048, 9.610621330000072 ],
							[ 10.353009928000063, 9.616060056000038 ],
							[ 10.346980065000025, 9.621583677000046 ],
							[ 10.342960627000025, 9.625266230000022 ],
							[ 10.338930277000031, 9.628948204000039 ],
							[ 10.32686042000006, 9.639995484000053 ],
							[ 10.313219853000021, 9.643250457000022 ],
							[ 10.297389876000068, 9.652200687000061 ],
							[ 10.291359709000062, 9.657724512000073 ],
							[ 10.28881315700005, 9.659103710000068 ],
							[ 10.283324505000053, 9.665565236000077 ],
							[ 10.264400489000025, 9.68351849000004 ],
							[ 10.255872465000039, 9.688255210000023 ],
							[ 10.230242418000046, 9.689282862000027 ],
							[ 10.190362574000062, 9.687530689000027 ],
							[ 10.149832942000046, 9.681633134000037 ],
							[ 10.147380100000021, 9.688010262000034 ],
							[ 10.145189979000065, 9.693704527000023 ],
							[ 10.145010101000025, 9.697558309000044 ],
							[ 10.14299980800007, 9.699398759000076 ],
							[ 10.140810130000034, 9.705093548000036 ],
							[ 10.136779696000076, 9.708776678000049 ],
							[ 10.130390055000021, 9.722004853000044 ],
							[ 10.128379538000047, 9.723846350000031 ],
							[ 10.120510082000067, 9.727357915000027 ],
							[ 10.112549494000064, 9.732796497000038 ],
							[ 10.096719811000071, 9.741745940000044 ],
							[ 10.094710169000052, 9.743586693000054 ],
							[ 10.088850241000046, 9.745257398000035 ],
							[ 10.082900237000047, 9.74885461100007 ],
							[ 10.077050424000049, 9.750525672000038 ],
							[ 10.065240008000046, 9.755792469000028 ],
							[ 10.06004420000005, 9.758606769000039 ],
							[ 10.057781151000029, 9.759832122000034 ],
							[ 10.055268334000061, 9.760041023000042 ],
							[ 10.051468918000069, 9.760355871000058 ],
							[ 10.044156110000074, 9.760961445000021 ],
							[ 10.021989830000052, 9.76592540300004 ],
							[ 10.016550264000045, 9.767143443000066 ],
							[ 10.008769780000023, 9.768729460000031 ],
							[ 10.005780065000067, 9.767579774000069 ],
							[ 10.003089897000052, 9.766546235000021 ],
							[ 9.993386112000053, 9.76804558200007 ],
							[ 9.983682829000031, 9.769545801000049 ],
							[ 9.965747627000042, 9.773833397000033 ],
							[ 9.965962296000043, 9.770541305000052 ],
							[ 9.965062196000076, 9.767054353000049 ],
							[ 9.961770577000038, 9.766337952000072 ],
							[ 9.954152145000023, 9.757884486000023 ],
							[ 9.946531036000067, 9.748489465000034 ],
							[ 9.93506889300005, 9.722157540000069 ],
							[ 9.93693855600003, 9.710855336000066 ],
							[ 9.939740415000074, 9.692960320000054 ],
							[ 9.954812073000028, 9.647731674000056 ],
							[ 9.960440135000056, 9.622297240000023 ],
							[ 9.953860892000023, 9.611177237000049 ],
							[ 9.942104112000038, 9.609039221000046 ],
							[ 9.915951612000072, 9.610874264000074 ],
							[ 9.906775440000047, 9.611791413000049 ],
							[ 9.881541281000068, 9.60766247600003 ],
							[ 9.853553953000073, 9.606744981000077 ],
							[ 9.838871045000076, 9.601239214000032 ],
							[ 9.816847691000021, 9.593439124000042 ],
							[ 9.809048453000059, 9.586098460000073 ],
							[ 9.796740686000021, 9.571181239000055 ],
							[ 9.796202649000065, 9.57052860400006 ],
							[ 9.793907199000046, 9.567746033000049 ],
							[ 9.784272429000055, 9.557192925000038 ],
							[ 9.781454129000053, 9.555940470000053 ],
							[ 9.777699340000026, 9.55120458500005 ],
							[ 9.75766492200006, 9.544544999000038 ],
							[ 9.746943251000062, 9.542259068000021 ],
							[ 9.745882091000055, 9.536978886000043 ],
							[ 9.741759530000024, 9.534790736000048 ],
							[ 9.732600090000062, 9.532527817000073 ],
							[ 9.713550440000063, 9.527821305000032 ],
							[ 9.703935823000052, 9.52739447700003 ],
							[ 9.694500147000042, 9.523114368000051 ],
							[ 9.686898461000055, 9.520846252000069 ],
							[ 9.681129487000021, 9.520590784000035 ],
							[ 9.669681404000073, 9.518152502000021 ],
							[ 9.660066554000025, 9.517725106000057 ],
							[ 9.656043022000063, 9.521406974000058 ],
							[ 9.650275198000031, 9.521150447000025 ],
							[ 9.645879970000067, 9.524072832000058 ],
							[ 9.631046532000028, 9.520297207000056 ],
							[ 9.625367123000046, 9.51811495100003 ],
							[ 9.621521209000036, 9.517943446000061 ],
							[ 9.611996571000077, 9.515590549000024 ],
							[ 9.608149535000052, 9.515420036000023 ],
							[ 9.596702685000025, 9.512980620000064 ],
							[ 9.585254870000028, 9.510541997000075 ],
							[ 9.573806613000045, 9.508103576000053 ],
							[ 9.564281421000032, 9.505749449000064 ],
							[ 9.556589884000061, 9.505408515000056 ],
							[ 9.55082238600005, 9.505151583000043 ],
							[ 9.542952692000028, 9.508663327000022 ],
							[ 9.531020123000076, 9.515978079000035 ],
							[ 9.530117021000024, 9.516121213000076 ],
							[ 9.523188481000034, 9.517735340000058 ],
							[ 9.519262796000021, 9.519116280000048 ],
							[ 9.516268653000054, 9.525538621000067 ],
							[ 9.514429508000035, 9.531958752000037 ],
							[ 9.511433884000041, 9.537464489000058 ],
							[ 9.508750080000027, 9.539963577000037 ],
							[ 9.501013670000077, 9.534406459000024 ],
							[ 9.49188984500006, 9.529563040000028 ],
							[ 9.490553931000022, 9.528097299000024 ],
							[ 9.491393452000068, 9.519657346000031 ],
							[ 9.491384543000038, 9.513066865000042 ],
							[ 9.491370542000027, 9.50271073600004 ],
							[ 9.489457648000041, 9.491415694000068 ],
							[ 9.487553154000068, 9.486711110000044 ],
							[ 9.48279543600006, 9.476361201000032 ],
							[ 9.478997888000038, 9.474482746000035 ],
							[ 9.475710250000077, 9.474486996000053 ],
							[ 9.468560928000045, 9.474496139000053 ],
							[ 9.463817747000064, 9.47544354200005 ],
							[ 9.455286551000029, 9.482044650000034 ],
							[ 9.445802787000048, 9.485822470000073 ],
							[ 9.436319937000064, 9.490540883000051 ],
							[ 9.426839072000064, 9.497142466000071 ],
							[ 9.42377302400007, 9.49932042000006 ],
							[ 9.422788567000055, 9.500019970000039 ],
							[ 9.419979969000053, 9.501273127000047 ],
							[ 9.416045392000058, 9.503028755000059 ],
							[ 9.40826510900007, 9.504614002000039 ],
							[ 9.404241471000034, 9.508295958000076 ],
							[ 9.402051064000034, 9.513990353000054 ],
							[ 9.401784059000022, 9.519769672000052 ],
							[ 9.399593545000073, 9.52546406700003 ],
							[ 9.397403522000047, 9.531158447000053 ],
							[ 9.397990284000059, 9.538000863000036 ],
							[ 9.394767668000043, 9.546486105000042 ],
							[ 9.388731841000038, 9.552008779000062 ],
							[ 9.369821337000076, 9.559732438000026 ],
							[ 9.365402241000027, 9.563154403000055 ],
							[ 9.362823647000027, 9.566110582000022 ],
							[ 9.360889389000022, 9.568031476000044 ],
							[ 9.358605134000072, 9.570300214000042 ],
							[ 9.356342239000071, 9.572319725000057 ],
							[ 9.351218190000054, 9.571578739000074 ],
							[ 9.345272301000023, 9.575176519000024 ],
							[ 9.339727614000026, 9.576032654000073 ],
							[ 9.335479876000022, 9.578601976000073 ],
							[ 9.329710800000043, 9.578346110000041 ],
							[ 9.322635912000067, 9.579787653000039 ],
							[ 9.32211559600006, 9.580175173000043 ],
							[ 9.319690469000022, 9.581983253000033 ],
							[ 9.318746386000043, 9.587632553000049 ],
							[ 9.322694811000076, 9.605061633000048 ],
							[ 9.320505380000043, 9.610756084000059 ],
							[ 9.320148707000044, 9.618462306000026 ],
							[ 9.314202321000039, 9.622059 ],
							[ 9.304588549000073, 9.621631383000022 ],
							[ 9.297289701000068, 9.624849204000043 ],
							[ 9.295415987000069, 9.625675146000049 ],
							[ 9.290562424000029, 9.630547670000055 ],
							[ 9.287669939000068, 9.633451584000056 ],
							[ 9.284580069000071, 9.636112064000031 ],
							[ 9.284468630000049, 9.64004336000005 ],
							[ 9.284290459000033, 9.643897289000051 ],
							[ 9.287779588000035, 9.651773371000047 ],
							[ 9.29135791300007, 9.657724173000076 ],
							[ 9.291179824000039, 9.661576973000024 ],
							[ 9.294490636000035, 9.673307484000077 ],
							[ 9.290555868000069, 9.675062884000056 ],
							[ 9.290200195000068, 9.682769028000052 ],
							[ 9.287832227000024, 9.692317181000021 ],
							[ 9.283629363000045, 9.699852255000053 ],
							[ 9.279338905000031, 9.709314214000074 ],
							[ 9.27330317600007, 9.714837759000034 ],
							[ 9.271290658000055, 9.716678549000051 ],
							[ 9.26753427500006, 9.714580367000053 ],
							[ 9.261855159000049, 9.712398747000066 ],
							[ 9.25818754900007, 9.70837520200007 ],
							[ 9.250763842000026, 9.702253266000071 ],
							[ 9.248836332000053, 9.700138944000059 ],
							[ 9.243833188000053, 9.687485279000043 ],
							[ 9.24003218200005, 9.681838764000076 ],
							[ 9.233387682000057, 9.683725826000057 ],
							[ 9.226746631000026, 9.690320386000053 ],
							[ 9.217255617000035, 9.695033116000047 ],
							[ 9.211678478000067, 9.700570091000031 ],
							[ 9.210764079000057, 9.700880834000031 ],
							[ 9.202514502000042, 9.703972989000022 ],
							[ 9.198135408000041, 9.708473320000053 ],
							[ 9.19428851300006, 9.715190077000045 ],
							[ 9.186418802000048, 9.718701524000039 ],
							[ 9.182306026000049, 9.724310759000048 ],
							[ 9.178281644000037, 9.727993017000074 ],
							[ 9.173604121000039, 9.730526759000043 ],
							[ 9.168400650000024, 9.733346033000032 ],
							[ 9.160442239000076, 9.738783830000045 ],
							[ 9.152183468000032, 9.744761231000041 ],
							[ 9.150382744000069, 9.747989649000033 ],
							[ 9.152127197000027, 9.751928581000072 ],
							[ 9.155795229000034, 9.755952037000043 ],
							[ 9.159550815000046, 9.758049091000032 ],
							[ 9.165230608000059, 9.760231723000061 ],
							[ 9.168987432000051, 9.762329236000028 ],
							[ 9.174352816000066, 9.764676867000048 ],
							[ 9.18410317300004, 9.768792053000027 ],
							[ 9.187948114000051, 9.768963020000058 ],
							[ 9.195372703000032, 9.775083380000069 ],
							[ 9.201051822000068, 9.777267699000049 ],
							[ 9.206731568000066, 9.779449690000035 ],
							[ 9.212410823000027, 9.78163270400006 ],
							[ 9.219923839000046, 9.785826765000024 ],
							[ 9.223591807000048, 9.789850097000055 ],
							[ 9.229270187000054, 9.792033426000046 ],
							[ 9.234860397000034, 9.796142473000032 ],
							[ 9.236695366000049, 9.798154058000023 ],
							[ 9.244208425000068, 9.802349325000023 ],
							[ 9.253554475000044, 9.808555366000064 ],
							[ 9.255299758000035, 9.812493241000027 ],
							[ 9.260800194000069, 9.818529385000033 ],
							[ 9.266034840000032, 9.830345132000048 ],
							[ 9.267690591000076, 9.83621045600006 ],
							[ 9.271268685000052, 9.842160242000034 ],
							[ 9.272834808000027, 9.849951904000022 ],
							[ 9.274490178000065, 9.855816617000073 ],
							[ 9.274312935000069, 9.859669919000055 ],
							[ 9.27605698900004, 9.863608259000046 ],
							[ 9.277713035000033, 9.869473508000056 ],
							[ 9.279546912000058, 9.871485465000035 ],
							[ 9.286881445000063, 9.879532574000052 ],
							[ 9.28853717800007, 9.885397770000054 ],
							[ 9.290192386000058, 9.891262975000075 ],
							[ 9.291670006000061, 9.900980897000068 ],
							[ 9.291556814000046, 9.903431671000021 ],
							[ 9.291501889000074, 9.904622292000056 ],
							[ 9.291314154000077, 9.908687515000054 ],
							[ 9.289213238000059, 9.912455297000065 ],
							[ 9.287022568000054, 9.918148890000055 ],
							[ 9.286754594000058, 9.923929118000046 ],
							[ 9.28657744700007, 9.927781854000045 ],
							[ 9.284476371000039, 9.931550179000055 ],
							[ 9.282107189000044, 9.941097025000033 ],
							[ 9.27982877900007, 9.948718050000025 ],
							[ 9.279650407000076, 9.952570778000052 ],
							[ 9.277638305000039, 9.954412705000038 ],
							[ 9.275536421000027, 9.958180399000071 ],
							[ 9.271513250000055, 9.961862491000034 ],
							[ 9.267312066000045, 9.969397830000048 ],
							[ 9.26713355000004, 9.973251102000063 ],
							[ 9.265032588000054, 9.977018176000058 ],
							[ 9.264854624000066, 9.980872020000049 ],
							[ 9.264497544000051, 9.988578 ],
							[ 9.264319569000065, 9.992430712000044 ],
							[ 9.262218449000045, 9.99619889500002 ],
							[ 9.262039882000067, 10.000049345000036 ],
							[ 9.259761802000071, 10.007670218000044 ],
							[ 9.255470521000063, 10.01713004100003 ],
							[ 9.253369166000027, 10.02089987200003 ],
							[ 9.251268367000023, 10.024669690000053 ],
							[ 9.247065459000055, 10.032210399000064 ],
							[ 9.239286213000071, 10.033789769000066 ],
							[ 9.221446130000061, 10.04458050900007 ],
							[ 9.217510986000036, 10.046340182000051 ],
							[ 9.211742555000058, 10.046079642000052 ],
							[ 9.202039463000062, 10.047579651000035 ],
							[ 9.196271025000044, 10.047320573000036 ],
							[ 9.190502019000064, 10.047069313000065 ],
							[ 9.177041798000062, 10.046469701000035 ],
							[ 9.17127395600005, 10.046210214000041 ],
							[ 9.163582761000043, 10.045869580000044 ],
							[ 9.159826458000055, 10.043780508000054 ],
							[ 9.15405867100003, 10.043520198000067 ],
							[ 9.149939765000056, 10.041655513000023 ],
							[ 9.14460824300005, 10.042514827000048 ],
							[ 9.142361665000067, 10.042876203000048 ],
							[ 9.136665570000048, 10.04209067000005 ],
							[ 9.129121887000053, 10.039181636000023 ],
							[ 9.125455616000067, 10.039408921000074 ],
							[ 9.115867714000046, 10.036029613000039 ],
							[ 9.110187340000039, 10.033849646000021 ],
							[ 9.102586621000057, 10.031579736000026 ],
							[ 9.091227381000067, 10.027219898000055 ],
							[ 9.089393761000053, 10.025199692000058 ],
							[ 9.085548432000053, 10.025029959000051 ],
							[ 9.074099297000032, 10.022589672000038 ],
							[ 9.068331929000067, 10.022339893000037 ],
							[ 9.064575258000048, 10.020239685000035 ],
							[ 9.058896, 10.018059502000028 ],
							[ 9.055050182000059, 10.017890008000052 ],
							[ 9.047448423000048, 10.015620092000063 ],
							[ 9.041679464000026, 10.015359731000046 ],
							[ 9.03398790600005, 10.015020345000039 ],
							[ 9.030141552000032, 10.014849481000056 ],
							[ 9.024284349000027, 10.016519654000035 ],
							[ 9.020439124000063, 10.016349800000057 ],
							[ 9.014759054000024, 10.01416951300007 ],
							[ 9.00523448000007, 10.011819681000077 ],
							[ 8.997633159000031, 10.009549869000068 ],
							[ 8.991864312000075, 10.00928986100007 ],
							[ 8.986306452000065, 10.010419813000055 ],
							[ 8.984083309000027, 10.010880250000071 ],
							[ 8.978225939000026, 10.012550268000041 ],
							[ 8.966421342000046, 10.017810093000037 ],
							[ 8.956540173000064, 10.023170349000054 ],
							[ 8.954439100000059, 10.026929980000034 ],
							[ 8.956396882000035, 10.033757267000055 ],
							[ 8.957838985000024, 10.036740373000043 ],
							[ 8.95940605800007, 10.044529883000052 ],
							[ 8.960972619000074, 10.052319381000075 ],
							[ 8.96061585700005, 10.060030125000026 ],
							[ 8.962093319000076, 10.069749838000064 ],
							[ 8.963570881000066, 10.079459929000052 ],
							[ 8.965405216000022, 10.081480178000049 ],
							[ 8.963124956000058, 10.089099740000051 ],
							[ 8.966703428000073, 10.095050142000048 ],
							[ 8.968270365000024, 10.102840142000048 ],
							[ 8.968092029000047, 10.106690424000021 ],
							[ 8.96983622700003, 10.11063013100005 ],
							[ 8.971225006000054, 10.122269834000065 ],
							[ 8.972880165000049, 10.128140318000021 ],
							[ 8.972701866000023, 10.131990596000037 ],
							[ 8.974357672000053, 10.137860497000077 ],
							[ 8.972168123000074, 10.143549887000063 ],
							[ 8.971989829000051, 10.147400162000054 ],
							[ 8.971811510000066, 10.151260600000057 ],
							[ 8.973557664000055, 10.155199723000067 ],
							[ 8.977134678000027, 10.16114998200004 ],
							[ 8.976689575000023, 10.170780171000047 ],
							[ 8.978166798000075, 10.180499753000049 ],
							[ 8.976065617000074, 10.184269655000037 ],
							[ 8.973875815000042, 10.189959595000062 ],
							[ 8.967928615000062, 10.19355964600004 ],
							[ 8.965916456000059, 10.195400404000054 ],
							[ 8.960058885000024, 10.197070116000077 ],
							[ 8.951066820000051, 10.203115675000049 ],
							[ 8.946154385000057, 10.206100733000028 ],
							[ 8.942219016000024, 10.20786027500003 ],
							[ 8.938284751000026, 10.209610164000026 ],
							[ 8.930326709000042, 10.215049646000068 ],
							[ 8.930147352000063, 10.218910048000055 ],
							[ 8.929880313000069, 10.224690485000053 ],
							[ 8.929702091000024, 10.228540146000057 ],
							[ 8.931448054000043, 10.242140711000047 ],
							[ 8.931246593000026, 10.245111706000046 ],
							[ 8.934668310000063, 10.246129999000061 ],
							[ 8.93641395700007, 10.250069831000076 ],
							[ 8.936057040000037, 10.257779885000048 ],
							[ 8.935790066000038, 10.263560312000038 ],
							[ 8.935611886000061, 10.267410541000061 ],
							[ 8.939189946000056, 10.273359996000067 ],
							[ 8.944691530000057, 10.279400120000048 ],
							[ 8.953860483000028, 10.289460399000063 ],
							[ 8.953592049000065, 10.295240253000031 ],
							[ 8.954892287000064, 10.308810082000036 ],
							[ 8.952791281000032, 10.312580396000044 ],
							[ 8.948677864000047, 10.318180036000058 ],
							[ 8.946309868000071, 10.327730169000063 ],
							[ 8.942196740000043, 10.333339915000067 ],
							[ 8.937905292000039, 10.342800379000039 ],
							[ 8.935715652000056, 10.348500203000071 ],
							[ 8.929770620000056, 10.352089997000064 ],
							[ 8.925834709000071, 10.353850496000064 ],
							[ 8.918143506000035, 10.353509838000036 ],
							[ 8.912286022000046, 10.355179907000036 ],
							[ 8.907376296000052, 10.356115557000066 ],
							[ 8.903918332000046, 10.356951519000063 ],
							[ 8.898693359000049, 10.358214141000076 ],
							[ 8.892881330000023, 10.358066713000028 ],
							[ 8.887109840000051, 10.357919781000021 ],
							[ 8.870192706000068, 10.35763453900006 ],
							[ 8.858535705000065, 10.365847748000022 ],
							[ 8.850364959000046, 10.370559850000063 ],
							[ 8.828425361000029, 10.379380315000049 ],
							[ 8.826342805000024, 10.382704644000057 ],
							[ 8.823521653000057, 10.383281917000033 ],
							[ 8.820233306000034, 10.391101148000075 ],
							[ 8.81656150200007, 10.395994346000066 ],
							[ 8.811652869000056, 10.402109064000058 ],
							[ 8.805507873000067, 10.409446974000048 ],
							[ 8.796814973000039, 10.41677885200005 ],
							[ 8.78924655700007, 10.419211656000073 ],
							[ 8.784225712000023, 10.421649760000037 ],
							[ 8.772834987000067, 10.424073203000034 ],
							[ 8.763954351000052, 10.425276850000046 ],
							[ 8.755073677000041, 10.426480266000056 ],
							[ 8.747429962000069, 10.426461021000023 ],
							[ 8.739860431000068, 10.428893943000048 ],
							[ 8.733715811000025, 10.436231761000045 ],
							[ 8.732628691000059, 10.442356070000073 ],
							[ 8.729069474000028, 10.450925770000026 ],
							[ 8.729219430000057, 10.455828767000071 ],
							[ 8.731879398000046, 10.459511965000047 ],
							[ 8.735888718000069, 10.465648393000038 ],
							[ 8.738682095000058, 10.468265410000072 ],
							[ 8.751692959000025, 10.471395664000056 ],
							[ 8.756005045000052, 10.47383781700006 ],
							[ 8.769562630000053, 10.476285807000068 ],
							[ 8.771408468000061, 10.480555848000051 ],
							[ 8.771401160000039, 10.492142608000051 ],
							[ 8.768928780000067, 10.503117602000032 ],
							[ 8.767460318000076, 10.512281665000046 ],
							[ 8.767962455000031, 10.514746700000046 ],
							[ 8.770848210000054, 10.525783758000046 ],
							[ 8.773807417000057, 10.539270291000037 ],
							[ 8.773957434000067, 10.544173243000046 ],
							[ 8.776804944000048, 10.553983521000021 ],
							[ 8.77940366200005, 10.562673690000054 ],
							[ 8.782103364000022, 10.565150165000034 ],
							[ 8.791132006000055, 10.573599906000027 ],
							[ 8.800201148000042, 10.58088996500004 ],
							[ 8.806861108000021, 10.590430222000066 ],
							[ 8.816890561000037, 10.603580386000033 ],
							[ 8.822385993000069, 10.613080242000024 ],
							[ 8.827800068000045, 10.624919790000035 ],
							[ 8.83108909300006, 10.630849675000036 ],
							[ 8.835419832000071, 10.640319959000067 ],
							[ 8.838545949000036, 10.650910126000042 ],
							[ 8.84067043400006, 10.656810041000028 ],
							[ 8.846124530000054, 10.667470059000038 ],
							[ 8.849332704000062, 10.675740134000023 ],
							[ 8.852458952000063, 10.686329604000036 ],
							[ 8.854420868000034, 10.696880147000059 ],
							[ 8.855218906000061, 10.707400300000074 ],
							[ 8.851278310000055, 10.720089980000068 ],
							[ 8.846459134000042, 10.724590208000052 ],
							[ 8.837983889000043, 10.733639584000059 ],
							[ 8.834166286000027, 10.74284003300005 ],
							[ 8.83151033200005, 10.75207996000006 ],
							[ 8.828980467000065, 10.757820103000029 ],
							[ 8.82158929700006, 10.769240448000062 ],
							[ 8.81560434000005, 10.773699595000039 ],
							[ 8.807210725000061, 10.780420336000077 ],
							[ 8.797653141000069, 10.787099574000024 ],
							[ 8.792169539000042, 10.79137157200006 ],
							[ 8.79046437900007, 10.792699561000063 ],
							[ 8.785644623000053, 10.797200106000048 ],
							[ 8.779417296000076, 10.808650212000032 ],
							[ 8.776844922000066, 10.815560134000066 ],
							[ 8.774353917000042, 10.820139907000055 ],
							[ 8.769371137000064, 10.829299935000051 ],
							[ 8.767881285000044, 10.83858020100007 ],
							[ 8.766309818000025, 10.850179839000077 ],
							[ 8.764780159000054, 10.860619495000037 ],
							[ 8.764617061000024, 10.86528032800004 ],
							[ 8.766619872000035, 10.874670119000029 ],
							[ 8.769745982000074, 10.885259577000056 ],
							[ 8.771707708000065, 10.895809868000072 ],
							[ 8.772627768000063, 10.902839390000054 ],
							[ 8.776958534000073, 10.912300027000072 ],
							[ 8.781412270000033, 10.918269932000044 ],
							[ 8.783576964000076, 10.923010088000069 ],
							[ 8.785742863000053, 10.927740047000043 ],
							[ 8.787745425000026, 10.937130258000025 ],
							[ 8.788697313000057, 10.942250513000033 ],
							[ 8.789707376000024, 10.947680418000061 ],
							[ 8.788258265000024, 10.955789418000052 ],
							[ 8.786849795000023, 10.962740128000064 ],
							[ 8.784114697000064, 10.974309695000045 ],
							[ 8.780174417000069, 10.98700068100004 ],
							[ 8.778440298000021, 11.003259910000054 ],
							[ 8.778155069000036, 11.011409655000023 ],
							[ 8.78011714400003, 11.021960388000025 ],
							[ 8.784244737000051, 11.037249517000021 ],
							[ 8.788454108000053, 11.05021017200005 ],
							[ 8.792744628000037, 11.060829932000047 ],
							[ 8.795992916000046, 11.067930098000033 ],
							[ 8.801487984000062, 11.077440139000032 ],
							[ 8.805900631000043, 11.084570215000042 ],
							[ 8.810353531000032, 11.090550442000051 ],
							[ 8.816011307000053, 11.095390436000059 ],
							[ 8.820546321000052, 11.099039868000034 ],
							[ 8.822860577000029, 11.099499772000058 ],
							[ 8.827490827000076, 11.100429721000069 ],
							[ 8.836885279000057, 11.098409511000057 ],
							[ 8.842787962000045, 11.096269496000048 ],
							[ 8.849854644000061, 11.094169424000029 ],
							[ 8.856920100000025, 11.092069773000048 ],
							[ 8.866314736000049, 11.090040319000025 ],
							[ 8.874504791000049, 11.089139724000063 ],
							[ 8.883614623000028, 11.095270343000038 ],
							[ 8.88931384600005, 11.098950365000064 ],
							[ 8.896217077000074, 11.101510207000047 ],
							[ 8.900710913000069, 11.10632034300005 ],
							[ 8.906328057000053, 11.112330231000044 ],
							[ 8.908493845000066, 11.117059987000061 ],
							[ 8.911824416000059, 11.121830128000056 ],
							[ 8.915111328000023, 11.127770367000039 ],
							[ 8.919607449000068, 11.132570083000076 ],
							[ 8.924100819000046, 11.137379907000025 ],
							[ 8.927430077000054, 11.142149901000039 ],
							[ 8.928432633000057, 11.146849364000047 ],
							[ 8.930678307000051, 11.149249977000068 ],
							[ 8.934048580000024, 11.152859945000046 ],
							[ 8.935090943000034, 11.156390368000075 ],
							[ 8.937297035000029, 11.159959958000059 ],
							[ 8.939543995000065, 11.16235996100005 ],
							[ 8.944038581000029, 11.167169533000049 ],
							[ 8.947367794000058, 11.171939914000063 ],
							[ 8.950778670000034, 11.174380131000021 ],
							[ 8.955313746000058, 11.178030154000055 ],
							[ 8.962135183000044, 11.182909833000053 ],
							[ 8.970122331000027, 11.187840342000072 ],
							[ 8.976984857000048, 11.191559780000034 ],
							[ 8.982683116000032, 11.195240046000038 ],
							[ 8.984889915000053, 11.19880934400004 ],
							[ 8.990507044000026, 11.204819623000049 ],
							[ 8.99504175900006, 11.208460099000035 ],
							[ 8.999535350000031, 11.213270114000068 ],
							[ 9.002946967000071, 11.21570984400006 ],
							[ 9.005193563000034, 11.218119624000053 ],
							[ 9.007440772000052, 11.220519761000048 ],
							[ 9.011934114000042, 11.225330195000026 ],
							[ 9.015304763000074, 11.228939962000027 ],
							[ 9.01747038600007, 11.233670173000064 ],
							[ 9.020840605000046, 11.237279887000057 ],
							[ 9.023047455000039, 11.240839913000059 ],
							[ 9.024048095000069, 11.24553964200004 ],
							[ 9.028541407000034, 11.250349868000058 ],
							[ 9.032995452000023, 11.256320005000077 ],
							[ 9.03869413700005, 11.259999969000035 ],
							[ 9.045515959000056, 11.264889957000037 ],
							[ 9.054626251000059, 11.271010657000033 ],
							[ 9.062612611000077, 11.275939701000027 ],
							[ 9.068311927000025, 11.279620335000061 ],
							[ 9.076297498000031, 11.284540046000075 ],
							[ 9.090146149000077, 11.288490331000048 ],
							[ 9.097049863000052, 11.291049810000061 ],
							[ 9.10387133100005, 11.295929681000075 ],
							[ 9.106117955000059, 11.298339947000045 ],
							[ 9.108324892000041, 11.301900025000066 ],
							[ 9.111695510000061, 11.30550945300007 ],
							[ 9.119681119000063, 11.310429352000028 ],
							[ 9.126544059000025, 11.314149955000062 ],
							[ 9.133366384000055, 11.319040570000027 ],
							[ 9.142353828000068, 11.328650347000064 ],
							[ 9.14290064100004, 11.331979750000073 ],
							[ 9.145411547000037, 11.329914226000028 ],
							[ 9.148207390000039, 11.327614641000025 ],
							[ 9.156778129000031, 11.330552007000051 ],
							[ 9.16117375500005, 11.332058707000044 ],
							[ 9.193549098000062, 11.350888068000074 ],
							[ 9.20842290500002, 11.357957913000064 ],
							[ 9.224948171000051, 11.365813202000027 ],
							[ 9.239074769000069, 11.369089913000039 ],
							[ 9.274881316000062, 11.371732547000022 ],
							[ 9.279035413000031, 11.372039635000021 ],
							[ 9.298985574000028, 11.370635290000052 ],
							[ 9.313064361000045, 11.36930439300005 ],
							[ 9.331754867000029, 11.359851914000046 ],
							[ 9.335126660000071, 11.358035189000077 ],
							[ 9.349270890000071, 11.350412591000065 ],
							[ 9.362108290000037, 11.345120252000072 ],
							[ 9.364930337000033, 11.343956956000056 ],
							[ 9.368845834000069, 11.34234335900004 ],
							[ 9.371885543000076, 11.339675173000046 ],
							[ 9.380538038000054, 11.335630524000067 ],
							[ 9.384074325000029, 11.333370227000046 ],
							[ 9.386428029000058, 11.332249612000055 ],
							[ 9.389964811000027, 11.329990387000066 ],
							[ 9.392318494000051, 11.32886975200006 ],
							[ 9.394672134000075, 11.32773949400007 ],
							[ 9.398208819000047, 11.325480746000039 ],
							[ 9.401746040000035, 11.323220277000075 ],
							[ 9.404099625000072, 11.322099567000066 ],
							[ 9.411160301000052, 11.318730535000043 ],
							[ 9.413514408000026, 11.317609765000043 ],
							[ 9.415868490000037, 11.316479945000026 ],
							[ 9.424100625000051, 11.313130271000034 ],
							[ 9.426442283000029, 11.31315978400005 ],
							[ 9.428795714000046, 11.312029882000047 ],
							[ 9.431137367000076, 11.312060494000036 ],
							[ 9.433490787000039, 11.310939598000061 ],
							[ 9.438174059000062, 11.310999639000045 ],
							[ 9.441699110000059, 11.309889727000041 ],
							[ 9.446573328000056, 11.307920465000052 ],
							[ 9.451077351000038, 11.308850122000024 ],
							[ 9.454602360000024, 11.307740089000049 ],
							[ 9.456918506000022, 11.307999408000057 ],
							[ 9.460391026000025, 11.308388935000039 ],
							[ 9.462745521000045, 11.308652132000077 ],
							[ 9.466311571000062, 11.30788037700006 ],
							[ 9.469809760000032, 11.309080008000024 ],
							[ 9.475652453000066, 11.310299771000075 ],
							[ 9.477970224000046, 11.312629730000026 ],
							[ 9.47927065600004, 11.316507438000031 ],
							[ 9.481159407000064, 11.31771016700003 ],
							[ 9.48308256200005, 11.318935447000058 ],
							[ 9.486364595000055, 11.321025650000024 ],
							[ 9.489286574000062, 11.32265073700006 ],
							[ 9.489532324000038, 11.326589689000059 ],
							[ 9.491861310000047, 11.327770305000058 ],
							[ 9.494178735000048, 11.330100161000075 ],
							[ 9.497667340000021, 11.332440302000066 ],
							[ 9.499984832000052, 11.334770121000076 ],
							[ 9.505838458000028, 11.33483959800003 ],
							[ 9.510508783000034, 11.336050241000066 ],
							[ 9.515180866000037, 11.337260268000023 ],
							[ 9.521023025000034, 11.33847987200005 ],
							[ 9.523352772000067, 11.33965973200003 ],
							[ 9.528023801000074, 11.340870154000072 ],
							[ 9.531536265000057, 11.340909779000071 ],
							[ 9.535047567000049, 11.340949927000054 ],
							[ 9.537402019000069, 11.339830630000051 ],
							[ 9.542085683000039, 11.339889360000029 ],
							[ 9.546780745000035, 11.33878973800006 ],
							[ 9.549134003000063, 11.337670369000023 ],
							[ 9.555012760000068, 11.335439424000072 ],
							[ 9.557366517000048, 11.334309837000035 ],
							[ 9.560914779000029, 11.330900280000037 ],
							[ 9.56680588200004, 11.327520462000052 ],
							[ 9.570354551000037, 11.324110258000076 ],
							[ 9.571549538000056, 11.32182012100003 ],
							[ 9.57275700100007, 11.318380165000065 ],
							[ 9.573951953000062, 11.316089445000046 ],
							[ 9.573975781000058, 11.313790370000049 ],
							[ 9.575195092000058, 11.309200014000055 ],
							[ 9.576427414000023, 11.303460391000044 ],
							[ 9.576475017000064, 11.298849795000024 ],
							[ 9.576511304000064, 11.295399769000028 ],
							[ 9.577779813000063, 11.286199371000066 ],
							[ 9.576645060000033, 11.282730244000049 ],
							[ 9.57549842800006, 11.28042055800006 ],
							[ 9.575534705000052, 11.276960346000067 ],
							[ 9.576754454000024, 11.272370547000037 ],
							[ 9.58033931500006, 11.265509627000029 ],
							[ 9.582263127000033, 11.259651355000074 ],
							[ 9.585731496000051, 11.258386471000051 ],
							[ 9.589767436000045, 11.259859862000042 ],
							[ 9.595621030000075, 11.259940343000039 ],
							[ 9.602657360000023, 11.258870142000035 ],
							[ 9.613219380000032, 11.256689964000032 ],
							[ 9.616743966000058, 11.255580088000045 ],
							[ 9.624977827000066, 11.252455886000064 ],
							[ 9.628618906000042, 11.251909021000074 ],
							[ 9.635501161000036, 11.253510424000069 ],
							[ 9.641366914000059, 11.252430178000054 ],
							[ 9.646050591000062, 11.252489870000034 ],
							[ 9.649550552000051, 11.253680459000066 ],
							[ 9.655405185000063, 11.25374983200004 ],
							[ 9.665929624000057, 11.255029491000073 ],
							[ 9.670613341000035, 11.255080394000061 ],
							[ 9.677637783000023, 11.255169887000022 ],
							[ 9.685808920000056, 11.257570323000039 ],
							[ 9.688151386000072, 11.257599886000037 ],
							[ 9.692834578000031, 11.257660100000066 ],
							[ 9.697505659000058, 11.258859360000031 ],
							[ 9.699431230000073, 11.25726577100005 ],
							[ 9.704860659000076, 11.259070337000026 ],
							[ 9.707310534000044, 11.259884939000074 ],
							[ 9.710820434000027, 11.26105121300003 ],
							[ 9.717473230000053, 11.263261733000036 ],
							[ 9.719515084000022, 11.263488876000054 ],
							[ 9.72204603800003, 11.263891907000072 ],
							[ 9.724413033000076, 11.263861855000073 ],
							[ 9.72909269400003, 11.261549697000021 ],
							[ 9.738254420000032, 11.263685977000023 ],
							[ 9.746642798000039, 11.262909824000076 ],
							[ 9.748984144000076, 11.262940134000075 ],
							[ 9.751314151000031, 11.264120255000023 ],
							[ 9.759497875000022, 11.265370370000028 ],
							[ 9.762986473000069, 11.267720451000059 ],
							[ 9.766486385000064, 11.268910511000058 ],
							[ 9.769986880000033, 11.270099388000062 ],
							[ 9.773486862000027, 11.271300119000045 ],
							[ 9.77697442300007, 11.273639906000028 ],
							[ 9.780475605000049, 11.274840550000022 ],
							[ 9.78397564200003, 11.276029315000073 ],
							[ 9.787463338000066, 11.278370132000077 ],
							[ 9.789805368000032, 11.278400184000077 ],
							[ 9.79449668500007, 11.281242370000029 ],
							[ 9.797355864000053, 11.282974223000053 ],
							[ 9.801440383000056, 11.285449798000059 ],
							[ 9.804953470000044, 11.285490220000042 ],
							[ 9.808466035000038, 11.285539645000028 ],
							[ 9.813160750000065, 11.284440036000035 ],
							[ 9.816673267000056, 11.284480349000034 ],
							[ 9.819014756000058, 11.284509663000051 ],
							[ 9.822539548000066, 11.283399518000067 ],
							[ 9.824892739000063, 11.282280079000031 ],
							[ 9.824930066000036, 11.278829463000022 ],
							[ 9.826124438000022, 11.276539609000054 ],
							[ 9.826161211000056, 11.273090123000031 ],
							[ 9.828551010000069, 11.268509861000041 ],
							[ 9.829746470000032, 11.266220008000062 ],
							[ 9.829770953000036, 11.263919775000033 ],
							[ 9.830977475000054, 11.260480073000053 ],
							[ 9.832173473000068, 11.258190210000066 ],
							[ 9.834586989000059, 11.251310244000024 ],
							[ 9.836977119000039, 11.246729920000064 ],
							[ 9.839342810000062, 11.244459979000055 ],
							[ 9.842904301000033, 11.239899568000055 ],
							[ 9.844099012000072, 11.237609667000072 ],
							[ 9.846465118000026, 11.235329514000057 ],
							[ 9.848867208000058, 11.229610587000025 ],
							[ 9.850062408000042, 11.227319516000023 ],
							[ 9.851295246000063, 11.221579511000073 ],
							[ 9.852500859000031, 11.218139731000065 ],
							[ 9.854891688000066, 11.21355985100007 ],
							[ 9.856086800000071, 11.211270461000026 ],
							[ 9.859671481000021, 11.204409636000037 ],
							[ 9.860878683000067, 11.200969839000038 ],
							[ 9.862110093000069, 11.195220182000071 ],
							[ 9.863316659000077, 11.191780358000074 ],
							[ 9.865719898000066, 11.186060153000028 ],
							[ 9.869293164000055, 11.180340024000031 ],
							[ 9.871659342000044, 11.178069883000035 ],
							[ 9.876390461000028, 11.173519931000044 ],
							[ 9.878743897000049, 11.172400712000069 ],
							[ 9.882281201000069, 11.170140150000066 ],
							[ 9.888183868000056, 11.165599678000035 ],
							[ 9.891731940000057, 11.162190318000057 ],
							[ 9.892939818000059, 11.158749829000044 ],
							[ 9.891805870000042, 11.155279944000029 ],
							[ 9.889499640000054, 11.151800445000049 ],
							[ 9.884864869000069, 11.147139374000062 ],
							[ 9.882559902000025, 11.143659808000052 ],
							[ 9.879072302000054, 11.141309700000022 ],
							[ 9.872108216000072, 11.135470268000063 ],
							[ 9.868631186000073, 11.131979814000033 ],
							[ 9.865143791000037, 11.129630130000066 ],
							[ 9.862826401000063, 11.127300299000069 ],
							[ 9.861680131000071, 11.124980157000039 ],
							[ 9.858203883000044, 11.121489602000054 ],
							[ 9.857057663000035, 11.119170019000023 ],
							[ 9.857081383000036, 11.116869763000068 ],
							[ 9.855935184000032, 11.114549603000057 ],
							[ 9.854800024000042, 11.111080172000072 ],
							[ 9.853653863000034, 11.108770168000035 ],
							[ 9.853726241000061, 11.101859783000066 ],
							[ 9.854993888000024, 11.092660008000053 ],
							[ 9.855018781000069, 11.090359745000058 ],
							[ 9.855042514000047, 11.088060615000074 ],
							[ 9.856274798000072, 11.082319966000057 ],
							[ 9.856310658000041, 11.078860251000037 ],
							[ 9.855164585000068, 11.076540082000065 ],
							[ 9.854042303000028, 11.071929811000075 ],
							[ 9.852932163000048, 11.066160098000069 ],
							[ 9.852955902000076, 11.063850226000056 ],
							[ 9.854199142000027, 11.056960287000038 ],
							[ 9.854224038000041, 11.054660020000028 ],
							[ 9.854248330000075, 11.052350143000069 ],
							[ 9.854277265000064, 11.049585979000028 ],
							[ 9.854320692000044, 11.045450471000038 ],
							[ 9.854369296000073, 11.040840325000033 ],
							[ 9.854406335000021, 11.037389633000032 ],
							[ 9.855625172000032, 11.032799966000027 ],
							[ 9.856868813000062, 11.025899839000033 ],
							[ 9.858076007000022, 11.022459990000073 ],
							[ 9.858112430000062, 11.019009869000058 ],
							[ 9.859332301000052, 11.014419608000026 ],
							[ 9.862929355000063, 11.006400309000071 ],
							[ 9.86581899600003, 10.999396306000051 ],
							[ 9.868856705000042, 10.999569997000037 ],
							[ 9.871210149000035, 10.998439518000055 ],
							[ 9.874032048000061, 10.997348596000052 ],
							[ 9.881819824000047, 10.991660117000038 ],
							[ 9.887170633000039, 10.992266615000062 ],
							[ 9.890393503000041, 10.991020344000049 ],
							[ 9.900637880000033, 10.98383050800004 ],
							[ 9.90356231100003, 10.985174935000032 ],
							[ 9.906516192000026, 10.981599591000077 ],
							[ 9.909095795000042, 10.982719363000058 ],
							[ 9.911595627000054, 10.982128991000025 ],
							[ 9.915930770000045, 10.977109905000077 ],
							[ 9.918273150000061, 10.977140271000053 ],
							[ 9.921809445000065, 10.974880428000063 ],
							[ 9.928858628000057, 10.972659795000027 ],
							[ 9.935919630000058, 10.969289728000035 ],
							[ 9.939444426000023, 10.968179588000055 ],
							[ 9.944138648000035, 10.967089583000075 ],
							[ 9.950030527000024, 10.963700188000075 ],
							[ 9.953554083000029, 10.962590472000045 ],
							[ 9.958249914000021, 10.961500283000021 ],
							[ 9.966458537000051, 10.960450070000036 ],
							[ 9.96878730800006, 10.96162997700003 ],
							[ 9.973458360000052, 10.962829723000027 ],
							[ 9.98047063000007, 10.964070141000036 ],
							[ 9.985142921000033, 10.965279345000056 ],
							[ 9.988655230000063, 10.965319708000038 ],
							[ 9.99099639800005, 10.965350191000027 ],
							[ 9.994508708000069, 10.965389943000048 ],
							[ 9.998008776000063, 10.966589685000031 ],
							[ 10.00269054100005, 10.966640336000069 ],
							[ 10.006190068000024, 10.967840021000029 ],
							[ 10.007191154000054, 10.968180271000051 ],
							[ 10.008189151000067, 10.967613098000072 ],
							[ 10.020596662000059, 10.97464087000003 ],
							[ 10.024416579000047, 10.977454899000065 ],
							[ 10.028696253000021, 10.974618485000065 ],
							[ 10.03250951900003, 10.975078550000035 ],
							[ 10.040137607000077, 10.976940463000062 ],
							[ 10.045854138000038, 10.976453143000072 ],
							[ 10.051570094000056, 10.975966309000057 ],
							[ 10.057294285000069, 10.978303930000038 ],
							[ 10.063018532000058, 10.980641480000031 ],
							[ 10.06969894100007, 10.983917496000061 ],
							[ 10.075564756000063, 10.989185801000076 ],
							[ 10.076219643000059, 10.989410467000027 ],
							[ 10.078559830000074, 10.989439826000023 ],
							[ 10.086779561000071, 10.987230299000032 ],
							[ 10.090310370000054, 10.986120393000022 ],
							[ 10.096200082000053, 10.982740551000063 ],
							[ 10.100879795000026, 10.982799578000026 ],
							[ 10.103239852000058, 10.981670416000043 ],
							[ 10.105629990000068, 10.977100133000022 ],
							[ 10.105649835000065, 10.974799846000053 ],
							[ 10.108581706000052, 10.967518057000063 ],
							[ 10.110460522000039, 10.963339688000076 ],
							[ 10.114000532000034, 10.959930427000074 ],
							[ 10.117549616000076, 10.956519974000059 ],
							[ 10.119919604000074, 10.954239698000038 ],
							[ 10.122269741000025, 10.953120052000031 ],
							[ 10.124630129000025, 10.951999805000071 ],
							[ 10.126979621000032, 10.950869391000026 ],
							[ 10.134040091000031, 10.94750005800006 ],
							[ 10.143420159000073, 10.94647001800007 ],
							[ 10.145759497000029, 10.946500084000036 ],
							[ 10.148119755000039, 10.945369503000052 ],
							[ 10.151090532000069, 10.947248622000075 ],
							[ 10.154158367000036, 10.946203381000032 ],
							[ 10.156319474000043, 10.944319627000027 ],
							[ 10.159839623000039, 10.944360047000032 ],
							[ 10.163350028000025, 10.944399907000047 ],
							[ 10.165699948000054, 10.943279967000024 ],
							[ 10.16804042800004, 10.943309907000071 ],
							[ 10.172739912000054, 10.942210210000042 ],
							[ 10.175080365000042, 10.942239513000061 ],
							[ 10.178580255000043, 10.943440406000036 ],
							[ 10.182090090000031, 10.943479517000071 ],
							[ 10.186759932000029, 10.944689579000055 ],
							[ 10.189079949000075, 10.947019667000063 ],
							[ 10.191409948000057, 10.948200453000027 ],
							[ 10.192560025000034, 10.950509985000053 ],
							[ 10.193699865000042, 10.952830266000035 ],
							[ 10.193679996000071, 10.955129433000025 ],
							[ 10.194800563000058, 10.959750022000037 ],
							[ 10.194780174000073, 10.962059928000031 ],
							[ 10.194750030000023, 10.964360255000031 ],
							[ 10.192380188000072, 10.967780099000038 ],
							[ 10.188830583000026, 10.97119974800006 ],
							[ 10.187629754000056, 10.973480090000066 ],
							[ 10.184109672000034, 10.974590335000073 ],
							[ 10.180569854000055, 10.976849306000076 ],
							[ 10.178230319000022, 10.976820016000033 ],
							[ 10.17586994800007, 10.977950230000033 ],
							[ 10.171362890000069, 10.978570766000075 ],
							[ 10.170453274000067, 10.980404885000041 ],
							[ 10.166469707000033, 10.981289840000045 ],
							[ 10.164119545000062, 10.982410339000069 ],
							[ 10.161749598000029, 10.984680154000046 ],
							[ 10.158220464000067, 10.986950171000046 ],
							[ 10.154669740000031, 10.990360457000065 ],
							[ 10.152290205000043, 10.99378004700003 ],
							[ 10.15117189700004, 10.996931854000024 ],
							[ 10.149839224000061, 11.000649357000043 ],
							[ 10.148846622000065, 11.003418804000034 ],
							[ 10.145119381000029, 11.007510384000057 ],
							[ 10.14272956800005, 11.012089949000028 ],
							[ 10.141530068000066, 11.014380312000071 ],
							[ 10.142669420000061, 11.017850030000034 ],
							[ 10.145130399000038, 11.021476686000028 ],
							[ 10.144442600000048, 11.026971996000043 ],
							[ 10.148390077000045, 11.030579336000073 ],
							[ 10.149519822000059, 11.034050187000048 ],
							[ 10.15066990400004, 11.036359836000031 ],
							[ 10.152959892000069, 11.041000614000041 ],
							[ 10.15411001800004, 11.043310251000037 ],
							[ 10.157590130000074, 11.046809912000072 ],
							[ 10.159870218000037, 11.05259994000005 ],
							[ 10.162170438000032, 11.05608008300004 ],
							[ 10.165640124000049, 11.060720484000058 ],
							[ 10.166780698000025, 11.063039700000047 ],
							[ 10.16909019700006, 11.066519769000024 ],
							[ 10.172550376000061, 11.071169724000072 ],
							[ 10.174859982000044, 11.074649754000063 ],
							[ 10.177189594000026, 11.075830604000032 ],
							[ 10.178330308000056, 11.078149801000052 ],
							[ 10.180649981000045, 11.080479370000035 ],
							[ 10.182970252000075, 11.082810606000066 ],
							[ 10.186440735000076, 11.086310576000074 ],
							[ 10.188759951000065, 11.088640094000027 ],
							[ 10.192250170000023, 11.090979388000051 ],
							[ 10.194549818000041, 11.094470044000047 ],
							[ 10.198887549000062, 11.100675838000029 ],
							[ 10.201354101000049, 11.101158292000036 ],
							[ 10.207208839000032, 11.10230322600006 ],
							[ 10.21080424400003, 11.103006265000033 ],
							[ 10.21553020500005, 11.103930446000049 ],
							[ 10.217870004000076, 11.10396005900003 ],
							[ 10.22890575100007, 11.103699907000021 ],
							[ 10.228605266000045, 11.100789893000069 ],
							[ 10.231969734000074, 11.099519862000022 ],
							[ 10.234339977000047, 11.097250189000022 ],
							[ 10.237859976000038, 11.096140054000045 ],
							[ 10.240219799000045, 11.095020213000055 ],
							[ 10.242569863000028, 11.093890208000062 ],
							[ 10.244910163000043, 11.093919648000053 ],
							[ 10.247249340000053, 11.093950207000034 ],
							[ 10.253110096000057, 11.09401973000007 ],
							[ 10.256620261000023, 11.094059824000055 ],
							[ 10.260129899000049, 11.094109514000024 ],
							[ 10.262459916000068, 11.095290401000057 ],
							[ 10.264780243000075, 11.097619996000049 ],
							[ 10.26708972800003, 11.099949613000035 ],
							[ 10.268230556000049, 11.103420054000026 ],
							[ 10.270500186000049, 11.110349650000046 ],
							[ 10.272820664000051, 11.112680321000028 ],
							[ 10.277489566000042, 11.113889812000025 ],
							[ 10.279830020000077, 11.113920162000056 ],
							[ 10.282159662000026, 11.115099796000038 ],
							[ 10.284091358000069, 11.116820386000029 ],
							[ 10.284578016000069, 11.117253778000077 ],
							[ 10.287446767000063, 11.118806674000041 ],
							[ 10.294613349000031, 11.123489513000038 ],
							[ 10.297473156000024, 11.123479520000046 ],
							[ 10.303192790000026, 11.123459468000021 ],
							[ 10.305119899000033, 11.129101780000042 ],
							[ 10.30655805400005, 11.131450617000041 ],
							[ 10.310849855000072, 11.131906136000055 ],
							[ 10.315607946000057, 11.129535389000068 ],
							[ 10.321325956000067, 11.129044402000034 ],
							[ 10.326229727000054, 11.129920215000027 ],
							[ 10.327670329000057, 11.129469820000054 ],
							[ 10.33115956100005, 11.13181006700006 ],
							[ 10.333470503000058, 11.135290228000031 ],
							[ 10.335760013000026, 11.139929858000073 ],
							[ 10.335720128000048, 11.143380024000066 ],
							[ 10.338020555000071, 11.148010007000039 ],
							[ 10.339160243000038, 11.150329836000026 ],
							[ 10.340310221000038, 11.152649623000059 ],
							[ 10.343790309000042, 11.156140171000061 ],
							[ 10.346090127000025, 11.159629879000022 ],
							[ 10.346444228000053, 11.162845828000059 ],
							[ 10.350700097000072, 11.166589457000043 ],
							[ 10.354180455000062, 11.17008949500007 ],
							[ 10.358810710000057, 11.174750378000056 ],
							[ 10.361140423000052, 11.17593008700004 ],
							[ 10.364620354000067, 11.179420436000044 ],
							[ 10.363360444000023, 11.187470412000039 ],
							[ 10.364500164000049, 11.190939969000055 ],
							[ 10.36446935400005, 11.193240282000033 ],
							[ 10.365619610000067, 11.195549802000073 ],
							[ 10.365579461000038, 11.199010144000056 ],
							[ 10.366720385000065, 11.202480263000041 ],
							[ 10.365509977000045, 11.205920028000037 ],
							[ 10.363160110000024, 11.207040083000038 ],
							[ 10.358460587000025, 11.208129722000024 ],
							[ 10.352609377000022, 11.208060139000054 ],
							[ 10.346750178000036, 11.207989918000067 ],
							[ 10.342080389000046, 11.206780175000063 ],
							[ 10.338569699000061, 11.206740315000047 ],
							[ 10.332730305000041, 11.205519416000072 ],
							[ 10.330389837000041, 11.205489972000066 ],
							[ 10.322196155000029, 11.204197675000046 ],
							[ 10.318955617000029, 11.204483818000028 ],
							[ 10.314050579000025, 11.204792199000053 ],
							[ 10.312681015000067, 11.204878419000067 ],
							[ 10.309383533000073, 11.205085568000072 ],
							[ 10.307053440000061, 11.205232773000034 ],
							[ 10.303560280000056, 11.205451852000067 ],
							[ 10.298810266000032, 11.205750470000055 ],
							[ 10.294860008000057, 11.205998192000038 ],
							[ 10.291992905000029, 11.207676130000038 ],
							[ 10.288379805000034, 11.208285345000036 ],
							[ 10.281369170000062, 11.209469064000075 ],
							[ 10.278593834000048, 11.209936855000024 ],
							[ 10.276716453000063, 11.211089188000074 ],
							[ 10.273101827000062, 11.211606742000072 ],
							[ 10.270640210000067, 11.211959830000069 ],
							[ 10.264937416000066, 11.212777129000074 ],
							[ 10.261089506000076, 11.213523053000074 ],
							[ 10.255309775000057, 11.218400134000035 ],
							[ 10.250610382000048, 11.219499717000076 ],
							[ 10.247090352000043, 11.22060035100003 ],
							[ 10.242389761000027, 11.221699833000059 ],
							[ 10.238118021000048, 11.22304642000006 ],
							[ 10.235339916000044, 11.223919607000028 ],
							[ 10.232999902000074, 11.223890103000031 ],
							[ 10.229479808000065, 11.225000178000073 ],
							[ 10.22597006500007, 11.224949638000055 ],
							[ 10.223620364000055, 11.224929698000039 ],
							[ 10.221269360000065, 11.226050538000038 ],
							[ 10.217760205000047, 11.226010084000052 ],
							[ 10.215419614000041, 11.225979915000039 ],
							[ 10.21306056800006, 11.227100156000063 ],
							[ 10.21071997100006, 11.227069939000046 ],
							[ 10.208379989000036, 11.227049889000057 ],
							[ 10.202297024000075, 11.225683096000068 ],
							[ 10.200025427000071, 11.226094993000061 ],
							[ 10.195520313000031, 11.224590216000024 ],
							[ 10.189689908000048, 11.222209881000026 ],
							[ 10.187339992000034, 11.223340093000047 ],
							[ 10.183830275000048, 11.223290284000029 ],
							[ 10.175620329000026, 11.224349816000029 ],
							[ 10.173270320000029, 11.225469774000032 ],
							[ 10.169739716000038, 11.226579890000039 ],
							[ 10.165049660000022, 11.227669878000029 ],
							[ 10.159159502000023, 11.23105978600006 ],
							[ 10.150890316000073, 11.237859528000058 ],
							[ 10.148529883000037, 11.238990100000024 ],
							[ 10.142108102000066, 11.239958588000036 ],
							[ 10.141500656000062, 11.240050308000036 ],
							[ 10.137980502000062, 11.240010262000055 ],
							[ 10.133299738000062, 11.239950012000065 ],
							[ 10.12742991600004, 11.241029554000022 ],
							[ 10.122729986000024, 11.243280154000047 ],
							[ 10.116849885000022, 11.245509939000044 ],
							[ 10.113309873000048, 11.247770478000064 ],
							[ 10.110960100000057, 11.248890039000059 ],
							[ 10.107430241000031, 11.250000130000046 ],
							[ 10.099189899000066, 11.254509730000052 ],
							[ 10.095649687000048, 11.25677010000004 ],
							[ 10.092140254000071, 11.256730161000064 ],
							[ 10.087450140000044, 11.257820294000055 ],
							[ 10.083929839000064, 11.257780309000054 ],
							[ 10.080410086000029, 11.258890094000037 ],
							[ 10.075730235000037, 11.258830244000023 ],
							[ 10.070197926000048, 11.260214702000042 ],
							[ 10.067253910000034, 11.260950930000035 ],
							[ 10.064188137000031, 11.260190181000041 ],
							[ 10.060413177000044, 11.261534438000069 ],
							[ 10.05696030200005, 11.262060140000074 ],
							[ 10.054610341000057, 11.262029538000036 ],
							[ 10.052270118000024, 11.262000023000041 ],
							[ 10.048749665000059, 11.263110083000072 ],
							[ 10.046409432000075, 11.263079383000047 ],
							[ 10.037039915000037, 11.262970088000031 ],
							[ 10.034710123000025, 11.261790056000052 ],
							[ 10.031209973000045, 11.260600284000077 ],
							[ 10.027720277000071, 11.258250445000044 ],
							[ 10.024220744000047, 11.257060032000027 ],
							[ 10.021848574000046, 11.254624569000043 ],
							[ 10.019559629000071, 11.254699794000032 ],
							[ 10.014866572000074, 11.254601352000066 ],
							[ 10.007889748000025, 11.251099748000058 ],
							[ 10.00556012900006, 11.249920106000047 ],
							[ 10.003229944000054, 11.248740432000034 ],
							[ 10.000920069000074, 11.246410328000024 ],
							[ 9.997390461000066, 11.247520481000038 ],
							[ 9.996195561000036, 11.24981032200003 ],
							[ 9.993841964000069, 11.250929705000033 ],
							[ 9.991488402000073, 11.252059831000054 ],
							[ 9.990292261000036, 11.254340046000038 ],
							[ 9.989097856000058, 11.256629842000052 ],
							[ 9.987791286000061, 11.259761824000066 ],
							[ 9.986695562000023, 11.262359855000057 ],
							[ 9.985500528000046, 11.264650234000044 ],
							[ 9.983134495000058, 11.266919958000074 ],
							[ 9.980756690000021, 11.270350258000065 ],
							[ 9.979549272000042, 11.273790432000055 ],
							[ 9.977948408000032, 11.276092575000064 ],
							[ 9.977366454000048, 11.276550082000028 ],
							[ 9.976898710000057, 11.276917436000076 ],
							[ 9.966399071000069, 11.286432115000025 ],
							[ 9.952126393000071, 11.299179584000058 ],
							[ 9.948805709000055, 11.305778995000026 ],
							[ 9.942627255000048, 11.313797606000037 ],
							[ 9.931670868000026, 11.318532409000056 ],
							[ 9.923575591000031, 11.323260331000029 ],
							[ 9.921192178000069, 11.323736909000047 ],
							[ 9.919768371000032, 11.326564956000027 ],
							[ 9.920252386000072, 11.329388808000033 ],
							[ 9.918354689000068, 11.333159331000047 ],
							[ 9.915981955000063, 11.337872897000068 ],
							[ 9.910744033000071, 11.341180935000068 ],
							[ 9.905029801000069, 11.344961287000046 ],
							[ 9.904623868000044, 11.345620518000032 ],
							[ 9.900316259000022, 11.347669569000061 ],
							[ 9.896819185000027, 11.348797894000029 ],
							[ 9.895046071000024, 11.351470721000055 ],
							[ 9.884999330000028, 11.356699710000044 ],
							[ 9.882633211000041, 11.358969906000027 ],
							[ 9.881438345000049, 11.361260487000038 ],
							[ 9.879059535000067, 11.364690111000073 ],
							[ 9.877864620000025, 11.366969923000056 ],
							[ 9.877841020000062, 11.369279751000022 ],
							[ 9.877816273000064, 11.371579971000074 ],
							[ 9.877792087000046, 11.373880191000069 ],
							[ 9.87927355000005, 11.376422068000068 ],
							[ 9.878044335000027, 11.378273705000026 ],
							[ 9.880036371000074, 11.383119605000047 ],
							[ 9.880000104000032, 11.386570218000031 ],
							[ 9.879976536000072, 11.388880043000029 ],
							[ 9.88110996000006, 11.392350467000028 ],
							[ 9.881073717000049, 11.395799394000051 ],
							[ 9.882207737000044, 11.399269812000057 ],
							[ 9.882184160000065, 11.40157060000007 ],
							[ 9.882159997000031, 11.403869685000075 ],
							[ 9.882111700000053, 11.408479723000028 ],
							[ 9.882086976000039, 11.410779938000076 ],
							[ 9.88206281500004, 11.413080152000077 ],
							[ 9.880867764000072, 11.415370149000069 ],
							[ 9.880831519000026, 11.418820180000068 ],
							[ 9.880806791000055, 11.42113000300003 ],
							[ 9.880783202000032, 11.423430213000074 ],
							[ 9.87956336600007, 11.428019287000041 ],
							[ 9.879538626000056, 11.430320073000075 ],
							[ 9.879503515000067, 11.433779707000042 ],
							[ 9.880637069000045, 11.437239404000024 ],
							[ 9.880600825000045, 11.440699594000023 ],
							[ 9.88056457700003, 11.444150194000031 ],
							[ 9.88053982100007, 11.446450404000075 ],
							[ 9.881198631000075, 11.448464243000046 ],
							[ 9.877631657000052, 11.453771329000062 ],
							[ 9.873342251000054, 11.455664635000062 ],
							[ 9.865712814000062, 11.457094452000035 ],
							[ 9.86046710100004, 11.458048521000023 ],
							[ 9.854749381000033, 11.461356848000037 ],
							[ 9.855235858000071, 11.465121515000021 ],
							[ 9.853335098000059, 11.46842158000004 ],
							[ 9.849042072000032, 11.468902071000059 ],
							[ 9.84570913500005, 11.471734147000063 ],
							[ 9.840469724000059, 11.475511828000037 ],
							[ 9.837604940000062, 11.474577004000025 ],
							[ 9.829943033000063, 11.462354448000042 ],
							[ 9.824204062000035, 11.45624762500006 ],
							[ 9.821338402000038, 11.45484200900006 ],
							[ 9.81466215100005, 11.456268686000044 ],
							[ 9.81179441300003, 11.453921128000047 ],
							[ 9.80845077400005, 11.452045252000062 ],
							[ 9.803676744000029, 11.450643668000055 ],
							[ 9.800816421000036, 11.451591148000034 ],
							[ 9.79653078900003, 11.455366712000057 ],
							[ 9.795102964000023, 11.456781739000064 ],
							[ 9.791171340000062, 11.460028955000041 ],
							[ 9.789388313000075, 11.461501573000021 ],
							[ 9.787969090000047, 11.467153543000052 ],
							[ 9.786789631000033, 11.473539510000023 ],
							[ 9.786745683000049, 11.474392199000022 ],
							[ 9.786523736000049, 11.478710450000051 ],
							[ 9.786487728000054, 11.482159901000045 ],
							[ 9.785280027000056, 11.485600518000069 ],
							[ 9.785242882000034, 11.489060133000066 ],
							[ 9.784049253000035, 11.491339636000077 ],
							[ 9.785183222000057, 11.494810346000065 ],
							[ 9.78514664000005, 11.498269957000048 ],
							[ 9.783951837000075, 11.500550016000034 ],
							[ 9.783916387000033, 11.50401019800006 ],
							[ 9.782708025000034, 11.507450247000065 ],
							[ 9.782683224000039, 11.509750439000072 ],
							[ 9.781463580000036, 11.514340289000074 ],
							[ 9.780269865000037, 11.51662994000003 ],
							[ 9.779719463000049, 11.520073112000034 ],
							[ 9.777829857000029, 11.525810190000072 ],
							[ 9.777780776000043, 11.530420174000028 ],
							[ 9.778928915000051, 11.532730345000061 ],
							[ 9.78123365700003, 11.536209892000045 ],
							[ 9.783539047000033, 11.539699588000076 ],
							[ 9.787027557000044, 11.542039797000029 ],
							[ 9.791662109000072, 11.546699716000035 ],
							[ 9.79607191100007, 11.548603960000037 ],
							[ 9.797425195000073, 11.551400088000037 ],
							[ 9.803297205000035, 11.553750291000028 ],
							[ 9.80561463600003, 11.556079858000032 ],
							[ 9.810273094000024, 11.558440486000052 ],
							[ 9.813749105000056, 11.561940431000039 ],
							[ 9.816054370000074, 11.565420330000052 ],
							[ 9.818371957000068, 11.56774981500007 ],
							[ 9.819506515000057, 11.571220401000062 ],
							[ 9.820625062000033, 11.576218206000021 ],
							[ 9.820683684000073, 11.579079829000023 ],
							[ 9.820599225000024, 11.582751405000067 ],
							[ 9.82051941900005, 11.586199801000021 ],
							[ 9.821495412000047, 11.588433361000057 ],
							[ 9.821798125000043, 11.588799380000069 ],
							[ 9.824668356000075, 11.593493285000022 ],
							[ 9.825468643000022, 11.597779317000061 ],
							[ 9.824687038000036, 11.601678994000054 ],
							[ 9.821140919000072, 11.606363953000027 ],
							[ 9.819856498000036, 11.608592895000072 ],
							[ 9.818085339000049, 11.611665903000073 ],
							[ 9.818371428000034, 11.619611461000034 ],
							[ 9.81599394400007, 11.623382418000062 ],
							[ 9.815045337000072, 11.626208957000074 ],
							[ 9.821741857000063, 11.632314293000036 ],
							[ 9.825095815000054, 11.637955749000071 ],
							[ 9.826386042000024, 11.644742506000057 ],
							[ 9.825719040000024, 11.648440225000058 ],
							[ 9.824522854000065, 11.650730003000035 ],
							[ 9.823304410000048, 11.655319943000052 ],
							[ 9.822109349000073, 11.657609710000031 ],
							[ 9.822073083000021, 11.661060251000038 ],
							[ 9.820865704000028, 11.664500385000053 ],
							[ 9.820804315000032, 11.670260704000043 ],
							[ 9.820768614000031, 11.673710111000048 ],
							[ 9.820719497000027, 11.678320036000059 ],
							[ 9.820670920000055, 11.68292038800007 ],
							[ 9.820613569000045, 11.688444509000021 ],
							[ 9.819961706000072, 11.689278185000035 ],
							[ 9.81042737000007, 11.695419188000074 ],
							[ 9.804703901000039, 11.697785411000041 ],
							[ 9.803278290000037, 11.700612919000037 ],
							[ 9.810937987000045, 11.710010863000036 ],
							[ 9.811440700000048, 11.721308037000028 ],
							[ 9.805250458000046, 11.728852921000055 ],
							[ 9.797625376000042, 11.73498941500003 ],
							[ 9.775669238000035, 11.738330973000075 ],
							[ 9.764206465000029, 11.736471593000033 ],
							[ 9.753696556000023, 11.733668646000069 ],
							[ 9.741747439000051, 11.727573203000077 ],
							[ 9.727415853000025, 11.723364608000054 ],
							[ 9.714042045000042, 11.720566134000023 ],
							[ 9.695426687000065, 11.722955219000028 ],
							[ 9.674425588000076, 11.726289009000027 ],
							[ 9.666702419000046, 11.729206473000033 ],
							[ 9.666157840000039, 11.729411972000037 ],
							[ 9.664443834000053, 11.729390176000038 ],
							[ 9.660931594000033, 11.72935011900006 ],
							[ 9.655053366000061, 11.731579838000073 ],
							[ 9.652711846000045, 11.731550059000028 ],
							[ 9.649198442000056, 11.731510449000041 ],
							[ 9.645674474000032, 11.732620032000057 ],
							[ 9.642137640000044, 11.734880497000063 ],
							[ 9.639783808000061, 11.735999849000052 ],
							[ 9.637418830000058, 11.738280299000053 ],
							[ 9.635052676000043, 11.740549979000036 ],
							[ 9.632674179000048, 11.743980184000065 ],
							[ 9.630284463000066, 11.748550546000047 ],
							[ 9.629076673000043, 11.751990588000069 ],
							[ 9.62788179000006, 11.75428028500005 ],
							[ 9.626674541000057, 11.757719760000043 ],
							[ 9.626651022000033, 11.760020472000065 ],
							[ 9.627784901000041, 11.763489893000042 ],
							[ 9.628919367000037, 11.766959864000057 ],
							[ 9.631188347000034, 11.773889648000022 ],
							[ 9.632322315000067, 11.777360181000063 ],
							[ 9.633457431000068, 11.780830134000041 ],
							[ 9.635774660000038, 11.783160170000031 ],
							[ 9.638091917000054, 11.785490208000056 ],
							[ 9.643896907000055, 11.790169637000076 ],
							[ 9.647398051000039, 11.791359654000075 ],
							[ 9.649727115000076, 11.792539820000059 ],
							[ 9.652067885000065, 11.792570193000074 ],
							[ 9.652844050000056, 11.792963191000069 ],
							[ 9.657829350000043, 11.78892767800005 ],
							[ 9.66260210400003, 11.787507284000071 ],
							[ 9.668328797000072, 11.785613901000033 ],
							[ 9.672148708000066, 11.785607012000071 ],
							[ 9.673584166000069, 11.787016883000035 ],
							[ 9.673112310000022, 11.79031279700007 ],
							[ 9.672645411000076, 11.795962466000049 ],
							[ 9.671227110000075, 11.803496976000076 ],
							[ 9.671725562000063, 11.814793690000045 ],
							[ 9.671262112000022, 11.822326462000035 ],
							[ 9.673663197000053, 11.829383412000027 ],
							[ 9.678931595000051, 11.837376390000031 ],
							[ 9.679896310000061, 11.842552776000048 ],
							[ 9.679455139000027, 11.861853735000068 ],
							[ 9.684245741000041, 11.869376840000029 ],
							[ 9.688550346000056, 11.872663931000034 ],
							[ 9.698106891000066, 11.874529222000035 ],
							[ 9.700966281000035, 11.871228818000077 ],
							[ 9.707167014000049, 11.866980186000035 ],
							[ 9.711931324000034, 11.860851659000048 ],
							[ 9.717654243000027, 11.856603782000036 ],
							[ 9.72386358600005, 11.856591741000045 ],
							[ 9.731516352000028, 11.862226111000041 ],
							[ 9.759248366000065, 11.87629247700005 ],
							[ 9.793654964000041, 11.883752512000058 ],
							[ 9.829971612000065, 11.890263258000061 ],
							[ 9.837621850000062, 11.893541692000042 ],
							[ 9.840498726000021, 11.898242142000072 ],
							[ 9.848667739000064, 11.918936135000024 ],
							[ 9.859208427000056, 11.932092584000031 ],
							[ 9.872124939000059, 11.939594390000025 ],
							[ 9.875485984000022, 11.946647734000067 ],
							[ 9.882012386000042, 11.950445418000072 ],
							[ 9.883422094000025, 11.95081043600004 ],
							[ 9.889263978000031, 11.952030456000045 ],
							[ 9.891605221000077, 11.952059876000021 ],
							[ 9.89394763100006, 11.95209040900005 ],
							[ 9.894061326000042, 11.952091617000065 ],
							[ 9.898630095000044, 11.952140141000029 ],
							[ 9.902130804000024, 11.95333959900006 ],
							[ 9.906777281000075, 11.956849871000031 ],
							[ 9.911460954000063, 11.95689943900004 ],
							[ 9.913790809000034, 11.958080199000051 ],
							[ 9.920778185000074, 11.961620136000022 ],
							[ 9.924291594000067, 11.961660572000028 ],
							[ 9.926632902000051, 11.961689771000067 ],
							[ 9.928961689000062, 11.962869865000073 ],
							[ 9.932462606000058, 11.964070184000036 ],
							[ 9.937133900000049, 11.965269780000028 ],
							[ 9.944146012000033, 11.966509703000042 ],
							[ 9.947647009000036, 11.967700271000069 ],
							[ 9.951158756000041, 11.967749492000053 ],
							[ 9.958208231000071, 11.965530184000045 ],
							[ 9.961732955000059, 11.964420489000076 ],
							[ 9.965257637000036, 11.963310187000047 ],
							[ 9.969927895000069, 11.964520668000034 ],
							[ 9.972221414000046, 11.969149861000062 ],
							[ 9.976868758000023, 11.972659810000039 ],
							[ 9.980381135000073, 11.972700291000024 ],
							[ 9.988563399000043, 11.973960023000075 ],
							[ 9.992064595000045, 11.975150154000062 ],
							[ 9.995564668000043, 11.976340273000062 ],
							[ 10.003740504000064, 11.978739844000074 ],
							[ 10.009579823000024, 11.979969829000026 ],
							[ 10.015410363000058, 11.982339904000071 ],
							[ 10.020079891000023, 11.983550312000034 ],
							[ 10.024740106000024, 11.985910451000052 ],
							[ 10.028240390000065, 11.987099688000058 ],
							[ 10.03524982600004, 11.988340158000028 ],
							[ 10.039920051000024, 11.989540169000065 ],
							[ 10.046919700000046, 11.99193019300003 ],
							[ 10.05041955400003, 11.993120351000073 ],
							[ 10.053920024000035, 11.994320102000074 ],
							[ 10.058589836000067, 11.995530025000051 ],
							[ 10.063249332000055, 11.997890257000051 ],
							[ 10.065589713000065, 11.997909532000051 ],
							[ 10.067919777000043, 11.999090453000065 ],
							[ 10.071430115000055, 12.000289991000045 ],
							[ 10.07841011000005, 12.003829816000064 ],
							[ 10.081910166000057, 12.005020251000076 ],
							[ 10.085410263000028, 12.006210078000038 ],
							[ 10.087739858000077, 12.007389746000058 ],
							[ 10.091240028000072, 12.008589683000025 ],
							[ 10.094740192000074, 12.009779996000077 ],
							[ 10.097060146000047, 12.012109953000049 ],
							[ 10.095870223000077, 12.014399400000059 ],
							[ 10.095830224000053, 12.017849899000055 ],
							[ 10.095769725000025, 12.02361012800003 ],
							[ 10.09922954700005, 12.028260486000022 ],
							[ 10.102729907000025, 12.029450166000061 ],
							[ 10.106249733000027, 12.029490550000048 ],
							[ 10.10977005500007, 12.028390209000065 ],
							[ 10.112110056000063, 12.028409766000038 ],
							[ 10.115619566000021, 12.028459700000042 ],
							[ 10.121480001000066, 12.028530215000046 ],
							[ 10.122630216000061, 12.03083980200006 ],
							[ 10.126079685000036, 12.036640258000034 ],
							[ 10.128410193000036, 12.037819667000065 ],
							[ 10.130740134000064, 12.038999637000074 ],
							[ 10.13424972100006, 12.039039787000036 ],
							[ 10.138940094000077, 12.037949725000033 ],
							[ 10.141300085000069, 12.036830033000058 ],
							[ 10.14363958000007, 12.036849967000023 ],
							[ 10.14713966000005, 12.038049951000062 ],
							[ 10.150639711000053, 12.039239737000059 ],
							[ 10.154140382000037, 12.040439653000021 ],
							[ 10.154469998000025, 12.041101912000045 ],
							[ 10.15459303800003, 12.043708951000042 ],
							[ 10.15597936000006, 12.049099138000031 ],
							[ 10.16383750600005, 12.058499170000061 ],
							[ 10.164225589000068, 12.058962939000025 ],
							[ 10.16427199900005, 12.073347322000075 ],
							[ 10.161562649000075, 12.082346400000063 ],
							[ 10.151614198000061, 12.111146920000067 ],
							[ 10.147098312000026, 12.126444800000058 ],
							[ 10.145344250000051, 12.148926362000054 ],
							[ 10.152756674000045, 12.183067334000043 ],
							[ 10.168490571000063, 12.248648551000031 ],
							[ 10.173097165000058, 12.261220646000027 ],
							[ 10.192357829000059, 12.286332558000026 ],
							[ 10.193169149000028, 12.287653705000025 ],
							[ 10.200622449000036, 12.299791412000047 ],
							[ 10.195654162000039, 12.327196580000077 ],
							[ 10.195240764000062, 12.329476743000043 ],
							[ 10.196189766000032, 12.340262461000066 ],
							[ 10.208103433000076, 12.351911033000022 ],
							[ 10.22368388600006, 12.367143823000049 ],
							[ 10.233765923000021, 12.376999845000057 ],
							[ 10.242043441000021, 12.393154841000069 ],
							[ 10.247558535000053, 12.40302566500003 ],
							[ 10.248680093000075, 12.403120210000054 ],
							[ 10.250969662000045, 12.407750384000053 ],
							[ 10.25325988700007, 12.412389575000077 ],
							[ 10.25557025300003, 12.415869935000046 ],
							[ 10.25669961400007, 12.419340125000076 ],
							[ 10.258995069000036, 12.422800742000049 ],
							[ 10.261329724000063, 12.425149894000072 ],
							[ 10.264789786000051, 12.429800298000032 ],
							[ 10.265930191000052, 12.433270438000022 ],
							[ 10.26825051700007, 12.434450111000046 ],
							[ 10.274049486000024, 12.440270414000054 ],
							[ 10.277520152000022, 12.443770374000053 ],
							[ 10.281009718000064, 12.446109790000037 ],
							[ 10.283340509000027, 12.447290461000023 ],
							[ 10.285659945000077, 12.449629921000053 ],
							[ 10.287989588000073, 12.450799830000051 ],
							[ 10.296150172000068, 12.454360209000072 ],
							[ 10.298489543000073, 12.45438934200007 ],
							[ 10.301989554000045, 12.455579943000032 ],
							[ 10.305499776000033, 12.455619616000035 ],
							[ 10.310159942000041, 12.457980088000056 ],
							[ 10.313659495000024, 12.45917961400005 ],
							[ 10.318329872000049, 12.460379481000075 ],
							[ 10.324170049000031, 12.461609602000067 ],
							[ 10.327670214000022, 12.46279996100003 ],
							[ 10.331169820000071, 12.463990288000048 ],
							[ 10.335849540000027, 12.465200639000045 ],
							[ 10.337584372000038, 12.465649792000022 ],
							[ 10.340520105000053, 12.466409836000025 ],
							[ 10.344019807000052, 12.467600042000072 ],
							[ 10.348699321000026, 12.467659968000021 ],
							[ 10.352210250000041, 12.467699773000049 ],
							[ 10.356880326000066, 12.468910469000036 ],
							[ 10.360380106000036, 12.470100503000026 ],
							[ 10.371110742000042, 12.472255500000074 ],
							[ 10.375550519000058, 12.472821041000032 ],
							[ 10.379010202000075, 12.473261902000047 ],
							[ 10.387299600000063, 12.471579717000054 ],
							[ 10.389639680000073, 12.471609960000023 ],
							[ 10.394330129000025, 12.471659664000072 ],
							[ 10.399009725000042, 12.471719513000039 ],
							[ 10.401349809000067, 12.471750257000053 ],
							[ 10.406029973000045, 12.471810570000059 ],
							[ 10.410719863000054, 12.471860069000059 ],
							[ 10.413059966000048, 12.471890184000074 ],
							[ 10.416569777000063, 12.471929387000046 ],
							[ 10.421270120000031, 12.470840296000063 ],
							[ 10.424779923000074, 12.470879421000063 ],
							[ 10.428339842000071, 12.466320018000033 ],
							[ 10.42838011300006, 12.462859994000041 ],
							[ 10.429570009000031, 12.460579720000055 ],
							[ 10.429620063000073, 12.455969928000059 ],
							[ 10.432049757000073, 12.447939692000034 ],
							[ 10.432069895000041, 12.44563961700004 ],
							[ 10.43327060300004, 12.443349678000061 ],
							[ 10.433289586000058, 12.441049606000036 ],
							[ 10.433309696000038, 12.438739921000035 ],
							[ 10.434530475000031, 12.43414991800006 ],
							[ 10.435730025000055, 12.431870712000034 ],
							[ 10.439289962000032, 12.427300469000045 ],
							[ 10.444000528000061, 12.425060184000074 ],
							[ 10.44635001100005, 12.42393015500005 ],
							[ 10.451029565000056, 12.423990478000064 ],
							[ 10.458029507000049, 12.426380078000022 ],
							[ 10.461540106000029, 12.427570269000057 ],
							[ 10.463859327000023, 12.42874985900005 ],
							[ 10.466190607000044, 12.429929962000074 ],
							[ 10.468540190000056, 12.429960245000075 ],
							[ 10.472039946000052, 12.431150381000066 ],
							[ 10.476710534000063, 12.432359555000062 ],
							[ 10.484870226000055, 12.435910165000053 ],
							[ 10.491850120000038, 12.439450052000041 ],
							[ 10.493000040000027, 12.441769750000049 ],
							[ 10.496470724000062, 12.446419649000063 ],
							[ 10.504550118000054, 12.456879754000056 ],
							[ 10.505700137000076, 12.459190357000068 ],
							[ 10.509149550000075, 12.464989951000064 ],
							[ 10.511469970000064, 12.467320652000069 ],
							[ 10.517259657000068, 12.473150203000046 ],
							[ 10.521909649000065, 12.476660019000064 ],
							[ 10.526539915000058, 12.481320160000053 ],
							[ 10.535859860000073, 12.486040016000061 ],
							[ 10.544010209000021, 12.49073962600005 ],
							[ 10.546349285000076, 12.490770611000073 ],
							[ 10.552180190000058, 12.493149720000076 ],
							[ 10.561519488000044, 12.495559823000065 ],
							[ 10.565019672000062, 12.496760374000075 ],
							[ 10.569680306000066, 12.499110247000033 ],
							[ 10.573179985000024, 12.500310167000066 ],
							[ 10.577839614000027, 12.502670106000039 ],
							[ 10.581330562000062, 12.505009533000077 ],
							[ 10.584819892000041, 12.50736022600006 ],
							[ 10.58829014500003, 12.510849939000025 ],
							[ 10.590599840000039, 12.514340635000053 ],
							[ 10.592900348000057, 12.51782061800003 ],
							[ 10.594050542000048, 12.520130385000073 ],
							[ 10.597049646000073, 12.520150303000037 ],
							[ 10.600750079000022, 12.520710111000028 ],
							[ 10.604439589000037, 12.521269948000054 ],
							[ 10.610470025000041, 12.521450131000051 ],
							[ 10.613969601000065, 12.520879611000055 ],
							[ 10.618430383000032, 12.518999794000024 ],
							[ 10.621929974000068, 12.518440510000062 ],
							[ 10.625229688000047, 12.51673968700004 ],
							[ 10.62872980700007, 12.516179762000036 ],
							[ 10.633199660000059, 12.514300302000038 ],
							[ 10.634939946000031, 12.510540147000029 ],
							[ 10.636480418000076, 12.505659938000065 ],
							[ 10.639590534000035, 12.502840045000028 ],
							[ 10.641519648000042, 12.500220256000034 ],
							[ 10.642988742000057, 12.498229039000023 ],
							[ 10.66239291100004, 12.492790944000035 ],
							[ 10.678076339000029, 12.487569746000077 ],
							[ 10.705496445000051, 12.473279048000052 ],
							[ 10.731636716000025, 12.465431834000071 ],
							[ 10.742107331000057, 12.465382989000034 ],
							[ 10.75125057200006, 12.461476795000067 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Bayelsa',
				admin1Pcod: 'NG006',
				admin1RefN: 'Bayelsa',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 5.046708,
				Shape_Area: 0.776768
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.552827764000028, 5.379875148000053 ],
							[ 6.561977843000022, 5.379249111000036 ],
							[ 6.569632174000049, 5.372142817000054 ],
							[ 6.569457923000073, 5.362852983000039 ],
							[ 6.568703251000045, 5.347066905000077 ],
							[ 6.567076170000064, 5.333620115000031 ],
							[ 6.563160091000043, 5.32021287200007 ],
							[ 6.555650307000064, 5.310586968000052 ],
							[ 6.548624035000046, 5.30234719300006 ],
							[ 6.541174890000036, 5.295972834000054 ],
							[ 6.530881853000039, 5.284537818000047 ],
							[ 6.532513007000034, 5.273820874000023 ],
							[ 6.53738488700003, 5.264905889000033 ],
							[ 6.547786345000077, 5.257751878000022 ],
							[ 6.566636039000059, 5.236972756000057 ],
							[ 6.552762079000047, 5.226540550000038 ],
							[ 6.549229987000047, 5.212652128000059 ],
							[ 6.555801570000028, 5.202908291000028 ],
							[ 6.558826444000033, 5.198422927000024 ],
							[ 6.553381041000023, 5.189809867000065 ],
							[ 6.544492122000065, 5.186294112000041 ],
							[ 6.534927555000024, 5.186825317000057 ],
							[ 6.505704386000048, 5.186912822000068 ],
							[ 6.494805102000043, 5.186838139000031 ],
							[ 6.48333737300004, 5.18128667000002 ],
							[ 6.47913236200003, 5.176393121000046 ],
							[ 6.476735290000022, 5.172728119000055 ],
							[ 6.476175589000036, 5.166035563000037 ],
							[ 6.476906276000022, 5.147798629000022 ],
							[ 6.477923943000064, 5.137354643000037 ],
							[ 6.472981060000052, 5.128497111000058 ],
							[ 6.46433685300002, 5.107274080000025 ],
							[ 6.46049877400003, 5.098048190000043 ],
							[ 6.451053017000049, 5.082880021000051 ],
							[ 6.443903895000062, 5.068137169000067 ],
							[ 6.439036777000069, 5.052887901000076 ],
							[ 6.429607929000042, 5.038650064000024 ],
							[ 6.425769755000033, 5.029424203000076 ],
							[ 6.417274030000044, 5.016098009000075 ],
							[ 6.411561096000071, 5.004581920000021 ],
							[ 6.41039192300002, 4.991127041000027 ],
							[ 6.421666074000029, 4.981634116000066 ],
							[ 6.430676116000029, 4.973575129000039 ],
							[ 6.437415137000073, 4.966485961000046 ],
							[ 6.440402, 4.954815865000057 ],
							[ 6.440640877000021, 4.943194856000048 ],
							[ 6.433986244000039, 4.930301160000056 ],
							[ 6.425559889000056, 4.920691026000043 ],
							[ 6.420719172000076, 4.906836953000038 ],
							[ 6.414565935000041, 4.896256907000065 ],
							[ 6.406606140000065, 4.887104045000058 ],
							[ 6.400884182000027, 4.875124 ],
							[ 6.398394078000024, 4.864480033000063 ],
							[ 6.398606663000066, 4.851465241000028 ],
							[ 6.399278997000067, 4.838441870000054 ],
							[ 6.402275130000021, 4.827237141000069 ],
							[ 6.408864901000072, 4.812250166000069 ],
							[ 6.412812170000052, 4.802886966000074 ],
							[ 6.42286383000004, 4.777152034000039 ],
							[ 6.429982289000066, 4.76587390800006 ],
							[ 6.441421939000065, 4.745329842000046 ],
							[ 6.445941947000051, 4.730044812000074 ],
							[ 6.459428911000032, 4.723997094000026 ],
							[ 6.461059938000062, 4.713280215000054 ],
							[ 6.478478833000054, 4.713902021000024 ],
							[ 6.484190041000033, 4.718555336000065 ],
							[ 6.489644139000063, 4.722999094000045 ],
							[ 6.498985761000029, 4.73259209500003 ],
							[ 6.501528294000025, 4.734480056000052 ],
							[ 6.510599994000074, 4.741216179000048 ],
							[ 6.513673766000068, 4.743821540000056 ],
							[ 6.517582007000044, 4.747134172000074 ],
							[ 6.523535940000045, 4.75072559900002 ],
							[ 6.527311746000066, 4.753003140000033 ],
							[ 6.537438875000021, 4.755611901000066 ],
							[ 6.552611951000074, 4.758596881000074 ],
							[ 6.558354701000042, 4.758495523000022 ],
							[ 6.560086142000046, 4.75662636900006 ],
							[ 6.560086313000056, 4.756626184000027 ],
							[ 6.560086333000072, 4.756626072000074 ],
							[ 6.560866727000075, 4.752285268000037 ],
							[ 6.560520965000023, 4.747747827000069 ],
							[ 6.561107326000069, 4.744350421000036 ],
							[ 6.56110734300006, 4.744350402000066 ],
							[ 6.564707177000059, 4.740028283000072 ],
							[ 6.568485950000024, 4.737030097000059 ],
							[ 6.577718654000023, 4.73350128900006 ],
							[ 6.581958628000052, 4.733942269000067 ],
							[ 6.583541002000061, 4.73410683700007 ],
							[ 6.583541075000028, 4.734106836000024 ],
							[ 6.588996217000044, 4.73338714700003 ],
							[ 6.598032991000025, 4.729859005000037 ],
							[ 6.597178154000062, 4.724484541000038 ],
							[ 6.596590891000062, 4.720792374000041 ],
							[ 6.592882007000071, 4.713975033000054 ],
							[ 6.58316333700003, 4.707872623000071 ],
							[ 6.577980166000032, 4.702214123000033 ],
							[ 6.572729429000049, 4.696481774000063 ],
							[ 6.570113197000069, 4.691517846000067 ],
							[ 6.567539851000049, 4.686635255000056 ],
							[ 6.564538758000026, 4.681528940000021 ],
							[ 6.563086471000076, 4.679057880000073 ],
							[ 6.562684434000062, 4.67402077600002 ],
							[ 6.561700123000037, 4.661689093000064 ],
							[ 6.561381137000069, 4.657120089000045 ],
							[ 6.561014770000043, 4.651872605000051 ],
							[ 6.562200294000036, 4.640178518000027 ],
							[ 6.562620498000058, 4.636033264000048 ],
							[ 6.564756458000033, 4.62727048000005 ],
							[ 6.566477623000026, 4.620209051000074 ],
							[ 6.581659807000051, 4.593893458000025 ],
							[ 6.596800444000053, 4.573615036000035 ],
							[ 6.602881768000032, 4.561578905000033 ],
							[ 6.602916128000061, 4.556295753000029 ],
							[ 6.599307121000038, 4.534383826000067 ],
							[ 6.588290293000057, 4.498082280000062 ],
							[ 6.578666666000061, 4.478394498000057 ],
							[ 6.575680949000059, 4.476110319000043 ],
							[ 6.563738489000059, 4.466973415000041 ],
							[ 6.554091944000049, 4.451058622000062 ],
							[ 6.551163630000076, 4.440649791000055 ],
							[ 6.547496962000025, 4.427616445000069 ],
							[ 6.549867443000039, 4.409517992000076 ],
							[ 6.551259239000046, 4.400161002000061 ],
							[ 6.552222639000036, 4.39368369500005 ],
							[ 6.560587202000022, 4.376380177000044 ],
							[ 6.572655872000041, 4.365894123000032 ],
							[ 6.582429672000046, 4.362185296000064 ],
							[ 6.586623945000042, 4.359690277000027 ],
							[ 6.589958547000037, 4.357706607000068 ],
							[ 6.595960687000058, 4.346147274000032 ],
							[ 6.601829683000062, 4.334844726000028 ],
							[ 6.598922257000027, 4.334255227000028 ],
							[ 6.597199445000058, 4.332003116000067 ],
							[ 6.594865323000022, 4.330317508000064 ],
							[ 6.591883180000025, 4.329020982000031 ],
							[ 6.588511937000021, 4.327853684000047 ],
							[ 6.586048609000045, 4.327335366000057 ],
							[ 6.580084332000069, 4.327205654000068 ],
							[ 6.569970604000048, 4.327724452000041 ],
							[ 6.565899207000029, 4.327665464000063 ],
							[ 6.561024197000052, 4.327594767000051 ],
							[ 6.552985200000023, 4.327075970000067 ],
							[ 6.551705259000073, 4.327123221000022 ],
							[ 6.538155289000031, 4.325009213000044 ],
							[ 6.528197883000075, 4.323508504000074 ],
							[ 6.523378688000037, 4.323475742000028 ],
							[ 6.518336716000022, 4.323992634000035 ],
							[ 6.516706937000038, 4.32430916900006 ],
							[ 6.512401588000046, 4.324093819000041 ],
							[ 6.506566528000064, 4.323834416000068 ],
							[ 6.498657227000024, 4.32292699900006 ],
							[ 6.493730552000045, 4.322278509000057 ],
							[ 6.485172742000032, 4.320463179000058 ],
							[ 6.481282706000059, 4.31903697100006 ],
							[ 6.469188690000067, 4.315401565000059 ],
							[ 6.463130475000071, 4.315406318000043 ],
							[ 6.454832081000063, 4.317610742000056 ],
							[ 6.451590532000068, 4.31722164100006 ],
							[ 6.447192677000032, 4.317646025000045 ],
							[ 6.443421840000042, 4.321111687000041 ],
							[ 6.441477297000063, 4.323964129000046 ],
							[ 6.438754083000049, 4.325130941000054 ],
							[ 6.434605116000057, 4.326946270000064 ],
							[ 6.431480884000052, 4.32931757700004 ],
							[ 6.423577787000056, 4.327324876000034 ],
							[ 6.412562842000057, 4.317221653000047 ],
							[ 6.414118766000058, 4.31514740700004 ],
							[ 6.41684151100003, 4.313591485000074 ],
							[ 6.422287470000072, 4.311387070000023 ],
							[ 6.422935478000056, 4.308404925000048 ],
							[ 6.420342444000028, 4.30607081200003 ],
							[ 6.412562843000046, 4.305033680000065 ],
							[ 6.408284191000064, 4.304514895000068 ],
							[ 6.39959668900002, 4.305682193000052 ],
							[ 6.390650276000031, 4.304385183000022 ],
							[ 6.384426591000022, 4.303477766000071 ],
							[ 6.378592004000041, 4.303348070000027 ],
							[ 6.373405459000026, 4.302440654000065 ],
							[ 6.364069932000064, 4.302181242000074 ],
							[ 6.351622587000065, 4.301014418000022 ],
							[ 6.349158768000052, 4.30114364700006 ],
							[ 6.330098629000076, 4.29971742400005 ],
							[ 6.326986782000063, 4.299199108000039 ],
							[ 6.320244795000065, 4.298550615000067 ],
							[ 6.313502304000053, 4.296994688000041 ],
							[ 6.304685107000068, 4.295957569000052 ],
							[ 6.297164910000049, 4.294661057000042 ],
							[ 6.294182780000028, 4.294271940000044 ],
							[ 6.292108065000036, 4.293753146000029 ],
							[ 6.292082529000027, 4.293752845000029 ],
							[ 6.29070921400006, 4.293326957000033 ],
							[ 6.289496261000068, 4.293012089000058 ],
							[ 6.288736749000066, 4.293006431000038 ],
							[ 6.286861068000064, 4.293298288000074 ],
							[ 6.277433974000076, 4.293516645000068 ],
							[ 6.27667856000005, 4.293493749000049 ],
							[ 6.273177624000027, 4.292845729000021 ],
							[ 6.268639567000037, 4.292586334000021 ],
							[ 6.257359030000032, 4.292456636000054 ],
							[ 6.253858562000062, 4.292586333000031 ],
							[ 6.249579426000025, 4.293364050000037 ],
							[ 6.246986384000024, 4.293623443000058 ],
							[ 6.244781967000051, 4.294661047000034 ],
							[ 6.240503306000051, 4.295568475000039 ],
							[ 6.238169668000069, 4.295698162000065 ],
							[ 6.235576155000047, 4.297902117000035 ],
							[ 6.235576144000049, 4.30062532900007 ],
							[ 6.221183774000053, 4.295698171000026 ],
							[ 6.222091681000052, 4.292067527000029 ],
							[ 6.222739702000069, 4.29012251000006 ],
							[ 6.221702576000041, 4.28506612700005 ],
							[ 6.218590729000027, 4.282732011000064 ],
							[ 6.216127388000075, 4.28130578400004 ],
							[ 6.214182378000032, 4.280786999000043 ],
							[ 6.208866120000039, 4.281565187000069 ],
							[ 6.203809258000035, 4.280657286000064 ],
							[ 6.199790005000068, 4.279490470000042 ],
							[ 6.193959243000052, 4.278836252000076 ],
							[ 6.191018098000029, 4.278234484000052 ],
							[ 6.188678262000053, 4.278568739000036 ],
							[ 6.185470111000029, 4.279237275000071 ],
							[ 6.181993967000039, 4.279237265000063 ],
							[ 6.178518302000043, 4.277967463000039 ],
							[ 6.17490863300003, 4.277967462000049 ],
							[ 6.170897962000026, 4.277232171000037 ],
							[ 6.168357843000024, 4.277365694000025 ],
							[ 6.164681429000041, 4.277365684000074 ],
							[ 6.161673059000066, 4.276964662000069 ],
							[ 6.156353956000032, 4.27676772600006 ],
							[ 6.149841778000052, 4.274959098000068 ],
							[ 6.147017963000053, 4.274693011000068 ],
							[ 6.14157246700006, 4.274693024000044 ],
							[ 6.136758325000073, 4.275572300000022 ],
							[ 6.133533468000053, 4.275341515000036 ],
							[ 6.130162234000068, 4.274693007000053 ],
							[ 6.126531606000071, 4.275211819000049 ],
							[ 6.120567320000021, 4.275471216000028 ],
							[ 6.116418368000041, 4.274952408000047 ],
							[ 6.113954536000051, 4.274952416000076 ],
							[ 6.107342240000037, 4.275082105000024 ],
							[ 6.103063110000051, 4.273396489000049 ],
							[ 6.09956264300007, 4.273007390000032 ],
							[ 6.096450799000024, 4.274044995000054 ],
							[ 6.091782561000059, 4.274952420000034 ],
							[ 6.087244515000066, 4.273785600000053 ],
							[ 6.083873276000077, 4.274044991000039 ],
							[ 6.082317352000075, 4.275730620000047 ],
							[ 6.082317348000061, 4.27884245100006 ],
							[ 6.082965846000036, 4.282472606000056 ],
							[ 6.074015140000029, 4.290771480000046 ],
							[ 6.070288653000034, 4.289675724000062 ],
							[ 6.065027244000021, 4.289237034000053 ],
							[ 6.059985640000036, 4.289456373000064 ],
							[ 6.055601114000069, 4.291429513000026 ],
							[ 6.048586373000035, 4.293182844000057 ],
							[ 6.04135227300003, 4.294279093000057 ],
							[ 6.029006008000067, 4.297628889000066 ],
							[ 6.019431117000067, 4.301513193000062 ],
							[ 6.01438904400004, 4.300636285000053 ],
							[ 6.010223865000057, 4.301951405000068 ],
							[ 6.007593632000066, 4.304362768000033 ],
							[ 6.003866680000044, 4.30633593500005 ],
							[ 6.000797739000063, 4.30896616900003 ],
							[ 5.993344314000069, 4.309624192000058 ],
							[ 5.98830269900003, 4.311816219000036 ],
							[ 5.982383733000063, 4.312254424000059 ],
							[ 5.976465234000045, 4.314446924000038 ],
							[ 5.975588329000061, 4.316858285000023 ],
							[ 5.975588328000072, 4.319927226000061 ],
							[ 5.978218563000041, 4.32628440700006 ],
							[ 5.967257972000027, 4.337902548000045 ],
							[ 5.964408405000029, 4.337244984000051 ],
							[ 5.957612509000057, 4.340752599000041 ],
							[ 5.952351577000059, 4.343163495000056 ],
							[ 5.949282653000068, 4.343821528000035 ],
							[ 5.938760286000047, 4.349740023000038 ],
							[ 5.928019039000048, 4.355878361000066 ],
							[ 5.913989065000067, 4.361358652000035 ],
							[ 5.908926017000056, 4.364295008000056 ],
							[ 5.905659203000027, 4.365304468000033 ],
							[ 5.894698147000042, 4.370346070000039 ],
							[ 5.881262777000074, 4.37918806700003 ],
							[ 5.870146270000021, 4.386568074000024 ],
							[ 5.858768182000063, 4.394226527000058 ],
							[ 5.847348323000062, 4.401913165000053 ],
							[ 5.844059987000037, 4.404543397000054 ],
							[ 5.83682574200003, 4.413531302000024 ],
							[ 5.831784305000042, 4.417257779000067 ],
							[ 5.828057220000062, 4.421203613000046 ],
							[ 5.822357611000029, 4.427560810000045 ],
							[ 5.817754249000075, 4.431287768000061 ],
							[ 5.808547529000066, 4.438740734000021 ],
							[ 5.799778916000037, 4.445317254000031 ],
							[ 5.794956108000065, 4.448386184000071 ],
							[ 5.778953585000068, 4.46088168600005 ],
							[ 5.774788428000022, 4.464827566000054 ],
							[ 5.769088621000037, 4.468334689000073 ],
							[ 5.75571689800006, 4.477541926000072 ],
							[ 5.745194357000059, 4.485652445000028 ],
							[ 5.741687227000057, 4.489160086000027 ],
							[ 5.735549021000054, 4.492229001000055 ],
							[ 5.728534299000046, 4.498586172000046 ],
							[ 5.721300194000037, 4.504505155000061 ],
							[ 5.713627917000053, 4.510423653000032 ],
							[ 5.712093349000043, 4.51217745200006 ],
							[ 5.70792827300005, 4.515465744000039 ],
							[ 5.704639926000027, 4.519849759000067 ],
							[ 5.702009596000039, 4.53212594300004 ],
							[ 5.69696766200002, 4.532783490000043 ],
							[ 5.692364234000024, 4.53366040700007 ],
							[ 5.687979603000031, 4.53738687200007 ],
							[ 5.682061136000073, 4.552074440000069 ],
							[ 5.675703932000033, 4.554924014000051 ],
							[ 5.671100043000024, 4.558212291000075 ],
							[ 5.666277989000037, 4.562158102000069 ],
							[ 5.663427812000066, 4.567200164000042 ],
							[ 5.662550949000035, 4.570269110000027 ],
							[ 5.654311635000056, 4.579339478000065 ],
							[ 5.647863744000063, 4.588683097000057 ],
							[ 5.640002786000025, 4.59549473900006 ],
							[ 5.63756079500007, 4.601616828000033 ],
							[ 5.634710769000037, 4.606658463000031 ],
							[ 5.625693737000063, 4.614419490000046 ],
							[ 5.616516122000064, 4.631210286000055 ],
							[ 5.609939653000026, 4.64085577000003 ],
							[ 5.60819202600004, 4.644698179000045 ],
							[ 5.605774845000042, 4.647212994000029 ],
							[ 5.602836029000059, 4.652226919000043 ],
							[ 5.594361810000066, 4.664459222000062 ],
							[ 5.591484413000046, 4.668667779000032 ],
							[ 5.588018307000027, 4.673738031000028 ],
							[ 5.581382274000021, 4.679040868000072 ],
							[ 5.580867979000061, 4.680430658000034 ],
							[ 5.578455127000041, 4.681375007000042 ],
							[ 5.572967810000023, 4.686188132000041 ],
							[ 5.571108497000068, 4.691035322000062 ],
							[ 5.570516108000049, 4.697214073000055 ],
							[ 5.567996563000065, 4.700273527000036 ],
							[ 5.561072822000028, 4.708581939000055 ],
							[ 5.556918750000023, 4.711812985000051 ],
							[ 5.550971085000072, 4.724376193000069 ],
							[ 5.546342827000046, 4.732164364000027 ],
							[ 5.544603702000074, 4.737380941000026 ],
							[ 5.545994655000072, 4.742945200000065 ],
							[ 5.54842956400006, 4.743988487000024 ],
							[ 5.551211265000063, 4.740510906000054 ],
							[ 5.553993697000067, 4.733555319000061 ],
							[ 5.553646044000061, 4.730425382000021 ],
							[ 5.558862612000041, 4.724513062000028 ],
							[ 5.562679642000035, 4.72316601600005 ],
							[ 5.566681877000065, 4.727214816000071 ],
							[ 5.564774911000029, 4.737380985000073 ],
							[ 5.559906065000064, 4.741206648000059 ],
							[ 5.545647080000037, 4.749553174000027 ],
							[ 5.539734769000063, 4.752683138000066 ],
							[ 5.53104066100002, 4.760333986000035 ],
							[ 5.533822564000047, 4.762420684000062 ],
							[ 5.533127375000049, 4.767289621000032 ],
							[ 5.531040629000074, 4.774245244000042 ],
							[ 5.526171583000064, 4.78467848300005 ],
							[ 5.520259286000055, 4.793025008000029 ],
							[ 5.51399936200005, 4.805545361000043 ],
							[ 5.510521767000057, 4.812500486000033 ],
							[ 5.505305216000068, 4.818760351000037 ],
							[ 5.500088195000046, 4.826411733000043 ],
							[ 5.492785023000067, 4.837540646000036 ],
							[ 5.480960826000057, 4.857711789000064 ],
							[ 5.475396171000057, 4.866405954000072 ],
							[ 5.470875376000038, 4.877882516000057 ],
							[ 5.468092809000041, 4.882055756000057 ],
							[ 5.463919744000066, 4.890750412000045 ],
							[ 5.46391959500005, 4.894923691000031 ],
							[ 5.46496284400007, 4.916833379000025 ],
							[ 5.457312186000024, 4.926571395000053 ],
							[ 5.451399766000065, 4.935961257000031 ],
							[ 5.448617322000075, 4.944307811000044 ],
							[ 5.448617369000033, 4.955089092000037 ],
							[ 5.449813817000063, 4.96812583600007 ],
							[ 5.447226638000075, 4.97838973100005 ],
							[ 5.442009963000032, 4.990214387000037 ],
							[ 5.439575156000046, 4.999604189000024 ],
							[ 5.436731351000049, 5.005872827000076 ],
							[ 5.42643664700006, 5.016795395000031 ],
							[ 5.405449268000041, 5.058546806000038 ],
							[ 5.390042550000032, 5.086366413000064 ],
							[ 5.388754130000052, 5.094258739000054 ],
							[ 5.386975669000037, 5.106974756000056 ],
							[ 5.386028395000039, 5.108164748000036 ],
							[ 5.381227973000023, 5.119421001000035 ],
							[ 5.380234769000026, 5.126704235000034 ],
							[ 5.382552244000067, 5.128028412000049 ],
							[ 5.386053695000044, 5.127846724000051 ],
							[ 5.388474377000023, 5.129208557000027 ],
							[ 5.390477593000071, 5.130386850000036 ],
							[ 5.390386947000025, 5.134204888000056 ],
							[ 5.393129501000033, 5.134601102000033 ],
							[ 5.396710833000043, 5.135118987000055 ],
							[ 5.401748316000067, 5.135847599000044 ],
							[ 5.40475475300002, 5.136281945000064 ],
							[ 5.413113525000028, 5.138521217000061 ],
							[ 5.416211545000067, 5.139350882000031 ],
							[ 5.418285415000071, 5.139730946000043 ],
							[ 5.424455113000022, 5.140861983000036 ],
							[ 5.428486337000038, 5.141601066000021 ],
							[ 5.432047814000043, 5.141510015000051 ],
							[ 5.436465232000046, 5.141396493000059 ],
							[ 5.441579827000055, 5.138941773000056 ],
							[ 5.447512706000055, 5.13485002300007 ],
							[ 5.453240863000076, 5.130758315000037 ],
							[ 5.458969198000034, 5.12830350400003 ],
							[ 5.465311421000024, 5.124825462000047 ],
							[ 5.469812470000022, 5.123188526000035 ],
							[ 5.472676213000057, 5.11766478800007 ],
							[ 5.47247171600003, 5.112345654000023 ],
							[ 5.469301750000056, 5.106533507000051 ],
							[ 5.467469329000039, 5.10170891000007 ],
							[ 5.469124432000058, 5.095749861000058 ],
							[ 5.470508622000068, 5.093212600000072 ],
							[ 5.472823178000056, 5.092679033000024 ],
							[ 5.475568261000035, 5.092287110000029 ],
							[ 5.479221714000062, 5.091764955000031 ],
							[ 5.485134080000023, 5.090373984000053 ],
							[ 5.493828162000057, 5.091764928000032 ],
							[ 5.502870472000041, 5.092460618000075 ],
							[ 5.511565091000023, 5.088287346000072 ],
							[ 5.516781933000061, 5.08063604900002 ],
							[ 5.524085055000057, 5.068811905000075 ],
							[ 5.533127232000027, 5.062899586000071 ],
							[ 5.544603860000052, 5.058030618000032 ],
							[ 5.550863648000075, 5.055596377000029 ],
							[ 5.569295902000022, 5.056291589000068 ],
							[ 5.58459816800007, 5.055248279000068 ],
							[ 5.585641319000047, 5.047944979000022 ],
							[ 5.590857994000032, 5.043424136000056 ],
							[ 5.599204434000058, 5.042380817000037 ],
							[ 5.611029056000064, 5.038902728000039 ],
							[ 5.619723407000038, 5.034729503000051 ],
							[ 5.625288025000032, 5.026035316000048 ],
							[ 5.632938844000023, 5.018383999000037 ],
							[ 5.642328626000051, 5.015602131000037 ],
							[ 5.647893368000041, 5.011776474000044 ],
							[ 5.656670524000049, 5.011776454000028 ],
							[ 5.661808871000062, 5.012883646000034 ],
							[ 5.663635225000064, 5.015100933000042 ],
							[ 5.663457027000049, 5.018840308000051 ],
							[ 5.663456905000032, 5.02400448800006 ],
							[ 5.666662267000049, 5.028277832000072 ],
							[ 5.675565157000051, 5.029880018000028 ],
							[ 5.682153776000064, 5.027921664000075 ],
							[ 5.691234724000026, 5.024182351000036 ],
							[ 5.702096331000064, 5.022935913000026 ],
							[ 5.710287135000044, 5.023826131000021 ],
							[ 5.719368480000071, 5.030948630000069 ],
							[ 5.72453199000006, 5.041098104000071 ],
							[ 5.728449450000028, 5.050713573000053 ],
							[ 5.730586130000063, 5.054452857000058 ],
							[ 5.736462249000056, 5.056589634000034 ],
							[ 5.743050556000071, 5.055699344000061 ],
							[ 5.748570415000074, 5.053206421000027 ],
							[ 5.75195351800005, 5.053740537000067 ],
							[ 5.758363806000034, 5.061040863000073 ],
							[ 5.764239769000028, 5.064246206000064 ],
							[ 5.769225630000051, 5.063355909000052 ],
							[ 5.773499077000054, 5.061219239000025 ],
							[ 5.775635688000023, 5.057479848000071 ],
							[ 5.779018868000037, 5.058013917000039 ],
							[ 5.780443276000028, 5.064958573000069 ],
							[ 5.784176763000062, 5.06932594400007 ],
							[ 5.788122132000069, 5.072540799000024 ],
							[ 5.789875405000032, 5.078677638000045 ],
							[ 5.789145087000065, 5.084376356000064 ],
							[ 5.791336483000066, 5.085837352000055 ],
							[ 5.797473973000024, 5.083061236000049 ],
							[ 5.803610923000065, 5.081892521000043 ],
							[ 5.80551059000004, 5.083645836000073 ],
							[ 5.809017251000057, 5.086421954000059 ],
							[ 5.814131321000048, 5.084522210000046 ],
							[ 5.815300339000032, 5.081892475000075 ],
							[ 5.817346125000029, 5.077362532000052 ],
							[ 5.820268610000028, 5.075170501000059 ],
							[ 5.822606631000042, 5.075755112000024 ],
							[ 5.825529070000073, 5.077508471000044 ],
							[ 5.830643178000059, 5.080285091000064 ],
							[ 5.835318976000053, 5.082476580000048 ],
							[ 5.840140873000053, 5.083061213000065 ],
							[ 5.840579176000062, 5.080577382000058 ],
							[ 5.83951098700004, 5.074536299000044 ],
							[ 5.83999503800004, 5.070786954000027 ],
							[ 5.840871458000038, 5.067572590000054 ],
							[ 5.848323709000056, 5.06654975400005 ],
							[ 5.851976338000043, 5.070202789000064 ],
							[ 5.853145639000047, 5.075462818000062 ],
							[ 5.857383133000042, 5.07736256000004 ],
							[ 5.863082038000073, 5.074440049000032 ],
							[ 5.864981263000061, 5.06947180800006 ],
							[ 5.86773878200006, 5.068109542000059 ],
							[ 5.869472159000054, 5.071081665000065 ],
							[ 5.870214984000029, 5.076034514000071 ],
							[ 5.871701311000038, 5.08123537900002 ],
							[ 5.874425489000032, 5.08618881700005 ],
							[ 5.877645095000048, 5.091389670000069 ],
							[ 5.878387959000065, 5.097085956000058 ],
							[ 5.877892463000023, 5.100305093000031 ],
							[ 5.877645029000064, 5.105258426000034 ],
							[ 5.88077271800006, 5.109508978000065 ],
							[ 5.881353491000027, 5.10950899900007 ],
							[ 5.882730492000064, 5.101841447000027 ],
							[ 5.885300594000057, 5.099972220000041 ],
							[ 5.896516428000041, 5.104411596000034 ],
							[ 5.909367476000057, 5.104878911000071 ],
							[ 5.914507979000064, 5.10814997600005 ],
							[ 5.915909672000055, 5.117496488000029 ],
							[ 5.916377159000035, 5.131515987000057 ],
							[ 5.917545344000075, 5.134086100000047 ],
							[ 5.921751381000036, 5.135020723000025 ],
							[ 5.926891694000062, 5.13992786700004 ],
							[ 5.927592717000039, 5.154881967000051 ],
							[ 5.927826474000028, 5.160723214000029 ],
							[ 5.933200709000062, 5.164695254000037 ],
							[ 5.946052103000056, 5.164227987000061 ],
							[ 5.960538769000038, 5.15721850400007 ],
							[ 5.964244911000037, 5.149107441000069 ],
							[ 5.972455546000049, 5.140614041000049 ],
							[ 5.974926594000067, 5.137524633000055 ],
							[ 5.97829684200002, 5.133312196000077 ],
							[ 5.980889411000021, 5.130557515000021 ],
							[ 5.985598630000027, 5.124185066000052 ],
							[ 5.992170353000063, 5.117613285000061 ],
							[ 5.995821024000065, 5.113232166000046 ],
							[ 6.000202140000056, 5.107390869000028 ],
							[ 6.004523214000073, 5.103686771000071 ],
							[ 6.01358220000003, 5.100392787000033 ],
							[ 6.019461505000038, 5.098254686000075 ],
							[ 6.027214582000056, 5.095670679000023 ],
							[ 6.044443003000026, 5.095670666000046 ],
							[ 6.054966095000054, 5.097167950000028 ],
							[ 6.06226784100005, 5.103009732000032 ],
							[ 6.063728271000059, 5.11177155300004 ],
							[ 6.063354122000021, 5.123184177000041 ],
							[ 6.06675100700005, 5.133347035000043 ],
							[ 6.069569589000025, 5.141344063000076 ],
							[ 6.085792668000067, 5.150802572000032 ],
							[ 6.097852588000023, 5.149941410000054 ],
							[ 6.109051218000047, 5.14218807900005 ],
							[ 6.120682733000024, 5.133677479000028 ],
							[ 6.129725895000036, 5.12926675600005 ],
							[ 6.143735923000065, 5.134776126000077 ],
							[ 6.150269039000023, 5.141166256000076 ],
							[ 6.154707358000053, 5.151664273000051 ],
							[ 6.165906323000058, 5.16113996100006 ],
							[ 6.178827659000035, 5.161139942000034 ],
							[ 6.190906914000038, 5.158570762000068 ],
							[ 6.200734075000071, 5.169549966000034 ],
							[ 6.202290892000065, 5.179281252000067 ],
							[ 6.200667800000076, 5.190462041000046 ],
							[ 6.198081900000034, 5.203775007000047 ],
							[ 6.195891476000043, 5.211076663000028 ],
							[ 6.19415135600002, 5.216709090000052 ],
							[ 6.195784226000058, 5.223075895000022 ],
							[ 6.202086897000072, 5.231778071000065 ],
							[ 6.20441611900003, 5.233211465000068 ],
							[ 6.213285462000044, 5.238669347000041 ],
							[ 6.233532927000056, 5.238167805000046 ],
							[ 6.238560250000035, 5.235210463000044 ],
							[ 6.244461045000037, 5.233656834000044 ],
							[ 6.259417428000063, 5.227870921000033 ],
							[ 6.272560722000037, 5.228236179000021 ],
							[ 6.278996068000026, 5.228888015000052 ],
							[ 6.284712919000071, 5.231664612000031 ],
							[ 6.290449986000056, 5.234077417000037 ],
							[ 6.293005958000037, 5.238823862000061 ],
							[ 6.292275299000039, 5.245395235000046 ],
							[ 6.290751905000036, 5.251054718000034 ],
							[ 6.28715561000007, 5.259274517000051 ],
							[ 6.285265436000032, 5.262743930000056 ],
							[ 6.28383446600003, 5.266500892000067 ],
							[ 6.283542122000028, 5.270599379000032 ],
							[ 6.283542045000047, 5.27499053400004 ],
							[ 6.285005418000026, 5.279088956000066 ],
							[ 6.286762163000049, 5.282309093000038 ],
							[ 6.287700740000048, 5.283717196000055 ],
							[ 6.288518478000071, 5.284943527000053 ],
							[ 6.289982303000045, 5.286700302000042 ],
							[ 6.292909551000037, 5.289627586000051 ],
							[ 6.295017594000058, 5.292945797000073 ],
							[ 6.297190657000044, 5.29634376100006 ],
							[ 6.300047772000028, 5.29902644200007 ],
							[ 6.302890285000046, 5.301016329000049 ],
							[ 6.306432293000057, 5.302983798000071 ],
							[ 6.310642852000058, 5.303543017000038 ],
							[ 6.31556808800002, 5.304772847000038 ],
							[ 6.32187704200004, 5.305265005000024 ],
							[ 6.325403125000037, 5.306539529000077 ],
							[ 6.327453742000046, 5.31041244100004 ],
							[ 6.330088242000045, 5.313632437000024 ],
							[ 6.333601004000059, 5.317730936000032 ],
							[ 6.338832400000058, 5.319910929000059 ],
							[ 6.347552897000071, 5.32045596200004 ],
							[ 6.362527497000031, 5.314038302000029 ],
							[ 6.380353951000075, 5.309046728000055 ],
							[ 6.393188895000037, 5.306907674000058 ],
							[ 6.408876887000076, 5.304768491000061 ],
							[ 6.425990658000046, 5.311185894000062 ],
							[ 6.434547526000074, 5.323308004000069 ],
							[ 6.450234820000048, 5.32758668300005 ],
							[ 6.473052980000034, 5.321882189000064 ],
							[ 6.490166611000063, 5.324021279000021 ],
							[ 6.493532108000068, 5.326040677000037 ],
							[ 6.493019238000045, 5.328299503000039 ],
							[ 6.502585989000067, 5.338941155000043 ],
							[ 6.500541118000058, 5.351987823000059 ],
							[ 6.495871100000045, 5.369657566000058 ],
							[ 6.485476966000022, 5.379205287000048 ],
							[ 6.500598009000043, 5.379403115000059 ],
							[ 6.518475161000026, 5.380016805000025 ],
							[ 6.528549249000037, 5.379838874000029 ],
							[ 6.540015304000065, 5.380566169000076 ],
							[ 6.552827764000028, 5.379875148000053 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Benue',
				admin1Pcod: 'NG007',
				admin1RefN: 'Benue',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 9.40808,
				Shape_Area: 2.578363
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.524424769000063, 8.157265553000059 ],
							[ 8.542395759000044, 8.148730200000045 ],
							[ 8.553634943000077, 8.151690539000072 ],
							[ 8.565614005000043, 8.153874643000051 ],
							[ 8.588485535000075, 8.156070427000031 ],
							[ 8.595024784000032, 8.152823467000076 ],
							[ 8.621654845000023, 8.120214861000022 ],
							[ 8.637149756000042, 8.116233965000049 ],
							[ 8.696668745000068, 8.096394572000065 ],
							[ 8.780536437000023, 8.07520205000003 ],
							[ 8.819764980000059, 8.070241853000027 ],
							[ 8.841859041000021, 8.067085359000032 ],
							[ 8.910846894000031, 8.056263920000049 ],
							[ 8.971267543000067, 8.057616334000045 ],
							[ 9.025375094000026, 8.062576341000067 ],
							[ 9.050175574000036, 8.075201949000075 ],
							[ 9.055993019000027, 8.077275382000039 ],
							[ 9.056972797000071, 8.07764538400005 ],
							[ 9.06170865100006, 8.070829841000034 ],
							[ 9.063389529000062, 8.067478977000064 ],
							[ 9.063388831000054, 8.063458423000043 ],
							[ 9.063388133000046, 8.059437610000032 ],
							[ 9.063386852000065, 8.052066475000061 ],
							[ 9.130944984000053, 7.930758903000026 ],
							[ 9.135650043000055, 7.924726215000021 ],
							[ 9.156827431000067, 7.916006129000039 ],
							[ 9.164221398000052, 7.911312374000033 ],
							[ 9.172960058000058, 7.90628244100003 ],
							[ 9.173732415000075, 7.905212294000023 ],
							[ 9.179125792000036, 7.908083360000035 ],
							[ 9.180937096000036, 7.903859024000042 ],
							[ 9.183191536000038, 7.89574301600004 ],
							[ 9.181838287000062, 7.891684953000038 ],
							[ 9.178231092000033, 7.888528811000072 ],
							[ 9.174623688000054, 7.887175952000064 ],
							[ 9.172170548000054, 7.886630903000025 ],
							[ 9.170429488000025, 7.882276024000021 ],
							[ 9.162518478000038, 7.86909242400003 ],
							[ 9.149994024000023, 7.859863892000021 ],
							[ 9.140106358000025, 7.848657692000074 ],
							[ 9.124285592000035, 7.826904642000045 ],
							[ 9.115717033000067, 7.808447318000049 ],
							[ 9.12033103300007, 7.793286321000039 ],
							[ 9.130218667000065, 7.78801287400006 ],
							[ 9.156585667000058, 7.797900769000023 ],
							[ 9.19020481900003, 7.810424781000052 ],
							[ 9.225141492000034, 7.818335170000069 ],
							[ 9.272602082000049, 7.822949485000038 ],
							[ 9.314789852000047, 7.834815056000025 ],
							[ 9.37807194100003, 7.843384307000065 ],
							[ 9.386640395000029, 7.844702598000026 ],
							[ 9.396453449000035, 7.844531926000059 ],
							[ 9.397112453000034, 7.844736357000045 ],
							[ 9.411067563000074, 7.849066547000064 ],
							[ 9.41558550700006, 7.850990525000043 ],
							[ 9.433934047000037, 7.858803648000048 ],
							[ 9.442655408000064, 7.870726425000043 ],
							[ 9.458993965000047, 7.880469763000065 ],
							[ 9.487304469000037, 7.88911193000007 ],
							[ 9.503637, 7.893429235000042 ],
							[ 9.541734892000022, 7.894460084000059 ],
							[ 9.551536215000056, 7.897699787000022 ],
							[ 9.57004166300004, 7.898756596000055 ],
							[ 9.583093283000039, 7.892227009000067 ],
							[ 9.624419647000025, 7.871547333000024 ],
							[ 9.658112833000075, 7.845452627000043 ],
							[ 9.729833930000041, 7.791075751000051 ],
							[ 9.763514426000029, 7.766056052000067 ],
							[ 9.786305708000043, 7.738886883000021 ],
							[ 9.79824771400007, 7.726928138000062 ],
							[ 9.844970077000028, 7.700787526000056 ],
							[ 9.863546855000038, 7.686014207000028 ],
							[ 9.863971941000045, 7.685555437000062 ],
							[ 9.880253320000065, 7.670328898000037 ],
							[ 9.905215989000055, 7.64748663000006 ],
							[ 9.905759961000058, 7.647485288000041 ],
							[ 9.911978703000045, 7.63813194100004 ],
							[ 9.912262088000034, 7.63770568800004 ],
							[ 9.907878668000023, 7.62469843100007 ],
							[ 9.905205686000045, 7.607648196000071 ],
							[ 9.905385451000029, 7.606342011000038 ],
							[ 9.906648963000066, 7.596314555000049 ],
							[ 9.904528083000059, 7.572416043000032 ],
							[ 9.905545217000054, 7.567015947000073 ],
							[ 9.911152208000033, 7.542327163000039 ],
							[ 9.912678206000066, 7.534355245000029 ],
							[ 9.924736793000022, 7.514533766000056 ],
							[ 9.925374307000027, 7.511961922000069 ],
							[ 9.925113246000024, 7.510420315000033 ],
							[ 9.92123883000005, 7.506574698000065 ],
							[ 9.911180113000057, 7.501716188000046 ],
							[ 9.908856117000028, 7.499665687000061 ],
							[ 9.907046217000072, 7.496842796000067 ],
							[ 9.901315411000041, 7.471153664000042 ],
							[ 9.894791627000075, 7.436984466000069 ],
							[ 9.892843631000062, 7.430306187000042 ],
							[ 9.890771534000066, 7.425427854000077 ],
							[ 9.887223316000075, 7.420144230000062 ],
							[ 9.880143944000054, 7.399117205000039 ],
							[ 9.873567438000066, 7.376351057000022 ],
							[ 9.866991733000077, 7.35358474700007 ],
							[ 9.868047900000022, 7.340563988000042 ],
							[ 9.86259853100006, 7.334067546000028 ],
							[ 9.856154897000067, 7.32598163800003 ],
							[ 9.855978841000024, 7.325266290000059 ],
							[ 9.840817212000047, 7.279782663000049 ],
							[ 9.82829311100005, 7.25737106400004 ],
							[ 9.824958578000064, 7.254598087000034 ],
							[ 9.825594417000048, 7.251420278000069 ],
							[ 9.828622864000067, 7.236277237000024 ],
							[ 9.835214464000046, 7.217160752000041 ],
							[ 9.834555820000048, 7.19936279500007 ],
							[ 9.824009106000062, 7.172336038000026 ],
							[ 9.822802327000034, 7.17095753500007 ],
							[ 9.823099955000032, 7.168174386000032 ],
							[ 9.823051143000043, 7.146477197000024 ],
							[ 9.821935439000072, 7.133461304000036 ],
							[ 9.819734012000026, 7.120447826000031 ],
							[ 9.819716999000036, 7.112854021000032 ],
							[ 9.819707278000067, 7.10851457900003 ],
							[ 9.815358217000039, 7.106354383000053 ],
							[ 9.79794716300006, 7.091204684000047 ],
							[ 9.771835582000051, 7.069562908000023 ],
							[ 9.766398218000063, 7.066319926000062 ],
							[ 9.751194600000076, 7.066351385000075 ],
							[ 9.73490858100007, 7.068553981000036 ],
							[ 9.731563404000042, 7.066891503000022 ],
							[ 9.69975286600004, 7.011495457000024 ],
							[ 9.689206032000072, 6.986446886000067 ],
							[ 9.664156716000036, 6.935689386000035 ],
							[ 9.645699592000028, 6.894820233000075 ],
							[ 9.641414530000077, 6.866475570000034 ],
							[ 9.643392579000022, 6.843403781000063 ],
							[ 9.648666375000062, 6.799238612000067 ],
							[ 9.646029545000033, 6.738594157000023 ],
							[ 9.636141940000073, 6.712885932000063 ],
							[ 9.622298331000025, 6.664106335000042 ],
							[ 9.606478803000073, 6.571820814000034 ],
							[ 9.602522945000032, 6.538202142000046 ],
							[ 9.599126590000026, 6.530925374000049 ],
							[ 9.588680431000057, 6.508539154000061 ],
							[ 9.587856392000049, 6.494531610000024 ],
							[ 9.58620815300003, 6.474756220000074 ],
							[ 9.574672805000034, 6.466516395000042 ],
							[ 9.543361524000034, 6.443444649000071 ],
							[ 9.491451099000074, 6.445917119000057 ],
							[ 9.467555305000076, 6.450861068000052 ],
							[ 9.465628632000062, 6.455608316000053 ],
							[ 9.466034788000059, 6.458327910000037 ],
							[ 9.467234491000056, 6.466357301000073 ],
							[ 9.467286210000054, 6.473245115000054 ],
							[ 9.470819670000026, 6.488145805000045 ],
							[ 9.471977163000076, 6.490434056000026 ],
							[ 9.473152312000025, 6.495017967000024 ],
							[ 9.474326935000022, 6.499602708000054 ],
							[ 9.474361698000052, 6.50419514500004 ],
							[ 9.474411835000069, 6.511083226000039 ],
							[ 9.473297268000067, 6.514534965000053 ],
							[ 9.473322059000054, 6.517979283000045 ],
							[ 9.472206888000073, 6.521431017000054 ],
							[ 9.471092271000032, 6.524884154000063 ],
							[ 9.468827127000054, 6.527194893000058 ],
							[ 9.465413198000022, 6.528367136000043 ],
							[ 9.462008598000068, 6.530685779000066 ],
							[ 9.458601698000052, 6.533006079000074 ],
							[ 9.454064648000042, 6.536480952000034 ],
							[ 9.449518200000057, 6.538808799000037 ],
							[ 9.446121022000057, 6.542276801000071 ],
							[ 9.441592474000061, 6.546901320000075 ],
							[ 9.43819512500005, 6.550367840000035 ],
							[ 9.432542033000061, 6.557295751000026 ],
							[ 9.428028804000064, 6.564215186000069 ],
							[ 9.424649083000077, 6.569979218000071 ],
							[ 9.422393744000033, 6.57343916800005 ],
							[ 9.420137253000064, 6.576899106000042 ],
							[ 9.416757864000033, 6.582663042000036 ],
							[ 9.413369169000021, 6.587277998000047 ],
							[ 9.40997118000007, 6.590745954000056 ],
							[ 9.405442150000056, 6.595369738000045 ],
							[ 9.402062361000048, 6.601133812000057 ],
							[ 9.39639287600005, 6.60576495600003 ],
							[ 9.39185433800003, 6.609241029000032 ],
							[ 9.386176039000077, 6.612723897000023 ],
							[ 9.381655540000054, 6.618496053000058 ],
							[ 9.377117278000071, 6.621972220000032 ],
							[ 9.372579484000028, 6.625446922000037 ],
							[ 9.368042179000042, 6.628922121000073 ],
							[ 9.361247915000035, 6.635857978000047 ],
							[ 9.357876658000066, 6.642769749000024 ],
							[ 9.352214982000021, 6.648549148000029 ],
							[ 9.347703077000062, 6.655468971000062 ],
							[ 9.343207262000021, 6.664684670000042 ],
							[ 9.334148391000042, 6.673932186000059 ],
							[ 9.325099100000045, 6.684327031000066 ],
							[ 9.318321955000044, 6.693559116000074 ],
							[ 9.313809409000044, 6.700477953000075 ],
							[ 9.309297824000055, 6.707397861000061 ],
							[ 9.307058310000059, 6.713153863000059 ],
							[ 9.303669842000033, 6.717770035000058 ],
							[ 9.300264162000076, 6.720088840000074 ],
							[ 9.295701291000057, 6.720119959000044 ],
							[ 9.291146129000026, 6.721301098000026 ],
							[ 9.285432740000033, 6.720191124000053 ],
							[ 9.279720515000065, 6.719082726000067 ],
							[ 9.270593682000026, 6.719144693000032 ],
							[ 9.26603026500004, 6.719176851000043 ],
							[ 9.255754711000066, 6.718099921000032 ],
							[ 9.247742405000054, 6.714711126000054 ],
							[ 9.238598919000026, 6.712477198000045 ],
							[ 9.228305812000031, 6.709104141000068 ],
							[ 9.215731832000074, 6.705746275000024 ],
							[ 9.208860123000022, 6.702349158000061 ],
							[ 9.199700117000077, 6.697820325000066 ],
							[ 9.192829261000043, 6.694423184000073 ],
							[ 9.184833343000037, 6.693329779000067 ],
							[ 9.175674416000049, 6.68879977000006 ],
							[ 9.171102351000059, 6.687684073000071 ],
							[ 9.168197033000069, 6.686731710000061 ],
							[ 9.167671369000061, 6.686559299000066 ],
							[ 9.165389850000054, 6.686575040000037 ],
							[ 9.158527372000037, 6.684326205000048 ],
							[ 9.153963824000073, 6.684358115000066 ],
							[ 9.149400830000047, 6.684389097000064 ],
							[ 9.146689415000026, 6.681962579000071 ],
							[ 9.144863650000048, 6.681090432000076 ],
							[ 9.143911261000028, 6.680773188000046 ],
							[ 9.142641325000056, 6.680614866000042 ],
							[ 9.139228244000037, 6.680140038000047 ],
							[ 9.136688410000033, 6.67990308100002 ],
							[ 9.136024966000036, 6.680005331000075 ],
							[ 9.129058021000048, 6.675517982000031 ],
							[ 9.123076398000023, 6.673089870000069 ],
							[ 9.117329450000057, 6.667387947000066 ],
							[ 9.115818618000048, 6.666533351000055 ],
							[ 9.111662387000024, 6.659887567000055 ],
							[ 9.100693780000029, 6.654406774000051 ],
							[ 9.087375849000068, 6.651276409000047 ],
							[ 9.085528078000038, 6.651646447000076 ],
							[ 9.082967638000071, 6.649254752000047 ],
							[ 9.077238003000048, 6.645850085000063 ],
							[ 9.070367554000029, 6.642453196000076 ],
							[ 9.058959107000021, 6.642531786000063 ],
							[ 9.047559841000066, 6.643758698000056 ],
							[ 9.035036194000043, 6.64728866400003 ],
							[ 9.027076021000028, 6.650787928000057 ],
							[ 9.022545919000038, 6.655412334000061 ],
							[ 9.018033907000074, 6.662332056000025 ],
							[ 9.011239771000021, 6.669267056000024 ],
							[ 9.00444591400003, 6.676201928000069 ],
							[ 8.997651818000065, 6.683137801000044 ],
							[ 8.992015986000069, 6.692361018000042 ],
							[ 8.989734423000073, 6.692377074000035 ],
							[ 8.98745343500002, 6.692392836000067 ],
							[ 8.985179752000022, 6.693556687000068 ],
							[ 8.980608209000025, 6.692440002000069 ],
							[ 8.978326089000063, 6.692455707000022 ],
							[ 8.97603613800004, 6.691322704000072 ],
							[ 8.972613544000069, 6.691347058000076 ],
							[ 8.970331431000034, 6.691361843000038 ],
							[ 8.969207701000073, 6.69366702800005 ],
							[ 8.968091764000064, 6.697119162000035 ],
							[ 8.969259107000028, 6.70055484300002 ],
							[ 8.970425359000046, 6.703991058000042 ],
							[ 8.972732721000057, 6.707418909000069 ],
							[ 8.973890055000027, 6.709708139000043 ],
							[ 8.975047976000042, 6.711995958000045 ],
							[ 8.977347595000026, 6.714275938000071 ],
							[ 8.977364399000066, 6.716573288000063 ],
							[ 8.980829321000044, 6.722289171000057 ],
							[ 8.981986752000068, 6.724576967000075 ],
							[ 8.984311219000062, 6.730301796000049 ],
							[ 8.986626762000071, 6.734879045000071 ],
							[ 8.987792694000063, 6.738315217000036 ],
							[ 8.990109462000021, 6.742890776000024 ],
							[ 8.991419027000063, 6.746609797000076 ],
							[ 9.001206251000042, 6.753134 ],
							[ 9.007475016000058, 6.757834581000054 ],
							[ 9.007475096000064, 6.761751663000041 ],
							[ 9.002773809000075, 6.768019268000046 ],
							[ 8.99493725800005, 6.776636959000029 ],
							[ 8.977304145000062, 6.786037592000071 ],
							[ 8.976742760000036, 6.786611180000023 ],
							[ 8.974477973000035, 6.788922779000075 ],
							[ 8.972205353000049, 6.790087118000031 ],
							[ 8.96879972000005, 6.792407075000028 ],
							[ 8.964253239000072, 6.794733946000065 ],
							[ 8.960848067000029, 6.797053830000038 ],
							[ 8.957450648000076, 6.800520945000073 ],
							[ 8.951771479000058, 6.804005235000034 ],
							[ 8.947234176000052, 6.807479732000047 ],
							[ 8.943828797000037, 6.80980001100005 ],
							[ 8.942987463000065, 6.810498690000031 ],
							[ 8.939709712000024, 6.813222477000068 ],
							[ 8.934744993000038, 6.815602894000051 ],
							[ 8.932479762000071, 6.81791508200007 ],
							[ 8.929426368000065, 6.820408795000048 ],
							[ 8.92680947100007, 6.822545969000032 ],
							[ 8.923403822000068, 6.824865779000049 ],
							[ 8.918857761000027, 6.827192659000048 ],
							[ 8.913179516000071, 6.830676922000066 ],
							[ 8.908633317000067, 6.83300397000005 ],
							[ 8.902946703000055, 6.835339957000031 ],
							[ 8.897258886000031, 6.837675023000031 ],
							[ 8.891579699000033, 6.841158950000022 ],
							[ 8.887033233000068, 6.843485710000039 ],
							[ 8.879090374000043, 6.849280778000036 ],
							[ 8.874535654000056, 6.850460972000064 ],
							[ 8.869998172000066, 6.853937044000077 ],
							[ 8.867715616000055, 6.85395206000004 ],
							[ 8.864310431000035, 6.856272171000057 ],
							[ 8.859772781000061, 6.859746985000072 ],
							[ 8.856358326000077, 6.860918905000062 ],
							[ 8.851812539000036, 6.863246080000067 ],
							[ 8.848406591000071, 6.865565763000063 ],
							[ 8.843852127000048, 6.866745 ],
							[ 8.839305614000068, 6.869072871000071 ],
							[ 8.833609477000039, 6.870259682000039 ],
							[ 8.830196526000066, 6.871431932000064 ],
							[ 8.822226689000047, 6.873783137000032 ],
							[ 8.817680525000071, 6.876111016000038 ],
							[ 8.811984765000034, 6.877297788000021 ],
							[ 8.807430036000028, 6.878477998000051 ],
							[ 8.802876426000068, 6.879656726000064 ],
							[ 8.798320509000064, 6.880835989000047 ],
							[ 8.790351948000023, 6.883186778000038 ],
							[ 8.785788705000073, 6.883218882000051 ],
							[ 8.781242193000026, 6.885546307000027 ],
							[ 8.776695048000022, 6.887874252000074 ],
							[ 8.769859555000039, 6.88906898700003 ],
							[ 8.76530456200004, 6.89024782100006 ],
							[ 8.759608411000045, 6.891436038000052 ],
							[ 8.753904508000062, 6.891475197000034 ],
							[ 8.748208857000066, 6.892662941000026 ],
							[ 8.741363264000029, 6.892710175000047 ],
							[ 8.733385872000042, 6.893911904000049 ],
							[ 8.727690690000031, 6.895100181000032 ],
							[ 8.720853771000066, 6.896295131000045 ],
							[ 8.715149788000076, 6.896334262000039 ],
							[ 8.707163532000038, 6.896389006000049 ],
							[ 8.703741818000026, 6.89641281300004 ],
							[ 8.701442759000031, 6.894133115000045 ],
							[ 8.696853392000037, 6.890719794000063 ],
							[ 8.693423075000055, 6.889595978000045 ],
							[ 8.689974935000066, 6.886175060000028 ],
							[ 8.686534936000044, 6.883903041000053 ],
							[ 8.680796699000041, 6.879350051000074 ],
							[ 8.677357952000023, 6.877077113000041 ],
							[ 8.675058604000071, 6.874795840000047 ],
							[ 8.669320077000066, 6.870244075000073 ],
							[ 8.664740447000042, 6.867979042000059 ],
							[ 8.661309312000071, 6.86685433100007 ],
							[ 8.656737166000028, 6.865737874000047 ],
							[ 8.654446550000046, 6.864604850000035 ],
							[ 8.651024574000076, 6.864628738000022 ],
							[ 8.645311441000047, 6.86352008700004 ],
							[ 8.636176388000024, 6.862434799000027 ],
							[ 8.629331323000031, 6.862483034000036 ],
							[ 8.623627856000041, 6.862522166000076 ],
							[ 8.61905587800004, 6.861404941000046 ],
							[ 8.608955381000044, 6.859628773000054 ],
							[ 8.606488929000022, 6.859195199000055 ],
							[ 8.600760435000041, 6.855791037000074 ],
							[ 8.600742578000052, 6.853494254000054 ],
							[ 8.601857378000034, 6.850042960000053 ],
							[ 8.604122043000075, 6.847731113000066 ],
							[ 8.607527688000062, 6.845410881000021 ],
							[ 8.61092487600007, 6.841942792000054 ],
							[ 8.615462409000031, 6.838467943000069 ],
							[ 8.620000969000046, 6.834991909000053 ],
							[ 8.622273301000064, 6.833828070000038 ],
							[ 8.625679256000069, 6.831509077000021 ],
							[ 8.630233989000033, 6.830328864000023 ],
							[ 8.633630821000054, 6.826860840000052 ],
							[ 8.637045116000024, 6.825689844000067 ],
							[ 8.639301499000055, 6.822229969000034 ],
							[ 8.641556728000069, 6.818770061000066 ],
							[ 8.640373978000071, 6.813037976000032 ],
							[ 8.640348517000064, 6.80959421700004 ],
							[ 8.640314032000049, 6.805000908000068 ],
							[ 8.636840644000074, 6.798137105000023 ],
							[ 8.632242917000042, 6.793575705000023 ],
							[ 8.631085192000057, 6.791287939000028 ],
							[ 8.628786115000025, 6.789007200000071 ],
							[ 8.625338395000028, 6.785586788000046 ],
							[ 8.623039944000027, 6.783307143000059 ],
							[ 8.620740967000074, 6.78102579800003 ],
							[ 8.616160475000072, 6.778760981000062 ],
							[ 8.612729769000055, 6.777636959000063 ],
							[ 8.607008041000029, 6.775380153000071 ],
							[ 8.601295304000075, 6.774271087000045 ],
							[ 8.594425189000049, 6.770874883000033 ],
							[ 8.587562375000061, 6.768624712000076 ],
							[ 8.57841101300005, 6.765244002000031 ],
							[ 8.57041650900004, 6.764151045000062 ],
							[ 8.563562213000068, 6.763050217000057 ],
							[ 8.557849798000063, 6.76194102900007 ],
							[ 8.550987947000067, 6.759693284000036 ],
							[ 8.544134348000057, 6.758592094000051 ],
							[ 8.535016241000051, 6.759802774000036 ],
							[ 8.530452826000044, 6.759833695000054 ],
							[ 8.523607980000065, 6.759881083000039 ],
							[ 8.515622405000045, 6.759935893000034 ],
							[ 8.508768351000072, 6.758834892000039 ],
							[ 8.503055592000067, 6.757727127000067 ],
							[ 8.496185568000044, 6.754329077000023 ],
							[ 8.488190397000039, 6.753235731000075 ],
							[ 8.480187308000041, 6.750996063000059 ],
							[ 8.476748749000024, 6.748722931000032 ],
							[ 8.472176378000029, 6.747605848000035 ],
							[ 8.468745256000034, 6.746481030000041 ],
							[ 8.464182, 6.746512812000049 ],
							[ 8.460759407000069, 6.746537180000075 ],
							[ 8.458478057000036, 6.746551909000061 ],
							[ 8.453923254000074, 6.74773225000007 ],
							[ 8.450499523000076, 6.747755113000039 ],
							[ 8.448244074000058, 6.751214969000046 ],
							[ 8.443707734000043, 6.754691151000031 ],
							[ 8.440300765000075, 6.757009885000059 ],
							[ 8.435746348000066, 6.758190278000029 ],
							[ 8.432323645000054, 6.758214103000057 ],
							[ 8.427760222000074, 6.758244857000022 ],
							[ 8.424328597000056, 6.757121049000034 ],
							[ 8.419765186000063, 6.757152 ],
							[ 8.415185101000077, 6.754887204000056 ],
							[ 8.410613968000064, 6.753770781000071 ],
							[ 8.408323126000028, 6.752637915000037 ],
							[ 8.402593601000035, 6.749232892000066 ],
							[ 8.398004309000044, 6.745820894000076 ],
							[ 8.393416234000028, 6.742407711000055 ],
							[ 8.391100141000038, 6.737832059000027 ],
							[ 8.387635215000046, 6.732114835000061 ],
							[ 8.386468707000063, 6.728679302000046 ],
							[ 8.383003384000062, 6.722962030000076 ],
							[ 8.381828144000053, 6.71837806800005 ],
							[ 8.379529926000032, 6.716096887000049 ],
							[ 8.377214193000043, 6.711521142000038 ],
							[ 8.374897926000074, 6.706943973000023 ],
							[ 8.372599840000021, 6.704664174000072 ],
							[ 8.370292461000076, 6.701234815000021 ],
							[ 8.366844013000048, 6.697815044000038 ],
							[ 8.361105879000036, 6.693262097000058 ],
							[ 8.357675622000045, 6.692138218000025 ],
							[ 8.354235542000026, 6.689865057000077 ],
							[ 8.348514514000044, 6.687607869000033 ],
							[ 8.345083200000033, 6.686483871000064 ],
							[ 8.340502774000072, 6.68421898400004 ],
							[ 8.335931045000052, 6.683103030000041 ],
							[ 8.330209678000074, 6.680846129000031 ],
							[ 8.325638025000046, 6.679728898000064 ],
							[ 8.321065822000037, 6.678613312000039 ],
							[ 8.317643299000054, 6.678636065000035 ],
							[ 8.313071155000046, 6.677518973000076 ],
							[ 8.307367138000075, 6.677557955000054 ],
							[ 8.303953163000074, 6.678730127000051 ],
							[ 8.299398703000065, 6.679910235000023 ],
							[ 8.294514797000033, 6.678708960000051 ],
							[ 8.283816288000025, 6.692505949000065 ],
							[ 8.278820836000023, 6.697955271000069 ],
							[ 8.280183636000061, 6.704766365000069 ],
							[ 8.285632082000063, 6.710215518000041 ],
							[ 8.280184021000025, 6.715210425000066 ],
							[ 8.273825536000061, 6.712940357000036 ],
							[ 8.273825687000055, 6.716572893000034 ],
							[ 8.26883097700005, 6.727925387000028 ],
							[ 8.261111047000043, 6.731103866000069 ],
							[ 8.25157553400004, 6.735644733000072 ],
							[ 8.256116849000023, 6.737461062000023 ],
							[ 8.262474214000065, 6.737915018000024 ],
							[ 8.266106468000032, 6.741548065000075 ],
							[ 8.261111116000052, 6.755170940000028 ],
							[ 8.255661927000062, 6.766069070000071 ],
							[ 8.249448575000031, 6.768473266000058 ],
							[ 8.249758572000076, 6.769190232000028 ],
							[ 8.249758698000051, 6.771063720000029 ],
							[ 8.249758435000047, 6.775604729000065 ],
							[ 8.244310478000045, 6.785595055000044 ],
							[ 8.24130664300003, 6.791225955000073 ],
							[ 8.240676706000045, 6.792406102000029 ],
							[ 8.234319625000069, 6.799217652000038 ],
							[ 8.229325312000071, 6.800125554000033 ],
							[ 8.226146485000072, 6.789681904000076 ],
							[ 8.220243542000048, 6.778783424000039 ],
							[ 8.212977446000025, 6.776058532000036 ],
							[ 8.199355191000052, 6.778329479000035 ],
							[ 8.193693203000066, 6.778329490000033 ],
							[ 8.185297673000036, 6.766789710000069 ],
							[ 8.180964223000046, 6.763525324000057 ],
							[ 8.170113315000037, 6.763500908000026 ],
							[ 8.163930276000031, 6.765031425000075 ],
							[ 8.160302935000061, 6.761074111000028 ],
							[ 8.15258308600005, 6.754716446000032 ],
							[ 8.149062866000065, 6.747088881000025 ],
							[ 8.147133808000035, 6.742909886000064 ],
							[ 8.14531811300003, 6.734736342000076 ],
							[ 8.144409447000044, 6.725200630000074 ],
							[ 8.136236410000038, 6.716118924000057 ],
							[ 8.127608169000041, 6.711577808000072 ],
							[ 8.112169253000047, 6.70930721600007 ],
							[ 8.103995048000058, 6.712031719000038 ],
							[ 8.098545892000061, 6.707037073000038 ],
							[ 8.095367309000039, 6.707944781000037 ],
							[ 8.089010112000039, 6.711577957000031 ],
							[ 8.079565762000072, 6.713152040000068 ],
							[ 8.075387773000045, 6.71384824200004 ],
							[ 8.065397521000023, 6.711577815000055 ],
							[ 8.050866117000055, 6.709307249000062 ],
							[ 8.038200732000064, 6.711914898000032 ],
							[ 8.028914813000029, 6.721439839000027 ],
							[ 8.021813563000023, 6.723549319000028 ],
							[ 8.014725609000038, 6.720692399000029 ],
							[ 8.006222379000064, 6.716412393000041 ],
							[ 8.000555802000065, 6.712849513000037 ],
							[ 8.000557789000027, 6.712121314000058 ],
							[ 7.994786110000064, 6.703858485000069 ],
							[ 7.988428771000031, 6.687964900000054 ],
							[ 7.98615185400007, 6.672408630000064 ],
							[ 7.982941431000029, 6.668102830000066 ],
							[ 7.982244041000058, 6.663843863000068 ],
							[ 7.987488527000039, 6.647822355000073 ],
							[ 7.987066513000059, 6.633473816000048 ],
							[ 7.988428589000023, 6.618034944000044 ],
							[ 7.987458250000032, 6.613183484000047 ],
							[ 7.97955819200007, 6.609205176000046 ],
							[ 7.971065948000046, 6.60208641600002 ],
							[ 7.965421513000024, 6.591428029000042 ],
							[ 7.968300900000031, 6.576536579000049 ],
							[ 7.971551574000046, 6.570586694000042 ],
							[ 7.970719460000055, 6.568538772000068 ],
							[ 7.966632128000072, 6.557640031000062 ],
							[ 7.962545383000077, 6.54401718500003 ],
							[ 7.956641997000077, 6.540384807000066 ],
							[ 7.949376722000068, 6.532210755000051 ],
							[ 7.932356556000059, 6.531763086000069 ],
							[ 7.929404789000046, 6.537402384000075 ],
							[ 7.910925219000035, 6.550828370000033 ],
							[ 7.904540405000034, 6.551518886000053 ],
							[ 7.896738572000061, 6.551495464000027 ],
							[ 7.890249109000024, 6.551475855000035 ],
							[ 7.887166045000072, 6.552645132000066 ],
							[ 7.878084389000037, 6.561726953000061 ],
							[ 7.871888456000022, 6.566683864000026 ],
							[ 7.869002240000043, 6.568992670000057 ],
							[ 7.855833717000053, 6.580799197000033 ],
							[ 7.852654932000064, 6.588064549000023 ],
							[ 7.84675174900002, 6.604411599000059 ],
							[ 7.844027285000038, 6.608498419000057 ],
							[ 7.836761673000069, 6.61485576900003 ],
							[ 7.833582982000053, 6.619396602000052 ],
							[ 7.833582776000071, 6.626208169000051 ],
							[ 7.831766562000041, 6.638468859000056 ],
							[ 7.821776742000054, 6.638922617000048 ],
							[ 7.808286619000057, 6.634216994000042 ],
							[ 7.807140816000071, 6.633817085000032 ],
							[ 7.804926667000075, 6.640460355000073 ],
							[ 7.810440582000069, 6.649913338000033 ],
							[ 7.815167004000045, 6.659366501000022 ],
							[ 7.821469580000041, 6.674333561000026 ],
							[ 7.830134484000041, 6.689300979000052 ],
							[ 7.841162931000042, 6.714508934000037 ],
							[ 7.845102031000067, 6.721599107000031 ],
							[ 7.855342136000047, 6.742868395000073 ],
							[ 7.867158833000076, 6.751533628000061 ],
							[ 7.878187539000066, 6.760199174000036 ],
							[ 7.88842801900006, 6.766501045000041 ],
							[ 7.894730259000028, 6.774378882000065 ],
							[ 7.903395841000076, 6.781468502000052 ],
							[ 7.900244797000028, 6.78619476800003 ],
							[ 7.890003801000034, 6.785407148000047 ],
							[ 7.887640739000062, 6.789345876000027 ],
							[ 7.886458993000076, 6.794072624000023 ],
							[ 7.886458805000075, 6.805101038000032 ],
							[ 7.888822041000026, 6.821643767000069 ],
							[ 7.890397592000056, 6.843700821000027 ],
							[ 7.888145937000047, 6.853458990000036 ],
							[ 7.890795345000072, 6.854055901000038 ],
							[ 7.894154448000052, 6.855747385000029 ],
							[ 7.895830903000046, 6.85743347600004 ],
							[ 7.89665946100007, 6.863931555000022 ],
							[ 7.895171022000056, 6.863255112000047 ],
							[ 7.882596942000021, 6.858291682000072 ],
							[ 7.87621807000005, 6.863394735000043 ],
							[ 7.874642240000071, 6.868908888000021 ],
							[ 7.864117927000052, 6.879433758000062 ],
							[ 7.863803381000025, 6.880031833000032 ],
							[ 7.832230925000033, 6.890440192000028 ],
							[ 7.826759985000024, 6.891683581000052 ],
							[ 7.817093141000043, 6.889971180000032 ],
							[ 7.809104514000069, 6.889525118000051 ],
							[ 7.800697977000027, 6.888236866000057 ],
							[ 7.79692026400005, 6.886122960000023 ],
							[ 7.792730196000036, 6.881485954000027 ],
							[ 7.788124313000026, 6.875586385000076 ],
							[ 7.785234519000028, 6.872969762000025 ],
							[ 7.776001764000057, 6.875904981000076 ],
							[ 7.76278157400003, 6.879937590000054 ],
							[ 7.750177347000033, 6.87836214500004 ],
							[ 7.734422462000055, 6.863394822000032 ],
							[ 7.720242601000052, 6.862606919000029 ],
							[ 7.692280065000034, 6.851824278000038 ],
							[ 7.67455295000002, 6.855517444000043 ],
							[ 7.672467026000049, 6.857106747000046 ],
							[ 7.662610498000049, 6.872628185000053 ],
							[ 7.657538379000073, 6.879754585000057 ],
							[ 7.65035381000007, 6.889395142000069 ],
							[ 7.641361603000064, 6.894001822000064 ],
							[ 7.635952807000024, 6.903570132000027 ],
							[ 7.620197679000057, 6.919325270000058 ],
							[ 7.59971626500004, 6.924839502000054 ],
							[ 7.577659090000054, 6.932717292000063 ],
							[ 7.571357371000033, 6.939806920000024 ],
							[ 7.564266879000058, 6.952411226000038 ],
							[ 7.558879091000051, 6.971568238000032 ],
							[ 7.557177686000045, 6.977619130000051 ],
							[ 7.556406736000042, 6.978775785000039 ],
							[ 7.552451177000023, 6.984708676000025 ],
							[ 7.549300032000076, 6.991011233000052 ],
							[ 7.545361043000071, 7.001251711000066 ],
							[ 7.542209889000048, 7.008341719000043 ],
							[ 7.53827158100006, 7.020157759000028 ],
							[ 7.530513945000052, 7.032278044000066 ],
							[ 7.543581844000073, 7.040121059000057 ],
							[ 7.544227638000052, 7.039896156000054 ],
							[ 7.559165001000054, 7.046932217000062 ],
							[ 7.567835538000054, 7.051305681000031 ],
							[ 7.578678053000033, 7.05568736400005 ],
							[ 7.583005401000037, 7.060043625000048 ],
							[ 7.584061640000073, 7.067641604000073 ],
							[ 7.580765697000061, 7.077392941000028 ],
							[ 7.580736243000047, 7.084987016000071 ],
							[ 7.584996842000066, 7.106700961000058 ],
							[ 7.589311748000057, 7.114311466000061 ],
							[ 7.591455207000024, 7.121913881000069 ],
							[ 7.605488405000074, 7.144749739000076 ],
							[ 7.618469633000075, 7.15890237900004 ],
							[ 7.622798658000022, 7.163258133000056 ],
							[ 7.633645167000054, 7.167638189000058 ],
							[ 7.646664972000053, 7.17202592700005 ],
							[ 7.686420105000025, 7.19100873800005 ],
							[ 7.68569393100006, 7.193865201000051 ],
							[ 7.69219294800007, 7.199312699000075 ],
							[ 7.708500112000024, 7.196115794000036 ],
							[ 7.723709619000033, 7.196169024000028 ],
							[ 7.736742758000048, 7.197299220000048 ],
							[ 7.767146684000068, 7.201742118000027 ],
							[ 7.783376349000036, 7.206027538000058 ],
							[ 7.789968921000025, 7.216885998000066 ],
							[ 7.793865041000061, 7.225825279000048 ],
							[ 7.794466659000022, 7.227205358000049 ],
							[ 7.797056336000026, 7.233145323000031 ],
							[ 7.806645176000075, 7.254406820000042 ],
							[ 7.818734745000029, 7.286925277000023 ],
							[ 7.810814020000066, 7.290260317000048 ],
							[ 7.797056204000057, 7.293595301000039 ],
							[ 7.789968679000026, 7.296514139000067 ],
							[ 7.779129301000069, 7.300682899000037 ],
							[ 7.774711988000035, 7.301192623000077 ],
							[ 7.787335237000036, 7.338501991000044 ],
							[ 7.798135410000043, 7.359149899000045 ],
							[ 7.795897510000032, 7.378670166000063 ],
							[ 7.795406941000067, 7.389567555000042 ],
							[ 7.794724558000041, 7.404702877000034 ],
							[ 7.793993636000039, 7.415134370000033 ],
							[ 7.79761204600004, 7.415858313000058 ],
							[ 7.796859710000035, 7.416643366000073 ],
							[ 7.835838245000048, 7.465323497000043 ],
							[ 7.835775211000055, 7.485114705000058 ],
							[ 7.829230753000047, 7.491602860000057 ],
							[ 7.818340744000068, 7.496992166000041 ],
							[ 7.810733532000029, 7.495882831000074 ],
							[ 7.807478709000065, 7.493702712000072 ],
							[ 7.800983436000024, 7.485002731000066 ],
							[ 7.793394432000071, 7.478468619000068 ],
							[ 7.785776313000042, 7.480613345000052 ],
							[ 7.779246116000024, 7.482761255000071 ],
							[ 7.771584074000032, 7.497923774000071 ],
							[ 7.765013163000049, 7.512004729000068 ],
							[ 7.757360984000059, 7.523912341000027 ],
							[ 7.755918139000073, 7.526767326000027 ],
							[ 7.739914636000037, 7.537955869000029 ],
							[ 7.74096114300005, 7.549892895000028 ],
							[ 7.747456141000043, 7.558593894000069 ],
							[ 7.751979436000056, 7.576910647000034 ],
							[ 7.750780530000043, 7.594179083000029 ],
							[ 7.75244800400003, 7.623362090000057 ],
							[ 7.752447911000047, 7.647782667000058 ],
							[ 7.75467386300005, 7.647728333000032 ],
							[ 7.754674007000062, 7.661315835000039 ],
							[ 7.75399436400005, 7.679658888000063 ],
							[ 7.753594530000044, 7.688056807000066 ],
							[ 7.75263548700002, 7.708192341000029 ],
							[ 7.752635511000051, 7.74419884200006 ],
							[ 7.74469063600003, 7.768656650000025 ],
							[ 7.728178297000056, 7.792434238000055 ],
							[ 7.692850809000049, 7.838631106000037 ],
							[ 7.66975270000006, 7.884828566000067 ],
							[ 7.663638098000035, 7.905889040000034 ],
							[ 7.663834543000064, 7.914345187000038 ],
							[ 7.679795935000072, 7.92503318100006 ],
							[ 7.695012539000061, 7.931596635000062 ],
							[ 7.697174054000072, 7.935943639000072 ],
							[ 7.692800577000071, 7.94135218200006 ],
							[ 7.677563957000075, 7.940212934000044 ],
							[ 7.665040941000029, 7.94016767100004 ],
							[ 7.671111268000061, 7.982658014000037 ],
							[ 7.676837731000035, 8.014471178000065 ],
							[ 7.697466677000023, 8.013662398000065 ],
							[ 7.738402973000063, 8.006218912000065 ],
							[ 7.760732249000057, 8.00342852700004 ],
							[ 7.782595479000065, 7.998776575000022 ],
							[ 7.81097223300003, 7.99133295300004 ],
							[ 7.838417762000063, 7.985285697000052 ],
							[ 7.867259746000059, 7.98621601800005 ],
							[ 7.870348426000021, 7.985333769000022 ],
							[ 7.879907328000058, 7.985363015000075 ],
							[ 7.91804146100003, 7.975713851000023 ],
							[ 7.964902019000021, 7.959576296000023 ],
							[ 8.062916116000054, 7.926988813000037 ],
							[ 8.063361437000026, 7.925603133000038 ],
							[ 8.080404100000067, 7.918659799000068 ],
							[ 8.08586299500007, 7.912164591000021 ],
							[ 8.092413127000043, 7.904586951000056 ],
							[ 8.100035057000071, 7.903520925000066 ],
							[ 8.120716456000025, 7.90357098000004 ],
							[ 8.139218479000021, 7.904699540000024 ],
							[ 8.14357472100005, 7.903624949000061 ],
							[ 8.144676139000069, 7.898203455000044 ],
							[ 8.139251728000033, 7.890596919000075 ],
							[ 8.10198331600003, 7.867123959000025 ],
							[ 8.113808780000056, 7.863872115000049 ],
							[ 8.13939391100007, 7.86154610400007 ],
							[ 8.159862468000028, 7.859685308000053 ],
							[ 8.175678340000047, 7.855033405000029 ],
							[ 8.180357876000073, 7.852580487000068 ],
							[ 8.183121598000071, 7.853172682000036 ],
							[ 8.185447643000032, 7.852242553000053 ],
							[ 8.191029737000065, 7.848986185000058 ],
							[ 8.21521967700005, 7.834565192000071 ],
							[ 8.251504143000034, 7.822470306000071 ],
							[ 8.27476316000002, 7.814562223000053 ],
							[ 8.301744556000074, 7.802467357000069 ],
							[ 8.328724994000027, 7.791768047000062 ],
							[ 8.345472117000043, 7.78432502000004 ],
							[ 8.362218641000027, 7.774556279000024 ],
							[ 8.372159140000065, 7.770002370000043 ],
							[ 8.385477798000068, 7.768043540000065 ],
							[ 8.40315559000004, 7.76618288800006 ],
							[ 8.41199371600004, 7.768508810000071 ],
							[ 8.411994024000023, 7.776416914000038 ],
							[ 8.406876796000063, 7.787116170000047 ],
							[ 8.401294668000048, 7.795954748000042 ],
							[ 8.393851194000035, 7.809910217000038 ],
							[ 8.392920342000025, 7.820144546000051 ],
							[ 8.39664259500006, 7.829448133000028 ],
							[ 8.404662231000032, 7.836760382000023 ],
							[ 8.405386953000061, 7.861023544000034 ],
							[ 8.397721471000068, 7.879961409000032 ],
							[ 8.378783177000059, 7.915582745000052 ],
							[ 8.378496422000069, 7.916155856000046 ],
							[ 8.368870713000035, 7.917098760000044 ],
							[ 8.36451063800007, 7.920345803000032 ],
							[ 8.364501197000038, 7.925769851000041 ],
							[ 8.367116421000048, 7.938917438000033 ],
							[ 8.36345269900005, 7.946243874000061 ],
							[ 8.360296453000046, 7.960672770000031 ],
							[ 8.360296328000061, 7.987276193000071 ],
							[ 8.363453119000042, 8.016584359000035 ],
							[ 8.371117610000056, 8.046343825000065 ],
							[ 8.390506523000056, 8.077907638000056 ],
							[ 8.426578783000025, 8.112175978000039 ],
							[ 8.46625780100004, 8.139229697000076 ],
							[ 8.472741367000026, 8.142471808000039 ],
							[ 8.48609835700006, 8.149149777000048 ],
							[ 8.503682833000028, 8.158167761000072 ],
							[ 8.512679995000042, 8.157776863000038 ],
							[ 8.524424769000063, 8.157265553000059 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Borno',
				admin1Pcod: 'NG008',
				admin1RefN: 'Borno',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 13.714364,
				Shape_Area: 5.987849
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 13.358852732000059, 13.71260630200004 ],
							[ 13.362073507000048, 13.71153292300005 ],
							[ 13.368379136000044, 13.712794809000059 ],
							[ 13.374056298000028, 13.712793925000028 ],
							[ 13.376577956000062, 13.71090231200003 ],
							[ 13.394236353000053, 13.710901818000025 ],
							[ 13.401803569000037, 13.710902012000076 ],
							[ 13.421352972000022, 13.709010220000039 ],
							[ 13.449733246000051, 13.710902626000063 ],
							[ 13.485048402000075, 13.709010361000026 ],
							[ 13.55694135400006, 13.71153243200007 ],
							[ 13.599823407000031, 13.710901893000027 ],
							[ 13.610544960000027, 13.708379985000022 ],
							[ 13.618112254000039, 13.709640164000064 ],
							[ 13.631642854000063, 13.711722340000051 ],
							[ 13.635930299000051, 13.707090742000048 ],
							[ 13.643230861000063, 13.699660162000043 ],
							[ 13.646889177000048, 13.695949232000032 ],
							[ 13.650140322000027, 13.691149573000075 ],
							[ 13.652690171000074, 13.687820722000026 ],
							[ 13.653902063000032, 13.685816225000053 ],
							[ 13.657370048000075, 13.680080100000055 ],
							[ 13.66133934800007, 13.67381040500004 ],
							[ 13.664600463000056, 13.669010483000022 ],
							[ 13.668569517000037, 13.662739556000076 ],
							[ 13.672860177000075, 13.653919981000058 ],
							[ 13.677949639000076, 13.647259805000033 ],
							[ 13.681200060000037, 13.642470019000029 ],
							[ 13.685569807000036, 13.637280879000059 ],
							[ 13.689940665000051, 13.632099555000025 ],
							[ 13.693910036000034, 13.625829475000046 ],
							[ 13.699390895000022, 13.620260071000075 ],
							[ 13.702649897000072, 13.615460939000059 ],
							[ 13.706299815000023, 13.611749489000033 ],
							[ 13.710269824000022, 13.605480340000042 ],
							[ 13.715680449000047, 13.596269814000038 ],
							[ 13.718219808000072, 13.59294062500004 ],
							[ 13.721480565000036, 13.588139032000072 ],
							[ 13.725839974000053, 13.58295965700006 ],
							[ 13.729819703000032, 13.576690153000072 ],
							[ 13.737440388000039, 13.56671005000004 ],
							[ 13.740699514000028, 13.561909422000042 ],
							[ 13.746499693000032, 13.553779343000031 ],
							[ 13.749039734000064, 13.550460061000024 ],
							[ 13.754119426000045, 13.543800010000041 ],
							[ 13.756660360000069, 13.540470498000047 ],
							[ 13.759209424000062, 13.537149916000033 ],
							[ 13.763569838000024, 13.531959888000074 ],
							[ 13.763774812000065, 13.531637044000036 ],
							[ 13.767550646000075, 13.525689928000077 ],
							[ 13.770090177000043, 13.52236033500003 ],
							[ 13.772629780000045, 13.519039774000078 ],
							[ 13.77589032000003, 13.514239920000023 ],
							[ 13.780890629000055, 13.503939830000036 ],
							[ 13.781685378000077, 13.502305697000054 ],
							[ 13.785180124000021, 13.495119938000073 ],
							[ 13.790980518000026, 13.486990205000041 ],
							[ 13.794630551000068, 13.483280084000057 ],
							[ 13.799720108000031, 13.476630519000025 ],
							[ 13.803477237000038, 13.472167302000059 ],
							[ 13.804089580000038, 13.471439880000048 ],
							[ 13.806630690000077, 13.468109994000031 ],
							[ 13.808110144000068, 13.465773375000026 ],
							[ 13.810600814000054, 13.461839661000056 ],
							[ 13.813860498000054, 13.45705074500006 ],
							[ 13.816044710000028, 13.454822790000037 ],
							[ 13.816730436000057, 13.454123331000062 ],
							[ 13.817662539000025, 13.452590006000037 ],
							[ 13.824331817000029, 13.441618935000065 ],
							[ 13.834766740000077, 13.423358661000066 ],
							[ 13.836944664000043, 13.41954719000006 ],
							[ 13.850188313000046, 13.399366193000048 ],
							[ 13.857854491000069, 13.395150095000076 ],
							[ 13.870410125000035, 13.37872985000007 ],
							[ 13.872949966000022, 13.375399506000065 ],
							[ 13.877639513000076, 13.367659637000031 ],
							[ 13.880180506000045, 13.364339961000042 ],
							[ 13.883440523000047, 13.359540209000045 ],
							[ 13.889639360000047, 13.352500217000056 ],
							[ 13.892179988000066, 13.34916970300003 ],
							[ 13.892321547000051, 13.349025834000031 ],
							[ 13.89582990100007, 13.345460225000068 ],
							[ 13.898988771000063, 13.339757318000068 ],
							[ 13.903379833000031, 13.33182985600007 ],
							[ 13.908060093000074, 13.324090258000069 ],
							[ 13.912429734000057, 13.31890951500003 ],
							[ 13.91569008700003, 13.314110022000023 ],
							[ 13.918230201000029, 13.310779320000051 ],
							[ 13.922599558000059, 13.305600156000025 ],
							[ 13.927679647000048, 13.298949963000041 ],
							[ 13.931659657000068, 13.292679762000034 ],
							[ 13.933800045000055, 13.288260421000075 ],
							[ 13.937769458000048, 13.281990287000042 ],
							[ 13.940747568000063, 13.275130954000076 ],
							[ 13.949828973000024, 13.263526755000044 ],
							[ 13.955378865000057, 13.256968778000044 ],
							[ 13.959414790000039, 13.24889633500004 ],
							[ 13.964964374000033, 13.243346133000045 ],
							[ 13.969000336000022, 13.238301334000028 ],
							[ 13.973679702000027, 13.232850151000036 ],
							[ 13.978760135000073, 13.226200304000031 ],
							[ 13.980899682000029, 13.221790278000071 ],
							[ 13.983450077000043, 13.218460101000062 ],
							[ 13.991390098000068, 13.205920304000074 ],
							[ 13.994649529000071, 13.201120619000051 ],
							[ 13.998299175000056, 13.19740949100003 ],
							[ 14.001560738000023, 13.192609712000035 ],
							[ 14.004099365000059, 13.189290259000074 ],
							[ 14.005503008000062, 13.187337695000053 ],
							[ 14.012388144000056, 13.177759999000045 ],
							[ 14.016424741000037, 13.171706126000061 ],
							[ 14.022999193000032, 13.165610136000055 ],
							[ 14.025549956000077, 13.162280198000076 ],
							[ 14.028089148000049, 13.158949822000068 ],
							[ 14.030630591000033, 13.155619397000066 ],
							[ 14.031560179000053, 13.150011771000038 ],
							[ 14.038622496000073, 13.140426864000062 ],
							[ 14.042659367000056, 13.134371526000052 ],
							[ 14.044676282000069, 13.13033564400007 ],
							[ 14.049449510000045, 13.128309982000076 ],
							[ 14.051999765000062, 13.124979852000024 ],
							[ 14.054860468000072, 13.119099628000072 ],
							[ 14.05953932500006, 13.111359642000025 ],
							[ 14.062089363000041, 13.10803056900005 ],
							[ 14.065739549000057, 13.104310246000068 ],
							[ 14.069389803000035, 13.100600066000027 ],
							[ 14.071930460000033, 13.097269919000041 ],
							[ 14.075509680000039, 13.089919807000058 ],
							[ 14.082456378000074, 13.082859445000054 ],
							[ 14.082819857000061, 13.082490019000033 ],
							[ 14.083945398000026, 13.080175870000062 ],
							[ 14.08710960600007, 13.073670159000073 ],
							[ 14.08703054800003, 13.07003041400003 ],
							[ 14.088700599000049, 13.060890303000065 ],
							[ 14.091560191000042, 13.055009767000058 ],
							[ 14.091799176000052, 13.048810197000023 ],
							[ 14.092429737000032, 13.043700239000032 ],
							[ 14.093042527000023, 13.038806150000028 ],
							[ 14.09311815600006, 13.038461556000073 ],
							[ 14.096249200000045, 13.030150288000073 ],
							[ 14.096879656000056, 13.025039742000047 ],
							[ 14.097519318000025, 13.019929640000043 ],
							[ 14.101729926000075, 13.007470349000073 ],
							[ 14.101249917000075, 13.002739977000033 ],
							[ 14.101637105000066, 12.999646325000072 ],
							[ 14.101889477000043, 12.997629859000028 ],
							[ 14.102529025000024, 12.992520293000041 ],
							[ 14.103880366000055, 12.985939902000041 ],
							[ 14.105230508000034, 12.979359518000024 ],
							[ 14.105722289000028, 12.974946918000057 ],
							[ 14.105217751000055, 12.968893076000029 ],
							[ 14.106227152000031, 12.961324627000067 ],
							[ 14.11076754000004, 12.943163181000045 ],
							[ 14.111776403000022, 12.937107780000076 ],
							[ 14.112280815000076, 12.934080939000069 ],
							[ 14.112758226000039, 12.93314491700005 ],
							[ 14.116040143000077, 12.92671032800007 ],
							[ 14.117070768000076, 12.922680147000051 ],
							[ 14.11730921700007, 12.91647994300007 ],
							[ 14.117229870000074, 12.91284014200005 ],
							[ 14.118980229000044, 12.907349719000024 ],
							[ 14.119930232000058, 12.899680310000065 ],
							[ 14.119059917000072, 12.893870470000024 ],
							[ 14.119700172000023, 12.88875968900004 ],
							[ 14.120730635000029, 12.884730060000038 ],
							[ 14.122871017000023, 12.88031959500006 ],
							[ 14.12461999900006, 12.874830277000058 ],
							[ 14.125260003000051, 12.869709873000033 ],
							[ 14.126290495000035, 12.865690384000061 ],
							[ 14.127639776000024, 12.859109859000057 ],
							[ 14.130499299000064, 12.853219888000069 ],
							[ 14.131139335000057, 12.848110200000065 ],
							[ 14.130579850000061, 12.839750172000038 ],
							[ 14.131940451000048, 12.833170015000064 ],
							[ 14.132570083000076, 12.828059905000032 ],
							[ 14.132809360000067, 12.821860167000068 ],
							[ 14.135270253000044, 12.814889679000032 ],
							[ 14.137200765000046, 12.808299950000048 ],
							[ 14.138049920000071, 12.805370540000069 ],
							[ 14.138689747000058, 12.800259692000054 ],
							[ 14.139640197000062, 12.792590173000065 ],
							[ 14.139809118000073, 12.782759507000037 ],
							[ 14.141469612000037, 12.773619627000073 ],
							[ 14.143619434000072, 12.76921001200003 ],
							[ 14.144570864000059, 12.761540428000046 ],
							[ 14.146639827000058, 12.75349041100003 ],
							[ 14.147589976000063, 12.745820270000024 ],
							[ 14.14893956800006, 12.739239574000067 ],
							[ 14.148870311000053, 12.735600736000038 ],
							[ 14.150540694000028, 12.726460640000028 ],
							[ 14.150770518000058, 12.720270023000069 ],
							[ 14.15097408400004, 12.719575106000036 ],
							[ 14.151847732000022, 12.71659272100004 ],
							[ 14.15062425800005, 12.711087208000038 ],
							[ 14.152765266000074, 12.704970150000065 ],
							[ 14.154155379000031, 12.700997538000024 ],
							[ 14.155669397000054, 12.687376159000053 ],
							[ 14.159201236000058, 12.670222047000038 ],
							[ 14.163236502000075, 12.648528347000024 ],
							[ 14.166263096000023, 12.62784298300005 ],
							[ 14.168281561000072, 12.613717264000059 ],
							[ 14.17080493900005, 12.600599267000064 ],
							[ 14.172630857000058, 12.590740849000042 ],
							[ 14.173327794000045, 12.586977817000047 ],
							[ 14.174877080000044, 12.58077613200004 ],
							[ 14.177362907000031, 12.570833427000025 ],
							[ 14.178372596000031, 12.551157853000063 ],
							[ 14.181903542000043, 12.534508463000066 ],
							[ 14.185940179000056, 12.524923198000067 ],
							[ 14.192498328000056, 12.518868565000048 ],
							[ 14.195020516000056, 12.516850729000055 ],
							[ 14.197039154000038, 12.509787485000061 ],
							[ 14.197038378000059, 12.504238421000025 ],
							[ 14.196680604000051, 12.502090667000061 ],
							[ 14.196534116000066, 12.501211282000043 ],
							[ 14.192223500000068, 12.498193871000069 ],
							[ 14.19148890300005, 12.497679657000049 ],
							[ 14.19039185500003, 12.493565457000045 ],
							[ 14.190329444000042, 12.493331402000024 ],
							[ 14.189470891000042, 12.49011162100004 ],
							[ 14.186948308000069, 12.483552964000069 ],
							[ 14.183365952000031, 12.480797003000077 ],
							[ 14.18039083900004, 12.478508204000036 ],
							[ 14.177180824000061, 12.474495981000075 ],
							[ 14.176354643000025, 12.473463332000051 ],
							[ 14.175345660000062, 12.469427276000033 ],
							[ 14.178876598000045, 12.463373110000077 ],
							[ 14.179885340000055, 12.455804716000046 ],
							[ 14.17887717800005, 12.451264647000073 ],
							[ 14.17890217300004, 12.451164650000067 ],
							[ 14.179886123000074, 12.447228219000067 ],
							[ 14.181399518000035, 12.445210348000046 ],
							[ 14.186948914000027, 12.44672418600004 ],
							[ 14.193002912000054, 12.447228538000047 ],
							[ 14.194902067000044, 12.446710381000059 ],
							[ 14.195394965000048, 12.446575901000074 ],
							[ 14.19822073000006, 12.442151301000024 ],
							[ 14.192021786000055, 12.438276417000054 ],
							[ 14.192216277000057, 12.437737886000036 ],
							[ 14.193718963000038, 12.433577053000022 ],
							[ 14.195380169000032, 12.428977290000034 ],
							[ 14.187631178000061, 12.42949386500004 ],
							[ 14.185822130000076, 12.424844065000059 ],
							[ 14.179623100000072, 12.423811043000057 ],
							[ 14.177199722000069, 12.423437727000021 ],
							[ 14.17626578900007, 12.423293857000033 ],
							[ 14.174457350000068, 12.420194417000062 ],
							[ 14.178290642000036, 12.415910392000058 ],
							[ 14.178848337000034, 12.415287122000052 ],
							[ 14.175588287000039, 12.410693556000069 ],
							[ 14.173164940000049, 12.407278946000076 ],
							[ 14.173940911000045, 12.404696740000077 ],
							[ 14.186339466000049, 12.401337456000022 ],
							[ 14.193571728000052, 12.392813765000028 ],
							[ 14.197067418000074, 12.386988160000044 ],
							[ 14.198996839000074, 12.383772760000056 ],
							[ 14.199824258000035, 12.383250231000034 ],
							[ 14.202418207000051, 12.382057606000046 ],
							[ 14.207746763000046, 12.379341787000044 ],
							[ 14.209416687000044, 12.378823165000028 ],
							[ 14.212241478000067, 12.376967704000037 ],
							[ 14.213568818000056, 12.375958627000045 ],
							[ 14.217215371000066, 12.372936050000021 ],
							[ 14.217463398000064, 12.372033217000023 ],
							[ 14.218490121000059, 12.368295878000026 ],
							[ 14.218456098000047, 12.368175620000045 ],
							[ 14.219402860000059, 12.364658189000068 ],
							[ 14.22147009400004, 12.361041832000069 ],
							[ 14.225601837000056, 12.359492159000069 ],
							[ 14.236434470000063, 12.361954014000048 ],
							[ 14.248333359000071, 12.364658190000057 ],
							[ 14.253758891000075, 12.363883263000048 ],
							[ 14.274422793000042, 12.368790408000052 ],
							[ 14.279847662000066, 12.367499231000068 ],
							[ 14.28449686600004, 12.364916615000027 ],
							[ 14.292504043000065, 12.349159415000031 ],
							[ 14.296896013000037, 12.35355108400006 ],
							[ 14.294553260000043, 12.358124817000032 ],
							[ 14.291471272000024, 12.364141750000044 ],
							[ 14.294632749000073, 12.367059160000053 ],
							[ 14.294829406000076, 12.36724063500003 ],
							[ 14.297413020000022, 12.36646651600006 ],
							[ 14.303352809000046, 12.355617548000055 ],
							[ 14.311361670000053, 12.357425560000024 ],
							[ 14.317818353000064, 12.36336620700007 ],
							[ 14.321104594000076, 12.365641332000052 ],
							[ 14.324535222000065, 12.368016419000071 ],
							[ 14.329544626000029, 12.369858480000062 ],
							[ 14.334354394000059, 12.366972902000043 ],
							[ 14.341324298000075, 12.363882802000035 ],
							[ 14.348318891000076, 12.359214648000034 ],
							[ 14.352789736000034, 12.357959864000065 ],
							[ 14.353145390000066, 12.358060654000042 ],
							[ 14.355774943000029, 12.360308994000036 ],
							[ 14.356079946000023, 12.361167513000055 ],
							[ 14.35638708700003, 12.362169805000065 ],
							[ 14.35668568400007, 12.362596962000055 ],
							[ 14.358148798000059, 12.362719725000034 ],
							[ 14.360621733000073, 12.361965014000077 ],
							[ 14.363964820000035, 12.360766312000067 ],
							[ 14.368764565000049, 12.359258939000028 ],
							[ 14.369036250000022, 12.358975944000065 ],
							[ 14.372580241000037, 12.357425517000024 ],
							[ 14.386333269000033, 12.356111375000069 ],
							[ 14.391764500000022, 12.357663402000071 ],
							[ 14.404868649000036, 12.360783868000055 ],
							[ 14.418918186000042, 12.356887651000022 ],
							[ 14.428227253000045, 12.359214973000064 ],
							[ 14.433658692000051, 12.360766408000075 ],
							[ 14.439999183000054, 12.36000817300004 ],
							[ 14.443710787000043, 12.357749168000055 ],
							[ 14.445940514000029, 12.35639208300006 ],
							[ 14.447231800000054, 12.350967522000076 ],
							[ 14.447914830000059, 12.350301200000047 ],
							[ 14.451502061000042, 12.346801715000026 ],
							[ 14.454981584000052, 12.350708801000053 ],
							[ 14.459630926000045, 12.354067556000075 ],
							[ 14.465466911000021, 12.35533627500007 ],
							[ 14.471482336000065, 12.351326048000033 ],
							[ 14.472449637000068, 12.350681190000046 ],
							[ 14.475713726000038, 12.346081458000072 ],
							[ 14.477195656000049, 12.343993132000037 ],
							[ 14.481587915000034, 12.347350882000057 ],
							[ 14.487265757000046, 12.348243640000021 ],
							[ 14.487965215000031, 12.348353620000069 ],
							[ 14.487190378000037, 12.34059525300006 ],
							[ 14.490645915000073, 12.338435853000021 ],
							[ 14.493396757000028, 12.336716824000064 ],
							[ 14.494416022000053, 12.335697472000049 ],
							[ 14.499725101000024, 12.330387941000026 ],
							[ 14.501930386000026, 12.328182469000069 ],
							[ 14.51046511100003, 12.325079019000043 ],
							[ 14.518998577000048, 12.324303906000068 ],
							[ 14.52214151000004, 12.318547962000025 ],
							[ 14.522916230000021, 12.31712914700006 ],
							[ 14.524724386000059, 12.312479746000065 ],
							[ 14.523653334000073, 12.309562532000029 ],
							[ 14.514343371000052, 12.311114329000077 ],
							[ 14.514343363000023, 12.307594141000038 ],
							[ 14.514343360000055, 12.306459550000056 ],
							[ 14.514960401000053, 12.303990863000024 ],
							[ 14.515894719000073, 12.300252798000031 ],
							[ 14.514713328000028, 12.296707149000042 ],
							[ 14.514343712000027, 12.295597840000028 ],
							[ 14.517446755000037, 12.293270221000057 ],
							[ 14.524749166000049, 12.29335876600004 ],
							[ 14.525242007000031, 12.293364742000051 ],
							[ 14.527364181000053, 12.290566988000023 ],
							[ 14.530859707000047, 12.285958684000036 ],
							[ 14.530862480000053, 12.285961717000077 ],
							[ 14.532718641000031, 12.287353274000054 ],
							[ 14.533907793000026, 12.287980660000073 ],
							[ 14.53521807900006, 12.287961171000063 ],
							[ 14.536776559000032, 12.28703488900004 ],
							[ 14.539101403000075, 12.284807055000044 ],
							[ 14.539111928000068, 12.284747798000069 ],
							[ 14.539169081000068, 12.284736356000053 ],
							[ 14.546721354000056, 12.277813277000064 ],
							[ 14.548478731000046, 12.276202310000031 ],
							[ 14.548478965000072, 12.266892157000029 ],
							[ 14.551044675000071, 12.257273488000067 ],
							[ 14.551582886000062, 12.255255771000066 ],
							[ 14.554687897000065, 12.250485692000041 ],
							[ 14.556011167000065, 12.251463320000028 ],
							[ 14.560630389000039, 12.254875989000027 ],
							[ 14.559339953000062, 12.243618208000044 ],
							[ 14.556237508000038, 12.242066612000031 ],
							[ 14.550589289000072, 12.235713017000023 ],
							[ 14.550030436000043, 12.235084372000074 ],
							[ 14.55546137500005, 12.230429880000031 ],
							[ 14.561561807000032, 12.229320460000054 ],
							[ 14.563995467000041, 12.228877877000059 ],
							[ 14.572530136000069, 12.226549878000071 ],
							[ 14.577960046000044, 12.222671429000059 ],
							[ 14.582844859000033, 12.222587421000071 ],
							[ 14.593176682000035, 12.221296490000043 ],
							[ 14.594635883000024, 12.220891212000026 ],
							[ 14.597826944000076, 12.220004927000048 ],
							[ 14.597181309000064, 12.214844656000025 ],
							[ 14.597051243000067, 12.213805097000034 ],
							[ 14.603561854000077, 12.206379036000044 ],
							[ 14.607440520000068, 12.199397112000042 ],
							[ 14.607502560000057, 12.199319596000066 ],
							[ 14.609966885000063, 12.196240556000078 ],
							[ 14.606665752000026, 12.194742106000035 ],
							[ 14.605137841000044, 12.193468577000033 ],
							[ 14.602011291000053, 12.190862566000021 ],
							[ 14.604025939000053, 12.187716569000031 ],
							[ 14.612922039000068, 12.187320807000049 ],
							[ 14.615650684000059, 12.187199417000045 ],
							[ 14.616625931000044, 12.186242539000034 ],
							[ 14.621405477000053, 12.181553015000077 ],
							[ 14.624508551000076, 12.178449794000073 ],
							[ 14.631491329000028, 12.177674558000035 ],
							[ 14.633042938000074, 12.184655939000038 ],
							[ 14.638473366000028, 12.186984151000047 ],
							[ 14.642353625000055, 12.186984085000063 ],
							[ 14.64623313900006, 12.190087207000033 ],
							[ 14.650110939000058, 12.18853548900006 ],
							[ 14.653880703000027, 12.191074592000064 ],
							[ 14.658528773000057, 12.194949070000064 ],
							[ 14.66407543400004, 12.189310816000045 ],
							[ 14.665388750000034, 12.186182107000036 ],
							[ 14.665503637000029, 12.18590841200006 ],
							[ 14.665187338000067, 12.184431815000039 ],
							[ 14.664113771000075, 12.179420022000045 ],
							[ 14.663954328000045, 12.178675683000051 ],
							[ 14.673770026000057, 12.17480075800006 ],
							[ 14.677482159000022, 12.16650874000004 ],
							[ 14.678040542000076, 12.165261447000034 ],
							[ 14.678816234000067, 12.156727014000069 ],
							[ 14.678677833000052, 12.150778052000021 ],
							[ 14.675713913000038, 12.148968798000055 ],
							[ 14.66640359400003, 12.14121082500003 ],
							[ 14.656317498000021, 12.134227794000026 ],
							[ 14.655938376000051, 12.131384011000023 ],
							[ 14.654766135000045, 12.122591053000065 ],
							[ 14.653796257000067, 12.114346155000021 ],
							[ 14.653214605000073, 12.109401554000044 ],
							[ 14.647784031000072, 12.101643445000036 ],
							[ 14.643573194000055, 12.095853939000051 ],
							[ 14.641577405000078, 12.09310991600006 ],
							[ 14.640189134000025, 12.088783491000072 ],
							[ 14.641578011000036, 12.083023777000051 ],
							[ 14.64478611100003, 12.077677062000078 ],
							[ 14.646232993000069, 12.075265646000048 ],
							[ 14.652330186000029, 12.067085446000021 ],
							[ 14.651879453000049, 12.063627899000039 ],
							[ 14.65155562800004, 12.061143853000033 ],
							[ 14.644680621000077, 12.055094884000027 ],
							[ 14.637697649000074, 12.052767704000075 ],
							[ 14.624896834000026, 12.04578484700005 ],
							[ 14.62336182100006, 12.03862074400007 ],
							[ 14.623189665000041, 12.037817271000051 ],
							[ 14.62310388700007, 12.03684467100004 ],
							[ 14.623018536000075, 12.035876908000034 ],
							[ 14.623090416000025, 12.03424671700003 ],
							[ 14.623198109000043, 12.031804302000069 ],
							[ 14.623214057000041, 12.031442616000049 ],
							[ 14.623433140000031, 12.03001262500004 ],
							[ 14.624014625000029, 12.026217167000027 ],
							[ 14.624094685000046, 12.025694612000052 ],
							[ 14.623788184000034, 12.023005691000037 ],
							[ 14.623875244000033, 12.017438991000063 ],
							[ 14.623916071000053, 12.017231851000076 ],
							[ 14.630115043000046, 12.005607584000074 ],
							[ 14.634497678000059, 11.995965909000063 ],
							[ 14.635776486000054, 11.993152566000049 ],
							[ 14.636572683000054, 11.991400954000028 ],
							[ 14.638019238000027, 11.98948063000006 ],
							[ 14.641190084000073, 11.985271285000067 ],
							[ 14.645067762000053, 11.976737195000055 ],
							[ 14.645542602000035, 11.975274883000054 ],
							[ 14.646599826000056, 11.972019068000066 ],
							[ 14.647939130000054, 11.967894562000026 ],
							[ 14.656353185000057, 11.962694413000065 ],
							[ 14.657481272000041, 11.961997220000057 ],
							[ 14.658012542000051, 11.956012559000044 ],
							[ 14.655154328000037, 11.951910807000047 ],
							[ 14.645068344000038, 11.942601125000067 ],
							[ 14.639156477000029, 11.930439885000055 ],
							[ 14.639638181000066, 11.923981916000059 ],
							[ 14.642028400000072, 11.916082823000067 ],
							[ 14.643220094000071, 11.912144562000037 ],
							[ 14.643546499000024, 11.911065872000052 ],
							[ 14.641223160000038, 11.905125676000068 ],
							[ 14.638085821000061, 11.905362009000044 ],
							[ 14.634207032000063, 11.900707116000035 ],
							[ 14.628565583000068, 11.893759890000069 ],
							[ 14.625681817000043, 11.893725244000052 ],
							[ 14.625672979000058, 11.893725138000036 ],
							[ 14.625501007000025, 11.893290762000049 ],
							[ 14.622107392000032, 11.884718967000026 ],
							[ 14.61481169800004, 11.878209064000032 ],
							[ 14.607383158000061, 11.868445132000033 ],
							[ 14.607002635000072, 11.865931796000041 ],
							[ 14.606092666000052, 11.859921505000045 ],
							[ 14.614358620000075, 11.853204767000022 ],
							[ 14.609488637000027, 11.84553177500004 ],
							[ 14.606767712000021, 11.841244771000049 ],
							[ 14.605833712000049, 11.839773189000027 ],
							[ 14.60588978100003, 11.837761622000073 ],
							[ 14.605921301000024, 11.836630766000042 ],
							[ 14.605987689000074, 11.834248976000026 ],
							[ 14.606016642000043, 11.833210225000073 ],
							[ 14.606092907000061, 11.830474073000062 ],
							[ 14.610149558000046, 11.819112763000021 ],
							[ 14.610932757000057, 11.816919288000065 ],
							[ 14.606868116000044, 11.80774305500006 ],
							[ 14.606277250000062, 11.800626562000048 ],
							[ 14.607829329000026, 11.786661975000072 ],
							[ 14.61022600900003, 11.779845070000022 ],
							[ 14.608605864000026, 11.776576937000073 ],
							[ 14.602476161000027, 11.767704710000032 ],
							[ 14.597052010000027, 11.758405885000059 ],
							[ 14.592918007000037, 11.755047820000073 ],
							[ 14.588614032000066, 11.75334205300004 ],
							[ 14.587180372000034, 11.752773859000058 ],
							[ 14.583021014000053, 11.751125407000075 ],
							[ 14.577573112000039, 11.74476835400003 ],
							[ 14.567603898000073, 11.734124078000036 ],
							[ 14.555464351000069, 11.729216290000068 ],
							[ 14.553655488000061, 11.726374531000033 ],
							[ 14.553521945000057, 11.717615249000062 ],
							[ 14.558952874000056, 11.705202100000065 ],
							[ 14.572917920000066, 11.69201281200003 ],
							[ 14.583003292000058, 11.686582537000049 ],
							[ 14.605501838000066, 11.672617194000054 ],
							[ 14.617915657000026, 11.657876957000042 ],
							[ 14.631103345000042, 11.653222295000035 ],
							[ 14.63963829000005, 11.639257147000023 ],
							[ 14.64274172100005, 11.62606824900007 ],
							[ 14.64429227200003, 11.607448871000031 ],
							[ 14.644553981000058, 11.606990991000032 ],
							[ 14.647396215000072, 11.60201827700007 ],
							[ 14.645424638000065, 11.596892206000064 ],
							[ 14.643517074000044, 11.591932569000051 ],
							[ 14.640413030000047, 11.574089056000048 ],
							[ 14.638970026000038, 11.571559697000055 ],
							[ 14.636660190000043, 11.568690296000057 ],
							[ 14.635069858000065, 11.564350405000027 ],
							[ 14.633879214000046, 11.561100100000033 ],
							[ 14.631420508000076, 11.550950313000044 ],
							[ 14.629119996000043, 11.54808018500006 ],
							[ 14.627139548000059, 11.542659526000023 ],
							[ 14.624759571000027, 11.536149802000068 ],
							[ 14.620149758000025, 11.530409563000035 ],
							[ 14.615229319000036, 11.527230316000043 ],
							[ 14.60910950300007, 11.520799655000076 ],
							[ 14.605300158000034, 11.517229981000071 ],
							[ 14.601889238000069, 11.514749382000048 ],
							[ 14.592829664000021, 11.510559769000054 ],
							[ 14.584179894000044, 11.507449862000044 ],
							[ 14.580760044000044, 11.504969760000051 ],
							[ 14.574730447000036, 11.502169440000046 ],
							[ 14.568690807000053, 11.499379912000052 ],
							[ 14.558450138000069, 11.49192977000007 ],
							[ 14.554340790000026, 11.490028216000042 ],
							[ 14.54726858600003, 11.487629003000052 ],
							[ 14.519616560000031, 11.475340046000042 ],
							[ 14.501182996000068, 11.469809817000055 ],
							[ 14.475990616000047, 11.466737030000047 ],
							[ 14.457924953000031, 11.462722647000021 ],
							[ 14.453870244000029, 11.461821647000022 ],
							[ 14.446495539000068, 11.448917491000032 ],
							[ 14.441579496000031, 11.430484110000066 ],
							[ 14.429905279000025, 11.415121837000072 ],
							[ 14.419708766000042, 11.408890124000038 ],
							[ 14.413346616000069, 11.404391218000058 ],
							[ 14.400537936000035, 11.395333736000055 ],
							[ 14.370318203000068, 11.374518659000046 ],
							[ 14.368328592000069, 11.373148233000052 ],
							[ 14.345083767000062, 11.353347924000047 ],
							[ 14.310387595000066, 11.334968705000051 ],
							[ 14.279629143000022, 11.32408119300004 ],
							[ 14.251330446000054, 11.306863901000042 ],
							[ 14.202388111000062, 11.272315665000065 ],
							[ 14.185530980000067, 11.25241582700005 ],
							[ 14.182404113000075, 11.241695978000052 ],
							[ 14.182371395000075, 11.241583812000044 ],
							[ 14.181509403000064, 11.238628641000048 ],
							[ 14.176321862000066, 11.232408594000049 ],
							[ 14.168675723000035, 11.232515197000055 ],
							[ 14.164998790000027, 11.234394784000074 ],
							[ 14.15349098400003, 11.240277384000024 ],
							[ 14.114091736000034, 11.248373481000044 ],
							[ 14.110040251000044, 11.249545147000049 ],
							[ 14.109865870000021, 11.249475369000038 ],
							[ 14.109610448000069, 11.24937316300003 ],
							[ 14.10616832900007, 11.25066484000007 ],
							[ 14.101435797000022, 11.251524990000064 ],
							[ 14.097133517000032, 11.253676580000047 ],
							[ 14.092861306000032, 11.256006789000025 ],
							[ 14.09240109700005, 11.25625780300004 ],
							[ 14.089387651000038, 11.258409700000072 ],
							[ 14.085516280000036, 11.261421484000039 ],
							[ 14.078201131000071, 11.26701470200004 ],
							[ 14.076120451000065, 11.273079794000068 ],
							[ 14.073122072000047, 11.274120415000027 ],
							[ 14.063069847000065, 11.28669824900004 ],
							[ 14.050778814000068, 11.29099922100005 ],
							[ 14.046155556000031, 11.292421916000023 ],
							[ 14.034802880000029, 11.295915427000068 ],
							[ 14.027429576000031, 11.297759187000054 ],
							[ 14.01452667500007, 11.299602428000071 ],
							[ 14.004080272000067, 11.306975160000036 ],
							[ 13.990561526000022, 11.312505560000034 ],
							[ 13.975814942000056, 11.305132840000056 ],
							[ 13.974994135000031, 11.303608851000035 ],
							[ 13.973744863000036, 11.301289330000031 ],
							[ 13.971513834000064, 11.297143968000057 ],
							[ 13.967212237000069, 11.284854900000028 ],
							[ 13.963525323000056, 11.267035724000038 ],
							[ 13.957995680000067, 11.254131208000047 ],
							[ 13.946803408000051, 11.242539839000074 ],
							[ 13.943032158000051, 11.234945839000034 ],
							[ 13.932556174000069, 11.221534822000024 ],
							[ 13.928141422000067, 11.212277567000058 ],
							[ 13.925134572000047, 11.205832260000022 ],
							[ 13.924813696000058, 11.202516354000068 ],
							[ 13.922356979000028, 11.191456122000034 ],
							[ 13.916826753000066, 11.187154707000047 ],
							[ 13.915596500000049, 11.181010056000048 ],
							[ 13.911611676000064, 11.173040474000061 ],
							[ 13.910066311000037, 11.169949769000027 ],
							[ 13.897778010000025, 11.157046141000023 ],
							[ 13.891933295000058, 11.155200539000077 ],
							[ 13.88610256100003, 11.153359351000063 ],
							[ 13.884937985000022, 11.151263022000023 ],
							[ 13.881615337000028, 11.145281991000047 ],
							[ 13.879958070000043, 11.142298779000043 ],
							[ 13.881801634000055, 11.132467177000024 ],
							[ 13.883135045000074, 11.129267708000043 ],
							[ 13.885871919000067, 11.124165815000026 ],
							[ 13.880045873000029, 11.120814242000051 ],
							[ 13.872584246000031, 11.118948720000049 ],
							[ 13.865826193000032, 11.110346677000052 ],
							[ 13.863843430000031, 11.106932240000049 ],
							[ 13.854764567000075, 11.091297893000046 ],
							[ 13.851450687000067, 11.088453190000052 ],
							[ 13.846242006000068, 11.087838216000023 ],
							[ 13.844319356000028, 11.087611214000049 ],
							[ 13.836946087000058, 11.079622992000054 ],
							[ 13.825270614000033, 11.069177773000035 ],
							[ 13.824693178000075, 11.068124800000021 ],
							[ 13.823681358000044, 11.066279712000039 ],
							[ 13.814824437000027, 11.050128817000029 ],
							[ 13.810307371000022, 11.038219236000032 ],
							[ 13.804847171000063, 11.03516541700003 ],
							[ 13.801689816000021, 11.033399549000023 ],
							[ 13.800421396000047, 11.022771216000024 ],
							[ 13.800249621000034, 11.021331884000062 ],
							[ 13.798528275000024, 11.013587251000047 ],
							[ 13.796849448000046, 11.011996786000054 ],
							[ 13.790353152000023, 11.005842409000024 ],
							[ 13.782608001000028, 11.004551750000076 ],
							[ 13.782085379000023, 11.00466782500007 ],
							[ 13.778734739000072, 11.005412002000071 ],
							[ 13.775722987000051, 11.004120635000049 ],
							[ 13.771420899000077, 11.003690620000043 ],
							[ 13.767069406000076, 11.00385000700004 ],
							[ 13.76326031900004, 11.000289844000065 ],
							[ 13.757940097000073, 10.99602010600006 ],
							[ 13.753019785000049, 10.992839701000037 ],
							[ 13.750825643000042, 10.988772651000033 ],
							[ 13.751088456000048, 10.988463968000076 ],
							[ 13.730387087000054, 10.978565756000023 ],
							[ 13.721728724000059, 10.971900855000058 ],
							[ 13.713026558000024, 10.961848662000023 ],
							[ 13.705236854000077, 10.956019232000074 ],
							[ 13.700051189000021, 10.952697384000032 ],
							[ 13.695755510000026, 10.951904905000049 ],
							[ 13.692258379000066, 10.94586821300004 ],
							[ 13.692224932000045, 10.945583558000067 ],
							[ 13.688402022000048, 10.944648713000049 ],
							[ 13.676028415000076, 10.94467727500006 ],
							[ 13.669884256000046, 10.944691458000023 ],
							[ 13.669883949000052, 10.944691459000069 ],
							[ 13.664097038000023, 10.944703839000056 ],
							[ 13.659538195000039, 10.944714434000048 ],
							[ 13.658308997000063, 10.944717291000075 ],
							[ 13.651371440000048, 10.945878053000058 ],
							[ 13.648082453000029, 10.945885556000064 ],
							[ 13.640953835000062, 10.945901819000028 ],
							[ 13.63054287600005, 10.947068902000069 ],
							[ 13.626769333000027, 10.948011551000036 ],
							[ 13.625918801000068, 10.948224018000076 ],
							[ 13.625918586000068, 10.948224018000076 ],
							[ 13.621289244000025, 10.948234284000023 ],
							[ 13.614438692000022, 10.949218973000029 ],
							[ 13.613192969000067, 10.949398032000033 ],
							[ 13.61319261500006, 10.949398033000023 ],
							[ 13.613192558000037, 10.949398035000058 ],
							[ 13.603933813000026, 10.94941876200005 ],
							[ 13.596988319000047, 10.949434334000046 ],
							[ 13.590043948000073, 10.949449744000049 ],
							[ 13.58667592300003, 10.949458126000025 ],
							[ 13.585414395000043, 10.949461265000025 ],
							[ 13.577307328000074, 10.948335811000049 ],
							[ 13.573770929000034, 10.946599556000024 ],
							[ 13.572668306000025, 10.946058204000053 ],
							[ 13.565707036000049, 10.942641126000069 ],
							[ 13.564445833000036, 10.942022319000046 ],
							[ 13.563387027000033, 10.94150281800006 ],
							[ 13.55642180500007, 10.936941925000042 ],
							[ 13.556063356000038, 10.936765907000051 ],
							[ 13.551781928000025, 10.934663494000063 ],
							[ 13.550673609000057, 10.933034802000066 ],
							[ 13.549450405000073, 10.931237284000076 ],
							[ 13.549369467000076, 10.931184248000022 ],
							[ 13.549368766000043, 10.931183442000076 ],
							[ 13.524451293000027, 10.902520899000024 ],
							[ 13.502311979000069, 10.888320371000077 ],
							[ 13.487570493000021, 10.880230514000061 ],
							[ 13.464483985000072, 10.873791035000067 ],
							[ 13.458838203000028, 10.873002003000067 ],
							[ 13.457674843000063, 10.872839417000023 ],
							[ 13.454538033000063, 10.872877132000042 ],
							[ 13.450349273000029, 10.872410664000029 ],
							[ 13.444094704000065, 10.874036077000028 ],
							[ 13.437323609000032, 10.876184375000037 ],
							[ 13.426395229000036, 10.880449352000028 ],
							[ 13.409740765000038, 10.886849358000063 ],
							[ 13.392556615000046, 10.892738061000045 ],
							[ 13.38060654800006, 10.899080959000059 ],
							[ 13.376984166000057, 10.902224468000043 ],
							[ 13.372901272000036, 10.910541267000042 ],
							[ 13.37037433200004, 10.917806084000063 ],
							[ 13.366789054000037, 10.92404977700005 ],
							[ 13.365191881000044, 10.926040372000045 ],
							[ 13.363089023000043, 10.927086718000055 ],
							[ 13.358470153000042, 10.929384930000026 ],
							[ 13.355002808000052, 10.930537167000068 ],
							[ 13.352522152000063, 10.931156885000064 ],
							[ 13.35037926900003, 10.931692221000048 ],
							[ 13.34574423600003, 10.930559373000051 ],
							[ 13.343418130000032, 10.928276079000057 ],
							[ 13.340913195000041, 10.926430523000022 ],
							[ 13.339447568000026, 10.924373899000045 ],
							[ 13.337746464000077, 10.921989074000066 ],
							[ 13.336970058000077, 10.920900609000057 ],
							[ 13.336411544000043, 10.914562004000061 ],
							[ 13.336172751000049, 10.911144445000048 ],
							[ 13.33594329500005, 10.907847504000074 ],
							[ 13.335940716000039, 10.907810443000074 ],
							[ 13.335939028000041, 10.907784833000051 ],
							[ 13.335634664000054, 10.903167475000032 ],
							[ 13.335390034000056, 10.899450500000057 ],
							[ 13.335309848000065, 10.89581012900004 ],
							[ 13.335214485000051, 10.895173400000033 ],
							[ 13.334439814000064, 10.890000107000049 ],
							[ 13.334700106000071, 10.885858519000067 ],
							[ 13.335714931000041, 10.881416512000044 ],
							[ 13.33593260400005, 10.879064559000028 ],
							[ 13.335932618000072, 10.879064412000048 ],
							[ 13.336046841000041, 10.874662321000073 ],
							[ 13.336140564000061, 10.871034146000056 ],
							[ 13.336198403000026, 10.868795126000066 ],
							[ 13.336180676000026, 10.864833578000059 ],
							[ 13.336180676000026, 10.864833551000061 ],
							[ 13.336180669000044, 10.864831946000038 ],
							[ 13.336301502000026, 10.859646595000072 ],
							[ 13.336247834000062, 10.857552506000047 ],
							[ 13.336224184000059, 10.854561029000024 ],
							[ 13.336223016000076, 10.854413278000038 ],
							[ 13.336210073000075, 10.852775845000053 ],
							[ 13.336160756000027, 10.850277625000047 ],
							[ 13.336160755000037, 10.850277541000025 ],
							[ 13.336160635000056, 10.850271478000025 ],
							[ 13.336160655000072, 10.850271224000039 ],
							[ 13.336376176000044, 10.84707536600007 ],
							[ 13.336376188000031, 10.847075190000055 ],
							[ 13.336300994000055, 10.84655269600006 ],
							[ 13.335600467000063, 10.841685016000042 ],
							[ 13.335283975000038, 10.836737578000054 ],
							[ 13.335116965000054, 10.833611356000063 ],
							[ 13.335113041000056, 10.833537902000046 ],
							[ 13.334965628000077, 10.830797466000035 ],
							[ 13.334855686000026, 10.828753637000034 ],
							[ 13.334826859000032, 10.822620124000025 ],
							[ 13.33480787700006, 10.818455787000062 ],
							[ 13.334800892000032, 10.816821074000075 ],
							[ 13.334794560000034, 10.815339013000028 ],
							[ 13.334788757000069, 10.814164133000077 ],
							[ 13.334788443000036, 10.814162454000041 ],
							[ 13.334603267000034, 10.813171017000059 ],
							[ 13.334962165000036, 10.805615896000063 ],
							[ 13.334905457000048, 10.800878733000047 ],
							[ 13.334397719000037, 10.79599876900005 ],
							[ 13.334381381000071, 10.795761953000067 ],
							[ 13.334381357000041, 10.795761601000038 ],
							[ 13.333809968000025, 10.794091680000065 ],
							[ 13.332360928000071, 10.789856766000071 ],
							[ 13.330585640000038, 10.784100435000028 ],
							[ 13.328834707000055, 10.778423076000024 ],
							[ 13.328542368000058, 10.777287443000034 ],
							[ 13.328001525000047, 10.775186461000033 ],
							[ 13.326536510000039, 10.770111299000064 ],
							[ 13.325305337000032, 10.765846224000029 ],
							[ 13.322958949000054, 10.758986509000067 ],
							[ 13.322958946000028, 10.75898647300005 ],
							[ 13.322945272000027, 10.756104108000045 ],
							[ 13.322931803000074, 10.753265476000024 ],
							[ 13.320945960000074, 10.744299065000064 ],
							[ 13.319385949000036, 10.737255357000038 ],
							[ 13.318030406000048, 10.732858884000052 ],
							[ 13.317065061000051, 10.729727952000076 ],
							[ 13.31704508100006, 10.729671487000076 ],
							[ 13.315702434000059, 10.725877026000035 ],
							[ 13.314744179000058, 10.723249296000063 ],
							[ 13.312346812000044, 10.716676580000069 ],
							[ 13.311799165000025, 10.715077517000054 ],
							[ 13.311798615000043, 10.715075910000053 ],
							[ 13.311245585000052, 10.713461128000063 ],
							[ 13.308754194000073, 10.711173312000028 ],
							[ 13.308754174000057, 10.711173248000023 ],
							[ 13.308305678000067, 10.709716466000032 ],
							[ 13.306909153000049, 10.705180348000056 ],
							[ 13.30690908300005, 10.705180121000069 ],
							[ 13.305322856000032, 10.701915790000044 ],
							[ 13.304165276000049, 10.699533582000072 ],
							[ 13.302626350000025, 10.697020548000069 ],
							[ 13.300666440000043, 10.693820057000039 ],
							[ 13.297412178000059, 10.689828861000024 ],
							[ 13.29601003700003, 10.688109203000067 ],
							[ 13.291349376000028, 10.681255194000073 ],
							[ 13.290988930000026, 10.680666820000056 ],
							[ 13.287849810000068, 10.67554268300006 ],
							[ 13.283182789000023, 10.667544368000051 ],
							[ 13.280836688000022, 10.660684339000056 ],
							[ 13.279590006000035, 10.657841628000028 ],
							[ 13.279349892000027, 10.65729411500007 ],
							[ 13.278061754000078, 10.654356236000069 ],
							[ 13.276961176000043, 10.651846130000024 ],
							[ 13.275774640000066, 10.649141207000071 ],
							[ 13.275771871000074, 10.649134896000021 ],
							[ 13.273818664000032, 10.644682209000052 ],
							[ 13.270303249000051, 10.635536879000028 ],
							[ 13.268264553000051, 10.63105175100003 ],
							[ 13.265627337000069, 10.625249880000069 ],
							[ 13.265589885000054, 10.625185688000045 ],
							[ 13.26558981900007, 10.625185576000035 ],
							[ 13.264665244000071, 10.623600883000051 ],
							[ 13.262662907000049, 10.620168941000031 ],
							[ 13.260960332000025, 10.617251253000063 ],
							[ 13.259860526000068, 10.615098893000038 ],
							[ 13.259381373000053, 10.61416117400006 ],
							[ 13.259381148000045, 10.614160882000022 ],
							[ 13.247694655000032, 10.599004322000042 ],
							[ 13.243202074000067, 10.593220200000076 ],
							[ 13.237200809000058, 10.58453548500006 ],
							[ 13.236433615000067, 10.581991176000031 ],
							[ 13.235615278000068, 10.575070735000054 ],
							[ 13.235542812000062, 10.568871214000069 ],
							[ 13.235093182000071, 10.561946549000027 ],
							[ 13.234956799000031, 10.55027685500005 ],
							[ 13.235261498000057, 10.544802468000057 ],
							[ 13.235581282000055, 10.543570926000029 ],
							[ 13.235493946000076, 10.536098573000061 ],
							[ 13.238638629000036, 10.52252323700003 ],
							[ 13.24011069200003, 10.517254059000038 ],
							[ 13.241894213000023, 10.508363005000035 ],
							[ 13.242563701000051, 10.505087220000064 ],
							[ 13.241672054000048, 10.502161007000041 ],
							[ 13.240211133000059, 10.497366475000035 ],
							[ 13.239374016000056, 10.494619076000049 ],
							[ 13.238169931000073, 10.492531917000065 ],
							[ 13.238119134000044, 10.492400253000028 ],
							[ 13.236623780000059, 10.490092780000055 ],
							[ 13.235098146000041, 10.48715908500003 ],
							[ 13.233360912000023, 10.480292957000074 ],
							[ 13.231211377000022, 10.472834817000034 ],
							[ 13.23077051000007, 10.471305163000068 ],
							[ 13.230000064000023, 10.46920515100004 ],
							[ 13.228987548000077, 10.467162323000025 ],
							[ 13.226456012000028, 10.465205347000051 ],
							[ 13.224669048000067, 10.463823946000048 ],
							[ 13.219425830000034, 10.454567567000026 ],
							[ 13.217596048000075, 10.444425344000024 ],
							[ 13.217105648000029, 10.439692254000022 ],
							[ 13.213301388000048, 10.428231850000031 ],
							[ 13.213157997000053, 10.42760497300003 ],
							[ 13.21312043000006, 10.427537796000024 ],
							[ 13.208743560000073, 10.419118342000047 ],
							[ 13.20527168600006, 10.414923161000047 ],
							[ 13.196705049000059, 10.414173473000062 ],
							[ 13.19077401100003, 10.419322223000052 ],
							[ 13.184833046000051, 10.423624056000051 ],
							[ 13.171977996000066, 10.422075322000069 ],
							[ 13.166566063000062, 10.422767220000026 ],
							[ 13.15745051500005, 10.423932614000023 ],
							[ 13.145528730000024, 10.429147864000072 ],
							[ 13.139030414000047, 10.432222178000075 ],
							[ 13.121693798000024, 10.440424026000073 ],
							[ 13.11398248200004, 10.439662877000046 ],
							[ 13.104607664000071, 10.443154640000046 ],
							[ 13.093521131000045, 10.446665075000055 ],
							[ 13.078184126000053, 10.452762805000077 ],
							[ 13.060260265000068, 10.457194422000043 ],
							[ 13.052586610000048, 10.459819264000032 ],
							[ 13.032207718000052, 10.47443940200003 ],
							[ 13.024639508000064, 10.484656102000031 ],
							[ 13.024618101000044, 10.484685 ],
							[ 13.014554223000061, 10.503426441000045 ],
							[ 13.01133868900007, 10.507376814000054 ],
							[ 13.002730665000058, 10.51795201300007 ],
							[ 13.00217337600003, 10.518694154000059 ],
							[ 12.997386094000035, 10.525069366000025 ],
							[ 12.992031108000049, 10.528621495000039 ],
							[ 12.98626474200006, 10.533211576000042 ],
							[ 12.98307062300006, 10.535331438000071 ],
							[ 12.982804627000064, 10.535507973000051 ],
							[ 12.982804414000043, 10.535508079000067 ],
							[ 12.981540859000063, 10.536136942000041 ],
							[ 12.974765820000073, 10.539508832000024 ],
							[ 12.973566402000074, 10.54010577400004 ],
							[ 12.968498067000041, 10.542988230000049 ],
							[ 12.965485186000024, 10.544701711000073 ],
							[ 12.964725351000027, 10.545079856000029 ],
							[ 12.958557632000065, 10.548149326000043 ],
							[ 12.940271426000038, 10.554981368000028 ],
							[ 12.940070152000033, 10.555056570000033 ],
							[ 12.940069669000025, 10.555056748000027 ],
							[ 12.934287823000034, 10.556214462000071 ],
							[ 12.927355355000032, 10.558518396000068 ],
							[ 12.920680370000071, 10.56101202900004 ],
							[ 12.918111474000057, 10.561971714000038 ],
							[ 12.917121508000037, 10.562218982000047 ],
							[ 12.90886309800004, 10.564281723000022 ],
							[ 12.902138205000028, 10.565775946000031 ],
							[ 12.898456162000059, 10.566594070000065 ],
							[ 12.890400727000042, 10.569271035000043 ],
							[ 12.888055662000056, 10.570050342000059 ],
							[ 12.875340318000042, 10.573511331000077 ],
							[ 12.868332747000068, 10.574220561000061 ],
							[ 12.863772538000035, 10.57468209600006 ],
							[ 12.858971538000048, 10.574693256000046 ],
							[ 12.855609634000075, 10.57470107100005 ],
							[ 12.853516724000031, 10.574705777000077 ],
							[ 12.847568955000042, 10.574719152000057 ],
							[ 12.846181383000044, 10.574448200000063 ],
							[ 12.844958667000071, 10.574209440000061 ],
							[ 12.844958427000051, 10.574209237000048 ],
							[ 12.84246392600005, 10.572097671000051 ],
							[ 12.832522701000073, 10.574753688000044 ],
							[ 12.825755546000039, 10.574768896000023 ],
							[ 12.824421490000077, 10.574771894000037 ],
							[ 12.819888821000063, 10.575423138000076 ],
							[ 12.816324348000023, 10.575935274000074 ],
							[ 12.810500309000076, 10.576191893000043 ],
							[ 12.810500145000049, 10.576191892000054 ],
							[ 12.804721550000068, 10.576082855000038 ],
							[ 12.798963391000029, 10.575974204000033 ],
							[ 12.788552645000038, 10.577142613000035 ],
							[ 12.780450229000053, 10.577160996000032 ],
							[ 12.776977121000073, 10.577169309000055 ],
							[ 12.774668686000041, 10.578318464000063 ],
							[ 12.770708207000041, 10.579306880000047 ],
							[ 12.770044417000065, 10.579472542000076 ],
							[ 12.769203770000047, 10.579890998000053 ],
							[ 12.765425171000061, 10.58177190400005 ],
							[ 12.763747982000041, 10.582190843000035 ],
							[ 12.760800859000028, 10.582926995000037 ],
							[ 12.749888784000063, 10.580642613000066 ],
							[ 12.744580901000063, 10.579531437000071 ],
							[ 12.741103954000039, 10.578395743000044 ],
							[ 12.740583370000024, 10.578191448000041 ],
							[ 12.735306298000069, 10.576120543000059 ],
							[ 12.732538189000024, 10.573402535000071 ],
							[ 12.731818418000046, 10.572695791000058 ],
							[ 12.729851559000053, 10.570124014000044 ],
							[ 12.728324547000057, 10.568127360000062 ],
							[ 12.72690409300003, 10.566038293000076 ],
							[ 12.725994080000021, 10.564699934000032 ],
							[ 12.725027405000048, 10.563435731000027 ],
							[ 12.722500353000044, 10.56013088900005 ],
							[ 12.721327263000035, 10.556701408000038 ],
							[ 12.716671563000034, 10.550991238000051 ],
							[ 12.714678795000054, 10.547737048000045 ],
							[ 12.713173218000065, 10.54527844100005 ],
							[ 12.709679834000042, 10.540708696000024 ],
							[ 12.708885643000031, 10.539929396000048 ],
							[ 12.70502822800006, 10.53614430500005 ],
							[ 12.704051259000039, 10.53470713400003 ],
							[ 12.702698206000036, 10.532716726000046 ],
							[ 12.700019545000032, 10.528126083000075 ],
							[ 12.698031179000054, 10.524718457000063 ],
							[ 12.695695325000031, 10.520147199000064 ],
							[ 12.694521470000041, 10.516717061000065 ],
							[ 12.693506180000043, 10.514729058000057 ],
							[ 12.692186872000036, 10.51214576700005 ],
							[ 12.689855959000056, 10.508717540000021 ],
							[ 12.686341833000029, 10.499573543000054 ],
							[ 12.682852857000057, 10.496148892000065 ],
							[ 12.679362703000038, 10.492037675000063 ],
							[ 12.677118305000022, 10.489230096000028 ],
							[ 12.676632643000062, 10.488622713000041 ],
							[ 12.673680061000027, 10.48493012800003 ],
							[ 12.673155453000049, 10.483491540000045 ],
							[ 12.672492281000075, 10.481672978000063 ],
							[ 12.671334775000048, 10.477228852000053 ],
							[ 12.670010972000057, 10.472150688000056 ],
							[ 12.667664069000068, 10.465290798000069 ],
							[ 12.666143692000048, 10.461580778000041 ],
							[ 12.66532200100005, 10.459575688000029 ],
							[ 12.664240150000069, 10.458710804000077 ],
							[ 12.662249999000039, 10.457119779000038 ],
							[ 12.661274810000066, 10.454756614000075 ],
							[ 12.66046108200004, 10.452784718000032 ],
							[ 12.658714114000077, 10.451472370000033 ],
							[ 12.657156824000026, 10.445864730000039 ],
							[ 12.654913837000038, 10.442566099000032 ],
							[ 12.652495455000064, 10.439009523000038 ],
							[ 12.652494511000043, 10.439009440000063 ],
							[ 12.644362560000047, 10.438298015000044 ],
							[ 12.639759443000059, 10.437894635000021 ],
							[ 12.636307312000042, 10.442479089000074 ],
							[ 12.628249320000066, 10.451651851000065 ],
							[ 12.625938937000058, 10.452800684000067 ],
							[ 12.618983704000073, 10.450528213000041 ],
							[ 12.616862630000071, 10.449875920000068 ],
							[ 12.616183708000051, 10.449667131000069 ],
							[ 12.612779097000043, 10.448620111000025 ],
							[ 12.61285993000007, 10.452260065000075 ],
							[ 12.609999285000072, 10.458140425000067 ],
							[ 12.606820225000035, 10.466580235000038 ],
							[ 12.606018059000064, 10.467631625000024 ],
							[ 12.599936230000026, 10.466334796000069 ],
							[ 12.599330549000058, 10.466205646000049 ],
							[ 12.577679965000073, 10.460662329000058 ],
							[ 12.56681853200007, 10.454192075000037 ],
							[ 12.566777353000077, 10.453979720000063 ],
							[ 12.564589415000057, 10.452709212000059 ],
							[ 12.56262706800004, 10.448918218000074 ],
							[ 12.56208033200005, 10.442256167000039 ],
							[ 12.562062943000058, 10.440486601000032 ],
							[ 12.562019501000066, 10.436065693000046 ],
							[ 12.562191311000049, 10.432600918000048 ],
							[ 12.562421156000028, 10.427965814000061 ],
							[ 12.563303961000031, 10.419861298000058 ],
							[ 12.563849977000075, 10.416615192000052 ],
							[ 12.56207097600003, 10.407180917000062 ],
							[ 12.560848992000047, 10.393453505000025 ],
							[ 12.561985889000027, 10.388873944000068 ],
							[ 12.560812588000033, 10.385443939000027 ],
							[ 12.560564774000056, 10.383585875000051 ],
							[ 12.560563770000044, 10.383578346000036 ],
							[ 12.559630571000071, 10.376581376000047 ],
							[ 12.559620639000059, 10.376506910000046 ],
							[ 12.557990782000047, 10.374835263000023 ],
							[ 12.554961816000059, 10.371728220000023 ],
							[ 12.54915859700003, 10.368308141000057 ],
							[ 12.54451328600004, 10.364886494000075 ],
							[ 12.539439032000075, 10.360733523000022 ],
							[ 12.537542752000036, 10.359181533000026 ],
							[ 12.537003481000056, 10.358981677000031 ],
							[ 12.531629710000061, 10.35699014100004 ],
							[ 12.527154672000052, 10.354917918000069 ],
							[ 12.526562624000064, 10.35464376300007 ],
							[ 12.526562420000062, 10.354643668000051 ],
							[ 12.522234163000064, 10.35079210400005 ],
							[ 12.521148167000035, 10.350651654000046 ],
							[ 12.519930589000069, 10.350494191000053 ],
							[ 12.513174356000036, 10.345507758000053 ],
							[ 12.507370815000058, 10.342087602000049 ],
							[ 12.502110509000033, 10.339875914000061 ],
							[ 12.499253155000076, 10.338674543000025 ],
							[ 12.493460491000064, 10.337542613000039 ],
							[ 12.487362460000043, 10.336944649000031 ],
							[ 12.487362429000029, 10.336944631000051 ],
							[ 12.48334987000004, 10.334630031000074 ],
							[ 12.478819999000052, 10.332539485000041 ],
							[ 12.478335541000035, 10.332622129000072 ],
							[ 12.476078643000051, 10.333007137000038 ],
							[ 12.471858574000066, 10.330116283000052 ],
							[ 12.469434130000025, 10.329022284000075 ],
							[ 12.467943883000032, 10.328349830000036 ],
							[ 12.465973046000045, 10.327634672000045 ],
							[ 12.46557118100003, 10.327488847000041 ],
							[ 12.462167328000021, 10.32515055600004 ],
							[ 12.45956185600005, 10.323360715000035 ],
							[ 12.459561554000061, 10.323360585000046 ],
							[ 12.458785748000025, 10.323025673000075 ],
							[ 12.45287373900004, 10.320473489000051 ],
							[ 12.449395671000048, 10.319337914000073 ],
							[ 12.443598322000071, 10.317062107000027 ],
							[ 12.438082099000042, 10.316296760000057 ],
							[ 12.438076936000073, 10.316296043000023 ],
							[ 12.435490963000063, 10.315937253000072 ],
							[ 12.430861604000029, 10.315947428000072 ],
							[ 12.428773418000048, 10.315951986000073 ],
							[ 12.423917002000053, 10.315962587000058 ],
							[ 12.421606935000057, 10.317112932000043 ],
							[ 12.421606863000022, 10.31711296800006 ],
							[ 12.420076383000037, 10.317284676000043 ],
							[ 12.420067114000062, 10.317285716000072 ],
							[ 12.411195569000029, 10.318281034000051 ],
							[ 12.404258415000072, 10.318640943000048 ],
							[ 12.402350776000048, 10.318739914000048 ],
							[ 12.402350426000055, 10.318739893000043 ],
							[ 12.396072552000021, 10.318355711000038 ],
							[ 12.393915496000034, 10.318223065000041 ],
							[ 12.393369808000045, 10.318189508000046 ],
							[ 12.390479360000029, 10.317482637000069 ],
							[ 12.389260683000032, 10.317184605000023 ],
							[ 12.389057281000021, 10.317184970000028 ],
							[ 12.38905724600005, 10.317184970000028 ],
							[ 12.385728421000067, 10.317190942000025 ],
							[ 12.381336708000049, 10.317204817000061 ],
							[ 12.381099092000056, 10.317205568000077 ],
							[ 12.379522576000056, 10.317403913000021 ],
							[ 12.379519301000073, 10.317404325000041 ],
							[ 12.375273152000034, 10.317938541000046 ],
							[ 12.372755771000072, 10.318256039000062 ],
							[ 12.371844170000031, 10.318371012000057 ],
							[ 12.36142288700006, 10.317250337000075 ],
							[ 12.353086638000036, 10.318368701000054 ],
							[ 12.353063786000064, 10.318371767000031 ],
							[ 12.344072762000053, 10.319577973000037 ],
							[ 12.340514882000036, 10.319586514000036 ],
							[ 12.335970498000052, 10.319597423000062 ],
							[ 12.32902585000005, 10.319613634000063 ],
							[ 12.319760103000021, 10.315630049000049 ],
							[ 12.312801168000021, 10.315073079000058 ],
							[ 12.308254974000022, 10.313587613000038 ],
							[ 12.302369611000074, 10.311664575000066 ],
							[ 12.295408791000057, 10.30824973700004 ],
							[ 12.292989815000055, 10.304928009000037 ],
							[ 12.29236965900003, 10.304076414000065 ],
							[ 12.289584682000054, 10.300252100000023 ],
							[ 12.28918402000005, 10.299728042000027 ],
							[ 12.289169687000026, 10.29970929600006 ],
							[ 12.286092205000045, 10.295684018000031 ],
							[ 12.282261830000039, 10.291298826000059 ],
							[ 12.282249334000028, 10.291284519000044 ],
							[ 12.279109840000046, 10.28769027900006 ],
							[ 12.275795566000056, 10.283972924000068 ],
							[ 12.270965683000043, 10.278555633000053 ],
							[ 12.269797815000061, 10.276270063000027 ],
							[ 12.265131135000047, 10.26827141800004 ],
							[ 12.263949813000067, 10.263086569000052 ],
							[ 12.26393768500003, 10.260422105000032 ],
							[ 12.263936975000036, 10.26026525900005 ],
							[ 12.26274744400007, 10.253402768000058 ],
							[ 12.261911585000064, 10.250960060000068 ],
							[ 12.261574006000046, 10.249973520000026 ],
							[ 12.260873393000054, 10.247253110000031 ],
							[ 12.260873289000074, 10.247252963000051 ],
							[ 12.260829050000041, 10.24719045300003 ],
							[ 12.260838736000039, 10.247118780000051 ],
							[ 12.260891369000035, 10.246729310000035 ],
							[ 12.259388400000034, 10.241971496000076 ],
							[ 12.258781789000068, 10.240051204000054 ],
							[ 12.25871743700003, 10.239688809000029 ],
							[ 12.258350040000039, 10.237619848000065 ],
							[ 12.25523240900003, 10.232577210000045 ],
							[ 12.255230457000039, 10.232574053000064 ],
							[ 12.255228832000057, 10.232571079000024 ],
							[ 12.251663649000022, 10.226043989000061 ],
							[ 12.251479808000056, 10.225707416000034 ],
							[ 12.251373786000045, 10.225476748000062 ],
							[ 12.248971578000067, 10.220250332000035 ],
							[ 12.245907574000057, 10.218056037000053 ],
							[ 12.245052548000046, 10.217443708000076 ],
							[ 12.24444918100005, 10.216910157000029 ],
							[ 12.242780242000038, 10.215434332000029 ],
							[ 12.238040499000022, 10.21282936800003 ],
							[ 12.236855859000059, 10.212178289000065 ],
							[ 12.23660172600006, 10.212038617000076 ],
							[ 12.231460608000077, 10.21005425900006 ],
							[ 12.231430597000042, 10.210049773000037 ],
							[ 12.226330356000062, 10.209287316000029 ],
							[ 12.22406720400005, 10.208292387000029 ],
							[ 12.22224514100003, 10.207265867000046 ],
							[ 12.217682294000042, 10.204695234000042 ],
							[ 12.209235767000052, 10.199695344000077 ],
							[ 12.196025182000028, 10.194966134000026 ],
							[ 12.194845216000033, 10.194543722000049 ],
							[ 12.194520455000031, 10.194227686000033 ],
							[ 12.19339410200007, 10.193131593000032 ],
							[ 12.193191628000022, 10.192934558000047 ],
							[ 12.188131807000048, 10.187027282000031 ],
							[ 12.188015333000067, 10.18689130000007 ],
							[ 12.179346949000035, 10.179863338000075 ],
							[ 12.179221204000044, 10.179839170000037 ],
							[ 12.179145178000056, 10.179824558000064 ],
							[ 12.179032928000026, 10.179680910000059 ],
							[ 12.175987832000033, 10.177117588000044 ],
							[ 12.174425167000038, 10.175802156000032 ],
							[ 12.171585972000059, 10.172088498000051 ],
							[ 12.170931898000049, 10.171232982000049 ],
							[ 12.168394348000049, 10.167914633000066 ],
							[ 12.167834070000026, 10.167181959000061 ],
							[ 12.167688506000047, 10.167071951000025 ],
							[ 12.131253359000027, 10.139536465000049 ],
							[ 12.109047634000035, 10.114513518000024 ],
							[ 12.104807280000045, 10.104602229000022 ],
							[ 12.101286026000025, 10.097771825000052 ],
							[ 12.100176949000058, 10.089549178000027 ],
							[ 12.101148257000034, 10.081652102000021 ],
							[ 12.100699442000064, 10.069650942000067 ],
							[ 12.100637900000038, 10.062448496000059 ],
							[ 12.09677971800005, 10.056649859000061 ],
							[ 12.090157810000051, 10.05156028500005 ],
							[ 12.078005518000055, 10.047545648000039 ],
							[ 12.066222882000034, 10.046271560000037 ],
							[ 12.046500643000059, 10.047806551000065 ],
							[ 12.013650779000045, 10.052877716000069 ],
							[ 12.011201670000048, 10.049810679000075 ],
							[ 12.00975995400006, 10.042962460000069 ],
							[ 12.007273998000073, 10.035436729000025 ],
							[ 12.003766625000026, 10.029977272000053 ],
							[ 12.000968728000032, 10.026570018000029 ],
							[ 12.00005808800006, 10.025534741000058 ],
							[ 11.999241816000051, 10.024606748000053 ],
							[ 11.976074133000054, 10.030544196000051 ],
							[ 11.972961227000042, 10.030912236000063 ],
							[ 11.959099873000071, 10.026975488000062 ],
							[ 11.952674127000023, 10.027567833000035 ],
							[ 11.943424889000028, 10.029877605000024 ],
							[ 11.938810606000061, 10.031222456000023 ],
							[ 11.935350280000023, 10.032230351000067 ],
							[ 11.929002513000057, 10.032297075000031 ],
							[ 11.925273118000064, 10.032337337000058 ],
							[ 11.919665062000036, 10.032396345000052 ],
							[ 11.91573332300004, 10.033517969000059 ],
							[ 11.912197066000033, 10.03452567100004 ],
							[ 11.905263117000061, 10.036829795000074 ],
							[ 11.891390981000029, 10.040293943000052 ],
							[ 11.886766303000059, 10.041448375000073 ],
							[ 11.878675334000036, 10.04375536200007 ],
							[ 11.872929332000069, 10.044715642000028 ],
							[ 11.871735365000063, 10.044915179000043 ],
							[ 11.865954397000053, 10.046073217000071 ],
							[ 11.861324547000038, 10.046083649000025 ],
							[ 11.857408330000055, 10.04487133300006 ],
							[ 11.855256111000074, 10.044204777000061 ],
							[ 11.853217169000061, 10.044957950000025 ],
							[ 11.847430005000035, 10.044971470000064 ],
							[ 11.843390308000039, 10.044590047000042 ],
							[ 11.838539831000048, 10.045049431000052 ],
							[ 11.833379630000024, 10.048069774000055 ],
							[ 11.831229959000041, 10.052479693000066 ],
							[ 11.828603850000036, 10.05387802000007 ],
							[ 11.822365714000057, 10.062296137000033 ],
							[ 11.81701462500007, 10.068552176000026 ],
							[ 11.811083690000032, 10.074794487000077 ],
							[ 11.807842227000037, 10.08486950200006 ],
							[ 11.806795974000067, 10.087003822000042 ],
							[ 11.799268752000046, 10.09358972800004 ],
							[ 11.789989499000058, 10.103168287000074 ],
							[ 11.774412013000074, 10.119248232000075 ],
							[ 11.759979389000023, 10.133681360000026 ],
							[ 11.757574097000031, 10.140897733000031 ],
							[ 11.748753799000042, 10.155330875000061 ],
							[ 11.739933779000069, 10.16415037400003 ],
							[ 11.723095073000025, 10.166556471000035 ],
							[ 11.716165592000038, 10.166779907000034 ],
							[ 11.710227427000063, 10.166971378000028 ],
							[ 11.700497256000062, 10.170737522000024 ],
							[ 11.691866661000063, 10.176223967000055 ],
							[ 11.685305940000035, 10.181938731000059 ],
							[ 11.678263102000074, 10.188073313000075 ],
							[ 11.666920135000055, 10.194145827000057 ],
							[ 11.663715255000056, 10.195861576000027 ],
							[ 11.656876340000053, 10.196814881000023 ],
							[ 11.601275615000077, 10.208057048000057 ],
							[ 11.589072362000024, 10.224864672000024 ],
							[ 11.582856, 10.249313308000069 ],
							[ 11.57024838500007, 10.273806821000051 ],
							[ 11.565323023000076, 10.287399132000075 ],
							[ 11.554185258000075, 10.293405861000053 ],
							[ 11.552588836000041, 10.294266830000026 ],
							[ 11.549734585000067, 10.296079024000051 ],
							[ 11.54395304600007, 10.299749783000038 ],
							[ 11.537587461000044, 10.303408754000031 ],
							[ 11.533394785000041, 10.305102735000048 ],
							[ 11.533864883000035, 10.311686444000031 ],
							[ 11.536784086000068, 10.316064961000052 ],
							[ 11.543486719000043, 10.326118252000072 ],
							[ 11.54573775800003, 10.327393955000048 ],
							[ 11.546287347000032, 10.327705417000061 ],
							[ 11.567541270000049, 10.339750385000059 ],
							[ 11.59561984000004, 10.353462821000051 ],
							[ 11.602020361000029, 10.356588576000036 ],
							[ 11.624470522000024, 10.381444774000045 ],
							[ 11.635480148000056, 10.394550546000062 ],
							[ 11.634939361000022, 10.399012731000028 ],
							[ 11.634692942000072, 10.402234108000073 ],
							[ 11.634142090000068, 10.40943528400004 ],
							[ 11.635131967000063, 10.417578673000037 ],
							[ 11.636097765000045, 10.426881712000068 ],
							[ 11.635275793000062, 10.438463388000059 ],
							[ 11.635102828000072, 10.446753844000057 ],
							[ 11.635082261000036, 10.447739636000051 ],
							[ 11.636035794000065, 10.457622512000057 ],
							[ 11.635926943000072, 10.462838087000023 ],
							[ 11.635866398000076, 10.465739079000059 ],
							[ 11.636904697000034, 10.471563096000068 ],
							[ 11.638523332000034, 10.477401600000064 ],
							[ 11.640576590000023, 10.490210382000043 ],
							[ 11.640370270000062, 10.50006701600006 ],
							[ 11.643630913000038, 10.510583195000038 ],
							[ 11.644501480000031, 10.512694150000073 ],
							[ 11.647495081000045, 10.519953045000022 ],
							[ 11.650200942000026, 10.52929666700004 ],
							[ 11.648750031000077, 10.543184253000049 ],
							[ 11.642770520000056, 10.551745197000059 ],
							[ 11.639074352000023, 10.562100203000057 ],
							[ 11.638313314000072, 10.57078371800003 ],
							[ 11.638772052000036, 10.576595436000048 ],
							[ 11.639218471000049, 10.582985836000034 ],
							[ 11.638753958000052, 10.587132700000041 ],
							[ 11.638506189000054, 10.589350033000073 ],
							[ 11.640003111000055, 10.600985692000052 ],
							[ 11.640150340000048, 10.609040031000063 ],
							[ 11.645789970000067, 10.610750331000077 ],
							[ 11.649519957000052, 10.610679600000026 ],
							[ 11.652540311000052, 10.612079650000055 ],
							[ 11.655559477000054, 10.613470075000066 ],
							[ 11.658594500000049, 10.614391869000031 ],
							[ 11.661190113000032, 10.615180206000048 ],
							[ 11.666439798000056, 10.615810327000077 ],
							[ 11.669449984000039, 10.61721033200007 ],
							[ 11.67469963800005, 10.617830172000026 ],
							[ 11.678819876000034, 10.618840086000034 ],
							[ 11.685569913000052, 10.62016938000005 ],
							[ 11.688589857000068, 10.621560104000025 ],
							[ 11.697649998000031, 10.625759810000034 ],
							[ 11.703679863000048, 10.628549591000024 ],
							[ 11.706385211000054, 10.630182331000071 ],
							[ 11.710109906000071, 10.632430271000032 ],
							[ 11.713529548000054, 10.634909551000021 ],
							[ 11.716940108000074, 10.637389996000024 ],
							[ 11.720749689000058, 10.64095973600007 ],
							[ 11.723050325000031, 10.643830235000053 ],
							[ 11.727259526000068, 10.648480171000074 ],
							[ 11.73146999100004, 10.653129476000061 ],
							[ 11.740626419000023, 10.660897422000062 ],
							[ 11.747830301000022, 10.66701048300007 ],
							[ 11.747930905000032, 10.667040973000042 ],
							[ 11.747950015000072, 10.667067382000027 ],
							[ 11.748805800000071, 10.668150677000028 ],
							[ 11.750169479000022, 10.669146381000076 ],
							[ 11.753920903000051, 10.672052182000073 ],
							[ 11.754313895000053, 10.672289372000023 ],
							[ 11.756390232000058, 10.673542544000043 ],
							[ 11.758772274000023, 10.674698277000061 ],
							[ 11.761410495000064, 10.676103545000046 ],
							[ 11.763964, 10.677509427000075 ],
							[ 11.767201337000074, 10.679664548000062 ],
							[ 11.768277742000066, 10.680221972000027 ],
							[ 11.768280009000023, 10.680339807000053 ],
							[ 11.768469910000022, 10.689189911000028 ],
							[ 11.766010573000074, 10.696149952000042 ],
							[ 11.759499774000062, 10.70575070600006 ],
							[ 11.757349869000052, 10.71015998200005 ],
							[ 11.756319943000051, 10.714189676000046 ],
							[ 11.753780514000027, 10.717510148000031 ],
							[ 11.750519671000063, 10.722310186000072 ],
							[ 11.746470219000059, 10.724940592000053 ],
							[ 11.741119713000046, 10.726801049000073 ],
							[ 11.739800443000036, 10.727259780000054 ],
							[ 11.734319791000075, 10.732829847000062 ],
							[ 11.728519344000063, 10.737250390000042 ],
							[ 11.728440449000061, 10.737310517000026 ],
							[ 11.726070492000076, 10.743459883000071 ],
							[ 11.721130368000047, 10.744739313000025 ],
							[ 11.722399443000029, 10.751629803000071 ],
							[ 11.722880209000039, 10.756360244000064 ],
							[ 11.725410450000027, 10.759718947000067 ],
							[ 11.730179527000075, 10.76605022900003 ],
							[ 11.732559921000075, 10.772560122000073 ],
							[ 11.73525999900005, 10.776510238000071 ],
							[ 11.737250527000072, 10.781930172000045 ],
							[ 11.738120335000076, 10.787739776000024 ],
							[ 11.739710119000051, 10.792080035000026 ],
							[ 11.738360665000073, 10.798660375000054 ],
							[ 11.736209975000065, 10.80307007600004 ],
							[ 11.735939196000061, 10.803245899000046 ],
							[ 11.734213222000051, 10.810748032000049 ],
							[ 11.730884321000076, 10.816487152000036 ],
							[ 11.725146677000055, 10.822243978000074 ],
							[ 11.724125086000072, 10.824998996000033 ],
							[ 11.722313586000041, 10.829884236000055 ],
							[ 11.719937070000071, 10.834187619000033 ],
							[ 11.718534748000025, 10.839912471000048 ],
							[ 11.709481209000046, 10.853312753000068 ],
							[ 11.700391486000058, 10.861950943000068 ],
							[ 11.691273031000037, 10.866779404000056 ],
							[ 11.685973348000061, 10.866817849000029 ],
							[ 11.681180140000038, 10.870186043000047 ],
							[ 11.681222456000057, 10.875900300000069 ],
							[ 11.683185091000041, 10.881314908000036 ],
							[ 11.684665679000034, 10.885399621000033 ],
							[ 11.685766299000022, 10.886923700000068 ],
							[ 11.688389498000049, 10.888139565000074 ],
							[ 11.694029717000035, 10.889849683000023 ],
							[ 11.699669970000059, 10.891559717000064 ],
							[ 11.705699710000033, 10.894350006000025 ],
							[ 11.706322551000028, 10.89452338500007 ],
							[ 11.715470476000064, 10.897069874000067 ],
							[ 11.717629096000053, 10.897904066000024 ],
							[ 11.727140466000037, 10.901579707000053 ],
							[ 11.733598717000064, 10.904567027000041 ],
							[ 11.736200582000038, 10.905770542000027 ],
							[ 11.741829874000075, 10.907480017000069 ],
							[ 11.744850203000055, 10.90888043800004 ],
							[ 11.748979668000061, 10.909890085000029 ],
							[ 11.756130158000076, 10.912300009000035 ],
							[ 11.758539619000032, 10.912889371000063 ],
							[ 11.761045306000028, 10.915676683000072 ],
							[ 11.761999107000065, 10.916737687000023 ],
							[ 11.762973077000026, 10.917400675000067 ],
							[ 11.76684313100003, 10.920035047000056 ],
							[ 11.767497396000067, 10.920214502000022 ],
							[ 11.768068222000068, 10.920371071000034 ],
							[ 11.770223428000065, 10.920962211000074 ],
							[ 11.780486905000032, 10.920885369000075 ],
							[ 11.780590089000043, 10.920920144000036 ],
							[ 11.789239882000061, 10.924030135000066 ],
							[ 11.79901036900003, 10.926750066000068 ],
							[ 11.803540242000054, 10.928839842000059 ],
							[ 11.809570331000032, 10.931639756000038 ],
							[ 11.816320346000055, 10.932959319000076 ],
							[ 11.822359768000069, 10.935759510000025 ],
							[ 11.829109920000064, 10.937080543000036 ],
							[ 11.834750417000066, 10.938790228000073 ],
							[ 11.834951265000029, 10.938829783000074 ],
							[ 11.841500175000021, 10.940119520000053 ],
							[ 11.848250455000027, 10.941439649000074 ],
							[ 11.85460002800005, 10.941680112000029 ],
							[ 11.860240160000046, 10.94338996700003 ],
							[ 11.865879763000066, 10.945099742000025 ],
							[ 11.870799746000046, 10.948280161000071 ],
							[ 11.876032283000029, 10.950161523000077 ],
							[ 11.879449842000042, 10.951390308000043 ],
							[ 11.885089666000056, 10.953100363000033 ],
							[ 11.890330286000051, 10.953720077000071 ],
							[ 11.895970216000023, 10.955429948000074 ],
							[ 11.900099509000029, 10.956440175000068 ],
							[ 11.902395802000058, 10.956716320000055 ],
							[ 11.905340289000037, 10.957070415000032 ],
							[ 11.910980324000036, 10.958780076000039 ],
							[ 11.915109715000028, 10.959790136000038 ],
							[ 11.920749842000077, 10.961500202000025 ],
							[ 11.926389462000031, 10.963210188000062 ],
							[ 11.930520071000046, 10.964220067000042 ],
							[ 11.933529621000048, 10.965620170000022 ],
							[ 11.936550043000068, 10.967020163000029 ],
							[ 11.948699818000023, 10.976240171000029 ],
							[ 11.955929590000039, 10.982290163000073 ],
							[ 11.964659673000028, 10.989040527000043 ],
							[ 11.968469681000045, 10.992609923000032 ],
							[ 11.975299974000052, 10.997569942000041 ],
							[ 11.979909858000042, 11.003310480000039 ],
							[ 11.986419631000047, 11.010829686000022 ],
							[ 11.991020046000074, 11.016559422000057 ],
							[ 12.00166008900004, 11.025090336000062 ],
							[ 12.004759551000063, 11.030130280000037 ],
							[ 12.007828950000032, 11.033951060000049 ],
							[ 12.009369746000061, 11.03586931500007 ],
							[ 12.012860470000021, 11.041990324000039 ],
							[ 12.015949943000066, 11.047030234000033 ],
							[ 12.019450041000027, 11.053149973000075 ],
							[ 12.022150208000028, 11.057100226000045 ],
							[ 12.028980257000057, 11.062069973000064 ],
							[ 12.035730108000052, 11.063389519000054 ],
							[ 12.040969824000058, 11.064020120000066 ],
							[ 12.047320469000056, 11.064249940000025 ],
							[ 12.054389818000061, 11.063019893000046 ],
							[ 12.057720034000056, 11.061869700000045 ],
							[ 12.06439970200006, 11.059550458000047 ],
							[ 12.06995975500007, 11.057620040000074 ],
							[ 12.076230254000052, 11.054220213000065 ],
							[ 12.082899930000053, 11.051900125000031 ],
							[ 12.088859727000056, 11.051059482000028 ],
							[ 12.094099856000071, 11.051679718000059 ],
							[ 12.098940079000045, 11.051220040000032 ],
							[ 12.106809553000062, 11.052160537000077 ],
							[ 12.112440032000052, 11.053870037000024 ],
							[ 12.116570509000042, 11.054880110000056 ],
							[ 12.124084433000064, 11.055700046000027 ],
							[ 12.128560333000053, 11.056830258000048 ],
							[ 12.139129784000033, 11.061720078000064 ],
							[ 12.143729937000046, 11.067459814000074 ],
							[ 12.146030361000044, 11.070329935000075 ],
							[ 12.146573040000021, 11.075771202000055 ],
							[ 12.148587063000036, 11.077669036000032 ],
							[ 12.149680083000021, 11.081069792000051 ],
							[ 12.145320103000074, 11.088910010000063 ],
							[ 12.141659837000077, 11.092630206000024 ],
							[ 12.139120117000061, 11.095949857000051 ],
							[ 12.137049846000025, 11.104000003000067 ],
							[ 12.136019574000045, 11.108030102000043 ],
							[ 12.135070023000026, 11.115699521000067 ],
							[ 12.135150196000041, 11.119339960000048 ],
							[ 12.134110619000069, 11.123359407000066 ],
							[ 12.133479800000032, 11.128470444000072 ],
							[ 12.130834407000066, 11.13390841000006 ],
							[ 12.12989970700005, 11.135829814000033 ],
							[ 12.127360230000022, 11.139160091000065 ],
							[ 12.122495572000048, 11.138975988000027 ],
							[ 12.121009842000035, 11.138919761000068 ],
							[ 12.120513888000062, 11.140180264000037 ],
							[ 12.11711037300006, 11.148830583000063 ],
							[ 12.114650007000023, 11.155790488000036 ],
							[ 12.115159012000049, 11.163403763000076 ],
							[ 12.115209550000031, 11.164159673000029 ],
							[ 12.115013394000073, 11.165726482000025 ],
							[ 12.114569815000038, 11.169269587000031 ],
							[ 12.114412731000073, 11.170523280000054 ],
							[ 12.113929485000028, 11.17438009600005 ],
							[ 12.114410126000053, 11.179099781000048 ],
							[ 12.114244983000049, 11.183362921000025 ],
							[ 12.114169958000048, 11.185299692000058 ],
							[ 12.113680608000038, 11.187684281000031 ],
							[ 12.112819558000069, 11.191880147000063 ],
							[ 12.112798387000055, 11.191963023000028 ],
							[ 12.111789916000077, 11.19591071900004 ],
							[ 12.110750422000024, 11.199930076000044 ],
							[ 12.109720171000049, 11.203960645000052 ],
							[ 12.108690463000073, 11.207989520000069 ],
							[ 12.107419920000041, 11.211975174000031 ],
							[ 12.106940227000052, 11.213479955000025 ],
							[ 12.106698976000075, 11.215407244000062 ],
							[ 12.105980060000036, 11.221150458000068 ],
							[ 12.103934696000067, 11.226596076000021 ],
							[ 12.102810051000063, 11.229590356000074 ],
							[ 12.102453879000052, 11.232431070000075 ],
							[ 12.101849662000063, 11.237250102000075 ],
							[ 12.102116358000046, 11.241232416000059 ],
							[ 12.102410182000028, 11.245619805000047 ],
							[ 12.104789592000031, 11.252129465000053 ],
							[ 12.107489313000031, 11.256079625000041 ],
							[ 12.109385975000066, 11.257459186000062 ],
							[ 12.110899895000045, 11.258560355000043 ],
							[ 12.112706793000029, 11.259874887000024 ],
							[ 12.117730383000037, 11.26352958700005 ],
							[ 12.121858881000037, 11.264539382000066 ],
							[ 12.121859837000045, 11.264539616000036 ],
							[ 12.121860522000077, 11.264540256000032 ],
							[ 12.125670240000034, 11.268100014000026 ],
							[ 12.130412798000066, 11.269265605000044 ],
							[ 12.133929953000063, 11.270130026000061 ],
							[ 12.136293232000071, 11.272338397000055 ],
							[ 12.13773997100003, 11.273690305000059 ],
							[ 12.143779606000066, 11.276490355000021 ],
							[ 12.148300413000072, 11.278579942000022 ],
							[ 12.150937007000039, 11.280281149000075 ],
							[ 12.153229736000071, 11.281760484000074 ],
							[ 12.159497980000026, 11.286197492000042 ],
							[ 12.162030148000042, 11.288559810000038 ],
							[ 12.167199549000031, 11.289139431000024 ],
							[ 12.167200240000057, 11.289139509000051 ],
							[ 12.167222168000023, 11.289153658000032 ],
							[ 12.172129879000067, 11.292320372000063 ],
							[ 12.174927140000023, 11.294007906000047 ],
							[ 12.178560207000032, 11.296199665000074 ],
							[ 12.182114518000049, 11.299046367000074 ],
							[ 12.18211460200007, 11.299046515000043 ],
							[ 12.18315229500007, 11.300872697000045 ],
							[ 12.186899950000054, 11.301859745000058 ],
							[ 12.186900368000067, 11.301859855000032 ],
							[ 12.189200343000039, 11.304730238000047 ],
							[ 12.191499768000028, 11.307600057000059 ],
							[ 12.194199730000037, 11.311550153000042 ],
							[ 12.194389765000039, 11.312069827000073 ],
							[ 12.195301202000053, 11.312446167000076 ],
							[ 12.205308049000053, 11.348999589000073 ],
							[ 12.208791390000044, 11.357381112000041 ],
							[ 12.211904931000049, 11.364872833000049 ],
							[ 12.22497252900007, 11.382282828000029 ],
							[ 12.244626353000058, 11.41397205100003 ],
							[ 12.268996780000066, 11.431281486000046 ],
							[ 12.304669543000045, 11.448488903000055 ],
							[ 12.316013380000072, 11.453166820000035 ],
							[ 12.32097335900005, 11.465866647000041 ],
							[ 12.325977678000072, 11.483345082000028 ],
							[ 12.330255743000066, 11.492758984000034 ],
							[ 12.330318818000023, 11.492897798000058 ],
							[ 12.33322951100007, 11.489439910000044 ],
							[ 12.338264995000031, 11.482850141000029 ],
							[ 12.34652081400003, 11.498998654000047 ],
							[ 12.350685498000075, 11.505044574000067 ],
							[ 12.373544407000054, 11.549756497000033 ],
							[ 12.362556301000041, 11.563680456000043 ],
							[ 12.362555857000075, 11.563681067000061 ],
							[ 12.348556922000057, 11.582952419000037 ],
							[ 12.348465619000024, 11.583078106000073 ],
							[ 12.329906409000046, 11.593625203000045 ],
							[ 12.323346448000052, 11.598188643000071 ],
							[ 12.313554043000067, 11.605638998000074 ],
							[ 12.299958763000063, 11.608619084000054 ],
							[ 12.30075484300005, 11.616162576000022 ],
							[ 12.301113649000058, 11.644909419000044 ],
							[ 12.291369860000032, 11.663127082000074 ],
							[ 12.282671917000073, 11.680417843000043 ],
							[ 12.274844400000063, 11.694021494000026 ],
							[ 12.241261707000035, 11.652309516000059 ],
							[ 12.223719519000042, 11.632855024000037 ],
							[ 12.192381452000063, 11.645032348000029 ],
							[ 12.178245921000041, 11.658351503000063 ],
							[ 12.178979675000051, 11.660350050000034 ],
							[ 12.180959478000034, 11.665779889000021 ],
							[ 12.185211010000046, 11.674910956000076 ],
							[ 12.186840054000072, 11.678409675000069 ],
							[ 12.188420256000029, 11.682749594000029 ],
							[ 12.191280119000055, 11.693980221000061 ],
							[ 12.193659512000067, 11.700490262000073 ],
							[ 12.196040676000052, 11.706999714000062 ],
							[ 12.198980209000069, 11.721870555000066 ],
							[ 12.202869837000037, 11.729080014000033 ],
							[ 12.207579204000069, 11.73372000300003 ],
							[ 12.209859939000069, 11.741319560000022 ],
							[ 12.209923915000047, 11.741433186000052 ],
							[ 12.220317468000076, 11.749259787000028 ],
							[ 12.222346324000057, 11.750787566000042 ],
							[ 12.233292173000052, 11.752232308000032 ],
							[ 12.236484634000021, 11.759903654000027 ],
							[ 12.236518280000041, 11.760000308000031 ],
							[ 12.241294287000073, 11.773720167000022 ],
							[ 12.239672773000052, 11.783271459000048 ],
							[ 12.240660035000076, 11.784410487000059 ],
							[ 12.243359589000022, 11.788370301000043 ],
							[ 12.248440144000028, 11.798830131000045 ],
							[ 12.252169069000047, 11.815870024000048 ],
							[ 12.252409571000044, 11.826789663000056 ],
							[ 12.251460611000027, 11.834460132000061 ],
							[ 12.250500058000057, 11.842119957000023 ],
							[ 12.249150318000034, 11.84871021500004 ],
							[ 12.248199989000057, 11.856369937000068 ],
							[ 12.247640091000051, 11.865120105000074 ],
							[ 12.246290122000062, 11.871700178000026 ],
							[ 12.24645074700004, 11.878979539000056 ],
							[ 12.245889716000022, 11.887739864000025 ],
							[ 12.245650689000058, 11.893930528000055 ],
							[ 12.246199501000035, 11.902299776000064 ],
							[ 12.245960469000067, 11.908490434000043 ],
							[ 12.246519644000045, 11.916859583000075 ],
							[ 12.24658990000006, 11.924671384000021 ],
							[ 12.244690280000043, 11.935829966000028 ],
							[ 12.245560171000022, 11.941639718000033 ],
							[ 12.24786038600007, 11.961620415000027 ],
							[ 12.248810171000059, 11.97107036500006 ],
							[ 12.248180648000073, 11.976180159000023 ],
							[ 12.248409675000062, 11.987099777000026 ],
							[ 12.248760097000059, 11.990586028000052 ],
							[ 12.249359540000057, 11.996549709000021 ],
							[ 12.249920086000031, 12.004919919000031 ],
							[ 12.250635071000033, 12.009166484000048 ],
							[ 12.251205789000039, 12.012550420000025 ],
							[ 12.252060131000064, 12.017620105000049 ],
							[ 12.253729910000061, 12.025600333000057 ],
							[ 12.255372213000044, 12.034514107000064 ],
							[ 12.257540166000069, 12.046280122000042 ],
							[ 12.262780078000048, 12.064020004000042 ],
							[ 12.264839881000057, 12.073089457000037 ],
							[ 12.268410108000069, 12.082850196000038 ],
							[ 12.269680597000047, 12.08974 ],
							[ 12.271739598000067, 12.098809983000024 ],
							[ 12.274200873000041, 12.108960091000029 ],
							[ 12.283889251000062, 12.142270331000077 ],
							[ 12.288809789000027, 12.16256975500005 ],
							[ 12.291589452000039, 12.17016026400006 ],
							[ 12.296509570000069, 12.190449965000028 ],
							[ 12.299359712000069, 12.201689672000043 ],
							[ 12.300630067000043, 12.208579877000034 ],
							[ 12.303090629000053, 12.218729733000032 ],
							[ 12.303169866000076, 12.222369901000036 ],
							[ 12.304050571000062, 12.228179870000076 ],
							[ 12.304099157000053, 12.230548088000035 ],
							[ 12.299778251000077, 12.240398201000062 ],
							[ 12.297891554000046, 12.261956697000073 ],
							[ 12.305930637000074, 12.287249093000071 ],
							[ 12.305780055000071, 12.291149921000056 ],
							[ 12.304269391000048, 12.307560104000061 ],
							[ 12.304030024000042, 12.313759664000031 ],
							[ 12.301569417000053, 12.320730388000072 ],
							[ 12.298390787000073, 12.329159489000062 ],
							[ 12.290840582000044, 12.342789589000063 ],
							[ 12.286887339000032, 12.350925242000073 ],
							[ 12.285840342000029, 12.353079931000025 ],
							[ 12.281549852000069, 12.36190964900004 ],
							[ 12.277580346000036, 12.368180465000023 ],
							[ 12.276667409000027, 12.377513144000034 ],
							[ 12.274535483000022, 12.379438186000073 ],
							[ 12.272461815000042, 12.386264585000049 ],
							[ 12.270359424000048, 12.388415336000037 ],
							[ 12.269160679000038, 12.393100048000065 ],
							[ 12.267810378000036, 12.399689546000047 ],
							[ 12.264630260000047, 12.40811999400006 ],
							[ 12.262880686000074, 12.413620275000028 ],
							[ 12.260620118000077, 12.420270236000022 ],
							[ 12.261099281000043, 12.422970101000033 ],
							[ 12.261244632000057, 12.423103891000039 ],
							[ 12.265380361000041, 12.426910668000062 ],
							[ 12.265500535000058, 12.431050422000055 ],
							[ 12.26937422900005, 12.43399335600003 ],
							[ 12.272006059000034, 12.435992345000045 ],
							[ 12.275952707000044, 12.438990498000067 ],
							[ 12.278630265000061, 12.440959657000064 ],
							[ 12.280616441000063, 12.441782863000071 ],
							[ 12.283153609000067, 12.44283284200003 ],
							[ 12.287010767000027, 12.447710260000065 ],
							[ 12.291099508000059, 12.450519435000047 ],
							[ 12.297720468000023, 12.454069999000069 ],
							[ 12.300439947000029, 12.455949912000051 ],
							[ 12.304140664000045, 12.456500173000052 ],
							[ 12.308029868000062, 12.45818997300006 ],
							[ 12.311920150000049, 12.459870134000028 ],
							[ 12.315559148000034, 12.459072190000029 ],
							[ 12.318400764000046, 12.460733369000025 ],
							[ 12.322039149000034, 12.462860310000053 ],
							[ 12.325610545000075, 12.465190075000066 ],
							[ 12.331842394000034, 12.468075705000047 ],
							[ 12.334659051000074, 12.469380467000065 ],
							[ 12.337643034000052, 12.471017063000033 ],
							[ 12.340530052000076, 12.472599707000029 ],
							[ 12.34320305500006, 12.47661086100004 ],
							[ 12.345399752000048, 12.479909873000054 ],
							[ 12.350848105000068, 12.480628138000043 ],
							[ 12.352919468000039, 12.480815158000041 ],
							[ 12.356523092000032, 12.482988397000042 ],
							[ 12.360122163000028, 12.485161086000062 ],
							[ 12.363799802000074, 12.487379926000074 ],
							[ 12.368009005000033, 12.49203016000007 ],
							[ 12.370510010000032, 12.493200228000035 ],
							[ 12.376749588000052, 12.498780020000027 ],
							[ 12.385880821000057, 12.50660977800004 ],
							[ 12.385995445000049, 12.506662942000048 ],
							[ 12.388899880000054, 12.508010042000024 ],
							[ 12.395082994000063, 12.515708749000055 ],
							[ 12.398110742000028, 12.519479556000022 ],
							[ 12.398770302000059, 12.524499666000054 ],
							[ 12.400530288000027, 12.527689902000077 ],
							[ 12.402480077000064, 12.531999352000071 ],
							[ 12.404440332000036, 12.536319970000022 ],
							[ 12.406200421000051, 12.539509622000026 ],
							[ 12.409320249000075, 12.543629767000027 ],
							[ 12.41223997700007, 12.546629670000073 ],
							[ 12.415560172000028, 12.551879601000053 ],
							[ 12.419352966000076, 12.554381591000038 ],
							[ 12.420829534000063, 12.556967549000035 ],
							[ 12.422634381000023, 12.560127328000021 ],
							[ 12.42443578700005, 12.563282038000068 ],
							[ 12.427169480000032, 12.568070566000074 ],
							[ 12.428620310000042, 12.571440923000068 ],
							[ 12.429726929000026, 12.574008774000049 ],
							[ 12.431511495000052, 12.578156277000062 ],
							[ 12.433873793000032, 12.581275334000054 ],
							[ 12.433897945000069, 12.581307213000059 ],
							[ 12.433867659000043, 12.581324970000026 ],
							[ 12.433841425000026, 12.581362997000042 ],
							[ 12.433809833000055, 12.581413497000028 ],
							[ 12.431518914000037, 12.586362436000059 ],
							[ 12.429886307000061, 12.596586537000064 ],
							[ 12.429918701000076, 12.599989270000037 ],
							[ 12.430274386000065, 12.601006899000026 ],
							[ 12.431328510000071, 12.602698705000023 ],
							[ 12.434464990000038, 12.605051904000049 ],
							[ 12.437950751000074, 12.607742165000047 ],
							[ 12.443159958000024, 12.609735857000032 ],
							[ 12.443526678000069, 12.609368345000064 ],
							[ 12.443605731000048, 12.609584092000034 ],
							[ 12.456987045000062, 12.611745362000022 ],
							[ 12.462302316000034, 12.613118107000048 ],
							[ 12.466435284000056, 12.614185506000069 ],
							[ 12.469037915000058, 12.61753187000005 ],
							[ 12.479040878000035, 12.633027629000026 ],
							[ 12.481911546000049, 12.640411999000037 ],
							[ 12.490464609000071, 12.662413492000042 ],
							[ 12.494128991000025, 12.686815926000065 ],
							[ 12.489991435000036, 12.702022360000058 ],
							[ 12.477544796000075, 12.717757619000054 ],
							[ 12.470517394000069, 12.726640972000041 ],
							[ 12.45192195900006, 12.753778284000077 ],
							[ 12.447862541000063, 12.777409828000032 ],
							[ 12.446236996000039, 12.786693907000029 ],
							[ 12.406485595000049, 12.843516100000045 ],
							[ 12.393817107000075, 12.863854893000052 ],
							[ 12.391755681000063, 12.865593165000064 ],
							[ 12.378149475000043, 12.87706643100006 ],
							[ 12.377654477000021, 12.877483832000053 ],
							[ 12.375256203000049, 12.896043460000044 ],
							[ 12.378820247000021, 12.910335902000043 ],
							[ 12.388442347000023, 12.930471685000043 ],
							[ 12.400011140000061, 12.968621297000027 ],
							[ 12.405260545000033, 12.985931896000068 ],
							[ 12.405407808000064, 13.00149489000006 ],
							[ 12.405579463000038, 13.019634001000043 ],
							[ 12.400743839000029, 13.052540409000073 ],
							[ 12.395780095000077, 13.071965931000022 ],
							[ 12.394037180000055, 13.076184138000031 ],
							[ 12.394129994000025, 13.077099768000039 ],
							[ 12.400262292000036, 13.077644484000075 ],
							[ 12.401638057000071, 13.074343831000022 ],
							[ 12.407138455000052, 13.078744982000046 ],
							[ 12.412364766000053, 13.077644121000048 ],
							[ 12.419517067000072, 13.075718747000053 ],
							[ 12.428318036000064, 13.072968482000022 ],
							[ 12.429491229000064, 13.07265546800005 ],
							[ 12.432443257000045, 13.071867850000046 ],
							[ 12.434536886000046, 13.071867915000041 ],
							[ 12.438494405000029, 13.071868038000048 ],
							[ 12.447846895000055, 13.071593551000035 ],
							[ 12.453898037000044, 13.071593507000046 ],
							[ 12.458298475000049, 13.070768546000068 ],
							[ 12.46136666600006, 13.069014967000044 ],
							[ 12.465999440000076, 13.066367172000071 ],
							[ 12.47095110600003, 13.063066346000028 ],
							[ 12.473701669000036, 13.06306672900007 ],
							[ 12.47727704700003, 13.063066734000074 ],
							[ 12.480027108000058, 13.064441633000058 ],
							[ 12.48085251200007, 13.066642954000031 ],
							[ 12.482503122000026, 13.069117352000035 ],
							[ 12.482227477000038, 13.072968480000043 ],
							[ 12.480852094000056, 13.075994278000053 ],
							[ 12.479477334000023, 13.080394622000028 ],
							[ 12.480027750000033, 13.083420530000069 ],
							[ 12.483603275000064, 13.084245905000046 ],
							[ 12.488829080000073, 13.082870524000043 ],
							[ 12.494605473000036, 13.082870671000023 ],
							[ 12.497629553000024, 13.084245704000068 ],
							[ 12.49542978900007, 13.08672166100007 ],
							[ 12.492404779000026, 13.088096878000044 ],
							[ 12.491029868000055, 13.089746466000065 ],
							[ 12.493229856000028, 13.091672172000074 ],
							[ 12.497080415000028, 13.093321938000031 ],
							[ 12.495705903000044, 13.095247230000041 ],
							[ 12.493504948000066, 13.096898346000046 ],
							[ 12.492129150000039, 13.100473329000067 ],
							[ 12.492714965000062, 13.101709890000052 ],
							[ 12.494605171000046, 13.105699805000029 ],
							[ 12.496254863000047, 13.109000112000047 ],
							[ 12.495672513000045, 13.111497719000056 ],
							[ 12.495292850000055, 13.113126032000025 ],
							[ 12.491442129000063, 13.117527014000075 ],
							[ 12.490891862000069, 13.122477289000074 ],
							[ 12.493917313000054, 13.12632856700003 ],
							[ 12.496667812000055, 13.130178453000042 ],
							[ 12.500242969000055, 13.134579889000065 ],
							[ 12.504369748000045, 13.138430353000047 ],
							[ 12.508495087000028, 13.141180795000025 ],
							[ 12.509738079000044, 13.142035261000046 ],
							[ 12.512895868000044, 13.144206011000051 ],
							[ 12.517021379000028, 13.148607173000073 ],
							[ 12.517783634000068, 13.148861325000041 ],
							[ 12.519496451000066, 13.149432416000025 ],
							[ 12.525823051000032, 13.146407176000025 ],
							[ 12.531874314000049, 13.14640698900007 ],
							[ 12.536824801000023, 13.146681559000058 ],
							[ 12.538200943000049, 13.141181002000053 ],
							[ 12.540125825000075, 13.139530009000055 ],
							[ 12.54232554400005, 13.140905154000052 ],
							[ 12.543976604000022, 13.143931225000074 ],
							[ 12.547001400000056, 13.149157779000063 ],
							[ 12.549477553000031, 13.15218277300005 ],
							[ 12.552502098000048, 13.154108187000077 ],
							[ 12.556078916000047, 13.155208548000076 ],
							[ 12.558553927000048, 13.157408641000075 ],
							[ 12.556079015000023, 13.159059343000024 ],
							[ 12.553327983000031, 13.164560044000041 ],
							[ 12.553328029000056, 13.168410279000057 ],
							[ 12.553328801000077, 13.175011846000075 ],
							[ 12.555802775000075, 13.180237919000035 ],
							[ 12.557729265000035, 13.184088982000048 ],
							[ 12.56020490800006, 13.18656419000007 ],
							[ 12.56433043800007, 13.191789675000052 ],
							[ 12.561853897000049, 13.197015499000031 ],
							[ 12.558553695000057, 13.196465656000044 ],
							[ 12.554427970000063, 13.192615787000022 ],
							[ 12.551126870000076, 13.190690192000034 ],
							[ 12.547002191000047, 13.192064989000073 ],
							[ 12.542326251000077, 13.193440475000045 ],
							[ 12.54067666800006, 13.197015952000072 ],
							[ 12.538750815000071, 13.19976637700006 ],
							[ 12.537389532000077, 13.202487236000024 ],
							[ 12.537099611000031, 13.203066715000034 ],
							[ 12.537925746000042, 13.206092717000047 ],
							[ 12.536000330000036, 13.210768509000047 ],
							[ 12.536550958000078, 13.212694325000029 ],
							[ 12.540590679000047, 13.21221861500004 ],
							[ 12.541226090000066, 13.212143790000027 ],
							[ 12.543426585000077, 13.208293022000078 ],
							[ 12.547551708000071, 13.201691792000076 ],
							[ 12.55112691000005, 13.201417115000027 ],
							[ 12.553328916000055, 13.205542392000041 ],
							[ 12.554153488000054, 13.213519136000059 ],
							[ 12.553603231000068, 13.218745182000077 ],
							[ 12.557591018000039, 13.221770410000033 ],
							[ 12.561716897000053, 13.223970540000039 ],
							[ 12.567493943000045, 13.22589610700004 ],
							[ 12.569967935000022, 13.227821566000046 ],
							[ 12.572169750000057, 13.230847320000066 ],
							[ 12.568868713000029, 13.234422909000045 ],
							[ 12.566118064000023, 13.237448565000022 ],
							[ 12.566118432000053, 13.239923662000024 ],
							[ 12.569693113000028, 13.243499569000051 ],
							[ 12.572718663000046, 13.248450169000023 ],
							[ 12.575469516000055, 13.250650331000031 ],
							[ 12.576569591000066, 13.253400740000075 ],
							[ 12.577119925000034, 13.256427049000024 ],
							[ 12.577944392000063, 13.260277817000031 ],
							[ 12.578494738000074, 13.263302975000045 ],
							[ 12.578770464000058, 13.266603642000064 ],
							[ 12.57904486800004, 13.26907949200006 ],
							[ 12.582895816000075, 13.268803559000048 ],
							[ 12.586746703000074, 13.266328774000044 ],
							[ 12.590321970000048, 13.263577504000068 ],
							[ 12.592247821000058, 13.264403569000024 ],
							[ 12.592248109000025, 13.267428375000065 ],
							[ 12.591697330000045, 13.269904236000059 ],
							[ 12.589771898000038, 13.272929464000072 ],
							[ 12.588396911000075, 13.275404786000024 ],
							[ 12.589497420000043, 13.277604881000059 ],
							[ 12.599398087000054, 13.275680753000074 ],
							[ 12.603523951000057, 13.275955057000033 ],
							[ 12.608750702000066, 13.276505652000026 ],
							[ 12.612051508000036, 13.274854551000033 ],
							[ 12.618101669000055, 13.271554202000061 ],
							[ 12.626628450000055, 13.272104194000065 ],
							[ 12.629654305000031, 13.275955477000025 ],
							[ 12.633780364000074, 13.278980230000059 ],
							[ 12.635155698000062, 13.282831151000039 ],
							[ 12.636530772000071, 13.285857243000066 ],
							[ 12.637630087000048, 13.289157743000032 ],
							[ 12.638730558000077, 13.292458803000045 ],
							[ 12.641206575000069, 13.297683688000063 ],
							[ 12.643131701000073, 13.299884630000065 ],
							[ 12.64316479200005, 13.299884630000065 ],
							[ 12.646432033000053, 13.299884591000023 ],
							[ 12.64753212100004, 13.295759257000043 ],
							[ 12.647532071000057, 13.294070249000072 ],
							[ 12.647532023000053, 13.29245876300007 ],
							[ 12.648356916000068, 13.290257533000045 ],
							[ 12.648907553000072, 13.285307050000029 ],
							[ 12.650558244000024, 13.283381352000049 ],
							[ 12.652372184000058, 13.283510805000049 ],
							[ 12.654407992000074, 13.283656091000068 ],
							[ 12.657709501000056, 13.285032203000071 ],
							[ 12.663485356000024, 13.28420662700006 ],
							[ 12.665961074000052, 13.280905549000067 ],
							[ 12.667060880000065, 13.276780150000036 ],
							[ 12.668711880000046, 13.273204670000041 ],
							[ 12.673937825000053, 13.272654397000053 ],
							[ 12.675862632000076, 13.274029757000051 ],
							[ 12.678063593000047, 13.275954931000058 ],
							[ 12.679713588000027, 13.278705837000075 ],
							[ 12.683288383000047, 13.28338119600005 ],
							[ 12.690440030000047, 13.292458377000059 ],
							[ 12.69099099500005, 13.294658538000022 ],
							[ 12.69236606000004, 13.297409273000028 ],
							[ 12.693740620000028, 13.299884891000033 ],
							[ 12.692640733000076, 13.302635250000037 ],
							[ 12.691541262000044, 13.306210465000049 ],
							[ 12.691265076000036, 13.308686013000056 ],
							[ 12.692091492000031, 13.312261373000069 ],
							[ 12.694565913000076, 13.31253745600003 ],
							[ 12.698141489000022, 13.31253706800004 ],
							[ 12.703642385000023, 13.312811878000048 ],
							[ 12.70556848800004, 13.311711439000021 ],
							[ 12.709693838000021, 13.307311225000035 ],
							[ 12.712994524000067, 13.305110096000021 ],
							[ 12.716019932000052, 13.304010513000037 ],
							[ 12.720695196000065, 13.305385365000063 ],
							[ 12.719871061000049, 13.309511002000022 ],
							[ 12.715194482000072, 13.313362032000043 ],
							[ 12.713819588000035, 13.316112374000056 ],
							[ 12.716845954000064, 13.31721329100003 ],
							[ 12.723171137000065, 13.318037795000066 ],
							[ 12.73032291800007, 13.318862964000061 ],
							[ 12.735547569000062, 13.320238360000076 ],
							[ 12.738849153000046, 13.321888890000025 ],
							[ 12.740548137000076, 13.32302136800007 ],
							[ 12.74076935100004, 13.323301079000032 ],
							[ 12.741076317000022, 13.323936704000062 ],
							[ 12.741283177000071, 13.324573351000026 ],
							[ 12.741941526000062, 13.325303718000043 ],
							[ 12.742699729000037, 13.326129941000033 ],
							[ 12.742699548000076, 13.329589665000071 ],
							[ 12.738299359000052, 13.331790217000048 ],
							[ 12.738574315000051, 13.334540503000028 ],
							[ 12.741049789000044, 13.339766415000042 ],
							[ 12.748750937000068, 13.34829346500004 ],
							[ 12.753151651000053, 13.352968902000043 ],
							[ 12.76002791600007, 13.351594082000076 ],
							[ 12.768829738000022, 13.353793944000074 ],
							[ 12.774682565000035, 13.355745359000025 ],
							[ 12.777906307000023, 13.356820200000072 ],
							[ 12.782792310000048, 13.35810615500003 ],
							[ 12.787080923000076, 13.365298018000033 ],
							[ 12.789417460000038, 13.366187825000054 ],
							[ 12.791763660000072, 13.367991076000067 ],
							[ 12.791797382000027, 13.371188089000043 ],
							[ 12.791821468000023, 13.373471679000033 ],
							[ 12.79310974200007, 13.377532587000076 ],
							[ 12.793265844000075, 13.378024655000047 ],
							[ 12.797948794000035, 13.380717632000028 ],
							[ 12.798190899000076, 13.380610147000027 ],
							[ 12.798260200000072, 13.380748639000046 ],
							[ 12.801285557000028, 13.381024613000022 ],
							[ 12.805961214000035, 13.384049198000071 ],
							[ 12.808161237000036, 13.385974740000051 ],
							[ 12.809537422000062, 13.387625623000076 ],
							[ 12.816195953000033, 13.390970524000068 ],
							[ 12.818888430000072, 13.392850852000038 ],
							[ 12.820814269000039, 13.396701668000048 ],
							[ 12.819714112000042, 13.400552939000022 ],
							[ 12.820813585000053, 13.403028621000033 ],
							[ 12.823014539000042, 13.405778890000022 ],
							[ 12.825764294000066, 13.405778970000028 ],
							[ 12.82631544700007, 13.403577905000077 ],
							[ 12.826589340000055, 13.399452048000057 ],
							[ 12.827689637000049, 13.396701860000064 ],
							[ 12.83071516900003, 13.396977596000056 ],
							[ 12.832090945000061, 13.398901829000067 ],
							[ 12.833466349000048, 13.401653181000029 ],
							[ 12.833740961000046, 13.404403966000075 ],
							[ 12.833466171000055, 13.406603889000053 ],
							[ 12.832641017000071, 13.411829739000041 ],
							[ 12.832641033000073, 13.414855087000035 ],
							[ 12.830440579000026, 13.41843125500003 ],
							[ 12.827690408000024, 13.419806300000062 ],
							[ 12.825489908000065, 13.420355961000041 ],
							[ 12.820813614000031, 13.420906093000042 ],
							[ 12.818366161000029, 13.421232502000066 ],
							[ 12.816688894000038, 13.42145619300004 ],
							[ 12.816098130000057, 13.425141215000053 ],
							[ 12.821970522000072, 13.425076442000034 ],
							[ 12.826040608000028, 13.425031549000039 ],
							[ 12.828790814000058, 13.426682399000072 ],
							[ 12.830165876000024, 13.430258028000026 ],
							[ 12.833191036000073, 13.430258044000027 ],
							[ 12.834842052000056, 13.428607808000038 ],
							[ 12.838142553000068, 13.429158052000048 ],
							[ 12.83869179800007, 13.431633131000069 ],
							[ 12.837316132000069, 13.433834207000075 ],
							[ 12.835941813000034, 13.437133552000034 ],
							[ 12.837041533000047, 13.439609751000035 ],
							[ 12.838141893000056, 13.442359944000032 ],
							[ 12.841442634000032, 13.443460422000044 ],
							[ 12.844193972000028, 13.441534983000054 ],
							[ 12.847218184000042, 13.439609545000053 ],
							[ 12.849969229000067, 13.439059172000043 ],
							[ 12.856845118000024, 13.437959411000065 ],
							[ 12.860421151000025, 13.437133783000036 ],
							[ 12.862621809000075, 13.439058978000048 ],
							[ 12.860971683000059, 13.441534743000034 ],
							[ 12.858220886000026, 13.445110538000051 ],
							[ 12.856570176000048, 13.447860846000026 ],
							[ 12.858221016000073, 13.456387600000028 ],
							[ 12.861245764000046, 13.462438631000055 ],
							[ 12.862071439000033, 13.466289860000074 ],
							[ 12.859953807000068, 13.466730731000041 ],
							[ 12.855469761000052, 13.467664268000021 ],
							[ 12.851619293000056, 13.468489286000022 ],
							[ 12.850244602000032, 13.470140702000037 ],
							[ 12.85216992200003, 13.473441657000023 ],
							[ 12.855469481000057, 13.475366862000044 ],
							[ 12.858220992000042, 13.477841172000069 ],
							[ 12.862071497000045, 13.478941452000072 ],
							[ 12.866472097000042, 13.476466599000048 ],
							[ 12.867864304000022, 13.475405760000058 ],
							[ 12.87224776000005, 13.472065638000061 ],
							[ 12.875273735000064, 13.469864829000073 ],
							[ 12.878298940000036, 13.470691007000028 ],
							[ 12.87967466300006, 13.474266442000044 ],
							[ 12.879399809000063, 13.477290673000027 ],
							[ 12.876098876000071, 13.480591527000058 ],
							[ 12.874748146000059, 13.482954912000025 ],
							[ 12.873897959000033, 13.484442493000074 ],
							[ 12.87279810800004, 13.49021868400007 ],
							[ 12.87444832500006, 13.494895199000041 ],
							[ 12.877749352000023, 13.49682019200003 ],
							[ 12.88489953800007, 13.494895094000071 ],
							[ 12.891777330000025, 13.493243848000077 ],
							[ 12.891911850000042, 13.491762636000033 ],
							[ 12.892326843000035, 13.487193115000025 ],
							[ 12.893701897000028, 13.485268112000028 ],
							[ 12.900302561000046, 13.485268146000067 ],
							[ 12.904154273000074, 13.483067511000058 ],
							[ 12.907180384000071, 13.481417834000069 ],
							[ 12.919281673000057, 13.482792647000053 ],
							[ 12.924782617000062, 13.48334163100003 ],
							[ 12.928358377000052, 13.489943385000061 ],
							[ 12.93688525400006, 13.499020528000074 ],
							[ 12.946511318000034, 13.502321075000054 ],
							[ 12.950026592000029, 13.505695353000021 ],
							[ 12.953388778000033, 13.508922683000037 ],
							[ 12.954502396000066, 13.50758597500004 ],
							[ 12.958888569000067, 13.502321126000027 ],
							[ 12.960815, 13.498195732000056 ],
							[ 12.964940326000033, 13.506721190000064 ],
							[ 12.967965527000047, 13.511673082000073 ],
							[ 12.97002261800003, 13.514141865000056 ],
							[ 12.970715719000054, 13.514973678000047 ],
							[ 12.971300285000041, 13.514681564000057 ],
							[ 12.973467253000024, 13.513598706000039 ],
							[ 12.975116849000074, 13.511947299000042 ],
							[ 12.975390840000046, 13.509747827000069 ],
							[ 12.986118188000034, 13.509471896000036 ],
							[ 12.992994813000053, 13.512497780000047 ],
							[ 12.998220273000072, 13.51359889500003 ],
							[ 13.001796733000049, 13.513598998000077 ],
							[ 13.003996396000048, 13.512222919000067 ],
							[ 13.005921901000022, 13.510847510000076 ],
							[ 13.009497952000061, 13.511948071000063 ],
							[ 13.015823873000045, 13.517723993000061 ],
							[ 13.026001339000061, 13.529826469000056 ],
							[ 13.027926205000028, 13.531475718000024 ],
							[ 13.033152701000063, 13.530376412000066 ],
							[ 13.039752725000028, 13.526249813000049 ],
							[ 13.042520778000039, 13.525528291000057 ],
							[ 13.046079878000057, 13.524600575000022 ],
							[ 13.046629904000042, 13.526800206000075 ],
							[ 13.046079338000027, 13.531201524000039 ],
							[ 13.046629605000021, 13.535876764000022 ],
							[ 13.047052559000065, 13.538414386000056 ],
							[ 13.047454867000056, 13.540828132000058 ],
							[ 13.049379805000058, 13.541652431000045 ],
							[ 13.052828916000067, 13.540272855000069 ],
							[ 13.056255744000055, 13.538902192000023 ],
							[ 13.060106707000045, 13.542478304000042 ],
							[ 13.063958334000063, 13.547429471000044 ],
							[ 13.067532061000065, 13.547428904000071 ],
							[ 13.068908255000053, 13.547428685000057 ],
							[ 13.074959806000038, 13.547154810000052 ],
							[ 13.081285660000049, 13.545779552000056 ],
							[ 13.080178017000037, 13.541260717000057 ],
							[ 13.076394518000029, 13.536845664000055 ],
							[ 13.072543047000067, 13.535989861000076 ],
							[ 13.070718554000052, 13.535584456000038 ],
							[ 13.06920499000006, 13.532179127000063 ],
							[ 13.068196119000049, 13.529909294000049 ],
							[ 13.069893011000033, 13.526514933000044 ],
							[ 13.071348828000055, 13.523602805000053 ],
							[ 13.075133160000064, 13.521710726000038 ],
							[ 13.079008086000044, 13.521157096000024 ],
							[ 13.081572190000031, 13.520790749000071 ],
							[ 13.083961721000037, 13.520449345000031 ],
							[ 13.08837550100003, 13.517296043000044 ],
							[ 13.088512851000075, 13.517158764000044 ],
							[ 13.08957016100004, 13.516101996000032 ],
							[ 13.091530230000046, 13.514142934000063 ],
							[ 13.09468276900003, 13.516665380000063 ],
							[ 13.100359035000054, 13.529277312000033 ],
							[ 13.105403528000068, 13.534953924000035 ],
							[ 13.111021232000041, 13.530834468000023 ],
							[ 13.114863073000038, 13.528017249000072 ],
							[ 13.118494540000029, 13.523175312000035 ],
							[ 13.118646815000034, 13.522972279000044 ],
							[ 13.118838995000033, 13.522852158000035 ],
							[ 13.119971726000074, 13.522144150000031 ],
							[ 13.123692687000073, 13.519818380000061 ],
							[ 13.126845003000028, 13.519188083000074 ],
							[ 13.126373262000072, 13.522967571000038 ],
							[ 13.12621527500005, 13.524233329000026 ],
							[ 13.126215281000043, 13.525502044000064 ],
							[ 13.126215296000055, 13.528647890000059 ],
							[ 13.126507140000058, 13.530401445000052 ],
							[ 13.126773581000066, 13.532002371000033 ],
							[ 13.126845049000053, 13.532431788000054 ],
							[ 13.125451512000041, 13.533592884000029 ],
							[ 13.123061518000043, 13.53558423000004 ],
							[ 13.123789763000048, 13.537040765000029 ],
							[ 13.124953780000055, 13.539368869000043 ],
							[ 13.138826787000028, 13.539999548000026 ],
							[ 13.141940863000059, 13.542489868000075 ],
							[ 13.145134198000051, 13.545043571000065 ],
							[ 13.149548468000035, 13.545044274000077 ],
							[ 13.148287181000057, 13.54126063800004 ],
							[ 13.145764133000057, 13.538737320000052 ],
							[ 13.145133797000028, 13.53432409100003 ],
							[ 13.145133544000032, 13.529909532000033 ],
							[ 13.143872, 13.525494270000024 ],
							[ 13.143242110000074, 13.519817791000037 ],
							[ 13.144842280000034, 13.516617893000046 ],
							[ 13.145133609000027, 13.516035316000057 ],
							[ 13.146111850000068, 13.515872264000052 ],
							[ 13.152701689000025, 13.51477387400007 ],
							[ 13.157746766000059, 13.512881626000024 ],
							[ 13.159638146000077, 13.518557588000021 ],
							[ 13.162161186000048, 13.522972295000045 ],
							[ 13.164053381000031, 13.52612513300005 ],
							[ 13.170990196000048, 13.529908633000048 ],
							[ 13.176665686000035, 13.531799955000054 ],
							[ 13.185494614000049, 13.531170038000027 ],
							[ 13.18609546600004, 13.529539257000067 ],
							[ 13.189909515000068, 13.519187504000058 ],
							[ 13.191847608000046, 13.514988307000067 ],
							[ 13.193693308000036, 13.510989293000023 ],
							[ 13.198738608000042, 13.509728117000066 ],
							[ 13.200630389000025, 13.516665986000021 ],
							[ 13.199368858000071, 13.52108006800006 ],
							[ 13.206305736000047, 13.526125045000072 ],
							[ 13.210090252000043, 13.529909420000024 ],
							[ 13.210568644000034, 13.532940081000049 ],
							[ 13.21067240900004, 13.533597443000076 ],
							[ 13.211981580000042, 13.541891169000053 ],
							[ 13.213873593000073, 13.550088824000056 ],
							[ 13.218918721000023, 13.54504442800004 ],
							[ 13.222072121000053, 13.550088883000058 ],
							[ 13.227748116000043, 13.553242664000038 ],
							[ 13.23657653600003, 13.557026138000026 ],
							[ 13.239099090000025, 13.560809831000029 ],
							[ 13.242251794000026, 13.565855251000073 ],
							[ 13.241621905000045, 13.570269082000038 ],
							[ 13.238468079000029, 13.572161319000031 ],
							[ 13.235610734000034, 13.572161308000034 ],
							[ 13.234684783000034, 13.572161304000076 ],
							[ 13.233738109000058, 13.573343903000023 ],
							[ 13.232160949000047, 13.575314113000047 ],
							[ 13.234685168000055, 13.580990436000036 ],
							[ 13.237837458000058, 13.585405394000077 ],
							[ 13.241621409000061, 13.586035869000057 ],
							[ 13.243800031000035, 13.584401600000035 ],
							[ 13.246092677000036, 13.582681798000067 ],
							[ 13.249189107000063, 13.580359047000059 ],
							[ 13.254234587000042, 13.579728743000032 ],
							[ 13.257387637000022, 13.586036154000055 ],
							[ 13.261820576000048, 13.589138993000063 ],
							[ 13.263693520000061, 13.590449962000037 ],
							[ 13.264955043000043, 13.594233189000022 ],
							[ 13.263063256000066, 13.598017407000043 ],
							[ 13.260538397000062, 13.598017558000038 ],
							[ 13.257386771000029, 13.598017746000039 ],
							[ 13.255496119000043, 13.599279922000051 ],
							[ 13.255496189000041, 13.600288853000052 ],
							[ 13.255496512000036, 13.604954336000048 ],
							[ 13.250449850000052, 13.61126131900005 ],
							[ 13.249189275000049, 13.613784058000022 ],
							[ 13.251711275000048, 13.616306706000046 ],
							[ 13.256126384000027, 13.617568235000022 ],
							[ 13.259193883000023, 13.620251917000076 ],
							[ 13.261171155000056, 13.621981786000049 ],
							[ 13.261434324000049, 13.622771541000077 ],
							[ 13.263693272000069, 13.629550504000065 ],
							[ 13.270000562000064, 13.634594680000077 ],
							[ 13.274414891000049, 13.638379096000051 ],
							[ 13.274365739000075, 13.638526464000051 ],
							[ 13.273709863000022, 13.640492907000066 ],
							[ 13.273153236000041, 13.642161785000042 ],
							[ 13.274415116000057, 13.648469166000041 ],
							[ 13.284505230000036, 13.651621274000036 ],
							[ 13.291441863000045, 13.657298528000069 ],
							[ 13.291946078000024, 13.657348915000057 ],
							[ 13.292995168000061, 13.657453753000027 ],
							[ 13.297749104000047, 13.657928825000056 ],
							[ 13.299640746000023, 13.665495508000049 ],
							[ 13.299074993000033, 13.667757899000037 ],
							[ 13.298316060000047, 13.670792802000051 ],
							[ 13.297748123000076, 13.673063929000023 ],
							[ 13.302185057000031, 13.674172494000061 ],
							[ 13.302793274000067, 13.674324457000068 ],
							[ 13.303110131000039, 13.67445119000007 ],
							[ 13.309100487000023, 13.676847159000033 ],
							[ 13.310361313000044, 13.68252394600006 ],
							[ 13.31288339300005, 13.687568115000033 ],
							[ 13.314775508000025, 13.690721285000052 ],
							[ 13.316288795000048, 13.690810320000026 ],
							[ 13.319800229000066, 13.691016916000024 ],
							[ 13.323402042000055, 13.691228830000057 ],
							[ 13.325497150000047, 13.69135209600006 ],
							[ 13.325596154000038, 13.691367338000077 ],
							[ 13.333694559000037, 13.692614113000047 ],
							[ 13.333856973000024, 13.693036262000021 ],
							[ 13.33509951700006, 13.696265894000021 ],
							[ 13.336848649000046, 13.700812255000073 ],
							[ 13.339371042000039, 13.70774883200005 ],
							[ 13.342523154000048, 13.710901923000051 ],
							[ 13.349460682000029, 13.711533065000026 ],
							[ 13.354023586000039, 13.713813996000056 ],
							[ 13.354505733000053, 13.714055015000042 ],
							[ 13.355803554000033, 13.713622493000059 ],
							[ 13.357071939000036, 13.713199782000061 ],
							[ 13.358396690000063, 13.712758286000053 ],
							[ 13.358852732000059, 13.71260630200004 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Cross River',
				admin1Pcod: 'NG009',
				admin1RefN: 'Cross River',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 8.779796,
				Shape_Area: 1.711218
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.560679695000033, 4.798473349000062 ],
							[ 8.559439932000032, 4.798471426000049 ],
							[ 8.557785010000032, 4.799569133000034 ],
							[ 8.556267778000063, 4.800254851000034 ],
							[ 8.553507049000075, 4.803551704000029 ],
							[ 8.553504091000036, 4.805064726000069 ],
							[ 8.553777868000054, 4.806028353000045 ],
							[ 8.55515301500003, 4.807544245000031 ],
							[ 8.555703149000067, 4.807819812000048 ],
							[ 8.556665159000033, 4.808784922000029 ],
							[ 8.557489473000032, 4.81016160300004 ],
							[ 8.55789962700004, 4.811813380000046 ],
							[ 8.558449604000032, 4.812227269000061 ],
							[ 8.558448062000025, 4.813052157000072 ],
							[ 8.559822866000047, 4.814430704000074 ],
							[ 8.562575490000029, 4.816085796000038 ],
							[ 8.563952196000059, 4.816363323000076 ],
							[ 8.565606020000075, 4.816228378000062 ],
							[ 8.566295707000052, 4.815954656000031 ],
							[ 8.568089550000025, 4.81389426800007 ],
							[ 8.568366271000059, 4.813344026000038 ],
							[ 8.568367078000051, 4.812655910000046 ],
							[ 8.568918907000068, 4.811969297000076 ],
							[ 8.569061081000029, 4.809630829000071 ],
							[ 8.569616048000057, 4.807705884000029 ],
							[ 8.570169314000054, 4.806743599000072 ],
							[ 8.571548303000043, 4.80550766600004 ],
							[ 8.571964374000061, 4.804545419000021 ],
							[ 8.571965333000037, 4.803720031000069 ],
							[ 8.571277365000071, 4.803030956000043 ],
							[ 8.570177111000021, 4.80247881400004 ],
							[ 8.569074519000026, 4.802477364000026 ],
							[ 8.568248480000022, 4.802063016000034 ],
							[ 8.567284363000056, 4.801923767000062 ],
							[ 8.56687271900006, 4.801510333000067 ],
							[ 8.565358082000046, 4.800957665000055 ],
							[ 8.564946443000053, 4.800544247000062 ],
							[ 8.564257843000064, 4.800405505000072 ],
							[ 8.562193743000023, 4.799026517000073 ],
							[ 8.561366839000073, 4.798887251000053 ],
							[ 8.560679695000033, 4.798473349000062 ]
						]
					],
					[
						[
							[ 8.586840551000023, 4.80470706400007 ],
							[ 8.58339813200007, 4.804426694000028 ],
							[ 8.580778800000076, 4.804697030000057 ],
							[ 8.57871321600004, 4.80455639500002 ],
							[ 8.576509541000064, 4.804690390000076 ],
							[ 8.57526888700005, 4.804963564000047 ],
							[ 8.574028129000055, 4.805787079000027 ],
							[ 8.57361439500005, 4.806199059000051 ],
							[ 8.572368592000032, 4.808948514000065 ],
							[ 8.571397563000062, 4.812798518000022 ],
							[ 8.570568146000028, 4.814311049000025 ],
							[ 8.570428690000028, 4.815273768000054 ],
							[ 8.570842047000042, 4.815686699000025 ],
							[ 8.572631632000025, 4.815827351000053 ],
							[ 8.573457690000055, 4.816241263000052 ],
							[ 8.577177018000043, 4.816385259000072 ],
							[ 8.580902824000077, 4.812814239000033 ],
							[ 8.581868310000061, 4.812265836000051 ],
							[ 8.583110092000027, 4.811029425000072 ],
							[ 8.584902576000047, 4.810069088000034 ],
							[ 8.587800216000062, 4.807322998000075 ],
							[ 8.588351406000072, 4.80718564600005 ],
							[ 8.588903590000029, 4.80663634900003 ],
							[ 8.589042841000037, 4.805811395000035 ],
							[ 8.588079320000077, 4.805121893000035 ],
							[ 8.586840551000023, 4.80470706400007 ]
						]
					],
					[
						[
							[ 8.300487296000028, 4.792379406000066 ],
							[ 8.297090797000067, 4.792372871000055 ],
							[ 8.294825385000024, 4.79301881300006 ],
							[ 8.292396129000053, 4.794964951000054 ],
							[ 8.28818605500004, 4.797557898000036 ],
							[ 8.285918074000051, 4.799504289000026 ],
							[ 8.283487129000036, 4.802263234000065 ],
							[ 8.282026687000041, 4.80469895400006 ],
							[ 8.280564639000033, 4.807947480000053 ],
							[ 8.278781181000056, 4.810219997000047 ],
							[ 8.276670872000068, 4.814280112000063 ],
							[ 8.275690777000023, 4.819155333000026 ],
							[ 8.275526921000051, 4.820130393000056 ],
							[ 8.276002270000049, 4.825171076000061 ],
							[ 8.276483064000047, 4.827448072000038 ],
							[ 8.277772764000076, 4.829564025000025 ],
							[ 8.28019749300006, 4.83038171100003 ],
							[ 8.282462508000037, 4.830061036000075 ],
							[ 8.285538329000076, 4.828603946000044 ],
							[ 8.292989961000046, 4.822440712000059 ],
							[ 8.298500920000038, 4.816273686000045 ],
							[ 8.301257255000053, 4.812702376000061 ],
							[ 8.303528908000033, 4.808804979000058 ],
							[ 8.306287589000021, 4.803933053000037 ],
							[ 8.306130867000036, 4.801169039000058 ],
							[ 8.305648086000076, 4.799867611000025 ],
							[ 8.30484509200005, 4.796939737000059 ],
							[ 8.303070433000073, 4.794660379000049 ],
							[ 8.302265008000063, 4.793033133000051 ],
							[ 8.30113265500006, 4.793030963000035 ],
							[ 8.300487296000028, 4.792379406000066 ]
						]
					],
					[
						[
							[ 8.848406591000071, 6.865565763000063 ],
							[ 8.851812539000036, 6.863246080000067 ],
							[ 8.856358326000077, 6.860918905000062 ],
							[ 8.859772781000061, 6.859746985000072 ],
							[ 8.864310431000035, 6.856272171000057 ],
							[ 8.867715616000055, 6.85395206000004 ],
							[ 8.869998172000066, 6.853937044000077 ],
							[ 8.874535654000056, 6.850460972000064 ],
							[ 8.879090374000043, 6.849280778000036 ],
							[ 8.887033233000068, 6.843485710000039 ],
							[ 8.891579699000033, 6.841158950000022 ],
							[ 8.897258886000031, 6.837675023000031 ],
							[ 8.902946703000055, 6.835339957000031 ],
							[ 8.908633317000067, 6.83300397000005 ],
							[ 8.913179516000071, 6.830676922000066 ],
							[ 8.918857761000027, 6.827192659000048 ],
							[ 8.923403822000068, 6.824865779000049 ],
							[ 8.92680947100007, 6.822545969000032 ],
							[ 8.929426368000065, 6.820408795000048 ],
							[ 8.932479762000071, 6.81791508200007 ],
							[ 8.934744993000038, 6.815602894000051 ],
							[ 8.939709712000024, 6.813222477000068 ],
							[ 8.942987463000065, 6.810498690000031 ],
							[ 8.943828797000037, 6.80980001100005 ],
							[ 8.947234176000052, 6.807479732000047 ],
							[ 8.951771479000058, 6.804005235000034 ],
							[ 8.957450648000076, 6.800520945000073 ],
							[ 8.960848067000029, 6.797053830000038 ],
							[ 8.964253239000072, 6.794733946000065 ],
							[ 8.96879972000005, 6.792407075000028 ],
							[ 8.972205353000049, 6.790087118000031 ],
							[ 8.974477973000035, 6.788922779000075 ],
							[ 8.976742760000036, 6.786611180000023 ],
							[ 8.977304145000062, 6.786037592000071 ],
							[ 8.99493725800005, 6.776636959000029 ],
							[ 9.002773809000075, 6.768019268000046 ],
							[ 9.007475096000064, 6.761751663000041 ],
							[ 9.007475016000058, 6.757834581000054 ],
							[ 9.001206251000042, 6.753134 ],
							[ 8.991419027000063, 6.746609797000076 ],
							[ 8.990109462000021, 6.742890776000024 ],
							[ 8.987792694000063, 6.738315217000036 ],
							[ 8.986626762000071, 6.734879045000071 ],
							[ 8.984311219000062, 6.730301796000049 ],
							[ 8.981986752000068, 6.724576967000075 ],
							[ 8.980829321000044, 6.722289171000057 ],
							[ 8.977364399000066, 6.716573288000063 ],
							[ 8.977347595000026, 6.714275938000071 ],
							[ 8.975047976000042, 6.711995958000045 ],
							[ 8.973890055000027, 6.709708139000043 ],
							[ 8.972732721000057, 6.707418909000069 ],
							[ 8.970425359000046, 6.703991058000042 ],
							[ 8.969259107000028, 6.70055484300002 ],
							[ 8.968091764000064, 6.697119162000035 ],
							[ 8.969207701000073, 6.69366702800005 ],
							[ 8.970331431000034, 6.691361843000038 ],
							[ 8.972613544000069, 6.691347058000076 ],
							[ 8.97603613800004, 6.691322704000072 ],
							[ 8.978326089000063, 6.692455707000022 ],
							[ 8.980608209000025, 6.692440002000069 ],
							[ 8.985179752000022, 6.693556687000068 ],
							[ 8.98745343500002, 6.692392836000067 ],
							[ 8.989734423000073, 6.692377074000035 ],
							[ 8.992015986000069, 6.692361018000042 ],
							[ 8.997651818000065, 6.683137801000044 ],
							[ 9.00444591400003, 6.676201928000069 ],
							[ 9.011239771000021, 6.669267056000024 ],
							[ 9.018033907000074, 6.662332056000025 ],
							[ 9.022545919000038, 6.655412334000061 ],
							[ 9.027076021000028, 6.650787928000057 ],
							[ 9.035036194000043, 6.64728866400003 ],
							[ 9.047559841000066, 6.643758698000056 ],
							[ 9.058959107000021, 6.642531786000063 ],
							[ 9.070367554000029, 6.642453196000076 ],
							[ 9.077238003000048, 6.645850085000063 ],
							[ 9.082967638000071, 6.649254752000047 ],
							[ 9.085528078000038, 6.651646447000076 ],
							[ 9.087375849000068, 6.651276409000047 ],
							[ 9.100693780000029, 6.654406774000051 ],
							[ 9.111662387000024, 6.659887567000055 ],
							[ 9.115818618000048, 6.666533351000055 ],
							[ 9.117329450000057, 6.667387947000066 ],
							[ 9.123076398000023, 6.673089870000069 ],
							[ 9.129058021000048, 6.675517982000031 ],
							[ 9.136024966000036, 6.680005331000075 ],
							[ 9.136688410000033, 6.67990308100002 ],
							[ 9.139228244000037, 6.680140038000047 ],
							[ 9.142641325000056, 6.680614866000042 ],
							[ 9.143911261000028, 6.680773188000046 ],
							[ 9.144863650000048, 6.681090432000076 ],
							[ 9.146689415000026, 6.681962579000071 ],
							[ 9.149400830000047, 6.684389097000064 ],
							[ 9.153963824000073, 6.684358115000066 ],
							[ 9.158527372000037, 6.684326205000048 ],
							[ 9.165389850000054, 6.686575040000037 ],
							[ 9.167671369000061, 6.686559299000066 ],
							[ 9.168197033000069, 6.686731710000061 ],
							[ 9.171102351000059, 6.687684073000071 ],
							[ 9.175674416000049, 6.68879977000006 ],
							[ 9.184833343000037, 6.693329779000067 ],
							[ 9.192829261000043, 6.694423184000073 ],
							[ 9.199700117000077, 6.697820325000066 ],
							[ 9.208860123000022, 6.702349158000061 ],
							[ 9.215731832000074, 6.705746275000024 ],
							[ 9.228305812000031, 6.709104141000068 ],
							[ 9.238598919000026, 6.712477198000045 ],
							[ 9.247742405000054, 6.714711126000054 ],
							[ 9.255754711000066, 6.718099921000032 ],
							[ 9.26603026500004, 6.719176851000043 ],
							[ 9.270593682000026, 6.719144693000032 ],
							[ 9.279720515000065, 6.719082726000067 ],
							[ 9.285432740000033, 6.720191124000053 ],
							[ 9.291146129000026, 6.721301098000026 ],
							[ 9.295701291000057, 6.720119959000044 ],
							[ 9.300264162000076, 6.720088840000074 ],
							[ 9.303669842000033, 6.717770035000058 ],
							[ 9.307058310000059, 6.713153863000059 ],
							[ 9.309297824000055, 6.707397861000061 ],
							[ 9.313809409000044, 6.700477953000075 ],
							[ 9.318321955000044, 6.693559116000074 ],
							[ 9.325099100000045, 6.684327031000066 ],
							[ 9.334148391000042, 6.673932186000059 ],
							[ 9.343207262000021, 6.664684670000042 ],
							[ 9.347703077000062, 6.655468971000062 ],
							[ 9.352214982000021, 6.648549148000029 ],
							[ 9.357876658000066, 6.642769749000024 ],
							[ 9.361247915000035, 6.635857978000047 ],
							[ 9.368042179000042, 6.628922121000073 ],
							[ 9.372579484000028, 6.625446922000037 ],
							[ 9.377117278000071, 6.621972220000032 ],
							[ 9.381655540000054, 6.618496053000058 ],
							[ 9.386176039000077, 6.612723897000023 ],
							[ 9.39185433800003, 6.609241029000032 ],
							[ 9.39639287600005, 6.60576495600003 ],
							[ 9.402062361000048, 6.601133812000057 ],
							[ 9.405442150000056, 6.595369738000045 ],
							[ 9.40997118000007, 6.590745954000056 ],
							[ 9.413369169000021, 6.587277998000047 ],
							[ 9.416757864000033, 6.582663042000036 ],
							[ 9.420137253000064, 6.576899106000042 ],
							[ 9.422393744000033, 6.57343916800005 ],
							[ 9.424649083000077, 6.569979218000071 ],
							[ 9.428028804000064, 6.564215186000069 ],
							[ 9.432542033000061, 6.557295751000026 ],
							[ 9.43819512500005, 6.550367840000035 ],
							[ 9.441592474000061, 6.546901320000075 ],
							[ 9.446121022000057, 6.542276801000071 ],
							[ 9.449518200000057, 6.538808799000037 ],
							[ 9.454064648000042, 6.536480952000034 ],
							[ 9.458601698000052, 6.533006079000074 ],
							[ 9.462008598000068, 6.530685779000066 ],
							[ 9.465413198000022, 6.528367136000043 ],
							[ 9.468827127000054, 6.527194893000058 ],
							[ 9.471092271000032, 6.524884154000063 ],
							[ 9.472206888000073, 6.521431017000054 ],
							[ 9.473322059000054, 6.517979283000045 ],
							[ 9.473297268000067, 6.514534965000053 ],
							[ 9.474411835000069, 6.511083226000039 ],
							[ 9.474361698000052, 6.50419514500004 ],
							[ 9.474326935000022, 6.499602708000054 ],
							[ 9.473152312000025, 6.495017967000024 ],
							[ 9.471977163000076, 6.490434056000026 ],
							[ 9.470819670000026, 6.488145805000045 ],
							[ 9.467286210000054, 6.473245115000054 ],
							[ 9.467234491000056, 6.466357301000073 ],
							[ 9.466034788000059, 6.458327910000037 ],
							[ 9.465628632000062, 6.455608316000053 ],
							[ 9.464834009000072, 6.450298810000049 ],
							[ 9.463642084000071, 6.443418936000057 ],
							[ 9.46245020300006, 6.436539057000061 ],
							[ 9.460117561000061, 6.429667107000057 ],
							[ 9.45778443100005, 6.422793716000058 ],
							[ 9.456600929000047, 6.417060805000062 ],
							[ 9.453136560000075, 6.411345109000024 ],
							[ 9.450803679000046, 6.404471965000027 ],
							[ 9.449627971000041, 6.399887986000067 ],
							[ 9.448436493000031, 6.393006919000072 ],
							[ 9.44606126900004, 6.380394006000074 ],
							[ 9.445992497000077, 6.371211063000032 ],
							[ 9.444801181000059, 6.364329828000052 ],
							[ 9.443591869000045, 6.355154061000064 ],
							[ 9.442408807000049, 6.349421089000032 ],
							[ 9.437785069000029, 6.341415897000047 ],
							[ 9.435461073000056, 6.335691042000064 ],
							[ 9.432014624000033, 6.332271086000048 ],
							[ 9.428564832000063, 6.328849823000041 ],
							[ 9.42397690100006, 6.325438072000054 ],
							[ 9.419387906000054, 6.322024818000045 ],
							[ 9.413665511000033, 6.319768002000046 ],
							[ 9.410226895000051, 6.317494839000062 ],
							[ 9.406796368000073, 6.316371031000074 ],
							[ 9.399950311000055, 6.316418135000049 ],
							[ 9.397686026000031, 6.318729860000076 ],
							[ 9.391998066000042, 6.321065032000035 ],
							[ 9.38972571000005, 6.322228856000038 ],
							[ 9.384038221000026, 6.324564904000056 ],
							[ 9.378359742000043, 6.328048250000052 ],
							[ 9.371548605000044, 6.332686871000021 ],
							[ 9.365853476000041, 6.333875210000031 ],
							[ 9.361307279000073, 6.33620213100005 ],
							[ 9.357884636000051, 6.336225976000037 ],
							[ 9.355602493000049, 6.336242182000035 ],
							[ 9.351038759000062, 6.336273164000033 ],
							[ 9.34647559900003, 6.336305198000048 ],
							[ 9.344194581000068, 6.336319925000055 ],
							[ 9.340755668000043, 6.334046815000022 ],
							[ 9.339588268000057, 6.330611286000021 ],
							[ 9.339545118000046, 6.324871122000047 ],
							[ 9.340669352000077, 6.322567044000039 ],
							[ 9.342926175000059, 6.319107116000055 ],
							[ 9.345181253000021, 6.315648046000035 ],
							[ 9.347437992000039, 6.312188108000043 ],
							[ 9.348545265000041, 6.307588040000041 ],
							[ 9.348519403000068, 6.304142983000077 ],
							[ 9.348485125000025, 6.299551056000041 ],
							[ 9.347318891000043, 6.296114843000055 ],
							[ 9.346152105000044, 6.292678774000024 ],
							[ 9.346135268000069, 6.290381975000059 ],
							[ 9.343828148000057, 6.286953883000024 ],
							[ 9.34266199800004, 6.283517801000073 ],
							[ 9.33918774700004, 6.276652741000021 ],
							[ 9.334582261000037, 6.270944174000022 ],
							[ 9.334564925000052, 6.268648207000069 ],
							[ 9.332265878000044, 6.266367893000051 ],
							[ 9.327634986000021, 6.25721500700007 ],
							[ 9.32529369100007, 6.249194170000067 ],
							[ 9.321811739000054, 6.241180821000057 ],
							[ 9.314889899000036, 6.230896058000042 ],
							[ 9.311424412000065, 6.225180133000038 ],
							[ 9.309091430000024, 6.218307021000044 ],
							[ 9.305601273000036, 6.20914609700003 ],
							[ 9.302127848000055, 6.202281944000049 ],
							[ 9.299812303000067, 6.197704742000042 ],
							[ 9.296337965000021, 6.190839828000037 ],
							[ 9.291724286000033, 6.183982846000049 ],
							[ 9.288258456000051, 6.178266971000028 ],
							[ 9.287083804000076, 6.173683136000022 ],
							[ 9.284776860000022, 6.170254231000058 ],
							[ 9.281319490000044, 6.165686146000041 ],
							[ 9.27902141900006, 6.163404915000058 ],
							[ 9.276714060000074, 6.159976958000072 ],
							[ 9.275555445000066, 6.157689045000041 ],
							[ 9.269818426000029, 6.153134838000028 ],
							[ 9.26294697000003, 6.14973881800006 ],
							[ 9.254927591000069, 6.14520127000003 ],
							[ 9.244617670000025, 6.13953207000003 ],
							[ 9.237738636000074, 6.134986862000062 ],
							[ 9.22857775500006, 6.130456974000026 ],
							[ 9.225146150000057, 6.129332996000073 ],
							[ 9.215985567000075, 6.124804051000069 ],
							[ 9.20912370700006, 6.122554731000037 ],
							[ 9.204543275000049, 6.120289865000075 ],
							[ 9.196522453000057, 6.115753144000053 ],
							[ 9.189652375000037, 6.112356168000076 ],
							[ 9.182790892000071, 6.110106909000024 ],
							[ 9.173629629000061, 6.105577883000024 ],
							[ 9.166767798000024, 6.103329175000056 ],
							[ 9.158756205000032, 6.099938914000063 ],
							[ 9.151885073000074, 6.096542783000075 ],
							[ 9.147314266000024, 6.095426045000067 ],
							[ 9.143874319000076, 6.093153029000064 ],
							[ 9.140434435000031, 6.090880851000065 ],
							[ 9.135853695000037, 6.088615826000023 ],
							[ 9.13126585200007, 6.085204173000022 ],
							[ 9.127834414000063, 6.08407874300002 ],
							[ 9.122104720000038, 6.08067414900006 ],
							[ 9.118664514000045, 6.078402044000029 ],
							[ 9.114075813000056, 6.074988896000036 ],
							[ 9.107187974000055, 6.06929592900002 ],
							[ 9.101441152000064, 6.063594738000063 ],
							[ 9.09456246700006, 6.059050102000072 ],
							[ 9.087675230000059, 6.053357190000042 ],
							[ 9.078488185000026, 6.045382907000032 ],
							[ 9.072758425000075, 6.041977832000043 ],
							[ 9.068152693000059, 6.036270106000075 ],
							[ 9.06011582900004, 6.02943706700006 ],
							[ 9.056659773000035, 6.024867947000075 ],
							[ 9.050912711000024, 6.01916695400007 ],
							[ 9.044016615000032, 6.01232578500003 ],
							[ 9.035979025000074, 6.005492209000067 ],
							[ 9.029083463000063, 5.998652014000072 ],
							[ 9.023327594000023, 5.991802153000037 ],
							[ 9.018731228000036, 5.98724222200002 ],
							[ 9.014124767000055, 5.981533082000055 ],
							[ 9.009526944000072, 5.976972182000054 ],
							[ 9.006063546000064, 5.971254850000037 ],
							[ 8.999157896000042, 5.963265949000061 ],
							[ 8.995709399000077, 5.959846080000034 ],
							[ 8.993401439000024, 5.956417015000056 ],
							[ 8.991111523000029, 5.955285075000063 ],
							[ 8.989945219000049, 5.951848958000028 ],
							[ 8.97846906500007, 5.942742873000043 ],
							[ 8.971572925000032, 5.93590213300007 ],
							[ 8.967000714000051, 5.934784838000041 ],
							[ 8.961262563000048, 5.930232109000031 ],
							[ 8.952101740000046, 5.92570302200005 ],
							[ 8.946372212000028, 5.922297916000048 ],
							[ 8.941791391000038, 5.920033002000025 ],
							[ 8.940877107000063, 5.917129996000028 ],
							[ 8.939579699000035, 5.911942931000056 ],
							[ 8.935473178000052, 5.910646012000029 ],
							[ 8.930071014000021, 5.907836404000022 ],
							[ 8.923154650000072, 5.902649439000072 ],
							[ 8.915590119000058, 5.904162426000028 ],
							[ 8.912347684000054, 5.903081901000064 ],
							[ 8.910836036000035, 5.899580013000048 ],
							[ 8.913645037000038, 5.892275290000043 ],
							[ 8.911700482000072, 5.88579174900002 ],
							[ 8.908026007000046, 5.881253224000034 ],
							[ 8.903919320000057, 5.871527262000029 ],
							[ 8.904567801000042, 5.86504364700005 ],
							[ 8.901973657000042, 5.861801688000071 ],
							[ 8.89527404200004, 5.860288561000061 ],
							[ 8.889223248000064, 5.859640648000038 ],
							[ 8.883387385000049, 5.860505103000037 ],
							[ 8.881441861000042, 5.857479106000028 ],
							[ 8.877552046000062, 5.854669629000057 ],
							[ 8.871067813000025, 5.852292137000063 ],
							[ 8.869555241000057, 5.849915041000031 ],
							[ 8.865232562000074, 5.847105 ],
							[ 8.860910585000056, 5.845592518000046 ],
							[ 8.857667798000023, 5.842782519000025 ],
							[ 8.859181541000055, 5.838892461000057 ],
							[ 8.858964867000054, 5.831976356000041 ],
							[ 8.851833518000035, 5.827653919000056 ],
							[ 8.844131587000049, 5.82771020000007 ],
							[ 8.844113402000062, 5.825413192000042 ],
							[ 8.844079824000062, 5.820820878000063 ],
							[ 8.844054220000032, 5.817377115000056 ],
							[ 8.844028617000049, 5.813932944000044 ],
							[ 8.846267650000073, 5.808177044000047 ],
							[ 8.848516301000075, 5.803568866000035 ],
							[ 8.853018589000044, 5.795501293000029 ],
							[ 8.855257941000048, 5.789744908000046 ],
							[ 8.857497224000042, 5.783988934000035 ],
							[ 8.858595961000049, 5.778241136000076 ],
							[ 8.858578797000064, 5.775945254000021 ],
							[ 8.85623729200006, 5.767924813000036 ],
							[ 8.853913025000054, 5.762199943000041 ],
							[ 8.852746918000037, 5.758763789000056 ],
							[ 8.85271268300005, 5.754171874000065 ],
							[ 8.852669896000066, 5.748432089000062 ],
							[ 8.851477623000051, 5.74155089900006 ],
							[ 8.850311032000036, 5.738114865000057 ],
							[ 8.84913649300006, 5.733530958000074 ],
							[ 8.845680212000048, 5.728961925000021 ],
							[ 8.842214351000052, 5.723245118000023 ],
							[ 8.836485046000064, 5.719840096000041 ],
							[ 8.834195388000069, 5.718708015000061 ],
							[ 8.828491106000058, 5.718747199000063 ],
							[ 8.825068533000035, 5.718771022000055 ],
							[ 8.821636948000048, 5.717647054000054 ],
							[ 8.818205940000041, 5.716522220000058 ],
							[ 8.815915787000051, 5.71539017200007 ],
							[ 8.814758065000035, 5.713100910000037 ],
							[ 8.813600357000041, 5.710813052000049 ],
							[ 8.813575047000029, 5.707368866000024 ],
							[ 8.814689429000055, 5.703917056000023 ],
							[ 8.815813908000052, 5.701612983000075 ],
							[ 8.818035810000026, 5.69356106500004 ],
							[ 8.819690663000074, 5.69229858500006 ],
							[ 8.826324705000047, 5.690639048000037 ],
							[ 8.843044256000042, 5.680589456000064 ],
							[ 8.846390369000062, 5.673886203000052 ],
							[ 8.851354644000025, 5.654006512000024 ],
							[ 8.859484004000024, 5.648203809000051 ],
							[ 8.867505850000043, 5.641345003000026 ],
							[ 8.872093005000067, 5.636767866000071 ],
							[ 8.877836980000041, 5.62874800700007 ],
							[ 8.885864047000041, 5.620738945000028 ],
							[ 8.891619430000048, 5.610421146000022 ],
							[ 8.895080463000056, 5.602390713000034 ],
							[ 8.896264105000057, 5.593201123000028 ],
							[ 8.896301308000034, 5.585154998000064 ],
							[ 8.895191025000031, 5.57825428700005 ],
							[ 8.892944097000054, 5.570197162000056 ],
							[ 8.890692973000057, 5.563291022000044 ],
							[ 8.887305329000071, 5.555229145000055 ],
							[ 8.886195287000021, 5.548326991000067 ],
							[ 8.88279644000005, 5.542563921000067 ],
							[ 8.878263415000049, 5.535646921000023 ],
							[ 8.873732656000072, 5.52757973100006 ],
							[ 8.871481029000051, 5.520672739000076 ],
							[ 8.868083239000043, 5.514911232000031 ],
							[ 8.864683858000035, 5.509148135000032 ],
							[ 8.862427580000031, 5.503389827000035 ],
							[ 8.860165858000073, 5.498782191000032 ],
							[ 8.859065831000066, 5.489582066000025 ],
							[ 8.85797690000004, 5.478083077000065 ],
							[ 8.858008285000039, 5.471186206000027 ],
							[ 8.855761699000027, 5.463129953000021 ],
							[ 8.853515766000044, 5.455073851000066 ],
							[ 8.85012271100004, 5.448162010000033 ],
							[ 8.846739730000024, 5.438950999000042 ],
							[ 8.845629693000035, 5.432048842000029 ],
							[ 8.844509128000027, 5.427445968000029 ],
							[ 8.843399150000039, 5.42054421000006 ],
							[ 8.843446924000034, 5.410200164000059 ],
							[ 8.842347027000073, 5.401000090000025 ],
							[ 8.841247739000039, 5.391798900000026 ],
							[ 8.839001433000021, 5.383742819000076 ],
							[ 8.837886128000036, 5.377989762000027 ],
							[ 8.835634392000031, 5.371082812000054 ],
							[ 8.832241322000073, 5.364171934000069 ],
							[ 8.827717028000052, 5.354955248000067 ],
							[ 8.825460596000028, 5.34919779300003 ],
							[ 8.822061717000054, 5.343434841000033 ],
							[ 8.818684364000035, 5.333075085000075 ],
							[ 8.816438912000024, 5.325017849000062 ],
							[ 8.813056247000077, 5.315806913000074 ],
							[ 8.809673161000035, 5.306595813000058 ],
							[ 8.807432065000057, 5.297389931000055 ],
							[ 8.806322049000073, 5.29048916000005 ],
							[ 8.806385044000024, 5.276696192000031 ],
							[ 8.807563071000061, 5.26865581800007 ],
							[ 8.808741620000035, 5.260614865000036 ],
							[ 8.811071389000062, 5.250280865000036 ],
							[ 8.812254890000077, 5.241090736000046 ],
							[ 8.813433801000031, 5.233049909000044 ],
							[ 8.812334058000033, 5.22384984100006 ],
							[ 8.811234352000042, 5.214650192000022 ],
							[ 8.810170949000053, 5.19740291100004 ],
							[ 8.807931057000076, 5.188198130000046 ],
							[ 8.805710601000044, 5.174394148000033 ],
							[ 8.803474380000068, 5.164039115000037 ],
							[ 8.801238855000065, 5.153684139000063 ],
							[ 8.797850746000051, 5.145623229000023 ],
							[ 8.79446335800003, 5.137560803000042 ],
							[ 8.791065224000022, 5.131797801000062 ],
							[ 8.779702075000046, 5.120252134000054 ],
							[ 8.771742971000037, 5.113317995000045 ],
							[ 8.763783594000074, 5.106386146000034 ],
							[ 8.755829799000026, 5.098302845000035 ],
							[ 8.744842259000052, 5.091834040000037 ],
							[ 8.736575127000037, 5.082294459000025 ],
							[ 8.730532446000041, 5.076888565000047 ],
							[ 8.726717124000061, 5.074344647000032 ],
							[ 8.725127216000033, 5.065758691000042 ],
							[ 8.720993101000033, 5.059398677000047 ],
							[ 8.71998773200005, 5.053365242000041 ],
							[ 8.723536433000049, 5.045724854000071 ],
							[ 8.724808764000045, 5.043180920000054 ],
							[ 8.723219082000071, 5.041273133000061 ],
							[ 8.718448762000037, 5.038093050000043 ],
							[ 8.718131086000028, 5.032687207000038 ],
							[ 8.715904976000047, 5.02887103200004 ],
							[ 8.714632959000028, 5.031732999000042 ],
							[ 8.712089647000028, 5.031414993000055 ],
							[ 8.707319174000077, 5.020921245000068 ],
							[ 8.704457155000057, 5.019649516000072 ],
							[ 8.700323317000027, 5.016787494000027 ],
							[ 8.696188707000033, 5.014243638000039 ],
							[ 8.69555401100007, 5.012335300000075 ],
							[ 8.693963332000067, 5.007247430000064 ],
							[ 8.689511316000051, 5.005021553000063 ],
							[ 8.685059580000029, 5.000569861000031 ],
							[ 8.68537707400003, 4.998343929000043 ],
							[ 8.689511275000029, 4.997071772000027 ],
							[ 8.693478758000026, 4.995327 ],
							[ 8.697143362000077, 4.990394126000069 ],
							[ 8.698097233000055, 4.987214111000071 ],
							[ 8.696188671000073, 4.984988226000041 ],
							[ 8.69300927300003, 4.986259930000074 ],
							[ 8.689830032000032, 4.985305807000032 ],
							[ 8.687285537000037, 4.981490162000057 ],
							[ 8.685377209000023, 4.983716005000076 ],
							[ 8.681243901000073, 4.980217933000063 ],
							[ 8.680289233000053, 4.976402273000076 ],
							[ 8.679018136000025, 4.971632027000055 ],
							[ 8.66693400500003, 4.963682197000026 ],
							[ 8.66629791400004, 4.958594346000041 ],
							[ 8.668523782000022, 4.954778678000025 ],
							[ 8.668842194000035, 4.952234769000029 ],
							[ 8.660256100000026, 4.944602472000042 ],
							[ 8.652941584000075, 4.945874674000038 ],
							[ 8.649444606000031, 4.942376619000072 ],
							[ 8.648489707000067, 4.935698976000026 ],
							[ 8.650079490000053, 4.931882879000057 ],
							[ 8.653260240000066, 4.925840857000026 ],
							[ 8.654213886000036, 4.92075300700003 ],
							[ 8.652306389000046, 4.915665121000075 ],
							[ 8.64721787700006, 4.910577271000022 ],
							[ 8.639123055000027, 4.906961885000044 ],
							[ 8.631636497000045, 4.907715277000023 ],
							[ 8.624640548000059, 4.907715364000069 ],
							[ 8.618916340000055, 4.903263573000061 ],
							[ 8.614464856000041, 4.896267410000064 ],
							[ 8.609376676000068, 4.88895366600002 ],
							[ 8.604671907000068, 4.879857941000068 ],
							[ 8.604712112000072, 4.87802689800003 ],
							[ 8.606230580000044, 4.872195934000047 ],
							[ 8.609463216000051, 4.866751244000056 ],
							[ 8.612692457000037, 4.86390091800007 ],
							[ 8.619407623000029, 4.860535286000072 ],
							[ 8.622766356000056, 4.857425557000056 ],
							[ 8.630650904000049, 4.846277899000029 ],
							[ 8.63207269000003, 4.843814690000045 ],
							[ 8.632203060000052, 4.842777063000028 ],
							[ 8.63039632300007, 4.842775235000033 ],
							[ 8.62963757700004, 4.842951074000041 ],
							[ 8.626642333000063, 4.842695033000041 ],
							[ 8.627413782000076, 4.841091660000075 ],
							[ 8.627415602000042, 4.840403545000072 ],
							[ 8.624105524000072, 4.842324280000071 ],
							[ 8.621054087000061, 4.842607110000074 ],
							[ 8.625214542000037, 4.838336437000066 ],
							[ 8.626458089000039, 4.836412394000035 ],
							[ 8.627426223000043, 4.834350088000065 ],
							[ 8.62784189000007, 4.832562465000024 ],
							[ 8.627844976000063, 4.831186789000071 ],
							[ 8.628121189000069, 4.830499157000077 ],
							[ 8.628128036000021, 4.826509973000043 ],
							[ 8.626895175000072, 4.822930855000038 ],
							[ 8.625519657000041, 4.821415438000031 ],
							[ 8.624143466000021, 4.820588133000058 ],
							[ 8.621665179000047, 4.820309191000035 ],
							[ 8.618770665000056, 4.820716860000061 ],
							[ 8.617393349000054, 4.820439318000069 ],
							[ 8.615879826000025, 4.819749312000056 ],
							[ 8.613952755000071, 4.818782781000039 ],
							[ 8.613263957000072, 4.818781848000071 ],
							[ 8.611750611000048, 4.81795455300005 ],
							[ 8.610098921000031, 4.817126271000063 ],
							[ 8.608723513000029, 4.815610439000068 ],
							[ 8.604458058000034, 4.81299020900002 ],
							[ 8.603495358000032, 4.812025576000053 ],
							[ 8.599230985000077, 4.808991916000025 ],
							[ 8.597853727000029, 4.808714856000051 ],
							[ 8.597027642000057, 4.808300517000021 ],
							[ 8.593309413000043, 4.80774446700002 ],
							[ 8.592207836000057, 4.807330161000039 ],
							[ 8.591243808000058, 4.807603828000026 ],
							[ 8.58862378200007, 4.80897520700006 ],
							[ 8.584346754000023, 4.812819943000022 ],
							[ 8.582692173000055, 4.81364246000004 ],
							[ 8.57979371700003, 4.816526909000061 ],
							[ 8.578552220000063, 4.817488210000022 ],
							[ 8.57662296500007, 4.818035115000043 ],
							[ 8.575105535000034, 4.818858109000075 ],
							[ 8.573865703000024, 4.818856236000045 ],
							[ 8.572350418000042, 4.819266276000064 ],
							[ 8.569044353000038, 4.819122776000029 ],
							[ 8.567528509000056, 4.819533851000074 ],
							[ 8.564359472000035, 4.819528114000036 ],
							[ 8.561881910000068, 4.818698902000051 ],
							[ 8.560780317000024, 4.818284058000074 ],
							[ 8.55940434300004, 4.817868675000057 ],
							[ 8.558921823000048, 4.81793691200005 ],
							[ 8.556927962000032, 4.816076289000023 ],
							[ 8.555277751000062, 4.814147957000046 ],
							[ 8.554452991000062, 4.813595752000026 ],
							[ 8.553901727000039, 4.813320182000041 ],
							[ 8.552937418000056, 4.813318223000067 ],
							[ 8.551974349000034, 4.812766546000034 ],
							[ 8.55142332500003, 4.812765590000026 ],
							[ 8.550735595000049, 4.812351724000052 ],
							[ 8.549495570000033, 4.812074168000038 ],
							[ 8.547707765000041, 4.81097124300004 ],
							[ 8.546607798000025, 4.809730968000054 ],
							[ 8.545646829000077, 4.807940443000064 ],
							[ 8.543998008000074, 4.805874308000057 ],
							[ 8.541109195000047, 4.80353115500003 ],
							[ 8.540422200000023, 4.802566554000066 ],
							[ 8.538497747000065, 4.800637743000038 ],
							[ 8.536438901000054, 4.796506903000022 ],
							[ 8.533563816000026, 4.787421723000023 ],
							[ 8.532669824000038, 4.786113292000039 ],
							[ 8.532740497000077, 4.78535701800007 ],
							[ 8.531916506000073, 4.783842581000044 ],
							[ 8.530821549000052, 4.780539039000075 ],
							[ 8.529723356000034, 4.778473415000065 ],
							[ 8.528761118000034, 4.777370886000028 ],
							[ 8.527113922000069, 4.774204241000064 ],
							[ 8.525464803000034, 4.772137667000038 ],
							[ 8.524505869000052, 4.769797295000046 ],
							[ 8.523695142000065, 4.761128867000025 ],
							[ 8.523704526000074, 4.756038689000036 ],
							[ 8.523433411000042, 4.753974885000048 ],
							[ 8.522886467000035, 4.752185337000071 ],
							[ 8.521513106000043, 4.749981907000063 ],
							[ 8.520690135000052, 4.747779326000057 ],
							[ 8.520142270000065, 4.745852427000045 ],
							[ 8.52000802200007, 4.744476290000023 ],
							[ 8.520296913000038, 4.737185478000072 ],
							[ 8.520989365000048, 4.73512314200002 ],
							[ 8.521268098000064, 4.733472800000072 ],
							[ 8.521682916000032, 4.732648378000022 ],
							[ 8.52168363100003, 4.732097588000045 ],
							[ 8.522788880000064, 4.730311408000034 ],
							[ 8.523204797000062, 4.729073967000033 ],
							[ 8.526105684000072, 4.724538830000029 ],
							[ 8.527488898000058, 4.721652080000069 ],
							[ 8.529835939000066, 4.718904953000049 ],
							[ 8.53066437800004, 4.717255590000036 ],
							[ 8.531635435000055, 4.713955402000067 ],
							[ 8.531638279000049, 4.712166775000071 ],
							[ 8.531366628000058, 4.710515494000049 ],
							[ 8.53054333700004, 4.708588124000073 ],
							[ 8.529718455000022, 4.707898645000057 ],
							[ 8.527102347000039, 4.707481906000055 ],
							[ 8.524073616000067, 4.706650717000059 ],
							[ 8.520083591000059, 4.704167826000059 ],
							[ 8.517192833000024, 4.703612306000025 ],
							[ 8.514712468000027, 4.704296576000047 ],
							[ 8.510717571000043, 4.704702400000031 ],
							[ 8.50851169200007, 4.705661318000068 ],
							[ 8.507683730000053, 4.706485240000063 ],
							[ 8.50464984000007, 4.708268628000042 ],
							[ 8.501478042000031, 4.710601798000027 ],
							[ 8.501337141000022, 4.712527712000053 ],
							[ 8.501609975000065, 4.713628799000048 ],
							[ 8.501195692000067, 4.714040752000074 ],
							[ 8.500507278000043, 4.713901982000039 ],
							[ 8.499958272000072, 4.713350787000024 ],
							[ 8.499546783000028, 4.712111959000026 ],
							[ 8.499552028000039, 4.70949794400002 ],
							[ 8.500932574000046, 4.70784948000005 ],
							[ 8.502035811000042, 4.707163351000077 ],
							[ 8.503552189000061, 4.706478078000032 ],
							[ 8.506307477000064, 4.705795258000023 ],
							[ 8.50727353000002, 4.704696204000072 ],
							[ 8.507965809000041, 4.702771662000032 ],
							[ 8.508390420000069, 4.697131614000057 ],
							[ 8.50894624700004, 4.694243445000041 ],
							[ 8.508122376000074, 4.693279243000063 ],
							[ 8.507158196000034, 4.69300269200005 ],
							[ 8.504404317000024, 4.693135247000043 ],
							[ 8.501509746000067, 4.693818066000063 ],
							[ 8.49834271800006, 4.693537211000034 ],
							[ 8.492835920000061, 4.692289822000021 ],
							[ 8.488983212000051, 4.690769647000025 ],
							[ 8.486366129000032, 4.690352466000036 ],
							[ 8.483474952000051, 4.690209828000036 ],
							[ 8.483061640000074, 4.689933790000055 ],
							[ 8.480859603000056, 4.689379662000022 ],
							[ 8.479895620000036, 4.689377787000069 ],
							[ 8.479204968000033, 4.690064921000044 ],
							[ 8.477961523000033, 4.692125817000033 ],
							[ 8.476167589000056, 4.693911096000022 ],
							[ 8.475202808000063, 4.694459925000046 ],
							[ 8.472446244000025, 4.695280559000025 ],
							[ 8.469002447000037, 4.69554947000006 ],
							[ 8.467900524000072, 4.696097859000076 ],
							[ 8.465143913000077, 4.696918511000035 ],
							[ 8.463766022000073, 4.697053406000066 ],
							[ 8.461698537000075, 4.697875046000036 ],
							[ 8.457979086000023, 4.698281273000021 ],
							[ 8.455497632000061, 4.699652684000057 ],
							[ 8.455632194000032, 4.70102836600006 ],
							[ 8.458377579000057, 4.705573069000025 ],
							[ 8.460019253000041, 4.711491597000077 ],
							[ 8.460568533000071, 4.712593051000056 ],
							[ 8.46138856500005, 4.715346337000028 ],
							[ 8.462896555000043, 4.719338398000048 ],
							[ 8.463031553000064, 4.720438959000035 ],
							[ 8.463990091000028, 4.723467338000034 ],
							[ 8.465774254000053, 4.726222030000031 ],
							[ 8.467012553000075, 4.727462310000021 ],
							[ 8.468938587000025, 4.728153669000051 ],
							[ 8.470729582000047, 4.72815655200003 ],
							[ 8.471556925000073, 4.727470412000059 ],
							[ 8.471833154000024, 4.726920594000035 ],
							[ 8.47183526300006, 4.726232978000041 ],
							[ 8.472526803000051, 4.724996068000053 ],
							[ 8.473216212000068, 4.724446773000068 ],
							[ 8.474594238000066, 4.723898897000026 ],
							[ 8.474730217000058, 4.72472478100002 ],
							[ 8.473349454000072, 4.726785665000023 ],
							[ 8.473347523000029, 4.727748900000051 ],
							[ 8.476239173000067, 4.728166570000042 ],
							[ 8.479407077000076, 4.727622022000048 ],
							[ 8.481198070000062, 4.727625354000054 ],
							[ 8.482988105000061, 4.727903369000046 ],
							[ 8.484916829000042, 4.727906730000029 ],
							[ 8.486155224000072, 4.728322055000035 ],
							[ 8.488357308000047, 4.72942636700003 ],
							[ 8.489731729000027, 4.730667131000075 ],
							[ 8.491654233000077, 4.733559143000036 ],
							[ 8.491928347000055, 4.734522836000053 ],
							[ 8.493026597000039, 4.736312848000068 ],
							[ 8.497836963000054, 4.741686784000024 ],
							[ 8.50045084900006, 4.743067263000057 ],
							[ 8.501965723000069, 4.743619906000049 ],
							[ 8.504169242000046, 4.743761091000067 ],
							[ 8.505270715000051, 4.744038132000071 ],
							[ 8.506371270000045, 4.744590250000044 ],
							[ 8.507333539000058, 4.745555420000073 ],
							[ 8.508018503000073, 4.747482319000028 ],
							[ 8.507873575000076, 4.751609300000041 ],
							[ 8.50814719300007, 4.752160091000064 ],
							[ 8.508145335000052, 4.753123326000036 ],
							[ 8.508420263000062, 4.753949162000026 ],
							[ 8.50800539200003, 4.754773641000043 ],
							[ 8.507724927000027, 4.757249361000049 ],
							[ 8.507031605000066, 4.75944899600006 ],
							[ 8.506202964000067, 4.760686359000033 ],
							[ 8.503166212000053, 4.763844985000048 ],
							[ 8.50027015400002, 4.766040820000057 ],
							[ 8.498199552000074, 4.768100700000048 ],
							[ 8.497369540000022, 4.769888406000064 ],
							[ 8.496955760000048, 4.770300410000061 ],
							[ 8.496405364000054, 4.76988650100003 ],
							[ 8.496406669000066, 4.769336211000052 ],
							[ 8.497238193000044, 4.766861466000023 ],
							[ 8.499859538000067, 4.764114857000038 ],
							[ 8.50123861700007, 4.763291356000025 ],
							[ 8.502895208000041, 4.761780740000063 ],
							[ 8.503862157000071, 4.76013183200007 ],
							[ 8.504690452000034, 4.759170500000039 ],
							[ 8.505797175000055, 4.756833528000072 ],
							[ 8.506214082000042, 4.754908064000062 ],
							[ 8.505664621000051, 4.753806606000069 ],
							[ 8.505670562000034, 4.751055250000036 ],
							[ 8.505122955000047, 4.74899057500005 ],
							[ 8.503750096000033, 4.746924906000061 ],
							[ 8.502787648000037, 4.746097554000073 ],
							[ 8.497830409000073, 4.745263606000037 ],
							[ 8.496729114000061, 4.74526166000004 ],
							[ 8.49480124400003, 4.744570225000075 ],
							[ 8.492462076000038, 4.743328082000062 ],
							[ 8.490538755000046, 4.740985848000037 ],
							[ 8.489990485000021, 4.739471922000064 ],
							[ 8.487931972000069, 4.735341078000033 ],
							[ 8.487381998000046, 4.73506498200004 ],
							[ 8.48504557800004, 4.732722286000069 ],
							[ 8.483944713000028, 4.732032331000028 ],
							[ 8.482843484000057, 4.731617954000058 ],
							[ 8.481741659000022, 4.731615555000076 ],
							[ 8.480638505000059, 4.73216390500005 ],
							[ 8.479810615000076, 4.732850566000025 ],
							[ 8.478431816000068, 4.73353580600002 ],
							[ 8.475538180000058, 4.734080797000047 ],
							[ 8.474020851000034, 4.735316243000057 ],
							[ 8.472361680000063, 4.73861505800005 ],
							[ 8.471947908000061, 4.739027479000072 ],
							[ 8.471532023000066, 4.740127088000065 ],
							[ 8.471118230000059, 4.740539082000055 ],
							[ 8.470841595000024, 4.741364017000024 ],
							[ 8.469875320000028, 4.742462602000046 ],
							[ 8.469322262000048, 4.743562233000034 ],
							[ 8.468078604000027, 4.74521114700002 ],
							[ 8.466698723000036, 4.746583960000066 ],
							[ 8.466007514000069, 4.747958686000061 ],
							[ 8.465041224000061, 4.749057779000054 ],
							[ 8.464902688000052, 4.749607996000066 ],
							[ 8.46421224900007, 4.750431521000053 ],
							[ 8.461590768000065, 4.752765650000072 ],
							[ 8.461175019000052, 4.753728403000025 ],
							[ 8.461170072000073, 4.756341433000046 ],
							[ 8.461994130000051, 4.757581691000041 ],
							[ 8.46391871000003, 4.759373637000067 ],
							[ 8.464467902000024, 4.760199984000053 ],
							[ 8.464466302000062, 4.761300546000029 ],
							[ 8.464740967000068, 4.761851315000058 ],
							[ 8.466115230000071, 4.763229365000029 ],
							[ 8.466803525000046, 4.763505473000066 ],
							[ 8.467765740000061, 4.76447059700007 ],
							[ 8.468451525000035, 4.765709879000042 ],
							[ 8.469136496000033, 4.767912358000046 ],
							[ 8.469548364000048, 4.768463601000065 ],
							[ 8.469684350000023, 4.769289060000062 ],
							[ 8.470096593000051, 4.769977556000072 ],
							[ 8.470778308000035, 4.773280128000067 ],
							[ 8.470776353000076, 4.774243343000023 ],
							[ 8.471461158000068, 4.776582681000036 ],
							[ 8.47173235200006, 4.778785202000051 ],
							[ 8.472554349000063, 4.781125088000067 ],
							[ 8.473103415000026, 4.781676322000067 ],
							[ 8.475032616000021, 4.781542316000071 ],
							[ 8.477376997000022, 4.780033103000051 ],
							[ 8.478616893000037, 4.779897255000037 ],
							[ 8.479856416000075, 4.780037428000071 ],
							[ 8.480957204000049, 4.780865240000026 ],
							[ 8.481782720000069, 4.781141718000072 ],
							[ 8.484670787000027, 4.783622781000076 ],
							[ 8.484807215000046, 4.784173468000063 ],
							[ 8.483428792000041, 4.784446264000053 ],
							[ 8.481228681000061, 4.782516481000073 ],
							[ 8.48040278600007, 4.782101638000029 ],
							[ 8.477233861000059, 4.782646618000058 ],
							[ 8.475303479000047, 4.783606506000069 ],
							[ 8.47392657000006, 4.783603669000058 ],
							[ 8.471861560000036, 4.782774940000024 ],
							[ 8.471174389000055, 4.782085399000039 ],
							[ 8.470213702000024, 4.780020203000049 ],
							[ 8.469257255000059, 4.776579404000074 ],
							[ 8.467883950000044, 4.774100803000067 ],
							[ 8.467889025000034, 4.771761911000056 ],
							[ 8.46720502200003, 4.769284240000047 ],
							[ 8.465968105000059, 4.767768845000035 ],
							[ 8.464318338000055, 4.766390315000024 ],
							[ 8.461982871000032, 4.763359560000026 ],
							[ 8.460610235000047, 4.760880432000022 ],
							[ 8.460611242000027, 4.760192888000063 ],
							[ 8.459788561000039, 4.758402837000062 ],
							[ 8.45827700500007, 4.756749163000052 ],
							[ 8.457865018000064, 4.755923307000046 ],
							[ 8.457044351000036, 4.75317001600007 ],
							[ 8.456092713000032, 4.74697782100003 ],
							[ 8.455133219000061, 4.744224540000062 ],
							[ 8.453070455000045, 4.74243261700002 ],
							[ 8.451282725000056, 4.741328716000055 ],
							[ 8.450733386000024, 4.740639695000027 ],
							[ 8.449772922000022, 4.738574517000075 ],
							[ 8.448673294000059, 4.737472068000045 ],
							[ 8.446885030000033, 4.736368163000066 ],
							[ 8.445507951000025, 4.736228446000041 ],
							[ 8.444542670000033, 4.736638995000021 ],
							[ 8.442477292000035, 4.73663569200005 ],
							[ 8.441376858000069, 4.736083030000032 ],
							[ 8.439585691000048, 4.735804605000055 ],
							[ 8.437934066000025, 4.735801709000043 ],
							[ 8.437382454000044, 4.735525110000026 ],
							[ 8.435733570000025, 4.733734154000047 ],
							[ 8.434772364000025, 4.73221920800006 ],
							[ 8.433122892000029, 4.730840183000055 ],
							[ 8.430232760000024, 4.729459288000044 ],
							[ 8.428305436000073, 4.728905218000023 ],
							[ 8.425828863000049, 4.727662560000056 ],
							[ 8.423625919000074, 4.727245784000047 ],
							[ 8.421009265000066, 4.727516180000066 ],
							[ 8.418390397000053, 4.728474599000037 ],
							[ 8.414808520000065, 4.728742629000067 ],
							[ 8.412878277000061, 4.72970198400003 ],
							[ 8.411083072000054, 4.731624618000069 ],
							[ 8.410944240000049, 4.732312662000027 ],
							[ 8.410115982000036, 4.733136158000036 ],
							[ 8.40942491800007, 4.734648272000072 ],
							[ 8.408173711000074, 4.740148532000035 ],
							[ 8.406655465000028, 4.741796494000027 ],
							[ 8.406104103000075, 4.742070695000052 ],
							[ 8.404447535000031, 4.743718645000058 ],
							[ 8.403757879000068, 4.74399276500003 ],
							[ 8.40279219100006, 4.744953643000031 ],
							[ 8.402104532000067, 4.744677046000049 ],
							[ 8.402105425000059, 4.744126755000025 ],
							[ 8.402520192000054, 4.743164568000054 ],
							[ 8.404175751000025, 4.741792244000067 ],
							[ 8.406521071000043, 4.740420814000061 ],
							[ 8.40707298500007, 4.739459049000061 ],
							[ 8.407491496000034, 4.736845986000048 ],
							[ 8.407494280000037, 4.735470307000071 ],
							[ 8.406945031000021, 4.734781294000072 ],
							[ 8.404194044000064, 4.733262517000071 ],
							[ 8.403643458000033, 4.732711324000036 ],
							[ 8.402680601000043, 4.732296949000045 ],
							[ 8.401716299000043, 4.732432843000026 ],
							[ 8.400058858000023, 4.734631064000041 ],
							[ 8.395094889000063, 4.737097272000028 ],
							[ 8.393990231000032, 4.738471028000049 ],
							[ 8.391230428000028, 4.74094197200003 ],
							[ 8.390953841000055, 4.741629648000071 ],
							[ 8.389987001000065, 4.74259089800006 ],
							[ 8.388742290000039, 4.745339882000053 ],
							[ 8.388052259000062, 4.746163839000076 ],
							[ 8.387636346000022, 4.747125674000074 ],
							[ 8.385980566000057, 4.748910868000053 ],
							[ 8.385978526000031, 4.749461156000052 ],
							[ 8.385425529000031, 4.750698561000036 ],
							[ 8.38542392200003, 4.751661296000066 ],
							[ 8.386246914000026, 4.753038848000074 ],
							[ 8.388583959000073, 4.755382049000048 ],
							[ 8.389269689000059, 4.756484042000068 ],
							[ 8.391057293000074, 4.758276014000046 ],
							[ 8.392570714000044, 4.758966455000063 ],
							[ 8.394770654000069, 4.76062104600004 ],
							[ 8.396007705000045, 4.761861788000033 ],
							[ 8.396556073000056, 4.763101088000042 ],
							[ 8.397518900000023, 4.763927943000056 ],
							[ 8.398619905000032, 4.764480609000032 ],
							[ 8.401923968000062, 4.765174870000067 ],
							[ 8.405917186000067, 4.765594996000061 ],
							[ 8.407431660000043, 4.766010759000039 ],
							[ 8.411012675000052, 4.766154318000076 ],
							[ 8.412664662000054, 4.766708332000064 ],
							[ 8.414037872000051, 4.768636720000075 ],
							[ 8.414034912000034, 4.770149743000047 ],
							[ 8.413482855000041, 4.77083682600005 ],
							[ 8.41251778000003, 4.771385211000052 ],
							[ 8.411687654000048, 4.772622134000073 ],
							[ 8.410031324000045, 4.774406954000028 ],
							[ 8.410029228000042, 4.775370095000028 ],
							[ 8.41030382200006, 4.775920831000064 ],
							[ 8.411266494000074, 4.776885476000075 ],
							[ 8.412091950000047, 4.777162056000066 ],
							[ 8.414020377000043, 4.777166366000074 ],
							[ 8.414710086000071, 4.776892152000073 ],
							[ 8.416228105000073, 4.775106460000075 ],
							[ 8.41650603000005, 4.774281515000041 ],
							[ 8.417471959000068, 4.773182848000033 ],
							[ 8.419127517000049, 4.772222958000043 ],
							[ 8.419954190000055, 4.772086651000052 ],
							[ 8.420642977000057, 4.771675579000032 ],
							[ 8.421745258000044, 4.771815305000075 ],
							[ 8.423945210000056, 4.773607695000067 ],
							[ 8.425596268000049, 4.77443598900004 ],
							[ 8.427249830000051, 4.774026849000052 ],
							[ 8.428355255000042, 4.77251528000005 ],
							[ 8.429598824000038, 4.770729526000025 ],
							[ 8.430977726000037, 4.769768724000073 ],
							[ 8.432080284000051, 4.769358146000059 ],
							[ 8.433733964000055, 4.769223651000061 ],
							[ 8.435797477000051, 4.770190719000027 ],
							[ 8.436622666000062, 4.771017034000067 ],
							[ 8.436620621000031, 4.77198026800005 ],
							[ 8.43620493700007, 4.773217684000031 ],
							[ 8.435100535000061, 4.774454087000038 ],
							[ 8.435237016000031, 4.775279559000069 ],
							[ 8.436062907000064, 4.775280968000061 ],
							[ 8.437168299000064, 4.77376987100007 ],
							[ 8.438685492000047, 4.772809049000045 ],
							[ 8.440062361000059, 4.772811889000025 ],
							[ 8.44116402800006, 4.773364076000064 ],
							[ 8.440887337000049, 4.773776523000038 ],
							[ 8.43950852200004, 4.77432390000007 ],
							[ 8.437715754000067, 4.775696746000051 ],
							[ 8.437850278000042, 4.777072431000022 ],
							[ 8.437435222000033, 4.777896903000055 ],
							[ 8.43506603700007, 4.780080809000026 ],
							[ 8.434535940000046, 4.781132232000061 ],
							[ 8.434534007000025, 4.78201916200004 ],
							[ 8.436737310000069, 4.782435871000075 ],
							[ 8.437288441000021, 4.782711470000038 ],
							[ 8.437700278000023, 4.783262749000073 ],
							[ 8.437696423000034, 4.78505136900003 ],
							[ 8.437419031000047, 4.786288703000025 ],
							[ 8.437411318000045, 4.789866 ],
							[ 8.437134, 4.790690407000056 ],
							[ 8.436307232000047, 4.791239272000041 ],
							[ 8.434378730000049, 4.791236413000036 ],
							[ 8.432588848000023, 4.79068230200005 ],
							[ 8.430109017000063, 4.790540235000037 ],
							[ 8.429283926000039, 4.789988523000034 ],
							[ 8.429284992000021, 4.789300462000028 ],
							[ 8.430663259000028, 4.789165494000031 ],
							[ 8.432589335000046, 4.789994740000054 ],
							[ 8.434931851000044, 4.789861683000026 ],
							[ 8.435622202000047, 4.789174560000049 ],
							[ 8.436729171000025, 4.78628778500007 ],
							[ 8.436731346000045, 4.785600152000029 ],
							[ 8.433565208000061, 4.784631249000029 ],
							[ 8.432602882000026, 4.783390989000054 ],
							[ 8.432605913000032, 4.782153140000048 ],
							[ 8.434434952000061, 4.780263439000066 ],
							[ 8.43495378700004, 4.778855800000031 ],
							[ 8.434955755000033, 4.778305512000031 ],
							[ 8.433718599000031, 4.776652385000034 ],
							[ 8.433445157000051, 4.775688649000074 ],
							[ 8.433446979000053, 4.77486368700005 ],
							[ 8.434275702000036, 4.773764614000072 ],
							[ 8.434552866000047, 4.772664554000073 ],
							[ 8.434418928000071, 4.771288386000037 ],
							[ 8.433593532000032, 4.770599393000055 ],
							[ 8.432904274000066, 4.770597488000021 ],
							[ 8.432077185000026, 4.771008997000024 ],
							[ 8.430282790000035, 4.773068941000076 ],
							[ 8.429728357000045, 4.774581435000073 ],
							[ 8.429175539000028, 4.775405905000071 ],
							[ 8.42834745600004, 4.776092078000033 ],
							[ 8.427244856000073, 4.776502588000028 ],
							[ 8.425179684000057, 4.776224162000062 ],
							[ 8.423804543000074, 4.775120704000074 ],
							[ 8.422704528000054, 4.77388049700005 ],
							[ 8.421604031000072, 4.773328313000036 ],
							[ 8.421053047000044, 4.773327383000037 ],
							[ 8.419535373000031, 4.774562378000041 ],
							[ 8.418570731000045, 4.774836088000029 ],
							[ 8.41788114600007, 4.775384926000072 ],
							[ 8.417329090000067, 4.776071515000069 ],
							[ 8.417051518000051, 4.777034307000065 ],
							[ 8.416085225000074, 4.777995071000021 ],
							[ 8.414981834000059, 4.778543444000036 ],
							[ 8.412778141000047, 4.778401851000069 ],
							[ 8.410851730000047, 4.77784774600002 ],
							[ 8.409612568000057, 4.777157770000031 ],
							[ 8.408924953000053, 4.776468740000041 ],
							[ 8.408651355000075, 4.775642831000027 ],
							[ 8.408655130000056, 4.773991555000066 ],
							[ 8.408931487000075, 4.773441787000024 ],
							[ 8.40989679300003, 4.772756084000036 ],
							[ 8.412382230000048, 4.770009033000065 ],
							[ 8.412521291000076, 4.769184069000062 ],
							[ 8.412109507000025, 4.768633411000053 ],
							[ 8.411422015000028, 4.768219485000031 ],
							[ 8.409770025000057, 4.767665410000063 ],
							[ 8.408117355000059, 4.767524753000032 ],
							[ 8.407429668000077, 4.767248166000059 ],
							[ 8.405363326000042, 4.767382146000045 ],
							[ 8.403983241000049, 4.768342980000057 ],
							[ 8.402880657000026, 4.768753047000075 ],
							[ 8.399016498000037, 4.772597789000031 ],
							[ 8.398873258000037, 4.774936658000058 ],
							[ 8.399420758000076, 4.776725280000051 ],
							[ 8.400519235000047, 4.778516272000047 ],
							[ 8.401203925000061, 4.780305877000046 ],
							[ 8.401890738000077, 4.781132248000063 ],
							[ 8.402164672000026, 4.782096434000039 ],
							[ 8.402850939000075, 4.782922727000027 ],
							[ 8.403398673000027, 4.78457497900007 ],
							[ 8.405045752000035, 4.78787995700003 ],
							[ 8.405181822000031, 4.788567519000026 ],
							[ 8.406416879000062, 4.790771031000077 ],
							[ 8.406827128000032, 4.792284986000027 ],
							[ 8.407924674000071, 4.794350645000065 ],
							[ 8.408745859000021, 4.796966069000064 ],
							[ 8.409015699000065, 4.799443251000071 ],
							[ 8.410926656000072, 4.807976290000056 ],
							[ 8.412843654000028, 4.813482291000071 ],
							[ 8.413797374000069, 4.818711781000047 ],
							[ 8.414201631000026, 4.822977078000065 ],
							[ 8.414193152000053, 4.827241450000031 ],
							[ 8.413914524000063, 4.828479336000044 ],
							[ 8.41376886200004, 4.832743673000039 ],
							[ 8.413074617000063, 4.835081100000025 ],
							[ 8.411525439000059, 4.839308149000033 ],
							[ 8.408162319000041, 4.839255218000062 ],
							[ 8.40411204000003, 4.839191465000056 ],
							[ 8.404390486000068, 4.838091358000042 ],
							[ 8.406050807000042, 4.834380121000038 ],
							[ 8.406189907000055, 4.833554755000023 ],
							[ 8.40771187100006, 4.830530625000051 ],
							[ 8.409100367000065, 4.82516815300005 ],
							[ 8.409387523000021, 4.819666398000038 ],
							[ 8.409256091000032, 4.816776719000075 ],
							[ 8.40898246200004, 4.815950886000053 ],
							[ 8.40815712400007, 4.814848909000034 ],
							[ 8.406644902000039, 4.813608189000036 ],
							[ 8.405133027000034, 4.811816673000067 ],
							[ 8.401702743000044, 4.805482389000076 ],
							[ 8.400881609000066, 4.802866468000047 ],
							[ 8.400472821000051, 4.800802219000047 ],
							[ 8.400481907000028, 4.796262245000037 ],
							[ 8.399938637000048, 4.792546770000058 ],
							[ 8.399951094000073, 4.78663206300007 ],
							[ 8.39830498300006, 4.783464411000068 ],
							[ 8.396243178000077, 4.781121730000052 ],
							[ 8.395555700000045, 4.780707793000033 ],
							[ 8.39500415200007, 4.780706879000036 ],
							[ 8.393628160000048, 4.780153744000074 ],
							[ 8.39307898800007, 4.779740311000069 ],
							[ 8.392664736000029, 4.780014516000051 ],
							[ 8.391700743000058, 4.779874785000061 ],
							[ 8.390324776000057, 4.779322620000073 ],
							[ 8.388809029000072, 4.779319303000023 ],
							[ 8.385781029000043, 4.778213018000031 ],
							[ 8.383852845000035, 4.778071435000072 ],
							[ 8.381376321000062, 4.776691409000023 ],
							[ 8.378622842000027, 4.775860352000052 ],
							[ 8.37352633200004, 4.775850774000048 ],
							[ 8.368702623000047, 4.777079582000056 ],
							[ 8.366772876000027, 4.778176316000042 ],
							[ 8.363598889000059, 4.780921429000045 ],
							[ 8.36263163600006, 4.78202059900002 ],
							[ 8.362216740000065, 4.782982314000037 ],
							[ 8.362212042000067, 4.785046106000038 ],
							[ 8.36413692900004, 4.787113196000064 ],
							[ 8.36413495100004, 4.787938602000054 ],
							[ 8.36289473100004, 4.788211314000023 ],
							[ 8.362482208000074, 4.787797943000044 ],
							[ 8.361933604000058, 4.786421301000075 ],
							[ 8.361384126000075, 4.78587004700006 ],
							[ 8.360145363000072, 4.785316953000063 ],
							[ 8.357667033000041, 4.785312167000029 ],
							[ 8.356287720000068, 4.785721771000055 ],
							[ 8.354081834000056, 4.786818506000031 ],
							[ 8.351598997000053, 4.788739219000036 ],
							[ 8.350495478000028, 4.789288026000065 ],
							[ 8.348839731000055, 4.790522074000023 ],
							[ 8.346495488000073, 4.791618793000055 ],
							[ 8.346356598000057, 4.792581059000042 ],
							[ 8.347043002000021, 4.793270130000053 ],
							[ 8.347731507000049, 4.79340933800006 ],
							[ 8.348281773000053, 4.793822787000067 ],
							[ 8.34801320300005, 4.794357393000041 ],
							[ 8.344762186000025, 4.79504025500006 ],
							[ 8.344255496000073, 4.795412229000021 ],
							[ 8.343681969000045, 4.795750098000042 ],
							[ 8.343209253000055, 4.795986546000051 ],
							[ 8.342399084000022, 4.796595141000068 ],
							[ 8.341334697000036, 4.797565363000047 ],
							[ 8.339039728000046, 4.799994912000045 ],
							[ 8.336693891000039, 4.801916623000068 ],
							[ 8.335314567000069, 4.802601303000074 ],
							[ 8.334485981000057, 4.80342532800006 ],
							[ 8.333932674000039, 4.804387554000073 ],
							[ 8.334066130000053, 4.806450820000066 ],
							[ 8.334612614000037, 4.808653371000048 ],
							[ 8.334468758000071, 4.811404674000073 ],
							[ 8.334191995000026, 4.812091806000069 ],
							[ 8.334321917000068, 4.815806401000032 ],
							[ 8.335279645000071, 4.818834332000051 ],
							[ 8.335413857000049, 4.820485579000035 ],
							[ 8.336645002000068, 4.824064703000033 ],
							[ 8.337470336000024, 4.824754248000033 ],
							[ 8.338846145000048, 4.825445160000072 ],
							[ 8.340637366000067, 4.825585807000039 ],
							[ 8.341051894000032, 4.824899216000063 ],
							[ 8.340925427000059, 4.819533833000037 ],
							[ 8.341342222000037, 4.817883455000072 ],
							[ 8.342032246000031, 4.817197287000056 ],
							[ 8.343686811000055, 4.816375233000031 ],
							[ 8.346029527000042, 4.816242246000058 ],
							[ 8.34768084600006, 4.81693317600002 ],
							[ 8.348368666000056, 4.817484845000024 ],
							[ 8.350292036000042, 4.819827103000023 ],
							[ 8.351252397000053, 4.821754915000042 ],
							[ 8.352482973000065, 4.825747002000071 ],
							[ 8.353032103000032, 4.826848485000028 ],
							[ 8.352889103000052, 4.829186925000045 ],
							[ 8.354534062000027, 4.83290484500003 ],
							[ 8.355221923000045, 4.833456057000035 ],
							[ 8.356322188000036, 4.833870872000034 ],
							[ 8.360179140000071, 4.834154115000047 ],
							[ 8.360867488000054, 4.834430718000021 ],
							[ 8.363209028000028, 4.834710137000059 ],
							[ 8.364859654000043, 4.835538407000058 ],
							[ 8.365821790000041, 4.836503522000044 ],
							[ 8.366096381000034, 4.837054715000022 ],
							[ 8.366370166000024, 4.837742809000076 ],
							[ 8.366368702000045, 4.838593976000027 ],
							[ 8.364439446000063, 4.838563504000035 ],
							[ 8.363480665000054, 4.836636564000059 ],
							[ 8.362104197000065, 4.835946040000067 ],
							[ 8.360864769000045, 4.835668066000039 ],
							[ 8.357282618000056, 4.835798741000076 ],
							[ 8.355631045000052, 4.835520726000027 ],
							[ 8.354254595000043, 4.834830302000057 ],
							[ 8.353292494000073, 4.833865157000048 ],
							[ 8.352743111000052, 4.832901039000035 ],
							[ 8.352056622000021, 4.832211980000068 ],
							[ 8.351233755000067, 4.830421891000071 ],
							[ 8.350687224000069, 4.828495020000048 ],
							[ 8.350007062000032, 4.824228736000066 ],
							[ 8.348773932000029, 4.821062598000026 ],
							[ 8.347399809000024, 4.81940893500007 ],
							[ 8.345474258000024, 4.818304548000071 ],
							[ 8.344509139000024, 4.818440437000049 ],
							[ 8.343543101000023, 4.819401286000073 ],
							[ 8.343539097000075, 4.821327177000057 ],
							[ 8.343947650000075, 4.823666532000061 ],
							[ 8.343941806000032, 4.826005419000069 ],
							[ 8.34352679400007, 4.826967278000041 ],
							[ 8.342836201000068, 4.827653873000031 ],
							[ 8.34173392200006, 4.828064458000028 ],
							[ 8.33966747900007, 4.828060665000066 ],
							[ 8.338566925000066, 4.827507953000065 ],
							[ 8.338015308000024, 4.827507025000045 ],
							[ 8.336502222000036, 4.82654096400006 ],
							[ 8.331272867000052, 4.824054255000021 ],
							[ 8.327280173000077, 4.823358493000057 ],
							[ 8.326177822000034, 4.823493950000056 ],
							[ 8.324108834000072, 4.824589775000049 ],
							[ 8.323004454000056, 4.825825689000055 ],
							[ 8.322865676000049, 4.826375945000052 ],
							[ 8.316238334000047, 4.833103683000047 ],
							[ 8.315411784000048, 4.833376897000051 ],
							[ 8.313755739000044, 4.834611895000023 ],
							[ 8.312928887000055, 4.834747332000063 ],
							[ 8.312514258000022, 4.83515884600007 ],
							[ 8.311549949000039, 4.835432069000035 ],
							[ 8.310584313000049, 4.836118214000066 ],
							[ 8.309876210000027, 4.837699850000035 ],
							[ 8.277482270000064, 4.837187106000044 ],
							[ 8.268738650000046, 4.829077246000054 ],
							[ 8.266182910000055, 4.835324752000076 ],
							[ 8.262337439000021, 4.838610641000059 ],
							[ 8.253651610000077, 4.845960589000072 ],
							[ 8.250850876000072, 4.848344338000061 ],
							[ 8.245797402000051, 4.852643992000026 ],
							[ 8.240281275000029, 4.854086426000038 ],
							[ 8.230303849000052, 4.860188493000067 ],
							[ 8.222901529000069, 4.872772238000039 ],
							[ 8.220311347000063, 4.882024761000025 ],
							[ 8.215499875000035, 4.897199132000026 ],
							[ 8.207357647000038, 4.908302289000062 ],
							[ 8.197363919000054, 4.913484126000071 ],
							[ 8.176268386000061, 4.929768538000076 ],
							[ 8.171456115000069, 4.933469795000065 ],
							[ 8.153121180000028, 4.938528079000037 ],
							[ 8.149249969000039, 4.939391588000035 ],
							[ 8.136296166000022, 4.957896692000077 ],
							[ 8.128153576000045, 4.968999882000048 ],
							[ 8.125563354000064, 4.981954055000074 ],
							[ 8.12223270000004, 4.994167358000027 ],
							[ 8.115570168000033, 5.002679834000048 ],
							[ 8.102616245000036, 5.023776013000031 ],
							[ 8.100026138000032, 5.033398607000038 ],
							[ 8.098175186000049, 5.043021676000023 ],
							[ 8.100026091000075, 5.062637312000049 ],
							[ 8.10039609200004, 5.081142905000036 ],
							[ 8.099286070000062, 5.107050438000044 ],
							[ 8.095214979000048, 5.115192414000035 ],
							[ 8.081890905000023, 5.127406085000075 ],
							[ 8.074797606000061, 5.131106878000026 ],
							[ 8.069683288000022, 5.135974906000058 ],
							[ 8.064817521000066, 5.145511089000024 ],
							[ 8.065447886000072, 5.151477623000062 ],
							[ 8.065684375000046, 5.156745126000033 ],
							[ 8.066420097000048, 5.162013999000067 ],
							[ 8.068158019000066, 5.166031328000031 ],
							[ 8.070893642000044, 5.170803678000027 ],
							[ 8.072883432000026, 5.174069229000054 ],
							[ 8.073875508000071, 5.176830642000027 ],
							[ 8.073363072000063, 5.181845508000038 ],
							[ 8.072605081000063, 5.185354869000037 ],
							[ 8.071099189000051, 5.188360672000044 ],
							[ 8.068587935000039, 5.193621234000034 ],
							[ 8.066832717000068, 5.196375695000029 ],
							[ 8.065576714000031, 5.199131296000076 ],
							[ 8.064713719000054, 5.200066309000022 ],
							[ 8.061627526000052, 5.206239255000071 ],
							[ 8.055150103000074, 5.217342435000035 ],
							[ 8.034794792000071, 5.243249978000051 ],
							[ 8.026467429000036, 5.247876208000037 ],
							[ 8.016752377000046, 5.246951104000061 ],
							[ 8.009812468000064, 5.238161079000065 ],
							[ 8.006573901000024, 5.225669864000054 ],
							[ 8.003335028000038, 5.219193045000054 ],
							[ 7.992694983000035, 5.206701739000039 ],
							[ 7.984367476000045, 5.205776190000051 ],
							[ 7.976094038000042, 5.208294437000063 ],
							[ 7.973348246000057, 5.211946019000038 ],
							[ 7.969827719000023, 5.220265383000026 ],
							[ 7.968719280000073, 5.225717990000021 ],
							[ 7.966410436000047, 5.231453884000075 ],
							[ 7.961823244000072, 5.23603102800007 ],
							[ 7.95382214600005, 5.238293210000052 ],
							[ 7.948098077000054, 5.241714085000069 ],
							[ 7.943515527000045, 5.245141975000024 ],
							[ 7.940071207000074, 5.249722984000073 ],
							[ 7.937766776000046, 5.254311096000038 ],
							[ 7.936598925000055, 5.26005310000005 ],
							[ 7.936083823000047, 5.262585597000054 ],
							[ 7.936199227000031, 5.265501019000055 ],
							[ 7.93560314900003, 5.267804113000068 ],
							[ 7.935573953000073, 5.270689018000041 ],
							[ 7.93497166700007, 5.273570971000026 ],
							[ 7.933796837000045, 5.276446881000027 ],
							[ 7.931457782000052, 5.281044886000075 ],
							[ 7.929696845000024, 5.285070916000052 ],
							[ 7.926206362000073, 5.290236906000075 ],
							[ 7.922725342000035, 5.294249076000028 ],
							[ 7.919829803000027, 5.297111077000068 ],
							[ 7.916361069000061, 5.299969261000058 ],
							[ 7.912880998000048, 5.303979913000035 ],
							[ 7.909412146000022, 5.306838011000025 ],
							[ 7.907107087000043, 5.307973927000035 ],
							[ 7.904796919000034, 5.309687137000026 ],
							[ 7.901913014000058, 5.31139521800003 ],
							[ 7.900181071000077, 5.312534796000023 ],
							[ 7.89557138300006, 5.314805906000061 ],
							[ 7.892114203000062, 5.316510211000036 ],
							[ 7.888667826000074, 5.31705898000007 ],
							[ 7.885801965000041, 5.31703611000006 ],
							[ 7.882356126000047, 5.317584970000041 ],
							[ 7.879489155000044, 5.31756204800007 ],
							[ 7.874896954000064, 5.318101944000034 ],
							[ 7.872041397000032, 5.316925019000053 ],
							[ 7.869759009000063, 5.315752977000045 ],
							[ 7.867472060000068, 5.315155971000024 ],
							[ 7.865183987000023, 5.314560927000059 ],
							[ 7.862885050000045, 5.315118816000052 ],
							[ 7.860000886000023, 5.316827695000029 ],
							[ 7.85768988600006, 5.318541048000043 ],
							[ 7.857093765000059, 5.320844142000055 ],
							[ 7.85592391800003, 5.323143046000041 ],
							[ 7.85418085200007, 5.325438018000057 ],
							[ 7.851291961000072, 5.327723024000022 ],
							[ 7.849249826000062, 5.329489712000054 ],
							[ 7.846665075000033, 5.33172604400005 ],
							[ 7.844353925000064, 5.33343788600007 ],
							[ 7.842617055000062, 5.335155907000058 ],
							[ 7.840874421000024, 5.337450964000027 ],
							[ 7.841986271000053, 5.340921895000065 ],
							[ 7.845976836000034, 5.343263087000025 ],
							[ 7.848831962000077, 5.344439917000045 ],
							[ 7.851704891000054, 5.343885904000047 ],
							[ 7.856290578000028, 5.34392404700003 ],
							[ 7.859164067000052, 5.343368996000038 ],
							[ 7.862031235000075, 5.343392911000024 ],
							[ 7.865476221000051, 5.342843962000074 ],
							[ 7.868342838000046, 5.342866011000069 ],
							[ 7.870653954000034, 5.341154166000024 ],
							[ 7.872963908000031, 5.339440876000026 ],
							[ 7.875853882000058, 5.337155850000045 ],
							[ 7.878726158000063, 5.336602179000067 ],
							[ 7.882172185000059, 5.336051947000044 ],
							[ 7.887338353000075, 5.335516968000036 ],
							[ 7.889636827000061, 5.334959054000024 ],
							[ 7.893662257000074, 5.333837097000071 ],
							[ 7.896533910000073, 5.333283006000045 ],
							[ 7.899390244000074, 5.334459741000046 ],
							[ 7.901671694000072, 5.33563287100003 ],
							[ 7.903936190000024, 5.338537238000072 ],
							[ 7.904481095000051, 5.341426821000027 ],
							[ 7.903317130000062, 5.343149108000034 ],
							[ 7.900422158000026, 5.346012102000032 ],
							[ 7.896386398000061, 5.348288062000051 ],
							[ 7.891764310000042, 5.35171321100006 ],
							[ 7.888289723000071, 5.355147856000031 ],
							[ 7.884815074000073, 5.35858206000006 ],
							[ 7.881334648000063, 5.36259509000007 ],
							[ 7.880370181000046, 5.366795550000063 ],
							[ 7.890454902000045, 5.365587176000076 ],
							[ 7.90188119000004, 5.363340797000035 ],
							[ 7.912165347000041, 5.36108923200004 ],
							[ 7.920155871000077, 5.361125961000027 ],
							[ 7.933869691000041, 5.35774084600007 ],
							[ 7.941861249000056, 5.357778085000064 ],
							[ 7.94985723700006, 5.356665165000038 ],
							[ 7.957847076000064, 5.356701919000045 ],
							[ 7.962418785000068, 5.355573115000027 ],
							[ 7.968125797000027, 5.355598907000058 ],
							[ 7.972698011000034, 5.354471272000069 ],
							[ 7.977264179000031, 5.354492152000034 ],
							[ 7.982965742000033, 5.35566811800004 ],
							[ 7.985238846000072, 5.357976862000044 ],
							[ 7.986369969000066, 5.360281014000066 ],
							[ 7.987501126000041, 5.362585014000047 ],
							[ 7.986339130000033, 5.367177011000024 ],
							[ 7.986322870000038, 5.370625006000068 ],
							[ 7.984012816000075, 5.376361835000068 ],
							[ 7.980556910000075, 5.383243055000037 ],
							[ 7.978248386000075, 5.388978998000027 ],
							[ 7.973661056000026, 5.393556123000053 ],
							[ 7.972493342000064, 5.399298216000034 ],
							[ 7.969032085000038, 5.407328172000064 ],
							[ 7.964424154000028, 5.416502040000069 ],
							[ 7.959815993000063, 5.425675828000067 ],
							[ 7.958647941000038, 5.431417999000075 ],
							[ 7.956337648000044, 5.437153810000041 ],
							[ 7.954035053000041, 5.44174100500004 ],
							[ 7.950584057000071, 5.447473081000055 ],
							[ 7.947137926000039, 5.452054980000071 ],
							[ 7.943693411000027, 5.456635998000024 ],
							[ 7.939105949000066, 5.461213108000038 ],
							[ 7.934503304000032, 5.46923786800005 ],
							[ 7.931040556000028, 5.477268150000043 ],
							[ 7.928716093000048, 5.48645302500006 ],
							[ 7.926406684000028, 5.492188919000057 ],
							[ 7.924092691000055, 5.499074904000054 ],
							[ 7.921783130000051, 5.504810766000048 ],
							[ 7.920620984000038, 5.509404238000059 ],
							[ 7.914861313000074, 5.520872066000038 ],
							[ 7.907942866000042, 5.535781823000036 ],
							[ 7.90791166300005, 5.542678758000022 ],
							[ 7.906727687000057, 5.551868015000025 ],
							[ 7.906691912000042, 5.559915087000036 ],
							[ 7.90597993800003, 5.564770260000046 ],
							[ 7.90457888800006, 5.568675557000063 ],
							[ 7.906092062000027, 5.570636730000047 ],
							[ 7.907749435000028, 5.578310060000035 ],
							[ 7.909990006000044, 5.587515895000024 ],
							[ 7.909987048000062, 5.588133485000071 ],
							[ 7.901973512000041, 5.597352288000025 ],
							[ 7.902698386000054, 5.601045796000051 ],
							[ 7.90637079000004, 5.603640823000035 ],
							[ 7.912205738000068, 5.609528041000033 ],
							[ 7.913289148000047, 5.615117075000057 ],
							[ 7.913272764000055, 5.618565040000021 ],
							[ 7.914372024000045, 5.627766178000059 ],
							[ 7.914361854000049, 5.63006402600007 ],
							[ 7.914336413000058, 5.635811822000051 ],
							[ 7.916587107000055, 5.642717769000058 ],
							[ 7.91769669200005, 5.649620060000075 ],
							[ 7.915388335000046, 5.655356841000071 ],
							[ 7.91079631100007, 5.661083265000059 ],
							[ 7.906209269000044, 5.665658941000061 ],
							[ 7.901621535000061, 5.670236130000035 ],
							[ 7.897023982000064, 5.677111088000061 ],
							[ 7.893288673000029, 5.682270450000033 ],
							[ 7.890100026000027, 5.684799704000056 ],
							[ 7.890106943000035, 5.69202185100005 ],
							[ 7.88780304900007, 5.69660903700003 ],
							[ 7.886645058000056, 5.700051810000048 ],
							[ 7.894609868000032, 5.705835857000068 ],
							[ 7.90029104100006, 5.711608915000056 ],
							[ 7.904841780000027, 5.715077940000072 ],
							[ 7.908244975000059, 5.719692203000022 ],
							[ 7.911643710000021, 5.725453864000031 ],
							[ 7.912759111000071, 5.731205872000032 ],
							[ 7.913879071000054, 5.735808907000035 ],
							[ 7.914358245000074, 5.73827760000006 ],
							[ 7.913096847000077, 5.741081234000035 ],
							[ 7.913087601000029, 5.744205926000063 ],
							[ 7.91307235000005, 5.749359303000062 ],
							[ 7.913855022000064, 5.75213664100005 ],
							[ 7.916223011000056, 5.753729273000033 ],
							[ 7.920948591000069, 5.760085959000037 ],
							[ 7.921978990000071, 5.760117669000067 ],
							[ 7.927452057000039, 5.76345772600007 ],
							[ 7.932007034000037, 5.765778121000039 ],
							[ 7.936557617000062, 5.769247019000034 ],
							[ 7.942259878000073, 5.770422032000056 ],
							[ 7.945674018000034, 5.772736970000039 ],
							[ 7.949093764000054, 5.773901894000062 ],
							[ 7.951371950000066, 5.775062103000039 ],
							[ 7.954974136000033, 5.774130846000048 ],
							[ 7.959377853000035, 5.771650849000025 ],
							[ 7.965096127000038, 5.769378131000053 ],
							[ 7.970076360000064, 5.768326691000027 ],
							[ 7.964233369000056, 5.775656192000042 ],
							[ 7.951041911000061, 5.792906714000026 ],
							[ 7.942416559000037, 5.805083294000042 ],
							[ 7.933635895000066, 5.819872429000043 ],
							[ 7.933654764000039, 5.822497867000038 ],
							[ 7.934822064000059, 5.825934852000046 ],
							[ 7.938540726000042, 5.834443632000045 ],
							[ 7.945628875000068, 5.838733613000045 ],
							[ 7.95215758300003, 5.841531779000036 ],
							[ 7.95756689500007, 5.843023763000076 ],
							[ 7.961817261000022, 5.849794509000048 ],
							[ 7.964178808000042, 5.853765336000038 ],
							[ 7.966140660000065, 5.85932075900007 ],
							[ 7.966917294000041, 5.864476354000033 ],
							[ 7.96769058700005, 5.87082119400003 ],
							[ 7.970054555000047, 5.873999198000035 ],
							[ 7.977551971000025, 5.88036291700007 ],
							[ 7.986241584000027, 5.884747710000056 ],
							[ 7.989783564000049, 5.891100183000049 ],
							[ 7.993944919000057, 5.896819622000066 ],
							[ 7.994126866000045, 5.897304466000037 ],
							[ 7.999349604000031, 5.899542763000056 ],
							[ 8.004966713000044, 5.900077775000057 ],
							[ 8.006133227000021, 5.903514795000035 ],
							[ 8.002753053000049, 5.909278829000073 ],
							[ 8.000496832000067, 5.91273788500007 ],
							[ 7.993677984000044, 5.916230175000067 ],
							[ 7.985733720000042, 5.922025255000051 ],
							[ 7.982346073000031, 5.926640938000048 ],
							[ 7.982370938000031, 5.930085167000072 ],
							[ 7.98241368500004, 5.935824890000049 ],
							[ 7.984721103000027, 5.939252870000075 ],
							[ 7.989310025000066, 5.942666074000044 ],
							[ 7.988211856000021, 5.94841380400004 ],
							[ 7.984831542000052, 5.954177813000058 ],
							[ 7.981452267000066, 5.959941922000041 ],
							[ 7.979213395000045, 5.965697759000022 ],
							[ 7.979246886000055, 5.970290144000046 ],
							[ 7.980422044000022, 5.974873992000028 ],
							[ 7.98617682400004, 5.981722780000041 ],
							[ 7.993039301000067, 5.98397205200007 ],
							[ 8.001051177000022, 5.987360900000056 ],
							[ 8.009070246000022, 5.99189900600004 ],
							[ 8.014790779000066, 5.994154938000065 ],
							[ 8.02394367900007, 5.997537169000054 ],
							[ 8.029664944000046, 5.999794019000035 ],
							[ 8.031781857000055, 6.000487445000033 ],
							[ 8.035580895000066, 5.999048667000068 ],
							[ 8.039140954000061, 5.999454357000047 ],
							[ 8.049031275000061, 5.999876681000046 ],
							[ 8.055366613000047, 5.997911051000074 ],
							[ 8.058150526000077, 5.992368396000074 ],
							[ 8.059749596000074, 5.986029890000054 ],
							[ 8.060161254000036, 5.97968830700006 ],
							[ 8.059391194000057, 5.971361648000027 ],
							[ 8.054273741000031, 5.961438160000057 ],
							[ 8.051121251000041, 5.95667293200006 ],
							[ 8.051124325000046, 5.955483688000072 ],
							[ 8.05192076700007, 5.953503700000056 ],
							[ 8.053902871000048, 5.951923228000055 ],
							[ 8.057468046000054, 5.949950383000044 ],
							[ 8.06538611700006, 5.947592189000034 ],
							[ 8.074089389000051, 5.947614336000072 ],
							[ 8.077248633000067, 5.950000845000034 ],
							[ 8.083565145000023, 5.955170111000029 ],
							[ 8.087508408000076, 5.960333352000021 ],
							[ 8.090670240000065, 5.961530499000048 ],
							[ 8.09897748000003, 5.96194738500003 ],
							[ 8.104417720000072, 5.96120908100005 ],
							[ 8.105802528000027, 5.959085020000032 ],
							[ 8.106909642000062, 5.954484973000035 ],
							[ 8.108016728000052, 5.949884921000034 ],
							[ 8.109123214000022, 5.945284865000076 ],
							[ 8.111371223000049, 5.940678098000035 ],
							[ 8.113847668000062, 5.938149875000022 ],
							[ 8.122343924000063, 5.943515354000056 ],
							[ 8.136439211000038, 5.954967070000066 ],
							[ 8.148331580000047, 5.963776615000029 ],
							[ 8.153281890000073, 5.967316194000034 ],
							[ 8.167711967000059, 5.969502483000042 ],
							[ 8.172950841000045, 5.973770676000072 ],
							[ 8.179605286000026, 5.979192794000028 ],
							[ 8.193699676000051, 5.991966201000025 ],
							[ 8.204271500000061, 6.004299624000055 ],
							[ 8.214842736000037, 6.012228078000021 ],
							[ 8.222770644000036, 6.014430093000044 ],
							[ 8.230259066000031, 6.012668193000025 ],
							[ 8.238187073000063, 6.014870596000037 ],
							[ 8.244793877000063, 6.017953886000043 ],
							[ 8.249199112000042, 6.015751307000073 ],
							[ 8.252722996000045, 6.012228045000029 ],
							[ 8.254043815000045, 6.005180392000057 ],
							[ 8.258448465000072, 5.998132628000064 ],
							[ 8.263733856000044, 5.996811351000076 ],
							[ 8.274305058000039, 5.999013896000065 ],
							[ 8.282233481000048, 6.004739753000024 ],
							[ 8.28663801600004, 6.016632621000042 ],
							[ 8.287519476000057, 6.028965476000053 ],
							[ 8.288840419000053, 6.045703421000042 ],
							[ 8.291483999000036, 6.055393709000043 ],
							[ 8.298229260000028, 6.067536786000062 ],
							[ 8.298350216000074, 6.077161849000049 ],
							[ 8.298409323000044, 6.085197934000064 ],
							[ 8.298452518000033, 6.090939003000074 ],
							[ 8.300775273000056, 6.09666295300002 ],
							[ 8.301960004000023, 6.102396013000032 ],
							[ 8.302002686000037, 6.108136225000067 ],
							[ 8.309471823000024, 6.112629360000028 ],
							[ 8.314921516000027, 6.116261894000047 ],
							[ 8.315412110000068, 6.117651583000054 ],
							[ 8.313495685000021, 6.123753061000059 ],
							[ 8.309913988000062, 6.134052996000037 ],
							[ 8.308321410000076, 6.13920332500004 ],
							[ 8.307915551000065, 6.144355893000068 ],
							[ 8.312254190000033, 6.152688706000049 ],
							[ 8.316601332000062, 6.156264595000039 ],
							[ 8.319365603000051, 6.159837377000031 ],
							[ 8.320543429000054, 6.164992898000037 ],
							[ 8.317763627000033, 6.169744721000029 ],
							[ 8.317364323000049, 6.171726012000022 ],
							[ 8.320520668000029, 6.177281531000062 ],
							[ 8.330331127000022, 6.189924881000024 ],
							[ 8.335355554000046, 6.18573811400006 ],
							[ 8.340923332000045, 6.183445545000041 ],
							[ 8.342674900000077, 6.184853854000039 ],
							[ 8.344253003000063, 6.187631645000067 ],
							[ 8.347416089000035, 6.190015667000068 ],
							[ 8.351372581000021, 6.191211943000042 ],
							[ 8.356911518000061, 6.19280744100007 ],
							[ 8.367986587000075, 6.19837638000007 ],
							[ 8.373124637000046, 6.203142228000047 ],
							[ 8.383796042000029, 6.213466981000067 ],
							[ 8.38971650600007, 6.224180004000061 ],
							[ 8.393266044000029, 6.232510510000054 ],
							[ 8.398408152000059, 6.235293825000042 ],
							[ 8.405286027000045, 6.237050570000065 ],
							[ 8.411189254000021, 6.246132432000024 ],
							[ 8.417315503000054, 6.259611516000064 ],
							[ 8.409052463000023, 6.263456204000022 ],
							[ 8.407859638000048, 6.266625556000065 ],
							[ 8.40785323800003, 6.270589620000067 ],
							[ 8.409429669000076, 6.274952682000048 ],
							[ 8.412848432000033, 6.279149580000023 ],
							[ 8.410900010000034, 6.28211638700003 ],
							[ 8.405286099000023, 6.286092692000068 ],
							[ 8.394387251000069, 6.296990802000039 ],
							[ 8.391663293000022, 6.306527098000061 ],
							[ 8.395977211000059, 6.331502020000073 ],
							[ 8.397913009000035, 6.345054686000026 ],
							[ 8.399155611000026, 6.353752603000032 ],
							[ 8.403696827000033, 6.366013019000036 ],
							[ 8.407329452000056, 6.374640895000027 ],
							[ 8.413233086000048, 6.377819544000033 ],
							[ 8.430942609000056, 6.38099799500003 ],
							[ 8.445018872000048, 6.385538926000038 ],
							[ 8.447743513000034, 6.391896319000068 ],
							[ 8.444110790000025, 6.402340353000056 ],
							[ 8.447743439000021, 6.405065135000029 ],
							[ 8.453646781000032, 6.405973434000032 ],
							[ 8.455009594000046, 6.410059892000049 ],
							[ 8.455009616000041, 6.414600964000044 ],
							[ 8.456825037000044, 6.417779572000029 ],
							[ 8.456825385000059, 6.428223742000057 ],
							[ 8.452284870000028, 6.430040468000072 ],
							[ 8.445927638000057, 6.429132017000029 ],
							[ 8.442294142000037, 6.432764424000027 ],
							[ 8.432304505000047, 6.433219012000052 ],
							[ 8.431850191000024, 6.439576068000065 ],
							[ 8.435482723000064, 6.443662998000036 ],
							[ 8.438661496000066, 6.446841830000039 ],
							[ 8.430487418000041, 6.457739898000057 ],
							[ 8.423676496000041, 6.46636770200007 ],
							[ 8.422768469000061, 6.477719768000043 ],
							[ 8.408691202000057, 6.477719896000053 ],
							[ 8.405966547000048, 6.484985416000029 ],
							[ 8.408691130000022, 6.488618429000041 ],
							[ 8.41096194000005, 6.494521229000043 ],
							[ 8.412323952000065, 6.50042452100007 ],
							[ 8.420498121000037, 6.505419712000048 ],
							[ 8.420043966000037, 6.510414711000067 ],
							[ 8.419135941000036, 6.514501626000026 ],
							[ 8.415503403000059, 6.518588163000061 ],
							[ 8.410054389000038, 6.517226139000059 ],
							[ 8.395976954000048, 6.519496335000042 ],
							[ 8.390981773000021, 6.52312908600004 ],
							[ 8.390981563000025, 6.526308094000058 ],
							[ 8.393706309000038, 6.531302979000031 ],
							[ 8.399155661000066, 6.53221084200004 ],
							[ 8.400063567000075, 6.536297848000061 ],
							[ 8.396430986000041, 6.539476275000027 ],
							[ 8.393252348000033, 6.544471826000063 ],
							[ 8.391436468000052, 6.550374978000036 ],
							[ 8.386895080000045, 6.549920648000068 ],
							[ 8.379629983000029, 6.54855825900006 ],
							[ 8.375088561000041, 6.545834101000025 ],
							[ 8.371456096000031, 6.548104344000024 ],
							[ 8.372363863000032, 6.553099074000045 ],
							[ 8.372818156000051, 6.559002350000071 ],
							[ 8.374180971000044, 6.563543191000065 ],
							[ 8.370093132000022, 6.563089427000023 ],
							[ 8.363736052000036, 6.562180966000028 ],
							[ 8.358742018000044, 6.563997699000026 ],
							[ 8.351929863000066, 6.574441970000066 ],
							[ 8.346935520000045, 6.575349792000054 ],
							[ 8.340122981000036, 6.575349729000038 ],
							[ 8.333312311000043, 6.584885736000047 ],
							[ 8.330133634000049, 6.591697138000029 ],
							[ 8.324752039000032, 6.589499524000075 ],
							[ 8.323322569000027, 6.596238085000039 ],
							[ 8.321959585000059, 6.603049653000028 ],
							[ 8.314240494000046, 6.607136345000072 ],
							[ 8.314240181000059, 6.612131489000035 ],
							[ 8.321051741000076, 6.613947880000069 ],
							[ 8.321505528000046, 6.618488907000028 ],
							[ 8.324230257000067, 6.621213466000029 ],
							[ 8.325137842000061, 6.62575447200004 ],
							[ 8.322414113000036, 6.634381649000034 ],
							[ 8.315602180000042, 6.634381688000076 ],
							[ 8.309245309000062, 6.639377043000025 ],
							[ 8.304704825000044, 6.650729229000035 ],
							[ 8.306520220000039, 6.660719534000066 ],
							[ 8.301979750000044, 6.669801154000027 ],
							[ 8.294514797000033, 6.678708960000051 ],
							[ 8.299398703000065, 6.679910235000023 ],
							[ 8.303953163000074, 6.678730127000051 ],
							[ 8.307367138000075, 6.677557955000054 ],
							[ 8.313071155000046, 6.677518973000076 ],
							[ 8.317643299000054, 6.678636065000035 ],
							[ 8.321065822000037, 6.678613312000039 ],
							[ 8.325638025000046, 6.679728898000064 ],
							[ 8.330209678000074, 6.680846129000031 ],
							[ 8.335931045000052, 6.683103030000041 ],
							[ 8.340502774000072, 6.68421898400004 ],
							[ 8.345083200000033, 6.686483871000064 ],
							[ 8.348514514000044, 6.687607869000033 ],
							[ 8.354235542000026, 6.689865057000077 ],
							[ 8.357675622000045, 6.692138218000025 ],
							[ 8.361105879000036, 6.693262097000058 ],
							[ 8.366844013000048, 6.697815044000038 ],
							[ 8.370292461000076, 6.701234815000021 ],
							[ 8.372599840000021, 6.704664174000072 ],
							[ 8.374897926000074, 6.706943973000023 ],
							[ 8.377214193000043, 6.711521142000038 ],
							[ 8.379529926000032, 6.716096887000049 ],
							[ 8.381828144000053, 6.71837806800005 ],
							[ 8.383003384000062, 6.722962030000076 ],
							[ 8.386468707000063, 6.728679302000046 ],
							[ 8.387635215000046, 6.732114835000061 ],
							[ 8.391100141000038, 6.737832059000027 ],
							[ 8.393416234000028, 6.742407711000055 ],
							[ 8.398004309000044, 6.745820894000076 ],
							[ 8.402593601000035, 6.749232892000066 ],
							[ 8.408323126000028, 6.752637915000037 ],
							[ 8.410613968000064, 6.753770781000071 ],
							[ 8.415185101000077, 6.754887204000056 ],
							[ 8.419765186000063, 6.757152 ],
							[ 8.424328597000056, 6.757121049000034 ],
							[ 8.427760222000074, 6.758244857000022 ],
							[ 8.432323645000054, 6.758214103000057 ],
							[ 8.435746348000066, 6.758190278000029 ],
							[ 8.440300765000075, 6.757009885000059 ],
							[ 8.443707734000043, 6.754691151000031 ],
							[ 8.448244074000058, 6.751214969000046 ],
							[ 8.450499523000076, 6.747755113000039 ],
							[ 8.453923254000074, 6.74773225000007 ],
							[ 8.458478057000036, 6.746551909000061 ],
							[ 8.460759407000069, 6.746537180000075 ],
							[ 8.464182, 6.746512812000049 ],
							[ 8.468745256000034, 6.746481030000041 ],
							[ 8.472176378000029, 6.747605848000035 ],
							[ 8.476748749000024, 6.748722931000032 ],
							[ 8.480187308000041, 6.750996063000059 ],
							[ 8.488190397000039, 6.753235731000075 ],
							[ 8.496185568000044, 6.754329077000023 ],
							[ 8.503055592000067, 6.757727127000067 ],
							[ 8.508768351000072, 6.758834892000039 ],
							[ 8.515622405000045, 6.759935893000034 ],
							[ 8.523607980000065, 6.759881083000039 ],
							[ 8.530452826000044, 6.759833695000054 ],
							[ 8.535016241000051, 6.759802774000036 ],
							[ 8.544134348000057, 6.758592094000051 ],
							[ 8.550987947000067, 6.759693284000036 ],
							[ 8.557849798000063, 6.76194102900007 ],
							[ 8.563562213000068, 6.763050217000057 ],
							[ 8.57041650900004, 6.764151045000062 ],
							[ 8.57841101300005, 6.765244002000031 ],
							[ 8.587562375000061, 6.768624712000076 ],
							[ 8.594425189000049, 6.770874883000033 ],
							[ 8.601295304000075, 6.774271087000045 ],
							[ 8.607008041000029, 6.775380153000071 ],
							[ 8.612729769000055, 6.777636959000063 ],
							[ 8.616160475000072, 6.778760981000062 ],
							[ 8.620740967000074, 6.78102579800003 ],
							[ 8.623039944000027, 6.783307143000059 ],
							[ 8.625338395000028, 6.785586788000046 ],
							[ 8.628786115000025, 6.789007200000071 ],
							[ 8.631085192000057, 6.791287939000028 ],
							[ 8.632242917000042, 6.793575705000023 ],
							[ 8.636840644000074, 6.798137105000023 ],
							[ 8.640314032000049, 6.805000908000068 ],
							[ 8.640348517000064, 6.80959421700004 ],
							[ 8.640373978000071, 6.813037976000032 ],
							[ 8.641556728000069, 6.818770061000066 ],
							[ 8.639301499000055, 6.822229969000034 ],
							[ 8.637045116000024, 6.825689844000067 ],
							[ 8.633630821000054, 6.826860840000052 ],
							[ 8.630233989000033, 6.830328864000023 ],
							[ 8.625679256000069, 6.831509077000021 ],
							[ 8.622273301000064, 6.833828070000038 ],
							[ 8.620000969000046, 6.834991909000053 ],
							[ 8.615462409000031, 6.838467943000069 ],
							[ 8.61092487600007, 6.841942792000054 ],
							[ 8.607527688000062, 6.845410881000021 ],
							[ 8.604122043000075, 6.847731113000066 ],
							[ 8.601857378000034, 6.850042960000053 ],
							[ 8.600742578000052, 6.853494254000054 ],
							[ 8.600760435000041, 6.855791037000074 ],
							[ 8.606488929000022, 6.859195199000055 ],
							[ 8.608955381000044, 6.859628773000054 ],
							[ 8.61905587800004, 6.861404941000046 ],
							[ 8.623627856000041, 6.862522166000076 ],
							[ 8.629331323000031, 6.862483034000036 ],
							[ 8.636176388000024, 6.862434799000027 ],
							[ 8.645311441000047, 6.86352008700004 ],
							[ 8.651024574000076, 6.864628738000022 ],
							[ 8.654446550000046, 6.864604850000035 ],
							[ 8.656737166000028, 6.865737874000047 ],
							[ 8.661309312000071, 6.86685433100007 ],
							[ 8.664740447000042, 6.867979042000059 ],
							[ 8.669320077000066, 6.870244075000073 ],
							[ 8.675058604000071, 6.874795840000047 ],
							[ 8.677357952000023, 6.877077113000041 ],
							[ 8.680796699000041, 6.879350051000074 ],
							[ 8.686534936000044, 6.883903041000053 ],
							[ 8.689974935000066, 6.886175060000028 ],
							[ 8.693423075000055, 6.889595978000045 ],
							[ 8.696853392000037, 6.890719794000063 ],
							[ 8.701442759000031, 6.894133115000045 ],
							[ 8.703741818000026, 6.89641281300004 ],
							[ 8.707163532000038, 6.896389006000049 ],
							[ 8.715149788000076, 6.896334262000039 ],
							[ 8.720853771000066, 6.896295131000045 ],
							[ 8.727690690000031, 6.895100181000032 ],
							[ 8.733385872000042, 6.893911904000049 ],
							[ 8.741363264000029, 6.892710175000047 ],
							[ 8.748208857000066, 6.892662941000026 ],
							[ 8.753904508000062, 6.891475197000034 ],
							[ 8.759608411000045, 6.891436038000052 ],
							[ 8.76530456200004, 6.89024782100006 ],
							[ 8.769859555000039, 6.88906898700003 ],
							[ 8.776695048000022, 6.887874252000074 ],
							[ 8.781242193000026, 6.885546307000027 ],
							[ 8.785788705000073, 6.883218882000051 ],
							[ 8.790351948000023, 6.883186778000038 ],
							[ 8.798320509000064, 6.880835989000047 ],
							[ 8.802876426000068, 6.879656726000064 ],
							[ 8.807430036000028, 6.878477998000051 ],
							[ 8.811984765000034, 6.877297788000021 ],
							[ 8.817680525000071, 6.876111016000038 ],
							[ 8.822226689000047, 6.873783137000032 ],
							[ 8.830196526000066, 6.871431932000064 ],
							[ 8.833609477000039, 6.870259682000039 ],
							[ 8.839305614000068, 6.869072871000071 ],
							[ 8.843852127000048, 6.866745 ],
							[ 8.848406591000071, 6.865565763000063 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Delta',
				admin1Pcod: 'NG010',
				admin1RefN: 'Delta',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 7.372526,
				Shape_Area: 1.394082
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.668921030000035, 6.501234142000044 ],
							[ 6.671113580000053, 6.484836704000031 ],
							[ 6.67386379900006, 6.468333132000055 ],
							[ 6.68176513800006, 6.455741800000055 ],
							[ 6.685224016000063, 6.442594927000073 ],
							[ 6.688670228000035, 6.432221807000076 ],
							[ 6.692131024000048, 6.418381129000068 ],
							[ 6.694902806000073, 6.40661476300005 ],
							[ 6.697680877000039, 6.393462056000033 ],
							[ 6.699773846000028, 6.381689966000067 ],
							[ 6.707312043000059, 6.368576951000023 ],
							[ 6.712820165000039, 6.353365884000027 ],
							[ 6.714923785000053, 6.338820046000023 ],
							[ 6.717024898000034, 6.324967916000048 ],
							[ 6.718440888000032, 6.312497153000038 ],
							[ 6.719175951000068, 6.300021153000046 ],
							[ 6.721268270000053, 6.288249049000058 ],
							[ 6.723369132000073, 6.274396858000046 ],
							[ 6.725022284000033, 6.265763216000039 ],
							[ 6.726152927000044, 6.259857111000031 ],
							[ 6.731006011000034, 6.239092778000042 ],
							[ 6.735824150000042, 6.225956026000063 ],
							[ 6.742698245000042, 6.209370203000049 ],
							[ 6.753652130000035, 6.192818227000032 ],
							[ 6.762889290000032, 6.185385698000061 ],
							[ 6.767301, 6.181836076000025 ],
							[ 6.769402045000049, 6.16798400700003 ],
							[ 6.766044970000053, 6.158247 ],
							[ 6.764783864000037, 6.155804660000058 ],
							[ 6.760651216000042, 6.147799950000035 ],
							[ 6.757986118000076, 6.135294916000021 ],
							[ 6.756189289000076, 6.125429188000055 ],
							[ 6.755804128000023, 6.123312503000022 ],
							[ 6.755329970000048, 6.120709925000028 ],
							[ 6.749240949000068, 6.113724220000051 ],
							[ 6.737713803000076, 6.105999876000055 ],
							[ 6.722111218000066, 6.097548017000065 ],
							[ 6.713315993000037, 6.087072808000073 ],
							[ 6.709276120000027, 6.078023883000071 ],
							[ 6.716826974000071, 6.062137177000068 ],
							[ 6.725058084000068, 6.046256078000056 ],
							[ 6.726493782000034, 6.028930117000073 ],
							[ 6.724527769000076, 6.012269978000063 ],
							[ 6.719857347000072, 5.992121182000062 ],
							[ 6.716505887000039, 5.980997150000064 ],
							[ 6.715875235000055, 5.969895905000044 ],
							[ 6.71321303600007, 5.95669797000005 ],
							[ 6.708522742000071, 5.940709176000041 ],
							[ 6.703824931000042, 5.926799820000042 ],
							[ 6.697762055000055, 5.913572759000033 ],
							[ 6.688315906000071, 5.896851006000077 ],
							[ 6.67942905700005, 5.888180738000074 ],
							[ 6.678992042000061, 5.887015395000049 ],
							[ 6.678410282000073, 5.886490458000026 ],
							[ 6.673657674000026, 5.882408030000022 ],
							[ 6.670752555000035, 5.876863872000058 ],
							[ 6.661483310000051, 5.857277318000058 ],
							[ 6.660915718000069, 5.856529203000036 ],
							[ 6.657929892000027, 5.840404461000048 ],
							[ 6.650763381000047, 5.817113447000054 ],
							[ 6.651957546000062, 5.798600159000046 ],
							[ 6.654832784000064, 5.779374091000022 ],
							[ 6.654868205000071, 5.771530105000068 ],
							[ 6.64363002500005, 5.747325379000074 ],
							[ 6.633620147000045, 5.730035796000038 ],
							[ 6.626148363000027, 5.723635495000053 ],
							[ 6.61829282900004, 5.716906120000033 ],
							[ 6.613452776000031, 5.702041206000047 ],
							[ 6.614049524000052, 5.690699538000047 ],
							[ 6.606566810000061, 5.680008817000044 ],
							[ 6.606450136000035, 5.670234126000025 ],
							[ 6.608761396000034, 5.666173423000032 ],
							[ 6.61232512600003, 5.659912115000054 ],
							[ 6.617293701000051, 5.651000048000071 ],
							[ 6.621346011000071, 5.642564747000051 ],
							[ 6.617601506000028, 5.631519319000063 ],
							[ 6.613107152000055, 5.62667936400004 ],
							[ 6.608144777000064, 5.62125486900004 ],
							[ 6.603773667000041, 5.614925921000065 ],
							[ 6.602045135000026, 5.607666030000075 ],
							[ 6.601007801000037, 5.596949593000033 ],
							[ 6.596167947000026, 5.587615466000045 ],
							[ 6.593402364000042, 5.580010427000047 ],
							[ 6.591673857000046, 5.574479108000048 ],
							[ 6.587871672000063, 5.567911110000068 ],
							[ 6.584760123000024, 5.561688428000025 ],
							[ 6.579228776000036, 5.559268538000026 ],
							[ 6.568858294000052, 5.553391468000029 ],
							[ 6.555376038000077, 5.552354768000043 ],
							[ 6.542584971000053, 5.549243462000049 ],
							[ 6.530831349000039, 5.531958621000058 ],
							[ 6.533251165000024, 5.509833761000039 ],
							[ 6.536935738000068, 5.491936270000053 ],
							[ 6.537696893000032, 5.478424987000039 ],
							[ 6.538163688000054, 5.475804358000062 ],
							[ 6.536656005000054, 5.469584009000073 ],
							[ 6.534836751000057, 5.458974368000042 ],
							[ 6.524530382000023, 5.438663960000042 ],
							[ 6.517558185000041, 5.434420059000047 ],
							[ 6.512404374000027, 5.428660405000073 ],
							[ 6.508820501000059, 5.425673928000037 ],
							[ 6.505941378000045, 5.425829342000043 ],
							[ 6.499718659000052, 5.426520825000068 ],
							[ 6.494187330000045, 5.425138013000037 ],
							[ 6.48934801300004, 5.422718520000046 ],
							[ 6.483125094000059, 5.420644217000074 ],
							[ 6.476556689000063, 5.404050860000041 ],
							[ 6.485476966000022, 5.379205287000048 ],
							[ 6.495871100000045, 5.369657566000058 ],
							[ 6.500541118000058, 5.351987823000059 ],
							[ 6.502585989000067, 5.338941155000043 ],
							[ 6.493019238000045, 5.328299503000039 ],
							[ 6.493532108000068, 5.326040677000037 ],
							[ 6.490166611000063, 5.324021279000021 ],
							[ 6.473052980000034, 5.321882189000064 ],
							[ 6.450234820000048, 5.32758668300005 ],
							[ 6.434547526000074, 5.323308004000069 ],
							[ 6.425990658000046, 5.311185894000062 ],
							[ 6.408876887000076, 5.304768491000061 ],
							[ 6.393188895000037, 5.306907674000058 ],
							[ 6.380353951000075, 5.309046728000055 ],
							[ 6.362527497000031, 5.314038302000029 ],
							[ 6.347552897000071, 5.32045596200004 ],
							[ 6.338832400000058, 5.319910929000059 ],
							[ 6.333601004000059, 5.317730936000032 ],
							[ 6.330088242000045, 5.313632437000024 ],
							[ 6.327453742000046, 5.31041244100004 ],
							[ 6.325403125000037, 5.306539529000077 ],
							[ 6.32187704200004, 5.305265005000024 ],
							[ 6.31556808800002, 5.304772847000038 ],
							[ 6.310642852000058, 5.303543017000038 ],
							[ 6.306432293000057, 5.302983798000071 ],
							[ 6.302890285000046, 5.301016329000049 ],
							[ 6.300047772000028, 5.29902644200007 ],
							[ 6.297190657000044, 5.29634376100006 ],
							[ 6.295017594000058, 5.292945797000073 ],
							[ 6.292909551000037, 5.289627586000051 ],
							[ 6.289982303000045, 5.286700302000042 ],
							[ 6.288518478000071, 5.284943527000053 ],
							[ 6.287700740000048, 5.283717196000055 ],
							[ 6.286762163000049, 5.282309093000038 ],
							[ 6.285005418000026, 5.279088956000066 ],
							[ 6.283542045000047, 5.27499053400004 ],
							[ 6.283542122000028, 5.270599379000032 ],
							[ 6.28383446600003, 5.266500892000067 ],
							[ 6.285265436000032, 5.262743930000056 ],
							[ 6.28715561000007, 5.259274517000051 ],
							[ 6.290751905000036, 5.251054718000034 ],
							[ 6.292275299000039, 5.245395235000046 ],
							[ 6.293005958000037, 5.238823862000061 ],
							[ 6.290449986000056, 5.234077417000037 ],
							[ 6.284712919000071, 5.231664612000031 ],
							[ 6.278996068000026, 5.228888015000052 ],
							[ 6.272560722000037, 5.228236179000021 ],
							[ 6.259417428000063, 5.227870921000033 ],
							[ 6.244461045000037, 5.233656834000044 ],
							[ 6.238560250000035, 5.235210463000044 ],
							[ 6.233532927000056, 5.238167805000046 ],
							[ 6.213285462000044, 5.238669347000041 ],
							[ 6.20441611900003, 5.233211465000068 ],
							[ 6.202086897000072, 5.231778071000065 ],
							[ 6.195784226000058, 5.223075895000022 ],
							[ 6.19415135600002, 5.216709090000052 ],
							[ 6.195891476000043, 5.211076663000028 ],
							[ 6.198081900000034, 5.203775007000047 ],
							[ 6.200667800000076, 5.190462041000046 ],
							[ 6.202290892000065, 5.179281252000067 ],
							[ 6.200734075000071, 5.169549966000034 ],
							[ 6.190906914000038, 5.158570762000068 ],
							[ 6.178827659000035, 5.161139942000034 ],
							[ 6.165906323000058, 5.16113996100006 ],
							[ 6.154707358000053, 5.151664273000051 ],
							[ 6.150269039000023, 5.141166256000076 ],
							[ 6.143735923000065, 5.134776126000077 ],
							[ 6.129725895000036, 5.12926675600005 ],
							[ 6.120682733000024, 5.133677479000028 ],
							[ 6.109051218000047, 5.14218807900005 ],
							[ 6.097852588000023, 5.149941410000054 ],
							[ 6.085792668000067, 5.150802572000032 ],
							[ 6.069569589000025, 5.141344063000076 ],
							[ 6.06675100700005, 5.133347035000043 ],
							[ 6.063354122000021, 5.123184177000041 ],
							[ 6.063728271000059, 5.11177155300004 ],
							[ 6.06226784100005, 5.103009732000032 ],
							[ 6.054966095000054, 5.097167950000028 ],
							[ 6.044443003000026, 5.095670666000046 ],
							[ 6.027214582000056, 5.095670679000023 ],
							[ 6.019461505000038, 5.098254686000075 ],
							[ 6.01358220000003, 5.100392787000033 ],
							[ 6.004523214000073, 5.103686771000071 ],
							[ 6.000202140000056, 5.107390869000028 ],
							[ 5.995821024000065, 5.113232166000046 ],
							[ 5.992170353000063, 5.117613285000061 ],
							[ 5.985598630000027, 5.124185066000052 ],
							[ 5.980889411000021, 5.130557515000021 ],
							[ 5.97829684200002, 5.133312196000077 ],
							[ 5.974926594000067, 5.137524633000055 ],
							[ 5.972455546000049, 5.140614041000049 ],
							[ 5.964244911000037, 5.149107441000069 ],
							[ 5.960538769000038, 5.15721850400007 ],
							[ 5.946052103000056, 5.164227987000061 ],
							[ 5.933200709000062, 5.164695254000037 ],
							[ 5.927826474000028, 5.160723214000029 ],
							[ 5.927592717000039, 5.154881967000051 ],
							[ 5.926891694000062, 5.13992786700004 ],
							[ 5.921751381000036, 5.135020723000025 ],
							[ 5.917545344000075, 5.134086100000047 ],
							[ 5.916377159000035, 5.131515987000057 ],
							[ 5.915909672000055, 5.117496488000029 ],
							[ 5.914507979000064, 5.10814997600005 ],
							[ 5.909367476000057, 5.104878911000071 ],
							[ 5.896516428000041, 5.104411596000034 ],
							[ 5.885300594000057, 5.099972220000041 ],
							[ 5.882730492000064, 5.101841447000027 ],
							[ 5.881353491000027, 5.10950899900007 ],
							[ 5.88077271800006, 5.109508978000065 ],
							[ 5.877645029000064, 5.105258426000034 ],
							[ 5.877892463000023, 5.100305093000031 ],
							[ 5.878387959000065, 5.097085956000058 ],
							[ 5.877645095000048, 5.091389670000069 ],
							[ 5.874425489000032, 5.08618881700005 ],
							[ 5.871701311000038, 5.08123537900002 ],
							[ 5.870214984000029, 5.076034514000071 ],
							[ 5.869472159000054, 5.071081665000065 ],
							[ 5.86773878200006, 5.068109542000059 ],
							[ 5.864981263000061, 5.06947180800006 ],
							[ 5.863082038000073, 5.074440049000032 ],
							[ 5.857383133000042, 5.07736256000004 ],
							[ 5.853145639000047, 5.075462818000062 ],
							[ 5.851976338000043, 5.070202789000064 ],
							[ 5.848323709000056, 5.06654975400005 ],
							[ 5.840871458000038, 5.067572590000054 ],
							[ 5.83999503800004, 5.070786954000027 ],
							[ 5.83951098700004, 5.074536299000044 ],
							[ 5.840579176000062, 5.080577382000058 ],
							[ 5.840140873000053, 5.083061213000065 ],
							[ 5.835318976000053, 5.082476580000048 ],
							[ 5.830643178000059, 5.080285091000064 ],
							[ 5.825529070000073, 5.077508471000044 ],
							[ 5.822606631000042, 5.075755112000024 ],
							[ 5.820268610000028, 5.075170501000059 ],
							[ 5.817346125000029, 5.077362532000052 ],
							[ 5.815300339000032, 5.081892475000075 ],
							[ 5.814131321000048, 5.084522210000046 ],
							[ 5.809017251000057, 5.086421954000059 ],
							[ 5.80551059000004, 5.083645836000073 ],
							[ 5.803610923000065, 5.081892521000043 ],
							[ 5.797473973000024, 5.083061236000049 ],
							[ 5.791336483000066, 5.085837352000055 ],
							[ 5.789145087000065, 5.084376356000064 ],
							[ 5.789875405000032, 5.078677638000045 ],
							[ 5.788122132000069, 5.072540799000024 ],
							[ 5.784176763000062, 5.06932594400007 ],
							[ 5.780443276000028, 5.064958573000069 ],
							[ 5.779018868000037, 5.058013917000039 ],
							[ 5.775635688000023, 5.057479848000071 ],
							[ 5.773499077000054, 5.061219239000025 ],
							[ 5.769225630000051, 5.063355909000052 ],
							[ 5.764239769000028, 5.064246206000064 ],
							[ 5.758363806000034, 5.061040863000073 ],
							[ 5.75195351800005, 5.053740537000067 ],
							[ 5.748570415000074, 5.053206421000027 ],
							[ 5.743050556000071, 5.055699344000061 ],
							[ 5.736462249000056, 5.056589634000034 ],
							[ 5.730586130000063, 5.054452857000058 ],
							[ 5.728449450000028, 5.050713573000053 ],
							[ 5.72453199000006, 5.041098104000071 ],
							[ 5.719368480000071, 5.030948630000069 ],
							[ 5.710287135000044, 5.023826131000021 ],
							[ 5.702096331000064, 5.022935913000026 ],
							[ 5.691234724000026, 5.024182351000036 ],
							[ 5.682153776000064, 5.027921664000075 ],
							[ 5.675565157000051, 5.029880018000028 ],
							[ 5.666662267000049, 5.028277832000072 ],
							[ 5.663456905000032, 5.02400448800006 ],
							[ 5.663457027000049, 5.018840308000051 ],
							[ 5.663635225000064, 5.015100933000042 ],
							[ 5.661808871000062, 5.012883646000034 ],
							[ 5.656670524000049, 5.011776454000028 ],
							[ 5.647893368000041, 5.011776474000044 ],
							[ 5.642328626000051, 5.015602131000037 ],
							[ 5.632938844000023, 5.018383999000037 ],
							[ 5.625288025000032, 5.026035316000048 ],
							[ 5.619723407000038, 5.034729503000051 ],
							[ 5.611029056000064, 5.038902728000039 ],
							[ 5.599204434000058, 5.042380817000037 ],
							[ 5.590857994000032, 5.043424136000056 ],
							[ 5.585641319000047, 5.047944979000022 ],
							[ 5.58459816800007, 5.055248279000068 ],
							[ 5.569295902000022, 5.056291589000068 ],
							[ 5.550863648000075, 5.055596377000029 ],
							[ 5.544603860000052, 5.058030618000032 ],
							[ 5.533127232000027, 5.062899586000071 ],
							[ 5.524085055000057, 5.068811905000075 ],
							[ 5.516781933000061, 5.08063604900002 ],
							[ 5.511565091000023, 5.088287346000072 ],
							[ 5.502870472000041, 5.092460618000075 ],
							[ 5.493828162000057, 5.091764928000032 ],
							[ 5.485134080000023, 5.090373984000053 ],
							[ 5.479221714000062, 5.091764955000031 ],
							[ 5.475568261000035, 5.092287110000029 ],
							[ 5.472823178000056, 5.092679033000024 ],
							[ 5.470508622000068, 5.093212600000072 ],
							[ 5.469124432000058, 5.095749861000058 ],
							[ 5.467469329000039, 5.10170891000007 ],
							[ 5.469301750000056, 5.106533507000051 ],
							[ 5.47247171600003, 5.112345654000023 ],
							[ 5.472676213000057, 5.11766478800007 ],
							[ 5.469812470000022, 5.123188526000035 ],
							[ 5.465311421000024, 5.124825462000047 ],
							[ 5.458969198000034, 5.12830350400003 ],
							[ 5.453240863000076, 5.130758315000037 ],
							[ 5.447512706000055, 5.13485002300007 ],
							[ 5.441579827000055, 5.138941773000056 ],
							[ 5.436465232000046, 5.141396493000059 ],
							[ 5.432047814000043, 5.141510015000051 ],
							[ 5.428486337000038, 5.141601066000021 ],
							[ 5.424455113000022, 5.140861983000036 ],
							[ 5.418285415000071, 5.139730946000043 ],
							[ 5.416211545000067, 5.139350882000031 ],
							[ 5.413113525000028, 5.138521217000061 ],
							[ 5.40475475300002, 5.136281945000064 ],
							[ 5.401748316000067, 5.135847599000044 ],
							[ 5.396710833000043, 5.135118987000055 ],
							[ 5.393129501000033, 5.134601102000033 ],
							[ 5.390386947000025, 5.134204888000056 ],
							[ 5.390290338000057, 5.138286604000029 ],
							[ 5.387953862000074, 5.139348484000038 ],
							[ 5.383784395000021, 5.141918155000042 ],
							[ 5.380001492000076, 5.14403629800006 ],
							[ 5.377580570000021, 5.146608356000058 ],
							[ 5.376359551000064, 5.149549033000028 ],
							[ 5.375047127000073, 5.152913074000026 ],
							[ 5.36941054700003, 5.162797903000069 ],
							[ 5.366081810000026, 5.166882970000074 ],
							[ 5.362298879000036, 5.171270814000025 ],
							[ 5.359698650000041, 5.174014989000057 ],
							[ 5.355641786000035, 5.179592554000067 ],
							[ 5.357457143000033, 5.18171073000002 ],
							[ 5.358062750000045, 5.184585514000048 ],
							[ 5.360785845000066, 5.184736742000041 ],
							[ 5.36123998000005, 5.187762768000027 ],
							[ 5.361693783000021, 5.192301706000023 ],
							[ 5.359878114000026, 5.200018374000024 ],
							[ 5.35836503400003, 5.208037350000041 ],
							[ 5.358213854000041, 5.210912206000046 ],
							[ 5.358365063000065, 5.214089358000024 ],
							[ 5.357457030000035, 5.216964289000032 ],
							[ 5.355641771000023, 5.237087762000044 ],
							[ 5.35443123500005, 5.245257811000045 ],
							[ 5.351102802000071, 5.257815900000026 ],
							[ 5.348076794000065, 5.267196679000051 ],
							[ 5.344748130000028, 5.275064431000033 ],
							[ 5.343235094000022, 5.281116530000077 ],
							[ 5.342175989000054, 5.284294129000045 ],
							[ 5.339906081000038, 5.289589439000054 ],
							[ 5.337485259000061, 5.293977331000065 ],
							[ 5.337334127000076, 5.300332032000028 ],
							[ 5.336123546000067, 5.30335801700005 ],
							[ 5.334459302000027, 5.308653873000026 ],
							[ 5.332341300000053, 5.315008589000058 ],
							[ 5.33067664400005, 5.322422536000033 ],
							[ 5.33052536100007, 5.325902429000053 ],
							[ 5.330223072000024, 5.329533543000025 ],
							[ 5.330525511000076, 5.338611595000032 ],
							[ 5.330071442000076, 5.347236094000039 ],
							[ 5.329295288000026, 5.349481085000036 ],
							[ 5.328407282000057, 5.352380289000052 ],
							[ 5.325835156000039, 5.359794134000026 ],
							[ 5.326591548000067, 5.36418197100005 ],
							[ 5.32780219700004, 5.365997320000076 ],
							[ 5.327824923000037, 5.374434009000026 ],
							[ 5.328256246000024, 5.383548665000035 ],
							[ 5.324776061000023, 5.406395427000064 ],
							[ 5.315092444000072, 5.411539549000054 ],
							[ 5.309342808000054, 5.415019541000049 ],
							[ 5.301170364000029, 5.418914770000072 ],
							[ 5.293810970000038, 5.423098125000024 ],
							[ 5.289550163000058, 5.424957332000076 ],
							[ 5.283430091000071, 5.42720367000004 ],
							[ 5.281261414000028, 5.427668514000061 ],
							[ 5.277930384000058, 5.42875298000007 ],
							[ 5.270957974000055, 5.430999734000068 ],
							[ 5.26863426500006, 5.432084088000067 ],
							[ 5.25887356000004, 5.436190185000044 ],
							[ 5.255929341000069, 5.437661628000058 ],
							[ 5.25253856300003, 5.440087748000053 ],
							[ 5.247175631000061, 5.444013996000024 ],
							[ 5.243767197000068, 5.445950961000051 ],
							[ 5.239196744000026, 5.450443665000023 ],
							[ 5.230341439000028, 5.456867651000039 ],
							[ 5.224013303000049, 5.461676628000021 ],
							[ 5.220217178000041, 5.465627723000068 ],
							[ 5.210146359000021, 5.476395540000055 ],
							[ 5.206505797000034, 5.482437549000053 ],
							[ 5.202167521000035, 5.489177221000034 ],
							[ 5.19914626800005, 5.493050536000055 ],
							[ 5.195508991000054, 5.497379316000035 ],
							[ 5.191167006000057, 5.504903341000045 ],
							[ 5.186286557000074, 5.511255790000064 ],
							[ 5.17513122500003, 5.524270038000054 ],
							[ 5.170328661000042, 5.530234802000052 ],
							[ 5.168469332000029, 5.532946111000058 ],
							[ 5.165060418000053, 5.537672072000021 ],
							[ 5.164363467000044, 5.541235444000051 ],
							[ 5.164905543000032, 5.543171891000043 ],
							[ 5.166145441000026, 5.545108755000058 ],
							[ 5.169088760000022, 5.546813070000042 ],
							[ 5.17257508900002, 5.555024672000059 ],
							[ 5.174821386000076, 5.55920743300004 ],
							[ 5.177300477000074, 5.565172607000022 ],
							[ 5.179314652000073, 5.568116241000041 ],
							[ 5.183871845000056, 5.570648690000041 ],
							[ 5.187681110000028, 5.573694243000034 ],
							[ 5.193335909000041, 5.579503935000048 ],
							[ 5.177406834000067, 5.594353716000057 ],
							[ 5.166200639000067, 5.601249627000072 ],
							[ 5.156287586000076, 5.609869994000064 ],
							[ 5.148960995000039, 5.618058690000055 ],
							[ 5.13861696400005, 5.629264791000026 ],
							[ 5.131488933000071, 5.637710158000061 ],
							[ 5.126008891000026, 5.646678875000021 ],
							[ 5.118624680000039, 5.665630301000022 ],
							[ 5.116885250000053, 5.678678009000066 ],
							[ 5.112089756000046, 5.68989749800005 ],
							[ 5.107317045000059, 5.698249330000067 ],
							[ 5.098618490000035, 5.708687333000057 ],
							[ 5.094704269000033, 5.717385725000042 ],
							[ 5.086875894000059, 5.725649348000047 ],
							[ 5.085135885000057, 5.729998627000043 ],
							[ 5.082264004000024, 5.736556040000039 ],
							[ 5.081656810000027, 5.747394983000049 ],
							[ 5.080351866000058, 5.752613948000032 ],
							[ 5.081221665000044, 5.762182187000064 ],
							[ 5.084701004000067, 5.768706352000038 ],
							[ 5.072958357000061, 5.782188377000068 ],
							[ 5.065564553000058, 5.780448981000063 ],
							[ 5.05599643100004, 5.784363317000043 ],
							[ 5.049472759000025, 5.788712470000064 ],
							[ 5.045558501000073, 5.79349656200003 ],
							[ 5.039904569000043, 5.803499669000075 ],
							[ 5.035120490000054, 5.816112094000061 ],
							[ 5.030336437000074, 5.825680233000071 ],
							[ 5.024682469000027, 5.834378748000063 ],
							[ 5.020333250000022, 5.840902356000072 ],
							[ 5.017723516000046, 5.845251580000024 ],
							[ 5.015452354000047, 5.851261669000053 ],
							[ 5.012452139000061, 5.854389162000075 ],
							[ 5.007306171000039, 5.862368072000038 ],
							[ 4.999670493000053, 5.873881317000041 ],
							[ 4.990134200000057, 5.887926590000063 ],
							[ 4.983859100000075, 5.898479872000053 ],
							[ 4.999207704000071, 5.921783577000042 ],
							[ 5.01268009000006, 5.942235431000029 ],
							[ 5.016400890000057, 5.948643152000045 ],
							[ 5.018467448000024, 5.953190836000033 ],
							[ 5.026321885000073, 5.971586750000029 ],
							[ 5.031282864000048, 5.982541560000072 ],
							[ 5.039689516000067, 6.000184508000075 ],
							[ 5.041731832000039, 6.005086433000031 ],
							[ 5.052998891000072, 6.027666088000046 ],
							[ 5.062687740000058, 6.048982110000054 ],
							[ 5.07592960300002, 6.07804869000006 ],
							[ 5.087879287000021, 6.101302130000022 ],
							[ 5.101121028000023, 6.124878931000069 ],
							[ 5.119040513000073, 6.149993473000052 ],
							[ 5.126955879000036, 6.163946601000021 ],
							[ 5.135050694000029, 6.169817003000048 ],
							[ 5.133102431000054, 6.164677568000059 ],
							[ 5.13829381000005, 6.159885425000027 ],
							[ 5.141374546000065, 6.155823230000067 ],
							[ 5.143491702000063, 6.151237930000036 ],
							[ 5.142963046000034, 6.143306744000029 ],
							[ 5.145542100000057, 6.141641575000051 ],
							[ 5.154594843000041, 6.144187978000048 ],
							[ 5.15786227600006, 6.146707063000065 ],
							[ 5.161456090000058, 6.142713541000035 ],
							[ 5.164626230000067, 6.14655212100007 ],
							[ 5.162909159000037, 6.14793056700006 ],
							[ 5.164650927000025, 6.155093215000022 ],
							[ 5.164251943000068, 6.159086669000033 ],
							[ 5.168694626000047, 6.163574671000049 ],
							[ 5.172923995000076, 6.160578755000074 ],
							[ 5.176631458000031, 6.159486284000025 ],
							[ 5.179445195000028, 6.15899231800006 ],
							[ 5.181912328000067, 6.156877533000056 ],
							[ 5.182441126000072, 6.154410326000061 ],
							[ 5.180149997000058, 6.149651473000063 ],
							[ 5.18402724300006, 6.14736036100004 ],
							[ 5.190372050000065, 6.151237913000045 ],
							[ 5.193720821000056, 6.15176632500004 ],
							[ 5.195835576000036, 6.150885143000039 ],
							[ 5.198831569000049, 6.147007959000064 ],
							[ 5.203388309000047, 6.145109593000029 ],
							[ 5.20605766500006, 6.147184438000068 ],
							[ 5.205528700000059, 6.151237906000063 ],
							[ 5.209378827000023, 6.153495786000065 ],
							[ 5.217764747000047, 6.149901900000032 ],
							[ 5.222557171000062, 6.148703616000034 ],
							[ 5.222956656000065, 6.144710107000037 ],
							[ 5.218563700000061, 6.141515315000049 ],
							[ 5.21736579700007, 6.136323473000061 ],
							[ 5.221758257000033, 6.131531209000059 ],
							[ 5.225352695000026, 6.125940377000063 ],
							[ 5.230544069000075, 6.118752070000028 ],
							[ 5.233739018000051, 6.11236236600007 ],
							[ 5.23134275700005, 6.101180552000073 ],
							[ 5.232541155000035, 6.094790927000076 ],
							[ 5.232541002000062, 6.089199996000048 ],
							[ 5.232940118000045, 6.081212935000053 ],
							[ 5.233738820000042, 6.07362560200005 ],
							[ 5.232984129000045, 6.071512190000021 ],
							[ 5.232940319000022, 6.065638498000055 ],
							[ 5.238930711000023, 6.06244377400003 ],
							[ 5.245213103000026, 6.063759812000058 ],
							[ 5.248787452000045, 6.064228039000056 ],
							[ 5.256502201000046, 6.065238947000068 ],
							[ 5.270169742000064, 6.054295938000053 ],
							[ 5.268269054000029, 6.052536039000074 ],
							[ 5.26502169400004, 6.043814232000045 ],
							[ 5.262348098000075, 6.033390121000025 ],
							[ 5.258984971000075, 6.025040105000073 ],
							[ 5.254957334000039, 6.013216895000028 ],
							[ 5.251615094000044, 6.000012831000049 ],
							[ 5.247795462000056, 5.986599919000071 ],
							[ 5.249100177000059, 5.970073184000057 ],
							[ 5.25344956400005, 5.957025491000024 ],
							[ 5.256058796000048, 5.950067066000031 ],
							[ 5.263452659000052, 5.943108109000036 ],
							[ 5.268236549000051, 5.93919422700003 ],
							[ 5.269696788000033, 5.918911538000032 ],
							[ 5.275195204000056, 5.909184410000023 ],
							[ 5.291287324000052, 5.912229005000029 ],
							[ 5.304334641000025, 5.919622409000056 ],
							[ 5.315147840000066, 5.934660880000024 ],
							[ 5.326080706000027, 5.94180340500003 ],
							[ 5.334778732000075, 5.949197319000064 ],
							[ 5.341302766000069, 5.95615581900006 ],
							[ 5.350001418000033, 5.960939859000064 ],
							[ 5.358699279000064, 5.969638356000075 ],
							[ 5.34521714300007, 5.975292153000055 ],
							[ 5.339998107000042, 5.98268558500007 ],
							[ 5.336518891000026, 5.984425604000023 ],
							[ 5.326950582000052, 5.982685583000034 ],
							[ 5.326080916000024, 5.990079373000071 ],
							[ 5.326950680000039, 5.994428680000055 ],
							[ 5.323036232000049, 6.005301473000031 ],
							[ 5.327385426000035, 6.018349230000069 ],
							[ 5.334344002000023, 6.021393262000061 ],
							[ 5.33824968600004, 6.018789819000062 ],
							[ 5.342172624000057, 6.016174356000022 ],
							[ 5.356524819000072, 6.017479416000072 ],
							[ 5.365658290000056, 6.019653761000029 ],
							[ 5.377835868000034, 6.019218877000071 ],
							[ 5.388225199000033, 6.017794072000072 ],
							[ 5.392153241000074, 6.019183670000075 ],
							[ 5.399711179000064, 6.02185679300004 ],
							[ 5.411169940000036, 6.02021988000007 ],
							[ 5.415844408000055, 6.010351631000049 ],
							[ 5.415374387000043, 6.008137677000036 ],
							[ 5.42590287400003, 6.005814490000034 ],
							[ 5.431141492000052, 6.00876088800004 ],
							[ 5.431468487000075, 6.01301716200004 ],
							[ 5.431141486000058, 6.017928060000031 ],
							[ 5.427212172000054, 6.023493751000046 ],
							[ 5.427539792000061, 6.030696410000076 ],
							[ 5.433464461000028, 6.03498698900006 ],
							[ 5.437034284000049, 6.037571944000035 ],
							[ 5.442483792000075, 6.040644127000064 ],
							[ 5.444632135000063, 6.039225625000029 ],
							[ 5.45209451900007, 6.034297958000025 ],
							[ 5.453060285000049, 6.027536860000055 ],
							[ 5.453403983000044, 6.025130776000026 ],
							[ 5.456191102000048, 6.022396534000052 ],
							[ 5.458463170000073, 6.023046051000051 ],
							[ 5.46551805100006, 6.027750057000048 ],
							[ 5.47367769300007, 6.027351811000074 ],
							[ 5.478940977000036, 6.027095337000048 ],
							[ 5.484179424000047, 6.027750078000054 ],
							[ 5.489745266000057, 6.030041729000061 ],
							[ 5.490273460000026, 6.033595082000033 ],
							[ 5.491382045000023, 6.038554136000073 ],
							[ 5.494328511000049, 6.041828168000052 ],
							[ 5.497275481000031, 6.042482782000036 ],
							[ 5.500876449000032, 6.036589660000061 ],
							[ 5.505787337000072, 6.03593492400006 ],
							[ 5.512989893000054, 6.035280223000029 ],
							[ 5.516591659000028, 6.030369251000025 ],
							[ 5.518883333000076, 6.027422406000028 ],
							[ 5.515282092000064, 6.02185681900005 ],
							[ 5.519210894000025, 6.016291190000061 ],
							[ 5.523467074000052, 6.012690003000046 ],
							[ 5.528377900000066, 6.012034837000044 ],
							[ 5.532633747000034, 6.01170783300006 ],
							[ 5.534598283000037, 6.009743196000045 ],
							[ 5.532306605000031, 6.004177627000047 ],
							[ 5.53659667900007, 5.989902054000027 ],
							[ 5.546327602000076, 5.985361643000033 ],
							[ 5.561079973000062, 5.98941183200003 ],
							[ 5.571273667000071, 5.990883831000076 ],
							[ 5.578019085000051, 6.003423240000075 ],
							[ 5.583425882000029, 6.011096 ],
							[ 5.593594980000034, 6.018116063000036 ],
							[ 5.605133945000034, 6.023066089000054 ],
							[ 5.616007918000037, 6.024542847000021 ],
							[ 5.626190268000073, 6.02878898900002 ],
							[ 5.637066901000026, 6.029572913000038 ],
							[ 5.657451969000022, 6.033209770000042 ],
							[ 5.667607290000035, 6.04986143900004 ],
							[ 5.668930094000075, 6.052030112000068 ],
							[ 5.673977931000024, 6.048922016000063 ],
							[ 5.698932998000032, 6.03355593200007 ],
							[ 5.745285025000044, 6.008976031000032 ],
							[ 5.756934757000067, 6.002093365000064 ],
							[ 5.76417742700005, 5.99781418200007 ],
							[ 5.795056902000056, 5.979569940000033 ],
							[ 5.812038802000075, 5.983872003000045 ],
							[ 5.822996052000065, 5.966627041000038 ],
							[ 5.830506682000021, 5.959753963000026 ],
							[ 5.835303723000038, 5.951471812000023 ],
							[ 5.833310038000036, 5.941052909000064 ],
							[ 5.921317030000068, 5.877292102000069 ],
							[ 5.910546830000044, 5.852237268000067 ],
							[ 5.906504235000057, 5.843882032000067 ],
							[ 5.90640413400007, 5.825979276000055 ],
							[ 5.902400943000032, 5.81105989200006 ],
							[ 5.89767075900005, 5.803782461000026 ],
							[ 5.894185192000066, 5.798591796000039 ],
							[ 5.902640887000075, 5.794611964000069 ],
							[ 5.906779639000035, 5.781470822000074 ],
							[ 5.906148982000047, 5.770370030000038 ],
							[ 5.917080979000048, 5.758672206000028 ],
							[ 5.924606723000068, 5.748332105000031 ],
							[ 5.940936923000038, 5.746387015000039 ],
							[ 5.958599083000024, 5.750695282000038 ],
							[ 5.969620103000068, 5.74880500300003 ],
							[ 5.981730981000055, 5.748114119000036 ],
							[ 5.991903766000064, 5.754439853000065 ],
							[ 6.004464521000045, 5.760642039000061 ],
							[ 6.00782496100004, 5.761753618000057 ],
							[ 6.01566224100003, 5.764347095000062 ],
							[ 6.019802725000034, 5.764380889000051 ],
							[ 6.024969422000026, 5.764423419000025 ],
							[ 6.027222250000023, 5.76444966300005 ],
							[ 6.031224788000031, 5.764495910000051 ],
							[ 6.039780199000063, 5.764610826000023 ],
							[ 6.044364499000039, 5.766866733000029 ],
							[ 6.048963980000053, 5.768785007000076 ],
							[ 6.061857344000032, 5.775133144000051 ],
							[ 6.07362553400003, 5.786473337000075 ],
							[ 6.07593724000003, 5.786094233000028 ],
							[ 6.080683628000031, 5.788235132000068 ],
							[ 6.093735220000042, 5.794122186000038 ],
							[ 6.101854928000023, 5.803205014000071 ],
							[ 6.107929126000045, 5.813658281000073 ],
							[ 6.111967026000059, 5.823400033000041 ],
							[ 6.120879071000047, 5.839725072000022 ],
							[ 6.130944833000058, 5.845688279000058 ],
							[ 6.132810235000022, 5.846793606000062 ],
							[ 6.142442331000041, 5.852086995000036 ],
							[ 6.151937068000052, 5.857713252000053 ],
							[ 6.160744168000065, 5.865415076000033 ],
							[ 6.168869904000076, 5.873110834000045 ],
							[ 6.179710033000049, 5.882216032000031 ],
							[ 6.191916951000053, 5.889945992000037 ],
							[ 6.198935389000042, 5.895133441000041 ],
							[ 6.201405943000054, 5.896959830000071 ],
							[ 6.203830194000034, 5.900574238000047 ],
							[ 6.20748715600007, 5.906025886000066 ],
							[ 6.217640858000038, 5.916511934000027 ],
							[ 6.223714853000047, 5.926965193000058 ],
							[ 6.229114978000041, 5.936026105000053 ],
							[ 6.233820082000022, 5.948546845000067 ],
							[ 6.243769716000031, 5.967384743000025 ],
							[ 6.245960153000055, 5.971532766000053 ],
							[ 6.247943881000026, 5.984033067000041 ],
							[ 6.250625251000031, 5.99307006600003 ],
							[ 6.256639924000069, 6.016697854000029 ],
							[ 6.240956858000061, 6.026276997000025 ],
							[ 6.232762834000027, 6.033836876000066 ],
							[ 6.223897926000063, 6.039310890000024 ],
							[ 6.210274216000073, 6.044745029000069 ],
							[ 6.201412287000039, 6.049526173000061 ],
							[ 6.193221946000051, 6.056393125000056 ],
							[ 6.185698863000027, 6.066039097000044 ],
							[ 6.176817923000044, 6.074979825000071 ],
							[ 6.169303874000036, 6.08254622100003 ],
							[ 6.163812231000065, 6.094288880000022 ],
							[ 6.156969181000022, 6.10394093900004 ],
							[ 6.148083166000049, 6.11427014800006 ],
							[ 6.14124020600002, 6.123920932000033 ],
							[ 6.13470716300003, 6.134811479000064 ],
							[ 6.134222601000033, 6.135543980000023 ],
							[ 6.119569342000034, 6.157698942000025 ],
							[ 6.110836508000034, 6.181171346000042 ],
							[ 6.106966008000029, 6.19772675400003 ],
							[ 6.106398058000025, 6.202002526000058 ],
							[ 6.108433898000044, 6.214859425000043 ],
							[ 6.112276172000065, 6.239651085000048 ],
							[ 6.112161246000028, 6.240878027000065 ],
							[ 6.095097032000069, 6.255299054000034 ],
							[ 6.084858915000041, 6.26353497100007 ],
							[ 6.076631948000056, 6.278722732000062 ],
							[ 6.086105770000074, 6.28920511900003 ],
							[ 6.086042009000039, 6.303767184000037 ],
							[ 6.088016880000055, 6.318347043000074 ],
							[ 6.094778107000025, 6.327417801000024 ],
							[ 6.100864070000057, 6.335096836000048 ],
							[ 6.106954071000075, 6.342083027000058 ],
							[ 6.113042904000054, 6.349068122000062 ],
							[ 6.117772018000039, 6.351524358000063 ],
							[ 6.126613217000056, 6.356115871000043 ],
							[ 6.140926864000051, 6.348607100000038 ],
							[ 6.150474951000035, 6.342443976000027 ],
							[ 6.158658922000029, 6.336965044000067 ],
							[ 6.167523836000044, 6.331491053000036 ],
							[ 6.180489892000026, 6.321196971000063 ],
							[ 6.188703012000076, 6.309475891000034 ],
							[ 6.193510957000058, 6.298419887000023 ],
							[ 6.19759881300007, 6.282746299000053 ],
							[ 6.212637875000041, 6.279160932000025 ],
							[ 6.223472123000022, 6.289653759000032 ],
							[ 6.236330981000037, 6.303630768000062 ],
							[ 6.24664413000005, 6.312650205000068 ],
							[ 6.253943916000026, 6.319034053000053 ],
							[ 6.261365826000031, 6.332272026000055 ],
							[ 6.270836893000023, 6.343445823000025 ],
							[ 6.281655757000067, 6.357406113000025 ],
							[ 6.290317970000046, 6.361868261000041 ],
							[ 6.298853358000031, 6.363377929000023 ],
							[ 6.304602678000037, 6.36504745700006 ],
							[ 6.307765428000039, 6.36596597700003 ],
							[ 6.312781342000051, 6.367324775000043 ],
							[ 6.319386909000059, 6.371263054000053 ],
							[ 6.322375173000069, 6.373044872000037 ],
							[ 6.324658830000033, 6.373845474000063 ],
							[ 6.331655541000032, 6.376447765000023 ],
							[ 6.336294613000064, 6.378173403000062 ],
							[ 6.341586010000071, 6.37841364600007 ],
							[ 6.349565855000037, 6.378776148000043 ],
							[ 6.357560244000069, 6.384293551000042 ],
							[ 6.36228939800003, 6.384439003000068 ],
							[ 6.365606856000056, 6.384541159000037 ],
							[ 6.37015393300004, 6.383304186000032 ],
							[ 6.379920943000059, 6.385480937000068 ],
							[ 6.385685805000037, 6.387722090000068 ],
							[ 6.391427135000072, 6.388796763000073 ],
							[ 6.397776213000043, 6.39161103400005 ],
							[ 6.401229724000075, 6.392722118000052 ],
							[ 6.406398783000043, 6.393806049000034 ],
							[ 6.41043700900002, 6.395491167000046 ],
							[ 6.412736078000023, 6.396036994000042 ],
							[ 6.415059111000062, 6.397748935000038 ],
							[ 6.419109701000025, 6.400015895000024 ],
							[ 6.423731903000032, 6.402274961000046 ],
							[ 6.428949756000065, 6.405688832000067 ],
							[ 6.431262039000046, 6.406817911000076 ],
							[ 6.433647290000067, 6.41144334300003 ],
							[ 6.436603904000037, 6.416058926000062 ],
							[ 6.440121306000037, 6.420083 ],
							[ 6.441873024000074, 6.421802920000061 ],
							[ 6.445375943000045, 6.42524469600005 ],
							[ 6.448867749000044, 6.42810395500004 ],
							[ 6.462081986000044, 6.437876668000058 ],
							[ 6.466182790000062, 6.440909823000027 ],
							[ 6.48050792600003, 6.447825808000061 ],
							[ 6.490446620000057, 6.455711242000064 ],
							[ 6.492447749000064, 6.456245762000037 ],
							[ 6.502198313000065, 6.463956942000038 ],
							[ 6.50962596100004, 6.475808055000073 ],
							[ 6.520029141000066, 6.483035438000059 ],
							[ 6.523318880000033, 6.487541214000032 ],
							[ 6.526188966000063, 6.488078035000058 ],
							[ 6.529631051000024, 6.488606822000065 ],
							[ 6.535428528000068, 6.490363728000034 ],
							[ 6.540155795000032, 6.492011903000048 ],
							[ 6.551711214000022, 6.493494933000022 ],
							[ 6.565308105000042, 6.494301678000056 ],
							[ 6.575504748000071, 6.495080046000055 ],
							[ 6.585692798000025, 6.497939037000037 ],
							[ 6.59725000100002, 6.498727731000031 ],
							[ 6.608806963000063, 6.499518059000025 ],
							[ 6.61889986400007, 6.496518636000076 ],
							[ 6.622426087000065, 6.495471106000025 ],
							[ 6.632654113000058, 6.489315146000024 ],
							[ 6.646256812000047, 6.488733683000021 ],
							[ 6.649354512000059, 6.49097781200004 ],
							[ 6.656365294000068, 6.496057575000066 ],
							[ 6.659813955000061, 6.498556208000025 ],
							[ 6.668921030000035, 6.501234142000044 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Ebonyi',
				admin1Pcod: 'NG011',
				admin1RefN: 'Ebonyi',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 4.489355,
				Shape_Area: 0.51805
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.244310478000045, 6.785595055000044 ],
							[ 8.249758435000047, 6.775604729000065 ],
							[ 8.249758698000051, 6.771063720000029 ],
							[ 8.249758572000076, 6.769190232000028 ],
							[ 8.249448575000031, 6.768473266000058 ],
							[ 8.255661927000062, 6.766069070000071 ],
							[ 8.261111116000052, 6.755170940000028 ],
							[ 8.266106468000032, 6.741548065000075 ],
							[ 8.262474214000065, 6.737915018000024 ],
							[ 8.256116849000023, 6.737461062000023 ],
							[ 8.25157553400004, 6.735644733000072 ],
							[ 8.261111047000043, 6.731103866000069 ],
							[ 8.26883097700005, 6.727925387000028 ],
							[ 8.273825687000055, 6.716572893000034 ],
							[ 8.273825536000061, 6.712940357000036 ],
							[ 8.280184021000025, 6.715210425000066 ],
							[ 8.285632082000063, 6.710215518000041 ],
							[ 8.280183636000061, 6.704766365000069 ],
							[ 8.278820836000023, 6.697955271000069 ],
							[ 8.283816288000025, 6.692505949000065 ],
							[ 8.294514797000033, 6.678708960000051 ],
							[ 8.301979750000044, 6.669801154000027 ],
							[ 8.306520220000039, 6.660719534000066 ],
							[ 8.304704825000044, 6.650729229000035 ],
							[ 8.309245309000062, 6.639377043000025 ],
							[ 8.315602180000042, 6.634381688000076 ],
							[ 8.322414113000036, 6.634381649000034 ],
							[ 8.325137842000061, 6.62575447200004 ],
							[ 8.324230257000067, 6.621213466000029 ],
							[ 8.321505528000046, 6.618488907000028 ],
							[ 8.321051741000076, 6.613947880000069 ],
							[ 8.314240181000059, 6.612131489000035 ],
							[ 8.314240494000046, 6.607136345000072 ],
							[ 8.321959585000059, 6.603049653000028 ],
							[ 8.323322569000027, 6.596238085000039 ],
							[ 8.324752039000032, 6.589499524000075 ],
							[ 8.330133634000049, 6.591697138000029 ],
							[ 8.333312311000043, 6.584885736000047 ],
							[ 8.340122981000036, 6.575349729000038 ],
							[ 8.346935520000045, 6.575349792000054 ],
							[ 8.351929863000066, 6.574441970000066 ],
							[ 8.358742018000044, 6.563997699000026 ],
							[ 8.363736052000036, 6.562180966000028 ],
							[ 8.370093132000022, 6.563089427000023 ],
							[ 8.374180971000044, 6.563543191000065 ],
							[ 8.372818156000051, 6.559002350000071 ],
							[ 8.372363863000032, 6.553099074000045 ],
							[ 8.371456096000031, 6.548104344000024 ],
							[ 8.375088561000041, 6.545834101000025 ],
							[ 8.379629983000029, 6.54855825900006 ],
							[ 8.386895080000045, 6.549920648000068 ],
							[ 8.391436468000052, 6.550374978000036 ],
							[ 8.393252348000033, 6.544471826000063 ],
							[ 8.396430986000041, 6.539476275000027 ],
							[ 8.400063567000075, 6.536297848000061 ],
							[ 8.399155661000066, 6.53221084200004 ],
							[ 8.393706309000038, 6.531302979000031 ],
							[ 8.390981563000025, 6.526308094000058 ],
							[ 8.390981773000021, 6.52312908600004 ],
							[ 8.395976954000048, 6.519496335000042 ],
							[ 8.410054389000038, 6.517226139000059 ],
							[ 8.415503403000059, 6.518588163000061 ],
							[ 8.419135941000036, 6.514501626000026 ],
							[ 8.420043966000037, 6.510414711000067 ],
							[ 8.420498121000037, 6.505419712000048 ],
							[ 8.412323952000065, 6.50042452100007 ],
							[ 8.41096194000005, 6.494521229000043 ],
							[ 8.408691130000022, 6.488618429000041 ],
							[ 8.405966547000048, 6.484985416000029 ],
							[ 8.408691202000057, 6.477719896000053 ],
							[ 8.422768469000061, 6.477719768000043 ],
							[ 8.423676496000041, 6.46636770200007 ],
							[ 8.430487418000041, 6.457739898000057 ],
							[ 8.438661496000066, 6.446841830000039 ],
							[ 8.435482723000064, 6.443662998000036 ],
							[ 8.431850191000024, 6.439576068000065 ],
							[ 8.432304505000047, 6.433219012000052 ],
							[ 8.442294142000037, 6.432764424000027 ],
							[ 8.445927638000057, 6.429132017000029 ],
							[ 8.452284870000028, 6.430040468000072 ],
							[ 8.456825385000059, 6.428223742000057 ],
							[ 8.456825037000044, 6.417779572000029 ],
							[ 8.455009616000041, 6.414600964000044 ],
							[ 8.455009594000046, 6.410059892000049 ],
							[ 8.453646781000032, 6.405973434000032 ],
							[ 8.447743439000021, 6.405065135000029 ],
							[ 8.444110790000025, 6.402340353000056 ],
							[ 8.447743513000034, 6.391896319000068 ],
							[ 8.445018872000048, 6.385538926000038 ],
							[ 8.430942609000056, 6.38099799500003 ],
							[ 8.413233086000048, 6.377819544000033 ],
							[ 8.407329452000056, 6.374640895000027 ],
							[ 8.403696827000033, 6.366013019000036 ],
							[ 8.399155611000026, 6.353752603000032 ],
							[ 8.397913009000035, 6.345054686000026 ],
							[ 8.395977211000059, 6.331502020000073 ],
							[ 8.391663293000022, 6.306527098000061 ],
							[ 8.394387251000069, 6.296990802000039 ],
							[ 8.405286099000023, 6.286092692000068 ],
							[ 8.410900010000034, 6.28211638700003 ],
							[ 8.412848432000033, 6.279149580000023 ],
							[ 8.409429669000076, 6.274952682000048 ],
							[ 8.40785323800003, 6.270589620000067 ],
							[ 8.407859638000048, 6.266625556000065 ],
							[ 8.409052463000023, 6.263456204000022 ],
							[ 8.417315503000054, 6.259611516000064 ],
							[ 8.411189254000021, 6.246132432000024 ],
							[ 8.405286027000045, 6.237050570000065 ],
							[ 8.398408152000059, 6.235293825000042 ],
							[ 8.393266044000029, 6.232510510000054 ],
							[ 8.38971650600007, 6.224180004000061 ],
							[ 8.383796042000029, 6.213466981000067 ],
							[ 8.373124637000046, 6.203142228000047 ],
							[ 8.367986587000075, 6.19837638000007 ],
							[ 8.356911518000061, 6.19280744100007 ],
							[ 8.351372581000021, 6.191211943000042 ],
							[ 8.347416089000035, 6.190015667000068 ],
							[ 8.344253003000063, 6.187631645000067 ],
							[ 8.342674900000077, 6.184853854000039 ],
							[ 8.340923332000045, 6.183445545000041 ],
							[ 8.335355554000046, 6.18573811400006 ],
							[ 8.330331127000022, 6.189924881000024 ],
							[ 8.320520668000029, 6.177281531000062 ],
							[ 8.317364323000049, 6.171726012000022 ],
							[ 8.317763627000033, 6.169744721000029 ],
							[ 8.320543429000054, 6.164992898000037 ],
							[ 8.319365603000051, 6.159837377000031 ],
							[ 8.316601332000062, 6.156264595000039 ],
							[ 8.312254190000033, 6.152688706000049 ],
							[ 8.307915551000065, 6.144355893000068 ],
							[ 8.308321410000076, 6.13920332500004 ],
							[ 8.309913988000062, 6.134052996000037 ],
							[ 8.313495685000021, 6.123753061000059 ],
							[ 8.315412110000068, 6.117651583000054 ],
							[ 8.314921516000027, 6.116261894000047 ],
							[ 8.309471823000024, 6.112629360000028 ],
							[ 8.302002686000037, 6.108136225000067 ],
							[ 8.301960004000023, 6.102396013000032 ],
							[ 8.300775273000056, 6.09666295300002 ],
							[ 8.298452518000033, 6.090939003000074 ],
							[ 8.298409323000044, 6.085197934000064 ],
							[ 8.298350216000074, 6.077161849000049 ],
							[ 8.298229260000028, 6.067536786000062 ],
							[ 8.291483999000036, 6.055393709000043 ],
							[ 8.288840419000053, 6.045703421000042 ],
							[ 8.287519476000057, 6.028965476000053 ],
							[ 8.28663801600004, 6.016632621000042 ],
							[ 8.282233481000048, 6.004739753000024 ],
							[ 8.274305058000039, 5.999013896000065 ],
							[ 8.263733856000044, 5.996811351000076 ],
							[ 8.258448465000072, 5.998132628000064 ],
							[ 8.254043815000045, 6.005180392000057 ],
							[ 8.252722996000045, 6.012228045000029 ],
							[ 8.249199112000042, 6.015751307000073 ],
							[ 8.244793877000063, 6.017953886000043 ],
							[ 8.238187073000063, 6.014870596000037 ],
							[ 8.230259066000031, 6.012668193000025 ],
							[ 8.222770644000036, 6.014430093000044 ],
							[ 8.214842736000037, 6.012228078000021 ],
							[ 8.204271500000061, 6.004299624000055 ],
							[ 8.193699676000051, 5.991966201000025 ],
							[ 8.179605286000026, 5.979192794000028 ],
							[ 8.172950841000045, 5.973770676000072 ],
							[ 8.167711967000059, 5.969502483000042 ],
							[ 8.153281890000073, 5.967316194000034 ],
							[ 8.148331580000047, 5.963776615000029 ],
							[ 8.136439211000038, 5.954967070000066 ],
							[ 8.122343924000063, 5.943515354000056 ],
							[ 8.113847668000062, 5.938149875000022 ],
							[ 8.111371223000049, 5.940678098000035 ],
							[ 8.109123214000022, 5.945284865000076 ],
							[ 8.108016728000052, 5.949884921000034 ],
							[ 8.106909642000062, 5.954484973000035 ],
							[ 8.105802528000027, 5.959085020000032 ],
							[ 8.104417720000072, 5.96120908100005 ],
							[ 8.09897748000003, 5.96194738500003 ],
							[ 8.090670240000065, 5.961530499000048 ],
							[ 8.087508408000076, 5.960333352000021 ],
							[ 8.083565145000023, 5.955170111000029 ],
							[ 8.077248633000067, 5.950000845000034 ],
							[ 8.074089389000051, 5.947614336000072 ],
							[ 8.06538611700006, 5.947592189000034 ],
							[ 8.057468046000054, 5.949950383000044 ],
							[ 8.053902871000048, 5.951923228000055 ],
							[ 8.05192076700007, 5.953503700000056 ],
							[ 8.051124325000046, 5.955483688000072 ],
							[ 8.051121251000041, 5.95667293200006 ],
							[ 8.054273741000031, 5.961438160000057 ],
							[ 8.059391194000057, 5.971361648000027 ],
							[ 8.060161254000036, 5.97968830700006 ],
							[ 8.059749596000074, 5.986029890000054 ],
							[ 8.058150526000077, 5.992368396000074 ],
							[ 8.055366613000047, 5.997911051000074 ],
							[ 8.049031275000061, 5.999876681000046 ],
							[ 8.039140954000061, 5.999454357000047 ],
							[ 8.035580895000066, 5.999048667000068 ],
							[ 8.031781857000055, 6.000487445000033 ],
							[ 8.029664944000046, 5.999794019000035 ],
							[ 8.02394367900007, 5.997537169000054 ],
							[ 8.014790779000066, 5.994154938000065 ],
							[ 8.009070246000022, 5.99189900600004 ],
							[ 8.001051177000022, 5.987360900000056 ],
							[ 7.993039301000067, 5.98397205200007 ],
							[ 7.98617682400004, 5.981722780000041 ],
							[ 7.980422044000022, 5.974873992000028 ],
							[ 7.979246886000055, 5.970290144000046 ],
							[ 7.979213395000045, 5.965697759000022 ],
							[ 7.981452267000066, 5.959941922000041 ],
							[ 7.984831542000052, 5.954177813000058 ],
							[ 7.988211856000021, 5.94841380400004 ],
							[ 7.989310025000066, 5.942666074000044 ],
							[ 7.984721103000027, 5.939252870000075 ],
							[ 7.98241368500004, 5.935824890000049 ],
							[ 7.982370938000031, 5.930085167000072 ],
							[ 7.982346073000031, 5.926640938000048 ],
							[ 7.985733720000042, 5.922025255000051 ],
							[ 7.993677984000044, 5.916230175000067 ],
							[ 8.000496832000067, 5.91273788500007 ],
							[ 8.002753053000049, 5.909278829000073 ],
							[ 8.006133227000021, 5.903514795000035 ],
							[ 8.004966713000044, 5.900077775000057 ],
							[ 7.999349604000031, 5.899542763000056 ],
							[ 7.994126866000045, 5.897304466000037 ],
							[ 7.993944919000057, 5.896819622000066 ],
							[ 7.989783564000049, 5.891100183000049 ],
							[ 7.986241584000027, 5.884747710000056 ],
							[ 7.977551971000025, 5.88036291700007 ],
							[ 7.970054555000047, 5.873999198000035 ],
							[ 7.96769058700005, 5.87082119400003 ],
							[ 7.966917294000041, 5.864476354000033 ],
							[ 7.966140660000065, 5.85932075900007 ],
							[ 7.964178808000042, 5.853765336000038 ],
							[ 7.961817261000022, 5.849794509000048 ],
							[ 7.95756689500007, 5.843023763000076 ],
							[ 7.95215758300003, 5.841531779000036 ],
							[ 7.945628875000068, 5.838733613000045 ],
							[ 7.938540726000042, 5.834443632000045 ],
							[ 7.934822064000059, 5.825934852000046 ],
							[ 7.933654764000039, 5.822497867000038 ],
							[ 7.933635895000066, 5.819872429000043 ],
							[ 7.942416559000037, 5.805083294000042 ],
							[ 7.951041911000061, 5.792906714000026 ],
							[ 7.964233369000056, 5.775656192000042 ],
							[ 7.970076360000064, 5.768326691000027 ],
							[ 7.965096127000038, 5.769378131000053 ],
							[ 7.959377853000035, 5.771650849000025 ],
							[ 7.954974136000033, 5.774130846000048 ],
							[ 7.951371950000066, 5.775062103000039 ],
							[ 7.949093764000054, 5.773901894000062 ],
							[ 7.945674018000034, 5.772736970000039 ],
							[ 7.942259878000073, 5.770422032000056 ],
							[ 7.936557617000062, 5.769247019000034 ],
							[ 7.932007034000037, 5.765778121000039 ],
							[ 7.927452057000039, 5.76345772600007 ],
							[ 7.921978990000071, 5.760117669000067 ],
							[ 7.920948591000069, 5.760085959000037 ],
							[ 7.916223011000056, 5.753729273000033 ],
							[ 7.913855022000064, 5.75213664100005 ],
							[ 7.91307235000005, 5.749359303000062 ],
							[ 7.913087601000029, 5.744205926000063 ],
							[ 7.913096847000077, 5.741081234000035 ],
							[ 7.914358245000074, 5.73827760000006 ],
							[ 7.913879071000054, 5.735808907000035 ],
							[ 7.912759111000071, 5.731205872000032 ],
							[ 7.911643710000021, 5.725453864000031 ],
							[ 7.908244975000059, 5.719692203000022 ],
							[ 7.904841780000027, 5.715077940000072 ],
							[ 7.90029104100006, 5.711608915000056 ],
							[ 7.894609868000032, 5.705835857000068 ],
							[ 7.886645058000056, 5.700051810000048 ],
							[ 7.88780304900007, 5.69660903700003 ],
							[ 7.890106943000035, 5.69202185100005 ],
							[ 7.890100026000027, 5.684799704000056 ],
							[ 7.886259341000027, 5.687846200000024 ],
							[ 7.870554614000071, 5.693556846000035 ],
							[ 7.861988669000027, 5.697363869000071 ],
							[ 7.840573117000076, 5.710688564000066 ],
							[ 7.830579626000031, 5.723062066000068 ],
							[ 7.820585659000074, 5.727820810000026 ],
							[ 7.815351161000024, 5.735910923000063 ],
							[ 7.803930087000026, 5.744953138000028 ],
							[ 7.796790871000042, 5.761609056000054 ],
							[ 7.793935864000048, 5.777313690000028 ],
							[ 7.792984109000031, 5.79159071600003 ],
							[ 7.786321889000021, 5.804915420000043 ],
							[ 7.780373146000045, 5.806818903000021 ],
							[ 7.764192295000043, 5.813957255000048 ],
							[ 7.756578414000046, 5.813005388000022 ],
							[ 7.728976790000047, 5.81586078600003 ],
							[ 7.713078744000029, 5.818000859000051 ],
							[ 7.70423048400005, 5.81919235600003 ],
							[ 7.683954931000073, 5.822346219000053 ],
							[ 7.657116623000036, 5.823951305000037 ],
							[ 7.650026825000054, 5.824476664000031 ],
							[ 7.630965567000032, 5.832315816000062 ],
							[ 7.626171728000031, 5.834143555000026 ],
							[ 7.622487368000066, 5.834498747000055 ],
							[ 7.612911476000022, 5.834831736000069 ],
							[ 7.605917072000068, 5.834066854000071 ],
							[ 7.595514055000024, 5.834386472000062 ],
							[ 7.584781440000029, 5.837275942000076 ],
							[ 7.570980526000028, 5.841083097000023 ],
							[ 7.56669762100006, 5.843938843000046 ],
							[ 7.552491313000075, 5.843480587000045 ],
							[ 7.551944996000032, 5.843462884000076 ],
							[ 7.544391577000056, 5.840471337000054 ],
							[ 7.539614244000063, 5.837868446000073 ],
							[ 7.535199275000025, 5.836743299000034 ],
							[ 7.532985728000028, 5.837841899000068 ],
							[ 7.53150061100007, 5.840788977000045 ],
							[ 7.527053056000057, 5.847784641000032 ],
							[ 7.522592537000037, 5.858102446000032 ],
							[ 7.52257916800005, 5.861424641000042 ],
							[ 7.524774164000064, 5.865124816000048 ],
							[ 7.526968648000036, 5.868825104000052 ],
							[ 7.527319673000022, 5.873256096000034 ],
							[ 7.526187758000049, 5.879896027000029 ],
							[ 7.520278598000061, 5.883932648000041 ],
							[ 7.500358344000063, 5.891603284000041 ],
							[ 7.498110587000042, 5.900822522000055 ],
							[ 7.49757730400006, 5.907660092000071 ],
							[ 7.518661551000037, 5.908042847000047 ],
							[ 7.53957179300005, 5.907232320000048 ],
							[ 7.547552210000049, 5.933350197000038 ],
							[ 7.542506520000074, 5.944190923000065 ],
							[ 7.540256563000071, 5.954148658000065 ],
							[ 7.540604877000021, 5.959317859000066 ],
							[ 7.546111651000047, 5.964138568000067 ],
							[ 7.552927325000041, 5.966341516000057 ],
							[ 7.553372531000036, 5.98147156400006 ],
							[ 7.547191706000035, 5.994943128000045 ],
							[ 7.559083787000077, 5.985483131000024 ],
							[ 7.573160677000033, 5.969135721000043 ],
							[ 7.589054284000042, 5.961416221000036 ],
							[ 7.635371866000071, 5.943252585000039 ],
							[ 7.664433615000064, 5.930084251000039 ],
							[ 7.678964660000076, 5.928721855000049 ],
							[ 7.691889462000063, 5.92464016200006 ],
							[ 7.704847866000023, 5.929175903000043 ],
							[ 7.714838197000063, 5.94597718700004 ],
							[ 7.711205105000033, 5.970952537000073 ],
							[ 7.69712835200005, 5.98866179700002 ],
							[ 7.675786089000042, 6.010004587000026 ],
							[ 7.675527213000066, 6.010617998000043 ],
							[ 7.670988431000069, 6.047668626000075 ],
							[ 7.668315227000051, 6.060618839000028 ],
							[ 7.669428333000042, 6.063587220000045 ],
							[ 7.686230089000048, 6.071306736000054 ],
							[ 7.698036050000042, 6.074031399000035 ],
							[ 7.713799297000037, 6.083923282000057 ],
							[ 7.726189951000038, 6.114899675000061 ],
							[ 7.738502653000069, 6.17030548200006 ],
							[ 7.73187278000006, 6.173342102000049 ],
							[ 7.730297246000021, 6.178021741000066 ],
							[ 7.73024859800006, 6.192076900000075 ],
							[ 7.730185530000028, 6.210296558000039 ],
							[ 7.731194563000031, 6.219149748000063 ],
							[ 7.733287182000026, 6.22495703900006 ],
							[ 7.733001534000039, 6.226606333000063 ],
							[ 7.725004742000067, 6.242600913000047 ],
							[ 7.721649288000037, 6.249311431000024 ],
							[ 7.708934689000046, 6.277919336000025 ],
							[ 7.708026635000067, 6.298353257000031 ],
							[ 7.720967975000065, 6.331048055000053 ],
							[ 7.738677255000027, 6.353752582000027 ],
							[ 7.751392596000073, 6.385539050000034 ],
							[ 7.750030250000066, 6.423228840000036 ],
							[ 7.749121908000063, 6.447295735000068 ],
							[ 7.74685151500006, 6.472724815000049 ],
							[ 7.730731110000022, 6.559910672000058 ],
							[ 7.717108495000048, 6.585340053000039 ],
							[ 7.703923711000073, 6.60705622200004 ],
							[ 7.701669077000076, 6.610769251000022 ],
							[ 7.698944704000041, 6.630749262000052 ],
							[ 7.705756016000066, 6.636198626000066 ],
							[ 7.724146666000024, 6.634836121000035 ],
							[ 7.737040899000021, 6.63407800400006 ],
							[ 7.741862639000033, 6.628331672000058 ],
							[ 7.749704262000023, 6.617006469000046 ],
							[ 7.753277580000031, 6.609214375000022 ],
							[ 7.762532400000055, 6.599312525000073 ],
							[ 7.768225911000059, 6.593655824000052 ],
							[ 7.776751915000034, 6.589427332000071 ],
							[ 7.78101455500007, 6.587313159000075 ],
							[ 7.783856685000046, 6.585903486000063 ],
							[ 7.788824179000073, 6.58521068400006 ],
							[ 7.791651650000063, 6.588057971000069 ],
							[ 7.795167808000031, 6.59729293700002 ],
							[ 7.807818187000066, 6.633518910000021 ],
							[ 7.808286619000057, 6.634216994000042 ],
							[ 7.821776742000054, 6.638922617000048 ],
							[ 7.831766562000041, 6.638468859000056 ],
							[ 7.833582776000071, 6.626208169000051 ],
							[ 7.833582982000053, 6.619396602000052 ],
							[ 7.836761673000069, 6.61485576900003 ],
							[ 7.844027285000038, 6.608498419000057 ],
							[ 7.84675174900002, 6.604411599000059 ],
							[ 7.852654932000064, 6.588064549000023 ],
							[ 7.855833717000053, 6.580799197000033 ],
							[ 7.869002240000043, 6.568992670000057 ],
							[ 7.871888456000022, 6.566683864000026 ],
							[ 7.878084389000037, 6.561726953000061 ],
							[ 7.887166045000072, 6.552645132000066 ],
							[ 7.890249109000024, 6.551475855000035 ],
							[ 7.896738572000061, 6.551495464000027 ],
							[ 7.904540405000034, 6.551518886000053 ],
							[ 7.910925219000035, 6.550828370000033 ],
							[ 7.929404789000046, 6.537402384000075 ],
							[ 7.932356556000059, 6.531763086000069 ],
							[ 7.949376722000068, 6.532210755000051 ],
							[ 7.956641997000077, 6.540384807000066 ],
							[ 7.962545383000077, 6.54401718500003 ],
							[ 7.966632128000072, 6.557640031000062 ],
							[ 7.970719460000055, 6.568538772000068 ],
							[ 7.971551574000046, 6.570586694000042 ],
							[ 7.968300900000031, 6.576536579000049 ],
							[ 7.965421513000024, 6.591428029000042 ],
							[ 7.971065948000046, 6.60208641600002 ],
							[ 7.97955819200007, 6.609205176000046 ],
							[ 7.987458250000032, 6.613183484000047 ],
							[ 7.988428589000023, 6.618034944000044 ],
							[ 7.987066513000059, 6.633473816000048 ],
							[ 7.987488527000039, 6.647822355000073 ],
							[ 7.982244041000058, 6.663843863000068 ],
							[ 7.982941431000029, 6.668102830000066 ],
							[ 7.98615185400007, 6.672408630000064 ],
							[ 7.988428771000031, 6.687964900000054 ],
							[ 7.994786110000064, 6.703858485000069 ],
							[ 8.000557789000027, 6.712121314000058 ],
							[ 8.000555802000065, 6.712849513000037 ],
							[ 8.006222379000064, 6.716412393000041 ],
							[ 8.014725609000038, 6.720692399000029 ],
							[ 8.021813563000023, 6.723549319000028 ],
							[ 8.028914813000029, 6.721439839000027 ],
							[ 8.038200732000064, 6.711914898000032 ],
							[ 8.050866117000055, 6.709307249000062 ],
							[ 8.065397521000023, 6.711577815000055 ],
							[ 8.075387773000045, 6.71384824200004 ],
							[ 8.079565762000072, 6.713152040000068 ],
							[ 8.089010112000039, 6.711577957000031 ],
							[ 8.095367309000039, 6.707944781000037 ],
							[ 8.098545892000061, 6.707037073000038 ],
							[ 8.103995048000058, 6.712031719000038 ],
							[ 8.112169253000047, 6.70930721600007 ],
							[ 8.127608169000041, 6.711577808000072 ],
							[ 8.136236410000038, 6.716118924000057 ],
							[ 8.144409447000044, 6.725200630000074 ],
							[ 8.14531811300003, 6.734736342000076 ],
							[ 8.147133808000035, 6.742909886000064 ],
							[ 8.149062866000065, 6.747088881000025 ],
							[ 8.15258308600005, 6.754716446000032 ],
							[ 8.160302935000061, 6.761074111000028 ],
							[ 8.163930276000031, 6.765031425000075 ],
							[ 8.170113315000037, 6.763500908000026 ],
							[ 8.180964223000046, 6.763525324000057 ],
							[ 8.185297673000036, 6.766789710000069 ],
							[ 8.193693203000066, 6.778329490000033 ],
							[ 8.199355191000052, 6.778329479000035 ],
							[ 8.212977446000025, 6.776058532000036 ],
							[ 8.220243542000048, 6.778783424000039 ],
							[ 8.226146485000072, 6.789681904000076 ],
							[ 8.229325312000071, 6.800125554000033 ],
							[ 8.234319625000069, 6.799217652000038 ],
							[ 8.240676706000045, 6.792406102000029 ],
							[ 8.24130664300003, 6.791225955000073 ],
							[ 8.244310478000045, 6.785595055000044 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Edo',
				admin1Pcod: 'NG012',
				admin1RefN: 'Edo',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 7.889425,
				Shape_Area: 1.595809
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.133570740000039, 7.552076860000057 ],
							[ 6.138349193000067, 7.55045315600006 ],
							[ 6.142948063000063, 7.550999291000039 ],
							[ 6.146391785000048, 7.550970975000041 ],
							[ 6.149836079000067, 7.55094291000006 ],
							[ 6.153279798000028, 7.550914789000046 ],
							[ 6.155569189000062, 7.550313131000053 ],
							[ 6.160735191000072, 7.550271165000026 ],
							[ 6.164177779000056, 7.550241822000032 ],
							[ 6.165878762000034, 7.548480091000044 ],
							[ 6.166984003000039, 7.544972838000035 ],
							[ 6.165800183000044, 7.542067857000063 ],
							[ 6.16469545700005, 7.538316060000056 ],
							[ 6.162255901000037, 7.533937083000069 ],
							[ 6.159916754000051, 7.530458028000055 ],
							[ 6.158284262000052, 7.52829782200007 ],
							[ 6.155834249000065, 7.52524511200005 ],
							[ 6.154077003000054, 7.522345 ],
							[ 6.150588871000025, 7.518876179000074 ],
							[ 6.146535998000047, 7.515994929000044 ],
							[ 6.142488843000024, 7.513696060000029 ],
							[ 6.137860844000045, 7.510819013000059 ],
							[ 6.135551093000061, 7.509672108000075 ],
							[ 6.131497024000055, 7.506791236000026 ],
							[ 6.128605900000025, 7.50506499200003 ],
							[ 6.125720956000066, 7.503922859000056 ],
							[ 6.122255763000055, 7.502203090000023 ],
							[ 6.118217050000055, 7.500486971000043 ],
							[ 6.114750784000023, 7.498765991000027 ],
							[ 6.112433055000054, 7.49703592000003 ],
							[ 6.11010217300003, 7.494140966000032 ],
							[ 6.108335888000056, 7.490657857000031 ],
							[ 6.108881976000021, 7.48832186900006 ],
							[ 6.108257753000032, 7.484245886000053 ],
							[ 6.108795981000071, 7.481328007000059 ],
							[ 6.108738841000047, 7.476665051000055 ],
							[ 6.109794271000055, 7.469077994000031 ],
							[ 6.109147927000038, 7.463254093000046 ],
							[ 6.10966398000005, 7.458587164000051 ],
							[ 6.110196015000042, 7.455084867000039 ],
							[ 6.112411976000033, 7.448655029000065 ],
							[ 6.118698252000058, 7.446270868000056 ],
							[ 6.127882098000043, 7.446197037000047 ],
							[ 6.133061846000032, 7.447320069000057 ],
							[ 6.138235120000047, 7.447861168000031 ],
							[ 6.142847944000039, 7.449572210000042 ],
							[ 6.147432845000026, 7.448952278000036 ],
							[ 6.154314119000048, 7.448313373000076 ],
							[ 6.159473004000063, 7.447688146000075 ],
							[ 6.163475899000048, 7.446488757000054 ],
							[ 6.166899327000067, 7.444713210000032 ],
							[ 6.170909823000045, 7.444097052000075 ],
							[ 6.173170111000047, 7.441164086000072 ],
							[ 6.174274958000069, 7.43765677500005 ],
							[ 6.175953770000035, 7.434145970000031 ],
							[ 6.17992201800007, 7.430033330000072 ],
							[ 6.182785168000066, 7.429427153000063 ],
							[ 6.186272191000057, 7.432896233000065 ],
							[ 6.186306949000027, 7.435811007000041 ],
							[ 6.186916868000026, 7.43872028100003 ],
							[ 6.189808883000069, 7.440444950000028 ],
							[ 6.193274092000024, 7.442165792000026 ],
							[ 6.200786054000048, 7.446185141000058 ],
							[ 6.208865078000031, 7.44961581900003 ],
							[ 6.218709093000029, 7.456531115000075 ],
							[ 6.221047757000065, 7.460008968000068 ],
							[ 6.223393945000055, 7.464070671000059 ],
							[ 6.225323300000071, 7.468395656000041 ],
							[ 6.226923948000035, 7.471036838000032 ],
							[ 6.233274069000061, 7.473898914000074 ],
							[ 6.23788679200004, 7.475611308000055 ],
							[ 6.24479006100006, 7.476719719000073 ],
							[ 6.252840094000021, 7.477819934000024 ],
							[ 6.255716821000021, 7.478380310000034 ],
							[ 6.259168230000057, 7.478933677000043 ],
							[ 6.263773883000056, 7.480062996000072 ],
							[ 6.267814173000033, 7.48177922800005 ],
							[ 6.270139253000025, 7.484090749000075 ],
							[ 6.271889101000056, 7.486409277000064 ],
							[ 6.273073304000036, 7.489314182000044 ],
							[ 6.274838057000068, 7.492795901000022 ],
							[ 6.276596107000046, 7.495697143000029 ],
							[ 6.284101099000054, 7.499133182000037 ],
							[ 6.290988948000063, 7.499076974000047 ],
							[ 6.292740607000042, 7.498807890000023 ],
							[ 6.295756942000025, 7.494733051000026 ],
							[ 6.324513164000052, 7.444304599000077 ],
							[ 6.336629023000057, 7.434093105000045 ],
							[ 6.357437035000032, 7.411069481000027 ],
							[ 6.359298799000044, 7.411565518000032 ],
							[ 6.362790121000046, 7.409305148000044 ],
							[ 6.367911868000022, 7.405765917000053 ],
							[ 6.375302746000045, 7.39987598600004 ],
							[ 6.378143891000036, 7.397520968000038 ],
							[ 6.422244922000061, 7.416828051000039 ],
							[ 6.426575102000072, 7.414612802000022 ],
							[ 6.427671912000051, 7.410524010000074 ],
							[ 6.432164055000044, 7.402327147000051 ],
							[ 6.432105923000051, 7.397663970000053 ],
							[ 6.422241230000054, 7.388999996000052 ],
							[ 6.423353292000058, 7.386076082000045 ],
							[ 6.426746833000038, 7.381968070000028 ],
							[ 6.432457920000047, 7.379590927000038 ],
							[ 6.434144072000038, 7.37666166200006 ],
							[ 6.434676269000022, 7.373159759000032 ],
							[ 6.43460420100007, 7.36733197500007 ],
							[ 6.435657907000063, 7.359745113000031 ],
							[ 6.438507008000045, 7.357973196000046 ],
							[ 6.442511099000058, 7.356773892000035 ],
							[ 6.450531986000044, 7.355543135000062 ],
							[ 6.455152911000027, 7.357837214000028 ],
							[ 6.459781164000049, 7.36071398200005 ],
							[ 6.463261144000057, 7.363599681000039 ],
							[ 6.466869880000047, 7.365318657000046 ],
							[ 6.47037206300007, 7.363700762000065 ],
							[ 6.472981883000045, 7.360606163000057 ],
							[ 6.475794880000024, 7.355919801000027 ],
							[ 6.477480841000045, 7.352992038000025 ],
							[ 6.480867859000057, 7.348299952000048 ],
							[ 6.485763157000065, 7.341996211000037 ],
							[ 6.488789048000058, 7.338909145000059 ],
							[ 6.492205094000042, 7.336550124000041 ],
							[ 6.494472015000042, 7.334199023000053 ],
							[ 6.497326843000053, 7.333010220000062 ],
							[ 6.499551643000075, 7.327163341000073 ],
							[ 6.499508751000064, 7.323666182000068 ],
							[ 6.498899090000066, 7.320756004000032 ],
							[ 6.498295650000045, 7.318429122000055 ],
							[ 6.499925095000037, 7.310838086000047 ],
							[ 6.499846082000033, 7.304427024000063 ],
							[ 6.499228842000036, 7.300933855000039 ],
							[ 6.499760009000056, 7.297431825000047 ],
							[ 6.499695675000055, 7.292187329000058 ],
							[ 6.501359975000071, 7.287509878000037 ],
							[ 6.503621157000055, 7.284576870000024 ],
							[ 6.50587327300002, 7.281061107000028 ],
							[ 6.508112027000038, 7.276380176000032 ],
							[ 6.508069067000065, 7.272881880000057 ],
							[ 6.508032885000034, 7.269967995000059 ],
							[ 6.511441099000024, 7.267024911000021 ],
							[ 6.514884929000061, 7.266996825000035 ],
							[ 6.525848017000044, 7.271571219000066 ],
							[ 6.529880971000068, 7.272704119000025 ],
							[ 6.537356967000051, 7.273808949000056 ],
							[ 6.544260121000036, 7.274919110000042 ],
							[ 6.556313034000027, 7.274819872000023 ],
							[ 6.569522033000055, 7.275294724000048 ],
							[ 6.572323420000032, 7.276078490000032 ],
							[ 6.575073617000044, 7.273528343000066 ],
							[ 6.592249475000074, 7.276216285000032 ],
							[ 6.607712365000054, 7.278034073000072 ],
							[ 6.637670056000047, 7.294765064000046 ],
							[ 6.669155147000026, 7.240518017000056 ],
							[ 6.674722232000022, 7.233436952000034 ],
							[ 6.678113869000072, 7.231634968000037 ],
							[ 6.683232788000055, 7.230389227000046 ],
							[ 6.685318590000065, 7.223532116000058 ],
							[ 6.690157355000053, 7.21957291700005 ],
							[ 6.69330491900007, 7.216998109000031 ],
							[ 6.697583608000059, 7.20416153900004 ],
							[ 6.700792678000028, 7.178488122000033 ],
							[ 6.703430038000022, 7.170575532000043 ],
							[ 6.707210948000068, 7.159233101000041 ],
							[ 6.711490074000039, 7.148535751000054 ],
							[ 6.715323562000037, 7.137034737000022 ],
							[ 6.715768731000026, 7.135699217000024 ],
							[ 6.715768789000037, 7.12282134000003 ],
							[ 6.715768871000023, 7.120723195000039 ],
							[ 6.711490244000061, 7.104677201000072 ],
							[ 6.690095502000077, 7.068306991000043 ],
							[ 6.683835609000027, 7.051613754000073 ],
							[ 6.677258902000062, 7.034075751000046 ],
							[ 6.677258923000068, 7.008402325000077 ],
							[ 6.678328556000054, 6.993426206000038 ],
							[ 6.678561991000038, 6.988056699000026 ],
							[ 6.679260241000065, 6.972002420000024 ],
							[ 6.679398608000042, 6.968822600000067 ],
							[ 6.67298033000003, 6.935661313000026 ],
							[ 6.65265560000006, 6.907848225000066 ],
							[ 6.646236778000059, 6.885384586000043 ],
							[ 6.634469919000026, 6.866129508000029 ],
							[ 6.621633657000075, 6.841525476000072 ],
							[ 6.614145333000067, 6.830828570000051 ],
							[ 6.610790139000073, 6.822104903000024 ],
							[ 6.613770048000049, 6.814801230000057 ],
							[ 6.61594403600003, 6.809521205000067 ],
							[ 6.617597020000062, 6.80658096600007 ],
							[ 6.620317864000071, 6.800128086000029 ],
							[ 6.623660950000044, 6.795996113000058 ],
							[ 6.623610081000038, 6.793598074000045 ],
							[ 6.623549128000036, 6.790752933000022 ],
							[ 6.627364134000061, 6.781950922000021 ],
							[ 6.629514163000067, 6.775506996000047 ],
							[ 6.631689042000062, 6.769058144000041 ],
							[ 6.62802176200006, 6.748887443000058 ],
							[ 6.626187918000028, 6.731467652000049 ],
							[ 6.621603490000041, 6.722299189000069 ],
							[ 6.617936031000056, 6.704879296000058 ],
							[ 6.62802167500007, 6.674623595000071 ],
							[ 6.652776338000024, 6.634282099000075 ],
							[ 6.660110903000032, 6.608610556000031 ],
							[ 6.661944921000043, 6.57285365000007 ],
							[ 6.661944801000061, 6.552682781000044 ],
							[ 6.664701088000072, 6.532356273000062 ],
							[ 6.668921030000035, 6.501234142000044 ],
							[ 6.659813955000061, 6.498556208000025 ],
							[ 6.656365294000068, 6.496057575000066 ],
							[ 6.649354512000059, 6.49097781200004 ],
							[ 6.646256812000047, 6.488733683000021 ],
							[ 6.632654113000058, 6.489315146000024 ],
							[ 6.622426087000065, 6.495471106000025 ],
							[ 6.61889986400007, 6.496518636000076 ],
							[ 6.608806963000063, 6.499518059000025 ],
							[ 6.59725000100002, 6.498727731000031 ],
							[ 6.585692798000025, 6.497939037000037 ],
							[ 6.575504748000071, 6.495080046000055 ],
							[ 6.565308105000042, 6.494301678000056 ],
							[ 6.551711214000022, 6.493494933000022 ],
							[ 6.540155795000032, 6.492011903000048 ],
							[ 6.535428528000068, 6.490363728000034 ],
							[ 6.529631051000024, 6.488606822000065 ],
							[ 6.526188966000063, 6.488078035000058 ],
							[ 6.523318880000033, 6.487541214000032 ],
							[ 6.520029141000066, 6.483035438000059 ],
							[ 6.50962596100004, 6.475808055000073 ],
							[ 6.502198313000065, 6.463956942000038 ],
							[ 6.492447749000064, 6.456245762000037 ],
							[ 6.490446620000057, 6.455711242000064 ],
							[ 6.48050792600003, 6.447825808000061 ],
							[ 6.466182790000062, 6.440909823000027 ],
							[ 6.462081986000044, 6.437876668000058 ],
							[ 6.448867749000044, 6.42810395500004 ],
							[ 6.445375943000045, 6.42524469600005 ],
							[ 6.441873024000074, 6.421802920000061 ],
							[ 6.440121306000037, 6.420083 ],
							[ 6.436603904000037, 6.416058926000062 ],
							[ 6.433647290000067, 6.41144334300003 ],
							[ 6.431262039000046, 6.406817911000076 ],
							[ 6.428949756000065, 6.405688832000067 ],
							[ 6.423731903000032, 6.402274961000046 ],
							[ 6.419109701000025, 6.400015895000024 ],
							[ 6.415059111000062, 6.397748935000038 ],
							[ 6.412736078000023, 6.396036994000042 ],
							[ 6.41043700900002, 6.395491167000046 ],
							[ 6.406398783000043, 6.393806049000034 ],
							[ 6.401229724000075, 6.392722118000052 ],
							[ 6.397776213000043, 6.39161103400005 ],
							[ 6.391427135000072, 6.388796763000073 ],
							[ 6.385685805000037, 6.387722090000068 ],
							[ 6.379920943000059, 6.385480937000068 ],
							[ 6.37015393300004, 6.383304186000032 ],
							[ 6.365606856000056, 6.384541159000037 ],
							[ 6.36228939800003, 6.384439003000068 ],
							[ 6.357560244000069, 6.384293551000042 ],
							[ 6.349565855000037, 6.378776148000043 ],
							[ 6.341586010000071, 6.37841364600007 ],
							[ 6.336294613000064, 6.378173403000062 ],
							[ 6.331655541000032, 6.376447765000023 ],
							[ 6.324658830000033, 6.373845474000063 ],
							[ 6.322375173000069, 6.373044872000037 ],
							[ 6.319386909000059, 6.371263054000053 ],
							[ 6.312781342000051, 6.367324775000043 ],
							[ 6.307765428000039, 6.36596597700003 ],
							[ 6.304602678000037, 6.36504745700006 ],
							[ 6.298853358000031, 6.363377929000023 ],
							[ 6.290317970000046, 6.361868261000041 ],
							[ 6.281655757000067, 6.357406113000025 ],
							[ 6.270836893000023, 6.343445823000025 ],
							[ 6.261365826000031, 6.332272026000055 ],
							[ 6.253943916000026, 6.319034053000053 ],
							[ 6.24664413000005, 6.312650205000068 ],
							[ 6.236330981000037, 6.303630768000062 ],
							[ 6.223472123000022, 6.289653759000032 ],
							[ 6.212637875000041, 6.279160932000025 ],
							[ 6.19759881300007, 6.282746299000053 ],
							[ 6.193510957000058, 6.298419887000023 ],
							[ 6.188703012000076, 6.309475891000034 ],
							[ 6.180489892000026, 6.321196971000063 ],
							[ 6.167523836000044, 6.331491053000036 ],
							[ 6.158658922000029, 6.336965044000067 ],
							[ 6.150474951000035, 6.342443976000027 ],
							[ 6.140926864000051, 6.348607100000038 ],
							[ 6.126613217000056, 6.356115871000043 ],
							[ 6.117772018000039, 6.351524358000063 ],
							[ 6.113042904000054, 6.349068122000062 ],
							[ 6.106954071000075, 6.342083027000058 ],
							[ 6.100864070000057, 6.335096836000048 ],
							[ 6.094778107000025, 6.327417801000024 ],
							[ 6.088016880000055, 6.318347043000074 ],
							[ 6.086042009000039, 6.303767184000037 ],
							[ 6.086105770000074, 6.28920511900003 ],
							[ 6.076631948000056, 6.278722732000062 ],
							[ 6.084858915000041, 6.26353497100007 ],
							[ 6.095097032000069, 6.255299054000034 ],
							[ 6.112161246000028, 6.240878027000065 ],
							[ 6.112276172000065, 6.239651085000048 ],
							[ 6.108433898000044, 6.214859425000043 ],
							[ 6.106398058000025, 6.202002526000058 ],
							[ 6.106966008000029, 6.19772675400003 ],
							[ 6.110836508000034, 6.181171346000042 ],
							[ 6.119569342000034, 6.157698942000025 ],
							[ 6.134222601000033, 6.135543980000023 ],
							[ 6.13470716300003, 6.134811479000064 ],
							[ 6.14124020600002, 6.123920932000033 ],
							[ 6.148083166000049, 6.11427014800006 ],
							[ 6.156969181000022, 6.10394093900004 ],
							[ 6.163812231000065, 6.094288880000022 ],
							[ 6.169303874000036, 6.08254622100003 ],
							[ 6.176817923000044, 6.074979825000071 ],
							[ 6.185698863000027, 6.066039097000044 ],
							[ 6.193221946000051, 6.056393125000056 ],
							[ 6.201412287000039, 6.049526173000061 ],
							[ 6.210274216000073, 6.044745029000069 ],
							[ 6.223897926000063, 6.039310890000024 ],
							[ 6.232762834000027, 6.033836876000066 ],
							[ 6.240956858000061, 6.026276997000025 ],
							[ 6.256639924000069, 6.016697854000029 ],
							[ 6.250625251000031, 5.99307006600003 ],
							[ 6.247943881000026, 5.984033067000041 ],
							[ 6.245960153000055, 5.971532766000053 ],
							[ 6.243769716000031, 5.967384743000025 ],
							[ 6.233820082000022, 5.948546845000067 ],
							[ 6.229114978000041, 5.936026105000053 ],
							[ 6.223714853000047, 5.926965193000058 ],
							[ 6.217640858000038, 5.916511934000027 ],
							[ 6.20748715600007, 5.906025886000066 ],
							[ 6.203830194000034, 5.900574238000047 ],
							[ 6.201405943000054, 5.896959830000071 ],
							[ 6.198935389000042, 5.895133441000041 ],
							[ 6.191916951000053, 5.889945992000037 ],
							[ 6.179710033000049, 5.882216032000031 ],
							[ 6.168869904000076, 5.873110834000045 ],
							[ 6.160744168000065, 5.865415076000033 ],
							[ 6.151937068000052, 5.857713252000053 ],
							[ 6.142442331000041, 5.852086995000036 ],
							[ 6.132810235000022, 5.846793606000062 ],
							[ 6.130944833000058, 5.845688279000058 ],
							[ 6.120879071000047, 5.839725072000022 ],
							[ 6.111967026000059, 5.823400033000041 ],
							[ 6.107929126000045, 5.813658281000073 ],
							[ 6.101854928000023, 5.803205014000071 ],
							[ 6.093735220000042, 5.794122186000038 ],
							[ 6.080683628000031, 5.788235132000068 ],
							[ 6.07593724000003, 5.786094233000028 ],
							[ 6.07362553400003, 5.786473337000075 ],
							[ 6.061857344000032, 5.775133144000051 ],
							[ 6.048963980000053, 5.768785007000076 ],
							[ 6.044364499000039, 5.766866733000029 ],
							[ 6.039780199000063, 5.764610826000023 ],
							[ 6.031224788000031, 5.764495910000051 ],
							[ 6.027222250000023, 5.76444966300005 ],
							[ 6.024969422000026, 5.764423419000025 ],
							[ 6.019802725000034, 5.764380889000051 ],
							[ 6.01566224100003, 5.764347095000062 ],
							[ 6.00782496100004, 5.761753618000057 ],
							[ 6.004464521000045, 5.760642039000061 ],
							[ 5.991903766000064, 5.754439853000065 ],
							[ 5.981730981000055, 5.748114119000036 ],
							[ 5.969620103000068, 5.74880500300003 ],
							[ 5.958599083000024, 5.750695282000038 ],
							[ 5.940936923000038, 5.746387015000039 ],
							[ 5.924606723000068, 5.748332105000031 ],
							[ 5.917080979000048, 5.758672206000028 ],
							[ 5.906148982000047, 5.770370030000038 ],
							[ 5.906779639000035, 5.781470822000074 ],
							[ 5.902640887000075, 5.794611964000069 ],
							[ 5.894185192000066, 5.798591796000039 ],
							[ 5.89767075900005, 5.803782461000026 ],
							[ 5.902400943000032, 5.81105989200006 ],
							[ 5.90640413400007, 5.825979276000055 ],
							[ 5.906504235000057, 5.843882032000067 ],
							[ 5.910546830000044, 5.852237268000067 ],
							[ 5.921317030000068, 5.877292102000069 ],
							[ 5.833310038000036, 5.941052909000064 ],
							[ 5.835303723000038, 5.951471812000023 ],
							[ 5.830506682000021, 5.959753963000026 ],
							[ 5.822996052000065, 5.966627041000038 ],
							[ 5.812038802000075, 5.983872003000045 ],
							[ 5.795056902000056, 5.979569940000033 ],
							[ 5.76417742700005, 5.99781418200007 ],
							[ 5.756934757000067, 6.002093365000064 ],
							[ 5.745285025000044, 6.008976031000032 ],
							[ 5.698932998000032, 6.03355593200007 ],
							[ 5.673977931000024, 6.048922016000063 ],
							[ 5.668930094000075, 6.052030112000068 ],
							[ 5.667607290000035, 6.04986143900004 ],
							[ 5.657451969000022, 6.033209770000042 ],
							[ 5.637066901000026, 6.029572913000038 ],
							[ 5.626190268000073, 6.02878898900002 ],
							[ 5.616007918000037, 6.024542847000021 ],
							[ 5.605133945000034, 6.023066089000054 ],
							[ 5.593594980000034, 6.018116063000036 ],
							[ 5.583425882000029, 6.011096 ],
							[ 5.578019085000051, 6.003423240000075 ],
							[ 5.571273667000071, 5.990883831000076 ],
							[ 5.561079973000062, 5.98941183200003 ],
							[ 5.546327602000076, 5.985361643000033 ],
							[ 5.53659667900007, 5.989902054000027 ],
							[ 5.532306605000031, 6.004177627000047 ],
							[ 5.534598283000037, 6.009743196000045 ],
							[ 5.532633747000034, 6.01170783300006 ],
							[ 5.528377900000066, 6.012034837000044 ],
							[ 5.523467074000052, 6.012690003000046 ],
							[ 5.519210894000025, 6.016291190000061 ],
							[ 5.515282092000064, 6.02185681900005 ],
							[ 5.518883333000076, 6.027422406000028 ],
							[ 5.516591659000028, 6.030369251000025 ],
							[ 5.512989893000054, 6.035280223000029 ],
							[ 5.505787337000072, 6.03593492400006 ],
							[ 5.500876449000032, 6.036589660000061 ],
							[ 5.497275481000031, 6.042482782000036 ],
							[ 5.494328511000049, 6.041828168000052 ],
							[ 5.491382045000023, 6.038554136000073 ],
							[ 5.490273460000026, 6.033595082000033 ],
							[ 5.489745266000057, 6.030041729000061 ],
							[ 5.484179424000047, 6.027750078000054 ],
							[ 5.478940977000036, 6.027095337000048 ],
							[ 5.47367769300007, 6.027351811000074 ],
							[ 5.46551805100006, 6.027750057000048 ],
							[ 5.458463170000073, 6.023046051000051 ],
							[ 5.456191102000048, 6.022396534000052 ],
							[ 5.453403983000044, 6.025130776000026 ],
							[ 5.453060285000049, 6.027536860000055 ],
							[ 5.45209451900007, 6.034297958000025 ],
							[ 5.444632135000063, 6.039225625000029 ],
							[ 5.442483792000075, 6.040644127000064 ],
							[ 5.437034284000049, 6.037571944000035 ],
							[ 5.433464461000028, 6.03498698900006 ],
							[ 5.427539792000061, 6.030696410000076 ],
							[ 5.427212172000054, 6.023493751000046 ],
							[ 5.431141486000058, 6.017928060000031 ],
							[ 5.431468487000075, 6.01301716200004 ],
							[ 5.431141492000052, 6.00876088800004 ],
							[ 5.42590287400003, 6.005814490000034 ],
							[ 5.415374387000043, 6.008137677000036 ],
							[ 5.415844408000055, 6.010351631000049 ],
							[ 5.411169940000036, 6.02021988000007 ],
							[ 5.399711179000064, 6.02185679300004 ],
							[ 5.392153241000074, 6.019183670000075 ],
							[ 5.388225199000033, 6.017794072000072 ],
							[ 5.377835868000034, 6.019218877000071 ],
							[ 5.365658290000056, 6.019653761000029 ],
							[ 5.356524819000072, 6.017479416000072 ],
							[ 5.342172624000057, 6.016174356000022 ],
							[ 5.33824968600004, 6.018789819000062 ],
							[ 5.334344002000023, 6.021393262000061 ],
							[ 5.327385426000035, 6.018349230000069 ],
							[ 5.323036232000049, 6.005301473000031 ],
							[ 5.326950680000039, 5.994428680000055 ],
							[ 5.326080916000024, 5.990079373000071 ],
							[ 5.326950582000052, 5.982685583000034 ],
							[ 5.336518891000026, 5.984425604000023 ],
							[ 5.339998107000042, 5.98268558500007 ],
							[ 5.34521714300007, 5.975292153000055 ],
							[ 5.358699279000064, 5.969638356000075 ],
							[ 5.350001418000033, 5.960939859000064 ],
							[ 5.341302766000069, 5.95615581900006 ],
							[ 5.334778732000075, 5.949197319000064 ],
							[ 5.326080706000027, 5.94180340500003 ],
							[ 5.315147840000066, 5.934660880000024 ],
							[ 5.304334641000025, 5.919622409000056 ],
							[ 5.291287324000052, 5.912229005000029 ],
							[ 5.275195204000056, 5.909184410000023 ],
							[ 5.269696788000033, 5.918911538000032 ],
							[ 5.268236549000051, 5.93919422700003 ],
							[ 5.263452659000052, 5.943108109000036 ],
							[ 5.256058796000048, 5.950067066000031 ],
							[ 5.25344956400005, 5.957025491000024 ],
							[ 5.249100177000059, 5.970073184000057 ],
							[ 5.247795462000056, 5.986599919000071 ],
							[ 5.251615094000044, 6.000012831000049 ],
							[ 5.254957334000039, 6.013216895000028 ],
							[ 5.258984971000075, 6.025040105000073 ],
							[ 5.262348098000075, 6.033390121000025 ],
							[ 5.26502169400004, 6.043814232000045 ],
							[ 5.268269054000029, 6.052536039000074 ],
							[ 5.270169742000064, 6.054295938000053 ],
							[ 5.256502201000046, 6.065238947000068 ],
							[ 5.248787452000045, 6.064228039000056 ],
							[ 5.245213103000026, 6.063759812000058 ],
							[ 5.238930711000023, 6.06244377400003 ],
							[ 5.232940319000022, 6.065638498000055 ],
							[ 5.232984129000045, 6.071512190000021 ],
							[ 5.233738820000042, 6.07362560200005 ],
							[ 5.232940118000045, 6.081212935000053 ],
							[ 5.232541002000062, 6.089199996000048 ],
							[ 5.232541155000035, 6.094790927000076 ],
							[ 5.23134275700005, 6.101180552000073 ],
							[ 5.233739018000051, 6.11236236600007 ],
							[ 5.230544069000075, 6.118752070000028 ],
							[ 5.225352695000026, 6.125940377000063 ],
							[ 5.221758257000033, 6.131531209000059 ],
							[ 5.21736579700007, 6.136323473000061 ],
							[ 5.218563700000061, 6.141515315000049 ],
							[ 5.222956656000065, 6.144710107000037 ],
							[ 5.222557171000062, 6.148703616000034 ],
							[ 5.217764747000047, 6.149901900000032 ],
							[ 5.209378827000023, 6.153495786000065 ],
							[ 5.205528700000059, 6.151237906000063 ],
							[ 5.20605766500006, 6.147184438000068 ],
							[ 5.203388309000047, 6.145109593000029 ],
							[ 5.198831569000049, 6.147007959000064 ],
							[ 5.195835576000036, 6.150885143000039 ],
							[ 5.193720821000056, 6.15176632500004 ],
							[ 5.190372050000065, 6.151237913000045 ],
							[ 5.18402724300006, 6.14736036100004 ],
							[ 5.180149997000058, 6.149651473000063 ],
							[ 5.182441126000072, 6.154410326000061 ],
							[ 5.181912328000067, 6.156877533000056 ],
							[ 5.179445195000028, 6.15899231800006 ],
							[ 5.176631458000031, 6.159486284000025 ],
							[ 5.172923995000076, 6.160578755000074 ],
							[ 5.168694626000047, 6.163574671000049 ],
							[ 5.164251943000068, 6.159086669000033 ],
							[ 5.164650927000025, 6.155093215000022 ],
							[ 5.162909159000037, 6.14793056700006 ],
							[ 5.164626230000067, 6.14655212100007 ],
							[ 5.161456090000058, 6.142713541000035 ],
							[ 5.15786227600006, 6.146707063000065 ],
							[ 5.154594843000041, 6.144187978000048 ],
							[ 5.145542100000057, 6.141641575000051 ],
							[ 5.142963046000034, 6.143306744000029 ],
							[ 5.143491702000063, 6.151237930000036 ],
							[ 5.141374546000065, 6.155823230000067 ],
							[ 5.13829381000005, 6.159885425000027 ],
							[ 5.133102431000054, 6.164677568000059 ],
							[ 5.135050694000029, 6.169817003000048 ],
							[ 5.133446313000036, 6.172210753000059 ],
							[ 5.137675884000032, 6.173091926000041 ],
							[ 5.137146951000034, 6.176264306000064 ],
							[ 5.132036292000066, 6.180846190000068 ],
							[ 5.13573745900004, 6.18225618300005 ],
							[ 5.135913425000069, 6.186662202000036 ],
							[ 5.133264904000043, 6.188217614000052 ],
							[ 5.131331064000051, 6.188953391000041 ],
							[ 5.128687245000037, 6.18965862500005 ],
							[ 5.128687476000039, 6.19388814000007 ],
							[ 5.127111776000049, 6.197025255000028 ],
							[ 5.122395062000066, 6.201276291000056 ],
							[ 5.122468124000022, 6.205872113000055 ],
							[ 5.119923435000032, 6.207008877000021 ],
							[ 5.117625118000035, 6.206946341000048 ],
							[ 5.11548995000004, 6.206108520000043 ],
							[ 5.113859607000052, 6.199817249000034 ],
							[ 5.112825873000077, 6.195474168000032 ],
							[ 5.109939606000069, 6.19542794900002 ],
							[ 5.104825576000053, 6.200029329000074 ],
							[ 5.10315603600003, 6.202176117000022 ],
							[ 5.099816826000051, 6.202176146000056 ],
							[ 5.098225684000056, 6.198143444000038 ],
							[ 5.095841467000071, 6.197087793000037 ],
							[ 5.092422998000075, 6.198280325000042 ],
							[ 5.089201807000052, 6.201057462000051 ],
							[ 5.088472282000055, 6.20433997300006 ],
							[ 5.086137636000046, 6.207841819000066 ],
							[ 5.087235862000057, 6.214525713000057 ],
							[ 5.085182634000034, 6.21608827700004 ],
							[ 5.081807556000058, 6.218033305000063 ],
							[ 5.075622570000064, 6.213434280000058 ],
							[ 5.074545801000056, 6.217686136000054 ],
							[ 5.077929966000056, 6.220148118000054 ],
							[ 5.07881124000005, 6.22261575400006 ],
							[ 5.075851381000064, 6.226736505000076 ],
							[ 5.073736241000063, 6.228851821000035 ],
							[ 5.072933676000048, 6.231186367000021 ],
							[ 5.07334766300005, 6.234776 ],
							[ 5.073347528000056, 6.239534803000026 ],
							[ 5.069470372000069, 6.242530824000028 ],
							[ 5.066297915000064, 6.245526751000057 ],
							[ 5.063478438000061, 6.248346838000032 ],
							[ 5.060573199000032, 6.248004853000054 ],
							[ 5.057486140000037, 6.247641531000056 ],
							[ 5.053448168000045, 6.24683329100003 ],
							[ 5.049714114000039, 6.251429585000039 ],
							[ 5.049030394000056, 6.260269209000057 ],
							[ 5.04796900100007, 6.264913558000046 ],
							[ 5.041041861000053, 6.274275327000055 ],
							[ 5.037746988000038, 6.277426663000028 ],
							[ 5.035061294000059, 6.278010390000077 ],
							[ 5.029640252000036, 6.279189100000053 ],
							[ 5.021533054000031, 6.285357474000023 ],
							[ 5.015618871000072, 6.292353136000031 ],
							[ 5.01307343600007, 6.298575342000049 ],
							[ 5.012897062000036, 6.302100250000024 ],
							[ 5.015364592000026, 6.30897376300004 ],
							[ 5.019684301000041, 6.315898355000058 ],
							[ 5.021523426000044, 6.318678921000071 ],
							[ 5.030136150000033, 6.323132936000036 ],
							[ 5.035301164000032, 6.328475905000062 ],
							[ 5.034945015000062, 6.332928607000042 ],
							[ 5.031738696000048, 6.337559675000023 ],
							[ 5.028711342000065, 6.341834049000056 ],
							[ 5.027820658000053, 6.345218246000059 ],
							[ 5.028176772000052, 6.350917290000041 ],
							[ 5.028355107000039, 6.358041817000071 ],
							[ 5.028709874000072, 6.360269416000051 ],
							[ 5.029245389000039, 6.363562967000064 ],
							[ 5.032392938000044, 6.364906654000038 ],
							[ 5.039219320000029, 6.364097194000067 ],
							[ 5.043849954000052, 6.366590900000062 ],
							[ 5.047234187000072, 6.372112393000066 ],
							[ 5.048124892000033, 6.375318185000026 ],
							[ 5.048082718000046, 6.377920995000068 ],
							[ 5.049193354000067, 6.382442114000071 ],
							[ 5.052824987000065, 6.38415088000005 ],
							[ 5.057386446000066, 6.381373501000041 ],
							[ 5.062550884000075, 6.377811292000047 ],
							[ 5.06611345400006, 6.37656435100007 ],
							[ 5.071278537000069, 6.376564454000061 ],
							[ 5.075731239000049, 6.377276878000032 ],
							[ 5.07929328900002, 6.381195675000072 ],
							[ 5.083274803000052, 6.385502683000027 ],
							[ 5.086036231000037, 6.389211621000072 ],
							[ 5.091582310000035, 6.392238161000023 ],
							[ 5.09888494300003, 6.397403352000026 ],
							[ 5.103693390000046, 6.402212082000062 ],
							[ 5.106899842000075, 6.40951429200004 ],
							[ 5.109570911000048, 6.412541874000055 ],
							[ 5.109749295000029, 6.41467963100007 ],
							[ 5.108502362000024, 6.41717301400007 ],
							[ 5.10725601300004, 6.419132275000038 ],
							[ 5.103871920000074, 6.422159605000047 ],
							[ 5.103693582000062, 6.424831358000063 ],
							[ 5.104406356000027, 6.427859330000047 ],
							[ 5.104584138000064, 6.431777430000068 ],
							[ 5.107612174000053, 6.433380598000042 ],
							[ 5.107789871000023, 6.435873843000024 ],
							[ 5.105118773000072, 6.437298823000049 ],
							[ 5.102803201000029, 6.438723624000033 ],
							[ 5.10298109200005, 6.442285655000035 ],
							[ 5.106009104000066, 6.446025805000033 ],
							[ 5.110461594000071, 6.449053685000024 ],
							[ 5.114023770000074, 6.453328193000061 ],
							[ 5.117407688000071, 6.459027864000063 ],
							[ 5.123463512000058, 6.462768034000021 ],
							[ 5.128806558000065, 6.468467167000028 ],
							[ 5.133615380000037, 6.472563621000063 ],
							[ 5.135752732000071, 6.477016429000059 ],
							[ 5.139492966000034, 6.480044318000068 ],
							[ 5.143589396000039, 6.486456049000026 ],
							[ 5.145370398000068, 6.488771469000028 ],
							[ 5.147329704000072, 6.489305562000027 ],
							[ 5.148576385000069, 6.49215564900004 ],
							[ 5.151069705000054, 6.493936503000043 ],
							[ 5.153385110000045, 6.498033025000041 ],
							[ 5.153741428000046, 6.501060530000075 ],
							[ 5.152138613000034, 6.503554258000065 ],
							[ 5.150179325000067, 6.504623052000056 ],
							[ 5.150179353000055, 6.508897311000055 ],
							[ 5.150357299000063, 6.51174687300005 ],
							[ 5.153029059000062, 6.515487126000039 ],
							[ 5.154810044000044, 6.518871253000043 ],
							[ 5.154810028000043, 6.529023179000035 ],
							[ 5.155166167000061, 6.533119732000046 ],
							[ 5.157125582000049, 6.536147668000069 ],
							[ 5.160331309000071, 6.539175386000068 ],
							[ 5.161222032000069, 6.543805987000042 ],
							[ 5.162290651000035, 6.546477803000073 ],
							[ 5.160687400000029, 6.549862025000039 ],
							[ 5.16229056100002, 6.552176876000033 ],
							[ 5.164249966000057, 6.554492359000051 ],
							[ 5.161934304000056, 6.559301299000026 ],
							[ 5.162112265000076, 6.561973111000043 ],
							[ 5.16064579600004, 6.563108169000031 ],
							[ 5.15458336100005, 6.567800049000027 ],
							[ 5.147155820000023, 6.569605469000066 ],
							[ 5.140639710000073, 6.574186375000068 ],
							[ 5.138774771000044, 6.576022502000058 ],
							[ 5.136910348000072, 6.577858921000029 ],
							[ 5.133171029000039, 6.583378368000069 ],
							[ 5.13222849400006, 6.586143480000032 ],
							[ 5.128054205000069, 6.588160895000044 ],
							[ 5.127551078000067, 6.593504506000045 ],
							[ 5.125671368000042, 6.598110696000049 ],
							[ 5.123801255000046, 6.60087049200007 ],
							[ 5.120984023000062, 6.607318279000026 ],
							[ 5.119108565000033, 6.611001515000055 ],
							[ 5.11722893700005, 6.615607663000048 ],
							[ 5.114416745000028, 6.621132486000022 ],
							[ 5.112531148000073, 6.626662344000067 ],
							[ 5.111583628000062, 6.630350017000069 ],
							[ 5.111542818000032, 6.637736862000054 ],
							[ 5.112434013000041, 6.644205556000031 ],
							[ 5.112397633000057, 6.650668645000053 ],
							[ 5.112367104000043, 6.656208570000047 ],
							[ 5.113263081000071, 6.661753789000045 ],
							[ 5.114149646000044, 6.669145666000077 ],
							[ 5.115957817000037, 6.677466014000061 ],
							[ 5.117776033000041, 6.683939420000058 ],
							[ 5.119599292000032, 6.689489923000053 ],
							[ 5.122350311000048, 6.695045440000058 ],
							[ 5.122329590000049, 6.698738423000066 ],
							[ 5.126022778000049, 6.70152910500002 ],
							[ 5.131524426000055, 6.712639728000056 ],
							[ 5.135192005000022, 6.720047036000039 ],
							[ 5.137937439000041, 6.726525863000063 ],
							[ 5.140693645000056, 6.731158342000072 ],
							[ 5.145293776000074, 6.737647048000042 ],
							[ 5.150810691000061, 6.745987820000039 ],
							[ 5.156353115000059, 6.749712066000029 ],
							[ 5.166505868000058, 6.758078584000032 ],
							[ 5.172970283000041, 6.762731214000041 ],
							[ 5.180367567000076, 6.766465144000051 ],
							[ 5.185914858000046, 6.769266044000062 ],
							[ 5.190535448000048, 6.772061744000041 ],
							[ 5.197015868000051, 6.773944995000022 ],
							[ 5.201615824000044, 6.780433539000057 ],
							[ 5.202527468000028, 6.783208693000063 ],
							[ 5.202496584000073, 6.788748856000041 ],
							[ 5.204325274000041, 6.793375457000025 ],
							[ 5.20523221600007, 6.797074363000036 ],
							[ 5.20613860800006, 6.800772684000037 ],
							[ 5.207045018000031, 6.80447100400005 ],
							[ 5.208868523000035, 6.81002131300005 ],
							[ 5.211624548000032, 6.814653483000029 ],
							[ 5.213458507000041, 6.818356871000049 ],
							[ 5.215297245000045, 6.821137325000052 ],
							[ 5.218970352000042, 6.827620852000052 ],
							[ 5.22356522900003, 6.835033387000067 ],
							[ 5.22632606600007, 6.838742274000026 ],
							[ 5.23276990200003, 6.847088470000074 ],
							[ 5.236447696000027, 6.852648627000065 ],
							[ 5.243793422000067, 6.865616868000075 ],
							[ 5.254822129000047, 6.883221749000029 ],
							[ 5.272408117000055, 6.888858673000072 ],
							[ 5.459748756000067, 6.885983013000043 ],
							[ 5.537059795000062, 6.884796622000067 ],
							[ 5.547441612000057, 6.883792757000037 ],
							[ 5.555240192000042, 6.883038993000071 ],
							[ 5.561751257000026, 6.879381573000046 ],
							[ 5.565518851000036, 6.876023304000057 ],
							[ 5.567853322000076, 6.87262105800005 ],
							[ 5.570152219000022, 6.869271320000053 ],
							[ 5.572089149000021, 6.854508433000035 ],
							[ 5.57121329000006, 6.845269804000054 ],
							[ 5.566607832000045, 6.83970406800006 ],
							[ 5.563836685000069, 6.83784213000007 ],
							[ 5.559206134000021, 6.836892979000027 ],
							[ 5.553648104000047, 6.83593901100005 ],
							[ 5.549027373000058, 6.833143134000068 ],
							[ 5.546266427000035, 6.829434430000049 ],
							[ 5.543510881000032, 6.824802272000056 ],
							[ 5.543556802000069, 6.816492643000061 ],
							[ 5.543577810000045, 6.812798848000057 ],
							[ 5.544519807000029, 6.810034408000035 ],
							[ 5.545835013000044, 6.80429218200004 ],
							[ 5.547423795000043, 6.802810282000053 ],
							[ 5.549863821000031, 6.797205811000026 ],
							[ 5.551097511000023, 6.794373447000055 ],
							[ 5.555774557000063, 6.787012501000049 ],
							[ 5.559534923000058, 6.777799566000056 ],
							[ 5.561419879000027, 6.772269754000035 ],
							[ 5.561877854000045, 6.767142811000042 ],
							[ 5.563216246000025, 6.762380187000076 ],
							[ 5.561532868000029, 6.751956380000024 ],
							[ 5.562522019000028, 6.740881971000022 ],
							[ 5.561656103000075, 6.72979635300004 ],
							[ 5.564489356000024, 6.720578595000063 ],
							[ 5.571025969000061, 6.712304593000056 ],
							[ 5.573838159000047, 6.70678001400006 ],
							[ 5.576634821000027, 6.704025769000054 ],
							[ 5.58219811500004, 6.704056232000028 ],
							[ 5.585876051000071, 6.70961711800004 ],
							[ 5.589559033000057, 6.714253951000046 ],
							[ 5.596013628000037, 6.720753333000062 ],
							[ 5.605238951000047, 6.729115106000052 ],
							[ 5.612620759000038, 6.735619110000073 ],
							[ 5.620002269000054, 6.742123513000024 ],
							[ 5.629253363000032, 6.745868255000062 ],
							[ 5.636665888000039, 6.746832748000031 ],
							[ 5.640374706000046, 6.746853219000059 ],
							[ 5.649687315000051, 6.739518283000052 ],
							[ 5.65434881300007, 6.734927289000041 ],
							[ 5.659932694000076, 6.731264464000049 ],
							[ 5.667370893000054, 6.727611926000066 ],
							[ 5.702557533000061, 6.736117457000034 ],
							[ 5.711441936000028, 6.751975031000029 ],
							[ 5.714492767000024, 6.757421013000055 ],
							[ 5.719102894000059, 6.762063420000061 ],
							[ 5.723659650000059, 6.766651009000043 ],
							[ 5.726479411000071, 6.769490791000067 ],
							[ 5.743122628000037, 6.777893473000063 ],
							[ 5.751592573000039, 6.781640027000037 ],
							[ 5.757921149000026, 6.784439148000047 ],
							[ 5.767152261000035, 6.791876734000027 ],
							[ 5.770835513000065, 6.796513963000052 ],
							[ 5.778134865000027, 6.817791980000038 ],
							[ 5.777176760000032, 6.823326484000063 ],
							[ 5.775301456000022, 6.827009606000047 ],
							[ 5.773421644000052, 6.831616472000064 ],
							[ 5.771552144000054, 6.834375851000061 ],
							[ 5.769666641000072, 6.839905828000042 ],
							[ 5.771480005000058, 6.847302391000028 ],
							[ 5.774225730000069, 6.85378111700004 ],
							[ 5.778835839000067, 6.85842358900004 ],
							[ 5.782503492000046, 6.865831011000068 ],
							[ 5.785634522000066, 6.869615174000046 ],
							[ 5.787108313000033, 6.871396528000048 ],
							[ 5.787062240000068, 6.879706394000038 ],
							[ 5.787943236000046, 6.888021846000072 ],
							[ 5.78790745200007, 6.894485104000069 ],
							[ 5.789725879000059, 6.900958680000031 ],
							[ 5.793403553000076, 6.906519371000059 ],
							[ 5.797993586000075, 6.914855067000076 ],
							[ 5.799103383000045, 6.918805135000071 ],
							[ 5.799811719000047, 6.921328581000068 ],
							[ 5.798848687000032, 6.92778684600006 ],
							[ 5.797895846000074, 6.932398228000068 ],
							[ 5.796005843000046, 6.938851495000051 ],
							[ 5.795052660000067, 6.943462865000072 ],
							[ 5.793172225000035, 6.948069215000032 ],
							[ 5.79220430700002, 6.955450627000062 ],
							[ 5.788464568000052, 6.960970392000036 ],
							[ 5.785642282000026, 6.968341460000033 ],
							[ 5.788356662000069, 6.980360098000062 ],
							[ 5.789242637000029, 6.987752081000053 ],
							[ 5.789217412000028, 6.992368720000059 ],
							[ 5.791041033000056, 6.997919091000028 ],
							[ 5.793781274000025, 7.00532105800005 ],
							[ 5.793755585000042, 7.009965394000062 ],
							[ 5.793735058000038, 7.013630894000073 ],
							[ 5.79400924600003, 7.015619852000043 ],
							[ 5.792581188000042, 7.021655213000031 ],
							[ 5.796177970000031, 7.024290674000042 ],
							[ 5.797366675000035, 7.02750160100004 ],
							[ 5.798359843000071, 7.03001454300005 ],
							[ 5.799312050000026, 7.032424527000046 ],
							[ 5.801023926000028, 7.036755654000046 ],
							[ 5.802832242000022, 7.045075430000054 ],
							[ 5.804257907000022, 7.048537354000075 ],
							[ 5.809593200000052, 7.051245179000034 ],
							[ 5.810224124000058, 7.055902830000036 ],
							[ 5.811988899000028, 7.059385765000059 ],
							[ 5.813187050000067, 7.063456905000066 ],
							[ 5.814392063000071, 7.068109922000076 ],
							[ 5.815583181000022, 7.071598020000067 ],
							[ 5.821989927000061, 7.079123123000045 ],
							[ 5.823740972000053, 7.081441065000035 ],
							[ 5.829487802000074, 7.081976835000034 ],
							[ 5.835264252000059, 7.084845207000058 ],
							[ 5.83815465400005, 7.086569882000049 ],
							[ 5.84104680300004, 7.088295105000043 ],
							[ 5.84624911700007, 7.091166838000049 ],
							[ 5.849707102000025, 7.092305127000031 ],
							[ 5.852578095000069, 7.093999 ],
							[ 5.854922710000039, 7.096343068000067 ],
							[ 5.854840517000071, 7.096485851000068 ],
							[ 5.853237192000051, 7.099270850000039 ],
							[ 5.850976991000039, 7.102203875000043 ],
							[ 5.848142984000049, 7.105142123000064 ],
							[ 5.845868157000041, 7.106908773000043 ],
							[ 5.843033795000053, 7.109846978000064 ],
							[ 5.841958128000044, 7.115684915000031 ],
							[ 5.842559893000043, 7.118010956000035 ],
							[ 5.844311273000073, 7.120328778000044 ],
							[ 5.846621893000076, 7.121476162000022 ],
							[ 5.850654260000056, 7.122609210000064 ],
							[ 5.854692944000021, 7.124323808000042 ],
							[ 5.857576878000032, 7.125466725000024 ],
							[ 5.860455082000044, 7.126026285000023 ],
							[ 5.862779011000043, 7.128338768000049 ],
							[ 5.865139112000065, 7.133565797000074 ],
							[ 5.866331231000061, 7.137052889000074 ],
							[ 5.867528831000072, 7.141123807000042 ],
							[ 5.867571892000058, 7.144620858000053 ],
							[ 5.868174989000067, 7.146947974000057 ],
							[ 5.871696811000049, 7.153330753000034 ],
							[ 5.872910016000048, 7.158568015000071 ],
							[ 5.872960033000027, 7.162648070000046 ],
							[ 5.874718141000074, 7.165547930000059 ],
							[ 5.877064266000048, 7.169609964000074 ],
							[ 5.878821880000032, 7.172510076000037 ],
							[ 5.882308956000031, 7.175979034000022 ],
							[ 5.886943900000063, 7.179438182000069 ],
							[ 5.889835031000075, 7.181162718000053 ],
							[ 5.893875102000038, 7.182878886000026 ],
							[ 5.893910056000038, 7.185792871000046 ],
							[ 5.890465167000059, 7.189054906000024 ],
							[ 5.883665047000022, 7.192871963000073 ],
							[ 5.884325119000039, 7.199862119000045 ],
							[ 5.888974247000021, 7.204486734000056 ],
							[ 5.893616253000062, 7.208529112000065 ],
							[ 5.895940909000046, 7.210842228000047 ],
							[ 5.899414054000033, 7.213146074000065 ],
							[ 5.903480916000035, 7.217193074000022 ],
							[ 5.90409885300005, 7.220685050000043 ],
							[ 5.901270756000031, 7.224205880000056 ],
							[ 5.897339974000033, 7.231233217000067 ],
							[ 5.898544877000063, 7.23588705700007 ],
							[ 5.898660242000062, 7.245212059000039 ],
							[ 5.898129046000065, 7.248714103000054 ],
							[ 5.895890240000028, 7.253396172000066 ],
							[ 5.896522055000048, 7.258053647000054 ],
							[ 5.900001175000057, 7.260940217000041 ],
							[ 5.902900240000065, 7.263247948000071 ],
							[ 5.904650143000026, 7.265564888000029 ],
							[ 5.905289044000028, 7.270805926000037 ],
							[ 5.907060990000048, 7.274871934000032 ],
							[ 5.90837355900004, 7.276502256000072 ],
							[ 5.909392835000062, 7.277768257000048 ],
							[ 5.91229904100004, 7.280659212000046 ],
							[ 5.914071132000061, 7.28472518600006 ],
							[ 5.916991110000026, 7.288781295000035 ],
							[ 5.921632802000033, 7.29282386400007 ],
							[ 5.923493291000057, 7.295585282000047 ],
							[ 5.924015383000039, 7.29593920800005 ],
							[ 5.926806818000045, 7.29736194000003 ],
							[ 5.930874372000062, 7.298540707000029 ],
							[ 5.936333963000038, 7.30087607300004 ],
							[ 5.94153570900005, 7.303590980000024 ],
							[ 5.945087226000055, 7.305528534000075 ],
							[ 5.95042707500005, 7.306972292000069 ],
							[ 5.962314048000053, 7.310251025000071 ],
							[ 5.968297215000064, 7.310936600000048 ],
							[ 5.973128518000067, 7.312248357000044 ],
							[ 5.975920268000039, 7.313670715000057 ],
							[ 5.978713091000031, 7.314965889000064 ],
							[ 5.97933839600006, 7.316370081000059 ],
							[ 5.979321585000037, 7.318405359000053 ],
							[ 5.97931107900007, 7.319677163000051 ],
							[ 5.978154967000023, 7.320812662000037 ],
							[ 5.975211569000066, 7.322314905000042 ],
							[ 5.972782165000069, 7.323312441000041 ],
							[ 5.969458710000026, 7.324557180000056 ],
							[ 5.966771355000049, 7.325934021000023 ],
							[ 5.964208955000061, 7.327566773000058 ],
							[ 5.961136241000077, 7.329322156000046 ],
							[ 5.957933715000024, 7.331330741000045 ],
							[ 5.955993604000071, 7.33474940900004 ],
							[ 5.955725076000022, 7.336400826000045 ],
							[ 5.955708134000076, 7.338435823000054 ],
							[ 5.955821741000022, 7.340090427000064 ],
							[ 5.956063800000038, 7.341618926000024 ],
							[ 5.956689358000062, 7.343023435000021 ],
							[ 5.957695131000037, 7.344322314000067 ],
							[ 5.958647537000047, 7.344483361000073 ],
							[ 5.964760845000058, 7.345518206000065 ],
							[ 5.968785873000058, 7.346066897000071 ],
							[ 5.971663130000024, 7.346627259000059 ],
							[ 5.975113730000032, 7.34718188100004 ],
							[ 5.978566034000039, 7.34773590900005 ],
							[ 5.980875956000034, 7.348884101000067 ],
							[ 5.986694960000023, 7.355247853000037 ],
							[ 5.986730947000069, 7.358161809000023 ],
							[ 5.987339997000049, 7.361072006000029 ],
							[ 5.986795027000028, 7.363408118000052 ],
							[ 5.981091139000057, 7.366368722000061 ],
							[ 5.978241988000036, 7.368141067000067 ],
							[ 5.975996131000045, 7.372240022000028 ],
							[ 5.974885068000049, 7.37516402600005 ],
							[ 5.974919832000069, 7.378077989000076 ],
							[ 5.974970743000029, 7.382157692000021 ],
							[ 5.977316812000026, 7.386219025000059 ],
							[ 5.978499769000052, 7.389123773000051 ],
							[ 5.979677091000042, 7.391446083000062 ],
							[ 5.978558073000045, 7.393786791000025 ],
							[ 5.975708976000021, 7.395558840000035 ],
							[ 5.974051861000021, 7.400818941000068 ],
							[ 5.974661725000033, 7.403728011000055 ],
							[ 5.970127074000061, 7.40842916400004 ],
							[ 5.969007950000048, 7.410768989000076 ],
							[ 5.968483950000063, 7.414854125000033 ],
							[ 5.971411214000057, 7.419494211000028 ],
							[ 5.972601864000069, 7.422980867000035 ],
							[ 5.973793955000076, 7.426468938000028 ],
							[ 5.974403017000043, 7.429378869000061 ],
							[ 5.975586942000064, 7.432283906000066 ],
							[ 5.977336981000064, 7.434600769000042 ],
							[ 5.97966189300007, 7.436913873000037 ],
							[ 5.981406323000044, 7.43864826600003 ],
							[ 5.984870828000055, 7.440369098000076 ],
							[ 5.987182220000022, 7.441515827000046 ],
							[ 5.987217107000049, 7.444429780000064 ],
							[ 5.987259918000063, 7.44792701700004 ],
							[ 5.986714792000043, 7.450263991000043 ],
							[ 5.984490934000064, 7.45611004400007 ],
							[ 5.978772288000073, 7.457905719000053 ],
							[ 5.975343265000049, 7.459099903000038 ],
							[ 5.971339324000041, 7.460298941000076 ],
							[ 5.966173148000053, 7.460339916000066 ],
							[ 5.963303177000057, 7.460363947000076 ],
							[ 5.957714234000036, 7.472651040000073 ],
							[ 5.956608897000024, 7.476157151000052 ],
							[ 5.954929753000044, 7.479669222000041 ],
							[ 5.953258162000054, 7.483763177000071 ],
							[ 5.948134961000051, 7.487301713000022 ],
							[ 5.948815910000064, 7.496039863000021 ],
							[ 5.951171766000073, 7.501221538000038 ],
							[ 5.95297087900002, 7.507081870000036 ],
							[ 5.953028307000068, 7.511744012000065 ],
							[ 5.954218805000039, 7.515232970000056 ],
							[ 5.959945245000029, 7.514019862000055 ],
							[ 5.962800109000057, 7.512831221000056 ],
							[ 5.965109923000057, 7.513978087000055 ],
							[ 5.967428212000073, 7.515707114000065 ],
							[ 5.969185931000027, 7.518608156000028 ],
							[ 5.970964895000066, 7.523255959000039 ],
							[ 5.973297122000076, 7.52615208800006 ],
							[ 5.97564324800004, 7.530212843000072 ],
							[ 5.979086794000068, 7.530185225000025 ],
							[ 5.982531197000071, 7.530157047000046 ],
							[ 5.987115968000069, 7.529537126000037 ],
							[ 5.989405231000035, 7.52893509300003 ],
							[ 5.994548823000059, 7.527143876000025 ],
							[ 6.002025944000025, 7.528248839000071 ],
							[ 6.004125679000026, 7.529235776000064 ],
							[ 6.012996292000025, 7.533405687000027 ],
							[ 6.017077992000054, 7.538619049000033 ],
							[ 6.02116836700003, 7.54441511400006 ],
							[ 6.023499149000031, 7.547309776000077 ],
							[ 6.022954133000042, 7.549645942000041 ],
							[ 6.024733893000075, 7.554296014000045 ],
							[ 6.025364754000066, 7.558952882000028 ],
							[ 6.02654893600004, 7.561859159000051 ],
							[ 6.028888161000054, 7.565337104000037 ],
							[ 6.032956114000058, 7.569383978000076 ],
							[ 6.035861932000046, 7.572275017000038 ],
							[ 6.040483033000044, 7.574569219000068 ],
							[ 6.043387821000067, 7.577459910000073 ],
							[ 6.048503768000046, 7.580123926000056 ],
							[ 6.09873101200003, 7.58819722800007 ],
							[ 6.118761111000026, 7.591416899000023 ],
							[ 6.122677716000055, 7.583224677000032 ],
							[ 6.123775886000033, 7.57913482400005 ],
							[ 6.125701498000069, 7.575399839000056 ],
							[ 6.126644027000054, 7.572976230000052 ],
							[ 6.128799048000076, 7.56743625200005 ],
							[ 6.129910893000044, 7.564511802000027 ],
							[ 6.130442024000047, 7.561010893000059 ],
							[ 6.132105766000052, 7.556333995000045 ],
							[ 6.133570740000039, 7.552076860000057 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Ekiti',
				admin1Pcod: 'NG013',
				admin1RefN: 'Ekiti',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 3.395633,
				Shape_Area: 0.471634
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 5.505934631000059, 8.018686365000065 ],
							[ 5.508718415000033, 8.01764298300003 ],
							[ 5.516025512000056, 8.022166210000023 ],
							[ 5.525768787000061, 8.030865578000032 ],
							[ 5.53168447500002, 8.035040760000072 ],
							[ 5.537599559000057, 8.04060836900004 ],
							[ 5.545255155000063, 8.044088498000065 ],
							[ 5.550822190000076, 8.051047388000029 ],
							[ 5.555031838000048, 8.054554859000064 ],
							[ 5.559173445000056, 8.05800625300003 ],
							[ 5.572744493000073, 8.068446020000067 ],
							[ 5.576571877000049, 8.071229965000043 ],
							[ 5.599189785000021, 8.073665659000028 ],
							[ 5.624591446000068, 8.072621338000033 ],
							[ 5.63955401100003, 8.073665711000046 ],
							[ 5.643636244000049, 8.073968050000076 ],
							[ 5.648949022000068, 8.074360948000049 ],
							[ 5.656256662000033, 8.074708913000052 ],
							[ 5.659736125000052, 8.069837645000064 ],
							[ 5.653820413000062, 8.064270042000032 ],
							[ 5.64581731800007, 8.060790170000075 ],
							[ 5.64303340400005, 8.059050683000066 ],
							[ 5.639902025000026, 8.053830964000042 ],
							[ 5.643033628000069, 8.048263519000045 ],
							[ 5.647209042000043, 8.040956444000074 ],
							[ 5.648949257000027, 8.03260507400006 ],
							[ 5.650689236000062, 8.023557704000041 ],
							[ 5.656328105000057, 8.019577020000042 ],
							[ 5.662519778000046, 8.015206209000041 ],
							[ 5.667391421000048, 8.008595557000035 ],
							[ 5.668087576000062, 8.002331861000073 ],
							[ 5.663215595000054, 8.000244125000052 ],
							[ 5.656952476000072, 7.994328925000048 ],
							[ 5.650732866000055, 7.990740735000031 ],
							[ 5.649573347000057, 7.987886369000023 ],
							[ 5.647557265000046, 7.986158421000027 ],
							[ 5.647557282000037, 7.974146818000065 ],
							[ 5.647209100000055, 7.966143736000049 ],
							[ 5.645469766000076, 7.963011655000059 ],
							[ 5.634334623000029, 7.958487890000072 ],
							[ 5.627027078000026, 7.957096216000025 ],
							[ 5.614152440000055, 7.962315955000065 ],
							[ 5.609628759000032, 7.966143526000053 ],
							[ 5.601625312000067, 7.96370732500003 ],
							[ 5.598841579000066, 7.958487877000039 ],
							[ 5.598841648000075, 7.951876509000044 ],
							[ 5.601973655000052, 7.942829500000073 ],
							[ 5.604061648000027, 7.926475082000024 ],
							[ 5.60858481300005, 7.910816756000031 ],
							[ 5.612412348000021, 7.876368137000043 ],
							[ 5.614152495000042, 7.865581069000029 ],
							[ 5.623199498000076, 7.84296312500004 ],
							[ 5.630506983000032, 7.834959867000066 ],
							[ 5.642686001000072, 7.82869615900006 ],
							[ 5.656604234000042, 7.828348618000064 ],
							[ 5.658838725000066, 7.827678318000039 ],
							[ 5.668435055000032, 7.829044389000046 ],
							[ 5.688617295000029, 7.829740515000026 ],
							[ 5.710191180000038, 7.83008800600004 ],
							[ 5.728981390000058, 7.828348640000058 ],
							[ 5.737289373000067, 7.821079247000057 ],
							[ 5.738130657000056, 7.819180466000034 ],
							[ 5.741160387000036, 7.812342213000022 ],
							[ 5.746031814000048, 7.80503477600007 ],
							[ 5.755775061000065, 7.795639437000034 ],
							[ 5.767953791000025, 7.78763630900005 ],
							[ 5.791615415000024, 7.782068704000039 ],
							[ 5.773028439000029, 7.747318798000038 ],
							[ 5.767605891000073, 7.737181237000073 ],
							[ 5.756123007000042, 7.728481821000059 ],
							[ 5.74255237400007, 7.71978275400005 ],
							[ 5.728285849000031, 7.710387782000055 ],
							[ 5.71645507300002, 7.700992561000021 ],
							[ 5.707059904000062, 7.690553750000049 ],
							[ 5.699404293000043, 7.682898142000056 ],
							[ 5.694880918000024, 7.671763526000063 ],
							[ 5.694324437000034, 7.664528247000021 ],
							[ 5.693140951000032, 7.65888837500006 ],
							[ 5.692097287000024, 7.65158116200007 ],
							[ 5.691400868000073, 7.642881983000052 ],
							[ 5.69140105300005, 7.638010353000027 ],
							[ 5.688617167000075, 7.632442799000046 ],
							[ 5.680962141000066, 7.623744084000066 ],
							[ 5.675742676000027, 7.623744065000039 ],
							[ 5.670300802000042, 7.625860175000071 ],
							[ 5.662113090000048, 7.630599549000067 ],
							[ 5.656248882000057, 7.633457891000035 ],
							[ 5.649406727000041, 7.636792102000072 ],
							[ 5.64356908600007, 7.636738802000025 ],
							[ 5.638717393000036, 7.635238878000052 ],
							[ 5.631451533000075, 7.631775447000052 ],
							[ 5.624411722000048, 7.628391951000026 ],
							[ 5.623217185000044, 7.627817789000062 ],
							[ 5.619847923000066, 7.62390502900007 ],
							[ 5.619915255000024, 7.616626735000068 ],
							[ 5.623392064000029, 7.608894900000053 ],
							[ 5.627364336000028, 7.600196629000038 ],
							[ 5.627886526000054, 7.596319652000034 ],
							[ 5.627418076000026, 7.594374334000065 ],
							[ 5.619283376000055, 7.579741968000064 ],
							[ 5.614991091000036, 7.570482740000045 ],
							[ 5.611167326000043, 7.563168540000049 ],
							[ 5.607821329000046, 7.55682936900007 ],
							[ 5.606474742000046, 7.544685825000045 ],
							[ 5.60607404700005, 7.535462138000071 ],
							[ 5.606110093000041, 7.531580686000041 ],
							[ 5.604723089000061, 7.523803734000069 ],
							[ 5.604759151000053, 7.519921984000064 ],
							[ 5.613048434000063, 7.520358276000024 ],
							[ 5.612900151000076, 7.51863091000007 ],
							[ 5.612029673000052, 7.508469246000061 ],
							[ 5.612070953000057, 7.501082448000034 ],
							[ 5.611185049000028, 7.493690479000065 ],
							[ 5.609946444000059, 7.485031837000065 ],
							[ 5.61030921300005, 7.484452224000052 ],
							[ 5.607496808000064, 7.480200274000026 ],
							[ 5.603910898000038, 7.467796338000028 ],
							[ 5.601190927000061, 7.456700827000077 ],
							[ 5.599372806000076, 7.450227323000036 ],
							[ 5.592938841000034, 7.440034904000072 ],
							[ 5.58834943200003, 7.431698837000056 ],
							[ 5.582811708000065, 7.427051440000071 ],
							[ 5.580973099000062, 7.424271535000059 ],
							[ 5.575445534000039, 7.41777718000003 ],
							[ 5.571772948000046, 7.411292993000075 ],
							[ 5.567172990000074, 7.404804336000041 ],
							[ 5.561655942000073, 7.396463476000065 ],
							[ 5.557071679000046, 7.387204114000042 ],
							[ 5.552477001000057, 7.379792126000041 ],
							[ 5.546017253000059, 7.374215972000059 ],
							[ 5.542344509000031, 7.367732024000077 ],
							[ 5.536838168000031, 7.357544757000028 ],
							[ 5.53314967600005, 7.353830728000048 ],
							[ 5.530404174000068, 7.347352019000027 ],
							[ 5.526381832000027, 7.340863481000042 ],
							[ 5.524378524000042, 7.339775229000054 ],
							[ 5.518293921000065, 7.335828650000053 ],
							[ 5.513603364000062, 7.331617444000074 ],
							[ 5.507240495000076, 7.327668053000025 ],
							[ 5.501696826000057, 7.325393023000061 ],
							[ 5.482807986000068, 7.321878844000025 ],
							[ 5.47140812300006, 7.320936073000041 ],
							[ 5.458054975000039, 7.320529640000075 ],
							[ 5.450471166000057, 7.318939185000033 ],
							[ 5.450781928000026, 7.315444943000045 ],
							[ 5.45252169500003, 7.306049791000021 ],
							[ 5.454043816000024, 7.300614314000029 ],
							[ 5.450928189000024, 7.299820380000028 ],
							[ 5.445370182000033, 7.298866263000036 ],
							[ 5.438889979000066, 7.296983607000072 ],
							[ 5.435851233000051, 7.296033729000044 ],
							[ 5.426857581000036, 7.293223284000021 ],
							[ 5.41759582800006, 7.291325580000034 ],
							[ 5.412964700000032, 7.290376628000047 ],
							[ 5.404620116000046, 7.290330294000057 ],
							[ 5.398140545000047, 7.28844775400006 ],
							[ 5.388226154000051, 7.283186924000063 ],
							[ 5.381466386000056, 7.285585033000075 ],
							[ 5.377747384000031, 7.287411113000076 ],
							[ 5.372174176000044, 7.289227049000033 ],
							[ 5.367527842000072, 7.291048115000024 ],
							[ 5.362881758000071, 7.292869125000038 ],
							[ 5.358230109000033, 7.29561321400007 ],
							[ 5.356345306000037, 7.30114330400005 ],
							[ 5.355397086000039, 7.304831508000063 ],
							[ 5.354439214000024, 7.310366274000046 ],
							[ 5.354403367000032, 7.31682974000006 ],
							[ 5.354377711000041, 7.321446331000061 ],
							[ 5.35526369400003, 7.328837901000043 ],
							[ 5.355233091000059, 7.334378205000064 ],
							[ 5.354249499000048, 7.344529569000031 ],
							[ 5.353306663000069, 7.347294310000052 ],
							[ 5.351400811000076, 7.356517249000035 ],
							[ 5.350443021000046, 7.362051995000058 ],
							[ 5.349479651000024, 7.368510741000023 ],
							[ 5.348521801000061, 7.374045479000074 ],
							[ 5.346626219000029, 7.381421536000062 ],
							[ 5.344746056000076, 7.386028409000062 ],
							[ 5.342865873000051, 7.390634420000026 ],
							[ 5.340048418000038, 7.397082287000046 ],
							[ 5.337236420000067, 7.402606979000041 ],
							[ 5.333496535000052, 7.408126345000028 ],
							[ 5.331616929000063, 7.412732579000021 ],
							[ 5.328809148000062, 7.41733406000003 ],
							[ 5.326919099000065, 7.423787134000065 ],
							[ 5.325028984000028, 7.430240196000057 ],
							[ 5.322216504000039, 7.435764765000044 ],
							[ 5.320331008000039, 7.44129466000004 ],
							[ 5.314757683000039, 7.443110571000034 ],
							[ 5.311981282000033, 7.442171649000045 ],
							[ 5.308277560000022, 7.441227900000058 ],
							[ 5.306253832000039, 7.440543755000022 ],
							[ 5.299021143000061, 7.438406354000051 ],
							[ 5.294395560000055, 7.436533924000059 ],
							[ 5.286978342000054, 7.436492883000028 ],
							[ 5.279565698000056, 7.435528254000076 ],
							[ 5.268423945000052, 7.438236748000065 ],
							[ 5.259157796000068, 7.437262193000038 ],
							[ 5.251750424000022, 7.435374144000036 ],
							[ 5.245270172000062, 7.433491761000028 ],
							[ 5.235998666000057, 7.433440330000053 ],
							[ 5.224867215000074, 7.434301786000049 ],
							[ 5.220231144000024, 7.434276116000035 ],
							[ 5.217449738000028, 7.434260744000028 ],
							[ 5.20816753500003, 7.436056027000063 ],
							[ 5.200744972000052, 7.436938360000056 ],
							[ 5.192399841000054, 7.436892026000066 ],
							[ 5.187743644000022, 7.440559944000029 ],
							[ 5.181248269000037, 7.441447290000042 ],
							[ 5.173830940000073, 7.441406222000069 ],
							[ 5.167345543000067, 7.44044672800004 ],
							[ 5.15992306000004, 7.441329091000057 ],
							[ 5.150640956000075, 7.443124333000071 ],
							[ 5.145077710000066, 7.44309336200007 ],
							[ 5.138582194000037, 7.443981081000061 ],
							[ 5.132086634000075, 7.444868113000041 ],
							[ 5.126518676000046, 7.44576085500006 ],
							[ 5.12001807200005, 7.447571664000066 ],
							[ 5.114450066000074, 7.448463945000071 ],
							[ 5.107954634000066, 7.449351682000042 ],
							[ 5.102385991000062, 7.450244070000053 ],
							[ 5.095885739000039, 7.452055013000063 ],
							[ 5.089379819000044, 7.454788714000074 ],
							[ 5.084746384000027, 7.456950544000051 ],
							[ 5.078228073000048, 7.459343974000035 ],
							[ 5.070805553000071, 7.460225947000026 ],
							[ 5.06524233600004, 7.46019519500004 ],
							[ 5.058757425000067, 7.459235772000056 ],
							[ 5.053199382000059, 7.458281958000043 ],
							[ 5.048568173000035, 7.457332668000049 ],
							[ 5.045802111000057, 7.454547414000047 ],
							[ 5.04303118100006, 7.452685281000072 ],
							[ 5.038420568000049, 7.448042991000023 ],
							[ 5.032877777000067, 7.444318855000063 ],
							[ 5.02272513500003, 7.435952168000028 ],
							[ 5.019959007000068, 7.433166416000063 ],
							[ 5.018114991000061, 7.431309620000036 ],
							[ 5.016275949000033, 7.428529653000055 ],
							[ 5.013515016000042, 7.424820989000068 ],
							[ 5.010754162000069, 7.42111202600006 ],
							[ 5.007993212000031, 7.417403318000027 ],
							[ 5.006159324000066, 7.413699601000076 ],
							[ 4.997020259000067, 7.415041790000032 ],
							[ 4.987108661000036, 7.419235366000066 ],
							[ 4.984821314000044, 7.42647843900005 ],
							[ 4.975672250000059, 7.433720932000028 ],
							[ 4.967667141000049, 7.43867694100004 ],
							[ 4.952418860000023, 7.447825949000048 ],
							[ 4.947844026000041, 7.454688082000075 ],
							[ 4.938885636000066, 7.478704029000028 ],
							[ 4.925924253000062, 7.505007817000035 ],
							[ 4.920206126000039, 7.517206221000038 ],
							[ 4.917919108000035, 7.529786601000069 ],
							[ 4.909968414000048, 7.555020211000055 ],
							[ 4.906387404000043, 7.562844340000026 ],
							[ 4.902247873000022, 7.576392719000069 ],
							[ 4.892839379000065, 7.592951284000037 ],
							[ 4.887570817000039, 7.604617567000048 ],
							[ 4.883054702000038, 7.611391644000037 ],
							[ 4.868001439000068, 7.616283940000073 ],
							[ 4.863861503000066, 7.622681664000027 ],
							[ 4.868377664000036, 7.635477020000053 ],
							[ 4.871326396000029, 7.645004118000031 ],
							[ 4.872064411000053, 7.650954576000061 ],
							[ 4.87709243200004, 7.667071646000068 ],
							[ 4.88030631700002, 7.676082532000066 ],
							[ 4.882562542000073, 7.686027619000072 ],
							[ 4.885723662000032, 7.699761502000058 ],
							[ 4.889363975000037, 7.713028506000057 ],
							[ 4.892622879000044, 7.725886273000071 ],
							[ 4.893228493000038, 7.730335228000058 ],
							[ 4.895473931000026, 7.734076845000061 ],
							[ 4.898226740000041, 7.739858122000044 ],
							[ 4.903000386000031, 7.749883143000034 ],
							[ 4.904205873000024, 7.757354752000026 ],
							[ 4.909398115000045, 7.766441917000066 ],
							[ 4.914290367000035, 7.783000491000053 ],
							[ 4.915257527000051, 7.791219668000053 ],
							[ 4.91579576700002, 7.795795823000049 ],
							[ 4.915795790000061, 7.810849182000027 ],
							[ 4.915795785000057, 7.82211453900004 ],
							[ 4.908579369000051, 7.829799287000071 ],
							[ 4.910020833000033, 7.841811727000049 ],
							[ 4.911100837000049, 7.850109558000042 ],
							[ 4.914134921000027, 7.850375589000066 ],
							[ 4.916396188000022, 7.857159181000043 ],
							[ 4.923782811000024, 7.863490713000033 ],
							[ 4.924837997000054, 7.868062978000069 ],
							[ 4.924486097000056, 7.874394512000038 ],
							[ 4.927300028000047, 7.880374016000076 ],
							[ 4.932576177000044, 7.885650264000049 ],
							[ 4.937149076000026, 7.889167807000035 ],
							[ 4.941370045000042, 7.89760930500006 ],
							[ 4.94203526900003, 7.89992561400004 ],
							[ 4.949749922000024, 7.90337283100007 ],
							[ 4.967285662000052, 7.915952767000022 ],
							[ 4.978341092000051, 7.923195718000045 ],
							[ 4.990539506000061, 7.931201337000061 ],
							[ 4.996639210000069, 7.939206565000063 ],
							[ 5.001213608000057, 7.946068195000066 ],
							[ 5.008075198000029, 7.951024018000055 ],
							[ 5.016461853000067, 7.95521745800005 ],
							[ 5.020274123000036, 7.960935457000062 ],
							[ 5.031329210000024, 7.976946291000047 ],
							[ 5.033235074000061, 7.982283551000023 ],
							[ 5.033235034000029, 7.989907760000051 ],
							[ 5.032854155000052, 7.996769431000075 ],
							[ 5.035522546000038, 8.00515656500005 ],
							[ 5.039715878000038, 8.01011180100005 ],
							[ 5.053058133000036, 8.023454725000022 ],
							[ 5.056489053000064, 8.031078432000072 ],
							[ 5.057632826000031, 8.039464844000065 ],
							[ 5.061813906000054, 8.038155546000041 ],
							[ 5.072351438000055, 8.031005766000021 ],
							[ 5.075738373000036, 8.024984335000056 ],
							[ 5.084393997000063, 8.024232004000055 ],
							[ 5.085538262000057, 8.025727701000051 ],
							[ 5.087330106000024, 8.02674455500005 ],
							[ 5.089995165000062, 8.028299660000073 ],
							[ 5.096099797000022, 8.030654815000048 ],
							[ 5.100696679000066, 8.030703594000045 ],
							[ 5.103740882000068, 8.032644386000072 ],
							[ 5.10486153100004, 8.035328 ],
							[ 5.104812651000032, 8.039907761000052 ],
							[ 5.105851247000032, 8.048555695000061 ],
							[ 5.11412481800005, 8.058101591000025 ],
							[ 5.117887938000024, 8.062618183000041 ],
							[ 5.127672678000067, 8.06299404300006 ],
							[ 5.142349686000045, 8.061864859000025 ],
							[ 5.150629011000035, 8.055843474000028 ],
							[ 5.157026706000067, 8.052456755000037 ],
							[ 5.162611575000028, 8.04950621100005 ],
							[ 5.16492979100002, 8.047187854000072 ],
							[ 5.176972979000027, 8.038908908000053 ],
							[ 5.181865234000043, 8.036273860000051 ],
							[ 5.188263057000029, 8.029499947000033 ],
							[ 5.191649902000051, 8.022350279000023 ],
							[ 5.195789458000036, 8.018210443000044 ],
							[ 5.202939956000023, 8.017081150000024 ],
							[ 5.220251223000048, 8.020844547000024 ],
							[ 5.230788655000026, 8.021973665000075 ],
							[ 5.244451466000044, 8.020535576000043 ],
							[ 5.256755915000042, 8.018210286000055 ],
							[ 5.262400613000068, 8.012941453000053 ],
							[ 5.269551415000024, 8.011059783000064 ],
							[ 5.282862325000053, 8.00847142300006 ],
							[ 5.297776298000031, 8.007673294000028 ],
							[ 5.317722238000044, 8.007673263000072 ],
							[ 5.332399277000036, 8.009554973000036 ],
							[ 5.340302517000055, 8.015575408000075 ],
							[ 5.345947260000059, 8.020091986000068 ],
							[ 5.351968844000055, 8.025736859000062 ],
							[ 5.357989876000033, 8.034016625000049 ],
							[ 5.359209904000068, 8.036107902000026 ],
							[ 5.360624312000027, 8.038532251000049 ],
							[ 5.361377103000052, 8.043424561000052 ],
							[ 5.36024812200003, 8.05358603600007 ],
							[ 5.358742751000023, 8.063370790000022 ],
							[ 5.358742846000041, 8.073907953000059 ],
							[ 5.360624219000044, 8.082940105000034 ],
							[ 5.36401115700005, 8.090466597000045 ],
							[ 5.371162051000056, 8.096487939000042 ],
							[ 5.387344358000064, 8.102132851000022 ],
							[ 5.398257631000035, 8.103638628000056 ],
							[ 5.42121467100003, 8.105520314000046 ],
							[ 5.449063219000038, 8.105895943000064 ],
							[ 5.47503034500005, 8.108154263000074 ],
							[ 5.497963823000021, 8.11066446600006 ],
							[ 5.50697851700005, 8.111246106000067 ],
							[ 5.513938034000034, 8.111246010000059 ],
							[ 5.51463362100003, 8.104982344000064 ],
							[ 5.513937818000045, 8.096631124000055 ],
							[ 5.512894273000029, 8.089672203000021 ],
							[ 5.513589915000068, 8.083756369000071 ],
							[ 5.512546143000066, 8.076796607000063 ],
							[ 5.510458433000053, 8.066705651000063 ],
							[ 5.507326620000072, 8.045479869000076 ],
							[ 5.507326560000024, 8.037128421000034 ],
							[ 5.505580807000058, 8.032327603000056 ],
							[ 5.504542828000069, 8.029473357000029 ],
							[ 5.503847044000054, 8.023557571000026 ],
							[ 5.505934631000059, 8.018686365000065 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Enugu',
				admin1Pcod: 'NG014',
				admin1RefN: 'Enugu',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 4.319893,
				Shape_Area: 0.624323
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 7.545361043000071, 7.001251711000066 ],
							[ 7.549300032000076, 6.991011233000052 ],
							[ 7.552451177000023, 6.984708676000025 ],
							[ 7.556406736000042, 6.978775785000039 ],
							[ 7.557177686000045, 6.977619130000051 ],
							[ 7.558879091000051, 6.971568238000032 ],
							[ 7.564266879000058, 6.952411226000038 ],
							[ 7.571357371000033, 6.939806920000024 ],
							[ 7.577659090000054, 6.932717292000063 ],
							[ 7.59971626500004, 6.924839502000054 ],
							[ 7.620197679000057, 6.919325270000058 ],
							[ 7.635952807000024, 6.903570132000027 ],
							[ 7.641361603000064, 6.894001822000064 ],
							[ 7.65035381000007, 6.889395142000069 ],
							[ 7.657538379000073, 6.879754585000057 ],
							[ 7.662610498000049, 6.872628185000053 ],
							[ 7.672467026000049, 6.857106747000046 ],
							[ 7.67455295000002, 6.855517444000043 ],
							[ 7.692280065000034, 6.851824278000038 ],
							[ 7.720242601000052, 6.862606919000029 ],
							[ 7.734422462000055, 6.863394822000032 ],
							[ 7.750177347000033, 6.87836214500004 ],
							[ 7.76278157400003, 6.879937590000054 ],
							[ 7.776001764000057, 6.875904981000076 ],
							[ 7.785234519000028, 6.872969762000025 ],
							[ 7.788124313000026, 6.875586385000076 ],
							[ 7.792730196000036, 6.881485954000027 ],
							[ 7.79692026400005, 6.886122960000023 ],
							[ 7.800697977000027, 6.888236866000057 ],
							[ 7.809104514000069, 6.889525118000051 ],
							[ 7.817093141000043, 6.889971180000032 ],
							[ 7.826759985000024, 6.891683581000052 ],
							[ 7.832230925000033, 6.890440192000028 ],
							[ 7.863803381000025, 6.880031833000032 ],
							[ 7.864117927000052, 6.879433758000062 ],
							[ 7.874642240000071, 6.868908888000021 ],
							[ 7.87621807000005, 6.863394735000043 ],
							[ 7.882596942000021, 6.858291682000072 ],
							[ 7.895171022000056, 6.863255112000047 ],
							[ 7.89665946100007, 6.863931555000022 ],
							[ 7.895830903000046, 6.85743347600004 ],
							[ 7.894154448000052, 6.855747385000029 ],
							[ 7.890795345000072, 6.854055901000038 ],
							[ 7.888145937000047, 6.853458990000036 ],
							[ 7.890397592000056, 6.843700821000027 ],
							[ 7.888822041000026, 6.821643767000069 ],
							[ 7.886458805000075, 6.805101038000032 ],
							[ 7.886458993000076, 6.794072624000023 ],
							[ 7.887640739000062, 6.789345876000027 ],
							[ 7.890003801000034, 6.785407148000047 ],
							[ 7.900244797000028, 6.78619476800003 ],
							[ 7.903395841000076, 6.781468502000052 ],
							[ 7.894730259000028, 6.774378882000065 ],
							[ 7.88842801900006, 6.766501045000041 ],
							[ 7.878187539000066, 6.760199174000036 ],
							[ 7.867158833000076, 6.751533628000061 ],
							[ 7.855342136000047, 6.742868395000073 ],
							[ 7.845102031000067, 6.721599107000031 ],
							[ 7.841162931000042, 6.714508934000037 ],
							[ 7.830134484000041, 6.689300979000052 ],
							[ 7.821469580000041, 6.674333561000026 ],
							[ 7.815167004000045, 6.659366501000022 ],
							[ 7.810440582000069, 6.649913338000033 ],
							[ 7.804926667000075, 6.640460355000073 ],
							[ 7.807140816000071, 6.633817085000032 ],
							[ 7.808286619000057, 6.634216994000042 ],
							[ 7.807818187000066, 6.633518910000021 ],
							[ 7.795167808000031, 6.59729293700002 ],
							[ 7.791651650000063, 6.588057971000069 ],
							[ 7.788824179000073, 6.58521068400006 ],
							[ 7.783856685000046, 6.585903486000063 ],
							[ 7.78101455500007, 6.587313159000075 ],
							[ 7.776751915000034, 6.589427332000071 ],
							[ 7.768225911000059, 6.593655824000052 ],
							[ 7.762532400000055, 6.599312525000073 ],
							[ 7.753277580000031, 6.609214375000022 ],
							[ 7.749704262000023, 6.617006469000046 ],
							[ 7.741862639000033, 6.628331672000058 ],
							[ 7.737040899000021, 6.63407800400006 ],
							[ 7.724146666000024, 6.634836121000035 ],
							[ 7.705756016000066, 6.636198626000066 ],
							[ 7.698944704000041, 6.630749262000052 ],
							[ 7.701669077000076, 6.610769251000022 ],
							[ 7.703923711000073, 6.60705622200004 ],
							[ 7.717108495000048, 6.585340053000039 ],
							[ 7.730731110000022, 6.559910672000058 ],
							[ 7.74685151500006, 6.472724815000049 ],
							[ 7.749121908000063, 6.447295735000068 ],
							[ 7.750030250000066, 6.423228840000036 ],
							[ 7.751392596000073, 6.385539050000034 ],
							[ 7.738677255000027, 6.353752582000027 ],
							[ 7.720967975000065, 6.331048055000053 ],
							[ 7.708026635000067, 6.298353257000031 ],
							[ 7.708934689000046, 6.277919336000025 ],
							[ 7.721649288000037, 6.249311431000024 ],
							[ 7.725004742000067, 6.242600913000047 ],
							[ 7.733001534000039, 6.226606333000063 ],
							[ 7.733287182000026, 6.22495703900006 ],
							[ 7.731194563000031, 6.219149748000063 ],
							[ 7.730185530000028, 6.210296558000039 ],
							[ 7.73024859800006, 6.192076900000075 ],
							[ 7.730297246000021, 6.178021741000066 ],
							[ 7.73187278000006, 6.173342102000049 ],
							[ 7.738502653000069, 6.17030548200006 ],
							[ 7.726189951000038, 6.114899675000061 ],
							[ 7.713799297000037, 6.083923282000057 ],
							[ 7.698036050000042, 6.074031399000035 ],
							[ 7.686230089000048, 6.071306736000054 ],
							[ 7.669428333000042, 6.063587220000045 ],
							[ 7.668315227000051, 6.060618839000028 ],
							[ 7.670988431000069, 6.047668626000075 ],
							[ 7.675527213000066, 6.010617998000043 ],
							[ 7.675786089000042, 6.010004587000026 ],
							[ 7.69712835200005, 5.98866179700002 ],
							[ 7.711205105000033, 5.970952537000073 ],
							[ 7.714838197000063, 5.94597718700004 ],
							[ 7.704847866000023, 5.929175903000043 ],
							[ 7.691889462000063, 5.92464016200006 ],
							[ 7.678964660000076, 5.928721855000049 ],
							[ 7.664433615000064, 5.930084251000039 ],
							[ 7.635371866000071, 5.943252585000039 ],
							[ 7.589054284000042, 5.961416221000036 ],
							[ 7.573160677000033, 5.969135721000043 ],
							[ 7.559083787000077, 5.985483131000024 ],
							[ 7.547191706000035, 5.994943128000045 ],
							[ 7.542301275000057, 6.000847251000039 ],
							[ 7.536556849000021, 6.008112341000071 ],
							[ 7.53069840400002, 6.012799714000039 ],
							[ 7.52005992200003, 6.02001858400007 ],
							[ 7.510541782000075, 6.021446280000021 ],
							[ 7.493886141000075, 6.016687393000041 ],
							[ 7.475801725000053, 6.012404450000076 ],
							[ 7.461525213000073, 6.014783822000027 ],
							[ 7.451531297000031, 6.013832119000028 ],
							[ 7.43716226600003, 6.008607143000063 ],
							[ 7.433186885000055, 6.009814017000053 ],
							[ 7.431351395000036, 6.008329485000047 ],
							[ 7.431148016000066, 6.006420018000028 ],
							[ 7.430592102000048, 6.006217990000039 ],
							[ 7.415839538000057, 6.006217950000064 ],
							[ 7.404417919000025, 6.012404418000074 ],
							[ 7.401171575000035, 6.017093758000044 ],
							[ 7.401421344000028, 6.02813311500006 ],
							[ 7.400671440000053, 6.031083010000032 ],
							[ 7.396972560000052, 6.034389046000058 ],
							[ 7.392545963000032, 6.035476920000065 ],
							[ 7.388858308000067, 6.036198957000067 ],
							[ 7.387286103000065, 6.036048758000049 ],
							[ 7.386810307000076, 6.036674993000076 ],
							[ 7.38479316400003, 6.037316737000026 ],
							[ 7.376340347000053, 6.040006130000052 ],
							[ 7.361587933000067, 6.040482047000069 ],
							[ 7.339062708000029, 6.042149551000023 ],
							[ 7.328451034000068, 6.042402309000067 ],
							[ 7.310148180000056, 6.045017309000059 ],
							[ 7.30186843000007, 6.045017253000026 ],
							[ 7.289230234000058, 6.048939287000053 ],
							[ 7.27310662900004, 6.057219036000049 ],
							[ 7.268748767000034, 6.060705196000072 ],
							[ 7.263083362000032, 6.070728243000076 ],
							[ 7.259596892000047, 6.087724243000025 ],
							[ 7.254803285000037, 6.104719616000068 ],
							[ 7.249721012000066, 6.10648778500007 ],
							[ 7.248377822000066, 6.108726078000075 ],
							[ 7.24034550600004, 6.113837523000029 ],
							[ 7.229060675000028, 6.116793306000034 ],
							[ 7.22369025200004, 6.11811039600002 ],
							[ 7.220313498000053, 6.123130662000051 ],
							[ 7.217253605000053, 6.13184598600003 ],
							[ 7.215220958000032, 6.136201177000032 ],
							[ 7.214882506000038, 6.136871101000054 ],
							[ 7.215539829000022, 6.13956057300004 ],
							[ 7.217209553000032, 6.140911699000071 ],
							[ 7.219868881000025, 6.145625624000047 ],
							[ 7.222338921000073, 6.152850509000075 ],
							[ 7.221594272000061, 6.157567064000034 ],
							[ 7.22474534600002, 6.178836377000039 ],
							[ 7.223957403000043, 6.191440122000074 ],
							[ 7.218442882000033, 6.196166959000038 ],
							[ 7.208989987000052, 6.202468801000066 ],
							[ 7.204263785000023, 6.208770757000025 ],
							[ 7.203475839000021, 6.218224035000048 ],
							[ 7.19953672500003, 6.234767014000056 ],
							[ 7.18929633700003, 6.239493291000031 ],
							[ 7.172753243000045, 6.233979195000074 ],
							[ 7.15699803800004, 6.230040052000049 ],
							[ 7.150997620000055, 6.232611678000069 ],
							[ 7.147720337000067, 6.242456490000052 ],
							[ 7.146946298000046, 6.244607002000066 ],
							[ 7.146946453000055, 6.252451446000066 ],
							[ 7.149125043000026, 6.262474604000033 ],
							[ 7.145202978000043, 6.274676266000029 ],
							[ 7.142588660000058, 6.289057341000046 ],
							[ 7.150432687000034, 6.296901629000047 ],
							[ 7.160283178000043, 6.303468697000028 ],
							[ 7.162199144000056, 6.304745706000062 ],
							[ 7.159148139000024, 6.31476877700004 ],
							[ 7.154354659000035, 6.32130567400003 ],
							[ 7.15086840400005, 6.334379242000068 ],
							[ 7.147382198000059, 6.343094887000063 ],
							[ 7.141934860000049, 6.359654957000032 ],
							[ 7.13583365900007, 6.372728214000063 ],
							[ 7.127989697000032, 6.386673554000026 ],
							[ 7.115351573000055, 6.40149013100006 ],
							[ 7.096612877000041, 6.422408098000062 ],
							[ 7.088769092000064, 6.430252163000034 ],
							[ 7.064800695000031, 6.437224748000062 ],
							[ 7.038653374000035, 6.44027509600005 ],
							[ 7.029937764000067, 6.442890240000054 ],
							[ 7.027468291000048, 6.44356334500003 ],
							[ 7.02971976200007, 6.447247925000056 ],
							[ 7.032770250000056, 6.45160566800007 ],
							[ 7.032770149000044, 6.456835158000047 ],
							[ 7.033206060000055, 6.466858477000073 ],
							[ 7.036256294000054, 6.473830641000063 ],
							[ 7.04540836700005, 6.471216269000024 ],
							[ 7.049659200000065, 6.474356678000049 ],
							[ 7.048458446000041, 6.485161357000038 ],
							[ 7.046279534000064, 6.500849774000073 ],
							[ 7.046279296000023, 6.512179937000042 ],
							[ 7.048458488000051, 6.527432393000026 ],
							[ 7.055431315000021, 6.545299889000034 ],
							[ 7.063275424000039, 6.554887376000067 ],
							[ 7.07809209800007, 6.557501900000034 ],
							[ 7.084193176000042, 6.562295389000042 ],
							[ 7.088551141000039, 6.568832514000064 ],
							[ 7.092037194000056, 6.575804765000044 ],
							[ 7.109468375000063, 6.593236367000031 ],
							[ 7.115569573000073, 6.603259628000046 ],
							[ 7.117748642000038, 6.615461401000061 ],
							[ 7.124720942000067, 6.625920174000044 ],
							[ 7.128642964000051, 6.635507593000057 ],
							[ 7.131693949000066, 6.643351511000049 ],
							[ 7.132565537000062, 6.652067663000025 ],
							[ 7.129078863000075, 6.659475804000067 ],
							[ 7.116877191000071, 6.666448697000021 ],
							[ 7.101624439000034, 6.675600191000058 ],
							[ 7.090293857000063, 6.677343031000021 ],
							[ 7.070247782000024, 6.685623298000053 ],
							[ 7.069753642000023, 6.685555692000037 ],
							[ 7.068487961000073, 6.687805995000076 ],
							[ 7.06510355000006, 6.692824863000055 ],
							[ 7.060386889000029, 6.695821831000046 ],
							[ 7.057693574000041, 6.697150668000063 ],
							[ 7.055682625000031, 6.696468307000032 ],
							[ 7.053705578000063, 6.689406579000035 ],
							[ 7.051172501000053, 6.682665409000037 ],
							[ 7.050637780000045, 6.68257243100004 ],
							[ 7.046279468000023, 6.687366084000075 ],
							[ 7.031898399000056, 6.696517346000064 ],
							[ 7.01664639300003, 6.706104736000043 ],
							[ 7.007494461000022, 6.708719805000044 ],
							[ 6.990934974000027, 6.713077481000028 ],
							[ 6.982655051000052, 6.713077483000063 ],
							[ 6.968709353000065, 6.711334323000074 ],
							[ 6.956507799000065, 6.710027345000071 ],
							[ 6.943869988000074, 6.706104761000063 ],
							[ 6.931667806000064, 6.698696598000026 ],
							[ 6.932539394000059, 6.71089829400006 ],
							[ 6.932975178000049, 6.722664876000067 ],
							[ 6.933411167000031, 6.73530239400003 ],
							[ 6.932072656000059, 6.767693572000042 ],
							[ 6.939578137000069, 6.77359092100005 ],
							[ 6.947413687000051, 6.785997491000046 ],
							[ 6.949030830000027, 6.788557971000046 ],
							[ 6.952182323000045, 6.808251936000033 ],
							[ 6.952182421000032, 6.827945781000039 ],
							[ 6.960817397000028, 6.840899050000075 ],
							[ 6.963210550000042, 6.84448868000004 ],
							[ 6.978178017000062, 6.850002735000032 ],
							[ 6.986843021000027, 6.844488716000058 ],
							[ 6.989994629000023, 6.833460263000063 ],
							[ 6.989994577000061, 6.823219291000044 ],
							[ 6.987630793000051, 6.81297863900005 ],
							[ 6.986843127000043, 6.806676449000065 ],
							[ 6.985267601000032, 6.798799074000044 ],
							[ 6.986055448000059, 6.788557899000068 ],
							[ 6.990782392000028, 6.785407089000046 ],
							[ 7.004961558000048, 6.785407135000071 ],
							[ 7.020716734000075, 6.789345839000021 ],
							[ 7.030169960000023, 6.785407030000044 ],
							[ 7.041986031000022, 6.783043832000033 ],
							[ 7.055377838000027, 6.786194716000068 ],
							[ 7.06010432000005, 6.79486048800004 ],
							[ 7.074284041000055, 6.79879898300004 ],
							[ 7.081373808000023, 6.800374538000028 ],
							[ 7.093190075000052, 6.819280590000062 ],
							[ 7.101067468000053, 6.828733369000076 ],
							[ 7.112884155000074, 6.837398937000046 ],
							[ 7.119973523000056, 6.848427399000059 ],
							[ 7.134153244000061, 6.85472960900006 ],
							[ 7.146757469000022, 6.864182507000066 ],
							[ 7.15227186900006, 6.875998954000067 ],
							[ 7.156210442000031, 6.88308862100007 ],
							[ 7.17038999500005, 6.883876289000057 ],
							[ 7.175904363000029, 6.896480480000037 ],
							[ 7.187720739000042, 6.901207001000046 ],
							[ 7.209887973000036, 6.902819248000071 ],
							[ 7.219230589000063, 6.905933265000044 ],
							[ 7.234985702000074, 6.910660406000034 ],
							[ 7.238136782000026, 6.921688465000045 ],
							[ 7.242863699000054, 6.927990316000034 ],
							[ 7.238924545000032, 6.941382532000034 ],
							[ 7.23813685600004, 6.957925343000056 ],
							[ 7.241288089000022, 6.971317371000055 ],
							[ 7.257830672000068, 6.980770001000053 ],
							[ 7.293279590000054, 6.994162165000034 ],
							[ 7.310144048000041, 7.003999623000027 ],
							[ 7.31299395800005, 6.999724358000037 ],
							[ 7.316786649000051, 6.998974328000031 ],
							[ 7.326880336000045, 6.999440704000051 ],
							[ 7.329821153000069, 7.000294778000068 ],
							[ 7.329790467000066, 7.007019393000064 ],
							[ 7.328493969000021, 7.014578904000075 ],
							[ 7.328107732000035, 7.024461366000025 ],
							[ 7.35393661300003, 7.034337551000021 ],
							[ 7.366836927000065, 7.039865897000027 ],
							[ 7.366937040000039, 7.040440243000035 ],
							[ 7.364408946000026, 7.048724265000033 ],
							[ 7.367466228000069, 7.056236603000059 ],
							[ 7.367583360000026, 7.062634336000031 ],
							[ 7.367074517000049, 7.064780221000035 ],
							[ 7.368167348000043, 7.071227239000052 ],
							[ 7.375259845000073, 7.092073206000066 ],
							[ 7.385961808000047, 7.109346229000039 ],
							[ 7.398158564000028, 7.113706141000023 ],
							[ 7.417560327000047, 7.113790365000057 ],
							[ 7.430476184000042, 7.118152301000066 ],
							[ 7.441973497000049, 7.118201322000061 ],
							[ 7.457075840000073, 7.115394197000057 ],
							[ 7.465713815000072, 7.111841750000053 ],
							[ 7.486626559000058, 7.093985161000035 ],
							[ 7.503968246000056, 7.070370898000021 ],
							[ 7.52629343600006, 7.057541969000056 ],
							[ 7.527767907000054, 7.048217271000055 ],
							[ 7.529822860000024, 7.032796357000052 ],
							[ 7.530513945000052, 7.032278044000066 ],
							[ 7.53827158100006, 7.020157759000028 ],
							[ 7.542209889000048, 7.008341719000043 ],
							[ 7.545361043000071, 7.001251711000066 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Federal Capital Territory',
				admin1Pcod: 'NG015',
				admin1RefN: 'Federal Capital Territory',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 3.498412,
				Shape_Area: 0.607222
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 7.672389156000065, 9.411277552000058 ],
							[ 7.719591356000024, 9.346354575000021 ],
							[ 7.729873720000057, 9.331881549000059 ],
							[ 7.727131371000041, 9.330638517000068 ],
							[ 7.667606114000023, 9.303650689000051 ],
							[ 7.603319026000065, 9.161440584000047 ],
							[ 7.588679113000069, 9.129055956000059 ],
							[ 7.589055153000061, 9.107646611000064 ],
							[ 7.592267237000044, 8.924564475000068 ],
							[ 7.594658760000073, 8.856400561000044 ],
							[ 7.591669137000054, 8.834875187000023 ],
							[ 7.565301303000069, 8.785279011000057 ],
							[ 7.54144287400004, 8.740403137000044 ],
							[ 7.518496926000068, 8.698686511000062 ],
							[ 7.501979553000069, 8.668651558000022 ],
							[ 7.496000787000071, 8.654301744000065 ],
							[ 7.493228465000072, 8.650736733000031 ],
							[ 7.479258585000025, 8.632776152000076 ],
							[ 7.477623738000034, 8.631333444000063 ],
							[ 7.443030644000032, 8.593281638000065 ],
							[ 7.41812717800002, 8.569902305000028 ],
							[ 7.397605820000024, 8.552622358000065 ],
							[ 7.371426125000028, 8.525280992000035 ],
							[ 7.355688874000066, 8.51244291200004 ],
							[ 7.34188673500006, 8.508452356000021 ],
							[ 7.336676273000023, 8.508596124000064 ],
							[ 7.312413313000036, 8.500782091000076 ],
							[ 7.271184033000054, 8.492465897000045 ],
							[ 7.210765491000075, 8.480960849000041 ],
							[ 7.162991009000052, 8.473337256000036 ],
							[ 7.116741289000061, 8.466221878000056 ],
							[ 7.084213805000047, 8.463680279000073 ],
							[ 7.057121862000031, 8.462964990000046 ],
							[ 7.025257966000026, 8.461647979000077 ],
							[ 6.991440651000062, 8.459823651000022 ],
							[ 6.984174740000071, 8.459377342000039 ],
							[ 6.94614352800005, 8.45893882300004 ],
							[ 6.827644088000056, 8.453450603000022 ],
							[ 6.778666576000035, 8.453830541000059 ],
							[ 6.778522372000054, 8.457550121000054 ],
							[ 6.780107920000034, 8.592872327000066 ],
							[ 6.779550879000055, 8.593106033000026 ],
							[ 6.778653202000044, 8.740360738000049 ],
							[ 6.780172040000025, 8.771520431000056 ],
							[ 6.780764973000032, 8.794405824000023 ],
							[ 6.782253395000055, 8.798002102000055 ],
							[ 6.795526474000042, 9.112392936000049 ],
							[ 6.800290570000072, 9.22510681600005 ],
							[ 6.953373078000027, 9.225987801000031 ],
							[ 6.961635948000037, 9.226033546000053 ],
							[ 6.986802020000027, 9.2263 ],
							[ 7.009547223000027, 9.227017903000046 ],
							[ 7.031083072000058, 9.229907511000022 ],
							[ 7.043642942000076, 9.23215388400007 ],
							[ 7.045871656000031, 9.230500929000073 ],
							[ 7.156177129000071, 9.146846621000066 ],
							[ 7.175747963000049, 9.131997177000073 ],
							[ 7.211495434000028, 9.104867418000026 ],
							[ 7.218979095000066, 9.101579750000042 ],
							[ 7.221700639000062, 9.110044452000068 ],
							[ 7.221936802000073, 9.110494277000043 ],
							[ 7.222413302000064, 9.112879569000029 ],
							[ 7.220556740000063, 9.113665701000059 ],
							[ 7.220021742000029, 9.113881250000077 ],
							[ 7.233965957000066, 9.133502264000072 ],
							[ 7.246386007000069, 9.15097885800003 ],
							[ 7.249756903000048, 9.155721869000047 ],
							[ 7.38816844400003, 9.349822979000066 ],
							[ 7.393836112000031, 9.353999385000066 ],
							[ 7.398538659000053, 9.357464010000058 ],
							[ 7.417672168000024, 9.367030941000053 ],
							[ 7.445176726000057, 9.369422875000055 ],
							[ 7.463114980000057, 9.368227131000026 ],
							[ 7.476269440000067, 9.370618872000023 ],
							[ 7.496598879000032, 9.362247435000029 ],
							[ 7.509752670000069, 9.361051365000037 ],
							[ 7.589875076000055, 9.431607240000062 ],
							[ 7.661629861000051, 9.413929610000025 ],
							[ 7.672389156000065, 9.411277552000058 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Gombe',
				admin1Pcod: 'NG016',
				admin1RefN: 'Gombe',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 5.955286,
				Shape_Area: 1.438747
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 11.071490203000053, 11.312490421000064 ],
							[ 11.074790062000034, 11.310799523000071 ],
							[ 11.078679937000061, 11.312480157000039 ],
							[ 11.080620084000032, 11.30984985300006 ],
							[ 11.085860530000048, 11.30552964900005 ],
							[ 11.088959691000071, 11.302709711000034 ],
							[ 11.092069741000046, 11.299899849000042 ],
							[ 11.09653012900003, 11.298010102000035 ],
							[ 11.098469565000073, 11.295389870000065 ],
							[ 11.101770233000025, 11.293689689000075 ],
							[ 11.107210237000061, 11.290499791000059 ],
							[ 11.110509716000024, 11.288809703000027 ],
							[ 11.113809693000064, 11.287110529000074 ],
							[ 11.118469955000023, 11.286359603000051 ],
							[ 11.121580141000038, 11.283539879000045 ],
							[ 11.130319659000065, 11.278650385000049 ],
							[ 11.133040179000034, 11.280529846000036 ],
							[ 11.13770024400003, 11.279769625000029 ],
							[ 11.141010382000047, 11.278079775000037 ],
							[ 11.144109445000026, 11.275259923000021 ],
							[ 11.147410341000068, 11.273570069000073 ],
							[ 11.150509915000043, 11.270750159000045 ],
							[ 11.15497943500003, 11.268870388000039 ],
							[ 11.159649602000059, 11.268110405000073 ],
							[ 11.163140269000053, 11.267550461000042 ],
							[ 11.166640015000041, 11.266980285000045 ],
							[ 11.170140331000027, 11.266410054000062 ],
							[ 11.175000379000039, 11.266779942000028 ],
							[ 11.178500160000056, 11.266219818000025 ],
							[ 11.181799608000063, 11.264519481000036 ],
							[ 11.186269473000038, 11.262639885000056 ],
							[ 11.193059968000057, 11.260379841000031 ],
							[ 11.197930276000022, 11.260749923000049 ],
							[ 11.20259001900007, 11.260000199000046 ],
							[ 11.207249681000064, 11.25924023400006 ],
							[ 11.210550158000046, 11.257550370000047 ],
							[ 11.212490198000069, 11.254919916000063 ],
							[ 11.216959769000027, 11.253040523000038 ],
							[ 11.22045987100006, 11.252469817000076 ],
							[ 11.223560405000057, 11.249649856000076 ],
							[ 11.226860118000047, 11.247959852000065 ],
							[ 11.230159823000065, 11.246270391000053 ],
							[ 11.233460638000054, 11.244580320000068 ],
							[ 11.237930547000076, 11.242699521000077 ],
							[ 11.242589914000064, 11.241939684000045 ],
							[ 11.247260142000073, 11.241180279000048 ],
							[ 11.250949675000072, 11.241740463000042 ],
							[ 11.25444968100004, 11.241180173000032 ],
							[ 11.259110700000065, 11.240420119000021 ],
							[ 11.262809977000074, 11.240980125000021 ],
							[ 11.270199953000031, 11.242099488000065 ],
							[ 11.273119703000077, 11.245099982000056 ],
							[ 11.275850395000077, 11.246970435000037 ],
							[ 11.279740522000054, 11.248649541000077 ],
							[ 11.283829540000056, 11.251460265000048 ],
							[ 11.286559812000064, 11.253339676000053 ],
							[ 11.292579802000034, 11.253520059000039 ],
							[ 11.296079796000072, 11.252949769000054 ],
							[ 11.300550638000061, 11.251069932000064 ],
							[ 11.303850030000035, 11.249380371000029 ],
							[ 11.305789914000059, 11.246749980000061 ],
							[ 11.309089820000054, 11.245059813000069 ],
							[ 11.31083013500006, 11.241299457000025 ],
							[ 11.313730426000063, 11.237360097000021 ],
							[ 11.315469531000076, 11.23359974300007 ],
							[ 11.317219525000041, 11.229850031000069 ],
							[ 11.319150075000039, 11.227219623000053 ],
							[ 11.320890269000074, 11.223469971000043 ],
							[ 11.321470517000023, 11.219899888000043 ],
							[ 11.323209526000028, 11.216150212000059 ],
							[ 11.326120268000068, 11.212199942000041 ],
							[ 11.326689636000026, 11.208640081000055 ],
							[ 11.328630301000032, 11.206010129000049 ],
							[ 11.329199551000045, 11.202440098000068 ],
							[ 11.330949833000034, 11.198690328000055 ],
							[ 11.332690299000035, 11.194929854000065 ],
							[ 11.334620600000051, 11.192310097000075 ],
							[ 11.336560538000072, 11.189680105000036 ],
							[ 11.338299740000025, 11.185919628000022 ],
							[ 11.340040117000058, 11.182169854000051 ],
							[ 11.34177982600005, 11.178409336000072 ],
							[ 11.343330291000029, 11.173529558000041 ],
							[ 11.343899992000047, 11.169969675000061 ],
							[ 11.345840328000065, 11.167340183000022 ],
							[ 11.348939724000047, 11.164520284000048 ],
							[ 11.349519666000049, 11.160960325000076 ],
							[ 11.352230176000035, 11.155890634000059 ],
							[ 11.353580227000066, 11.149880333000056 ],
							[ 11.355509509000058, 11.147250299000063 ],
							[ 11.358619592000025, 11.144439838000039 ],
							[ 11.360360079000031, 11.140680364000048 ],
							[ 11.362099448000038, 11.136930479000057 ],
							[ 11.364040596000052, 11.134300322000058 ],
							[ 11.364609960000053, 11.130729660000043 ],
							[ 11.365189694000037, 11.127169651000031 ],
							[ 11.368289739000033, 11.124349576000043 ],
							[ 11.37140004500003, 11.121529409000061 ],
							[ 11.37333019700003, 11.11890040000003 ],
							[ 11.376439899000047, 11.116089783000064 ],
							[ 11.378379706000032, 11.113459575000036 ],
							[ 11.380120400000067, 11.109699978000037 ],
							[ 11.382049837000068, 11.107069796000076 ],
							[ 11.383990188000041, 11.104450272000065 ],
							[ 11.38592987800007, 11.101820003000057 ],
							[ 11.389229730000068, 11.100119487000029 ],
							[ 11.392329989000075, 11.097309923000068 ],
							[ 11.395439879000037, 11.094490123000071 ],
							[ 11.39873969100006, 11.092800242000067 ],
							[ 11.400138878000064, 11.089087412000026 ],
							[ 11.402456535000056, 11.087930474000075 ],
							[ 11.407191456000021, 11.083399846000077 ],
							[ 11.412119974000063, 11.080209846000059 ],
							[ 11.415101015000062, 11.076471388000073 ],
							[ 11.417252650000023, 11.073772339000072 ],
							[ 11.420797625000034, 11.070808214000067 ],
							[ 11.424053236000077, 11.06752500500005 ],
							[ 11.427465625000025, 11.064084215000037 ],
							[ 11.429089404000024, 11.062446851000061 ],
							[ 11.434853477000047, 11.05590832200005 ],
							[ 11.439234526000064, 11.053138561000026 ],
							[ 11.443258972000024, 11.050594259000036 ],
							[ 11.448561447000031, 11.04665658600004 ],
							[ 11.452182768000057, 11.042205055000068 ],
							[ 11.456897013000059, 11.036409345000038 ],
							[ 11.459810512000047, 11.033257979000041 ],
							[ 11.470545482000034, 11.021645815000056 ],
							[ 11.475329181000063, 11.014795054000047 ],
							[ 11.482541069000035, 11.002781620000064 ],
							[ 11.488483711000072, 10.99595907500003 ],
							[ 11.494970038000076, 10.990889472000049 ],
							[ 11.504500679000046, 10.978930591000051 ],
							[ 11.509260318000031, 10.973240114000021 ],
							[ 11.513415341000041, 10.968695615000058 ],
							[ 11.517026810000061, 10.962398628000074 ],
							[ 11.519599155000037, 10.956843202000073 ],
							[ 11.521278538000047, 10.953218357000026 ],
							[ 11.527306791000058, 10.942335889000049 ],
							[ 11.528694886000039, 10.938942969000038 ],
							[ 11.530362358000048, 10.934866683000052 ],
							[ 11.530698038000025, 10.932335839000075 ],
							[ 11.531051581000042, 10.929661917000033 ],
							[ 11.53150548900004, 10.923272169000029 ],
							[ 11.53187342700005, 10.91808031100004 ],
							[ 11.531004092000046, 10.904140476000066 ],
							[ 11.529787963000047, 10.897920330000034 ],
							[ 11.528905069000075, 10.893400943000074 ],
							[ 11.52737731700006, 10.891585144000032 ],
							[ 11.527787205000038, 10.88691340400004 ],
							[ 11.52812885000003, 10.883029805000035 ],
							[ 11.528417442000034, 10.879737594000062 ],
							[ 11.528674367000065, 10.876823606000073 ],
							[ 11.531390343000055, 10.871069893000026 ],
							[ 11.534164584000052, 10.867039703000046 ],
							[ 11.536488286000065, 10.86366349900004 ],
							[ 11.539074697000046, 10.850962823000032 ],
							[ 11.538146092000034, 10.839919762000022 ],
							[ 11.534410315000059, 10.833539977000044 ],
							[ 11.536439973000029, 10.828774642000042 ],
							[ 11.536177524000038, 10.82305311500005 ],
							[ 11.534790924000049, 10.816131759000029 ],
							[ 11.534076581000022, 10.812563861000058 ],
							[ 11.532619221000061, 10.807447172000025 ],
							[ 11.532677794000051, 10.804135053000039 ],
							[ 11.530296496000062, 10.799135407000051 ],
							[ 11.528739060000021, 10.790397454000072 ],
							[ 11.528603661000034, 10.785740141000076 ],
							[ 11.528509040000074, 10.782501940000031 ],
							[ 11.52840139500006, 10.778790315000037 ],
							[ 11.526962344000026, 10.773483056000032 ],
							[ 11.526517905000048, 10.757864260000076 ],
							[ 11.52216732200003, 10.749465092000037 ],
							[ 11.519827022000072, 10.744948210000075 ],
							[ 11.518564348000041, 10.739901315000054 ],
							[ 11.516615182000066, 10.732112956000037 ],
							[ 11.516856902000029, 10.720517103000077 ],
							[ 11.515468379000026, 10.70366380300004 ],
							[ 11.515070768000044, 10.694954009000071 ],
							[ 11.516460688000052, 10.68396567800005 ],
							[ 11.521214717000021, 10.666885240000056 ],
							[ 11.522719195000036, 10.661479898000039 ],
							[ 11.524185346000024, 10.660006421000048 ],
							[ 11.521450924000021, 10.639417655000045 ],
							[ 11.519313864000026, 10.630667694000067 ],
							[ 11.518379395000068, 10.626752038000063 ],
							[ 11.51730137100003, 10.622234431000038 ],
							[ 11.516113313000062, 10.617252529000041 ],
							[ 11.51474842600004, 10.612222664000058 ],
							[ 11.513419941000052, 10.60732930100005 ],
							[ 11.512864398000033, 10.603256226000042 ],
							[ 11.512397958000065, 10.599837426000022 ],
							[ 11.513373105000028, 10.594977584000048 ],
							[ 11.512720824000041, 10.585271001000024 ],
							[ 11.514617893000036, 10.577775135000024 ],
							[ 11.516513733000068, 10.570278134000034 ],
							[ 11.52187374500005, 10.568999122000037 ],
							[ 11.527589543000033, 10.567634525000074 ],
							[ 11.532735685000034, 10.571234935000064 ],
							[ 11.537276427000052, 10.575980247000075 ],
							[ 11.54499456700006, 10.582441209000024 ],
							[ 11.548360134000063, 10.585259163000046 ],
							[ 11.551528658000052, 10.58791173000003 ],
							[ 11.561311795000051, 10.591618764000032 ],
							[ 11.572894895000047, 10.592467423000073 ],
							[ 11.583307471000069, 10.593869156000039 ],
							[ 11.593669889000068, 10.597590496000066 ],
							[ 11.602257801000064, 10.603010148000067 ],
							[ 11.613828400000045, 10.604437917000041 ],
							[ 11.624216172000047, 10.606999294000047 ],
							[ 11.628817532000028, 10.608846595000045 ],
							[ 11.632864258000041, 10.609520222000071 ],
							[ 11.640150340000048, 10.609040031000063 ],
							[ 11.640003111000055, 10.600985692000052 ],
							[ 11.638506189000054, 10.589350033000073 ],
							[ 11.638753958000052, 10.587132700000041 ],
							[ 11.639218471000049, 10.582985836000034 ],
							[ 11.638772052000036, 10.576595436000048 ],
							[ 11.638313314000072, 10.57078371800003 ],
							[ 11.639074352000023, 10.562100203000057 ],
							[ 11.642770520000056, 10.551745197000059 ],
							[ 11.648750031000077, 10.543184253000049 ],
							[ 11.650200942000026, 10.52929666700004 ],
							[ 11.647495081000045, 10.519953045000022 ],
							[ 11.644501480000031, 10.512694150000073 ],
							[ 11.643630913000038, 10.510583195000038 ],
							[ 11.640370270000062, 10.50006701600006 ],
							[ 11.640576590000023, 10.490210382000043 ],
							[ 11.638523332000034, 10.477401600000064 ],
							[ 11.636904697000034, 10.471563096000068 ],
							[ 11.635866398000076, 10.465739079000059 ],
							[ 11.635926943000072, 10.462838087000023 ],
							[ 11.636035794000065, 10.457622512000057 ],
							[ 11.635082261000036, 10.447739636000051 ],
							[ 11.635102828000072, 10.446753844000057 ],
							[ 11.635275793000062, 10.438463388000059 ],
							[ 11.636097765000045, 10.426881712000068 ],
							[ 11.635131967000063, 10.417578673000037 ],
							[ 11.634142090000068, 10.40943528400004 ],
							[ 11.634692942000072, 10.402234108000073 ],
							[ 11.634939361000022, 10.399012731000028 ],
							[ 11.635480148000056, 10.394550546000062 ],
							[ 11.624470522000024, 10.381444774000045 ],
							[ 11.602020361000029, 10.356588576000036 ],
							[ 11.59561984000004, 10.353462821000051 ],
							[ 11.567541270000049, 10.339750385000059 ],
							[ 11.546287347000032, 10.327705417000061 ],
							[ 11.54573775800003, 10.327393955000048 ],
							[ 11.543486719000043, 10.326118252000072 ],
							[ 11.536784086000068, 10.316064961000052 ],
							[ 11.533864883000035, 10.311686444000031 ],
							[ 11.533394785000041, 10.305102735000048 ],
							[ 11.537587461000044, 10.303408754000031 ],
							[ 11.54395304600007, 10.299749783000038 ],
							[ 11.549734585000067, 10.296079024000051 ],
							[ 11.552588836000041, 10.294266830000026 ],
							[ 11.554185258000075, 10.293405861000053 ],
							[ 11.565323023000076, 10.287399132000075 ],
							[ 11.57024838500007, 10.273806821000051 ],
							[ 11.582856, 10.249313308000069 ],
							[ 11.589072362000024, 10.224864672000024 ],
							[ 11.601275615000077, 10.208057048000057 ],
							[ 11.656876340000053, 10.196814881000023 ],
							[ 11.663715255000056, 10.195861576000027 ],
							[ 11.666920135000055, 10.194145827000057 ],
							[ 11.678263102000074, 10.188073313000075 ],
							[ 11.685305940000035, 10.181938731000059 ],
							[ 11.691866661000063, 10.176223967000055 ],
							[ 11.700497256000062, 10.170737522000024 ],
							[ 11.710227427000063, 10.166971378000028 ],
							[ 11.716165592000038, 10.166779907000034 ],
							[ 11.723095073000025, 10.166556471000035 ],
							[ 11.739933779000069, 10.16415037400003 ],
							[ 11.748753799000042, 10.155330875000061 ],
							[ 11.757574097000031, 10.140897733000031 ],
							[ 11.759979389000023, 10.133681360000026 ],
							[ 11.774412013000074, 10.119248232000075 ],
							[ 11.789989499000058, 10.103168287000074 ],
							[ 11.799268752000046, 10.09358972800004 ],
							[ 11.806795974000067, 10.087003822000042 ],
							[ 11.807842227000037, 10.08486950200006 ],
							[ 11.811083690000032, 10.074794487000077 ],
							[ 11.81701462500007, 10.068552176000026 ],
							[ 11.822365714000057, 10.062296137000033 ],
							[ 11.828603850000036, 10.05387802000007 ],
							[ 11.831229959000041, 10.052479693000066 ],
							[ 11.833379630000024, 10.048069774000055 ],
							[ 11.838539831000048, 10.045049431000052 ],
							[ 11.843390308000039, 10.044590047000042 ],
							[ 11.843942566000067, 10.04154683400003 ],
							[ 11.843921539000064, 10.036969873000032 ],
							[ 11.843910447000042, 10.034681406000061 ],
							[ 11.845056632000023, 10.032390813000063 ],
							[ 11.847345136000058, 10.026664812000035 ],
							[ 11.849639113000023, 10.022083011000063 ],
							[ 11.851921893000053, 10.015213299000038 ],
							[ 11.854205164000064, 10.008342991000063 ],
							[ 11.855346773000065, 10.004907562000028 ],
							[ 11.85299010500006, 9.995759080000028 ],
							[ 11.85062683600006, 9.985466904000077 ],
							[ 11.850605737000024, 9.980891076000034 ],
							[ 11.850585186000046, 9.976313539000046 ],
							[ 11.851693880000028, 9.966013792000069 ],
							[ 11.852820389000044, 9.959146745000055 ],
							[ 11.853945692000025, 9.952279126000064 ],
							[ 11.854986282000027, 9.927104730000053 ],
							[ 11.853808184000059, 9.922531043000049 ],
							[ 11.852623986000026, 9.916813095000066 ],
							[ 11.851440398000022, 9.911094582000032 ],
							[ 11.850267384000063, 9.90766512600004 ],
							[ 11.847920315000067, 9.90080561700006 ],
							[ 11.846747366000045, 9.897376147000045 ],
							[ 11.844410933000063, 9.89280397400006 ],
							[ 11.843243600000051, 9.890518180000072 ],
							[ 11.839738816000022, 9.883661296000071 ],
							[ 11.83619783100005, 9.868795219000049 ],
							[ 11.836171957000033, 9.863074534000077 ],
							[ 11.836149890000058, 9.858498680000025 ],
							[ 11.837286816000073, 9.853919004000034 ],
							[ 11.840700084000048, 9.841324806000046 ],
							[ 11.848738637000054, 9.827576628000031 ],
							[ 11.849880295000048, 9.824141730000065 ],
							[ 11.853325702000063, 9.818412889000058 ],
							[ 11.854462874000035, 9.813834273000055 ],
							[ 11.857860692000031, 9.797807677000037 ],
							[ 11.857850102000043, 9.795519728000045 ],
							[ 11.856681839000032, 9.793233972000053 ],
							[ 11.85319331200003, 9.789809381000055 ],
							[ 11.850863614000048, 9.78638148400006 ],
							[ 11.847374638000076, 9.782957988000021 ],
							[ 11.840398583000024, 9.776108607000026 ],
							[ 11.83575335300003, 9.772687093000059 ],
							[ 11.833433254000056, 9.771548173000042 ],
							[ 11.827629994000063, 9.768128658000023 ],
							[ 11.823396674000037, 9.76605044300004 ],
							[ 11.820669867000049, 9.764712238000072 ],
							[ 11.817141277000076, 9.762731987000052 ],
							[ 11.811478173000069, 9.75536459500006 ],
							[ 11.801367268000035, 9.736503719000041 ],
							[ 11.784703003000061, 9.729219165000075 ],
							[ 11.774005977000058, 9.708401097000035 ],
							[ 11.772902262000059, 9.706252998000025 ],
							[ 11.759548955000071, 9.697295806000056 ],
							[ 11.750403427000037, 9.69571733600003 ],
							[ 11.732924261000051, 9.690083766000043 ],
							[ 11.717346916000054, 9.684037553000053 ],
							[ 11.716515564000076, 9.683506831000045 ],
							[ 11.711619205000034, 9.680097471000067 ],
							[ 11.70686558400007, 9.676787602000047 ],
							[ 11.700855266000076, 9.673814589000074 ],
							[ 11.699039222000067, 9.671813908000047 ],
							[ 11.696383256000047, 9.668886366000038 ],
							[ 11.688261139000076, 9.664328824000052 ],
							[ 11.683625991000042, 9.663194492000059 ],
							[ 11.677817480000044, 9.65863119900007 ],
							[ 11.67201523600005, 9.655212623000068 ],
							[ 11.663881507000042, 9.648366013000043 ],
							[ 11.660382285000026, 9.642653354000061 ],
							[ 11.654569454000068, 9.636945983000032 ],
							[ 11.64528858400007, 9.632390155000053 ],
							[ 11.636013976000072, 9.628978925000069 ],
							[ 11.632525702000066, 9.625553946000025 ],
							[ 11.624403090000044, 9.620996688000048 ],
							[ 11.615117053000063, 9.615296416000035 ],
							[ 11.608162110000023, 9.613024779000057 ],
							[ 11.598887210000044, 9.60961317500005 ],
							[ 11.588470220000033, 9.609637168000063 ],
							[ 11.583835516000022, 9.608503288000065 ],
							[ 11.578043227000023, 9.60737234100003 ],
							[ 11.57109336700006, 9.606244289000074 ],
							[ 11.562986482000042, 9.605118472000072 ],
							[ 11.545625594000057, 9.605158867000057 ],
							[ 11.527323051000053, 9.60823713700006 ],
							[ 11.515543879000063, 9.607515133000049 ],
							[ 11.506284573000073, 9.607536273000051 ],
							[ 11.501655759000073, 9.607546751000029 ],
							[ 11.491222143000073, 9.604138612000042 ],
							[ 11.484278713000037, 9.604154868000023 ],
							[ 11.473844778000057, 9.600746223000044 ],
							[ 11.468048048000071, 9.598470415000065 ],
							[ 11.461077948000025, 9.592766056000073 ],
							[ 11.452939066000056, 9.584775642000068 ],
							[ 11.447883383000033, 9.579132324000057 ],
							[ 11.444465870000045, 9.576152655000044 ],
							[ 11.442859586000054, 9.569734305000054 ],
							[ 11.441893610000022, 9.560431446000052 ],
							[ 11.433946865000053, 9.552126847000068 ],
							[ 11.429598748000046, 9.549625590000062 ],
							[ 11.427642005000052, 9.548499808000031 ],
							[ 11.420153772000049, 9.546006072000068 ],
							[ 11.405684331000032, 9.544509901000026 ],
							[ 11.393471852000062, 9.545966929000031 ],
							[ 11.381296876000022, 9.545684567000023 ],
							[ 11.373851973000058, 9.546802773000024 ],
							[ 11.369313781000074, 9.547484024000028 ],
							[ 11.367914470000073, 9.547694166000042 ],
							[ 11.353419083000063, 9.547357375000047 ],
							[ 11.346438669000065, 9.54835622500002 ],
							[ 11.337729440000032, 9.548734693000029 ],
							[ 11.329032991000076, 9.548532541000043 ],
							[ 11.32032487400005, 9.548910016000036 ],
							[ 11.311049639000032, 9.548694691000037 ],
							[ 11.298292984000057, 9.548399086000074 ],
							[ 11.293075707000071, 9.548277813000027 ],
							[ 11.278014462000044, 9.547348800000066 ],
							[ 11.274511279000023, 9.548427682000067 ],
							[ 11.269281115000069, 9.548886486000072 ],
							[ 11.254666246000056, 9.554347156000063 ],
							[ 11.244061246000058, 9.562221787000055 ],
							[ 11.227090087000022, 9.569368317000055 ],
							[ 11.22245195000005, 9.569261772000061 ],
							[ 11.210349203000021, 9.565500309000072 ],
							[ 11.198839092000071, 9.561172664000026 ],
							[ 11.191942331000064, 9.558113046000074 ],
							[ 11.182134589000043, 9.555564964000041 ],
							[ 11.171167639000032, 9.552990111000042 ],
							[ 11.161347099000068, 9.551022459000023 ],
							[ 11.150912030000029, 9.550780493000047 ],
							[ 11.139316503000032, 9.550511627000049 ],
							[ 11.126549682000075, 9.550794461000066 ],
							[ 11.117840759000046, 9.551173386000073 ],
							[ 11.109132970000076, 9.551550970000051 ],
							[ 11.097536894000029, 9.551281925000069 ],
							[ 11.088839413000073, 9.551079770000058 ],
							[ 11.080131606000066, 9.551458374000049 ],
							[ 11.070855903000052, 9.551242647000038 ],
							[ 11.062135961000024, 9.552200114000073 ],
							[ 11.035526005000065, 9.548683181000058 ],
							[ 11.027398142000038, 9.549074286000064 ],
							[ 11.020428432000074, 9.549492800000053 ],
							[ 11.008192816000076, 9.552108639000039 ],
							[ 10.99238200800005, 9.559283249000032 ],
							[ 10.984711680000032, 9.565485072000058 ],
							[ 10.979952616000048, 9.571175575000041 ],
							[ 10.978141060000041, 9.574613635000048 ],
							[ 10.977500165000038, 9.578269191000061 ],
							[ 10.975506617000065, 9.589634055000033 ],
							[ 10.97489368600003, 9.592890513000043 ],
							[ 10.973549847000072, 9.600029009000025 ],
							[ 10.971616859000051, 9.609264292000034 ],
							[ 10.969091509000066, 9.61906721500003 ],
							[ 10.966554890000054, 9.629448834000073 ],
							[ 10.960938488000068, 9.648460295000064 ],
							[ 10.957796237000025, 9.659987629000057 ],
							[ 10.951502866000055, 9.683623137000041 ],
							[ 10.950101093000058, 9.695192601000031 ],
							[ 10.950426377000042, 9.707380182000065 ],
							[ 10.950280412000041, 9.714368139000044 ],
							[ 10.950038754000047, 9.725933158000032 ],
							[ 10.949846054000034, 9.735155082000063 ],
							[ 10.949824335000073, 9.736205690000077 ],
							[ 10.950155191000022, 9.737432972000022 ],
							[ 10.95359482300006, 9.750192872000071 ],
							[ 10.959460638000053, 9.760042168000041 ],
							[ 10.961153074000038, 9.765796402000035 ],
							[ 10.960371567000038, 9.774857585000063 ],
							[ 10.951311649000047, 9.788903017000052 ],
							[ 10.950486597000065, 9.791399918000025 ],
							[ 10.950881090000053, 9.796717440000066 ],
							[ 10.950120039000069, 9.805401001000064 ],
							[ 10.944345450000071, 9.831948087000058 ],
							[ 10.943521156000031, 9.837276551000059 ],
							[ 10.944209757000067, 9.845574404000047 ],
							[ 10.944686945000058, 9.855267974000071 ],
							[ 10.946005712000044, 9.863270942000042 ],
							[ 10.945603016000064, 9.867488499000046 ],
							[ 10.940967625000042, 9.875522199000045 ],
							[ 10.936535682000056, 9.882932069000049 ],
							[ 10.936200580000047, 9.883492682000053 ],
							[ 10.933035883000059, 9.901292037000076 ],
							[ 10.928464603000066, 9.909826442000053 ],
							[ 10.906676273000073, 9.920537937000063 ],
							[ 10.875178272000028, 9.940522264000037 ],
							[ 10.865881979000051, 9.949856779000072 ],
							[ 10.857723364000037, 9.955719778000059 ],
							[ 10.841357427000048, 9.962840147000065 ],
							[ 10.824990310000032, 9.969960349000075 ],
							[ 10.812171832000047, 9.979338556000073 ],
							[ 10.802899217000061, 9.990977369000063 ],
							[ 10.794776855000066, 10.000296401000071 ],
							[ 10.788445397000032, 10.006125230000066 ],
							[ 10.775262019000024, 10.012479640000038 ],
							[ 10.763509049000049, 10.019747821000067 ],
							[ 10.751164589000041, 10.027581238000039 ],
							[ 10.740003412000021, 10.034282468000072 ],
							[ 10.727671742000041, 10.041537533000053 ],
							[ 10.717476174000069, 10.049853048000045 ],
							[ 10.714216089000047, 10.051123747000076 ],
							[ 10.695251731000042, 10.066306851000036 ],
							[ 10.689924364000035, 10.071404378000068 ],
							[ 10.684512985000026, 10.080558802000041 ],
							[ 10.693126790000065, 10.097456086000022 ],
							[ 10.700672160000067, 10.112256047000074 ],
							[ 10.718596313000035, 10.114992284000039 ],
							[ 10.730204452000066, 10.114681068000039 ],
							[ 10.740652056000044, 10.11434441800003 ],
							[ 10.749928227000055, 10.114559443000076 ],
							[ 10.760685975000058, 10.113011538000023 ],
							[ 10.770280823000064, 10.11213114800006 ],
							[ 10.780173181000066, 10.110619589000066 ],
							[ 10.790633170000035, 10.109702843000036 ],
							[ 10.796998293000058, 10.110430849000068 ],
							[ 10.806177122000065, 10.115284114000076 ],
							[ 10.811818293000044, 10.122956056000021 ],
							[ 10.815174905000049, 10.12883376700006 ],
							[ 10.817753682000046, 10.141707337000071 ],
							[ 10.818917421000037, 10.144064714000024 ],
							[ 10.818640488000028, 10.157335986000021 ],
							[ 10.817226432000041, 10.169484957000066 ],
							[ 10.819883375000074, 10.181147750000036 ],
							[ 10.823796066000057, 10.188199092000048 ],
							[ 10.830290854000054, 10.196922052000048 ],
							[ 10.831428979000066, 10.198451128000045 ],
							[ 10.835629403000041, 10.204093893000049 ],
							[ 10.839326813000071, 10.215169927000034 ],
							[ 10.851166821000049, 10.224235437000061 ],
							[ 10.857159818000071, 10.235681753000051 ],
							[ 10.845745297000065, 10.266931792000037 ],
							[ 10.831813092000061, 10.282085241000061 ],
							[ 10.826063403000035, 10.293679250000025 ],
							[ 10.828570281000054, 10.308624483000074 ],
							[ 10.829841630000033, 10.317824085000041 ],
							[ 10.831162597000059, 10.331632592000062 ],
							[ 10.833607714000038, 10.340818453000054 ],
							[ 10.833729903000062, 10.352337625000075 ],
							[ 10.833864192000021, 10.365008313000033 ],
							[ 10.834033691000059, 10.381135805000042 ],
							[ 10.835072661000027, 10.395102730000076 ],
							[ 10.835403480000025, 10.399551459000065 ],
							[ 10.835104173000047, 10.402119417000051 ],
							[ 10.825754718000042, 10.420228679000047 ],
							[ 10.809185533000061, 10.434306933000073 ],
							[ 10.799287647000028, 10.451645171000052 ],
							[ 10.793504046000066, 10.459906597000042 ],
							[ 10.78491116500004, 10.46793907600005 ],
							[ 10.779782148000038, 10.470533600000067 ],
							[ 10.765764121000075, 10.47762317400003 ],
							[ 10.74937428800007, 10.482438923000075 ],
							[ 10.728274226000053, 10.486162251000053 ],
							[ 10.704875246000029, 10.494523828000069 ],
							[ 10.691982319000033, 10.496991210000033 ],
							[ 10.660314312000025, 10.500848635000068 ],
							[ 10.639189895000072, 10.50226893100006 ],
							[ 10.616939717000037, 10.508310498000071 ],
							[ 10.588965385000051, 10.528250630000059 ],
							[ 10.577321051000069, 10.537614647000055 ],
							[ 10.566887024000039, 10.550419946000034 ],
							[ 10.558776173000069, 10.560891129000026 ],
							[ 10.553527220000035, 10.573633487000052 ],
							[ 10.553559645000064, 10.581196480000074 ],
							[ 10.553619652000066, 10.595192370000063 ],
							[ 10.542110551000064, 10.628996739000058 ],
							[ 10.514791846000037, 10.665335445000039 ],
							[ 10.503181234000067, 10.676085752000063 ],
							[ 10.493204405000029, 10.679419151000047 ],
							[ 10.486557391000076, 10.682739507000065 ],
							[ 10.47868107000005, 10.687846800000045 ],
							[ 10.481477548000043, 10.692054768000048 ],
							[ 10.495824668000068, 10.716066131000048 ],
							[ 10.501793009000039, 10.725208050000049 ],
							[ 10.510170835000054, 10.740079153000067 ],
							[ 10.524259685000061, 10.761432464000052 ],
							[ 10.526629584000034, 10.763833160000047 ],
							[ 10.531493481000041, 10.768494424000039 ],
							[ 10.532866266000042, 10.769031186000063 ],
							[ 10.537638142000048, 10.771365357000036 ],
							[ 10.539553020000028, 10.773710794000067 ],
							[ 10.543870415000072, 10.781225329000051 ],
							[ 10.544029127000044, 10.781991436000055 ],
							[ 10.55065518400005, 10.792561591000037 ],
							[ 10.551976371000023, 10.80636895300006 ],
							[ 10.559265142000072, 10.829317821000075 ],
							[ 10.564107920000026, 10.843081448000021 ],
							[ 10.568975342000044, 10.859149115000037 ],
							[ 10.571445663000077, 10.870638791000033 ],
							[ 10.576300767000021, 10.885554290000073 ],
							[ 10.579859676000069, 10.888965492000068 ],
							[ 10.585755261000031, 10.891194243000029 ],
							[ 10.589277996000021, 10.891150345000028 ],
							[ 10.593951114000049, 10.888786517000028 ],
							[ 10.599773348000042, 10.884104978000039 ],
							[ 10.606733167000073, 10.875951957000041 ],
							[ 10.610171293000064, 10.867843479000044 ],
							[ 10.617764429000033, 10.858255106000058 ],
							[ 10.629999091000059, 10.854919222000035 ],
							[ 10.642976642000065, 10.860515646000067 ],
							[ 10.645520168000076, 10.878916590000074 ],
							[ 10.638669267000068, 10.897435983000037 ],
							[ 10.634615172000053, 10.903776059000052 ],
							[ 10.628272327000047, 10.913697137000042 ],
							[ 10.615489121000053, 10.926531839000063 ],
							[ 10.602949970000054, 10.96240533200006 ],
							[ 10.610226862000047, 10.984202639000046 ],
							[ 10.618556238000053, 10.994465733000027 ],
							[ 10.630407066000032, 11.004682673000048 ],
							[ 10.641072995000059, 11.013765368000065 ],
							[ 10.648155445000043, 11.017132028000049 ],
							[ 10.658723893000058, 11.016998456000067 ],
							[ 10.670490206000068, 11.019152645000077 ],
							[ 10.67766952900007, 11.031735641000068 ],
							[ 10.688335134000056, 11.040816397000071 ],
							[ 10.701483031000066, 11.062539285000071 ],
							[ 10.712209431000076, 11.077380100000028 ],
							[ 10.720526946000064, 11.086491648000049 ],
							[ 10.73237911800004, 11.096710380000047 ],
							[ 10.761953471000027, 11.117071923000026 ],
							[ 10.769010667000032, 11.118135610000024 ],
							[ 10.779591690000075, 11.119152889000077 ],
							[ 10.793634285000053, 11.114366714000028 ],
							[ 10.804117289000033, 11.106169875000035 ],
							[ 10.807864088000031, 11.103155859000026 ],
							[ 10.809939169000074, 11.101487384000052 ],
							[ 10.822697504000075, 11.086348258000044 ],
							[ 10.82376541800005, 11.085429357000066 ],
							[ 10.829277503000071, 11.080681961000039 ],
							[ 10.836810570000068, 11.077890217000061 ],
							[ 10.843479996000042, 11.077385903000049 ],
							[ 10.849206665000054, 11.078769946000023 ],
							[ 10.851632454000026, 11.080311110000025 ],
							[ 10.854758442000048, 11.082297390000065 ],
							[ 10.860675893000064, 11.084832407000022 ],
							[ 10.86161743200006, 11.086384337000027 ],
							[ 10.866634463000025, 11.088556517000029 ],
							[ 10.870060046000049, 11.092145267000035 ],
							[ 10.870926655000062, 11.09380038200004 ],
							[ 10.874420841000074, 11.105867002000025 ],
							[ 10.874583366000024, 11.106428383000036 ],
							[ 10.897173639000073, 11.132639829000027 ],
							[ 10.904365748000032, 11.14637381600005 ],
							[ 10.924461328000064, 11.158791649000023 ],
							[ 10.936313655000049, 11.169010057000037 ],
							[ 10.949586092000061, 11.182710784000051 ],
							[ 10.951748979000058, 11.18494312200005 ],
							[ 10.970719391000046, 11.201984559000039 ],
							[ 10.984993248000023, 11.21908465000007 ],
							[ 11.000440380000043, 11.236169949000043 ],
							[ 11.013649660000056, 11.263651850000031 ],
							[ 11.019844767000052, 11.277661254000066 ],
							[ 11.023275241000022, 11.285419535000074 ],
							[ 11.022173859000077, 11.292345923000028 ],
							[ 11.011630312000023, 11.29478367300004 ],
							[ 11.007315551000033, 11.295865316000061 ],
							[ 11.012749626000073, 11.298859321000066 ],
							[ 11.017020440000067, 11.29585 ],
							[ 11.019749671000056, 11.297730239000032 ],
							[ 11.028300236000064, 11.298649787000045 ],
							[ 11.030059856000037, 11.301839883000071 ],
							[ 11.03297979000007, 11.304839987000037 ],
							[ 11.036869984000077, 11.306520482000053 ],
							[ 11.041729838000037, 11.306889303000048 ],
							[ 11.051260109000054, 11.306510167000056 ],
							[ 11.054949992000047, 11.307070019000037 ],
							[ 11.058850028000052, 11.30875024900007 ],
							[ 11.062740421000058, 11.310440100000051 ],
							[ 11.066430308000065, 11.310990248000053 ],
							[ 11.071490203000053, 11.312490421000064 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Imo',
				admin1Pcod: 'NG017',
				admin1RefN: 'Imo',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 2.878088,
				Shape_Area: 0.414097
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 7.265750939000043, 5.930810153000039 ],
							[ 7.266066196000054, 5.927650760000063 ],
							[ 7.266761561000067, 5.92228276700007 ],
							[ 7.269452607000062, 5.919945513000073 ],
							[ 7.283199515000035, 5.918990324000049 ],
							[ 7.284521172000041, 5.918389822000051 ],
							[ 7.287953778000031, 5.91779226400007 ],
							[ 7.29023978500004, 5.917202462000034 ],
							[ 7.293385844000056, 5.916606920000049 ],
							[ 7.29596237100003, 5.916301735000047 ],
							[ 7.300262505000035, 5.916272159000073 ],
							[ 7.302838726000061, 5.915967479000074 ],
							[ 7.305411757000059, 5.915375673000028 ],
							[ 7.307701461000022, 5.915072890000033 ],
							[ 7.30970089300007, 5.914485028000058 ],
							[ 7.31227737100005, 5.914180301000044 ],
							[ 7.31542338600002, 5.913584700000058 ],
							[ 7.317999852000071, 5.913280062000069 ],
							[ 7.320003045000021, 5.91297914300003 ],
							[ 7.322866008000062, 5.91267252800003 ],
							[ 7.324872947000074, 5.912658711000063 ],
							[ 7.328022409000027, 5.912349702000029 ],
							[ 7.33116866000006, 5.911754114000075 ],
							[ 7.335178747000043, 5.911439360000031 ],
							[ 7.338603915000022, 5.91026779300006 ],
							[ 7.340890346000037, 5.909677973000043 ],
							[ 7.343448717000058, 5.907937945000072 ],
							[ 7.345731337000075, 5.907061096000064 ],
							[ 7.350031356000045, 5.907031508000046 ],
							[ 7.35346781800007, 5.906721168000047 ],
							[ 7.355757149000056, 5.906418379000058 ],
							[ 7.358896278000032, 5.905248706000066 ],
							[ 7.362891653000077, 5.903785731000028 ],
							[ 7.367187826000077, 5.903469091000034 ],
							[ 7.369202030000054, 5.904029415000025 ],
							[ 7.371215686000028, 5.904589706000024 ],
							[ 7.373803179000049, 5.905145650000065 ],
							[ 7.376680971000042, 5.905986846000076 ],
							[ 7.378415482000037, 5.90712306100005 ],
							[ 7.380436485000075, 5.908257426000034 ],
							[ 7.382457799000065, 5.909391355000025 ],
							[ 7.385055931000068, 5.910808580000037 ],
							[ 7.387947935000057, 5.912797897000075 ],
							[ 7.391950628000075, 5.91190913500003 ],
							[ 7.394222266000043, 5.910171455000068 ],
							[ 7.395909694000068, 5.907576514000027 ],
							[ 7.396443465000061, 5.904415562000054 ],
							[ 7.396396044000028, 5.900684913000021 ],
							[ 7.396083917000055, 5.898677860000021 ],
							[ 7.394879354000068, 5.894093521000059 ],
							[ 7.393707242000062, 5.892092170000069 ],
							[ 7.392535676000023, 5.890091373000075 ],
							[ 7.391345612000066, 5.886655381000025 ],
							[ 7.391029720000063, 5.884361212000044 ],
							[ 7.389560282000048, 5.881501150000076 ],
							[ 7.389445902000034, 5.879099788000076 ],
							[ 7.388624091000054, 5.875480154000059 ],
							[ 7.388311772000066, 5.873472684000035 ],
							[ 7.387415895000061, 5.870608806000064 ],
							[ 7.387100318000023, 5.868314765000036 ],
							[ 7.386788270000068, 5.866307720000066 ],
							[ 7.38646183000003, 5.863153018000048 ],
							[ 7.38643273200006, 5.860856574000024 ],
							[ 7.386686846000032, 5.858271582000043 ],
							[ 7.387209458000029, 5.854249959000072 ],
							[ 7.388312891000055, 5.850798062000024 ],
							[ 7.389423376000025, 5.847919991000026 ],
							[ 7.389964189000068, 5.845333147000076 ],
							[ 7.391049045000045, 5.840446047000057 ],
							[ 7.391310186000055, 5.83843515600006 ],
							[ 7.391854779000028, 5.836134883000057 ],
							[ 7.392399382000065, 5.83383516400005 ],
							[ 7.392649611000024, 5.83096318500003 ],
							[ 7.391735574000052, 5.826663953000036 ],
							[ 7.392265440000074, 5.823216018000039 ],
							[ 7.392791784000053, 5.819481362000033 ],
							[ 7.393042001000026, 5.816609382000024 ],
							[ 7.39298782700007, 5.812304041000061 ],
							[ 7.392374387000075, 5.809151188000044 ],
							[ 7.392631952000045, 5.806853315000069 ],
							[ 7.392875085000071, 5.803407227000037 ],
							[ 7.393394597000054, 5.799098036000032 ],
							[ 7.393641543000058, 5.795938927000066 ],
							[ 7.393318635000071, 5.793071202000021 ],
							[ 7.393557947000033, 5.789338131000022 ],
							[ 7.394352931000071, 5.784166275000075 ],
							[ 7.394309631000056, 5.780722184000069 ],
							[ 7.393997064000075, 5.778715118000036 ],
							[ 7.394251345000043, 5.776130263000027 ],
							[ 7.393630895000058, 5.772403300000065 ],
							[ 7.394447907000028, 5.768953359000022 ],
							[ 7.394970329000046, 5.764931156000046 ],
							[ 7.394629457000065, 5.760628215000054 ],
							[ 7.394280981000065, 5.75575116300007 ],
							[ 7.394531716000074, 5.752879178000057 ],
							[ 7.394785976000037, 5.750294173000043 ],
							[ 7.395036129000061, 5.747422184000072 ],
							[ 7.395293634000041, 5.745124304000058 ],
							[ 7.396099682000056, 5.740813266000032 ],
							[ 7.396331849000035, 5.736506486000053 ],
							[ 7.397148479000066, 5.733056551000061 ],
							[ 7.397954460000051, 5.728745490000051 ],
							[ 7.399050349000049, 5.724719586000049 ],
							[ 7.40014590100003, 5.720693510000046 ],
							[ 7.400393031000021, 5.717534537000063 ],
							[ 7.400301015000025, 5.712711869000032 ],
							[ 7.399729277000063, 5.710363442000073 ],
							[ 7.396840913000062, 5.70866103000003 ],
							[ 7.394872630000066, 5.708085811000046 ],
							[ 7.394144327000049, 5.705712516000062 ],
							[ 7.392837594000071, 5.703633272000047 ],
							[ 7.391340971000034, 5.701930157000049 ],
							[ 7.388898174000076, 5.701730898000051 ],
							[ 7.385134342000072, 5.702656717000025 ],
							[ 7.381607578000057, 5.705608877000031 ],
							[ 7.382155293000039, 5.703596142000038 ],
							[ 7.382979043000034, 5.700719770000035 ],
							[ 7.384001354000077, 5.698827696000023 ],
							[ 7.385791721000032, 5.696395438000025 ],
							[ 7.387203331000023, 5.694663474000038 ],
							[ 7.389743444000032, 5.69148874900003 ],
							[ 7.39144192200007, 5.689754497000024 ],
							[ 7.393147436000049, 5.688594753000075 ],
							[ 7.395698635000031, 5.686281244000043 ],
							[ 7.398253042000022, 5.684254122000027 ],
							[ 7.400804181000069, 5.681940578000024 ],
							[ 7.40280006100005, 5.681065506000039 ],
							[ 7.40507517900005, 5.679615009000031 ],
							[ 7.40819588100004, 5.677010006000046 ],
							[ 7.410163055000055, 5.673839142000077 ],
							[ 7.410994172000073, 5.671537390000026 ],
							[ 7.412394920000054, 5.668944349000071 ],
							[ 7.413795370000059, 5.666351728000052 ],
							[ 7.415196374000061, 5.663758697000048 ],
							[ 7.41631402400003, 5.661454637000077 ],
							[ 7.416575177000027, 5.659443865000071 ],
							[ 7.417678875000036, 5.655991991000064 ],
							[ 7.417932904000054, 5.653407109000057 ],
							[ 7.417903900000056, 5.651111077000053 ],
							[ 7.417584202000057, 5.648530061000031 ],
							[ 7.418691703000036, 5.645364757000038 ],
							[ 7.419798610000043, 5.642200022000054 ],
							[ 7.420632866000062, 5.640185354000039 ],
							[ 7.421467688000064, 5.638170279000065 ],
							[ 7.422574550000036, 5.635005534000072 ],
							[ 7.425684365000052, 5.63153982800003 ],
							[ 7.426508167000065, 5.628663500000073 ],
							[ 7.427633042000025, 5.626933514000029 ],
							[ 7.428460665000046, 5.624344869000026 ],
							[ 7.42898335700005, 5.620322749000024 ],
							[ 7.429513702000065, 5.616874722000034 ],
							[ 7.432285979000028, 5.609393174000047 ],
							[ 7.430239260000064, 5.606249745000071 ],
							[ 7.429064002000075, 5.603961931000072 ],
							[ 7.428457452000032, 5.601382798000031 ],
							[ 7.427851464000071, 5.598803518000068 ],
							[ 7.427235099000029, 5.595363574000032 ],
							[ 7.426622004000023, 5.592210737000073 ],
							[ 7.426280944000041, 5.587907244000064 ],
							[ 7.425965090000034, 5.585613231000025 ],
							[ 7.422786106000046, 5.583626314000071 ],
							[ 7.422467005000044, 5.581045169000049 ],
							[ 7.419865521000077, 5.579340908000063 ],
							[ 7.417270615000064, 5.57821090300007 ],
							[ 7.415235124000048, 5.575928628000042 ],
							[ 7.414919922000024, 5.573634588000061 ],
							[ 7.41517361700005, 5.571049699000071 ],
							[ 7.416272949000074, 5.567310280000072 ],
							[ 7.417093427000054, 5.56414752400002 ],
							[ 7.417355061000023, 5.562136618000068 ],
							[ 7.415888848000066, 5.55956362400002 ],
							[ 7.414143535000051, 5.557566176000023 ],
							[ 7.411531058000037, 5.555001185000037 ],
							[ 7.409498717000076, 5.553005725000048 ],
							[ 7.408312276000061, 5.549856725000041 ],
							[ 7.407996568000044, 5.547562546000051 ],
							[ 7.408533546000058, 5.544688668000049 ],
							[ 7.408795508000026, 5.542677898000022 ],
							[ 7.407586994000042, 5.537806536000062 ],
							[ 7.407274838000035, 5.535799485000041 ],
							[ 7.406669123000029, 5.533220739000058 ],
							[ 7.404317981000077, 5.528644623000048 ],
							[ 7.402851719000068, 5.52607101600006 ],
							[ 7.401679590000072, 5.524070216000041 ],
							[ 7.400220999000055, 5.522070861000032 ],
							[ 7.398192449000021, 5.520362875000046 ],
							[ 7.39615357100007, 5.517793653000069 ],
							[ 7.393820349000066, 5.51465226800002 ],
							[ 7.392644485000062, 5.512364454000021 ],
							[ 7.390909559000022, 5.511228152000058 ],
							[ 7.388032556000041, 5.510386898000036 ],
							[ 7.38713264200004, 5.507235976000061 ],
							[ 7.385655984000039, 5.503801889000044 ],
							[ 7.384766687000024, 5.501511501000039 ],
							[ 7.383598254000049, 5.499797364000074 ],
							[ 7.382408135000048, 5.496361289000049 ],
							[ 7.381228883000063, 5.49378630700005 ],
							[ 7.379763196000056, 5.491213291000065 ],
							[ 7.379142232000049, 5.487485836000076 ],
							[ 7.378540031000057, 5.485194168000021 ],
							[ 7.377647291000073, 5.482616908000068 ],
							[ 7.376170544000047, 5.479182778000052 ],
							[ 7.374995197000032, 5.476894925000067 ],
							[ 7.373539896000068, 5.475182570000072 ],
							[ 7.370636918000059, 5.472332532000053 ],
							[ 7.368601453000053, 5.470050282000045 ],
							[ 7.366572766000047, 5.468341837000025 ],
							[ 7.364247411000065, 5.465774520000025 ],
							[ 7.363626367000052, 5.462047589000065 ],
							[ 7.362443856000027, 5.459185561000027 ],
							[ 7.361558311000067, 5.45718239200005 ],
							[ 7.361246473000051, 5.45517542500005 ],
							[ 7.360357169000054, 5.452885121000065 ],
							[ 7.358891493000044, 5.450312165000071 ],
							[ 7.357436092000057, 5.448600316000068 ],
							[ 7.355974249000042, 5.446313925000027 ],
							[ 7.353945750000037, 5.444605833000026 ],
							[ 7.351347378000071, 5.443188644000031 ],
							[ 7.349050549000026, 5.442917429000033 ],
							[ 7.34703316100007, 5.442069974000049 ],
							[ 7.343560186000047, 5.439510770000027 ],
							[ 7.341238086000033, 5.437230580000062 ],
							[ 7.339496114000042, 5.435520598000039 ],
							[ 7.338041416000067, 5.433808397000064 ],
							[ 7.336299494000059, 5.432098400000029 ],
							[ 7.335130659000072, 5.430384081000057 ],
							[ 7.333367286000055, 5.426951870000039 ],
							[ 7.331327976000068, 5.424382680000065 ],
							[ 7.32956464800003, 5.420950431000051 ],
							[ 7.32867562000007, 5.418660602000045 ],
							[ 7.327496423000071, 5.416085308000049 ],
							[ 7.326324500000055, 5.41408440400005 ],
							[ 7.323980401000028, 5.410081879000074 ],
							[ 7.322800985000072, 5.407506994000073 ],
							[ 7.321908546000031, 5.404929589000062 ],
							[ 7.321574713000075, 5.40120078700005 ],
							[ 7.322122582000077, 5.399187993000055 ],
							[ 7.322649014000035, 5.395452976000058 ],
							[ 7.322619777000057, 5.393157061000068 ],
							[ 7.322576642000058, 5.389712747000033 ],
							[ 7.322536996000053, 5.386555712000074 ],
							[ 7.322224582000047, 5.384548723000023 ],
							[ 7.321912745000077, 5.382541717000038 ],
							[ 7.320152896000025, 5.379396418000056 ],
							[ 7.31926721800005, 5.377393695000023 ],
							[ 7.318077526000025, 5.373957098000062 ],
							[ 7.316600366000046, 5.370523412000068 ],
							[ 7.31570778300005, 5.367946154000037 ],
							[ 7.314532320000069, 5.365658206000035 ],
							[ 7.313356871000053, 5.363369994000038 ],
							[ 7.311897697000063, 5.361371101000032 ],
							[ 7.308726228000069, 5.35995772800004 ],
							[ 7.305711777000056, 5.358143337000058 ],
							[ 7.304350313000043, 5.35396 ],
							[ 7.303461585000036, 5.351670253000066 ],
							[ 7.303522179000026, 5.348239414000034 ],
							[ 7.301665183000068, 5.345654932000059 ],
							[ 7.301066322000054, 5.343649828000025 ],
							[ 7.299880081000026, 5.340500874000043 ],
							[ 7.298704511000039, 5.338212463000048 ],
							[ 7.297532437000029, 5.336211738000031 ],
							[ 7.296074031000046, 5.334212356000023 ],
							[ 7.294328165000024, 5.332215311000027 ],
							[ 7.293152673000066, 5.329927011000052 ],
							[ 7.292263519000073, 5.327637233000075 ],
							[ 7.29166127600007, 5.325344980000068 ],
							[ 7.29162879200004, 5.322762072000046 ],
							[ 7.291588828000044, 5.319604876000028 ],
							[ 7.292040635000035, 5.315009600000053 ],
							[ 7.289610983000046, 5.312547995000045 ],
							[ 7.28732469800002, 5.307904007000047 ],
							[ 7.287346276000051, 5.303270972000064 ],
							[ 7.288608052000029, 5.298303374000056 ],
							[ 7.287859524000055, 5.296668575000069 ],
							[ 7.286687350000022, 5.294667254000046 ],
							[ 7.284923655000057, 5.291235491000066 ],
							[ 7.283740885000043, 5.288373036000053 ],
							[ 7.282274723000057, 5.28580002800004 ],
							[ 7.28078707800006, 5.28150465300007 ],
							[ 7.279310360000068, 5.278070953000054 ],
							[ 7.278711713000064, 5.276065808000055 ],
							[ 7.277811889000077, 5.27291445700007 ],
							[ 7.276797264000038, 5.260026457000038 ],
							[ 7.27606809200006, 5.25567146800006 ],
							[ 7.273684067000033, 5.252831005000075 ],
							[ 7.271567957000059, 5.249406819000058 ],
							[ 7.269765910000046, 5.246843332000026 ],
							[ 7.267606787000034, 5.242113618000076 ],
							[ 7.266949584000031, 5.239419484000052 ],
							[ 7.266848458000027, 5.236210426000071 ],
							[ 7.266766411000049, 5.23361114100004 ],
							[ 7.266388483000071, 5.230728574000068 ],
							[ 7.266306457000042, 5.228129437000064 ],
							[ 7.26624251800007, 5.226107584000033 ],
							[ 7.265255025000044, 5.222081702000025 ],
							[ 7.264590858000076, 5.219205401000067 ],
							[ 7.263917422000077, 5.215610564000031 ],
							[ 7.260108958000046, 5.213327425000045 ],
							[ 7.256109198000047, 5.213470954000059 ],
							[ 7.252560635000066, 5.204719940000075 ],
							[ 7.251398108000046, 5.201854226000023 ],
							[ 7.242135034000057, 5.195867014000044 ],
							[ 7.231534133000025, 5.194551871000044 ],
							[ 7.229193720000069, 5.194728295000061 ],
							[ 7.226599089000047, 5.194924395000044 ],
							[ 7.224537683000051, 5.195079699000075 ],
							[ 7.217231941000023, 5.199615333000054 ],
							[ 7.213542942000061, 5.199133968000069 ],
							[ 7.210079932000042, 5.196578858000066 ],
							[ 7.206140409000056, 5.197731429000044 ],
							[ 7.200284087000057, 5.199445224000044 ],
							[ 7.198285954000028, 5.199775744000021 ],
							[ 7.196079678000046, 5.200654440000051 ],
							[ 7.19390978000007, 5.201518487000044 ],
							[ 7.190780631000052, 5.202972337000062 ],
							[ 7.18822392200002, 5.204425337000032 ],
							[ 7.185384870000064, 5.206168126000023 ],
							[ 7.182541891000028, 5.207621624000069 ],
							[ 7.179408451000029, 5.208785978000037 ],
							[ 7.174276386000031, 5.210244669000076 ],
							[ 7.17141435000002, 5.210251393000021 ],
							[ 7.168567634000055, 5.211415257000056 ],
							[ 7.166314635000049, 5.212044165000066 ],
							[ 7.165475828000069, 5.215111465000064 ],
							[ 7.16078716100003, 5.229914096000073 ],
							[ 7.157072414000027, 5.234528506000061 ],
							[ 7.153378510000039, 5.234973149000041 ],
							[ 7.148305050000033, 5.234484116000033 ],
							[ 7.138138836000053, 5.237212407000072 ],
							[ 7.131662045000041, 5.240422516000024 ],
							[ 7.124716738000075, 5.245020042000021 ],
							[ 7.121017680000023, 5.246390951000024 ],
							[ 7.117785208000043, 5.246837609000067 ],
							[ 7.115941732000067, 5.246364776000064 ],
							[ 7.112756525000066, 5.237545321000027 ],
							[ 7.107709305000071, 5.23195925400006 ],
							[ 7.102190809000035, 5.228224071000056 ],
							[ 7.096663113000034, 5.226342016000046 ],
							[ 7.093960554000034, 5.225473609000062 ],
							[ 7.092122492000044, 5.226262629000075 ],
							[ 7.089569972000049, 5.227182395000057 ],
							[ 7.087322357000062, 5.228674458000057 ],
							[ 7.083796085000074, 5.228972466000073 ],
							[ 7.081119591000061, 5.230340432000048 ],
							[ 7.076833824000062, 5.232649837000054 ],
							[ 7.074863508000021, 5.233847130000072 ],
							[ 7.071746378000057, 5.235067874000038 ],
							[ 7.070062588000042, 5.236259006000068 ],
							[ 7.066474064000033, 5.237781572000074 ],
							[ 7.06442082600006, 5.239266469000029 ],
							[ 7.062163324000039, 5.240469504000032 ],
							[ 7.059900414000026, 5.24002312500005 ],
							[ 7.057805054000028, 5.242176524000058 ],
							[ 7.055966359000024, 5.244066756000052 ],
							[ 7.053144809000059, 5.245570665000059 ],
							[ 7.049116151000021, 5.246992648000059 ],
							[ 7.047133893000023, 5.247860920000051 ],
							[ 7.045132174000059, 5.248737837000021 ],
							[ 7.042693641000028, 5.249077870000065 ],
							[ 7.039992260000076, 5.249618104000035 ],
							[ 7.035688006000044, 5.248759733000043 ],
							[ 7.032237935000069, 5.247610615000042 ],
							[ 7.028792517000056, 5.246750312000074 ],
							[ 7.023335405000068, 5.245316013000036 ],
							[ 7.021244426000067, 5.244569354000021 ],
							[ 7.019309931000066, 5.243878795000057 ],
							[ 7.014990380000029, 5.241863307000074 ],
							[ 7.012022862000038, 5.242380124000022 ],
							[ 7.008833507000077, 5.241290562000074 ],
							[ 7.006873609000024, 5.240621134000037 ],
							[ 7.003732795000076, 5.239980703000072 ],
							[ 7.001007560000062, 5.239575784000067 ],
							[ 6.99844039900006, 5.239194343000065 ],
							[ 6.993147528000065, 5.237004831000036 ],
							[ 6.990743595000026, 5.236010567000051 ],
							[ 6.986503087000074, 5.234255716000064 ],
							[ 6.982931196000038, 5.234736885000075 ],
							[ 6.980701466000028, 5.235037247000037 ],
							[ 6.978500750000023, 5.235333941000022 ],
							[ 6.975482910000039, 5.235740247000024 ],
							[ 6.971787899000049, 5.236238014000037 ],
							[ 6.967688246000023, 5.234678875000043 ],
							[ 6.965338732000021, 5.233785626000042 ],
							[ 6.96314342900007, 5.232950745000039 ],
							[ 6.959775788000059, 5.23166985000006 ],
							[ 6.956932031000065, 5.23113961200005 ],
							[ 6.954387718000021, 5.230665146000035 ],
							[ 6.951506570000049, 5.230127878000076 ],
							[ 6.947785029000045, 5.229433957000026 ],
							[ 6.944733546000066, 5.22885850800003 ],
							[ 6.941577779000056, 5.228263385000048 ],
							[ 6.938544869000054, 5.227691655000058 ],
							[ 6.934500353000033, 5.226929230000053 ],
							[ 6.931180977000054, 5.226303127000051 ],
							[ 6.928904953000028, 5.226479547000054 ],
							[ 6.926602856000045, 5.226238283000043 ],
							[ 6.925146091000045, 5.225679358000036 ],
							[ 6.89736664000003, 5.235745092000059 ],
							[ 6.883736118000058, 5.236426321000067 ],
							[ 6.87653203800005, 5.236809931000039 ],
							[ 6.875578970000049, 5.237932210000054 ],
							[ 6.866418848000023, 5.243133085000068 ],
							[ 6.848985212000059, 5.249797784000066 ],
							[ 6.83609494600006, 5.249899879000054 ],
							[ 6.833809237000025, 5.250541248000047 ],
							[ 6.830335692000062, 5.251515906000066 ],
							[ 6.827092750000077, 5.252425641000059 ],
							[ 6.82428223200003, 5.254179503000046 ],
							[ 6.818213014000037, 5.257966936000059 ],
							[ 6.811363326000048, 5.264082010000038 ],
							[ 6.803584090000072, 5.26927177500005 ],
							[ 6.794449860000043, 5.27726984100002 ],
							[ 6.786232124000037, 5.284793805000049 ],
							[ 6.777565837000054, 5.293721206000043 ],
							[ 6.770724653000059, 5.300767841000038 ],
							[ 6.765624107000065, 5.308510297000055 ],
							[ 6.763694831000066, 5.312908686000071 ],
							[ 6.755795060000025, 5.323452896000049 ],
							[ 6.757574211000076, 5.342773910000062 ],
							[ 6.760469999000065, 5.353443168000069 ],
							[ 6.759709970000074, 5.36695379400004 ],
							[ 6.758449063000057, 5.377213041000061 ],
							[ 6.758138119000023, 5.389788198000076 ],
							[ 6.760583954000026, 5.401392886000053 ],
							[ 6.762536054000066, 5.410212944000023 ],
							[ 6.762082025000041, 5.411522816000058 ],
							[ 6.770588480000072, 5.418930542000055 ],
							[ 6.777372623000076, 5.424292478000041 ],
							[ 6.777363432000072, 5.425812502000042 ],
							[ 6.777345051000054, 5.428852678000055 ],
							[ 6.772782394000046, 5.43186517700002 ],
							[ 6.762926784000058, 5.433324995000021 ],
							[ 6.750439862000064, 5.43705482300004 ],
							[ 6.749034900000026, 5.438793170000054 ],
							[ 6.74408184300006, 5.449101904000031 ],
							[ 6.743738217000043, 5.458882794000033 ],
							[ 6.751193907000072, 5.464835186000073 ],
							[ 6.749992576000068, 5.476081273000034 ],
							[ 6.743181110000023, 5.474344936000023 ],
							[ 6.741005161000032, 5.474331479000057 ],
							[ 6.739914900000031, 5.474744528000031 ],
							[ 6.739491730000054, 5.475497567000048 ],
							[ 6.739487599000029, 5.476169267000046 ],
							[ 6.739651800000047, 5.476674161000062 ],
							[ 6.739900444000057, 5.477095496000061 ],
							[ 6.740899856000055, 5.47785734100006 ],
							[ 6.741900046000069, 5.478535254000064 ],
							[ 6.742231036000021, 5.47912509300005 ],
							[ 6.742311076000021, 5.479713381000067 ],
							[ 6.741714413000068, 5.481472956000061 ],
							[ 6.739922656000033, 5.487087621000057 ],
							[ 6.73923665500007, 5.489770276000058 ],
							[ 6.738135717000034, 5.491862714000035 ],
							[ 6.736563781000029, 5.493826331000037 ],
							[ 6.733294901000022, 5.493944102000057 ],
							[ 6.729844060000062, 5.493106812000065 ],
							[ 6.726963130000058, 5.491978569000025 ],
							[ 6.722477793000053, 5.491321990000074 ],
							[ 6.719904348000057, 5.490441333000035 ],
							[ 6.715590567000049, 5.489103828000054 ],
							[ 6.712041921000036, 5.489194933000022 ],
							[ 6.652729391000037, 5.490715923000039 ],
							[ 6.64544999900005, 5.492535608000026 ],
							[ 6.639079972000047, 5.493445818000055 ],
							[ 6.658436437000034, 5.624850225000046 ],
							[ 6.668868104000069, 5.689925195000058 ],
							[ 6.669886308000059, 5.696277190000046 ],
							[ 6.695837938000068, 5.708676653000055 ],
							[ 6.742266052000048, 5.734919547000061 ],
							[ 6.793809097000064, 5.772606795000058 ],
							[ 6.802055334000045, 5.778885245000026 ],
							[ 6.809638469000049, 5.780314477000047 ],
							[ 6.814211457000056, 5.777803482000024 ],
							[ 6.819149725000045, 5.774247751000075 ],
							[ 6.828137737000077, 5.766234428000075 ],
							[ 6.836272563000023, 5.755614837000053 ],
							[ 6.83610552600004, 5.754879957000071 ],
							[ 6.836696521000022, 5.755081416000053 ],
							[ 6.84295979500007, 5.754222062000053 ],
							[ 6.846519917000023, 5.756932064000068 ],
							[ 6.849901296000041, 5.763119541000037 ],
							[ 6.85106984500004, 5.762076316000048 ],
							[ 6.853617062000069, 5.759475755000039 ],
							[ 6.855881558000021, 5.757163466000065 ],
							[ 6.858712259000072, 5.754273881000074 ],
							[ 6.860123502000022, 5.752541959000041 ],
							[ 6.861538970000026, 5.751097154000036 ],
							[ 6.863240810000036, 5.74965048100006 ],
							[ 6.865512266000053, 5.747912401000065 ],
							[ 6.868074320000062, 5.746460006000063 ],
							[ 6.870070450000071, 5.745585006000056 ],
							[ 6.872349237000037, 5.744421008000074 ],
							[ 6.874352422000072, 5.744120125000052 ],
							[ 6.87672016700003, 5.744140966000032 ],
							[ 6.88355746700006, 5.744180636000067 ],
							[ 6.884802207000064, 5.745443107000028 ],
							[ 6.884791364000023, 5.747325817000046 ],
							[ 6.884149104000073, 5.750146461000043 ],
							[ 6.883200226000042, 5.752176898000073 ],
							[ 6.883652781000023, 5.754102277000072 ],
							[ 6.88369950200007, 5.757833035000033 ],
							[ 6.883739368000022, 5.760990098000036 ],
							[ 6.884077037000054, 5.765006117000041 ],
							[ 6.884399878000067, 5.76787416600007 ],
							[ 6.884740691000047, 5.772177185000032 ],
							[ 6.884486759000026, 5.774762133000024 ],
							[ 6.885110852000025, 5.778776139000058 ],
							[ 6.885437140000022, 5.781931468000039 ],
							[ 6.885502387000031, 5.787097473000074 ],
							[ 6.886427454000057, 5.792257303000042 ],
							[ 6.888198361000036, 5.796263654000029 ],
							[ 6.888818824000055, 5.799990664000063 ],
							[ 6.889439004000053, 5.803717671000072 ],
							[ 6.889758212000061, 5.806298724000044 ],
							[ 6.890100382000071, 5.806800589000034 ],
							[ 6.886498832000029, 5.821928508000042 ],
							[ 6.885637033000023, 5.824470784000027 ],
							[ 6.885619920000067, 5.827442699000073 ],
							[ 6.886873399000024, 5.830421816000069 ],
							[ 6.888555479000047, 5.832554359000028 ],
							[ 6.891106408000041, 5.830870872000048 ],
							[ 6.893246056000066, 5.827062155000021 ],
							[ 6.897500911000066, 5.823690187000068 ],
							[ 6.903023802000064, 5.820749923000051 ],
							[ 6.907081442000049, 5.819555076000029 ],
							[ 6.908863059000055, 5.820805569000072 ],
							[ 6.911744570000053, 5.821933757000068 ],
							[ 6.915580713000054, 5.823798144000023 ],
							[ 6.91587977000006, 5.824304127000062 ],
							[ 6.921930196000062, 5.843297310000025 ],
							[ 6.930217902000038, 5.855689451000046 ],
							[ 6.932834734000039, 5.859672396000065 ],
							[ 6.94119487200004, 5.859278738000057 ],
							[ 6.946461216000046, 5.861512856000047 ],
							[ 6.952597411000056, 5.865515415000061 ],
							[ 6.96830869300004, 5.888088911000068 ],
							[ 6.970478995000065, 5.893391763000068 ],
							[ 6.97766621300002, 5.901716137000051 ],
							[ 6.986794996000071, 5.900771682000027 ],
							[ 6.993331375000025, 5.898157070000025 ],
							[ 6.994971712000051, 5.896848221000027 ],
							[ 6.996720733000075, 5.899132801000064 ],
							[ 6.998175691000029, 5.900844631000041 ],
							[ 6.999913641000035, 5.902267946000052 ],
							[ 7.00311197700006, 5.905193281000038 ],
							[ 7.009156345000065, 5.907657658000062 ],
							[ 7.014613578000024, 5.908481134000056 ],
							[ 7.017770897000048, 5.908746231000066 ],
							[ 7.020931180000048, 5.909298873000068 ],
							[ 7.025525684000058, 5.909841125000071 ],
							[ 7.031001218000029, 5.912099312000066 ],
							[ 7.03389684800004, 5.914375755000037 ],
							[ 7.035634999000024, 5.915799177000054 ],
							[ 7.037663504000022, 5.917507150000063 ],
							[ 7.039412612000035, 5.919791246000045 ],
							[ 7.041157619000046, 5.921788198000058 ],
							[ 7.043472694000059, 5.923494859000073 ],
							[ 7.045501277000028, 5.925202789000025 ],
							[ 7.047820229000024, 5.927195999000048 ],
							[ 7.053009060000022, 5.929456692000031 ],
							[ 7.056444716000044, 5.930613533000042 ],
							[ 7.060201193000069, 5.931415964000053 ],
							[ 7.062781295000036, 5.93139842100004 ],
							[ 7.065357550000044, 5.931093728000064 ],
							[ 7.068220599000028, 5.930787143000032 ],
							[ 7.070797403000029, 5.930481841000073 ],
							[ 7.07308712300005, 5.930179118000069 ],
							[ 7.07595729600007, 5.930446586000073 ],
							[ 7.077960492000045, 5.930145714000048 ],
							[ 7.080533143000025, 5.929553933000022 ],
							[ 7.083106064000049, 5.928962263000074 ],
							[ 7.085678994000034, 5.928370446000031 ],
							[ 7.088828508000063, 5.928061514000035 ],
							[ 7.091404994000072, 5.927756787000021 ],
							[ 7.093985067000062, 5.92773915500004 ],
							[ 7.095514696000066, 5.927449570000022 ],
							[ 7.097553441000059, 5.928826926000056 ],
							[ 7.101219094000044, 5.932538859000033 ],
							[ 7.103229117000069, 5.932549293000022 ],
							[ 7.106586113000048, 5.931223830000022 ],
							[ 7.10894152000003, 5.929221721000033 ],
							[ 7.112095743000054, 5.924706149000031 ],
							[ 7.116570523000064, 5.922704208000027 ],
							[ 7.118852953000044, 5.921827346000043 ],
							[ 7.121142857000052, 5.921524472000044 ],
							[ 7.124572149000073, 5.92064005900005 ],
							[ 7.12657495600007, 5.920339048000073 ],
							[ 7.128574730000025, 5.91975119600005 ],
							[ 7.130581425000059, 5.919737296000051 ],
							[ 7.132874927000046, 5.919721608000032 ],
							[ 7.135741730000063, 5.919702052000048 ],
							[ 7.138314215000037, 5.919109863000074 ],
							[ 7.142893674000049, 5.918504245000065 ],
							[ 7.146323163000034, 5.917619628000068 ],
							[ 7.14917906200003, 5.916739003000032 ],
							[ 7.152031032000025, 5.915571224000075 ],
							[ 7.154596755000057, 5.91440536600004 ],
							[ 7.156592257000057, 5.91353035700007 ],
							[ 7.159161590000053, 5.912652024000067 ],
							[ 7.162304479000056, 5.911769352000022 ],
							[ 7.164876866000043, 5.911177106000025 ],
							[ 7.168295301000057, 5.909431467000047 ],
							[ 7.170574146000035, 5.908268006000071 ],
							[ 7.172570131000043, 5.907392931000061 ],
							[ 7.17342977800007, 5.906905658000028 ],
							[ 7.182052270000042, 5.913142848000064 ],
							[ 7.188068514000065, 5.915858451000076 ],
							[ 7.199427520000029, 5.914527191000047 ],
							[ 7.200787189000039, 5.916957378000063 ],
							[ 7.201786613000024, 5.918901979000054 ],
							[ 7.202844695000067, 5.920961891000047 ],
							[ 7.206038139000043, 5.924097077000056 ],
							[ 7.207779538000068, 5.925806933000047 ],
							[ 7.209517582000046, 5.927230376000068 ],
							[ 7.212119695000069, 5.928934536000042 ],
							[ 7.215004405000059, 5.930349854000042 ],
							[ 7.21701825100007, 5.930910162000032 ],
							[ 7.220468934000053, 5.931747474000076 ],
							[ 7.223056669000073, 5.932303912000066 ],
							[ 7.226500008000073, 5.932566612000073 ],
							[ 7.228797557000064, 5.932838027000059 ],
							[ 7.232240626000021, 5.933101098000066 ],
							[ 7.235960533000025, 5.932501768000066 ],
							[ 7.237959899000032, 5.931913872000052 ],
							[ 7.24053628300004, 5.931609224000056 ],
							[ 7.24511943300007, 5.93129058300002 ],
							[ 7.251315195000075, 5.929930499000022 ],
							[ 7.257011851000073, 5.929621998000073 ],
							[ 7.258012096000073, 5.930633905000036 ],
							[ 7.258751946000075, 5.936006330000055 ],
							[ 7.259231650000061, 5.936359878000076 ],
							[ 7.260983948000046, 5.938931012000069 ],
							[ 7.262671603000058, 5.936335961000054 ],
							[ 7.26321946500002, 5.93432328800003 ],
							[ 7.264627458000064, 5.932304381000051 ],
							[ 7.265750939000043, 5.930810153000039 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Jigawa',
				admin1Pcod: 'NG018',
				admin1RefN: 'Jigawa',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 11.771497,
				Shape_Area: 1.928873
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 10.230024591000074, 13.010598929000025 ],
							[ 10.230835379000041, 13.010596254000063 ],
							[ 10.234892961000071, 13.011644846000024 ],
							[ 10.240038029000061, 13.014548288000071 ],
							[ 10.245713605000049, 13.014529371000037 ],
							[ 10.251654829000074, 13.013181970000062 ],
							[ 10.254797588000031, 13.012141113000041 ],
							[ 10.255150048000075, 13.011139603000061 ],
							[ 10.255466847000037, 13.009947575000069 ],
							[ 10.255154358000027, 13.00918768400004 ],
							[ 10.254337096000029, 13.007331923000038 ],
							[ 10.25352169100006, 13.006007157000056 ],
							[ 10.253785476000076, 13.004147759000034 ],
							[ 10.255130283000028, 13.00228474100004 ],
							[ 10.257599524000057, 13.000869218000048 ],
							[ 10.257600032000028, 13.000809774000061 ],
							[ 10.262389886000051, 12.99050984400003 ],
							[ 10.263600363000023, 12.987070305000032 ],
							[ 10.265979921000053, 12.983639770000025 ],
							[ 10.27429945800003, 12.972229989000027 ],
							[ 10.27668968200004, 12.967650254000034 ],
							[ 10.277879889000076, 12.965359823000028 ],
							[ 10.279100420000077, 12.960769397000035 ],
							[ 10.280320357000051, 12.956179504000033 ],
							[ 10.280360410000071, 12.952730367000072 ],
							[ 10.281550547000052, 12.95044047600004 ],
							[ 10.281579717000056, 12.948139913000034 ],
							[ 10.281600246000039, 12.945830326000078 ],
							[ 10.281649412000036, 12.941229766000049 ],
							[ 10.281689412000048, 12.937769888000048 ],
							[ 10.281729971000061, 12.933170483000026 ],
							[ 10.279470428000025, 12.926230490000023 ],
							[ 10.276009373000022, 12.920430139000075 ],
							[ 10.274889721000022, 12.915820017000044 ],
							[ 10.273779823000041, 12.910050581000064 ],
							[ 10.272788798000022, 12.90488917600004 ],
							[ 10.271550363000074, 12.899660264000033 ],
							[ 10.271590457000059, 12.896200358000044 ],
							[ 10.271606578000046, 12.894295272000022 ],
							[ 10.273425446000033, 12.884399213000052 ],
							[ 10.276713104000066, 12.875252322000051 ],
							[ 10.276028283000073, 12.869381747000034 ],
							[ 10.274029427000073, 12.867430938000041 ],
							[ 10.259405937000054, 12.862912796000046 ],
							[ 10.253423405000035, 12.860975315000076 ],
							[ 10.248709167000072, 12.857031431000053 ],
							[ 10.247440238000024, 12.854459663000057 ],
							[ 10.247469671000033, 12.851010520000045 ],
							[ 10.249860011000067, 12.846429784000065 ],
							[ 10.252229530000022, 12.844149581000067 ],
							[ 10.255740123000066, 12.844199916000036 ],
							[ 10.259260446000042, 12.843089773000031 ],
							[ 10.261609838000027, 12.843119686000023 ],
							[ 10.268649844000038, 12.840900366000028 ],
							[ 10.272170128000027, 12.840940350000039 ],
							[ 10.274499995000042, 12.842119925000077 ],
							[ 10.278010537000057, 12.842160442000022 ],
							[ 10.281510267000044, 12.843359693000025 ],
							[ 10.287349599000038, 12.844579643000031 ],
							[ 10.289679543000034, 12.845760252000048 ],
							[ 10.292012195000041, 12.846247639000069 ],
							[ 10.295520090000025, 12.84698006900004 ],
							[ 10.299039873000027, 12.847020357000076 ],
							[ 10.306039542000065, 12.849409951000041 ],
							[ 10.31886985400007, 12.854169705000061 ],
							[ 10.32236980600004, 12.855360092000069 ],
							[ 10.32703964500007, 12.856569998000055 ],
							[ 10.330539664000071, 12.857769917000041 ],
							[ 10.334460375000049, 12.858780155000034 ],
							[ 10.341049897000062, 12.860199973000022 ],
							[ 10.349230017000025, 12.861450067000021 ],
							[ 10.355090259000065, 12.861519759000032 ],
							[ 10.359769699000026, 12.861570511000025 ],
							[ 10.364460100000031, 12.861630216000037 ],
							[ 10.366800413000021, 12.861660055000073 ],
							[ 10.376199627000062, 12.858320488000061 ],
							[ 10.379719720000026, 12.857210337000026 ],
							[ 10.382080303000066, 12.856089755000028 ],
							[ 10.391480379000029, 12.852749816000028 ],
							[ 10.393839709000076, 12.85161955500007 ],
							[ 10.398549931000048, 12.848230262000072 ],
							[ 10.403269775000069, 12.844829580000066 ],
							[ 10.405630227000074, 12.84370999600003 ],
							[ 10.408201354000028, 12.840026942000065 ],
							[ 10.410288001000026, 12.840339391000043 ],
							[ 10.412340442000072, 12.841750209000054 ],
							[ 10.415839973000061, 12.84117989300006 ],
							[ 10.419540534000021, 12.84173981400005 ],
							[ 10.422840191000034, 12.840049876000023 ],
							[ 10.427499481000041, 12.839289712000038 ],
							[ 10.432169717000022, 12.838540183000077 ],
							[ 10.435660559000041, 12.837969714000053 ],
							[ 10.442460126000071, 12.835709619000056 ],
							[ 10.44556999100007, 12.832889750000049 ],
							[ 10.451970049000067, 12.828379691000066 ],
							[ 10.455079818000058, 12.825569905000066 ],
							[ 10.458379702000059, 12.823869483000067 ],
							[ 10.460648246000062, 12.821568571000057 ],
							[ 10.464400357000045, 12.822540247000063 ],
							[ 10.470250238000062, 12.822609871000054 ],
							[ 10.474940139000068, 12.822669871000073 ],
							[ 10.478460168000026, 12.821559912000055 ],
							[ 10.481969974000037, 12.821600214000057 ],
							[ 10.48549009900006, 12.821639868000034 ],
							[ 10.490169669000068, 12.821700272000044 ],
							[ 10.49600993200005, 12.822919785000067 ],
							[ 10.501850255000022, 12.824139761000026 ],
							[ 10.504180191000046, 12.825320163000072 ],
							[ 10.506509575000052, 12.826499996000052 ],
							[ 10.508919463000041, 12.82494001200007 ],
							[ 10.51352036000003, 12.827739919000066 ],
							[ 10.515870247000066, 12.82777048500003 ],
							[ 10.517399414000067, 12.828004018000058 ],
							[ 10.521515726000075, 12.824739703000034 ],
							[ 10.524154740000029, 12.820813603000033 ],
							[ 10.530790851000063, 12.820133859000066 ],
							[ 10.536774195000021, 12.822066895000034 ],
							[ 10.541427030000023, 12.823352774000057 ],
							[ 10.551379802000042, 12.822006385000066 ],
							[ 10.561335349000046, 12.821312265000074 ],
							[ 10.56190024600005, 12.821267171000045 ],
							[ 10.566310056000077, 12.819170044000032 ],
							[ 10.569840278000072, 12.816910068000027 ],
							[ 10.574580103000073, 12.812359674000049 ],
							[ 10.577699950000067, 12.806850577000034 ],
							[ 10.570880112000054, 12.802169910000032 ],
							[ 10.572819907000053, 12.799539762000052 ],
							[ 10.577120061000073, 12.792820052000025 ],
							[ 10.576677076000067, 12.788257621000071 ],
							[ 10.576868319000027, 12.786256864000052 ],
							[ 10.577230407000059, 12.782449781000025 ],
							[ 10.57587987200003, 12.775899716000026 ],
							[ 10.575289804000022, 12.772519798000076 ],
							[ 10.57333019400005, 12.768210668000052 ],
							[ 10.573909972000024, 12.764639939000062 ],
							[ 10.574279696000076, 12.759949769000059 ],
							[ 10.574460028000033, 12.754140108000058 ],
							[ 10.576399539000022, 12.751509932000033 ],
							[ 10.578140415000064, 12.747750096000061 ],
							[ 10.580079906000037, 12.745129507000058 ],
							[ 10.582019916000036, 12.742500428000028 ],
							[ 10.583959308000033, 12.739870191000023 ],
							[ 10.585700047000046, 12.736109761000023 ],
							[ 10.586270510000077, 12.732549781000046 ],
							[ 10.588209822000067, 12.729920668000034 ],
							[ 10.590689530000077, 12.727350256000022 ],
							[ 10.590720156000032, 12.725050171000021 ],
							[ 10.593830127000047, 12.720909839000058 ],
							[ 10.595569524000041, 12.717149918000075 ],
							[ 10.596543328000052, 12.71505200200005 ],
							[ 10.596170525000048, 12.708001202000048 ],
							[ 10.593288417000053, 12.704490449000048 ],
							[ 10.592534418000071, 12.696038249000026 ],
							[ 10.593936433000067, 12.688986085000067 ],
							[ 10.595688075000055, 12.684331624000038 ],
							[ 10.596061245000044, 12.683340021000049 ],
							[ 10.596434029000022, 12.680613633000064 ],
							[ 10.595999922000033, 12.677730677000056 ],
							[ 10.595492601000046, 12.674362278000046 ],
							[ 10.59480951200004, 12.669830236000053 ],
							[ 10.596040020000032, 12.664090022000039 ],
							[ 10.59408013500007, 12.660679816000027 ],
							[ 10.595099703000074, 12.657253467000032 ],
							[ 10.596150346000059, 12.653719643000045 ],
							[ 10.597389986000053, 12.647979951000025 ],
							[ 10.598620377000032, 12.642239720000077 ],
							[ 10.598649892000026, 12.638789907000046 ],
							[ 10.597281402000021, 12.634244989000024 ],
							[ 10.596646431000067, 12.632135312000059 ],
							[ 10.595749699000066, 12.629160097000067 ],
							[ 10.596130483000024, 12.624469828000031 ],
							[ 10.59566423800004, 12.61881143100004 ],
							[ 10.597080237000057, 12.616209907000041 ],
							[ 10.597660148000045, 12.61264983500007 ],
							[ 10.598230254000043, 12.609080195000047 ],
							[ 10.597250005000035, 12.603449991000048 ],
							[ 10.597820143000035, 12.599889957000073 ],
							[ 10.598399977000042, 12.596320271000025 ],
							[ 10.598770358000024, 12.591630032000069 ],
							[ 10.598959980000075, 12.585820225000077 ],
							[ 10.599329759000057, 12.581129966000049 ],
							[ 10.598549815000069, 12.576620365000053 ],
							[ 10.595430431000068, 12.572500432000027 ],
							[ 10.595999907000021, 12.568929658000059 ],
							[ 10.595210327000075, 12.564430251000033 ],
							[ 10.593459922000022, 12.561249644000043 ],
							[ 10.591041517000065, 12.558058868000046 ],
							[ 10.588579988000049, 12.553930416000071 ],
							[ 10.588959618000047, 12.549240132000023 ],
							[ 10.590534448000028, 12.545846996000023 ],
							[ 10.594969564000053, 12.542480583000042 ],
							[ 10.594180050000034, 12.537980035000032 ],
							[ 10.592419409000058, 12.534789834000037 ],
							[ 10.590470068000059, 12.530480104000048 ],
							[ 10.589679471000068, 12.525980122000021 ],
							[ 10.590250103000074, 12.522410468000032 ],
							[ 10.594050542000048, 12.520130385000073 ],
							[ 10.592900348000057, 12.51782061800003 ],
							[ 10.590599840000039, 12.514340635000053 ],
							[ 10.58829014500003, 12.510849939000025 ],
							[ 10.584819892000041, 12.50736022600006 ],
							[ 10.581330562000062, 12.505009533000077 ],
							[ 10.577839614000027, 12.502670106000039 ],
							[ 10.573179985000024, 12.500310167000066 ],
							[ 10.569680306000066, 12.499110247000033 ],
							[ 10.565019672000062, 12.496760374000075 ],
							[ 10.561519488000044, 12.495559823000065 ],
							[ 10.552180190000058, 12.493149720000076 ],
							[ 10.546349285000076, 12.490770611000073 ],
							[ 10.544010209000021, 12.49073962600005 ],
							[ 10.535859860000073, 12.486040016000061 ],
							[ 10.526539915000058, 12.481320160000053 ],
							[ 10.521909649000065, 12.476660019000064 ],
							[ 10.517259657000068, 12.473150203000046 ],
							[ 10.511469970000064, 12.467320652000069 ],
							[ 10.509149550000075, 12.464989951000064 ],
							[ 10.505700137000076, 12.459190357000068 ],
							[ 10.504550118000054, 12.456879754000056 ],
							[ 10.496470724000062, 12.446419649000063 ],
							[ 10.493000040000027, 12.441769750000049 ],
							[ 10.491850120000038, 12.439450052000041 ],
							[ 10.484870226000055, 12.435910165000053 ],
							[ 10.476710534000063, 12.432359555000062 ],
							[ 10.472039946000052, 12.431150381000066 ],
							[ 10.468540190000056, 12.429960245000075 ],
							[ 10.466190607000044, 12.429929962000074 ],
							[ 10.463859327000023, 12.42874985900005 ],
							[ 10.461540106000029, 12.427570269000057 ],
							[ 10.458029507000049, 12.426380078000022 ],
							[ 10.451029565000056, 12.423990478000064 ],
							[ 10.44635001100005, 12.42393015500005 ],
							[ 10.444000528000061, 12.425060184000074 ],
							[ 10.439289962000032, 12.427300469000045 ],
							[ 10.435730025000055, 12.431870712000034 ],
							[ 10.434530475000031, 12.43414991800006 ],
							[ 10.433309696000038, 12.438739921000035 ],
							[ 10.433289586000058, 12.441049606000036 ],
							[ 10.43327060300004, 12.443349678000061 ],
							[ 10.432069895000041, 12.44563961700004 ],
							[ 10.432049757000073, 12.447939692000034 ],
							[ 10.429620063000073, 12.455969928000059 ],
							[ 10.429570009000031, 12.460579720000055 ],
							[ 10.42838011300006, 12.462859994000041 ],
							[ 10.428339842000071, 12.466320018000033 ],
							[ 10.424779923000074, 12.470879421000063 ],
							[ 10.421270120000031, 12.470840296000063 ],
							[ 10.416569777000063, 12.471929387000046 ],
							[ 10.413059966000048, 12.471890184000074 ],
							[ 10.410719863000054, 12.471860069000059 ],
							[ 10.406029973000045, 12.471810570000059 ],
							[ 10.401349809000067, 12.471750257000053 ],
							[ 10.399009725000042, 12.471719513000039 ],
							[ 10.394330129000025, 12.471659664000072 ],
							[ 10.389639680000073, 12.471609960000023 ],
							[ 10.387299600000063, 12.471579717000054 ],
							[ 10.379010202000075, 12.473261902000047 ],
							[ 10.375550519000058, 12.472821041000032 ],
							[ 10.371110742000042, 12.472255500000074 ],
							[ 10.360380106000036, 12.470100503000026 ],
							[ 10.356880326000066, 12.468910469000036 ],
							[ 10.352210250000041, 12.467699773000049 ],
							[ 10.348699321000026, 12.467659968000021 ],
							[ 10.344019807000052, 12.467600042000072 ],
							[ 10.340520105000053, 12.466409836000025 ],
							[ 10.337584372000038, 12.465649792000022 ],
							[ 10.335849540000027, 12.465200639000045 ],
							[ 10.331169820000071, 12.463990288000048 ],
							[ 10.327670214000022, 12.46279996100003 ],
							[ 10.324170049000031, 12.461609602000067 ],
							[ 10.318329872000049, 12.460379481000075 ],
							[ 10.313659495000024, 12.45917961400005 ],
							[ 10.310159942000041, 12.457980088000056 ],
							[ 10.305499776000033, 12.455619616000035 ],
							[ 10.301989554000045, 12.455579943000032 ],
							[ 10.298489543000073, 12.45438934200007 ],
							[ 10.296150172000068, 12.454360209000072 ],
							[ 10.287989588000073, 12.450799830000051 ],
							[ 10.285659945000077, 12.449629921000053 ],
							[ 10.283340509000027, 12.447290461000023 ],
							[ 10.281009718000064, 12.446109790000037 ],
							[ 10.277520152000022, 12.443770374000053 ],
							[ 10.274049486000024, 12.440270414000054 ],
							[ 10.26825051700007, 12.434450111000046 ],
							[ 10.265930191000052, 12.433270438000022 ],
							[ 10.264789786000051, 12.429800298000032 ],
							[ 10.261329724000063, 12.425149894000072 ],
							[ 10.258995069000036, 12.422800742000049 ],
							[ 10.25669961400007, 12.419340125000076 ],
							[ 10.25557025300003, 12.415869935000046 ],
							[ 10.25325988700007, 12.412389575000077 ],
							[ 10.250969662000045, 12.407750384000053 ],
							[ 10.248680093000075, 12.403120210000054 ],
							[ 10.247558535000053, 12.40302566500003 ],
							[ 10.242043441000021, 12.393154841000069 ],
							[ 10.233765923000021, 12.376999845000057 ],
							[ 10.22368388600006, 12.367143823000049 ],
							[ 10.208103433000076, 12.351911033000022 ],
							[ 10.196189766000032, 12.340262461000066 ],
							[ 10.195240764000062, 12.329476743000043 ],
							[ 10.195654162000039, 12.327196580000077 ],
							[ 10.200622449000036, 12.299791412000047 ],
							[ 10.193169149000028, 12.287653705000025 ],
							[ 10.192357829000059, 12.286332558000026 ],
							[ 10.173097165000058, 12.261220646000027 ],
							[ 10.168490571000063, 12.248648551000031 ],
							[ 10.152756674000045, 12.183067334000043 ],
							[ 10.145344250000051, 12.148926362000054 ],
							[ 10.147098312000026, 12.126444800000058 ],
							[ 10.151614198000061, 12.111146920000067 ],
							[ 10.161562649000075, 12.082346400000063 ],
							[ 10.16427199900005, 12.073347322000075 ],
							[ 10.164225589000068, 12.058962939000025 ],
							[ 10.16383750600005, 12.058499170000061 ],
							[ 10.15597936000006, 12.049099138000031 ],
							[ 10.15459303800003, 12.043708951000042 ],
							[ 10.154469998000025, 12.041101912000045 ],
							[ 10.154140382000037, 12.040439653000021 ],
							[ 10.150639711000053, 12.039239737000059 ],
							[ 10.14713966000005, 12.038049951000062 ],
							[ 10.14363958000007, 12.036849967000023 ],
							[ 10.141300085000069, 12.036830033000058 ],
							[ 10.138940094000077, 12.037949725000033 ],
							[ 10.13424972100006, 12.039039787000036 ],
							[ 10.130740134000064, 12.038999637000074 ],
							[ 10.128410193000036, 12.037819667000065 ],
							[ 10.126079685000036, 12.036640258000034 ],
							[ 10.122630216000061, 12.03083980200006 ],
							[ 10.121480001000066, 12.028530215000046 ],
							[ 10.115619566000021, 12.028459700000042 ],
							[ 10.112110056000063, 12.028409766000038 ],
							[ 10.10977005500007, 12.028390209000065 ],
							[ 10.106249733000027, 12.029490550000048 ],
							[ 10.102729907000025, 12.029450166000061 ],
							[ 10.09922954700005, 12.028260486000022 ],
							[ 10.095769725000025, 12.02361012800003 ],
							[ 10.095830224000053, 12.017849899000055 ],
							[ 10.095870223000077, 12.014399400000059 ],
							[ 10.097060146000047, 12.012109953000049 ],
							[ 10.094740192000074, 12.009779996000077 ],
							[ 10.091240028000072, 12.008589683000025 ],
							[ 10.087739858000077, 12.007389746000058 ],
							[ 10.085410263000028, 12.006210078000038 ],
							[ 10.081910166000057, 12.005020251000076 ],
							[ 10.07841011000005, 12.003829816000064 ],
							[ 10.071430115000055, 12.000289991000045 ],
							[ 10.067919777000043, 11.999090453000065 ],
							[ 10.065589713000065, 11.997909532000051 ],
							[ 10.063249332000055, 11.997890257000051 ],
							[ 10.058589836000067, 11.995530025000051 ],
							[ 10.053920024000035, 11.994320102000074 ],
							[ 10.05041955400003, 11.993120351000073 ],
							[ 10.046919700000046, 11.99193019300003 ],
							[ 10.039920051000024, 11.989540169000065 ],
							[ 10.03524982600004, 11.988340158000028 ],
							[ 10.028240390000065, 11.987099688000058 ],
							[ 10.024740106000024, 11.985910451000052 ],
							[ 10.020079891000023, 11.983550312000034 ],
							[ 10.015410363000058, 11.982339904000071 ],
							[ 10.009579823000024, 11.979969829000026 ],
							[ 10.003740504000064, 11.978739844000074 ],
							[ 9.995564668000043, 11.976340273000062 ],
							[ 9.992064595000045, 11.975150154000062 ],
							[ 9.988563399000043, 11.973960023000075 ],
							[ 9.980381135000073, 11.972700291000024 ],
							[ 9.976868758000023, 11.972659810000039 ],
							[ 9.972221414000046, 11.969149861000062 ],
							[ 9.969927895000069, 11.964520668000034 ],
							[ 9.965257637000036, 11.963310187000047 ],
							[ 9.961732955000059, 11.964420489000076 ],
							[ 9.958208231000071, 11.965530184000045 ],
							[ 9.951158756000041, 11.967749492000053 ],
							[ 9.947647009000036, 11.967700271000069 ],
							[ 9.944146012000033, 11.966509703000042 ],
							[ 9.937133900000049, 11.965269780000028 ],
							[ 9.932462606000058, 11.964070184000036 ],
							[ 9.928961689000062, 11.962869865000073 ],
							[ 9.926632902000051, 11.961689771000067 ],
							[ 9.924291594000067, 11.961660572000028 ],
							[ 9.920778185000074, 11.961620136000022 ],
							[ 9.913790809000034, 11.958080199000051 ],
							[ 9.911460954000063, 11.95689943900004 ],
							[ 9.906777281000075, 11.956849871000031 ],
							[ 9.902130804000024, 11.95333959900006 ],
							[ 9.898630095000044, 11.952140141000029 ],
							[ 9.894061326000042, 11.952091617000065 ],
							[ 9.89394763100006, 11.95209040900005 ],
							[ 9.891605221000077, 11.952059876000021 ],
							[ 9.889263978000031, 11.952030456000045 ],
							[ 9.883422094000025, 11.95081043600004 ],
							[ 9.882012386000042, 11.950445418000072 ],
							[ 9.875485984000022, 11.946647734000067 ],
							[ 9.872124939000059, 11.939594390000025 ],
							[ 9.859208427000056, 11.932092584000031 ],
							[ 9.848667739000064, 11.918936135000024 ],
							[ 9.840498726000021, 11.898242142000072 ],
							[ 9.837621850000062, 11.893541692000042 ],
							[ 9.829971612000065, 11.890263258000061 ],
							[ 9.793654964000041, 11.883752512000058 ],
							[ 9.759248366000065, 11.87629247700005 ],
							[ 9.731516352000028, 11.862226111000041 ],
							[ 9.72386358600005, 11.856591741000045 ],
							[ 9.717654243000027, 11.856603782000036 ],
							[ 9.711931324000034, 11.860851659000048 ],
							[ 9.707167014000049, 11.866980186000035 ],
							[ 9.700966281000035, 11.871228818000077 ],
							[ 9.698106891000066, 11.874529222000035 ],
							[ 9.688550346000056, 11.872663931000034 ],
							[ 9.684245741000041, 11.869376840000029 ],
							[ 9.679455139000027, 11.861853735000068 ],
							[ 9.679896310000061, 11.842552776000048 ],
							[ 9.678931595000051, 11.837376390000031 ],
							[ 9.673663197000053, 11.829383412000027 ],
							[ 9.671262112000022, 11.822326462000035 ],
							[ 9.671725562000063, 11.814793690000045 ],
							[ 9.671227110000075, 11.803496976000076 ],
							[ 9.672645411000076, 11.795962466000049 ],
							[ 9.673112310000022, 11.79031279700007 ],
							[ 9.673584166000069, 11.787016883000035 ],
							[ 9.672148708000066, 11.785607012000071 ],
							[ 9.668328797000072, 11.785613901000033 ],
							[ 9.66260210400003, 11.787507284000071 ],
							[ 9.657829350000043, 11.78892767800005 ],
							[ 9.652844050000056, 11.792963191000069 ],
							[ 9.652067885000065, 11.792570193000074 ],
							[ 9.649727115000076, 11.792539820000059 ],
							[ 9.647398051000039, 11.791359654000075 ],
							[ 9.643896907000055, 11.790169637000076 ],
							[ 9.638091917000054, 11.785490208000056 ],
							[ 9.635774660000038, 11.783160170000031 ],
							[ 9.633457431000068, 11.780830134000041 ],
							[ 9.632322315000067, 11.777360181000063 ],
							[ 9.631188347000034, 11.773889648000022 ],
							[ 9.628919367000037, 11.766959864000057 ],
							[ 9.627784901000041, 11.763489893000042 ],
							[ 9.626651022000033, 11.760020472000065 ],
							[ 9.626674541000057, 11.757719760000043 ],
							[ 9.62788179000006, 11.75428028500005 ],
							[ 9.629076673000043, 11.751990588000069 ],
							[ 9.630284463000066, 11.748550546000047 ],
							[ 9.632674179000048, 11.743980184000065 ],
							[ 9.635052676000043, 11.740549979000036 ],
							[ 9.637418830000058, 11.738280299000053 ],
							[ 9.639783808000061, 11.735999849000052 ],
							[ 9.642137640000044, 11.734880497000063 ],
							[ 9.645674474000032, 11.732620032000057 ],
							[ 9.649198442000056, 11.731510449000041 ],
							[ 9.652711846000045, 11.731550059000028 ],
							[ 9.655053366000061, 11.731579838000073 ],
							[ 9.660931594000033, 11.72935011900006 ],
							[ 9.664443834000053, 11.729390176000038 ],
							[ 9.666157840000039, 11.729411972000037 ],
							[ 9.666702419000046, 11.729206473000033 ],
							[ 9.674425588000076, 11.726289009000027 ],
							[ 9.695426687000065, 11.722955219000028 ],
							[ 9.714042045000042, 11.720566134000023 ],
							[ 9.727415853000025, 11.723364608000054 ],
							[ 9.741747439000051, 11.727573203000077 ],
							[ 9.753696556000023, 11.733668646000069 ],
							[ 9.764206465000029, 11.736471593000033 ],
							[ 9.775669238000035, 11.738330973000075 ],
							[ 9.797625376000042, 11.73498941500003 ],
							[ 9.805250458000046, 11.728852921000055 ],
							[ 9.811440700000048, 11.721308037000028 ],
							[ 9.810937987000045, 11.710010863000036 ],
							[ 9.803278290000037, 11.700612919000037 ],
							[ 9.804703901000039, 11.697785411000041 ],
							[ 9.81042737000007, 11.695419188000074 ],
							[ 9.819961706000072, 11.689278185000035 ],
							[ 9.820613569000045, 11.688444509000021 ],
							[ 9.820670920000055, 11.68292038800007 ],
							[ 9.820719497000027, 11.678320036000059 ],
							[ 9.820768614000031, 11.673710111000048 ],
							[ 9.820804315000032, 11.670260704000043 ],
							[ 9.820865704000028, 11.664500385000053 ],
							[ 9.822073083000021, 11.661060251000038 ],
							[ 9.822109349000073, 11.657609710000031 ],
							[ 9.823304410000048, 11.655319943000052 ],
							[ 9.824522854000065, 11.650730003000035 ],
							[ 9.825719040000024, 11.648440225000058 ],
							[ 9.826386042000024, 11.644742506000057 ],
							[ 9.825095815000054, 11.637955749000071 ],
							[ 9.821741857000063, 11.632314293000036 ],
							[ 9.815045337000072, 11.626208957000074 ],
							[ 9.81599394400007, 11.623382418000062 ],
							[ 9.818371428000034, 11.619611461000034 ],
							[ 9.818085339000049, 11.611665903000073 ],
							[ 9.819856498000036, 11.608592895000072 ],
							[ 9.821140919000072, 11.606363953000027 ],
							[ 9.824687038000036, 11.601678994000054 ],
							[ 9.825468643000022, 11.597779317000061 ],
							[ 9.824668356000075, 11.593493285000022 ],
							[ 9.821798125000043, 11.588799380000069 ],
							[ 9.821495412000047, 11.588433361000057 ],
							[ 9.82051941900005, 11.586199801000021 ],
							[ 9.820599225000024, 11.582751405000067 ],
							[ 9.820683684000073, 11.579079829000023 ],
							[ 9.820625062000033, 11.576218206000021 ],
							[ 9.819506515000057, 11.571220401000062 ],
							[ 9.818371957000068, 11.56774981500007 ],
							[ 9.816054370000074, 11.565420330000052 ],
							[ 9.813749105000056, 11.561940431000039 ],
							[ 9.810273094000024, 11.558440486000052 ],
							[ 9.80561463600003, 11.556079858000032 ],
							[ 9.803297205000035, 11.553750291000028 ],
							[ 9.797425195000073, 11.551400088000037 ],
							[ 9.79607191100007, 11.548603960000037 ],
							[ 9.791662109000072, 11.546699716000035 ],
							[ 9.787027557000044, 11.542039797000029 ],
							[ 9.783539047000033, 11.539699588000076 ],
							[ 9.78123365700003, 11.536209892000045 ],
							[ 9.778928915000051, 11.532730345000061 ],
							[ 9.777780776000043, 11.530420174000028 ],
							[ 9.777829857000029, 11.525810190000072 ],
							[ 9.779719463000049, 11.520073112000034 ],
							[ 9.780269865000037, 11.51662994000003 ],
							[ 9.781463580000036, 11.514340289000074 ],
							[ 9.782683224000039, 11.509750439000072 ],
							[ 9.782708025000034, 11.507450247000065 ],
							[ 9.783916387000033, 11.50401019800006 ],
							[ 9.783951837000075, 11.500550016000034 ],
							[ 9.78514664000005, 11.498269957000048 ],
							[ 9.785183222000057, 11.494810346000065 ],
							[ 9.784049253000035, 11.491339636000077 ],
							[ 9.785242882000034, 11.489060133000066 ],
							[ 9.785280027000056, 11.485600518000069 ],
							[ 9.786487728000054, 11.482159901000045 ],
							[ 9.786523736000049, 11.478710450000051 ],
							[ 9.786745683000049, 11.474392199000022 ],
							[ 9.786789631000033, 11.473539510000023 ],
							[ 9.787969090000047, 11.467153543000052 ],
							[ 9.789388313000075, 11.461501573000021 ],
							[ 9.791171340000062, 11.460028955000041 ],
							[ 9.795102964000023, 11.456781739000064 ],
							[ 9.79653078900003, 11.455366712000057 ],
							[ 9.800816421000036, 11.451591148000034 ],
							[ 9.803676744000029, 11.450643668000055 ],
							[ 9.80845077400005, 11.452045252000062 ],
							[ 9.81179441300003, 11.453921128000047 ],
							[ 9.81466215100005, 11.456268686000044 ],
							[ 9.821338402000038, 11.45484200900006 ],
							[ 9.824204062000035, 11.45624762500006 ],
							[ 9.829943033000063, 11.462354448000042 ],
							[ 9.837604940000062, 11.474577004000025 ],
							[ 9.840469724000059, 11.475511828000037 ],
							[ 9.84570913500005, 11.471734147000063 ],
							[ 9.849042072000032, 11.468902071000059 ],
							[ 9.853335098000059, 11.46842158000004 ],
							[ 9.855235858000071, 11.465121515000021 ],
							[ 9.854749381000033, 11.461356848000037 ],
							[ 9.86046710100004, 11.458048521000023 ],
							[ 9.865712814000062, 11.457094452000035 ],
							[ 9.873342251000054, 11.455664635000062 ],
							[ 9.877631657000052, 11.453771329000062 ],
							[ 9.881198631000075, 11.448464243000046 ],
							[ 9.88053982100007, 11.446450404000075 ],
							[ 9.88056457700003, 11.444150194000031 ],
							[ 9.880600825000045, 11.440699594000023 ],
							[ 9.880637069000045, 11.437239404000024 ],
							[ 9.879503515000067, 11.433779707000042 ],
							[ 9.879538626000056, 11.430320073000075 ],
							[ 9.87956336600007, 11.428019287000041 ],
							[ 9.880783202000032, 11.423430213000074 ],
							[ 9.880806791000055, 11.42113000300003 ],
							[ 9.880831519000026, 11.418820180000068 ],
							[ 9.880867764000072, 11.415370149000069 ],
							[ 9.88206281500004, 11.413080152000077 ],
							[ 9.882086976000039, 11.410779938000076 ],
							[ 9.882111700000053, 11.408479723000028 ],
							[ 9.882159997000031, 11.403869685000075 ],
							[ 9.882184160000065, 11.40157060000007 ],
							[ 9.882207737000044, 11.399269812000057 ],
							[ 9.881073717000049, 11.395799394000051 ],
							[ 9.88110996000006, 11.392350467000028 ],
							[ 9.879976536000072, 11.388880043000029 ],
							[ 9.880000104000032, 11.386570218000031 ],
							[ 9.880036371000074, 11.383119605000047 ],
							[ 9.878044335000027, 11.378273705000026 ],
							[ 9.87927355000005, 11.376422068000068 ],
							[ 9.877792087000046, 11.373880191000069 ],
							[ 9.877816273000064, 11.371579971000074 ],
							[ 9.877841020000062, 11.369279751000022 ],
							[ 9.877864620000025, 11.366969923000056 ],
							[ 9.879059535000067, 11.364690111000073 ],
							[ 9.881438345000049, 11.361260487000038 ],
							[ 9.882633211000041, 11.358969906000027 ],
							[ 9.884999330000028, 11.356699710000044 ],
							[ 9.895046071000024, 11.351470721000055 ],
							[ 9.896819185000027, 11.348797894000029 ],
							[ 9.900316259000022, 11.347669569000061 ],
							[ 9.904623868000044, 11.345620518000032 ],
							[ 9.905029801000069, 11.344961287000046 ],
							[ 9.910744033000071, 11.341180935000068 ],
							[ 9.915981955000063, 11.337872897000068 ],
							[ 9.918354689000068, 11.333159331000047 ],
							[ 9.920252386000072, 11.329388808000033 ],
							[ 9.919768371000032, 11.326564956000027 ],
							[ 9.921192178000069, 11.323736909000047 ],
							[ 9.923575591000031, 11.323260331000029 ],
							[ 9.931670868000026, 11.318532409000056 ],
							[ 9.942627255000048, 11.313797606000037 ],
							[ 9.948805709000055, 11.305778995000026 ],
							[ 9.952126393000071, 11.299179584000058 ],
							[ 9.966399071000069, 11.286432115000025 ],
							[ 9.976898710000057, 11.276917436000076 ],
							[ 9.977366454000048, 11.276550082000028 ],
							[ 9.977948408000032, 11.276092575000064 ],
							[ 9.979549272000042, 11.273790432000055 ],
							[ 9.980756690000021, 11.270350258000065 ],
							[ 9.983134495000058, 11.266919958000074 ],
							[ 9.985500528000046, 11.264650234000044 ],
							[ 9.986695562000023, 11.262359855000057 ],
							[ 9.987791286000061, 11.259761824000066 ],
							[ 9.989097856000058, 11.256629842000052 ],
							[ 9.990292261000036, 11.254340046000038 ],
							[ 9.991488402000073, 11.252059831000054 ],
							[ 9.993841964000069, 11.250929705000033 ],
							[ 9.996195561000036, 11.24981032200003 ],
							[ 9.997390461000066, 11.247520481000038 ],
							[ 10.000920069000074, 11.246410328000024 ],
							[ 10.003229944000054, 11.248740432000034 ],
							[ 10.00556012900006, 11.249920106000047 ],
							[ 10.007889748000025, 11.251099748000058 ],
							[ 10.014866572000074, 11.254601352000066 ],
							[ 10.019559629000071, 11.254699794000032 ],
							[ 10.021848574000046, 11.254624569000043 ],
							[ 10.024220744000047, 11.257060032000027 ],
							[ 10.027720277000071, 11.258250445000044 ],
							[ 10.031209973000045, 11.260600284000077 ],
							[ 10.034710123000025, 11.261790056000052 ],
							[ 10.037039915000037, 11.262970088000031 ],
							[ 10.046409432000075, 11.263079383000047 ],
							[ 10.048749665000059, 11.263110083000072 ],
							[ 10.052270118000024, 11.262000023000041 ],
							[ 10.054610341000057, 11.262029538000036 ],
							[ 10.05696030200005, 11.262060140000074 ],
							[ 10.060413177000044, 11.261534438000069 ],
							[ 10.064188137000031, 11.260190181000041 ],
							[ 10.067253910000034, 11.260950930000035 ],
							[ 10.070197926000048, 11.260214702000042 ],
							[ 10.075730235000037, 11.258830244000023 ],
							[ 10.080410086000029, 11.258890094000037 ],
							[ 10.083929839000064, 11.257780309000054 ],
							[ 10.087450140000044, 11.257820294000055 ],
							[ 10.092140254000071, 11.256730161000064 ],
							[ 10.095649687000048, 11.25677010000004 ],
							[ 10.099189899000066, 11.254509730000052 ],
							[ 10.107430241000031, 11.250000130000046 ],
							[ 10.110960100000057, 11.248890039000059 ],
							[ 10.113309873000048, 11.247770478000064 ],
							[ 10.116849885000022, 11.245509939000044 ],
							[ 10.122729986000024, 11.243280154000047 ],
							[ 10.12742991600004, 11.241029554000022 ],
							[ 10.133299738000062, 11.239950012000065 ],
							[ 10.137980502000062, 11.240010262000055 ],
							[ 10.141500656000062, 11.240050308000036 ],
							[ 10.142108102000066, 11.239958588000036 ],
							[ 10.148529883000037, 11.238990100000024 ],
							[ 10.150890316000073, 11.237859528000058 ],
							[ 10.159159502000023, 11.23105978600006 ],
							[ 10.165049660000022, 11.227669878000029 ],
							[ 10.169739716000038, 11.226579890000039 ],
							[ 10.173270320000029, 11.225469774000032 ],
							[ 10.175620329000026, 11.224349816000029 ],
							[ 10.183830275000048, 11.223290284000029 ],
							[ 10.187339992000034, 11.223340093000047 ],
							[ 10.189689908000048, 11.222209881000026 ],
							[ 10.195520313000031, 11.224590216000024 ],
							[ 10.200025427000071, 11.226094993000061 ],
							[ 10.202297024000075, 11.225683096000068 ],
							[ 10.208379989000036, 11.227049889000057 ],
							[ 10.21071997100006, 11.227069939000046 ],
							[ 10.21306056800006, 11.227100156000063 ],
							[ 10.215419614000041, 11.225979915000039 ],
							[ 10.217760205000047, 11.226010084000052 ],
							[ 10.221269360000065, 11.226050538000038 ],
							[ 10.223620364000055, 11.224929698000039 ],
							[ 10.22597006500007, 11.224949638000055 ],
							[ 10.229479808000065, 11.225000178000073 ],
							[ 10.232999902000074, 11.223890103000031 ],
							[ 10.235339916000044, 11.223919607000028 ],
							[ 10.238118021000048, 11.22304642000006 ],
							[ 10.242389761000027, 11.221699833000059 ],
							[ 10.247090352000043, 11.22060035100003 ],
							[ 10.250610382000048, 11.219499717000076 ],
							[ 10.255309775000057, 11.218400134000035 ],
							[ 10.261089506000076, 11.213523053000074 ],
							[ 10.264937416000066, 11.212777129000074 ],
							[ 10.270640210000067, 11.211959830000069 ],
							[ 10.273101827000062, 11.211606742000072 ],
							[ 10.276716453000063, 11.211089188000074 ],
							[ 10.278593834000048, 11.209936855000024 ],
							[ 10.281369170000062, 11.209469064000075 ],
							[ 10.288379805000034, 11.208285345000036 ],
							[ 10.291992905000029, 11.207676130000038 ],
							[ 10.294860008000057, 11.205998192000038 ],
							[ 10.298810266000032, 11.205750470000055 ],
							[ 10.303560280000056, 11.205451852000067 ],
							[ 10.307053440000061, 11.205232773000034 ],
							[ 10.309383533000073, 11.205085568000072 ],
							[ 10.312681015000067, 11.204878419000067 ],
							[ 10.314050579000025, 11.204792199000053 ],
							[ 10.318955617000029, 11.204483818000028 ],
							[ 10.322196155000029, 11.204197675000046 ],
							[ 10.330389837000041, 11.205489972000066 ],
							[ 10.332730305000041, 11.205519416000072 ],
							[ 10.338569699000061, 11.206740315000047 ],
							[ 10.342080389000046, 11.206780175000063 ],
							[ 10.346750178000036, 11.207989918000067 ],
							[ 10.352609377000022, 11.208060139000054 ],
							[ 10.358460587000025, 11.208129722000024 ],
							[ 10.363160110000024, 11.207040083000038 ],
							[ 10.365509977000045, 11.205920028000037 ],
							[ 10.366720385000065, 11.202480263000041 ],
							[ 10.365579461000038, 11.199010144000056 ],
							[ 10.365619610000067, 11.195549802000073 ],
							[ 10.36446935400005, 11.193240282000033 ],
							[ 10.364500164000049, 11.190939969000055 ],
							[ 10.363360444000023, 11.187470412000039 ],
							[ 10.364620354000067, 11.179420436000044 ],
							[ 10.361140423000052, 11.17593008700004 ],
							[ 10.358810710000057, 11.174750378000056 ],
							[ 10.354180455000062, 11.17008949500007 ],
							[ 10.350700097000072, 11.166589457000043 ],
							[ 10.346444228000053, 11.162845828000059 ],
							[ 10.346090127000025, 11.159629879000022 ],
							[ 10.343790309000042, 11.156140171000061 ],
							[ 10.340310221000038, 11.152649623000059 ],
							[ 10.339160243000038, 11.150329836000026 ],
							[ 10.338020555000071, 11.148010007000039 ],
							[ 10.335720128000048, 11.143380024000066 ],
							[ 10.335760013000026, 11.139929858000073 ],
							[ 10.333470503000058, 11.135290228000031 ],
							[ 10.33115956100005, 11.13181006700006 ],
							[ 10.327670329000057, 11.129469820000054 ],
							[ 10.326229727000054, 11.129920215000027 ],
							[ 10.321325956000067, 11.129044402000034 ],
							[ 10.315607946000057, 11.129535389000068 ],
							[ 10.310849855000072, 11.131906136000055 ],
							[ 10.30655805400005, 11.131450617000041 ],
							[ 10.305119899000033, 11.129101780000042 ],
							[ 10.303192790000026, 11.123459468000021 ],
							[ 10.297473156000024, 11.123479520000046 ],
							[ 10.294613349000031, 11.123489513000038 ],
							[ 10.287446767000063, 11.118806674000041 ],
							[ 10.284578016000069, 11.117253778000077 ],
							[ 10.284091358000069, 11.116820386000029 ],
							[ 10.282159662000026, 11.115099796000038 ],
							[ 10.279830020000077, 11.113920162000056 ],
							[ 10.277489566000042, 11.113889812000025 ],
							[ 10.272820664000051, 11.112680321000028 ],
							[ 10.270500186000049, 11.110349650000046 ],
							[ 10.268230556000049, 11.103420054000026 ],
							[ 10.26708972800003, 11.099949613000035 ],
							[ 10.264780243000075, 11.097619996000049 ],
							[ 10.262459916000068, 11.095290401000057 ],
							[ 10.260129899000049, 11.094109514000024 ],
							[ 10.256620261000023, 11.094059824000055 ],
							[ 10.253110096000057, 11.09401973000007 ],
							[ 10.247249340000053, 11.093950207000034 ],
							[ 10.244910163000043, 11.093919648000053 ],
							[ 10.242569863000028, 11.093890208000062 ],
							[ 10.240219799000045, 11.095020213000055 ],
							[ 10.237859976000038, 11.096140054000045 ],
							[ 10.234339977000047, 11.097250189000022 ],
							[ 10.231969734000074, 11.099519862000022 ],
							[ 10.228605266000045, 11.100789893000069 ],
							[ 10.22890575100007, 11.103699907000021 ],
							[ 10.217870004000076, 11.10396005900003 ],
							[ 10.21553020500005, 11.103930446000049 ],
							[ 10.21080424400003, 11.103006265000033 ],
							[ 10.207208839000032, 11.10230322600006 ],
							[ 10.201354101000049, 11.101158292000036 ],
							[ 10.198887549000062, 11.100675838000029 ],
							[ 10.194549818000041, 11.094470044000047 ],
							[ 10.192250170000023, 11.090979388000051 ],
							[ 10.188759951000065, 11.088640094000027 ],
							[ 10.186440735000076, 11.086310576000074 ],
							[ 10.182970252000075, 11.082810606000066 ],
							[ 10.180649981000045, 11.080479370000035 ],
							[ 10.178330308000056, 11.078149801000052 ],
							[ 10.177189594000026, 11.075830604000032 ],
							[ 10.174859982000044, 11.074649754000063 ],
							[ 10.172550376000061, 11.071169724000072 ],
							[ 10.16909019700006, 11.066519769000024 ],
							[ 10.166780698000025, 11.063039700000047 ],
							[ 10.165640124000049, 11.060720484000058 ],
							[ 10.162170438000032, 11.05608008300004 ],
							[ 10.159870218000037, 11.05259994000005 ],
							[ 10.157590130000074, 11.046809912000072 ],
							[ 10.15411001800004, 11.043310251000037 ],
							[ 10.152959892000069, 11.041000614000041 ],
							[ 10.15066990400004, 11.036359836000031 ],
							[ 10.149519822000059, 11.034050187000048 ],
							[ 10.148390077000045, 11.030579336000073 ],
							[ 10.144442600000048, 11.026971996000043 ],
							[ 10.145130399000038, 11.021476686000028 ],
							[ 10.142669420000061, 11.017850030000034 ],
							[ 10.141530068000066, 11.014380312000071 ],
							[ 10.14272956800005, 11.012089949000028 ],
							[ 10.145119381000029, 11.007510384000057 ],
							[ 10.148846622000065, 11.003418804000034 ],
							[ 10.149839224000061, 11.000649357000043 ],
							[ 10.15117189700004, 10.996931854000024 ],
							[ 10.152290205000043, 10.99378004700003 ],
							[ 10.154669740000031, 10.990360457000065 ],
							[ 10.158220464000067, 10.986950171000046 ],
							[ 10.161749598000029, 10.984680154000046 ],
							[ 10.164119545000062, 10.982410339000069 ],
							[ 10.166469707000033, 10.981289840000045 ],
							[ 10.170453274000067, 10.980404885000041 ],
							[ 10.171362890000069, 10.978570766000075 ],
							[ 10.17586994800007, 10.977950230000033 ],
							[ 10.178230319000022, 10.976820016000033 ],
							[ 10.180569854000055, 10.976849306000076 ],
							[ 10.184109672000034, 10.974590335000073 ],
							[ 10.187629754000056, 10.973480090000066 ],
							[ 10.188830583000026, 10.97119974800006 ],
							[ 10.192380188000072, 10.967780099000038 ],
							[ 10.194750030000023, 10.964360255000031 ],
							[ 10.194780174000073, 10.962059928000031 ],
							[ 10.194800563000058, 10.959750022000037 ],
							[ 10.193679996000071, 10.955129433000025 ],
							[ 10.193699865000042, 10.952830266000035 ],
							[ 10.192560025000034, 10.950509985000053 ],
							[ 10.191409948000057, 10.948200453000027 ],
							[ 10.189079949000075, 10.947019667000063 ],
							[ 10.186759932000029, 10.944689579000055 ],
							[ 10.182090090000031, 10.943479517000071 ],
							[ 10.178580255000043, 10.943440406000036 ],
							[ 10.175080365000042, 10.942239513000061 ],
							[ 10.172739912000054, 10.942210210000042 ],
							[ 10.16804042800004, 10.943309907000071 ],
							[ 10.165699948000054, 10.943279967000024 ],
							[ 10.163350028000025, 10.944399907000047 ],
							[ 10.159839623000039, 10.944360047000032 ],
							[ 10.156319474000043, 10.944319627000027 ],
							[ 10.154158367000036, 10.946203381000032 ],
							[ 10.151090532000069, 10.947248622000075 ],
							[ 10.148119755000039, 10.945369503000052 ],
							[ 10.145759497000029, 10.946500084000036 ],
							[ 10.143420159000073, 10.94647001800007 ],
							[ 10.134040091000031, 10.94750005800006 ],
							[ 10.126979621000032, 10.950869391000026 ],
							[ 10.124630129000025, 10.951999805000071 ],
							[ 10.122269741000025, 10.953120052000031 ],
							[ 10.119919604000074, 10.954239698000038 ],
							[ 10.117549616000076, 10.956519974000059 ],
							[ 10.114000532000034, 10.959930427000074 ],
							[ 10.110460522000039, 10.963339688000076 ],
							[ 10.108581706000052, 10.967518057000063 ],
							[ 10.105649835000065, 10.974799846000053 ],
							[ 10.105629990000068, 10.977100133000022 ],
							[ 10.103239852000058, 10.981670416000043 ],
							[ 10.100879795000026, 10.982799578000026 ],
							[ 10.096200082000053, 10.982740551000063 ],
							[ 10.090310370000054, 10.986120393000022 ],
							[ 10.086779561000071, 10.987230299000032 ],
							[ 10.078559830000074, 10.989439826000023 ],
							[ 10.076219643000059, 10.989410467000027 ],
							[ 10.075564756000063, 10.989185801000076 ],
							[ 10.06969894100007, 10.983917496000061 ],
							[ 10.063018532000058, 10.980641480000031 ],
							[ 10.057294285000069, 10.978303930000038 ],
							[ 10.051570094000056, 10.975966309000057 ],
							[ 10.045854138000038, 10.976453143000072 ],
							[ 10.040137607000077, 10.976940463000062 ],
							[ 10.03250951900003, 10.975078550000035 ],
							[ 10.028696253000021, 10.974618485000065 ],
							[ 10.024416579000047, 10.977454899000065 ],
							[ 10.020596662000059, 10.97464087000003 ],
							[ 10.008189151000067, 10.967613098000072 ],
							[ 10.007191154000054, 10.968180271000051 ],
							[ 10.006190068000024, 10.967840021000029 ],
							[ 10.00269054100005, 10.966640336000069 ],
							[ 9.998008776000063, 10.966589685000031 ],
							[ 9.994508708000069, 10.965389943000048 ],
							[ 9.99099639800005, 10.965350191000027 ],
							[ 9.988655230000063, 10.965319708000038 ],
							[ 9.985142921000033, 10.965279345000056 ],
							[ 9.98047063000007, 10.964070141000036 ],
							[ 9.973458360000052, 10.962829723000027 ],
							[ 9.96878730800006, 10.96162997700003 ],
							[ 9.966458537000051, 10.960450070000036 ],
							[ 9.958249914000021, 10.961500283000021 ],
							[ 9.953554083000029, 10.962590472000045 ],
							[ 9.950030527000024, 10.963700188000075 ],
							[ 9.944138648000035, 10.967089583000075 ],
							[ 9.939444426000023, 10.968179588000055 ],
							[ 9.935919630000058, 10.969289728000035 ],
							[ 9.928858628000057, 10.972659795000027 ],
							[ 9.921809445000065, 10.974880428000063 ],
							[ 9.918273150000061, 10.977140271000053 ],
							[ 9.915930770000045, 10.977109905000077 ],
							[ 9.911595627000054, 10.982128991000025 ],
							[ 9.909095795000042, 10.982719363000058 ],
							[ 9.906516192000026, 10.981599591000077 ],
							[ 9.90356231100003, 10.985174935000032 ],
							[ 9.900637880000033, 10.98383050800004 ],
							[ 9.890393503000041, 10.991020344000049 ],
							[ 9.887170633000039, 10.992266615000062 ],
							[ 9.881819824000047, 10.991660117000038 ],
							[ 9.874032048000061, 10.997348596000052 ],
							[ 9.871210149000035, 10.998439518000055 ],
							[ 9.868856705000042, 10.999569997000037 ],
							[ 9.86581899600003, 10.999396306000051 ],
							[ 9.862929355000063, 11.006400309000071 ],
							[ 9.859332301000052, 11.014419608000026 ],
							[ 9.858112430000062, 11.019009869000058 ],
							[ 9.858076007000022, 11.022459990000073 ],
							[ 9.856868813000062, 11.025899839000033 ],
							[ 9.855625172000032, 11.032799966000027 ],
							[ 9.854406335000021, 11.037389633000032 ],
							[ 9.854369296000073, 11.040840325000033 ],
							[ 9.854320692000044, 11.045450471000038 ],
							[ 9.854277265000064, 11.049585979000028 ],
							[ 9.854248330000075, 11.052350143000069 ],
							[ 9.854224038000041, 11.054660020000028 ],
							[ 9.854199142000027, 11.056960287000038 ],
							[ 9.852955902000076, 11.063850226000056 ],
							[ 9.852932163000048, 11.066160098000069 ],
							[ 9.854042303000028, 11.071929811000075 ],
							[ 9.855164585000068, 11.076540082000065 ],
							[ 9.856310658000041, 11.078860251000037 ],
							[ 9.856274798000072, 11.082319966000057 ],
							[ 9.855042514000047, 11.088060615000074 ],
							[ 9.855018781000069, 11.090359745000058 ],
							[ 9.854993888000024, 11.092660008000053 ],
							[ 9.853726241000061, 11.101859783000066 ],
							[ 9.853653863000034, 11.108770168000035 ],
							[ 9.854800024000042, 11.111080172000072 ],
							[ 9.855935184000032, 11.114549603000057 ],
							[ 9.857081383000036, 11.116869763000068 ],
							[ 9.857057663000035, 11.119170019000023 ],
							[ 9.858203883000044, 11.121489602000054 ],
							[ 9.861680131000071, 11.124980157000039 ],
							[ 9.862826401000063, 11.127300299000069 ],
							[ 9.865143791000037, 11.129630130000066 ],
							[ 9.868631186000073, 11.131979814000033 ],
							[ 9.872108216000072, 11.135470268000063 ],
							[ 9.879072302000054, 11.141309700000022 ],
							[ 9.882559902000025, 11.143659808000052 ],
							[ 9.884864869000069, 11.147139374000062 ],
							[ 9.889499640000054, 11.151800445000049 ],
							[ 9.891805870000042, 11.155279944000029 ],
							[ 9.892939818000059, 11.158749829000044 ],
							[ 9.891731940000057, 11.162190318000057 ],
							[ 9.888183868000056, 11.165599678000035 ],
							[ 9.882281201000069, 11.170140150000066 ],
							[ 9.878743897000049, 11.172400712000069 ],
							[ 9.876390461000028, 11.173519931000044 ],
							[ 9.871659342000044, 11.178069883000035 ],
							[ 9.869293164000055, 11.180340024000031 ],
							[ 9.865719898000066, 11.186060153000028 ],
							[ 9.863316659000077, 11.191780358000074 ],
							[ 9.862110093000069, 11.195220182000071 ],
							[ 9.860878683000067, 11.200969839000038 ],
							[ 9.859671481000021, 11.204409636000037 ],
							[ 9.856086800000071, 11.211270461000026 ],
							[ 9.854891688000066, 11.21355985100007 ],
							[ 9.852500859000031, 11.218139731000065 ],
							[ 9.851295246000063, 11.221579511000073 ],
							[ 9.850062408000042, 11.227319516000023 ],
							[ 9.848867208000058, 11.229610587000025 ],
							[ 9.846465118000026, 11.235329514000057 ],
							[ 9.844099012000072, 11.237609667000072 ],
							[ 9.842904301000033, 11.239899568000055 ],
							[ 9.839342810000062, 11.244459979000055 ],
							[ 9.836977119000039, 11.246729920000064 ],
							[ 9.834586989000059, 11.251310244000024 ],
							[ 9.832173473000068, 11.258190210000066 ],
							[ 9.830977475000054, 11.260480073000053 ],
							[ 9.829770953000036, 11.263919775000033 ],
							[ 9.829746470000032, 11.266220008000062 ],
							[ 9.828551010000069, 11.268509861000041 ],
							[ 9.826161211000056, 11.273090123000031 ],
							[ 9.826124438000022, 11.276539609000054 ],
							[ 9.824930066000036, 11.278829463000022 ],
							[ 9.824892739000063, 11.282280079000031 ],
							[ 9.822539548000066, 11.283399518000067 ],
							[ 9.819014756000058, 11.284509663000051 ],
							[ 9.816673267000056, 11.284480349000034 ],
							[ 9.813160750000065, 11.284440036000035 ],
							[ 9.808466035000038, 11.285539645000028 ],
							[ 9.804953470000044, 11.285490220000042 ],
							[ 9.801440383000056, 11.285449798000059 ],
							[ 9.797355864000053, 11.282974223000053 ],
							[ 9.79449668500007, 11.281242370000029 ],
							[ 9.789805368000032, 11.278400184000077 ],
							[ 9.787463338000066, 11.278370132000077 ],
							[ 9.78397564200003, 11.276029315000073 ],
							[ 9.780475605000049, 11.274840550000022 ],
							[ 9.77697442300007, 11.273639906000028 ],
							[ 9.773486862000027, 11.271300119000045 ],
							[ 9.769986880000033, 11.270099388000062 ],
							[ 9.766486385000064, 11.268910511000058 ],
							[ 9.762986473000069, 11.267720451000059 ],
							[ 9.759497875000022, 11.265370370000028 ],
							[ 9.751314151000031, 11.264120255000023 ],
							[ 9.748984144000076, 11.262940134000075 ],
							[ 9.746642798000039, 11.262909824000076 ],
							[ 9.738254420000032, 11.263685977000023 ],
							[ 9.72909269400003, 11.261549697000021 ],
							[ 9.724413033000076, 11.263861855000073 ],
							[ 9.72204603800003, 11.263891907000072 ],
							[ 9.719515084000022, 11.263488876000054 ],
							[ 9.717473230000053, 11.263261733000036 ],
							[ 9.710820434000027, 11.26105121300003 ],
							[ 9.707310534000044, 11.259884939000074 ],
							[ 9.704860659000076, 11.259070337000026 ],
							[ 9.699431230000073, 11.25726577100005 ],
							[ 9.697505659000058, 11.258859360000031 ],
							[ 9.692834578000031, 11.257660100000066 ],
							[ 9.688151386000072, 11.257599886000037 ],
							[ 9.685808920000056, 11.257570323000039 ],
							[ 9.677637783000023, 11.255169887000022 ],
							[ 9.670613341000035, 11.255080394000061 ],
							[ 9.665929624000057, 11.255029491000073 ],
							[ 9.655405185000063, 11.25374983200004 ],
							[ 9.649550552000051, 11.253680459000066 ],
							[ 9.646050591000062, 11.252489870000034 ],
							[ 9.641366914000059, 11.252430178000054 ],
							[ 9.635501161000036, 11.253510424000069 ],
							[ 9.628618906000042, 11.251909021000074 ],
							[ 9.624977827000066, 11.252455886000064 ],
							[ 9.616743966000058, 11.255580088000045 ],
							[ 9.613219380000032, 11.256689964000032 ],
							[ 9.602657360000023, 11.258870142000035 ],
							[ 9.595621030000075, 11.259940343000039 ],
							[ 9.589767436000045, 11.259859862000042 ],
							[ 9.585731496000051, 11.258386471000051 ],
							[ 9.582263127000033, 11.259651355000074 ],
							[ 9.58033931500006, 11.265509627000029 ],
							[ 9.576754454000024, 11.272370547000037 ],
							[ 9.575534705000052, 11.276960346000067 ],
							[ 9.57549842800006, 11.28042055800006 ],
							[ 9.576645060000033, 11.282730244000049 ],
							[ 9.577779813000063, 11.286199371000066 ],
							[ 9.576511304000064, 11.295399769000028 ],
							[ 9.576475017000064, 11.298849795000024 ],
							[ 9.576427414000023, 11.303460391000044 ],
							[ 9.575195092000058, 11.309200014000055 ],
							[ 9.573975781000058, 11.313790370000049 ],
							[ 9.573951953000062, 11.316089445000046 ],
							[ 9.57275700100007, 11.318380165000065 ],
							[ 9.571549538000056, 11.32182012100003 ],
							[ 9.570354551000037, 11.324110258000076 ],
							[ 9.56680588200004, 11.327520462000052 ],
							[ 9.560914779000029, 11.330900280000037 ],
							[ 9.557366517000048, 11.334309837000035 ],
							[ 9.555012760000068, 11.335439424000072 ],
							[ 9.549134003000063, 11.337670369000023 ],
							[ 9.546780745000035, 11.33878973800006 ],
							[ 9.542085683000039, 11.339889360000029 ],
							[ 9.537402019000069, 11.339830630000051 ],
							[ 9.535047567000049, 11.340949927000054 ],
							[ 9.531536265000057, 11.340909779000071 ],
							[ 9.528023801000074, 11.340870154000072 ],
							[ 9.523352772000067, 11.33965973200003 ],
							[ 9.521023025000034, 11.33847987200005 ],
							[ 9.515180866000037, 11.337260268000023 ],
							[ 9.510508783000034, 11.336050241000066 ],
							[ 9.505838458000028, 11.33483959800003 ],
							[ 9.499984832000052, 11.334770121000076 ],
							[ 9.497667340000021, 11.332440302000066 ],
							[ 9.494178735000048, 11.330100161000075 ],
							[ 9.491861310000047, 11.327770305000058 ],
							[ 9.489532324000038, 11.326589689000059 ],
							[ 9.489286574000062, 11.32265073700006 ],
							[ 9.486364595000055, 11.321025650000024 ],
							[ 9.48308256200005, 11.318935447000058 ],
							[ 9.481159407000064, 11.31771016700003 ],
							[ 9.47927065600004, 11.316507438000031 ],
							[ 9.477970224000046, 11.312629730000026 ],
							[ 9.475652453000066, 11.310299771000075 ],
							[ 9.469809760000032, 11.309080008000024 ],
							[ 9.466311571000062, 11.30788037700006 ],
							[ 9.462745521000045, 11.308652132000077 ],
							[ 9.460391026000025, 11.308388935000039 ],
							[ 9.456918506000022, 11.307999408000057 ],
							[ 9.454602360000024, 11.307740089000049 ],
							[ 9.451077351000038, 11.308850122000024 ],
							[ 9.446573328000056, 11.307920465000052 ],
							[ 9.441699110000059, 11.309889727000041 ],
							[ 9.438174059000062, 11.310999639000045 ],
							[ 9.433490787000039, 11.310939598000061 ],
							[ 9.431137367000076, 11.312060494000036 ],
							[ 9.428795714000046, 11.312029882000047 ],
							[ 9.426442283000029, 11.31315978400005 ],
							[ 9.424100625000051, 11.313130271000034 ],
							[ 9.415868490000037, 11.316479945000026 ],
							[ 9.413514408000026, 11.317609765000043 ],
							[ 9.411160301000052, 11.318730535000043 ],
							[ 9.404099625000072, 11.322099567000066 ],
							[ 9.401746040000035, 11.323220277000075 ],
							[ 9.398208819000047, 11.325480746000039 ],
							[ 9.394672134000075, 11.32773949400007 ],
							[ 9.392318494000051, 11.32886975200006 ],
							[ 9.389964811000027, 11.329990387000066 ],
							[ 9.386428029000058, 11.332249612000055 ],
							[ 9.384074325000029, 11.333370227000046 ],
							[ 9.380538038000054, 11.335630524000067 ],
							[ 9.371885543000076, 11.339675173000046 ],
							[ 9.368845834000069, 11.34234335900004 ],
							[ 9.364930337000033, 11.343956956000056 ],
							[ 9.362108290000037, 11.345120252000072 ],
							[ 9.349270890000071, 11.350412591000065 ],
							[ 9.335126660000071, 11.358035189000077 ],
							[ 9.331754867000029, 11.359851914000046 ],
							[ 9.313064361000045, 11.36930439300005 ],
							[ 9.298985574000028, 11.370635290000052 ],
							[ 9.279035413000031, 11.372039635000021 ],
							[ 9.274881316000062, 11.371732547000022 ],
							[ 9.239074769000069, 11.369089913000039 ],
							[ 9.224948171000051, 11.365813202000027 ],
							[ 9.20842290500002, 11.357957913000064 ],
							[ 9.193549098000062, 11.350888068000074 ],
							[ 9.194149063000054, 11.355250201000047 ],
							[ 9.194100591000051, 11.359860152000067 ],
							[ 9.194075488000067, 11.362159768000026 ],
							[ 9.19286829300006, 11.365599608000025 ],
							[ 9.19283179100006, 11.369050148000042 ],
							[ 9.193954575000021, 11.373669649000021 ],
							[ 9.196235614000045, 11.379460375000065 ],
							[ 9.196211105000032, 11.381759413000054 ],
							[ 9.197345952000035, 11.385230232000026 ],
							[ 9.198492213000065, 11.38755012300004 ],
							[ 9.199627093000061, 11.391010194000046 ],
							[ 9.201955565000048, 11.392189811000037 ],
							[ 9.204224310000029, 11.399129702000039 ],
							[ 9.204200995000065, 11.401429866000058 ],
							[ 9.206505668000034, 11.404910172000029 ],
							[ 9.208810953000068, 11.408399516000031 ],
							[ 9.211091862000046, 11.414179954000076 ],
							[ 9.21336093900004, 11.421119798000063 ],
							[ 9.215665805000071, 11.424599486000034 ],
							[ 9.215641981000033, 11.426899648000074 ],
							[ 9.217946926000025, 11.430379876000075 ],
							[ 9.219093462000046, 11.432700251000028 ],
							[ 9.217898185000024, 11.434989807000022 ],
							[ 9.219044735000068, 11.437310181000043 ],
							[ 9.220143144000076, 11.444230314000038 ],
							[ 9.221277265000026, 11.447689752000031 ],
							[ 9.222387074000039, 11.453470229000061 ],
							[ 9.222338943000068, 11.458069988000034 ],
							[ 9.222302693000074, 11.461519937000048 ],
							[ 9.22223021800005, 11.46842945800006 ],
							[ 9.223376340000073, 11.470749814000044 ],
							[ 9.224522458000024, 11.473070165000024 ],
							[ 9.227998828000068, 11.476559793000035 ],
							[ 9.235012198000049, 11.477800125000044 ],
							[ 9.242024443000048, 11.479029586000024 ],
							[ 9.244365435000077, 11.479060267000023 ],
							[ 9.247866830000021, 11.480259717000024 ],
							[ 9.25020780400007, 11.480280179000033 ],
							[ 9.253696302000037, 11.482629939000049 ],
							[ 9.256037881000054, 11.482660526000075 ],
							[ 9.263050312000075, 11.483889610000062 ],
							[ 9.267710008000051, 11.486249698000051 ],
							[ 9.273539715000027, 11.488630327000067 ],
							[ 9.275868476000028, 11.48981004500007 ],
							[ 9.279369491000068, 11.491000144000054 ],
							[ 9.282857129000035, 11.493339465000076 ],
							[ 9.285174256000062, 11.495679634000055 ],
							[ 9.287504238000054, 11.49685022400007 ],
							[ 9.289833097000042, 11.498029835000068 ],
							[ 9.294468122000069, 11.502699925000059 ],
							[ 9.29796876100005, 11.503889847000039 ],
							[ 9.301456634000033, 11.506239710000045 ],
							[ 9.304956186000027, 11.507430142000032 ],
							[ 9.308457487000055, 11.508619984000063 ],
							[ 9.31078705300007, 11.509800036000058 ],
							[ 9.313128249000044, 11.509830265000062 ],
							[ 9.317799625000021, 11.511039931000028 ],
							[ 9.321299277000037, 11.512229653000077 ],
							[ 9.323641635000058, 11.512260388000072 ],
							[ 9.325934301000075, 11.516889718000073 ],
							[ 9.327080660000036, 11.519209765000028 ],
							[ 9.329398249000064, 11.521539491000055 ],
							[ 9.330545210000025, 11.52386006200004 ],
							[ 9.330520402000047, 11.526160216000051 ],
							[ 9.330496738000022, 11.52845981400003 ],
							[ 9.331618362000029, 11.533079980000025 ],
							[ 9.332753256000046, 11.536549795000042 ],
							[ 9.33507041200005, 11.538880612000071 ],
							[ 9.337388178000026, 11.541210284000044 ],
							[ 9.342022635000035, 11.545869586000038 ],
							[ 9.346693993000031, 11.547080016000052 ],
							[ 9.351376294000033, 11.547140025000033 ],
							[ 9.354866087000062, 11.549480344000074 ],
							[ 9.357183467000027, 11.551810447000037 ],
							[ 9.359512346000031, 11.552990191000049 ],
							[ 9.362975909000056, 11.557640195000033 ],
							[ 9.364122635000058, 11.559960102000048 ],
							[ 9.362553578000075, 11.564279386000067 ],
							[ 9.365196170000047, 11.569179327000029 ],
							[ 9.362829989000033, 11.571459740000023 ],
							[ 9.359975681000037, 11.575396634000072 ],
							[ 9.354598272000032, 11.574810248000063 ],
							[ 9.352244596000048, 11.575929493000046 ],
							[ 9.346378458000061, 11.577010212000062 ],
							[ 9.344036834000065, 11.576990356000067 ],
							[ 9.340512304000072, 11.57808970800005 ],
							[ 9.338158572000054, 11.579219602000023 ],
							[ 9.33581636200006, 11.579190087000029 ],
							[ 9.33114518900004, 11.577980096000033 ],
							[ 9.328804112000057, 11.577949406000073 ],
							[ 9.325278954000055, 11.579059351000069 ],
							[ 9.321754338000062, 11.580169818000059 ],
							[ 9.31587588900004, 11.582400413000073 ],
							[ 9.310022308000043, 11.582329642000047 ],
							[ 9.307681225000067, 11.58229994900006 ],
							[ 9.30533956000005, 11.582269667000048 ],
							[ 9.299448740000059, 11.585659970000052 ],
							[ 9.297106491000022, 11.585630210000033 ],
							[ 9.294741473000045, 11.587900003000073 ],
							[ 9.291216696000049, 11.58901019700005 ],
							[ 9.288851046000048, 11.59129069100004 ],
							[ 9.285326217000033, 11.592399700000044 ],
							[ 9.284130803000039, 11.594679817000042 ],
							[ 9.27706986000004, 11.59805030800004 ],
							[ 9.274704081000039, 11.600330154000062 ],
							[ 9.272337680000021, 11.602600376000055 ],
							[ 9.27113042700006, 11.606039859000077 ],
							[ 9.268765702000053, 11.608320221000042 ],
							[ 9.267569549000029, 11.610600301000034 ],
							[ 9.264020937000055, 11.614009797000051 ],
							[ 9.262815275000037, 11.617449823000072 ],
							[ 9.263960986000029, 11.619770030000041 ],
							[ 9.263936310000076, 11.622070165000025 ],
							[ 9.265070853000054, 11.625539591000063 ],
							[ 9.265046185000074, 11.62784029900007 ],
							[ 9.266193651000037, 11.63016049600003 ],
							[ 9.267327095000041, 11.633629916000075 ],
							[ 9.267278930000032, 11.638230179000061 ],
							[ 9.269559943000047, 11.644020352000041 ],
							[ 9.270706336000046, 11.646329796000032 ],
							[ 9.271851607000031, 11.648649974000023 ],
							[ 9.272987454000031, 11.652119370000037 ],
							[ 9.275279796000063, 11.656749541000067 ],
							[ 9.276427420000061, 11.659070276000023 ],
							[ 9.276389958000038, 11.662520184000073 ],
							[ 9.274037204000024, 11.663649663000058 ],
							[ 9.271695014000045, 11.663619744000073 ],
							[ 9.267024110000023, 11.662409526000033 ],
							[ 9.263524328000074, 11.661219879000043 ],
							[ 9.260022854000056, 11.660020017000022 ],
							[ 9.257681834000039, 11.659990582000034 ],
							[ 9.254182107000076, 11.658800273000054 ],
							[ 9.251851764000037, 11.657620451000071 ],
							[ 9.249522, 11.656440040000064 ],
							[ 9.243680783000059, 11.655220131000021 ],
							[ 9.241338651000035, 11.655190594000032 ],
							[ 9.237814762000028, 11.656299701000023 ],
							[ 9.234290268000052, 11.657409887000028 ],
							[ 9.231911382000021, 11.660829637000063 ],
							[ 9.231887652000069, 11.663140501000044 ],
							[ 9.230692819000069, 11.665430042000025 ],
							[ 9.230656648000036, 11.668880516000058 ],
							[ 9.229460642000049, 11.671170072000052 ],
							[ 9.229436889000056, 11.673469640000064 ],
							[ 9.228242007000063, 11.675760302000072 ],
							[ 9.228217119000021, 11.678059296000072 ],
							[ 9.228193357000066, 11.680359992000035 ],
							[ 9.224644816000023, 11.683770224000057 ],
							[ 9.223437419000049, 11.687210088000029 ],
							[ 9.221071280000046, 11.689490152000076 ],
							[ 9.219863829000076, 11.692930002000026 ],
							[ 9.218656337000027, 11.696369865000065 ],
							[ 9.217449968000039, 11.699810279000076 ],
							[ 9.219754963000071, 11.703289877000032 ],
							[ 9.222083767000072, 11.704469897000024 ],
							[ 9.224426218000076, 11.704500130000042 ],
							[ 9.226779976000046, 11.703369838000071 ],
							[ 9.230304945000057, 11.702269317000059 ],
							[ 9.233828725000023, 11.701160284000025 ],
							[ 9.236158162000038, 11.702340216000039 ],
							[ 9.236134450000065, 11.704640335000022 ],
							[ 9.237280828000053, 11.706959481000069 ],
							[ 9.238427215000058, 11.709269588000041 ],
							[ 9.240744870000071, 11.711600410000074 ],
							[ 9.241890736000073, 11.713919541000053 ],
							[ 9.243038327000022, 11.716239796000025 ],
							[ 9.245330691000049, 11.720870132000073 ],
							[ 9.246453532000032, 11.725489363000065 ],
							[ 9.249150061000023, 11.726076859000045 ],
							[ 9.24874599900005, 11.730119676000072 ],
							[ 9.249363211000059, 11.731368092000025 ],
							[ 9.251040241000055, 11.734760154000071 ],
							[ 9.251014911000027, 11.737059696000074 ],
							[ 9.250990728000033, 11.739359810000053 ],
							[ 9.249784522000027, 11.74279976300005 ],
							[ 9.248576003000039, 11.746239694000053 ],
							[ 9.245039991000056, 11.748499780000031 ],
							[ 9.242697836000048, 11.748470233000035 ],
							[ 9.240343827000061, 11.749589884000045 ],
							[ 9.236831442000039, 11.749549780000052 ],
							[ 9.234490427000026, 11.749520180000047 ],
							[ 9.230990471000041, 11.748330233000047 ],
							[ 9.228648318000069, 11.748299487000054 ],
							[ 9.226319191000073, 11.747120066000036 ],
							[ 9.222805687000061, 11.747079812000038 ],
							[ 9.218135013000051, 11.745869541000047 ],
							[ 9.215805936000038, 11.744690055000035 ],
							[ 9.213463808000029, 11.744660342000032 ],
							[ 9.209951474000036, 11.744620521000058 ],
							[ 9.207622431000061, 11.743439872000067 ],
							[ 9.202938744000051, 11.743389899000022 ],
							[ 9.199206359000073, 11.743344468000032 ],
							[ 9.194743333000076, 11.743289775000051 ],
							[ 9.192388627000071, 11.744409679000057 ],
							[ 9.190023640000049, 11.746680500000025 ],
							[ 9.186487258000056, 11.748940565000055 ],
							[ 9.180657365000059, 11.746569905000058 ],
							[ 9.178315229000077, 11.746539971000061 ],
							[ 9.17598627700005, 11.745360235000021 ],
							[ 9.171327838000025, 11.743000164000023 ],
							[ 9.167839093000055, 11.740659837000067 ],
							[ 9.16552172300004, 11.738329713000041 ],
							[ 9.163191721000032, 11.737150470000074 ],
							[ 9.160850793000066, 11.737119850000056 ],
							[ 9.150252222000063, 11.742749943000035 ],
							[ 9.146703496000043, 11.746159416000069 ],
							[ 9.146679211000048, 11.748460095000041 ],
							[ 9.146654926000053, 11.750760201000048 ],
							[ 9.147752924000031, 11.75769056300004 ],
							[ 9.147704928000053, 11.762290215000064 ],
							[ 9.147680085000047, 11.764590319000035 ],
							[ 9.146485547000054, 11.766880148000041 ],
							[ 9.145278255000051, 11.770319737000023 ],
							[ 9.142875765000042, 11.776049728000032 ],
							[ 9.141680577000045, 11.778340095000033 ],
							[ 9.140437301000077, 11.785229546000039 ],
							[ 9.140400822000061, 11.788689591000036 ],
							[ 9.140375912000025, 11.79098969000006 ],
							[ 9.14151094500005, 11.794460581000067 ],
							[ 9.142633268000054, 11.799080101000072 ],
							[ 9.143767198000035, 11.802539692000039 ],
							[ 9.144889575000036, 11.807160332000024 ],
							[ 9.148378236000042, 11.809510455000066 ],
							[ 9.151855400000045, 11.812999586000046 ],
							[ 9.155341871000076, 11.815349644000037 ],
							[ 9.157659670000044, 11.817680392000057 ],
							[ 9.161147946000028, 11.820019656000056 ],
							[ 9.165783149000049, 11.824690119000024 ],
							[ 9.17042938000003, 11.828200580000043 ],
							[ 9.171575725000025, 11.830510300000071 ],
							[ 9.175051592000045, 11.834009929000047 ],
							[ 9.177382258000023, 11.835189662000062 ],
							[ 9.18084503700004, 11.83984014400005 ],
							[ 9.184321662000059, 11.843330649000052 ],
							[ 9.188968877000036, 11.846839744000022 ],
							[ 9.191285948000029, 11.849169705000065 ],
							[ 9.193615056000056, 11.850350466000066 ],
							[ 9.197104452000076, 11.852700124000023 ],
							[ 9.198237348000021, 11.856169700000066 ],
							[ 9.199383959000045, 11.858480477000057 ],
							[ 9.200518625000029, 11.861950044000025 ],
							[ 9.201664710000045, 11.864270403000035 ],
							[ 9.205141190000063, 11.867759578000062 ],
							[ 9.207457360000035, 11.870100174000072 ],
							[ 9.210970914000029, 11.87014 ],
							[ 9.214470820000031, 11.871330144000069 ],
							[ 9.219118125000023, 11.874839967000071 ],
							[ 9.222618128000022, 11.876040199000045 ],
							[ 9.226081874000045, 11.880680063000057 ],
							[ 9.228399402000036, 11.883009805000029 ],
							[ 9.229546298000059, 11.885330076000059 ],
							[ 9.231851430000063, 11.88881010800003 ],
							[ 9.234156624000036, 11.89229014600005 ],
							[ 9.236461851000058, 11.895779757000071 ],
							[ 9.237607687000036, 11.898090393000075 ],
							[ 9.238755252000033, 11.90040950100007 ],
							[ 9.238719135000053, 11.90385991900007 ],
							[ 9.238681858000064, 11.90731994500004 ],
							[ 9.237426975000062, 11.915360544000066 ],
							[ 9.236231924000037, 11.917650081000033 ],
							[ 9.234999540000047, 11.923390583000071 ],
							[ 9.233804448000058, 11.92568009300004 ],
							[ 9.231426086000056, 11.929109383000025 ],
							[ 9.230232099000034, 11.931400583000027 ],
							[ 9.229036362000045, 11.933679357000074 ],
							[ 9.225475184000061, 11.938249452000036 ],
							[ 9.22426865400007, 11.941690403000052 ],
							[ 9.22187819100003, 11.946260328000051 ],
							[ 9.221841895000068, 11.949720342000035 ],
							[ 9.220646625000029, 11.951999641000043 ],
							[ 9.219390627000053, 11.96004976200004 ],
							[ 9.220513298000071, 11.964670123000076 ],
							[ 9.221647355000073, 11.96813959900004 ],
							[ 9.223965416000055, 11.970470484000032 ],
							[ 9.227440841000032, 11.973959981000064 ],
							[ 9.229758414000059, 11.976289700000052 ],
							[ 9.232087866000029, 11.977470210000035 ],
							[ 9.235576467000044, 11.979820043000075 ],
							[ 9.23906394200003, 11.982159698000032 ],
							[ 9.241394064000076, 11.983340129000055 ],
							[ 9.243723036000063, 11.98452001000004 ],
							[ 9.247211199000049, 11.986869732000059 ],
							[ 9.249541360000023, 11.988050705000035 ],
							[ 9.251858583000057, 11.990380266000045 ],
							[ 9.254176418000043, 11.992709830000024 ],
							[ 9.254138710000063, 11.996159654000053 ],
							[ 9.251157976000059, 12.001310400000023 ],
							[ 9.240040743000066, 12.000599643000044 ],
							[ 9.236528209000028, 12.000560091000068 ],
							[ 9.233003789000065, 12.001669680000077 ],
							[ 9.229492384000025, 12.001620452000054 ],
							[ 9.225967369000045, 12.002730548000045 ],
							[ 9.223588990000053, 12.006159751000041 ],
							[ 9.221211142000072, 12.009579902000041 ],
							[ 9.220015678000038, 12.011870501000033 ],
							[ 9.21999186000005, 12.014169439000057 ],
							[ 9.218784441000025, 12.017609780000043 ],
							[ 9.217589498000052, 12.019900370000073 ],
							[ 9.217565646000025, 12.022199862000036 ],
							[ 9.216370699000038, 12.024490447000062 ],
							[ 9.216345693000051, 12.02678938400004 ],
							[ 9.217468551000024, 12.031410306000055 ],
							[ 9.217443557000024, 12.033719961000031 ],
							[ 9.217419704000065, 12.036020043000065 ],
							[ 9.217383368000071, 12.039469852000025 ],
							[ 9.217358367000031, 12.041769916000021 ],
							[ 9.218481285000053, 12.046389700000077 ],
							[ 9.219579813000053, 12.053319706000025 ],
							[ 9.220726041000034, 12.055629812000063 ],
							[ 9.222994751000044, 12.062569844000052 ],
							[ 9.222933487000034, 12.068329870000071 ],
							[ 9.222897765000027, 12.071780244000024 ],
							[ 9.222861459000057, 12.075229489000037 ],
							[ 9.221666370000037, 12.077520637000077 ],
							[ 9.221641394000073, 12.079819566000026 ],
							[ 9.222395860000063, 12.084775711000077 ],
							[ 9.220374191000076, 12.089019930000063 ],
							[ 9.220349224000074, 12.091319987000077 ],
							[ 9.219154611000022, 12.093609994000076 ],
							[ 9.21913134700003, 12.095910049000054 ],
							[ 9.219081949000042, 12.10052033900007 ],
							[ 9.217886733000057, 12.102809787000069 ],
							[ 9.217862314000058, 12.105110395000054 ],
							[ 9.216667075000032, 12.107399838000049 ],
							[ 9.215459894000048, 12.110839571000042 ],
							[ 9.214240761000042, 12.115429630000051 ],
							[ 9.213033531000065, 12.118869927000048 ],
							[ 9.206009017000042, 12.118780096000023 ],
							[ 9.202483931000074, 12.119889959000034 ],
							[ 9.196629681000047, 12.11981964000006 ],
							[ 9.191972024000052, 12.117460461000064 ],
							[ 9.189629771000057, 12.117429449000042 ],
							[ 9.186129835000031, 12.11623968300006 ],
							[ 9.18379963700005, 12.115060027000027 ],
							[ 9.172164869000028, 12.108010119000028 ],
							[ 9.169846259000053, 12.105680638000024 ],
							[ 9.167517893000024, 12.104499748000023 ],
							[ 9.165187813000045, 12.103319974000044 ],
							[ 9.164053875000036, 12.09984980400003 ],
							[ 9.162907870000026, 12.097540085000048 ],
							[ 9.160601501000031, 12.09405064200007 ],
							[ 9.159455518000073, 12.091739784000026 ],
							[ 9.158309, 12.089420461000032 ],
							[ 9.153710338000053, 12.081299645000058 ],
							[ 9.152563875000055, 12.078989892000038 ],
							[ 9.152588145000038, 12.076689840000029 ],
							[ 9.151441700000021, 12.07437049300006 ],
							[ 9.150295270000072, 12.072050013000023 ],
							[ 9.150320136000062, 12.069749959000035 ],
							[ 9.148026212000048, 12.065119707000065 ],
							[ 9.148050486000045, 12.062810044000059 ],
							[ 9.148088652000069, 12.059359692000044 ],
							[ 9.146964885000045, 12.054739700000027 ],
							[ 9.146988607000026, 12.052439643000071 ],
							[ 9.147013474000062, 12.050140142000032 ],
							[ 9.145878732000028, 12.046669903000065 ],
							[ 9.14590362000007, 12.044360237000035 ],
							[ 9.147110098000041, 12.040929749000043 ],
							[ 9.145963803000029, 12.038610379000033 ],
							[ 9.145988676000059, 12.036310301000071 ],
							[ 9.146012417000065, 12.034000652000032 ],
							[ 9.145992454000066, 12.030041809000068 ],
							[ 9.143779549000044, 12.023610315000042 ],
							[ 9.142633352000075, 12.021300541000073 ],
							[ 9.139157140000066, 12.017800081000075 ],
							[ 9.135657210000034, 12.016610387000071 ],
							[ 9.125131364000026, 12.015330431000052 ],
							[ 9.120449343000075, 12.015270401000066 ],
							[ 9.118094365000047, 12.016400550000071 ],
							[ 9.115753338000047, 12.016369646000044 ],
							[ 9.113400647000049, 12.017490175000034 ],
							[ 9.111058467000021, 12.017459816000041 ],
							[ 9.108716288000039, 12.017430554000043 ],
							[ 9.106362424000054, 12.018560646000026 ],
							[ 9.104008524000051, 12.019679985000039 ],
							[ 9.101655191000077, 12.020799865000072 ],
							[ 9.096947903000057, 12.02305031700007 ],
							[ 9.093398774000036, 12.026459786000032 ],
							[ 9.091033045000074, 12.028730488000065 ],
							[ 9.088666705000037, 12.031009634000043 ],
							[ 9.087472038000044, 12.033300359000066 ],
							[ 9.085094452000021, 12.036720206000041 ],
							[ 9.083898597000029, 12.039009789000033 ],
							[ 9.08270387400006, 12.041299941000034 ],
							[ 9.082679744000075, 12.043599996000069 ],
							[ 9.080301463000069, 12.04703052900004 ],
							[ 9.080277317000025, 12.049330008000027 ],
							[ 9.080252588000064, 12.051630060000036 ],
							[ 9.079021286000057, 12.057370007000031 ],
							[ 9.074277948000031, 12.063070528000026 ],
							[ 9.074253201000033, 12.065380186000027 ],
							[ 9.074216621000062, 12.068829418000064 ],
							[ 9.073021737000033, 12.071120090000022 ],
							[ 9.071814456000027, 12.074559939000039 ],
							[ 9.070607152000036, 12.078000339000027 ],
							[ 9.068229674000065, 12.081420067000067 ],
							[ 9.065875830000039, 12.082549880000045 ],
							[ 9.058811126000023, 12.084670322000022 ],
							[ 9.055071548000058, 12.083029058000022 ],
							[ 9.053071264000039, 12.082150225000021 ],
							[ 9.050655287000041, 12.082360170000072 ],
							[ 9.048325732000023, 12.081179681000037 ],
							[ 9.046256096000036, 12.080413824000061 ],
							[ 9.043839428000069, 12.079520311000067 ],
							[ 9.041300867000075, 12.081100290000052 ],
							[ 9.03897076800007, 12.079919724000035 ],
							[ 9.036629919000063, 12.079890025000054 ],
							[ 9.034288488000072, 12.079859738000039 ],
							[ 9.031945929000074, 12.07983001100007 ],
							[ 9.028446314000064, 12.078639869000028 ],
							[ 9.024933608000026, 12.078600003000076 ],
							[ 9.020262765000041, 12.077389532000041 ],
							[ 9.015591354000037, 12.076180706000059 ],
							[ 9.010920575000057, 12.07497010000003 ],
							[ 9.007407906000026, 12.074930087000041 ],
							[ 9.000358495000057, 12.077150540000048 ],
							[ 8.996846388000051, 12.077110409000056 ],
							[ 8.994492349000041, 12.078230179000059 ],
							[ 8.992138859000022, 12.079350490000024 ],
							[ 8.989772710000068, 12.081629573000043 ],
							[ 8.986236377000068, 12.083889956000064 ],
							[ 8.985041468000077, 12.086170179000021 ],
							[ 8.981491807000054, 12.089589857000021 ],
							[ 8.979150900000036, 12.089559779000069 ],
							[ 8.976822115000061, 12.088379972000041 ],
							[ 8.974479487000053, 12.088350438000077 ],
							[ 8.970979979000049, 12.087160329000028 ],
							[ 8.968638506000048, 12.08712962900006 ],
							[ 8.966510571000072, 12.085522634000029 ],
							[ 8.963990921000061, 12.08362009800004 ],
							[ 8.959356152000055, 12.078950038000073 ],
							[ 8.957051288000059, 12.075470066000037 ],
							[ 8.954721501000051, 12.074290101000031 ],
							[ 8.950062575000061, 12.071930125000051 ],
							[ 8.948916318000045, 12.069620336000071 ],
							[ 8.946574378000037, 12.069589498000028 ],
							[ 8.945367042000044, 12.073030621000044 ],
							[ 8.946464431000038, 12.079950126000028 ],
							[ 8.947599653000054, 12.083419835000029 ],
							[ 8.948722122000049, 12.088040420000027 ],
							[ 8.947503094000069, 12.092630167000038 ],
							[ 8.947454248000042, 12.097230240000044 ],
							[ 8.94740596500003, 12.101839918000053 ],
							[ 8.946198530000061, 12.105279926000037 ],
							[ 8.943820276000054, 12.108699964000039 ],
							[ 8.942624997000053, 12.110989654000036 ],
							[ 8.941417486000034, 12.114430200000072 ],
							[ 8.942539971000031, 12.11905021900003 ],
							[ 8.94368577900002, 12.121370202000037 ],
							[ 8.945992537000052, 12.124850264000031 ],
							[ 8.948284813000043, 12.129480594000029 ],
							[ 8.949431273000073, 12.131799447000049 ],
							[ 8.950566104000075, 12.135270264000042 ],
							[ 8.950530065000066, 12.138719466000055 ],
							[ 8.950505085000032, 12.141020050000066 ],
							[ 8.951639956000065, 12.144490306000023 ],
							[ 8.953957406000029, 12.146820012000035 ],
							[ 8.957457435000038, 12.148019883000075 ],
							[ 8.960969664000061, 12.148059814000021 ],
							[ 8.963310949000061, 12.14809056100006 ],
							[ 8.965641222000045, 12.149269911000033 ],
							[ 8.967958229000033, 12.151600084000052 ],
							[ 8.967934507000052, 12.153900113000077 ],
							[ 8.967885894000062, 12.158510349000039 ],
							[ 8.96667846500003, 12.16194983500003 ],
							[ 8.965458851000051, 12.166540172000055 ],
							[ 8.964264120000053, 12.168820315000062 ],
							[ 8.963056621000021, 12.172260342000072 ],
							[ 8.962984189000053, 12.179169466000076 ],
							[ 8.961764470000048, 12.183759785000063 ],
							[ 8.958192135000047, 12.189479813000048 ],
							[ 8.956996698000069, 12.191759928000067 ],
							[ 8.954605779000076, 12.196339345000069 ],
							[ 8.951057623000054, 12.199749672000053 ],
							[ 8.947508817000028, 12.20316051900005 ],
							[ 8.945154947000049, 12.20429013100005 ],
							[ 8.941607188000035, 12.207699792000028 ],
							[ 8.938069317000043, 12.20996025200003 ],
							[ 8.935690861000069, 12.213379656000029 ],
							[ 8.933325787000058, 12.215660061000051 ],
							[ 8.930959521000034, 12.217929695000066 ],
							[ 8.927422630000024, 12.220190071000047 ],
							[ 8.925045150000074, 12.223620698000047 ],
							[ 8.923944436000056, 12.224144211000066 ],
							[ 8.913561296000069, 12.237490640000033 ],
							[ 8.911608792000038, 12.245650694000062 ],
							[ 8.910632393000071, 12.249010924000061 ],
							[ 8.905999205000057, 12.252610380000021 ],
							[ 8.904779528000063, 12.255490303000045 ],
							[ 8.902584833000049, 12.258369986000048 ],
							[ 8.898683040000037, 12.26028928900007 ],
							[ 8.896731382000041, 12.263888773000076 ],
							[ 8.895511333000059, 12.267729140000029 ],
							[ 8.889650877000065, 12.272796388000074 ],
							[ 8.89052126100006, 12.277319835000071 ],
							[ 8.890472228000021, 12.281920412000034 ],
							[ 8.891607548000025, 12.285389687000077 ],
							[ 8.892753556000059, 12.287710353000023 ],
							[ 8.897352418000025, 12.295820167000045 ],
							[ 8.899669927000048, 12.298150185000054 ],
							[ 8.903133568000044, 12.302800266000077 ],
							[ 8.905438219000075, 12.306279959000051 ],
							[ 8.906573194000032, 12.309749736000072 ],
							[ 8.907707026000026, 12.313220084000022 ],
							[ 8.907671123000057, 12.316670367000029 ],
							[ 8.905329894000033, 12.316639843000075 ],
							[ 8.901817197000071, 12.316599945000064 ],
							[ 8.897145961000035, 12.315389634000041 ],
							[ 8.894792348000067, 12.316519492000054 ],
							[ 8.891280219000066, 12.316469905000076 ],
							[ 8.881949153000051, 12.312909987000069 ],
							[ 8.879607933000045, 12.312879302000056 ],
							[ 8.87146099000006, 12.308170342000039 ],
							[ 8.869142472000021, 12.305839594000076 ],
							[ 8.868009595000046, 12.302379847000054 ],
							[ 8.866862552000043, 12.300060234000057 ],
							[ 8.865764874000035, 12.293140605000076 ],
							[ 8.864642266000033, 12.28851983900006 ],
							[ 8.863544665000063, 12.281600215000026 ],
							[ 8.85188458600004, 12.276849967000032 ],
							[ 8.849518570000043, 12.279120220000038 ],
							[ 8.847141107000027, 12.282549761000041 ],
							[ 8.844726532000038, 12.289429587000029 ],
							[ 8.843525181000075, 12.292351685000028 ],
							[ 8.843482704000053, 12.296320053000045 ],
							[ 8.843434211000044, 12.300930208000068 ],
							[ 8.842226848000053, 12.304369829000052 ],
							[ 8.841008027000044, 12.308959711000057 ],
							[ 8.837458836000053, 12.312370056000077 ],
							[ 8.836263372000076, 12.314659927000037 ],
							[ 8.824544127000024, 12.315669841000044 ],
							[ 8.818689066000047, 12.315600369000038 ],
							[ 8.815006496000024, 12.314420017000032 ],
							[ 8.809347227000046, 12.313179880000064 ],
							[ 8.807006011000055, 12.313150423000025 ],
							[ 8.801212149000037, 12.307330410000077 ],
							[ 8.798919470000044, 12.302689625000028 ],
							[ 8.798944280000057, 12.300390188000051 ],
							[ 8.797833494000031, 12.294619512000054 ],
							[ 8.796698464000031, 12.291149953000058 ],
							[ 8.794381102000045, 12.288820422000072 ],
							[ 8.790881408000075, 12.287630340000021 ],
							[ 8.787381142000072, 12.286430599000028 ],
							[ 8.782710116000032, 12.28521999700007 ],
							[ 8.779221525000025, 12.282880075000037 ],
							[ 8.775770128000033, 12.277079678000064 ],
							[ 8.773452991000056, 12.274750014000062 ],
							[ 8.771159160000025, 12.270119783000041 ],
							[ 8.770025046000058, 12.266650138000045 ],
							[ 8.767720226000051, 12.263170150000064 ],
							[ 8.765426547000061, 12.258529699000064 ],
							[ 8.761962786000026, 12.253890186000035 ],
							[ 8.760816580000039, 12.251569665000034 ],
							[ 8.757364067000026, 12.245770213000071 ],
							[ 8.756230164000044, 12.24229994500007 ],
							[ 8.753925068000058, 12.238819885000055 ],
							[ 8.750449383000046, 12.235320365000064 ],
							[ 8.748143241000037, 12.231840248000026 ],
							[ 8.745826574000034, 12.229510403000063 ],
							[ 8.744679383000062, 12.227189828000064 ],
							[ 8.742386805000024, 12.222559959000023 ],
							[ 8.740081950000047, 12.219079790000023 ],
							[ 8.73024575200003, 12.214969363000023 ],
							[ 8.727456154000038, 12.214614229000063 ],
							[ 8.724909826000044, 12.214289744000041 ],
							[ 8.721372826000049, 12.216549848000056 ],
							[ 8.72133631500003, 12.220000137000056 ],
							[ 8.721287983000025, 12.224610302000031 ],
							[ 8.722433858000045, 12.226920230000076 ],
							[ 8.722386141000072, 12.23153039400006 ],
							[ 8.723532601000045, 12.233849353000039 ],
							[ 8.724799911000048, 12.238014157000066 ],
							[ 8.725776777000021, 12.243079886000032 ],
							[ 8.726899176000074, 12.247699958000055 ],
							[ 8.726851495000062, 12.252309561000061 ],
							[ 8.727996921000056, 12.254630185000053 ],
							[ 8.726489031000028, 12.258837017000076 ],
							[ 8.729070609000075, 12.263850237000042 ],
							[ 8.729884401000049, 12.26611525900006 ],
							[ 8.729993795000041, 12.268698951000033 ],
							[ 8.731103216000065, 12.271685658000024 ],
							[ 8.731230453000023, 12.281149638000045 ],
							[ 8.732364802000063, 12.284620527000072 ],
							[ 8.732328391000067, 12.288069671000073 ],
							[ 8.733451014000025, 12.292690265000033 ],
							[ 8.73458600500004, 12.296160016000044 ],
							[ 8.735707554000044, 12.300780025000051 ],
							[ 8.735909692000064, 12.301398263000067 ],
							[ 8.736842015000036, 12.304240177000054 ],
							[ 8.737918252000043, 12.308888395000054 ],
							[ 8.738183121000077, 12.309301570000059 ],
							[ 8.739111008000066, 12.311180203000049 ],
							[ 8.740233226000043, 12.315799638000044 ],
							[ 8.742550976000075, 12.318129503000023 ],
							[ 8.749491005000039, 12.326269842000045 ],
							[ 8.75180831800003, 12.328599646000043 ],
							[ 8.758772107000027, 12.334450004000075 ],
							[ 8.759088317000021, 12.335415180000041 ],
							[ 8.761063706000073, 12.335902328000032 ],
							[ 8.765707634000023, 12.339970808000032 ],
							[ 8.774996438000073, 12.347091231000036 ],
							[ 8.781190897000045, 12.349635609000075 ],
							[ 8.782222647000026, 12.351161054000045 ],
							[ 8.785313411000061, 12.363358881000067 ],
							[ 8.787635975000057, 12.37219693000003 ],
							[ 8.788800295000044, 12.373959730000024 ],
							[ 8.791106008000042, 12.377440687000046 ],
							[ 8.793422772000042, 12.379770223000037 ],
							[ 8.798033860000032, 12.386730360000058 ],
							[ 8.801509734000035, 12.390229952000027 ],
							[ 8.806144737000068, 12.394890006000026 ],
							[ 8.810803471000042, 12.397250023000026 ],
							[ 8.811949880000043, 12.399570365000045 ],
							[ 8.815218837000032, 12.402947702000063 ],
							[ 8.816571929000077, 12.405379986000071 ],
							[ 8.81648743900007, 12.413439795000045 ],
							[ 8.815292090000071, 12.415730711000037 ],
							[ 8.811755976000029, 12.417989956000042 ],
							[ 8.805877925000061, 12.420220259000075 ],
							[ 8.802352686000063, 12.421330251000029 ],
							[ 8.797645773000056, 12.423569521000047 ],
							[ 8.794121039000061, 12.424680009000042 ],
							[ 8.791780267000036, 12.424650456000052 ],
							[ 8.788254922000021, 12.425760333000028 ],
							[ 8.78534770300007, 12.426295393000032 ],
							[ 8.782388788000048, 12.426839990000076 ],
							[ 8.772961714000076, 12.432490266000059 ],
							[ 8.767954767000049, 12.436479794000036 ],
							[ 8.764380305000032, 12.438699574000054 ],
							[ 8.762322529000073, 12.438633860000039 ],
							[ 8.759724474000052, 12.438550272000043 ],
							[ 8.755836573000067, 12.439705926000045 ],
							[ 8.752657891000069, 12.440650150000067 ],
							[ 8.750594933000059, 12.440582109000047 ],
							[ 8.747111520000033, 12.440466757000024 ],
							[ 8.743626377000055, 12.440352519000044 ],
							[ 8.739075747000072, 12.441601882000043 ],
							[ 8.732911314000035, 12.44329467700004 ],
							[ 8.728324660000055, 12.445759695000049 ],
							[ 8.723629901000038, 12.446860198000024 ],
							[ 8.721287844000074, 12.446829628000046 ],
							[ 8.718933975000027, 12.447950459000026 ],
							[ 8.715398088000029, 12.450209340000072 ],
							[ 8.711872841000059, 12.451319603000059 ],
							[ 8.709519490000048, 12.452449964000039 ],
							[ 8.707165548000035, 12.45357014700005 ],
							[ 8.702409601000056, 12.460419440000067 ],
							[ 8.694129150000037, 12.46837987400005 ],
							[ 8.690580441000066, 12.471790484000053 ],
							[ 8.68704299500007, 12.474049646000026 ],
							[ 8.684676915000068, 12.476329557000042 ],
							[ 8.683482240000046, 12.478609878000043 ],
							[ 8.681092264000029, 12.48318970300005 ],
							[ 8.681055905000051, 12.486639922000052 ],
							[ 8.681031469000061, 12.488950039000031 ],
							[ 8.679812285000025, 12.493539906000024 ],
							[ 8.678616347000059, 12.495829814000047 ],
							[ 8.67859248600007, 12.498129768000069 ],
							[ 8.678568607000045, 12.500430294000068 ],
							[ 8.678544163000026, 12.502730244000077 ],
							[ 8.678520273000061, 12.505040359000077 ],
							[ 8.679654264000021, 12.508500059000028 ],
							[ 8.680788834000055, 12.511969345000068 ],
							[ 8.681935371000066, 12.514290068000037 ],
							[ 8.681911512000056, 12.516590017000055 ],
							[ 8.683058054000071, 12.518910181000024 ],
							[ 8.684204055000066, 12.521220158000062 ],
							[ 8.68652161600005, 12.523549779000064 ],
							[ 8.687667645000033, 12.525870482000073 ],
							[ 8.691156274000036, 12.52822025200004 ],
							[ 8.694644957000037, 12.530559826000058 ],
							[ 8.696962644000052, 12.532890492000035 ],
							[ 8.699278651000043, 12.535220013000071 ],
							[ 8.70160885100006, 12.536399852000045 ],
							[ 8.70508466800004, 12.539899731000048 ],
							[ 8.708561117000045, 12.543399596000029 ],
							[ 8.712024678000034, 12.548040652000054 ],
							[ 8.714317215000051, 12.552680184000053 ],
							[ 8.716610969000044, 12.557310114000074 ],
							[ 8.716586198000073, 12.559610630000066 ],
							[ 8.717720719000056, 12.563079791000064 ],
							[ 8.719115976000069, 12.566590407000035 ],
							[ 8.716465140000025, 12.571120526000072 ],
							[ 8.714099173000022, 12.573399462000054 ],
							[ 8.704696661000071, 12.576740087000076 ],
							[ 8.702343041000063, 12.577859674000024 ],
							[ 8.700000716000034, 12.577830100000028 ],
							[ 8.697647065000069, 12.578960376000055 ],
							[ 8.695293409000044, 12.58007991900007 ],
							[ 8.690586044000042, 12.582329659000038 ],
							[ 8.687049807000051, 12.584589952000044 ],
							[ 8.684707421000041, 12.584560300000021 ],
							[ 8.682366202000026, 12.584530061000066 ],
							[ 8.670621845000028, 12.587840125000071 ],
							[ 8.667096826000034, 12.588949971000034 ],
							[ 8.663584943000046, 12.588910087000045 ],
							[ 8.661243678000062, 12.588879733000056 ],
							[ 8.657731213000034, 12.588839793000034 ],
							[ 8.648327773000062, 12.592180121000069 ],
							[ 8.648220847000061, 12.595221779000042 ],
							[ 8.637760036000032, 12.591873957000075 ],
							[ 8.627826867000067, 12.588951789000077 ],
							[ 8.619551367000042, 12.588951907000023 ],
							[ 8.615692988000035, 12.591190274000041 ],
							[ 8.60746172100005, 12.59325001600007 ],
							[ 8.597985329000039, 12.597599713000022 ],
							[ 8.58401592000007, 12.59714991900006 ],
							[ 8.571250722000059, 12.595560330000069 ],
							[ 8.564615297000046, 12.595347550000042 ],
							[ 8.561287524000022, 12.596434023000029 ],
							[ 8.557703895000031, 12.597687941000061 ],
							[ 8.553354026000022, 12.598438122000061 ],
							[ 8.549771543000077, 12.599188588000061 ],
							[ 8.545679863000032, 12.597422067000025 ],
							[ 8.540823278000062, 12.594144980000067 ],
							[ 8.534705548000034, 12.590291697000055 ],
							[ 8.534220215000062, 12.590262840000037 ],
							[ 8.531958498000051, 12.590027245000044 ],
							[ 8.529078654000045, 12.590704955000035 ],
							[ 8.513886494000076, 12.589536874000032 ],
							[ 8.499277819000042, 12.586031141000035 ],
							[ 8.480580117000045, 12.585446230000059 ],
							[ 8.470646683000041, 12.587199445000067 ],
							[ 8.46739178200005, 12.588199859000042 ],
							[ 8.465718140000035, 12.586520102000065 ],
							[ 8.463541590000034, 12.584322017000034 ],
							[ 8.463401031000046, 12.584180082000046 ],
							[ 8.461083373000065, 12.581850229000054 ],
							[ 8.459936896000045, 12.579540129000065 ],
							[ 8.457607209000059, 12.578359355000032 ],
							[ 8.454143764000037, 12.573710313000049 ],
							[ 8.450667195000051, 12.570210337000049 ],
							[ 8.448349690000043, 12.567879828000059 ],
							[ 8.446032234000029, 12.56554987800007 ],
							[ 8.444886470000029, 12.563240285000063 ],
							[ 8.44256849900006, 12.560899575000064 ],
							[ 8.441446473000042, 12.556279878000055 ],
							[ 8.439141238000047, 12.552800207000075 ],
							[ 8.437994994000064, 12.550490607000029 ],
							[ 8.439563870000029, 12.545840482000074 ],
							[ 8.436027803000059, 12.543185472000062 ],
							[ 8.43524837800004, 12.540653248000069 ],
							[ 8.431115006000027, 12.536589388000039 ],
							[ 8.429981051000027, 12.533119902000067 ],
							[ 8.428834925000046, 12.530800090000071 ],
							[ 8.428283939000039, 12.524061489000076 ],
							[ 8.424247751000053, 12.521529869000062 ],
							[ 8.421942925000053, 12.518050063000032 ],
							[ 8.422386972000027, 12.513678497000058 ],
							[ 8.420388256000024, 12.510219499000073 ],
							[ 8.41621010700004, 12.506469502000073 ],
							[ 8.413904890000026, 12.502989659000036 ],
							[ 8.412758963000044, 12.500670367000055 ],
							[ 8.409282916000052, 12.49717054100006 ],
							[ 8.405806357000074, 12.493679733000022 ],
							[ 8.403502451000065, 12.490199825000047 ],
							[ 8.391890807000038, 12.480839881000065 ],
							[ 8.389573109000025, 12.478510118000031 ],
							[ 8.388425705000031, 12.476200351000045 ],
							[ 8.388463151000053, 12.472739993000062 ],
							[ 8.387315755000031, 12.470430222000061 ],
							[ 8.387340361000042, 12.468119546000025 ],
							[ 8.386205858000039, 12.464659514000061 ],
							[ 8.385095413000045, 12.458889928000076 ],
							[ 8.385131759000046, 12.455430120000074 ],
							[ 8.385169217000055, 12.451979365000057 ],
							[ 8.385204977000058, 12.448519554000029 ],
							[ 8.383086381000055, 12.443739802000039 ],
							[ 8.381777778000071, 12.44042014200005 ],
							[ 8.374728268000069, 12.44264055900004 ],
							[ 8.372350964000077, 12.446069865000027 ],
							[ 8.369972464000057, 12.449489563000043 ],
							[ 8.367594484000051, 12.452919965000035 ],
							[ 8.367569770000046, 12.455220480000037 ],
							[ 8.365204105000032, 12.457489889000044 ],
							[ 8.36282661000007, 12.460920259000034 ],
							[ 8.361631643000067, 12.463210289000074 ],
							[ 8.360424311000031, 12.466649997000047 ],
							[ 8.360398966000048, 12.468950489000065 ],
							[ 8.358021934000021, 12.472370108000064 ],
							[ 8.355643127000064, 12.475799298000027 ],
							[ 8.35092372400004, 12.479200110000022 ],
							[ 8.346204835000037, 12.482600306000052 ],
							[ 8.343851278000045, 12.483719876000066 ],
							[ 8.341496575000065, 12.484840577000057 ],
							[ 8.339131114000054, 12.487110372000075 ],
							[ 8.336777506000033, 12.488240079000036 ],
							[ 8.333241701000077, 12.49050040000003 ],
							[ 8.329715444000044, 12.49160931800003 ],
							[ 8.327361793000023, 12.492729930000053 ],
							[ 8.326167080000062, 12.495019824000053 ],
							[ 8.322617501000025, 12.498430312000039 ],
							[ 8.320239896000032, 12.501850237000042 ],
							[ 8.316703835000055, 12.50412000700004 ],
							[ 8.315496501000041, 12.507559559000072 ],
							[ 8.313106264000055, 12.512129688000073 ],
							[ 8.311886904000062, 12.516720034000059 ],
							[ 8.311826507000035, 12.522479748000023 ],
							[ 8.311790635000023, 12.525930481000046 ],
							[ 8.311765543000035, 12.528240014000062 ],
							[ 8.311741615000074, 12.530540514000052 ],
							[ 8.311693216000037, 12.535139809000043 ],
							[ 8.311668121000025, 12.537449896000055 ],
							[ 8.311632247000034, 12.540900070000077 ],
							[ 8.310412189000033, 12.545490400000062 ],
							[ 8.311534818000041, 12.550109925000072 ],
							[ 8.311498359000041, 12.553560095000023 ],
							[ 8.311474993000047, 12.555870201000062 ],
							[ 8.311449914000036, 12.558170119000067 ],
							[ 8.311426003000065, 12.560469468000065 ],
							[ 8.311390105000044, 12.563920191000022 ],
							[ 8.310401863000038, 12.567397975000063 ],
							[ 8.311315448000073, 12.570830127000022 ],
							[ 8.311267582000028, 12.575440128000025 ],
							[ 8.311231683000074, 12.578889738000044 ],
							[ 8.310024046000024, 12.582329808000054 ],
							[ 8.305280595000056, 12.588029935000066 ],
							[ 8.301756175000037, 12.589139715000044 ],
							[ 8.297048542000027, 12.591379984000071 ],
							[ 8.294683248000069, 12.593660218000025 ],
							[ 8.292304767000076, 12.597080496000046 ],
							[ 8.290033503000075, 12.599989680000022 ],
							[ 8.29200746600003, 12.604550424000024 ],
							[ 8.291990989000055, 12.611470054000051 ],
							[ 8.291969444000074, 12.620680002000029 ],
							[ 8.288421845000073, 12.632180363000032 ],
							[ 8.286055713000053, 12.640229918000045 ],
							[ 8.281357587000059, 12.641349672000047 ],
							[ 8.276662930000043, 12.641309503000059 ],
							[ 8.270801555000048, 12.637820184000077 ],
							[ 8.26728698200003, 12.635490175000029 ],
							[ 8.260248008000076, 12.634289844000023 ],
							[ 8.252026552000075, 12.636529875000065 ],
							[ 8.24967089200004, 12.639969707000034 ],
							[ 8.244957003000025, 12.648010301000056 ],
							[ 8.241435942000066, 12.647979988000031 ],
							[ 8.236741179000035, 12.647950053000045 ],
							[ 8.230100461000063, 12.645005379000054 ],
							[ 8.226188406000063, 12.643269620000069 ],
							[ 8.22267045500007, 12.642090002000032 ],
							[ 8.214460329000076, 12.639730451000048 ],
							[ 8.20624721300004, 12.638520421000067 ],
							[ 8.201544907000027, 12.64194985000006 ],
							[ 8.199191306000046, 12.644230052000069 ],
							[ 8.198008768000022, 12.647680555000022 ],
							[ 8.193304256000033, 12.65225956300003 ],
							[ 8.185082553000029, 12.654499818000033 ],
							[ 8.178038771000047, 12.655610299000045 ],
							[ 8.173338050000041, 12.657880076000026 ],
							[ 8.166290375000074, 12.660140032000072 ],
							[ 8.161589522000043, 12.662409660000037 ],
							[ 8.153371805000063, 12.66350009100006 ],
							[ 8.149850575000073, 12.663479650000056 ],
							[ 8.146409119000054, 12.663249740000026 ],
							[ 8.143693215000042, 12.667886911000039 ],
							[ 8.142413113000032, 12.670070933000034 ],
							[ 8.14509229500004, 12.677049819000047 ],
							[ 8.145032083000046, 12.682809999000028 ],
							[ 8.146166081000047, 12.686280318000058 ],
							[ 8.14730066900006, 12.689749506000055 ],
							[ 8.149580720000074, 12.695529710000073 ],
							[ 8.149544968000043, 12.698989995000034 ],
							[ 8.149988869000026, 12.701290396000047 ],
							[ 8.15065467900007, 12.704759629000023 ],
							[ 8.150605891000055, 12.70935997600003 ],
							[ 8.15174117600003, 12.712829700000043 ],
							[ 8.150855920000026, 12.716889166000044 ],
							[ 8.152664052000034, 12.719406210000045 ],
							[ 8.15908126100004, 12.731510270000058 ],
							[ 8.166095497000072, 12.743079959000056 ],
							[ 8.167126511000049, 12.754976273000068 ],
							[ 8.170755290000045, 12.758089925000036 ],
							[ 8.173488577000057, 12.760299888000077 ],
							[ 8.174598839000055, 12.766070553000077 ],
							[ 8.174180008000064, 12.770784171000059 ],
							[ 8.173548786000026, 12.77788255300004 ],
							[ 8.171875218000025, 12.781140321000066 ],
							[ 8.173037597000075, 12.785759717000076 ],
							[ 8.173032632000059, 12.787921791000031 ],
							[ 8.173026781000033, 12.79022339100004 ],
							[ 8.173015882000072, 12.794969561000073 ],
							[ 8.16792395300007, 12.804061889000025 ],
							[ 8.165944362000062, 12.807600292000075 ],
							[ 8.16475678300003, 12.813349988000027 ],
							[ 8.162396663000038, 12.819089696000049 ],
							[ 8.169419518000041, 12.827210222000076 ],
							[ 8.17058203800002, 12.831819428000074 ],
							[ 8.171089552000069, 12.833330111000066 ],
							[ 8.175240264000024, 12.834299740000063 ],
							[ 8.178812798000024, 12.835995139000033 ],
							[ 8.181814151000026, 12.837126569000077 ],
							[ 8.185387383000034, 12.838539984000022 ],
							[ 8.187178779000021, 12.839178350000054 ],
							[ 8.190497239000024, 12.838114984000072 ],
							[ 8.199592356000039, 12.838910294000073 ],
							[ 8.206617463000043, 12.838999900000033 ],
							[ 8.214825731000076, 12.837940475000039 ],
							[ 8.219933591000029, 12.837347271000056 ],
							[ 8.222870011000055, 12.83693308200003 ],
							[ 8.224731888000065, 12.836093482000024 ],
							[ 8.229026356000077, 12.834696229000031 ],
							[ 8.230027963000055, 12.834557623000023 ],
							[ 8.232030255000041, 12.83470243000005 ],
							[ 8.233458829000028, 12.835689524000031 ],
							[ 8.235171905000072, 12.837239879000037 ],
							[ 8.237458983000067, 12.838088025000047 ],
							[ 8.238567772000067, 12.838090292000061 ],
							[ 8.239461595000023, 12.838092118000077 ],
							[ 8.241321671000037, 12.837814576000028 ],
							[ 8.244757046000075, 12.836837399000046 ],
							[ 8.245572243000026, 12.837048092000032 ],
							[ 8.24909524800006, 12.837313379000022 ],
							[ 8.254632917000038, 12.838430682000023 ],
							[ 8.259314604000053, 12.83848011200007 ],
							[ 8.263275960000044, 12.83942974100006 ],
							[ 8.274499988000059, 12.842120033000072 ],
							[ 8.280342250000047, 12.843339789000026 ],
							[ 8.284683207000057, 12.842206934000046 ],
							[ 8.293803142000058, 12.839589964000027 ],
							[ 8.298336720000066, 12.840735202000076 ],
							[ 8.303171082000063, 12.841817117000062 ],
							[ 8.307291011000075, 12.841986372000065 ],
							[ 8.313750094000056, 12.842029384000057 ],
							[ 8.315740750000032, 12.841686179000021 ],
							[ 8.32316109900006, 12.840404290000038 ],
							[ 8.325337283000067, 12.841659595000067 ],
							[ 8.330176603000041, 12.844450152000036 ],
							[ 8.336050804000024, 12.842189552000036 ],
							[ 8.340101908000065, 12.839460123000038 ],
							[ 8.350153829000021, 12.834219781000058 ],
							[ 8.361901003000071, 12.829689948000066 ],
							[ 8.372470158000056, 12.827460512000073 ],
							[ 8.377170685000067, 12.825189397000031 ],
							[ 8.383039749000034, 12.825229648000061 ],
							[ 8.390081343000077, 12.825280225000029 ],
							[ 8.399010561000068, 12.827055680000058 ],
							[ 8.399477110000021, 12.826810126000055 ],
							[ 8.403708373000029, 12.824579147000065 ],
							[ 8.411452078000025, 12.822689256000046 ],
							[ 8.420164015000069, 12.819850473000031 ],
							[ 8.433715731000063, 12.816066910000075 ],
							[ 8.440487506000068, 12.816552830000035 ],
							[ 8.454985475000058, 12.826084163000075 ],
							[ 8.463205950000031, 12.828473411000061 ],
							[ 8.474811853000062, 12.831818630000043 ],
							[ 8.482548606000023, 12.834206768000058 ],
							[ 8.48699487500005, 12.836086862000059 ],
							[ 8.488647668000056, 12.836330367000073 ],
							[ 8.495636408000053, 12.834476106000068 ],
							[ 8.498859351000021, 12.833621166000057 ],
							[ 8.50301537200005, 12.833877573000052 ],
							[ 8.506997887000068, 12.835333655000056 ],
							[ 8.512128805000032, 12.833029753000062 ],
							[ 8.515644004000023, 12.835359689000029 ],
							[ 8.523862818000055, 12.834269740000025 ],
							[ 8.527384, 12.834289476000038 ],
							[ 8.535599537000053, 12.834349527000029 ],
							[ 8.536602266000045, 12.834138346000032 ],
							[ 8.546168312000077, 12.832119685000066 ],
							[ 8.55673695400003, 12.829890116000058 ],
							[ 8.562610651000057, 12.827619724000044 ],
							[ 8.572010973000033, 12.823079954000036 ],
							[ 8.576711893000038, 12.820810266000024 ],
							[ 8.581411634000062, 12.818530336000038 ],
							[ 8.58380710800003, 12.816209942000057 ],
							[ 8.590817543000071, 12.81169017600007 ],
							[ 8.593174662000024, 12.807100267000067 ],
							[ 8.595544940000025, 12.797900330000061 ],
							[ 8.595565635000071, 12.788680318000047 ],
							[ 8.600266560000023, 12.786410329000034 ],
							[ 8.600578849000044, 12.785179626000058 ],
							[ 8.599594553000031, 12.779826211000056 ],
							[ 8.597125282000036, 12.774958158000061 ],
							[ 8.595645737000041, 12.770577593000041 ],
							[ 8.59565010700004, 12.766685641000038 ],
							[ 8.599299663000068, 12.764896414000077 ],
							[ 8.605021426000064, 12.761090205000073 ],
							[ 8.609733314000039, 12.753060386000072 ],
							[ 8.607397158000026, 12.748440004000031 ],
							[ 8.607405524000058, 12.744979667000052 ],
							[ 8.607416293000028, 12.740369634000047 ],
							[ 8.612127220000048, 12.733490025000037 ],
							[ 8.616824928000028, 12.732370624000055 ],
							[ 8.623867051000047, 12.732420174000026 ],
							[ 8.635603915000047, 12.732500185000049 ],
							[ 8.647345353000048, 12.730279917000075 ],
							[ 8.650867246000075, 12.730310354000039 ],
							[ 8.65792285200007, 12.724590510000041 ],
							[ 8.661452558000065, 12.721159929000066 ],
							[ 8.670868951000045, 12.709709915000076 ],
							[ 8.677100962000054, 12.705171569000072 ],
							[ 8.680274919000055, 12.702860096000052 ],
							[ 8.682632482000031, 12.698269585000048 ],
							[ 8.68732026400005, 12.69854020300005 ],
							[ 8.687768979000055, 12.695251961000054 ],
							[ 8.694205099000044, 12.690878330000032 ],
							[ 8.697668356000065, 12.691854008000064 ],
							[ 8.699151028000074, 12.694287920000022 ],
							[ 8.698652879000065, 12.697693634000075 ],
							[ 8.701617881000061, 12.703047856000069 ],
							[ 8.710527330000048, 12.699649324000063 ],
							[ 8.714982090000035, 12.698192918000075 ],
							[ 8.723894486000063, 12.691387933000044 ],
							[ 8.72669516600007, 12.687607668000055 ],
							[ 8.727857321000045, 12.686039009000069 ],
							[ 8.731302086000028, 12.680290241000023 ],
							[ 8.732811580000032, 12.677771067000037 ],
							[ 8.738750389000074, 12.675828983000031 ],
							[ 8.742707618000054, 12.677778033000038 ],
							[ 8.752111307000064, 12.674378843000056 ],
							[ 8.756069253000021, 12.675354308000067 ],
							[ 8.767444252000075, 12.683146138000041 ],
							[ 8.776348016000043, 12.68801755100003 ],
							[ 8.785751945000072, 12.684130524000068 ],
							[ 8.792684177000069, 12.675862899000037 ],
							[ 8.797137490000068, 12.675865351000027 ],
							[ 8.808021330000031, 12.679763693000041 ],
							[ 8.811977652000053, 12.683171275000063 ],
							[ 8.81543791200005, 12.690471677000062 ],
							[ 8.818405971000061, 12.691932421000047 ],
							[ 8.824839353000073, 12.690962651000063 ],
							[ 8.82978664500007, 12.692911220000042 ],
							[ 8.836221765000062, 12.688048683000034 ],
							[ 8.847109963000037, 12.682214479000038 ],
							[ 8.855522045000043, 12.682217836000063 ],
							[ 8.863932459000068, 12.685140150000052 ],
							[ 8.871849040000029, 12.687575651000031 ],
							[ 8.88075400300005, 12.693417435000072 ],
							[ 8.902523717000065, 12.70315458500005 ],
							[ 8.913904722000041, 12.705103662000056 ],
							[ 8.92379986800006, 12.712890356000059 ],
							[ 8.93072647200006, 12.720189807000054 ],
							[ 8.936291511000036, 12.72742280600005 ],
							[ 8.938430046000065, 12.728840072000025 ],
							[ 8.943116177000036, 12.732330545000025 ],
							[ 8.946822100000077, 12.739492466000058 ],
							[ 8.947381234000034, 12.74339976300007 ],
							[ 8.947778489000029, 12.746179405000078 ],
							[ 8.952459471000054, 12.75197984700003 ],
							[ 8.954555620000065, 12.753851807000046 ],
							[ 8.956906339000057, 12.755949766000072 ],
							[ 8.957745484000043, 12.759941824000066 ],
							[ 8.95822507400004, 12.762225977000071 ],
							[ 8.960191775000055, 12.766113077000057 ],
							[ 8.959721321000075, 12.769369987000061 ],
							[ 8.961388399000043, 12.770520968000028 ],
							[ 8.961791789000074, 12.776229881000063 ],
							[ 8.964127510000026, 12.780859895000049 ],
							[ 8.965286042000059, 12.787779544000045 ],
							[ 8.96762543400007, 12.791250270000035 ],
							[ 8.967615263000027, 12.795860274000063 ],
							[ 8.966433265000035, 12.799309782000023 ],
							[ 8.967593152000063, 12.805080297000075 ],
							[ 8.969935656000075, 12.807399628000042 ],
							[ 8.970385453000063, 12.810230072000024 ],
							[ 8.977337888000022, 12.817230315000074 ],
							[ 8.980825486000072, 12.819569396000077 ],
							[ 8.986339325000074, 12.819029560000047 ],
							[ 8.991031766000049, 12.820210438000061 ],
							[ 8.995729425000036, 12.819089673000065 ],
							[ 8.999250501000063, 12.819120188000056 ],
							[ 9.002774432000024, 12.817990277000035 ],
							[ 9.006294914000023, 12.818019614000036 ],
							[ 9.009818811000059, 12.816889637000031 ],
							[ 9.01334270600006, 12.815759627000034 ],
							[ 9.018037997000022, 12.815789938000023 ],
							[ 9.022731557000043, 12.815830352000035 ],
							[ 9.026255401000071, 12.814700221000066 ],
							[ 9.032125507000046, 12.814740433000054 ],
							[ 9.037084566000033, 12.814490260000071 ],
							[ 9.041513186000032, 12.814800522000041 ],
							[ 9.045033219000061, 12.815979738000067 ],
							[ 9.047374560000037, 12.818300265000062 ],
							[ 9.049713208000071, 12.821769893000067 ],
							[ 9.049706227000058, 12.825229663000073 ],
							[ 9.048523770000031, 12.828679979000071 ],
							[ 9.046162707000065, 12.834420169000055 ],
							[ 9.045207744000038, 12.837206055000024 ],
							[ 9.061523452000074, 12.836696408000023 ],
							[ 9.073954756000035, 12.836872108000023 ],
							[ 9.081819311000061, 12.834775057000058 ],
							[ 9.094404890000021, 12.836085585000035 ],
							[ 9.113283266000053, 12.834774726000035 ],
							[ 9.137404557000025, 12.834250250000025 ],
							[ 9.142386285000043, 12.832676685000024 ],
							[ 9.14710522300004, 12.833725666000078 ],
							[ 9.160740001000022, 12.833725682000022 ],
							[ 9.180928492000021, 12.82769574200006 ],
							[ 9.207147618000022, 12.827957222000066 ],
							[ 9.225494536000042, 12.828307009000071 ],
							[ 9.230482107000057, 12.824025018000043 ],
							[ 9.246738470000025, 12.824548488000062 ],
							[ 9.260896392000063, 12.818780863000029 ],
							[ 9.272016256000029, 12.817630016000066 ],
							[ 9.287116032000029, 12.812488364000046 ],
							[ 9.297184010000024, 12.812291221000066 ],
							[ 9.312286132000054, 12.811177203000057 ],
							[ 9.333028737000063, 12.813054095000041 ],
							[ 9.340755754000043, 12.814437757000064 ],
							[ 9.354761010000061, 12.816945219000047 ],
							[ 9.374974933000033, 12.818392608000067 ],
							[ 9.381302903000062, 12.818659614000069 ],
							[ 9.389894711000068, 12.822451716000046 ],
							[ 9.415326957000048, 12.821402824000074 ],
							[ 9.429222871000036, 12.821927178000067 ],
							[ 9.436039970000024, 12.818780941000057 ],
							[ 9.462680543000033, 12.814578915000027 ],
							[ 9.494712981000021, 12.810003465000023 ],
							[ 9.53360765900004, 12.808478111000056 ],
							[ 9.549623282000027, 12.807715304000055 ],
							[ 9.56503843400003, 12.806719964000024 ],
							[ 9.57001979100005, 12.807768841000041 ],
							[ 9.574631854000074, 12.807180434000031 ],
							[ 9.579315046000033, 12.807230238000045 ],
							[ 9.582827465000037, 12.807279559000051 ],
							[ 9.596902014000023, 12.805139282000027 ],
							[ 9.601585201000034, 12.805199544000061 ],
							[ 9.606749742000034, 12.806113181000057 ],
							[ 9.616689631000042, 12.805147180000063 ],
							[ 9.628488736000065, 12.805147241000043 ],
							[ 9.647366566000073, 12.803573735000043 ],
							[ 9.653658627000027, 12.804360469000073 ],
							[ 9.655921952000028, 12.807168819000026 ],
							[ 9.658108450000043, 12.809121391000076 ],
							[ 9.665942986000061, 12.809430070000076 ],
							[ 9.670626825000056, 12.809490037000046 ],
							[ 9.678810318000046, 12.810740710000061 ],
							[ 9.682323350000047, 12.810779483000033 ],
							[ 9.68934712500004, 12.81086995000004 ],
							[ 9.691677177000031, 12.812049777000027 ],
							[ 9.696359868000059, 12.812100370000053 ],
							[ 9.700415011000075, 12.812148734000061 ],
							[ 9.702213992000054, 12.812170174000073 ],
							[ 9.711592684000038, 12.811139999000034 ],
							[ 9.716275968000048, 12.811189786000057 ],
							[ 9.723300891000065, 12.811280165000028 ],
							[ 9.726465483000027, 12.812100533000034 ],
							[ 9.73619195200007, 12.810279821000051 ],
							[ 9.740875231000075, 12.810340018000034 ],
							[ 9.744399094000073, 12.809229640000069 ],
							[ 9.747912688000042, 12.809270071000071 ],
							[ 9.752594808000026, 12.809330142000022 ],
							[ 9.758126700000048, 12.809395793000022 ],
							[ 9.758449462000044, 12.809399616000064 ],
							[ 9.764302992000069, 12.809470149000049 ],
							[ 9.770145158000048, 12.810689714000034 ],
							[ 9.773657620000051, 12.810729887000036 ],
							[ 9.780670108000038, 12.811970033000023 ],
							[ 9.786512391000031, 12.813190471000041 ],
							[ 9.788853479000068, 12.813219993000075 ],
							[ 9.79586723400007, 12.814459833000058 ],
							[ 9.799769413000035, 12.814500074000023 ],
							[ 9.812233653000021, 12.816960609000034 ],
							[ 9.815733849000026, 12.818149531000074 ],
							[ 9.820417246000034, 12.818209876000026 ],
							[ 9.82274721400006, 12.819390568000074 ],
							[ 9.827406021000058, 12.821749672000067 ],
							[ 9.830918598000039, 12.82178987900005 ],
							[ 9.833259748000046, 12.821820233000039 ],
							[ 9.846090274000062, 12.826579867000021 ],
							[ 9.848431454000036, 12.826609588000053 ],
							[ 9.850748747000068, 12.828940383000031 ],
							[ 9.856578759000058, 12.831309997000062 ],
							[ 9.858919972000024, 12.831339634000074 ],
							[ 9.864750078000043, 12.833709694000049 ],
							[ 9.869422093000026, 12.834919678000063 ],
							[ 9.874080847000073, 12.83728045600003 ],
							[ 9.881903474000069, 12.844362351000029 ],
							[ 9.885679227000026, 12.847780385000021 ],
							[ 9.888009014000033, 12.848960112000043 ],
							[ 9.892655402000059, 12.852470358000062 ],
							[ 9.89263181900003, 12.854779940000071 ],
							[ 9.894936351000069, 12.858260175000055 ],
							[ 9.897241511000061, 12.861740376000057 ],
							[ 9.900717729000064, 12.865240337000046 ],
							[ 9.903022970000052, 12.868719944000077 ],
							[ 9.906487270000071, 12.873370123000029 ],
							[ 9.908780020000052, 12.878000522000036 ],
							[ 9.909926403000043, 12.880310079000026 ],
							[ 9.911061363000044, 12.883779492000031 ],
							[ 9.912207788000046, 12.886099760000036 ],
							[ 9.914524745000051, 12.888429594000058 ],
							[ 9.916830287000039, 12.891909665000071 ],
							[ 9.917964204000043, 12.895380187000058 ],
							[ 9.920269825000048, 12.898860254000056 ],
							[ 9.920239031000051, 12.901818147000029 ],
							[ 9.925725235000073, 12.912977402000024 ],
							[ 9.930882188000055, 12.921991654000067 ],
							[ 9.933594483000036, 12.925967410000055 ],
							[ 9.936853464000023, 12.93233127700006 ],
							[ 9.939843044000042, 12.938961311000071 ],
							[ 9.944177538000076, 12.94319839700006 ],
							[ 9.949321380000072, 12.946902366000074 ],
							[ 9.95284048700006, 12.949282908000043 ],
							[ 9.957438416000059, 12.950864141000068 ],
							[ 9.964736447000064, 12.951641835000032 ],
							[ 9.969869890000041, 12.951363015000027 ],
							[ 9.974190515000032, 12.950289743000042 ],
							[ 9.975271368000051, 12.950286917000028 ],
							[ 9.977706213000033, 12.951342548000071 ],
							[ 9.981763838000063, 12.952924898000049 ],
							[ 9.986634345000027, 12.955301542000029 ],
							[ 9.99123908100006, 12.959271859000069 ],
							[ 9.993947192000064, 12.961388666000062 ],
							[ 10.008288497000024, 12.968253209000068 ],
							[ 10.014505313000029, 12.968767349000075 ],
							[ 10.019636706000028, 12.967691344000059 ],
							[ 10.021532090000051, 12.969013666000023 ],
							[ 10.036409347000074, 12.974017144000072 ],
							[ 10.051280814000052, 12.976896031000024 ],
							[ 10.064530312000045, 12.979513438000026 ],
							[ 10.070477954000069, 12.980292917000043 ],
							[ 10.077516898000056, 12.984520669000062 ],
							[ 10.084418104000065, 12.987819433000027 ],
							[ 10.107409399000062, 12.994389400000045 ],
							[ 10.117137600000035, 12.994094881000024 ],
							[ 10.129021105000049, 12.99166957500006 ],
							[ 10.134960714000044, 12.989793032000023 ],
							[ 10.147113374000071, 12.987100827000063 ],
							[ 10.150629108000032, 12.987886500000059 ],
							[ 10.156859249000036, 12.992380752000031 ],
							[ 10.166744428000072, 12.998854743000038 ],
							[ 10.177581496000073, 13.007051205000039 ],
							[ 10.184349357000031, 13.01048129000003 ],
							[ 10.194908110000029, 13.016023134000022 ],
							[ 10.200594341000055, 13.019190886000047 ],
							[ 10.205194232000053, 13.020769055000073 ],
							[ 10.208979800000066, 13.021287804000053 ],
							[ 10.212491530000023, 13.020745401000056 ],
							[ 10.217893341000035, 13.019665793000058 ],
							[ 10.222208635000072, 13.016996661000064 ],
							[ 10.226251789000059, 13.013797372000056 ],
							[ 10.228677810000022, 13.011930876000065 ],
							[ 10.230024591000074, 13.010598929000025 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Kaduna',
				admin1Pcod: 'NG019',
				admin1RefN: 'Kaduna',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 12.613678,
				Shape_Area: 3.645639
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.169531326000026, 11.487270111000043 ],
							[ 8.169548748000068, 11.47968774800006 ],
							[ 8.16794698700005, 11.472501218000048 ],
							[ 8.167155899000022, 11.464917035000042 ],
							[ 8.166750766000064, 11.460824898000055 ],
							[ 8.166670176000025, 11.460011666000071 ],
							[ 8.166514052000025, 11.458438331000025 ],
							[ 8.16636520000003, 11.456933959000025 ],
							[ 8.164856855000039, 11.451769773000024 ],
							[ 8.16044435200007, 11.444629406000047 ],
							[ 8.157154924000054, 11.438689923000027 ],
							[ 8.152702221000027, 11.432720076000066 ],
							[ 8.148289628000043, 11.425580128000036 ],
							[ 8.142672736000065, 11.419570177000026 ],
							[ 8.139464357000065, 11.411310210000067 ],
							[ 8.136093880000033, 11.407699321000052 ],
							[ 8.132845728000063, 11.400609892000034 ],
							[ 8.130802138000035, 11.392379681000023 ],
							[ 8.132210599000075, 11.385430529000075 ],
							[ 8.13598823500007, 11.377400341000055 ],
							[ 8.14205380900006, 11.370599814000059 ],
							[ 8.14803693600004, 11.366140390000055 ],
							[ 8.154021085000068, 11.361669577000043 ],
							[ 8.158799159000068, 11.358329918000038 ],
							[ 8.163578289000043, 11.354990201000021 ],
							[ 8.167070533000071, 11.355099754000037 ],
							[ 8.17546479400005, 11.348380152000061 ],
							[ 8.187391269000045, 11.340609291000021 ],
							[ 8.193455813000071, 11.333820331000027 ],
							[ 8.200807669000028, 11.323570507000056 ],
							[ 8.207996644000048, 11.317979843000046 ],
							[ 8.215225144000044, 11.311220124000045 ],
							[ 8.219962853000027, 11.309040122000056 ],
							[ 8.231524241000045, 11.311750326000038 ],
							[ 8.241920507000032, 11.314419912000062 ],
							[ 8.258177886000055, 11.316120132000037 ],
							[ 8.267613274000041, 11.312930148000021 ],
							[ 8.273596765000036, 11.308460227000069 ],
							[ 8.274441638000042, 11.30619357300003 ],
							[ 8.271068011000068, 11.302531539000029 ],
							[ 8.263835903000029, 11.286796564000042 ],
							[ 8.265810011000042, 11.277698997000073 ],
							[ 8.275006680000047, 11.274088273000075 ],
							[ 8.276063212000054, 11.271249798000042 ],
							[ 8.282047073000058, 11.266779731000042 ],
							[ 8.288030764000041, 11.262310121000041 ],
							[ 8.294096100000047, 11.255520045000026 ],
							[ 8.297831631000065, 11.248649758000056 ],
							[ 8.302815523000049, 11.239489523000032 ],
							[ 8.306551885000033, 11.232609536000041 ],
							[ 8.321091688000024, 11.216770190000034 ],
							[ 8.329404020000027, 11.212380434000067 ],
							[ 8.33755313000006, 11.212650463000045 ],
							[ 8.345621367000035, 11.21523955400005 ],
							[ 8.359346214000027, 11.222679925000023 ],
							[ 8.366413026000032, 11.220580022000036 ],
							[ 8.373397904000058, 11.220809985000074 ],
							[ 8.382589542000062, 11.224610437000024 ],
							[ 8.389492773000029, 11.227160490000074 ],
							[ 8.399929744000076, 11.228670282000053 ],
							[ 8.415022968000073, 11.230330676000051 ],
							[ 8.423335031000022, 11.225939951000043 ],
							[ 8.434015954000074, 11.220459916000038 ],
							[ 8.44124628000003, 11.213700282000048 ],
							[ 8.449720396000032, 11.204650072000049 ],
							[ 8.459400223000046, 11.194479871000055 ],
							[ 8.470081376000053, 11.188999919000025 ],
							[ 8.476390778000052, 11.175219603000073 ],
							[ 8.482618562000027, 11.163769774000059 ],
							[ 8.489766319000069, 11.159339760000023 ],
							[ 8.500406050000038, 11.155019969000023 ],
							[ 8.500945871000056, 11.154800335000061 ],
							[ 8.506288544000029, 11.152627082000038 ],
							[ 8.509972430000062, 11.153078334000043 ],
							[ 8.512828571000057, 11.152175809000028 ],
							[ 8.51686776400004, 11.150899626000069 ],
							[ 8.52052279000003, 11.146360473000072 ],
							[ 8.524300771000071, 11.138320040000053 ],
							[ 8.525234330000046, 11.131427429000041 ],
							[ 8.525872082000035, 11.126720489000036 ],
							[ 8.527361906000067, 11.117440235000061 ],
							[ 8.53716352400005, 11.103779677000034 ],
							[ 8.543351226000027, 11.09348983600006 ],
							[ 8.557524778000072, 11.088120669000034 ],
							[ 8.565796135000028, 11.084899644000075 ],
							[ 8.580050670000048, 11.077200124000058 ],
							[ 8.592017989000055, 11.068269745000066 ],
							[ 8.596919007000054, 11.061440607000065 ],
							[ 8.597122249000051, 11.05561996800003 ],
							[ 8.597814604000064, 11.035829647000071 ],
							[ 8.598262931000022, 11.023019930000032 ],
							[ 8.598587802000054, 11.013709810000023 ],
							[ 8.598995324000043, 11.002060013000062 ],
							[ 8.598157023000056, 10.992710204000048 ],
							[ 8.597114425000029, 10.989179367000077 ],
							[ 8.594908717000067, 10.985609969000052 ],
							[ 8.593906356000048, 10.980919716000074 ],
							[ 8.591822473000036, 10.973859695000044 ],
							[ 8.588533679000022, 10.967920468000045 ],
							[ 8.58657174800004, 10.957369809000056 ],
							[ 8.583363638000037, 10.949110067000049 ],
							[ 8.581279564000056, 10.942049972000063 ],
							[ 8.576827139000045, 10.936070320000056 ],
							[ 8.573578617000067, 10.928970407000065 ],
							[ 8.571494268000038, 10.921910247000028 ],
							[ 8.570492967000064, 10.917219931000034 ],
							[ 8.568489854000063, 10.907830231000048 ],
							[ 8.568979316000025, 10.893859903000077 ],
							[ 8.567220852000048, 10.877490101000035 ],
							[ 8.567423897000026, 10.871660373000054 ],
							[ 8.570036927000046, 10.863590273000057 ],
							[ 8.571526810000023, 10.854319848000046 ],
							[ 8.573179335000077, 10.840379637000069 ],
							[ 8.574587990000055, 10.833439845000044 ],
							[ 8.574913892000041, 10.82411946600007 ],
							[ 8.574237562000064, 10.810109873000044 ],
							[ 8.574523202000023, 10.801969782000072 ],
							[ 8.576094341000044, 10.790360176000036 ],
							[ 8.577665348000039, 10.778760164000062 ],
							[ 8.576745949000042, 10.77173970900003 ],
							[ 8.577030372000024, 10.763589986000056 ],
							[ 8.579644141000074, 10.755510156000071 ],
							[ 8.581378001000076, 10.739250440000035 ],
							[ 8.585277801000075, 10.727720228000067 ],
							[ 8.587931853000043, 10.718489378000072 ],
							[ 8.586776864000058, 10.714577486000053 ],
							[ 8.582972295000047, 10.714047174000029 ],
							[ 8.576564538000071, 10.712805876000061 ],
							[ 8.567609961000073, 10.711558097000022 ],
							[ 8.561164912000038, 10.709092050000038 ],
							[ 8.556031025000038, 10.707853606000072 ],
							[ 8.549698690000071, 10.709063608000065 ],
							[ 8.544640608000066, 10.710276773000032 ],
							[ 8.539582495000047, 10.711489868000058 ],
							[ 8.534523784000044, 10.712702895000064 ],
							[ 8.526841913000055, 10.711459115000025 ],
							[ 8.519010630000025, 10.705311614000038 ],
							[ 8.519595060000029, 10.702399061000051 ],
							[ 8.513689721000048, 10.69794679000006 ],
							[ 8.510712439000031, 10.695594562000053 ],
							[ 8.508171865000065, 10.695245965000026 ],
							[ 8.510313927000027, 10.671669845000054 ],
							[ 8.514254554000047, 10.658969597000066 ],
							[ 8.515582256000073, 10.654350474000069 ],
							[ 8.521645722000073, 10.647560387000055 ],
							[ 8.525383789000045, 10.64068981500003 ],
							[ 8.536105091000024, 10.634050639000066 ],
							[ 8.546705097000029, 10.63088994800006 ],
							[ 8.553771294000057, 10.62878979900006 ],
							[ 8.561961388000043, 10.62790003300006 ],
							[ 8.568939950000072, 10.629693176000046 ],
							[ 8.572357019000037, 10.630570127000055 ],
							[ 8.577014101000032, 10.630720115000031 ],
							[ 8.582617537000033, 10.632126645000028 ],
							[ 8.587440254000057, 10.633337830000073 ],
							[ 8.589407098000038, 10.634492696000052 ],
							[ 8.595937710000044, 10.638098614000057 ],
							[ 8.60122018900006, 10.644239502000062 ],
							[ 8.608976497000072, 10.647934657000064 ],
							[ 8.612835670000038, 10.649169912000048 ],
							[ 8.620442510000032, 10.647962395000036 ],
							[ 8.628048718000059, 10.646756406000065 ],
							[ 8.634344359000067, 10.644319452000047 ],
							[ 8.639402358000041, 10.643107336000071 ],
							[ 8.646971910000048, 10.640674793000073 ],
							[ 8.653228501000058, 10.637013610000054 ],
							[ 8.656901528000049, 10.632120974000031 ],
							[ 8.660497922000047, 10.624777099000028 ],
							[ 8.662708233000046, 10.613753514000052 ],
							[ 8.665069845000062, 10.607631777000051 ],
							[ 8.672376592000035, 10.596619473000032 ],
							[ 8.674773947000062, 10.591723258000059 ],
							[ 8.678334110000037, 10.58315360000006 ],
							[ 8.683167325000056, 10.574587810000025 ],
							[ 8.685603306000075, 10.570917111000028 ],
							[ 8.689275807000058, 10.566023579000046 ],
							[ 8.69429586800004, 10.563585097000043 ],
							[ 8.700554073000035, 10.559924229000046 ],
							[ 8.709359067000037, 10.556269779000047 ],
							[ 8.718164465000029, 10.552613991000044 ],
							[ 8.723261087000026, 10.552626869000051 ],
							[ 8.729155657000035, 10.550347334000037 ],
							[ 8.735888533000036, 10.548981402000038 ],
							[ 8.744806261000065, 10.549003486000061 ],
							[ 8.749901691000048, 10.549015997000026 ],
							[ 8.756309428000066, 10.550256588000025 ],
							[ 8.761517281000067, 10.55394573600006 ],
							[ 8.764178297000058, 10.557629809000048 ],
							[ 8.76814949200002, 10.562540819000048 ],
							[ 8.773358186000053, 10.566229798000052 ],
							[ 8.77940366200005, 10.562673690000054 ],
							[ 8.776804944000048, 10.553983521000021 ],
							[ 8.773957434000067, 10.544173243000046 ],
							[ 8.773807417000057, 10.539270291000037 ],
							[ 8.770848210000054, 10.525783758000046 ],
							[ 8.767962455000031, 10.514746700000046 ],
							[ 8.767460318000076, 10.512281665000046 ],
							[ 8.768928780000067, 10.503117602000032 ],
							[ 8.771401160000039, 10.492142608000051 ],
							[ 8.771408468000061, 10.480555848000051 ],
							[ 8.769562630000053, 10.476285807000068 ],
							[ 8.756005045000052, 10.47383781700006 ],
							[ 8.751692959000025, 10.471395664000056 ],
							[ 8.738682095000058, 10.468265410000072 ],
							[ 8.735888718000069, 10.465648393000038 ],
							[ 8.731879398000046, 10.459511965000047 ],
							[ 8.729219430000057, 10.455828767000071 ],
							[ 8.729069474000028, 10.450925770000026 ],
							[ 8.732628691000059, 10.442356070000073 ],
							[ 8.733715811000025, 10.436231761000045 ],
							[ 8.739860431000068, 10.428893943000048 ],
							[ 8.747429962000069, 10.426461021000023 ],
							[ 8.755073677000041, 10.426480266000056 ],
							[ 8.763954351000052, 10.425276850000046 ],
							[ 8.772834987000067, 10.424073203000034 ],
							[ 8.784225712000023, 10.421649760000037 ],
							[ 8.78924655700007, 10.419211656000073 ],
							[ 8.796814973000039, 10.41677885200005 ],
							[ 8.805507873000067, 10.409446974000048 ],
							[ 8.811652869000056, 10.402109064000058 ],
							[ 8.81656150200007, 10.395994346000066 ],
							[ 8.820233306000034, 10.391101148000075 ],
							[ 8.823521653000057, 10.383281917000033 ],
							[ 8.82039844600007, 10.383919494000054 ],
							[ 8.816470080000045, 10.385966413000062 ],
							[ 8.810783336000043, 10.383489793000024 ],
							[ 8.808949470000073, 10.381480110000041 ],
							[ 8.805015539000067, 10.383230268000034 ],
							[ 8.799246585000049, 10.382980243000077 ],
							[ 8.793389218000073, 10.384650132000047 ],
							[ 8.789364988000045, 10.388330271000029 ],
							[ 8.787257155000077, 10.389271001000054 ],
							[ 8.781496147000041, 10.391839736000065 ],
							[ 8.77756138500007, 10.393600340000035 ],
							[ 8.773364139000023, 10.395070290000035 ],
							[ 8.771792310000023, 10.393340297000066 ],
							[ 8.771970570000065, 10.389490177000027 ],
							[ 8.774160187000064, 10.383790206000072 ],
							[ 8.776262335000069, 10.380029958000023 ],
							[ 8.776483362000022, 10.37524075400006 ],
							[ 8.780104597000047, 10.369573804000026 ],
							[ 8.789968889000022, 10.357993236000027 ],
							[ 8.796133421000036, 10.352508379000028 ],
							[ 8.797372505000055, 10.339702246000058 ],
							[ 8.797380343000043, 10.325676625000028 ],
							[ 8.789999262000038, 10.305548280000039 ],
							[ 8.782615945000032, 10.291517787000032 ],
							[ 8.767836182000053, 10.284801104000053 ],
							[ 8.750592238000024, 10.279301554000028 ],
							[ 8.736428192000062, 10.275022786000022 ],
							[ 8.731057317000023, 10.272963822000065 ],
							[ 8.729244983000058, 10.271760080000035 ],
							[ 8.72557762100007, 10.267740423000021 ],
							[ 8.718064346000062, 10.263540402000046 ],
							[ 8.710462379000035, 10.26127998100003 ],
							[ 8.708628573000055, 10.259260175000065 ],
							[ 8.703038327000058, 10.255150082000057 ],
							[ 8.697536563000028, 10.249119908000068 ],
							[ 8.695792374000064, 10.245180079000022 ],
							[ 8.688279349000027, 10.240980002000072 ],
							[ 8.684611031000031, 10.236960504000024 ],
							[ 8.680854937000049, 10.234860389000062 ],
							[ 8.673520375000066, 10.22682007700007 ],
							[ 8.669763309000075, 10.224719829000037 ],
							[ 8.662338962000035, 10.218600393000031 ],
							[ 8.654916007000054, 10.212479678000022 ],
							[ 8.64950258600004, 10.204509598000072 ],
							[ 8.646013427000071, 10.196640175000027 ],
							[ 8.64829246100004, 10.189020335000066 ],
							[ 8.646726892000061, 10.181219965000025 ],
							[ 8.647260786000061, 10.169670219000068 ],
							[ 8.645694761000073, 10.161869835000061 ],
							[ 8.643950405000055, 10.157939904000045 ],
							[ 8.638448805000053, 10.151900352000041 ],
							[ 8.633036639000068, 10.143940168000029 ],
							[ 8.629547310000021, 10.136059819000025 ],
							[ 8.628158596000048, 10.124419966000062 ],
							[ 8.628337118000047, 10.120559685000046 ],
							[ 8.628693588000033, 10.112859469000057 ],
							[ 8.631061681000062, 10.103309555000067 ],
							[ 8.635174734000032, 10.097700373000066 ],
							[ 8.641120824000041, 10.094099984000025 ],
							[ 8.645144436000066, 10.090419707000024 ],
							[ 8.649435953000022, 10.080960211000047 ],
							[ 8.649792507000029, 10.073249773000043 ],
							[ 8.653906068000026, 10.067640375000053 ],
							[ 8.660208908000072, 10.056339980000075 ],
							[ 8.662220708000063, 10.05449972100007 ],
							[ 8.668434041000069, 10.045120299000075 ],
							[ 8.672635992000039, 10.037590114000068 ],
							[ 8.674826748000044, 10.03189003600005 ],
							[ 8.67527227000005, 10.022259571000063 ],
							[ 8.679473814000062, 10.014730428000064 ],
							[ 8.679919183000038, 10.005089764000047 ],
							[ 8.676608105000071, 9.993362457000046 ],
							[ 8.673029689000032, 9.98741261400005 ],
							[ 8.667349898000055, 9.985228670000026 ],
							[ 8.661760339000068, 9.98112012200005 ],
							[ 8.652234918000033, 9.978766282000038 ],
							[ 8.640698154000063, 9.978254043000049 ],
							[ 8.634839726000052, 9.97992540000007 ],
							[ 8.627238544000022, 9.977657578000048 ],
							[ 8.624618785000052, 9.977009628000076 ],
							[ 8.617712857000072, 9.975303421000035 ],
							[ 8.614222618000042, 9.967426233000026 ],
							[ 8.610556731000031, 9.963402545000065 ],
							[ 8.610823542000048, 9.957622800000024 ],
							[ 8.616859692000048, 9.952099986000064 ],
							[ 8.622895100000051, 9.946575923000069 ],
							[ 8.629109319000065, 9.937199362000058 ],
							[ 8.633221657000036, 9.931591259000072 ],
							[ 8.635500997000065, 9.923970266000026 ],
							[ 8.635857670000064, 9.916263714000024 ],
							[ 8.628523147000067, 9.908216700000025 ],
							[ 8.626777925000056, 9.904277591000039 ],
							[ 8.623199505000059, 9.898326659000077 ],
							[ 8.619621202000076, 9.892376838000075 ],
							[ 8.618143352000061, 9.882659036000064 ],
							[ 8.620333462000076, 9.87696432000007 ],
							[ 8.626547695000056, 9.867588319000049 ],
							[ 8.628827333000061, 9.859967322000045 ],
							[ 8.629094337000026, 9.85418810300007 ],
							[ 8.629538579000041, 9.844554756000036 ],
							[ 8.628062357000033, 9.834836986000028 ],
							[ 8.630252024000072, 9.829142751000063 ],
							[ 8.630520123000053, 9.823362391000046 ],
							[ 8.629885945000069, 9.819196082000076 ],
							[ 8.631571054000062, 9.808410087000027 ],
							[ 8.631583427000066, 9.796213133000037 ],
							[ 8.632207662000042, 9.787676158000068 ],
							[ 8.637142249000021, 9.774264083000048 ],
							[ 8.637150169000051, 9.766336183000021 ],
							[ 8.631621161000055, 9.759012519000066 ],
							[ 8.627790374000028, 9.757615783000062 ],
							[ 8.624112235000041, 9.753580297000042 ],
							[ 8.62035577100005, 9.751482889000044 ],
							[ 8.615465220000033, 9.742396615000075 ],
							[ 8.611186846000066, 9.741423611000073 ],
							[ 8.603585070000065, 9.739154690000021 ],
							[ 8.599562915000035, 9.737936025000067 ],
							[ 8.594327001000067, 9.731021777000024 ],
							[ 8.592849923000074, 9.721303782000064 ],
							[ 8.595307453000032, 9.709830473000068 ],
							[ 8.595574321000072, 9.704050116000076 ],
							[ 8.597764269000038, 9.698355455000069 ],
							[ 8.598120959000028, 9.690649983000071 ],
							[ 8.596554628000035, 9.682858219000025 ],
							[ 8.594809695000038, 9.678920016000063 ],
							[ 8.593065361000072, 9.674981823000053 ],
							[ 8.58765332300004, 9.667019055000026 ],
							[ 8.581884525000021, 9.666763379000031 ],
							[ 8.576026856000055, 9.668433257000061 ],
							[ 8.56632326600004, 9.669933566000054 ],
							[ 8.562477927000032, 9.669762330000026 ],
							[ 8.55505364000004, 9.663641097000038 ],
							[ 8.55366626700004, 9.651996673000042 ],
							[ 8.559969013000057, 9.640693751000072 ],
							[ 8.560235833000036, 9.634913395000069 ],
							[ 8.564438126000027, 9.627378614000065 ],
							[ 8.566628678000029, 9.621684113000072 ],
							[ 8.566984238000032, 9.613977506000026 ],
							[ 8.567339799000024, 9.606272024000077 ],
							[ 8.565863372000024, 9.596553849000031 ],
							[ 8.56228442500003, 9.590602789000059 ],
							[ 8.560450820000028, 9.588591773000076 ],
							[ 8.555038506000074, 9.580629615000021 ],
							[ 8.553649753000059, 9.568984022000052 ],
							[ 8.556285931000048, 9.553657293000072 ],
							[ 8.554541494000034, 9.549718870000049 ],
							[ 8.55562379600002, 9.547332727000025 ],
							[ 8.558832982000069, 9.540257315000076 ],
							[ 8.559011385000076, 9.536403711000048 ],
							[ 8.561377552000067, 9.52685619600004 ],
							[ 8.563569263000034, 9.521162257000071 ],
							[ 8.565848629000072, 9.513540940000041 ],
							[ 8.568216182000072, 9.503993926000021 ],
							[ 8.570287677000067, 9.498610024000072 ],
							[ 8.574430498000027, 9.494617475000041 ],
							[ 8.580555157000049, 9.48716715300003 ],
							[ 8.586234368000021, 9.481567448000021 ],
							[ 8.593622156000038, 9.478786427000045 ],
							[ 8.602242729000068, 9.476547362000076 ],
							[ 8.602418942000043, 9.472694295000053 ],
							[ 8.605117922000034, 9.466742409000062 ],
							[ 8.607570746000022, 9.464001569000061 ],
							[ 8.608116303000031, 9.461351367000077 ],
							[ 8.608900012000049, 9.457537915000046 ],
							[ 8.613191851000067, 9.448076257000025 ],
							[ 8.617661758000054, 9.434760871000037 ],
							[ 8.619672610000066, 9.432920241000033 ],
							[ 8.623696531000064, 9.429237211000043 ],
							[ 8.627899124000066, 9.421702197000059 ],
							[ 8.631833193000034, 9.419945925000036 ],
							[ 8.636124551000023, 9.41048453600007 ],
							[ 8.638225362000071, 9.406716377000066 ],
							[ 8.642338948000031, 9.401108025000042 ],
							[ 8.64837450400006, 9.395583953000028 ],
							[ 8.656154627000035, 9.393998878000048 ],
							[ 8.664890509000031, 9.39317681700004 ],
							[ 8.669524879000051, 9.396523434000073 ],
							[ 8.679406921000066, 9.391171241000052 ],
							[ 8.68334212700006, 9.389414974000033 ],
							[ 8.687276736000058, 9.387659238000026 ],
							[ 8.691300608000063, 9.383976037000025 ],
							[ 8.695412539000074, 9.378367641000068 ],
							[ 8.69561027900005, 9.374082814000076 ],
							[ 8.695857856000032, 9.368734528000061 ],
							[ 8.692458202000068, 9.358931501000029 ],
							[ 8.690713785000071, 9.354993122000053 ],
							[ 8.68941484100003, 9.341422170000044 ],
							[ 8.691426531000047, 9.339580568000031 ],
							[ 8.693972791000022, 9.326180649000037 ],
							[ 8.694150795000041, 9.322327489000031 ],
							[ 8.698085745000071, 9.320571059000031 ],
							[ 8.692673829000057, 9.312608537000074 ],
							[ 8.689273648000039, 9.302804912000056 ],
							[ 8.687528397000051, 9.298867061000067 ],
							[ 8.684395886000061, 9.283283359000052 ],
							[ 8.678983688000073, 9.275321749000057 ],
							[ 8.671559227000046, 9.269200439000031 ],
							[ 8.66579041500006, 9.268943801000034 ],
							[ 8.658100184000034, 9.268602215000044 ],
							[ 8.648664742000051, 9.264323018000027 ],
							[ 8.643072821000032, 9.260214003000044 ],
							[ 8.641328854000051, 9.256275369000036 ],
							[ 8.639494784000021, 9.254263022000032 ],
							[ 8.635916861000055, 9.248313114000041 ],
							[ 8.63819585300007, 9.240692366000076 ],
							[ 8.642219736000072, 9.237009114000045 ],
							[ 8.646065647000057, 9.237179530000049 ],
							[ 8.650911167000061, 9.234711888000049 ],
							[ 8.655309454000076, 9.228500094000026 ],
							[ 8.657099450000032, 9.226499106000063 ],
							[ 8.653339011000071, 9.219762598000045 ],
							[ 8.653344318000052, 9.214193680000051 ],
							[ 8.651108496000063, 9.206395084000064 ],
							[ 8.644384167000055, 9.20082031000004 ],
							[ 8.626448106000055, 9.191892307000046 ],
							[ 8.626466403000052, 9.174072315000046 ],
							[ 8.624248659000045, 9.149566750000076 ],
							[ 8.617546866000055, 9.123943284000063 ],
							[ 8.61117239500004, 9.10594363000007 ],
							[ 8.610223835000056, 9.105054857000027 ],
							[ 8.604977578000046, 9.100140349000071 ],
							[ 8.594523383000023, 9.091536259000065 ],
							[ 8.586617673000035, 9.08293812200003 ],
							[ 8.579985416000056, 9.074343523000039 ],
							[ 8.576051497000037, 9.070657459000074 ],
							[ 8.570730019000052, 9.063291565000043 ],
							[ 8.566833533000022, 9.060831114000052 ],
							[ 8.564022958000066, 9.052246333000028 ],
							[ 8.562450562000038, 9.042438458000049 ],
							[ 8.559676848000038, 9.035078794000071 ],
							[ 8.559490314000072, 9.028950713000029 ],
							[ 8.554131472000051, 9.020358844000043 ],
							[ 8.547574299000075, 9.014216215000033 ],
							[ 8.541092180000021, 9.010523797000076 ],
							[ 8.533261415000027, 9.004377638000051 ],
							[ 8.521720172000073, 9.001897908000046 ],
							[ 8.514039212000057, 9.00065313500005 ],
							[ 8.508942708000063, 9.000640735000047 ],
							[ 8.504346156000054, 9.001431368000055 ],
							[ 8.498586514000067, 9.011305908000054 ],
							[ 8.494181381000033, 9.023639818000049 ],
							[ 8.490657971000076, 9.037735124000051 ],
							[ 8.48661633200004, 9.042786525000054 ],
							[ 8.479083166000066, 9.05220225100004 ],
							[ 8.476561586000059, 9.055354144000034 ],
							[ 8.474369257000035, 9.069972741000072 ],
							[ 8.473918935000029, 9.072973298000022 ],
							[ 8.471419222000065, 9.076597050000032 ],
							[ 8.466701289000071, 9.083439149000071 ],
							[ 8.462333790000059, 9.089771983000048 ],
							[ 8.456299630000046, 9.098521464000044 ],
							[ 8.454324677000045, 9.101393715000029 ],
							[ 8.446608641000068, 9.112617334000049 ],
							[ 8.441974566000056, 9.116478946000029 ],
							[ 8.436967819000074, 9.120651309000039 ],
							[ 8.430751520000058, 9.125831536000021 ],
							[ 8.423004132000074, 9.135594974000071 ],
							[ 8.418205487000023, 9.141693783000051 ],
							[ 8.409548287000064, 9.150699399000075 ],
							[ 8.395826965000026, 9.162228127000049 ],
							[ 8.387766659000022, 9.175688801000035 ],
							[ 8.381488599000022, 9.181091283000058 ],
							[ 8.380673698000066, 9.181792887000029 ],
							[ 8.366620218000037, 9.183536063000076 ],
							[ 8.339506845000074, 9.188963072000035 ],
							[ 8.335131989000047, 9.192425978000074 ],
							[ 8.333778385000073, 9.19266086600004 ],
							[ 8.332676923000065, 9.192851579000035 ],
							[ 8.331689892000043, 9.193022702000064 ],
							[ 8.327366104000021, 9.194344496000042 ],
							[ 8.324588707000032, 9.195193266000047 ],
							[ 8.322483740000052, 9.195838123000044 ],
							[ 8.318663779000076, 9.197008180000068 ],
							[ 8.315255471000057, 9.198052362000055 ],
							[ 8.31200543500006, 9.199048202000029 ],
							[ 8.309815568000033, 9.199718826000037 ],
							[ 8.308230380000055, 9.20020975500006 ],
							[ 8.306157764000034, 9.20086361400007 ],
							[ 8.302839294000023, 9.201911240000072 ],
							[ 8.299784427000077, 9.202875714000072 ],
							[ 8.298040336000042, 9.203426181000054 ],
							[ 8.296256366000023, 9.203989579000051 ],
							[ 8.294405780000034, 9.20457373000005 ],
							[ 8.292858681000041, 9.204768545000036 ],
							[ 8.290856794000035, 9.205021252000051 ],
							[ 8.289173644000073, 9.205233881000026 ],
							[ 8.286555313000065, 9.205564480000021 ],
							[ 8.284905734000063, 9.20578054300006 ],
							[ 8.282463668000048, 9.205590448000066 ],
							[ 8.280853473000036, 9.205465251000021 ],
							[ 8.278749402000074, 9.204714103000072 ],
							[ 8.278208050000046, 9.204520914000057 ],
							[ 8.277707618000022, 9.204342507000035 ],
							[ 8.275814550000064, 9.203666897000062 ],
							[ 8.274279741000043, 9.203119136000055 ],
							[ 8.271835666000072, 9.202248606000069 ],
							[ 8.268936085000064, 9.201225726000075 ],
							[ 8.264742248000061, 9.199747030000026 ],
							[ 8.264198054000076, 9.199554945000045 ],
							[ 8.263643632000026, 9.199359449000042 ],
							[ 8.261784723000062, 9.198701370000038 ],
							[ 8.257858817000056, 9.195909292000067 ],
							[ 8.255656367000029, 9.194342390000031 ],
							[ 8.255172820000041, 9.193997854000031 ],
							[ 8.253240320000032, 9.192621354000039 ],
							[ 8.251018158000022, 9.190975818000027 ],
							[ 8.248138636000022, 9.182248980000054 ],
							[ 8.255879586000049, 9.178689917000042 ],
							[ 8.254117251000025, 9.174284898000053 ],
							[ 8.252280196000072, 9.166937859000029 ],
							[ 8.251474292000069, 9.163713522000023 ],
							[ 8.243545267000059, 9.155784428000061 ],
							[ 8.241190163000056, 9.148718812000027 ],
							[ 8.239851229000067, 9.144702217000031 ],
							[ 8.238259575000029, 9.139926757000069 ],
							[ 8.231598643000041, 9.134034882000037 ],
							[ 8.229938695000044, 9.132566719000067 ],
							[ 8.223494408000022, 9.126865574000021 ],
							[ 8.215354710000042, 9.119664937000039 ],
							[ 8.206231871000057, 9.110542379000037 ],
							[ 8.20106606500002, 9.105376479000029 ],
							[ 8.186074132000044, 9.090384631000063 ],
							[ 8.181877801000041, 9.08618858400007 ],
							[ 8.17538552000002, 9.080264974000045 ],
							[ 8.165406230000031, 9.071158112000035 ],
							[ 8.164801110000042, 9.070606344000055 ],
							[ 8.160416502000032, 9.066604586000039 ],
							[ 8.155382993000046, 9.063074821000043 ],
							[ 8.149352892000024, 9.058846509000034 ],
							[ 8.143115088000059, 9.054472959000066 ],
							[ 8.13998117400007, 9.053366554000036 ],
							[ 8.135277485000074, 9.051707176000036 ],
							[ 8.128138509000053, 9.049187580000023 ],
							[ 8.107876980000071, 9.052711628000054 ],
							[ 8.102590389000056, 9.063282968000067 ],
							[ 8.101373756000044, 9.075446993000071 ],
							[ 8.100828389000071, 9.080902190000074 ],
							[ 8.100702210000065, 9.085691595000071 ],
							[ 8.100430303000053, 9.096033787000067 ],
							[ 8.099948024000071, 9.114378845000033 ],
							[ 8.099704963000022, 9.123631595000063 ],
							[ 8.099066937000032, 9.14785598900005 ],
							[ 8.098958961000051, 9.151246826000033 ],
							[ 8.098590705000049, 9.162779951000061 ],
							[ 8.099162756000055, 9.163387700000044 ],
							[ 8.101902236000058, 9.16717651600004 ],
							[ 8.106128437000052, 9.173814135000043 ],
							[ 8.107522298000049, 9.178551259000074 ],
							[ 8.108029900000076, 9.180395798000063 ],
							[ 8.111090256000068, 9.191518470000062 ],
							[ 8.114125423000075, 9.202547798000069 ],
							[ 8.114450322000039, 9.210041634000049 ],
							[ 8.114495053000041, 9.211070200000051 ],
							[ 8.114932014000033, 9.221165407000058 ],
							[ 8.114999382000065, 9.222716737000042 ],
							[ 8.114909488000023, 9.224345663000065 ],
							[ 8.111093569000047, 9.229057781000051 ],
							[ 8.095640041000024, 9.240082929000039 ],
							[ 8.093636586000059, 9.241512189000048 ],
							[ 8.093306840000025, 9.241831794000063 ],
							[ 8.088165420000053, 9.246813922000058 ],
							[ 8.084856235000075, 9.25008322900004 ],
							[ 8.076400419000038, 9.25843616700007 ],
							[ 8.072721712000032, 9.262349765000067 ],
							[ 8.063565313000026, 9.272227495000038 ],
							[ 8.061646911000025, 9.272344129000032 ],
							[ 8.061093227000072, 9.272519017000036 ],
							[ 8.05930115700005, 9.273084621000066 ],
							[ 8.045767546000036, 9.277358080000056 ],
							[ 8.040239258000042, 9.280972721000069 ],
							[ 8.03111640000003, 9.286937646000069 ],
							[ 8.025481185000046, 9.290623001000029 ],
							[ 8.022862258000032, 9.292334499000049 ],
							[ 8.015777644000025, 9.296869113000071 ],
							[ 8.006057889000033, 9.303090076000046 ],
							[ 8.000838094000073, 9.306430889000069 ],
							[ 7.997314929000026, 9.314358511000023 ],
							[ 7.997315167000068, 9.317783487000042 ],
							[ 7.997314801000073, 9.324931238000033 ],
							[ 7.998341245000063, 9.328625713000065 ],
							[ 8.001719430000037, 9.340788130000021 ],
							[ 7.999076890000026, 9.35400278700007 ],
							[ 7.993228644000055, 9.359294138000052 ],
							[ 7.98717758600003, 9.364769060000071 ],
							[ 7.980576701000075, 9.370740759000057 ],
							[ 7.94407525400004, 9.39887699600007 ],
							[ 7.938289389000033, 9.403336418000038 ],
							[ 7.92067049800005, 9.405098668000051 ],
							[ 7.914795814000058, 9.400461328000063 ],
							[ 7.903931862000036, 9.391884626000035 ],
							[ 7.901639172000046, 9.389019049000069 ],
							[ 7.889836535000029, 9.374264460000063 ],
							[ 7.883907919000023, 9.365042512000059 ],
							[ 7.881907797000054, 9.361931883000068 ],
							[ 7.87843514900004, 9.35653046300007 ],
							[ 7.865868965000061, 9.336982528000021 ],
							[ 7.858121438000069, 9.324931381000056 ],
							[ 7.854626389000032, 9.31999640500004 ],
							[ 7.846659396000064, 9.316887357000041 ],
							[ 7.844635396000058, 9.316097824000053 ],
							[ 7.843781146000026, 9.315682634000041 ],
							[ 7.839999864000049, 9.313844983000024 ],
							[ 7.838095962000068, 9.312892474000023 ],
							[ 7.833882416000051, 9.310784406000039 ],
							[ 7.83116579600005, 9.310326004000046 ],
							[ 7.828161087000069, 9.309719181000048 ],
							[ 7.827270808000037, 9.310069522000049 ],
							[ 7.824436736000052, 9.311264702000074 ],
							[ 7.823261939000076, 9.311759928000072 ],
							[ 7.821066408000036, 9.312813594000033 ],
							[ 7.82063368200005, 9.313021284000058 ],
							[ 7.815063016000067, 9.317599286000075 ],
							[ 7.802932051000028, 9.324982519000059 ],
							[ 7.802185411000039, 9.325436681000042 ],
							[ 7.800537879000046, 9.326158611000039 ],
							[ 7.797523701000046, 9.327527592000024 ],
							[ 7.789250709000044, 9.331105799000056 ],
							[ 7.782733560000054, 9.332870592000063 ],
							[ 7.777380269000048, 9.334025447000045 ],
							[ 7.769930513000077, 9.335632087000022 ],
							[ 7.768490603000032, 9.336033577000023 ],
							[ 7.767582884000035, 9.336287097000024 ],
							[ 7.757361279000065, 9.340212930000064 ],
							[ 7.75217477700005, 9.342312241000059 ],
							[ 7.751334486000076, 9.342652401000066 ],
							[ 7.750706538000031, 9.342991595000058 ],
							[ 7.748904268000047, 9.343964208000045 ],
							[ 7.746373187000074, 9.345459878000042 ],
							[ 7.740754213000059, 9.348780955000052 ],
							[ 7.737789361000068, 9.34778872000004 ],
							[ 7.731813857000077, 9.335779969000043 ],
							[ 7.729873720000057, 9.331881549000059 ],
							[ 7.719591356000024, 9.346354575000021 ],
							[ 7.672389156000065, 9.411277552000058 ],
							[ 7.661629861000051, 9.413929610000025 ],
							[ 7.589875076000055, 9.431607240000062 ],
							[ 7.509752670000069, 9.361051365000037 ],
							[ 7.496598879000032, 9.362247435000029 ],
							[ 7.476269440000067, 9.370618872000023 ],
							[ 7.463114980000057, 9.368227131000026 ],
							[ 7.445176726000057, 9.369422875000055 ],
							[ 7.417672168000024, 9.367030941000053 ],
							[ 7.398538659000053, 9.357464010000058 ],
							[ 7.393836112000031, 9.353999385000066 ],
							[ 7.38816844400003, 9.349822979000066 ],
							[ 7.376487386000065, 9.363329893000071 ],
							[ 7.360388661000059, 9.367021642000054 ],
							[ 7.350243105000061, 9.364686132000031 ],
							[ 7.346700243000043, 9.363870688000077 ],
							[ 7.343762326000046, 9.363171488000035 ],
							[ 7.335326546000033, 9.361163094000062 ],
							[ 7.328394818000049, 9.363837950000061 ],
							[ 7.321242254000026, 9.366598146000058 ],
							[ 7.31496326000007, 9.364677619000076 ],
							[ 7.308789235000063, 9.362787982000043 ],
							[ 7.298315654000021, 9.358440673000075 ],
							[ 7.290733868000075, 9.355293135000068 ],
							[ 7.282647076000046, 9.35193627600006 ],
							[ 7.280085145000044, 9.350873277000062 ],
							[ 7.273269239000058, 9.349850858000025 ],
							[ 7.270301901000039, 9.349405225000055 ],
							[ 7.267087466000021, 9.351414864000049 ],
							[ 7.264964643000042, 9.353006333000053 ],
							[ 7.262890080000034, 9.354562445000056 ],
							[ 7.260588101000053, 9.356288681000024 ],
							[ 7.257774390000066, 9.35935852700004 ],
							[ 7.254630977000033, 9.362788443000056 ],
							[ 7.255014490000065, 9.365856413000074 ],
							[ 7.256255540000041, 9.375785765000046 ],
							[ 7.259282667000036, 9.379418303000023 ],
							[ 7.265466771000035, 9.386838749000049 ],
							[ 7.26979534700007, 9.392033568000045 ],
							[ 7.275750051000045, 9.397728684000072 ],
							[ 7.28038540600005, 9.402162494000038 ],
							[ 7.282251946000031, 9.403947809000044 ],
							[ 7.284920750000026, 9.405854388000023 ],
							[ 7.289833968000039, 9.409363753000036 ],
							[ 7.289763313000037, 9.412825718000022 ],
							[ 7.289505812000073, 9.42543721800007 ],
							[ 7.285032356000045, 9.429871598000034 ],
							[ 7.279543919000048, 9.435360145000061 ],
							[ 7.276604126000052, 9.438626183000054 ],
							[ 7.274669518000053, 9.440775766000058 ],
							[ 7.267395581000073, 9.439563500000077 ],
							[ 7.264921067000046, 9.439150676000054 ],
							[ 7.261124238000036, 9.439949916000046 ],
							[ 7.257818719000056, 9.440646334000064 ],
							[ 7.254630921000057, 9.441317300000037 ],
							[ 7.247048740000025, 9.439150991000076 ],
							[ 7.241091162000032, 9.437526865000052 ],
							[ 7.23784146600002, 9.436985270000037 ],
							[ 7.227951020000035, 9.44357879100005 ],
							[ 7.221594366000033, 9.447816876000047 ],
							[ 7.22281597500006, 9.453436872000054 ],
							[ 7.22430218300002, 9.460272514000053 ],
							[ 7.221334418000026, 9.467018314000029 ],
							[ 7.219436276000067, 9.471332622000034 ],
							[ 7.218344655000067, 9.473813163000045 ],
							[ 7.217339960000061, 9.476157374000024 ],
							[ 7.214905670000064, 9.481836561000023 ],
							[ 7.213470579000045, 9.485185652000041 ],
							[ 7.213084354000046, 9.487695586000029 ],
							[ 7.212387204000038, 9.492226439000035 ],
							[ 7.211304339000037, 9.500891836000051 ],
							[ 7.215551024000035, 9.503336027000046 ],
							[ 7.22059772700004, 9.495765655000071 ],
							[ 7.222775498000033, 9.495494173000054 ],
							[ 7.227326797000046, 9.494924409000021 ],
							[ 7.23405600600006, 9.509223871000074 ],
							[ 7.239102943000034, 9.518476343000032 ],
							[ 7.244192248000047, 9.520421845000044 ],
							[ 7.246541920000027, 9.521320197000023 ],
							[ 7.251441133000071, 9.523193499000058 ],
							[ 7.253884663000065, 9.524126726000077 ],
							[ 7.260972565000031, 9.52688782000007 ],
							[ 7.263447678000034, 9.529681940000046 ],
							[ 7.267756431000066, 9.534546620000071 ],
							[ 7.272693008000033, 9.540121092000049 ],
							[ 7.276942801000075, 9.544919281000034 ],
							[ 7.283338933000039, 9.552140297000051 ],
							[ 7.28422706300006, 9.553142912000055 ],
							[ 7.285171304000073, 9.554209094000043 ],
							[ 7.285392552000076, 9.555124988000046 ],
							[ 7.285575361000042, 9.555615193000051 ],
							[ 7.285692687000051, 9.556162180000058 ],
							[ 7.287310794000064, 9.563692596000067 ],
							[ 7.290026007000051, 9.576329077000025 ],
							[ 7.292681496000057, 9.588685521000059 ],
							[ 7.293227877000049, 9.591217349000033 ],
							[ 7.293667567000057, 9.59299711500006 ],
							[ 7.294064887000047, 9.594096906000061 ],
							[ 7.311447502000021, 9.600044008000054 ],
							[ 7.315110133000076, 9.601311822000071 ],
							[ 7.315817244000073, 9.601408278000065 ],
							[ 7.32510893500006, 9.602697102000036 ],
							[ 7.333666612000059, 9.606661977000044 ],
							[ 7.337062210000056, 9.614027830000055 ],
							[ 7.341392935000044, 9.618378052000025 ],
							[ 7.343521591000069, 9.622648876000028 ],
							[ 7.343013904000031, 9.62534880100003 ],
							[ 7.342154663000031, 9.629675134000024 ],
							[ 7.341113760000042, 9.63198331600006 ],
							[ 7.339474078000023, 9.635407680000071 ],
							[ 7.338730866000049, 9.636959980000029 ],
							[ 7.326943032000031, 9.649533444000042 ],
							[ 7.322563041000024, 9.654205408000053 ],
							[ 7.317328889000066, 9.659788308000032 ],
							[ 7.314399359000049, 9.664391618000025 ],
							[ 7.311441050000042, 9.669040565000046 ],
							[ 7.30407232500005, 9.673461863000057 ],
							[ 7.29937591700002, 9.676279785000077 ],
							[ 7.294681424000032, 9.679095952000068 ],
							[ 7.290412314000037, 9.68165805700005 ],
							[ 7.282842045000052, 9.684181045000059 ],
							[ 7.282001093000076, 9.691751426000053 ],
							[ 7.283833511000068, 9.695645410000054 ],
							[ 7.28669562500005, 9.701727042000073 ],
							[ 7.288730121000071, 9.706050832000074 ],
							[ 7.286068040000032, 9.711908485000038 ],
							[ 7.283289567000054, 9.718020437000064 ],
							[ 7.280318653000052, 9.724555848000023 ],
							[ 7.271066161000022, 9.732126490000041 ],
							[ 7.268494218000058, 9.732126314000027 ],
							[ 7.24499086700007, 9.732126161000053 ],
							[ 7.224803606000023, 9.738855262000072 ],
							[ 7.214709653000057, 9.748949157000027 ],
							[ 7.204739963000065, 9.758295778000047 ],
							[ 7.206725077000044, 9.76579662000006 ],
							[ 7.207546731000036, 9.768900041000052 ],
							[ 7.210504188000073, 9.780071197000041 ],
							[ 7.21490529600004, 9.782547160000036 ],
							[ 7.220837224000036, 9.785883712000043 ],
							[ 7.223962370000038, 9.787641430000065 ],
							[ 7.231532464000054, 9.791006028000027 ],
							[ 7.251720078000062, 9.794370482000033 ],
							[ 7.256766769000023, 9.790164735000076 ],
							[ 7.260131267000077, 9.786800327000037 ],
							[ 7.267701572000021, 9.783435853000071 ],
							[ 7.27358971700005, 9.783435729000075 ],
							[ 7.278636577000043, 9.786800544000073 ],
							[ 7.285365548000073, 9.789323811000031 ],
							[ 7.291253644000051, 9.800258672000041 ],
							[ 7.292935904000046, 9.811193264000053 ],
							[ 7.292094779000024, 9.823810816000048 ],
							[ 7.291084263000073, 9.82886297500005 ],
							[ 7.28873008100004, 9.840633257000036 ],
							[ 7.292935923000073, 9.851568186000065 ],
							[ 7.294904615000064, 9.853045334000058 ],
							[ 7.299664925000059, 9.856615902000044 ],
							[ 7.300506191000068, 9.862503860000061 ],
							[ 7.296500124000033, 9.869848283000067 ],
							[ 7.295459258000051, 9.871756803000039 ],
							[ 7.295459310000069, 9.880002889000025 ],
							[ 7.295459202000075, 9.887738127000034 ],
							[ 7.297982704000049, 9.898673109000072 ],
							[ 7.29377706300005, 9.907925366000029 ],
							[ 7.287889055000051, 9.923066294000023 ],
							[ 7.287137613000027, 9.926072357000066 ],
							[ 7.285365601000024, 9.933159719000059 ],
							[ 7.28546665500005, 9.935183689000041 ],
							[ 7.285765228000059, 9.941148811000062 ],
							[ 7.286206775000039, 9.949982854000041 ],
							[ 7.284524447000024, 9.957553186000041 ],
							[ 7.28313683500005, 9.960546672000021 ],
							[ 7.281075402000056, 9.96499435000004 ],
							[ 7.280541905000064, 9.971077635000029 ],
							[ 7.280318695000062, 9.973534649000044 ],
							[ 7.280318787000056, 9.983628427000042 ],
							[ 7.28031880900005, 9.989039516000048 ],
							[ 7.281084150000027, 9.993737071000055 ],
							[ 7.280318622000038, 9.999610203000032 ],
							[ 7.279907316000049, 10.002283882000029 ],
							[ 7.278636526000071, 10.010544877000029 ],
							[ 7.277795293000054, 10.016432903000066 ],
							[ 7.273243356000023, 10.023513972000046 ],
							[ 7.27022491200006, 10.028208551000034 ],
							[ 7.264654556000039, 10.032187641000064 ],
							[ 7.258449077000023, 10.03662020400003 ],
							[ 7.254066906000048, 10.039226540000072 ],
							[ 7.250727291000032, 10.039417007000054 ],
							[ 7.240785109000058, 10.03998462900006 ],
							[ 7.232373590000066, 10.043349024000065 ],
							[ 7.229088267000066, 10.038420626000061 ],
							[ 7.22668778700006, 10.038840268000058 ],
							[ 7.222132219000059, 10.037259952000056 ],
							[ 7.21601591700005, 10.037407915000074 ],
							[ 7.213868607000052, 10.039984428000025 ],
							[ 7.207139522000034, 10.041666838000026 ],
							[ 7.200410505000036, 10.033255356000041 ],
							[ 7.19368120200005, 10.029890911000052 ],
							[ 7.188634385000057, 10.027367515000037 ],
							[ 7.179381724000052, 10.027367827000035 ],
							[ 7.166764693000061, 10.033255648000022 ],
							[ 7.156671163000055, 10.034096573000056 ],
							[ 7.149941908000073, 10.034937684000056 ],
							[ 7.138165964000052, 10.035778719000064 ],
							[ 7.125548860000038, 10.034096632000058 ],
							[ 7.123025325000071, 10.029891048000025 ],
							[ 7.112090695000063, 10.026526352000076 ],
							[ 7.105361603000063, 10.026526391000061 ],
							[ 7.103679189000047, 10.031573420000029 ],
							[ 7.100314562000051, 10.029890904000069 ],
							[ 7.091903239000032, 10.032414685000049 ],
							[ 7.085174087000041, 10.036620159000051 ],
							[ 7.064986747000034, 10.042508173000044 ],
							[ 7.033863988000064, 10.04419063000006 ],
							[ 7.018723528000066, 10.038302630000032 ],
							[ 7.007788652000045, 10.045031456000061 ],
							[ 6.983395558000041, 10.045872847000055 ],
							[ 6.962367086000029, 10.046713649000026 ],
							[ 6.952273235000064, 10.04755521800007 ],
							[ 6.940497515000061, 10.061013007000042 ],
							[ 6.926198113000055, 10.067742149000026 ],
							[ 6.914422137000031, 10.07783581800004 ],
							[ 6.906362923000074, 10.077836051000077 ],
							[ 6.904328443000054, 10.077835822000054 ],
							[ 6.901178894000054, 10.076381575000028 ],
							[ 6.89252940800003, 10.093015957000034 ],
							[ 6.882088149000026, 10.117111173000069 ],
							[ 6.885301151000021, 10.150844707000033 ],
							[ 6.888347751000026, 10.153129713000055 ],
							[ 6.894938990000071, 10.158073156000057 ],
							[ 6.903774388000045, 10.168515418000027 ],
							[ 6.905458976000034, 10.17094796400005 ],
							[ 6.911003200000039, 10.17895629700007 ],
							[ 6.915019043000029, 10.188594632000047 ],
							[ 6.92144434000005, 10.191004056000054 ],
							[ 6.923635151000042, 10.189126212000076 ],
							[ 6.927066804000049, 10.186185107000028 ],
							[ 6.928672646000052, 10.180562692000024 ],
							[ 6.936705025000037, 10.180562939000026 ],
							[ 6.93991752200003, 10.180562753000061 ],
							[ 6.942326900000069, 10.180563165000024 ],
							[ 6.947146328000031, 10.185381670000027 ],
							[ 6.954472460000034, 10.191998453000053 ],
							[ 6.959194310000044, 10.202248760000032 ],
							[ 6.960800678000055, 10.207067429000062 ],
							[ 6.965619530000026, 10.215099173000056 ],
							[ 6.967225920000033, 10.222328359000073 ],
							[ 6.970438852000029, 10.232770233000053 ],
							[ 6.978470898000069, 10.23999894800005 ],
							[ 6.982486818000041, 10.244014710000044 ],
							[ 6.984092834000023, 10.239998554000067 ],
							[ 6.984888955000031, 10.236814251000055 ],
							[ 6.987305708000065, 10.236786152000036 ],
							[ 6.990495117000023, 10.240836898000055 ],
							[ 6.996140997000055, 10.244817846000046 ],
							[ 7.002566400000035, 10.247227530000032 ],
							[ 7.002895868000053, 10.244918785000038 ],
							[ 7.003369232000068, 10.241604998000071 ],
							[ 7.008188654000037, 10.242407938000042 ],
							[ 7.009129058000042, 10.247109200000068 ],
							[ 7.009794768000063, 10.250439862000064 ],
							[ 7.017127849000076, 10.266047739000044 ],
							[ 7.019433029000027, 10.273732460000076 ],
							[ 7.025858930000027, 10.28015817000005 ],
							[ 7.032284124000057, 10.28738671800005 ],
							[ 7.040316183000073, 10.28899285600005 ],
							[ 7.045938436000029, 10.291402611000024 ],
							[ 7.049150859000065, 10.301040801000056 ],
							[ 7.049954352000043, 10.310679268000058 ],
							[ 7.05075748400003, 10.317104237000024 ],
							[ 7.046628505000058, 10.322609853000074 ],
							[ 7.043528589000061, 10.326743252000028 ],
							[ 7.033890841000073, 10.343609957000069 ],
							[ 7.029107992000036, 10.347266986000022 ],
							[ 7.024765029000037, 10.350588964000053 ],
							[ 7.020236529000044, 10.354051728000059 ],
							[ 7.006392887000061, 10.35616701400005 ],
							[ 7.003381251000064, 10.356627689000049 ],
							[ 7.000393976000055, 10.356549959000063 ],
							[ 6.996944079000059, 10.356460685000059 ],
							[ 6.991198531000066, 10.359332797000036 ],
							[ 6.988912081000024, 10.360476485000049 ],
							[ 6.986279422000052, 10.361456114000021 ],
							[ 6.977213752000068, 10.364829777000068 ],
							[ 6.965060572000027, 10.362796765000041 ],
							[ 6.960800605000031, 10.362083384000073 ],
							[ 6.951178683000023, 10.360799558000053 ],
							[ 6.948752816000024, 10.360476463000055 ],
							[ 6.940587152000035, 10.360930617000065 ],
							[ 6.934295128000031, 10.36127923600003 ],
							[ 6.930211031000056, 10.365772340000035 ],
							[ 6.926263300000073, 10.370115313000042 ],
							[ 6.924767972000041, 10.375723575000052 ],
							[ 6.923050901000067, 10.382162941000047 ],
							[ 6.928428174000032, 10.403036254000028 ],
							[ 6.933492294000075, 10.417503407000027 ],
							[ 6.937668847000054, 10.431425893000039 ],
							[ 6.938311030000023, 10.433567068000059 ],
							[ 6.942557211000064, 10.442484085000046 ],
							[ 6.946342825000045, 10.450433920000023 ],
							[ 6.949005214000067, 10.454426658000045 ],
							[ 6.950424188000056, 10.456555169000069 ],
							[ 6.954374871000027, 10.462481252000032 ],
							[ 6.954133020000029, 10.468047366000064 ],
							[ 6.953519878000066, 10.482178771000065 ],
							[ 6.947949378000033, 10.491396136000049 ],
							[ 6.935901542000067, 10.500231546000066 ],
							[ 6.929167636000045, 10.512475896000069 ],
							[ 6.927066894000063, 10.516295201000048 ],
							[ 6.922659993000025, 10.524226984000052 ],
							[ 6.91903486800004, 10.530752163000045 ],
							[ 6.914323481000054, 10.536052956000049 ],
							[ 6.912609157000077, 10.537980858000026 ],
							[ 6.905256696000038, 10.540769664000038 ],
							[ 6.900235077000048, 10.542674267000052 ],
							[ 6.895732875000022, 10.54438228500004 ],
							[ 6.889316960000031, 10.54681559900007 ],
							[ 6.882088055000054, 10.550831954000046 ],
							[ 6.874302787000033, 10.553264524000042 ],
							[ 6.869102364000071, 10.555356963000065 ],
							[ 6.863134976000026, 10.551937932000044 ],
							[ 6.861205187000053, 10.550831724000034 ],
							[ 6.856386124000039, 10.537981157000047 ],
							[ 6.84915723000006, 10.53075235700004 ],
							[ 6.844338440000058, 10.52834321000006 ],
							[ 6.839519050000035, 10.531555130000072 ],
							[ 6.835503126000049, 10.537981158000036 ],
							[ 6.839519077000034, 10.549225961000047 ],
							[ 6.841125379000061, 10.55725792100003 ],
							[ 6.841652919000069, 10.567273119000049 ],
							[ 6.841929004000065, 10.572518387000059 ],
							[ 6.841384799000025, 10.574693477000039 ],
							[ 6.838716064000039, 10.585369210000067 ],
							[ 6.836068683000065, 10.593016384000066 ],
							[ 6.835101706000046, 10.595811113000025 ],
							[ 6.829479311000057, 10.599826772000029 ],
							[ 6.829791466000074, 10.604509471000028 ],
							[ 6.830282647000047, 10.61187437500007 ],
							[ 6.830089947000033, 10.614956152000047 ],
							[ 6.829479280000044, 10.624725070000068 ],
							[ 6.823499252000033, 10.631078969000043 ],
							[ 6.819104613000036, 10.635748109000076 ],
							[ 6.816628363000063, 10.638379067000074 ],
							[ 6.814443535000066, 10.639150479000023 ],
							[ 6.80297415800004, 10.643198987000062 ],
							[ 6.799229618000027, 10.642075476000059 ],
							[ 6.794942466000066, 10.640788796000038 ],
							[ 6.785303972000065, 10.639183064000065 ],
							[ 6.78228365800004, 10.639647543000024 ],
							[ 6.774862823000035, 10.640789065000035 ],
							[ 6.765357437000034, 10.64364071600005 ],
							[ 6.758799212000042, 10.645608134000042 ],
							[ 6.75004967600006, 10.646634339000059 ],
							[ 6.744777227000043, 10.647252077000076 ],
							[ 6.742323799000076, 10.647497052000062 ],
							[ 6.734772242000076, 10.648251630000061 ],
							[ 6.729081260000044, 10.648820815000022 ],
							[ 6.726885328000037, 10.647723022000037 ],
							[ 6.722655276000069, 10.645608047000053 ],
							[ 6.71703340800002, 10.640789182000049 ],
							[ 6.715897641000026, 10.638517455000056 ],
							[ 6.713820525000074, 10.634363406000034 ],
							[ 6.717490596000061, 10.628071620000071 ],
							[ 6.719442976000039, 10.624725560000059 ],
							[ 6.724261643000034, 10.617496576000065 ],
							[ 6.72507181900005, 10.614526014000035 ],
							[ 6.726671357000043, 10.608661577000021 ],
							[ 6.729884045000063, 10.603038690000062 ],
							[ 6.731490689000054, 10.596613752000053 ],
							[ 6.729305230000023, 10.593804492000061 ],
							[ 6.725868303000027, 10.589385050000033 ],
							[ 6.709845606000044, 10.587535798000033 ],
							[ 6.705233019000048, 10.588267236000036 ],
							[ 6.70048803100002, 10.589019846000042 ],
							[ 6.696150308000028, 10.590990902000044 ],
							[ 6.686180666000041, 10.589942166000071 ],
							[ 6.680889584000056, 10.589385265000033 ],
							[ 6.677781503000062, 10.589863044000026 ],
							[ 6.671233089000054, 10.590868962000059 ],
							[ 6.668568526000058, 10.591261855000027 ],
							[ 6.653288973000031, 10.59280229500007 ],
							[ 6.647069932000022, 10.593429601000025 ],
							[ 6.636498864000032, 10.590010412000026 ],
							[ 6.630505478000032, 10.588106871000036 ],
							[ 6.624107905000074, 10.586084252000035 ],
							[ 6.618111733000035, 10.584188418000053 ],
							[ 6.614225378000071, 10.582959276000054 ],
							[ 6.61077978000003, 10.581728975000033 ],
							[ 6.606240290000073, 10.580107911000027 ],
							[ 6.602980539000043, 10.578943173000027 ],
							[ 6.597023572000069, 10.577156286000047 ],
							[ 6.586916975000065, 10.574124626000071 ],
							[ 6.583181918000037, 10.569856727000058 ],
							[ 6.581294447000062, 10.567699461000075 ],
							[ 6.57778451300004, 10.562629471000037 ],
							[ 6.574065626000049, 10.557257476000075 ],
							[ 6.569400863000055, 10.554924854000035 ],
							[ 6.566545613000073, 10.553497431000039 ],
							[ 6.561214903000064, 10.550832028000059 ],
							[ 6.556100724000032, 10.54694070100004 ],
							[ 6.551589435000039, 10.545555905000072 ],
							[ 6.549167113000067, 10.545209876000058 ],
							[ 6.540331802000026, 10.545210062000024 ],
							[ 6.534732701000053, 10.550808667000069 ],
							[ 6.532300005000025, 10.553241975000049 ],
							[ 6.525071568000044, 10.564486328000044 ],
							[ 6.521593199000051, 10.572834881000063 ],
							[ 6.521055607000051, 10.582156353000073 ],
							[ 6.521845441000039, 10.585315760000071 ],
							[ 6.524268138000025, 10.595006787000045 ],
							[ 6.519689001000074, 10.59958640800005 ],
							[ 6.513826710000046, 10.605448510000031 ],
							[ 6.511785471000053, 10.606582418000073 ],
							[ 6.506597855000052, 10.609464887000058 ],
							[ 6.504096587000049, 10.61040298100005 ],
							[ 6.493747215000042, 10.614283362000037 ],
							[ 6.482116275000067, 10.610179050000056 ],
							[ 6.480092894000052, 10.60946462000004 ],
							[ 6.474883626000064, 10.606149579000032 ],
							[ 6.47125782300003, 10.603842604000022 ],
							[ 6.469135646000041, 10.602969224000049 ],
							[ 6.457603557000027, 10.598220076000075 ],
							[ 6.450375084000029, 10.595007226000064 ],
							[ 6.439130272000057, 10.592598225000074 ],
							[ 6.433216059000074, 10.589049435000049 ],
							[ 6.429186759000061, 10.586631875000023 ],
							[ 6.427082671000051, 10.585369066000055 ],
							[ 6.418247611000027, 10.57653455600007 ],
							[ 6.401724677000061, 10.573475059000032 ],
							[ 6.396561666000025, 10.572518327000068 ],
							[ 6.394822675000057, 10.567735918000039 ],
							[ 6.392539576000047, 10.561457913000027 ],
							[ 6.39091914200003, 10.557001784000022 ],
							[ 6.390135826000062, 10.554847923000068 ],
							[ 6.387966243000051, 10.552417583000022 ],
							[ 6.374429677000023, 10.537256915000057 ],
							[ 6.37005608000004, 10.532359050000025 ],
							[ 6.365288311000029, 10.529411166000045 ],
							[ 6.344614645000036, 10.516631084000039 ],
							[ 6.325881092000031, 10.505050466000057 ],
							[ 6.311894281000036, 10.493394874000046 ],
							[ 6.306604365000055, 10.488986844000067 ],
							[ 6.303569812000035, 10.482007192000026 ],
							[ 6.300349797000024, 10.474600666000072 ],
							[ 6.298572539000077, 10.47051306700007 ],
							[ 6.285515756000052, 10.463984359000051 ],
							[ 6.282508983000071, 10.462481291000074 ],
							[ 6.27791208900004, 10.461140521000061 ],
							[ 6.263232120000055, 10.456859331000032 ],
							[ 6.261819008000032, 10.454674544000056 ],
							[ 6.254397272000062, 10.44320478800006 ],
							[ 6.252790838000067, 10.434370006000051 ],
							[ 6.251211096000077, 10.428051074000052 ],
							[ 6.250625598000056, 10.425708517000032 ],
							[ 6.249577905000024, 10.421519265000029 ],
							[ 6.241546092000021, 10.421519399000033 ],
							[ 6.23913682400007, 10.420766855000068 ],
							[ 6.228695259000062, 10.417503590000024 ],
							[ 6.226480382000034, 10.410119157000054 ],
							[ 6.216883056000029, 10.394705687000055 ],
							[ 6.215008691000037, 10.393771104000052 ],
							[ 6.201386939000031, 10.386981963000039 ],
							[ 6.19083061300006, 10.385952126000063 ],
							[ 6.171065355000053, 10.384023902000024 ],
							[ 6.168456196000022, 10.383768891000045 ],
							[ 6.167398323000043, 10.385478025000054 ],
							[ 6.163934361000031, 10.391074437000043 ],
							[ 6.158014683000033, 10.40063566300006 ],
							[ 6.155260557000076, 10.407198623000056 ],
							[ 6.154097138000054, 10.409971483000049 ],
							[ 6.153195918000051, 10.423125424000034 ],
							[ 6.152519715000039, 10.428196169000046 ],
							[ 6.151589258000058, 10.435173189000068 ],
							[ 6.150339006000024, 10.443093074000046 ],
							[ 6.14918005800007, 10.450433903000032 ],
							[ 6.147385021000048, 10.452065530000027 ],
							[ 6.140344723000055, 10.458465347000072 ],
							[ 6.132312709000075, 10.464087735000021 ],
							[ 6.129734392000046, 10.46354506800003 ],
							[ 6.117052172000058, 10.460875496000028 ],
							[ 6.112777773000062, 10.459451009000077 ],
							[ 6.105004190000045, 10.456859395000038 ],
							[ 6.100185272000033, 10.464087241000072 ],
							[ 6.096478309000076, 10.470743993000042 ],
							[ 6.094739510000068, 10.473866431000033 ],
							[ 6.089743977000069, 10.475332521000041 ],
							[ 6.090547183000069, 10.480954973000053 ],
							[ 6.086531256000058, 10.515491537000059 ],
							[ 6.091350207000062, 10.522720359000061 ],
							[ 6.090547165000032, 10.53798082000003 ],
							[ 6.092956500000071, 10.546012846000053 ],
							[ 6.098578967000037, 10.546012875000031 ],
							[ 6.107414252000069, 10.546816002000071 ],
							[ 6.11464260200006, 10.549225868000065 ],
							[ 6.121068024000067, 10.55324145000003 ],
							[ 6.123478029000069, 10.562076779000051 ],
							[ 6.127493763000075, 10.569305654000061 ],
							[ 6.129099784000061, 10.580550306000021 ],
							[ 6.127493833000074, 10.59340080100003 ],
							[ 6.114642523000043, 10.599022663000028 ],
							[ 6.112233093000043, 10.605448926000065 ],
							[ 6.113036136000062, 10.612677721000068 ],
							[ 6.120264901000041, 10.62713500600006 ],
							[ 6.122674536000034, 10.635970293000071 ],
							[ 6.123667728000044, 10.640440259000059 ],
							[ 6.12428086400007, 10.643199084000059 ],
							[ 6.12465181500005, 10.646166811000057 ],
							[ 6.125084040000047, 10.649623771000051 ],
							[ 6.129099707000023, 10.656049647000032 ],
							[ 6.132163891000062, 10.659624175000033 ],
							[ 6.133919220000053, 10.661671395000042 ],
							[ 6.137132262000023, 10.669703626000057 ],
							[ 6.135525733000065, 10.677735303000077 ],
							[ 6.132229692000067, 10.678991345000043 ],
							[ 6.118658484000036, 10.684161370000027 ],
							[ 6.113839596000048, 10.689782856000022 ],
							[ 6.11143026000002, 10.695406131000027 ],
							[ 6.104201366000041, 10.699422046000052 ],
							[ 6.095365948000051, 10.697814799000071 ],
							[ 6.091350055000021, 10.70022487600005 ],
							[ 6.091350126000066, 10.708256542000072 ],
							[ 6.097775793000039, 10.715485358000024 ],
							[ 6.103397796000024, 10.718698733000053 ],
							[ 6.105807732000073, 10.725123568000072 ],
							[ 6.108217209000031, 10.733155387000068 ],
							[ 6.110244120000061, 10.735858020000023 ],
							[ 6.113036169000054, 10.739580872000033 ],
							[ 6.110931392000055, 10.744843745000026 ],
							[ 6.109823732000052, 10.747612890000028 ],
							[ 6.10913086100004, 10.753007687000036 ],
							[ 6.108217330000059, 10.759660818000043 ],
							[ 6.106454970000073, 10.761171558000058 ],
							[ 6.102594984000064, 10.764479717000029 ],
							[ 6.099381809000022, 10.770905754000069 ],
							[ 6.10179172900007, 10.775724143000048 ],
							[ 6.10368210300004, 10.779729880000048 ],
							[ 6.105807710000022, 10.78455901500007 ],
							[ 6.108217257000035, 10.790984929000047 ],
							[ 6.110626750000051, 10.796606749000034 ],
							[ 6.110626614000068, 10.801137889000074 ],
							[ 6.110626794000041, 10.803835991000028 ],
							[ 6.107414118000065, 10.80945858900003 ],
							[ 6.10821715700007, 10.813474448000022 ],
							[ 6.115446015000032, 10.813474853000059 ],
							[ 6.121067975000074, 10.814277429000072 ],
							[ 6.125083945000029, 10.818293329000028 ],
							[ 6.129099795000059, 10.82150623900003 ],
							[ 6.13109840900006, 10.824039584000047 ],
							[ 6.130255702000056, 10.828456818000063 ],
							[ 6.125887406000061, 10.833554163000031 ],
							[ 6.121067996000022, 10.835160487000053 ],
							[ 6.118658499000048, 10.837570326000048 ],
							[ 6.122674327000027, 10.84479910400006 ],
							[ 6.137132159000032, 10.852026710000075 ],
							[ 6.140905739000061, 10.852498841000056 ],
							[ 6.14355766400007, 10.85283062700006 ],
							[ 6.149982949000048, 10.852830934000053 ],
							[ 6.154562478000059, 10.855394147000027 ],
							[ 6.158817689000045, 10.86166546000004 ],
							[ 6.160006572000043, 10.863805921000051 ],
							[ 6.161300640000036, 10.866134800000054 ],
							[ 6.167300241000021, 10.866134933000069 ],
							[ 6.174082630000044, 10.868170006000071 ],
							[ 6.181543874000056, 10.872239222000076 ],
							[ 6.189004974000056, 10.871561322000048 ],
							[ 6.191718152000021, 10.868847625000058 ],
							[ 6.196465907000061, 10.868169928000043 ],
							[ 6.201214218000075, 10.863421559000074 ],
							[ 6.205962088000035, 10.863421222000056 ],
							[ 6.20799689100005, 10.86884800100006 ],
							[ 6.207996864000052, 10.873596074000034 ],
							[ 6.211388148000026, 10.879021751000039 ],
							[ 6.217492494000055, 10.888518568000052 ],
							[ 6.221562389000042, 10.891231366000056 ],
							[ 6.226310299000033, 10.895300778000035 ],
							[ 6.224183527000037, 10.902745125000024 ],
							[ 6.223597482000059, 10.904796368000063 ],
							[ 6.223597559000041, 10.910901104000061 ],
							[ 6.228345465000075, 10.919040489000054 ],
							[ 6.229701988000045, 10.927858524000044 ],
							[ 6.229701872000021, 10.933962694000058 ],
							[ 6.233093398000051, 10.940745357000026 ],
							[ 6.233771310000066, 10.945493946000056 ],
							[ 6.237162995000062, 10.946171867000032 ],
							[ 6.245302189000029, 10.948206704000029 ],
							[ 6.249372093000034, 10.951598358000069 ],
							[ 6.251406619000022, 10.95566761200007 ],
							[ 6.256833056000062, 10.960415742000066 ],
							[ 6.263615505000075, 10.96584222000007 ],
							[ 6.271755339000038, 10.963128993000055 ],
							[ 6.277181739000071, 10.965842102000067 ],
							[ 6.282607502000076, 10.97737201800004 ],
							[ 6.290747276000047, 10.986868883000056 ],
							[ 6.296851637000032, 10.995007650000048 ],
							[ 6.300920978000022, 11.001791204000028 ],
							[ 6.309060614000032, 11.009251822000067 ],
							[ 6.314486997000074, 11.011286880000057 ],
							[ 6.318026660000044, 11.013941622000061 ],
							[ 6.322626009000032, 11.017391098000076 ],
							[ 6.32330410700007, 11.020782274000055 ],
							[ 6.323304163000046, 11.025530844000059 ],
							[ 6.32805255300002, 11.026208679000035 ],
							[ 6.333197707000068, 11.021442326000056 ],
							[ 6.337548277000053, 11.021460593000029 ],
							[ 6.339848652000057, 11.022035354000025 ],
							[ 6.345687424000062, 11.023495440000033 ],
							[ 6.350523993000024, 11.021077214000059 ],
							[ 6.357218236000051, 11.01942647900006 ],
							[ 6.363869657000066, 11.020033780000063 ],
							[ 6.36942713600007, 11.026208989000054 ],
							[ 6.374853603000076, 11.030956480000043 ],
							[ 6.382992836000028, 11.033669678000024 ],
							[ 6.387741199000061, 11.033669258000032 ],
							[ 6.392488995000065, 11.033669347000057 ],
							[ 6.398593473000062, 11.032313182000053 ],
							[ 6.401984588000062, 11.030277962000071 ],
							[ 6.404697919000057, 11.030278319000047 ],
							[ 6.411480931000028, 11.030278277000036 ],
							[ 6.417585427000063, 11.024173795000024 ],
							[ 6.418263570000022, 11.018747376000022 ],
							[ 6.419996281000067, 11.014126685000065 ],
							[ 6.428437799000051, 11.007895337000036 ],
							[ 6.433864022000023, 11.003147058000025 ],
							[ 6.439290122000045, 10.998398697000027 ],
							[ 6.442681806000053, 10.999077544000045 ],
							[ 6.446751128000074, 11.003825344000063 ],
							[ 6.452177474000052, 11.002469294000036 ],
							[ 6.458282051000026, 10.999078019000024 ],
							[ 6.463708269000051, 11.001112018000072 ],
							[ 6.466420994000032, 11.009251866000056 ],
							[ 6.46777769800002, 11.016713405000075 ],
							[ 6.471847551000053, 11.021460369000067 ],
							[ 6.478630517000056, 11.022138827000049 ],
							[ 6.486769547000051, 11.020104576000051 ],
							[ 6.490576664000059, 11.017249240000069 ],
							[ 6.49219620100007, 11.016034070000046 ],
							[ 6.498978599000054, 11.014678025000023 ],
							[ 6.50779639600006, 11.013320842000041 ],
							[ 6.512356174000047, 11.017003867000028 ],
							[ 6.51661395800005, 11.020782552000071 ],
							[ 6.51729251200004, 11.026208617000066 ],
							[ 6.527466353000023, 11.027564878000021 ],
							[ 6.534927344000039, 11.027564821000055 ],
							[ 6.548492932000045, 11.028242962000036 ],
							[ 6.557310585000039, 11.025530795000066 ],
							[ 6.563415466000038, 11.024851668000053 ],
							[ 6.575624541000025, 11.023495942000068 ],
							[ 6.585120306000022, 11.030278438000039 ],
							[ 6.59161384500004, 11.037204483000039 ],
							[ 6.595294336000052, 11.041131067000038 ],
							[ 6.606825348000029, 11.047235722000039 ],
							[ 6.618053521000036, 11.047745914000075 ],
							[ 6.621747375000041, 11.047913707000021 ],
							[ 6.638026173000071, 11.049948464000067 ],
							[ 6.645487291000052, 11.048591806000047 ],
							[ 6.653626377000023, 11.054696051000064 ],
							[ 6.657696330000022, 11.054696341000067 ],
							[ 6.663122170000065, 11.05673136200005 ],
							[ 6.663800784000045, 11.061479667000071 ],
							[ 6.667870436000044, 11.062157881000076 ],
							[ 6.670583357000055, 11.060122747000037 ],
							[ 6.673296546000074, 11.058087575000059 ],
							[ 6.676009559000022, 11.057409033000056 ],
							[ 6.682114223000042, 11.062835960000029 ],
							[ 6.689575313000034, 11.068262202000028 ],
							[ 6.691609960000051, 11.073009269000067 ],
							[ 6.690253810000058, 11.076401770000075 ],
							[ 6.688897022000049, 11.08047115100004 ],
							[ 6.688218573000029, 11.085219404000043 ],
							[ 6.686861926000063, 11.090644869000073 ],
							[ 6.68889699500005, 11.09471505700003 ],
							[ 6.694323136000037, 11.094714929000077 ],
							[ 6.697036285000024, 11.096749510000052 ],
							[ 6.702462795000031, 11.094715156000063 ],
							[ 6.706532050000021, 11.095392939000021 ],
							[ 6.711280334000037, 11.102175528000032 ],
							[ 6.716028164000022, 11.106245906000026 ],
							[ 6.716028234000021, 11.110315269000068 ],
							[ 6.713993114000061, 11.113706480000076 ],
							[ 6.707888482000044, 11.114384684000072 ],
							[ 6.706532042000049, 11.117098070000054 ],
							[ 6.706531976000065, 11.121846020000021 ],
							[ 6.709988223000039, 11.122801935000041 ],
							[ 6.712281159000042, 11.123435928000049 ],
							[ 6.713992967000024, 11.131341651000071 ],
							[ 6.71806299900004, 11.136090367000065 ],
							[ 6.722132582000029, 11.140837904000023 ],
							[ 6.729593726000076, 11.143550590000075 ],
							[ 6.733663500000034, 11.147619945000031 ],
							[ 6.735020113000076, 11.153725479000059 ],
							[ 6.731628318000048, 11.161186301000043 ],
							[ 6.733663427000067, 11.165255645000059 ],
							[ 6.73908952000005, 11.169325884000045 ],
							[ 6.742463699000041, 11.174532835000036 ],
							[ 6.73976804800003, 11.179499414000077 ],
							[ 6.738411356000029, 11.190352675000042 ],
							[ 6.734341627000049, 11.195100059000026 ],
							[ 6.732985039000027, 11.201883417000033 ],
							[ 6.736715848000074, 11.209343654000065 ],
							[ 6.743498268000053, 11.216805462000025 ],
							[ 6.744855051000059, 11.222909783000034 ],
							[ 6.744854970000063, 11.22833630100007 ],
							[ 6.746890166000071, 11.233761610000045 ],
							[ 6.746889994000071, 11.237832064000031 ],
							[ 6.745533370000032, 11.243258242000024 ],
							[ 6.749602791000029, 11.248005988000045 ],
							[ 6.752994597000054, 11.254110179000065 ],
							[ 6.754351126000074, 11.262928263000049 ],
							[ 6.759637865000059, 11.267686089000051 ],
							[ 6.76113358300006, 11.269032529000071 ],
							[ 6.773342658000047, 11.275815100000045 ],
							[ 6.783516950000035, 11.281241484000077 ],
							[ 6.789621292000049, 11.288024839000059 ],
							[ 6.799117683000077, 11.294807339000045 ],
							[ 6.808613193000042, 11.300234069000055 ],
							[ 6.816164862000051, 11.309980607000057 ],
							[ 6.823485423000022, 11.309167033000051 ],
							[ 6.828365914000074, 11.30754010000004 ],
							[ 6.838940161000039, 11.318114078000065 ],
							[ 6.84788754300007, 11.325435642000059 ],
							[ 6.851141375000054, 11.331942634000029 ],
							[ 6.855208376000064, 11.336822526000049 ],
							[ 6.864155664000066, 11.340076672000066 ],
							[ 6.869036103000042, 11.351464472000032 ],
							[ 6.874730193000062, 11.364479012000061 ],
							[ 6.882863974000031, 11.374239888000034 ],
							[ 6.885452352000073, 11.377198402000033 ],
							[ 6.888557998000067, 11.380746882000039 ],
							[ 6.893651122000051, 11.379879697000035 ],
							[ 6.895823030000031, 11.379460348000066 ],
							[ 6.898165088000042, 11.38177945800004 ],
							[ 6.91107315000005, 11.383029833000023 ],
							[ 6.919289111000069, 11.38307936800004 ],
							[ 6.923988937000047, 11.380810010000062 ],
							[ 6.928690983000024, 11.377389667000045 ],
							[ 6.93456795700007, 11.37397013900005 ],
							[ 6.940439131000062, 11.372859732000052 ],
							[ 6.946319022000068, 11.368289668000045 ],
							[ 6.949848042000042, 11.364859731000024 ],
							[ 6.954548002000024, 11.362589988000025 ],
							[ 6.959248184000046, 11.360320185000035 ],
							[ 6.961600751000049, 11.358030105000068 ],
							[ 6.968645911000067, 11.356929642000068 ],
							[ 6.975688092000041, 11.356969820000074 ],
							[ 6.981551250000052, 11.359319460000052 ],
							[ 6.988589870000055, 11.360520477000023 ],
							[ 6.995633214000065, 11.360569875000067 ],
							[ 7.002672195000059, 11.361769516000038 ],
							[ 7.006193031000066, 11.361789576000035 ],
							[ 7.010892876000071, 11.359519675000058 ],
							[ 7.013245968000035, 11.357230401000038 ],
							[ 7.01560680800003, 11.351490005000073 ],
							[ 7.017968156000052, 11.345749595000029 ],
							[ 7.020326031000025, 11.341150480000067 ],
							[ 7.023858081000071, 11.336570044000041 ],
							[ 7.023881836000044, 11.326199786000075 ],
							[ 7.022725061000074, 11.319279481000024 ],
							[ 7.021561974000065, 11.314669499000047 ],
							[ 7.014535778000038, 11.30770029200005 ],
							[ 7.008699978000038, 11.293840695000029 ],
							[ 7.005187148000061, 11.29036049900003 ],
							[ 7.002844723000067, 11.288040346000059 ],
							[ 6.999334935000036, 11.283410295000067 ],
							[ 6.996993177000036, 11.281089552000026 ],
							[ 6.993476767000061, 11.278760185000067 ],
							[ 6.988791006000042, 11.275270652000074 ],
							[ 6.986447940000062, 11.272950407000053 ],
							[ 6.984106893000046, 11.270630140000037 ],
							[ 6.980596932000026, 11.265999902000033 ],
							[ 6.978253752000057, 11.263669976000074 ],
							[ 6.975914960000068, 11.260199295000064 ],
							[ 6.972404914000037, 11.255570660000046 ],
							[ 6.968896853000047, 11.249789943000053 ],
							[ 6.967733888000055, 11.245169583000063 ],
							[ 6.970087074000048, 11.242879995000067 ],
							[ 6.973610929000074, 11.241749994000031 ],
							[ 6.980647910000073, 11.244110047000049 ],
							[ 6.990035165000052, 11.245320446000051 ],
							[ 7.004116140000065, 11.246569438000051 ],
							[ 7.008815642000059, 11.244300143000032 ],
							[ 7.017043095000076, 11.239749990000064 ],
							[ 7.022920140000053, 11.23633019500005 ],
							[ 7.02879901700004, 11.231769516000043 ],
							[ 7.035851983000043, 11.227209887000072 ],
							[ 7.040541284000028, 11.229539985000031 ],
							[ 7.044062223000026, 11.22957043200006 ],
							[ 7.048761821000028, 11.227299484000071 ],
							[ 7.056985912000073, 11.223899948000053 ],
							[ 7.065213133000043, 11.219349871000077 ],
							[ 7.073440194000057, 11.214790557000072 ],
							[ 7.076973881000072, 11.209059776000061 ],
							[ 7.085200791000034, 11.204509809000058 ],
							[ 7.092256941000073, 11.198789694000027 ],
							[ 7.095783390000065, 11.196520021000026 ],
							[ 7.095794195000053, 11.19191003800006 ],
							[ 7.105207063000023, 11.181599648000031 ],
							[ 7.109909946000073, 11.17817980500007 ],
							[ 7.11343727700006, 11.175900376000072 ],
							[ 7.118143177000036, 11.171319883000024 ],
							[ 7.120498052000073, 11.167880079000042 ],
							[ 7.127552852000065, 11.162170573000026 ],
							[ 7.131087734000062, 11.156439824000074 ],
							[ 7.135794314000066, 11.151860255000031 ],
							[ 7.139326177000044, 11.147279808000064 ],
							[ 7.142851875000076, 11.145000645000039 ],
							[ 7.146380861000068, 11.141569938000032 ],
							[ 7.152254941000024, 11.139300155000058 ],
							[ 7.158125893000033, 11.138190251000026 ],
							[ 7.163991997000039, 11.139380469000059 ],
							[ 7.168683882000039, 11.140569872000071 ],
							[ 7.176891788000034, 11.144080140000028 ],
							[ 7.186267944000065, 11.149909710000031 ],
							[ 7.189777956000057, 11.154540288000021 ],
							[ 7.192120128000056, 11.156860408000057 ],
							[ 7.199157213000035, 11.15921011100005 ],
							[ 7.206192943000076, 11.161560229000031 ],
							[ 7.210877058000051, 11.166199666000068 ],
							[ 7.217633778000049, 11.170467622000047 ],
							[ 7.221198443000048, 11.172718848000045 ],
							[ 7.227282074000072, 11.177839962000064 ],
							[ 7.23079479300003, 11.181320335000066 ],
							[ 7.235478895000028, 11.185960008000052 ],
							[ 7.236638922000054, 11.191729335000048 ],
							[ 7.235454915000048, 11.196330346000025 ],
							[ 7.234273085000041, 11.199780396000051 ],
							[ 7.231916799000032, 11.203220349000048 ],
							[ 7.229556923000075, 11.208959328000049 ],
							[ 7.227201109000021, 11.212400380000076 ],
							[ 7.224844955000037, 11.215840267000033 ],
							[ 7.222486816000071, 11.220430519000047 ],
							[ 7.220132010000043, 11.223870400000067 ],
							[ 7.21542895500005, 11.227299513000048 ],
							[ 7.213074317000064, 11.230739350000022 ],
							[ 7.209530890000053, 11.23993043400003 ],
							[ 7.205999019000046, 11.244509819000029 ],
							[ 7.203642736000063, 11.247949583000036 ],
							[ 7.200446182000064, 11.252619240000058 ],
							[ 7.201271950000034, 11.257520288000023 ],
							[ 7.20598999200007, 11.261440642000025 ],
							[ 7.213848413000051, 11.269014241000036 ],
							[ 7.221998533000033, 11.270872202000021 ],
							[ 7.231464479000067, 11.272736239000039 ],
							[ 7.247775218000072, 11.274373066000067 ],
							[ 7.252765755000041, 11.276475305000076 ],
							[ 7.269842658000073, 11.283051365000063 ],
							[ 7.281678320000026, 11.284925158000021 ],
							[ 7.291954392000036, 11.282633988000043 ],
							[ 7.293352846000062, 11.282340949000059 ],
							[ 7.300130565000075, 11.279033939000044 ],
							[ 7.309096389000047, 11.275436733000049 ],
							[ 7.321493524000061, 11.26977671700007 ],
							[ 7.337560089000021, 11.267250508000075 ],
							[ 7.351780949000045, 11.265495151000039 ],
							[ 7.356248096000058, 11.267073856000025 ],
							[ 7.358858603000044, 11.271762655000032 ],
							[ 7.358637507000026, 11.274830300000076 ],
							[ 7.362001756000041, 11.28705975500003 ],
							[ 7.364335975000074, 11.292840016000071 ],
							[ 7.36432308600007, 11.298600331000046 ],
							[ 7.364308754000035, 11.304360619000022 ],
							[ 7.364293258000032, 11.311269597000035 ],
							[ 7.366620931000057, 11.319359619000068 ],
							[ 7.366612791000023, 11.322810150000066 ],
							[ 7.370109993000028, 11.33320036300006 ],
							[ 7.373620075000076, 11.337839866000024 ],
							[ 7.377128016000029, 11.343620093000027 ],
							[ 7.380634864000058, 11.349409888000025 ],
							[ 7.382971822000059, 11.354029475000061 ],
							[ 7.388813121000055, 11.365590251000071 ],
							[ 7.389976129000047, 11.370209782000074 ],
							[ 7.392312754000045, 11.374830449000058 ],
							[ 7.393476118000024, 11.379449966000038 ],
							[ 7.39581705300003, 11.381769858000041 ],
							[ 7.399332739000045, 11.384099911000021 ],
							[ 7.402851869000074, 11.385269963000042 ],
							[ 7.402882041000055, 11.387769724000066 ],
							[ 7.412221771000077, 11.393400052000061 ],
							[ 7.415738239000063, 11.395729932000052 ],
							[ 7.42393994400004, 11.401550040000075 ],
							[ 7.432147919000045, 11.405059634000054 ],
							[ 7.439185068000029, 11.407409790000031 ],
							[ 7.447398038000074, 11.408620025000062 ],
							[ 7.452093251000065, 11.408659980000039 ],
							[ 7.45561312500007, 11.408679733000042 ],
							[ 7.460306336000031, 11.409859765000022 ],
							[ 7.463827321000053, 11.409890164000046 ],
							[ 7.469691950000026, 11.411080589000051 ],
							[ 7.477912828000058, 11.408829475000061 ],
							[ 7.483789909000052, 11.405420499000058 ],
							[ 7.489668859000062, 11.400850327000057 ],
							[ 7.492026762000023, 11.396260145000042 ],
							[ 7.490868036000052, 11.390489464000041 ],
							[ 7.493227794000063, 11.384750590000067 ],
							[ 7.497938847000057, 11.377869719000046 ],
							[ 7.499861225000075, 11.370703512000034 ],
							[ 7.503495443000077, 11.364586060000022 ],
							[ 7.504619430000048, 11.359685693000074 ],
							[ 7.507055520000051, 11.356014999000024 ],
							[ 7.512151437000057, 11.356028685000069 ],
							[ 7.518558982000059, 11.357269085000041 ],
							[ 7.52372964400007, 11.35973281400004 ],
							[ 7.530137289000038, 11.360974160000069 ],
							[ 7.540253889000041, 11.358548355000039 ],
							[ 7.550296034000041, 11.353670969000063 ],
							[ 7.556515726000043, 11.348784648000048 ],
							[ 7.561387237000076, 11.341443113000025 ],
							[ 7.568843848000029, 11.335333609000031 ],
							[ 7.57757437600003, 11.329227708000076 ],
							[ 7.581358778000038, 11.328012724000075 ],
							[ 7.585143146000064, 11.326795422000032 ],
							[ 7.592712397000071, 11.324363002000041 ],
							[ 7.600243354000042, 11.32070531100004 ],
							[ 7.605189987000074, 11.315815068000063 ],
							[ 7.610060603000022, 11.308474564000051 ],
							[ 7.614856877000022, 11.29868212100007 ],
							[ 7.619765317000031, 11.292567136000059 ],
							[ 7.623437274000025, 11.287674176000053 ],
							[ 7.627147390000061, 11.284006258000034 ],
							[ 7.629555874000062, 11.283232609000038 ],
							[ 7.634716021000031, 11.281574106000051 ],
							[ 7.647493298000029, 11.282830023000031 ],
							[ 7.656523607000054, 11.286529788000053 ],
							[ 7.659634536000056, 11.288011792000077 ],
							[ 7.66142676100003, 11.289496901000064 ],
							[ 7.666057722000062, 11.292262960000073 ],
							[ 7.669753675000038, 11.296857949000071 ],
							[ 7.671289517000048, 11.299917932000028 ],
							[ 7.67530531500006, 11.301764628000058 ],
							[ 7.678086493000023, 11.302691011000036 ],
							[ 7.687049412000022, 11.30547163600005 ],
							[ 7.693538645000046, 11.307938224000054 ],
							[ 7.694348077000029, 11.308074413000043 ],
							[ 7.695416790000024, 11.308683317000032 ],
							[ 7.70441029400007, 11.311156250000067 ],
							[ 7.710855085000048, 11.313623526000072 ],
							[ 7.71726198500005, 11.314863904000049 ],
							[ 7.722358467000049, 11.314876394000066 ],
							[ 7.73000206200004, 11.314895854000042 ],
							[ 7.733823864000044, 11.314904395000042 ],
							[ 7.73891978100005, 11.314917785000034 ],
							[ 7.746639023000057, 11.317387618000055 ],
							[ 7.751922172000036, 11.323527367000054 ],
							[ 7.753082946000063, 11.327527070000031 ],
							[ 7.753182629000037, 11.329830395000045 ],
							[ 7.75192869600005, 11.334407887000054 ],
							[ 7.750368801000036, 11.338067899000066 ],
							[ 7.74788462600003, 11.340503205000061 ],
							[ 7.746320430000026, 11.345384746000036 ],
							[ 7.750003724000067, 11.354255472000034 ],
							[ 7.752759175000051, 11.362817349000068 ],
							[ 7.760160039000027, 11.370477749000031 ],
							[ 7.765136322000046, 11.374902571000064 ],
							[ 7.771317049000061, 11.373660273000041 ],
							[ 7.777465945000074, 11.371832991000076 ],
							[ 7.78024872900005, 11.375066662000052 ],
							[ 7.784145927000054, 11.377528232000031 ],
							[ 7.794300098000065, 11.376326799000026 ],
							[ 7.805765733000044, 11.376355386000057 ],
							[ 7.818505689000062, 11.376386550000063 ],
							[ 7.824950710000053, 11.378852957000049 ],
							[ 7.832557239000039, 11.377646348000042 ],
							[ 7.833902840000064, 11.377389545000028 ],
							[ 7.837649782000028, 11.37667299800006 ],
							[ 7.840686524000034, 11.379438059000051 ],
							[ 7.845255183000063, 11.380272708000064 ],
							[ 7.852325415000053, 11.378653840000027 ],
							[ 7.856686278000041, 11.379487687000051 ],
							[ 7.86291618000007, 11.380737435000071 ],
							[ 7.869554061000031, 11.384448931000065 ],
							[ 7.872446264000075, 11.389789743000051 ],
							[ 7.882387383000037, 11.400483848000022 ],
							[ 7.886536281000076, 11.402957515000026 ],
							[ 7.892715397000075, 11.404501522000032 ],
							[ 7.897386633000053, 11.408451862000049 ],
							[ 7.900600593000036, 11.411170055000071 ],
							[ 7.903408960000036, 11.413414128000056 ],
							[ 7.90734597200003, 11.416560410000045 ],
							[ 7.910757876000048, 11.418999418000055 ],
							[ 7.919785804000071, 11.427450107000027 ],
							[ 7.928936150000027, 11.432410301000061 ],
							[ 7.937152174000062, 11.441697817000033 ],
							[ 7.937652307000064, 11.441998142000045 ],
							[ 7.93919535200007, 11.444947375000027 ],
							[ 7.943322369000043, 11.455212938000045 ],
							[ 7.945805290000067, 11.458911771000032 ],
							[ 7.949545532000059, 11.45933257300004 ],
							[ 7.96118735500005, 11.458134746000042 ],
							[ 7.966793301000052, 11.457113593000031 ],
							[ 7.969383612000058, 11.458786443000065 ],
							[ 7.972239132000027, 11.460630637000065 ],
							[ 7.98363581600006, 11.46800034100005 ],
							[ 7.990206648000026, 11.469874319000041 ],
							[ 7.997484189000033, 11.47194954400004 ],
							[ 8.00896375700006, 11.476980121000054 ],
							[ 8.025098846000049, 11.48217007900007 ],
							[ 8.033166833000053, 11.484769868000058 ],
							[ 8.033634223000035, 11.484946229000059 ],
							[ 8.037340494000034, 11.484711784000069 ],
							[ 8.039702528000021, 11.484562511000036 ],
							[ 8.04699, 11.481787887000053 ],
							[ 8.052656029000048, 11.480605150000031 ],
							[ 8.062351544000023, 11.485019861000069 ],
							[ 8.081748901000026, 11.491852394000034 ],
							[ 8.087805609000043, 11.495857959000034 ],
							[ 8.091029718000073, 11.500654866000048 ],
							[ 8.101929827000049, 11.509061577000068 ],
							[ 8.120520973000055, 11.515491212000029 ],
							[ 8.137915016000022, 11.515532002000043 ],
							[ 8.148841120000043, 11.513561608000032 ],
							[ 8.158965866000074, 11.508396797000046 ],
							[ 8.159781, 11.505605248000052 ],
							[ 8.166679294000062, 11.496043354000051 ],
							[ 8.169531326000026, 11.487270111000043 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Kano',
				admin1Pcod: 'NG020',
				admin1RefN: 'Kano',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 8.244195,
				Shape_Area: 1.685607
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.305280595000056, 12.588029935000066 ],
							[ 8.310024046000024, 12.582329808000054 ],
							[ 8.311231683000074, 12.578889738000044 ],
							[ 8.311267582000028, 12.575440128000025 ],
							[ 8.311315448000073, 12.570830127000022 ],
							[ 8.310401863000038, 12.567397975000063 ],
							[ 8.311390105000044, 12.563920191000022 ],
							[ 8.311426003000065, 12.560469468000065 ],
							[ 8.311449914000036, 12.558170119000067 ],
							[ 8.311474993000047, 12.555870201000062 ],
							[ 8.311498359000041, 12.553560095000023 ],
							[ 8.311534818000041, 12.550109925000072 ],
							[ 8.310412189000033, 12.545490400000062 ],
							[ 8.311632247000034, 12.540900070000077 ],
							[ 8.311668121000025, 12.537449896000055 ],
							[ 8.311693216000037, 12.535139809000043 ],
							[ 8.311741615000074, 12.530540514000052 ],
							[ 8.311765543000035, 12.528240014000062 ],
							[ 8.311790635000023, 12.525930481000046 ],
							[ 8.311826507000035, 12.522479748000023 ],
							[ 8.311886904000062, 12.516720034000059 ],
							[ 8.313106264000055, 12.512129688000073 ],
							[ 8.315496501000041, 12.507559559000072 ],
							[ 8.316703835000055, 12.50412000700004 ],
							[ 8.320239896000032, 12.501850237000042 ],
							[ 8.322617501000025, 12.498430312000039 ],
							[ 8.326167080000062, 12.495019824000053 ],
							[ 8.327361793000023, 12.492729930000053 ],
							[ 8.329715444000044, 12.49160931800003 ],
							[ 8.333241701000077, 12.49050040000003 ],
							[ 8.336777506000033, 12.488240079000036 ],
							[ 8.339131114000054, 12.487110372000075 ],
							[ 8.341496575000065, 12.484840577000057 ],
							[ 8.343851278000045, 12.483719876000066 ],
							[ 8.346204835000037, 12.482600306000052 ],
							[ 8.35092372400004, 12.479200110000022 ],
							[ 8.355643127000064, 12.475799298000027 ],
							[ 8.358021934000021, 12.472370108000064 ],
							[ 8.360398966000048, 12.468950489000065 ],
							[ 8.360424311000031, 12.466649997000047 ],
							[ 8.361631643000067, 12.463210289000074 ],
							[ 8.36282661000007, 12.460920259000034 ],
							[ 8.365204105000032, 12.457489889000044 ],
							[ 8.367569770000046, 12.455220480000037 ],
							[ 8.367594484000051, 12.452919965000035 ],
							[ 8.369972464000057, 12.449489563000043 ],
							[ 8.372350964000077, 12.446069865000027 ],
							[ 8.374728268000069, 12.44264055900004 ],
							[ 8.381777778000071, 12.44042014200005 ],
							[ 8.383086381000055, 12.443739802000039 ],
							[ 8.385204977000058, 12.448519554000029 ],
							[ 8.385169217000055, 12.451979365000057 ],
							[ 8.385131759000046, 12.455430120000074 ],
							[ 8.385095413000045, 12.458889928000076 ],
							[ 8.386205858000039, 12.464659514000061 ],
							[ 8.387340361000042, 12.468119546000025 ],
							[ 8.387315755000031, 12.470430222000061 ],
							[ 8.388463151000053, 12.472739993000062 ],
							[ 8.388425705000031, 12.476200351000045 ],
							[ 8.389573109000025, 12.478510118000031 ],
							[ 8.391890807000038, 12.480839881000065 ],
							[ 8.403502451000065, 12.490199825000047 ],
							[ 8.405806357000074, 12.493679733000022 ],
							[ 8.409282916000052, 12.49717054100006 ],
							[ 8.412758963000044, 12.500670367000055 ],
							[ 8.413904890000026, 12.502989659000036 ],
							[ 8.41621010700004, 12.506469502000073 ],
							[ 8.420388256000024, 12.510219499000073 ],
							[ 8.422386972000027, 12.513678497000058 ],
							[ 8.421942925000053, 12.518050063000032 ],
							[ 8.424247751000053, 12.521529869000062 ],
							[ 8.428283939000039, 12.524061489000076 ],
							[ 8.428834925000046, 12.530800090000071 ],
							[ 8.429981051000027, 12.533119902000067 ],
							[ 8.431115006000027, 12.536589388000039 ],
							[ 8.43524837800004, 12.540653248000069 ],
							[ 8.436027803000059, 12.543185472000062 ],
							[ 8.439563870000029, 12.545840482000074 ],
							[ 8.437994994000064, 12.550490607000029 ],
							[ 8.439141238000047, 12.552800207000075 ],
							[ 8.441446473000042, 12.556279878000055 ],
							[ 8.44256849900006, 12.560899575000064 ],
							[ 8.444886470000029, 12.563240285000063 ],
							[ 8.446032234000029, 12.56554987800007 ],
							[ 8.448349690000043, 12.567879828000059 ],
							[ 8.450667195000051, 12.570210337000049 ],
							[ 8.454143764000037, 12.573710313000049 ],
							[ 8.457607209000059, 12.578359355000032 ],
							[ 8.459936896000045, 12.579540129000065 ],
							[ 8.461083373000065, 12.581850229000054 ],
							[ 8.463401031000046, 12.584180082000046 ],
							[ 8.463541590000034, 12.584322017000034 ],
							[ 8.465718140000035, 12.586520102000065 ],
							[ 8.46739178200005, 12.588199859000042 ],
							[ 8.470646683000041, 12.587199445000067 ],
							[ 8.480580117000045, 12.585446230000059 ],
							[ 8.499277819000042, 12.586031141000035 ],
							[ 8.513886494000076, 12.589536874000032 ],
							[ 8.529078654000045, 12.590704955000035 ],
							[ 8.531958498000051, 12.590027245000044 ],
							[ 8.534220215000062, 12.590262840000037 ],
							[ 8.534705548000034, 12.590291697000055 ],
							[ 8.540823278000062, 12.594144980000067 ],
							[ 8.545679863000032, 12.597422067000025 ],
							[ 8.549771543000077, 12.599188588000061 ],
							[ 8.553354026000022, 12.598438122000061 ],
							[ 8.557703895000031, 12.597687941000061 ],
							[ 8.561287524000022, 12.596434023000029 ],
							[ 8.564615297000046, 12.595347550000042 ],
							[ 8.571250722000059, 12.595560330000069 ],
							[ 8.58401592000007, 12.59714991900006 ],
							[ 8.597985329000039, 12.597599713000022 ],
							[ 8.60746172100005, 12.59325001600007 ],
							[ 8.615692988000035, 12.591190274000041 ],
							[ 8.619551367000042, 12.588951907000023 ],
							[ 8.627826867000067, 12.588951789000077 ],
							[ 8.637760036000032, 12.591873957000075 ],
							[ 8.648220847000061, 12.595221779000042 ],
							[ 8.648327773000062, 12.592180121000069 ],
							[ 8.657731213000034, 12.588839793000034 ],
							[ 8.661243678000062, 12.588879733000056 ],
							[ 8.663584943000046, 12.588910087000045 ],
							[ 8.667096826000034, 12.588949971000034 ],
							[ 8.670621845000028, 12.587840125000071 ],
							[ 8.682366202000026, 12.584530061000066 ],
							[ 8.684707421000041, 12.584560300000021 ],
							[ 8.687049807000051, 12.584589952000044 ],
							[ 8.690586044000042, 12.582329659000038 ],
							[ 8.695293409000044, 12.58007991900007 ],
							[ 8.697647065000069, 12.578960376000055 ],
							[ 8.700000716000034, 12.577830100000028 ],
							[ 8.702343041000063, 12.577859674000024 ],
							[ 8.704696661000071, 12.576740087000076 ],
							[ 8.714099173000022, 12.573399462000054 ],
							[ 8.716465140000025, 12.571120526000072 ],
							[ 8.719115976000069, 12.566590407000035 ],
							[ 8.717720719000056, 12.563079791000064 ],
							[ 8.716586198000073, 12.559610630000066 ],
							[ 8.716610969000044, 12.557310114000074 ],
							[ 8.714317215000051, 12.552680184000053 ],
							[ 8.712024678000034, 12.548040652000054 ],
							[ 8.708561117000045, 12.543399596000029 ],
							[ 8.70508466800004, 12.539899731000048 ],
							[ 8.70160885100006, 12.536399852000045 ],
							[ 8.699278651000043, 12.535220013000071 ],
							[ 8.696962644000052, 12.532890492000035 ],
							[ 8.694644957000037, 12.530559826000058 ],
							[ 8.691156274000036, 12.52822025200004 ],
							[ 8.687667645000033, 12.525870482000073 ],
							[ 8.68652161600005, 12.523549779000064 ],
							[ 8.684204055000066, 12.521220158000062 ],
							[ 8.683058054000071, 12.518910181000024 ],
							[ 8.681911512000056, 12.516590017000055 ],
							[ 8.681935371000066, 12.514290068000037 ],
							[ 8.680788834000055, 12.511969345000068 ],
							[ 8.679654264000021, 12.508500059000028 ],
							[ 8.678520273000061, 12.505040359000077 ],
							[ 8.678544163000026, 12.502730244000077 ],
							[ 8.678568607000045, 12.500430294000068 ],
							[ 8.67859248600007, 12.498129768000069 ],
							[ 8.678616347000059, 12.495829814000047 ],
							[ 8.679812285000025, 12.493539906000024 ],
							[ 8.681031469000061, 12.488950039000031 ],
							[ 8.681055905000051, 12.486639922000052 ],
							[ 8.681092264000029, 12.48318970300005 ],
							[ 8.683482240000046, 12.478609878000043 ],
							[ 8.684676915000068, 12.476329557000042 ],
							[ 8.68704299500007, 12.474049646000026 ],
							[ 8.690580441000066, 12.471790484000053 ],
							[ 8.694129150000037, 12.46837987400005 ],
							[ 8.702409601000056, 12.460419440000067 ],
							[ 8.707165548000035, 12.45357014700005 ],
							[ 8.709519490000048, 12.452449964000039 ],
							[ 8.711872841000059, 12.451319603000059 ],
							[ 8.715398088000029, 12.450209340000072 ],
							[ 8.718933975000027, 12.447950459000026 ],
							[ 8.721287844000074, 12.446829628000046 ],
							[ 8.723629901000038, 12.446860198000024 ],
							[ 8.728324660000055, 12.445759695000049 ],
							[ 8.732911314000035, 12.44329467700004 ],
							[ 8.739075747000072, 12.441601882000043 ],
							[ 8.743626377000055, 12.440352519000044 ],
							[ 8.747111520000033, 12.440466757000024 ],
							[ 8.750594933000059, 12.440582109000047 ],
							[ 8.752657891000069, 12.440650150000067 ],
							[ 8.755836573000067, 12.439705926000045 ],
							[ 8.759724474000052, 12.438550272000043 ],
							[ 8.762322529000073, 12.438633860000039 ],
							[ 8.764380305000032, 12.438699574000054 ],
							[ 8.767954767000049, 12.436479794000036 ],
							[ 8.772961714000076, 12.432490266000059 ],
							[ 8.782388788000048, 12.426839990000076 ],
							[ 8.78534770300007, 12.426295393000032 ],
							[ 8.788254922000021, 12.425760333000028 ],
							[ 8.791780267000036, 12.424650456000052 ],
							[ 8.794121039000061, 12.424680009000042 ],
							[ 8.797645773000056, 12.423569521000047 ],
							[ 8.802352686000063, 12.421330251000029 ],
							[ 8.805877925000061, 12.420220259000075 ],
							[ 8.811755976000029, 12.417989956000042 ],
							[ 8.815292090000071, 12.415730711000037 ],
							[ 8.81648743900007, 12.413439795000045 ],
							[ 8.816571929000077, 12.405379986000071 ],
							[ 8.815218837000032, 12.402947702000063 ],
							[ 8.811949880000043, 12.399570365000045 ],
							[ 8.810803471000042, 12.397250023000026 ],
							[ 8.806144737000068, 12.394890006000026 ],
							[ 8.801509734000035, 12.390229952000027 ],
							[ 8.798033860000032, 12.386730360000058 ],
							[ 8.793422772000042, 12.379770223000037 ],
							[ 8.791106008000042, 12.377440687000046 ],
							[ 8.788800295000044, 12.373959730000024 ],
							[ 8.787635975000057, 12.37219693000003 ],
							[ 8.785313411000061, 12.363358881000067 ],
							[ 8.782222647000026, 12.351161054000045 ],
							[ 8.781190897000045, 12.349635609000075 ],
							[ 8.774996438000073, 12.347091231000036 ],
							[ 8.765707634000023, 12.339970808000032 ],
							[ 8.761063706000073, 12.335902328000032 ],
							[ 8.759088317000021, 12.335415180000041 ],
							[ 8.758772107000027, 12.334450004000075 ],
							[ 8.75180831800003, 12.328599646000043 ],
							[ 8.749491005000039, 12.326269842000045 ],
							[ 8.742550976000075, 12.318129503000023 ],
							[ 8.740233226000043, 12.315799638000044 ],
							[ 8.739111008000066, 12.311180203000049 ],
							[ 8.738183121000077, 12.309301570000059 ],
							[ 8.737918252000043, 12.308888395000054 ],
							[ 8.736842015000036, 12.304240177000054 ],
							[ 8.735909692000064, 12.301398263000067 ],
							[ 8.735707554000044, 12.300780025000051 ],
							[ 8.73458600500004, 12.296160016000044 ],
							[ 8.733451014000025, 12.292690265000033 ],
							[ 8.732328391000067, 12.288069671000073 ],
							[ 8.732364802000063, 12.284620527000072 ],
							[ 8.731230453000023, 12.281149638000045 ],
							[ 8.731103216000065, 12.271685658000024 ],
							[ 8.729993795000041, 12.268698951000033 ],
							[ 8.729884401000049, 12.26611525900006 ],
							[ 8.729070609000075, 12.263850237000042 ],
							[ 8.726489031000028, 12.258837017000076 ],
							[ 8.727996921000056, 12.254630185000053 ],
							[ 8.726851495000062, 12.252309561000061 ],
							[ 8.726899176000074, 12.247699958000055 ],
							[ 8.725776777000021, 12.243079886000032 ],
							[ 8.724799911000048, 12.238014157000066 ],
							[ 8.723532601000045, 12.233849353000039 ],
							[ 8.722386141000072, 12.23153039400006 ],
							[ 8.722433858000045, 12.226920230000076 ],
							[ 8.721287983000025, 12.224610302000031 ],
							[ 8.72133631500003, 12.220000137000056 ],
							[ 8.721372826000049, 12.216549848000056 ],
							[ 8.724909826000044, 12.214289744000041 ],
							[ 8.727456154000038, 12.214614229000063 ],
							[ 8.73024575200003, 12.214969363000023 ],
							[ 8.740081950000047, 12.219079790000023 ],
							[ 8.742386805000024, 12.222559959000023 ],
							[ 8.744679383000062, 12.227189828000064 ],
							[ 8.745826574000034, 12.229510403000063 ],
							[ 8.748143241000037, 12.231840248000026 ],
							[ 8.750449383000046, 12.235320365000064 ],
							[ 8.753925068000058, 12.238819885000055 ],
							[ 8.756230164000044, 12.24229994500007 ],
							[ 8.757364067000026, 12.245770213000071 ],
							[ 8.760816580000039, 12.251569665000034 ],
							[ 8.761962786000026, 12.253890186000035 ],
							[ 8.765426547000061, 12.258529699000064 ],
							[ 8.767720226000051, 12.263170150000064 ],
							[ 8.770025046000058, 12.266650138000045 ],
							[ 8.771159160000025, 12.270119783000041 ],
							[ 8.773452991000056, 12.274750014000062 ],
							[ 8.775770128000033, 12.277079678000064 ],
							[ 8.779221525000025, 12.282880075000037 ],
							[ 8.782710116000032, 12.28521999700007 ],
							[ 8.787381142000072, 12.286430599000028 ],
							[ 8.790881408000075, 12.287630340000021 ],
							[ 8.794381102000045, 12.288820422000072 ],
							[ 8.796698464000031, 12.291149953000058 ],
							[ 8.797833494000031, 12.294619512000054 ],
							[ 8.798944280000057, 12.300390188000051 ],
							[ 8.798919470000044, 12.302689625000028 ],
							[ 8.801212149000037, 12.307330410000077 ],
							[ 8.807006011000055, 12.313150423000025 ],
							[ 8.809347227000046, 12.313179880000064 ],
							[ 8.815006496000024, 12.314420017000032 ],
							[ 8.818689066000047, 12.315600369000038 ],
							[ 8.824544127000024, 12.315669841000044 ],
							[ 8.836263372000076, 12.314659927000037 ],
							[ 8.837458836000053, 12.312370056000077 ],
							[ 8.841008027000044, 12.308959711000057 ],
							[ 8.842226848000053, 12.304369829000052 ],
							[ 8.843434211000044, 12.300930208000068 ],
							[ 8.843482704000053, 12.296320053000045 ],
							[ 8.843525181000075, 12.292351685000028 ],
							[ 8.844726532000038, 12.289429587000029 ],
							[ 8.847141107000027, 12.282549761000041 ],
							[ 8.849518570000043, 12.279120220000038 ],
							[ 8.85188458600004, 12.276849967000032 ],
							[ 8.863544665000063, 12.281600215000026 ],
							[ 8.864642266000033, 12.28851983900006 ],
							[ 8.865764874000035, 12.293140605000076 ],
							[ 8.866862552000043, 12.300060234000057 ],
							[ 8.868009595000046, 12.302379847000054 ],
							[ 8.869142472000021, 12.305839594000076 ],
							[ 8.87146099000006, 12.308170342000039 ],
							[ 8.879607933000045, 12.312879302000056 ],
							[ 8.881949153000051, 12.312909987000069 ],
							[ 8.891280219000066, 12.316469905000076 ],
							[ 8.894792348000067, 12.316519492000054 ],
							[ 8.897145961000035, 12.315389634000041 ],
							[ 8.901817197000071, 12.316599945000064 ],
							[ 8.905329894000033, 12.316639843000075 ],
							[ 8.907671123000057, 12.316670367000029 ],
							[ 8.907707026000026, 12.313220084000022 ],
							[ 8.906573194000032, 12.309749736000072 ],
							[ 8.905438219000075, 12.306279959000051 ],
							[ 8.903133568000044, 12.302800266000077 ],
							[ 8.899669927000048, 12.298150185000054 ],
							[ 8.897352418000025, 12.295820167000045 ],
							[ 8.892753556000059, 12.287710353000023 ],
							[ 8.891607548000025, 12.285389687000077 ],
							[ 8.890472228000021, 12.281920412000034 ],
							[ 8.89052126100006, 12.277319835000071 ],
							[ 8.889650877000065, 12.272796388000074 ],
							[ 8.895511333000059, 12.267729140000029 ],
							[ 8.896731382000041, 12.263888773000076 ],
							[ 8.898683040000037, 12.26028928900007 ],
							[ 8.902584833000049, 12.258369986000048 ],
							[ 8.904779528000063, 12.255490303000045 ],
							[ 8.905999205000057, 12.252610380000021 ],
							[ 8.910632393000071, 12.249010924000061 ],
							[ 8.911608792000038, 12.245650694000062 ],
							[ 8.913561296000069, 12.237490640000033 ],
							[ 8.923944436000056, 12.224144211000066 ],
							[ 8.925045150000074, 12.223620698000047 ],
							[ 8.927422630000024, 12.220190071000047 ],
							[ 8.930959521000034, 12.217929695000066 ],
							[ 8.933325787000058, 12.215660061000051 ],
							[ 8.935690861000069, 12.213379656000029 ],
							[ 8.938069317000043, 12.20996025200003 ],
							[ 8.941607188000035, 12.207699792000028 ],
							[ 8.945154947000049, 12.20429013100005 ],
							[ 8.947508817000028, 12.20316051900005 ],
							[ 8.951057623000054, 12.199749672000053 ],
							[ 8.954605779000076, 12.196339345000069 ],
							[ 8.956996698000069, 12.191759928000067 ],
							[ 8.958192135000047, 12.189479813000048 ],
							[ 8.961764470000048, 12.183759785000063 ],
							[ 8.962984189000053, 12.179169466000076 ],
							[ 8.963056621000021, 12.172260342000072 ],
							[ 8.964264120000053, 12.168820315000062 ],
							[ 8.965458851000051, 12.166540172000055 ],
							[ 8.96667846500003, 12.16194983500003 ],
							[ 8.967885894000062, 12.158510349000039 ],
							[ 8.967934507000052, 12.153900113000077 ],
							[ 8.967958229000033, 12.151600084000052 ],
							[ 8.965641222000045, 12.149269911000033 ],
							[ 8.963310949000061, 12.14809056100006 ],
							[ 8.960969664000061, 12.148059814000021 ],
							[ 8.957457435000038, 12.148019883000075 ],
							[ 8.953957406000029, 12.146820012000035 ],
							[ 8.951639956000065, 12.144490306000023 ],
							[ 8.950505085000032, 12.141020050000066 ],
							[ 8.950530065000066, 12.138719466000055 ],
							[ 8.950566104000075, 12.135270264000042 ],
							[ 8.949431273000073, 12.131799447000049 ],
							[ 8.948284813000043, 12.129480594000029 ],
							[ 8.945992537000052, 12.124850264000031 ],
							[ 8.94368577900002, 12.121370202000037 ],
							[ 8.942539971000031, 12.11905021900003 ],
							[ 8.941417486000034, 12.114430200000072 ],
							[ 8.942624997000053, 12.110989654000036 ],
							[ 8.943820276000054, 12.108699964000039 ],
							[ 8.946198530000061, 12.105279926000037 ],
							[ 8.94740596500003, 12.101839918000053 ],
							[ 8.947454248000042, 12.097230240000044 ],
							[ 8.947503094000069, 12.092630167000038 ],
							[ 8.948722122000049, 12.088040420000027 ],
							[ 8.947599653000054, 12.083419835000029 ],
							[ 8.946464431000038, 12.079950126000028 ],
							[ 8.945367042000044, 12.073030621000044 ],
							[ 8.946574378000037, 12.069589498000028 ],
							[ 8.948916318000045, 12.069620336000071 ],
							[ 8.950062575000061, 12.071930125000051 ],
							[ 8.954721501000051, 12.074290101000031 ],
							[ 8.957051288000059, 12.075470066000037 ],
							[ 8.959356152000055, 12.078950038000073 ],
							[ 8.963990921000061, 12.08362009800004 ],
							[ 8.966510571000072, 12.085522634000029 ],
							[ 8.968638506000048, 12.08712962900006 ],
							[ 8.970979979000049, 12.087160329000028 ],
							[ 8.974479487000053, 12.088350438000077 ],
							[ 8.976822115000061, 12.088379972000041 ],
							[ 8.979150900000036, 12.089559779000069 ],
							[ 8.981491807000054, 12.089589857000021 ],
							[ 8.985041468000077, 12.086170179000021 ],
							[ 8.986236377000068, 12.083889956000064 ],
							[ 8.989772710000068, 12.081629573000043 ],
							[ 8.992138859000022, 12.079350490000024 ],
							[ 8.994492349000041, 12.078230179000059 ],
							[ 8.996846388000051, 12.077110409000056 ],
							[ 9.000358495000057, 12.077150540000048 ],
							[ 9.007407906000026, 12.074930087000041 ],
							[ 9.010920575000057, 12.07497010000003 ],
							[ 9.015591354000037, 12.076180706000059 ],
							[ 9.020262765000041, 12.077389532000041 ],
							[ 9.024933608000026, 12.078600003000076 ],
							[ 9.028446314000064, 12.078639869000028 ],
							[ 9.031945929000074, 12.07983001100007 ],
							[ 9.034288488000072, 12.079859738000039 ],
							[ 9.036629919000063, 12.079890025000054 ],
							[ 9.03897076800007, 12.079919724000035 ],
							[ 9.041300867000075, 12.081100290000052 ],
							[ 9.043839428000069, 12.079520311000067 ],
							[ 9.046256096000036, 12.080413824000061 ],
							[ 9.048325732000023, 12.081179681000037 ],
							[ 9.050655287000041, 12.082360170000072 ],
							[ 9.053071264000039, 12.082150225000021 ],
							[ 9.055071548000058, 12.083029058000022 ],
							[ 9.058811126000023, 12.084670322000022 ],
							[ 9.065875830000039, 12.082549880000045 ],
							[ 9.068229674000065, 12.081420067000067 ],
							[ 9.070607152000036, 12.078000339000027 ],
							[ 9.071814456000027, 12.074559939000039 ],
							[ 9.073021737000033, 12.071120090000022 ],
							[ 9.074216621000062, 12.068829418000064 ],
							[ 9.074253201000033, 12.065380186000027 ],
							[ 9.074277948000031, 12.063070528000026 ],
							[ 9.079021286000057, 12.057370007000031 ],
							[ 9.080252588000064, 12.051630060000036 ],
							[ 9.080277317000025, 12.049330008000027 ],
							[ 9.080301463000069, 12.04703052900004 ],
							[ 9.082679744000075, 12.043599996000069 ],
							[ 9.08270387400006, 12.041299941000034 ],
							[ 9.083898597000029, 12.039009789000033 ],
							[ 9.085094452000021, 12.036720206000041 ],
							[ 9.087472038000044, 12.033300359000066 ],
							[ 9.088666705000037, 12.031009634000043 ],
							[ 9.091033045000074, 12.028730488000065 ],
							[ 9.093398774000036, 12.026459786000032 ],
							[ 9.096947903000057, 12.02305031700007 ],
							[ 9.101655191000077, 12.020799865000072 ],
							[ 9.104008524000051, 12.019679985000039 ],
							[ 9.106362424000054, 12.018560646000026 ],
							[ 9.108716288000039, 12.017430554000043 ],
							[ 9.111058467000021, 12.017459816000041 ],
							[ 9.113400647000049, 12.017490175000034 ],
							[ 9.115753338000047, 12.016369646000044 ],
							[ 9.118094365000047, 12.016400550000071 ],
							[ 9.120449343000075, 12.015270401000066 ],
							[ 9.125131364000026, 12.015330431000052 ],
							[ 9.135657210000034, 12.016610387000071 ],
							[ 9.139157140000066, 12.017800081000075 ],
							[ 9.142633352000075, 12.021300541000073 ],
							[ 9.143779549000044, 12.023610315000042 ],
							[ 9.145992454000066, 12.030041809000068 ],
							[ 9.146012417000065, 12.034000652000032 ],
							[ 9.145988676000059, 12.036310301000071 ],
							[ 9.145963803000029, 12.038610379000033 ],
							[ 9.147110098000041, 12.040929749000043 ],
							[ 9.14590362000007, 12.044360237000035 ],
							[ 9.145878732000028, 12.046669903000065 ],
							[ 9.147013474000062, 12.050140142000032 ],
							[ 9.146988607000026, 12.052439643000071 ],
							[ 9.146964885000045, 12.054739700000027 ],
							[ 9.148088652000069, 12.059359692000044 ],
							[ 9.148050486000045, 12.062810044000059 ],
							[ 9.148026212000048, 12.065119707000065 ],
							[ 9.150320136000062, 12.069749959000035 ],
							[ 9.150295270000072, 12.072050013000023 ],
							[ 9.151441700000021, 12.07437049300006 ],
							[ 9.152588145000038, 12.076689840000029 ],
							[ 9.152563875000055, 12.078989892000038 ],
							[ 9.153710338000053, 12.081299645000058 ],
							[ 9.158309, 12.089420461000032 ],
							[ 9.159455518000073, 12.091739784000026 ],
							[ 9.160601501000031, 12.09405064200007 ],
							[ 9.162907870000026, 12.097540085000048 ],
							[ 9.164053875000036, 12.09984980400003 ],
							[ 9.165187813000045, 12.103319974000044 ],
							[ 9.167517893000024, 12.104499748000023 ],
							[ 9.169846259000053, 12.105680638000024 ],
							[ 9.172164869000028, 12.108010119000028 ],
							[ 9.18379963700005, 12.115060027000027 ],
							[ 9.186129835000031, 12.11623968300006 ],
							[ 9.189629771000057, 12.117429449000042 ],
							[ 9.191972024000052, 12.117460461000064 ],
							[ 9.196629681000047, 12.11981964000006 ],
							[ 9.202483931000074, 12.119889959000034 ],
							[ 9.206009017000042, 12.118780096000023 ],
							[ 9.213033531000065, 12.118869927000048 ],
							[ 9.214240761000042, 12.115429630000051 ],
							[ 9.215459894000048, 12.110839571000042 ],
							[ 9.216667075000032, 12.107399838000049 ],
							[ 9.217862314000058, 12.105110395000054 ],
							[ 9.217886733000057, 12.102809787000069 ],
							[ 9.219081949000042, 12.10052033900007 ],
							[ 9.21913134700003, 12.095910049000054 ],
							[ 9.219154611000022, 12.093609994000076 ],
							[ 9.220349224000074, 12.091319987000077 ],
							[ 9.220374191000076, 12.089019930000063 ],
							[ 9.222395860000063, 12.084775711000077 ],
							[ 9.221641394000073, 12.079819566000026 ],
							[ 9.221666370000037, 12.077520637000077 ],
							[ 9.222861459000057, 12.075229489000037 ],
							[ 9.222897765000027, 12.071780244000024 ],
							[ 9.222933487000034, 12.068329870000071 ],
							[ 9.222994751000044, 12.062569844000052 ],
							[ 9.220726041000034, 12.055629812000063 ],
							[ 9.219579813000053, 12.053319706000025 ],
							[ 9.218481285000053, 12.046389700000077 ],
							[ 9.217358367000031, 12.041769916000021 ],
							[ 9.217383368000071, 12.039469852000025 ],
							[ 9.217419704000065, 12.036020043000065 ],
							[ 9.217443557000024, 12.033719961000031 ],
							[ 9.217468551000024, 12.031410306000055 ],
							[ 9.216345693000051, 12.02678938400004 ],
							[ 9.216370699000038, 12.024490447000062 ],
							[ 9.217565646000025, 12.022199862000036 ],
							[ 9.217589498000052, 12.019900370000073 ],
							[ 9.218784441000025, 12.017609780000043 ],
							[ 9.21999186000005, 12.014169439000057 ],
							[ 9.220015678000038, 12.011870501000033 ],
							[ 9.221211142000072, 12.009579902000041 ],
							[ 9.223588990000053, 12.006159751000041 ],
							[ 9.225967369000045, 12.002730548000045 ],
							[ 9.229492384000025, 12.001620452000054 ],
							[ 9.233003789000065, 12.001669680000077 ],
							[ 9.236528209000028, 12.000560091000068 ],
							[ 9.240040743000066, 12.000599643000044 ],
							[ 9.251157976000059, 12.001310400000023 ],
							[ 9.254138710000063, 11.996159654000053 ],
							[ 9.254176418000043, 11.992709830000024 ],
							[ 9.251858583000057, 11.990380266000045 ],
							[ 9.249541360000023, 11.988050705000035 ],
							[ 9.247211199000049, 11.986869732000059 ],
							[ 9.243723036000063, 11.98452001000004 ],
							[ 9.241394064000076, 11.983340129000055 ],
							[ 9.23906394200003, 11.982159698000032 ],
							[ 9.235576467000044, 11.979820043000075 ],
							[ 9.232087866000029, 11.977470210000035 ],
							[ 9.229758414000059, 11.976289700000052 ],
							[ 9.227440841000032, 11.973959981000064 ],
							[ 9.223965416000055, 11.970470484000032 ],
							[ 9.221647355000073, 11.96813959900004 ],
							[ 9.220513298000071, 11.964670123000076 ],
							[ 9.219390627000053, 11.96004976200004 ],
							[ 9.220646625000029, 11.951999641000043 ],
							[ 9.221841895000068, 11.949720342000035 ],
							[ 9.22187819100003, 11.946260328000051 ],
							[ 9.22426865400007, 11.941690403000052 ],
							[ 9.225475184000061, 11.938249452000036 ],
							[ 9.229036362000045, 11.933679357000074 ],
							[ 9.230232099000034, 11.931400583000027 ],
							[ 9.231426086000056, 11.929109383000025 ],
							[ 9.233804448000058, 11.92568009300004 ],
							[ 9.234999540000047, 11.923390583000071 ],
							[ 9.236231924000037, 11.917650081000033 ],
							[ 9.237426975000062, 11.915360544000066 ],
							[ 9.238681858000064, 11.90731994500004 ],
							[ 9.238719135000053, 11.90385991900007 ],
							[ 9.238755252000033, 11.90040950100007 ],
							[ 9.237607687000036, 11.898090393000075 ],
							[ 9.236461851000058, 11.895779757000071 ],
							[ 9.234156624000036, 11.89229014600005 ],
							[ 9.231851430000063, 11.88881010800003 ],
							[ 9.229546298000059, 11.885330076000059 ],
							[ 9.228399402000036, 11.883009805000029 ],
							[ 9.226081874000045, 11.880680063000057 ],
							[ 9.222618128000022, 11.876040199000045 ],
							[ 9.219118125000023, 11.874839967000071 ],
							[ 9.214470820000031, 11.871330144000069 ],
							[ 9.210970914000029, 11.87014 ],
							[ 9.207457360000035, 11.870100174000072 ],
							[ 9.205141190000063, 11.867759578000062 ],
							[ 9.201664710000045, 11.864270403000035 ],
							[ 9.200518625000029, 11.861950044000025 ],
							[ 9.199383959000045, 11.858480477000057 ],
							[ 9.198237348000021, 11.856169700000066 ],
							[ 9.197104452000076, 11.852700124000023 ],
							[ 9.193615056000056, 11.850350466000066 ],
							[ 9.191285948000029, 11.849169705000065 ],
							[ 9.188968877000036, 11.846839744000022 ],
							[ 9.184321662000059, 11.843330649000052 ],
							[ 9.18084503700004, 11.83984014400005 ],
							[ 9.177382258000023, 11.835189662000062 ],
							[ 9.175051592000045, 11.834009929000047 ],
							[ 9.171575725000025, 11.830510300000071 ],
							[ 9.17042938000003, 11.828200580000043 ],
							[ 9.165783149000049, 11.824690119000024 ],
							[ 9.161147946000028, 11.820019656000056 ],
							[ 9.157659670000044, 11.817680392000057 ],
							[ 9.155341871000076, 11.815349644000037 ],
							[ 9.151855400000045, 11.812999586000046 ],
							[ 9.148378236000042, 11.809510455000066 ],
							[ 9.144889575000036, 11.807160332000024 ],
							[ 9.143767198000035, 11.802539692000039 ],
							[ 9.142633268000054, 11.799080101000072 ],
							[ 9.14151094500005, 11.794460581000067 ],
							[ 9.140375912000025, 11.79098969000006 ],
							[ 9.140400822000061, 11.788689591000036 ],
							[ 9.140437301000077, 11.785229546000039 ],
							[ 9.141680577000045, 11.778340095000033 ],
							[ 9.142875765000042, 11.776049728000032 ],
							[ 9.145278255000051, 11.770319737000023 ],
							[ 9.146485547000054, 11.766880148000041 ],
							[ 9.147680085000047, 11.764590319000035 ],
							[ 9.147704928000053, 11.762290215000064 ],
							[ 9.147752924000031, 11.75769056300004 ],
							[ 9.146654926000053, 11.750760201000048 ],
							[ 9.146679211000048, 11.748460095000041 ],
							[ 9.146703496000043, 11.746159416000069 ],
							[ 9.150252222000063, 11.742749943000035 ],
							[ 9.160850793000066, 11.737119850000056 ],
							[ 9.163191721000032, 11.737150470000074 ],
							[ 9.16552172300004, 11.738329713000041 ],
							[ 9.167839093000055, 11.740659837000067 ],
							[ 9.171327838000025, 11.743000164000023 ],
							[ 9.17598627700005, 11.745360235000021 ],
							[ 9.178315229000077, 11.746539971000061 ],
							[ 9.180657365000059, 11.746569905000058 ],
							[ 9.186487258000056, 11.748940565000055 ],
							[ 9.190023640000049, 11.746680500000025 ],
							[ 9.192388627000071, 11.744409679000057 ],
							[ 9.194743333000076, 11.743289775000051 ],
							[ 9.199206359000073, 11.743344468000032 ],
							[ 9.202938744000051, 11.743389899000022 ],
							[ 9.207622431000061, 11.743439872000067 ],
							[ 9.209951474000036, 11.744620521000058 ],
							[ 9.213463808000029, 11.744660342000032 ],
							[ 9.215805936000038, 11.744690055000035 ],
							[ 9.218135013000051, 11.745869541000047 ],
							[ 9.222805687000061, 11.747079812000038 ],
							[ 9.226319191000073, 11.747120066000036 ],
							[ 9.228648318000069, 11.748299487000054 ],
							[ 9.230990471000041, 11.748330233000047 ],
							[ 9.234490427000026, 11.749520180000047 ],
							[ 9.236831442000039, 11.749549780000052 ],
							[ 9.240343827000061, 11.749589884000045 ],
							[ 9.242697836000048, 11.748470233000035 ],
							[ 9.245039991000056, 11.748499780000031 ],
							[ 9.248576003000039, 11.746239694000053 ],
							[ 9.249784522000027, 11.74279976300005 ],
							[ 9.250990728000033, 11.739359810000053 ],
							[ 9.251014911000027, 11.737059696000074 ],
							[ 9.251040241000055, 11.734760154000071 ],
							[ 9.249363211000059, 11.731368092000025 ],
							[ 9.24874599900005, 11.730119676000072 ],
							[ 9.249150061000023, 11.726076859000045 ],
							[ 9.246453532000032, 11.725489363000065 ],
							[ 9.245330691000049, 11.720870132000073 ],
							[ 9.243038327000022, 11.716239796000025 ],
							[ 9.241890736000073, 11.713919541000053 ],
							[ 9.240744870000071, 11.711600410000074 ],
							[ 9.238427215000058, 11.709269588000041 ],
							[ 9.237280828000053, 11.706959481000069 ],
							[ 9.236134450000065, 11.704640335000022 ],
							[ 9.236158162000038, 11.702340216000039 ],
							[ 9.233828725000023, 11.701160284000025 ],
							[ 9.230304945000057, 11.702269317000059 ],
							[ 9.226779976000046, 11.703369838000071 ],
							[ 9.224426218000076, 11.704500130000042 ],
							[ 9.222083767000072, 11.704469897000024 ],
							[ 9.219754963000071, 11.703289877000032 ],
							[ 9.217449968000039, 11.699810279000076 ],
							[ 9.218656337000027, 11.696369865000065 ],
							[ 9.219863829000076, 11.692930002000026 ],
							[ 9.221071280000046, 11.689490152000076 ],
							[ 9.223437419000049, 11.687210088000029 ],
							[ 9.224644816000023, 11.683770224000057 ],
							[ 9.228193357000066, 11.680359992000035 ],
							[ 9.228217119000021, 11.678059296000072 ],
							[ 9.228242007000063, 11.675760302000072 ],
							[ 9.229436889000056, 11.673469640000064 ],
							[ 9.229460642000049, 11.671170072000052 ],
							[ 9.230656648000036, 11.668880516000058 ],
							[ 9.230692819000069, 11.665430042000025 ],
							[ 9.231887652000069, 11.663140501000044 ],
							[ 9.231911382000021, 11.660829637000063 ],
							[ 9.234290268000052, 11.657409887000028 ],
							[ 9.237814762000028, 11.656299701000023 ],
							[ 9.241338651000035, 11.655190594000032 ],
							[ 9.243680783000059, 11.655220131000021 ],
							[ 9.249522, 11.656440040000064 ],
							[ 9.251851764000037, 11.657620451000071 ],
							[ 9.254182107000076, 11.658800273000054 ],
							[ 9.257681834000039, 11.659990582000034 ],
							[ 9.260022854000056, 11.660020017000022 ],
							[ 9.263524328000074, 11.661219879000043 ],
							[ 9.267024110000023, 11.662409526000033 ],
							[ 9.271695014000045, 11.663619744000073 ],
							[ 9.274037204000024, 11.663649663000058 ],
							[ 9.276389958000038, 11.662520184000073 ],
							[ 9.276427420000061, 11.659070276000023 ],
							[ 9.275279796000063, 11.656749541000067 ],
							[ 9.272987454000031, 11.652119370000037 ],
							[ 9.271851607000031, 11.648649974000023 ],
							[ 9.270706336000046, 11.646329796000032 ],
							[ 9.269559943000047, 11.644020352000041 ],
							[ 9.267278930000032, 11.638230179000061 ],
							[ 9.267327095000041, 11.633629916000075 ],
							[ 9.266193651000037, 11.63016049600003 ],
							[ 9.265046185000074, 11.62784029900007 ],
							[ 9.265070853000054, 11.625539591000063 ],
							[ 9.263936310000076, 11.622070165000025 ],
							[ 9.263960986000029, 11.619770030000041 ],
							[ 9.262815275000037, 11.617449823000072 ],
							[ 9.264020937000055, 11.614009797000051 ],
							[ 9.267569549000029, 11.610600301000034 ],
							[ 9.268765702000053, 11.608320221000042 ],
							[ 9.27113042700006, 11.606039859000077 ],
							[ 9.272337680000021, 11.602600376000055 ],
							[ 9.274704081000039, 11.600330154000062 ],
							[ 9.27706986000004, 11.59805030800004 ],
							[ 9.284130803000039, 11.594679817000042 ],
							[ 9.285326217000033, 11.592399700000044 ],
							[ 9.288851046000048, 11.59129069100004 ],
							[ 9.291216696000049, 11.58901019700005 ],
							[ 9.294741473000045, 11.587900003000073 ],
							[ 9.297106491000022, 11.585630210000033 ],
							[ 9.299448740000059, 11.585659970000052 ],
							[ 9.30533956000005, 11.582269667000048 ],
							[ 9.307681225000067, 11.58229994900006 ],
							[ 9.310022308000043, 11.582329642000047 ],
							[ 9.31587588900004, 11.582400413000073 ],
							[ 9.321754338000062, 11.580169818000059 ],
							[ 9.325278954000055, 11.579059351000069 ],
							[ 9.328804112000057, 11.577949406000073 ],
							[ 9.33114518900004, 11.577980096000033 ],
							[ 9.33581636200006, 11.579190087000029 ],
							[ 9.338158572000054, 11.579219602000023 ],
							[ 9.340512304000072, 11.57808970800005 ],
							[ 9.344036834000065, 11.576990356000067 ],
							[ 9.346378458000061, 11.577010212000062 ],
							[ 9.352244596000048, 11.575929493000046 ],
							[ 9.354598272000032, 11.574810248000063 ],
							[ 9.359975681000037, 11.575396634000072 ],
							[ 9.362829989000033, 11.571459740000023 ],
							[ 9.365196170000047, 11.569179327000029 ],
							[ 9.362553578000075, 11.564279386000067 ],
							[ 9.364122635000058, 11.559960102000048 ],
							[ 9.362975909000056, 11.557640195000033 ],
							[ 9.359512346000031, 11.552990191000049 ],
							[ 9.357183467000027, 11.551810447000037 ],
							[ 9.354866087000062, 11.549480344000074 ],
							[ 9.351376294000033, 11.547140025000033 ],
							[ 9.346693993000031, 11.547080016000052 ],
							[ 9.342022635000035, 11.545869586000038 ],
							[ 9.337388178000026, 11.541210284000044 ],
							[ 9.33507041200005, 11.538880612000071 ],
							[ 9.332753256000046, 11.536549795000042 ],
							[ 9.331618362000029, 11.533079980000025 ],
							[ 9.330496738000022, 11.52845981400003 ],
							[ 9.330520402000047, 11.526160216000051 ],
							[ 9.330545210000025, 11.52386006200004 ],
							[ 9.329398249000064, 11.521539491000055 ],
							[ 9.327080660000036, 11.519209765000028 ],
							[ 9.325934301000075, 11.516889718000073 ],
							[ 9.323641635000058, 11.512260388000072 ],
							[ 9.321299277000037, 11.512229653000077 ],
							[ 9.317799625000021, 11.511039931000028 ],
							[ 9.313128249000044, 11.509830265000062 ],
							[ 9.31078705300007, 11.509800036000058 ],
							[ 9.308457487000055, 11.508619984000063 ],
							[ 9.304956186000027, 11.507430142000032 ],
							[ 9.301456634000033, 11.506239710000045 ],
							[ 9.29796876100005, 11.503889847000039 ],
							[ 9.294468122000069, 11.502699925000059 ],
							[ 9.289833097000042, 11.498029835000068 ],
							[ 9.287504238000054, 11.49685022400007 ],
							[ 9.285174256000062, 11.495679634000055 ],
							[ 9.282857129000035, 11.493339465000076 ],
							[ 9.279369491000068, 11.491000144000054 ],
							[ 9.275868476000028, 11.48981004500007 ],
							[ 9.273539715000027, 11.488630327000067 ],
							[ 9.267710008000051, 11.486249698000051 ],
							[ 9.263050312000075, 11.483889610000062 ],
							[ 9.256037881000054, 11.482660526000075 ],
							[ 9.253696302000037, 11.482629939000049 ],
							[ 9.25020780400007, 11.480280179000033 ],
							[ 9.247866830000021, 11.480259717000024 ],
							[ 9.244365435000077, 11.479060267000023 ],
							[ 9.242024443000048, 11.479029586000024 ],
							[ 9.235012198000049, 11.477800125000044 ],
							[ 9.227998828000068, 11.476559793000035 ],
							[ 9.224522458000024, 11.473070165000024 ],
							[ 9.223376340000073, 11.470749814000044 ],
							[ 9.22223021800005, 11.46842945800006 ],
							[ 9.222302693000074, 11.461519937000048 ],
							[ 9.222338943000068, 11.458069988000034 ],
							[ 9.222387074000039, 11.453470229000061 ],
							[ 9.221277265000026, 11.447689752000031 ],
							[ 9.220143144000076, 11.444230314000038 ],
							[ 9.219044735000068, 11.437310181000043 ],
							[ 9.217898185000024, 11.434989807000022 ],
							[ 9.219093462000046, 11.432700251000028 ],
							[ 9.217946926000025, 11.430379876000075 ],
							[ 9.215641981000033, 11.426899648000074 ],
							[ 9.215665805000071, 11.424599486000034 ],
							[ 9.21336093900004, 11.421119798000063 ],
							[ 9.211091862000046, 11.414179954000076 ],
							[ 9.208810953000068, 11.408399516000031 ],
							[ 9.206505668000034, 11.404910172000029 ],
							[ 9.204200995000065, 11.401429866000058 ],
							[ 9.204224310000029, 11.399129702000039 ],
							[ 9.201955565000048, 11.392189811000037 ],
							[ 9.199627093000061, 11.391010194000046 ],
							[ 9.198492213000065, 11.38755012300004 ],
							[ 9.197345952000035, 11.385230232000026 ],
							[ 9.196211105000032, 11.381759413000054 ],
							[ 9.196235614000045, 11.379460375000065 ],
							[ 9.193954575000021, 11.373669649000021 ],
							[ 9.19283179100006, 11.369050148000042 ],
							[ 9.19286829300006, 11.365599608000025 ],
							[ 9.194075488000067, 11.362159768000026 ],
							[ 9.194100591000051, 11.359860152000067 ],
							[ 9.194149063000054, 11.355250201000047 ],
							[ 9.193549098000062, 11.350888068000074 ],
							[ 9.16117375500005, 11.332058707000044 ],
							[ 9.156778129000031, 11.330552007000051 ],
							[ 9.148207390000039, 11.327614641000025 ],
							[ 9.145411547000037, 11.329914226000028 ],
							[ 9.14290064100004, 11.331979750000073 ],
							[ 9.142353828000068, 11.328650347000064 ],
							[ 9.133366384000055, 11.319040570000027 ],
							[ 9.126544059000025, 11.314149955000062 ],
							[ 9.119681119000063, 11.310429352000028 ],
							[ 9.111695510000061, 11.30550945300007 ],
							[ 9.108324892000041, 11.301900025000066 ],
							[ 9.106117955000059, 11.298339947000045 ],
							[ 9.10387133100005, 11.295929681000075 ],
							[ 9.097049863000052, 11.291049810000061 ],
							[ 9.090146149000077, 11.288490331000048 ],
							[ 9.076297498000031, 11.284540046000075 ],
							[ 9.068311927000025, 11.279620335000061 ],
							[ 9.062612611000077, 11.275939701000027 ],
							[ 9.054626251000059, 11.271010657000033 ],
							[ 9.045515959000056, 11.264889957000037 ],
							[ 9.03869413700005, 11.259999969000035 ],
							[ 9.032995452000023, 11.256320005000077 ],
							[ 9.028541407000034, 11.250349868000058 ],
							[ 9.024048095000069, 11.24553964200004 ],
							[ 9.023047455000039, 11.240839913000059 ],
							[ 9.020840605000046, 11.237279887000057 ],
							[ 9.01747038600007, 11.233670173000064 ],
							[ 9.015304763000074, 11.228939962000027 ],
							[ 9.011934114000042, 11.225330195000026 ],
							[ 9.007440772000052, 11.220519761000048 ],
							[ 9.005193563000034, 11.218119624000053 ],
							[ 9.002946967000071, 11.21570984400006 ],
							[ 8.999535350000031, 11.213270114000068 ],
							[ 8.99504175900006, 11.208460099000035 ],
							[ 8.990507044000026, 11.204819623000049 ],
							[ 8.984889915000053, 11.19880934400004 ],
							[ 8.982683116000032, 11.195240046000038 ],
							[ 8.976984857000048, 11.191559780000034 ],
							[ 8.970122331000027, 11.187840342000072 ],
							[ 8.962135183000044, 11.182909833000053 ],
							[ 8.955313746000058, 11.178030154000055 ],
							[ 8.950778670000034, 11.174380131000021 ],
							[ 8.947367794000058, 11.171939914000063 ],
							[ 8.944038581000029, 11.167169533000049 ],
							[ 8.939543995000065, 11.16235996100005 ],
							[ 8.937297035000029, 11.159959958000059 ],
							[ 8.935090943000034, 11.156390368000075 ],
							[ 8.934048580000024, 11.152859945000046 ],
							[ 8.930678307000051, 11.149249977000068 ],
							[ 8.928432633000057, 11.146849364000047 ],
							[ 8.927430077000054, 11.142149901000039 ],
							[ 8.924100819000046, 11.137379907000025 ],
							[ 8.919607449000068, 11.132570083000076 ],
							[ 8.915111328000023, 11.127770367000039 ],
							[ 8.911824416000059, 11.121830128000056 ],
							[ 8.908493845000066, 11.117059987000061 ],
							[ 8.906328057000053, 11.112330231000044 ],
							[ 8.900710913000069, 11.10632034300005 ],
							[ 8.896217077000074, 11.101510207000047 ],
							[ 8.88931384600005, 11.098950365000064 ],
							[ 8.883614623000028, 11.095270343000038 ],
							[ 8.874504791000049, 11.089139724000063 ],
							[ 8.866314736000049, 11.090040319000025 ],
							[ 8.856920100000025, 11.092069773000048 ],
							[ 8.849854644000061, 11.094169424000029 ],
							[ 8.842787962000045, 11.096269496000048 ],
							[ 8.836885279000057, 11.098409511000057 ],
							[ 8.827490827000076, 11.100429721000069 ],
							[ 8.822860577000029, 11.099499772000058 ],
							[ 8.820546321000052, 11.099039868000034 ],
							[ 8.816011307000053, 11.095390436000059 ],
							[ 8.810353531000032, 11.090550442000051 ],
							[ 8.805900631000043, 11.084570215000042 ],
							[ 8.801487984000062, 11.077440139000032 ],
							[ 8.795992916000046, 11.067930098000033 ],
							[ 8.792744628000037, 11.060829932000047 ],
							[ 8.788454108000053, 11.05021017200005 ],
							[ 8.784244737000051, 11.037249517000021 ],
							[ 8.78011714400003, 11.021960388000025 ],
							[ 8.778155069000036, 11.011409655000023 ],
							[ 8.778440298000021, 11.003259910000054 ],
							[ 8.780174417000069, 10.98700068100004 ],
							[ 8.784114697000064, 10.974309695000045 ],
							[ 8.786849795000023, 10.962740128000064 ],
							[ 8.788258265000024, 10.955789418000052 ],
							[ 8.789707376000024, 10.947680418000061 ],
							[ 8.788697313000057, 10.942250513000033 ],
							[ 8.787745425000026, 10.937130258000025 ],
							[ 8.785742863000053, 10.927740047000043 ],
							[ 8.783576964000076, 10.923010088000069 ],
							[ 8.781412270000033, 10.918269932000044 ],
							[ 8.776958534000073, 10.912300027000072 ],
							[ 8.772627768000063, 10.902839390000054 ],
							[ 8.771707708000065, 10.895809868000072 ],
							[ 8.769745982000074, 10.885259577000056 ],
							[ 8.766619872000035, 10.874670119000029 ],
							[ 8.764617061000024, 10.86528032800004 ],
							[ 8.764780159000054, 10.860619495000037 ],
							[ 8.766309818000025, 10.850179839000077 ],
							[ 8.767881285000044, 10.83858020100007 ],
							[ 8.769371137000064, 10.829299935000051 ],
							[ 8.774353917000042, 10.820139907000055 ],
							[ 8.776844922000066, 10.815560134000066 ],
							[ 8.779417296000076, 10.808650212000032 ],
							[ 8.785644623000053, 10.797200106000048 ],
							[ 8.79046437900007, 10.792699561000063 ],
							[ 8.792169539000042, 10.79137157200006 ],
							[ 8.797653141000069, 10.787099574000024 ],
							[ 8.807210725000061, 10.780420336000077 ],
							[ 8.81560434000005, 10.773699595000039 ],
							[ 8.82158929700006, 10.769240448000062 ],
							[ 8.828980467000065, 10.757820103000029 ],
							[ 8.83151033200005, 10.75207996000006 ],
							[ 8.834166286000027, 10.74284003300005 ],
							[ 8.837983889000043, 10.733639584000059 ],
							[ 8.846459134000042, 10.724590208000052 ],
							[ 8.851278310000055, 10.720089980000068 ],
							[ 8.855218906000061, 10.707400300000074 ],
							[ 8.854420868000034, 10.696880147000059 ],
							[ 8.852458952000063, 10.686329604000036 ],
							[ 8.849332704000062, 10.675740134000023 ],
							[ 8.846124530000054, 10.667470059000038 ],
							[ 8.84067043400006, 10.656810041000028 ],
							[ 8.838545949000036, 10.650910126000042 ],
							[ 8.835419832000071, 10.640319959000067 ],
							[ 8.83108909300006, 10.630849675000036 ],
							[ 8.827800068000045, 10.624919790000035 ],
							[ 8.822385993000069, 10.613080242000024 ],
							[ 8.816890561000037, 10.603580386000033 ],
							[ 8.806861108000021, 10.590430222000066 ],
							[ 8.800201148000042, 10.58088996500004 ],
							[ 8.791132006000055, 10.573599906000027 ],
							[ 8.782103364000022, 10.565150165000034 ],
							[ 8.77940366200005, 10.562673690000054 ],
							[ 8.773358186000053, 10.566229798000052 ],
							[ 8.76814949200002, 10.562540819000048 ],
							[ 8.764178297000058, 10.557629809000048 ],
							[ 8.761517281000067, 10.55394573600006 ],
							[ 8.756309428000066, 10.550256588000025 ],
							[ 8.749901691000048, 10.549015997000026 ],
							[ 8.744806261000065, 10.549003486000061 ],
							[ 8.735888533000036, 10.548981402000038 ],
							[ 8.729155657000035, 10.550347334000037 ],
							[ 8.723261087000026, 10.552626869000051 ],
							[ 8.718164465000029, 10.552613991000044 ],
							[ 8.709359067000037, 10.556269779000047 ],
							[ 8.700554073000035, 10.559924229000046 ],
							[ 8.69429586800004, 10.563585097000043 ],
							[ 8.689275807000058, 10.566023579000046 ],
							[ 8.685603306000075, 10.570917111000028 ],
							[ 8.683167325000056, 10.574587810000025 ],
							[ 8.678334110000037, 10.58315360000006 ],
							[ 8.674773947000062, 10.591723258000059 ],
							[ 8.672376592000035, 10.596619473000032 ],
							[ 8.665069845000062, 10.607631777000051 ],
							[ 8.662708233000046, 10.613753514000052 ],
							[ 8.660497922000047, 10.624777099000028 ],
							[ 8.656901528000049, 10.632120974000031 ],
							[ 8.653228501000058, 10.637013610000054 ],
							[ 8.646971910000048, 10.640674793000073 ],
							[ 8.639402358000041, 10.643107336000071 ],
							[ 8.634344359000067, 10.644319452000047 ],
							[ 8.628048718000059, 10.646756406000065 ],
							[ 8.620442510000032, 10.647962395000036 ],
							[ 8.612835670000038, 10.649169912000048 ],
							[ 8.608976497000072, 10.647934657000064 ],
							[ 8.60122018900006, 10.644239502000062 ],
							[ 8.595937710000044, 10.638098614000057 ],
							[ 8.589407098000038, 10.634492696000052 ],
							[ 8.587440254000057, 10.633337830000073 ],
							[ 8.582617537000033, 10.632126645000028 ],
							[ 8.577014101000032, 10.630720115000031 ],
							[ 8.572357019000037, 10.630570127000055 ],
							[ 8.568939950000072, 10.629693176000046 ],
							[ 8.561961388000043, 10.62790003300006 ],
							[ 8.553771294000057, 10.62878979900006 ],
							[ 8.546705097000029, 10.63088994800006 ],
							[ 8.536105091000024, 10.634050639000066 ],
							[ 8.525383789000045, 10.64068981500003 ],
							[ 8.521645722000073, 10.647560387000055 ],
							[ 8.515582256000073, 10.654350474000069 ],
							[ 8.514254554000047, 10.658969597000066 ],
							[ 8.510313927000027, 10.671669845000054 ],
							[ 8.508171865000065, 10.695245965000026 ],
							[ 8.510712439000031, 10.695594562000053 ],
							[ 8.513689721000048, 10.69794679000006 ],
							[ 8.519595060000029, 10.702399061000051 ],
							[ 8.519010630000025, 10.705311614000038 ],
							[ 8.526841913000055, 10.711459115000025 ],
							[ 8.534523784000044, 10.712702895000064 ],
							[ 8.539582495000047, 10.711489868000058 ],
							[ 8.544640608000066, 10.710276773000032 ],
							[ 8.549698690000071, 10.709063608000065 ],
							[ 8.556031025000038, 10.707853606000072 ],
							[ 8.561164912000038, 10.709092050000038 ],
							[ 8.567609961000073, 10.711558097000022 ],
							[ 8.576564538000071, 10.712805876000061 ],
							[ 8.582972295000047, 10.714047174000029 ],
							[ 8.586776864000058, 10.714577486000053 ],
							[ 8.587931853000043, 10.718489378000072 ],
							[ 8.585277801000075, 10.727720228000067 ],
							[ 8.581378001000076, 10.739250440000035 ],
							[ 8.579644141000074, 10.755510156000071 ],
							[ 8.577030372000024, 10.763589986000056 ],
							[ 8.576745949000042, 10.77173970900003 ],
							[ 8.577665348000039, 10.778760164000062 ],
							[ 8.576094341000044, 10.790360176000036 ],
							[ 8.574523202000023, 10.801969782000072 ],
							[ 8.574237562000064, 10.810109873000044 ],
							[ 8.574913892000041, 10.82411946600007 ],
							[ 8.574587990000055, 10.833439845000044 ],
							[ 8.573179335000077, 10.840379637000069 ],
							[ 8.571526810000023, 10.854319848000046 ],
							[ 8.570036927000046, 10.863590273000057 ],
							[ 8.567423897000026, 10.871660373000054 ],
							[ 8.567220852000048, 10.877490101000035 ],
							[ 8.568979316000025, 10.893859903000077 ],
							[ 8.568489854000063, 10.907830231000048 ],
							[ 8.570492967000064, 10.917219931000034 ],
							[ 8.571494268000038, 10.921910247000028 ],
							[ 8.573578617000067, 10.928970407000065 ],
							[ 8.576827139000045, 10.936070320000056 ],
							[ 8.581279564000056, 10.942049972000063 ],
							[ 8.583363638000037, 10.949110067000049 ],
							[ 8.58657174800004, 10.957369809000056 ],
							[ 8.588533679000022, 10.967920468000045 ],
							[ 8.591822473000036, 10.973859695000044 ],
							[ 8.593906356000048, 10.980919716000074 ],
							[ 8.594908717000067, 10.985609969000052 ],
							[ 8.597114425000029, 10.989179367000077 ],
							[ 8.598157023000056, 10.992710204000048 ],
							[ 8.598995324000043, 11.002060013000062 ],
							[ 8.598587802000054, 11.013709810000023 ],
							[ 8.598262931000022, 11.023019930000032 ],
							[ 8.597814604000064, 11.035829647000071 ],
							[ 8.597122249000051, 11.05561996800003 ],
							[ 8.596919007000054, 11.061440607000065 ],
							[ 8.592017989000055, 11.068269745000066 ],
							[ 8.580050670000048, 11.077200124000058 ],
							[ 8.565796135000028, 11.084899644000075 ],
							[ 8.557524778000072, 11.088120669000034 ],
							[ 8.543351226000027, 11.09348983600006 ],
							[ 8.53716352400005, 11.103779677000034 ],
							[ 8.527361906000067, 11.117440235000061 ],
							[ 8.525872082000035, 11.126720489000036 ],
							[ 8.525234330000046, 11.131427429000041 ],
							[ 8.524300771000071, 11.138320040000053 ],
							[ 8.52052279000003, 11.146360473000072 ],
							[ 8.51686776400004, 11.150899626000069 ],
							[ 8.512828571000057, 11.152175809000028 ],
							[ 8.509972430000062, 11.153078334000043 ],
							[ 8.506288544000029, 11.152627082000038 ],
							[ 8.500945871000056, 11.154800335000061 ],
							[ 8.500406050000038, 11.155019969000023 ],
							[ 8.489766319000069, 11.159339760000023 ],
							[ 8.482618562000027, 11.163769774000059 ],
							[ 8.476390778000052, 11.175219603000073 ],
							[ 8.470081376000053, 11.188999919000025 ],
							[ 8.459400223000046, 11.194479871000055 ],
							[ 8.449720396000032, 11.204650072000049 ],
							[ 8.44124628000003, 11.213700282000048 ],
							[ 8.434015954000074, 11.220459916000038 ],
							[ 8.423335031000022, 11.225939951000043 ],
							[ 8.415022968000073, 11.230330676000051 ],
							[ 8.399929744000076, 11.228670282000053 ],
							[ 8.389492773000029, 11.227160490000074 ],
							[ 8.382589542000062, 11.224610437000024 ],
							[ 8.373397904000058, 11.220809985000074 ],
							[ 8.366413026000032, 11.220580022000036 ],
							[ 8.359346214000027, 11.222679925000023 ],
							[ 8.345621367000035, 11.21523955400005 ],
							[ 8.33755313000006, 11.212650463000045 ],
							[ 8.329404020000027, 11.212380434000067 ],
							[ 8.321091688000024, 11.216770190000034 ],
							[ 8.306551885000033, 11.232609536000041 ],
							[ 8.302815523000049, 11.239489523000032 ],
							[ 8.297831631000065, 11.248649758000056 ],
							[ 8.294096100000047, 11.255520045000026 ],
							[ 8.288030764000041, 11.262310121000041 ],
							[ 8.282047073000058, 11.266779731000042 ],
							[ 8.276063212000054, 11.271249798000042 ],
							[ 8.275006680000047, 11.274088273000075 ],
							[ 8.265810011000042, 11.277698997000073 ],
							[ 8.263835903000029, 11.286796564000042 ],
							[ 8.271068011000068, 11.302531539000029 ],
							[ 8.274441638000042, 11.30619357300003 ],
							[ 8.273596765000036, 11.308460227000069 ],
							[ 8.267613274000041, 11.312930148000021 ],
							[ 8.258177886000055, 11.316120132000037 ],
							[ 8.241920507000032, 11.314419912000062 ],
							[ 8.231524241000045, 11.311750326000038 ],
							[ 8.219962853000027, 11.309040122000056 ],
							[ 8.215225144000044, 11.311220124000045 ],
							[ 8.207996644000048, 11.317979843000046 ],
							[ 8.200807669000028, 11.323570507000056 ],
							[ 8.193455813000071, 11.333820331000027 ],
							[ 8.187391269000045, 11.340609291000021 ],
							[ 8.17546479400005, 11.348380152000061 ],
							[ 8.167070533000071, 11.355099754000037 ],
							[ 8.163578289000043, 11.354990201000021 ],
							[ 8.158799159000068, 11.358329918000038 ],
							[ 8.154021085000068, 11.361669577000043 ],
							[ 8.14803693600004, 11.366140390000055 ],
							[ 8.14205380900006, 11.370599814000059 ],
							[ 8.13598823500007, 11.377400341000055 ],
							[ 8.132210599000075, 11.385430529000075 ],
							[ 8.130802138000035, 11.392379681000023 ],
							[ 8.132845728000063, 11.400609892000034 ],
							[ 8.136093880000033, 11.407699321000052 ],
							[ 8.139464357000065, 11.411310210000067 ],
							[ 8.142672736000065, 11.419570177000026 ],
							[ 8.148289628000043, 11.425580128000036 ],
							[ 8.152702221000027, 11.432720076000066 ],
							[ 8.157154924000054, 11.438689923000027 ],
							[ 8.16044435200007, 11.444629406000047 ],
							[ 8.164856855000039, 11.451769773000024 ],
							[ 8.16636520000003, 11.456933959000025 ],
							[ 8.166514052000025, 11.458438331000025 ],
							[ 8.166670176000025, 11.460011666000071 ],
							[ 8.166750766000064, 11.460824898000055 ],
							[ 8.167155899000022, 11.464917035000042 ],
							[ 8.16794698700005, 11.472501218000048 ],
							[ 8.169548748000068, 11.47968774800006 ],
							[ 8.169531326000026, 11.487270111000043 ],
							[ 8.166679294000062, 11.496043354000051 ],
							[ 8.159781, 11.505605248000052 ],
							[ 8.158965866000074, 11.508396797000046 ],
							[ 8.148841120000043, 11.513561608000032 ],
							[ 8.137915016000022, 11.515532002000043 ],
							[ 8.120520973000055, 11.515491212000029 ],
							[ 8.101929827000049, 11.509061577000068 ],
							[ 8.091029718000073, 11.500654866000048 ],
							[ 8.087805609000043, 11.495857959000034 ],
							[ 8.081748901000026, 11.491852394000034 ],
							[ 8.062351544000023, 11.485019861000069 ],
							[ 8.052656029000048, 11.480605150000031 ],
							[ 8.04699, 11.481787887000053 ],
							[ 8.039702528000021, 11.484562511000036 ],
							[ 8.037340494000034, 11.484711784000069 ],
							[ 8.033634223000035, 11.484946229000059 ],
							[ 8.033166833000053, 11.484769868000058 ],
							[ 8.025098846000049, 11.48217007900007 ],
							[ 8.00896375700006, 11.476980121000054 ],
							[ 7.997484189000033, 11.47194954400004 ],
							[ 7.990206648000026, 11.469874319000041 ],
							[ 7.98363581600006, 11.46800034100005 ],
							[ 7.972239132000027, 11.460630637000065 ],
							[ 7.969383612000058, 11.458786443000065 ],
							[ 7.966793301000052, 11.457113593000031 ],
							[ 7.96118735500005, 11.458134746000042 ],
							[ 7.949545532000059, 11.45933257300004 ],
							[ 7.945805290000067, 11.458911771000032 ],
							[ 7.943322369000043, 11.455212938000045 ],
							[ 7.93919535200007, 11.444947375000027 ],
							[ 7.937652307000064, 11.441998142000045 ],
							[ 7.937152174000062, 11.441697817000033 ],
							[ 7.928936150000027, 11.432410301000061 ],
							[ 7.919785804000071, 11.427450107000027 ],
							[ 7.910757876000048, 11.418999418000055 ],
							[ 7.90734597200003, 11.416560410000045 ],
							[ 7.903408960000036, 11.413414128000056 ],
							[ 7.900600593000036, 11.411170055000071 ],
							[ 7.897386633000053, 11.408451862000049 ],
							[ 7.892715397000075, 11.404501522000032 ],
							[ 7.886536281000076, 11.402957515000026 ],
							[ 7.882387383000037, 11.400483848000022 ],
							[ 7.872446264000075, 11.389789743000051 ],
							[ 7.869554061000031, 11.384448931000065 ],
							[ 7.86291618000007, 11.380737435000071 ],
							[ 7.856686278000041, 11.379487687000051 ],
							[ 7.852325415000053, 11.378653840000027 ],
							[ 7.845255183000063, 11.380272708000064 ],
							[ 7.840686524000034, 11.379438059000051 ],
							[ 7.837649782000028, 11.37667299800006 ],
							[ 7.833902840000064, 11.377389545000028 ],
							[ 7.832557239000039, 11.377646348000042 ],
							[ 7.824950710000053, 11.378852957000049 ],
							[ 7.818505689000062, 11.376386550000063 ],
							[ 7.805765733000044, 11.376355386000057 ],
							[ 7.794300098000065, 11.376326799000026 ],
							[ 7.784145927000054, 11.377528232000031 ],
							[ 7.78024872900005, 11.375066662000052 ],
							[ 7.777465945000074, 11.371832991000076 ],
							[ 7.771317049000061, 11.373660273000041 ],
							[ 7.765136322000046, 11.374902571000064 ],
							[ 7.751364306000028, 11.377670253000076 ],
							[ 7.747961588000067, 11.379777036000064 ],
							[ 7.737068173000068, 11.386520292000057 ],
							[ 7.728429568000024, 11.400230217000058 ],
							[ 7.71256312600002, 11.420689414000037 ],
							[ 7.70859099200004, 11.426064706000034 ],
							[ 7.69910610200003, 11.43889972900007 ],
							[ 7.698213709000072, 11.444707736000055 ],
							[ 7.697787451000067, 11.44748045700004 ],
							[ 7.693179153000074, 11.46126633800003 ],
							[ 7.692019680000044, 11.467420301000061 ],
							[ 7.689734965000071, 11.473560124000073 ],
							[ 7.68587664100005, 11.483920054000066 ],
							[ 7.686756262000074, 11.492110159000049 ],
							[ 7.687593916000026, 11.501460079000026 ],
							[ 7.689394253000046, 11.516670245000057 ],
							[ 7.693725197000049, 11.526140348000069 ],
							[ 7.699179240000035, 11.536800292000066 ],
							[ 7.704756022000026, 11.54397954500007 ],
							[ 7.711496788000034, 11.551190466000037 ],
							[ 7.720403308000073, 11.563140069000042 ],
							[ 7.724855652000031, 11.569109988000037 ],
							[ 7.725654251000037, 11.579629745000034 ],
							[ 7.728901754000049, 11.58672038800006 ],
							[ 7.734560246000058, 11.59156994600005 ],
							[ 7.745916695000062, 11.600099356000044 ],
							[ 7.759643304000065, 11.607540230000041 ],
							[ 7.771039723000058, 11.614910181000027 ],
							[ 7.781396192000045, 11.618739997000034 ],
							[ 7.797490979000031, 11.625089411000033 ],
							[ 7.813666726000065, 11.629119611000021 ],
							[ 7.825228453000022, 11.631829968000034 ],
							[ 7.841444059000025, 11.634690087000024 ],
							[ 7.850677034000057, 11.637319471000069 ],
							[ 7.862196128000051, 11.641189610000026 ],
							[ 7.871345958000063, 11.646149432000072 ],
							[ 7.878250171000047, 11.648710040000026 ],
							[ 7.884029583000029, 11.650059714000065 ],
							[ 7.89334369200003, 11.650369510000075 ],
							[ 7.900410178000072, 11.648259888000041 ],
							[ 7.906189634000043, 11.649619956000038 ],
							[ 7.909177646000046, 11.653900159000045 ],
							[ 7.911604152000052, 11.661450610000031 ],
							[ 7.909911402000034, 11.676549817000023 ],
							[ 7.903682963000051, 11.687999878000028 ],
							[ 7.895044677000044, 11.701700402000029 ],
							[ 7.888857862000066, 11.711990369000034 ],
							[ 7.885120662000077, 11.71885975500004 ],
							[ 7.881424707000065, 11.72457038500005 ],
							[ 7.882938633000037, 11.747929734000024 ],
							[ 7.880163873000072, 11.760660377000022 ],
							[ 7.876059844000054, 11.778010373000029 ],
							[ 7.874760311000045, 11.783614037000063 ],
							[ 7.87025090800006, 11.785819661000062 ],
							[ 7.865415363000068, 11.789292654000064 ],
							[ 7.862811609000062, 11.799113361000025 ],
							[ 7.861819456000035, 11.807671157000073 ],
							[ 7.861151365000069, 11.815595436000024 ],
							[ 7.859187423000037, 11.826369519000025 ],
							[ 7.859477950000041, 11.836199643000043 ],
							[ 7.86523403800004, 11.846997352000074 ],
							[ 7.865209127000071, 11.854923579000058 ],
							[ 7.86533475400006, 11.855808178000075 ],
							[ 7.864990549000026, 11.856616769000027 ],
							[ 7.863896732000057, 11.859189797000056 ],
							[ 7.859957380000026, 11.871890085000075 ],
							[ 7.856221409000057, 11.878760243000045 ],
							[ 7.848746732000052, 11.892499818000033 ],
							[ 7.84640322000007, 11.898014239000076 ],
							[ 7.845335719000047, 11.900525136000056 ],
							[ 7.842397091000066, 11.90743997800007 ],
							[ 7.841142387000048, 11.918060167000021 ],
							[ 7.840676798000061, 11.921997846000068 ],
							[ 7.839490496000053, 11.923973863000072 ],
							[ 7.839372069000035, 11.927459501000044 ],
							[ 7.837003122000056, 11.936659758000076 ],
							[ 7.836479193000059, 11.943380378000029 ],
							[ 7.834988843000076, 11.952659638000057 ],
							[ 7.833694930000036, 11.965359944000056 ],
							[ 7.833214060000046, 11.970080621000022 ],
							[ 7.837193093000053, 11.987156895000055 ],
							[ 7.837203654000064, 11.990107859000034 ],
							[ 7.837234401000046, 11.998896355000056 ],
							[ 7.837253488000044, 12.004363241000021 ],
							[ 7.836443300000042, 12.006635910000057 ],
							[ 7.835505755000042, 12.009268079000037 ],
							[ 7.837995970000065, 12.013859633000038 ],
							[ 7.837987154000075, 12.017309971000032 ],
							[ 7.840352133000067, 12.021993201000043 ],
							[ 7.84149190200003, 12.024249921000035 ],
							[ 7.841489313000068, 12.025595142000043 ],
							[ 7.842650435000053, 12.026918567000052 ],
							[ 7.847831990000032, 12.037209519000044 ],
							[ 7.853014425000026, 12.047499819000052 ],
							[ 7.853867025000056, 12.05263930600006 ],
							[ 7.853839829000037, 12.061201588000074 ],
							[ 7.853812634000064, 12.069763291000072 ],
							[ 7.856349569000031, 12.092032076000066 ],
							[ 7.859791631000064, 12.103173405000064 ],
							[ 7.863225833000058, 12.11688308600003 ],
							[ 7.860553108000033, 12.137423340000055 ],
							[ 7.853565093000043, 12.147676648000072 ],
							[ 7.850029988000074, 12.165645716000029 ],
							[ 7.84910615900003, 12.182766318000063 ],
							[ 7.853659074000063, 12.200246751000066 ],
							[ 7.86297785000005, 12.232158272000049 ],
							[ 7.864670235000062, 12.237952784000072 ],
							[ 7.868158349000055, 12.245371400000067 ],
							[ 7.869508247000056, 12.24824220000005 ],
							[ 7.870152284000028, 12.264682761000074 ],
							[ 7.870116483000061, 12.276109873000053 ],
							[ 7.870105073000047, 12.279751693000037 ],
							[ 7.876266713000064, 12.311962683000047 ],
							[ 7.878481493000038, 12.317184656000052 ],
							[ 7.881793229000039, 12.324993544000051 ],
							[ 7.893602029000022, 12.331878015000029 ],
							[ 7.904738476000034, 12.331226210000068 ],
							[ 7.912724067000056, 12.336900502000049 ],
							[ 7.91746325400004, 12.341816597000047 ],
							[ 7.918582958000059, 12.342456017000075 ],
							[ 7.925242863000051, 12.346260063000045 ],
							[ 7.927584867000064, 12.348580378000065 ],
							[ 7.940483277000055, 12.354430344000036 ],
							[ 7.942680076000045, 12.354445051000027 ],
							[ 7.956597035000073, 12.358401296000068 ],
							[ 7.971556322000026, 12.363524852000069 ],
							[ 7.983419303000062, 12.368130898000061 ],
							[ 7.991660603000071, 12.375267462000068 ],
							[ 8.003023600000063, 12.374281668000037 ],
							[ 8.004571012000042, 12.374794288000032 ],
							[ 8.016427852000049, 12.381940504000056 ],
							[ 8.025728205000064, 12.379932198000063 ],
							[ 8.033966526000029, 12.388592648000042 ],
							[ 8.042224729000054, 12.390138293000064 ],
							[ 8.063366195000071, 12.40188020100004 ],
							[ 8.071102888000041, 12.40545664900003 ],
							[ 8.080397793000031, 12.405479727000056 ],
							[ 8.088194129000044, 12.406458228000076 ],
							[ 8.092787909000037, 12.407034985000053 ],
							[ 8.105826618000037, 12.403611736000073 ],
							[ 8.10937778400006, 12.405130407000058 ],
							[ 8.115242880000039, 12.406330277000052 ],
							[ 8.123453346000076, 12.408690368000066 ],
							[ 8.131421114000034, 12.410795472000075 ],
							[ 8.133535656000049, 12.412210567000045 ],
							[ 8.139220482000042, 12.41829944400007 ],
							[ 8.145425, 12.423467961000028 ],
							[ 8.149413207000066, 12.426790076000032 ],
							[ 8.153439380000066, 12.429871565000042 ],
							[ 8.156250132000025, 12.433686404000071 ],
							[ 8.160938269000042, 12.440050076000034 ],
							[ 8.163267315000041, 12.448129848000065 ],
							[ 8.166772178000031, 12.455069304000062 ],
							[ 8.167934585000069, 12.459689961000038 ],
							[ 8.17261598500005, 12.46548002000003 ],
							[ 8.174955424000075, 12.468950154000026 ],
							[ 8.180137667000054, 12.471503953000024 ],
							[ 8.179628598000022, 12.478199969000059 ],
							[ 8.184671510000044, 12.485049968000055 ],
							[ 8.188990649000061, 12.489789875000042 ],
							[ 8.189619002000029, 12.496629032000044 ],
							[ 8.189963195000075, 12.500369851000073 ],
							[ 8.191460784000071, 12.505532943000048 ],
							[ 8.192279689000031, 12.508358998000062 ],
							[ 8.195174308000048, 12.518019394000021 ],
							[ 8.19729927000003, 12.523919972000044 ],
							[ 8.20175183200007, 12.529889918000038 ],
							[ 8.203959656000052, 12.533459575000052 ],
							[ 8.207287694000058, 12.538229899000044 ],
							[ 8.209920629000067, 12.540487442000028 ],
							[ 8.20997903600005, 12.548689717000059 ],
							[ 8.207618490000073, 12.554429818000074 ],
							[ 8.207610316000057, 12.557890203000056 ],
							[ 8.208767967000028, 12.564809521000029 ],
							[ 8.212272545000076, 12.571740005000038 ],
							[ 8.214611886000057, 12.575220042000069 ],
							[ 8.218124183000043, 12.578700268000034 ],
							[ 8.226329718000045, 12.583359706000067 ],
							[ 8.233366851000028, 12.585709800000075 ],
							[ 8.240411933000075, 12.584610141000041 ],
							[ 8.246279948000051, 12.584650293000038 ],
							[ 8.25449549700005, 12.584710334000022 ],
							[ 8.26506153400004, 12.583629661000032 ],
							[ 8.271050579000075, 12.583454989000074 ],
							[ 8.27341838600006, 12.583386176000033 ],
							[ 8.277726962000031, 12.585391132000041 ],
							[ 8.279722314000026, 12.586318705000053 ],
							[ 8.284998187000042, 12.590680223000049 ],
							[ 8.288505604000022, 12.596460286000024 ],
							[ 8.290033503000075, 12.599989680000022 ],
							[ 8.292304767000076, 12.597080496000046 ],
							[ 8.294683248000069, 12.593660218000025 ],
							[ 8.297048542000027, 12.591379984000071 ],
							[ 8.301756175000037, 12.589139715000044 ],
							[ 8.305280595000056, 12.588029935000066 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Katsina',
				admin1Pcod: 'NG021',
				admin1RefN: 'Katsina',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 9.105637,
				Shape_Area: 1.991947
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 7.818018262000066, 13.330421332000071 ],
							[ 7.820821852000051, 13.329409372000043 ],
							[ 7.838428289000035, 13.329529538000031 ],
							[ 7.847817184000064, 13.329589721000048 ],
							[ 7.853687756000056, 13.328479620000053 ],
							[ 7.860734180000065, 13.327380367000046 ],
							[ 7.867781330000071, 13.325120059000028 ],
							[ 7.874824778000061, 13.324020531000031 ],
							[ 7.879652117000035, 13.324049732000049 ],
							[ 7.883041640000044, 13.324080236000043 ],
							[ 7.891256799000075, 13.324130146000073 ],
							[ 7.904172743000061, 13.321920434000049 ],
							[ 7.910041828000033, 13.321960205000039 ],
							[ 7.919433988000037, 13.320870648000039 ],
							[ 7.925304758000038, 13.319760569000039 ],
							[ 7.937046775000056, 13.317540100000031 ],
							[ 7.940565828000047, 13.318710474000056 ],
							[ 7.961691911000059, 13.318860236000035 ],
							[ 7.974610535000068, 13.315489984000067 ],
							[ 7.986341993000053, 13.317879495000057 ],
							[ 7.99338724100005, 13.316769831000045 ],
							[ 8.001608616000055, 13.314530488000059 ],
							[ 8.010997701000065, 13.314590510000073 ],
							[ 8.015692539000042, 13.314619859000061 ],
							[ 8.021563489000073, 13.313509952000061 ],
							[ 8.02626140700005, 13.312390119000042 ],
							[ 8.042700976000049, 13.309049839000068 ],
							[ 8.04857535900004, 13.306790439000054 ],
							[ 8.054443622000065, 13.306830214000058 ],
							[ 8.059138359000031, 13.306860146000076 ],
							[ 8.066014503000076, 13.304209808000053 ],
							[ 8.070885651000026, 13.302330014000063 ],
							[ 8.075136398000041, 13.301316913000051 ],
							[ 8.079827696000052, 13.301086153000028 ],
							[ 8.089832143000024, 13.302338844000076 ],
							[ 8.093585272000041, 13.302347978000057 ],
							[ 8.097350148000032, 13.297445023000023 ],
							[ 8.099239283000031, 13.292538060000027 ],
							[ 8.102326760000039, 13.28895705900004 ],
							[ 8.108484156000031, 13.285309924000046 ],
							[ 8.113182017000042, 13.28419010600004 ],
							[ 8.119064611000056, 13.278469975000064 ],
							[ 8.129637919000061, 13.273940288000063 ],
							[ 8.13433281600004, 13.273969849000025 ],
							[ 8.141380069000036, 13.271710594000069 ],
							[ 8.144901480000044, 13.271740134000027 ],
							[ 8.149599048000027, 13.270620449000035 ],
							[ 8.159007022000026, 13.262619706000066 ],
							[ 8.162536403000047, 13.259189456000058 ],
							[ 8.169603515000063, 13.248869781000053 ],
							[ 8.171958180000047, 13.24543004800006 ],
							[ 8.181925943000067, 13.237473458000068 ],
							[ 8.199455779000061, 13.228915993000044 ],
							[ 8.213228456000024, 13.22219205500005 ],
							[ 8.230130765000069, 13.213631818000067 ],
							[ 8.238890735000041, 13.211194013000068 ],
							[ 8.254542869000034, 13.20201602000003 ],
							[ 8.278342764000058, 13.183030682000037 ],
							[ 8.292121875000021, 13.17139168500006 ],
							[ 8.29901860800004, 13.161581587000057 ],
							[ 8.316565288000049, 13.140739671000063 ],
							[ 8.337853236000058, 13.123587546000067 ],
							[ 8.352874318000033, 13.114404256000057 ],
							[ 8.361012287000051, 13.108279095000057 ],
							[ 8.382920384000045, 13.09173892900003 ],
							[ 8.390429562000065, 13.087453777000064 ],
							[ 8.399200166000071, 13.075802552000027 ],
							[ 8.401108151000074, 13.07091321300004 ],
							[ 8.40477966900005, 13.06386014900005 ],
							[ 8.408303269000044, 13.062729655000055 ],
							[ 8.414184439000053, 13.057009878000031 ],
							[ 8.418030938000072, 13.05389797600003 ],
							[ 8.425849086000028, 13.056341037000038 ],
							[ 8.452542461000064, 13.065493693000064 ],
							[ 8.481523802000027, 13.070069326000066 ],
							[ 8.499064696000062, 13.067781556000057 ],
							[ 8.501581435000048, 13.066686686000025 ],
							[ 8.51509780300006, 13.068080130000055 ],
							[ 8.524498106000067, 13.063529766000045 ],
							[ 8.529195798000046, 13.062410363000026 ],
							[ 8.535075274000064, 13.057849624000028 ],
							[ 8.54447564000003, 13.053299882000033 ],
							[ 8.551530628000023, 13.047590088000049 ],
							[ 8.559760384000072, 13.041890026000033 ],
							[ 8.571524516000068, 13.030450090000045 ],
							[ 8.576232807000054, 13.024720159000026 ],
							[ 8.578587277000054, 13.021280066000031 ],
							[ 8.590341440000032, 13.014450013000044 ],
							[ 8.592693256000075, 13.012160036000068 ],
							[ 8.597396670000023, 13.008740466000063 ],
							[ 8.603276129000051, 13.004170156000043 ],
							[ 8.611508189000062, 12.997309673000075 ],
							[ 8.617390792000037, 12.99160020100004 ],
							[ 8.619743456000037, 12.989310069000055 ],
							[ 8.632681024000021, 12.977879519000055 ],
							[ 8.63443576800006, 12.972449288000064 ],
							[ 8.639774592000038, 12.961771928000076 ],
							[ 8.642825104000053, 12.956433314000037 ],
							[ 8.645113244000072, 12.948043791000032 ],
							[ 8.646637924000061, 12.941180003000056 ],
							[ 8.661891990000072, 12.931265774000053 ],
							[ 8.677145243000041, 12.925164313000039 ],
							[ 8.69926192500003, 12.914486666000073 ],
							[ 8.709818127000062, 12.91382668600005 ],
							[ 8.722141179000062, 12.913724779000063 ],
							[ 8.739681950000033, 12.906860340000037 ],
							[ 8.757223269000065, 12.900759442000037 ],
							[ 8.77171396600005, 12.897708823000073 ],
							[ 8.783153693000031, 12.896182952000061 ],
							[ 8.788492445000031, 12.890844445000027 ],
							[ 8.806796269000074, 12.887794605000067 ],
							[ 8.82433684800003, 12.880168001000072 ],
							[ 8.839589905000025, 12.876354133000063 ],
							[ 8.848742552000033, 12.871778471000027 ],
							[ 8.867046327000025, 12.869490633000055 ],
							[ 8.885350342000038, 12.860338322000075 ],
							[ 8.922719983000036, 12.850424027000031 ],
							[ 8.944074759000046, 12.842797057000041 ],
							[ 8.966954350000037, 12.832882635000033 ],
							[ 8.977631689000077, 12.834407677000058 ],
							[ 8.990596731000039, 12.838984324000023 ],
							[ 9.012713538000071, 12.838221537000038 ],
							[ 9.037119121000046, 12.837458365000032 ],
							[ 9.045207744000038, 12.837206055000024 ],
							[ 9.046162707000065, 12.834420169000055 ],
							[ 9.048523770000031, 12.828679979000071 ],
							[ 9.049706227000058, 12.825229663000073 ],
							[ 9.049713208000071, 12.821769893000067 ],
							[ 9.047374560000037, 12.818300265000062 ],
							[ 9.045033219000061, 12.815979738000067 ],
							[ 9.041513186000032, 12.814800522000041 ],
							[ 9.037084566000033, 12.814490260000071 ],
							[ 9.032125507000046, 12.814740433000054 ],
							[ 9.026255401000071, 12.814700221000066 ],
							[ 9.022731557000043, 12.815830352000035 ],
							[ 9.018037997000022, 12.815789938000023 ],
							[ 9.01334270600006, 12.815759627000034 ],
							[ 9.009818811000059, 12.816889637000031 ],
							[ 9.006294914000023, 12.818019614000036 ],
							[ 9.002774432000024, 12.817990277000035 ],
							[ 8.999250501000063, 12.819120188000056 ],
							[ 8.995729425000036, 12.819089673000065 ],
							[ 8.991031766000049, 12.820210438000061 ],
							[ 8.986339325000074, 12.819029560000047 ],
							[ 8.980825486000072, 12.819569396000077 ],
							[ 8.977337888000022, 12.817230315000074 ],
							[ 8.970385453000063, 12.810230072000024 ],
							[ 8.969935656000075, 12.807399628000042 ],
							[ 8.967593152000063, 12.805080297000075 ],
							[ 8.966433265000035, 12.799309782000023 ],
							[ 8.967615263000027, 12.795860274000063 ],
							[ 8.96762543400007, 12.791250270000035 ],
							[ 8.965286042000059, 12.787779544000045 ],
							[ 8.964127510000026, 12.780859895000049 ],
							[ 8.961791789000074, 12.776229881000063 ],
							[ 8.961388399000043, 12.770520968000028 ],
							[ 8.959721321000075, 12.769369987000061 ],
							[ 8.960191775000055, 12.766113077000057 ],
							[ 8.95822507400004, 12.762225977000071 ],
							[ 8.957745484000043, 12.759941824000066 ],
							[ 8.956906339000057, 12.755949766000072 ],
							[ 8.954555620000065, 12.753851807000046 ],
							[ 8.952459471000054, 12.75197984700003 ],
							[ 8.947778489000029, 12.746179405000078 ],
							[ 8.947381234000034, 12.74339976300007 ],
							[ 8.946822100000077, 12.739492466000058 ],
							[ 8.943116177000036, 12.732330545000025 ],
							[ 8.938430046000065, 12.728840072000025 ],
							[ 8.936291511000036, 12.72742280600005 ],
							[ 8.93072647200006, 12.720189807000054 ],
							[ 8.92379986800006, 12.712890356000059 ],
							[ 8.913904722000041, 12.705103662000056 ],
							[ 8.902523717000065, 12.70315458500005 ],
							[ 8.88075400300005, 12.693417435000072 ],
							[ 8.871849040000029, 12.687575651000031 ],
							[ 8.863932459000068, 12.685140150000052 ],
							[ 8.855522045000043, 12.682217836000063 ],
							[ 8.847109963000037, 12.682214479000038 ],
							[ 8.836221765000062, 12.688048683000034 ],
							[ 8.82978664500007, 12.692911220000042 ],
							[ 8.824839353000073, 12.690962651000063 ],
							[ 8.818405971000061, 12.691932421000047 ],
							[ 8.81543791200005, 12.690471677000062 ],
							[ 8.811977652000053, 12.683171275000063 ],
							[ 8.808021330000031, 12.679763693000041 ],
							[ 8.797137490000068, 12.675865351000027 ],
							[ 8.792684177000069, 12.675862899000037 ],
							[ 8.785751945000072, 12.684130524000068 ],
							[ 8.776348016000043, 12.68801755100003 ],
							[ 8.767444252000075, 12.683146138000041 ],
							[ 8.756069253000021, 12.675354308000067 ],
							[ 8.752111307000064, 12.674378843000056 ],
							[ 8.742707618000054, 12.677778033000038 ],
							[ 8.738750389000074, 12.675828983000031 ],
							[ 8.732811580000032, 12.677771067000037 ],
							[ 8.731302086000028, 12.680290241000023 ],
							[ 8.727857321000045, 12.686039009000069 ],
							[ 8.72669516600007, 12.687607668000055 ],
							[ 8.723894486000063, 12.691387933000044 ],
							[ 8.714982090000035, 12.698192918000075 ],
							[ 8.710527330000048, 12.699649324000063 ],
							[ 8.701617881000061, 12.703047856000069 ],
							[ 8.698652879000065, 12.697693634000075 ],
							[ 8.699151028000074, 12.694287920000022 ],
							[ 8.697668356000065, 12.691854008000064 ],
							[ 8.694205099000044, 12.690878330000032 ],
							[ 8.687768979000055, 12.695251961000054 ],
							[ 8.68732026400005, 12.69854020300005 ],
							[ 8.682632482000031, 12.698269585000048 ],
							[ 8.680274919000055, 12.702860096000052 ],
							[ 8.677100962000054, 12.705171569000072 ],
							[ 8.670868951000045, 12.709709915000076 ],
							[ 8.661452558000065, 12.721159929000066 ],
							[ 8.65792285200007, 12.724590510000041 ],
							[ 8.650867246000075, 12.730310354000039 ],
							[ 8.647345353000048, 12.730279917000075 ],
							[ 8.635603915000047, 12.732500185000049 ],
							[ 8.623867051000047, 12.732420174000026 ],
							[ 8.616824928000028, 12.732370624000055 ],
							[ 8.612127220000048, 12.733490025000037 ],
							[ 8.607416293000028, 12.740369634000047 ],
							[ 8.607405524000058, 12.744979667000052 ],
							[ 8.607397158000026, 12.748440004000031 ],
							[ 8.609733314000039, 12.753060386000072 ],
							[ 8.605021426000064, 12.761090205000073 ],
							[ 8.599299663000068, 12.764896414000077 ],
							[ 8.59565010700004, 12.766685641000038 ],
							[ 8.595645737000041, 12.770577593000041 ],
							[ 8.597125282000036, 12.774958158000061 ],
							[ 8.599594553000031, 12.779826211000056 ],
							[ 8.600578849000044, 12.785179626000058 ],
							[ 8.600266560000023, 12.786410329000034 ],
							[ 8.595565635000071, 12.788680318000047 ],
							[ 8.595544940000025, 12.797900330000061 ],
							[ 8.593174662000024, 12.807100267000067 ],
							[ 8.590817543000071, 12.81169017600007 ],
							[ 8.58380710800003, 12.816209942000057 ],
							[ 8.581411634000062, 12.818530336000038 ],
							[ 8.576711893000038, 12.820810266000024 ],
							[ 8.572010973000033, 12.823079954000036 ],
							[ 8.562610651000057, 12.827619724000044 ],
							[ 8.55673695400003, 12.829890116000058 ],
							[ 8.546168312000077, 12.832119685000066 ],
							[ 8.536602266000045, 12.834138346000032 ],
							[ 8.535599537000053, 12.834349527000029 ],
							[ 8.527384, 12.834289476000038 ],
							[ 8.523862818000055, 12.834269740000025 ],
							[ 8.515644004000023, 12.835359689000029 ],
							[ 8.512128805000032, 12.833029753000062 ],
							[ 8.506997887000068, 12.835333655000056 ],
							[ 8.50301537200005, 12.833877573000052 ],
							[ 8.498859351000021, 12.833621166000057 ],
							[ 8.495636408000053, 12.834476106000068 ],
							[ 8.488647668000056, 12.836330367000073 ],
							[ 8.48699487500005, 12.836086862000059 ],
							[ 8.482548606000023, 12.834206768000058 ],
							[ 8.474811853000062, 12.831818630000043 ],
							[ 8.463205950000031, 12.828473411000061 ],
							[ 8.454985475000058, 12.826084163000075 ],
							[ 8.440487506000068, 12.816552830000035 ],
							[ 8.433715731000063, 12.816066910000075 ],
							[ 8.420164015000069, 12.819850473000031 ],
							[ 8.411452078000025, 12.822689256000046 ],
							[ 8.403708373000029, 12.824579147000065 ],
							[ 8.399477110000021, 12.826810126000055 ],
							[ 8.399010561000068, 12.827055680000058 ],
							[ 8.390081343000077, 12.825280225000029 ],
							[ 8.383039749000034, 12.825229648000061 ],
							[ 8.377170685000067, 12.825189397000031 ],
							[ 8.372470158000056, 12.827460512000073 ],
							[ 8.361901003000071, 12.829689948000066 ],
							[ 8.350153829000021, 12.834219781000058 ],
							[ 8.340101908000065, 12.839460123000038 ],
							[ 8.336050804000024, 12.842189552000036 ],
							[ 8.330176603000041, 12.844450152000036 ],
							[ 8.325337283000067, 12.841659595000067 ],
							[ 8.32316109900006, 12.840404290000038 ],
							[ 8.315740750000032, 12.841686179000021 ],
							[ 8.313750094000056, 12.842029384000057 ],
							[ 8.307291011000075, 12.841986372000065 ],
							[ 8.303171082000063, 12.841817117000062 ],
							[ 8.298336720000066, 12.840735202000076 ],
							[ 8.293803142000058, 12.839589964000027 ],
							[ 8.284683207000057, 12.842206934000046 ],
							[ 8.280342250000047, 12.843339789000026 ],
							[ 8.274499988000059, 12.842120033000072 ],
							[ 8.263275960000044, 12.83942974100006 ],
							[ 8.259314604000053, 12.83848011200007 ],
							[ 8.254632917000038, 12.838430682000023 ],
							[ 8.24909524800006, 12.837313379000022 ],
							[ 8.245572243000026, 12.837048092000032 ],
							[ 8.244757046000075, 12.836837399000046 ],
							[ 8.241321671000037, 12.837814576000028 ],
							[ 8.239461595000023, 12.838092118000077 ],
							[ 8.238567772000067, 12.838090292000061 ],
							[ 8.237458983000067, 12.838088025000047 ],
							[ 8.235171905000072, 12.837239879000037 ],
							[ 8.233458829000028, 12.835689524000031 ],
							[ 8.232030255000041, 12.83470243000005 ],
							[ 8.230027963000055, 12.834557623000023 ],
							[ 8.229026356000077, 12.834696229000031 ],
							[ 8.224731888000065, 12.836093482000024 ],
							[ 8.222870011000055, 12.83693308200003 ],
							[ 8.219933591000029, 12.837347271000056 ],
							[ 8.214825731000076, 12.837940475000039 ],
							[ 8.206617463000043, 12.838999900000033 ],
							[ 8.199592356000039, 12.838910294000073 ],
							[ 8.190497239000024, 12.838114984000072 ],
							[ 8.187178779000021, 12.839178350000054 ],
							[ 8.185387383000034, 12.838539984000022 ],
							[ 8.181814151000026, 12.837126569000077 ],
							[ 8.178812798000024, 12.835995139000033 ],
							[ 8.175240264000024, 12.834299740000063 ],
							[ 8.171089552000069, 12.833330111000066 ],
							[ 8.17058203800002, 12.831819428000074 ],
							[ 8.169419518000041, 12.827210222000076 ],
							[ 8.162396663000038, 12.819089696000049 ],
							[ 8.16475678300003, 12.813349988000027 ],
							[ 8.165944362000062, 12.807600292000075 ],
							[ 8.16792395300007, 12.804061889000025 ],
							[ 8.173015882000072, 12.794969561000073 ],
							[ 8.173026781000033, 12.79022339100004 ],
							[ 8.173032632000059, 12.787921791000031 ],
							[ 8.173037597000075, 12.785759717000076 ],
							[ 8.171875218000025, 12.781140321000066 ],
							[ 8.173548786000026, 12.77788255300004 ],
							[ 8.174180008000064, 12.770784171000059 ],
							[ 8.174598839000055, 12.766070553000077 ],
							[ 8.173488577000057, 12.760299888000077 ],
							[ 8.170755290000045, 12.758089925000036 ],
							[ 8.167126511000049, 12.754976273000068 ],
							[ 8.166095497000072, 12.743079959000056 ],
							[ 8.15908126100004, 12.731510270000058 ],
							[ 8.152664052000034, 12.719406210000045 ],
							[ 8.150855920000026, 12.716889166000044 ],
							[ 8.15174117600003, 12.712829700000043 ],
							[ 8.150605891000055, 12.70935997600003 ],
							[ 8.15065467900007, 12.704759629000023 ],
							[ 8.149988869000026, 12.701290396000047 ],
							[ 8.149544968000043, 12.698989995000034 ],
							[ 8.149580720000074, 12.695529710000073 ],
							[ 8.14730066900006, 12.689749506000055 ],
							[ 8.146166081000047, 12.686280318000058 ],
							[ 8.145032083000046, 12.682809999000028 ],
							[ 8.14509229500004, 12.677049819000047 ],
							[ 8.142413113000032, 12.670070933000034 ],
							[ 8.143693215000042, 12.667886911000039 ],
							[ 8.146409119000054, 12.663249740000026 ],
							[ 8.149850575000073, 12.663479650000056 ],
							[ 8.153371805000063, 12.66350009100006 ],
							[ 8.161589522000043, 12.662409660000037 ],
							[ 8.166290375000074, 12.660140032000072 ],
							[ 8.173338050000041, 12.657880076000026 ],
							[ 8.178038771000047, 12.655610299000045 ],
							[ 8.185082553000029, 12.654499818000033 ],
							[ 8.193304256000033, 12.65225956300003 ],
							[ 8.198008768000022, 12.647680555000022 ],
							[ 8.199191306000046, 12.644230052000069 ],
							[ 8.201544907000027, 12.64194985000006 ],
							[ 8.20624721300004, 12.638520421000067 ],
							[ 8.214460329000076, 12.639730451000048 ],
							[ 8.22267045500007, 12.642090002000032 ],
							[ 8.226188406000063, 12.643269620000069 ],
							[ 8.230100461000063, 12.645005379000054 ],
							[ 8.236741179000035, 12.647950053000045 ],
							[ 8.241435942000066, 12.647979988000031 ],
							[ 8.244957003000025, 12.648010301000056 ],
							[ 8.24967089200004, 12.639969707000034 ],
							[ 8.252026552000075, 12.636529875000065 ],
							[ 8.260248008000076, 12.634289844000023 ],
							[ 8.26728698200003, 12.635490175000029 ],
							[ 8.270801555000048, 12.637820184000077 ],
							[ 8.276662930000043, 12.641309503000059 ],
							[ 8.281357587000059, 12.641349672000047 ],
							[ 8.286055713000053, 12.640229918000045 ],
							[ 8.288421845000073, 12.632180363000032 ],
							[ 8.291969444000074, 12.620680002000029 ],
							[ 8.291990989000055, 12.611470054000051 ],
							[ 8.29200746600003, 12.604550424000024 ],
							[ 8.290033503000075, 12.599989680000022 ],
							[ 8.288505604000022, 12.596460286000024 ],
							[ 8.284998187000042, 12.590680223000049 ],
							[ 8.279722314000026, 12.586318705000053 ],
							[ 8.277726962000031, 12.585391132000041 ],
							[ 8.27341838600006, 12.583386176000033 ],
							[ 8.271050579000075, 12.583454989000074 ],
							[ 8.26506153400004, 12.583629661000032 ],
							[ 8.25449549700005, 12.584710334000022 ],
							[ 8.246279948000051, 12.584650293000038 ],
							[ 8.240411933000075, 12.584610141000041 ],
							[ 8.233366851000028, 12.585709800000075 ],
							[ 8.226329718000045, 12.583359706000067 ],
							[ 8.218124183000043, 12.578700268000034 ],
							[ 8.214611886000057, 12.575220042000069 ],
							[ 8.212272545000076, 12.571740005000038 ],
							[ 8.208767967000028, 12.564809521000029 ],
							[ 8.207610316000057, 12.557890203000056 ],
							[ 8.207618490000073, 12.554429818000074 ],
							[ 8.20997903600005, 12.548689717000059 ],
							[ 8.209920629000067, 12.540487442000028 ],
							[ 8.207287694000058, 12.538229899000044 ],
							[ 8.203959656000052, 12.533459575000052 ],
							[ 8.20175183200007, 12.529889918000038 ],
							[ 8.19729927000003, 12.523919972000044 ],
							[ 8.195174308000048, 12.518019394000021 ],
							[ 8.192279689000031, 12.508358998000062 ],
							[ 8.191460784000071, 12.505532943000048 ],
							[ 8.189963195000075, 12.500369851000073 ],
							[ 8.189619002000029, 12.496629032000044 ],
							[ 8.188990649000061, 12.489789875000042 ],
							[ 8.184671510000044, 12.485049968000055 ],
							[ 8.179628598000022, 12.478199969000059 ],
							[ 8.180137667000054, 12.471503953000024 ],
							[ 8.174955424000075, 12.468950154000026 ],
							[ 8.17261598500005, 12.46548002000003 ],
							[ 8.167934585000069, 12.459689961000038 ],
							[ 8.166772178000031, 12.455069304000062 ],
							[ 8.163267315000041, 12.448129848000065 ],
							[ 8.160938269000042, 12.440050076000034 ],
							[ 8.156250132000025, 12.433686404000071 ],
							[ 8.153439380000066, 12.429871565000042 ],
							[ 8.149413207000066, 12.426790076000032 ],
							[ 8.145425, 12.423467961000028 ],
							[ 8.139220482000042, 12.41829944400007 ],
							[ 8.133535656000049, 12.412210567000045 ],
							[ 8.131421114000034, 12.410795472000075 ],
							[ 8.123453346000076, 12.408690368000066 ],
							[ 8.115242880000039, 12.406330277000052 ],
							[ 8.10937778400006, 12.405130407000058 ],
							[ 8.105826618000037, 12.403611736000073 ],
							[ 8.092787909000037, 12.407034985000053 ],
							[ 8.088194129000044, 12.406458228000076 ],
							[ 8.080397793000031, 12.405479727000056 ],
							[ 8.071102888000041, 12.40545664900003 ],
							[ 8.063366195000071, 12.40188020100004 ],
							[ 8.042224729000054, 12.390138293000064 ],
							[ 8.033966526000029, 12.388592648000042 ],
							[ 8.025728205000064, 12.379932198000063 ],
							[ 8.016427852000049, 12.381940504000056 ],
							[ 8.004571012000042, 12.374794288000032 ],
							[ 8.003023600000063, 12.374281668000037 ],
							[ 7.991660603000071, 12.375267462000068 ],
							[ 7.983419303000062, 12.368130898000061 ],
							[ 7.971556322000026, 12.363524852000069 ],
							[ 7.956597035000073, 12.358401296000068 ],
							[ 7.942680076000045, 12.354445051000027 ],
							[ 7.940483277000055, 12.354430344000036 ],
							[ 7.927584867000064, 12.348580378000065 ],
							[ 7.925242863000051, 12.346260063000045 ],
							[ 7.918582958000059, 12.342456017000075 ],
							[ 7.91746325400004, 12.341816597000047 ],
							[ 7.912724067000056, 12.336900502000049 ],
							[ 7.904738476000034, 12.331226210000068 ],
							[ 7.893602029000022, 12.331878015000029 ],
							[ 7.881793229000039, 12.324993544000051 ],
							[ 7.878481493000038, 12.317184656000052 ],
							[ 7.876266713000064, 12.311962683000047 ],
							[ 7.870105073000047, 12.279751693000037 ],
							[ 7.870116483000061, 12.276109873000053 ],
							[ 7.870152284000028, 12.264682761000074 ],
							[ 7.869508247000056, 12.24824220000005 ],
							[ 7.868158349000055, 12.245371400000067 ],
							[ 7.864670235000062, 12.237952784000072 ],
							[ 7.86297785000005, 12.232158272000049 ],
							[ 7.853659074000063, 12.200246751000066 ],
							[ 7.84910615900003, 12.182766318000063 ],
							[ 7.850029988000074, 12.165645716000029 ],
							[ 7.853565093000043, 12.147676648000072 ],
							[ 7.860553108000033, 12.137423340000055 ],
							[ 7.863225833000058, 12.11688308600003 ],
							[ 7.859791631000064, 12.103173405000064 ],
							[ 7.856349569000031, 12.092032076000066 ],
							[ 7.853812634000064, 12.069763291000072 ],
							[ 7.853839829000037, 12.061201588000074 ],
							[ 7.853867025000056, 12.05263930600006 ],
							[ 7.853014425000026, 12.047499819000052 ],
							[ 7.847831990000032, 12.037209519000044 ],
							[ 7.842650435000053, 12.026918567000052 ],
							[ 7.841489313000068, 12.025595142000043 ],
							[ 7.84149190200003, 12.024249921000035 ],
							[ 7.840352133000067, 12.021993201000043 ],
							[ 7.837987154000075, 12.017309971000032 ],
							[ 7.837995970000065, 12.013859633000038 ],
							[ 7.835505755000042, 12.009268079000037 ],
							[ 7.836443300000042, 12.006635910000057 ],
							[ 7.837253488000044, 12.004363241000021 ],
							[ 7.837234401000046, 11.998896355000056 ],
							[ 7.837203654000064, 11.990107859000034 ],
							[ 7.837193093000053, 11.987156895000055 ],
							[ 7.833214060000046, 11.970080621000022 ],
							[ 7.833694930000036, 11.965359944000056 ],
							[ 7.834988843000076, 11.952659638000057 ],
							[ 7.836479193000059, 11.943380378000029 ],
							[ 7.837003122000056, 11.936659758000076 ],
							[ 7.839372069000035, 11.927459501000044 ],
							[ 7.839490496000053, 11.923973863000072 ],
							[ 7.840676798000061, 11.921997846000068 ],
							[ 7.841142387000048, 11.918060167000021 ],
							[ 7.842397091000066, 11.90743997800007 ],
							[ 7.845335719000047, 11.900525136000056 ],
							[ 7.84640322000007, 11.898014239000076 ],
							[ 7.848746732000052, 11.892499818000033 ],
							[ 7.856221409000057, 11.878760243000045 ],
							[ 7.859957380000026, 11.871890085000075 ],
							[ 7.863896732000057, 11.859189797000056 ],
							[ 7.864990549000026, 11.856616769000027 ],
							[ 7.86533475400006, 11.855808178000075 ],
							[ 7.865209127000071, 11.854923579000058 ],
							[ 7.86523403800004, 11.846997352000074 ],
							[ 7.859477950000041, 11.836199643000043 ],
							[ 7.859187423000037, 11.826369519000025 ],
							[ 7.861151365000069, 11.815595436000024 ],
							[ 7.861819456000035, 11.807671157000073 ],
							[ 7.862811609000062, 11.799113361000025 ],
							[ 7.865415363000068, 11.789292654000064 ],
							[ 7.87025090800006, 11.785819661000062 ],
							[ 7.874760311000045, 11.783614037000063 ],
							[ 7.876059844000054, 11.778010373000029 ],
							[ 7.880163873000072, 11.760660377000022 ],
							[ 7.882938633000037, 11.747929734000024 ],
							[ 7.881424707000065, 11.72457038500005 ],
							[ 7.885120662000077, 11.71885975500004 ],
							[ 7.888857862000066, 11.711990369000034 ],
							[ 7.895044677000044, 11.701700402000029 ],
							[ 7.903682963000051, 11.687999878000028 ],
							[ 7.909911402000034, 11.676549817000023 ],
							[ 7.911604152000052, 11.661450610000031 ],
							[ 7.909177646000046, 11.653900159000045 ],
							[ 7.906189634000043, 11.649619956000038 ],
							[ 7.900410178000072, 11.648259888000041 ],
							[ 7.89334369200003, 11.650369510000075 ],
							[ 7.884029583000029, 11.650059714000065 ],
							[ 7.878250171000047, 11.648710040000026 ],
							[ 7.871345958000063, 11.646149432000072 ],
							[ 7.862196128000051, 11.641189610000026 ],
							[ 7.850677034000057, 11.637319471000069 ],
							[ 7.841444059000025, 11.634690087000024 ],
							[ 7.825228453000022, 11.631829968000034 ],
							[ 7.813666726000065, 11.629119611000021 ],
							[ 7.797490979000031, 11.625089411000033 ],
							[ 7.781396192000045, 11.618739997000034 ],
							[ 7.771039723000058, 11.614910181000027 ],
							[ 7.759643304000065, 11.607540230000041 ],
							[ 7.745916695000062, 11.600099356000044 ],
							[ 7.734560246000058, 11.59156994600005 ],
							[ 7.728901754000049, 11.58672038800006 ],
							[ 7.725654251000037, 11.579629745000034 ],
							[ 7.724855652000031, 11.569109988000037 ],
							[ 7.720403308000073, 11.563140069000042 ],
							[ 7.711496788000034, 11.551190466000037 ],
							[ 7.704756022000026, 11.54397954500007 ],
							[ 7.699179240000035, 11.536800292000066 ],
							[ 7.693725197000049, 11.526140348000069 ],
							[ 7.689394253000046, 11.516670245000057 ],
							[ 7.687593916000026, 11.501460079000026 ],
							[ 7.686756262000074, 11.492110159000049 ],
							[ 7.68587664100005, 11.483920054000066 ],
							[ 7.689734965000071, 11.473560124000073 ],
							[ 7.692019680000044, 11.467420301000061 ],
							[ 7.693179153000074, 11.46126633800003 ],
							[ 7.697787451000067, 11.44748045700004 ],
							[ 7.698213709000072, 11.444707736000055 ],
							[ 7.69910610200003, 11.43889972900007 ],
							[ 7.70859099200004, 11.426064706000034 ],
							[ 7.71256312600002, 11.420689414000037 ],
							[ 7.728429568000024, 11.400230217000058 ],
							[ 7.737068173000068, 11.386520292000057 ],
							[ 7.747961588000067, 11.379777036000064 ],
							[ 7.751364306000028, 11.377670253000076 ],
							[ 7.765136322000046, 11.374902571000064 ],
							[ 7.760160039000027, 11.370477749000031 ],
							[ 7.752759175000051, 11.362817349000068 ],
							[ 7.750003724000067, 11.354255472000034 ],
							[ 7.746320430000026, 11.345384746000036 ],
							[ 7.74788462600003, 11.340503205000061 ],
							[ 7.750368801000036, 11.338067899000066 ],
							[ 7.75192869600005, 11.334407887000054 ],
							[ 7.753182629000037, 11.329830395000045 ],
							[ 7.753082946000063, 11.327527070000031 ],
							[ 7.751922172000036, 11.323527367000054 ],
							[ 7.746639023000057, 11.317387618000055 ],
							[ 7.73891978100005, 11.314917785000034 ],
							[ 7.733823864000044, 11.314904395000042 ],
							[ 7.73000206200004, 11.314895854000042 ],
							[ 7.722358467000049, 11.314876394000066 ],
							[ 7.71726198500005, 11.314863904000049 ],
							[ 7.710855085000048, 11.313623526000072 ],
							[ 7.70441029400007, 11.311156250000067 ],
							[ 7.695416790000024, 11.308683317000032 ],
							[ 7.694348077000029, 11.308074413000043 ],
							[ 7.693538645000046, 11.307938224000054 ],
							[ 7.687049412000022, 11.30547163600005 ],
							[ 7.678086493000023, 11.302691011000036 ],
							[ 7.67530531500006, 11.301764628000058 ],
							[ 7.671289517000048, 11.299917932000028 ],
							[ 7.669753675000038, 11.296857949000071 ],
							[ 7.666057722000062, 11.292262960000073 ],
							[ 7.66142676100003, 11.289496901000064 ],
							[ 7.659634536000056, 11.288011792000077 ],
							[ 7.656523607000054, 11.286529788000053 ],
							[ 7.647493298000029, 11.282830023000031 ],
							[ 7.634716021000031, 11.281574106000051 ],
							[ 7.629555874000062, 11.283232609000038 ],
							[ 7.627147390000061, 11.284006258000034 ],
							[ 7.623437274000025, 11.287674176000053 ],
							[ 7.619765317000031, 11.292567136000059 ],
							[ 7.614856877000022, 11.29868212100007 ],
							[ 7.610060603000022, 11.308474564000051 ],
							[ 7.605189987000074, 11.315815068000063 ],
							[ 7.600243354000042, 11.32070531100004 ],
							[ 7.592712397000071, 11.324363002000041 ],
							[ 7.585143146000064, 11.326795422000032 ],
							[ 7.581358778000038, 11.328012724000075 ],
							[ 7.57757437600003, 11.329227708000076 ],
							[ 7.568843848000029, 11.335333609000031 ],
							[ 7.561387237000076, 11.341443113000025 ],
							[ 7.556515726000043, 11.348784648000048 ],
							[ 7.550296034000041, 11.353670969000063 ],
							[ 7.540253889000041, 11.358548355000039 ],
							[ 7.530137289000038, 11.360974160000069 ],
							[ 7.52372964400007, 11.35973281400004 ],
							[ 7.518558982000059, 11.357269085000041 ],
							[ 7.512151437000057, 11.356028685000069 ],
							[ 7.507055520000051, 11.356014999000024 ],
							[ 7.504619430000048, 11.359685693000074 ],
							[ 7.503495443000077, 11.364586060000022 ],
							[ 7.499861225000075, 11.370703512000034 ],
							[ 7.497938847000057, 11.377869719000046 ],
							[ 7.493227794000063, 11.384750590000067 ],
							[ 7.490868036000052, 11.390489464000041 ],
							[ 7.492026762000023, 11.396260145000042 ],
							[ 7.489668859000062, 11.400850327000057 ],
							[ 7.483789909000052, 11.405420499000058 ],
							[ 7.477912828000058, 11.408829475000061 ],
							[ 7.469691950000026, 11.411080589000051 ],
							[ 7.463827321000053, 11.409890164000046 ],
							[ 7.460306336000031, 11.409859765000022 ],
							[ 7.45561312500007, 11.408679733000042 ],
							[ 7.452093251000065, 11.408659980000039 ],
							[ 7.447398038000074, 11.408620025000062 ],
							[ 7.439185068000029, 11.407409790000031 ],
							[ 7.432147919000045, 11.405059634000054 ],
							[ 7.42393994400004, 11.401550040000075 ],
							[ 7.415738239000063, 11.395729932000052 ],
							[ 7.412221771000077, 11.393400052000061 ],
							[ 7.402882041000055, 11.387769724000066 ],
							[ 7.402851869000074, 11.385269963000042 ],
							[ 7.399332739000045, 11.384099911000021 ],
							[ 7.39581705300003, 11.381769858000041 ],
							[ 7.393476118000024, 11.379449966000038 ],
							[ 7.392312754000045, 11.374830449000058 ],
							[ 7.389976129000047, 11.370209782000074 ],
							[ 7.388813121000055, 11.365590251000071 ],
							[ 7.382971822000059, 11.354029475000061 ],
							[ 7.380634864000058, 11.349409888000025 ],
							[ 7.377128016000029, 11.343620093000027 ],
							[ 7.373620075000076, 11.337839866000024 ],
							[ 7.370109993000028, 11.33320036300006 ],
							[ 7.366612791000023, 11.322810150000066 ],
							[ 7.366620931000057, 11.319359619000068 ],
							[ 7.364293258000032, 11.311269597000035 ],
							[ 7.364308754000035, 11.304360619000022 ],
							[ 7.36432308600007, 11.298600331000046 ],
							[ 7.364335975000074, 11.292840016000071 ],
							[ 7.362001756000041, 11.28705975500003 ],
							[ 7.358637507000026, 11.274830300000076 ],
							[ 7.358858603000044, 11.271762655000032 ],
							[ 7.356248096000058, 11.267073856000025 ],
							[ 7.351780949000045, 11.265495151000039 ],
							[ 7.337560089000021, 11.267250508000075 ],
							[ 7.321493524000061, 11.26977671700007 ],
							[ 7.309096389000047, 11.275436733000049 ],
							[ 7.300130565000075, 11.279033939000044 ],
							[ 7.293352846000062, 11.282340949000059 ],
							[ 7.291954392000036, 11.282633988000043 ],
							[ 7.281678320000026, 11.284925158000021 ],
							[ 7.269842658000073, 11.283051365000063 ],
							[ 7.252765755000041, 11.276475305000076 ],
							[ 7.247775218000072, 11.274373066000067 ],
							[ 7.231464479000067, 11.272736239000039 ],
							[ 7.221998533000033, 11.270872202000021 ],
							[ 7.213848413000051, 11.269014241000036 ],
							[ 7.20598999200007, 11.261440642000025 ],
							[ 7.201271950000034, 11.257520288000023 ],
							[ 7.200446182000064, 11.252619240000058 ],
							[ 7.203642736000063, 11.247949583000036 ],
							[ 7.205999019000046, 11.244509819000029 ],
							[ 7.209530890000053, 11.23993043400003 ],
							[ 7.213074317000064, 11.230739350000022 ],
							[ 7.21542895500005, 11.227299513000048 ],
							[ 7.220132010000043, 11.223870400000067 ],
							[ 7.222486816000071, 11.220430519000047 ],
							[ 7.224844955000037, 11.215840267000033 ],
							[ 7.227201109000021, 11.212400380000076 ],
							[ 7.229556923000075, 11.208959328000049 ],
							[ 7.231916799000032, 11.203220349000048 ],
							[ 7.234273085000041, 11.199780396000051 ],
							[ 7.235454915000048, 11.196330346000025 ],
							[ 7.236638922000054, 11.191729335000048 ],
							[ 7.235478895000028, 11.185960008000052 ],
							[ 7.23079479300003, 11.181320335000066 ],
							[ 7.227282074000072, 11.177839962000064 ],
							[ 7.221198443000048, 11.172718848000045 ],
							[ 7.217633778000049, 11.170467622000047 ],
							[ 7.210877058000051, 11.166199666000068 ],
							[ 7.206192943000076, 11.161560229000031 ],
							[ 7.199157213000035, 11.15921011100005 ],
							[ 7.192120128000056, 11.156860408000057 ],
							[ 7.189777956000057, 11.154540288000021 ],
							[ 7.186267944000065, 11.149909710000031 ],
							[ 7.176891788000034, 11.144080140000028 ],
							[ 7.168683882000039, 11.140569872000071 ],
							[ 7.163991997000039, 11.139380469000059 ],
							[ 7.158125893000033, 11.138190251000026 ],
							[ 7.152254941000024, 11.139300155000058 ],
							[ 7.146380861000068, 11.141569938000032 ],
							[ 7.142851875000076, 11.145000645000039 ],
							[ 7.139326177000044, 11.147279808000064 ],
							[ 7.135794314000066, 11.151860255000031 ],
							[ 7.131087734000062, 11.156439824000074 ],
							[ 7.127552852000065, 11.162170573000026 ],
							[ 7.120498052000073, 11.167880079000042 ],
							[ 7.118143177000036, 11.171319883000024 ],
							[ 7.11343727700006, 11.175900376000072 ],
							[ 7.109909946000073, 11.17817980500007 ],
							[ 7.105207063000023, 11.181599648000031 ],
							[ 7.095794195000053, 11.19191003800006 ],
							[ 7.095783390000065, 11.196520021000026 ],
							[ 7.092256941000073, 11.198789694000027 ],
							[ 7.085200791000034, 11.204509809000058 ],
							[ 7.076973881000072, 11.209059776000061 ],
							[ 7.073440194000057, 11.214790557000072 ],
							[ 7.065213133000043, 11.219349871000077 ],
							[ 7.056985912000073, 11.223899948000053 ],
							[ 7.048761821000028, 11.227299484000071 ],
							[ 7.044062223000026, 11.22957043200006 ],
							[ 7.040541284000028, 11.229539985000031 ],
							[ 7.035851983000043, 11.227209887000072 ],
							[ 7.02879901700004, 11.231769516000043 ],
							[ 7.022920140000053, 11.23633019500005 ],
							[ 7.017043095000076, 11.239749990000064 ],
							[ 7.008815642000059, 11.244300143000032 ],
							[ 7.004116140000065, 11.246569438000051 ],
							[ 6.990035165000052, 11.245320446000051 ],
							[ 6.980647910000073, 11.244110047000049 ],
							[ 6.973610929000074, 11.241749994000031 ],
							[ 6.970087074000048, 11.242879995000067 ],
							[ 6.967733888000055, 11.245169583000063 ],
							[ 6.968896853000047, 11.249789943000053 ],
							[ 6.972404914000037, 11.255570660000046 ],
							[ 6.975914960000068, 11.260199295000064 ],
							[ 6.978253752000057, 11.263669976000074 ],
							[ 6.980596932000026, 11.265999902000033 ],
							[ 6.984106893000046, 11.270630140000037 ],
							[ 6.986447940000062, 11.272950407000053 ],
							[ 6.988791006000042, 11.275270652000074 ],
							[ 6.993476767000061, 11.278760185000067 ],
							[ 6.996993177000036, 11.281089552000026 ],
							[ 6.999334935000036, 11.283410295000067 ],
							[ 7.002844723000067, 11.288040346000059 ],
							[ 7.005187148000061, 11.29036049900003 ],
							[ 7.008699978000038, 11.293840695000029 ],
							[ 7.014535778000038, 11.30770029200005 ],
							[ 7.021561974000065, 11.314669499000047 ],
							[ 7.022725061000074, 11.319279481000024 ],
							[ 7.023881836000044, 11.326199786000075 ],
							[ 7.023858081000071, 11.336570044000041 ],
							[ 7.020326031000025, 11.341150480000067 ],
							[ 7.017968156000052, 11.345749595000029 ],
							[ 7.01560680800003, 11.351490005000073 ],
							[ 7.013245968000035, 11.357230401000038 ],
							[ 7.010892876000071, 11.359519675000058 ],
							[ 7.006193031000066, 11.361789576000035 ],
							[ 7.002672195000059, 11.361769516000038 ],
							[ 6.995633214000065, 11.360569875000067 ],
							[ 6.988589870000055, 11.360520477000023 ],
							[ 6.981551250000052, 11.359319460000052 ],
							[ 6.975688092000041, 11.356969820000074 ],
							[ 6.968645911000067, 11.356929642000068 ],
							[ 6.961600751000049, 11.358030105000068 ],
							[ 6.959248184000046, 11.360320185000035 ],
							[ 6.954548002000024, 11.362589988000025 ],
							[ 6.949848042000042, 11.364859731000024 ],
							[ 6.946319022000068, 11.368289668000045 ],
							[ 6.940439131000062, 11.372859732000052 ],
							[ 6.93456795700007, 11.37397013900005 ],
							[ 6.928690983000024, 11.377389667000045 ],
							[ 6.923988937000047, 11.380810010000062 ],
							[ 6.919289111000069, 11.38307936800004 ],
							[ 6.91107315000005, 11.383029833000023 ],
							[ 6.898165088000042, 11.38177945800004 ],
							[ 6.895823030000031, 11.379460348000066 ],
							[ 6.893651122000051, 11.379879697000035 ],
							[ 6.888557998000067, 11.380746882000039 ],
							[ 6.888219481000021, 11.383961619000047 ],
							[ 6.887447246000022, 11.391299074000074 ],
							[ 6.886931369000024, 11.396202194000068 ],
							[ 6.886117842000033, 11.417349588000036 ],
							[ 6.886460285000055, 11.423166147000074 ],
							[ 6.886931336000032, 11.431177940000055 ],
							[ 6.891482011000051, 11.441417644000069 ],
							[ 6.893438377000052, 11.445820050000066 ],
							[ 6.900759090000065, 11.458833729000048 ],
							[ 6.90210778900007, 11.462204891000056 ],
							[ 6.907266648000075, 11.475102244000027 ],
							[ 6.903199205000021, 11.48649034400006 ],
							[ 6.895878723000067, 11.505198395000036 ],
							[ 6.900621374000025, 11.51468357400006 ],
							[ 6.902236846000051, 11.517914984000072 ],
							[ 6.90319929900005, 11.519839193000053 ],
							[ 6.904760858000031, 11.523586152000064 ],
							[ 6.907266635000042, 11.52960015900004 ],
							[ 6.906637771000021, 11.534001457000045 ],
							[ 6.906153638000035, 11.537390501000061 ],
							[ 6.905639649000022, 11.540987866000023 ],
							[ 6.904489077000051, 11.54961759300005 ],
							[ 6.904012721000072, 11.553189170000053 ],
							[ 6.902339317000042, 11.554482649000022 ],
							[ 6.886117812000066, 11.567017566000061 ],
							[ 6.876124302000051, 11.574422650000031 ],
							[ 6.867409276000046, 11.577592143000061 ],
							[ 6.86277350000006, 11.582584500000053 ],
							[ 6.856834968000044, 11.588979556000027 ],
							[ 6.854380584000069, 11.595219495000038 ],
							[ 6.853581514000041, 11.606060823000064 ],
							[ 6.852428562000057, 11.616435111000044 ],
							[ 6.851954362000072, 11.62070157200003 ],
							[ 6.855208499000071, 11.641850630000022 ],
							[ 6.865782692000039, 11.662999355000068 ],
							[ 6.869036084000072, 11.678454627000065 ],
							[ 6.871317499000043, 11.683587247000048 ],
							[ 6.875543729000071, 11.693095080000035 ],
							[ 6.876567284000032, 11.695399391000024 ],
							[ 6.882051009000065, 11.707737102000067 ],
							[ 6.888473404000024, 11.724150940000072 ],
							[ 6.889371416000074, 11.726445443000046 ],
							[ 6.889426798000045, 11.72849108500003 ],
							[ 6.889583213000037, 11.734282704000066 ],
							[ 6.889676998000027, 11.737748985000053 ],
							[ 6.889949323000053, 11.747831361000067 ],
							[ 6.890184988000044, 11.756541112000036 ],
							[ 6.890184912000052, 11.759495530000038 ],
							[ 6.890184857000065, 11.763773743000058 ],
							[ 6.890184895000061, 11.771125564000045 ],
							[ 6.890184892000036, 11.779316994000055 ],
							[ 6.892148147000057, 11.783979252000051 ],
							[ 6.895235048000075, 11.791310161000069 ],
							[ 6.89669217900007, 11.794771129000026 ],
							[ 6.908893028000023, 11.801279326000042 ],
							[ 6.916048201000024, 11.802556884000069 ],
							[ 6.926131676000068, 11.797657093000055 ],
							[ 6.937113332000024, 11.799524517000066 ],
							[ 6.949891275000027, 11.807721668000056 ],
							[ 6.970939332000057, 11.811449227000026 ],
							[ 6.984680032000028, 11.811523995000073 ],
							[ 6.991087274000051, 11.812461576000032 ],
							[ 6.999316357000055, 11.815215136000063 ],
							[ 7.011215133000064, 11.817085316000032 ],
							[ 7.015747575000034, 11.81766833100005 ],
							[ 7.016262951000044, 11.817546781000033 ],
							[ 7.01948268600006, 11.81271736900004 ],
							[ 7.021888314000023, 11.809108535000064 ],
							[ 7.026009990000034, 11.808853018000036 ],
							[ 7.02948718500005, 11.807271958000058 ],
							[ 7.033870210000032, 11.806538636000028 ],
							[ 7.03757960300004, 11.804514635000032 ],
							[ 7.046242216000053, 11.801010372000064 ],
							[ 7.052335794000044, 11.801223721000042 ],
							[ 7.054534421000028, 11.800853868000047 ],
							[ 7.062627228000053, 11.804531938000025 ],
							[ 7.067096847000073, 11.806394852000039 ],
							[ 7.06991574400007, 11.807569440000066 ],
							[ 7.072388063000062, 11.808599280000067 ],
							[ 7.074828232000073, 11.817546853000067 ],
							[ 7.076295360000074, 11.819258492000074 ],
							[ 7.085381595000058, 11.823795469000061 ],
							[ 7.093611955000029, 11.826546958000051 ],
							[ 7.106413045000068, 11.831127317000039 ],
							[ 7.113694743000053, 11.840194173000043 ],
							[ 7.117769985000052, 11.854233590000035 ],
							[ 7.121176295000055, 11.858017678000067 ],
							[ 7.122819482000068, 11.859844136000049 ],
							[ 7.125672451000071, 11.861745634000044 ],
							[ 7.13014019600007, 11.86472420900003 ],
							[ 7.133032239000045, 11.868394846000058 ],
							[ 7.137064, 11.873513051000032 ],
							[ 7.139681873000029, 11.876740445000053 ],
							[ 7.141120928000021, 11.878552244000048 ],
							[ 7.142747923000059, 11.886686176000069 ],
							[ 7.142747819000022, 11.898074253000061 ],
							[ 7.143201004000048, 11.90532089900006 ],
							[ 7.143561205000026, 11.911089063000077 ],
							[ 7.154949108000039, 11.917595762000076 ],
							[ 7.164710031000027, 11.911902582000039 ],
							[ 7.171418290000076, 11.910560437000072 ],
							[ 7.182542757000022, 11.909471450000069 ],
							[ 7.190739081000061, 11.907021494000048 ],
							[ 7.195137937000027, 11.906621948000065 ],
							[ 7.199686493000058, 11.906207820000077 ],
							[ 7.20428997700003, 11.908409844000062 ],
							[ 7.209920218000036, 11.911102652000068 ],
							[ 7.218699791000063, 11.911144826000054 ],
							[ 7.23057600900006, 11.918425583000044 ],
							[ 7.23241881000007, 11.925087160000032 ],
							[ 7.235048793000033, 11.927378848000046 ],
							[ 7.241856941000037, 11.933309664000035 ],
							[ 7.239543412000046, 11.941184904000067 ],
							[ 7.236446040000033, 11.944282791000035 ],
							[ 7.234662987000036, 11.946064792000072 ],
							[ 7.232506738000041, 11.946064711000076 ],
							[ 7.226529145000029, 11.946065052000051 ],
							[ 7.224144500000023, 11.950357241000063 ],
							[ 7.222461581000061, 11.953386043000023 ],
							[ 7.216767659000027, 11.958266301000037 ],
							[ 7.213514299000053, 11.96640016200007 ],
							[ 7.213514417000056, 11.97778816500005 ],
							[ 7.209447361000059, 11.985108428000046 ],
							[ 7.202923703000067, 11.987480995000055 ],
							[ 7.200499885000056, 11.988362538000047 ],
							[ 7.18992578700005, 11.99324303700007 ],
							[ 7.178195599000048, 11.998456 ],
							[ 7.173856663000038, 12.000384503000078 ],
							[ 7.168660701000022, 12.002694060000067 ],
							[ 7.157708167000067, 12.007561612000075 ],
							[ 7.153322186000025, 12.009511099000065 ],
							[ 7.151047227000049, 12.01083770300005 ],
							[ 7.143561298000066, 12.015204528000027 ],
							[ 7.125666253000077, 12.029846354000028 ],
							[ 7.113035705000073, 12.039845551000042 ],
							[ 7.106144364000045, 12.045301664000021 ],
							[ 7.103155486000048, 12.049087687000053 ],
							[ 7.100131902000044, 12.05291758900006 ],
							[ 7.093943634000027, 12.060755676000042 ],
							[ 7.090689728000029, 12.06400971100004 ],
							[ 7.09557011000004, 12.070516312000052 ],
							[ 7.099094783000055, 12.074824316000047 ],
							[ 7.102891107000062, 12.079464194000025 ],
							[ 7.106711960000041, 12.082402961000071 ],
							[ 7.11134241600007, 12.085965041000065 ],
							[ 7.113465424000026, 12.087597902000027 ],
							[ 7.115156054000067, 12.090473227000075 ],
							[ 7.115662091000047, 12.091333451000025 ],
							[ 7.119086390000064, 12.097154343000057 ],
							[ 7.121599311000068, 12.101425939000023 ],
							[ 7.12369577000004, 12.107033853000075 ],
							[ 7.126100958000052, 12.113465383000062 ],
							[ 7.128158720000044, 12.116551547000029 ],
							[ 7.136552336000022, 12.129142640000055 ],
							[ 7.138508385000023, 12.132076237000035 ],
							[ 7.141934354000057, 12.137215535000053 ],
							[ 7.145618560000059, 12.147162448000074 ],
							[ 7.150068630000021, 12.15917756500005 ],
							[ 7.150643484000057, 12.165113360000021 ],
							[ 7.150991934000047, 12.168714609000062 ],
							[ 7.151186433000021, 12.170726934000072 ],
							[ 7.152087717000029, 12.180041233000054 ],
							[ 7.152508642000043, 12.184393723000028 ],
							[ 7.151547088000029, 12.188242130000049 ],
							[ 7.149255154000059, 12.197408933000077 ],
							[ 7.147886883000069, 12.202449697000077 ],
							[ 7.14693354000002, 12.205961961000071 ],
							[ 7.144321430000048, 12.215584762000049 ],
							[ 7.141527544000041, 12.225877814000057 ],
							[ 7.137232179000023, 12.234468370000059 ],
							[ 7.135386823000033, 12.238159210000049 ],
							[ 7.133769608000023, 12.241394107000076 ],
							[ 7.129437044000042, 12.250058969000065 ],
							[ 7.128513284000064, 12.251906326000039 ],
							[ 7.123377755000035, 12.26071001400004 ],
							[ 7.117474655000024, 12.270830097000044 ],
							[ 7.114380253000036, 12.276134308000053 ],
							[ 7.111431670000059, 12.281188933000067 ],
							[ 7.108170596000036, 12.285847592000039 ],
							[ 7.105737797000074, 12.289322846000061 ],
							[ 7.104723569000043, 12.295072580000067 ],
							[ 7.104324252000026, 12.297334426000077 ],
							[ 7.103837382000052, 12.300093575000062 ],
							[ 7.103297570000052, 12.303151331000038 ],
							[ 7.102447136000023, 12.309444271000075 ],
							[ 7.10166265600003, 12.315250515000059 ],
							[ 7.100743186000045, 12.322052154000062 ],
							[ 7.100283281000031, 12.325457765000067 ],
							[ 7.09923083700005, 12.333247417000052 ],
							[ 7.098339534000047, 12.336923705000061 ],
							[ 7.094387969000024, 12.353222912000035 ],
							[ 7.093268866000074, 12.357838711000056 ],
							[ 7.092723386000046, 12.360089048000077 ],
							[ 7.089445006000062, 12.368102841000052 ],
							[ 7.087827265000044, 12.372057840000025 ],
							[ 7.085402487000067, 12.377985003000049 ],
							[ 7.083239025000069, 12.382311768000022 ],
							[ 7.078082148000021, 12.392625958000053 ],
							[ 7.07530027100006, 12.397957967000025 ],
							[ 7.070996234000063, 12.406206944000076 ],
							[ 7.068321183000023, 12.411333807000062 ],
							[ 7.066891724000072, 12.423247389000039 ],
							[ 7.065880823000043, 12.431669317000058 ],
							[ 7.06520830900007, 12.438171519000036 ],
							[ 7.064253249000046, 12.447405596000067 ],
							[ 7.063898952000045, 12.450829752000061 ],
							[ 7.063440770000057, 12.455258479000065 ],
							[ 7.06256761800006, 12.461150004000046 ],
							[ 7.06152189900007, 12.468209005000062 ],
							[ 7.060515786000053, 12.474998711000069 ],
							[ 7.060186863000069, 12.477220732000035 ],
							[ 7.059929725000075, 12.480822793000073 ],
							[ 7.059373860000051, 12.488608617000068 ],
							[ 7.058836959000075, 12.491216566000048 ],
							[ 7.057652856000061, 12.496967466000058 ],
							[ 7.056012260000045, 12.504935189000037 ],
							[ 7.054828049000037, 12.510686074000034 ],
							[ 7.053680077000024, 12.516263713000058 ],
							[ 7.053679847000069, 12.52927896500006 ],
							[ 7.052393970000026, 12.534851910000043 ],
							[ 7.05123951500002, 12.539853207000021 ],
							[ 7.051743141000031, 12.54539029700004 ],
							[ 7.052052950000075, 12.548800397000036 ],
							[ 7.052052978000063, 12.553363103000038 ],
							[ 7.052052837000076, 12.557747841000037 ],
							[ 7.050656294000021, 12.568359155000053 ],
							[ 7.050019217000056, 12.57320211900003 ],
							[ 7.05164637200005, 12.587030368000057 ],
							[ 7.051230394000072, 12.589386940000054 ],
							[ 7.049877201000072, 12.597056661000067 ],
							[ 7.049206359000038, 12.600858771000048 ],
							[ 7.048277872000028, 12.606196247000071 ],
							[ 7.047660360000066, 12.609744969000076 ],
							[ 7.046849227000052, 12.614409610000052 ],
							[ 7.045952386000067, 12.619567036000035 ],
							[ 7.046957391000035, 12.63403887000004 ],
							[ 7.047209720000069, 12.637671229000034 ],
							[ 7.04735947000006, 12.639825114000075 ],
							[ 7.046776632000046, 12.645497151000029 ],
							[ 7.046272889000022, 12.650032875000022 ],
							[ 7.045952458000045, 12.652917048000063 ],
							[ 7.045152606000045, 12.656115645000057 ],
							[ 7.043740790000072, 12.661762993000025 ],
							[ 7.04269892700006, 12.665930919000061 ],
							[ 7.042586862000064, 12.66963650100007 ],
							[ 7.042412238000054, 12.675392029000022 ],
							[ 7.042272583000056, 12.679998592000061 ],
							[ 7.042132928000058, 12.684604581000031 ],
							[ 7.042027527000073, 12.688086485000042 ],
							[ 7.041885409000031, 12.692773802000033 ],
							[ 7.040604167000026, 12.701101995000045 ],
							[ 7.039511727000047, 12.708200686000055 ],
							[ 7.038873749000061, 12.712348109000061 ],
							[ 7.037489022000045, 12.721348617000046 ],
							[ 7.037004977000038, 12.724495731000047 ],
							[ 7.034697460000075, 12.73504329900004 ],
							[ 7.033925977000024, 12.738571380000053 ],
							[ 7.033329820000063, 12.74129700800006 ],
							[ 7.032249792000073, 12.746234161000075 ],
							[ 7.031310897000026, 12.750525758000038 ],
							[ 7.031000223000035, 12.753426314000023 ],
							[ 7.028871151000033, 12.77330117300005 ],
							[ 7.028411754000047, 12.787983202000021 ],
							[ 7.028261175000068, 12.792810577000068 ],
							[ 7.028057653000076, 12.799329705000048 ],
							[ 7.02818099700005, 12.802049510000074 ],
							[ 7.028547247000063, 12.810107214000027 ],
							[ 7.02887097200005, 12.817224599000042 ],
							[ 7.023177278000048, 12.831866450000064 ],
							[ 7.023717006000027, 12.836007060000043 ],
							[ 7.024695284000074, 12.843507560000035 ],
							[ 7.025617042000022, 12.850574431000041 ],
							[ 7.025973328000021, 12.853690370000038 ],
							[ 7.02685124900006, 12.861372227000061 ],
							[ 7.027179835000027, 12.864246205000029 ],
							[ 7.027736066000045, 12.86911570500007 ],
							[ 7.028871104000075, 12.879043495000076 ],
							[ 7.030583881000041, 12.883523043000025 ],
							[ 7.032017275000044, 12.887271768000062 ],
							[ 7.034158347000073, 12.892871618000072 ],
							[ 7.035118239000042, 12.899912073000053 ],
							[ 7.036237653000057, 12.908121128000062 ],
							[ 7.036598067000057, 12.910766459000058 ],
							[ 7.037804640000047, 12.919612106000045 ],
							[ 7.038596589000065, 12.925420630000076 ],
							[ 7.039038613000059, 12.928661263000038 ],
							[ 7.039062152000042, 12.929247824000072 ],
							[ 7.043148520000045, 12.942550243000028 ],
							[ 7.049950823000074, 12.972053865000078 ],
							[ 7.047684777000029, 12.999111687000038 ],
							[ 7.049030882000068, 12.99934980300003 ],
							[ 7.053943765000042, 12.999766418000036 ],
							[ 7.059272803000056, 13.000432035000074 ],
							[ 7.063352712000039, 13.000848707000046 ],
							[ 7.067266409000069, 13.002180834000058 ],
							[ 7.072345595000058, 13.003930215000025 ],
							[ 7.082337838000058, 13.007010444000059 ],
							[ 7.088007095000023, 13.00867000900007 ],
							[ 7.094911459000059, 13.010591714000043 ],
							[ 7.102738354000053, 13.012839451000048 ],
							[ 7.111460166000029, 13.018050193000022 ],
							[ 7.116151927000033, 13.019240550000063 ],
							[ 7.123182757000052, 13.023889375000067 ],
							[ 7.129043033000073, 13.027389320000054 ],
							[ 7.131384938000053, 13.029710006000073 ],
							[ 7.136072183000067, 13.033199996000064 ],
							[ 7.138413876000072, 13.035519506000071 ],
							[ 7.141929960000027, 13.037850519000074 ],
							[ 7.15832896400002, 13.051790057000062 ],
							[ 7.161836043000051, 13.057570402000067 ],
							[ 7.170030986000029, 13.066840247000073 ],
							[ 7.177053036000075, 13.074959599000067 ],
							[ 7.181732225000076, 13.081899669000052 ],
							[ 7.187586740000029, 13.087699651000037 ],
							[ 7.192274147000035, 13.091190618000041 ],
							[ 7.195784193000065, 13.095820400000036 ],
							[ 7.200459948000059, 13.103920027000072 ],
							[ 7.203972796000073, 13.107400064000046 ],
							[ 7.208656898000072, 13.112039331000062 ],
							[ 7.213343092000059, 13.115529999000046 ],
							[ 7.220000428000048, 13.120840689000033 ],
							[ 7.22797038300007, 13.123592623000036 ],
							[ 7.235338194000065, 13.123929224000051 ],
							[ 7.248560829000041, 13.120071399000039 ],
							[ 7.261473076000073, 13.11681444900006 ],
							[ 7.271331866000025, 13.109924999000043 ],
							[ 7.282088444000067, 13.107863497000039 ],
							[ 7.288851972000032, 13.106085617000076 ],
							[ 7.298998569000048, 13.103116365000062 ],
							[ 7.300160987000027, 13.10265726800003 ],
							[ 7.303749980000021, 13.10233011400004 ],
							[ 7.314319119000061, 13.100099611000076 ],
							[ 7.320191841000053, 13.097840339000072 ],
							[ 7.333106014000066, 13.096770071000037 ],
							[ 7.337928929000043, 13.09522839300007 ],
							[ 7.340486371000054, 13.095161252000025 ],
							[ 7.350318168000058, 13.093697034000058 ],
							[ 7.36413985400003, 13.09255175100003 ],
							[ 7.36598635100006, 13.091655361000051 ],
							[ 7.370288654000035, 13.090769144000035 ],
							[ 7.375815209000052, 13.090793251000036 ],
							[ 7.380730455000048, 13.090211830000044 ],
							[ 7.390237529000046, 13.092665297000053 ],
							[ 7.397609947000035, 13.091792559000055 ],
							[ 7.404667722000056, 13.092727296000021 ],
							[ 7.411024564000058, 13.094004091000045 ],
							[ 7.42113303800005, 13.097379623000052 ],
							[ 7.431681984000022, 13.10321028900006 ],
							[ 7.443399901000021, 13.111359542000059 ],
							[ 7.453951672000073, 13.116040480000038 ],
							[ 7.460981161000063, 13.121850054000049 ],
							[ 7.480908773000067, 13.132360597000059 ],
							[ 7.493805662000057, 13.138210328000071 ],
							[ 7.503184721000025, 13.142880603000037 ],
							[ 7.513732158000039, 13.149860266000076 ],
							[ 7.520762812000044, 13.154520174000027 ],
							[ 7.526620612000045, 13.159170227000061 ],
							[ 7.533658166000066, 13.161519858000077 ],
							[ 7.539864736000027, 13.164790353000058 ],
							[ 7.553586048000057, 13.172030414000062 ],
							[ 7.555928178000045, 13.174349522000057 ],
							[ 7.564130681000051, 13.180170132000057 ],
							[ 7.57350090500006, 13.188299965000056 ],
							[ 7.578187903000071, 13.19177981200005 ],
							[ 7.588739717000067, 13.196470461000047 ],
							[ 7.596948234000024, 13.199979737000035 ],
							[ 7.599286907000021, 13.203449920000025 ],
							[ 7.605146938000075, 13.206950189000054 ],
							[ 7.611004960000059, 13.211600593000071 ],
							[ 7.619217684000034, 13.212809544000038 ],
							[ 7.621559911000077, 13.215129915000034 ],
							[ 7.627419820000057, 13.218620240000064 ],
							[ 7.635355141000048, 13.222023157000024 ],
							[ 7.639143713000067, 13.224459458000069 ],
							[ 7.646177750000049, 13.227970147000065 ],
							[ 7.654380065000055, 13.233790422000027 ],
							[ 7.661411911000073, 13.238439719000041 ],
							[ 7.664929849000032, 13.239620050000042 ],
							[ 7.673134692000076, 13.244280110000034 ],
							[ 7.680165794000061, 13.248940336000032 ],
							[ 7.688379031000068, 13.250149462000024 ],
							[ 7.693060711000044, 13.255940655000074 ],
							[ 7.696575908000057, 13.258270314000072 ],
							[ 7.702434210000035, 13.262920389000044 ],
							[ 7.71063891700004, 13.267580167000062 ],
							[ 7.716498791000049, 13.27107981000006 ],
							[ 7.728219989000024, 13.278079928000068 ],
							[ 7.734083136000038, 13.280420056000025 ],
							[ 7.73876709700005, 13.285059899000032 ],
							[ 7.744626942000025, 13.288559666000026 ],
							[ 7.752832193000074, 13.293219635000071 ],
							[ 7.757518817000062, 13.296710184000062 ],
							[ 7.765724387000034, 13.301380029000029 ],
							[ 7.771582015000035, 13.306019941000045 ],
							[ 7.775092070000028, 13.310660542000051 ],
							[ 7.778607181000041, 13.312989989000073 ],
							[ 7.78446457900003, 13.317629693000072 ],
							[ 7.789152393000052, 13.321120395000037 ],
							[ 7.795012162000035, 13.324620487000061 ],
							[ 7.798522152000032, 13.32925012100003 ],
							[ 7.802038180000068, 13.331579902000044 ],
							[ 7.806729941000071, 13.332770554000035 ],
							[ 7.811425008000072, 13.332799659000045 ],
							[ 7.818018262000066, 13.330421332000071 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Kebbi',
				admin1Pcod: 'NG022',
				admin1RefN: 'Kebbi',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 14.968981,
				Shape_Area: 3.035227
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 4.25354724400006, 13.260446413000068 ],
							[ 4.266816609000045, 13.251079785000059 ],
							[ 4.276964123000027, 13.243273785000042 ],
							[ 4.287891804000026, 13.230004415000053 ],
							[ 4.297258888000044, 13.230784183000026 ],
							[ 4.315211752000039, 13.229222997000022 ],
							[ 4.334725860000049, 13.221418235000044 ],
							[ 4.345653515000038, 13.213612782000041 ],
							[ 4.351898212000037, 13.199562173000061 ],
							[ 4.355020575000026, 13.183950536000054 ],
							[ 4.365168100000062, 13.173803118000023 ],
							[ 4.379998630000046, 13.169901134000042 ],
							[ 4.397951608000028, 13.165217308000024 ],
							[ 4.426052108000022, 13.16287529400006 ],
							[ 4.447127356000067, 13.156631295000068 ],
							[ 4.466641462000041, 13.151167175000069 ],
							[ 4.504108478000035, 13.141800143000069 ],
							[ 4.522875430000056, 13.14050549500007 ],
							[ 4.526744819000044, 13.140238913000076 ],
							[ 4.541575396000042, 13.128530417000036 ],
							[ 4.550161873000036, 13.119163320000041 ],
							[ 4.552503529000035, 13.115261251000049 ],
							[ 4.570456570000033, 13.105113709000022 ],
							[ 4.579042493000031, 13.104333066000038 ],
							[ 4.602459401000033, 13.09496567900004 ],
							[ 4.618851665000022, 13.091844418000051 ],
							[ 4.629779390000067, 13.098869327000045 ],
							[ 4.642268669000032, 13.123846839000066 ],
							[ 4.654757517000064, 13.147264531000076 ],
							[ 4.661782794000032, 13.156631684000047 ],
							[ 4.670368687000064, 13.165997609000044 ],
							[ 4.685199332000025, 13.169901109000023 ],
							[ 4.696907924000072, 13.16990075700005 ],
							[ 4.702822725000033, 13.166943486000037 ],
							[ 4.708420271000023, 13.156124802000022 ],
							[ 4.706701312000064, 13.144947879000028 ],
							[ 4.701903400000049, 13.134479536000072 ],
							[ 4.700594362000061, 13.124446847000058 ],
							[ 4.696668595000062, 13.117032017000042 ],
							[ 4.691870663000032, 13.106127917000038 ],
							[ 4.689689690000023, 13.09435005000006 ],
							[ 4.687944948000052, 13.084318445000065 ],
							[ 4.685764277000033, 13.068615040000054 ],
							[ 4.68557419800004, 13.067664813000022 ],
							[ 4.699030106000066, 13.076701706000051 ],
							[ 4.706336357000055, 13.083640211000045 ],
							[ 4.70199232300007, 13.07108437200003 ],
							[ 4.693441834000055, 13.058555616000035 ],
							[ 4.681040709000058, 13.037436717000048 ],
							[ 4.670072892000064, 13.028764190000061 ],
							[ 4.669232230000034, 13.028651031000038 ],
							[ 4.668098531000055, 13.027176809000025 ],
							[ 4.662864193000075, 13.022814763000042 ],
							[ 4.655448909000029, 13.015835665000054 ],
							[ 4.64934211700006, 13.008420805000071 ],
							[ 4.644544155000062, 13.003186158000062 ],
							[ 4.63930982200003, 12.994462085000066 ],
							[ 4.638437242000066, 12.984866361000059 ],
							[ 4.64934211700006, 12.969162886000049 ],
							[ 4.656321021000053, 12.959131036000031 ],
							[ 4.662181924000038, 12.951595693000058 ],
							[ 4.670413117000066, 12.947307648000049 ],
							[ 4.691953545000047, 12.936085157000036 ],
							[ 4.700846192000029, 12.924715211000034 ],
							[ 4.701990146000071, 12.910387925000066 ],
							[ 4.711922811000022, 12.893293936000077 ],
							[ 4.713233308000042, 12.890981826000029 ],
							[ 4.716515556000047, 12.889340420000053 ],
							[ 4.723494482000035, 12.879307847000064 ],
							[ 4.729601319000039, 12.877126951000037 ],
							[ 4.743123010000033, 12.870584266000037 ],
							[ 4.75271947300007, 12.864041575000044 ],
							[ 4.761006830000042, 12.861860145000037 ],
							[ 4.767549992000056, 12.857934954000029 ],
							[ 4.774092687000064, 12.857062262000056 ],
							[ 4.78019959300002, 12.850083246000054 ],
							[ 4.789359583000021, 12.843104143000062 ],
							[ 4.792849082000032, 12.836561435000021 ],
							[ 4.795902264000063, 12.828273629000023 ],
							[ 4.797156342000051, 12.824720436000064 ],
							[ 4.797543492000045, 12.819524069000067 ],
							[ 4.798919625000053, 12.813559743000042 ],
							[ 4.799415095000029, 12.809348254000042 ],
							[ 4.802284275000034, 12.806938125000045 ],
							[ 4.813211946000024, 12.788203980000048 ],
							[ 4.816334202000064, 12.774154405000047 ],
							[ 4.813992570000039, 12.758542847000058 ],
							[ 4.812808526000026, 12.756440762000068 ],
							[ 4.81225587800003, 12.756766205000076 ],
							[ 4.804477017000067, 12.756678699000076 ],
							[ 4.789086945000065, 12.74216503100007 ],
							[ 4.774692892000076, 12.72574951200005 ],
							[ 4.773120192000022, 12.724463263000075 ],
							[ 4.759282859000052, 12.713145699000052 ],
							[ 4.750667972000031, 12.701574767000068 ],
							[ 4.750014631000056, 12.684833862000062 ],
							[ 4.749921423000046, 12.682445690000065 ],
							[ 4.758058911000035, 12.65194503500004 ],
							[ 4.782730040000047, 12.620675753000057 ],
							[ 4.797593010000071, 12.605638529000032 ],
							[ 4.802363939000031, 12.598482387000047 ],
							[ 4.815017754000053, 12.587636145000033 ],
							[ 4.828876493000053, 12.579199682000024 ],
							[ 4.840325390000032, 12.570160627000064 ],
							[ 4.841767889000039, 12.568718472000057 ],
							[ 4.844187906000059, 12.559999882000056 ],
							[ 4.844283873000052, 12.551679070000034 ],
							[ 4.843339553000021, 12.547263241000053 ],
							[ 4.838980253000045, 12.536934949000056 ],
							[ 4.831166839000048, 12.524120917000062 ],
							[ 4.824869129000035, 12.509365423000077 ],
							[ 4.81556426700007, 12.496533743000043 ],
							[ 4.807678415000055, 12.49008198100006 ],
							[ 4.781095367000034, 12.465796197000031 ],
							[ 4.768716668000025, 12.460760528000037 ],
							[ 4.760890141000061, 12.449412643000073 ],
							[ 4.756713001000037, 12.423910977000048 ],
							[ 4.753010652000057, 12.400372964000042 ],
							[ 4.751142376000075, 12.390071895000062 ],
							[ 4.750748797000028, 12.381256098000051 ],
							[ 4.749909933000026, 12.368029849000038 ],
							[ 4.748441151000065, 12.366055328000073 ],
							[ 4.74496004100007, 12.366015548000064 ],
							[ 4.737980335000032, 12.367404145000023 ],
							[ 4.732995763000076, 12.368326196000055 ],
							[ 4.719978433000051, 12.375519030000021 ],
							[ 4.717163235000044, 12.377168108000035 ],
							[ 4.710962705000043, 12.380799775000071 ],
							[ 4.70338031600005, 12.390992098000027 ],
							[ 4.701303382000049, 12.398310977000051 ],
							[ 4.693310767000071, 12.401155497000047 ],
							[ 4.679296729000043, 12.40833538000004 ],
							[ 4.670788641000058, 12.412642462000065 ],
							[ 4.664743767000061, 12.418935235000049 ],
							[ 4.654304703000037, 12.41832422400006 ],
							[ 4.639732711000022, 12.430391336000071 ],
							[ 4.630773739000063, 12.430776163000075 ],
							[ 4.621838406000052, 12.429202158000066 ],
							[ 4.618439992000049, 12.42230918000007 ],
							[ 4.614544267000042, 12.415410326000028 ],
							[ 4.613597298000059, 12.41148307800006 ],
							[ 4.610407943000041, 12.404021832000069 ],
							[ 4.609872830000029, 12.400097168000059 ],
							[ 4.608868248000022, 12.383020325000075 ],
							[ 4.608868299000051, 12.365188022000041 ],
							[ 4.607132468000032, 12.357455131000052 ],
							[ 4.600828808000074, 12.343797226000049 ],
							[ 4.599901669000076, 12.341788385000029 ],
							[ 4.594664549000072, 12.337948194000035 ],
							[ 4.58782057600007, 12.331453659000033 ],
							[ 4.575182205000033, 12.320954824000069 ],
							[ 4.569976014000076, 12.316827611000065 ],
							[ 4.567024917000026, 12.313694496000039 ],
							[ 4.562933714000053, 12.309351623000055 ],
							[ 4.554069263000031, 12.297418379000021 ],
							[ 4.551866859000029, 12.29332620100007 ],
							[ 4.549430561000065, 12.277773830000058 ],
							[ 4.54771781900007, 12.264447781000058 ],
							[ 4.547474849000025, 12.253726495000024 ],
							[ 4.542383998000048, 12.240359381000076 ],
							[ 4.541342476000068, 12.231508336000047 ],
							[ 4.53898711100004, 12.21148974700003 ],
							[ 4.53498654200007, 12.201092864000032 ],
							[ 4.533652018000055, 12.184961358000066 ],
							[ 4.533984324000073, 12.183252193000044 ],
							[ 4.543614466000065, 12.164849705000051 ],
							[ 4.551384975000076, 12.141014636000023 ],
							[ 4.560077567000064, 12.118110821000073 ],
							[ 4.56484537700004, 12.106168875000037 ],
							[ 4.570682663000071, 12.091547842000068 ],
							[ 4.577673564000065, 12.054818035000039 ],
							[ 4.565921754000044, 11.984733505000065 ],
							[ 4.562198206000062, 11.977553352000029 ],
							[ 4.562198151000075, 11.957992636000029 ],
							[ 4.56115337500006, 11.929778981000027 ],
							[ 4.556973516000028, 11.898431996000056 ],
							[ 4.541299797000022, 11.87648776900005 ],
							[ 4.53512136300003, 11.863444403000074 ],
							[ 4.536891966000042, 11.86167338000007 ],
							[ 4.537586728000065, 11.827633369000068 ],
							[ 4.540972142000044, 11.821157447000076 ],
							[ 4.544565689000024, 11.81284368300004 ],
							[ 4.544680922000055, 11.803504473000032 ],
							[ 4.53203906300007, 11.79403639700007 ],
							[ 4.531428366000057, 11.792203984000025 ],
							[ 4.521280758000046, 11.776592461000064 ],
							[ 4.504476380000028, 11.769590442000037 ],
							[ 4.500306701000056, 11.76265839000007 ],
							[ 4.497425529000054, 11.75377499800004 ],
							[ 4.492051887000059, 11.744860771000049 ],
							[ 4.481145311000034, 11.739812442000073 ],
							[ 4.470737568000061, 11.734769869000047 ],
							[ 4.453917444000069, 11.724240590000022 ],
							[ 4.444495255000049, 11.720192502000032 ],
							[ 4.436599802000046, 11.713704436000057 ],
							[ 4.425177793000046, 11.710122843000022 ],
							[ 4.424196978000055, 11.710554889000036 ],
							[ 4.418659865000052, 11.712992043000042 ],
							[ 4.413120215000049, 11.717348523000055 ],
							[ 4.408489960000054, 11.728597803000071 ],
							[ 4.407897564000052, 11.735964829000068 ],
							[ 4.400281980000045, 11.746685489000072 ],
							[ 4.385780082000053, 11.749947343000031 ],
							[ 4.373366687000043, 11.745859725000059 ],
							[ 4.361458396000046, 11.741286987000024 ],
							[ 4.348658245000024, 11.727478936000068 ],
							[ 4.356079407000038, 11.721138761000077 ],
							[ 4.35991986800002, 11.714702892000048 ],
							[ 4.366566148000061, 11.709228084000074 ],
							[ 4.367091661000075, 11.706121826000071 ],
							[ 4.37219281900002, 11.701658283000029 ],
							[ 4.375315250000028, 11.693071232000023 ],
							[ 4.382340416000034, 11.688389093000069 ],
							[ 4.386243322000041, 11.68136325100005 ],
							[ 4.394048730000065, 11.679801713000074 ],
							[ 4.398732191000022, 11.67980211500003 ],
							[ 4.399512720000075, 11.676679748000026 ],
							[ 4.401854574000026, 11.668093522000049 ],
							[ 4.409659912000052, 11.656385176000072 ],
							[ 4.435418632000051, 11.629845516000046 ],
							[ 4.447907912000062, 11.61891827200003 ],
							[ 4.470544345000064, 11.593939806000037 ],
							[ 4.477373348000071, 11.589594205000026 ],
							[ 4.487577929000054, 11.583100527000056 ],
							[ 4.497236697000062, 11.581740178000075 ],
							[ 4.50567430600006, 11.585576196000034 ],
							[ 4.519481124000038, 11.598615851000034 ],
							[ 4.530986763000044, 11.607820608000054 ],
							[ 4.544026357000064, 11.620092356000043 ],
							[ 4.547040154000058, 11.629133585000034 ],
							[ 4.550801762000049, 11.63053420600005 ],
							[ 4.559712479000041, 11.635556683000061 ],
							[ 4.564107498000055, 11.642983554000068 ],
							[ 4.566545169000051, 11.647436936000076 ],
							[ 4.580895945000066, 11.661192081000024 ],
							[ 4.583145683000055, 11.661513231000072 ],
							[ 4.590049274000023, 11.659978671000033 ],
							[ 4.605390116000024, 11.656144177000044 ],
							[ 4.613060414000074, 11.656143879000069 ],
							[ 4.620730854000044, 11.656910732000028 ],
							[ 4.628401810000071, 11.666882507000025 ],
							[ 4.639907410000035, 11.670718161000025 ],
							[ 4.645276581000076, 11.672251952000067 ],
							[ 4.656782114000066, 11.669950203000042 ],
							[ 4.66982215500002, 11.665348760000029 ],
							[ 4.678259332000039, 11.658445633000042 ],
							[ 4.689765512000065, 11.656910774000039 ],
							[ 4.699737081000023, 11.659211915000071 ],
							[ 4.713543835000053, 11.668417185000067 ],
							[ 4.728117473000054, 11.676087622000068 ],
							[ 4.73885629800003, 11.675319903000059 ],
							[ 4.749594613000056, 11.674552996000045 ],
							[ 4.754197183000031, 11.675319832000071 ],
							[ 4.759566279000069, 11.680689664000056 ],
							[ 4.764935964000074, 11.689893766000068 ],
							[ 4.770305113000063, 11.692194744000062 ],
							[ 4.77874274100003, 11.694496048000076 ],
							[ 4.783344740000075, 11.698331791000044 ],
							[ 4.78564599300006, 11.703700870000034 ],
							[ 4.787179997000067, 11.710604907000061 ],
							[ 4.79101511500005, 11.716740584000036 ],
							[ 4.795617554000046, 11.725177803000065 ],
							[ 4.804822007000041, 11.727479269000071 ],
							[ 4.811725598000066, 11.725177794000047 ],
							[ 4.82399844300005, 11.722876703000054 ],
							[ 4.832435603000022, 11.716740807000065 ],
							[ 4.840106444000071, 11.710604639000053 ],
							[ 4.850078129000053, 11.714439159000051 ],
							[ 4.860049673000049, 11.720576486000027 ],
							[ 4.866952929000036, 11.726712190000057 ],
							[ 4.874623290000045, 11.729013497000039 ],
							[ 4.887663338000038, 11.725945288000048 ],
							[ 4.888430192000044, 11.719041727000047 ],
							[ 4.893799280000053, 11.711371472000053 ],
							[ 4.903770863000034, 11.713672743000075 ],
							[ 4.912208510000028, 11.719808706000038 ],
							[ 4.924864792000051, 11.720576050000034 ],
							[ 4.932535123000036, 11.719808602000057 ],
							[ 4.940972819000024, 11.725945586000023 ],
							[ 4.942946371000062, 11.732523763000074 ],
							[ 4.943274077000069, 11.733615594000071 ],
							[ 4.958614803000046, 11.735149587000024 ],
							[ 4.969353614000056, 11.736683911000057 ],
							[ 4.97702412600006, 11.741286144000071 ],
							[ 4.992364842000029, 11.741286391000074 ],
							[ 5.003870513000038, 11.738985200000059 ],
							[ 5.010774206000065, 11.735149396000054 ],
							[ 5.015673188000051, 11.728944245000037 ],
							[ 5.027753152000059, 11.729868605000036 ],
							[ 5.034153753000055, 11.731519061000029 ],
							[ 5.061500374000047, 11.725481056000035 ],
							[ 5.075643779000075, 11.723731665000059 ],
							[ 5.086711464000075, 11.725945233000061 ],
							[ 5.09975139900007, 11.72824640500005 ],
							[ 5.117393554000046, 11.719808861000047 ],
							[ 5.138870644000065, 11.72210971800007 ],
							[ 5.148842420000051, 11.733616055000027 ],
							[ 5.164183179000077, 11.738984855000069 ],
							[ 5.171086832000071, 11.741286227000046 ],
							[ 5.175689094000063, 11.749723715000073 ],
							[ 5.174922029000072, 11.75816181700003 ],
							[ 5.169552752000072, 11.764297491000036 ],
							[ 5.163416336000068, 11.772734440000022 ],
							[ 5.165717670000049, 11.778871767000055 ],
							[ 5.172620643000073, 11.783473861000061 ],
							[ 5.182592408000062, 11.781172834000074 ],
							[ 5.193331155000067, 11.775803379000024 ],
							[ 5.204836732000047, 11.770433560000072 ],
							[ 5.209438809000062, 11.76046205800003 ],
							[ 5.221711608000021, 11.75432601600005 ],
							[ 5.226314021000064, 11.757393988000047 ],
							[ 5.229381966000062, 11.76122943300004 ],
							[ 5.234751839000069, 11.769666598000072 ],
							[ 5.260064126000032, 11.77043358700007 ],
							[ 5.280007239000042, 11.770433191000052 ],
							[ 5.287677760000065, 11.773502267000026 ],
							[ 5.291512972000021, 11.781172892000029 ],
							[ 5.298416576000022, 11.786542219000069 ],
							[ 5.304690233000031, 11.784260943000049 ],
							[ 5.306853631000024, 11.783473897000022 ],
							[ 5.308387862000075, 11.77580370000004 ],
							[ 5.310688867000067, 11.770433410000066 ],
							[ 5.318359255000075, 11.766598965000071 ],
							[ 5.322194503000048, 11.761996405000048 ],
							[ 5.330632258000037, 11.746655232000023 ],
							[ 5.330632254000022, 11.738985031000027 ],
							[ 5.341371159000062, 11.729013167000062 ],
							[ 5.345973149000031, 11.72057630300003 ],
							[ 5.345973131000051, 11.712138211000024 ],
							[ 5.35249445200003, 11.704892580000035 ],
							[ 5.352876645000038, 11.704468030000044 ],
							[ 5.362848314000075, 11.709836917000075 ],
							[ 5.37237020200007, 11.705756164000036 ],
							[ 5.372819942000035, 11.708303740000076 ],
							[ 5.377422279000029, 11.708303450000074 ],
							[ 5.388927919000025, 11.707535716000052 ],
							[ 5.398899477000043, 11.704467801000021 ],
							[ 5.408103818000029, 11.702934146000075 ],
							[ 5.42421187900004, 11.70293416100003 ],
							[ 5.431115154000054, 11.704467907000037 ],
							[ 5.443387893000022, 11.702934034000066 ],
							[ 5.450291758000049, 11.697563979000051 ],
							[ 5.454893664000053, 11.680689948000065 ],
							[ 5.457961973000067, 11.672251761000041 ],
							[ 5.461813522000057, 11.665992953000057 ],
							[ 5.469659387000036, 11.668608509000023 ],
							[ 5.476371150000034, 11.668608395000035 ],
							[ 5.486917835000042, 11.668608844000062 ],
							[ 5.502258716000028, 11.66956723800007 ],
							[ 5.511846916000025, 11.668608722000045 ],
							[ 5.524311638000029, 11.662856078000061 ],
							[ 5.541570342000057, 11.665732103000039 ],
							[ 5.54540546800007, 11.672443542000053 ],
							[ 5.554993133000039, 11.675319837000075 ],
							[ 5.556911114000059, 11.682031455000072 ],
							[ 5.559787377000021, 11.685867497000061 ],
							[ 5.565540398000053, 11.682991077000054 ],
							[ 5.574169563000055, 11.678196860000071 ],
							[ 5.582799009000041, 11.675319584000022 ],
							[ 5.590469465000069, 11.670526352000024 ],
							[ 5.593345662000047, 11.669567333000032 ],
							[ 5.604851319000034, 11.668608723000034 ],
							[ 5.611563199000045, 11.667649015000052 ],
							[ 5.617315775000066, 11.66764945400007 ],
							[ 5.639368608000041, 11.662855952000029 ],
							[ 5.646079970000073, 11.662856158000068 ],
							[ 5.658544469000049, 11.668608534000043 ],
							[ 5.671968124000045, 11.659979109000062 ],
							[ 5.679638511000064, 11.663814760000037 ],
							[ 5.692102823000027, 11.667649441000037 ],
							[ 5.698814277000054, 11.672443465000072 ],
							[ 5.70552635100006, 11.676279090000037 ],
							[ 5.711278823000043, 11.682031692000066 ],
							[ 5.718949381000073, 11.684907666000072 ],
							[ 5.724702217000072, 11.684908145000065 ],
							[ 5.724702380000053, 11.691620039000043 ],
							[ 5.730454809000037, 11.694496369000035 ],
							[ 5.737166849000062, 11.688743579000061 ],
							[ 5.744837373000053, 11.68107291900003 ],
							[ 5.757301824000024, 11.682031731000052 ],
							[ 5.757301744000074, 11.687784193000027 ],
							[ 5.765931129000023, 11.693537874000071 ],
							[ 5.782230767000044, 11.676279046000047 ],
							[ 5.807159907000027, 11.658062061000066 ],
							[ 5.819306907000055, 11.649385277000022 ],
							[ 5.820699956000055, 11.649293244000035 ],
							[ 5.820657088000075, 11.648694522000028 ],
							[ 5.827904403000048, 11.646380559000022 ],
							[ 5.839256172000034, 11.633806746000062 ],
							[ 5.84289558200004, 11.610139549000053 ],
							[ 5.843938426000022, 11.603358554000067 ],
							[ 5.840436454000042, 11.590658141000063 ],
							[ 5.823399072000029, 11.565172064000024 ],
							[ 5.820299384000066, 11.552474860000075 ],
							[ 5.817231580000055, 11.536214284000039 ],
							[ 5.816627728000071, 11.513639724000029 ],
							[ 5.819998415000043, 11.495454981000023 ],
							[ 5.824129821000042, 11.482027450000032 ],
							[ 5.826959414000044, 11.479676267000059 ],
							[ 5.840205075000029, 11.479393145000074 ],
							[ 5.856256271000063, 11.479529403000072 ],
							[ 5.869080212000028, 11.481617629000027 ],
							[ 5.917564391000042, 11.490338230000077 ],
							[ 5.936017056000026, 11.491283138000028 ],
							[ 5.954884312000047, 11.49064618400007 ],
							[ 5.966528496000024, 11.489949349000028 ],
							[ 5.992237546000069, 11.486990968000043 ],
							[ 6.00914739500007, 11.480396427000073 ],
							[ 6.021117331000028, 11.46940518100007 ],
							[ 6.029852418000075, 11.454118900000026 ],
							[ 6.06633974600004, 11.41868977300004 ],
							[ 6.13056995900007, 11.374539473000027 ],
							[ 6.14942562400006, 11.345792054000071 ],
							[ 6.155036971000072, 11.309062467000047 ],
							[ 6.128868010000076, 11.253702015000044 ],
							[ 6.062446580000028, 11.242675311000028 ],
							[ 6.054487662000042, 11.23998537500006 ],
							[ 6.036017126000047, 11.221452069000065 ],
							[ 6.030761623000046, 11.213530073000072 ],
							[ 6.022911367000063, 11.19770718500007 ],
							[ 6.01772174000007, 11.181905526000037 ],
							[ 6.008521716000075, 11.141016 ],
							[ 6.006212368000035, 11.141978265000034 ],
							[ 6.000590183000043, 11.143584452000027 ],
							[ 5.993361586000049, 11.145994019000057 ],
							[ 5.985329603000025, 11.144387401000074 ],
							[ 5.974084851000043, 11.136355366000032 ],
							[ 5.962036983000075, 11.12029169300007 ],
							[ 5.960174960000074, 11.117856944000039 ],
							[ 5.951595721000047, 11.106637739000064 ],
							[ 5.945170379000047, 11.100212354000064 ],
							[ 5.935531998000044, 11.089770559000044 ],
							[ 5.926696908000054, 11.076919602000032 ],
							[ 5.921074483000041, 11.070494784000061 ],
							[ 5.91143622900006, 11.064871568000058 ],
							[ 5.893766377000077, 11.062462689000029 ],
							[ 5.875292911000031, 11.059250038000073 ],
							[ 5.86324505500005, 11.057643048000045 ],
							[ 5.844771946000037, 11.056839921000062 ],
							[ 5.833341911000048, 11.056167850000065 ],
							[ 5.83111770000005, 11.056036981000034 ],
							[ 5.820676394000031, 11.052824032000046 ],
							[ 5.807427978000021, 11.045851713000047 ],
							[ 5.805415655000047, 11.044792449000056 ],
							[ 5.795777211000029, 11.043989411000041 ],
							[ 5.779713491000052, 11.035154230000046 ],
							[ 5.765256499000031, 11.028728697000076 ],
							[ 5.754814507000049, 11.017483458000072 ],
							[ 5.741963921000035, 11.003830002000029 ],
							[ 5.72108071100007, 10.994995045000053 ],
							[ 5.70421406600002, 10.990175377000071 ],
							[ 5.691362984000023, 10.982143473000065 ],
							[ 5.68011868800005, 10.975718358000051 ],
							[ 5.664054739000051, 10.973308326000051 ],
							[ 5.65682593300005, 10.966882462000058 ],
							[ 5.636746472000027, 10.96206404000003 ],
							[ 5.622288671000035, 10.962063687000068 ],
							[ 5.60943797300007, 10.965276793000044 ],
							[ 5.589358249000043, 10.964473874000021 ],
							[ 5.574900752000076, 10.965276883000058 ],
							[ 5.562852987000042, 10.963670823000029 ],
							[ 5.533938269000032, 10.96126064300006 ],
							[ 5.525906635000069, 10.96206354900005 ],
							[ 5.514661756000066, 10.962063672000056 ],
							[ 5.492172784000047, 10.958851146000029 ],
							[ 5.472896125000034, 10.95644192900005 ],
							[ 5.465667253000049, 10.954032077000022 ],
							[ 5.458438757000067, 10.95322909600003 ],
							[ 5.45281641300005, 10.956441908000045 ],
							[ 5.447809672000062, 10.96695648900004 ],
							[ 5.44478476200004, 10.973308625000072 ],
							[ 5.440509057000043, 10.985444467000036 ],
							[ 5.440724555000031, 10.986064698000064 ],
							[ 5.439965179000069, 10.998207140000034 ],
							[ 5.435949446000052, 11.007042674000047 ],
							[ 5.429990297000074, 11.016810452000072 ],
							[ 5.431130278000069, 11.019090677000065 ],
							[ 5.42872107900007, 11.039973195000073 ],
							[ 5.428761206000047, 11.040979939000067 ],
							[ 5.418115381000064, 11.040823581000041 ],
							[ 5.409898157000043, 11.042544364000037 ],
							[ 5.40514655100003, 11.043539145000068 ],
							[ 5.403991888000064, 11.043780520000041 ],
							[ 5.403434073000028, 11.043994926000039 ],
							[ 5.40278495900003, 11.044533895000029 ],
							[ 5.401537862000055, 11.04549989700007 ],
							[ 5.397920203000069, 11.048301320000064 ],
							[ 5.394654099000036, 11.050830965000046 ],
							[ 5.392956477000041, 11.052145705000044 ],
							[ 5.389621718000058, 11.054728296000064 ],
							[ 5.387528590000045, 11.056349389000047 ],
							[ 5.385055359000035, 11.05885839900003 ],
							[ 5.384337953000056, 11.059585972000036 ],
							[ 5.386179266000056, 11.075677885000061 ],
							[ 5.387244275000057, 11.084827355000073 ],
							[ 5.387074220000045, 11.101858517000039 ],
							[ 5.38703805800003, 11.105480109000041 ],
							[ 5.387065115000041, 11.110334134000027 ],
							[ 5.40126624800007, 11.124519848000034 ],
							[ 5.402718904000039, 11.125836371000048 ],
							[ 5.409529278000036, 11.132010515000047 ],
							[ 5.411053725000045, 11.133392689000061 ],
							[ 5.411922110000035, 11.134180277000041 ],
							[ 5.414220013000033, 11.136263230000054 ],
							[ 5.417144227000051, 11.138913866000053 ],
							[ 5.417137715000024, 11.139571508000074 ],
							[ 5.416971872000033, 11.156316734000029 ],
							[ 5.416711004000035, 11.157064606000063 ],
							[ 5.414631858000064, 11.163029473000051 ],
							[ 5.413972862000037, 11.164919950000069 ],
							[ 5.412379519000069, 11.169491030000074 ],
							[ 5.408640991000027, 11.170892889000072 ],
							[ 5.39418366600006, 11.178924317000053 ],
							[ 5.381332789000055, 11.186153235000063 ],
							[ 5.374104084000066, 11.191775485000051 ],
							[ 5.368481718000055, 11.205430167000031 ],
							[ 5.358040337000034, 11.22711591500007 ],
							[ 5.358040371000072, 11.232738656000038 ],
							[ 5.358843322000041, 11.244786488000045 ],
							[ 5.356433754000022, 11.249605239000061 ],
							[ 5.353221011000073, 11.258439774000067 ],
							[ 5.353221058000031, 11.271290527000076 ],
							[ 5.353221011000073, 11.276913826000055 ],
							[ 5.350811497000052, 11.296190264000074 ],
							[ 5.347599162000051, 11.304222355000036 ],
							[ 5.344385970000076, 11.315466099000048 ],
							[ 5.338763669000059, 11.327514420000057 ],
							[ 5.337157145000049, 11.333136531000036 ],
							[ 5.336900143000037, 11.335451231000036 ],
							[ 5.335983061000036, 11.335763102000044 ],
							[ 5.325734644000022, 11.339248661000056 ],
							[ 5.317324244000076, 11.342054343000029 ],
							[ 5.316238726000051, 11.341899484000066 ],
							[ 5.308813894000025, 11.340841979000061 ],
							[ 5.300650009000037, 11.33967864300007 ],
							[ 5.295636886000068, 11.338968107000028 ],
							[ 5.295007530000021, 11.338878765000061 ],
							[ 5.293351553000036, 11.338644120000026 ],
							[ 5.291764655000065, 11.337226978000047 ],
							[ 5.289277831000049, 11.335006360000023 ],
							[ 5.284597922000046, 11.330815527000027 ],
							[ 5.280061645000046, 11.326753216000043 ],
							[ 5.279614962000039, 11.32635321500004 ],
							[ 5.279043234000028, 11.325841202000049 ],
							[ 5.278213306000055, 11.325608548000048 ],
							[ 5.276943879000044, 11.325253413000041 ],
							[ 5.253456249000067, 11.31867556800006 ],
							[ 5.243881209000051, 11.315993708000065 ],
							[ 5.239170257000069, 11.303304872000069 ],
							[ 5.239295355000024, 11.293202050000048 ],
							[ 5.239353402000063, 11.285885323000059 ],
							[ 5.239415448000045, 11.285093198000027 ],
							[ 5.239751045000048, 11.280808483000044 ],
							[ 5.239904501000069, 11.278849337000054 ],
							[ 5.240155345000062, 11.275447480000025 ],
							[ 5.240372214000047, 11.272508069000025 ],
							[ 5.240470809000044, 11.271171037000045 ],
							[ 5.240513443000054, 11.270595123000021 ],
							[ 5.24060673300005, 11.269328118000033 ],
							[ 5.241012794000028, 11.263824189000047 ],
							[ 5.241170700000055, 11.261680876000071 ],
							[ 5.241240983000068, 11.260728917000051 ],
							[ 5.229342120000069, 11.254567859000076 ],
							[ 5.228534598000067, 11.254072030000032 ],
							[ 5.22165000800004, 11.249870369000064 ],
							[ 5.217092318000027, 11.247088556000051 ],
							[ 5.211907283000073, 11.243886647000068 ],
							[ 5.199836475000041, 11.236432864000051 ],
							[ 5.193756897000071, 11.232340641000064 ],
							[ 5.18046907400003, 11.223395009000058 ],
							[ 5.178013192000037, 11.221741820000034 ],
							[ 5.171072233000075, 11.21703122100007 ],
							[ 5.168285562000051, 11.214577831000042 ],
							[ 5.164820850000069, 11.211526935000052 ],
							[ 5.161724789000061, 11.208794545000046 ],
							[ 5.161339546000022, 11.208454929000027 ],
							[ 5.159767436000038, 11.207067846000029 ],
							[ 5.158801384000071, 11.206215372000031 ],
							[ 5.155857218000051, 11.206509212000071 ],
							[ 5.152926228000069, 11.206801462000044 ],
							[ 5.14904948800006, 11.207668147000049 ],
							[ 5.13952775100006, 11.20843145200007 ],
							[ 5.137718343000074, 11.213170321000064 ],
							[ 5.136265374000061, 11.21697604700006 ],
							[ 5.135452379000071, 11.21859151600006 ],
							[ 5.133826336000027, 11.221720206000043 ],
							[ 5.132144565000033, 11.222322519000045 ],
							[ 5.131390042000021, 11.222562148000065 ],
							[ 5.13018848400003, 11.222944015000053 ],
							[ 5.12737622700007, 11.223836834000053 ],
							[ 5.126845649000074, 11.224005322000039 ],
							[ 5.124774462000062, 11.22495275700004 ],
							[ 5.124089360000028, 11.225282360000051 ],
							[ 5.121876421000024, 11.226345834000028 ],
							[ 5.113639945000045, 11.230365287000041 ],
							[ 5.11298541900004, 11.233572975000072 ],
							[ 5.115994237000052, 11.237433817000067 ],
							[ 5.121781204000058, 11.237494354000034 ],
							[ 5.123199414000055, 11.237434576000055 ],
							[ 5.124728689000051, 11.237370325000029 ],
							[ 5.130419165000035, 11.236793473000034 ],
							[ 5.135165233000066, 11.234512124000048 ],
							[ 5.13843300700006, 11.232851004000054 ],
							[ 5.138870736000058, 11.232588863000046 ],
							[ 5.144623423000041, 11.229146525000033 ],
							[ 5.145596066000053, 11.228549214000054 ],
							[ 5.147389458000021, 11.227568265000059 ],
							[ 5.15024859500005, 11.226409085000057 ],
							[ 5.151682309000023, 11.225881512000058 ],
							[ 5.152906620000067, 11.225430878000054 ],
							[ 5.168262377000076, 11.238186365000047 ],
							[ 5.171583670000075, 11.240944735000028 ],
							[ 5.176839857000061, 11.245310251000035 ],
							[ 5.177337647000058, 11.245645362000062 ],
							[ 5.177758457000039, 11.245928817000049 ],
							[ 5.195117417000063, 11.254000932000054 ],
							[ 5.19590729600003, 11.254618706000031 ],
							[ 5.20906915300003, 11.264943453000058 ],
							[ 5.210543653000059, 11.268859576000068 ],
							[ 5.21560230700004, 11.282859884000061 ],
							[ 5.215875994000044, 11.283617009000068 ],
							[ 5.215876123000044, 11.284945432000029 ],
							[ 5.216679203000069, 11.28896147200004 ],
							[ 5.216678978000061, 11.292977226000062 ],
							[ 5.216678997000031, 11.299402345000033 ],
							[ 5.214269689000048, 11.306631228000072 ],
							[ 5.212663037000027, 11.312253954000028 ],
							[ 5.209450831000026, 11.322694789000025 ],
							[ 5.203025441000023, 11.330726718000051 ],
							[ 5.193386922000059, 11.333939701000077 ],
							[ 5.184551734000024, 11.339562683000054 ],
							[ 5.176519821000056, 11.341168359000051 ],
							[ 5.16848804600005, 11.341971520000072 ],
							[ 5.162062680000076, 11.344381144000067 ],
							[ 5.154251172000045, 11.346612991000029 ],
							[ 5.150817944000039, 11.347594482000034 ],
							[ 5.14439261800004, 11.34920031200005 ],
							[ 5.140376487000026, 11.351610290000053 ],
							[ 5.133951062000051, 11.353215939000052 ],
							[ 5.125919477000025, 11.354019295000057 ],
							[ 5.117887538000048, 11.355625983000039 ],
							[ 5.105036190000021, 11.352412978000075 ],
							[ 5.100217400000076, 11.345987563000051 ],
							[ 5.100217242000042, 11.33875839500007 ],
							[ 5.101823412000044, 11.330726794000043 ],
							[ 5.104233194000074, 11.322694566000052 ],
							[ 5.105839203000073, 11.314662920000046 ],
							[ 5.105839133000075, 11.301009195000063 ],
							[ 5.101020410000046, 11.285748710000064 ],
							[ 5.100217414000042, 11.279322476000061 ],
							[ 5.093791379000038, 11.270487624000054 ],
							[ 5.089775634000034, 11.26486606800006 ],
							[ 5.080137209000043, 11.25522692800007 ],
							[ 5.073711738000043, 11.241573557000038 ],
							[ 5.071302493000076, 11.237557132000063 ],
							[ 5.071302323000054, 11.231934328000023 ],
							[ 5.071302308000043, 11.227115595000043 ],
							[ 5.068892878000042, 11.223099703000059 ],
							[ 5.06648339000003, 11.216675 ],
							[ 5.06246744300006, 11.208642042000065 ],
							[ 5.057648235000045, 11.204625907000036 ],
							[ 5.052829203000044, 11.205430139000043 ],
							[ 5.048813296000048, 11.211052014000074 ],
							[ 5.048813393000046, 11.219083625000053 ],
							[ 5.048813301000052, 11.227918730000056 ],
							[ 5.052829127000052, 11.231131387000062 ],
							[ 5.056845189000057, 11.237557441000035 ],
							[ 5.062467684000069, 11.244786481000062 ],
							[ 5.064876949000052, 11.254424075000031 ],
							[ 5.062467610000056, 11.262456093000026 ],
							[ 5.05845156700002, 11.270487970000033 ],
							[ 5.052025851000053, 11.272897951000061 ],
							[ 5.045600350000029, 11.272897906000026 ],
							[ 5.040781508000066, 11.270488 ],
							[ 5.034355598000047, 11.264866089000066 ],
							[ 5.032749605000049, 11.26085009600007 ],
							[ 5.025520799000049, 11.256030269000064 ],
							[ 5.016685378000034, 11.251211161000072 ],
							[ 5.011866503000022, 11.252014311000039 ],
							[ 5.005441075000022, 11.257636930000047 ],
							[ 5.00624405700006, 11.262455825000075 ],
							[ 5.008653615000071, 11.266472119000071 ],
							[ 5.011063533000026, 11.270487844000058 ],
							[ 5.010260004000031, 11.27450347000007 ],
							[ 5.008653745000061, 11.279322437000076 ],
							[ 5.000621746000036, 11.282535818000042 ],
							[ 4.990983491000065, 11.286551577000068 ],
							[ 4.988574008000057, 11.29137025700004 ],
							[ 4.984558138000068, 11.304222323000033 ],
							[ 4.981345680000061, 11.319481658000029 ],
							[ 4.977329776000033, 11.329120775000035 ],
							[ 4.972510306000061, 11.339562545000035 ],
							[ 4.972510283000076, 11.347594104000052 ],
							[ 4.970903828000075, 11.353216385000053 ],
							[ 4.966085028000066, 11.362051060000056 ],
							[ 4.958856067000056, 11.36928014800003 ],
							[ 4.952430716000038, 11.369279640000059 ],
							[ 4.947611859000062, 11.364460899000051 ],
							[ 4.942792391000069, 11.357232174000046 ],
							[ 4.933957569000029, 11.354019252000057 ],
							[ 4.929941648000067, 11.357232151000062 ],
							[ 4.923515869000028, 11.364460905000044 ],
							[ 4.917893804000073, 11.370885820000069 ],
							[ 4.905845704000058, 11.373295818000031 ],
							[ 4.890585456000053, 11.37088564000004 ],
							[ 4.880143661000034, 11.361248300000057 ],
							[ 4.871308785000053, 11.353216291000024 ],
							[ 4.86488348000006, 11.350806033000026 ],
							[ 4.848819748000039, 11.34357830700003 ],
							[ 4.837574914000072, 11.337152630000048 ],
							[ 4.826330238000025, 11.335546489000023 ],
							[ 4.81990478900002, 11.330726627000047 ],
							[ 4.805447543000071, 11.321088757000041 ],
							[ 4.782958003000033, 11.31225415800003 ],
							[ 4.766075644000068, 11.306000630000028 ],
							[ 4.762192418000041, 11.313450575000047 ],
							[ 4.761457664000034, 11.314919782000061 ],
							[ 4.760019040000032, 11.313466428000027 ],
							[ 4.758655065000028, 11.312078337000059 ],
							[ 4.755930126000067, 11.309305563000066 ],
							[ 4.754884554000057, 11.308774315000051 ],
							[ 4.75168341400007, 11.307148193000046 ],
							[ 4.751168759000052, 11.306898191000073 ],
							[ 4.749583916000063, 11.30612853100007 ],
							[ 4.748018538000053, 11.30550656500003 ],
							[ 4.747036703000049, 11.304985061000025 ],
							[ 4.746458184000062, 11.304591935000076 ],
							[ 4.745280435000041, 11.30379189100006 ],
							[ 4.74275784200006, 11.30207798400005 ],
							[ 4.740883917000076, 11.300804328000027 ],
							[ 4.740570959000024, 11.299503939000033 ],
							[ 4.740119936000042, 11.297630705000074 ],
							[ 4.740337214000022, 11.296813309000072 ],
							[ 4.740579928000045, 11.295900147000054 ],
							[ 4.740965791000065, 11.294448435000049 ],
							[ 4.741330337000022, 11.293457554000042 ],
							[ 4.741781858000024, 11.292249575000028 ],
							[ 4.74399277200007, 11.286335687000076 ],
							[ 4.74547590700007, 11.280633795000028 ],
							[ 4.747070345000054, 11.274504314000069 ],
							[ 4.747167410000031, 11.271932753000044 ],
							[ 4.747232896000071, 11.270196534000036 ],
							[ 4.747269270000061, 11.269234101000052 ],
							[ 4.747358247000022, 11.266874896000047 ],
							[ 4.747434719000069, 11.264850050000064 ],
							[ 4.747390359000065, 11.264228546000027 ],
							[ 4.747301181000068, 11.262978200000077 ],
							[ 4.746725293000054, 11.258413880000035 ],
							[ 4.746132044000035, 11.257479260000025 ],
							[ 4.744364973000074, 11.254694759000074 ],
							[ 4.743761605000032, 11.254122221000046 ],
							[ 4.743023350000044, 11.253668488000073 ],
							[ 4.736222316000067, 11.249489805000053 ],
							[ 4.70340625700004, 11.228543278000075 ],
							[ 4.699217313000077, 11.222259353000027 ],
							[ 4.699915415000021, 11.203408155000034 ],
							[ 4.70201011100005, 11.192236994000041 ],
							[ 4.710388250000051, 11.180367197000066 ],
							[ 4.72158528500006, 11.176336572000025 ],
							[ 4.746122355000068, 11.147592376000034 ],
							[ 4.757622301000026, 11.14299287700004 ],
							[ 4.765289237000047, 11.13685977800003 ],
							[ 4.779431781000028, 11.131420138000067 ],
							[ 4.780209003000039, 11.128700310000056 ],
							[ 4.785794672000065, 11.123812789000056 ],
							[ 4.78858759000002, 11.118925125000032 ],
							[ 4.802551755000025, 11.114037465000024 ],
							[ 4.814421105000065, 11.116132926000034 ],
							[ 4.817800545000068, 11.119511665000061 ],
							[ 4.839656405000028, 11.11385940200006 ],
							[ 4.850389957000061, 11.108492946000069 ],
							[ 4.860673978000023, 11.103350593000073 ],
							[ 4.863295538000045, 11.100073704000067 ],
							[ 4.87656161700005, 11.091694720000021 ],
							[ 4.898205750000045, 11.087506398000073 ],
							[ 4.908678974000054, 11.08192067400006 ],
							[ 4.934512561000076, 11.084014637000053 ],
							[ 4.947778245000052, 11.082618926000066 ],
							[ 4.960346210000068, 11.082618728000057 ],
							[ 4.972215712000036, 11.080523776000064 ],
							[ 4.979896026000063, 11.080523477000042 ],
							[ 4.991765472000054, 11.07005040100006 ],
							[ 4.991067463000036, 11.060974270000031 ],
							[ 4.995954389000076, 11.053992120000032 ],
							[ 5.009220559000028, 11.051896943000031 ],
							[ 5.021090010000023, 11.053992012000037 ],
							[ 5.027373770000054, 11.050500677000059 ],
							[ 5.035752267000021, 11.051897312000051 ],
							[ 5.040639727000041, 11.051896982000073 ],
							[ 5.046239356000058, 11.04168627100006 ],
							[ 5.062970136000047, 11.012407353000071 ],
							[ 5.067988924000076, 10.989821605000031 ],
							[ 5.081373634000045, 10.950504212000055 ],
							[ 5.082210148000058, 10.92708119100007 ],
							[ 5.075517776000027, 10.915370173000042 ],
							[ 5.054604611000059, 10.904495084000075 ],
							[ 5.034527645000026, 10.899476124000046 ],
							[ 5.025326132000032, 10.890273859000047 ],
							[ 5.026162485000043, 10.871870289000071 ],
							[ 5.028672167000025, 10.859322292000058 ],
							[ 5.038710455000057, 10.83589936900006 ],
							[ 5.047075666000069, 10.825024757000051 ],
							[ 5.05878726100002, 10.820842027000026 ],
							[ 5.083883382000067, 10.817496185000039 ],
							[ 5.099777217000053, 10.808294511000042 ],
							[ 5.108979303000069, 10.795745691000036 ],
							[ 5.107306114000039, 10.785707270000046 ],
							[ 5.102286789000061, 10.772323501000074 ],
							[ 5.10087866300006, 10.759829796000076 ],
							[ 5.098797226000045, 10.76056395300003 ],
							[ 5.09643125100007, 10.759775061000028 ],
							[ 5.079700423000077, 10.755593223000062 ],
							[ 5.07468117600007, 10.748900568000067 ],
							[ 5.064642854000056, 10.74722771200004 ],
							[ 5.057114167000066, 10.739698402000045 ],
							[ 5.049585378000074, 10.73802583500003 ],
							[ 5.040383850000069, 10.73718907500006 ],
							[ 5.031181857000036, 10.744717883000021 ],
							[ 5.026999082000032, 10.748064020000072 ],
							[ 5.020306942000047, 10.746391368000047 ],
							[ 5.01194152100004, 10.740535460000046 ],
							[ 5.008595362000051, 10.733842933000062 ],
							[ 5.008595617000026, 10.721295602000055 ],
							[ 4.995211057000063, 10.719621519000043 ],
							[ 4.986845992000042, 10.71711250900006 ],
							[ 4.979317128000048, 10.71711281000006 ],
							[ 4.975970752000023, 10.713766303000057 ],
							[ 4.973461191000069, 10.710420380000073 ],
							[ 4.965932431000056, 10.707074070000033 ],
							[ 4.955893941000056, 10.704564219000076 ],
							[ 4.948365153000054, 10.701218676000053 ],
							[ 4.946692491000022, 10.693689462000066 ],
							[ 4.941673219000052, 10.688670364000075 ],
							[ 4.931634884000061, 10.687833566000052 ],
							[ 4.923269693000066, 10.693689533000054 ],
							[ 4.914904167000032, 10.687833660000024 ],
							[ 4.909048597000037, 10.687834032000069 ],
							[ 4.893990936000023, 10.693689402000075 ],
							[ 4.890645032000066, 10.701218594000068 ],
							[ 4.881442970000023, 10.702891296000075 ],
							[ 4.868058724000036, 10.700381950000065 ],
							[ 4.862203066000063, 10.707074264000028 ],
							[ 4.847145604000048, 10.704564134000066 ],
							[ 4.843799165000064, 10.699544934000073 ],
							[ 4.836270283000033, 10.700382017000038 ],
							[ 4.831251175000034, 10.703727861000061 ],
							[ 4.814520869000035, 10.704564259000051 ],
							[ 4.809501600000033, 10.69787244500003 ],
							[ 4.80699205600007, 10.692852760000051 ],
							[ 4.814520826000035, 10.674449852000066 ],
							[ 4.812011270000028, 10.661901713000077 ],
							[ 4.802809230000037, 10.652699312000038 ],
							[ 4.796117236000043, 10.629277359000071 ],
							[ 4.801972947000024, 10.613383227000043 ],
							[ 4.814520875000028, 10.604181152000024 ],
							[ 4.82372242100007, 10.594979372000068 ],
							[ 4.827905128000054, 10.569883446000063 ],
							[ 4.822049587000038, 10.559008642000038 ],
							[ 4.819539961000032, 10.538095622000071 ],
							[ 4.81870318600005, 10.522201255000027 ],
							[ 4.818703129000028, 10.507144174000075 ],
							[ 4.826231913000072, 10.495431956000061 ],
							[ 4.832924414000047, 10.487903645000074 ],
							[ 4.845054151000056, 10.478701459000035 ],
							[ 4.853419294000048, 10.460297360000027 ],
							[ 4.852582928000061, 10.444403585000032 ],
							[ 4.846726954000076, 10.420981432000076 ],
							[ 4.844217351000054, 10.389192832000049 ],
							[ 4.840286555000034, 10.371505075000073 ],
							[ 4.847116414000027, 10.342635355000027 ],
							[ 4.853479280000045, 10.320254196000064 ],
							[ 4.856323074000045, 10.300424449000047 ],
							[ 4.854647685000032, 10.294360397000048 ],
							[ 4.848562145000074, 10.292565264000075 ],
							[ 4.841584506000061, 10.292486914000051 ],
							[ 4.829344027000047, 10.294939495000051 ],
							[ 4.813644475000046, 10.294762209000055 ],
							[ 4.803168042000038, 10.295507563000058 ],
							[ 4.802757024000073, 10.296298548000038 ],
							[ 4.776458794000064, 10.295501470000033 ],
							[ 4.74885318500003, 10.305540007000047 ],
							[ 4.731286056000044, 10.314741948000062 ],
							[ 4.712882535000062, 10.310559452000064 ],
							[ 4.705353797000043, 10.302193622000061 ],
							[ 4.702007724000055, 10.293828758000075 ],
							[ 4.702007710000032, 10.27291593800004 ],
							[ 4.705353771000034, 10.252839301000051 ],
							[ 4.696988531000045, 10.240290910000056 ],
							[ 4.67691185800004, 10.230252528000051 ],
							[ 4.669383038000035, 10.226069255000027 ],
							[ 4.671055839000076, 10.211849076000021 ],
							[ 4.678584519000026, 10.197627838000074 ],
							[ 4.69280580700007, 10.180060534000063 ],
							[ 4.705353702000025, 10.172531911000021 ],
							[ 4.712882450000052, 10.161657575000049 ],
							[ 4.712882568000055, 10.156638423000061 ],
							[ 4.710791062000055, 10.131542101000036 ],
							[ 4.699916425000026, 10.119830250000064 ],
							[ 4.689878051000051, 10.113974306000046 ],
							[ 4.668128068000044, 10.113138143000072 ],
							[ 4.643868956000063, 10.120667345000072 ],
							[ 4.636340220000022, 10.127359168000055 ],
							[ 4.635644726000066, 10.127583794000032 ],
							[ 4.626700330000062, 10.123394185000052 ],
							[ 4.617965158000061, 10.122897488000035 ],
							[ 4.61399736900006, 10.122457153000028 ],
							[ 4.610010382000041, 10.123590332000049 ],
							[ 4.589275953000026, 10.129639946000054 ],
							[ 4.579357998000035, 10.131970085000034 ],
							[ 4.578817711000056, 10.131791270000065 ],
							[ 4.537629550000077, 10.118157544000042 ],
							[ 4.526921198000025, 10.114263504000064 ],
							[ 4.527485399000057, 10.143033796000054 ],
							[ 4.551834065000037, 10.194852852000054 ],
							[ 4.567442371000027, 10.222947937000072 ],
							[ 4.568690776000039, 10.246048074000043 ],
							[ 4.566055740000024, 10.246030058000031 ],
							[ 4.566055774000063, 10.256835201000058 ],
							[ 4.55999657600006, 10.268087555000022 ],
							[ 4.541819514000053, 10.287129579000066 ],
							[ 4.518449301000032, 10.298381925000058 ],
							[ 4.490751217000025, 10.313961807000055 ],
							[ 4.482961218000071, 10.328677284000037 ],
							[ 4.483826596000029, 10.340794367000058 ],
							[ 4.48642346400004, 10.368492866000054 ],
							[ 4.479498871000033, 10.421292231000052 ],
							[ 4.485557995000022, 10.469764763000057 ],
							[ 4.498541345000035, 10.517370259000074 ],
							[ 4.510058333000075, 10.535167838000064 ],
							[ 4.51085691000003, 10.535522782000044 ],
							[ 4.525493158000074, 10.542027744000052 ],
							[ 4.547369896000021, 10.550325228000077 ],
							[ 4.585088776000021, 10.563904846000071 ],
							[ 4.606965007000042, 10.575974637000058 ],
							[ 4.628087466000068, 10.594078856000067 ],
							[ 4.661279730000047, 10.62123697100003 ],
							[ 4.689191289000064, 10.642358854000065 ],
							[ 4.697489776000054, 10.66725370000006 ],
							[ 4.699518148000038, 10.693626130000041 ],
							[ 4.700507168000058, 10.706480052000074 ],
							[ 4.706542083000045, 10.741181116000064 ],
							[ 4.714839903000041, 10.770601020000072 ],
							[ 4.72012035900002, 10.815863739000065 ],
							[ 4.737471111000048, 10.850565127000039 ],
							[ 4.741997292000065, 10.873195546000034 ],
							[ 4.732190666000065, 10.897335988000066 ],
							[ 4.724556794000023, 10.906424125000058 ],
							[ 4.716348694000033, 10.916194860000076 ],
							[ 4.707637799000054, 10.918297840000037 ],
							[ 4.702770180000073, 10.92449276900004 ],
							[ 4.697049393000043, 10.930759109000064 ],
							[ 4.689088034000065, 10.933409432000076 ],
							[ 4.67443337900005, 10.938389301000029 ],
							[ 4.665039654000054, 10.93930967600005 ],
							[ 4.644910535000065, 10.940068258000053 ],
							[ 4.63638544500003, 10.928264342000034 ],
							[ 4.63336801500003, 10.924492589000067 ],
							[ 4.626578816000062, 10.915440411000077 ],
							[ 4.601684589000058, 10.911668987000041 ],
							[ 4.587351770000055, 10.906388158000027 ],
							[ 4.568492367000033, 10.908651164000048 ],
							[ 4.553404817000057, 10.915440618000048 ],
							[ 4.539826386000072, 10.919966986000077 ],
							[ 4.523984363000068, 10.916195181000035 ],
							[ 4.492300986000032, 10.910914346000027 ],
							[ 4.471933315000058, 10.903371029000027 ],
							[ 4.467736132000027, 10.901844893000032 ],
							[ 4.450919617000068, 10.888324669000042 ],
							[ 4.429085009000062, 10.883934962000069 ],
							[ 4.412344356000062, 10.88887932800003 ],
							[ 4.401434985000037, 10.89622683500005 ],
							[ 4.39800501700006, 10.896581513000058 ],
							[ 4.37990002500004, 10.900353601000063 ],
							[ 4.370847705000074, 10.900353680000023 ],
							[ 4.355760147000069, 10.910159960000044 ],
							[ 4.347462243000052, 10.911668615000053 ],
							[ 4.343673701000057, 10.911533460000044 ],
							[ 4.341008174000024, 10.915531191000071 ],
							[ 4.335703885000044, 10.915319260000047 ],
							[ 4.319368907000069, 10.914666385000032 ],
							[ 4.310713308000061, 10.920724758000063 ],
							[ 4.28820849400006, 10.928515406000031 ],
							[ 4.283880691000036, 10.937170989000037 ],
							[ 4.284746243000029, 10.953616009000029 ],
							[ 4.288208534000034, 10.980448988000035 ],
							[ 4.277821507000056, 10.986508323000066 ],
							[ 4.263107386000058, 10.982180321000044 ],
							[ 4.256182704000025, 10.985642705000032 ],
							[ 4.23887110000004, 11.00728117400007 ],
							[ 4.226610722000032, 11.018696757000043 ],
							[ 4.213769894000052, 11.030652027000031 ],
							[ 4.186071453000068, 11.032383092000032 ],
							[ 4.167028948000052, 11.024593340000024 ],
							[ 4.132406253000056, 11.025458187000027 ],
							[ 4.100380419000032, 11.026324187000057 ],
							[ 4.068354661000058, 11.021996639000065 ],
							[ 3.993915751000031, 11.017668509000032 ],
							[ 3.937653743000055, 11.036710584000048 ],
							[ 3.890913258000069, 11.054022585000041 ],
							[ 3.836382444000037, 11.086914226000033 ],
							[ 3.818447822000053, 11.097894433000022 ],
							[ 3.793969656000058, 11.11288047100004 ],
							[ 3.751556859000061, 11.127595735000057 ],
							[ 3.72212765300003, 11.12759569900004 ],
							[ 3.723256381000056, 11.125112423000076 ],
							[ 3.704749413000059, 11.126113295000039 ],
							[ 3.68538185500006, 11.115317337000022 ],
							[ 3.653614719000075, 11.110930618000054 ],
							[ 3.620972135000045, 11.165478188000066 ],
							[ 3.579894955000043, 11.219557272000031 ],
							[ 3.566764854000041, 11.230447522000077 ],
							[ 3.536307599000054, 11.258049 ],
							[ 3.518223760000069, 11.273278388000051 ],
							[ 3.497284408000041, 11.290885907000074 ],
							[ 3.497284407000052, 11.29754815900003 ],
							[ 3.498236188000021, 11.308017890000031 ],
							[ 3.501567650000027, 11.321343168000055 ],
							[ 3.524586955000075, 11.427345455000022 ],
							[ 3.515786726000044, 11.54121148400003 ],
							[ 3.514892143000054, 11.600145953000037 ],
							[ 3.513937588000033, 11.663009868000074 ],
							[ 3.565199692000022, 11.699131695000062 ],
							[ 3.624880068000039, 11.727940430000046 ],
							[ 3.633778564000067, 11.733660871000041 ],
							[ 3.643925946000024, 11.743027874000063 ],
							[ 3.662659409000071, 11.771128698000041 ],
							[ 3.667342921000056, 11.785178224000049 ],
							[ 3.667342868000048, 11.800790090000021 ],
							[ 3.665001173000064, 11.806253317000028 ],
							[ 3.647828852000032, 11.82264493100007 ],
							[ 3.629875627000047, 11.83435432400006 ],
							[ 3.622070052000026, 11.850746078000043 ],
							[ 3.617386576000058, 11.917094397000028 ],
							[ 3.620508900000061, 11.927241567000067 ],
							[ 3.629875648000052, 11.93426607300006 ],
							[ 3.640803600000027, 11.946755402000065 ],
							[ 3.662659420000068, 11.967049342000053 ],
							[ 3.668904068000074, 11.979538881000053 ],
							[ 3.668904044000044, 11.992808424000032 ],
							[ 3.647048227000028, 12.067743089000032 ],
							[ 3.640803581000057, 12.082573671000034 ],
							[ 3.636900869000044, 12.094282171000032 ],
							[ 3.634559168000067, 12.105210212000031 ],
							[ 3.629875683000023, 12.112235012000042 ],
							[ 3.631436801000063, 12.156727957000044 ],
							[ 3.634559178000075, 12.181705367000063 ],
							[ 3.63690093200006, 12.197317251000072 ],
							[ 3.641584181000042, 12.212147483000024 ],
							[ 3.647828783000023, 12.235564357000044 ],
							[ 3.653292633000035, 12.256639641000049 ],
							[ 3.656415013000071, 12.284740732000046 ],
							[ 3.655634385000042, 12.31752405800006 ],
							[ 3.65329268000005, 12.33313447200004 ],
							[ 3.650170317000061, 12.364357880000057 ],
							[ 3.649389735000057, 12.380749512000023 ],
							[ 3.64782880000007, 12.397141179000073 ],
							[ 3.647828817000061, 12.408069423000029 ],
							[ 3.642364748000034, 12.47910012400007 ],
							[ 3.642364738000026, 12.503297924000037 ],
							[ 3.643145340000046, 12.511884537000071 ],
							[ 3.643925868000053, 12.521250889000044 ],
							[ 3.659537044000047, 12.535301248000053 ],
							[ 3.729788066000026, 12.596185868000077 ],
							[ 3.778963548000036, 12.635994071000027 ],
							[ 3.853507534000073, 12.696097460000033 ],
							[ 3.916733226000076, 12.739809112000046 ],
							[ 3.93468639200006, 12.758542949000059 ],
							[ 3.940150277000043, 12.762445676000027 ],
							[ 3.982300677000069, 12.830354720000059 ],
							[ 4.014304156000037, 12.876408731000026 ],
							[ 4.029915342000038, 12.902947366000035 ],
							[ 4.044745915000021, 12.925583753000069 ],
							[ 4.081432403000065, 12.973197662000075 ],
							[ 4.09665340600003, 12.997395752000045 ],
							[ 4.102898086000039, 13.016129393000028 ],
							[ 4.11070387500007, 13.061402401000066 ],
							[ 4.120070519000024, 13.100430327000026 ],
							[ 4.125534465000044, 13.132433796000043 ],
							[ 4.130217964000053, 13.153509116000066 ],
							[ 4.127095694000047, 13.189415019000023 ],
							[ 4.128656947000025, 13.222978556000044 ],
							[ 4.129437476000021, 13.258104142000036 ],
							[ 4.129437497000026, 13.262787850000052 ],
							[ 4.152073809000058, 13.252640625000026 ],
							[ 4.166904466000062, 13.251859545000059 ],
							[ 4.184076732000051, 13.251859543000023 ],
							[ 4.20202967900002, 13.259665516000041 ],
							[ 4.212957772000038, 13.267471515000068 ],
							[ 4.23247195700003, 13.269812340000044 ],
							[ 4.25354724400006, 13.260446413000068 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Kogi',
				admin1Pcod: 'NG023',
				admin1RefN: 'Kogi',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 10.476383,
				Shape_Area: 2.368882
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.512696821000077, 8.398669299000062 ],
							[ 6.527404728000022, 8.375688490000073 ],
							[ 6.551305298000045, 8.369254147000049 ],
							[ 6.562335978000021, 8.353626250000048 ],
							[ 6.571528321000073, 8.342595069000026 ],
							[ 6.579801959000065, 8.330645476000029 ],
							[ 6.588075250000031, 8.326048834000062 ],
							[ 6.599105934000022, 8.324210279000056 ],
							[ 6.608298331000071, 8.314098467000065 ],
							[ 6.613813977000063, 8.29939074400005 ],
							[ 6.626683176000029, 8.287440212000035 ],
							[ 6.631279513000038, 8.276410037000062 ],
							[ 6.641391139000064, 8.255267260000039 ],
							[ 6.657938137000031, 8.235962872000073 ],
							[ 6.665291879000051, 8.22677036400006 ],
							[ 6.671726660000047, 8.220335935000037 ],
							[ 6.683676652000031, 8.212981090000028 ],
							[ 6.688272870000048, 8.201951031000021 ],
							[ 6.690111669000032, 8.185403790000066 ],
							[ 6.691258404000052, 8.183006379000062 ],
							[ 6.699763818000065, 8.189081094000073 ],
							[ 6.712633242000038, 8.201951013000041 ],
							[ 6.71447143100005, 8.229528425000069 ],
							[ 6.718148618000043, 8.243316572000026 ],
							[ 6.724220377000051, 8.24938859100007 ],
							[ 6.72589677600007, 8.250656645000049 ],
							[ 6.723495682000021, 8.258602430000053 ],
							[ 6.721010135000029, 8.265875451000056 ],
							[ 6.719986854000069, 8.274571459000072 ],
							[ 6.730098618000056, 8.283763845000067 ],
							[ 6.743887294000046, 8.265378976000022 ],
							[ 6.749403019000056, 8.263540216000024 ],
							[ 6.755838070000038, 8.268136114000072 ],
							[ 6.761353148000069, 8.272732820000044 ],
							[ 6.763191770000049, 8.281925271000034 ],
							[ 6.761353038000038, 8.292956335000042 ],
							[ 6.749402887000031, 8.299390836000043 ],
							[ 6.751241307000043, 8.30490579800005 ],
							[ 6.760434184000076, 8.305825372000072 ],
							[ 6.766868595000062, 8.308583282000029 ],
							[ 6.769626628000026, 8.317775631000075 ],
							[ 6.770545369000047, 8.330645642000036 ],
							[ 6.772384208000062, 8.335241334000045 ],
							[ 6.779738087000055, 8.331565006000062 ],
							[ 6.790769176000026, 8.330645537000066 ],
							[ 6.801800136000054, 8.338918584000055 ],
							[ 6.807315304000042, 8.342595105000044 ],
							[ 6.810992652000039, 8.351787505000061 ],
							[ 6.814669618000039, 8.356384295000055 ],
							[ 6.822942874000034, 8.367415319000031 ],
							[ 6.826619601000061, 8.37476920000006 ],
							[ 6.827538988000072, 8.379364818000056 ],
							[ 6.832135203000064, 8.386719843000037 ],
							[ 6.836731508000071, 8.391315592000069 ],
							[ 6.837650704000055, 8.396831478000024 ],
							[ 6.83305450000006, 8.401427409000064 ],
							[ 6.824781385000051, 8.40418528500004 ],
							[ 6.832135109000035, 8.417054047000022 ],
							[ 6.832135315000073, 8.42532738500006 ],
							[ 6.824781428000051, 8.432681982000076 ],
							[ 6.825058411000043, 8.433941292000043 ],
							[ 6.827644088000056, 8.453450603000022 ],
							[ 6.94614352800005, 8.45893882300004 ],
							[ 6.984174740000071, 8.459377342000039 ],
							[ 6.969562482000072, 8.391315348000035 ],
							[ 6.957612375000053, 8.374769221000065 ],
							[ 6.955773803000056, 8.359142219000034 ],
							[ 6.951177491000067, 8.346272326000076 ],
							[ 6.94198508900007, 8.322372388000076 ],
							[ 6.934631299000046, 8.310421833000021 ],
							[ 6.934631423000042, 8.301229566000075 ],
							[ 6.941065752000043, 8.292956362000041 ],
							[ 6.953016409000043, 8.283763785000076 ],
							[ 6.95945058500007, 8.275490830000024 ],
							[ 6.964966397000069, 8.265378832000067 ],
							[ 6.970481979000056, 8.245155304000036 ],
							[ 6.972320680000053, 8.224931780000077 ],
							[ 6.979674381000052, 8.211143388000039 ],
							[ 6.992543805000025, 8.195515538000052 ],
							[ 7.000817436000034, 8.181727489000025 ],
							[ 7.002655441000059, 8.167018791000032 ],
							[ 6.99714001600006, 8.153230563000022 ],
							[ 6.983351226000025, 8.115541574000076 ],
							[ 6.987028634000069, 8.092559925000046 ],
							[ 6.987028639000073, 8.077852212000039 ],
							[ 6.977376581000044, 8.050274989000059 ],
							[ 6.970941589000063, 8.030051162000063 ],
							[ 6.972780299000021, 8.019939562000047 ],
							[ 6.979214581000065, 8.000635118000048 ],
							[ 6.978295218000028, 7.985927653000033 ],
							[ 6.963587718000042, 7.960188432000052 ],
							[ 6.960829776000026, 7.942722745000026 ],
							[ 6.957152936000057, 7.929853495000032 ],
							[ 6.952556542000025, 7.917903359000036 ],
							[ 6.954395357000067, 7.905033671000069 ],
							[ 6.959910449000063, 7.896760550000067 ],
							[ 6.962668485000052, 7.887568139000052 ],
							[ 6.963587837000034, 7.879294880000032 ],
							[ 6.966287171000033, 7.886808856000073 ],
							[ 6.97803095200004, 7.901606666000021 ],
							[ 6.987842507000039, 7.909518098000035 ],
							[ 6.991730527000072, 7.919361638000055 ],
							[ 6.994654750000052, 7.92527079000007 ],
							[ 7.004494163000061, 7.928270937000036 ],
							[ 7.015335315000073, 7.928329629000075 ],
							[ 7.052934449000077, 7.932279774000051 ],
							[ 7.072845509000047, 7.942744684000047 ],
							[ 7.117855112000029, 7.959025014000076 ],
							[ 7.195903178000037, 7.993021407000072 ],
							[ 7.253840822000029, 8.01504695400007 ],
							[ 7.305553927000062, 8.025581284000054 ],
							[ 7.393178358000057, 8.041861449000066 ],
							[ 7.404485174000058, 8.042964915000027 ],
							[ 7.432442342000058, 8.045692366000026 ],
							[ 7.445786741000063, 8.044860924000034 ],
							[ 7.450995657000021, 8.046518377000041 ],
							[ 7.505345099000067, 8.047198221000031 ],
							[ 7.54271052100006, 8.043121359000054 ],
							[ 7.583472535000055, 8.037686194000059 ],
							[ 7.624914034000028, 8.02953420700004 ],
							[ 7.665675917000044, 8.019343461000062 ],
							[ 7.677225828000076, 8.016626254000073 ],
							[ 7.676837731000035, 8.014471178000065 ],
							[ 7.671111268000061, 7.982658014000037 ],
							[ 7.665040941000029, 7.94016767100004 ],
							[ 7.677563957000075, 7.940212934000044 ],
							[ 7.692800577000071, 7.94135218200006 ],
							[ 7.697174054000072, 7.935943639000072 ],
							[ 7.695012539000061, 7.931596635000062 ],
							[ 7.679795935000072, 7.92503318100006 ],
							[ 7.663834543000064, 7.914345187000038 ],
							[ 7.663638098000035, 7.905889040000034 ],
							[ 7.66975270000006, 7.884828566000067 ],
							[ 7.692850809000049, 7.838631106000037 ],
							[ 7.728178297000056, 7.792434238000055 ],
							[ 7.74469063600003, 7.768656650000025 ],
							[ 7.752635511000051, 7.74419884200006 ],
							[ 7.75263548700002, 7.708192341000029 ],
							[ 7.753594530000044, 7.688056807000066 ],
							[ 7.75399436400005, 7.679658888000063 ],
							[ 7.754674007000062, 7.661315835000039 ],
							[ 7.75467386300005, 7.647728333000032 ],
							[ 7.752447911000047, 7.647782667000058 ],
							[ 7.75244800400003, 7.623362090000057 ],
							[ 7.750780530000043, 7.594179083000029 ],
							[ 7.751979436000056, 7.576910647000034 ],
							[ 7.747456141000043, 7.558593894000069 ],
							[ 7.74096114300005, 7.549892895000028 ],
							[ 7.739914636000037, 7.537955869000029 ],
							[ 7.755918139000073, 7.526767326000027 ],
							[ 7.757360984000059, 7.523912341000027 ],
							[ 7.765013163000049, 7.512004729000068 ],
							[ 7.771584074000032, 7.497923774000071 ],
							[ 7.779246116000024, 7.482761255000071 ],
							[ 7.785776313000042, 7.480613345000052 ],
							[ 7.793394432000071, 7.478468619000068 ],
							[ 7.800983436000024, 7.485002731000066 ],
							[ 7.807478709000065, 7.493702712000072 ],
							[ 7.810733532000029, 7.495882831000074 ],
							[ 7.818340744000068, 7.496992166000041 ],
							[ 7.829230753000047, 7.491602860000057 ],
							[ 7.835775211000055, 7.485114705000058 ],
							[ 7.835838245000048, 7.465323497000043 ],
							[ 7.796859710000035, 7.416643366000073 ],
							[ 7.79761204600004, 7.415858313000058 ],
							[ 7.793993636000039, 7.415134370000033 ],
							[ 7.794724558000041, 7.404702877000034 ],
							[ 7.795406941000067, 7.389567555000042 ],
							[ 7.795897510000032, 7.378670166000063 ],
							[ 7.798135410000043, 7.359149899000045 ],
							[ 7.787335237000036, 7.338501991000044 ],
							[ 7.774711988000035, 7.301192623000077 ],
							[ 7.779129301000069, 7.300682899000037 ],
							[ 7.789968679000026, 7.296514139000067 ],
							[ 7.797056204000057, 7.293595301000039 ],
							[ 7.810814020000066, 7.290260317000048 ],
							[ 7.818734745000029, 7.286925277000023 ],
							[ 7.806645176000075, 7.254406820000042 ],
							[ 7.797056336000026, 7.233145323000031 ],
							[ 7.794466659000022, 7.227205358000049 ],
							[ 7.793865041000061, 7.225825279000048 ],
							[ 7.789968921000025, 7.216885998000066 ],
							[ 7.783376349000036, 7.206027538000058 ],
							[ 7.767146684000068, 7.201742118000027 ],
							[ 7.736742758000048, 7.197299220000048 ],
							[ 7.723709619000033, 7.196169024000028 ],
							[ 7.708500112000024, 7.196115794000036 ],
							[ 7.69219294800007, 7.199312699000075 ],
							[ 7.68569393100006, 7.193865201000051 ],
							[ 7.686420105000025, 7.19100873800005 ],
							[ 7.646664972000053, 7.17202592700005 ],
							[ 7.633645167000054, 7.167638189000058 ],
							[ 7.622798658000022, 7.163258133000056 ],
							[ 7.618469633000075, 7.15890237900004 ],
							[ 7.605488405000074, 7.144749739000076 ],
							[ 7.591455207000024, 7.121913881000069 ],
							[ 7.589311748000057, 7.114311466000061 ],
							[ 7.584996842000066, 7.106700961000058 ],
							[ 7.580736243000047, 7.084987016000071 ],
							[ 7.580765697000061, 7.077392941000028 ],
							[ 7.584061640000073, 7.067641604000073 ],
							[ 7.583005401000037, 7.060043625000048 ],
							[ 7.578678053000033, 7.05568736400005 ],
							[ 7.567835538000054, 7.051305681000031 ],
							[ 7.559165001000054, 7.046932217000062 ],
							[ 7.544227638000052, 7.039896156000054 ],
							[ 7.543581844000073, 7.040121059000057 ],
							[ 7.530513945000052, 7.032278044000066 ],
							[ 7.529822860000024, 7.032796357000052 ],
							[ 7.527767907000054, 7.048217271000055 ],
							[ 7.52629343600006, 7.057541969000056 ],
							[ 7.503968246000056, 7.070370898000021 ],
							[ 7.486626559000058, 7.093985161000035 ],
							[ 7.465713815000072, 7.111841750000053 ],
							[ 7.457075840000073, 7.115394197000057 ],
							[ 7.441973497000049, 7.118201322000061 ],
							[ 7.430476184000042, 7.118152301000066 ],
							[ 7.417560327000047, 7.113790365000057 ],
							[ 7.398158564000028, 7.113706141000023 ],
							[ 7.385961808000047, 7.109346229000039 ],
							[ 7.375259845000073, 7.092073206000066 ],
							[ 7.368167348000043, 7.071227239000052 ],
							[ 7.367074517000049, 7.064780221000035 ],
							[ 7.367583360000026, 7.062634336000031 ],
							[ 7.367466228000069, 7.056236603000059 ],
							[ 7.364408946000026, 7.048724265000033 ],
							[ 7.366937040000039, 7.040440243000035 ],
							[ 7.366836927000065, 7.039865897000027 ],
							[ 7.35393661300003, 7.034337551000021 ],
							[ 7.328107732000035, 7.024461366000025 ],
							[ 7.328493969000021, 7.014578904000075 ],
							[ 7.329790467000066, 7.007019393000064 ],
							[ 7.329821153000069, 7.000294778000068 ],
							[ 7.326880336000045, 6.999440704000051 ],
							[ 7.316786649000051, 6.998974328000031 ],
							[ 7.31299395800005, 6.999724358000037 ],
							[ 7.310144048000041, 7.003999623000027 ],
							[ 7.293279590000054, 6.994162165000034 ],
							[ 7.257830672000068, 6.980770001000053 ],
							[ 7.241288089000022, 6.971317371000055 ],
							[ 7.23813685600004, 6.957925343000056 ],
							[ 7.238924545000032, 6.941382532000034 ],
							[ 7.242863699000054, 6.927990316000034 ],
							[ 7.238136782000026, 6.921688465000045 ],
							[ 7.234985702000074, 6.910660406000034 ],
							[ 7.219230589000063, 6.905933265000044 ],
							[ 7.209887973000036, 6.902819248000071 ],
							[ 7.187720739000042, 6.901207001000046 ],
							[ 7.175904363000029, 6.896480480000037 ],
							[ 7.17038999500005, 6.883876289000057 ],
							[ 7.156210442000031, 6.88308862100007 ],
							[ 7.15227186900006, 6.875998954000067 ],
							[ 7.146757469000022, 6.864182507000066 ],
							[ 7.134153244000061, 6.85472960900006 ],
							[ 7.119973523000056, 6.848427399000059 ],
							[ 7.112884155000074, 6.837398937000046 ],
							[ 7.101067468000053, 6.828733369000076 ],
							[ 7.093190075000052, 6.819280590000062 ],
							[ 7.081373808000023, 6.800374538000028 ],
							[ 7.074284041000055, 6.79879898300004 ],
							[ 7.06010432000005, 6.79486048800004 ],
							[ 7.055377838000027, 6.786194716000068 ],
							[ 7.041986031000022, 6.783043832000033 ],
							[ 7.030169960000023, 6.785407030000044 ],
							[ 7.020716734000075, 6.789345839000021 ],
							[ 7.004961558000048, 6.785407135000071 ],
							[ 6.990782392000028, 6.785407089000046 ],
							[ 6.986055448000059, 6.788557899000068 ],
							[ 6.985267601000032, 6.798799074000044 ],
							[ 6.986843127000043, 6.806676449000065 ],
							[ 6.987630793000051, 6.81297863900005 ],
							[ 6.989994577000061, 6.823219291000044 ],
							[ 6.989994629000023, 6.833460263000063 ],
							[ 6.986843021000027, 6.844488716000058 ],
							[ 6.978178017000062, 6.850002735000032 ],
							[ 6.963210550000042, 6.84448868000004 ],
							[ 6.960817397000028, 6.840899050000075 ],
							[ 6.952182421000032, 6.827945781000039 ],
							[ 6.952182323000045, 6.808251936000033 ],
							[ 6.949030830000027, 6.788557971000046 ],
							[ 6.947413687000051, 6.785997491000046 ],
							[ 6.939578137000069, 6.77359092100005 ],
							[ 6.932072656000059, 6.767693572000042 ],
							[ 6.931231952000076, 6.788032918000056 ],
							[ 6.923387972000057, 6.801977957000076 ],
							[ 6.912057266000033, 6.800670509000042 ],
							[ 6.896804837000047, 6.792390711000053 ],
							[ 6.88482102100005, 6.772780437000051 ],
							[ 6.883077673000059, 6.768422577000024 ],
							[ 6.878283991000046, 6.760578111000029 ],
							[ 6.868260994000025, 6.75534870000007 ],
							[ 6.859980975000042, 6.753170054000066 ],
							[ 6.853008387000045, 6.744454018000056 ],
							[ 6.84211338800003, 6.730508781000026 ],
							[ 6.833833697000046, 6.722228887000028 ],
							[ 6.819452883000054, 6.712641765000058 ],
							[ 6.806379261000075, 6.703054547000022 ],
							[ 6.800278270000035, 6.693467270000042 ],
							[ 6.798970612000062, 6.679521902000033 ],
							[ 6.800713994000034, 6.669934741000077 ],
							[ 6.802893090000055, 6.656425402000025 ],
							[ 6.80507183900005, 6.636815088000048 ],
							[ 6.808993724000061, 6.615897213000039 ],
							[ 6.80899378600003, 6.598030072000029 ],
							[ 6.808122609000065, 6.580162482000048 ],
							[ 6.793305883000073, 6.572318566000035 ],
							[ 6.76410807600007, 6.563602927000034 ],
							[ 6.743190251000044, 6.557501917000025 ],
							[ 6.717043026000056, 6.55009354300006 ],
							[ 6.683923278000066, 6.543120783000063 ],
							[ 6.664701088000072, 6.532356273000062 ],
							[ 6.661944801000061, 6.552682781000044 ],
							[ 6.661944921000043, 6.57285365000007 ],
							[ 6.660110903000032, 6.608610556000031 ],
							[ 6.652776338000024, 6.634282099000075 ],
							[ 6.62802167500007, 6.674623595000071 ],
							[ 6.617936031000056, 6.704879296000058 ],
							[ 6.621603490000041, 6.722299189000069 ],
							[ 6.626187918000028, 6.731467652000049 ],
							[ 6.62802176200006, 6.748887443000058 ],
							[ 6.631689042000062, 6.769058144000041 ],
							[ 6.629514163000067, 6.775506996000047 ],
							[ 6.627364134000061, 6.781950922000021 ],
							[ 6.623549128000036, 6.790752933000022 ],
							[ 6.623610081000038, 6.793598074000045 ],
							[ 6.623660950000044, 6.795996113000058 ],
							[ 6.620317864000071, 6.800128086000029 ],
							[ 6.617597020000062, 6.80658096600007 ],
							[ 6.61594403600003, 6.809521205000067 ],
							[ 6.613770048000049, 6.814801230000057 ],
							[ 6.610790139000073, 6.822104903000024 ],
							[ 6.614145333000067, 6.830828570000051 ],
							[ 6.621633657000075, 6.841525476000072 ],
							[ 6.634469919000026, 6.866129508000029 ],
							[ 6.646236778000059, 6.885384586000043 ],
							[ 6.65265560000006, 6.907848225000066 ],
							[ 6.67298033000003, 6.935661313000026 ],
							[ 6.679398608000042, 6.968822600000067 ],
							[ 6.679260241000065, 6.972002420000024 ],
							[ 6.678561991000038, 6.988056699000026 ],
							[ 6.678328556000054, 6.993426206000038 ],
							[ 6.677258923000068, 7.008402325000077 ],
							[ 6.677258902000062, 7.034075751000046 ],
							[ 6.683835609000027, 7.051613754000073 ],
							[ 6.690095502000077, 7.068306991000043 ],
							[ 6.711490244000061, 7.104677201000072 ],
							[ 6.715768871000023, 7.120723195000039 ],
							[ 6.715768789000037, 7.12282134000003 ],
							[ 6.715768731000026, 7.135699217000024 ],
							[ 6.715323562000037, 7.137034737000022 ],
							[ 6.711490074000039, 7.148535751000054 ],
							[ 6.707210948000068, 7.159233101000041 ],
							[ 6.703430038000022, 7.170575532000043 ],
							[ 6.700792678000028, 7.178488122000033 ],
							[ 6.697583608000059, 7.20416153900004 ],
							[ 6.69330491900007, 7.216998109000031 ],
							[ 6.690157355000053, 7.21957291700005 ],
							[ 6.685318590000065, 7.223532116000058 ],
							[ 6.683232788000055, 7.230389227000046 ],
							[ 6.678113869000072, 7.231634968000037 ],
							[ 6.674722232000022, 7.233436952000034 ],
							[ 6.669155147000026, 7.240518017000056 ],
							[ 6.637670056000047, 7.294765064000046 ],
							[ 6.607712365000054, 7.278034073000072 ],
							[ 6.592249475000074, 7.276216285000032 ],
							[ 6.575073617000044, 7.273528343000066 ],
							[ 6.572323420000032, 7.276078490000032 ],
							[ 6.569522033000055, 7.275294724000048 ],
							[ 6.556313034000027, 7.274819872000023 ],
							[ 6.544260121000036, 7.274919110000042 ],
							[ 6.537356967000051, 7.273808949000056 ],
							[ 6.529880971000068, 7.272704119000025 ],
							[ 6.525848017000044, 7.271571219000066 ],
							[ 6.514884929000061, 7.266996825000035 ],
							[ 6.511441099000024, 7.267024911000021 ],
							[ 6.508032885000034, 7.269967995000059 ],
							[ 6.508069067000065, 7.272881880000057 ],
							[ 6.508112027000038, 7.276380176000032 ],
							[ 6.50587327300002, 7.281061107000028 ],
							[ 6.503621157000055, 7.284576870000024 ],
							[ 6.501359975000071, 7.287509878000037 ],
							[ 6.499695675000055, 7.292187329000058 ],
							[ 6.499760009000056, 7.297431825000047 ],
							[ 6.499228842000036, 7.300933855000039 ],
							[ 6.499846082000033, 7.304427024000063 ],
							[ 6.499925095000037, 7.310838086000047 ],
							[ 6.498295650000045, 7.318429122000055 ],
							[ 6.498899090000066, 7.320756004000032 ],
							[ 6.499508751000064, 7.323666182000068 ],
							[ 6.499551643000075, 7.327163341000073 ],
							[ 6.497326843000053, 7.333010220000062 ],
							[ 6.494472015000042, 7.334199023000053 ],
							[ 6.492205094000042, 7.336550124000041 ],
							[ 6.488789048000058, 7.338909145000059 ],
							[ 6.485763157000065, 7.341996211000037 ],
							[ 6.480867859000057, 7.348299952000048 ],
							[ 6.477480841000045, 7.352992038000025 ],
							[ 6.475794880000024, 7.355919801000027 ],
							[ 6.472981883000045, 7.360606163000057 ],
							[ 6.47037206300007, 7.363700762000065 ],
							[ 6.466869880000047, 7.365318657000046 ],
							[ 6.463261144000057, 7.363599681000039 ],
							[ 6.459781164000049, 7.36071398200005 ],
							[ 6.455152911000027, 7.357837214000028 ],
							[ 6.450531986000044, 7.355543135000062 ],
							[ 6.442511099000058, 7.356773892000035 ],
							[ 6.438507008000045, 7.357973196000046 ],
							[ 6.435657907000063, 7.359745113000031 ],
							[ 6.43460420100007, 7.36733197500007 ],
							[ 6.434676269000022, 7.373159759000032 ],
							[ 6.434144072000038, 7.37666166200006 ],
							[ 6.432457920000047, 7.379590927000038 ],
							[ 6.426746833000038, 7.381968070000028 ],
							[ 6.423353292000058, 7.386076082000045 ],
							[ 6.422241230000054, 7.388999996000052 ],
							[ 6.432105923000051, 7.397663970000053 ],
							[ 6.432164055000044, 7.402327147000051 ],
							[ 6.427671912000051, 7.410524010000074 ],
							[ 6.426575102000072, 7.414612802000022 ],
							[ 6.422244922000061, 7.416828051000039 ],
							[ 6.378143891000036, 7.397520968000038 ],
							[ 6.375302746000045, 7.39987598600004 ],
							[ 6.367911868000022, 7.405765917000053 ],
							[ 6.362790121000046, 7.409305148000044 ],
							[ 6.359298799000044, 7.411565518000032 ],
							[ 6.357437035000032, 7.411069481000027 ],
							[ 6.336629023000057, 7.434093105000045 ],
							[ 6.324513164000052, 7.444304599000077 ],
							[ 6.295756942000025, 7.494733051000026 ],
							[ 6.292740607000042, 7.498807890000023 ],
							[ 6.290988948000063, 7.499076974000047 ],
							[ 6.284101099000054, 7.499133182000037 ],
							[ 6.276596107000046, 7.495697143000029 ],
							[ 6.274838057000068, 7.492795901000022 ],
							[ 6.273073304000036, 7.489314182000044 ],
							[ 6.271889101000056, 7.486409277000064 ],
							[ 6.270139253000025, 7.484090749000075 ],
							[ 6.267814173000033, 7.48177922800005 ],
							[ 6.263773883000056, 7.480062996000072 ],
							[ 6.259168230000057, 7.478933677000043 ],
							[ 6.255716821000021, 7.478380310000034 ],
							[ 6.252840094000021, 7.477819934000024 ],
							[ 6.24479006100006, 7.476719719000073 ],
							[ 6.23788679200004, 7.475611308000055 ],
							[ 6.233274069000061, 7.473898914000074 ],
							[ 6.226923948000035, 7.471036838000032 ],
							[ 6.225323300000071, 7.468395656000041 ],
							[ 6.223393945000055, 7.464070671000059 ],
							[ 6.221047757000065, 7.460008968000068 ],
							[ 6.218709093000029, 7.456531115000075 ],
							[ 6.208865078000031, 7.44961581900003 ],
							[ 6.200786054000048, 7.446185141000058 ],
							[ 6.193274092000024, 7.442165792000026 ],
							[ 6.189808883000069, 7.440444950000028 ],
							[ 6.186916868000026, 7.43872028100003 ],
							[ 6.186306949000027, 7.435811007000041 ],
							[ 6.186272191000057, 7.432896233000065 ],
							[ 6.182785168000066, 7.429427153000063 ],
							[ 6.17992201800007, 7.430033330000072 ],
							[ 6.175953770000035, 7.434145970000031 ],
							[ 6.174274958000069, 7.43765677500005 ],
							[ 6.173170111000047, 7.441164086000072 ],
							[ 6.170909823000045, 7.444097052000075 ],
							[ 6.166899327000067, 7.444713210000032 ],
							[ 6.163475899000048, 7.446488757000054 ],
							[ 6.159473004000063, 7.447688146000075 ],
							[ 6.154314119000048, 7.448313373000076 ],
							[ 6.147432845000026, 7.448952278000036 ],
							[ 6.142847944000039, 7.449572210000042 ],
							[ 6.138235120000047, 7.447861168000031 ],
							[ 6.133061846000032, 7.447320069000057 ],
							[ 6.127882098000043, 7.446197037000047 ],
							[ 6.118698252000058, 7.446270868000056 ],
							[ 6.112411976000033, 7.448655029000065 ],
							[ 6.110196015000042, 7.455084867000039 ],
							[ 6.10966398000005, 7.458587164000051 ],
							[ 6.109147927000038, 7.463254093000046 ],
							[ 6.109794271000055, 7.469077994000031 ],
							[ 6.108738841000047, 7.476665051000055 ],
							[ 6.108795981000071, 7.481328007000059 ],
							[ 6.108257753000032, 7.484245886000053 ],
							[ 6.108881976000021, 7.48832186900006 ],
							[ 6.108335888000056, 7.490657857000031 ],
							[ 6.11010217300003, 7.494140966000032 ],
							[ 6.112433055000054, 7.49703592000003 ],
							[ 6.114750784000023, 7.498765991000027 ],
							[ 6.118217050000055, 7.500486971000043 ],
							[ 6.122255763000055, 7.502203090000023 ],
							[ 6.125720956000066, 7.503922859000056 ],
							[ 6.128605900000025, 7.50506499200003 ],
							[ 6.131497024000055, 7.506791236000026 ],
							[ 6.135551093000061, 7.509672108000075 ],
							[ 6.137860844000045, 7.510819013000059 ],
							[ 6.142488843000024, 7.513696060000029 ],
							[ 6.146535998000047, 7.515994929000044 ],
							[ 6.150588871000025, 7.518876179000074 ],
							[ 6.154077003000054, 7.522345 ],
							[ 6.155834249000065, 7.52524511200005 ],
							[ 6.158284262000052, 7.52829782200007 ],
							[ 6.159916754000051, 7.530458028000055 ],
							[ 6.162255901000037, 7.533937083000069 ],
							[ 6.16469545700005, 7.538316060000056 ],
							[ 6.165800183000044, 7.542067857000063 ],
							[ 6.166984003000039, 7.544972838000035 ],
							[ 6.165878762000034, 7.548480091000044 ],
							[ 6.164177779000056, 7.550241822000032 ],
							[ 6.160735191000072, 7.550271165000026 ],
							[ 6.155569189000062, 7.550313131000053 ],
							[ 6.153279798000028, 7.550914789000046 ],
							[ 6.149836079000067, 7.55094291000006 ],
							[ 6.146391785000048, 7.550970975000041 ],
							[ 6.142948063000063, 7.550999291000039 ],
							[ 6.138349193000067, 7.55045315600006 ],
							[ 6.133570740000039, 7.552076860000057 ],
							[ 6.132105766000052, 7.556333995000045 ],
							[ 6.130442024000047, 7.561010893000059 ],
							[ 6.129910893000044, 7.564511802000027 ],
							[ 6.128799048000076, 7.56743625200005 ],
							[ 6.126644027000054, 7.572976230000052 ],
							[ 6.125701498000069, 7.575399839000056 ],
							[ 6.123775886000033, 7.57913482400005 ],
							[ 6.122677716000055, 7.583224677000032 ],
							[ 6.118761111000026, 7.591416899000023 ],
							[ 6.09873101200003, 7.58819722800007 ],
							[ 6.048503768000046, 7.580123926000056 ],
							[ 6.043387821000067, 7.577459910000073 ],
							[ 6.040483033000044, 7.574569219000068 ],
							[ 6.035861932000046, 7.572275017000038 ],
							[ 6.032956114000058, 7.569383978000076 ],
							[ 6.028888161000054, 7.565337104000037 ],
							[ 6.02654893600004, 7.561859159000051 ],
							[ 6.025364754000066, 7.558952882000028 ],
							[ 6.024733893000075, 7.554296014000045 ],
							[ 6.022954133000042, 7.549645942000041 ],
							[ 6.023499149000031, 7.547309776000077 ],
							[ 6.02116836700003, 7.54441511400006 ],
							[ 6.017077992000054, 7.538619049000033 ],
							[ 6.012996292000025, 7.533405687000027 ],
							[ 6.004125679000026, 7.529235776000064 ],
							[ 6.004090166000026, 7.535571972000071 ],
							[ 6.003391750000048, 7.537396811000065 ],
							[ 6.005297627000061, 7.548155045000044 ],
							[ 6.005708395000056, 7.557377989000031 ],
							[ 6.007618216000026, 7.56176077300006 ],
							[ 6.009029822000059, 7.567595313000027 ],
							[ 6.008998051000049, 7.571477074000029 ],
							[ 6.008966281000028, 7.575358556000026 ],
							[ 6.008942450000063, 7.578270029000066 ],
							[ 6.008930536000037, 7.579725478000057 ],
							[ 6.007937682000033, 7.582143861000077 ],
							[ 6.004002797000055, 7.587449344000049 ],
							[ 5.989333995000038, 7.596548979000033 ],
							[ 5.976594238000075, 7.607604915000024 ],
							[ 5.972192039000049, 7.610480295000059 ],
							[ 5.96387788100003, 7.615749236000056 ],
							[ 5.955044732000033, 7.62489596100005 ],
							[ 5.950179820000074, 7.630550625000069 ],
							[ 5.947909477000053, 7.634060291000026 ],
							[ 5.944175163000068, 7.638656694000076 ],
							[ 5.940000477000069, 7.645110143000068 ],
							[ 5.937628320000044, 7.64877712200007 ],
							[ 5.93663985400002, 7.65985203200006 ],
							[ 5.935671260000049, 7.667233576000058 ],
							[ 5.93378651200004, 7.672763384000064 ],
							[ 5.931891012000051, 7.680139416000031 ],
							[ 5.929975161000073, 7.691209381000021 ],
							[ 5.929021703000046, 7.695821224000042 ],
							[ 5.927141652000046, 7.700427572000024 ],
							[ 5.923391840000022, 7.707793131000074 ],
							[ 5.922439093000037, 7.712404682000056 ],
							[ 5.921491280000055, 7.716093091000062 ],
							[ 5.922403337000048, 7.718868273000055 ],
							[ 5.92330989900006, 7.722566568000047 ],
							[ 5.925133252000023, 7.72811689200006 ],
							[ 5.926039584000023, 7.731815454000071 ],
							[ 5.927884173000052, 7.733672149000029 ],
							[ 5.929718063000053, 7.737376196000071 ],
							[ 5.93062446700003, 7.741074467000033 ],
							[ 5.930603921000056, 7.744768102000023 ],
							[ 5.928738980000048, 7.746604504000061 ],
							[ 5.921306619000063, 7.749333002000071 ],
							[ 5.919446852000021, 7.750245944000028 ],
							[ 5.914816031000043, 7.749297205000062 ],
							[ 5.90833099300005, 7.748338161000049 ],
							[ 5.903699952000068, 7.747388705000049 ],
							[ 5.898147237000046, 7.745511639000028 ],
							[ 5.889812831000029, 7.743618580000032 ],
							[ 5.883338034000076, 7.740812283000025 ],
							[ 5.876858297000069, 7.738929860000042 ],
							[ 5.870383401000026, 7.736123887000076 ],
							[ 5.86482989600006, 7.734246306000045 ],
							[ 5.857433342000036, 7.730512186000055 ],
							[ 5.850026124000067, 7.728624463000074 ],
							[ 5.842608408000046, 7.728582826000036 ],
							[ 5.837035077000053, 7.730399026000043 ],
							[ 5.832389478000039, 7.732220030000065 ],
							[ 5.827743, 7.734040690000029 ],
							[ 5.822185106000063, 7.733086458000059 ],
							[ 5.819413547000067, 7.731224593000036 ],
							[ 5.816439270000046, 7.727848906000077 ],
							[ 5.80102458600004, 7.733303508000063 ],
							[ 5.78024203700005, 7.745772819000024 ],
							[ 5.773028439000029, 7.747318798000038 ],
							[ 5.791615415000024, 7.782068704000039 ],
							[ 5.767953791000025, 7.78763630900005 ],
							[ 5.755775061000065, 7.795639437000034 ],
							[ 5.746031814000048, 7.80503477600007 ],
							[ 5.741160387000036, 7.812342213000022 ],
							[ 5.738130657000056, 7.819180466000034 ],
							[ 5.737289373000067, 7.821079247000057 ],
							[ 5.728981390000058, 7.828348640000058 ],
							[ 5.710191180000038, 7.83008800600004 ],
							[ 5.688617295000029, 7.829740515000026 ],
							[ 5.668435055000032, 7.829044389000046 ],
							[ 5.658838725000066, 7.827678318000039 ],
							[ 5.656604234000042, 7.828348618000064 ],
							[ 5.642686001000072, 7.82869615900006 ],
							[ 5.630506983000032, 7.834959867000066 ],
							[ 5.623199498000076, 7.84296312500004 ],
							[ 5.614152495000042, 7.865581069000029 ],
							[ 5.612412348000021, 7.876368137000043 ],
							[ 5.60858481300005, 7.910816756000031 ],
							[ 5.604061648000027, 7.926475082000024 ],
							[ 5.601973655000052, 7.942829500000073 ],
							[ 5.598841648000075, 7.951876509000044 ],
							[ 5.598841579000066, 7.958487877000039 ],
							[ 5.601625312000067, 7.96370732500003 ],
							[ 5.609628759000032, 7.966143526000053 ],
							[ 5.614152440000055, 7.962315955000065 ],
							[ 5.627027078000026, 7.957096216000025 ],
							[ 5.634334623000029, 7.958487890000072 ],
							[ 5.645469766000076, 7.963011655000059 ],
							[ 5.647209100000055, 7.966143736000049 ],
							[ 5.647557282000037, 7.974146818000065 ],
							[ 5.647557265000046, 7.986158421000027 ],
							[ 5.649573347000057, 7.987886369000023 ],
							[ 5.650732866000055, 7.990740735000031 ],
							[ 5.656952476000072, 7.994328925000048 ],
							[ 5.663215595000054, 8.000244125000052 ],
							[ 5.668087576000062, 8.002331861000073 ],
							[ 5.667391421000048, 8.008595557000035 ],
							[ 5.662519778000046, 8.015206209000041 ],
							[ 5.656328105000057, 8.019577020000042 ],
							[ 5.650689236000062, 8.023557704000041 ],
							[ 5.648949257000027, 8.03260507400006 ],
							[ 5.647209042000043, 8.040956444000074 ],
							[ 5.643033628000069, 8.048263519000045 ],
							[ 5.639902025000026, 8.053830964000042 ],
							[ 5.64303340400005, 8.059050683000066 ],
							[ 5.64581731800007, 8.060790170000075 ],
							[ 5.653820413000062, 8.064270042000032 ],
							[ 5.659736125000052, 8.069837645000064 ],
							[ 5.656256662000033, 8.074708913000052 ],
							[ 5.648949022000068, 8.074360948000049 ],
							[ 5.643636244000049, 8.073968050000076 ],
							[ 5.63955401100003, 8.073665711000046 ],
							[ 5.624591446000068, 8.072621338000033 ],
							[ 5.599189785000021, 8.073665659000028 ],
							[ 5.576571877000049, 8.071229965000043 ],
							[ 5.572744493000073, 8.068446020000067 ],
							[ 5.559173445000056, 8.05800625300003 ],
							[ 5.555031838000048, 8.054554859000064 ],
							[ 5.550822190000076, 8.051047388000029 ],
							[ 5.545255155000063, 8.044088498000065 ],
							[ 5.537599559000057, 8.04060836900004 ],
							[ 5.53168447500002, 8.035040760000072 ],
							[ 5.525768787000061, 8.030865578000032 ],
							[ 5.516025512000056, 8.022166210000023 ],
							[ 5.508718415000033, 8.01764298300003 ],
							[ 5.505934631000059, 8.018686365000065 ],
							[ 5.503847044000054, 8.023557571000026 ],
							[ 5.504542828000069, 8.029473357000029 ],
							[ 5.505580807000058, 8.032327603000056 ],
							[ 5.507326560000024, 8.037128421000034 ],
							[ 5.507326620000072, 8.045479869000076 ],
							[ 5.510458433000053, 8.066705651000063 ],
							[ 5.512546143000066, 8.076796607000063 ],
							[ 5.513589915000068, 8.083756369000071 ],
							[ 5.512894273000029, 8.089672203000021 ],
							[ 5.513937818000045, 8.096631124000055 ],
							[ 5.51463362100003, 8.104982344000064 ],
							[ 5.513938034000034, 8.111246010000059 ],
							[ 5.50697851700005, 8.111246106000067 ],
							[ 5.497963823000021, 8.11066446600006 ],
							[ 5.497963933000051, 8.114674434000051 ],
							[ 5.504484566000031, 8.123804203000077 ],
							[ 5.517961624000066, 8.131194135000044 ],
							[ 5.526221313000065, 8.139020049000067 ],
							[ 5.532742430000042, 8.146844943000076 ],
							[ 5.53665490800006, 8.156409259000043 ],
							[ 5.537089952000031, 8.163364318000049 ],
							[ 5.538394026000049, 8.17858030900004 ],
							[ 5.539691364000021, 8.190689088000056 ],
							[ 5.539360952000038, 8.192347204000043 ],
							[ 5.537386699000024, 8.198435538000069 ],
							[ 5.526977134000049, 8.204825784000036 ],
							[ 5.517384625000034, 8.205880079000053 ],
							[ 5.51009908900005, 8.206192777000069 ],
							[ 5.500517481000031, 8.206101753000041 ],
							[ 5.488231069000051, 8.208274914000071 ],
							[ 5.481582452000055, 8.207327216000067 ],
							[ 5.477531350000049, 8.205099092000069 ],
							[ 5.471879501000046, 8.204229429000065 ],
							[ 5.466227858000025, 8.20249079000007 ],
							[ 5.457533390000037, 8.206403844000022 ],
							[ 5.442318001000046, 8.218141651000053 ],
							[ 5.431884212000057, 8.224661802000071 ],
							[ 5.422319892000075, 8.228139774000056 ],
							[ 5.418842215000041, 8.235530751000056 ],
							[ 5.422319790000074, 8.251180572000067 ],
							[ 5.421885375000045, 8.261614782000038 ],
							[ 5.41971154600003, 8.267701121000073 ],
							[ 5.421450636000031, 8.27378763300004 ],
							[ 5.418407548000062, 8.283785874000046 ],
							[ 5.404496089000077, 8.292045640000026 ],
							[ 5.397105626000041, 8.292045683000026 ],
							[ 5.387541433000024, 8.283351038000035 ],
							[ 5.38102062300004, 8.278134289000036 ],
							[ 5.372326040000075, 8.274221534000048 ],
							[ 5.362761468000031, 8.273352590000059 ],
							[ 5.356675277000022, 8.274656340000035 ],
							[ 5.353197669000053, 8.286828944000035 ],
							[ 5.35276277600002, 8.298131977000025 ],
							[ 5.35319749100006, 8.305522956000061 ],
							[ 5.353197459000057, 8.309869734000074 ],
							[ 5.347546215000023, 8.315521315000069 ],
							[ 5.343198732000076, 8.321607637000056 ],
							[ 5.335808175000068, 8.328563619000022 ],
							[ 5.332330174000049, 8.331171859000051 ],
							[ 5.324504717000025, 8.333780171000058 ],
							[ 5.323493048000046, 8.337099028000068 ],
							[ 5.321502720000069, 8.341078636000077 ],
							[ 5.322829601000024, 8.344395708000036 ],
							[ 5.327473274000056, 8.349039204000064 ],
							[ 5.330789498000058, 8.355672828000024 ],
							[ 5.341900835000047, 8.375241171000027 ],
							[ 5.347705320000046, 8.382704684000032 ],
							[ 5.359314031000054, 8.395142518000057 ],
							[ 5.372581045000061, 8.409238919000074 ],
							[ 5.38087280600007, 8.420847064000043 ],
							[ 5.396627396000042, 8.438260043000071 ],
							[ 5.412382034000075, 8.452356384000041 ],
							[ 5.433111605000022, 8.466452523000044 ],
							[ 5.446378769000034, 8.479719060000036 ],
							[ 5.453513709000049, 8.486290862000033 ],
							[ 5.465723473000025, 8.49239529700003 ],
							[ 5.476577225000028, 8.499857941000073 ],
							[ 5.490143647000025, 8.518173198000056 ],
							[ 5.496249155000044, 8.529025933000071 ],
							[ 5.50303223100002, 8.534453521000046 ],
							[ 5.510494360000052, 8.535131547000049 ],
							[ 5.514564105000034, 8.53513139000006 ],
							[ 5.513207559000023, 8.538522623000063 ],
							[ 5.524109972000076, 8.536930025000061 ],
							[ 5.533869258000038, 8.530423259000031 ],
							[ 5.544442199000059, 8.523103589000073 ],
							[ 5.55420198500002, 8.512530466000044 ],
							[ 5.574534477000043, 8.50602424300007 ],
							[ 5.588360336000051, 8.49707802000006 ],
							[ 5.599746743000026, 8.488944855000057 ],
							[ 5.64285170900007, 8.462919137000029 ],
							[ 5.658304203000057, 8.459666116000051 ],
							[ 5.688176712000029, 8.452851312000064 ],
							[ 5.704662429000052, 8.445839890000059 ],
							[ 5.719302158000062, 8.443400433000022 ],
							[ 5.748580931000049, 8.437706824000031 ],
							[ 5.76647321300004, 8.432826864000049 ],
							[ 5.782739482000068, 8.427947069000027 ],
							[ 5.790872621000062, 8.427134487000046 ],
							[ 5.805511817000024, 8.425507648000064 ],
							[ 5.818524814000057, 8.427134620000061 ],
							[ 5.829097694000041, 8.426321069000039 ],
							[ 5.849430195000025, 8.422254642000041 ],
							[ 5.865695883000058, 8.42306799000005 ],
							[ 5.883588696000061, 8.423068173000047 ],
							[ 5.89741476100005, 8.42306799000005 ],
							[ 5.912053926000056, 8.423880539000038 ],
							[ 5.925066856000058, 8.418188144000055 ],
							[ 5.94295973800007, 8.412494594000066 ],
							[ 5.956785815000046, 8.408428063000031 ],
							[ 5.978745017000051, 8.405175235000058 ],
							[ 5.990944249000052, 8.401922254000056 ],
							[ 6.013716737000038, 8.400295348000043 ],
							[ 6.020223250000072, 8.397855872000036 ],
							[ 6.040555979000032, 8.392975858000057 ],
							[ 6.06007487100004, 8.393788398000027 ],
							[ 6.077967640000054, 8.397855702000072 ],
							[ 6.082657812000036, 8.401764024000045 ],
							[ 6.097487065000053, 8.414121627000043 ],
							[ 6.111312818000044, 8.431200870000055 ],
							[ 6.113124791000075, 8.441468164000071 ],
							[ 6.113752814000065, 8.445026332000054 ],
							[ 6.113752943000065, 8.461293193000074 ],
							[ 6.117005825000035, 8.483251465000023 ],
							[ 6.119445891000055, 8.510904308000022 ],
							[ 6.123512340000048, 8.538555951000035 ],
							[ 6.125139105000073, 8.555635371000051 ],
							[ 6.13327216700003, 8.567834782000034 ],
							[ 6.139778575000037, 8.580847862000041 ],
							[ 6.147911624000074, 8.596301187000051 ],
							[ 6.154418046000046, 8.61744690200004 ],
							[ 6.162550915000054, 8.637779110000054 ],
							[ 6.169870305000074, 8.662991419000036 ],
							[ 6.183696654000073, 8.686576949000028 ],
							[ 6.208536010000046, 8.732374926000034 ],
							[ 6.224512548000064, 8.73511381700007 ],
							[ 6.24657492700004, 8.738791546000073 ],
							[ 6.267717300000072, 8.738791289000062 ],
							[ 6.30264913600007, 8.727760228000022 ],
							[ 6.323791507000067, 8.713051883000048 ],
							[ 6.343095898000058, 8.697424873000045 ],
							[ 6.358722742000054, 8.684555122000063 ],
							[ 6.375269421000041, 8.661574262000045 ],
							[ 6.37986575900004, 8.64870452200006 ],
							[ 6.384096723000027, 8.644262261000051 ],
							[ 6.398250517000065, 8.629400198000042 ],
							[ 6.405604398000037, 8.611934556000051 ],
							[ 6.408037315000058, 8.603014911000059 ],
							[ 6.410115761000043, 8.595393092000052 ],
							[ 6.413877499000023, 8.581600119000029 ],
							[ 6.42490850400003, 8.549426081000036 ],
							[ 6.431343655000035, 8.51909074100007 ],
							[ 6.439157094000052, 8.493351941000071 ],
							[ 6.449268907000032, 8.459340227000041 ],
							[ 6.452945710000051, 8.448309082000037 ],
							[ 6.481442582000057, 8.427165857000034 ],
							[ 6.503504251000038, 8.412458504000028 ],
							[ 6.512696821000077, 8.398669299000062 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Kwara',
				admin1Pcod: 'NG024',
				admin1RefN: 'Kwara',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 12.046963,
				Shape_Area: 2.766244
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 3.730682397000066, 10.068287141000042 ],
							[ 3.736506674000054, 10.068287020000071 ],
							[ 3.736506699000074, 10.074838401000022 ],
							[ 3.734322573000043, 10.079935155000044 ],
							[ 3.735050430000058, 10.087943034000034 ],
							[ 3.743058938000047, 10.084304042000042 ],
							[ 3.745970960000022, 10.077023234000023 ],
							[ 3.750339015000066, 10.068286993000072 ],
							[ 3.757619380000051, 10.063190637000048 ],
							[ 3.761987693000037, 10.058822607000025 ],
							[ 3.766355771000065, 10.053726091000044 ],
							[ 3.771451972000023, 10.047174458000029 ],
							[ 3.772180061000029, 10.040621500000043 ],
							[ 3.775092104000066, 10.033341587000052 ],
							[ 3.783828481000057, 10.031157556000039 ],
							[ 3.788196553000034, 10.034069049000038 ],
							[ 3.788196556000059, 10.038437952000038 ],
							[ 3.784556408000071, 10.045718433000047 ],
							[ 3.780188301000067, 10.052269782000053 ],
							[ 3.784556419000069, 10.062462800000048 ],
							[ 3.794020868000075, 10.062462805000052 ],
							[ 3.796932957000024, 10.066102998000076 ],
							[ 3.799117076000073, 10.071198288000062 ],
							[ 3.818045698000049, 10.066830650000043 ],
							[ 3.823319255000058, 10.054305857000031 ],
							[ 3.833034999000063, 10.047018979000029 ],
							[ 3.851039631000049, 10.055203207000034 ],
							[ 3.864133851000076, 10.050293070000066 ],
							[ 3.872317569000074, 10.042109652000022 ],
							[ 3.882138203000068, 10.03556267600004 ],
							[ 3.890322163000064, 10.030651989000035 ],
							[ 3.903416334000042, 10.019194762000041 ],
							[ 3.890322237000021, 10.015920603000041 ],
							[ 3.887048754000034, 10.004463427000076 ],
							[ 3.887048768000056, 9.988095440000052 ],
							[ 3.909963579000021, 9.989732878000041 ],
							[ 3.921420798000042, 9.999552775000041 ],
							[ 3.93451520800005, 9.999552702000074 ],
							[ 3.949246106000032, 9.991369079000037 ],
							[ 3.954156389000048, 9.98318490500003 ],
							[ 3.952519644000063, 9.978275456000063 ],
							[ 3.94106215000005, 9.976637692000054 ],
							[ 3.936151943000027, 9.973364556000035 ],
							[ 3.934515172000033, 9.970091039000067 ],
							[ 3.93451523400006, 9.966817891000062 ],
							[ 3.941062131000024, 9.961907396000072 ],
							[ 3.949246145000075, 9.961907419000056 ],
							[ 3.959066867000047, 9.960269826000058 ],
							[ 3.965613838000024, 9.956997179000041 ],
							[ 3.97052413800003, 9.948813356000073 ],
							[ 3.968887348000067, 9.942265691000046 ],
							[ 3.960703662000071, 9.937355819000061 ],
							[ 3.959066841000038, 9.934081829000036 ],
							[ 3.942698929000073, 9.932445462000032 ],
							[ 3.934515238000074, 9.927535018000071 ],
							[ 3.936152003000075, 9.916077653000059 ],
							[ 3.945972466000057, 9.907892987000025 ],
							[ 3.95415640300007, 9.907892916000037 ],
							[ 3.962340314000073, 9.907893221000052 ],
							[ 3.967250542000045, 9.902983693000067 ],
							[ 3.980017411000063, 9.906583608000062 ],
							[ 3.982636228000047, 9.898727320000035 ],
							[ 3.985255249000033, 9.893489774000045 ],
							[ 3.99442097900004, 9.900036559000057 ],
							[ 4.002277808000031, 9.906583506000061 ],
							[ 4.011443653000072, 9.898727326000028 ],
							[ 4.012752962000036, 9.885633750000068 ],
							[ 4.016681256000027, 9.868611170000065 ],
							[ 4.020609344000036, 9.860754189000033 ],
							[ 4.021918801000027, 9.856825623000077 ],
							[ 4.027156801000046, 9.856826054000067 ],
							[ 4.038941365000028, 9.864683018000051 ],
							[ 4.052035743000033, 9.863374015000034 ],
							[ 4.057273302000056, 9.85682601600007 ],
							[ 4.070367358000055, 9.856825732000061 ],
							[ 4.078224137000063, 9.854207162000023 ],
							[ 4.080843036000033, 9.851588239000023 ],
							[ 4.083461784000065, 9.851588052000068 ],
							[ 4.095246289000045, 9.86730170900006 ],
							[ 4.095246316000043, 9.877777235000053 ],
							[ 4.114887674000045, 9.881705516000068 ],
							[ 4.124053503000027, 9.875158216000045 ],
							[ 4.12274405200003, 9.860754267000061 ],
							[ 4.118815909000034, 9.855516345000069 ],
							[ 4.127982215000031, 9.851588243000037 ],
							[ 4.141076110000029, 9.860753865000049 ],
							[ 4.152861122000047, 9.862063141000021 ],
							[ 4.171192605000044, 9.820162563000054 ],
							[ 4.206547220000061, 9.78218939900006 ],
							[ 4.20991807300004, 9.778295399000058 ],
							[ 4.282493645000045, 9.694457871000054 ],
							[ 4.350583491000066, 9.588395326000068 ],
							[ 4.387247045000038, 9.562206369000023 ],
							[ 4.443552061000048, 9.509829482000043 ],
							[ 4.501166772000033, 9.440430649000064 ],
							[ 4.578422513000021, 9.388054068000031 ],
							[ 4.609726900000055, 9.361758014000031 ],
							[ 4.61705022600006, 9.330439541000032 ],
							[ 4.639310402000035, 9.308178631000033 ],
							[ 4.68514007400006, 9.292466170000068 ],
							[ 4.713947285000074, 9.278062970000065 ],
							[ 4.737516839000023, 9.268896205000033 ],
							[ 4.754539497000053, 9.247945602000073 ],
							[ 4.757158268000069, 9.224376782000036 ],
							[ 4.751920709000046, 9.199497417000032 ],
							[ 4.751920746000053, 9.17985651500004 ],
							[ 4.764554923000048, 9.149932077000074 ],
							[ 4.768706303000044, 9.15352985100003 ],
							[ 4.772460970000054, 9.147629492000021 ],
							[ 4.785332682000046, 9.135293749000027 ],
							[ 4.800886638000065, 9.136366879000036 ],
							[ 4.810540756000023, 9.136367039000049 ],
							[ 4.815904170000067, 9.132612195000036 ],
							[ 4.828239856000039, 9.134221816000036 ],
							[ 4.848084450000044, 9.141194459000076 ],
							[ 4.857202113000028, 9.148702377000063 ],
							[ 4.865247280000062, 9.150311699000042 ],
							[ 4.871683122000036, 9.155138739000051 ],
							[ 4.876071439000043, 9.157646106000072 ],
							[ 4.876584936000029, 9.161239810000041 ],
							[ 4.886081749000027, 9.167345203000025 ],
							[ 4.898970081000073, 9.17005832600006 ],
							[ 4.911180479000052, 9.171414545000061 ],
							[ 4.923390375000054, 9.176162777000059 ],
							[ 4.941705735000028, 9.18430300600005 ],
							[ 4.962055684000063, 9.193121808000058 ],
							[ 4.971552792000068, 9.200583511000048 ],
							[ 4.996651167000039, 9.207366909000029 ],
							[ 5.016323103000047, 9.205331823000051 ],
							[ 5.032603276000032, 9.197870263000027 ],
							[ 5.048204908000059, 9.187694361000069 ],
							[ 5.065163489000042, 9.162596723000036 ],
							[ 5.084835399000042, 9.150385864000043 ],
							[ 5.107899150000037, 9.135462722000057 ],
							[ 5.130962847000035, 9.123252973000035 ],
							[ 5.15945277700007, 9.106972967000047 ],
							[ 5.171663294000041, 9.087300379000055 ],
							[ 5.196761706000075, 9.054062004000059 ],
							[ 5.208971875000032, 9.035068563000038 ],
							[ 5.225591286000054, 9.026927826000076 ],
							[ 5.237801141000034, 9.01878828100007 ],
							[ 5.254759893000028, 9.016753011000048 ],
							[ 5.272396431000061, 9.014718167000069 ],
							[ 5.286641443000065, 9.009969654000031 ],
							[ 5.297495244000061, 9.003186030000052 ],
							[ 5.307670036000047, 9.00047320300007 ],
							[ 5.323272089000056, 8.992332582000074 ],
							[ 5.332090371000049, 8.995724691000021 ],
							[ 5.348370499000055, 8.99843781800007 ],
							[ 5.370077663000075, 8.996402999000054 ],
							[ 5.375504483000043, 8.99029715000006 ],
							[ 5.391106056000069, 8.982835771000055 ],
							[ 5.408064929000034, 8.978766379000035 ],
							[ 5.425022957000067, 8.978766199000063 ],
							[ 5.436554901000022, 8.975374105000071 ],
							[ 5.445373416000052, 8.972660768000026 ],
							[ 5.457583503000023, 8.968590765000044 ],
							[ 5.460975280000071, 8.962486142000046 ],
							[ 5.46640217700002, 8.954345649000061 ],
							[ 5.473863527000049, 8.947562196000035 ],
							[ 5.485395397000048, 8.944848983000043 ],
							[ 5.497605853000039, 8.944171208000057 ],
							[ 5.511850769000034, 8.944171206000021 ],
							[ 5.525417717000039, 8.940100508000057 ],
							[ 5.539562563000061, 8.930567758000052 ],
							[ 5.543629252000073, 8.91674127400006 ],
							[ 5.563148070000068, 8.898848426000029 ],
							[ 5.572907808000025, 8.892342651000035 ],
							[ 5.60137316600003, 8.876889130000052 ],
							[ 5.605439731000047, 8.867942890000052 ],
							[ 5.619265925000036, 8.852490158000023 ],
							[ 5.642038403000072, 8.835411332000035 ],
							[ 5.665624230000049, 8.830530931000055 ],
							[ 5.691649949000066, 8.828091352000058 ],
							[ 5.712795845000073, 8.828905043000077 ],
							[ 5.728248146000055, 8.819958811000049 ],
							[ 5.742074586000058, 8.797999246000074 ],
							[ 5.760780457000067, 8.773600628000054 ],
							[ 5.774606102000064, 8.753267352000023 ],
							[ 5.795751941000049, 8.741881567000064 ],
							[ 5.82421778500003, 8.742694855000025 ],
							[ 5.864883022000072, 8.745134086000064 ],
							[ 5.886841850000053, 8.746761348000064 ],
							[ 5.901481214000057, 8.750014384000053 ],
							[ 5.928320409000037, 8.742694791000076 ],
							[ 5.943772701000057, 8.748388017000025 ],
							[ 5.97467847300004, 8.756521421000059 ],
							[ 5.978744949000031, 8.750014096000029 ],
							[ 5.999890892000053, 8.751641520000021 ],
							[ 6.033236041000066, 8.758960813000044 ],
							[ 6.058448203000069, 8.758960656000056 ],
							[ 6.084474026000066, 8.760587648000069 ],
							[ 6.114566446000026, 8.758147023000049 ],
							[ 6.131645289000062, 8.751641379000034 ],
							[ 6.144658132000075, 8.729681997000057 ],
							[ 6.149537986000041, 8.720735539000032 ],
							[ 6.169057294000027, 8.721548929000051 ],
							[ 6.185323292000021, 8.729681844000027 ],
							[ 6.203216031000068, 8.734561707000069 ],
							[ 6.208536010000046, 8.732374926000034 ],
							[ 6.183696654000073, 8.686576949000028 ],
							[ 6.169870305000074, 8.662991419000036 ],
							[ 6.162550915000054, 8.637779110000054 ],
							[ 6.154418046000046, 8.61744690200004 ],
							[ 6.147911624000074, 8.596301187000051 ],
							[ 6.139778575000037, 8.580847862000041 ],
							[ 6.13327216700003, 8.567834782000034 ],
							[ 6.125139105000073, 8.555635371000051 ],
							[ 6.123512340000048, 8.538555951000035 ],
							[ 6.119445891000055, 8.510904308000022 ],
							[ 6.117005825000035, 8.483251465000023 ],
							[ 6.113752943000065, 8.461293193000074 ],
							[ 6.113752814000065, 8.445026332000054 ],
							[ 6.113124791000075, 8.441468164000071 ],
							[ 6.111312818000044, 8.431200870000055 ],
							[ 6.097487065000053, 8.414121627000043 ],
							[ 6.082657812000036, 8.401764024000045 ],
							[ 6.077967640000054, 8.397855702000072 ],
							[ 6.06007487100004, 8.393788398000027 ],
							[ 6.040555979000032, 8.392975858000057 ],
							[ 6.020223250000072, 8.397855872000036 ],
							[ 6.013716737000038, 8.400295348000043 ],
							[ 5.990944249000052, 8.401922254000056 ],
							[ 5.978745017000051, 8.405175235000058 ],
							[ 5.956785815000046, 8.408428063000031 ],
							[ 5.94295973800007, 8.412494594000066 ],
							[ 5.925066856000058, 8.418188144000055 ],
							[ 5.912053926000056, 8.423880539000038 ],
							[ 5.89741476100005, 8.42306799000005 ],
							[ 5.883588696000061, 8.423068173000047 ],
							[ 5.865695883000058, 8.42306799000005 ],
							[ 5.849430195000025, 8.422254642000041 ],
							[ 5.829097694000041, 8.426321069000039 ],
							[ 5.818524814000057, 8.427134620000061 ],
							[ 5.805511817000024, 8.425507648000064 ],
							[ 5.790872621000062, 8.427134487000046 ],
							[ 5.782739482000068, 8.427947069000027 ],
							[ 5.76647321300004, 8.432826864000049 ],
							[ 5.748580931000049, 8.437706824000031 ],
							[ 5.719302158000062, 8.443400433000022 ],
							[ 5.704662429000052, 8.445839890000059 ],
							[ 5.688176712000029, 8.452851312000064 ],
							[ 5.658304203000057, 8.459666116000051 ],
							[ 5.64285170900007, 8.462919137000029 ],
							[ 5.599746743000026, 8.488944855000057 ],
							[ 5.588360336000051, 8.49707802000006 ],
							[ 5.574534477000043, 8.50602424300007 ],
							[ 5.55420198500002, 8.512530466000044 ],
							[ 5.544442199000059, 8.523103589000073 ],
							[ 5.533869258000038, 8.530423259000031 ],
							[ 5.524109972000076, 8.536930025000061 ],
							[ 5.513207559000023, 8.538522623000063 ],
							[ 5.514564105000034, 8.53513139000006 ],
							[ 5.510494360000052, 8.535131547000049 ],
							[ 5.50303223100002, 8.534453521000046 ],
							[ 5.496249155000044, 8.529025933000071 ],
							[ 5.490143647000025, 8.518173198000056 ],
							[ 5.476577225000028, 8.499857941000073 ],
							[ 5.465723473000025, 8.49239529700003 ],
							[ 5.453513709000049, 8.486290862000033 ],
							[ 5.446378769000034, 8.479719060000036 ],
							[ 5.433111605000022, 8.466452523000044 ],
							[ 5.412382034000075, 8.452356384000041 ],
							[ 5.396627396000042, 8.438260043000071 ],
							[ 5.38087280600007, 8.420847064000043 ],
							[ 5.372581045000061, 8.409238919000074 ],
							[ 5.359314031000054, 8.395142518000057 ],
							[ 5.347705320000046, 8.382704684000032 ],
							[ 5.341900835000047, 8.375241171000027 ],
							[ 5.330789498000058, 8.355672828000024 ],
							[ 5.327473274000056, 8.349039204000064 ],
							[ 5.322829601000024, 8.344395708000036 ],
							[ 5.321502720000069, 8.341078636000077 ],
							[ 5.323493048000046, 8.337099028000068 ],
							[ 5.324504717000025, 8.333780171000058 ],
							[ 5.332330174000049, 8.331171859000051 ],
							[ 5.335808175000068, 8.328563619000022 ],
							[ 5.343198732000076, 8.321607637000056 ],
							[ 5.347546215000023, 8.315521315000069 ],
							[ 5.353197459000057, 8.309869734000074 ],
							[ 5.35319749100006, 8.305522956000061 ],
							[ 5.35276277600002, 8.298131977000025 ],
							[ 5.353197669000053, 8.286828944000035 ],
							[ 5.356675277000022, 8.274656340000035 ],
							[ 5.362761468000031, 8.273352590000059 ],
							[ 5.372326040000075, 8.274221534000048 ],
							[ 5.38102062300004, 8.278134289000036 ],
							[ 5.387541433000024, 8.283351038000035 ],
							[ 5.397105626000041, 8.292045683000026 ],
							[ 5.404496089000077, 8.292045640000026 ],
							[ 5.418407548000062, 8.283785874000046 ],
							[ 5.421450636000031, 8.27378763300004 ],
							[ 5.41971154600003, 8.267701121000073 ],
							[ 5.421885375000045, 8.261614782000038 ],
							[ 5.422319790000074, 8.251180572000067 ],
							[ 5.418842215000041, 8.235530751000056 ],
							[ 5.422319892000075, 8.228139774000056 ],
							[ 5.431884212000057, 8.224661802000071 ],
							[ 5.442318001000046, 8.218141651000053 ],
							[ 5.457533390000037, 8.206403844000022 ],
							[ 5.466227858000025, 8.20249079000007 ],
							[ 5.471879501000046, 8.204229429000065 ],
							[ 5.477531350000049, 8.205099092000069 ],
							[ 5.481582452000055, 8.207327216000067 ],
							[ 5.488231069000051, 8.208274914000071 ],
							[ 5.500517481000031, 8.206101753000041 ],
							[ 5.51009908900005, 8.206192777000069 ],
							[ 5.517384625000034, 8.205880079000053 ],
							[ 5.526977134000049, 8.204825784000036 ],
							[ 5.537386699000024, 8.198435538000069 ],
							[ 5.539360952000038, 8.192347204000043 ],
							[ 5.539691364000021, 8.190689088000056 ],
							[ 5.538394026000049, 8.17858030900004 ],
							[ 5.537089952000031, 8.163364318000049 ],
							[ 5.53665490800006, 8.156409259000043 ],
							[ 5.532742430000042, 8.146844943000076 ],
							[ 5.526221313000065, 8.139020049000067 ],
							[ 5.517961624000066, 8.131194135000044 ],
							[ 5.504484566000031, 8.123804203000077 ],
							[ 5.497963933000051, 8.114674434000051 ],
							[ 5.497963823000021, 8.11066446600006 ],
							[ 5.47503034500005, 8.108154263000074 ],
							[ 5.449063219000038, 8.105895943000064 ],
							[ 5.42121467100003, 8.105520314000046 ],
							[ 5.398257631000035, 8.103638628000056 ],
							[ 5.387344358000064, 8.102132851000022 ],
							[ 5.371162051000056, 8.096487939000042 ],
							[ 5.36401115700005, 8.090466597000045 ],
							[ 5.360624219000044, 8.082940105000034 ],
							[ 5.358742846000041, 8.073907953000059 ],
							[ 5.358742751000023, 8.063370790000022 ],
							[ 5.36024812200003, 8.05358603600007 ],
							[ 5.361377103000052, 8.043424561000052 ],
							[ 5.360624312000027, 8.038532251000049 ],
							[ 5.359209904000068, 8.036107902000026 ],
							[ 5.357989876000033, 8.034016625000049 ],
							[ 5.351968844000055, 8.025736859000062 ],
							[ 5.345947260000059, 8.020091986000068 ],
							[ 5.340302517000055, 8.015575408000075 ],
							[ 5.332399277000036, 8.009554973000036 ],
							[ 5.317722238000044, 8.007673263000072 ],
							[ 5.297776298000031, 8.007673294000028 ],
							[ 5.282862325000053, 8.00847142300006 ],
							[ 5.269551415000024, 8.011059783000064 ],
							[ 5.262400613000068, 8.012941453000053 ],
							[ 5.256755915000042, 8.018210286000055 ],
							[ 5.244451466000044, 8.020535576000043 ],
							[ 5.230788655000026, 8.021973665000075 ],
							[ 5.220251223000048, 8.020844547000024 ],
							[ 5.202939956000023, 8.017081150000024 ],
							[ 5.195789458000036, 8.018210443000044 ],
							[ 5.191649902000051, 8.022350279000023 ],
							[ 5.188263057000029, 8.029499947000033 ],
							[ 5.181865234000043, 8.036273860000051 ],
							[ 5.176972979000027, 8.038908908000053 ],
							[ 5.16492979100002, 8.047187854000072 ],
							[ 5.162611575000028, 8.04950621100005 ],
							[ 5.157026706000067, 8.052456755000037 ],
							[ 5.150629011000035, 8.055843474000028 ],
							[ 5.142349686000045, 8.061864859000025 ],
							[ 5.127672678000067, 8.06299404300006 ],
							[ 5.117887938000024, 8.062618183000041 ],
							[ 5.11412481800005, 8.058101591000025 ],
							[ 5.105851247000032, 8.048555695000061 ],
							[ 5.104812651000032, 8.039907761000052 ],
							[ 5.10486153100004, 8.035328 ],
							[ 5.103740882000068, 8.032644386000072 ],
							[ 5.100696679000066, 8.030703594000045 ],
							[ 5.096099797000022, 8.030654815000048 ],
							[ 5.089995165000062, 8.028299660000073 ],
							[ 5.087330106000024, 8.02674455500005 ],
							[ 5.085538262000057, 8.025727701000051 ],
							[ 5.084393997000063, 8.024232004000055 ],
							[ 5.075738373000036, 8.024984335000056 ],
							[ 5.072351438000055, 8.031005766000021 ],
							[ 5.061813906000054, 8.038155546000041 ],
							[ 5.057632826000031, 8.039464844000065 ],
							[ 5.054930121000041, 8.049190382000063 ],
							[ 5.05343960600004, 8.057763014000045 ],
							[ 5.049627341000075, 8.064624853000055 ],
							[ 5.044290594000074, 8.069961576000026 ],
							[ 5.039715686000022, 8.071868041000073 ],
							[ 5.037047463000022, 8.070724532000042 ],
							[ 5.03247261100006, 8.068055999000023 ],
							[ 5.029041674000041, 8.063862839000024 ],
							[ 5.020655128000044, 8.059287969000025 ],
							[ 5.014936944000056, 8.054713296000045 ],
							[ 5.009981645000039, 8.050139477000073 ],
							[ 5.005406923000066, 8.043276873000025 ],
							[ 5.001976071000058, 8.034128186000032 ],
							[ 4.995876826000028, 8.032221679000031 ],
							[ 4.989295543000026, 8.034039534000044 ],
							[ 4.987490188000038, 8.032221812000046 ],
							[ 4.981009444000051, 8.025741615000072 ],
							[ 4.973385367000049, 8.018117047000032 ],
							[ 4.96957294200007, 8.01735499800003 ],
							[ 4.967106322000063, 8.020046335000075 ],
							[ 4.96919199000007, 8.024216606000039 ],
							[ 4.970716506000031, 8.032603246000065 ],
							[ 4.968429542000024, 8.037559449000071 ],
							[ 4.966904722000038, 8.046708116000048 ],
							[ 4.960805446000052, 8.052426363000052 ],
							[ 4.95673851600003, 8.053548416000069 ],
							[ 4.949749934000067, 8.05547610800005 ],
							[ 4.945175584000026, 8.06081302900003 ],
							[ 4.939457440000069, 8.068056237000064 ],
							[ 4.926877575000049, 8.079872952000073 ],
							[ 4.913153709000028, 8.085591218000047 ],
							[ 4.89540809500005, 8.089356432000045 ],
							[ 4.887231307000036, 8.090929172000074 ],
							[ 4.877701280000053, 8.091309628000033 ],
							[ 4.861309114000051, 8.09474080800004 ],
							[ 4.854066381000052, 8.095502729000032 ],
							[ 4.851778965000051, 8.091690968000023 ],
							[ 4.854828823000048, 8.08597291500007 ],
							[ 4.856734742000071, 8.079111106000028 ],
							[ 4.857497206000062, 8.072630860000061 ],
							[ 4.852541486000064, 8.068056010000021 ],
							[ 4.850381306000031, 8.066615935000073 ],
							[ 4.843392319000031, 8.061956414000065 ],
							[ 4.830050021000034, 8.055476178000049 ],
							[ 4.818613509000045, 8.053569738000022 ],
							[ 4.812895274000027, 8.059287906000066 ],
							[ 4.805271173000051, 8.063862700000072 ],
							[ 4.799491759000034, 8.065018318000057 ],
							[ 4.792956210000057, 8.066541575000031 ],
							[ 4.792447081000034, 8.06666093900003 ],
							[ 4.785638801000061, 8.068437545000052 ],
							[ 4.779158060000043, 8.068437532000075 ],
							[ 4.774718792000044, 8.068306862000043 ],
							[ 4.766197170000055, 8.068055971000035 ],
							[ 4.754760694000026, 8.065006199000038 ],
							[ 4.745611695000036, 8.064244313000074 ],
							[ 4.735319143000027, 8.063481383000067 ],
							[ 4.721214226000029, 8.064244390000056 ],
							[ 4.711684199000047, 8.059669476000067 ],
							[ 4.70558836400005, 8.051510911000037 ],
							[ 4.704440997000063, 8.048233164000067 ],
							[ 4.699608794000028, 8.049096193000025 ],
							[ 4.695673010000064, 8.052426399000069 ],
							[ 4.69224220500007, 8.056238051000037 ],
							[ 4.687286365000034, 8.057001134000075 ],
							[ 4.682712087000027, 8.054713384000024 ],
							[ 4.679662213000029, 8.052044772000045 ],
							[ 4.67508790200003, 8.049758055000041 ],
							[ 4.673562925000056, 8.045946211000057 ],
							[ 4.665557331000059, 8.043276767000066 ],
							[ 4.659458108000024, 8.045564640000066 ],
							[ 4.653358953000065, 8.050519983000072 ],
							[ 4.648403217000066, 8.053569732000028 ],
							[ 4.642684902000042, 8.053188356000021 ],
							[ 4.637347652000074, 8.053569846000073 ],
							[ 4.633916792000036, 8.057001187000026 ],
							[ 4.632392438000068, 8.061194375000071 ],
							[ 4.634137659000032, 8.068176168000036 ],
							[ 4.633916796000051, 8.071486508000021 ],
							[ 4.62896101900003, 8.074536293000051 ],
							[ 4.621718383000029, 8.075679913000045 ],
							[ 4.612950261000037, 8.073011290000068 ],
							[ 4.603038842000046, 8.068055986000047 ],
							[ 4.598083013000064, 8.063862828000026 ],
							[ 4.590458858000034, 8.062337949000039 ],
							[ 4.579022868000038, 8.057762994000029 ],
							[ 4.56899831100003, 8.054756062000024 ],
							[ 4.55726807800005, 8.058665318000067 ],
							[ 4.533486792000076, 8.071575153000026 ],
							[ 4.505629043000056, 8.09195905200005 ],
							[ 4.495437203000051, 8.117099880000069 ],
							[ 4.494964129000039, 8.122775065000042 ],
							[ 4.494078150000064, 8.133406753000031 ],
							[ 4.494078202000026, 8.149713594000048 ],
							[ 4.494078223000031, 8.166700296000045 ],
							[ 4.490001147000044, 8.182328124000037 ],
							[ 4.483886264000034, 8.202712132000045 ],
							[ 4.477091393000023, 8.218340037000075 ],
							[ 4.460104991000037, 8.236005920000025 ],
							[ 4.441759587000035, 8.252991798000039 ],
							[ 4.421376163000048, 8.270464021000066 ],
							[ 4.403814142000044, 8.278723835000051 ],
							[ 4.386852023000074, 8.291888842000048 ],
							[ 4.382558415000062, 8.299359495000033 ],
							[ 4.381735117000062, 8.301432059000035 ],
							[ 4.375618189000022, 8.316829813000027 ],
							[ 4.375293417000023, 8.31818662400002 ],
							[ 4.367635834000055, 8.350172280000038 ],
							[ 4.364490252000053, 8.358498112000063 ],
							[ 4.348138820000031, 8.39119518800004 ],
							[ 4.259768564000069, 8.575006624000025 ],
							[ 4.209143677000043, 8.680658442000038 ],
							[ 4.21342082700005, 8.682601930000033 ],
							[ 4.210346702000038, 8.691824773000064 ],
							[ 4.206113358000039, 8.703819271000043 ],
							[ 4.20112417100006, 8.717955644000028 ],
							[ 4.190364355000042, 8.753309497000032 ],
							[ 4.190404072000035, 8.75385198500004 ],
							[ 4.182902261000038, 8.765195413000072 ],
							[ 4.182627296000021, 8.785983032000047 ],
							[ 4.186058465000031, 8.805596089000062 ],
							[ 4.194982455000059, 8.817972847000021 ],
							[ 4.200089657000035, 8.825055700000064 ],
							[ 4.221106449000047, 8.860906421000038 ],
							[ 4.257996989000048, 8.900871186000074 ],
							[ 4.321018258000038, 8.942373513000064 ],
							[ 4.334852221000062, 8.957744881000053 ],
							[ 4.334852185000045, 8.980800855000041 ],
							[ 4.329013373000066, 8.989558892000048 ],
							[ 4.325629678000041, 8.994634468000072 ],
							[ 4.316407144000038, 8.997709318000034 ],
							[ 4.290276449000032, 8.991561151000042 ],
							[ 4.273368431000051, 8.979264356000044 ],
							[ 4.253385500000036, 8.966966721000063 ],
							[ 4.21046018800007, 8.970032953000043 ],
							[ 4.133491503000073, 8.994634352000048 ],
							[ 4.072007238000026, 9.01461714900006 ],
							[ 4.012060235000035, 9.034599247000074 ],
							[ 3.990540481000039, 9.048433267000064 ],
							[ 3.972095313000068, 9.077638608000029 ],
							[ 3.944427252000025, 9.148346189000051 ],
							[ 3.921370700000068, 9.172939180000071 ],
							[ 3.86296051000005, 9.188310909000052 ],
							[ 3.826070085000026, 9.182162529000038 ],
							[ 3.810251222000034, 9.173462019000056 ],
							[ 3.795327883000027, 9.165253826000026 ],
							[ 3.770734316000073, 9.146808348000036 ],
							[ 3.753826168000046, 9.120677777000026 ],
							[ 3.752289080000025, 9.086861817000056 ],
							[ 3.743066318000047, 9.046897127000022 ],
							[ 3.720009789000073, 9.013080864000074 ],
							[ 3.682179721000068, 8.994165201000044 ],
							[ 3.655451303000063, 8.98080051900007 ],
							[ 3.603774280000039, 8.960129976000076 ],
							[ 3.524797205000027, 8.928539069000067 ],
							[ 3.421287402000075, 8.86341090600007 ],
							[ 3.416131462000067, 8.860165662000043 ],
							[ 3.387994755000022, 8.842461796000066 ],
							[ 3.338807342000052, 8.825553077000052 ],
							[ 3.281934503000059, 8.804033350000054 ],
							[ 3.237358302000075, 8.782514720000052 ],
							[ 3.197393671000043, 8.796348468000076 ],
							[ 3.183559631000037, 8.81171884400004 ],
							[ 3.16357731100004, 8.808645402000025 ],
							[ 3.131298057000038, 8.777902844000039 ],
							[ 3.103630093000049, 8.75023447500007 ],
							[ 3.05136847600005, 8.72871572400004 ],
							[ 3.014477965000026, 8.710269922000066 ],
							[ 2.942233827000052, 8.638026171000035 ],
							[ 2.920714386000043, 8.611895650000065 ],
							[ 2.888435125000058, 8.608820868000066 ],
							[ 2.840784788000065, 8.610358374000043 ],
							[ 2.802357206000067, 8.610358252000026 ],
							[ 2.776226281000049, 8.590375992000077 ],
							[ 2.761227125000062, 8.570056076000071 ],
							[ 2.758129842000073, 8.561151632000076 ],
							[ 2.755489122000029, 8.567092742000057 ],
							[ 2.750392921000071, 8.578742122000051 ],
							[ 2.74930071700004, 8.595486735000065 ],
							[ 2.749300724000022, 8.612231223000038 ],
							[ 2.750028840000027, 8.636984004000055 ],
							[ 2.745660790000045, 8.647904407000055 ],
							[ 2.738380440000071, 8.650816869000039 ],
							[ 2.737652303000061, 8.657368546000043 ],
							[ 2.742748499000072, 8.663921445000028 ],
							[ 2.744932646000052, 8.669745459000069 ],
							[ 2.741292476000069, 8.674113264000027 ],
							[ 2.743476636000025, 8.684306157000037 ],
							[ 2.748572822000028, 8.693041700000038 ],
							[ 2.749300724000022, 8.701778219000062 ],
							[ 2.745660779000048, 8.709786695000048 ],
							[ 2.744204528000068, 8.719979331000047 ],
							[ 2.74711680300004, 8.737451670000041 ],
							[ 2.742020621000052, 8.745460236000042 ],
							[ 2.73110009100003, 8.762205175000076 ],
							[ 2.729644056000041, 8.77385308700002 ],
							[ 2.733284232000074, 8.782589985000072 ],
							[ 2.737652311000033, 8.788413855000044 ],
							[ 2.739836465000053, 8.797878185000059 ],
							[ 2.747116811000069, 8.800790970000037 ],
							[ 2.753669020000075, 8.800790763000066 ],
							[ 2.758037090000073, 8.803702562000069 ],
							[ 2.762405403000059, 8.805886352000073 ],
							[ 2.761677267000039, 8.811710081000058 ],
							[ 2.760221241000067, 8.817535593000059 ],
							[ 2.75949311100004, 8.821174885000062 ],
							[ 2.759493124000073, 8.829183475000036 ],
							[ 2.76022124800005, 8.836464007000075 ],
							[ 2.760949373000074, 8.842288085000064 ],
							[ 2.760949363000066, 8.851752558000044 ],
							[ 2.758037082000044, 8.857576582000036 ],
							[ 2.759493108000072, 8.864856988000042 ],
							[ 2.766773468000054, 8.867040836000058 ],
							[ 2.769685519000063, 8.870680651000043 ],
							[ 2.771869655000046, 8.888153860000045 ],
							[ 2.772597781000059, 8.892521984000041 ],
							[ 2.771869668000022, 8.899802169000054 ],
							[ 2.766773459000035, 8.903442220000045 ],
							[ 2.763133294000056, 8.901258390000066 ],
							[ 2.758765221000033, 8.903442334000033 ],
							[ 2.758765222000022, 8.906354134000026 ],
							[ 2.764589310000076, 8.914362923000056 ],
							[ 2.766773453000042, 8.916546967000045 ],
							[ 2.77041364400003, 8.918730757000048 ],
							[ 2.772597778000033, 8.924555069000064 ],
							[ 2.774781703000031, 8.928923455000074 ],
							[ 2.778421872000024, 8.936203904000024 ],
							[ 2.780606029000069, 8.943483490000062 ],
							[ 2.779150014000038, 8.948579977000065 ],
							[ 2.774053811000044, 8.951492195000071 ],
							[ 2.771869648000063, 8.953676434000045 ],
							[ 2.777693991000035, 8.958044113000028 ],
							[ 2.787158260000069, 8.96314056500006 ],
							[ 2.787886377000063, 8.969693026000073 ],
							[ 2.785702228000048, 8.974788557000068 ],
							[ 2.783518080000022, 8.980612581000059 ],
							[ 2.784246205000045, 8.982797395000034 ],
							[ 2.787158251000051, 8.987892913000053 ],
							[ 2.784246204000056, 8.99007795600005 ],
							[ 2.779150022000067, 8.99226174100005 ],
							[ 2.779150005000076, 8.998085862000039 ],
							[ 2.780606031000048, 9.003182282000068 ],
							[ 2.777693993000071, 9.006822600000021 ],
							[ 2.773325688000057, 9.007550266000067 ],
							[ 2.773325680000028, 9.011190552000073 ],
							[ 2.782790187000046, 9.014830611000036 ],
							[ 2.783518077000053, 9.017742218000024 ],
							[ 2.782062052000072, 9.022110751000071 ],
							[ 2.778421877000028, 9.022110955000073 ],
							[ 2.77478171100006, 9.025022311000043 ],
							[ 2.779877915000043, 9.027934764000065 ],
							[ 2.782790177000038, 9.030847602000051 ],
							[ 2.783518082000057, 9.037399487000073 ],
							[ 2.783518082000057, 9.046135923000065 ],
							[ 2.778421870000045, 9.052687912000067 ],
							[ 2.779877896000073, 9.065064293000034 ],
							[ 2.827927827000053, 9.065792025000064 ],
							[ 2.864329325000028, 9.062880803000041 ],
							[ 2.870881556000029, 9.062880695000047 ],
							[ 2.878161668000075, 9.063608055000032 ],
							[ 2.881073959000048, 9.069432425000059 ],
							[ 2.88617014700003, 9.076712687000054 ],
							[ 2.897818562000054, 9.083992834000071 ],
							[ 2.912379035000072, 9.089089414000057 ],
							[ 2.954604636000056, 9.091273292000039 ],
							[ 2.959700805000068, 9.086905563000073 ],
							[ 2.964797031000046, 9.079624890000048 ],
							[ 2.976445436000063, 9.075985216000049 ],
							[ 2.986637835000067, 9.071617254000046 ],
							[ 2.999742494000031, 9.073072397000033 ],
							[ 3.016487114000029, 9.075984939000023 ],
							[ 3.030319717000054, 9.081081420000032 ],
							[ 3.049976369000035, 9.086905514000023 ],
							[ 3.078369403000067, 9.095641052000076 ],
							[ 3.087833889000024, 9.102921303000073 ],
							[ 3.090745920000074, 9.118210974000021 ],
							[ 3.094386092000036, 9.139323238000031 ],
							[ 3.109674684000026, 9.164075916000058 ],
							[ 3.120594981000067, 9.188829498000075 ],
							[ 3.124484313000039, 9.191745526000034 ],
							[ 3.122050991000037, 9.208485746000065 ],
							[ 3.121323100000041, 9.239063317000046 ],
							[ 3.129331361000027, 9.258719266000071 ],
							[ 3.14607598200007, 9.26818438600003 ],
							[ 3.158452509000028, 9.28565678700005 ],
							[ 3.153356320000057, 9.30458561100005 ],
							[ 3.151172148000057, 9.334434650000048 ],
							[ 3.151172164000059, 9.353362770000047 ],
							[ 3.15335630900006, 9.369379898000034 ],
							[ 3.144619943000066, 9.390492249000033 ],
							[ 3.131515519000061, 9.437087138000038 ],
							[ 3.131515527000033, 9.451646552000057 ],
							[ 3.146075994000057, 9.480040280000026 ],
							[ 3.158452518000047, 9.49387280600007 ],
							[ 3.175197125000068, 9.502609508000035 ],
							[ 3.191213860000062, 9.510617363000051 ],
							[ 3.199222090000035, 9.522993966000058 ],
							[ 3.210870520000071, 9.547019153000065 ],
							[ 3.221790788000021, 9.563035186000036 ],
							[ 3.232711323000046, 9.57468399000004 ],
							[ 3.250184071000035, 9.603076848000057 ],
							[ 3.253824226000063, 9.613269913000067 ],
							[ 3.256736254000032, 9.642390055000021 ],
							[ 3.267656609000028, 9.657679475000066 ],
							[ 3.288769462000062, 9.661318820000076 ],
							[ 3.306970146000026, 9.657679440000038 ],
							[ 3.317162496000037, 9.659863544000075 ],
							[ 3.331723212000043, 9.671511567000039 ],
							[ 3.348467811000035, 9.686072362000061 ],
							[ 3.352108024000074, 9.69844802700004 ],
							[ 3.349195743000053, 9.703544532000024 ],
							[ 3.346600521000028, 9.710811482000054 ],
							[ 3.339003329000036, 9.726113203000068 ],
							[ 3.324442830000066, 9.759602416000064 ],
							[ 3.323714745000075, 9.779987531000074 ],
							[ 3.331723214000021, 9.802557060000026 ],
							[ 3.353564021000068, 9.823669715000051 ],
							[ 3.37831686100003, 9.833133873000065 ],
							[ 3.418358302000058, 9.849878149000062 ],
							[ 3.447479475000023, 9.857886199000063 ],
							[ 3.467864296000073, 9.863711523000063 ],
							[ 3.486064884000029, 9.860799018000023 ],
							[ 3.499169589000076, 9.855702459000042 ],
							[ 3.513002166000035, 9.857158717000061 ],
							[ 3.515914231000068, 9.861526310000045 ],
							[ 3.526834510000072, 9.860798682000052 ],
							[ 3.534842927000057, 9.865166396000063 ],
							[ 3.55158756000003, 9.880456176000052 ],
							[ 3.563963883000042, 9.897200548000058 ],
							[ 3.563236013000051, 9.916856759000041 ],
							[ 3.575612532000036, 9.929961305000063 ],
							[ 3.600365389000046, 9.953985984000042 ],
							[ 3.606917613000064, 9.970003404000067 ],
							[ 3.609829644000058, 9.994027930000073 ],
							[ 3.612013800000057, 10.023149346000025 ],
							[ 3.612013819000026, 10.044989531000056 ],
							[ 3.610557816000039, 10.064646887000038 ],
							[ 3.617110049000075, 10.079934976000061 ],
							[ 3.625846375000037, 10.092312089000075 ],
							[ 3.639678943000035, 10.098135767000031 ],
							[ 3.647687224000038, 10.102503709000075 ],
							[ 3.660063698000044, 10.102504040000042 ],
							[ 3.665159921000054, 10.109783950000065 ],
							[ 3.670984042000043, 10.125801350000074 ],
							[ 3.673896334000062, 10.138905383000065 ],
							[ 3.677536290000035, 10.15419382400006 ],
							[ 3.678992482000069, 10.166570421000074 ],
							[ 3.676445227000045, 10.178597253000021 ],
							[ 3.68481657500007, 10.170210990000044 ],
							[ 3.68845678200006, 10.161474068000075 ],
							[ 3.692824812000026, 10.157834147000074 ],
							[ 3.703017207000073, 10.152737686000023 ],
							[ 3.707385569000053, 10.143273540000052 ],
							[ 3.707385552000062, 10.132352714000035 ],
							[ 3.707385546000069, 10.117064672000026 ],
							[ 3.712481755000056, 10.108327984000027 ],
							[ 3.714665665000041, 10.101776182000037 ],
							[ 3.715393776000042, 10.089399265000054 ],
							[ 3.730682397000066, 10.068287141000042 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Lagos',
				admin1Pcod: 'NG025',
				admin1RefN: 'Lagos',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 4.28777,
				Shape_Area: 0.300166
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 3.323865431000058, 6.673875677000069 ],
							[ 3.328786269000034, 6.673373946000027 ],
							[ 3.334996210000043, 6.673614469000029 ],
							[ 3.34279747000005, 6.673735040000054 ],
							[ 3.344835586000045, 6.672755034000033 ],
							[ 3.347636259000069, 6.669185373000062 ],
							[ 3.352653629000031, 6.658717711000065 ],
							[ 3.352861799000038, 6.658283432000076 ],
							[ 3.356962429000021, 6.65473374100003 ],
							[ 3.359876135000036, 6.653189206000036 ],
							[ 3.365576726000029, 6.648941710000031 ],
							[ 3.369425862000071, 6.642931548000035 ],
							[ 3.370129142000053, 6.64118366200006 ],
							[ 3.380329197000037, 6.640830495000046 ],
							[ 3.383424337000065, 6.639900981000039 ],
							[ 3.386295257000029, 6.638892825000028 ],
							[ 3.387346415000025, 6.63861220800004 ],
							[ 3.434690753000041, 6.639135703000022 ],
							[ 3.448281781000048, 6.640582923000068 ],
							[ 3.457663547000038, 6.639433479000047 ],
							[ 3.456408977000024, 6.641914750000069 ],
							[ 3.454292075000069, 6.644476771000029 ],
							[ 3.452574966000043, 6.652251362000072 ],
							[ 3.449467450000043, 6.65784461100003 ],
							[ 3.444489234000059, 6.664900228000022 ],
							[ 3.442171304000055, 6.667244916000072 ],
							[ 3.442200700000058, 6.670141268000066 ],
							[ 3.442594701000075, 6.67162191500006 ],
							[ 3.44583142700003, 6.673411586000043 ],
							[ 3.446922398000027, 6.673210522000034 ],
							[ 3.449935015000051, 6.675432281000042 ],
							[ 3.451019398000028, 6.675666315000058 ],
							[ 3.453413326000032, 6.671054222000066 ],
							[ 3.455652791000034, 6.668368284000053 ],
							[ 3.464591333000044, 6.668950854000059 ],
							[ 3.464262462000022, 6.671816912000054 ],
							[ 3.465392810000026, 6.67374131300005 ],
							[ 3.46796630700004, 6.679330922000076 ],
							[ 3.463662588000034, 6.683956086000023 ],
							[ 3.459111612000072, 6.687321545000032 ],
							[ 3.533058968000034, 6.689738283000054 ],
							[ 3.585492627000065, 6.682901078000043 ],
							[ 3.586022627000034, 6.682468057000051 ],
							[ 3.596421501000066, 6.682339550000052 ],
							[ 3.699962121000056, 6.682374855000035 ],
							[ 3.707865025000046, 6.68303398300003 ],
							[ 3.815373653000051, 6.682108532000029 ],
							[ 3.916836717000024, 6.680703983000058 ],
							[ 3.919335189000037, 6.68066120900005 ],
							[ 3.947232830000075, 6.680181193000067 ],
							[ 3.979125550000049, 6.682415390000074 ],
							[ 4.019699532000061, 6.679941469000028 ],
							[ 4.050180392000073, 6.680025123000064 ],
							[ 4.073162055000068, 6.679252569000028 ],
							[ 4.078527024000039, 6.679176339000037 ],
							[ 4.091480698000055, 6.67899264700003 ],
							[ 4.091296251000074, 6.676961370000072 ],
							[ 4.092404302000034, 6.675114726000061 ],
							[ 4.092773395000052, 6.672528763000059 ],
							[ 4.094251211000028, 6.670681975000036 ],
							[ 4.091850294000039, 6.667911961000073 ],
							[ 4.09037253200006, 6.666065132000028 ],
							[ 4.091480725000054, 6.662371669000038 ],
							[ 4.091850295000029, 6.660339906000047 ],
							[ 4.093327558000055, 6.65867763500006 ],
							[ 4.092588920000026, 6.656461745000058 ],
							[ 4.095231067000043, 6.651519377000056 ],
							[ 4.091480764000039, 6.652583154000069 ],
							[ 4.09055760800004, 6.650736403000053 ],
							[ 4.089633982000066, 6.648335469000074 ],
							[ 4.087972182000044, 6.644827005000025 ],
							[ 4.085386228000061, 6.642795159000059 ],
							[ 4.084463184000072, 6.639840023000033 ],
							[ 4.081508188000043, 6.637624303000052 ],
							[ 4.078922810000051, 6.637439243000074 ],
							[ 4.076706399000045, 6.637070047000066 ],
							[ 4.074559236000027, 6.635638607000033 ],
							[ 4.070426931000043, 6.633191641000053 ],
							[ 4.068395594000037, 6.633191704000069 ],
							[ 4.066693271000076, 6.634893777000059 ],
							[ 4.064332561000072, 6.636146418000067 ],
							[ 4.061562482000056, 6.636700554000072 ],
							[ 4.058792162000032, 6.635962053000071 ],
							[ 4.05786889500007, 6.633930118000023 ],
							[ 4.05860761200006, 6.629867478000051 ],
							[ 4.05768396600007, 6.627466735000041 ],
							[ 4.056575741000074, 6.624696121000056 ],
							[ 4.053805861000058, 6.621002682000039 ],
							[ 4.050481298000022, 6.619155888000023 ],
							[ 4.051404970000021, 6.616016498000022 ],
							[ 4.053805902000022, 6.614354157000037 ],
							[ 4.056531843000073, 6.611081664000039 ],
							[ 4.056022636000023, 6.608876141000053 ],
							[ 4.055933435000043, 6.605656074000024 ],
							[ 4.055875357000048, 6.603553282000064 ],
							[ 4.057574356000032, 6.599493453000036 ],
							[ 4.057499415000052, 6.596439745000055 ],
							[ 4.059261802000037, 6.594275896000056 ],
							[ 4.061119977000033, 6.588450784000031 ],
							[ 4.062079381000046, 6.586159078000037 ],
							[ 4.063204774000042, 6.582680706000076 ],
							[ 4.06488654900005, 6.580557285000054 ],
							[ 4.066097661000072, 6.575692745000026 ],
							[ 4.066284135000046, 6.571547484000064 ],
							[ 4.067058558000042, 6.569550010000057 ],
							[ 4.06544064600007, 6.566706134000071 ],
							[ 4.064938036000058, 6.562396160000048 ],
							[ 4.068231056000059, 6.563551991000054 ],
							[ 4.071724860000074, 6.562435557000072 ],
							[ 4.074369379000075, 6.562299600000074 ],
							[ 4.076335390000054, 6.561621789000071 ],
							[ 4.080267867000032, 6.560401511000066 ],
							[ 4.08293294300006, 6.559184806000076 ],
							[ 4.086427775000061, 6.55941824000007 ],
							[ 4.087188729000047, 6.560910701000068 ],
							[ 4.088607769000021, 6.56263864400006 ],
							[ 4.090641964000042, 6.561825173000045 ],
							[ 4.091167493000057, 6.558509378000053 ],
							[ 4.089619192000043, 6.555962133000037 ],
							[ 4.091971917000023, 6.555282646000023 ],
							[ 4.095366999000021, 6.550732997000068 ],
							[ 4.093583171000034, 6.549289300000055 ],
							[ 4.094421289000024, 6.546886518000065 ],
							[ 4.10168021800007, 6.547468365000043 ],
							[ 4.109479467000028, 6.548430308000036 ],
							[ 4.109479391000036, 6.552256484000054 ],
							[ 4.11059619100007, 6.555606404000059 ],
							[ 4.110596169000075, 6.558286054000064 ],
							[ 4.116402626000024, 6.563422710000054 ],
							[ 4.11997556700004, 6.564985652000075 ],
							[ 4.122209023000039, 6.566325564000067 ],
							[ 4.124665239000024, 6.568559199000049 ],
							[ 4.128857337000056, 6.570985747000066 ],
							[ 4.130525459000069, 6.57207898300004 ],
							[ 4.131805721000035, 6.573222749000024 ],
							[ 4.133595717000048, 6.574148053000044 ],
							[ 4.139138439000021, 6.576644137000073 ],
							[ 4.141882782000039, 6.577807297000049 ],
							[ 4.144799029000069, 6.578747467000028 ],
							[ 4.150955124000063, 6.581296173000055 ],
							[ 4.155615744000045, 6.586752462000049 ],
							[ 4.159720937000031, 6.590592774000072 ],
							[ 4.162237171000072, 6.592182036000054 ],
							[ 4.166342255000075, 6.593373705000033 ],
							[ 4.169150354000067, 6.592352989000062 ],
							[ 4.171562692000066, 6.591560728000047 ],
							[ 4.174912405000043, 6.589774741000042 ],
							[ 4.178262167000071, 6.588657932000046 ],
							[ 4.179602185000022, 6.586424926000063 ],
							[ 4.186748515000033, 6.58486117800004 ],
							[ 4.194118048000064, 6.585531365000065 ],
							[ 4.198807673000033, 6.585754805000022 ],
							[ 4.199585025000033, 6.588473749000059 ],
							[ 4.201487467000049, 6.586871162000023 ],
							[ 4.204390985000032, 6.585531244000038 ],
							[ 4.207517196000026, 6.583968116000051 ],
							[ 4.211760601000037, 6.581735105000064 ],
							[ 4.212653667000041, 6.578608479000025 ],
							[ 4.215333421000025, 6.577714878000052 ],
							[ 4.217413405000059, 6.572402830000044 ],
							[ 4.218013327000051, 6.569675371000073 ],
							[ 4.220036558000061, 6.56751670400007 ],
							[ 4.221983437000063, 6.565439835000063 ],
							[ 4.223108233000062, 6.561960685000031 ],
							[ 4.225383271000055, 6.561189173000059 ],
							[ 4.228733101000046, 6.557616090000067 ],
							[ 4.226499601000057, 6.553596635000076 ],
							[ 4.227616367000053, 6.551586627000063 ],
							[ 4.224712860000068, 6.543100378000076 ],
							[ 4.222256721000065, 6.537740708000058 ],
							[ 4.214440333000027, 6.531934308000075 ],
							[ 4.211143909000043, 6.528088491000062 ],
							[ 4.206097071000045, 6.525256054000067 ],
							[ 4.203321027000072, 6.524242731000072 ],
							[ 4.201116097000067, 6.523320253000065 ],
							[ 4.198338942000021, 6.522307730000023 ],
							[ 4.194009760000029, 6.519895155000029 ],
							[ 4.191804906000073, 6.518972048000023 ],
							[ 4.185030038000036, 6.517334076000054 ],
							[ 4.181109885000069, 6.516143886000066 ],
							[ 4.17833427000005, 6.51513095200005 ],
							[ 4.176371138000036, 6.512509937000061 ],
							[ 4.174899126000071, 6.510542849000046 ],
							[ 4.173997915000029, 6.508666917000028 ],
							[ 4.167706033000059, 6.503632991000075 ],
							[ 4.165502056000037, 6.502708900000073 ],
							[ 4.160584477000043, 6.50064280600003 ],
							[ 4.156153691000043, 6.49878459100006 ],
							[ 4.155010269000059, 6.496640615000047 ],
							[ 4.157010980000052, 6.492209851000041 ],
							[ 4.158382893000066, 6.489767925000024 ],
							[ 4.159441002000051, 6.487207961000024 ],
							[ 4.160012800000061, 6.481776816000036 ],
							[ 4.160954526000069, 6.476819114000023 ],
							[ 4.161073195000029, 6.474720079000065 ],
							[ 4.164872162000052, 6.474630386000058 ],
							[ 4.168302066000024, 6.474201743000037 ],
							[ 4.172018566000077, 6.473629885000037 ],
							[ 4.174734128000068, 6.47205795800005 ],
							[ 4.177449655000032, 6.470056441000054 ],
							[ 4.182880323000063, 6.468484319000027 ],
							[ 4.186025192000045, 6.467055307000066 ],
							[ 4.189598101000058, 6.46648364400005 ],
							[ 4.192170607000037, 6.465483111000026 ],
							[ 4.193742806000046, 6.463196295000046 ],
							[ 4.200460493000037, 6.463196252000046 ],
							[ 4.203461599000036, 6.461909892000051 ],
							[ 4.205748566000068, 6.463053314000035 ],
							[ 4.207791753000038, 6.464179123000065 ],
							[ 4.213389431000053, 6.464474753000047 ],
							[ 4.215181864000044, 6.463339233000056 ],
							[ 4.217325681000034, 6.463053265000042 ],
							[ 4.21875471200002, 6.459766041000023 ],
							[ 4.219969701000025, 6.455478139000036 ],
							[ 4.221828004000031, 6.450475729000061 ],
							[ 4.223792609000043, 6.448614995000071 ],
							[ 4.22582961400002, 6.445616283000049 ],
							[ 4.227973429000031, 6.444758834000027 ],
							[ 4.230688985000029, 6.44547361900004 ],
							[ 4.233404685000039, 6.445473654000068 ],
							[ 4.235691493000047, 6.444758762000049 ],
							[ 4.237406736000025, 6.441900222000072 ],
							[ 4.236883159000058, 6.438172931000054 ],
							[ 4.243409575000044, 6.436183494000034 ],
							[ 4.245267316000024, 6.434468201000072 ],
							[ 4.249984272000063, 6.433753403000026 ],
							[ 4.253271547000054, 6.434325089000026 ],
							[ 4.253963956000064, 6.436204899000074 ],
							[ 4.253342638000049, 6.439041530000054 ],
							[ 4.254343550000044, 6.441328609000038 ],
							[ 4.259145677000049, 6.442461051000066 ],
							[ 4.263808781000023, 6.443560554000044 ],
							[ 4.265706062000049, 6.442900627000029 ],
							[ 4.268421627000066, 6.442471979000061 ],
							[ 4.272423218000029, 6.442757967000034 ],
							[ 4.275138789000039, 6.441900275000023 ],
							[ 4.276282372000026, 6.439613246000022 ],
							[ 4.278997934000074, 6.437898665000034 ],
							[ 4.281284852000056, 6.438755897000021 ],
							[ 4.284000380000066, 6.436755303000041 ],
							[ 4.287573395000038, 6.436040265000031 ],
							[ 4.291146817000026, 6.436612182000033 ],
							[ 4.295577053000045, 6.434611238000059 ],
							[ 4.29872178200003, 6.43518292300007 ],
							[ 4.301939901000026, 6.438798896000037 ],
							[ 4.303938362000054, 6.439470836000055 ],
							[ 4.309226462000026, 6.439470816000039 ],
							[ 4.316617421000046, 6.438040255000033 ],
							[ 4.324056663000022, 6.437792262000073 ],
							[ 4.332735513000046, 6.43952798600003 ],
							[ 4.337199247000058, 6.439528069000062 ],
							[ 4.343398618000037, 6.435808250000036 ],
							[ 4.344638305000046, 6.433824603000062 ],
							[ 4.34761424200002, 6.431345019000048 ],
							[ 4.350093832000027, 6.429857240000047 ],
							[ 4.35083774900005, 6.427625099000068 ],
							[ 4.350837707000039, 6.421683377000022 ],
							[ 4.350837702000035, 6.415349109000033 ],
							[ 4.350837698000021, 6.389730584000063 ],
							[ 4.350837695000052, 6.377373772000055 ],
							[ 4.350837715000068, 6.371037511000054 ],
							[ 4.346622405000062, 6.370839257000057 ],
							[ 4.329606604000048, 6.372818903000052 ],
							[ 4.301863198000035, 6.37604667200003 ],
							[ 4.277065705000041, 6.378278580000028 ],
							[ 4.248796488000039, 6.38125420800003 ],
							[ 4.241102759000057, 6.382299927000076 ],
							[ 4.197714353000038, 6.388197508000076 ],
							[ 4.150846923000074, 6.397124266000048 ],
							[ 4.106707542000038, 6.405307770000036 ],
							[ 4.076207177000072, 6.411755031000041 ],
							[ 4.051409757000044, 6.416218151000066 ],
							[ 4.039031083000054, 6.41793782700006 ],
							[ 4.035739482000054, 6.417937854000058 ],
							[ 4.022650673000044, 6.42048273000006 ],
							[ 4.003381199000046, 6.424118473000021 ],
							[ 3.997103413000048, 6.425187169000026 ],
							[ 3.98629352100005, 6.427027112000076 ],
							[ 3.963388701000042, 6.431026373000066 ],
							[ 3.959666470000059, 6.431384080000043 ],
							[ 3.944482847000074, 6.432844038000042 ],
							[ 3.917578696000021, 6.434661965000032 ],
							[ 3.90667154800002, 6.436843298000042 ],
							[ 3.890310810000074, 6.437207345000047 ],
							[ 3.871768704000033, 6.43684348000005 ],
							[ 3.855044367000062, 6.43757051800003 ],
							[ 3.83541155100005, 6.437934430000041 ],
							[ 3.821232335000047, 6.43793448200006 ],
							[ 3.810325157000023, 6.437934308000024 ],
							[ 3.799054357000045, 6.437570576000041 ],
							[ 3.784147991000054, 6.435388992000071 ],
							[ 3.765969535000067, 6.433571211000071 ],
							[ 3.760515892000058, 6.431389736000028 ],
							[ 3.748518021000052, 6.429935315000023 ],
							[ 3.734702369000047, 6.427754352000022 ],
							[ 3.725612872000056, 6.427390627000023 ],
							[ 3.699799538000036, 6.425936208000053 ],
							[ 3.679075994000073, 6.423027905000026 ],
							[ 3.661624436000068, 6.42339131600005 ],
							[ 3.641991606000033, 6.422664157000042 ],
							[ 3.630803101000026, 6.422573109000041 ],
							[ 3.617995979000057, 6.422300808000045 ],
							[ 3.57473086400006, 6.421209926000074 ],
							[ 3.539828080000063, 6.421573533000071 ],
							[ 3.508924499000045, 6.419392113000072 ],
							[ 3.487837312000067, 6.42011941100003 ],
							[ 3.467477301000031, 6.420119155000066 ],
							[ 3.44675374600007, 6.419392162000065 ],
							[ 3.41912250300004, 6.418664994000039 ],
							[ 3.412941698000054, 6.417210497000042 ],
							[ 3.405291082000076, 6.412123311000073 ],
							[ 3.404863115000069, 6.409325124000077 ],
							[ 3.404695042000071, 6.406404852000037 ],
							[ 3.403794055000049, 6.404528091000032 ],
							[ 3.402346359000035, 6.400687280000056 ],
							[ 3.405003998000041, 6.396037109000076 ],
							[ 3.405407890000049, 6.393206128000031 ],
							[ 3.406374478000032, 6.390175753000051 ],
							[ 3.399489668000058, 6.390306448000047 ],
							[ 3.395853725000052, 6.391397060000031 ],
							[ 3.382707381000046, 6.392435072000069 ],
							[ 3.37004020400002, 6.394668945000035 ],
							[ 3.338409694000063, 6.398304931000041 ],
							[ 3.312959711000076, 6.400122682000074 ],
							[ 3.266422487000057, 6.400849842000071 ],
							[ 3.235518941000066, 6.400849675000075 ],
							[ 3.20497897000007, 6.400849841000024 ],
							[ 3.202145901000051, 6.400765412000055 ],
							[ 3.200511208000023, 6.400716899000031 ],
							[ 3.180619705000026, 6.400122517000057 ],
							[ 3.164986132000024, 6.399759432000053 ],
							[ 3.150806924000051, 6.398668319000024 ],
							[ 3.129356165000047, 6.398304807000045 ],
							[ 3.113722547000066, 6.398668408000049 ],
							[ 3.095180492000054, 6.397214397000027 ],
							[ 3.076902608000069, 6.396850660000041 ],
							[ 3.072730771000067, 6.399371629000029 ],
							[ 3.068990925000037, 6.396607520000032 ],
							[ 3.062458991000028, 6.396123476000071 ],
							[ 3.047050778000028, 6.395563157000026 ],
							[ 3.04246259100006, 6.395396079000022 ],
							[ 3.023193371000048, 6.394669018000059 ],
							[ 3.005378255000039, 6.39539622500007 ],
							[ 2.994107480000025, 6.395760008000025 ],
							[ 2.970111839000026, 6.396123448000026 ],
							[ 2.951206208000031, 6.395396326000025 ],
							[ 2.923211097000035, 6.393578558000058 ],
							[ 2.90103339500007, 6.39357848700007 ],
							[ 2.878128298000036, 6.392488125000057 ],
							[ 2.850497010000026, 6.391760934000047 ],
							[ 2.835954187000027, 6.388852027000041 ],
							[ 2.812321911000026, 6.387397673000066 ],
							[ 2.789417031000028, 6.383398656000054 ],
							[ 2.771601919000034, 6.382307972000035 ],
							[ 2.755241386000023, 6.380126577000055 ],
							[ 2.735244752000028, 6.37612712400005 ],
							[ 2.713794236000069, 6.373582003000024 ],
							[ 2.703980441000056, 6.372600470000066 ],
							[ 2.704056981000065, 6.375636031000056 ],
							[ 2.703491918000054, 6.379598281000028 ],
							[ 2.703578954000022, 6.383083938000027 ],
							[ 2.703747028000066, 6.386004051000043 ],
							[ 2.703835007000066, 6.389490020000039 ],
							[ 2.703351023000039, 6.392886151000027 ],
							[ 2.702946901000075, 6.395717015000059 ],
							[ 2.703196046000073, 6.398069881000026 ],
							[ 2.70279193500005, 6.400900745000058 ],
							[ 2.703041076000034, 6.403255036000076 ],
							[ 2.703208930000073, 6.406174290000024 ],
							[ 2.702805994000073, 6.409005173000025 ],
							[ 2.702159893000044, 6.413534090000041 ],
							[ 2.702409027000044, 6.41588695400003 ],
							[ 2.70265697800005, 6.418240946000026 ],
							[ 2.702905891000057, 6.420594101000063 ],
							[ 2.702831985000046, 6.425211987000068 ],
							[ 2.702508936000072, 6.427476732000059 ],
							[ 2.702677017000042, 6.430397137000057 ],
							[ 2.702274084000067, 6.433227146000036 ],
							[ 2.702441931000067, 6.43614726900006 ],
							[ 2.702691086000073, 6.438499853000053 ],
							[ 2.702857972000061, 6.44142021600004 ],
							[ 2.702536111000029, 6.443684981000047 ],
							[ 2.702542063000067, 6.447737318000065 ],
							[ 2.702610966000066, 6.452376882000067 ],
							[ 2.710158354000043, 6.456112888000064 ],
							[ 2.713794237000059, 6.459021061000044 ],
							[ 2.719611412000063, 6.459384884000031 ],
							[ 2.722883458000069, 6.458657864000031 ],
							[ 2.732699870000033, 6.457930419000036 ],
							[ 2.735445981000055, 6.457930508000061 ],
							[ 2.73960708900006, 6.454444354000032 ],
							[ 2.744410998000035, 6.449075298000025 ],
							[ 2.747943410000062, 6.44935805700004 ],
							[ 2.75260591600005, 6.451194716000032 ],
							[ 2.758681534000061, 6.452042509000023 ],
							[ 2.763626806000048, 6.451759949000063 ],
							[ 2.768713243000036, 6.451335868000058 ],
							[ 2.773799895000025, 6.452466422000043 ],
							[ 2.775919200000033, 6.452466567000045 ],
							[ 2.779169079000042, 6.45204248500005 ],
							[ 2.784114127000066, 6.452748821000057 ],
							[ 2.786374806000026, 6.455009447000066 ],
							[ 2.789907216000074, 6.457411175000061 ],
							[ 2.79414605900007, 6.459389620000024 ],
							[ 2.799232483000026, 6.460095886000033 ],
							[ 2.806297070000028, 6.458824703000062 ],
							[ 2.813079127000037, 6.45599844700007 ],
							[ 2.818306933000031, 6.454585503000033 ],
							[ 2.823676100000057, 6.451477146000059 ],
							[ 2.828480006000063, 6.446814409000069 ],
							[ 2.831588512000053, 6.444836745000032 ],
							[ 2.833001370000034, 6.443141082000068 ],
							[ 2.842185499000038, 6.443988688000047 ],
							[ 2.852550747000066, 6.449971292000043 ],
							[ 2.853846083000064, 6.451702045000047 ],
							[ 2.854747054000029, 6.453577940000059 ],
							[ 2.855895994000036, 6.45780910700006 ],
							[ 2.857807631000071, 6.460039958000038 ],
							[ 2.859586250000064, 6.467747147000068 ],
							[ 2.866494178000039, 6.47138269200002 ],
							[ 2.868675471000074, 6.471746511000049 ],
							[ 2.873765468000045, 6.470655467000029 ],
							[ 2.879946236000023, 6.469928225000046 ],
							[ 2.885399822000068, 6.473564244000045 ],
							[ 2.889399057000048, 6.47610902100007 ],
							[ 2.893034696000029, 6.480108271000063 ],
							[ 2.895266543000048, 6.48485080100005 ],
							[ 2.89667058200007, 6.487743368000054 ],
							[ 2.896670586000027, 6.491742699000042 ],
							[ 2.89630698600007, 6.495014715000025 ],
							[ 2.89448905100005, 6.499014275000036 ],
							[ 2.892274856000029, 6.501963203000059 ],
							[ 2.889995567000028, 6.506023981000055 ],
							[ 2.888858791000075, 6.50834366600003 ],
							[ 2.886929308000049, 6.510953090000044 ],
							[ 2.898197266000068, 6.511141057000032 ],
							[ 2.901930985000035, 6.510892397000021 ],
							[ 2.905638835000047, 6.512197940000021 ],
							[ 2.912132825000072, 6.514171536000049 ],
							[ 2.921485196000049, 6.512461372000075 ],
							[ 2.923375232000069, 6.510938058000022 ],
							[ 2.927114087000064, 6.510378045000039 ],
							[ 2.934545436000064, 6.512055923000048 ],
							[ 2.940448968000055, 6.512153678000061 ],
							[ 2.948548061000054, 6.511043833000031 ],
							[ 2.955684175000044, 6.51178376200005 ],
							[ 2.969992340000033, 6.511086765000073 ],
							[ 2.985538159000043, 6.510720724000066 ],
							[ 2.99210389700005, 6.508340927000063 ],
							[ 3.009834730000023, 6.507387659000074 ],
							[ 3.023551576000045, 6.504813368000043 ],
							[ 3.024404101000073, 6.504091071000062 ],
							[ 3.030138255000054, 6.503189509000038 ],
							[ 3.046273687000053, 6.502695987000038 ],
							[ 3.064279599000031, 6.50392099000004 ],
							[ 3.073605827000051, 6.503761193000059 ],
							[ 3.081999969000037, 6.503586091000045 ],
							[ 3.091651972000022, 6.502498208000077 ],
							[ 3.094720844000051, 6.501485364000075 ],
							[ 3.095901619000074, 6.501701958000069 ],
							[ 3.100608909000073, 6.500694573000033 ],
							[ 3.101540839000052, 6.499492355000029 ],
							[ 3.103946204000067, 6.499459311000066 ],
							[ 3.109108907000063, 6.500844998000048 ],
							[ 3.111393925000073, 6.501201958000024 ],
							[ 3.11433198900005, 6.501081898000052 ],
							[ 3.118412007000074, 6.501140965000047 ],
							[ 3.12165927500007, 6.500112408000064 ],
							[ 3.123716111000022, 6.500812960000076 ],
							[ 3.130162941000037, 6.500661745000059 ],
							[ 3.13343, 6.502329814000063 ],
							[ 3.136205907000033, 6.503343087000076 ],
							[ 3.140777119000063, 6.504056968000043 ],
							[ 3.143634063000036, 6.504502853000076 ],
							[ 3.147514559000058, 6.502919236000025 ],
							[ 3.155649450000055, 6.501806247000047 ],
							[ 3.157017974000041, 6.504858016000071 ],
							[ 3.159874908000063, 6.50530490400007 ],
							[ 3.163223052000035, 6.50640596300002 ],
							[ 3.166569944000059, 6.507507804000056 ],
							[ 3.168775107000044, 6.508430907000047 ],
							[ 3.171061060000056, 6.508788914000036 ],
							[ 3.174613267000041, 6.507490034000057 ],
							[ 3.177074440000069, 6.508471149000059 ],
							[ 3.179516071000023, 6.509849 ],
							[ 3.18245769300006, 6.513895604000027 ],
							[ 3.184807786000022, 6.516785071000072 ],
							[ 3.186571615000048, 6.519096884000021 ],
							[ 3.189397571000029, 6.521421323000027 ],
							[ 3.191240052000069, 6.522936829000059 ],
							[ 3.194574370000055, 6.523625868000067 ],
							[ 3.197072520000063, 6.524859805000062 ],
							[ 3.200002203000054, 6.527748187000043 ],
							[ 3.203960405000032, 6.529477442000029 ],
							[ 3.20496702600002, 6.533184042000073 ],
							[ 3.205135109000025, 6.536102710000023 ],
							[ 3.20522190600002, 6.539588995000031 ],
							[ 3.204818967000051, 6.542418947000044 ],
							[ 3.203924914000027, 6.544594765000056 ],
							[ 3.203928961000031, 6.547192620000033 ],
							[ 3.201736432000075, 6.552176555000074 ],
							[ 3.20155718500007, 6.555401734000043 ],
							[ 3.200840473000028, 6.559164986000042 ],
							[ 3.19743561100006, 6.566333236000048 ],
							[ 3.196377288000065, 6.570620963000067 ],
							[ 3.196181066000065, 6.573322177000023 ],
							[ 3.196898010000041, 6.576189628000066 ],
							[ 3.199406865000071, 6.580310895000025 ],
							[ 3.207470873000034, 6.588375166000048 ],
							[ 3.216072574000066, 6.595005580000077 ],
							[ 3.229724176000047, 6.601042213000028 ],
							[ 3.233282788000054, 6.605851246000043 ],
							[ 3.235311051000053, 6.609843721000061 ],
							[ 3.237481129000059, 6.61411621700006 ],
							[ 3.240007623000054, 6.616025448000073 ],
							[ 3.240591056000028, 6.618102212000053 ],
							[ 3.241116767000051, 6.623126012000057 ],
							[ 3.241439128000025, 6.62532668800003 ],
							[ 3.241477246000045, 6.628627356000038 ],
							[ 3.239337185000068, 6.630731948000061 ],
							[ 3.23937010800006, 6.633987325000021 ],
							[ 3.238851300000022, 6.640009252000027 ],
							[ 3.239293345000021, 6.642240946000072 ],
							[ 3.240011200000026, 6.645865998000033 ],
							[ 3.240473275000056, 6.64819914900005 ],
							[ 3.241377363000026, 6.654182996000031 ],
							[ 3.241841081000075, 6.659866261000047 ],
							[ 3.244319439000037, 6.664321889000064 ],
							[ 3.246590126000058, 6.667577633000064 ],
							[ 3.248302677000027, 6.670033019000073 ],
							[ 3.249513651000029, 6.672148177000054 ],
							[ 3.251984340000035, 6.676463996000052 ],
							[ 3.254357565000021, 6.681670770000039 ],
							[ 3.259441050000021, 6.690226274000054 ],
							[ 3.259861725000064, 6.690934322000032 ],
							[ 3.261134371000026, 6.693116227000075 ],
							[ 3.26253597300007, 6.695919368000034 ],
							[ 3.262496200000044, 6.69640229700002 ],
							[ 3.262002861000042, 6.697911903000033 ],
							[ 3.262429504000067, 6.698352113000055 ],
							[ 3.26459896700004, 6.69824139900004 ],
							[ 3.267937722000056, 6.69728219600006 ],
							[ 3.27184997400002, 6.696620752000058 ],
							[ 3.274172866000072, 6.695934704000024 ],
							[ 3.28234700400003, 6.690715383000054 ],
							[ 3.287864544000058, 6.687031433000072 ],
							[ 3.288480260000028, 6.686620510000068 ],
							[ 3.290516343000036, 6.685785200000055 ],
							[ 3.29604682300004, 6.683270205000042 ],
							[ 3.298371911000061, 6.682439352000074 ],
							[ 3.302441805000058, 6.680912986000067 ],
							[ 3.311308329000042, 6.677582587000074 ],
							[ 3.314664787000027, 6.675467070000025 ],
							[ 3.323865431000058, 6.673875677000069 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Nasarawa',
				admin1Pcod: 'NG026',
				admin1RefN: 'Nasarawa',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 9.537188,
				Shape_Area: 2.197927
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 7.997314929000026, 9.314358511000023 ],
							[ 8.000838094000073, 9.306430889000069 ],
							[ 8.006057889000033, 9.303090076000046 ],
							[ 8.015777644000025, 9.296869113000071 ],
							[ 8.022862258000032, 9.292334499000049 ],
							[ 8.025481185000046, 9.290623001000029 ],
							[ 8.03111640000003, 9.286937646000069 ],
							[ 8.040239258000042, 9.280972721000069 ],
							[ 8.045767546000036, 9.277358080000056 ],
							[ 8.05930115700005, 9.273084621000066 ],
							[ 8.061093227000072, 9.272519017000036 ],
							[ 8.061646911000025, 9.272344129000032 ],
							[ 8.063565313000026, 9.272227495000038 ],
							[ 8.072721712000032, 9.262349765000067 ],
							[ 8.076400419000038, 9.25843616700007 ],
							[ 8.084856235000075, 9.25008322900004 ],
							[ 8.088165420000053, 9.246813922000058 ],
							[ 8.093306840000025, 9.241831794000063 ],
							[ 8.093636586000059, 9.241512189000048 ],
							[ 8.095640041000024, 9.240082929000039 ],
							[ 8.111093569000047, 9.229057781000051 ],
							[ 8.114909488000023, 9.224345663000065 ],
							[ 8.114999382000065, 9.222716737000042 ],
							[ 8.114932014000033, 9.221165407000058 ],
							[ 8.114495053000041, 9.211070200000051 ],
							[ 8.114450322000039, 9.210041634000049 ],
							[ 8.114125423000075, 9.202547798000069 ],
							[ 8.111090256000068, 9.191518470000062 ],
							[ 8.108029900000076, 9.180395798000063 ],
							[ 8.107522298000049, 9.178551259000074 ],
							[ 8.106128437000052, 9.173814135000043 ],
							[ 8.101902236000058, 9.16717651600004 ],
							[ 8.099162756000055, 9.163387700000044 ],
							[ 8.098590705000049, 9.162779951000061 ],
							[ 8.098958961000051, 9.151246826000033 ],
							[ 8.099066937000032, 9.14785598900005 ],
							[ 8.099704963000022, 9.123631595000063 ],
							[ 8.099948024000071, 9.114378845000033 ],
							[ 8.100430303000053, 9.096033787000067 ],
							[ 8.100702210000065, 9.085691595000071 ],
							[ 8.100828389000071, 9.080902190000074 ],
							[ 8.101373756000044, 9.075446993000071 ],
							[ 8.102590389000056, 9.063282968000067 ],
							[ 8.107876980000071, 9.052711628000054 ],
							[ 8.128138509000053, 9.049187580000023 ],
							[ 8.135277485000074, 9.051707176000036 ],
							[ 8.13998117400007, 9.053366554000036 ],
							[ 8.143115088000059, 9.054472959000066 ],
							[ 8.149352892000024, 9.058846509000034 ],
							[ 8.155382993000046, 9.063074821000043 ],
							[ 8.160416502000032, 9.066604586000039 ],
							[ 8.164801110000042, 9.070606344000055 ],
							[ 8.165406230000031, 9.071158112000035 ],
							[ 8.17538552000002, 9.080264974000045 ],
							[ 8.181877801000041, 9.08618858400007 ],
							[ 8.186074132000044, 9.090384631000063 ],
							[ 8.20106606500002, 9.105376479000029 ],
							[ 8.206231871000057, 9.110542379000037 ],
							[ 8.215354710000042, 9.119664937000039 ],
							[ 8.223494408000022, 9.126865574000021 ],
							[ 8.229938695000044, 9.132566719000067 ],
							[ 8.231598643000041, 9.134034882000037 ],
							[ 8.238259575000029, 9.139926757000069 ],
							[ 8.239851229000067, 9.144702217000031 ],
							[ 8.241190163000056, 9.148718812000027 ],
							[ 8.243545267000059, 9.155784428000061 ],
							[ 8.251474292000069, 9.163713522000023 ],
							[ 8.252280196000072, 9.166937859000029 ],
							[ 8.254117251000025, 9.174284898000053 ],
							[ 8.255879586000049, 9.178689917000042 ],
							[ 8.248138636000022, 9.182248980000054 ],
							[ 8.251018158000022, 9.190975818000027 ],
							[ 8.253240320000032, 9.192621354000039 ],
							[ 8.255172820000041, 9.193997854000031 ],
							[ 8.255656367000029, 9.194342390000031 ],
							[ 8.257858817000056, 9.195909292000067 ],
							[ 8.261784723000062, 9.198701370000038 ],
							[ 8.263643632000026, 9.199359449000042 ],
							[ 8.264198054000076, 9.199554945000045 ],
							[ 8.264742248000061, 9.199747030000026 ],
							[ 8.268936085000064, 9.201225726000075 ],
							[ 8.271835666000072, 9.202248606000069 ],
							[ 8.274279741000043, 9.203119136000055 ],
							[ 8.275814550000064, 9.203666897000062 ],
							[ 8.277707618000022, 9.204342507000035 ],
							[ 8.278208050000046, 9.204520914000057 ],
							[ 8.278749402000074, 9.204714103000072 ],
							[ 8.280853473000036, 9.205465251000021 ],
							[ 8.282463668000048, 9.205590448000066 ],
							[ 8.284905734000063, 9.20578054300006 ],
							[ 8.286555313000065, 9.205564480000021 ],
							[ 8.289173644000073, 9.205233881000026 ],
							[ 8.290856794000035, 9.205021252000051 ],
							[ 8.292858681000041, 9.204768545000036 ],
							[ 8.294405780000034, 9.20457373000005 ],
							[ 8.296256366000023, 9.203989579000051 ],
							[ 8.298040336000042, 9.203426181000054 ],
							[ 8.299784427000077, 9.202875714000072 ],
							[ 8.302839294000023, 9.201911240000072 ],
							[ 8.306157764000034, 9.20086361400007 ],
							[ 8.308230380000055, 9.20020975500006 ],
							[ 8.309815568000033, 9.199718826000037 ],
							[ 8.31200543500006, 9.199048202000029 ],
							[ 8.315255471000057, 9.198052362000055 ],
							[ 8.318663779000076, 9.197008180000068 ],
							[ 8.322483740000052, 9.195838123000044 ],
							[ 8.324588707000032, 9.195193266000047 ],
							[ 8.327366104000021, 9.194344496000042 ],
							[ 8.331689892000043, 9.193022702000064 ],
							[ 8.332676923000065, 9.192851579000035 ],
							[ 8.333778385000073, 9.19266086600004 ],
							[ 8.335131989000047, 9.192425978000074 ],
							[ 8.339506845000074, 9.188963072000035 ],
							[ 8.366620218000037, 9.183536063000076 ],
							[ 8.380673698000066, 9.181792887000029 ],
							[ 8.381488599000022, 9.181091283000058 ],
							[ 8.387766659000022, 9.175688801000035 ],
							[ 8.395826965000026, 9.162228127000049 ],
							[ 8.409548287000064, 9.150699399000075 ],
							[ 8.418205487000023, 9.141693783000051 ],
							[ 8.423004132000074, 9.135594974000071 ],
							[ 8.430751520000058, 9.125831536000021 ],
							[ 8.436967819000074, 9.120651309000039 ],
							[ 8.441974566000056, 9.116478946000029 ],
							[ 8.446608641000068, 9.112617334000049 ],
							[ 8.454324677000045, 9.101393715000029 ],
							[ 8.456299630000046, 9.098521464000044 ],
							[ 8.462333790000059, 9.089771983000048 ],
							[ 8.466701289000071, 9.083439149000071 ],
							[ 8.471419222000065, 9.076597050000032 ],
							[ 8.473918935000029, 9.072973298000022 ],
							[ 8.474369257000035, 9.069972741000072 ],
							[ 8.476561586000059, 9.055354144000034 ],
							[ 8.479083166000066, 9.05220225100004 ],
							[ 8.48661633200004, 9.042786525000054 ],
							[ 8.490657971000076, 9.037735124000051 ],
							[ 8.494181381000033, 9.023639818000049 ],
							[ 8.498586514000067, 9.011305908000054 ],
							[ 8.504346156000054, 9.001431368000055 ],
							[ 8.508942708000063, 9.000640735000047 ],
							[ 8.514039212000057, 9.00065313500005 ],
							[ 8.521720172000073, 9.001897908000046 ],
							[ 8.533261415000027, 9.004377638000051 ],
							[ 8.541092180000021, 9.010523797000076 ],
							[ 8.547574299000075, 9.014216215000033 ],
							[ 8.554131472000051, 9.020358844000043 ],
							[ 8.559490314000072, 9.028950713000029 ],
							[ 8.559676848000038, 9.035078794000071 ],
							[ 8.562450562000038, 9.042438458000049 ],
							[ 8.564022958000066, 9.052246333000028 ],
							[ 8.566833533000022, 9.060831114000052 ],
							[ 8.570730019000052, 9.063291565000043 ],
							[ 8.576051497000037, 9.070657459000074 ],
							[ 8.579985416000056, 9.074343523000039 ],
							[ 8.586617673000035, 9.08293812200003 ],
							[ 8.594523383000023, 9.091536259000065 ],
							[ 8.604977578000046, 9.100140349000071 ],
							[ 8.610223835000056, 9.105054857000027 ],
							[ 8.61117239500004, 9.10594363000007 ],
							[ 8.617546866000055, 9.123943284000063 ],
							[ 8.624248659000045, 9.149566750000076 ],
							[ 8.626466403000052, 9.174072315000046 ],
							[ 8.626448106000055, 9.191892307000046 ],
							[ 8.644384167000055, 9.20082031000004 ],
							[ 8.651108496000063, 9.206395084000064 ],
							[ 8.653344318000052, 9.214193680000051 ],
							[ 8.653339011000071, 9.219762598000045 ],
							[ 8.657099450000032, 9.226499106000063 ],
							[ 8.659735425000065, 9.223551796000038 ],
							[ 8.657498089000057, 9.218362804000037 ],
							[ 8.656659379000075, 9.216415306000044 ],
							[ 8.656926046000024, 9.210636428000043 ],
							[ 8.65780746400003, 9.207689198000025 ],
							[ 8.661484839000025, 9.195393495000076 ],
							[ 8.663496998000028, 9.193553141000052 ],
							[ 8.667521241000031, 9.189869605000069 ],
							[ 8.67328937700006, 9.190126217000056 ],
							[ 8.677312903000029, 9.186444276000032 ],
							[ 8.681158873000072, 9.186614883000061 ],
							[ 8.687015683000027, 9.184944409000025 ],
							[ 8.690772927000069, 9.187042360000021 ],
							[ 8.696332926000025, 9.187096715000052 ],
							[ 8.70807839500003, 9.187809722000054 ],
							[ 8.712012959000049, 9.186054260000049 ],
							[ 8.721716724000032, 9.184555185000022 ],
							[ 8.733431549000045, 9.181214582000052 ],
							[ 8.739396302000046, 9.180125392000036 ],
							[ 8.746224480000024, 9.178643979000071 ],
							[ 8.748992052000062, 9.178043486000036 ],
							[ 8.75502797200005, 9.17252043700006 ],
							[ 8.761064288000057, 9.166996716000028 ],
							[ 8.764998372000036, 9.165241261000062 ],
							[ 8.767553060000068, 9.157420375000072 ],
							[ 8.767710924000028, 9.149915548000024 ],
							[ 8.767901221000045, 9.144135392000067 ],
							[ 8.767522768000049, 9.136285513000075 ],
							[ 8.764768317000062, 9.12855053800007 ],
							[ 8.761279028000047, 9.120674354000073 ],
							[ 8.761457355000061, 9.116821118000075 ],
							[ 8.761635678000061, 9.112968475000059 ],
							[ 8.760068892000049, 9.105177133000041 ],
							[ 8.764093451000065, 9.101494786000046 ],
							[ 8.768505904000051, 9.097862931000066 ],
							[ 8.768846582000037, 9.095305561000032 ],
							[ 8.774152808000053, 9.092287881000061 ],
							[ 8.77817652300007, 9.088605934000043 ],
							[ 8.780188620000047, 9.086765508000042 ],
							[ 8.784123477000037, 9.085009843000023 ],
							[ 8.791902612000058, 9.083423586000038 ],
							[ 8.795664961000057, 9.075638759000071 ],
							[ 8.799438432000045, 9.071156268000038 ],
							[ 8.796550819000061, 9.066255363000039 ],
							[ 8.794805542000063, 9.062317968000059 ],
							[ 8.793061439000041, 9.058379453000043 ],
							[ 8.789482935000024, 9.052428157000065 ],
							[ 8.789750205000075, 9.046649133000074 ],
							[ 8.789928195000073, 9.042795869000031 ],
							[ 8.794041591000052, 9.037186847000044 ],
							[ 8.798154860000068, 9.031578333000027 ],
							[ 8.806030314000054, 9.032741551000072 ],
							[ 8.811725768000031, 9.030302180000035 ],
							[ 8.823241282000026, 9.030761655000049 ],
							[ 8.838712955000062, 9.029518189000044 ],
							[ 8.844481502000065, 9.029773830000067 ],
							[ 8.850249498000039, 9.030030511000064 ],
							[ 8.861964430000057, 9.026689743000077 ],
							[ 8.865810494000073, 9.026860129000056 ],
							[ 8.875513348000027, 9.02535983000007 ],
							[ 8.885355030000028, 9.021609106000028 ],
							[ 8.888392159000034, 9.022071720000042 ],
							[ 8.894920511000066, 9.022360707000075 ],
							[ 8.898164625000049, 9.021048342000029 ],
							[ 8.907392306000077, 9.01932329400006 ],
							[ 8.91633895700005, 9.01752108900007 ],
							[ 8.92279934000004, 9.016204705000064 ],
							[ 8.924118943000053, 9.015936002000046 ],
							[ 8.927272735000031, 9.016075089000026 ],
							[ 8.929887912000027, 9.016190307000045 ],
							[ 8.943020875000059, 9.01558114200003 ],
							[ 8.95112812800005, 9.015204264000033 ],
							[ 8.954884581000044, 9.01730178400004 ],
							[ 8.960741957000039, 9.015630914000042 ],
							[ 8.963750147000042, 9.015764025000067 ],
							[ 8.966815876000055, 9.015042135000044 ],
							[ 8.96952710000005, 9.016020544000071 ],
							[ 8.974201232000041, 9.016228846000047 ],
							[ 8.977663298000039, 9.016536490000021 ],
							[ 8.983613150000053, 9.017694665000022 ],
							[ 8.989495331000057, 9.01883863200004 ],
							[ 8.992649173000075, 9.018939293000074 ],
							[ 8.995292834000054, 9.01902295900004 ],
							[ 8.998668500000065, 9.020369610000046 ],
							[ 9.00094303700007, 9.021277144000067 ],
							[ 9.004336357000057, 9.021058990000029 ],
							[ 9.008892232000051, 9.020766193000043 ],
							[ 9.012277626000071, 9.021788341000047 ],
							[ 9.01632505200007, 9.021977500000048 ],
							[ 9.020171137000034, 9.022131020000074 ],
							[ 9.023927481000044, 9.024227835000033 ],
							[ 9.027773595000042, 9.024398792000056 ],
							[ 9.039221713000074, 9.026837262000072 ],
							[ 9.044990359000053, 9.027093710000031 ],
							[ 9.049557019000076, 9.02616411300005 ],
							[ 9.052770548000069, 9.025508724000076 ],
							[ 9.056705700000066, 9.023752977000072 ],
							[ 9.060153692000029, 9.020597519000034 ],
							[ 9.062741213000038, 9.018228793000048 ],
							[ 9.066963006000037, 9.007926994000059 ],
							[ 9.06908140400003, 8.997752121000076 ],
							[ 9.069753770000034, 8.995417602000032 ],
							[ 9.069836692000024, 8.991868795000073 ],
							[ 9.070737918000077, 8.987840651000056 ],
							[ 9.068367679000062, 8.979869902000075 ],
							[ 9.067069095000022, 8.966298317000053 ],
							[ 9.069081329000028, 8.964458562000061 ],
							[ 9.071182462000024, 8.960689552000076 ],
							[ 9.073461347000034, 8.953068830000063 ],
							[ 9.071894817000043, 8.94527706100007 ],
							[ 9.068227942000021, 8.941252590000033 ],
							[ 9.06254786900007, 8.93907064800004 ],
							[ 9.053290228000037, 8.930936991000067 ],
							[ 9.045955527000046, 8.922889912000073 ],
							[ 9.044300124000074, 8.917024443000059 ],
							[ 9.044477629000028, 8.913171603000023 ],
							[ 9.040810785000076, 8.909147982000036 ],
							[ 9.039065332000064, 8.905209469000056 ],
							[ 9.031820558000049, 8.895235094000043 ],
							[ 9.028508221000038, 8.883505113000069 ],
							[ 9.026764161000074, 8.879565963000061 ],
							[ 9.025197252000055, 8.871774539000057 ],
							[ 9.023541279000028, 8.865909516000045 ],
							[ 9.018040408000047, 8.859874039000033 ],
							[ 9.016206831000034, 8.857862373000046 ],
							[ 9.010616449000054, 8.853753192000056 ],
							[ 9.003014811000071, 8.85148504600005 ],
							[ 8.999079583000025, 8.853240809000056 ],
							[ 8.989554200000043, 8.850887030000024 ],
							[ 8.985619455000062, 8.852643225000065 ],
							[ 8.973994408000067, 8.854057590000025 ],
							[ 8.96639129600004, 8.851789243000042 ],
							[ 8.958789974000069, 8.849521869000057 ],
							[ 8.957044559000053, 8.845582937000074 ],
							[ 8.955300299000044, 8.841644015000043 ],
							[ 8.949798672000043, 8.835607511000035 ],
							[ 8.950065511000048, 8.829829415000063 ],
							[ 8.951099365000061, 8.827142518000073 ],
							[ 8.950969015000055, 8.824192132000064 ],
							[ 8.950596673000064, 8.815776646000074 ],
							[ 8.949033591000045, 8.810478074000059 ],
							[ 8.945146484000077, 8.799559835000025 ],
							[ 8.938326895000046, 8.793672696000044 ],
							[ 8.932709566000028, 8.78550830000006 ],
							[ 8.929040689000033, 8.784493260000033 ],
							[ 8.923966372000052, 8.782485008000037 ],
							[ 8.919151234000026, 8.78329547800007 ],
							[ 8.916966213000023, 8.783663428000068 ],
							[ 8.911553643000047, 8.779817804000061 ],
							[ 8.907196712000029, 8.775965590000055 ],
							[ 8.903566646000058, 8.770669675000022 ],
							[ 8.901631660000021, 8.765132883000035 ],
							[ 8.901088610000045, 8.763946602000033 ],
							[ 8.904689654000038, 8.758125326000027 ],
							[ 8.904410264000035, 8.755034163000062 ],
							[ 8.899371161000033, 8.750970892000055 ],
							[ 8.896033421000027, 8.748279530000048 ],
							[ 8.891933419000054, 8.74035365900005 ],
							[ 8.892136804000074, 8.732930286000055 ],
							[ 8.896886766000023, 8.727269067000066 ],
							[ 8.902836893000028, 8.719330727000056 ],
							[ 8.907656733000067, 8.71021943900007 ],
							[ 8.910738058000049, 8.702993408000054 ],
							[ 8.911806309000042, 8.698771513000054 ],
							[ 8.91394714300003, 8.686176204000049 ],
							[ 8.919826704000059, 8.681688358000031 ],
							[ 8.925706698000056, 8.677200416000062 ],
							[ 8.929218050000031, 8.673177586000065 ],
							[ 8.932219790000033, 8.67140401100005 ],
							[ 8.934799053000063, 8.667881068000042 ],
							[ 8.940243882000061, 8.667230721000067 ],
							[ 8.940459438000062, 8.662574867000046 ],
							[ 8.941023599000061, 8.65960506600004 ],
							[ 8.941788484000028, 8.655583365000041 ],
							[ 8.942275964000032, 8.652189151000073 ],
							[ 8.94449789600003, 8.646667584000056 ],
							[ 8.94732397100006, 8.63964857700006 ],
							[ 8.949756567000065, 8.633942516000047 ],
							[ 8.951176418000045, 8.621312075000048 ],
							[ 8.95831004300004, 8.612245579000046 ],
							[ 8.964166734000059, 8.608907578000071 ],
							[ 8.970023317000027, 8.605570873000033 ],
							[ 8.977794758000073, 8.605191115000025 ],
							[ 8.980529942000032, 8.601173380000034 ],
							[ 8.983431539000037, 8.596580632000041 ],
							[ 8.983179822000068, 8.58511351900006 ],
							[ 8.981021992000024, 8.578203314000064 ],
							[ 8.982412274000069, 8.574304444000063 ],
							[ 8.983221900000046, 8.572034754000072 ],
							[ 8.986305055000059, 8.566468304000068 ],
							[ 8.987382748000073, 8.564522687000022 ],
							[ 8.986068898000042, 8.56198024300005 ],
							[ 8.984094492000054, 8.558160882000038 ],
							[ 8.984020308000026, 8.553231184000026 ],
							[ 8.978455392000058, 8.549236184000051 ],
							[ 8.974965897000061, 8.541358947000049 ],
							[ 8.975144511000053, 8.537506065000059 ],
							[ 8.979004058000044, 8.53058431900007 ],
							[ 8.982459996000046, 8.527254054000025 ],
							[ 8.988257193000038, 8.52288545600004 ],
							[ 8.990618976000064, 8.521105723000062 ],
							[ 8.99473867200004, 8.519574083000066 ],
							[ 8.997275358000024, 8.517252935000045 ],
							[ 9.005144807000022, 8.513740490000032 ],
							[ 9.010276583000064, 8.515272106000054 ],
							[ 9.013636673000065, 8.512063041000033 ],
							[ 9.019470297000055, 8.509875242000021 ],
							[ 9.026385074000075, 8.512754307000023 ],
							[ 9.032154098000035, 8.513010980000047 ],
							[ 9.036776295000038, 8.512514947000057 ],
							[ 9.040200356000071, 8.514884078000023 ],
							[ 9.043380926000054, 8.518355453000027 ],
							[ 9.049360359000048, 8.519665751000048 ],
							[ 9.054130401000066, 8.516071277000037 ],
							[ 9.059162165000032, 8.512280341000064 ],
							[ 9.059518734000051, 8.504572779000057 ],
							[ 9.059874710000031, 8.496867215000066 ],
							[ 9.063793023000073, 8.493480719000047 ],
							[ 9.066352617000064, 8.492520434000028 ],
							[ 9.069934901000067, 8.487661259000049 ],
							[ 9.075792320000062, 8.485990494000021 ],
							[ 9.080109691000075, 8.488046571000041 ],
							[ 9.083287507000023, 8.486323411000058 ],
							[ 9.088303482000072, 8.483602660000031 ],
							[ 9.095366584000033, 8.477988262000054 ],
							[ 9.101412669000069, 8.473614694000048 ],
							[ 9.102123233000043, 8.469863029000066 ],
							[ 9.103188744000022, 8.464233407000052 ],
							[ 9.100202725000031, 8.458116655000026 ],
							[ 9.098458215000051, 8.454178887000069 ],
							[ 9.096802942000068, 8.448313769000038 ],
							[ 9.097158672000035, 8.440607017000048 ],
							[ 9.101093512000034, 8.438851296000053 ],
							[ 9.111243342000023, 8.438022748000037 ],
							[ 9.11842802700005, 8.43816375800003 ],
							[ 9.121526888000062, 8.438224911000077 ],
							[ 9.124434669000038, 8.434096373000045 ],
							[ 9.127536755000051, 8.433738739000034 ],
							[ 9.128458931000068, 8.430414159000065 ],
							[ 9.132394088000069, 8.428657547000057 ],
							[ 9.136756043000048, 8.435445723000043 ],
							[ 9.138598702000024, 8.43831354100007 ],
							[ 9.145819467000024, 8.443388819000063 ],
							[ 9.148897011000031, 8.446765804000052 ],
							[ 9.152475456000047, 8.452715762000025 ],
							[ 9.156231793000075, 8.454813013000035 ],
							[ 9.15806582700003, 8.456825134000042 ],
							[ 9.17492597100005, 8.467225953000025 ],
							[ 9.17702655100004, 8.463459072000035 ],
							[ 9.182617364000066, 8.467567513000063 ],
							[ 9.184629685000061, 8.465726799000038 ],
							[ 9.202290679000043, 8.458789006000075 ],
							[ 9.211994056000037, 8.457288835000043 ],
							[ 9.215840509000031, 8.457459516000029 ],
							[ 9.233145960000058, 8.458228252000026 ],
							[ 9.244682583000042, 8.45874010600005 ],
							[ 9.254297415000053, 8.459166454000069 ],
							[ 9.261899249000066, 8.461435403000053 ],
							[ 9.263910559000067, 8.459594830000071 ],
							[ 9.273347147000038, 8.463870887000041 ],
							[ 9.274537300000077, 8.463628699000026 ],
							[ 9.28621709600003, 8.46898212800005 ],
							[ 9.292800733000036, 8.47084830600005 ],
							[ 9.301267318000043, 8.475051808000046 ],
							[ 9.310206564000055, 8.482529804000023 ],
							[ 9.318673622000063, 8.486733180000044 ],
							[ 9.336542663000046, 8.489056255000037 ],
							[ 9.345948812000074, 8.491854726000042 ],
							[ 9.362403306000033, 8.489967543000034 ],
							[ 9.377915250000058, 8.485741321000035 ],
							[ 9.397651818000043, 8.475896135000028 ],
							[ 9.420674031000033, 8.462303432000056 ],
							[ 9.491624281000043, 8.430870612000035 ],
							[ 9.508541524000066, 8.426169385000037 ],
							[ 9.529692713000031, 8.423800277000055 ],
							[ 9.545914408000044, 8.42611585700007 ],
							[ 9.560495548000063, 8.431240109000044 ],
							[ 9.571774599000037, 8.42888348200006 ],
							[ 9.575997521000033, 8.42373073300007 ],
							[ 9.60886811000006, 8.402157311000053 ],
							[ 9.621068700000023, 8.38950557000004 ],
							[ 9.621475490000023, 8.388993169000059 ],
							[ 9.618506508000053, 8.387319517000037 ],
							[ 9.620701731000054, 8.38540942000003 ],
							[ 9.622797155000058, 8.383587919000036 ],
							[ 9.618302376000031, 8.377766649000023 ],
							[ 9.621118977000037, 8.370690381000031 ],
							[ 9.624042147000068, 8.360604708000039 ],
							[ 9.624523762000024, 8.355725841000037 ],
							[ 9.624685319000037, 8.354587421000076 ],
							[ 9.623699554000041, 8.351499914000044 ],
							[ 9.622226369000032, 8.349551357000053 ],
							[ 9.619939005000049, 8.349229993000051 ],
							[ 9.611966946000052, 8.350235397000063 ],
							[ 9.61211290500006, 8.340802291000045 ],
							[ 9.612330567000072, 8.330449071000032 ],
							[ 9.613750697000057, 8.31781871000004 ],
							[ 9.617530084000066, 8.302932677000058 ],
							[ 9.615387130000045, 8.294835929000044 ],
							[ 9.609673514000065, 8.291272220000053 ],
							[ 9.600370560000044, 8.29339115700003 ],
							[ 9.592200154000068, 8.296684316000039 ],
							[ 9.584077011000034, 8.297676067000054 ],
							[ 9.56665050600003, 8.300786854000023 ],
							[ 9.561973597000076, 8.302996550000046 ],
							[ 9.546886581000024, 8.305003111000076 ],
							[ 9.54343964100002, 8.303785314000038 ],
							[ 9.535340061000056, 8.303627102000064 ],
							[ 9.524930066000024, 8.303422832000024 ],
							[ 9.518010193000066, 8.302137405000053 ],
							[ 9.512203396000075, 8.303174039000055 ],
							[ 9.504057080000052, 8.305316037000068 ],
							[ 9.498297012000023, 8.304052369000033 ],
							[ 9.492509578000067, 8.303939797000055 ],
							[ 9.483206759000041, 8.306058879000034 ],
							[ 9.475036727000031, 8.309351969000033 ],
							[ 9.466866544000027, 8.312644043000034 ],
							[ 9.452839675000064, 8.319273951000071 ],
							[ 9.443512653000028, 8.322544019000077 ],
							[ 9.431823709000071, 8.328068630000075 ],
							[ 9.420156359000032, 8.33244310300006 ],
							[ 9.408443628000043, 8.33911895500006 ],
							[ 9.40140605800002, 8.343584010000029 ],
							[ 9.389716940000028, 8.349109536000071 ],
							[ 9.382753106000052, 8.350123292000035 ],
							[ 9.376966690000074, 8.35001088100006 ],
							[ 9.367830490000074, 8.344077984000023 ],
							[ 9.36327667200004, 8.34053698300005 ],
							[ 9.359949792000066, 8.333567622000032 ],
							[ 9.356623615000046, 8.326597098000036 ],
							[ 9.354546560000074, 8.315049066000029 ],
							[ 9.35018365600007, 8.302306275000035 ],
							[ 9.346856817000059, 8.295336783000039 ],
							[ 9.342466535000028, 8.283742785000072 ],
							[ 9.335693461000062, 8.275555731000054 ],
							[ 9.330030185000055, 8.269691594000051 ],
							[ 9.32551933600007, 8.263849221000044 ],
							[ 9.323423626000022, 8.253451275000032 ],
							[ 9.325903046000064, 8.245444233000057 ],
							[ 9.329566937000038, 8.236310146000051 ],
							[ 9.336165264000044, 8.232561142000066 ],
							[ 9.341457459000026, 8.229338784000049 ],
							[ 9.346748426000033, 8.226116072000025 ],
							[ 9.353749341000025, 8.222971030000053 ],
							[ 9.358958381000036, 8.22147549400006 ],
							[ 9.364250215000027, 8.218253103000052 ],
							[ 9.371249134000038, 8.215108005000047 ],
							[ 9.37483304400007, 8.211809183000071 ],
							[ 9.380124020000039, 8.20858656300004 ],
							[ 9.382139199000051, 8.205823874000032 ],
							[ 9.384363256000029, 8.204757733000065 ],
							[ 9.390469302000042, 8.201827967000042 ],
							[ 9.397533645000067, 8.196212756000023 ],
							[ 9.405823696000027, 8.187169185000073 ],
							[ 9.411823419000029, 8.176929396000048 ],
							[ 9.40627661700006, 8.165313663000063 ],
							[ 9.400613899000064, 8.159449711000036 ],
							[ 9.391430075000073, 8.155817137000042 ],
							[ 9.386946785000021, 8.148825743000032 ],
							[ 9.391909803000033, 8.132811544000049 ],
							[ 9.392219410000052, 8.117857920000063 ],
							[ 9.392556123000077, 8.101754226000025 ],
							[ 9.395036955000023, 8.093748080000069 ],
							[ 9.398653037000031, 8.086914160000049 ],
							[ 9.403423478000036, 8.080102937000049 ],
							[ 9.414026503000059, 8.071104061000028 ],
							[ 9.415376428000059, 8.06192500700007 ],
							[ 9.404143196000064, 8.045594246000064 ],
							[ 9.396162853000021, 8.039684437000062 ],
							[ 9.387053299000058, 8.032602446000055 ],
							[ 9.379050327000073, 8.027843431000065 ],
							[ 9.368829581000057, 8.018436358000031 ],
							[ 9.359693282000023, 8.012503744000071 ],
							[ 9.351713268000026, 8.006593796000061 ],
							[ 9.346026618000053, 8.001879574000043 ],
							[ 9.33932298700006, 7.990242065000075 ],
							[ 9.335518035000064, 7.985507863000066 ],
							[ 9.319115787000044, 7.976533354000026 ],
							[ 9.314585523000062, 7.974054672000022 ],
							[ 9.305362942000045, 7.969008002000066 ],
							[ 9.281080473000031, 7.955854941000041 ],
							[ 9.250726435000047, 7.944725045000041 ],
							[ 9.220373356000039, 7.926513076000049 ],
							[ 9.209753932000069, 7.921768581000038 ],
							[ 9.186791677000031, 7.911508424000033 ],
							[ 9.179125792000036, 7.908083360000035 ],
							[ 9.173732415000075, 7.905212294000023 ],
							[ 9.172960058000058, 7.90628244100003 ],
							[ 9.164221398000052, 7.911312374000033 ],
							[ 9.156827431000067, 7.916006129000039 ],
							[ 9.135650043000055, 7.924726215000021 ],
							[ 9.130944984000053, 7.930758903000026 ],
							[ 9.063386852000065, 8.052066475000061 ],
							[ 9.063388133000046, 8.059437610000032 ],
							[ 9.063388831000054, 8.063458423000043 ],
							[ 9.063389529000062, 8.067478977000064 ],
							[ 9.06170865100006, 8.070829841000034 ],
							[ 9.056972797000071, 8.07764538400005 ],
							[ 9.055993019000027, 8.077275382000039 ],
							[ 9.050175574000036, 8.075201949000075 ],
							[ 9.025375094000026, 8.062576341000067 ],
							[ 8.971267543000067, 8.057616334000045 ],
							[ 8.910846894000031, 8.056263920000049 ],
							[ 8.841859041000021, 8.067085359000032 ],
							[ 8.819764980000059, 8.070241853000027 ],
							[ 8.780536437000023, 8.07520205000003 ],
							[ 8.696668745000068, 8.096394572000065 ],
							[ 8.637149756000042, 8.116233965000049 ],
							[ 8.621654845000023, 8.120214861000022 ],
							[ 8.595024784000032, 8.152823467000076 ],
							[ 8.588485535000075, 8.156070427000031 ],
							[ 8.565614005000043, 8.153874643000051 ],
							[ 8.553634943000077, 8.151690539000072 ],
							[ 8.542395759000044, 8.148730200000045 ],
							[ 8.524424769000063, 8.157265553000059 ],
							[ 8.512679995000042, 8.157776863000038 ],
							[ 8.503682833000028, 8.158167761000072 ],
							[ 8.48609835700006, 8.149149777000048 ],
							[ 8.472741367000026, 8.142471808000039 ],
							[ 8.46625780100004, 8.139229697000076 ],
							[ 8.426578783000025, 8.112175978000039 ],
							[ 8.390506523000056, 8.077907638000056 ],
							[ 8.371117610000056, 8.046343825000065 ],
							[ 8.363453119000042, 8.016584359000035 ],
							[ 8.360296328000061, 7.987276193000071 ],
							[ 8.360296453000046, 7.960672770000031 ],
							[ 8.36345269900005, 7.946243874000061 ],
							[ 8.367116421000048, 7.938917438000033 ],
							[ 8.364501197000038, 7.925769851000041 ],
							[ 8.36451063800007, 7.920345803000032 ],
							[ 8.368870713000035, 7.917098760000044 ],
							[ 8.378496422000069, 7.916155856000046 ],
							[ 8.378783177000059, 7.915582745000052 ],
							[ 8.397721471000068, 7.879961409000032 ],
							[ 8.405386953000061, 7.861023544000034 ],
							[ 8.404662231000032, 7.836760382000023 ],
							[ 8.39664259500006, 7.829448133000028 ],
							[ 8.392920342000025, 7.820144546000051 ],
							[ 8.393851194000035, 7.809910217000038 ],
							[ 8.401294668000048, 7.795954748000042 ],
							[ 8.406876796000063, 7.787116170000047 ],
							[ 8.411994024000023, 7.776416914000038 ],
							[ 8.41199371600004, 7.768508810000071 ],
							[ 8.40315559000004, 7.76618288800006 ],
							[ 8.385477798000068, 7.768043540000065 ],
							[ 8.372159140000065, 7.770002370000043 ],
							[ 8.362218641000027, 7.774556279000024 ],
							[ 8.345472117000043, 7.78432502000004 ],
							[ 8.328724994000027, 7.791768047000062 ],
							[ 8.301744556000074, 7.802467357000069 ],
							[ 8.27476316000002, 7.814562223000053 ],
							[ 8.251504143000034, 7.822470306000071 ],
							[ 8.21521967700005, 7.834565192000071 ],
							[ 8.191029737000065, 7.848986185000058 ],
							[ 8.185447643000032, 7.852242553000053 ],
							[ 8.183121598000071, 7.853172682000036 ],
							[ 8.180357876000073, 7.852580487000068 ],
							[ 8.175678340000047, 7.855033405000029 ],
							[ 8.159862468000028, 7.859685308000053 ],
							[ 8.13939391100007, 7.86154610400007 ],
							[ 8.113808780000056, 7.863872115000049 ],
							[ 8.10198331600003, 7.867123959000025 ],
							[ 8.139251728000033, 7.890596919000075 ],
							[ 8.144676139000069, 7.898203455000044 ],
							[ 8.14357472100005, 7.903624949000061 ],
							[ 8.139218479000021, 7.904699540000024 ],
							[ 8.120716456000025, 7.90357098000004 ],
							[ 8.100035057000071, 7.903520925000066 ],
							[ 8.092413127000043, 7.904586951000056 ],
							[ 8.08586299500007, 7.912164591000021 ],
							[ 8.080404100000067, 7.918659799000068 ],
							[ 8.063361437000026, 7.925603133000038 ],
							[ 8.062916116000054, 7.926988813000037 ],
							[ 7.964902019000021, 7.959576296000023 ],
							[ 7.91804146100003, 7.975713851000023 ],
							[ 7.879907328000058, 7.985363015000075 ],
							[ 7.870348426000021, 7.985333769000022 ],
							[ 7.867259746000059, 7.98621601800005 ],
							[ 7.838417762000063, 7.985285697000052 ],
							[ 7.81097223300003, 7.99133295300004 ],
							[ 7.782595479000065, 7.998776575000022 ],
							[ 7.760732249000057, 8.00342852700004 ],
							[ 7.738402973000063, 8.006218912000065 ],
							[ 7.697466677000023, 8.013662398000065 ],
							[ 7.676837731000035, 8.014471178000065 ],
							[ 7.677225828000076, 8.016626254000073 ],
							[ 7.665675917000044, 8.019343461000062 ],
							[ 7.624914034000028, 8.02953420700004 ],
							[ 7.583472535000055, 8.037686194000059 ],
							[ 7.54271052100006, 8.043121359000054 ],
							[ 7.505345099000067, 8.047198221000031 ],
							[ 7.450995657000021, 8.046518377000041 ],
							[ 7.445786741000063, 8.044860924000034 ],
							[ 7.432442342000058, 8.045692366000026 ],
							[ 7.404485174000058, 8.042964915000027 ],
							[ 7.393178358000057, 8.041861449000066 ],
							[ 7.305553927000062, 8.025581284000054 ],
							[ 7.253840822000029, 8.01504695400007 ],
							[ 7.195903178000037, 7.993021407000072 ],
							[ 7.117855112000029, 7.959025014000076 ],
							[ 7.072845509000047, 7.942744684000047 ],
							[ 7.052934449000077, 7.932279774000051 ],
							[ 7.015335315000073, 7.928329629000075 ],
							[ 7.004494163000061, 7.928270937000036 ],
							[ 6.994654750000052, 7.92527079000007 ],
							[ 6.991730527000072, 7.919361638000055 ],
							[ 6.987842507000039, 7.909518098000035 ],
							[ 6.97803095200004, 7.901606666000021 ],
							[ 6.966287171000033, 7.886808856000073 ],
							[ 6.963587837000034, 7.879294880000032 ],
							[ 6.962668485000052, 7.887568139000052 ],
							[ 6.959910449000063, 7.896760550000067 ],
							[ 6.954395357000067, 7.905033671000069 ],
							[ 6.952556542000025, 7.917903359000036 ],
							[ 6.957152936000057, 7.929853495000032 ],
							[ 6.960829776000026, 7.942722745000026 ],
							[ 6.963587718000042, 7.960188432000052 ],
							[ 6.978295218000028, 7.985927653000033 ],
							[ 6.979214581000065, 8.000635118000048 ],
							[ 6.972780299000021, 8.019939562000047 ],
							[ 6.970941589000063, 8.030051162000063 ],
							[ 6.977376581000044, 8.050274989000059 ],
							[ 6.987028639000073, 8.077852212000039 ],
							[ 6.987028634000069, 8.092559925000046 ],
							[ 6.983351226000025, 8.115541574000076 ],
							[ 6.99714001600006, 8.153230563000022 ],
							[ 7.002655441000059, 8.167018791000032 ],
							[ 7.000817436000034, 8.181727489000025 ],
							[ 6.992543805000025, 8.195515538000052 ],
							[ 6.979674381000052, 8.211143388000039 ],
							[ 6.972320680000053, 8.224931780000077 ],
							[ 6.970481979000056, 8.245155304000036 ],
							[ 6.964966397000069, 8.265378832000067 ],
							[ 6.95945058500007, 8.275490830000024 ],
							[ 6.953016409000043, 8.283763785000076 ],
							[ 6.941065752000043, 8.292956362000041 ],
							[ 6.934631423000042, 8.301229566000075 ],
							[ 6.934631299000046, 8.310421833000021 ],
							[ 6.94198508900007, 8.322372388000076 ],
							[ 6.951177491000067, 8.346272326000076 ],
							[ 6.955773803000056, 8.359142219000034 ],
							[ 6.957612375000053, 8.374769221000065 ],
							[ 6.969562482000072, 8.391315348000035 ],
							[ 6.984174740000071, 8.459377342000039 ],
							[ 6.991440651000062, 8.459823651000022 ],
							[ 7.025257966000026, 8.461647979000077 ],
							[ 7.057121862000031, 8.462964990000046 ],
							[ 7.084213805000047, 8.463680279000073 ],
							[ 7.116741289000061, 8.466221878000056 ],
							[ 7.162991009000052, 8.473337256000036 ],
							[ 7.210765491000075, 8.480960849000041 ],
							[ 7.271184033000054, 8.492465897000045 ],
							[ 7.312413313000036, 8.500782091000076 ],
							[ 7.336676273000023, 8.508596124000064 ],
							[ 7.34188673500006, 8.508452356000021 ],
							[ 7.355688874000066, 8.51244291200004 ],
							[ 7.371426125000028, 8.525280992000035 ],
							[ 7.397605820000024, 8.552622358000065 ],
							[ 7.41812717800002, 8.569902305000028 ],
							[ 7.443030644000032, 8.593281638000065 ],
							[ 7.477623738000034, 8.631333444000063 ],
							[ 7.479258585000025, 8.632776152000076 ],
							[ 7.493228465000072, 8.650736733000031 ],
							[ 7.496000787000071, 8.654301744000065 ],
							[ 7.501979553000069, 8.668651558000022 ],
							[ 7.518496926000068, 8.698686511000062 ],
							[ 7.54144287400004, 8.740403137000044 ],
							[ 7.565301303000069, 8.785279011000057 ],
							[ 7.591669137000054, 8.834875187000023 ],
							[ 7.594658760000073, 8.856400561000044 ],
							[ 7.592267237000044, 8.924564475000068 ],
							[ 7.589055153000061, 9.107646611000064 ],
							[ 7.588679113000069, 9.129055956000059 ],
							[ 7.603319026000065, 9.161440584000047 ],
							[ 7.667606114000023, 9.303650689000051 ],
							[ 7.727131371000041, 9.330638517000068 ],
							[ 7.729873720000057, 9.331881549000059 ],
							[ 7.731813857000077, 9.335779969000043 ],
							[ 7.737789361000068, 9.34778872000004 ],
							[ 7.740754213000059, 9.348780955000052 ],
							[ 7.746373187000074, 9.345459878000042 ],
							[ 7.748904268000047, 9.343964208000045 ],
							[ 7.750706538000031, 9.342991595000058 ],
							[ 7.751334486000076, 9.342652401000066 ],
							[ 7.75217477700005, 9.342312241000059 ],
							[ 7.757361279000065, 9.340212930000064 ],
							[ 7.767582884000035, 9.336287097000024 ],
							[ 7.768490603000032, 9.336033577000023 ],
							[ 7.769930513000077, 9.335632087000022 ],
							[ 7.777380269000048, 9.334025447000045 ],
							[ 7.782733560000054, 9.332870592000063 ],
							[ 7.789250709000044, 9.331105799000056 ],
							[ 7.797523701000046, 9.327527592000024 ],
							[ 7.800537879000046, 9.326158611000039 ],
							[ 7.802185411000039, 9.325436681000042 ],
							[ 7.802932051000028, 9.324982519000059 ],
							[ 7.815063016000067, 9.317599286000075 ],
							[ 7.82063368200005, 9.313021284000058 ],
							[ 7.821066408000036, 9.312813594000033 ],
							[ 7.823261939000076, 9.311759928000072 ],
							[ 7.824436736000052, 9.311264702000074 ],
							[ 7.827270808000037, 9.310069522000049 ],
							[ 7.828161087000069, 9.309719181000048 ],
							[ 7.83116579600005, 9.310326004000046 ],
							[ 7.833882416000051, 9.310784406000039 ],
							[ 7.838095962000068, 9.312892474000023 ],
							[ 7.839999864000049, 9.313844983000024 ],
							[ 7.843781146000026, 9.315682634000041 ],
							[ 7.844635396000058, 9.316097824000053 ],
							[ 7.846659396000064, 9.316887357000041 ],
							[ 7.854626389000032, 9.31999640500004 ],
							[ 7.858121438000069, 9.324931381000056 ],
							[ 7.865868965000061, 9.336982528000021 ],
							[ 7.87843514900004, 9.35653046300007 ],
							[ 7.881907797000054, 9.361931883000068 ],
							[ 7.883907919000023, 9.365042512000059 ],
							[ 7.889836535000029, 9.374264460000063 ],
							[ 7.901639172000046, 9.389019049000069 ],
							[ 7.903931862000036, 9.391884626000035 ],
							[ 7.914795814000058, 9.400461328000063 ],
							[ 7.92067049800005, 9.405098668000051 ],
							[ 7.938289389000033, 9.403336418000038 ],
							[ 7.94407525400004, 9.39887699600007 ],
							[ 7.980576701000075, 9.370740759000057 ],
							[ 7.98717758600003, 9.364769060000071 ],
							[ 7.993228644000055, 9.359294138000052 ],
							[ 7.999076890000026, 9.35400278700007 ],
							[ 8.001719430000037, 9.340788130000021 ],
							[ 7.998341245000063, 9.328625713000065 ],
							[ 7.997314801000073, 9.324931238000033 ],
							[ 7.997315167000068, 9.317783487000042 ],
							[ 7.997314929000026, 9.314358511000023 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Niger',
				admin1Pcod: 'NG027',
				admin1RefN: 'Niger',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 18.921559,
				Shape_Area: 5.930956
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 4.929941648000067, 11.357232151000062 ],
							[ 4.933957569000029, 11.354019252000057 ],
							[ 4.942792391000069, 11.357232174000046 ],
							[ 4.947611859000062, 11.364460899000051 ],
							[ 4.952430716000038, 11.369279640000059 ],
							[ 4.958856067000056, 11.36928014800003 ],
							[ 4.966085028000066, 11.362051060000056 ],
							[ 4.970903828000075, 11.353216385000053 ],
							[ 4.972510283000076, 11.347594104000052 ],
							[ 4.972510306000061, 11.339562545000035 ],
							[ 4.977329776000033, 11.329120775000035 ],
							[ 4.981345680000061, 11.319481658000029 ],
							[ 4.984558138000068, 11.304222323000033 ],
							[ 4.988574008000057, 11.29137025700004 ],
							[ 4.990983491000065, 11.286551577000068 ],
							[ 5.000621746000036, 11.282535818000042 ],
							[ 5.008653745000061, 11.279322437000076 ],
							[ 5.010260004000031, 11.27450347000007 ],
							[ 5.011063533000026, 11.270487844000058 ],
							[ 5.008653615000071, 11.266472119000071 ],
							[ 5.00624405700006, 11.262455825000075 ],
							[ 5.005441075000022, 11.257636930000047 ],
							[ 5.011866503000022, 11.252014311000039 ],
							[ 5.016685378000034, 11.251211161000072 ],
							[ 5.025520799000049, 11.256030269000064 ],
							[ 5.032749605000049, 11.26085009600007 ],
							[ 5.034355598000047, 11.264866089000066 ],
							[ 5.040781508000066, 11.270488 ],
							[ 5.045600350000029, 11.272897906000026 ],
							[ 5.052025851000053, 11.272897951000061 ],
							[ 5.05845156700002, 11.270487970000033 ],
							[ 5.062467610000056, 11.262456093000026 ],
							[ 5.064876949000052, 11.254424075000031 ],
							[ 5.062467684000069, 11.244786481000062 ],
							[ 5.056845189000057, 11.237557441000035 ],
							[ 5.052829127000052, 11.231131387000062 ],
							[ 5.048813301000052, 11.227918730000056 ],
							[ 5.048813393000046, 11.219083625000053 ],
							[ 5.048813296000048, 11.211052014000074 ],
							[ 5.052829203000044, 11.205430139000043 ],
							[ 5.057648235000045, 11.204625907000036 ],
							[ 5.06246744300006, 11.208642042000065 ],
							[ 5.06648339000003, 11.216675 ],
							[ 5.068892878000042, 11.223099703000059 ],
							[ 5.071302308000043, 11.227115595000043 ],
							[ 5.071302323000054, 11.231934328000023 ],
							[ 5.071302493000076, 11.237557132000063 ],
							[ 5.073711738000043, 11.241573557000038 ],
							[ 5.080137209000043, 11.25522692800007 ],
							[ 5.089775634000034, 11.26486606800006 ],
							[ 5.093791379000038, 11.270487624000054 ],
							[ 5.100217414000042, 11.279322476000061 ],
							[ 5.101020410000046, 11.285748710000064 ],
							[ 5.105839133000075, 11.301009195000063 ],
							[ 5.105839203000073, 11.314662920000046 ],
							[ 5.104233194000074, 11.322694566000052 ],
							[ 5.101823412000044, 11.330726794000043 ],
							[ 5.100217242000042, 11.33875839500007 ],
							[ 5.100217400000076, 11.345987563000051 ],
							[ 5.105036190000021, 11.352412978000075 ],
							[ 5.117887538000048, 11.355625983000039 ],
							[ 5.125919477000025, 11.354019295000057 ],
							[ 5.133951062000051, 11.353215939000052 ],
							[ 5.140376487000026, 11.351610290000053 ],
							[ 5.14439261800004, 11.34920031200005 ],
							[ 5.150817944000039, 11.347594482000034 ],
							[ 5.154251172000045, 11.346612991000029 ],
							[ 5.162062680000076, 11.344381144000067 ],
							[ 5.16848804600005, 11.341971520000072 ],
							[ 5.176519821000056, 11.341168359000051 ],
							[ 5.184551734000024, 11.339562683000054 ],
							[ 5.193386922000059, 11.333939701000077 ],
							[ 5.203025441000023, 11.330726718000051 ],
							[ 5.209450831000026, 11.322694789000025 ],
							[ 5.212663037000027, 11.312253954000028 ],
							[ 5.214269689000048, 11.306631228000072 ],
							[ 5.216678997000031, 11.299402345000033 ],
							[ 5.216678978000061, 11.292977226000062 ],
							[ 5.216679203000069, 11.28896147200004 ],
							[ 5.215876123000044, 11.284945432000029 ],
							[ 5.215875994000044, 11.283617009000068 ],
							[ 5.21560230700004, 11.282859884000061 ],
							[ 5.210543653000059, 11.268859576000068 ],
							[ 5.20906915300003, 11.264943453000058 ],
							[ 5.19590729600003, 11.254618706000031 ],
							[ 5.195117417000063, 11.254000932000054 ],
							[ 5.177758457000039, 11.245928817000049 ],
							[ 5.177337647000058, 11.245645362000062 ],
							[ 5.176839857000061, 11.245310251000035 ],
							[ 5.171583670000075, 11.240944735000028 ],
							[ 5.168262377000076, 11.238186365000047 ],
							[ 5.152906620000067, 11.225430878000054 ],
							[ 5.151682309000023, 11.225881512000058 ],
							[ 5.15024859500005, 11.226409085000057 ],
							[ 5.147389458000021, 11.227568265000059 ],
							[ 5.145596066000053, 11.228549214000054 ],
							[ 5.144623423000041, 11.229146525000033 ],
							[ 5.138870736000058, 11.232588863000046 ],
							[ 5.13843300700006, 11.232851004000054 ],
							[ 5.135165233000066, 11.234512124000048 ],
							[ 5.130419165000035, 11.236793473000034 ],
							[ 5.124728689000051, 11.237370325000029 ],
							[ 5.123199414000055, 11.237434576000055 ],
							[ 5.121781204000058, 11.237494354000034 ],
							[ 5.115994237000052, 11.237433817000067 ],
							[ 5.11298541900004, 11.233572975000072 ],
							[ 5.113639945000045, 11.230365287000041 ],
							[ 5.121876421000024, 11.226345834000028 ],
							[ 5.124089360000028, 11.225282360000051 ],
							[ 5.124774462000062, 11.22495275700004 ],
							[ 5.126845649000074, 11.224005322000039 ],
							[ 5.12737622700007, 11.223836834000053 ],
							[ 5.13018848400003, 11.222944015000053 ],
							[ 5.131390042000021, 11.222562148000065 ],
							[ 5.132144565000033, 11.222322519000045 ],
							[ 5.133826336000027, 11.221720206000043 ],
							[ 5.135452379000071, 11.21859151600006 ],
							[ 5.136265374000061, 11.21697604700006 ],
							[ 5.137718343000074, 11.213170321000064 ],
							[ 5.13952775100006, 11.20843145200007 ],
							[ 5.14904948800006, 11.207668147000049 ],
							[ 5.152926228000069, 11.206801462000044 ],
							[ 5.155857218000051, 11.206509212000071 ],
							[ 5.158801384000071, 11.206215372000031 ],
							[ 5.159767436000038, 11.207067846000029 ],
							[ 5.161339546000022, 11.208454929000027 ],
							[ 5.161724789000061, 11.208794545000046 ],
							[ 5.164820850000069, 11.211526935000052 ],
							[ 5.168285562000051, 11.214577831000042 ],
							[ 5.171072233000075, 11.21703122100007 ],
							[ 5.178013192000037, 11.221741820000034 ],
							[ 5.18046907400003, 11.223395009000058 ],
							[ 5.193756897000071, 11.232340641000064 ],
							[ 5.199836475000041, 11.236432864000051 ],
							[ 5.211907283000073, 11.243886647000068 ],
							[ 5.217092318000027, 11.247088556000051 ],
							[ 5.22165000800004, 11.249870369000064 ],
							[ 5.228534598000067, 11.254072030000032 ],
							[ 5.229342120000069, 11.254567859000076 ],
							[ 5.241240983000068, 11.260728917000051 ],
							[ 5.241170700000055, 11.261680876000071 ],
							[ 5.241012794000028, 11.263824189000047 ],
							[ 5.24060673300005, 11.269328118000033 ],
							[ 5.240513443000054, 11.270595123000021 ],
							[ 5.240470809000044, 11.271171037000045 ],
							[ 5.240372214000047, 11.272508069000025 ],
							[ 5.240155345000062, 11.275447480000025 ],
							[ 5.239904501000069, 11.278849337000054 ],
							[ 5.239751045000048, 11.280808483000044 ],
							[ 5.239415448000045, 11.285093198000027 ],
							[ 5.239353402000063, 11.285885323000059 ],
							[ 5.239295355000024, 11.293202050000048 ],
							[ 5.239170257000069, 11.303304872000069 ],
							[ 5.243881209000051, 11.315993708000065 ],
							[ 5.253456249000067, 11.31867556800006 ],
							[ 5.276943879000044, 11.325253413000041 ],
							[ 5.278213306000055, 11.325608548000048 ],
							[ 5.279043234000028, 11.325841202000049 ],
							[ 5.279614962000039, 11.32635321500004 ],
							[ 5.280061645000046, 11.326753216000043 ],
							[ 5.284597922000046, 11.330815527000027 ],
							[ 5.289277831000049, 11.335006360000023 ],
							[ 5.291764655000065, 11.337226978000047 ],
							[ 5.293351553000036, 11.338644120000026 ],
							[ 5.295007530000021, 11.338878765000061 ],
							[ 5.295636886000068, 11.338968107000028 ],
							[ 5.300650009000037, 11.33967864300007 ],
							[ 5.308813894000025, 11.340841979000061 ],
							[ 5.316238726000051, 11.341899484000066 ],
							[ 5.317324244000076, 11.342054343000029 ],
							[ 5.325734644000022, 11.339248661000056 ],
							[ 5.335983061000036, 11.335763102000044 ],
							[ 5.336900143000037, 11.335451231000036 ],
							[ 5.337157145000049, 11.333136531000036 ],
							[ 5.338763669000059, 11.327514420000057 ],
							[ 5.344385970000076, 11.315466099000048 ],
							[ 5.347599162000051, 11.304222355000036 ],
							[ 5.350811497000052, 11.296190264000074 ],
							[ 5.353221011000073, 11.276913826000055 ],
							[ 5.353221058000031, 11.271290527000076 ],
							[ 5.353221011000073, 11.258439774000067 ],
							[ 5.356433754000022, 11.249605239000061 ],
							[ 5.358843322000041, 11.244786488000045 ],
							[ 5.358040371000072, 11.232738656000038 ],
							[ 5.358040337000034, 11.22711591500007 ],
							[ 5.368481718000055, 11.205430167000031 ],
							[ 5.374104084000066, 11.191775485000051 ],
							[ 5.381332789000055, 11.186153235000063 ],
							[ 5.39418366600006, 11.178924317000053 ],
							[ 5.408640991000027, 11.170892889000072 ],
							[ 5.412379519000069, 11.169491030000074 ],
							[ 5.413972862000037, 11.164919950000069 ],
							[ 5.414631858000064, 11.163029473000051 ],
							[ 5.416711004000035, 11.157064606000063 ],
							[ 5.416971872000033, 11.156316734000029 ],
							[ 5.417137715000024, 11.139571508000074 ],
							[ 5.417144227000051, 11.138913866000053 ],
							[ 5.414220013000033, 11.136263230000054 ],
							[ 5.411922110000035, 11.134180277000041 ],
							[ 5.411053725000045, 11.133392689000061 ],
							[ 5.409529278000036, 11.132010515000047 ],
							[ 5.402718904000039, 11.125836371000048 ],
							[ 5.40126624800007, 11.124519848000034 ],
							[ 5.387065115000041, 11.110334134000027 ],
							[ 5.38703805800003, 11.105480109000041 ],
							[ 5.387074220000045, 11.101858517000039 ],
							[ 5.387244275000057, 11.084827355000073 ],
							[ 5.386179266000056, 11.075677885000061 ],
							[ 5.384337953000056, 11.059585972000036 ],
							[ 5.385055359000035, 11.05885839900003 ],
							[ 5.387528590000045, 11.056349389000047 ],
							[ 5.389621718000058, 11.054728296000064 ],
							[ 5.392956477000041, 11.052145705000044 ],
							[ 5.394654099000036, 11.050830965000046 ],
							[ 5.397920203000069, 11.048301320000064 ],
							[ 5.401537862000055, 11.04549989700007 ],
							[ 5.40278495900003, 11.044533895000029 ],
							[ 5.403434073000028, 11.043994926000039 ],
							[ 5.403991888000064, 11.043780520000041 ],
							[ 5.40514655100003, 11.043539145000068 ],
							[ 5.409898157000043, 11.042544364000037 ],
							[ 5.418115381000064, 11.040823581000041 ],
							[ 5.428761206000047, 11.040979939000067 ],
							[ 5.42872107900007, 11.039973195000073 ],
							[ 5.431130278000069, 11.019090677000065 ],
							[ 5.429990297000074, 11.016810452000072 ],
							[ 5.435949446000052, 11.007042674000047 ],
							[ 5.439965179000069, 10.998207140000034 ],
							[ 5.440724555000031, 10.986064698000064 ],
							[ 5.440509057000043, 10.985444467000036 ],
							[ 5.44478476200004, 10.973308625000072 ],
							[ 5.447809672000062, 10.96695648900004 ],
							[ 5.45281641300005, 10.956441908000045 ],
							[ 5.458438757000067, 10.95322909600003 ],
							[ 5.465667253000049, 10.954032077000022 ],
							[ 5.472896125000034, 10.95644192900005 ],
							[ 5.492172784000047, 10.958851146000029 ],
							[ 5.514661756000066, 10.962063672000056 ],
							[ 5.525906635000069, 10.96206354900005 ],
							[ 5.533938269000032, 10.96126064300006 ],
							[ 5.562852987000042, 10.963670823000029 ],
							[ 5.574900752000076, 10.965276883000058 ],
							[ 5.589358249000043, 10.964473874000021 ],
							[ 5.60943797300007, 10.965276793000044 ],
							[ 5.622288671000035, 10.962063687000068 ],
							[ 5.636746472000027, 10.96206404000003 ],
							[ 5.65682593300005, 10.966882462000058 ],
							[ 5.664054739000051, 10.973308326000051 ],
							[ 5.68011868800005, 10.975718358000051 ],
							[ 5.691362984000023, 10.982143473000065 ],
							[ 5.70421406600002, 10.990175377000071 ],
							[ 5.72108071100007, 10.994995045000053 ],
							[ 5.741963921000035, 11.003830002000029 ],
							[ 5.754814507000049, 11.017483458000072 ],
							[ 5.765256499000031, 11.028728697000076 ],
							[ 5.779713491000052, 11.035154230000046 ],
							[ 5.795777211000029, 11.043989411000041 ],
							[ 5.805415655000047, 11.044792449000056 ],
							[ 5.807427978000021, 11.045851713000047 ],
							[ 5.820676394000031, 11.052824032000046 ],
							[ 5.83111770000005, 11.056036981000034 ],
							[ 5.833341911000048, 11.056167850000065 ],
							[ 5.844771946000037, 11.056839921000062 ],
							[ 5.86324505500005, 11.057643048000045 ],
							[ 5.875292911000031, 11.059250038000073 ],
							[ 5.893766377000077, 11.062462689000029 ],
							[ 5.91143622900006, 11.064871568000058 ],
							[ 5.921074483000041, 11.070494784000061 ],
							[ 5.926696908000054, 11.076919602000032 ],
							[ 5.935531998000044, 11.089770559000044 ],
							[ 5.945170379000047, 11.100212354000064 ],
							[ 5.951595721000047, 11.106637739000064 ],
							[ 5.960174960000074, 11.117856944000039 ],
							[ 5.962036983000075, 11.12029169300007 ],
							[ 5.974084851000043, 11.136355366000032 ],
							[ 5.985329603000025, 11.144387401000074 ],
							[ 5.993361586000049, 11.145994019000057 ],
							[ 6.000590183000043, 11.143584452000027 ],
							[ 6.006212368000035, 11.141978265000034 ],
							[ 6.008521716000075, 11.141016 ],
							[ 6.015850398000055, 11.137962247000075 ],
							[ 6.021472864000032, 11.125914480000063 ],
							[ 6.026292321000028, 11.113866979000022 ],
							[ 6.026292390000037, 11.101015175000043 ],
							[ 6.031111139000075, 11.084148375000041 ],
							[ 6.03271778800007, 11.075313825000023 ],
							[ 6.039946076000035, 11.066479001000062 ],
							[ 6.046371976000046, 11.054431025000042 ],
							[ 6.052797202000022, 11.043186414000047 ],
							[ 6.064845209000055, 11.03194159700007 ],
							[ 6.076089997000054, 11.025515504000055 ],
							[ 6.094562859000064, 11.012664764000021 ],
							[ 6.106610655000054, 11.007845943000063 ],
							[ 6.114642674000038, 11.001420150000058 ],
							[ 6.119461974000046, 10.995798165000053 ],
							[ 6.131509705000042, 10.97893070300006 ],
							[ 6.13472220400007, 10.97170267000007 ],
							[ 6.140344710000022, 10.963670476000061 ],
							[ 6.14596685500004, 10.957244638000077 ],
							[ 6.148376348000056, 10.950015926000049 ],
							[ 6.149983039000062, 10.939574982000067 ],
							[ 6.152392404000068, 10.929936332000068 ],
							[ 6.159621347000041, 10.925920654000038 ],
							[ 6.161227587000042, 10.917085501000031 ],
							[ 6.160424353000053, 10.909054006000076 ],
							[ 6.160424154000054, 10.902627758000051 ],
							[ 6.163637145000052, 10.890580172000057 ],
							[ 6.164440167000066, 10.881745616000046 ],
							[ 6.164440178000063, 10.872910558000058 ],
							[ 6.162834301000032, 10.868894363000038 ],
							[ 6.161300640000036, 10.866134800000054 ],
							[ 6.160006572000043, 10.863805921000051 ],
							[ 6.158817689000045, 10.86166546000004 ],
							[ 6.154562478000059, 10.855394147000027 ],
							[ 6.149982949000048, 10.852830934000053 ],
							[ 6.14355766400007, 10.85283062700006 ],
							[ 6.140905739000061, 10.852498841000056 ],
							[ 6.137132159000032, 10.852026710000075 ],
							[ 6.122674327000027, 10.84479910400006 ],
							[ 6.118658499000048, 10.837570326000048 ],
							[ 6.121067996000022, 10.835160487000053 ],
							[ 6.125887406000061, 10.833554163000031 ],
							[ 6.130255702000056, 10.828456818000063 ],
							[ 6.13109840900006, 10.824039584000047 ],
							[ 6.129099795000059, 10.82150623900003 ],
							[ 6.125083945000029, 10.818293329000028 ],
							[ 6.121067975000074, 10.814277429000072 ],
							[ 6.115446015000032, 10.813474853000059 ],
							[ 6.10821715700007, 10.813474448000022 ],
							[ 6.107414118000065, 10.80945858900003 ],
							[ 6.110626794000041, 10.803835991000028 ],
							[ 6.110626614000068, 10.801137889000074 ],
							[ 6.110626750000051, 10.796606749000034 ],
							[ 6.108217257000035, 10.790984929000047 ],
							[ 6.105807710000022, 10.78455901500007 ],
							[ 6.10368210300004, 10.779729880000048 ],
							[ 6.10179172900007, 10.775724143000048 ],
							[ 6.099381809000022, 10.770905754000069 ],
							[ 6.102594984000064, 10.764479717000029 ],
							[ 6.106454970000073, 10.761171558000058 ],
							[ 6.108217330000059, 10.759660818000043 ],
							[ 6.10913086100004, 10.753007687000036 ],
							[ 6.109823732000052, 10.747612890000028 ],
							[ 6.110931392000055, 10.744843745000026 ],
							[ 6.113036169000054, 10.739580872000033 ],
							[ 6.110244120000061, 10.735858020000023 ],
							[ 6.108217209000031, 10.733155387000068 ],
							[ 6.105807732000073, 10.725123568000072 ],
							[ 6.103397796000024, 10.718698733000053 ],
							[ 6.097775793000039, 10.715485358000024 ],
							[ 6.091350126000066, 10.708256542000072 ],
							[ 6.091350055000021, 10.70022487600005 ],
							[ 6.095365948000051, 10.697814799000071 ],
							[ 6.104201366000041, 10.699422046000052 ],
							[ 6.11143026000002, 10.695406131000027 ],
							[ 6.113839596000048, 10.689782856000022 ],
							[ 6.118658484000036, 10.684161370000027 ],
							[ 6.132229692000067, 10.678991345000043 ],
							[ 6.135525733000065, 10.677735303000077 ],
							[ 6.137132262000023, 10.669703626000057 ],
							[ 6.133919220000053, 10.661671395000042 ],
							[ 6.132163891000062, 10.659624175000033 ],
							[ 6.129099707000023, 10.656049647000032 ],
							[ 6.125084040000047, 10.649623771000051 ],
							[ 6.12465181500005, 10.646166811000057 ],
							[ 6.12428086400007, 10.643199084000059 ],
							[ 6.123667728000044, 10.640440259000059 ],
							[ 6.122674536000034, 10.635970293000071 ],
							[ 6.120264901000041, 10.62713500600006 ],
							[ 6.113036136000062, 10.612677721000068 ],
							[ 6.112233093000043, 10.605448926000065 ],
							[ 6.114642523000043, 10.599022663000028 ],
							[ 6.127493833000074, 10.59340080100003 ],
							[ 6.129099784000061, 10.580550306000021 ],
							[ 6.127493763000075, 10.569305654000061 ],
							[ 6.123478029000069, 10.562076779000051 ],
							[ 6.121068024000067, 10.55324145000003 ],
							[ 6.11464260200006, 10.549225868000065 ],
							[ 6.107414252000069, 10.546816002000071 ],
							[ 6.098578967000037, 10.546012875000031 ],
							[ 6.092956500000071, 10.546012846000053 ],
							[ 6.090547165000032, 10.53798082000003 ],
							[ 6.091350207000062, 10.522720359000061 ],
							[ 6.086531256000058, 10.515491537000059 ],
							[ 6.090547183000069, 10.480954973000053 ],
							[ 6.089743977000069, 10.475332521000041 ],
							[ 6.094739510000068, 10.473866431000033 ],
							[ 6.096478309000076, 10.470743993000042 ],
							[ 6.100185272000033, 10.464087241000072 ],
							[ 6.105004190000045, 10.456859395000038 ],
							[ 6.112777773000062, 10.459451009000077 ],
							[ 6.117052172000058, 10.460875496000028 ],
							[ 6.129734392000046, 10.46354506800003 ],
							[ 6.132312709000075, 10.464087735000021 ],
							[ 6.140344723000055, 10.458465347000072 ],
							[ 6.147385021000048, 10.452065530000027 ],
							[ 6.14918005800007, 10.450433903000032 ],
							[ 6.150339006000024, 10.443093074000046 ],
							[ 6.151589258000058, 10.435173189000068 ],
							[ 6.152519715000039, 10.428196169000046 ],
							[ 6.153195918000051, 10.423125424000034 ],
							[ 6.154097138000054, 10.409971483000049 ],
							[ 6.155260557000076, 10.407198623000056 ],
							[ 6.158014683000033, 10.40063566300006 ],
							[ 6.163934361000031, 10.391074437000043 ],
							[ 6.167398323000043, 10.385478025000054 ],
							[ 6.168456196000022, 10.383768891000045 ],
							[ 6.171065355000053, 10.384023902000024 ],
							[ 6.19083061300006, 10.385952126000063 ],
							[ 6.201386939000031, 10.386981963000039 ],
							[ 6.215008691000037, 10.393771104000052 ],
							[ 6.216883056000029, 10.394705687000055 ],
							[ 6.226480382000034, 10.410119157000054 ],
							[ 6.228695259000062, 10.417503590000024 ],
							[ 6.23913682400007, 10.420766855000068 ],
							[ 6.241546092000021, 10.421519399000033 ],
							[ 6.249577905000024, 10.421519265000029 ],
							[ 6.250625598000056, 10.425708517000032 ],
							[ 6.251211096000077, 10.428051074000052 ],
							[ 6.252790838000067, 10.434370006000051 ],
							[ 6.254397272000062, 10.44320478800006 ],
							[ 6.261819008000032, 10.454674544000056 ],
							[ 6.263232120000055, 10.456859331000032 ],
							[ 6.27791208900004, 10.461140521000061 ],
							[ 6.282508983000071, 10.462481291000074 ],
							[ 6.285515756000052, 10.463984359000051 ],
							[ 6.298572539000077, 10.47051306700007 ],
							[ 6.300349797000024, 10.474600666000072 ],
							[ 6.303569812000035, 10.482007192000026 ],
							[ 6.306604365000055, 10.488986844000067 ],
							[ 6.311894281000036, 10.493394874000046 ],
							[ 6.325881092000031, 10.505050466000057 ],
							[ 6.344614645000036, 10.516631084000039 ],
							[ 6.365288311000029, 10.529411166000045 ],
							[ 6.37005608000004, 10.532359050000025 ],
							[ 6.374429677000023, 10.537256915000057 ],
							[ 6.387966243000051, 10.552417583000022 ],
							[ 6.390135826000062, 10.554847923000068 ],
							[ 6.39091914200003, 10.557001784000022 ],
							[ 6.392539576000047, 10.561457913000027 ],
							[ 6.394822675000057, 10.567735918000039 ],
							[ 6.396561666000025, 10.572518327000068 ],
							[ 6.401724677000061, 10.573475059000032 ],
							[ 6.418247611000027, 10.57653455600007 ],
							[ 6.427082671000051, 10.585369066000055 ],
							[ 6.429186759000061, 10.586631875000023 ],
							[ 6.433216059000074, 10.589049435000049 ],
							[ 6.439130272000057, 10.592598225000074 ],
							[ 6.450375084000029, 10.595007226000064 ],
							[ 6.457603557000027, 10.598220076000075 ],
							[ 6.469135646000041, 10.602969224000049 ],
							[ 6.47125782300003, 10.603842604000022 ],
							[ 6.474883626000064, 10.606149579000032 ],
							[ 6.480092894000052, 10.60946462000004 ],
							[ 6.482116275000067, 10.610179050000056 ],
							[ 6.493747215000042, 10.614283362000037 ],
							[ 6.504096587000049, 10.61040298100005 ],
							[ 6.506597855000052, 10.609464887000058 ],
							[ 6.511785471000053, 10.606582418000073 ],
							[ 6.513826710000046, 10.605448510000031 ],
							[ 6.519689001000074, 10.59958640800005 ],
							[ 6.524268138000025, 10.595006787000045 ],
							[ 6.521845441000039, 10.585315760000071 ],
							[ 6.521055607000051, 10.582156353000073 ],
							[ 6.521593199000051, 10.572834881000063 ],
							[ 6.525071568000044, 10.564486328000044 ],
							[ 6.532300005000025, 10.553241975000049 ],
							[ 6.534732701000053, 10.550808667000069 ],
							[ 6.540331802000026, 10.545210062000024 ],
							[ 6.549167113000067, 10.545209876000058 ],
							[ 6.551589435000039, 10.545555905000072 ],
							[ 6.556100724000032, 10.54694070100004 ],
							[ 6.561214903000064, 10.550832028000059 ],
							[ 6.566545613000073, 10.553497431000039 ],
							[ 6.569400863000055, 10.554924854000035 ],
							[ 6.574065626000049, 10.557257476000075 ],
							[ 6.57778451300004, 10.562629471000037 ],
							[ 6.581294447000062, 10.567699461000075 ],
							[ 6.583181918000037, 10.569856727000058 ],
							[ 6.586916975000065, 10.574124626000071 ],
							[ 6.597023572000069, 10.577156286000047 ],
							[ 6.602980539000043, 10.578943173000027 ],
							[ 6.606240290000073, 10.580107911000027 ],
							[ 6.61077978000003, 10.581728975000033 ],
							[ 6.614225378000071, 10.582959276000054 ],
							[ 6.618111733000035, 10.584188418000053 ],
							[ 6.624107905000074, 10.586084252000035 ],
							[ 6.630505478000032, 10.588106871000036 ],
							[ 6.636498864000032, 10.590010412000026 ],
							[ 6.647069932000022, 10.593429601000025 ],
							[ 6.653288973000031, 10.59280229500007 ],
							[ 6.668568526000058, 10.591261855000027 ],
							[ 6.671233089000054, 10.590868962000059 ],
							[ 6.677781503000062, 10.589863044000026 ],
							[ 6.680889584000056, 10.589385265000033 ],
							[ 6.686180666000041, 10.589942166000071 ],
							[ 6.696150308000028, 10.590990902000044 ],
							[ 6.70048803100002, 10.589019846000042 ],
							[ 6.705233019000048, 10.588267236000036 ],
							[ 6.709845606000044, 10.587535798000033 ],
							[ 6.725868303000027, 10.589385050000033 ],
							[ 6.729305230000023, 10.593804492000061 ],
							[ 6.731490689000054, 10.596613752000053 ],
							[ 6.729884045000063, 10.603038690000062 ],
							[ 6.726671357000043, 10.608661577000021 ],
							[ 6.72507181900005, 10.614526014000035 ],
							[ 6.724261643000034, 10.617496576000065 ],
							[ 6.719442976000039, 10.624725560000059 ],
							[ 6.717490596000061, 10.628071620000071 ],
							[ 6.713820525000074, 10.634363406000034 ],
							[ 6.715897641000026, 10.638517455000056 ],
							[ 6.71703340800002, 10.640789182000049 ],
							[ 6.722655276000069, 10.645608047000053 ],
							[ 6.726885328000037, 10.647723022000037 ],
							[ 6.729081260000044, 10.648820815000022 ],
							[ 6.734772242000076, 10.648251630000061 ],
							[ 6.742323799000076, 10.647497052000062 ],
							[ 6.744777227000043, 10.647252077000076 ],
							[ 6.75004967600006, 10.646634339000059 ],
							[ 6.758799212000042, 10.645608134000042 ],
							[ 6.765357437000034, 10.64364071600005 ],
							[ 6.774862823000035, 10.640789065000035 ],
							[ 6.78228365800004, 10.639647543000024 ],
							[ 6.785303972000065, 10.639183064000065 ],
							[ 6.794942466000066, 10.640788796000038 ],
							[ 6.799229618000027, 10.642075476000059 ],
							[ 6.80297415800004, 10.643198987000062 ],
							[ 6.814443535000066, 10.639150479000023 ],
							[ 6.816628363000063, 10.638379067000074 ],
							[ 6.819104613000036, 10.635748109000076 ],
							[ 6.823499252000033, 10.631078969000043 ],
							[ 6.829479280000044, 10.624725070000068 ],
							[ 6.830089947000033, 10.614956152000047 ],
							[ 6.830282647000047, 10.61187437500007 ],
							[ 6.829791466000074, 10.604509471000028 ],
							[ 6.829479311000057, 10.599826772000029 ],
							[ 6.835101706000046, 10.595811113000025 ],
							[ 6.836068683000065, 10.593016384000066 ],
							[ 6.838716064000039, 10.585369210000067 ],
							[ 6.841384799000025, 10.574693477000039 ],
							[ 6.841929004000065, 10.572518387000059 ],
							[ 6.841652919000069, 10.567273119000049 ],
							[ 6.841125379000061, 10.55725792100003 ],
							[ 6.839519077000034, 10.549225961000047 ],
							[ 6.835503126000049, 10.537981158000036 ],
							[ 6.839519050000035, 10.531555130000072 ],
							[ 6.844338440000058, 10.52834321000006 ],
							[ 6.84915723000006, 10.53075235700004 ],
							[ 6.856386124000039, 10.537981157000047 ],
							[ 6.861205187000053, 10.550831724000034 ],
							[ 6.863134976000026, 10.551937932000044 ],
							[ 6.869102364000071, 10.555356963000065 ],
							[ 6.874302787000033, 10.553264524000042 ],
							[ 6.882088055000054, 10.550831954000046 ],
							[ 6.889316960000031, 10.54681559900007 ],
							[ 6.895732875000022, 10.54438228500004 ],
							[ 6.900235077000048, 10.542674267000052 ],
							[ 6.905256696000038, 10.540769664000038 ],
							[ 6.912609157000077, 10.537980858000026 ],
							[ 6.914323481000054, 10.536052956000049 ],
							[ 6.91903486800004, 10.530752163000045 ],
							[ 6.922659993000025, 10.524226984000052 ],
							[ 6.927066894000063, 10.516295201000048 ],
							[ 6.929167636000045, 10.512475896000069 ],
							[ 6.935901542000067, 10.500231546000066 ],
							[ 6.947949378000033, 10.491396136000049 ],
							[ 6.953519878000066, 10.482178771000065 ],
							[ 6.954133020000029, 10.468047366000064 ],
							[ 6.954374871000027, 10.462481252000032 ],
							[ 6.950424188000056, 10.456555169000069 ],
							[ 6.949005214000067, 10.454426658000045 ],
							[ 6.946342825000045, 10.450433920000023 ],
							[ 6.942557211000064, 10.442484085000046 ],
							[ 6.938311030000023, 10.433567068000059 ],
							[ 6.937668847000054, 10.431425893000039 ],
							[ 6.933492294000075, 10.417503407000027 ],
							[ 6.928428174000032, 10.403036254000028 ],
							[ 6.923050901000067, 10.382162941000047 ],
							[ 6.924767972000041, 10.375723575000052 ],
							[ 6.926263300000073, 10.370115313000042 ],
							[ 6.930211031000056, 10.365772340000035 ],
							[ 6.934295128000031, 10.36127923600003 ],
							[ 6.940587152000035, 10.360930617000065 ],
							[ 6.948752816000024, 10.360476463000055 ],
							[ 6.951178683000023, 10.360799558000053 ],
							[ 6.960800605000031, 10.362083384000073 ],
							[ 6.965060572000027, 10.362796765000041 ],
							[ 6.977213752000068, 10.364829777000068 ],
							[ 6.986279422000052, 10.361456114000021 ],
							[ 6.988912081000024, 10.360476485000049 ],
							[ 6.991198531000066, 10.359332797000036 ],
							[ 6.996944079000059, 10.356460685000059 ],
							[ 7.000393976000055, 10.356549959000063 ],
							[ 7.003381251000064, 10.356627689000049 ],
							[ 7.006392887000061, 10.35616701400005 ],
							[ 7.020236529000044, 10.354051728000059 ],
							[ 7.024765029000037, 10.350588964000053 ],
							[ 7.029107992000036, 10.347266986000022 ],
							[ 7.033890841000073, 10.343609957000069 ],
							[ 7.043528589000061, 10.326743252000028 ],
							[ 7.046628505000058, 10.322609853000074 ],
							[ 7.05075748400003, 10.317104237000024 ],
							[ 7.049954352000043, 10.310679268000058 ],
							[ 7.049150859000065, 10.301040801000056 ],
							[ 7.045938436000029, 10.291402611000024 ],
							[ 7.040316183000073, 10.28899285600005 ],
							[ 7.032284124000057, 10.28738671800005 ],
							[ 7.025858930000027, 10.28015817000005 ],
							[ 7.019433029000027, 10.273732460000076 ],
							[ 7.017127849000076, 10.266047739000044 ],
							[ 7.009794768000063, 10.250439862000064 ],
							[ 7.009129058000042, 10.247109200000068 ],
							[ 7.008188654000037, 10.242407938000042 ],
							[ 7.003369232000068, 10.241604998000071 ],
							[ 7.002895868000053, 10.244918785000038 ],
							[ 7.002566400000035, 10.247227530000032 ],
							[ 6.996140997000055, 10.244817846000046 ],
							[ 6.990495117000023, 10.240836898000055 ],
							[ 6.987305708000065, 10.236786152000036 ],
							[ 6.984888955000031, 10.236814251000055 ],
							[ 6.984092834000023, 10.239998554000067 ],
							[ 6.982486818000041, 10.244014710000044 ],
							[ 6.978470898000069, 10.23999894800005 ],
							[ 6.970438852000029, 10.232770233000053 ],
							[ 6.967225920000033, 10.222328359000073 ],
							[ 6.965619530000026, 10.215099173000056 ],
							[ 6.960800678000055, 10.207067429000062 ],
							[ 6.959194310000044, 10.202248760000032 ],
							[ 6.954472460000034, 10.191998453000053 ],
							[ 6.947146328000031, 10.185381670000027 ],
							[ 6.942326900000069, 10.180563165000024 ],
							[ 6.93991752200003, 10.180562753000061 ],
							[ 6.936705025000037, 10.180562939000026 ],
							[ 6.928672646000052, 10.180562692000024 ],
							[ 6.927066804000049, 10.186185107000028 ],
							[ 6.923635151000042, 10.189126212000076 ],
							[ 6.92144434000005, 10.191004056000054 ],
							[ 6.915019043000029, 10.188594632000047 ],
							[ 6.911003200000039, 10.17895629700007 ],
							[ 6.905458976000034, 10.17094796400005 ],
							[ 6.903774388000045, 10.168515418000027 ],
							[ 6.894938990000071, 10.158073156000057 ],
							[ 6.888347751000026, 10.153129713000055 ],
							[ 6.885301151000021, 10.150844707000033 ],
							[ 6.882088149000026, 10.117111173000069 ],
							[ 6.89252940800003, 10.093015957000034 ],
							[ 6.901178894000054, 10.076381575000028 ],
							[ 6.904328443000054, 10.077835822000054 ],
							[ 6.906362923000074, 10.077836051000077 ],
							[ 6.914422137000031, 10.07783581800004 ],
							[ 6.926198113000055, 10.067742149000026 ],
							[ 6.940497515000061, 10.061013007000042 ],
							[ 6.952273235000064, 10.04755521800007 ],
							[ 6.962367086000029, 10.046713649000026 ],
							[ 6.983395558000041, 10.045872847000055 ],
							[ 7.007788652000045, 10.045031456000061 ],
							[ 7.018723528000066, 10.038302630000032 ],
							[ 7.033863988000064, 10.04419063000006 ],
							[ 7.064986747000034, 10.042508173000044 ],
							[ 7.085174087000041, 10.036620159000051 ],
							[ 7.091903239000032, 10.032414685000049 ],
							[ 7.100314562000051, 10.029890904000069 ],
							[ 7.103679189000047, 10.031573420000029 ],
							[ 7.105361603000063, 10.026526391000061 ],
							[ 7.112090695000063, 10.026526352000076 ],
							[ 7.123025325000071, 10.029891048000025 ],
							[ 7.125548860000038, 10.034096632000058 ],
							[ 7.138165964000052, 10.035778719000064 ],
							[ 7.149941908000073, 10.034937684000056 ],
							[ 7.156671163000055, 10.034096573000056 ],
							[ 7.166764693000061, 10.033255648000022 ],
							[ 7.179381724000052, 10.027367827000035 ],
							[ 7.188634385000057, 10.027367515000037 ],
							[ 7.19368120200005, 10.029890911000052 ],
							[ 7.200410505000036, 10.033255356000041 ],
							[ 7.207139522000034, 10.041666838000026 ],
							[ 7.213868607000052, 10.039984428000025 ],
							[ 7.21601591700005, 10.037407915000074 ],
							[ 7.222132219000059, 10.037259952000056 ],
							[ 7.22668778700006, 10.038840268000058 ],
							[ 7.229088267000066, 10.038420626000061 ],
							[ 7.232373590000066, 10.043349024000065 ],
							[ 7.240785109000058, 10.03998462900006 ],
							[ 7.250727291000032, 10.039417007000054 ],
							[ 7.254066906000048, 10.039226540000072 ],
							[ 7.258449077000023, 10.03662020400003 ],
							[ 7.264654556000039, 10.032187641000064 ],
							[ 7.27022491200006, 10.028208551000034 ],
							[ 7.273243356000023, 10.023513972000046 ],
							[ 7.277795293000054, 10.016432903000066 ],
							[ 7.278636526000071, 10.010544877000029 ],
							[ 7.279907316000049, 10.002283882000029 ],
							[ 7.280318622000038, 9.999610203000032 ],
							[ 7.281084150000027, 9.993737071000055 ],
							[ 7.28031880900005, 9.989039516000048 ],
							[ 7.280318787000056, 9.983628427000042 ],
							[ 7.280318695000062, 9.973534649000044 ],
							[ 7.280541905000064, 9.971077635000029 ],
							[ 7.281075402000056, 9.96499435000004 ],
							[ 7.28313683500005, 9.960546672000021 ],
							[ 7.284524447000024, 9.957553186000041 ],
							[ 7.286206775000039, 9.949982854000041 ],
							[ 7.285765228000059, 9.941148811000062 ],
							[ 7.28546665500005, 9.935183689000041 ],
							[ 7.285365601000024, 9.933159719000059 ],
							[ 7.287137613000027, 9.926072357000066 ],
							[ 7.287889055000051, 9.923066294000023 ],
							[ 7.29377706300005, 9.907925366000029 ],
							[ 7.297982704000049, 9.898673109000072 ],
							[ 7.295459202000075, 9.887738127000034 ],
							[ 7.295459310000069, 9.880002889000025 ],
							[ 7.295459258000051, 9.871756803000039 ],
							[ 7.296500124000033, 9.869848283000067 ],
							[ 7.300506191000068, 9.862503860000061 ],
							[ 7.299664925000059, 9.856615902000044 ],
							[ 7.294904615000064, 9.853045334000058 ],
							[ 7.292935923000073, 9.851568186000065 ],
							[ 7.28873008100004, 9.840633257000036 ],
							[ 7.291084263000073, 9.82886297500005 ],
							[ 7.292094779000024, 9.823810816000048 ],
							[ 7.292935904000046, 9.811193264000053 ],
							[ 7.291253644000051, 9.800258672000041 ],
							[ 7.285365548000073, 9.789323811000031 ],
							[ 7.278636577000043, 9.786800544000073 ],
							[ 7.27358971700005, 9.783435729000075 ],
							[ 7.267701572000021, 9.783435853000071 ],
							[ 7.260131267000077, 9.786800327000037 ],
							[ 7.256766769000023, 9.790164735000076 ],
							[ 7.251720078000062, 9.794370482000033 ],
							[ 7.231532464000054, 9.791006028000027 ],
							[ 7.223962370000038, 9.787641430000065 ],
							[ 7.220837224000036, 9.785883712000043 ],
							[ 7.21490529600004, 9.782547160000036 ],
							[ 7.210504188000073, 9.780071197000041 ],
							[ 7.207546731000036, 9.768900041000052 ],
							[ 7.206725077000044, 9.76579662000006 ],
							[ 7.204739963000065, 9.758295778000047 ],
							[ 7.214709653000057, 9.748949157000027 ],
							[ 7.224803606000023, 9.738855262000072 ],
							[ 7.24499086700007, 9.732126161000053 ],
							[ 7.268494218000058, 9.732126314000027 ],
							[ 7.271066161000022, 9.732126490000041 ],
							[ 7.280318653000052, 9.724555848000023 ],
							[ 7.283289567000054, 9.718020437000064 ],
							[ 7.286068040000032, 9.711908485000038 ],
							[ 7.288730121000071, 9.706050832000074 ],
							[ 7.28669562500005, 9.701727042000073 ],
							[ 7.283833511000068, 9.695645410000054 ],
							[ 7.282001093000076, 9.691751426000053 ],
							[ 7.282842045000052, 9.684181045000059 ],
							[ 7.290412314000037, 9.68165805700005 ],
							[ 7.294681424000032, 9.679095952000068 ],
							[ 7.29937591700002, 9.676279785000077 ],
							[ 7.30407232500005, 9.673461863000057 ],
							[ 7.311441050000042, 9.669040565000046 ],
							[ 7.314399359000049, 9.664391618000025 ],
							[ 7.317328889000066, 9.659788308000032 ],
							[ 7.322563041000024, 9.654205408000053 ],
							[ 7.326943032000031, 9.649533444000042 ],
							[ 7.338730866000049, 9.636959980000029 ],
							[ 7.339474078000023, 9.635407680000071 ],
							[ 7.341113760000042, 9.63198331600006 ],
							[ 7.342154663000031, 9.629675134000024 ],
							[ 7.343013904000031, 9.62534880100003 ],
							[ 7.343521591000069, 9.622648876000028 ],
							[ 7.341392935000044, 9.618378052000025 ],
							[ 7.337062210000056, 9.614027830000055 ],
							[ 7.333666612000059, 9.606661977000044 ],
							[ 7.32510893500006, 9.602697102000036 ],
							[ 7.315817244000073, 9.601408278000065 ],
							[ 7.315110133000076, 9.601311822000071 ],
							[ 7.311447502000021, 9.600044008000054 ],
							[ 7.294064887000047, 9.594096906000061 ],
							[ 7.293667567000057, 9.59299711500006 ],
							[ 7.293227877000049, 9.591217349000033 ],
							[ 7.292681496000057, 9.588685521000059 ],
							[ 7.290026007000051, 9.576329077000025 ],
							[ 7.287310794000064, 9.563692596000067 ],
							[ 7.285692687000051, 9.556162180000058 ],
							[ 7.285575361000042, 9.555615193000051 ],
							[ 7.285392552000076, 9.555124988000046 ],
							[ 7.285171304000073, 9.554209094000043 ],
							[ 7.28422706300006, 9.553142912000055 ],
							[ 7.283338933000039, 9.552140297000051 ],
							[ 7.276942801000075, 9.544919281000034 ],
							[ 7.272693008000033, 9.540121092000049 ],
							[ 7.267756431000066, 9.534546620000071 ],
							[ 7.263447678000034, 9.529681940000046 ],
							[ 7.260972565000031, 9.52688782000007 ],
							[ 7.253884663000065, 9.524126726000077 ],
							[ 7.251441133000071, 9.523193499000058 ],
							[ 7.246541920000027, 9.521320197000023 ],
							[ 7.244192248000047, 9.520421845000044 ],
							[ 7.239102943000034, 9.518476343000032 ],
							[ 7.23405600600006, 9.509223871000074 ],
							[ 7.227326797000046, 9.494924409000021 ],
							[ 7.222775498000033, 9.495494173000054 ],
							[ 7.22059772700004, 9.495765655000071 ],
							[ 7.215551024000035, 9.503336027000046 ],
							[ 7.211304339000037, 9.500891836000051 ],
							[ 7.212387204000038, 9.492226439000035 ],
							[ 7.213084354000046, 9.487695586000029 ],
							[ 7.213470579000045, 9.485185652000041 ],
							[ 7.214905670000064, 9.481836561000023 ],
							[ 7.217339960000061, 9.476157374000024 ],
							[ 7.218344655000067, 9.473813163000045 ],
							[ 7.219436276000067, 9.471332622000034 ],
							[ 7.221334418000026, 9.467018314000029 ],
							[ 7.22430218300002, 9.460272514000053 ],
							[ 7.22281597500006, 9.453436872000054 ],
							[ 7.221594366000033, 9.447816876000047 ],
							[ 7.227951020000035, 9.44357879100005 ],
							[ 7.23784146600002, 9.436985270000037 ],
							[ 7.241091162000032, 9.437526865000052 ],
							[ 7.247048740000025, 9.439150991000076 ],
							[ 7.254630921000057, 9.441317300000037 ],
							[ 7.257818719000056, 9.440646334000064 ],
							[ 7.261124238000036, 9.439949916000046 ],
							[ 7.264921067000046, 9.439150676000054 ],
							[ 7.267395581000073, 9.439563500000077 ],
							[ 7.274669518000053, 9.440775766000058 ],
							[ 7.276604126000052, 9.438626183000054 ],
							[ 7.279543919000048, 9.435360145000061 ],
							[ 7.285032356000045, 9.429871598000034 ],
							[ 7.289505812000073, 9.42543721800007 ],
							[ 7.289763313000037, 9.412825718000022 ],
							[ 7.289833968000039, 9.409363753000036 ],
							[ 7.284920750000026, 9.405854388000023 ],
							[ 7.282251946000031, 9.403947809000044 ],
							[ 7.28038540600005, 9.402162494000038 ],
							[ 7.275750051000045, 9.397728684000072 ],
							[ 7.26979534700007, 9.392033568000045 ],
							[ 7.265466771000035, 9.386838749000049 ],
							[ 7.259282667000036, 9.379418303000023 ],
							[ 7.256255540000041, 9.375785765000046 ],
							[ 7.255014490000065, 9.365856413000074 ],
							[ 7.254630977000033, 9.362788443000056 ],
							[ 7.257774390000066, 9.35935852700004 ],
							[ 7.260588101000053, 9.356288681000024 ],
							[ 7.262890080000034, 9.354562445000056 ],
							[ 7.264964643000042, 9.353006333000053 ],
							[ 7.267087466000021, 9.351414864000049 ],
							[ 7.270301901000039, 9.349405225000055 ],
							[ 7.273269239000058, 9.349850858000025 ],
							[ 7.280085145000044, 9.350873277000062 ],
							[ 7.282647076000046, 9.35193627600006 ],
							[ 7.290733868000075, 9.355293135000068 ],
							[ 7.298315654000021, 9.358440673000075 ],
							[ 7.308789235000063, 9.362787982000043 ],
							[ 7.31496326000007, 9.364677619000076 ],
							[ 7.321242254000026, 9.366598146000058 ],
							[ 7.328394818000049, 9.363837950000061 ],
							[ 7.335326546000033, 9.361163094000062 ],
							[ 7.343762326000046, 9.363171488000035 ],
							[ 7.346700243000043, 9.363870688000077 ],
							[ 7.350243105000061, 9.364686132000031 ],
							[ 7.360388661000059, 9.367021642000054 ],
							[ 7.376487386000065, 9.363329893000071 ],
							[ 7.38816844400003, 9.349822979000066 ],
							[ 7.249756903000048, 9.155721869000047 ],
							[ 7.246386007000069, 9.15097885800003 ],
							[ 7.233965957000066, 9.133502264000072 ],
							[ 7.220021742000029, 9.113881250000077 ],
							[ 7.220556740000063, 9.113665701000059 ],
							[ 7.222413302000064, 9.112879569000029 ],
							[ 7.221936802000073, 9.110494277000043 ],
							[ 7.221700639000062, 9.110044452000068 ],
							[ 7.218979095000066, 9.101579750000042 ],
							[ 7.211495434000028, 9.104867418000026 ],
							[ 7.175747963000049, 9.131997177000073 ],
							[ 7.156177129000071, 9.146846621000066 ],
							[ 7.045871656000031, 9.230500929000073 ],
							[ 7.043642942000076, 9.23215388400007 ],
							[ 7.031083072000058, 9.229907511000022 ],
							[ 7.009547223000027, 9.227017903000046 ],
							[ 6.986802020000027, 9.2263 ],
							[ 6.961635948000037, 9.226033546000053 ],
							[ 6.953373078000027, 9.225987801000031 ],
							[ 6.800290570000072, 9.22510681600005 ],
							[ 6.795526474000042, 9.112392936000049 ],
							[ 6.782253395000055, 8.798002102000055 ],
							[ 6.780764973000032, 8.794405824000023 ],
							[ 6.780172040000025, 8.771520431000056 ],
							[ 6.778653202000044, 8.740360738000049 ],
							[ 6.779550879000055, 8.593106033000026 ],
							[ 6.780107920000034, 8.592872327000066 ],
							[ 6.778522372000054, 8.457550121000054 ],
							[ 6.778666576000035, 8.453830541000059 ],
							[ 6.827644088000056, 8.453450603000022 ],
							[ 6.825058411000043, 8.433941292000043 ],
							[ 6.824781428000051, 8.432681982000076 ],
							[ 6.832135315000073, 8.42532738500006 ],
							[ 6.832135109000035, 8.417054047000022 ],
							[ 6.824781385000051, 8.40418528500004 ],
							[ 6.83305450000006, 8.401427409000064 ],
							[ 6.837650704000055, 8.396831478000024 ],
							[ 6.836731508000071, 8.391315592000069 ],
							[ 6.832135203000064, 8.386719843000037 ],
							[ 6.827538988000072, 8.379364818000056 ],
							[ 6.826619601000061, 8.37476920000006 ],
							[ 6.822942874000034, 8.367415319000031 ],
							[ 6.814669618000039, 8.356384295000055 ],
							[ 6.810992652000039, 8.351787505000061 ],
							[ 6.807315304000042, 8.342595105000044 ],
							[ 6.801800136000054, 8.338918584000055 ],
							[ 6.790769176000026, 8.330645537000066 ],
							[ 6.779738087000055, 8.331565006000062 ],
							[ 6.772384208000062, 8.335241334000045 ],
							[ 6.770545369000047, 8.330645642000036 ],
							[ 6.769626628000026, 8.317775631000075 ],
							[ 6.766868595000062, 8.308583282000029 ],
							[ 6.760434184000076, 8.305825372000072 ],
							[ 6.751241307000043, 8.30490579800005 ],
							[ 6.749402887000031, 8.299390836000043 ],
							[ 6.761353038000038, 8.292956335000042 ],
							[ 6.763191770000049, 8.281925271000034 ],
							[ 6.761353148000069, 8.272732820000044 ],
							[ 6.755838070000038, 8.268136114000072 ],
							[ 6.749403019000056, 8.263540216000024 ],
							[ 6.743887294000046, 8.265378976000022 ],
							[ 6.730098618000056, 8.283763845000067 ],
							[ 6.719986854000069, 8.274571459000072 ],
							[ 6.721010135000029, 8.265875451000056 ],
							[ 6.723495682000021, 8.258602430000053 ],
							[ 6.72589677600007, 8.250656645000049 ],
							[ 6.724220377000051, 8.24938859100007 ],
							[ 6.718148618000043, 8.243316572000026 ],
							[ 6.71447143100005, 8.229528425000069 ],
							[ 6.712633242000038, 8.201951013000041 ],
							[ 6.699763818000065, 8.189081094000073 ],
							[ 6.691258404000052, 8.183006379000062 ],
							[ 6.690111669000032, 8.185403790000066 ],
							[ 6.688272870000048, 8.201951031000021 ],
							[ 6.683676652000031, 8.212981090000028 ],
							[ 6.671726660000047, 8.220335935000037 ],
							[ 6.665291879000051, 8.22677036400006 ],
							[ 6.657938137000031, 8.235962872000073 ],
							[ 6.641391139000064, 8.255267260000039 ],
							[ 6.631279513000038, 8.276410037000062 ],
							[ 6.626683176000029, 8.287440212000035 ],
							[ 6.613813977000063, 8.29939074400005 ],
							[ 6.608298331000071, 8.314098467000065 ],
							[ 6.599105934000022, 8.324210279000056 ],
							[ 6.588075250000031, 8.326048834000062 ],
							[ 6.579801959000065, 8.330645476000029 ],
							[ 6.571528321000073, 8.342595069000026 ],
							[ 6.562335978000021, 8.353626250000048 ],
							[ 6.551305298000045, 8.369254147000049 ],
							[ 6.527404728000022, 8.375688490000073 ],
							[ 6.512696821000077, 8.398669299000062 ],
							[ 6.503504251000038, 8.412458504000028 ],
							[ 6.481442582000057, 8.427165857000034 ],
							[ 6.452945710000051, 8.448309082000037 ],
							[ 6.449268907000032, 8.459340227000041 ],
							[ 6.439157094000052, 8.493351941000071 ],
							[ 6.431343655000035, 8.51909074100007 ],
							[ 6.42490850400003, 8.549426081000036 ],
							[ 6.413877499000023, 8.581600119000029 ],
							[ 6.410115761000043, 8.595393092000052 ],
							[ 6.408037315000058, 8.603014911000059 ],
							[ 6.405604398000037, 8.611934556000051 ],
							[ 6.398250517000065, 8.629400198000042 ],
							[ 6.384096723000027, 8.644262261000051 ],
							[ 6.37986575900004, 8.64870452200006 ],
							[ 6.375269421000041, 8.661574262000045 ],
							[ 6.358722742000054, 8.684555122000063 ],
							[ 6.343095898000058, 8.697424873000045 ],
							[ 6.323791507000067, 8.713051883000048 ],
							[ 6.30264913600007, 8.727760228000022 ],
							[ 6.267717300000072, 8.738791289000062 ],
							[ 6.24657492700004, 8.738791546000073 ],
							[ 6.224512548000064, 8.73511381700007 ],
							[ 6.208536010000046, 8.732374926000034 ],
							[ 6.203216031000068, 8.734561707000069 ],
							[ 6.185323292000021, 8.729681844000027 ],
							[ 6.169057294000027, 8.721548929000051 ],
							[ 6.149537986000041, 8.720735539000032 ],
							[ 6.144658132000075, 8.729681997000057 ],
							[ 6.131645289000062, 8.751641379000034 ],
							[ 6.114566446000026, 8.758147023000049 ],
							[ 6.084474026000066, 8.760587648000069 ],
							[ 6.058448203000069, 8.758960656000056 ],
							[ 6.033236041000066, 8.758960813000044 ],
							[ 5.999890892000053, 8.751641520000021 ],
							[ 5.978744949000031, 8.750014096000029 ],
							[ 5.97467847300004, 8.756521421000059 ],
							[ 5.943772701000057, 8.748388017000025 ],
							[ 5.928320409000037, 8.742694791000076 ],
							[ 5.901481214000057, 8.750014384000053 ],
							[ 5.886841850000053, 8.746761348000064 ],
							[ 5.864883022000072, 8.745134086000064 ],
							[ 5.82421778500003, 8.742694855000025 ],
							[ 5.795751941000049, 8.741881567000064 ],
							[ 5.774606102000064, 8.753267352000023 ],
							[ 5.760780457000067, 8.773600628000054 ],
							[ 5.742074586000058, 8.797999246000074 ],
							[ 5.728248146000055, 8.819958811000049 ],
							[ 5.712795845000073, 8.828905043000077 ],
							[ 5.691649949000066, 8.828091352000058 ],
							[ 5.665624230000049, 8.830530931000055 ],
							[ 5.642038403000072, 8.835411332000035 ],
							[ 5.619265925000036, 8.852490158000023 ],
							[ 5.605439731000047, 8.867942890000052 ],
							[ 5.60137316600003, 8.876889130000052 ],
							[ 5.572907808000025, 8.892342651000035 ],
							[ 5.563148070000068, 8.898848426000029 ],
							[ 5.543629252000073, 8.91674127400006 ],
							[ 5.539562563000061, 8.930567758000052 ],
							[ 5.525417717000039, 8.940100508000057 ],
							[ 5.511850769000034, 8.944171206000021 ],
							[ 5.497605853000039, 8.944171208000057 ],
							[ 5.485395397000048, 8.944848983000043 ],
							[ 5.473863527000049, 8.947562196000035 ],
							[ 5.46640217700002, 8.954345649000061 ],
							[ 5.460975280000071, 8.962486142000046 ],
							[ 5.457583503000023, 8.968590765000044 ],
							[ 5.445373416000052, 8.972660768000026 ],
							[ 5.436554901000022, 8.975374105000071 ],
							[ 5.425022957000067, 8.978766199000063 ],
							[ 5.408064929000034, 8.978766379000035 ],
							[ 5.391106056000069, 8.982835771000055 ],
							[ 5.375504483000043, 8.99029715000006 ],
							[ 5.370077663000075, 8.996402999000054 ],
							[ 5.348370499000055, 8.99843781800007 ],
							[ 5.332090371000049, 8.995724691000021 ],
							[ 5.323272089000056, 8.992332582000074 ],
							[ 5.307670036000047, 9.00047320300007 ],
							[ 5.297495244000061, 9.003186030000052 ],
							[ 5.286641443000065, 9.009969654000031 ],
							[ 5.272396431000061, 9.014718167000069 ],
							[ 5.254759893000028, 9.016753011000048 ],
							[ 5.237801141000034, 9.01878828100007 ],
							[ 5.225591286000054, 9.026927826000076 ],
							[ 5.208971875000032, 9.035068563000038 ],
							[ 5.196761706000075, 9.054062004000059 ],
							[ 5.171663294000041, 9.087300379000055 ],
							[ 5.15945277700007, 9.106972967000047 ],
							[ 5.130962847000035, 9.123252973000035 ],
							[ 5.107899150000037, 9.135462722000057 ],
							[ 5.084835399000042, 9.150385864000043 ],
							[ 5.065163489000042, 9.162596723000036 ],
							[ 5.048204908000059, 9.187694361000069 ],
							[ 5.032603276000032, 9.197870263000027 ],
							[ 5.016323103000047, 9.205331823000051 ],
							[ 4.996651167000039, 9.207366909000029 ],
							[ 4.971552792000068, 9.200583511000048 ],
							[ 4.962055684000063, 9.193121808000058 ],
							[ 4.941705735000028, 9.18430300600005 ],
							[ 4.923390375000054, 9.176162777000059 ],
							[ 4.911180479000052, 9.171414545000061 ],
							[ 4.898970081000073, 9.17005832600006 ],
							[ 4.886081749000027, 9.167345203000025 ],
							[ 4.876584936000029, 9.161239810000041 ],
							[ 4.876071439000043, 9.157646106000072 ],
							[ 4.871683122000036, 9.155138739000051 ],
							[ 4.865247280000062, 9.150311699000042 ],
							[ 4.857202113000028, 9.148702377000063 ],
							[ 4.848084450000044, 9.141194459000076 ],
							[ 4.828239856000039, 9.134221816000036 ],
							[ 4.815904170000067, 9.132612195000036 ],
							[ 4.810540756000023, 9.136367039000049 ],
							[ 4.800886638000065, 9.136366879000036 ],
							[ 4.785332682000046, 9.135293749000027 ],
							[ 4.772460970000054, 9.147629492000021 ],
							[ 4.768706303000044, 9.15352985100003 ],
							[ 4.764554923000048, 9.149932077000074 ],
							[ 4.751920746000053, 9.17985651500004 ],
							[ 4.751920709000046, 9.199497417000032 ],
							[ 4.757158268000069, 9.224376782000036 ],
							[ 4.754539497000053, 9.247945602000073 ],
							[ 4.737516839000023, 9.268896205000033 ],
							[ 4.713947285000074, 9.278062970000065 ],
							[ 4.68514007400006, 9.292466170000068 ],
							[ 4.639310402000035, 9.308178631000033 ],
							[ 4.61705022600006, 9.330439541000032 ],
							[ 4.609726900000055, 9.361758014000031 ],
							[ 4.578422513000021, 9.388054068000031 ],
							[ 4.501166772000033, 9.440430649000064 ],
							[ 4.443552061000048, 9.509829482000043 ],
							[ 4.387247045000038, 9.562206369000023 ],
							[ 4.350583491000066, 9.588395326000068 ],
							[ 4.282493645000045, 9.694457871000054 ],
							[ 4.20991807300004, 9.778295399000058 ],
							[ 4.206547220000061, 9.78218939900006 ],
							[ 4.171192605000044, 9.820162563000054 ],
							[ 4.152861122000047, 9.862063141000021 ],
							[ 4.141076110000029, 9.860753865000049 ],
							[ 4.127982215000031, 9.851588243000037 ],
							[ 4.118815909000034, 9.855516345000069 ],
							[ 4.12274405200003, 9.860754267000061 ],
							[ 4.124053503000027, 9.875158216000045 ],
							[ 4.114887674000045, 9.881705516000068 ],
							[ 4.095246316000043, 9.877777235000053 ],
							[ 4.095246289000045, 9.86730170900006 ],
							[ 4.083461784000065, 9.851588052000068 ],
							[ 4.080843036000033, 9.851588239000023 ],
							[ 4.078224137000063, 9.854207162000023 ],
							[ 4.070367358000055, 9.856825732000061 ],
							[ 4.057273302000056, 9.85682601600007 ],
							[ 4.052035743000033, 9.863374015000034 ],
							[ 4.038941365000028, 9.864683018000051 ],
							[ 4.027156801000046, 9.856826054000067 ],
							[ 4.021918801000027, 9.856825623000077 ],
							[ 4.020609344000036, 9.860754189000033 ],
							[ 4.016681256000027, 9.868611170000065 ],
							[ 4.012752962000036, 9.885633750000068 ],
							[ 4.011443653000072, 9.898727326000028 ],
							[ 4.002277808000031, 9.906583506000061 ],
							[ 3.99442097900004, 9.900036559000057 ],
							[ 3.985255249000033, 9.893489774000045 ],
							[ 3.982636228000047, 9.898727320000035 ],
							[ 3.980017411000063, 9.906583608000062 ],
							[ 3.967250542000045, 9.902983693000067 ],
							[ 3.962340314000073, 9.907893221000052 ],
							[ 3.95415640300007, 9.907892916000037 ],
							[ 3.945972466000057, 9.907892987000025 ],
							[ 3.936152003000075, 9.916077653000059 ],
							[ 3.934515238000074, 9.927535018000071 ],
							[ 3.942698929000073, 9.932445462000032 ],
							[ 3.959066841000038, 9.934081829000036 ],
							[ 3.960703662000071, 9.937355819000061 ],
							[ 3.968887348000067, 9.942265691000046 ],
							[ 3.97052413800003, 9.948813356000073 ],
							[ 3.965613838000024, 9.956997179000041 ],
							[ 3.959066867000047, 9.960269826000058 ],
							[ 3.949246145000075, 9.961907419000056 ],
							[ 3.941062131000024, 9.961907396000072 ],
							[ 3.93451523400006, 9.966817891000062 ],
							[ 3.934515172000033, 9.970091039000067 ],
							[ 3.936151943000027, 9.973364556000035 ],
							[ 3.94106215000005, 9.976637692000054 ],
							[ 3.952519644000063, 9.978275456000063 ],
							[ 3.954156389000048, 9.98318490500003 ],
							[ 3.949246106000032, 9.991369079000037 ],
							[ 3.93451520800005, 9.999552702000074 ],
							[ 3.921420798000042, 9.999552775000041 ],
							[ 3.909963579000021, 9.989732878000041 ],
							[ 3.887048768000056, 9.988095440000052 ],
							[ 3.887048754000034, 10.004463427000076 ],
							[ 3.890322237000021, 10.015920603000041 ],
							[ 3.903416334000042, 10.019194762000041 ],
							[ 3.890322163000064, 10.030651989000035 ],
							[ 3.882138203000068, 10.03556267600004 ],
							[ 3.872317569000074, 10.042109652000022 ],
							[ 3.864133851000076, 10.050293070000066 ],
							[ 3.851039631000049, 10.055203207000034 ],
							[ 3.833034999000063, 10.047018979000029 ],
							[ 3.823319255000058, 10.054305857000031 ],
							[ 3.818045698000049, 10.066830650000043 ],
							[ 3.799117076000073, 10.071198288000062 ],
							[ 3.796932957000024, 10.066102998000076 ],
							[ 3.794020868000075, 10.062462805000052 ],
							[ 3.784556419000069, 10.062462800000048 ],
							[ 3.780188301000067, 10.052269782000053 ],
							[ 3.784556408000071, 10.045718433000047 ],
							[ 3.788196556000059, 10.038437952000038 ],
							[ 3.788196553000034, 10.034069049000038 ],
							[ 3.783828481000057, 10.031157556000039 ],
							[ 3.775092104000066, 10.033341587000052 ],
							[ 3.772180061000029, 10.040621500000043 ],
							[ 3.771451972000023, 10.047174458000029 ],
							[ 3.766355771000065, 10.053726091000044 ],
							[ 3.761987693000037, 10.058822607000025 ],
							[ 3.757619380000051, 10.063190637000048 ],
							[ 3.750339015000066, 10.068286993000072 ],
							[ 3.745970960000022, 10.077023234000023 ],
							[ 3.743058938000047, 10.084304042000042 ],
							[ 3.735050430000058, 10.087943034000034 ],
							[ 3.734322573000043, 10.079935155000044 ],
							[ 3.736506699000074, 10.074838401000022 ],
							[ 3.736506674000054, 10.068287020000071 ],
							[ 3.730682397000066, 10.068287141000042 ],
							[ 3.715393776000042, 10.089399265000054 ],
							[ 3.714665665000041, 10.101776182000037 ],
							[ 3.712481755000056, 10.108327984000027 ],
							[ 3.707385546000069, 10.117064672000026 ],
							[ 3.707385552000062, 10.132352714000035 ],
							[ 3.707385569000053, 10.143273540000052 ],
							[ 3.703017207000073, 10.152737686000023 ],
							[ 3.692824812000026, 10.157834147000074 ],
							[ 3.68845678200006, 10.161474068000075 ],
							[ 3.68481657500007, 10.170210990000044 ],
							[ 3.676445227000045, 10.178597253000021 ],
							[ 3.674886240000035, 10.180159384000035 ],
							[ 3.673408978000055, 10.182990811000025 ],
							[ 3.652806294000072, 10.184239151000043 ],
							[ 3.64406566200006, 10.190483211000071 ],
							[ 3.634076592000042, 10.20234485900005 ],
							[ 3.625960379000048, 10.211710200000027 ],
							[ 3.614098044000059, 10.219825641000057 ],
							[ 3.607854829000075, 10.226692937000053 ],
							[ 3.600987227000076, 10.236682864000045 ],
							[ 3.593495398000073, 10.251042624000036 ],
							[ 3.587099050000063, 10.267221502000041 ],
							[ 3.58536790200003, 10.281070811000063 ],
							[ 3.601813775000039, 10.31742452800006 ],
							[ 3.613931671000046, 10.352046773000041 ],
							[ 3.631242973000042, 10.384073189000048 ],
							[ 3.633839844000022, 10.40830872500004 ],
							[ 3.636436494000066, 10.427352001000031 ],
							[ 3.649420043000021, 10.436872620000031 ],
							[ 3.68144587300003, 10.452452385000072 ],
							[ 3.691832822000038, 10.457646142000044 ],
							[ 3.730783233000068, 10.433410421000076 ],
							[ 3.760212642000056, 10.419561584000064 ],
							[ 3.770599324000045, 10.414368825000054 ],
							[ 3.781851756000037, 10.40571202600006 ],
							[ 3.787045195000076, 10.405712305000065 ],
							[ 3.800028542000064, 10.442065984000067 ],
							[ 3.802625479000028, 10.465437163000047 ],
							[ 3.802625439000053, 10.485345081000048 ],
							[ 3.818205575000036, 10.537279044000059 ],
							[ 3.832920252000065, 10.56843955100004 ],
							[ 3.851962517000061, 10.594405871000049 ],
							[ 3.851097079000056, 10.618642038000075 ],
							[ 3.848500542000068, 10.658458006000046 ],
							[ 3.845038134000049, 10.67403802900003 ],
							[ 3.843382062000046, 10.69888015500004 ],
							[ 3.838113489000023, 10.706064240000046 ],
							[ 3.80781862200007, 10.72597229400003 ],
							[ 3.791373056000054, 10.739821255000038 ],
							[ 3.780986061000021, 10.762326166000037 ],
							[ 3.769733884000061, 10.789159023000025 ],
							[ 3.750691399000061, 10.818588033000026 ],
							[ 3.747229119000053, 10.83589962700006 ],
							[ 3.761078152000039, 10.872253359000069 ],
							[ 3.77059938900004, 10.902547592000076 ],
							[ 3.771464826000056, 10.925052433000076 ],
							[ 3.76454043800004, 10.974389942000073 ],
							[ 3.759346957000048, 10.996894999000062 ],
							[ 3.750691437000057, 11.011609355000076 ],
							[ 3.735976668000035, 11.020265725000058 ],
							[ 3.72039654200006, 11.02286170900004 ],
							[ 3.716934239000068, 11.028055222000035 ],
							[ 3.721261961000039, 11.044501397000033 ],
							[ 3.72558972500002, 11.051425892000054 ],
							[ 3.719530814000052, 11.078258407000021 ],
							[ 3.723858824000047, 11.101628410000046 ],
							[ 3.726455477000059, 11.118074665000051 ],
							[ 3.723256381000056, 11.125112423000076 ],
							[ 3.72212765300003, 11.12759569900004 ],
							[ 3.751556859000061, 11.127595735000057 ],
							[ 3.793969656000058, 11.11288047100004 ],
							[ 3.818447822000053, 11.097894433000022 ],
							[ 3.836382444000037, 11.086914226000033 ],
							[ 3.890913258000069, 11.054022585000041 ],
							[ 3.937653743000055, 11.036710584000048 ],
							[ 3.993915751000031, 11.017668509000032 ],
							[ 4.068354661000058, 11.021996639000065 ],
							[ 4.100380419000032, 11.026324187000057 ],
							[ 4.132406253000056, 11.025458187000027 ],
							[ 4.167028948000052, 11.024593340000024 ],
							[ 4.186071453000068, 11.032383092000032 ],
							[ 4.213769894000052, 11.030652027000031 ],
							[ 4.226610722000032, 11.018696757000043 ],
							[ 4.23887110000004, 11.00728117400007 ],
							[ 4.256182704000025, 10.985642705000032 ],
							[ 4.263107386000058, 10.982180321000044 ],
							[ 4.277821507000056, 10.986508323000066 ],
							[ 4.288208534000034, 10.980448988000035 ],
							[ 4.284746243000029, 10.953616009000029 ],
							[ 4.283880691000036, 10.937170989000037 ],
							[ 4.28820849400006, 10.928515406000031 ],
							[ 4.310713308000061, 10.920724758000063 ],
							[ 4.319368907000069, 10.914666385000032 ],
							[ 4.335703885000044, 10.915319260000047 ],
							[ 4.341008174000024, 10.915531191000071 ],
							[ 4.343673701000057, 10.911533460000044 ],
							[ 4.347462243000052, 10.911668615000053 ],
							[ 4.355760147000069, 10.910159960000044 ],
							[ 4.370847705000074, 10.900353680000023 ],
							[ 4.37990002500004, 10.900353601000063 ],
							[ 4.39800501700006, 10.896581513000058 ],
							[ 4.401434985000037, 10.89622683500005 ],
							[ 4.412344356000062, 10.88887932800003 ],
							[ 4.429085009000062, 10.883934962000069 ],
							[ 4.450919617000068, 10.888324669000042 ],
							[ 4.467736132000027, 10.901844893000032 ],
							[ 4.471933315000058, 10.903371029000027 ],
							[ 4.492300986000032, 10.910914346000027 ],
							[ 4.523984363000068, 10.916195181000035 ],
							[ 4.539826386000072, 10.919966986000077 ],
							[ 4.553404817000057, 10.915440618000048 ],
							[ 4.568492367000033, 10.908651164000048 ],
							[ 4.587351770000055, 10.906388158000027 ],
							[ 4.601684589000058, 10.911668987000041 ],
							[ 4.626578816000062, 10.915440411000077 ],
							[ 4.63336801500003, 10.924492589000067 ],
							[ 4.63638544500003, 10.928264342000034 ],
							[ 4.644910535000065, 10.940068258000053 ],
							[ 4.665039654000054, 10.93930967600005 ],
							[ 4.67443337900005, 10.938389301000029 ],
							[ 4.689088034000065, 10.933409432000076 ],
							[ 4.697049393000043, 10.930759109000064 ],
							[ 4.702770180000073, 10.92449276900004 ],
							[ 4.707637799000054, 10.918297840000037 ],
							[ 4.716348694000033, 10.916194860000076 ],
							[ 4.724556794000023, 10.906424125000058 ],
							[ 4.732190666000065, 10.897335988000066 ],
							[ 4.741997292000065, 10.873195546000034 ],
							[ 4.737471111000048, 10.850565127000039 ],
							[ 4.72012035900002, 10.815863739000065 ],
							[ 4.714839903000041, 10.770601020000072 ],
							[ 4.706542083000045, 10.741181116000064 ],
							[ 4.700507168000058, 10.706480052000074 ],
							[ 4.699518148000038, 10.693626130000041 ],
							[ 4.697489776000054, 10.66725370000006 ],
							[ 4.689191289000064, 10.642358854000065 ],
							[ 4.661279730000047, 10.62123697100003 ],
							[ 4.628087466000068, 10.594078856000067 ],
							[ 4.606965007000042, 10.575974637000058 ],
							[ 4.585088776000021, 10.563904846000071 ],
							[ 4.547369896000021, 10.550325228000077 ],
							[ 4.525493158000074, 10.542027744000052 ],
							[ 4.51085691000003, 10.535522782000044 ],
							[ 4.510058333000075, 10.535167838000064 ],
							[ 4.498541345000035, 10.517370259000074 ],
							[ 4.485557995000022, 10.469764763000057 ],
							[ 4.479498871000033, 10.421292231000052 ],
							[ 4.48642346400004, 10.368492866000054 ],
							[ 4.483826596000029, 10.340794367000058 ],
							[ 4.482961218000071, 10.328677284000037 ],
							[ 4.490751217000025, 10.313961807000055 ],
							[ 4.518449301000032, 10.298381925000058 ],
							[ 4.541819514000053, 10.287129579000066 ],
							[ 4.55999657600006, 10.268087555000022 ],
							[ 4.566055774000063, 10.256835201000058 ],
							[ 4.566055740000024, 10.246030058000031 ],
							[ 4.568690776000039, 10.246048074000043 ],
							[ 4.567442371000027, 10.222947937000072 ],
							[ 4.551834065000037, 10.194852852000054 ],
							[ 4.527485399000057, 10.143033796000054 ],
							[ 4.526921198000025, 10.114263504000064 ],
							[ 4.537629550000077, 10.118157544000042 ],
							[ 4.578817711000056, 10.131791270000065 ],
							[ 4.579357998000035, 10.131970085000034 ],
							[ 4.589275953000026, 10.129639946000054 ],
							[ 4.610010382000041, 10.123590332000049 ],
							[ 4.61399736900006, 10.122457153000028 ],
							[ 4.617965158000061, 10.122897488000035 ],
							[ 4.626700330000062, 10.123394185000052 ],
							[ 4.635644726000066, 10.127583794000032 ],
							[ 4.636340220000022, 10.127359168000055 ],
							[ 4.643868956000063, 10.120667345000072 ],
							[ 4.668128068000044, 10.113138143000072 ],
							[ 4.689878051000051, 10.113974306000046 ],
							[ 4.699916425000026, 10.119830250000064 ],
							[ 4.710791062000055, 10.131542101000036 ],
							[ 4.712882568000055, 10.156638423000061 ],
							[ 4.712882450000052, 10.161657575000049 ],
							[ 4.705353702000025, 10.172531911000021 ],
							[ 4.69280580700007, 10.180060534000063 ],
							[ 4.678584519000026, 10.197627838000074 ],
							[ 4.671055839000076, 10.211849076000021 ],
							[ 4.669383038000035, 10.226069255000027 ],
							[ 4.67691185800004, 10.230252528000051 ],
							[ 4.696988531000045, 10.240290910000056 ],
							[ 4.705353771000034, 10.252839301000051 ],
							[ 4.702007710000032, 10.27291593800004 ],
							[ 4.702007724000055, 10.293828758000075 ],
							[ 4.705353797000043, 10.302193622000061 ],
							[ 4.712882535000062, 10.310559452000064 ],
							[ 4.731286056000044, 10.314741948000062 ],
							[ 4.74885318500003, 10.305540007000047 ],
							[ 4.776458794000064, 10.295501470000033 ],
							[ 4.802757024000073, 10.296298548000038 ],
							[ 4.803168042000038, 10.295507563000058 ],
							[ 4.813644475000046, 10.294762209000055 ],
							[ 4.829344027000047, 10.294939495000051 ],
							[ 4.841584506000061, 10.292486914000051 ],
							[ 4.848562145000074, 10.292565264000075 ],
							[ 4.854647685000032, 10.294360397000048 ],
							[ 4.856323074000045, 10.300424449000047 ],
							[ 4.853479280000045, 10.320254196000064 ],
							[ 4.847116414000027, 10.342635355000027 ],
							[ 4.840286555000034, 10.371505075000073 ],
							[ 4.844217351000054, 10.389192832000049 ],
							[ 4.846726954000076, 10.420981432000076 ],
							[ 4.852582928000061, 10.444403585000032 ],
							[ 4.853419294000048, 10.460297360000027 ],
							[ 4.845054151000056, 10.478701459000035 ],
							[ 4.832924414000047, 10.487903645000074 ],
							[ 4.826231913000072, 10.495431956000061 ],
							[ 4.818703129000028, 10.507144174000075 ],
							[ 4.81870318600005, 10.522201255000027 ],
							[ 4.819539961000032, 10.538095622000071 ],
							[ 4.822049587000038, 10.559008642000038 ],
							[ 4.827905128000054, 10.569883446000063 ],
							[ 4.82372242100007, 10.594979372000068 ],
							[ 4.814520875000028, 10.604181152000024 ],
							[ 4.801972947000024, 10.613383227000043 ],
							[ 4.796117236000043, 10.629277359000071 ],
							[ 4.802809230000037, 10.652699312000038 ],
							[ 4.812011270000028, 10.661901713000077 ],
							[ 4.814520826000035, 10.674449852000066 ],
							[ 4.80699205600007, 10.692852760000051 ],
							[ 4.809501600000033, 10.69787244500003 ],
							[ 4.814520869000035, 10.704564259000051 ],
							[ 4.831251175000034, 10.703727861000061 ],
							[ 4.836270283000033, 10.700382017000038 ],
							[ 4.843799165000064, 10.699544934000073 ],
							[ 4.847145604000048, 10.704564134000066 ],
							[ 4.862203066000063, 10.707074264000028 ],
							[ 4.868058724000036, 10.700381950000065 ],
							[ 4.881442970000023, 10.702891296000075 ],
							[ 4.890645032000066, 10.701218594000068 ],
							[ 4.893990936000023, 10.693689402000075 ],
							[ 4.909048597000037, 10.687834032000069 ],
							[ 4.914904167000032, 10.687833660000024 ],
							[ 4.923269693000066, 10.693689533000054 ],
							[ 4.931634884000061, 10.687833566000052 ],
							[ 4.941673219000052, 10.688670364000075 ],
							[ 4.946692491000022, 10.693689462000066 ],
							[ 4.948365153000054, 10.701218676000053 ],
							[ 4.955893941000056, 10.704564219000076 ],
							[ 4.965932431000056, 10.707074070000033 ],
							[ 4.973461191000069, 10.710420380000073 ],
							[ 4.975970752000023, 10.713766303000057 ],
							[ 4.979317128000048, 10.71711281000006 ],
							[ 4.986845992000042, 10.71711250900006 ],
							[ 4.995211057000063, 10.719621519000043 ],
							[ 5.008595617000026, 10.721295602000055 ],
							[ 5.008595362000051, 10.733842933000062 ],
							[ 5.01194152100004, 10.740535460000046 ],
							[ 5.020306942000047, 10.746391368000047 ],
							[ 5.026999082000032, 10.748064020000072 ],
							[ 5.031181857000036, 10.744717883000021 ],
							[ 5.040383850000069, 10.73718907500006 ],
							[ 5.049585378000074, 10.73802583500003 ],
							[ 5.057114167000066, 10.739698402000045 ],
							[ 5.064642854000056, 10.74722771200004 ],
							[ 5.07468117600007, 10.748900568000067 ],
							[ 5.079700423000077, 10.755593223000062 ],
							[ 5.09643125100007, 10.759775061000028 ],
							[ 5.098797226000045, 10.76056395300003 ],
							[ 5.10087866300006, 10.759829796000076 ],
							[ 5.102286789000061, 10.772323501000074 ],
							[ 5.107306114000039, 10.785707270000046 ],
							[ 5.108979303000069, 10.795745691000036 ],
							[ 5.099777217000053, 10.808294511000042 ],
							[ 5.083883382000067, 10.817496185000039 ],
							[ 5.05878726100002, 10.820842027000026 ],
							[ 5.047075666000069, 10.825024757000051 ],
							[ 5.038710455000057, 10.83589936900006 ],
							[ 5.028672167000025, 10.859322292000058 ],
							[ 5.026162485000043, 10.871870289000071 ],
							[ 5.025326132000032, 10.890273859000047 ],
							[ 5.034527645000026, 10.899476124000046 ],
							[ 5.054604611000059, 10.904495084000075 ],
							[ 5.075517776000027, 10.915370173000042 ],
							[ 5.082210148000058, 10.92708119100007 ],
							[ 5.081373634000045, 10.950504212000055 ],
							[ 5.067988924000076, 10.989821605000031 ],
							[ 5.062970136000047, 11.012407353000071 ],
							[ 5.046239356000058, 11.04168627100006 ],
							[ 5.040639727000041, 11.051896982000073 ],
							[ 5.035752267000021, 11.051897312000051 ],
							[ 5.027373770000054, 11.050500677000059 ],
							[ 5.021090010000023, 11.053992012000037 ],
							[ 5.009220559000028, 11.051896943000031 ],
							[ 4.995954389000076, 11.053992120000032 ],
							[ 4.991067463000036, 11.060974270000031 ],
							[ 4.991765472000054, 11.07005040100006 ],
							[ 4.979896026000063, 11.080523477000042 ],
							[ 4.972215712000036, 11.080523776000064 ],
							[ 4.960346210000068, 11.082618728000057 ],
							[ 4.947778245000052, 11.082618926000066 ],
							[ 4.934512561000076, 11.084014637000053 ],
							[ 4.908678974000054, 11.08192067400006 ],
							[ 4.898205750000045, 11.087506398000073 ],
							[ 4.87656161700005, 11.091694720000021 ],
							[ 4.863295538000045, 11.100073704000067 ],
							[ 4.860673978000023, 11.103350593000073 ],
							[ 4.850389957000061, 11.108492946000069 ],
							[ 4.839656405000028, 11.11385940200006 ],
							[ 4.817800545000068, 11.119511665000061 ],
							[ 4.814421105000065, 11.116132926000034 ],
							[ 4.802551755000025, 11.114037465000024 ],
							[ 4.78858759000002, 11.118925125000032 ],
							[ 4.785794672000065, 11.123812789000056 ],
							[ 4.780209003000039, 11.128700310000056 ],
							[ 4.779431781000028, 11.131420138000067 ],
							[ 4.765289237000047, 11.13685977800003 ],
							[ 4.757622301000026, 11.14299287700004 ],
							[ 4.746122355000068, 11.147592376000034 ],
							[ 4.72158528500006, 11.176336572000025 ],
							[ 4.710388250000051, 11.180367197000066 ],
							[ 4.70201011100005, 11.192236994000041 ],
							[ 4.699915415000021, 11.203408155000034 ],
							[ 4.699217313000077, 11.222259353000027 ],
							[ 4.70340625700004, 11.228543278000075 ],
							[ 4.736222316000067, 11.249489805000053 ],
							[ 4.743023350000044, 11.253668488000073 ],
							[ 4.743761605000032, 11.254122221000046 ],
							[ 4.744364973000074, 11.254694759000074 ],
							[ 4.746132044000035, 11.257479260000025 ],
							[ 4.746725293000054, 11.258413880000035 ],
							[ 4.747301181000068, 11.262978200000077 ],
							[ 4.747390359000065, 11.264228546000027 ],
							[ 4.747434719000069, 11.264850050000064 ],
							[ 4.747358247000022, 11.266874896000047 ],
							[ 4.747269270000061, 11.269234101000052 ],
							[ 4.747232896000071, 11.270196534000036 ],
							[ 4.747167410000031, 11.271932753000044 ],
							[ 4.747070345000054, 11.274504314000069 ],
							[ 4.74547590700007, 11.280633795000028 ],
							[ 4.74399277200007, 11.286335687000076 ],
							[ 4.741781858000024, 11.292249575000028 ],
							[ 4.741330337000022, 11.293457554000042 ],
							[ 4.740965791000065, 11.294448435000049 ],
							[ 4.740579928000045, 11.295900147000054 ],
							[ 4.740337214000022, 11.296813309000072 ],
							[ 4.740119936000042, 11.297630705000074 ],
							[ 4.740570959000024, 11.299503939000033 ],
							[ 4.740883917000076, 11.300804328000027 ],
							[ 4.74275784200006, 11.30207798400005 ],
							[ 4.745280435000041, 11.30379189100006 ],
							[ 4.746458184000062, 11.304591935000076 ],
							[ 4.747036703000049, 11.304985061000025 ],
							[ 4.748018538000053, 11.30550656500003 ],
							[ 4.749583916000063, 11.30612853100007 ],
							[ 4.751168759000052, 11.306898191000073 ],
							[ 4.75168341400007, 11.307148193000046 ],
							[ 4.754884554000057, 11.308774315000051 ],
							[ 4.755930126000067, 11.309305563000066 ],
							[ 4.758655065000028, 11.312078337000059 ],
							[ 4.760019040000032, 11.313466428000027 ],
							[ 4.761457664000034, 11.314919782000061 ],
							[ 4.762192418000041, 11.313450575000047 ],
							[ 4.766075644000068, 11.306000630000028 ],
							[ 4.782958003000033, 11.31225415800003 ],
							[ 4.805447543000071, 11.321088757000041 ],
							[ 4.81990478900002, 11.330726627000047 ],
							[ 4.826330238000025, 11.335546489000023 ],
							[ 4.837574914000072, 11.337152630000048 ],
							[ 4.848819748000039, 11.34357830700003 ],
							[ 4.86488348000006, 11.350806033000026 ],
							[ 4.871308785000053, 11.353216291000024 ],
							[ 4.880143661000034, 11.361248300000057 ],
							[ 4.890585456000053, 11.37088564000004 ],
							[ 4.905845704000058, 11.373295818000031 ],
							[ 4.917893804000073, 11.370885820000069 ],
							[ 4.923515869000028, 11.364460905000044 ],
							[ 4.929941648000067, 11.357232151000062 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Ogun',
				admin1Pcod: 'NG028',
				admin1RefN: 'Ogun',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 9.649774,
				Shape_Area: 1.364021
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 2.846128470000053, 7.929880232000073 ],
							[ 2.850770002000047, 7.929319804000045 ],
							[ 2.853672029000052, 7.929332173000034 ],
							[ 2.859474424000041, 7.92877719300003 ],
							[ 2.862374554000041, 7.928210120000074 ],
							[ 2.865853552000033, 7.927066305000039 ],
							[ 2.869334220000042, 7.926501235000046 ],
							[ 2.872236499000053, 7.926513739000029 ],
							[ 2.878615394000064, 7.924802905000035 ],
							[ 2.883839128000034, 7.924824580000063 ],
							[ 2.886160846000053, 7.924834388000022 ],
							[ 2.889643424000042, 7.924849054000049 ],
							[ 2.893127679000031, 7.925442221000026 ],
							[ 2.896612168000047, 7.926036471000032 ],
							[ 2.899516350000056, 7.926627558000064 ],
							[ 2.901837821000072, 7.926637244000062 ],
							[ 2.905320160000031, 7.926651498000069 ],
							[ 2.908222438000053, 7.926663995000069 ],
							[ 2.912865885000031, 7.926682992000053 ],
							[ 2.917507167000053, 7.926123684000061 ],
							[ 2.919821744000046, 7.92381669100007 ],
							[ 2.918653258000063, 7.921494446000054 ],
							[ 2.915747643000032, 7.920324424000057 ],
							[ 2.913422339000022, 7.919156101000056 ],
							[ 2.910516267000048, 7.917985474000034 ],
							[ 2.907021050000026, 7.913916597000025 ],
							[ 2.902950760000067, 7.911582929000076 ],
							[ 2.896553039000025, 7.907501681000042 ],
							[ 2.894806389000053, 7.905756985000039 ],
							[ 2.891305211000031, 7.899950421000028 ],
							[ 2.890713696000034, 7.896472524000046 ],
							[ 2.889541869000027, 7.892992589000073 ],
							[ 2.888369825000041, 7.889512645000025 ],
							[ 2.88719605600005, 7.88545332700005 ],
							[ 2.885441779000075, 7.881391439000026 ],
							[ 2.884268051000049, 7.877332109000065 ],
							[ 2.883676536000053, 7.873854750000021 ],
							[ 2.883085019000021, 7.870377093000059 ],
							[ 2.881909374000031, 7.86573839600004 ],
							[ 2.881319771000051, 7.862840110000036 ],
							[ 2.881308803000024, 7.859364501000073 ],
							[ 2.882456780000041, 7.855315268000027 ],
							[ 2.883020397000053, 7.850104435000048 ],
							[ 2.883007530000043, 7.846050284000057 ],
							[ 2.882414094000069, 7.841993838000064 ],
							[ 2.881824499000061, 7.839094974000034 ],
							[ 2.881232976000035, 7.83561760300006 ],
							[ 2.881217958000036, 7.830983532000062 ],
							[ 2.88004445200005, 7.826924457000075 ],
							[ 2.879450792000057, 7.822867423000048 ],
							[ 2.879439839000042, 7.81939208600005 ],
							[ 2.880011087000071, 7.816498714000033 ],
							[ 2.882317786000044, 7.811874742000043 ],
							[ 2.884053706000032, 7.810144439000055 ],
							[ 2.886950493000029, 7.808418756000037 ],
							[ 2.890425450000066, 7.80611654300003 ],
							[ 2.894482847000063, 7.804396244000031 ],
							[ 2.897379392000062, 7.802670457000033 ],
							[ 2.90027595600003, 7.800945223000042 ],
							[ 2.904337162000047, 7.800382697000032 ],
							[ 2.908972983000069, 7.79808515700006 ],
							[ 2.911287309000045, 7.795777915000031 ],
							[ 2.91243696500004, 7.792307957000048 ],
							[ 2.915317058000028, 7.785369462000062 ],
							[ 2.915888063000068, 7.782476013000064 ],
							[ 2.915294894000056, 7.778419043000042 ],
							[ 2.915285595000057, 7.775523058000033 ],
							[ 2.91469240300006, 7.771466362000069 ],
							[ 2.914100895000047, 7.767988185000036 ],
							[ 2.913509390000058, 7.764510580000035 ],
							[ 2.912919761000069, 7.761612035000041 ],
							[ 2.912331810000069, 7.759293156000069 ],
							[ 2.91174410900004, 7.756974262000028 ],
							[ 2.911731011000029, 7.75291954000005 ],
							[ 2.914625643000022, 7.750614559000041 ],
							[ 2.918692359000033, 7.75178997200004 ],
							[ 2.921019555000044, 7.753537109000035 ],
							[ 2.924507623000068, 7.755289578000031 ],
							[ 2.926832921000027, 7.75645787600007 ],
							[ 2.929162017000067, 7.75878431700005 ],
							[ 2.932071690000043, 7.761113277000049 ],
							[ 2.934983008000074, 7.764020990000063 ],
							[ 2.93730997900002, 7.765768573000059 ],
							[ 2.940800194000076, 7.768099687000074 ],
							[ 2.943709383000055, 7.770428543000037 ],
							[ 2.947201500000062, 7.773339247000024 ],
							[ 2.95011282300004, 7.776247410000053 ],
							[ 2.952445496000053, 7.779732701000057 ],
							[ 2.955369706000056, 7.786694968000063 ],
							[ 2.957120180000061, 7.78959810300006 ],
							[ 2.961781984000027, 7.795409587000051 ],
							[ 2.965284843000063, 7.801795432000063 ],
							[ 2.968205456000021, 7.807599414000038 ],
							[ 2.971124177000036, 7.812824849000037 ],
							[ 2.972876806000045, 7.816306981000025 ],
							[ 2.97404838500006, 7.819787502000054 ],
							[ 2.975220443000069, 7.823267451000049 ],
							[ 2.975817681000024, 7.828482757000074 ],
							[ 2.976989486000036, 7.831962695000072 ],
							[ 2.977579110000022, 7.834860821000063 ],
							[ 2.978168716000027, 7.837759520000077 ],
							[ 2.978179908000072, 7.841234562000068 ],
							[ 2.978187344000048, 7.843551732000037 ],
							[ 2.978780491000066, 7.847608 ],
							[ 2.979368448000059, 7.849927902000047 ],
							[ 2.980540541000039, 7.853407828000059 ],
							[ 2.981708529000059, 7.855729020000069 ],
							[ 2.982876775000022, 7.858051343000056 ],
							[ 2.985782862000065, 7.859221405000028 ],
							[ 2.98983266700003, 7.855184016000067 ],
							[ 2.991559038000048, 7.850557821000052 ],
							[ 2.992710589000069, 7.847666150000066 ],
							[ 2.993282089000047, 7.844773179000072 ],
							[ 2.997326350000037, 7.838997913000071 ],
							[ 2.999058253000044, 7.836109200000067 ],
							[ 3.004849695000075, 7.832078881000029 ],
							[ 3.006583688000035, 7.829769211000041 ],
							[ 3.008892297000045, 7.825724696000066 ],
							[ 3.011776222000037, 7.819944761000045 ],
							[ 3.012929456000052, 7.817632671000069 ],
							[ 3.014082917000053, 7.815320564000046 ],
							[ 3.015814780000028, 7.812431772000025 ],
							[ 3.01812196700007, 7.807807835000062 ],
							[ 3.019850013000053, 7.803760578000038 ],
							[ 3.021580448000066, 7.800292413000022 ],
							[ 3.023306872000035, 7.795666625000024 ],
							[ 3.025958563000074, 7.791801102000022 ],
							[ 3.025025846000062, 7.788723102000063 ],
							[ 3.022123816000033, 7.788710939000055 ],
							[ 3.019225380000023, 7.789857475000076 ],
							[ 3.019210591000046, 7.785223987000052 ],
							[ 3.019783744000051, 7.782908933000044 ],
							[ 3.020354743000041, 7.780015909000042 ],
							[ 3.022084945000074, 7.776547995000044 ],
							[ 3.020911214000023, 7.77248856500006 ],
							[ 3.020312061000027, 7.766693955000051 ],
							[ 3.025523204000024, 7.762661521000041 ],
							[ 3.026090613000065, 7.758609209000042 ],
							[ 3.025500995000073, 7.755710579000038 ],
							[ 3.023750573000029, 7.752807477000033 ],
							[ 3.022000098000035, 7.749904661000073 ],
							[ 3.020829909000042, 7.747003650000067 ],
							[ 3.020240311000066, 7.744105307000041 ],
							[ 3.019071802000042, 7.741783085000066 ],
							[ 3.017323249000071, 7.739459043000068 ],
							[ 3.016153121000059, 7.736558571000046 ],
							[ 3.014970282000036, 7.72960284100003 ],
							[ 3.013796312000068, 7.725543630000061 ],
							[ 3.010301136000066, 7.721474558000068 ],
							[ 3.007972012000039, 7.719148075000021 ],
							[ 3.006803754000032, 7.716826942000068 ],
							[ 3.006213931000048, 7.713928270000054 ],
							[ 3.007361889000038, 7.709878371000059 ],
							[ 3.010835185000076, 7.706997369000021 ],
							[ 3.01198314100003, 7.702947753000046 ],
							[ 3.011975531000076, 7.700630570000044 ],
							[ 3.008489363000024, 7.699457726000048 ],
							[ 3.001529926000046, 7.701166649000072 ],
							[ 2.996879110000066, 7.698830692000058 ],
							[ 2.999180552000041, 7.692468768000026 ],
							[ 3.001498472000037, 7.691319973000077 ],
							[ 3.002648377000071, 7.687849461000042 ],
							[ 2.998001110000075, 7.686671687000057 ],
							[ 2.994516832000045, 7.686078082000051 ],
							[ 2.992769950000024, 7.684332987000062 ],
							[ 2.989851232000035, 7.679108236000047 ],
							[ 2.993326449000051, 7.676805903000059 ],
							[ 2.99622848100006, 7.676818308000065 ],
							[ 2.999707442000044, 7.675673815000039 ],
							[ 3.00202181100002, 7.673367142000075 ],
							[ 3.000262046000046, 7.667567383000062 ],
							[ 2.995611188000055, 7.665231119000055 ],
							[ 2.993276597000033, 7.661167257000045 ],
							[ 2.99674796100004, 7.657706752000024 ],
							[ 2.997890226000038, 7.651919338000027 ],
							[ 2.997302525000066, 7.649600575000022 ],
							[ 2.993790379000075, 7.640318430000036 ],
							[ 2.992622380000057, 7.637996668000028 ],
							[ 2.990797534000023, 7.634079894000024 ],
							[ 2.989022018000071, 7.630609563000064 ],
							[ 2.987269896000043, 7.629455564000068 ],
							[ 2.984345905000055, 7.62714685800006 ],
							[ 2.981427692000068, 7.625417273000039 ],
							[ 2.977343345000065, 7.623112088000028 ],
							[ 2.974993458000029, 7.620222630000058 ],
							[ 2.972631903000035, 7.61617430900003 ],
							[ 2.972573047000026, 7.610381930000074 ],
							[ 2.97369790700003, 7.606903454000076 ],
							[ 2.975408819000052, 7.604002898000033 ],
							[ 2.97653389200002, 7.600524416000042 ],
							[ 2.977652546000058, 7.596466478000025 ],
							[ 2.978760002000058, 7.59124997400005 ],
							[ 2.980459238000037, 7.587190526000029 ],
							[ 2.982750163000048, 7.584288700000059 ],
							[ 2.986763722000035, 7.579643158000067 ],
							[ 2.990789190000044, 7.576156998000044 ],
							[ 2.99539470600007, 7.572668410000063 ],
							[ 2.997673978000023, 7.568607367000027 ],
							[ 3.000539299000025, 7.565124454000056 ],
							[ 3.003990405000025, 7.562218580000035 ],
							[ 3.00682617800004, 7.555838454000025 ],
							[ 3.010881164000068, 7.555248131000042 ],
							[ 3.014349923000054, 7.554080454000029 ],
							[ 3.020719276000023, 7.552903743000059 ],
							[ 3.025354410000034, 7.552311966000048 ],
							[ 3.025914657000044, 7.552198141000076 ],
							[ 3.028248787000052, 7.551724327000045 ],
							[ 3.033452287000046, 7.549972551000053 ],
							[ 3.036891703000038, 7.545908062000024 ],
							[ 3.043202412000028, 7.538940023000066 ],
							[ 3.044315569000048, 7.534303321000039 ],
							[ 3.050655598000048, 7.530230626000048 ],
							[ 3.054112677000035, 7.527903991000073 ],
							[ 3.058161261000066, 7.526734274000034 ],
							[ 3.06045843000004, 7.524411143000066 ],
							[ 3.06447197600005, 7.519765894000045 ],
							[ 3.067307945000039, 7.513386149000041 ],
							[ 3.068989285000043, 7.507589412000073 ],
							[ 3.073577171000068, 7.502363273000071 ],
							[ 3.075276119000023, 7.498303945000032 ],
							[ 3.076946018000058, 7.491348884000047 ],
							[ 3.076336403000028, 7.488454794000063 ],
							[ 3.074578280000026, 7.486722030000067 ],
							[ 3.073371179000048, 7.482091007000065 ],
							[ 3.069245835000061, 7.475731344000053 ],
							[ 3.069192628000053, 7.470518129000027 ],
							[ 3.070885906000058, 7.465879969000071 ],
							[ 3.07317684800006, 7.462977033000072 ],
							[ 3.074266669000053, 7.456023333000076 ],
							[ 3.07537960600007, 7.451386507000052 ],
							[ 3.077664618000028, 7.44790467200005 ],
							[ 3.079375741000035, 7.445003639000049 ],
							[ 3.080500574000041, 7.441525112000022 ],
							[ 3.082211250000057, 7.438624355000059 ],
							[ 3.086260329000027, 7.437454628000069 ],
							[ 3.090309367000032, 7.436284578000027 ],
							[ 3.094340808000027, 7.433377302000054 ],
							[ 3.097791907000044, 7.430471969000052 ],
							[ 3.098468670000045, 7.429409127000042 ],
							[ 3.104073046000053, 7.420607143000041 ],
							[ 3.10727978500006, 7.416192909000074 ],
							[ 3.113248827000064, 7.410155885000052 ],
							[ 3.115294470000038, 7.408086395000055 ],
							[ 3.123631979000038, 7.404334169000037 ],
							[ 3.128249164000067, 7.402004216000023 ],
							[ 3.132292264000057, 7.400255136000055 ],
							[ 3.136903755000048, 7.39734650500003 ],
							[ 3.140366550000067, 7.395598772000028 ],
							[ 3.143902570000023, 7.390937708000024 ],
							[ 3.146091213000034, 7.388053028000058 ],
							[ 3.148382411000057, 7.385150485000054 ],
							[ 3.151138546000027, 7.382362425000053 ],
							[ 3.15527297400007, 7.378179903000046 ],
							[ 3.161624697000036, 7.375266450000026 ],
							[ 3.167988329000025, 7.373511200000053 ],
							[ 3.173197279000021, 7.372337769000069 ],
							[ 3.178406493000068, 7.371165397000027 ],
							[ 3.183035868000047, 7.369993652000062 ],
							[ 3.198106759000041, 7.368792917000064 ],
							[ 3.206233980000036, 7.369350012000041 ],
							[ 3.212621210000066, 7.369910588000039 ],
							[ 3.22074273100003, 7.369888220000064 ],
							[ 3.230024319000051, 7.369862448000049 ],
							[ 3.236973767000052, 7.368684675000054 ],
							[ 3.24914431000002, 7.367492242000026 ],
							[ 3.256644527000049, 7.363416700000073 ],
							[ 3.257195214000035, 7.360519029000045 ],
							[ 3.260066534000032, 7.357614972000022 ],
							[ 3.264097470000024, 7.354707831000042 ],
							[ 3.280169966000074, 7.337864305000039 ],
							[ 3.282466890000023, 7.335541191000061 ],
							[ 3.286995904000037, 7.324523508000027 ],
							[ 3.291601460000038, 7.32103550100004 ],
							[ 3.296212890000049, 7.318126301000063 ],
							[ 3.306613726000023, 7.314042564000033 ],
							[ 3.311254486000053, 7.314029752000067 ],
							[ 3.319375973000035, 7.314007202000028 ],
							[ 3.324028480000038, 7.315152251000029 ],
							[ 3.328189366000061, 7.324987882000073 ],
							[ 3.329964902000029, 7.328458278000028 ],
							[ 3.331142907000071, 7.33019243800004 ],
							[ 3.332924582000032, 7.334242241000027 ],
							[ 3.334694180000042, 7.33713289800005 ],
							[ 3.339411529000074, 7.344649742000058 ],
							[ 3.343519473000072, 7.349272314000075 ],
							[ 3.344714902000021, 7.352744710000024 ],
							[ 3.347082395000029, 7.357371787000034 ],
							[ 3.350041879000059, 7.363155315000029 ],
							[ 3.353557812000076, 7.366621001000055 ],
							[ 3.357079732000045, 7.370666088000064 ],
							[ 3.359435359000031, 7.374135060000071 ],
							[ 3.363525388000028, 7.377019403000077 ],
							[ 3.369379524000067, 7.382215896000048 ],
							[ 3.375807781000049, 7.386832135000077 ],
							[ 3.377577533000022, 7.389723405000041 ],
							[ 3.379347317000054, 7.392614407000053 ],
							[ 3.380543032000048, 7.396086102000027 ],
							[ 3.383466973000054, 7.398395074000064 ],
							[ 3.38581683600006, 7.401284731000032 ],
							[ 3.385852313000044, 7.40476041900007 ],
							[ 3.383034008000038, 7.412877148000064 ],
							[ 3.381323093000049, 7.415778137000075 ],
							[ 3.382512788000042, 7.418670683000073 ],
							[ 3.384276630000045, 7.420982796000033 ],
							[ 3.383145814000045, 7.423881945000062 ],
							[ 3.381428976000052, 7.426203797000028 ],
							[ 3.381476193000026, 7.430837771000029 ],
							[ 3.382659899000032, 7.43315117700007 ],
							[ 3.385004087000027, 7.435461404000023 ],
							[ 3.387341971000069, 7.43719304900003 ],
							[ 3.393166516000065, 7.439493138000046 ],
							[ 3.396079059000044, 7.440643716000068 ],
							[ 3.399014734000048, 7.444110821000038 ],
							[ 3.400228050000067, 7.449320710000052 ],
							[ 3.393893709000054, 7.453972002000057 ],
							[ 3.393082837000065, 7.455832136000026 ],
							[ 3.395687116000033, 7.459179744000039 ],
							[ 3.398605365000037, 7.460909707000042 ],
							[ 3.401535249000062, 7.463797653000029 ],
							[ 3.40679740500002, 7.467837941000028 ],
							[ 3.41147378200003, 7.471299525000063 ],
							[ 3.417304020000074, 7.474179754000033 ],
							[ 3.421388378000074, 7.476485119000074 ],
							[ 3.426644830000043, 7.479945706000024 ],
							[ 3.432486982000057, 7.483984548000024 ],
							[ 3.43892697900003, 7.48975845800004 ],
							[ 3.442431207000027, 7.49206553700003 ],
							[ 3.445929542000044, 7.493793450000055 ],
							[ 3.453482648000033, 7.49493063500006 ],
							[ 3.453435633000026, 7.490297275000046 ],
							[ 3.452234286000021, 7.486245573000076 ],
							[ 3.455685362000054, 7.483340307000049 ],
							[ 3.459124846000066, 7.479276176000042 ],
							[ 3.461984132000055, 7.475213151000048 ],
							[ 3.466583995000065, 7.471145526000043 ],
							[ 3.465974288000041, 7.468251571000053 ],
							[ 3.465364677000025, 7.465356744000076 ],
							[ 3.463577287000021, 7.46072851200006 ],
							[ 3.460641362000047, 7.457261083000049 ],
							[ 3.460014320000028, 7.452629132000027 ],
							[ 3.459404926000047, 7.449734592000027 ],
							[ 3.466295509000076, 7.442764191000037 ],
							[ 3.468598138000061, 7.44102050500004 ],
							[ 3.471475128000066, 7.438695375000066 ],
							[ 3.472611872000073, 7.436375673000043 ],
							[ 3.474879515000055, 7.431156275000035 ],
							[ 3.477732629000059, 7.426514268000062 ],
							[ 3.480580530000054, 7.421293396000067 ],
							[ 3.482859587000064, 7.417231929000025 ],
							[ 3.482226640000022, 7.412020583000071 ],
							[ 3.481013333000021, 7.40681126800007 ],
							[ 3.47924374300004, 7.403920219000042 ],
							[ 3.477485659000024, 7.402186865000033 ],
							[ 3.475715896000054, 7.39929577700002 ],
							[ 3.47336604100002, 7.396406705000061 ],
							[ 3.472733042000073, 7.391195352000068 ],
							[ 3.474449628000059, 7.388873631000024 ],
							[ 3.479096441000024, 7.389439522000032 ],
							[ 3.482014690000028, 7.391169127000069 ],
							[ 3.483766547000073, 7.392323027000032 ],
							[ 3.486093069000049, 7.392895785000064 ],
							[ 3.488993390000076, 7.392887618000032 ],
							[ 3.491899928000066, 7.39345830600007 ],
							[ 3.494226191000052, 7.394031009000059 ],
							[ 3.497712809000063, 7.394600721000074 ],
							[ 3.499470679000069, 7.396333696000056 ],
							[ 3.501228791000074, 7.398066389000064 ],
							[ 3.502980972000046, 7.399219645000073 ],
							[ 3.506490937000024, 7.40210638700006 ],
							[ 3.508835085000044, 7.404416687000037 ],
							[ 3.511753075000058, 7.40614602900007 ],
							[ 3.515843145000076, 7.409030906000055 ],
							[ 3.518199181000057, 7.412499407000041 ],
							[ 3.519956806000039, 7.414232277000053 ],
							[ 3.522289280000052, 7.41538421100006 ],
							[ 3.524662493000051, 7.420590921000041 ],
							[ 3.529338845000041, 7.424053244000049 ],
							[ 3.532263024000031, 7.426362142000073 ],
							[ 3.533452515000022, 7.429254454000045 ],
							[ 3.535222300000044, 7.432145529000024 ],
							[ 3.536986103000061, 7.434457725000073 ],
							[ 3.53875586700002, 7.437348763000045 ],
							[ 3.540519717000052, 7.439660959000037 ],
							[ 3.545752558000061, 7.440804434000029 ],
							[ 3.549233246000028, 7.440794796000034 ],
							[ 3.555040158000054, 7.441358010000044 ],
							[ 3.571863207000035, 7.441311287000076 ],
							[ 3.578830441000036, 7.441870648000076 ],
							[ 3.586371687000053, 7.441850265000028 ],
							[ 3.591018676000033, 7.442416136000077 ],
							[ 3.598559862000059, 7.442395204000036 ],
							[ 3.603786733000049, 7.442960129000028 ],
							[ 3.611908208000045, 7.442937286000074 ],
							[ 3.618869538000069, 7.442917315000045 ],
							[ 3.633378070000049, 7.443456120000064 ],
							[ 3.640339388000029, 7.44343716800006 ],
							[ 3.646140313000046, 7.443420367000044 ],
							[ 3.657162406000054, 7.443389349000029 ],
							[ 3.665301555000042, 7.445104975000049 ],
							[ 3.674009130000059, 7.445659552000052 ],
							[ 3.682704672000057, 7.445056507000061 ],
							[ 3.687931782000021, 7.445620531000031 ],
							[ 3.69141836700004, 7.446190371000057 ],
							[ 3.699551349000046, 7.447325811000042 ],
							[ 3.704198331000043, 7.447892244000059 ],
							[ 3.707093001000032, 7.44730521200006 ],
							[ 3.707649495000055, 7.44498688300007 ],
							[ 3.707608445000062, 7.440932160000045 ],
							[ 3.707573172000025, 7.437456558000065 ],
							[ 3.706945864000033, 7.432824492000066 ],
							[ 3.706312668000066, 7.427613303000044 ],
							[ 3.706839768000066, 7.422399064000047 ],
							[ 3.706804499000043, 7.418923736000067 ],
							[ 3.706775229000073, 7.416027532000044 ],
							[ 3.706733964000023, 7.411973080000053 ],
							[ 3.706704616000025, 7.409077170000046 ],
							[ 3.706089062000046, 7.405603360000043 ],
							[ 3.705461754000055, 7.400971285000026 ],
							[ 3.704840399000034, 7.396918350000021 ],
							[ 3.705373734000034, 7.392282958000067 ],
							[ 3.708244805000049, 7.389379130000066 ],
							[ 3.71057079000002, 7.389951563000068 ],
							[ 3.71405745900006, 7.390520940000044 ],
							[ 3.717532349000066, 7.38993229600004 ],
							[ 3.719829120000043, 7.387609040000029 ],
							[ 3.719793806000041, 7.384133710000071 ],
							[ 3.720350253000049, 7.381815375000031 ],
							[ 3.720320933000039, 7.378919169000028 ],
							[ 3.719705374000057, 7.375445378000052 ],
							[ 3.719676057000072, 7.372549449000076 ],
							[ 3.719605416000036, 7.365598782000063 ],
							[ 3.718978376000052, 7.360966745000042 ],
							[ 3.71892549100005, 7.355754021000052 ],
							[ 3.718901828000071, 7.353436931000033 ],
							[ 3.718221945000039, 7.343592163000039 ],
							[ 3.717606094000075, 7.34011835900003 ],
							[ 3.717571024000051, 7.336643302000027 ],
							[ 3.717535718000022, 7.333167963000051 ],
							[ 3.717482587000063, 7.327954672000033 ],
							[ 3.716222547000029, 7.31811140800005 ],
							[ 3.715606908000041, 7.314637599000037 ],
							[ 3.715238097000054, 7.310486273000038 ],
							[ 3.714381963000051, 7.308269402000064 ],
							[ 3.714346630000023, 7.304794336000043 ],
							[ 3.715483189000054, 7.302474450000034 ],
							[ 3.718372106000061, 7.301308217000042 ],
							[ 3.722432824000066, 7.301296841000067 ],
							[ 3.725913517000038, 7.301286696000034 ],
							[ 3.730548362000036, 7.300694553000028 ],
							[ 3.736349583000049, 7.300678622000021 ],
							[ 3.740404358000035, 7.300087923000035 ],
							[ 3.746779654000022, 7.299490849000051 ],
							[ 3.749686033000046, 7.300062316000037 ],
							[ 3.754912850000039, 7.300626246000036 ],
							[ 3.76013972100003, 7.301191233000054 ],
							[ 3.76189314800007, 7.300967577000051 ],
							[ 3.762840751000056, 7.296727157000021 ],
							[ 3.762460201000067, 7.29215860000005 ],
							[ 3.759795195000038, 7.28111784500004 ],
							[ 3.760175933000028, 7.27502621900004 ],
							[ 3.760556446000066, 7.27007713200004 ],
							[ 3.761141055000053, 7.267154644000072 ],
							[ 3.761698710000076, 7.264366284000062 ],
							[ 3.761698703000036, 7.257893920000072 ],
							[ 3.762079516000028, 7.247614729000077 ],
							[ 3.762460272000055, 7.238858745000073 ],
							[ 3.763578881000058, 7.230654550000054 ],
							[ 3.763602279000054, 7.230482978000055 ],
							[ 3.768932360000065, 7.223629969000058 ],
							[ 3.77502366300007, 7.210685817000069 ],
							[ 3.780353773000058, 7.202690655000026 ],
							[ 3.777688726000065, 7.192030951000049 ],
							[ 3.777307965000034, 7.180609334000053 ],
							[ 3.782638102000021, 7.17223348400006 ],
							[ 3.787206682000033, 7.163858015000073 ],
							[ 3.789919347000023, 7.152704766000056 ],
							[ 3.790632939000034, 7.149771609000027 ],
							[ 3.793044552000026, 7.14484169800005 ],
							[ 3.794440035000036, 7.139492104000055 ],
							[ 3.79291728000004, 7.132258814000068 ],
							[ 3.781495798000037, 7.121218072000033 ],
							[ 3.752561545000049, 7.107892889000027 ],
							[ 3.73847507000005, 7.103324304000068 ],
							[ 3.727434392000021, 7.096852427000044 ],
							[ 3.724769577000075, 7.087715187000072 ],
							[ 3.727600573000075, 7.083577640000044 ],
							[ 3.731213091000029, 7.080647546000023 ],
							[ 3.73999810600003, 7.081623442000023 ],
							[ 3.758652906000066, 7.080862455000045 ],
							[ 3.783780061000073, 7.080101060000061 ],
							[ 3.797485824000034, 7.080862657000068 ],
							[ 3.816521385000044, 7.082765954000024 ],
							[ 3.823610312000028, 7.082812675000071 ],
							[ 3.842820781000057, 7.082940291000057 ],
							[ 3.874199637000061, 7.083146588000034 ],
							[ 3.890189658000054, 7.083908098000052 ],
							[ 3.916078086000027, 7.083527211000046 ],
							[ 3.938921004000065, 7.083908191000035 ],
							[ 3.953388242000074, 7.088095610000039 ],
							[ 3.960240826000074, 7.09228385800003 ],
							[ 3.961383127000033, 7.097994208000046 ],
							[ 3.965570913000022, 7.109796521000021 ],
							[ 3.969758685000045, 7.111319540000068 ],
							[ 3.978134370000021, 7.11588808700003 ],
							[ 3.995383008000033, 7.125019771000041 ],
							[ 3.997550919000048, 7.126167310000028 ],
							[ 4.005546086000038, 7.128451434000056 ],
							[ 4.012756801000023, 7.126267852000069 ],
							[ 4.019251343000064, 7.124644145000048 ],
							[ 4.026865985000029, 7.128451488000053 ],
							[ 4.045901760000049, 7.138349918000074 ],
							[ 4.057703487000026, 7.137208070000042 ],
							[ 4.069125224000061, 7.132258945000046 ],
							[ 4.085114932000067, 7.13187784400003 ],
							[ 4.088356089000058, 7.133446168000035 ],
							[ 4.096917136000059, 7.137588562000076 ],
							[ 4.115952936000042, 7.146345029000031 ],
							[ 4.139557376000027, 7.15700528900004 ],
							[ 4.15668912700005, 7.164999917000046 ],
							[ 4.162019229000066, 7.171091530000069 ],
							[ 4.168972456000063, 7.173986243000058 ],
							[ 4.171156418000066, 7.170710609000025 ],
							[ 4.172551627000075, 7.161500525000065 ],
							[ 4.173059927000054, 7.158147426000028 ],
							[ 4.177628566000067, 7.134542930000066 ],
							[ 4.182958606000057, 7.111319415000025 ],
							[ 4.186004165000043, 7.085431171000039 ],
							[ 4.190192272000047, 7.069821783000066 ],
							[ 4.194914877000031, 7.061510073000022 ],
							[ 4.195080787000052, 7.057858846000045 ],
							[ 4.195630996000034, 7.054961225000056 ],
							[ 4.197892650000028, 7.049162297000066 ],
							[ 4.200775603000068, 7.047416577000035 ],
							[ 4.203627631000074, 7.046032427000057 ],
							[ 4.205973206000067, 7.045085026000038 ],
							[ 4.210033917000032, 7.045073960000025 ],
							[ 4.213520548000076, 7.045643706000021 ],
							[ 4.218773866000049, 7.047115895000047 ],
							[ 4.223458826000069, 7.053145959000062 ],
							[ 4.227542860000028, 7.05545144000007 ],
							[ 4.233373224000047, 7.058330909000063 ],
							[ 4.236297639000043, 7.060639864000052 ],
							[ 4.240399412000045, 7.064682814000037 ],
							[ 4.244495353000048, 7.06814717900005 ],
							[ 4.24742554300002, 7.071034874000077 ],
							[ 4.250355224000032, 7.073922520000053 ],
							[ 4.25269933200002, 7.076233436000052 ],
							[ 4.256209905000048, 7.079119812000044 ],
							[ 4.261477421000052, 7.083738831000062 ],
							[ 4.264981784000042, 7.086045709000075 ],
							[ 4.268486023000037, 7.08835255200006 ],
							[ 4.272552436000069, 7.088921024000058 ],
							[ 4.276045261000036, 7.090069856000071 ],
							[ 4.284764281000037, 7.091782971000043 ],
							[ 4.287664944000028, 7.091775030000065 ],
							[ 4.292299734000039, 7.091182331000027 ],
							[ 4.29751492500003, 7.090588536000041 ],
							[ 4.311372840000047, 7.084178386000076 ],
							[ 4.315403985000046, 7.081271140000069 ],
							[ 4.334441211000069, 7.070791607000046 ],
							[ 4.340799301000061, 7.068457101000035 ],
							[ 4.347742623000045, 7.066699907000043 ],
							[ 4.352946321000047, 7.064947950000033 ],
							[ 4.357569757000022, 7.063197630000047 ],
							[ 4.361026306000042, 7.060870683000076 ],
							[ 4.365075514000068, 7.059701074000031 ],
							[ 4.368550309000057, 7.059111960000052 ],
							[ 4.370260706000067, 7.056211523000059 ],
							[ 4.370220218000043, 7.052156911000054 ],
							[ 4.370178730000021, 7.048102268000036 ],
							[ 4.370155278000027, 7.045785544000069 ],
							[ 4.370688023000071, 7.041150078000044 ],
							[ 4.370060931000069, 7.036518044000047 ],
							[ 4.371180085000049, 7.032460253000067 ],
							[ 4.376939743000037, 7.028389511000057 ],
							[ 4.378656894000073, 7.026067875000024 ],
							[ 4.376307049000047, 7.023178104000067 ],
							[ 4.371032709000076, 7.017980184000066 ],
							[ 4.370991642000035, 7.013925541000049 ],
							[ 4.371542375000047, 7.011027694000063 ],
							[ 4.372661583000024, 7.006969896000044 ],
							[ 4.373774077000064, 7.002332699000021 ],
							[ 4.374319499000023, 6.998856024000077 ],
							[ 4.376018463000037, 6.994796769000061 ],
							[ 4.382944092000059, 6.991302134000023 ],
							[ 4.386407363000046, 6.98955436600005 ],
							[ 4.390379985000038, 6.980854894000061 ],
							[ 4.400536613000043, 6.980734899000026 ],
							[ 4.409673744000031, 6.98758734200004 ],
							[ 4.416907242000036, 6.99405941200007 ],
							[ 4.424521397000035, 7.002435126000023 ],
							[ 4.438227135000034, 7.004719730000033 ],
							[ 4.454217031000042, 7.000912554000024 ],
							[ 4.463354081000034, 6.993298159000062 ],
							[ 4.480105464000076, 6.990633050000042 ],
							[ 4.488861999000051, 6.991775472000029 ],
							[ 4.496476121000057, 7.001293067000063 ],
							[ 4.509039871000027, 7.013475805000041 ],
							[ 4.516273511000065, 7.022612998000056 ],
							[ 4.522364591000041, 7.024516664000032 ],
							[ 4.526552682000045, 7.023374684000032 ],
							[ 4.534166777000053, 7.02375517400003 ],
							[ 4.542542395000055, 7.021090509000032 ],
							[ 4.55929423300006, 7.02147096300007 ],
							[ 4.577187544000026, 7.023374603000036 ],
							[ 4.592416265000054, 7.028704575000063 ],
							[ 4.604599006000058, 7.031369582000025 ],
							[ 4.609585837000054, 7.031077780000032 ],
							[ 4.606824920000065, 7.027369577000059 ],
							[ 4.60220950300004, 7.023650150000037 ],
							[ 4.595739792000074, 7.019920822000074 ],
							[ 4.590197032000049, 7.01619658200002 ],
							[ 4.584784003000038, 7.01397892500006 ],
							[ 4.581135721000066, 7.011210765000044 ],
							[ 4.579163051000023, 7.009602482000048 ],
							[ 4.574287919000028, 7.006175996000024 ],
							[ 4.572642329000075, 7.005019255000036 ],
							[ 4.568954071000064, 7.001305501000047 ],
							[ 4.564349150000055, 6.995739797000056 ],
							[ 4.561608729000056, 6.988337432000037 ],
							[ 4.560707133000051, 6.983715420000067 ],
							[ 4.558888909000075, 6.977242050000029 ],
							[ 4.55800245100005, 6.969850511000061 ],
							[ 4.557106075000036, 6.964305043000024 ],
							[ 4.556209998000043, 6.95876013000003 ],
							[ 4.557173272000057, 6.952302079000049 ],
							[ 4.55720375900006, 6.946762190000072 ],
							[ 4.557229526000071, 6.942145470000071 ],
							[ 4.55632822900003, 6.937523440000064 ],
							[ 4.556405022000035, 6.923673546000032 ],
							[ 4.557368200000042, 6.917215485000042 ],
							[ 4.556487626000035, 6.908900189000065 ],
							[ 4.55745035800004, 6.902441840000051 ],
							[ 4.557475990000057, 6.897825111000031 ],
							[ 4.557501751000075, 6.893208383000058 ],
							[ 4.557522251000023, 6.889515394000057 ],
							[ 4.554771364000032, 6.883959842000024 ],
							[ 4.553870234000044, 6.879338369000038 ],
							[ 4.55204152400006, 6.874711006000041 ],
							[ 4.550238576000027, 6.865467455000044 ],
							[ 4.549336867000022, 6.860845662000031 ],
							[ 4.549398451000059, 6.849765827000056 ],
							[ 4.548315941000055, 6.844217191000041 ],
							[ 4.547595562000026, 6.840522251000039 ],
							[ 4.542058051000026, 6.835874621000073 ],
							[ 4.53743742000006, 6.833078889000035 ],
							[ 4.53373915100002, 6.831211945000064 ],
							[ 4.529113316000064, 6.829339573000027 ],
							[ 4.524497921000034, 6.825620782000044 ],
							[ 4.519877965000035, 6.822824556000057 ],
							[ 4.515257362000057, 6.820029391000048 ],
							[ 4.502296885000021, 6.816263762000062 ],
							[ 4.498598558000026, 6.814396456000054 ],
							[ 4.49305104900003, 6.811595843000021 ],
							[ 4.487508240000068, 6.80787194800007 ],
							[ 4.476412696000068, 6.80226999100006 ],
							[ 4.472719697000059, 6.799479526000027 ],
							[ 4.467176852000023, 6.795755317000044 ],
							[ 4.45424801300004, 6.786450008000031 ],
							[ 4.445022048000055, 6.778088666000031 ],
							[ 4.439479801000061, 6.774364608000042 ],
							[ 4.433931788000052, 6.771563632000039 ],
							[ 4.425607721000063, 6.767824183000073 ],
							[ 4.422841504000075, 6.765039106000074 ],
							[ 4.418231484000046, 6.760396327000024 ],
							[ 4.415485885000066, 6.75391761800006 ],
							[ 4.414579384000035, 6.750219203000029 ],
							[ 4.413682874000074, 6.744674150000037 ],
							[ 4.410926780000068, 6.740042325000047 ],
							[ 4.40724377600003, 6.735405082000057 ],
							[ 4.403565956000023, 6.729844030000038 ],
							[ 4.398033626000029, 6.724273224000058 ],
							[ 4.392501336000066, 6.718702350000058 ],
							[ 4.389735168000072, 6.715917009000066 ],
							[ 4.388823470000034, 6.713141993000022 ],
							[ 4.388854501000026, 6.707602038000061 ],
							[ 4.388884959000052, 6.702062057000035 ],
							[ 4.388915970000028, 6.696522081000069 ],
							[ 4.388951825000049, 6.690058643000043 ],
							[ 4.388982706000036, 6.684518682000032 ],
							[ 4.389940699000022, 6.678984147000051 ],
							[ 4.389039061000062, 6.674362191000057 ],
							[ 4.387210331000063, 6.669735058000072 ],
							[ 4.384439462000046, 6.667872870000053 ],
							[ 4.379813719000026, 6.666000827000062 ],
							[ 4.37612013100005, 6.663210450000065 ],
							[ 4.37615106100003, 6.657669908000059 ],
							[ 4.376176828000041, 6.653053396000075 ],
							[ 4.376202594000063, 6.648436884000034 ],
							[ 4.377055184000028, 6.645117222000067 ],
							[ 4.379962498000054, 6.639224095000031 ],
							[ 4.382769537000058, 6.634622595000053 ],
							[ 4.387431616000072, 6.630032013000061 ],
							[ 4.390223562000074, 6.628200579000065 ],
							[ 4.393937057000073, 6.627297773000066 ],
							[ 4.403224473000023, 6.624578843000052 ],
							[ 4.406943262000027, 6.622753283000065 ],
							[ 4.410662711000043, 6.620926823000048 ],
							[ 4.413454037000065, 6.619095797000057 ],
							[ 4.418105574000037, 6.616351188000067 ],
							[ 4.424611102000028, 6.613617538000028 ],
							[ 4.42647074100006, 6.612704241000074 ],
							[ 4.454317098000047, 6.607318383000063 ],
							[ 4.458051707000038, 6.602722179000068 ],
							[ 4.459931819000076, 6.59811593400002 ],
							[ 4.460884577000058, 6.593504409000047 ],
							[ 4.462774753000076, 6.587051506000023 ],
							[ 4.465224715000033, 6.582136630000036 ],
							[ 4.464685884000062, 6.576904685000045 ],
							[ 4.46657084800006, 6.571375497000076 ],
							[ 4.467523582000069, 6.566763969000021 ],
							[ 4.470346386000074, 6.559392505000062 ],
							[ 4.473174114000074, 6.551097833000028 ],
							[ 4.475064724000049, 6.544644866000056 ],
							[ 4.47694447300006, 6.540038525000057 ],
							[ 4.478824603000021, 6.535431919000075 ],
							[ 4.482574506000049, 6.528065599000058 ],
							[ 4.486324267000043, 6.520700072000068 ],
							[ 4.48914190000005, 6.514251659000024 ],
							[ 4.491026875000045, 6.508722358000057 ],
							[ 4.496610628000042, 6.505059626000048 ],
							[ 4.499381974000073, 6.506921843000043 ],
							[ 4.499831316000041, 6.508754932000045 ],
							[ 4.504565867000053, 6.514733298000067 ],
							[ 4.508673141000031, 6.520188148000045 ],
							[ 4.517124756000044, 6.531837625000037 ],
							[ 4.519393637000064, 6.547096104000047 ],
							[ 4.521695851000061, 6.559653036000043 ],
							[ 4.525611508000054, 6.57952073000007 ],
							[ 4.525959884000031, 6.580096971000046 ],
							[ 4.52643729700003, 6.580886458000066 ],
							[ 4.528610209000021, 6.586492625000062 ],
							[ 4.532298145000027, 6.590206623000029 ],
							[ 4.535043720000033, 6.596685340000022 ],
							[ 4.537379163000026, 6.60061057300004 ],
							[ 4.537691195000036, 6.601134929000068 ],
							[ 4.542405078000058, 6.606883024000069 ],
							[ 4.547004711000056, 6.613371778000044 ],
							[ 4.551615199000025, 6.618014379000044 ],
							[ 4.555293151000058, 6.623574791000067 ],
							[ 4.560433808000028, 6.628088345000037 ],
							[ 4.561747480000065, 6.630073981000066 ],
							[ 4.564508480000029, 6.633782906000022 ],
							[ 4.567274613000052, 6.636568613000065 ],
							[ 4.572300469000027, 6.63910581600004 ],
							[ 4.581601440000043, 6.633065120000026 ],
							[ 4.582822126000053, 6.61670242100007 ],
							[ 4.577916013000049, 6.599242232000051 ],
							[ 4.575093690000074, 6.578736685000024 ],
							[ 4.581503872000042, 6.556295185000067 ],
							[ 4.588915120000024, 6.543191690000072 ],
							[ 4.588255923000077, 6.536082872000065 ],
							[ 4.588248774000022, 6.532358700000032 ],
							[ 4.594794749000073, 6.523147631000029 ],
							[ 4.597601845000042, 6.518546194000066 ],
							[ 4.599482042000034, 6.513939820000076 ],
							[ 4.604174651000051, 6.503809044000036 ],
							[ 4.606054249000067, 6.499202349000029 ],
							[ 4.605220786000075, 6.492155060000073 ],
							[ 4.603319161000059, 6.490877209000075 ],
							[ 4.593438008000021, 6.492928491000043 ],
							[ 4.586972163000041, 6.494270667000023 ],
							[ 4.564331022000033, 6.498971379000068 ],
							[ 4.565273839000042, 6.496206268000037 ],
							[ 4.565294220000055, 6.492513219000045 ],
							[ 4.566237013000034, 6.489748087000066 ],
							[ 4.56626277700002, 6.485131839000076 ],
							[ 4.566283173000045, 6.481438233000063 ],
							[ 4.566308941000045, 6.476821427000061 ],
							[ 4.565402015000075, 6.473123071000032 ],
							[ 4.564495532000024, 6.469424717000038 ],
							[ 4.563593917000048, 6.464802617000032 ],
							[ 4.562682090000067, 6.462027458000023 ],
							[ 4.561780918000068, 6.457405894000033 ],
							[ 4.559962306000045, 6.450932356000067 ],
							[ 4.556299700000068, 6.442601803000059 ],
							[ 4.554461049000054, 6.439821293000023 ],
							[ 4.549860927000054, 6.433332558000075 ],
							[ 4.546183133000056, 6.427772028000049 ],
							[ 4.542505325000036, 6.422211180000033 ],
							[ 4.541588322000052, 6.420359701000052 ],
							[ 4.539754353000035, 6.416655921000029 ],
							[ 4.538778388000026, 6.414685036000037 ],
							[ 4.537933589000033, 6.413980681000055 ],
							[ 4.536625534000052, 6.413173443000062 ],
							[ 4.534965011000054, 6.412449800000047 ],
							[ 4.533648400000061, 6.412345838000022 ],
							[ 4.531705034000026, 6.413201290000075 ],
							[ 4.53027165900005, 6.415470040000059 ],
							[ 4.529106130000059, 6.417389925000066 ],
							[ 4.528563979000069, 6.418614307000041 ],
							[ 4.527670431000047, 6.419834414000036 ],
							[ 4.525987364000059, 6.420956971000066 ],
							[ 4.525374692000071, 6.420773704000055 ],
							[ 4.524321480000026, 6.420672651000075 ],
							[ 4.521951758000057, 6.420467890000054 ],
							[ 4.519146052000053, 6.419993822000038 ],
							[ 4.512144071000023, 6.417797864000022 ],
							[ 4.509170184000027, 6.416706441000031 ],
							[ 4.507417591000035, 6.416333100000031 ],
							[ 4.504097594000029, 6.415534957000034 ],
							[ 4.503599227000052, 6.415532225000049 ],
							[ 4.500823005000029, 6.414593789000037 ],
							[ 4.496197206000033, 6.412721182000041 ],
							[ 4.489712302000044, 6.411761883000054 ],
							[ 4.479822716000058, 6.409050120000074 ],
							[ 4.478621458000021, 6.405237120000038 ],
							[ 4.475855901000045, 6.402451547000055 ],
							[ 4.474944039000036, 6.399676429000067 ],
							[ 4.473125430000039, 6.393202701000064 ],
							[ 4.474683285000026, 6.388328157000046 ],
							[ 4.477510882000047, 6.38757520300004 ],
							[ 4.480064388000073, 6.386895722000077 ],
							[ 4.482433305000029, 6.386790731000076 ],
							[ 4.487063878000072, 6.387739753000062 ],
							[ 4.49168972700005, 6.389612186000022 ],
							[ 4.495387974000039, 6.391479367000045 ],
							[ 4.499086404000025, 6.393346809000036 ],
							[ 4.504639638000071, 6.395223979000036 ],
							[ 4.509270224000034, 6.396172995000029 ],
							[ 4.514828218000048, 6.397127112000021 ],
							[ 4.517614757000047, 6.396219329000076 ],
							[ 4.521328977000053, 6.395316673000025 ],
							[ 4.524115504000065, 6.39440884000004 ],
							[ 4.531543270000043, 6.392602776000047 ],
							[ 4.535262144000058, 6.390777065000066 ],
							[ 4.539908367000066, 6.388956115000042 ],
							[ 4.546409131000075, 6.387145511000028 ],
							[ 4.555455705000043, 6.385737071000051 ],
							[ 4.560848184000065, 6.382564455000022 ],
							[ 4.567192989000034, 6.382564494000064 ],
							[ 4.575757958000054, 6.380978461000041 ],
							[ 4.582102275000068, 6.378123387000073 ],
							[ 4.585908956000026, 6.375902570000051 ],
							[ 4.590033108000057, 6.371778831000029 ],
							[ 4.594156738000038, 6.369241287000023 ],
							[ 4.596694509000031, 6.368289517000051 ],
							[ 4.598449739000046, 6.366196197000022 ],
							[ 4.597548073000041, 6.361574719000032 ],
							[ 4.59756855400002, 6.357881099000053 ],
							[ 4.597594198000024, 6.353264273000036 ],
							[ 4.597625279000056, 6.347724521000032 ],
							[ 4.595837561000053, 6.335710970000036 ],
							[ 4.594925829000033, 6.332935876000022 ],
							[ 4.593091890000039, 6.32923220400005 ],
							[ 4.590335819000074, 6.324600236000038 ],
							[ 4.58758543600004, 6.319044617000031 ],
							[ 4.584824039000068, 6.315335790000063 ],
							[ 4.582063117000075, 6.311627373000022 ],
							[ 4.578468305000058, 6.30842447200007 ],
							[ 4.57561402500005, 6.30420450400004 ],
							[ 4.571910326000022, 6.303260751000039 ],
							[ 4.567274557000076, 6.303234997000061 ],
							[ 4.563560557000073, 6.304137688000026 ],
							[ 4.55984170000005, 6.305963977000033 ],
							[ 4.556122806000076, 6.307790229000034 ],
							[ 4.551476415000025, 6.309611374000042 ],
							[ 4.548674629000061, 6.313289147000035 ],
							[ 4.545877509000036, 6.316043407000052 ],
							[ 4.543090800000073, 6.316951231000075 ],
							[ 4.535632186000043, 6.324296986000036 ],
							[ 4.53097583300007, 6.327964849000068 ],
							[ 4.52817340200005, 6.331642607000049 ],
							[ 4.518845050000039, 6.341747799000075 ],
							[ 4.513251277000052, 6.347257131000049 ],
							[ 4.497422214000039, 6.359172776000037 ],
							[ 4.490937248000023, 6.358214040000064 ],
							[ 4.48631137700005, 6.35634131200004 ],
							[ 4.48168092100002, 6.355392555000037 ],
							[ 4.478904233000037, 6.354453661000036 ],
							[ 4.470110463000026, 6.349720413000057 ],
							[ 4.465037881000057, 6.346990101000074 ],
							[ 4.463188129000059, 6.346056349000037 ],
							[ 4.460481124000069, 6.343330435000041 ],
							[ 4.440857435000055, 6.34921077000007 ],
							[ 4.415401995000025, 6.358120316000054 ],
							[ 4.394188811000049, 6.362787370000035 ],
							[ 4.373612365000042, 6.366817472000037 ],
							[ 4.354945163000025, 6.369787161000033 ],
							[ 4.350837715000068, 6.371037511000054 ],
							[ 4.350837695000052, 6.377373772000055 ],
							[ 4.350837698000021, 6.389730584000063 ],
							[ 4.350837702000035, 6.415349109000033 ],
							[ 4.350837707000039, 6.421683377000022 ],
							[ 4.35083774900005, 6.427625099000068 ],
							[ 4.350093832000027, 6.429857240000047 ],
							[ 4.34761424200002, 6.431345019000048 ],
							[ 4.344638305000046, 6.433824603000062 ],
							[ 4.343398618000037, 6.435808250000036 ],
							[ 4.337199247000058, 6.439528069000062 ],
							[ 4.332735513000046, 6.43952798600003 ],
							[ 4.324056663000022, 6.437792262000073 ],
							[ 4.316617421000046, 6.438040255000033 ],
							[ 4.309226462000026, 6.439470816000039 ],
							[ 4.303938362000054, 6.439470836000055 ],
							[ 4.301939901000026, 6.438798896000037 ],
							[ 4.29872178200003, 6.43518292300007 ],
							[ 4.295577053000045, 6.434611238000059 ],
							[ 4.291146817000026, 6.436612182000033 ],
							[ 4.287573395000038, 6.436040265000031 ],
							[ 4.284000380000066, 6.436755303000041 ],
							[ 4.281284852000056, 6.438755897000021 ],
							[ 4.278997934000074, 6.437898665000034 ],
							[ 4.276282372000026, 6.439613246000022 ],
							[ 4.275138789000039, 6.441900275000023 ],
							[ 4.272423218000029, 6.442757967000034 ],
							[ 4.268421627000066, 6.442471979000061 ],
							[ 4.265706062000049, 6.442900627000029 ],
							[ 4.263808781000023, 6.443560554000044 ],
							[ 4.259145677000049, 6.442461051000066 ],
							[ 4.254343550000044, 6.441328609000038 ],
							[ 4.253342638000049, 6.439041530000054 ],
							[ 4.253963956000064, 6.436204899000074 ],
							[ 4.253271547000054, 6.434325089000026 ],
							[ 4.249984272000063, 6.433753403000026 ],
							[ 4.245267316000024, 6.434468201000072 ],
							[ 4.243409575000044, 6.436183494000034 ],
							[ 4.236883159000058, 6.438172931000054 ],
							[ 4.237406736000025, 6.441900222000072 ],
							[ 4.235691493000047, 6.444758762000049 ],
							[ 4.233404685000039, 6.445473654000068 ],
							[ 4.230688985000029, 6.44547361900004 ],
							[ 4.227973429000031, 6.444758834000027 ],
							[ 4.22582961400002, 6.445616283000049 ],
							[ 4.223792609000043, 6.448614995000071 ],
							[ 4.221828004000031, 6.450475729000061 ],
							[ 4.219969701000025, 6.455478139000036 ],
							[ 4.21875471200002, 6.459766041000023 ],
							[ 4.217325681000034, 6.463053265000042 ],
							[ 4.215181864000044, 6.463339233000056 ],
							[ 4.213389431000053, 6.464474753000047 ],
							[ 4.207791753000038, 6.464179123000065 ],
							[ 4.205748566000068, 6.463053314000035 ],
							[ 4.203461599000036, 6.461909892000051 ],
							[ 4.200460493000037, 6.463196252000046 ],
							[ 4.193742806000046, 6.463196295000046 ],
							[ 4.192170607000037, 6.465483111000026 ],
							[ 4.189598101000058, 6.46648364400005 ],
							[ 4.186025192000045, 6.467055307000066 ],
							[ 4.182880323000063, 6.468484319000027 ],
							[ 4.177449655000032, 6.470056441000054 ],
							[ 4.174734128000068, 6.47205795800005 ],
							[ 4.172018566000077, 6.473629885000037 ],
							[ 4.168302066000024, 6.474201743000037 ],
							[ 4.164872162000052, 6.474630386000058 ],
							[ 4.161073195000029, 6.474720079000065 ],
							[ 4.160954526000069, 6.476819114000023 ],
							[ 4.160012800000061, 6.481776816000036 ],
							[ 4.159441002000051, 6.487207961000024 ],
							[ 4.158382893000066, 6.489767925000024 ],
							[ 4.157010980000052, 6.492209851000041 ],
							[ 4.155010269000059, 6.496640615000047 ],
							[ 4.156153691000043, 6.49878459100006 ],
							[ 4.160584477000043, 6.50064280600003 ],
							[ 4.165502056000037, 6.502708900000073 ],
							[ 4.167706033000059, 6.503632991000075 ],
							[ 4.173997915000029, 6.508666917000028 ],
							[ 4.174899126000071, 6.510542849000046 ],
							[ 4.176371138000036, 6.512509937000061 ],
							[ 4.17833427000005, 6.51513095200005 ],
							[ 4.181109885000069, 6.516143886000066 ],
							[ 4.185030038000036, 6.517334076000054 ],
							[ 4.191804906000073, 6.518972048000023 ],
							[ 4.194009760000029, 6.519895155000029 ],
							[ 4.198338942000021, 6.522307730000023 ],
							[ 4.201116097000067, 6.523320253000065 ],
							[ 4.203321027000072, 6.524242731000072 ],
							[ 4.206097071000045, 6.525256054000067 ],
							[ 4.211143909000043, 6.528088491000062 ],
							[ 4.214440333000027, 6.531934308000075 ],
							[ 4.222256721000065, 6.537740708000058 ],
							[ 4.224712860000068, 6.543100378000076 ],
							[ 4.227616367000053, 6.551586627000063 ],
							[ 4.226499601000057, 6.553596635000076 ],
							[ 4.228733101000046, 6.557616090000067 ],
							[ 4.225383271000055, 6.561189173000059 ],
							[ 4.223108233000062, 6.561960685000031 ],
							[ 4.221983437000063, 6.565439835000063 ],
							[ 4.220036558000061, 6.56751670400007 ],
							[ 4.218013327000051, 6.569675371000073 ],
							[ 4.217413405000059, 6.572402830000044 ],
							[ 4.215333421000025, 6.577714878000052 ],
							[ 4.212653667000041, 6.578608479000025 ],
							[ 4.211760601000037, 6.581735105000064 ],
							[ 4.207517196000026, 6.583968116000051 ],
							[ 4.204390985000032, 6.585531244000038 ],
							[ 4.201487467000049, 6.586871162000023 ],
							[ 4.199585025000033, 6.588473749000059 ],
							[ 4.198807673000033, 6.585754805000022 ],
							[ 4.194118048000064, 6.585531365000065 ],
							[ 4.186748515000033, 6.58486117800004 ],
							[ 4.179602185000022, 6.586424926000063 ],
							[ 4.178262167000071, 6.588657932000046 ],
							[ 4.174912405000043, 6.589774741000042 ],
							[ 4.171562692000066, 6.591560728000047 ],
							[ 4.169150354000067, 6.592352989000062 ],
							[ 4.166342255000075, 6.593373705000033 ],
							[ 4.162237171000072, 6.592182036000054 ],
							[ 4.159720937000031, 6.590592774000072 ],
							[ 4.155615744000045, 6.586752462000049 ],
							[ 4.150955124000063, 6.581296173000055 ],
							[ 4.144799029000069, 6.578747467000028 ],
							[ 4.141882782000039, 6.577807297000049 ],
							[ 4.139138439000021, 6.576644137000073 ],
							[ 4.133595717000048, 6.574148053000044 ],
							[ 4.131805721000035, 6.573222749000024 ],
							[ 4.130525459000069, 6.57207898300004 ],
							[ 4.128857337000056, 6.570985747000066 ],
							[ 4.124665239000024, 6.568559199000049 ],
							[ 4.122209023000039, 6.566325564000067 ],
							[ 4.11997556700004, 6.564985652000075 ],
							[ 4.116402626000024, 6.563422710000054 ],
							[ 4.110596169000075, 6.558286054000064 ],
							[ 4.11059619100007, 6.555606404000059 ],
							[ 4.109479391000036, 6.552256484000054 ],
							[ 4.109479467000028, 6.548430308000036 ],
							[ 4.10168021800007, 6.547468365000043 ],
							[ 4.094421289000024, 6.546886518000065 ],
							[ 4.093583171000034, 6.549289300000055 ],
							[ 4.095366999000021, 6.550732997000068 ],
							[ 4.091971917000023, 6.555282646000023 ],
							[ 4.089619192000043, 6.555962133000037 ],
							[ 4.091167493000057, 6.558509378000053 ],
							[ 4.090641964000042, 6.561825173000045 ],
							[ 4.088607769000021, 6.56263864400006 ],
							[ 4.087188729000047, 6.560910701000068 ],
							[ 4.086427775000061, 6.55941824000007 ],
							[ 4.08293294300006, 6.559184806000076 ],
							[ 4.080267867000032, 6.560401511000066 ],
							[ 4.076335390000054, 6.561621789000071 ],
							[ 4.074369379000075, 6.562299600000074 ],
							[ 4.071724860000074, 6.562435557000072 ],
							[ 4.068231056000059, 6.563551991000054 ],
							[ 4.064938036000058, 6.562396160000048 ],
							[ 4.06544064600007, 6.566706134000071 ],
							[ 4.067058558000042, 6.569550010000057 ],
							[ 4.066284135000046, 6.571547484000064 ],
							[ 4.066097661000072, 6.575692745000026 ],
							[ 4.06488654900005, 6.580557285000054 ],
							[ 4.063204774000042, 6.582680706000076 ],
							[ 4.062079381000046, 6.586159078000037 ],
							[ 4.061119977000033, 6.588450784000031 ],
							[ 4.059261802000037, 6.594275896000056 ],
							[ 4.057499415000052, 6.596439745000055 ],
							[ 4.057574356000032, 6.599493453000036 ],
							[ 4.055875357000048, 6.603553282000064 ],
							[ 4.055933435000043, 6.605656074000024 ],
							[ 4.056022636000023, 6.608876141000053 ],
							[ 4.056531843000073, 6.611081664000039 ],
							[ 4.053805902000022, 6.614354157000037 ],
							[ 4.051404970000021, 6.616016498000022 ],
							[ 4.050481298000022, 6.619155888000023 ],
							[ 4.053805861000058, 6.621002682000039 ],
							[ 4.056575741000074, 6.624696121000056 ],
							[ 4.05768396600007, 6.627466735000041 ],
							[ 4.05860761200006, 6.629867478000051 ],
							[ 4.05786889500007, 6.633930118000023 ],
							[ 4.058792162000032, 6.635962053000071 ],
							[ 4.061562482000056, 6.636700554000072 ],
							[ 4.064332561000072, 6.636146418000067 ],
							[ 4.066693271000076, 6.634893777000059 ],
							[ 4.068395594000037, 6.633191704000069 ],
							[ 4.070426931000043, 6.633191641000053 ],
							[ 4.074559236000027, 6.635638607000033 ],
							[ 4.076706399000045, 6.637070047000066 ],
							[ 4.078922810000051, 6.637439243000074 ],
							[ 4.081508188000043, 6.637624303000052 ],
							[ 4.084463184000072, 6.639840023000033 ],
							[ 4.085386228000061, 6.642795159000059 ],
							[ 4.087972182000044, 6.644827005000025 ],
							[ 4.089633982000066, 6.648335469000074 ],
							[ 4.09055760800004, 6.650736403000053 ],
							[ 4.091480764000039, 6.652583154000069 ],
							[ 4.095231067000043, 6.651519377000056 ],
							[ 4.092588920000026, 6.656461745000058 ],
							[ 4.093327558000055, 6.65867763500006 ],
							[ 4.091850295000029, 6.660339906000047 ],
							[ 4.091480725000054, 6.662371669000038 ],
							[ 4.09037253200006, 6.666065132000028 ],
							[ 4.091850294000039, 6.667911961000073 ],
							[ 4.094251211000028, 6.670681975000036 ],
							[ 4.092773395000052, 6.672528763000059 ],
							[ 4.092404302000034, 6.675114726000061 ],
							[ 4.091296251000074, 6.676961370000072 ],
							[ 4.091480698000055, 6.67899264700003 ],
							[ 4.078527024000039, 6.679176339000037 ],
							[ 4.073162055000068, 6.679252569000028 ],
							[ 4.050180392000073, 6.680025123000064 ],
							[ 4.019699532000061, 6.679941469000028 ],
							[ 3.979125550000049, 6.682415390000074 ],
							[ 3.947232830000075, 6.680181193000067 ],
							[ 3.919335189000037, 6.68066120900005 ],
							[ 3.916836717000024, 6.680703983000058 ],
							[ 3.815373653000051, 6.682108532000029 ],
							[ 3.707865025000046, 6.68303398300003 ],
							[ 3.699962121000056, 6.682374855000035 ],
							[ 3.596421501000066, 6.682339550000052 ],
							[ 3.586022627000034, 6.682468057000051 ],
							[ 3.585492627000065, 6.682901078000043 ],
							[ 3.533058968000034, 6.689738283000054 ],
							[ 3.459111612000072, 6.687321545000032 ],
							[ 3.463662588000034, 6.683956086000023 ],
							[ 3.46796630700004, 6.679330922000076 ],
							[ 3.465392810000026, 6.67374131300005 ],
							[ 3.464262462000022, 6.671816912000054 ],
							[ 3.464591333000044, 6.668950854000059 ],
							[ 3.455652791000034, 6.668368284000053 ],
							[ 3.453413326000032, 6.671054222000066 ],
							[ 3.451019398000028, 6.675666315000058 ],
							[ 3.449935015000051, 6.675432281000042 ],
							[ 3.446922398000027, 6.673210522000034 ],
							[ 3.44583142700003, 6.673411586000043 ],
							[ 3.442594701000075, 6.67162191500006 ],
							[ 3.442200700000058, 6.670141268000066 ],
							[ 3.442171304000055, 6.667244916000072 ],
							[ 3.444489234000059, 6.664900228000022 ],
							[ 3.449467450000043, 6.65784461100003 ],
							[ 3.452574966000043, 6.652251362000072 ],
							[ 3.454292075000069, 6.644476771000029 ],
							[ 3.456408977000024, 6.641914750000069 ],
							[ 3.457663547000038, 6.639433479000047 ],
							[ 3.448281781000048, 6.640582923000068 ],
							[ 3.434690753000041, 6.639135703000022 ],
							[ 3.387346415000025, 6.63861220800004 ],
							[ 3.386295257000029, 6.638892825000028 ],
							[ 3.383424337000065, 6.639900981000039 ],
							[ 3.380329197000037, 6.640830495000046 ],
							[ 3.370129142000053, 6.64118366200006 ],
							[ 3.369425862000071, 6.642931548000035 ],
							[ 3.365576726000029, 6.648941710000031 ],
							[ 3.359876135000036, 6.653189206000036 ],
							[ 3.356962429000021, 6.65473374100003 ],
							[ 3.352861799000038, 6.658283432000076 ],
							[ 3.352653629000031, 6.658717711000065 ],
							[ 3.347636259000069, 6.669185373000062 ],
							[ 3.344835586000045, 6.672755034000033 ],
							[ 3.34279747000005, 6.673735040000054 ],
							[ 3.334996210000043, 6.673614469000029 ],
							[ 3.328786269000034, 6.673373946000027 ],
							[ 3.323865431000058, 6.673875677000069 ],
							[ 3.314664787000027, 6.675467070000025 ],
							[ 3.311308329000042, 6.677582587000074 ],
							[ 3.302441805000058, 6.680912986000067 ],
							[ 3.298371911000061, 6.682439352000074 ],
							[ 3.29604682300004, 6.683270205000042 ],
							[ 3.290516343000036, 6.685785200000055 ],
							[ 3.288480260000028, 6.686620510000068 ],
							[ 3.287864544000058, 6.687031433000072 ],
							[ 3.28234700400003, 6.690715383000054 ],
							[ 3.274172866000072, 6.695934704000024 ],
							[ 3.27184997400002, 6.696620752000058 ],
							[ 3.267937722000056, 6.69728219600006 ],
							[ 3.26459896700004, 6.69824139900004 ],
							[ 3.262429504000067, 6.698352113000055 ],
							[ 3.262002861000042, 6.697911903000033 ],
							[ 3.262496200000044, 6.69640229700002 ],
							[ 3.26253597300007, 6.695919368000034 ],
							[ 3.261134371000026, 6.693116227000075 ],
							[ 3.259861725000064, 6.690934322000032 ],
							[ 3.259441050000021, 6.690226274000054 ],
							[ 3.254357565000021, 6.681670770000039 ],
							[ 3.251984340000035, 6.676463996000052 ],
							[ 3.249513651000029, 6.672148177000054 ],
							[ 3.248302677000027, 6.670033019000073 ],
							[ 3.246590126000058, 6.667577633000064 ],
							[ 3.244319439000037, 6.664321889000064 ],
							[ 3.241841081000075, 6.659866261000047 ],
							[ 3.241377363000026, 6.654182996000031 ],
							[ 3.240473275000056, 6.64819914900005 ],
							[ 3.240011200000026, 6.645865998000033 ],
							[ 3.239293345000021, 6.642240946000072 ],
							[ 3.238851300000022, 6.640009252000027 ],
							[ 3.23937010800006, 6.633987325000021 ],
							[ 3.239337185000068, 6.630731948000061 ],
							[ 3.241477246000045, 6.628627356000038 ],
							[ 3.241439128000025, 6.62532668800003 ],
							[ 3.241116767000051, 6.623126012000057 ],
							[ 3.240591056000028, 6.618102212000053 ],
							[ 3.240007623000054, 6.616025448000073 ],
							[ 3.237481129000059, 6.61411621700006 ],
							[ 3.235311051000053, 6.609843721000061 ],
							[ 3.233282788000054, 6.605851246000043 ],
							[ 3.229724176000047, 6.601042213000028 ],
							[ 3.216072574000066, 6.595005580000077 ],
							[ 3.207470873000034, 6.588375166000048 ],
							[ 3.199406865000071, 6.580310895000025 ],
							[ 3.196898010000041, 6.576189628000066 ],
							[ 3.196181066000065, 6.573322177000023 ],
							[ 3.196377288000065, 6.570620963000067 ],
							[ 3.19743561100006, 6.566333236000048 ],
							[ 3.200840473000028, 6.559164986000042 ],
							[ 3.20155718500007, 6.555401734000043 ],
							[ 3.201736432000075, 6.552176555000074 ],
							[ 3.203928961000031, 6.547192620000033 ],
							[ 3.203924914000027, 6.544594765000056 ],
							[ 3.204818967000051, 6.542418947000044 ],
							[ 3.20522190600002, 6.539588995000031 ],
							[ 3.205135109000025, 6.536102710000023 ],
							[ 3.20496702600002, 6.533184042000073 ],
							[ 3.203960405000032, 6.529477442000029 ],
							[ 3.200002203000054, 6.527748187000043 ],
							[ 3.197072520000063, 6.524859805000062 ],
							[ 3.194574370000055, 6.523625868000067 ],
							[ 3.191240052000069, 6.522936829000059 ],
							[ 3.189397571000029, 6.521421323000027 ],
							[ 3.186571615000048, 6.519096884000021 ],
							[ 3.184807786000022, 6.516785071000072 ],
							[ 3.18245769300006, 6.513895604000027 ],
							[ 3.179516071000023, 6.509849 ],
							[ 3.177074440000069, 6.508471149000059 ],
							[ 3.174613267000041, 6.507490034000057 ],
							[ 3.171061060000056, 6.508788914000036 ],
							[ 3.168775107000044, 6.508430907000047 ],
							[ 3.166569944000059, 6.507507804000056 ],
							[ 3.163223052000035, 6.50640596300002 ],
							[ 3.159874908000063, 6.50530490400007 ],
							[ 3.157017974000041, 6.504858016000071 ],
							[ 3.155649450000055, 6.501806247000047 ],
							[ 3.147514559000058, 6.502919236000025 ],
							[ 3.143634063000036, 6.504502853000076 ],
							[ 3.140777119000063, 6.504056968000043 ],
							[ 3.136205907000033, 6.503343087000076 ],
							[ 3.13343, 6.502329814000063 ],
							[ 3.130162941000037, 6.500661745000059 ],
							[ 3.123716111000022, 6.500812960000076 ],
							[ 3.12165927500007, 6.500112408000064 ],
							[ 3.118412007000074, 6.501140965000047 ],
							[ 3.11433198900005, 6.501081898000052 ],
							[ 3.111393925000073, 6.501201958000024 ],
							[ 3.109108907000063, 6.500844998000048 ],
							[ 3.103946204000067, 6.499459311000066 ],
							[ 3.101540839000052, 6.499492355000029 ],
							[ 3.100608909000073, 6.500694573000033 ],
							[ 3.095901619000074, 6.501701958000069 ],
							[ 3.094720844000051, 6.501485364000075 ],
							[ 3.091651972000022, 6.502498208000077 ],
							[ 3.081999969000037, 6.503586091000045 ],
							[ 3.073605827000051, 6.503761193000059 ],
							[ 3.064279599000031, 6.50392099000004 ],
							[ 3.046273687000053, 6.502695987000038 ],
							[ 3.030138255000054, 6.503189509000038 ],
							[ 3.024404101000073, 6.504091071000062 ],
							[ 3.023551576000045, 6.504813368000043 ],
							[ 3.009834730000023, 6.507387659000074 ],
							[ 2.99210389700005, 6.508340927000063 ],
							[ 2.985538159000043, 6.510720724000066 ],
							[ 2.969992340000033, 6.511086765000073 ],
							[ 2.955684175000044, 6.51178376200005 ],
							[ 2.948548061000054, 6.511043833000031 ],
							[ 2.940448968000055, 6.512153678000061 ],
							[ 2.934545436000064, 6.512055923000048 ],
							[ 2.927114087000064, 6.510378045000039 ],
							[ 2.923375232000069, 6.510938058000022 ],
							[ 2.921485196000049, 6.512461372000075 ],
							[ 2.912132825000072, 6.514171536000049 ],
							[ 2.905638835000047, 6.512197940000021 ],
							[ 2.901930985000035, 6.510892397000021 ],
							[ 2.898197266000068, 6.511141057000032 ],
							[ 2.886929308000049, 6.510953090000044 ],
							[ 2.888858791000075, 6.50834366600003 ],
							[ 2.889995567000028, 6.506023981000055 ],
							[ 2.892274856000029, 6.501963203000059 ],
							[ 2.89448905100005, 6.499014275000036 ],
							[ 2.89630698600007, 6.495014715000025 ],
							[ 2.896670586000027, 6.491742699000042 ],
							[ 2.89667058200007, 6.487743368000054 ],
							[ 2.895266543000048, 6.48485080100005 ],
							[ 2.893034696000029, 6.480108271000063 ],
							[ 2.889399057000048, 6.47610902100007 ],
							[ 2.885399822000068, 6.473564244000045 ],
							[ 2.879946236000023, 6.469928225000046 ],
							[ 2.873765468000045, 6.470655467000029 ],
							[ 2.868675471000074, 6.471746511000049 ],
							[ 2.866494178000039, 6.47138269200002 ],
							[ 2.859586250000064, 6.467747147000068 ],
							[ 2.857807631000071, 6.460039958000038 ],
							[ 2.855895994000036, 6.45780910700006 ],
							[ 2.854747054000029, 6.453577940000059 ],
							[ 2.853846083000064, 6.451702045000047 ],
							[ 2.852550747000066, 6.449971292000043 ],
							[ 2.842185499000038, 6.443988688000047 ],
							[ 2.833001370000034, 6.443141082000068 ],
							[ 2.831588512000053, 6.444836745000032 ],
							[ 2.828480006000063, 6.446814409000069 ],
							[ 2.823676100000057, 6.451477146000059 ],
							[ 2.818306933000031, 6.454585503000033 ],
							[ 2.813079127000037, 6.45599844700007 ],
							[ 2.806297070000028, 6.458824703000062 ],
							[ 2.799232483000026, 6.460095886000033 ],
							[ 2.79414605900007, 6.459389620000024 ],
							[ 2.789907216000074, 6.457411175000061 ],
							[ 2.786374806000026, 6.455009447000066 ],
							[ 2.784114127000066, 6.452748821000057 ],
							[ 2.779169079000042, 6.45204248500005 ],
							[ 2.775919200000033, 6.452466567000045 ],
							[ 2.773799895000025, 6.452466422000043 ],
							[ 2.768713243000036, 6.451335868000058 ],
							[ 2.763626806000048, 6.451759949000063 ],
							[ 2.758681534000061, 6.452042509000023 ],
							[ 2.75260591600005, 6.451194716000032 ],
							[ 2.747943410000062, 6.44935805700004 ],
							[ 2.744410998000035, 6.449075298000025 ],
							[ 2.73960708900006, 6.454444354000032 ],
							[ 2.735445981000055, 6.457930508000061 ],
							[ 2.732699870000033, 6.457930419000036 ],
							[ 2.722883458000069, 6.458657864000031 ],
							[ 2.719611412000063, 6.459384884000031 ],
							[ 2.713794237000059, 6.459021061000044 ],
							[ 2.710158354000043, 6.456112888000064 ],
							[ 2.702610966000066, 6.452376882000067 ],
							[ 2.703252550000059, 6.456667379000066 ],
							[ 2.705196388000047, 6.461110552000036 ],
							[ 2.704505678000032, 6.463678714000025 ],
							[ 2.70626377800005, 6.465411734000043 ],
							[ 2.709193703000039, 6.468299904000048 ],
							[ 2.711537599000053, 6.470610176000037 ],
							[ 2.712733043000071, 6.47408236800004 ],
							[ 2.713952305000021, 6.479871325000033 ],
							[ 2.713413235000075, 6.483927278000067 ],
							[ 2.710536485000034, 6.486252442000023 ],
							[ 2.710577484000055, 6.490306503000056 ],
							[ 2.711198812000021, 6.494359494000037 ],
							[ 2.709470277000037, 6.495522455000071 ],
							[ 2.701975830000038, 6.500177816000075 ],
							[ 2.700856929000054, 6.50423537100005 ],
							[ 2.700318104000075, 6.508291328000041 ],
							[ 2.701519249000057, 6.512343012000031 ],
							[ 2.703289278000057, 6.515233859000034 ],
							[ 2.704472782000039, 6.517547102000037 ],
							[ 2.706822877000036, 6.520436902000029 ],
							[ 2.709160811000061, 6.522168271000055 ],
							[ 2.716749192000066, 6.52678094700002 ],
							[ 2.72025942700003, 6.52966680000003 ],
							[ 2.723189601000058, 6.532555122000076 ],
							[ 2.724390750000055, 6.536606443000039 ],
							[ 2.726166485000022, 6.540076631000034 ],
							[ 2.727930540000045, 6.542389015000026 ],
							[ 2.730274435000069, 6.544699169000069 ],
							[ 2.732618328000058, 6.547009012000046 ],
							[ 2.73438810600004, 6.549900545000071 ],
							[ 2.737329712000076, 6.553947109000035 ],
							[ 2.74027157300003, 6.557993373000045 ],
							[ 2.743225093000035, 6.563198552000074 ],
							[ 2.743248472000062, 6.565515023000046 ],
							[ 2.74211191400002, 6.567835417000026 ],
							[ 2.739826680000021, 6.571316656000022 ],
							[ 2.738707791000024, 6.575374590000024 ],
							[ 2.737026460000038, 6.581171484000038 ],
							[ 2.735338927000043, 6.586389164000025 ],
							[ 2.733645678000073, 6.591027654000072 ],
							[ 2.733106857000053, 6.595084198000052 ],
							[ 2.732005592000064, 6.600879677000023 ],
							[ 2.731454602000042, 6.603777548000039 ],
							[ 2.730915784000047, 6.60783351200007 ],
							[ 2.730376955000054, 6.611889494000025 ],
							[ 2.729826218000028, 6.614787088000071 ],
							[ 2.729275708000046, 6.617684962000055 ],
							[ 2.728144882000038, 6.620583919000069 ],
							[ 2.728180162000058, 6.624059329000033 ],
							[ 2.729943992000074, 6.626371389000042 ],
							[ 2.732305521000058, 6.630419356000061 ],
							[ 2.734104639000066, 6.636206523000055 ],
							[ 2.736472362000029, 6.640833370000053 ],
							[ 2.738845588000061, 6.64603992900004 ],
							[ 2.741213314000049, 6.650667296000051 ],
							[ 2.743580826000027, 6.655294365000032 ],
							[ 2.745350598000073, 6.658186100000023 ],
							[ 2.748286489000066, 6.661653085000069 ],
							[ 2.751802213000076, 6.665118342000028 ],
							[ 2.756484500000056, 6.669159935000039 ],
							[ 2.759420390000059, 6.67262738200003 ],
							[ 2.763516432000074, 6.676090832000057 ],
							[ 2.766446353000049, 6.678979039000069 ],
							[ 2.769370564000042, 6.681287748000045 ],
							[ 2.772294289000058, 6.683596721000072 ],
							[ 2.775230172000022, 6.687063757000033 ],
							[ 2.778177736000032, 6.69168914200003 ],
							[ 2.779953486000068, 6.695159322000052 ],
							[ 2.779994730000055, 6.699213888000031 ],
							[ 2.779455902000052, 6.703269901000056 ],
							[ 2.779502878000073, 6.707903938000072 ],
							[ 2.779561991000037, 6.713696059000029 ],
							[ 2.779608974000041, 6.71832979800007 ],
							[ 2.780241961000058, 6.723540710000066 ],
							[ 2.782029386000033, 6.728169800000046 ],
							[ 2.783804900000064, 6.731640227000071 ],
							[ 2.783846376000042, 6.735694790000025 ],
							[ 2.784485106000034, 6.741485142000045 ],
							[ 2.785692458000028, 6.746115684000074 ],
							[ 2.786893846000055, 6.750167024000064 ],
							[ 2.784608839000043, 6.753648810000072 ],
							[ 2.782311912000068, 6.755971948000024 ],
							[ 2.777677064000045, 6.756564481000055 ],
							[ 2.765500552000049, 6.757177352000042 ],
							[ 2.763168341000039, 6.756025843000032 ],
							[ 2.759107598000071, 6.756037224000067 ],
							[ 2.752169857000069, 6.758373237000058 ],
							[ 2.748120787000062, 6.759542871000065 ],
							[ 2.742929219000075, 6.762453651000044 ],
							[ 2.740626578000047, 6.764197931000069 ],
							[ 2.736595162000071, 6.767105548000075 ],
							[ 2.73255229800003, 6.768854216000022 ],
							[ 2.729106911000031, 6.77233927900005 ],
							[ 2.726827612000022, 6.776400186000046 ],
							[ 2.723979960000065, 6.781621391000044 ],
							[ 2.723435395000024, 6.785097590000021 ],
							[ 2.724068397000053, 6.790308874000061 ],
							[ 2.724109653000028, 6.794363996000072 ],
							[ 2.725908748000052, 6.800150876000032 ],
							[ 2.727702141000066, 6.805358846000047 ],
							[ 2.729489325000031, 6.80998788200003 ],
							[ 2.730696688000023, 6.814618601000063 ],
							[ 2.731312281000044, 6.818091742000036 ],
							[ 2.733105422000051, 6.823300249000056 ],
							[ 2.735461230000055, 6.826768948000051 ],
							[ 2.737213373000031, 6.827922861000047 ],
							[ 2.738391159000059, 6.829657066000038 ],
							[ 2.740178582000055, 6.83428576700004 ],
							[ 2.740799659000061, 6.838338372000067 ],
							[ 2.742082598000025, 6.845998274000067 ],
							[ 2.74147986600002, 6.848183618000064 ],
							[ 2.740949155000067, 6.853036404000022 ],
							[ 2.740982296000027, 6.856294131000027 ],
							[ 2.741013258000066, 6.859332562000077 ],
							[ 2.741029499000035, 6.860927581000055 ],
							[ 2.74106478300007, 6.864402657000028 ],
							[ 2.739406835000068, 6.872517070000072 ],
							[ 2.73771928900004, 6.877734738000072 ],
							[ 2.737766504000035, 6.882368464000024 ],
							[ 2.738997464000022, 6.889316135000058 ],
							[ 2.740198847000045, 6.893367308000052 ],
							[ 2.74082016400007, 6.897419332000027 ],
							[ 2.740867139000045, 6.902053344000024 ],
							[ 2.740339995000056, 6.907267924000053 ],
							[ 2.739238735000072, 6.913063643000044 ],
							[ 2.739866024000037, 6.917695415000026 ],
							[ 2.737580776000073, 6.92117748000004 ],
							[ 2.735266211000067, 6.921763064000061 ],
							[ 2.731229063000058, 6.92409090600006 ],
							[ 2.726611854000055, 6.926420650000068 ],
							[ 2.723734614000023, 6.92874620200007 ],
							[ 2.717424159000075, 6.935714234000045 ],
							[ 2.714564794000069, 6.939776986000027 ],
							[ 2.712279796000075, 6.943258901000036 ],
							[ 2.71174097100004, 6.947314811000069 ],
							[ 2.711799624000037, 6.953106895000076 ],
							[ 2.713042263000034, 6.961212709000051 ],
							[ 2.714231968000036, 6.964105033000067 ],
							[ 2.715433359000031, 6.968156294000039 ],
							[ 2.716628793000041, 6.97162865100006 ],
							[ 2.716699598000048, 6.978579083000056 ],
							[ 2.716178188000072, 6.984372531000076 ],
							[ 2.719196570000065, 6.995948733000034 ],
							[ 2.720403917000056, 7.000579421000054 ],
							[ 2.721013305000042, 7.003473666000048 ],
							[ 2.723978757000054, 7.009837197000024 ],
							[ 2.728672508000045, 7.015037655000071 ],
							[ 2.73394655900006, 7.020235521000075 ],
							[ 2.738036631000057, 7.023120526000071 ],
							[ 2.746211047000031, 7.028310354000041 ],
							[ 2.751461517000052, 7.031191826000054 ],
							[ 2.76019836100005, 7.034642667000071 ],
							[ 2.767200951000063, 7.038678248000053 ],
							[ 2.772492410000041, 7.045614196000031 ],
							[ 2.771941905000062, 7.048512088000052 ],
							[ 2.76965665900002, 7.051993491000076 ],
							[ 2.766779666000048, 7.054318551000051 ],
							[ 2.761025671000027, 7.058968031000063 ],
							[ 2.758723021000037, 7.060711859000037 ],
							[ 2.756414421000045, 7.06187733400003 ],
							[ 2.752951395000025, 7.063624814000036 ],
							[ 2.750086072000045, 7.06710780800006 ],
							[ 2.749529602000052, 7.069426496000062 ],
							[ 2.751891143000023, 7.073474488000045 ],
							[ 2.754229074000023, 7.075205761000063 ],
							[ 2.75658512900003, 7.07867426100006 ],
							[ 2.758366347000049, 7.082724198000051 ],
							[ 2.759585616000038, 7.088512811000044 ],
							[ 2.758472445000052, 7.093150205000029 ],
							[ 2.755618808000065, 7.097791579000045 ],
							[ 2.753327847000037, 7.100694287000067 ],
							[ 2.751030934000028, 7.103017236000028 ],
							[ 2.747585782000044, 7.106502480000074 ],
							[ 2.742412082000044, 7.111150826000028 ],
							[ 2.737290861000076, 7.121011679000048 ],
							[ 2.737343792000047, 7.12622509800002 ],
							[ 2.738545416000022, 7.130276245000061 ],
							[ 2.739770172000021, 7.136644356000033 ],
							[ 2.740971801000057, 7.14069549200002 ],
							[ 2.742184872000053, 7.145904956000038 ],
							[ 2.743392225000036, 7.150535546000071 ],
							[ 2.745771650000052, 7.156321640000044 ],
							[ 2.747559066000065, 7.160949656000071 ],
							[ 2.749908680000033, 7.163839277000022 ],
							[ 2.756313558000045, 7.166138616000069 ],
							[ 2.759805917000051, 7.167287230000056 ],
							[ 2.766796586000055, 7.170164119000049 ],
							[ 2.768548734000035, 7.171317557000066 ],
							[ 2.769152407000036, 7.173632507000036 ],
							[ 2.769773719000057, 7.177685826000072 ],
							[ 2.770992756000055, 7.183474644000057 ],
							[ 2.772206071000028, 7.188684568000042 ],
							[ 2.771678935000068, 7.193898572000023 ],
							[ 2.770571466000035, 7.199114852000037 ],
							[ 2.770612718000052, 7.203169334000052 ],
							[ 2.770659683000076, 7.20780327600005 ],
							[ 2.770156148000069, 7.215334515000052 ],
							[ 2.768480777000036, 7.221710607000034 ],
							[ 2.765639063000037, 7.22751097500003 ],
							[ 2.762791393000043, 7.232731858000022 ],
							[ 2.759932278000065, 7.236794374000056 ],
							[ 2.757647048000024, 7.240276269000049 ],
							[ 2.755362044000037, 7.243757599000048 ],
							[ 2.753076561000057, 7.247239462000039 ],
							[ 2.746801621000031, 7.257683284000052 ],
							[ 2.746280429000024, 7.263477264000073 ],
							[ 2.746339081000031, 7.26926953100002 ],
							[ 2.746415608000063, 7.276799316000051 ],
							[ 2.745906120000029, 7.283751065000047 ],
							[ 2.745408529000031, 7.291861996000023 ],
							[ 2.745491020000031, 7.29997064500003 ],
							[ 2.745561594000037, 7.306921532000047 ],
							[ 2.746212237000066, 7.313870432000044 ],
							[ 2.746318097000028, 7.324296041000025 ],
							[ 2.746945380000057, 7.328927983000028 ],
							[ 2.74700427700003, 7.334720237000056 ],
							[ 2.746655118000035, 7.338599914000042 ],
							[ 2.746482847000038, 7.340514195000026 ],
							[ 2.745979318000025, 7.348045369000033 ],
							[ 2.746026508000057, 7.352679293000051 ],
							[ 2.745499368000026, 7.357893511000043 ],
							[ 2.746173615000032, 7.367159344000072 ],
							[ 2.745676032000063, 7.375269970000033 ],
							[ 2.745723018000035, 7.379903865000074 ],
							[ 2.745776173000024, 7.38511666900007 ],
							[ 2.74584436400005, 7.391833770000062 ],
							[ 2.745917078000048, 7.399017809000043 ],
							[ 2.746550328000069, 7.404229194000038 ],
							[ 2.746556288000022, 7.404815933000066 ],
							[ 2.744996499000024, 7.407172334000052 ],
							[ 2.743146308000064, 7.409842155000035 ],
							[ 2.742625969000073, 7.413885310000069 ],
							[ 2.742494992000047, 7.421536899000046 ],
							[ 2.743366207000065, 7.423352667000074 ],
							[ 2.747860943000035, 7.424329696000029 ],
							[ 2.755069473000049, 7.424902642000063 ],
							[ 2.756329766000022, 7.424642451000068 ],
							[ 2.759777306000046, 7.425038718000053 ],
							[ 2.766742473000022, 7.425068038000063 ],
							[ 2.769060137000054, 7.423919300000023 ],
							[ 2.77370000600007, 7.42278 ],
							[ 2.777760982000075, 7.422218372000032 ],
							[ 2.783563380000032, 7.421662730000037 ],
							[ 2.787045962000036, 7.421677616000068 ],
							[ 2.791693216000056, 7.422855308000067 ],
							[ 2.795186516000058, 7.426344956000037 ],
							[ 2.796365975000072, 7.432142269000053 ],
							[ 2.79638458200003, 7.437934350000035 ],
							[ 2.796403176000069, 7.443726170000048 ],
							[ 2.795835492000037, 7.447778216000074 ],
							[ 2.795269732000065, 7.452409372000034 ],
							[ 2.795866734000072, 7.457624812000063 ],
							[ 2.795881742000063, 7.46225842900003 ],
							[ 2.796482327000035, 7.468632358000036 ],
							[ 2.795920368000054, 7.474421397000071 ],
							[ 2.794776433000038, 7.479629465000073 ],
							[ 2.794785507000029, 7.482525783000028 ],
							[ 2.794227116000059, 7.489473864000047 ],
							[ 2.79365801900002, 7.492946540000048 ],
							[ 2.791929495000034, 7.496993931000077 ],
							[ 2.786742936000053, 7.508555848000071 ],
							[ 2.785591372000056, 7.511447503000056 ],
							[ 2.783859250000035, 7.514336103000062 ],
							[ 2.780382148000058, 7.516059062000068 ],
							[ 2.775163887000076, 7.517774493000047 ],
							[ 2.769947529000035, 7.520070089000058 ],
							[ 2.765311474000043, 7.522367558000042 ],
							[ 2.763579380000067, 7.525256066000054 ],
							[ 2.757786274000068, 7.528706905000035 ],
							[ 2.754311087000076, 7.531009545000074 ],
							[ 2.750838048000048, 7.533890711000026 ],
							[ 2.748521803000074, 7.535618701000033 ],
							[ 2.747961763000035, 7.541987347000031 ],
							[ 2.748499452000033, 7.547192359000064 ],
							[ 2.748081568000032, 7.548957923000046 ],
							[ 2.747570474000042, 7.550970192000023 ],
							[ 2.746946339000033, 7.552623895000067 ],
							[ 2.745853820000036, 7.553794093000022 ],
							[ 2.744286797000029, 7.554837319000058 ],
							[ 2.74283685000006, 7.556001418000051 ],
							[ 2.74223102000002, 7.556585505000044 ],
							[ 2.741853271000025, 7.557767873000046 ],
							[ 2.741584461000059, 7.559546490000059 ],
							[ 2.74120930700002, 7.560359150000068 ],
							[ 2.741055721000066, 7.560492957000065 ],
							[ 2.73874354700007, 7.563379388000044 ],
							[ 2.732955944000025, 7.568567667000025 ],
							[ 2.729495283000062, 7.575503698000034 ],
							[ 2.728944532000071, 7.584768906000022 ],
							[ 2.728968620000046, 7.592297975000065 ],
							[ 2.728412160000062, 7.599825314000043 ],
							[ 2.729014634000066, 7.606778142000053 ],
							[ 2.729044201000022, 7.616045894000024 ],
							[ 2.727905754000062, 7.622991027000069 ],
							[ 2.726772785000037, 7.631674825000061 ],
							[ 2.726785428000028, 7.635729326000046 ],
							[ 2.727386234000051, 7.642102764000072 ],
							[ 2.727983469000037, 7.64731828400005 ],
							[ 2.728003512000043, 7.653688872000032 ],
							[ 2.728610039000046, 7.661800419000031 ],
							[ 2.729794736000031, 7.669335441000044 ],
							[ 2.730972297000051, 7.67455239100002 ],
							[ 2.731565483000054, 7.678609419000054 ],
							[ 2.733329066000067, 7.685567319000029 ],
							[ 2.735090724000031, 7.691946120000068 ],
							[ 2.737430818000064, 7.697747790000051 ],
							[ 2.73918700300004, 7.702388160000055 ],
							[ 2.740362642000036, 7.707026814000074 ],
							[ 2.739225628000042, 7.714551884000059 ],
							[ 2.73750115100006, 7.71975720000006 ],
							[ 2.734044543000039, 7.727851980000025 ],
							[ 2.732329132000075, 7.735953287000029 ],
							[ 2.73176909600005, 7.742321841000035 ],
							[ 2.731789359000061, 7.74869353400004 ],
							[ 2.732378951000044, 7.751591803000053 ],
							[ 2.73413490300004, 7.756233308000049 ],
							[ 2.734730012000057, 7.760869140000068 ],
							[ 2.736490003000029, 7.76666797200005 ],
							[ 2.737661833000061, 7.770147873000042 ],
							[ 2.738253362000023, 7.77362580700003 ],
							[ 2.738270053000065, 7.778838729000029 ],
							[ 2.737698794000039, 7.781732201000068 ],
							[ 2.735972401000026, 7.786358970000038 ],
							[ 2.734826338000062, 7.790987423000047 ],
							[ 2.73426080400003, 7.795618993000062 ],
							[ 2.73311448000004, 7.80024773100007 ],
							[ 2.732545372000061, 7.803720558000066 ],
							[ 2.732559930000036, 7.808353813000053 ],
							[ 2.730831867000063, 7.812401179000062 ],
							[ 2.725613600000031, 7.814116829000056 ],
							[ 2.721554276000063, 7.815258453000069 ],
							[ 2.718655828000067, 7.81640433900003 ],
							[ 2.716337682000074, 7.817553052000051 ],
							[ 2.714025254000035, 7.820439875000034 ],
							[ 2.713476187000026, 7.830283770000051 ],
							[ 2.71349667800007, 7.836655151000059 ],
							[ 2.711195234000058, 7.843016748000025 ],
							[ 2.710043680000069, 7.845907593000049 ],
							[ 2.708315381000034, 7.849954572000058 ],
							[ 2.706002944000033, 7.852841326000032 ],
							[ 2.701370712000028, 7.856297070000039 ],
							[ 2.698475846000065, 7.858601522000072 ],
							[ 2.694427735000033, 7.863218224000036 ],
							[ 2.693283563000023, 7.868426219000071 ],
							[ 2.69561267000006, 7.870752716000027 ],
							[ 2.700265417000026, 7.873668732000056 ],
							[ 2.706076856000038, 7.876009350000061 ],
							[ 2.713631622000037, 7.878937285000063 ],
							[ 2.718275071000051, 7.878956194000068 ],
							[ 2.721179009000025, 7.879548142000033 ],
							[ 2.725826027000039, 7.88072541400004 ],
							[ 2.728735441000026, 7.883054266000045 ],
							[ 2.733389860000045, 7.886548653000034 ],
							[ 2.739208941000072, 7.891206771000043 ],
							[ 2.742697002000057, 7.892958983000028 ],
							[ 2.746767519000059, 7.895292749000021 ],
							[ 2.750253676000057, 7.896465788000057 ],
							[ 2.753159291000031, 7.897636279000039 ],
							[ 2.756649262000053, 7.899967696000033 ],
							[ 2.760142802000075, 7.903457273000072 ],
							[ 2.76247025300006, 7.90520482900007 ],
							[ 2.765381579000064, 7.908112463000066 ],
							[ 2.767714260000048, 7.91159779000003 ],
							[ 2.770063650000054, 7.920295149000026 ],
							[ 2.770655160000047, 7.923772718000066 ],
							[ 2.771252150000066, 7.928988372000049 ],
							[ 2.77300643500007, 7.933050258000037 ],
							[ 2.774176372000056, 7.935950643000069 ],
							[ 2.775932553000075, 7.940591888000029 ],
							[ 2.77710653400004, 7.944650999000032 ],
							[ 2.781178715000067, 7.947564014000022 ],
							[ 2.78466654500005, 7.949316083000042 ],
							[ 2.79163717800003, 7.951082566000025 ],
							[ 2.793960812000023, 7.951672032000033 ],
							[ 2.79686474500005, 7.952263368000047 ],
							[ 2.800349236000045, 7.952856546000021 ],
							[ 2.804409987000042, 7.952294812000048 ],
							[ 2.807890655000051, 7.951730318000045 ],
							[ 2.81020879700003, 7.950581600000021 ],
							[ 2.813107248000051, 7.949434707000023 ],
							[ 2.817737590000036, 7.945399621000035 ],
							[ 2.819471590000035, 7.943089984000039 ],
							[ 2.821199897000042, 7.939043644000037 ],
							[ 2.822933674000069, 7.936733118000063 ],
							[ 2.827567817000045, 7.933856379000076 ],
							[ 2.831629030000045, 7.933294911000075 ],
							[ 2.835107811000057, 7.932150808000074 ],
							[ 2.838586806000023, 7.931006953000065 ],
							[ 2.84206748400004, 7.930441862000066 ],
							[ 2.846128470000053, 7.929880232000073 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Ondo',
				admin1Pcod: 'NG029',
				admin1RefN: 'Ondo',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 7.751109,
				Shape_Area: 1.233546
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 5.926039584000023, 7.731815454000071 ],
							[ 5.925133252000023, 7.72811689200006 ],
							[ 5.92330989900006, 7.722566568000047 ],
							[ 5.922403337000048, 7.718868273000055 ],
							[ 5.921491280000055, 7.716093091000062 ],
							[ 5.922439093000037, 7.712404682000056 ],
							[ 5.923391840000022, 7.707793131000074 ],
							[ 5.927141652000046, 7.700427572000024 ],
							[ 5.929021703000046, 7.695821224000042 ],
							[ 5.929975161000073, 7.691209381000021 ],
							[ 5.931891012000051, 7.680139416000031 ],
							[ 5.93378651200004, 7.672763384000064 ],
							[ 5.935671260000049, 7.667233576000058 ],
							[ 5.93663985400002, 7.65985203200006 ],
							[ 5.937628320000044, 7.64877712200007 ],
							[ 5.940000477000069, 7.645110143000068 ],
							[ 5.944175163000068, 7.638656694000076 ],
							[ 5.947909477000053, 7.634060291000026 ],
							[ 5.950179820000074, 7.630550625000069 ],
							[ 5.955044732000033, 7.62489596100005 ],
							[ 5.96387788100003, 7.615749236000056 ],
							[ 5.972192039000049, 7.610480295000059 ],
							[ 5.976594238000075, 7.607604915000024 ],
							[ 5.989333995000038, 7.596548979000033 ],
							[ 6.004002797000055, 7.587449344000049 ],
							[ 6.007937682000033, 7.582143861000077 ],
							[ 6.008930536000037, 7.579725478000057 ],
							[ 6.008942450000063, 7.578270029000066 ],
							[ 6.008966281000028, 7.575358556000026 ],
							[ 6.008998051000049, 7.571477074000029 ],
							[ 6.009029822000059, 7.567595313000027 ],
							[ 6.007618216000026, 7.56176077300006 ],
							[ 6.005708395000056, 7.557377989000031 ],
							[ 6.005297627000061, 7.548155045000044 ],
							[ 6.003391750000048, 7.537396811000065 ],
							[ 6.004090166000026, 7.535571972000071 ],
							[ 6.004125679000026, 7.529235776000064 ],
							[ 6.002025944000025, 7.528248839000071 ],
							[ 5.994548823000059, 7.527143876000025 ],
							[ 5.989405231000035, 7.52893509300003 ],
							[ 5.987115968000069, 7.529537126000037 ],
							[ 5.982531197000071, 7.530157047000046 ],
							[ 5.979086794000068, 7.530185225000025 ],
							[ 5.97564324800004, 7.530212843000072 ],
							[ 5.973297122000076, 7.52615208800006 ],
							[ 5.970964895000066, 7.523255959000039 ],
							[ 5.969185931000027, 7.518608156000028 ],
							[ 5.967428212000073, 7.515707114000065 ],
							[ 5.965109923000057, 7.513978087000055 ],
							[ 5.962800109000057, 7.512831221000056 ],
							[ 5.959945245000029, 7.514019862000055 ],
							[ 5.954218805000039, 7.515232970000056 ],
							[ 5.953028307000068, 7.511744012000065 ],
							[ 5.95297087900002, 7.507081870000036 ],
							[ 5.951171766000073, 7.501221538000038 ],
							[ 5.948815910000064, 7.496039863000021 ],
							[ 5.948134961000051, 7.487301713000022 ],
							[ 5.953258162000054, 7.483763177000071 ],
							[ 5.954929753000044, 7.479669222000041 ],
							[ 5.956608897000024, 7.476157151000052 ],
							[ 5.957714234000036, 7.472651040000073 ],
							[ 5.963303177000057, 7.460363947000076 ],
							[ 5.966173148000053, 7.460339916000066 ],
							[ 5.971339324000041, 7.460298941000076 ],
							[ 5.975343265000049, 7.459099903000038 ],
							[ 5.978772288000073, 7.457905719000053 ],
							[ 5.984490934000064, 7.45611004400007 ],
							[ 5.986714792000043, 7.450263991000043 ],
							[ 5.987259918000063, 7.44792701700004 ],
							[ 5.987217107000049, 7.444429780000064 ],
							[ 5.987182220000022, 7.441515827000046 ],
							[ 5.984870828000055, 7.440369098000076 ],
							[ 5.981406323000044, 7.43864826600003 ],
							[ 5.97966189300007, 7.436913873000037 ],
							[ 5.977336981000064, 7.434600769000042 ],
							[ 5.975586942000064, 7.432283906000066 ],
							[ 5.974403017000043, 7.429378869000061 ],
							[ 5.973793955000076, 7.426468938000028 ],
							[ 5.972601864000069, 7.422980867000035 ],
							[ 5.971411214000057, 7.419494211000028 ],
							[ 5.968483950000063, 7.414854125000033 ],
							[ 5.969007950000048, 7.410768989000076 ],
							[ 5.970127074000061, 7.40842916400004 ],
							[ 5.974661725000033, 7.403728011000055 ],
							[ 5.974051861000021, 7.400818941000068 ],
							[ 5.975708976000021, 7.395558840000035 ],
							[ 5.978558073000045, 7.393786791000025 ],
							[ 5.979677091000042, 7.391446083000062 ],
							[ 5.978499769000052, 7.389123773000051 ],
							[ 5.977316812000026, 7.386219025000059 ],
							[ 5.974970743000029, 7.382157692000021 ],
							[ 5.974919832000069, 7.378077989000076 ],
							[ 5.974885068000049, 7.37516402600005 ],
							[ 5.975996131000045, 7.372240022000028 ],
							[ 5.978241988000036, 7.368141067000067 ],
							[ 5.981091139000057, 7.366368722000061 ],
							[ 5.986795027000028, 7.363408118000052 ],
							[ 5.987339997000049, 7.361072006000029 ],
							[ 5.986730947000069, 7.358161809000023 ],
							[ 5.986694960000023, 7.355247853000037 ],
							[ 5.980875956000034, 7.348884101000067 ],
							[ 5.978566034000039, 7.34773590900005 ],
							[ 5.975113730000032, 7.34718188100004 ],
							[ 5.971663130000024, 7.346627259000059 ],
							[ 5.968785873000058, 7.346066897000071 ],
							[ 5.964760845000058, 7.345518206000065 ],
							[ 5.958647537000047, 7.344483361000073 ],
							[ 5.957695131000037, 7.344322314000067 ],
							[ 5.956689358000062, 7.343023435000021 ],
							[ 5.956063800000038, 7.341618926000024 ],
							[ 5.955821741000022, 7.340090427000064 ],
							[ 5.955708134000076, 7.338435823000054 ],
							[ 5.955725076000022, 7.336400826000045 ],
							[ 5.955993604000071, 7.33474940900004 ],
							[ 5.957933715000024, 7.331330741000045 ],
							[ 5.961136241000077, 7.329322156000046 ],
							[ 5.964208955000061, 7.327566773000058 ],
							[ 5.966771355000049, 7.325934021000023 ],
							[ 5.969458710000026, 7.324557180000056 ],
							[ 5.972782165000069, 7.323312441000041 ],
							[ 5.975211569000066, 7.322314905000042 ],
							[ 5.978154967000023, 7.320812662000037 ],
							[ 5.97931107900007, 7.319677163000051 ],
							[ 5.979321585000037, 7.318405359000053 ],
							[ 5.97933839600006, 7.316370081000059 ],
							[ 5.978713091000031, 7.314965889000064 ],
							[ 5.975920268000039, 7.313670715000057 ],
							[ 5.973128518000067, 7.312248357000044 ],
							[ 5.968297215000064, 7.310936600000048 ],
							[ 5.962314048000053, 7.310251025000071 ],
							[ 5.95042707500005, 7.306972292000069 ],
							[ 5.945087226000055, 7.305528534000075 ],
							[ 5.94153570900005, 7.303590980000024 ],
							[ 5.936333963000038, 7.30087607300004 ],
							[ 5.930874372000062, 7.298540707000029 ],
							[ 5.926806818000045, 7.29736194000003 ],
							[ 5.924015383000039, 7.29593920800005 ],
							[ 5.923493291000057, 7.295585282000047 ],
							[ 5.921632802000033, 7.29282386400007 ],
							[ 5.916991110000026, 7.288781295000035 ],
							[ 5.914071132000061, 7.28472518600006 ],
							[ 5.91229904100004, 7.280659212000046 ],
							[ 5.909392835000062, 7.277768257000048 ],
							[ 5.90837355900004, 7.276502256000072 ],
							[ 5.907060990000048, 7.274871934000032 ],
							[ 5.905289044000028, 7.270805926000037 ],
							[ 5.904650143000026, 7.265564888000029 ],
							[ 5.902900240000065, 7.263247948000071 ],
							[ 5.900001175000057, 7.260940217000041 ],
							[ 5.896522055000048, 7.258053647000054 ],
							[ 5.895890240000028, 7.253396172000066 ],
							[ 5.898129046000065, 7.248714103000054 ],
							[ 5.898660242000062, 7.245212059000039 ],
							[ 5.898544877000063, 7.23588705700007 ],
							[ 5.897339974000033, 7.231233217000067 ],
							[ 5.901270756000031, 7.224205880000056 ],
							[ 5.90409885300005, 7.220685050000043 ],
							[ 5.903480916000035, 7.217193074000022 ],
							[ 5.899414054000033, 7.213146074000065 ],
							[ 5.895940909000046, 7.210842228000047 ],
							[ 5.893616253000062, 7.208529112000065 ],
							[ 5.888974247000021, 7.204486734000056 ],
							[ 5.884325119000039, 7.199862119000045 ],
							[ 5.883665047000022, 7.192871963000073 ],
							[ 5.890465167000059, 7.189054906000024 ],
							[ 5.893910056000038, 7.185792871000046 ],
							[ 5.893875102000038, 7.182878886000026 ],
							[ 5.889835031000075, 7.181162718000053 ],
							[ 5.886943900000063, 7.179438182000069 ],
							[ 5.882308956000031, 7.175979034000022 ],
							[ 5.878821880000032, 7.172510076000037 ],
							[ 5.877064266000048, 7.169609964000074 ],
							[ 5.874718141000074, 7.165547930000059 ],
							[ 5.872960033000027, 7.162648070000046 ],
							[ 5.872910016000048, 7.158568015000071 ],
							[ 5.871696811000049, 7.153330753000034 ],
							[ 5.868174989000067, 7.146947974000057 ],
							[ 5.867571892000058, 7.144620858000053 ],
							[ 5.867528831000072, 7.141123807000042 ],
							[ 5.866331231000061, 7.137052889000074 ],
							[ 5.865139112000065, 7.133565797000074 ],
							[ 5.862779011000043, 7.128338768000049 ],
							[ 5.860455082000044, 7.126026285000023 ],
							[ 5.857576878000032, 7.125466725000024 ],
							[ 5.854692944000021, 7.124323808000042 ],
							[ 5.850654260000056, 7.122609210000064 ],
							[ 5.846621893000076, 7.121476162000022 ],
							[ 5.844311273000073, 7.120328778000044 ],
							[ 5.842559893000043, 7.118010956000035 ],
							[ 5.841958128000044, 7.115684915000031 ],
							[ 5.843033795000053, 7.109846978000064 ],
							[ 5.845868157000041, 7.106908773000043 ],
							[ 5.848142984000049, 7.105142123000064 ],
							[ 5.850976991000039, 7.102203875000043 ],
							[ 5.853237192000051, 7.099270850000039 ],
							[ 5.854840517000071, 7.096485851000068 ],
							[ 5.854922710000039, 7.096343068000067 ],
							[ 5.852578095000069, 7.093999 ],
							[ 5.849707102000025, 7.092305127000031 ],
							[ 5.84624911700007, 7.091166838000049 ],
							[ 5.84104680300004, 7.088295105000043 ],
							[ 5.83815465400005, 7.086569882000049 ],
							[ 5.835264252000059, 7.084845207000058 ],
							[ 5.829487802000074, 7.081976835000034 ],
							[ 5.823740972000053, 7.081441065000035 ],
							[ 5.821989927000061, 7.079123123000045 ],
							[ 5.815583181000022, 7.071598020000067 ],
							[ 5.814392063000071, 7.068109922000076 ],
							[ 5.813187050000067, 7.063456905000066 ],
							[ 5.811988899000028, 7.059385765000059 ],
							[ 5.810224124000058, 7.055902830000036 ],
							[ 5.809593200000052, 7.051245179000034 ],
							[ 5.804257907000022, 7.048537354000075 ],
							[ 5.802832242000022, 7.045075430000054 ],
							[ 5.801023926000028, 7.036755654000046 ],
							[ 5.799312050000026, 7.032424527000046 ],
							[ 5.798359843000071, 7.03001454300005 ],
							[ 5.797366675000035, 7.02750160100004 ],
							[ 5.796177970000031, 7.024290674000042 ],
							[ 5.792581188000042, 7.021655213000031 ],
							[ 5.79400924600003, 7.015619852000043 ],
							[ 5.793735058000038, 7.013630894000073 ],
							[ 5.793755585000042, 7.009965394000062 ],
							[ 5.793781274000025, 7.00532105800005 ],
							[ 5.791041033000056, 6.997919091000028 ],
							[ 5.789217412000028, 6.992368720000059 ],
							[ 5.789242637000029, 6.987752081000053 ],
							[ 5.788356662000069, 6.980360098000062 ],
							[ 5.785642282000026, 6.968341460000033 ],
							[ 5.788464568000052, 6.960970392000036 ],
							[ 5.79220430700002, 6.955450627000062 ],
							[ 5.793172225000035, 6.948069215000032 ],
							[ 5.795052660000067, 6.943462865000072 ],
							[ 5.796005843000046, 6.938851495000051 ],
							[ 5.797895846000074, 6.932398228000068 ],
							[ 5.798848687000032, 6.92778684600006 ],
							[ 5.799811719000047, 6.921328581000068 ],
							[ 5.799103383000045, 6.918805135000071 ],
							[ 5.797993586000075, 6.914855067000076 ],
							[ 5.793403553000076, 6.906519371000059 ],
							[ 5.789725879000059, 6.900958680000031 ],
							[ 5.78790745200007, 6.894485104000069 ],
							[ 5.787943236000046, 6.888021846000072 ],
							[ 5.787062240000068, 6.879706394000038 ],
							[ 5.787108313000033, 6.871396528000048 ],
							[ 5.785634522000066, 6.869615174000046 ],
							[ 5.782503492000046, 6.865831011000068 ],
							[ 5.778835839000067, 6.85842358900004 ],
							[ 5.774225730000069, 6.85378111700004 ],
							[ 5.771480005000058, 6.847302391000028 ],
							[ 5.769666641000072, 6.839905828000042 ],
							[ 5.771552144000054, 6.834375851000061 ],
							[ 5.773421644000052, 6.831616472000064 ],
							[ 5.775301456000022, 6.827009606000047 ],
							[ 5.777176760000032, 6.823326484000063 ],
							[ 5.778134865000027, 6.817791980000038 ],
							[ 5.770835513000065, 6.796513963000052 ],
							[ 5.767152261000035, 6.791876734000027 ],
							[ 5.757921149000026, 6.784439148000047 ],
							[ 5.751592573000039, 6.781640027000037 ],
							[ 5.743122628000037, 6.777893473000063 ],
							[ 5.726479411000071, 6.769490791000067 ],
							[ 5.723659650000059, 6.766651009000043 ],
							[ 5.719102894000059, 6.762063420000061 ],
							[ 5.714492767000024, 6.757421013000055 ],
							[ 5.711441936000028, 6.751975031000029 ],
							[ 5.702557533000061, 6.736117457000034 ],
							[ 5.667370893000054, 6.727611926000066 ],
							[ 5.659932694000076, 6.731264464000049 ],
							[ 5.65434881300007, 6.734927289000041 ],
							[ 5.649687315000051, 6.739518283000052 ],
							[ 5.640374706000046, 6.746853219000059 ],
							[ 5.636665888000039, 6.746832748000031 ],
							[ 5.629253363000032, 6.745868255000062 ],
							[ 5.620002269000054, 6.742123513000024 ],
							[ 5.612620759000038, 6.735619110000073 ],
							[ 5.605238951000047, 6.729115106000052 ],
							[ 5.596013628000037, 6.720753333000062 ],
							[ 5.589559033000057, 6.714253951000046 ],
							[ 5.585876051000071, 6.70961711800004 ],
							[ 5.58219811500004, 6.704056232000028 ],
							[ 5.576634821000027, 6.704025769000054 ],
							[ 5.573838159000047, 6.70678001400006 ],
							[ 5.571025969000061, 6.712304593000056 ],
							[ 5.564489356000024, 6.720578595000063 ],
							[ 5.561656103000075, 6.72979635300004 ],
							[ 5.562522019000028, 6.740881971000022 ],
							[ 5.561532868000029, 6.751956380000024 ],
							[ 5.563216246000025, 6.762380187000076 ],
							[ 5.561877854000045, 6.767142811000042 ],
							[ 5.561419879000027, 6.772269754000035 ],
							[ 5.559534923000058, 6.777799566000056 ],
							[ 5.555774557000063, 6.787012501000049 ],
							[ 5.551097511000023, 6.794373447000055 ],
							[ 5.549863821000031, 6.797205811000026 ],
							[ 5.547423795000043, 6.802810282000053 ],
							[ 5.545835013000044, 6.80429218200004 ],
							[ 5.544519807000029, 6.810034408000035 ],
							[ 5.543577810000045, 6.812798848000057 ],
							[ 5.543556802000069, 6.816492643000061 ],
							[ 5.543510881000032, 6.824802272000056 ],
							[ 5.546266427000035, 6.829434430000049 ],
							[ 5.549027373000058, 6.833143134000068 ],
							[ 5.553648104000047, 6.83593901100005 ],
							[ 5.559206134000021, 6.836892979000027 ],
							[ 5.563836685000069, 6.83784213000007 ],
							[ 5.566607832000045, 6.83970406800006 ],
							[ 5.57121329000006, 6.845269804000054 ],
							[ 5.572089149000021, 6.854508433000035 ],
							[ 5.570152219000022, 6.869271320000053 ],
							[ 5.567853322000076, 6.87262105800005 ],
							[ 5.565518851000036, 6.876023304000057 ],
							[ 5.561751257000026, 6.879381573000046 ],
							[ 5.555240192000042, 6.883038993000071 ],
							[ 5.547441612000057, 6.883792757000037 ],
							[ 5.537059795000062, 6.884796622000067 ],
							[ 5.459748756000067, 6.885983013000043 ],
							[ 5.272408117000055, 6.888858673000072 ],
							[ 5.254822129000047, 6.883221749000029 ],
							[ 5.243793422000067, 6.865616868000075 ],
							[ 5.236447696000027, 6.852648627000065 ],
							[ 5.23276990200003, 6.847088470000074 ],
							[ 5.22632606600007, 6.838742274000026 ],
							[ 5.22356522900003, 6.835033387000067 ],
							[ 5.218970352000042, 6.827620852000052 ],
							[ 5.215297245000045, 6.821137325000052 ],
							[ 5.213458507000041, 6.818356871000049 ],
							[ 5.211624548000032, 6.814653483000029 ],
							[ 5.208868523000035, 6.81002131300005 ],
							[ 5.207045018000031, 6.80447100400005 ],
							[ 5.20613860800006, 6.800772684000037 ],
							[ 5.20523221600007, 6.797074363000036 ],
							[ 5.204325274000041, 6.793375457000025 ],
							[ 5.202496584000073, 6.788748856000041 ],
							[ 5.202527468000028, 6.783208693000063 ],
							[ 5.201615824000044, 6.780433539000057 ],
							[ 5.197015868000051, 6.773944995000022 ],
							[ 5.190535448000048, 6.772061744000041 ],
							[ 5.185914858000046, 6.769266044000062 ],
							[ 5.180367567000076, 6.766465144000051 ],
							[ 5.172970283000041, 6.762731214000041 ],
							[ 5.166505868000058, 6.758078584000032 ],
							[ 5.156353115000059, 6.749712066000029 ],
							[ 5.150810691000061, 6.745987820000039 ],
							[ 5.145293776000074, 6.737647048000042 ],
							[ 5.140693645000056, 6.731158342000072 ],
							[ 5.137937439000041, 6.726525863000063 ],
							[ 5.135192005000022, 6.720047036000039 ],
							[ 5.131524426000055, 6.712639728000056 ],
							[ 5.126022778000049, 6.70152910500002 ],
							[ 5.122329590000049, 6.698738423000066 ],
							[ 5.122350311000048, 6.695045440000058 ],
							[ 5.119599292000032, 6.689489923000053 ],
							[ 5.117776033000041, 6.683939420000058 ],
							[ 5.115957817000037, 6.677466014000061 ],
							[ 5.114149646000044, 6.669145666000077 ],
							[ 5.113263081000071, 6.661753789000045 ],
							[ 5.112367104000043, 6.656208570000047 ],
							[ 5.112397633000057, 6.650668645000053 ],
							[ 5.112434013000041, 6.644205556000031 ],
							[ 5.111542818000032, 6.637736862000054 ],
							[ 5.111583628000062, 6.630350017000069 ],
							[ 5.112531148000073, 6.626662344000067 ],
							[ 5.114416745000028, 6.621132486000022 ],
							[ 5.11722893700005, 6.615607663000048 ],
							[ 5.119108565000033, 6.611001515000055 ],
							[ 5.120984023000062, 6.607318279000026 ],
							[ 5.123801255000046, 6.60087049200007 ],
							[ 5.125671368000042, 6.598110696000049 ],
							[ 5.127551078000067, 6.593504506000045 ],
							[ 5.128054205000069, 6.588160895000044 ],
							[ 5.13222849400006, 6.586143480000032 ],
							[ 5.133171029000039, 6.583378368000069 ],
							[ 5.136910348000072, 6.577858921000029 ],
							[ 5.138774771000044, 6.576022502000058 ],
							[ 5.140639710000073, 6.574186375000068 ],
							[ 5.147155820000023, 6.569605469000066 ],
							[ 5.15458336100005, 6.567800049000027 ],
							[ 5.16064579600004, 6.563108169000031 ],
							[ 5.162112265000076, 6.561973111000043 ],
							[ 5.161934304000056, 6.559301299000026 ],
							[ 5.164249966000057, 6.554492359000051 ],
							[ 5.16229056100002, 6.552176876000033 ],
							[ 5.160687400000029, 6.549862025000039 ],
							[ 5.162290651000035, 6.546477803000073 ],
							[ 5.161222032000069, 6.543805987000042 ],
							[ 5.160331309000071, 6.539175386000068 ],
							[ 5.157125582000049, 6.536147668000069 ],
							[ 5.155166167000061, 6.533119732000046 ],
							[ 5.154810028000043, 6.529023179000035 ],
							[ 5.154810044000044, 6.518871253000043 ],
							[ 5.153029059000062, 6.515487126000039 ],
							[ 5.150357299000063, 6.51174687300005 ],
							[ 5.150179353000055, 6.508897311000055 ],
							[ 5.150179325000067, 6.504623052000056 ],
							[ 5.152138613000034, 6.503554258000065 ],
							[ 5.153741428000046, 6.501060530000075 ],
							[ 5.153385110000045, 6.498033025000041 ],
							[ 5.151069705000054, 6.493936503000043 ],
							[ 5.148576385000069, 6.49215564900004 ],
							[ 5.147329704000072, 6.489305562000027 ],
							[ 5.145370398000068, 6.488771469000028 ],
							[ 5.143589396000039, 6.486456049000026 ],
							[ 5.139492966000034, 6.480044318000068 ],
							[ 5.135752732000071, 6.477016429000059 ],
							[ 5.133615380000037, 6.472563621000063 ],
							[ 5.128806558000065, 6.468467167000028 ],
							[ 5.123463512000058, 6.462768034000021 ],
							[ 5.117407688000071, 6.459027864000063 ],
							[ 5.114023770000074, 6.453328193000061 ],
							[ 5.110461594000071, 6.449053685000024 ],
							[ 5.106009104000066, 6.446025805000033 ],
							[ 5.10298109200005, 6.442285655000035 ],
							[ 5.102803201000029, 6.438723624000033 ],
							[ 5.105118773000072, 6.437298823000049 ],
							[ 5.107789871000023, 6.435873843000024 ],
							[ 5.107612174000053, 6.433380598000042 ],
							[ 5.104584138000064, 6.431777430000068 ],
							[ 5.104406356000027, 6.427859330000047 ],
							[ 5.103693582000062, 6.424831358000063 ],
							[ 5.103871920000074, 6.422159605000047 ],
							[ 5.10725601300004, 6.419132275000038 ],
							[ 5.108502362000024, 6.41717301400007 ],
							[ 5.109749295000029, 6.41467963100007 ],
							[ 5.109570911000048, 6.412541874000055 ],
							[ 5.106899842000075, 6.40951429200004 ],
							[ 5.103693390000046, 6.402212082000062 ],
							[ 5.09888494300003, 6.397403352000026 ],
							[ 5.091582310000035, 6.392238161000023 ],
							[ 5.086036231000037, 6.389211621000072 ],
							[ 5.083274803000052, 6.385502683000027 ],
							[ 5.07929328900002, 6.381195675000072 ],
							[ 5.075731239000049, 6.377276878000032 ],
							[ 5.071278537000069, 6.376564454000061 ],
							[ 5.06611345400006, 6.37656435100007 ],
							[ 5.062550884000075, 6.377811292000047 ],
							[ 5.057386446000066, 6.381373501000041 ],
							[ 5.052824987000065, 6.38415088000005 ],
							[ 5.049193354000067, 6.382442114000071 ],
							[ 5.048082718000046, 6.377920995000068 ],
							[ 5.048124892000033, 6.375318185000026 ],
							[ 5.047234187000072, 6.372112393000066 ],
							[ 5.043849954000052, 6.366590900000062 ],
							[ 5.039219320000029, 6.364097194000067 ],
							[ 5.032392938000044, 6.364906654000038 ],
							[ 5.029245389000039, 6.363562967000064 ],
							[ 5.028709874000072, 6.360269416000051 ],
							[ 5.028355107000039, 6.358041817000071 ],
							[ 5.028176772000052, 6.350917290000041 ],
							[ 5.027820658000053, 6.345218246000059 ],
							[ 5.028711342000065, 6.341834049000056 ],
							[ 5.031738696000048, 6.337559675000023 ],
							[ 5.034945015000062, 6.332928607000042 ],
							[ 5.035301164000032, 6.328475905000062 ],
							[ 5.030136150000033, 6.323132936000036 ],
							[ 5.021523426000044, 6.318678921000071 ],
							[ 5.019684301000041, 6.315898355000058 ],
							[ 5.015364592000026, 6.30897376300004 ],
							[ 5.012897062000036, 6.302100250000024 ],
							[ 5.01307343600007, 6.298575342000049 ],
							[ 5.015618871000072, 6.292353136000031 ],
							[ 5.021533054000031, 6.285357474000023 ],
							[ 5.029640252000036, 6.279189100000053 ],
							[ 5.035061294000059, 6.278010390000077 ],
							[ 5.037746988000038, 6.277426663000028 ],
							[ 5.041041861000053, 6.274275327000055 ],
							[ 5.04796900100007, 6.264913558000046 ],
							[ 5.049030394000056, 6.260269209000057 ],
							[ 5.049714114000039, 6.251429585000039 ],
							[ 5.053448168000045, 6.24683329100003 ],
							[ 5.057486140000037, 6.247641531000056 ],
							[ 5.060573199000032, 6.248004853000054 ],
							[ 5.063478438000061, 6.248346838000032 ],
							[ 5.066297915000064, 6.245526751000057 ],
							[ 5.069470372000069, 6.242530824000028 ],
							[ 5.073347528000056, 6.239534803000026 ],
							[ 5.07334766300005, 6.234776 ],
							[ 5.072933676000048, 6.231186367000021 ],
							[ 5.073736241000063, 6.228851821000035 ],
							[ 5.075851381000064, 6.226736505000076 ],
							[ 5.07881124000005, 6.22261575400006 ],
							[ 5.077929966000056, 6.220148118000054 ],
							[ 5.074545801000056, 6.217686136000054 ],
							[ 5.075622570000064, 6.213434280000058 ],
							[ 5.081807556000058, 6.218033305000063 ],
							[ 5.085182634000034, 6.21608827700004 ],
							[ 5.087235862000057, 6.214525713000057 ],
							[ 5.086137636000046, 6.207841819000066 ],
							[ 5.088472282000055, 6.20433997300006 ],
							[ 5.089201807000052, 6.201057462000051 ],
							[ 5.092422998000075, 6.198280325000042 ],
							[ 5.095841467000071, 6.197087793000037 ],
							[ 5.098225684000056, 6.198143444000038 ],
							[ 5.099816826000051, 6.202176146000056 ],
							[ 5.10315603600003, 6.202176117000022 ],
							[ 5.104825576000053, 6.200029329000074 ],
							[ 5.109939606000069, 6.19542794900002 ],
							[ 5.112825873000077, 6.195474168000032 ],
							[ 5.113859607000052, 6.199817249000034 ],
							[ 5.11548995000004, 6.206108520000043 ],
							[ 5.117625118000035, 6.206946341000048 ],
							[ 5.119923435000032, 6.207008877000021 ],
							[ 5.122468124000022, 6.205872113000055 ],
							[ 5.122395062000066, 6.201276291000056 ],
							[ 5.127111776000049, 6.197025255000028 ],
							[ 5.128687476000039, 6.19388814000007 ],
							[ 5.128687245000037, 6.18965862500005 ],
							[ 5.131331064000051, 6.188953391000041 ],
							[ 5.133264904000043, 6.188217614000052 ],
							[ 5.135913425000069, 6.186662202000036 ],
							[ 5.13573745900004, 6.18225618300005 ],
							[ 5.132036292000066, 6.180846190000068 ],
							[ 5.137146951000034, 6.176264306000064 ],
							[ 5.137675884000032, 6.173091926000041 ],
							[ 5.133446313000036, 6.172210753000059 ],
							[ 5.135050694000029, 6.169817003000048 ],
							[ 5.126955879000036, 6.163946601000021 ],
							[ 5.119040513000073, 6.149993473000052 ],
							[ 5.101121028000023, 6.124878931000069 ],
							[ 5.087879287000021, 6.101302130000022 ],
							[ 5.07592960300002, 6.07804869000006 ],
							[ 5.062687740000058, 6.048982110000054 ],
							[ 5.052998891000072, 6.027666088000046 ],
							[ 5.041731832000039, 6.005086433000031 ],
							[ 5.039689516000067, 6.000184508000075 ],
							[ 5.031282864000048, 5.982541560000072 ],
							[ 5.026321885000073, 5.971586750000029 ],
							[ 5.018467448000024, 5.953190836000033 ],
							[ 5.016400890000057, 5.948643152000045 ],
							[ 5.01268009000006, 5.942235431000029 ],
							[ 4.999207704000071, 5.921783577000042 ],
							[ 4.983859100000075, 5.898479872000053 ],
							[ 4.980848782000066, 5.901516413000024 ],
							[ 4.980641882000043, 5.907303818000059 ],
							[ 4.977727949000041, 5.910016967000047 ],
							[ 4.975061012000026, 5.91226438800004 ],
							[ 4.975474371000075, 5.919085457000051 ],
							[ 4.976156700000047, 5.92346905900007 ],
							[ 4.971870415000069, 5.925326883000025 ],
							[ 4.970273053000028, 5.92404887400005 ],
							[ 4.966586570000061, 5.925906581000049 ],
							[ 4.965319208000039, 5.929987931000028 ],
							[ 4.963548738000043, 5.932964819000063 ],
							[ 4.961645167000029, 5.935552073000053 ],
							[ 4.957698317000052, 5.94285582200007 ],
							[ 4.955011304000038, 5.943475673000023 ],
							[ 4.951796581000053, 5.941439675000026 ],
							[ 4.949822401000063, 5.943860068000049 ],
							[ 4.945503203000044, 5.945956285000022 ],
							[ 4.943436696000049, 5.948023356000022 ],
							[ 4.939947552000035, 5.950159517000031 ],
							[ 4.935168739000062, 5.955050976000052 ],
							[ 4.930414632000065, 5.95959814400004 ],
							[ 4.927435833000061, 5.962605484000051 ],
							[ 4.921046232000037, 5.967397691000031 ],
							[ 4.914292289000059, 5.976133813000047 ],
							[ 4.909464864000029, 5.979378226000051 ],
							[ 4.907264314000031, 5.982541628000035 ],
							[ 4.904163887000038, 5.983575288000054 ],
							[ 4.901477811000063, 5.985767865000071 ],
							[ 4.898788515000035, 5.989951167000072 ],
							[ 4.894689042000039, 5.995352315000048 ],
							[ 4.89196877400002, 5.997424165000041 ],
							[ 4.887628110000037, 6.001351302000046 ],
							[ 4.873779303000049, 6.014579758000025 ],
							[ 4.862741075000031, 6.024904230000061 ],
							[ 4.85331631400004, 6.033182568000029 ],
							[ 4.839880946000051, 6.044550933000039 ],
							[ 4.82999378300002, 6.054456711000057 ],
							[ 4.821691495000039, 6.063153697000075 ],
							[ 4.803237458000069, 6.079615621000073 ],
							[ 4.798128190000057, 6.084650010000075 ],
							[ 4.780474131000062, 6.100781448000021 ],
							[ 4.774883278000061, 6.106372319000059 ],
							[ 4.768625247000045, 6.110444475000065 ],
							[ 4.76116177800003, 6.118713328000069 ],
							[ 4.750916432000054, 6.126966948000074 ],
							[ 4.744800047000069, 6.131983823000041 ],
							[ 4.737979017000043, 6.136117992000038 ],
							[ 4.731985092000059, 6.14211232200006 ],
							[ 4.72456509500006, 6.148304523000036 ],
							[ 4.714828988000022, 6.154927260000022 ],
							[ 4.705795311000031, 6.163080215000036 ],
							[ 4.700566810000055, 6.167949226000076 ],
							[ 4.695351103000064, 6.173831002000043 ],
							[ 4.687420893000024, 6.179541122000046 ],
							[ 4.67741687900002, 6.186965398000041 ],
							[ 4.672194040000022, 6.19254735800007 ],
							[ 4.668941974000063, 6.195233289000043 ],
							[ 4.666048477000061, 6.196887009000022 ],
							[ 4.661725574000059, 6.20111225200003 ],
							[ 4.657601879000026, 6.205870667000056 ],
							[ 4.653440004000061, 6.208875180000064 ],
							[ 4.649671081000065, 6.211580803000061 ],
							[ 4.646498606000023, 6.214118429000052 ],
							[ 4.643009146000054, 6.216656199000056 ],
							[ 4.640154381000059, 6.219194424000023 ],
							[ 4.637616617000049, 6.221097446000044 ],
							[ 4.634127096000043, 6.224269869000068 ],
							[ 4.630954792000068, 6.226490550000051 ],
							[ 4.624915582000028, 6.229752090000034 ],
							[ 4.621195286000045, 6.232439076000048 ],
							[ 4.619334731000038, 6.235332559000028 ],
							[ 4.614787614000022, 6.239259666000066 ],
							[ 4.610335373000055, 6.242668574000049 ],
							[ 4.604625182000063, 6.246158107000042 ],
							[ 4.59949210000002, 6.249801602000048 ],
							[ 4.594117643000061, 6.253521962000036 ],
							[ 4.588764151000021, 6.257895442000063 ],
							[ 4.584196100000042, 6.261376406000068 ],
							[ 4.57902858500006, 6.265510498000026 ],
							[ 4.571633733000056, 6.270901714000047 ],
							[ 4.565180376000058, 6.27563854400006 ],
							[ 4.559805882000035, 6.279359386000067 ],
							[ 4.55298476400003, 6.284319862000075 ],
							[ 4.550064517000067, 6.287076047000028 ],
							[ 4.547894437000025, 6.287599027000056 ],
							[ 4.545196087000022, 6.288244707000047 ],
							[ 4.540957992000074, 6.292802332000065 ],
							[ 4.539207016000034, 6.294685874000038 ],
							[ 4.535996902000022, 6.296379497000032 ],
							[ 4.53130389100005, 6.301126465000038 ],
							[ 4.527383366000038, 6.305091800000071 ],
							[ 4.521773876000054, 6.307263374000058 ],
							[ 4.512947522000047, 6.314043962000028 ],
							[ 4.504824575000043, 6.319045110000047 ],
							[ 4.498672482000075, 6.32387828700007 ],
							[ 4.489115265000066, 6.329173146000073 ],
							[ 4.484774515000026, 6.331653571000061 ],
							[ 4.481467757000075, 6.333100796000053 ],
							[ 4.476784197000029, 6.335932692000029 ],
							[ 4.474245995000047, 6.337518645000046 ],
							[ 4.472342917000049, 6.338470513000061 ],
							[ 4.467411076000076, 6.340592842000035 ],
							[ 4.460481124000069, 6.343330435000041 ],
							[ 4.463188129000059, 6.346056349000037 ],
							[ 4.465037881000057, 6.346990101000074 ],
							[ 4.470110463000026, 6.349720413000057 ],
							[ 4.478904233000037, 6.354453661000036 ],
							[ 4.48168092100002, 6.355392555000037 ],
							[ 4.48631137700005, 6.35634131200004 ],
							[ 4.490937248000023, 6.358214040000064 ],
							[ 4.497422214000039, 6.359172776000037 ],
							[ 4.513251277000052, 6.347257131000049 ],
							[ 4.518845050000039, 6.341747799000075 ],
							[ 4.52817340200005, 6.331642607000049 ],
							[ 4.53097583300007, 6.327964849000068 ],
							[ 4.535632186000043, 6.324296986000036 ],
							[ 4.543090800000073, 6.316951231000075 ],
							[ 4.545877509000036, 6.316043407000052 ],
							[ 4.548674629000061, 6.313289147000035 ],
							[ 4.551476415000025, 6.309611374000042 ],
							[ 4.556122806000076, 6.307790229000034 ],
							[ 4.55984170000005, 6.305963977000033 ],
							[ 4.563560557000073, 6.304137688000026 ],
							[ 4.567274557000076, 6.303234997000061 ],
							[ 4.571910326000022, 6.303260751000039 ],
							[ 4.57561402500005, 6.30420450400004 ],
							[ 4.578468305000058, 6.30842447200007 ],
							[ 4.582063117000075, 6.311627373000022 ],
							[ 4.584824039000068, 6.315335790000063 ],
							[ 4.58758543600004, 6.319044617000031 ],
							[ 4.590335819000074, 6.324600236000038 ],
							[ 4.593091890000039, 6.32923220400005 ],
							[ 4.594925829000033, 6.332935876000022 ],
							[ 4.595837561000053, 6.335710970000036 ],
							[ 4.597625279000056, 6.347724521000032 ],
							[ 4.597594198000024, 6.353264273000036 ],
							[ 4.59756855400002, 6.357881099000053 ],
							[ 4.597548073000041, 6.361574719000032 ],
							[ 4.598449739000046, 6.366196197000022 ],
							[ 4.596694509000031, 6.368289517000051 ],
							[ 4.594156738000038, 6.369241287000023 ],
							[ 4.590033108000057, 6.371778831000029 ],
							[ 4.585908956000026, 6.375902570000051 ],
							[ 4.582102275000068, 6.378123387000073 ],
							[ 4.575757958000054, 6.380978461000041 ],
							[ 4.567192989000034, 6.382564494000064 ],
							[ 4.560848184000065, 6.382564455000022 ],
							[ 4.555455705000043, 6.385737071000051 ],
							[ 4.546409131000075, 6.387145511000028 ],
							[ 4.539908367000066, 6.388956115000042 ],
							[ 4.535262144000058, 6.390777065000066 ],
							[ 4.531543270000043, 6.392602776000047 ],
							[ 4.524115504000065, 6.39440884000004 ],
							[ 4.521328977000053, 6.395316673000025 ],
							[ 4.517614757000047, 6.396219329000076 ],
							[ 4.514828218000048, 6.397127112000021 ],
							[ 4.509270224000034, 6.396172995000029 ],
							[ 4.504639638000071, 6.395223979000036 ],
							[ 4.499086404000025, 6.393346809000036 ],
							[ 4.495387974000039, 6.391479367000045 ],
							[ 4.49168972700005, 6.389612186000022 ],
							[ 4.487063878000072, 6.387739753000062 ],
							[ 4.482433305000029, 6.386790731000076 ],
							[ 4.480064388000073, 6.386895722000077 ],
							[ 4.477510882000047, 6.38757520300004 ],
							[ 4.474683285000026, 6.388328157000046 ],
							[ 4.473125430000039, 6.393202701000064 ],
							[ 4.474944039000036, 6.399676429000067 ],
							[ 4.475855901000045, 6.402451547000055 ],
							[ 4.478621458000021, 6.405237120000038 ],
							[ 4.479822716000058, 6.409050120000074 ],
							[ 4.489712302000044, 6.411761883000054 ],
							[ 4.496197206000033, 6.412721182000041 ],
							[ 4.500823005000029, 6.414593789000037 ],
							[ 4.503599227000052, 6.415532225000049 ],
							[ 4.504097594000029, 6.415534957000034 ],
							[ 4.507417591000035, 6.416333100000031 ],
							[ 4.509170184000027, 6.416706441000031 ],
							[ 4.512144071000023, 6.417797864000022 ],
							[ 4.519146052000053, 6.419993822000038 ],
							[ 4.521951758000057, 6.420467890000054 ],
							[ 4.524321480000026, 6.420672651000075 ],
							[ 4.525374692000071, 6.420773704000055 ],
							[ 4.525987364000059, 6.420956971000066 ],
							[ 4.527670431000047, 6.419834414000036 ],
							[ 4.528563979000069, 6.418614307000041 ],
							[ 4.529106130000059, 6.417389925000066 ],
							[ 4.53027165900005, 6.415470040000059 ],
							[ 4.531705034000026, 6.413201290000075 ],
							[ 4.533648400000061, 6.412345838000022 ],
							[ 4.534965011000054, 6.412449800000047 ],
							[ 4.536625534000052, 6.413173443000062 ],
							[ 4.537933589000033, 6.413980681000055 ],
							[ 4.538778388000026, 6.414685036000037 ],
							[ 4.539754353000035, 6.416655921000029 ],
							[ 4.541588322000052, 6.420359701000052 ],
							[ 4.542505325000036, 6.422211180000033 ],
							[ 4.546183133000056, 6.427772028000049 ],
							[ 4.549860927000054, 6.433332558000075 ],
							[ 4.554461049000054, 6.439821293000023 ],
							[ 4.556299700000068, 6.442601803000059 ],
							[ 4.559962306000045, 6.450932356000067 ],
							[ 4.561780918000068, 6.457405894000033 ],
							[ 4.562682090000067, 6.462027458000023 ],
							[ 4.563593917000048, 6.464802617000032 ],
							[ 4.564495532000024, 6.469424717000038 ],
							[ 4.565402015000075, 6.473123071000032 ],
							[ 4.566308941000045, 6.476821427000061 ],
							[ 4.566283173000045, 6.481438233000063 ],
							[ 4.56626277700002, 6.485131839000076 ],
							[ 4.566237013000034, 6.489748087000066 ],
							[ 4.565294220000055, 6.492513219000045 ],
							[ 4.565273839000042, 6.496206268000037 ],
							[ 4.564331022000033, 6.498971379000068 ],
							[ 4.586972163000041, 6.494270667000023 ],
							[ 4.593438008000021, 6.492928491000043 ],
							[ 4.603319161000059, 6.490877209000075 ],
							[ 4.605220786000075, 6.492155060000073 ],
							[ 4.606054249000067, 6.499202349000029 ],
							[ 4.604174651000051, 6.503809044000036 ],
							[ 4.599482042000034, 6.513939820000076 ],
							[ 4.597601845000042, 6.518546194000066 ],
							[ 4.594794749000073, 6.523147631000029 ],
							[ 4.588248774000022, 6.532358700000032 ],
							[ 4.588255923000077, 6.536082872000065 ],
							[ 4.588915120000024, 6.543191690000072 ],
							[ 4.581503872000042, 6.556295185000067 ],
							[ 4.575093690000074, 6.578736685000024 ],
							[ 4.577916013000049, 6.599242232000051 ],
							[ 4.582822126000053, 6.61670242100007 ],
							[ 4.581601440000043, 6.633065120000026 ],
							[ 4.572300469000027, 6.63910581600004 ],
							[ 4.567274613000052, 6.636568613000065 ],
							[ 4.564508480000029, 6.633782906000022 ],
							[ 4.561747480000065, 6.630073981000066 ],
							[ 4.560433808000028, 6.628088345000037 ],
							[ 4.555293151000058, 6.623574791000067 ],
							[ 4.551615199000025, 6.618014379000044 ],
							[ 4.547004711000056, 6.613371778000044 ],
							[ 4.542405078000058, 6.606883024000069 ],
							[ 4.537691195000036, 6.601134929000068 ],
							[ 4.537379163000026, 6.60061057300004 ],
							[ 4.535043720000033, 6.596685340000022 ],
							[ 4.532298145000027, 6.590206623000029 ],
							[ 4.528610209000021, 6.586492625000062 ],
							[ 4.52643729700003, 6.580886458000066 ],
							[ 4.525959884000031, 6.580096971000046 ],
							[ 4.525611508000054, 6.57952073000007 ],
							[ 4.521695851000061, 6.559653036000043 ],
							[ 4.519393637000064, 6.547096104000047 ],
							[ 4.517124756000044, 6.531837625000037 ],
							[ 4.508673141000031, 6.520188148000045 ],
							[ 4.504565867000053, 6.514733298000067 ],
							[ 4.499831316000041, 6.508754932000045 ],
							[ 4.499381974000073, 6.506921843000043 ],
							[ 4.496610628000042, 6.505059626000048 ],
							[ 4.491026875000045, 6.508722358000057 ],
							[ 4.48914190000005, 6.514251659000024 ],
							[ 4.486324267000043, 6.520700072000068 ],
							[ 4.482574506000049, 6.528065599000058 ],
							[ 4.478824603000021, 6.535431919000075 ],
							[ 4.47694447300006, 6.540038525000057 ],
							[ 4.475064724000049, 6.544644866000056 ],
							[ 4.473174114000074, 6.551097833000028 ],
							[ 4.470346386000074, 6.559392505000062 ],
							[ 4.467523582000069, 6.566763969000021 ],
							[ 4.46657084800006, 6.571375497000076 ],
							[ 4.464685884000062, 6.576904685000045 ],
							[ 4.465224715000033, 6.582136630000036 ],
							[ 4.462774753000076, 6.587051506000023 ],
							[ 4.460884577000058, 6.593504409000047 ],
							[ 4.459931819000076, 6.59811593400002 ],
							[ 4.458051707000038, 6.602722179000068 ],
							[ 4.454317098000047, 6.607318383000063 ],
							[ 4.42647074100006, 6.612704241000074 ],
							[ 4.424611102000028, 6.613617538000028 ],
							[ 4.418105574000037, 6.616351188000067 ],
							[ 4.413454037000065, 6.619095797000057 ],
							[ 4.410662711000043, 6.620926823000048 ],
							[ 4.406943262000027, 6.622753283000065 ],
							[ 4.403224473000023, 6.624578843000052 ],
							[ 4.393937057000073, 6.627297773000066 ],
							[ 4.390223562000074, 6.628200579000065 ],
							[ 4.387431616000072, 6.630032013000061 ],
							[ 4.382769537000058, 6.634622595000053 ],
							[ 4.379962498000054, 6.639224095000031 ],
							[ 4.377055184000028, 6.645117222000067 ],
							[ 4.376202594000063, 6.648436884000034 ],
							[ 4.376176828000041, 6.653053396000075 ],
							[ 4.37615106100003, 6.657669908000059 ],
							[ 4.37612013100005, 6.663210450000065 ],
							[ 4.379813719000026, 6.666000827000062 ],
							[ 4.384439462000046, 6.667872870000053 ],
							[ 4.387210331000063, 6.669735058000072 ],
							[ 4.389039061000062, 6.674362191000057 ],
							[ 4.389940699000022, 6.678984147000051 ],
							[ 4.388982706000036, 6.684518682000032 ],
							[ 4.388951825000049, 6.690058643000043 ],
							[ 4.388915970000028, 6.696522081000069 ],
							[ 4.388884959000052, 6.702062057000035 ],
							[ 4.388854501000026, 6.707602038000061 ],
							[ 4.388823470000034, 6.713141993000022 ],
							[ 4.389735168000072, 6.715917009000066 ],
							[ 4.392501336000066, 6.718702350000058 ],
							[ 4.398033626000029, 6.724273224000058 ],
							[ 4.403565956000023, 6.729844030000038 ],
							[ 4.40724377600003, 6.735405082000057 ],
							[ 4.410926780000068, 6.740042325000047 ],
							[ 4.413682874000074, 6.744674150000037 ],
							[ 4.414579384000035, 6.750219203000029 ],
							[ 4.415485885000066, 6.75391761800006 ],
							[ 4.418231484000046, 6.760396327000024 ],
							[ 4.422841504000075, 6.765039106000074 ],
							[ 4.425607721000063, 6.767824183000073 ],
							[ 4.433931788000052, 6.771563632000039 ],
							[ 4.439479801000061, 6.774364608000042 ],
							[ 4.445022048000055, 6.778088666000031 ],
							[ 4.45424801300004, 6.786450008000031 ],
							[ 4.467176852000023, 6.795755317000044 ],
							[ 4.472719697000059, 6.799479526000027 ],
							[ 4.476412696000068, 6.80226999100006 ],
							[ 4.487508240000068, 6.80787194800007 ],
							[ 4.49305104900003, 6.811595843000021 ],
							[ 4.498598558000026, 6.814396456000054 ],
							[ 4.502296885000021, 6.816263762000062 ],
							[ 4.515257362000057, 6.820029391000048 ],
							[ 4.519877965000035, 6.822824556000057 ],
							[ 4.524497921000034, 6.825620782000044 ],
							[ 4.529113316000064, 6.829339573000027 ],
							[ 4.53373915100002, 6.831211945000064 ],
							[ 4.53743742000006, 6.833078889000035 ],
							[ 4.542058051000026, 6.835874621000073 ],
							[ 4.547595562000026, 6.840522251000039 ],
							[ 4.548315941000055, 6.844217191000041 ],
							[ 4.549398451000059, 6.849765827000056 ],
							[ 4.549336867000022, 6.860845662000031 ],
							[ 4.550238576000027, 6.865467455000044 ],
							[ 4.55204152400006, 6.874711006000041 ],
							[ 4.553870234000044, 6.879338369000038 ],
							[ 4.554771364000032, 6.883959842000024 ],
							[ 4.557522251000023, 6.889515394000057 ],
							[ 4.557501751000075, 6.893208383000058 ],
							[ 4.557475990000057, 6.897825111000031 ],
							[ 4.55745035800004, 6.902441840000051 ],
							[ 4.556487626000035, 6.908900189000065 ],
							[ 4.557368200000042, 6.917215485000042 ],
							[ 4.556405022000035, 6.923673546000032 ],
							[ 4.55632822900003, 6.937523440000064 ],
							[ 4.557229526000071, 6.942145470000071 ],
							[ 4.55720375900006, 6.946762190000072 ],
							[ 4.557173272000057, 6.952302079000049 ],
							[ 4.556209998000043, 6.95876013000003 ],
							[ 4.557106075000036, 6.964305043000024 ],
							[ 4.55800245100005, 6.969850511000061 ],
							[ 4.558888909000075, 6.977242050000029 ],
							[ 4.560707133000051, 6.983715420000067 ],
							[ 4.561608729000056, 6.988337432000037 ],
							[ 4.564349150000055, 6.995739797000056 ],
							[ 4.568954071000064, 7.001305501000047 ],
							[ 4.572642329000075, 7.005019255000036 ],
							[ 4.574287919000028, 7.006175996000024 ],
							[ 4.579163051000023, 7.009602482000048 ],
							[ 4.581135721000066, 7.011210765000044 ],
							[ 4.584784003000038, 7.01397892500006 ],
							[ 4.590197032000049, 7.01619658200002 ],
							[ 4.595739792000074, 7.019920822000074 ],
							[ 4.60220950300004, 7.023650150000037 ],
							[ 4.606824920000065, 7.027369577000059 ],
							[ 4.609585837000054, 7.031077780000032 ],
							[ 4.610748279000063, 7.035215293000022 ],
							[ 4.611404367000034, 7.037551428000029 ],
							[ 4.612295621000044, 7.044020192000062 ],
							[ 4.612259860000052, 7.05048325000007 ],
							[ 4.613145767000049, 7.057875166000031 ],
							[ 4.613094328000045, 7.067108551000047 ],
							[ 4.614006058000029, 7.069883789000073 ],
							[ 4.613985558000024, 7.073577023000041 ],
							[ 4.613949820000073, 7.080040332000067 ],
							[ 4.616705367000066, 7.084672477000026 ],
							[ 4.618534092000061, 7.089299175000065 ],
							[ 4.621295437000072, 7.093008113000053 ],
							[ 4.624968024000054, 7.099491949000026 ],
							[ 4.626796646000059, 7.104118879000055 ],
							[ 4.630479758000035, 7.108756044000074 ],
							[ 4.635089895000021, 7.113398538000069 ],
							[ 4.639710420000029, 7.116194361000055 ],
							[ 4.644325683000034, 7.119913002000033 ],
							[ 4.648024151000072, 7.121780473000058 ],
							[ 4.65264458300004, 7.124576128000058 ],
							[ 4.656338263000066, 7.127366675000076 ],
							[ 4.659104299000035, 7.130151873000045 ],
							[ 4.662802671000065, 7.132018913000024 ],
							[ 4.665574070000048, 7.133881166000037 ],
							[ 4.669262370000069, 7.137595030000057 ],
							[ 4.672955451000064, 7.140385694000031 ],
							[ 4.673872522000067, 7.142237594000051 ],
							[ 4.674789439000051, 7.144089213000029 ],
							[ 4.67198703400004, 7.147767135000038 ],
							[ 4.670107348000045, 7.152373795000074 ],
							[ 4.669154222000031, 7.156985164000048 ],
							[ 4.668217197000047, 7.158826774000033 ],
							[ 4.669123592000062, 7.162525287000051 ],
							[ 4.671884571000021, 7.166234111000051 ],
							[ 4.675593315000071, 7.166254389000073 ],
							[ 4.68392754100006, 7.168147016000034 ],
							[ 4.687646865000033, 7.166321124000035 ],
							[ 4.691360549000024, 7.165418105000072 ],
							[ 4.698793438000052, 7.162689598000043 ],
							[ 4.703434519000041, 7.161791940000057 ],
							[ 4.706211103000044, 7.162730724000028 ],
							[ 4.708982529000025, 7.16459264100007 ],
							[ 4.711748536000073, 7.167378004000057 ],
							[ 4.71543164600007, 7.172015116000068 ],
							[ 4.71633812500005, 7.175713506000022 ],
							[ 4.718166866000047, 7.180340799000021 ],
							[ 4.719058116000042, 7.186808960000064 ],
							[ 4.720881525000038, 7.192359389000046 ],
							[ 4.720866239000031, 7.195129444000031 ],
							[ 4.72641900900004, 7.19700726700006 ],
							[ 4.736561728000027, 7.207219979000058 ],
							[ 4.739322609000055, 7.210928831000047 ],
							[ 4.742083549000029, 7.214637382000035 ],
							[ 4.743917474000057, 7.21834080800005 ],
							[ 4.746673133000058, 7.222973326000044 ],
							[ 4.747580073000051, 7.226671631000045 ],
							[ 4.74940874300006, 7.231298472000049 ],
							[ 4.750294741000062, 7.238690258000076 ],
							[ 4.750253634000046, 7.24607695100002 ],
							[ 4.74667929800006, 7.264390270000035 ],
							[ 4.745934400000067, 7.268180185000062 ],
							[ 4.745286044000068, 7.272271053000054 ],
							[ 4.745153440000024, 7.275068572000066 ],
							[ 4.745248931000049, 7.279767714000059 ],
							[ 4.745477029000028, 7.281669634000025 ],
							[ 4.74664589300005, 7.284482241000035 ],
							[ 4.747406153000043, 7.28798950700002 ],
							[ 4.74864500800004, 7.297700315000043 ],
							[ 4.748368084000049, 7.304294428000048 ],
							[ 4.74733789000004, 7.315378046000035 ],
							[ 4.747471880000035, 7.321077430000059 ],
							[ 4.748355670000024, 7.322587082000041 ],
							[ 4.749552422000022, 7.323000805000049 ],
							[ 4.751054275000058, 7.323018198000057 ],
							[ 4.753158059000043, 7.322942803000046 ],
							[ 4.756068687000038, 7.322376549000069 ],
							[ 4.758373724000023, 7.322203410000043 ],
							[ 4.761291288000052, 7.321037526000055 ],
							[ 4.762291408000067, 7.321149121000076 ],
							[ 4.76278965900002, 7.321354664000069 ],
							[ 4.763581411000075, 7.322163524000075 ],
							[ 4.764666620000071, 7.322995566000031 ],
							[ 4.767417874000046, 7.327427775000046 ],
							[ 4.767402638000021, 7.330197794000071 ],
							[ 4.768293834000076, 7.336666629000035 ],
							[ 4.768252801000074, 7.344053289000044 ],
							[ 4.769159240000022, 7.347751509000034 ],
							[ 4.77192065600002, 7.351460379000059 ],
							[ 4.778379957000027, 7.35703615500006 ],
							[ 4.78763096800003, 7.360781327000041 ],
							[ 4.795955163000031, 7.36452046200003 ],
							[ 4.801503265000065, 7.367321400000037 ],
							[ 4.806123699000068, 7.37011730200004 ],
							[ 4.814457834000052, 7.372009699000046 ],
							[ 4.821870328000045, 7.372974415000044 ],
							[ 4.832064084000024, 7.373954187000038 ],
							[ 4.834250885000074, 7.374184176000028 ],
							[ 4.841331020000041, 7.374928823000062 ],
							[ 4.848743494000075, 7.375892995000072 ],
							[ 4.853374012000074, 7.376842561000046 ],
							[ 4.860786406000045, 7.37780678300004 ],
							[ 4.870985451000024, 7.377863440000056 ],
							[ 4.875616056000069, 7.37881244700003 ],
							[ 4.878397482000025, 7.378827581000053 ],
							[ 4.888606985000024, 7.377037463000022 ],
							[ 4.891388971000026, 7.377052783000067 ],
							[ 4.896034713000063, 7.375231782000071 ],
							[ 4.90253021500007, 7.374344413000074 ],
							[ 4.909025686000064, 7.373456930000032 ],
							[ 4.914588872000024, 7.373488081000062 ],
							[ 4.919224750000069, 7.373513673000048 ],
							[ 4.930361333000064, 7.371728463000068 ],
							[ 4.935929244000022, 7.370836144000066 ],
							[ 4.943352292000043, 7.369954084000028 ],
							[ 4.947982844000023, 7.370903009000074 ],
							[ 4.95261382800004, 7.371851882000044 ],
							[ 4.95723917600003, 7.373723851000022 ],
							[ 4.960948016000032, 7.373744610000074 ],
							[ 4.964656838000053, 7.373765351000031 ],
							[ 4.96928734200003, 7.374714303000076 ],
							[ 4.974850692000075, 7.374745395000048 ],
							[ 4.979481661000023, 7.375694223000039 ],
							[ 4.985039268000037, 7.376648314000022 ],
							[ 4.985594776000028, 7.376762323000037 ],
							[ 4.989844521000066, 7.377728888000036 ],
							[ 4.993363371000044, 7.380388294000056 ],
							[ 4.997051663000036, 7.384101869000062 ],
							[ 4.999823124000045, 7.385963813000046 ],
							[ 5.003516653000077, 7.388754457000061 ],
							[ 5.006279988000074, 7.392001764000042 ],
							[ 5.006267076000029, 7.394310061000056 ],
							[ 5.006231305000028, 7.40077296100003 ],
							[ 5.005278103000023, 7.405384555000069 ],
							[ 5.005761563000021, 7.409947335000027 ],
							[ 5.006159324000066, 7.413699601000076 ],
							[ 5.007993212000031, 7.417403318000027 ],
							[ 5.010754162000069, 7.42111202600006 ],
							[ 5.013515016000042, 7.424820989000068 ],
							[ 5.016275949000033, 7.428529653000055 ],
							[ 5.018114991000061, 7.431309620000036 ],
							[ 5.019959007000068, 7.433166416000063 ],
							[ 5.02272513500003, 7.435952168000028 ],
							[ 5.032877777000067, 7.444318855000063 ],
							[ 5.038420568000049, 7.448042991000023 ],
							[ 5.04303118100006, 7.452685281000072 ],
							[ 5.045802111000057, 7.454547414000047 ],
							[ 5.048568173000035, 7.457332668000049 ],
							[ 5.053199382000059, 7.458281958000043 ],
							[ 5.058757425000067, 7.459235772000056 ],
							[ 5.06524233600004, 7.46019519500004 ],
							[ 5.070805553000071, 7.460225947000026 ],
							[ 5.078228073000048, 7.459343974000035 ],
							[ 5.084746384000027, 7.456950544000051 ],
							[ 5.089379819000044, 7.454788714000074 ],
							[ 5.095885739000039, 7.452055013000063 ],
							[ 5.102385991000062, 7.450244070000053 ],
							[ 5.107954634000066, 7.449351682000042 ],
							[ 5.114450066000074, 7.448463945000071 ],
							[ 5.12001807200005, 7.447571664000066 ],
							[ 5.126518676000046, 7.44576085500006 ],
							[ 5.132086634000075, 7.444868113000041 ],
							[ 5.138582194000037, 7.443981081000061 ],
							[ 5.145077710000066, 7.44309336200007 ],
							[ 5.150640956000075, 7.443124333000071 ],
							[ 5.15992306000004, 7.441329091000057 ],
							[ 5.167345543000067, 7.44044672800004 ],
							[ 5.173830940000073, 7.441406222000069 ],
							[ 5.181248269000037, 7.441447290000042 ],
							[ 5.187743644000022, 7.440559944000029 ],
							[ 5.192399841000054, 7.436892026000066 ],
							[ 5.200744972000052, 7.436938360000056 ],
							[ 5.20816753500003, 7.436056027000063 ],
							[ 5.217449738000028, 7.434260744000028 ],
							[ 5.220231144000024, 7.434276116000035 ],
							[ 5.224867215000074, 7.434301786000049 ],
							[ 5.235998666000057, 7.433440330000053 ],
							[ 5.245270172000062, 7.433491761000028 ],
							[ 5.251750424000022, 7.435374144000036 ],
							[ 5.259157796000068, 7.437262193000038 ],
							[ 5.268423945000052, 7.438236748000065 ],
							[ 5.279565698000056, 7.435528254000076 ],
							[ 5.286978342000054, 7.436492883000028 ],
							[ 5.294395560000055, 7.436533924000059 ],
							[ 5.299021143000061, 7.438406354000051 ],
							[ 5.306253832000039, 7.440543755000022 ],
							[ 5.308277560000022, 7.441227900000058 ],
							[ 5.311981282000033, 7.442171649000045 ],
							[ 5.314757683000039, 7.443110571000034 ],
							[ 5.320331008000039, 7.44129466000004 ],
							[ 5.322216504000039, 7.435764765000044 ],
							[ 5.325028984000028, 7.430240196000057 ],
							[ 5.326919099000065, 7.423787134000065 ],
							[ 5.328809148000062, 7.41733406000003 ],
							[ 5.331616929000063, 7.412732579000021 ],
							[ 5.333496535000052, 7.408126345000028 ],
							[ 5.337236420000067, 7.402606979000041 ],
							[ 5.340048418000038, 7.397082287000046 ],
							[ 5.342865873000051, 7.390634420000026 ],
							[ 5.344746056000076, 7.386028409000062 ],
							[ 5.346626219000029, 7.381421536000062 ],
							[ 5.348521801000061, 7.374045479000074 ],
							[ 5.349479651000024, 7.368510741000023 ],
							[ 5.350443021000046, 7.362051995000058 ],
							[ 5.351400811000076, 7.356517249000035 ],
							[ 5.353306663000069, 7.347294310000052 ],
							[ 5.354249499000048, 7.344529569000031 ],
							[ 5.355233091000059, 7.334378205000064 ],
							[ 5.35526369400003, 7.328837901000043 ],
							[ 5.354377711000041, 7.321446331000061 ],
							[ 5.354403367000032, 7.31682974000006 ],
							[ 5.354439214000024, 7.310366274000046 ],
							[ 5.355397086000039, 7.304831508000063 ],
							[ 5.356345306000037, 7.30114330400005 ],
							[ 5.358230109000033, 7.29561321400007 ],
							[ 5.362881758000071, 7.292869125000038 ],
							[ 5.367527842000072, 7.291048115000024 ],
							[ 5.372174176000044, 7.289227049000033 ],
							[ 5.377747384000031, 7.287411113000076 ],
							[ 5.381466386000056, 7.285585033000075 ],
							[ 5.388226154000051, 7.283186924000063 ],
							[ 5.398140545000047, 7.28844775400006 ],
							[ 5.404620116000046, 7.290330294000057 ],
							[ 5.412964700000032, 7.290376628000047 ],
							[ 5.41759582800006, 7.291325580000034 ],
							[ 5.426857581000036, 7.293223284000021 ],
							[ 5.435851233000051, 7.296033729000044 ],
							[ 5.438889979000066, 7.296983607000072 ],
							[ 5.445370182000033, 7.298866263000036 ],
							[ 5.450928189000024, 7.299820380000028 ],
							[ 5.454043816000024, 7.300614314000029 ],
							[ 5.45252169500003, 7.306049791000021 ],
							[ 5.450781928000026, 7.315444943000045 ],
							[ 5.450471166000057, 7.318939185000033 ],
							[ 5.458054975000039, 7.320529640000075 ],
							[ 5.47140812300006, 7.320936073000041 ],
							[ 5.482807986000068, 7.321878844000025 ],
							[ 5.501696826000057, 7.325393023000061 ],
							[ 5.507240495000076, 7.327668053000025 ],
							[ 5.513603364000062, 7.331617444000074 ],
							[ 5.518293921000065, 7.335828650000053 ],
							[ 5.524378524000042, 7.339775229000054 ],
							[ 5.526381832000027, 7.340863481000042 ],
							[ 5.530404174000068, 7.347352019000027 ],
							[ 5.53314967600005, 7.353830728000048 ],
							[ 5.536838168000031, 7.357544757000028 ],
							[ 5.542344509000031, 7.367732024000077 ],
							[ 5.546017253000059, 7.374215972000059 ],
							[ 5.552477001000057, 7.379792126000041 ],
							[ 5.557071679000046, 7.387204114000042 ],
							[ 5.561655942000073, 7.396463476000065 ],
							[ 5.567172990000074, 7.404804336000041 ],
							[ 5.571772948000046, 7.411292993000075 ],
							[ 5.575445534000039, 7.41777718000003 ],
							[ 5.580973099000062, 7.424271535000059 ],
							[ 5.582811708000065, 7.427051440000071 ],
							[ 5.58834943200003, 7.431698837000056 ],
							[ 5.592938841000034, 7.440034904000072 ],
							[ 5.599372806000076, 7.450227323000036 ],
							[ 5.601190927000061, 7.456700827000077 ],
							[ 5.603910898000038, 7.467796338000028 ],
							[ 5.607496808000064, 7.480200274000026 ],
							[ 5.61030921300005, 7.484452224000052 ],
							[ 5.609946444000059, 7.485031837000065 ],
							[ 5.611185049000028, 7.493690479000065 ],
							[ 5.612070953000057, 7.501082448000034 ],
							[ 5.612029673000052, 7.508469246000061 ],
							[ 5.612900151000076, 7.51863091000007 ],
							[ 5.613048434000063, 7.520358276000024 ],
							[ 5.604759151000053, 7.519921984000064 ],
							[ 5.604723089000061, 7.523803734000069 ],
							[ 5.606110093000041, 7.531580686000041 ],
							[ 5.60607404700005, 7.535462138000071 ],
							[ 5.606474742000046, 7.544685825000045 ],
							[ 5.607821329000046, 7.55682936900007 ],
							[ 5.611167326000043, 7.563168540000049 ],
							[ 5.614991091000036, 7.570482740000045 ],
							[ 5.619283376000055, 7.579741968000064 ],
							[ 5.627418076000026, 7.594374334000065 ],
							[ 5.627886526000054, 7.596319652000034 ],
							[ 5.627364336000028, 7.600196629000038 ],
							[ 5.623392064000029, 7.608894900000053 ],
							[ 5.619915255000024, 7.616626735000068 ],
							[ 5.619847923000066, 7.62390502900007 ],
							[ 5.623217185000044, 7.627817789000062 ],
							[ 5.624411722000048, 7.628391951000026 ],
							[ 5.631451533000075, 7.631775447000052 ],
							[ 5.638717393000036, 7.635238878000052 ],
							[ 5.64356908600007, 7.636738802000025 ],
							[ 5.649406727000041, 7.636792102000072 ],
							[ 5.656248882000057, 7.633457891000035 ],
							[ 5.662113090000048, 7.630599549000067 ],
							[ 5.670300802000042, 7.625860175000071 ],
							[ 5.675742676000027, 7.623744065000039 ],
							[ 5.680962141000066, 7.623744084000066 ],
							[ 5.688617167000075, 7.632442799000046 ],
							[ 5.69140105300005, 7.638010353000027 ],
							[ 5.691400868000073, 7.642881983000052 ],
							[ 5.692097287000024, 7.65158116200007 ],
							[ 5.693140951000032, 7.65888837500006 ],
							[ 5.694324437000034, 7.664528247000021 ],
							[ 5.694880918000024, 7.671763526000063 ],
							[ 5.699404293000043, 7.682898142000056 ],
							[ 5.707059904000062, 7.690553750000049 ],
							[ 5.71645507300002, 7.700992561000021 ],
							[ 5.728285849000031, 7.710387782000055 ],
							[ 5.74255237400007, 7.71978275400005 ],
							[ 5.756123007000042, 7.728481821000059 ],
							[ 5.767605891000073, 7.737181237000073 ],
							[ 5.773028439000029, 7.747318798000038 ],
							[ 5.78024203700005, 7.745772819000024 ],
							[ 5.80102458600004, 7.733303508000063 ],
							[ 5.816439270000046, 7.727848906000077 ],
							[ 5.819413547000067, 7.731224593000036 ],
							[ 5.822185106000063, 7.733086458000059 ],
							[ 5.827743, 7.734040690000029 ],
							[ 5.832389478000039, 7.732220030000065 ],
							[ 5.837035077000053, 7.730399026000043 ],
							[ 5.842608408000046, 7.728582826000036 ],
							[ 5.850026124000067, 7.728624463000074 ],
							[ 5.857433342000036, 7.730512186000055 ],
							[ 5.86482989600006, 7.734246306000045 ],
							[ 5.870383401000026, 7.736123887000076 ],
							[ 5.876858297000069, 7.738929860000042 ],
							[ 5.883338034000076, 7.740812283000025 ],
							[ 5.889812831000029, 7.743618580000032 ],
							[ 5.898147237000046, 7.745511639000028 ],
							[ 5.903699952000068, 7.747388705000049 ],
							[ 5.90833099300005, 7.748338161000049 ],
							[ 5.914816031000043, 7.749297205000062 ],
							[ 5.919446852000021, 7.750245944000028 ],
							[ 5.921306619000063, 7.749333002000071 ],
							[ 5.928738980000048, 7.746604504000061 ],
							[ 5.930603921000056, 7.744768102000023 ],
							[ 5.93062446700003, 7.741074467000033 ],
							[ 5.929718063000053, 7.737376196000071 ],
							[ 5.927884173000052, 7.733672149000029 ],
							[ 5.926039584000023, 7.731815454000071 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Osun',
				admin1Pcod: 'NG030',
				admin1RefN: 'Osun',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 4.66014,
				Shape_Area: 0.704549
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 4.949749934000067, 8.05547610800005 ],
							[ 4.95673851600003, 8.053548416000069 ],
							[ 4.960805446000052, 8.052426363000052 ],
							[ 4.966904722000038, 8.046708116000048 ],
							[ 4.968429542000024, 8.037559449000071 ],
							[ 4.970716506000031, 8.032603246000065 ],
							[ 4.96919199000007, 8.024216606000039 ],
							[ 4.967106322000063, 8.020046335000075 ],
							[ 4.96957294200007, 8.01735499800003 ],
							[ 4.973385367000049, 8.018117047000032 ],
							[ 4.981009444000051, 8.025741615000072 ],
							[ 4.987490188000038, 8.032221812000046 ],
							[ 4.989295543000026, 8.034039534000044 ],
							[ 4.995876826000028, 8.032221679000031 ],
							[ 5.001976071000058, 8.034128186000032 ],
							[ 5.005406923000066, 8.043276873000025 ],
							[ 5.009981645000039, 8.050139477000073 ],
							[ 5.014936944000056, 8.054713296000045 ],
							[ 5.020655128000044, 8.059287969000025 ],
							[ 5.029041674000041, 8.063862839000024 ],
							[ 5.03247261100006, 8.068055999000023 ],
							[ 5.037047463000022, 8.070724532000042 ],
							[ 5.039715686000022, 8.071868041000073 ],
							[ 5.044290594000074, 8.069961576000026 ],
							[ 5.049627341000075, 8.064624853000055 ],
							[ 5.05343960600004, 8.057763014000045 ],
							[ 5.054930121000041, 8.049190382000063 ],
							[ 5.057632826000031, 8.039464844000065 ],
							[ 5.056489053000064, 8.031078432000072 ],
							[ 5.053058133000036, 8.023454725000022 ],
							[ 5.039715878000038, 8.01011180100005 ],
							[ 5.035522546000038, 8.00515656500005 ],
							[ 5.032854155000052, 7.996769431000075 ],
							[ 5.033235034000029, 7.989907760000051 ],
							[ 5.033235074000061, 7.982283551000023 ],
							[ 5.031329210000024, 7.976946291000047 ],
							[ 5.020274123000036, 7.960935457000062 ],
							[ 5.016461853000067, 7.95521745800005 ],
							[ 5.008075198000029, 7.951024018000055 ],
							[ 5.001213608000057, 7.946068195000066 ],
							[ 4.996639210000069, 7.939206565000063 ],
							[ 4.990539506000061, 7.931201337000061 ],
							[ 4.978341092000051, 7.923195718000045 ],
							[ 4.967285662000052, 7.915952767000022 ],
							[ 4.949749922000024, 7.90337283100007 ],
							[ 4.94203526900003, 7.89992561400004 ],
							[ 4.941370045000042, 7.89760930500006 ],
							[ 4.937149076000026, 7.889167807000035 ],
							[ 4.932576177000044, 7.885650264000049 ],
							[ 4.927300028000047, 7.880374016000076 ],
							[ 4.924486097000056, 7.874394512000038 ],
							[ 4.924837997000054, 7.868062978000069 ],
							[ 4.923782811000024, 7.863490713000033 ],
							[ 4.916396188000022, 7.857159181000043 ],
							[ 4.914134921000027, 7.850375589000066 ],
							[ 4.911100837000049, 7.850109558000042 ],
							[ 4.910020833000033, 7.841811727000049 ],
							[ 4.908579369000051, 7.829799287000071 ],
							[ 4.915795785000057, 7.82211453900004 ],
							[ 4.915795790000061, 7.810849182000027 ],
							[ 4.91579576700002, 7.795795823000049 ],
							[ 4.915257527000051, 7.791219668000053 ],
							[ 4.914290367000035, 7.783000491000053 ],
							[ 4.909398115000045, 7.766441917000066 ],
							[ 4.904205873000024, 7.757354752000026 ],
							[ 4.903000386000031, 7.749883143000034 ],
							[ 4.898226740000041, 7.739858122000044 ],
							[ 4.895473931000026, 7.734076845000061 ],
							[ 4.893228493000038, 7.730335228000058 ],
							[ 4.892622879000044, 7.725886273000071 ],
							[ 4.889363975000037, 7.713028506000057 ],
							[ 4.885723662000032, 7.699761502000058 ],
							[ 4.882562542000073, 7.686027619000072 ],
							[ 4.88030631700002, 7.676082532000066 ],
							[ 4.87709243200004, 7.667071646000068 ],
							[ 4.872064411000053, 7.650954576000061 ],
							[ 4.871326396000029, 7.645004118000031 ],
							[ 4.868377664000036, 7.635477020000053 ],
							[ 4.863861503000066, 7.622681664000027 ],
							[ 4.868001439000068, 7.616283940000073 ],
							[ 4.883054702000038, 7.611391644000037 ],
							[ 4.887570817000039, 7.604617567000048 ],
							[ 4.892839379000065, 7.592951284000037 ],
							[ 4.902247873000022, 7.576392719000069 ],
							[ 4.906387404000043, 7.562844340000026 ],
							[ 4.909968414000048, 7.555020211000055 ],
							[ 4.917919108000035, 7.529786601000069 ],
							[ 4.920206126000039, 7.517206221000038 ],
							[ 4.925924253000062, 7.505007817000035 ],
							[ 4.938885636000066, 7.478704029000028 ],
							[ 4.947844026000041, 7.454688082000075 ],
							[ 4.952418860000023, 7.447825949000048 ],
							[ 4.967667141000049, 7.43867694100004 ],
							[ 4.975672250000059, 7.433720932000028 ],
							[ 4.984821314000044, 7.42647843900005 ],
							[ 4.987108661000036, 7.419235366000066 ],
							[ 4.997020259000067, 7.415041790000032 ],
							[ 5.006159324000066, 7.413699601000076 ],
							[ 5.005761563000021, 7.409947335000027 ],
							[ 5.005278103000023, 7.405384555000069 ],
							[ 5.006231305000028, 7.40077296100003 ],
							[ 5.006267076000029, 7.394310061000056 ],
							[ 5.006279988000074, 7.392001764000042 ],
							[ 5.003516653000077, 7.388754457000061 ],
							[ 4.999823124000045, 7.385963813000046 ],
							[ 4.997051663000036, 7.384101869000062 ],
							[ 4.993363371000044, 7.380388294000056 ],
							[ 4.989844521000066, 7.377728888000036 ],
							[ 4.985594776000028, 7.376762323000037 ],
							[ 4.985039268000037, 7.376648314000022 ],
							[ 4.979481661000023, 7.375694223000039 ],
							[ 4.974850692000075, 7.374745395000048 ],
							[ 4.96928734200003, 7.374714303000076 ],
							[ 4.964656838000053, 7.373765351000031 ],
							[ 4.960948016000032, 7.373744610000074 ],
							[ 4.95723917600003, 7.373723851000022 ],
							[ 4.95261382800004, 7.371851882000044 ],
							[ 4.947982844000023, 7.370903009000074 ],
							[ 4.943352292000043, 7.369954084000028 ],
							[ 4.935929244000022, 7.370836144000066 ],
							[ 4.930361333000064, 7.371728463000068 ],
							[ 4.919224750000069, 7.373513673000048 ],
							[ 4.914588872000024, 7.373488081000062 ],
							[ 4.909025686000064, 7.373456930000032 ],
							[ 4.90253021500007, 7.374344413000074 ],
							[ 4.896034713000063, 7.375231782000071 ],
							[ 4.891388971000026, 7.377052783000067 ],
							[ 4.888606985000024, 7.377037463000022 ],
							[ 4.878397482000025, 7.378827581000053 ],
							[ 4.875616056000069, 7.37881244700003 ],
							[ 4.870985451000024, 7.377863440000056 ],
							[ 4.860786406000045, 7.37780678300004 ],
							[ 4.853374012000074, 7.376842561000046 ],
							[ 4.848743494000075, 7.375892995000072 ],
							[ 4.841331020000041, 7.374928823000062 ],
							[ 4.834250885000074, 7.374184176000028 ],
							[ 4.832064084000024, 7.373954187000038 ],
							[ 4.821870328000045, 7.372974415000044 ],
							[ 4.814457834000052, 7.372009699000046 ],
							[ 4.806123699000068, 7.37011730200004 ],
							[ 4.801503265000065, 7.367321400000037 ],
							[ 4.795955163000031, 7.36452046200003 ],
							[ 4.78763096800003, 7.360781327000041 ],
							[ 4.778379957000027, 7.35703615500006 ],
							[ 4.77192065600002, 7.351460379000059 ],
							[ 4.769159240000022, 7.347751509000034 ],
							[ 4.768252801000074, 7.344053289000044 ],
							[ 4.768293834000076, 7.336666629000035 ],
							[ 4.767402638000021, 7.330197794000071 ],
							[ 4.767417874000046, 7.327427775000046 ],
							[ 4.764666620000071, 7.322995566000031 ],
							[ 4.763581411000075, 7.322163524000075 ],
							[ 4.76278965900002, 7.321354664000069 ],
							[ 4.762291408000067, 7.321149121000076 ],
							[ 4.761291288000052, 7.321037526000055 ],
							[ 4.758373724000023, 7.322203410000043 ],
							[ 4.756068687000038, 7.322376549000069 ],
							[ 4.753158059000043, 7.322942803000046 ],
							[ 4.751054275000058, 7.323018198000057 ],
							[ 4.749552422000022, 7.323000805000049 ],
							[ 4.748355670000024, 7.322587082000041 ],
							[ 4.747471880000035, 7.321077430000059 ],
							[ 4.74733789000004, 7.315378046000035 ],
							[ 4.748368084000049, 7.304294428000048 ],
							[ 4.74864500800004, 7.297700315000043 ],
							[ 4.747406153000043, 7.28798950700002 ],
							[ 4.74664589300005, 7.284482241000035 ],
							[ 4.745477029000028, 7.281669634000025 ],
							[ 4.745248931000049, 7.279767714000059 ],
							[ 4.745153440000024, 7.275068572000066 ],
							[ 4.745286044000068, 7.272271053000054 ],
							[ 4.745934400000067, 7.268180185000062 ],
							[ 4.74667929800006, 7.264390270000035 ],
							[ 4.750253634000046, 7.24607695100002 ],
							[ 4.750294741000062, 7.238690258000076 ],
							[ 4.74940874300006, 7.231298472000049 ],
							[ 4.747580073000051, 7.226671631000045 ],
							[ 4.746673133000058, 7.222973326000044 ],
							[ 4.743917474000057, 7.21834080800005 ],
							[ 4.742083549000029, 7.214637382000035 ],
							[ 4.739322609000055, 7.210928831000047 ],
							[ 4.736561728000027, 7.207219979000058 ],
							[ 4.72641900900004, 7.19700726700006 ],
							[ 4.720866239000031, 7.195129444000031 ],
							[ 4.720881525000038, 7.192359389000046 ],
							[ 4.719058116000042, 7.186808960000064 ],
							[ 4.718166866000047, 7.180340799000021 ],
							[ 4.71633812500005, 7.175713506000022 ],
							[ 4.71543164600007, 7.172015116000068 ],
							[ 4.711748536000073, 7.167378004000057 ],
							[ 4.708982529000025, 7.16459264100007 ],
							[ 4.706211103000044, 7.162730724000028 ],
							[ 4.703434519000041, 7.161791940000057 ],
							[ 4.698793438000052, 7.162689598000043 ],
							[ 4.691360549000024, 7.165418105000072 ],
							[ 4.687646865000033, 7.166321124000035 ],
							[ 4.68392754100006, 7.168147016000034 ],
							[ 4.675593315000071, 7.166254389000073 ],
							[ 4.671884571000021, 7.166234111000051 ],
							[ 4.669123592000062, 7.162525287000051 ],
							[ 4.668217197000047, 7.158826774000033 ],
							[ 4.669154222000031, 7.156985164000048 ],
							[ 4.670107348000045, 7.152373795000074 ],
							[ 4.67198703400004, 7.147767135000038 ],
							[ 4.674789439000051, 7.144089213000029 ],
							[ 4.673872522000067, 7.142237594000051 ],
							[ 4.672955451000064, 7.140385694000031 ],
							[ 4.669262370000069, 7.137595030000057 ],
							[ 4.665574070000048, 7.133881166000037 ],
							[ 4.662802671000065, 7.132018913000024 ],
							[ 4.659104299000035, 7.130151873000045 ],
							[ 4.656338263000066, 7.127366675000076 ],
							[ 4.65264458300004, 7.124576128000058 ],
							[ 4.648024151000072, 7.121780473000058 ],
							[ 4.644325683000034, 7.119913002000033 ],
							[ 4.639710420000029, 7.116194361000055 ],
							[ 4.635089895000021, 7.113398538000069 ],
							[ 4.630479758000035, 7.108756044000074 ],
							[ 4.626796646000059, 7.104118879000055 ],
							[ 4.624968024000054, 7.099491949000026 ],
							[ 4.621295437000072, 7.093008113000053 ],
							[ 4.618534092000061, 7.089299175000065 ],
							[ 4.616705367000066, 7.084672477000026 ],
							[ 4.613949820000073, 7.080040332000067 ],
							[ 4.613985558000024, 7.073577023000041 ],
							[ 4.614006058000029, 7.069883789000073 ],
							[ 4.613094328000045, 7.067108551000047 ],
							[ 4.613145767000049, 7.057875166000031 ],
							[ 4.612259860000052, 7.05048325000007 ],
							[ 4.612295621000044, 7.044020192000062 ],
							[ 4.611404367000034, 7.037551428000029 ],
							[ 4.610748279000063, 7.035215293000022 ],
							[ 4.609585837000054, 7.031077780000032 ],
							[ 4.604599006000058, 7.031369582000025 ],
							[ 4.592416265000054, 7.028704575000063 ],
							[ 4.577187544000026, 7.023374603000036 ],
							[ 4.55929423300006, 7.02147096300007 ],
							[ 4.542542395000055, 7.021090509000032 ],
							[ 4.534166777000053, 7.02375517400003 ],
							[ 4.526552682000045, 7.023374684000032 ],
							[ 4.522364591000041, 7.024516664000032 ],
							[ 4.516273511000065, 7.022612998000056 ],
							[ 4.509039871000027, 7.013475805000041 ],
							[ 4.496476121000057, 7.001293067000063 ],
							[ 4.488861999000051, 6.991775472000029 ],
							[ 4.480105464000076, 6.990633050000042 ],
							[ 4.463354081000034, 6.993298159000062 ],
							[ 4.454217031000042, 7.000912554000024 ],
							[ 4.438227135000034, 7.004719730000033 ],
							[ 4.424521397000035, 7.002435126000023 ],
							[ 4.416907242000036, 6.99405941200007 ],
							[ 4.409673744000031, 6.98758734200004 ],
							[ 4.400536613000043, 6.980734899000026 ],
							[ 4.390379985000038, 6.980854894000061 ],
							[ 4.386407363000046, 6.98955436600005 ],
							[ 4.382944092000059, 6.991302134000023 ],
							[ 4.376018463000037, 6.994796769000061 ],
							[ 4.374319499000023, 6.998856024000077 ],
							[ 4.373774077000064, 7.002332699000021 ],
							[ 4.372661583000024, 7.006969896000044 ],
							[ 4.371542375000047, 7.011027694000063 ],
							[ 4.370991642000035, 7.013925541000049 ],
							[ 4.371032709000076, 7.017980184000066 ],
							[ 4.376307049000047, 7.023178104000067 ],
							[ 4.378656894000073, 7.026067875000024 ],
							[ 4.376939743000037, 7.028389511000057 ],
							[ 4.371180085000049, 7.032460253000067 ],
							[ 4.370060931000069, 7.036518044000047 ],
							[ 4.370688023000071, 7.041150078000044 ],
							[ 4.370155278000027, 7.045785544000069 ],
							[ 4.370178730000021, 7.048102268000036 ],
							[ 4.370220218000043, 7.052156911000054 ],
							[ 4.370260706000067, 7.056211523000059 ],
							[ 4.368550309000057, 7.059111960000052 ],
							[ 4.365075514000068, 7.059701074000031 ],
							[ 4.361026306000042, 7.060870683000076 ],
							[ 4.357569757000022, 7.063197630000047 ],
							[ 4.352946321000047, 7.064947950000033 ],
							[ 4.347742623000045, 7.066699907000043 ],
							[ 4.340799301000061, 7.068457101000035 ],
							[ 4.334441211000069, 7.070791607000046 ],
							[ 4.315403985000046, 7.081271140000069 ],
							[ 4.311372840000047, 7.084178386000076 ],
							[ 4.29751492500003, 7.090588536000041 ],
							[ 4.292299734000039, 7.091182331000027 ],
							[ 4.287664944000028, 7.091775030000065 ],
							[ 4.284764281000037, 7.091782971000043 ],
							[ 4.276045261000036, 7.090069856000071 ],
							[ 4.272552436000069, 7.088921024000058 ],
							[ 4.268486023000037, 7.08835255200006 ],
							[ 4.264981784000042, 7.086045709000075 ],
							[ 4.261477421000052, 7.083738831000062 ],
							[ 4.256209905000048, 7.079119812000044 ],
							[ 4.25269933200002, 7.076233436000052 ],
							[ 4.250355224000032, 7.073922520000053 ],
							[ 4.24742554300002, 7.071034874000077 ],
							[ 4.244495353000048, 7.06814717900005 ],
							[ 4.240399412000045, 7.064682814000037 ],
							[ 4.236297639000043, 7.060639864000052 ],
							[ 4.233373224000047, 7.058330909000063 ],
							[ 4.227542860000028, 7.05545144000007 ],
							[ 4.223458826000069, 7.053145959000062 ],
							[ 4.218773866000049, 7.047115895000047 ],
							[ 4.213520548000076, 7.045643706000021 ],
							[ 4.210033917000032, 7.045073960000025 ],
							[ 4.205973206000067, 7.045085026000038 ],
							[ 4.203627631000074, 7.046032427000057 ],
							[ 4.200775603000068, 7.047416577000035 ],
							[ 4.197892650000028, 7.049162297000066 ],
							[ 4.195630996000034, 7.054961225000056 ],
							[ 4.195080787000052, 7.057858846000045 ],
							[ 4.194914877000031, 7.061510073000022 ],
							[ 4.190192272000047, 7.069821783000066 ],
							[ 4.186004165000043, 7.085431171000039 ],
							[ 4.182958606000057, 7.111319415000025 ],
							[ 4.177628566000067, 7.134542930000066 ],
							[ 4.173059927000054, 7.158147426000028 ],
							[ 4.172551627000075, 7.161500525000065 ],
							[ 4.171156418000066, 7.170710609000025 ],
							[ 4.168972456000063, 7.173986243000058 ],
							[ 4.162019229000066, 7.171091530000069 ],
							[ 4.15668912700005, 7.164999917000046 ],
							[ 4.139557376000027, 7.15700528900004 ],
							[ 4.115952936000042, 7.146345029000031 ],
							[ 4.096917136000059, 7.137588562000076 ],
							[ 4.088356089000058, 7.133446168000035 ],
							[ 4.087641761000043, 7.138052541000036 ],
							[ 4.084511710000072, 7.139930843000059 ],
							[ 4.080755711000052, 7.140243631000033 ],
							[ 4.079190789000052, 7.138365683000075 ],
							[ 4.076374092000037, 7.134922871000072 ],
							[ 4.073244039000031, 7.135548625000069 ],
							[ 4.071053048000067, 7.138991741000041 ],
							[ 4.071053046000031, 7.142121781000071 ],
							[ 4.072618073000058, 7.146816682000065 ],
							[ 4.077000115000033, 7.149946225000065 ],
							[ 4.080442886000071, 7.149946080000063 ],
							[ 4.083260062000022, 7.149007189000031 ],
							[ 4.085450709000042, 7.151198420000071 ],
							[ 4.083260024000026, 7.154641037000033 ],
							[ 4.080130143000076, 7.158084342000052 ],
							[ 4.075121829000068, 7.159962239000038 ],
							[ 4.071991917000048, 7.162779230000069 ],
							[ 4.072617985000022, 7.167474233000064 ],
							[ 4.074496239000041, 7.170604363000052 ],
							[ 4.07606129100003, 7.17529866700005 ],
							[ 4.077626270000053, 7.177803127000061 ],
							[ 4.078565123000033, 7.182184671000073 ],
							[ 4.078565068000046, 7.186879738000073 ],
							[ 4.078565074000039, 7.190948563000063 ],
							[ 4.07981684300006, 7.195643457000074 ],
							[ 4.081381858000043, 7.200964528000043 ],
							[ 4.084825103000071, 7.204407217000039 ],
							[ 4.084511840000062, 7.207849838000072 ],
							[ 4.086389979000046, 7.212232067000059 ],
							[ 4.088580614000023, 7.214423267000029 ],
							[ 4.089206619000038, 7.218491983000035 ],
							[ 4.09108451700007, 7.222247958000025 ],
							[ 4.094214431000069, 7.228194661000032 ],
							[ 4.097031578000042, 7.230698546000042 ],
							[ 4.099848746000021, 7.232263602000046 ],
							[ 4.102608700000076, 7.233126215000027 ],
							[ 4.105320949000031, 7.237194202000069 ],
							[ 4.107489100000066, 7.245866862000071 ],
							[ 4.111283307000065, 7.25128705000003 ],
							[ 4.125375785000074, 7.257791400000031 ],
							[ 4.125917884000046, 7.26483776200007 ],
							[ 4.125917955000034, 7.270257944000036 ],
							[ 4.129712121000068, 7.27676252200007 ],
							[ 4.129712119000033, 7.282182681000052 ],
							[ 4.128086045000032, 7.288145042000053 ],
							[ 4.129712108000035, 7.293565320000027 ],
							[ 4.131338091000032, 7.299527599000044 ],
							[ 4.129170009000063, 7.305490121000048 ],
							[ 4.128886655000031, 7.310589725000057 ],
							[ 4.131924214000037, 7.312614410000037 ],
							[ 4.136680177000073, 7.330280846000051 ],
							[ 4.138039131000028, 7.352023558000042 ],
							[ 4.134980631000076, 7.363748529000077 ],
							[ 4.133962675000021, 7.367650934000039 ],
							[ 4.134348398000043, 7.373439899000061 ],
							[ 4.135321129000033, 7.388034890000029 ],
							[ 4.135321221000027, 7.405701228000055 ],
							[ 4.138039096000057, 7.421328469000059 ],
							[ 4.141436537000061, 7.439674443000058 ],
							[ 4.146192533000033, 7.448507337000024 ],
							[ 4.148307190000025, 7.458775323000054 ],
							[ 4.15230796000003, 7.462776265000059 ],
							[ 4.156384912000021, 7.479083087000049 ],
							[ 4.161171588000059, 7.48822165100006 ],
							[ 4.163858862000041, 7.493351914000073 ],
							[ 4.165897398000027, 7.506261837000068 ],
							[ 4.164538350000043, 7.514415357000075 ],
							[ 4.161140899000031, 7.523248210000077 ],
							[ 4.15892642600005, 7.529891559000021 ],
							[ 4.148873397000045, 7.534146963000069 ],
							[ 4.142473079000069, 7.531728706000024 ],
							[ 4.134326455000064, 7.528703931000052 ],
							[ 4.125517648000027, 7.531503999000051 ],
							[ 4.127716363000047, 7.542034573000024 ],
							[ 4.130008357000065, 7.545565429000021 ],
							[ 4.129377009000052, 7.549057523000045 ],
							[ 4.128153182000062, 7.553125020000039 ],
							[ 4.119873906000066, 7.560015995000072 ],
							[ 4.115133772000036, 7.564620245000071 ],
							[ 4.108078010000042, 7.56744322000003 ],
							[ 4.108553433000054, 7.575617295000029 ],
							[ 4.114946624000027, 7.578619599000035 ],
							[ 4.121940276000032, 7.580462643000033 ],
							[ 4.124263718000066, 7.581660326000076 ],
							[ 4.128863845000069, 7.587555454000039 ],
							[ 4.129394188000049, 7.591646214000036 ],
							[ 4.129870119000032, 7.599820254000065 ],
							[ 4.122806134000029, 7.603226966000022 ],
							[ 4.118127825000045, 7.603164807000041 ],
							[ 4.113496323000049, 7.599602748000052 ],
							[ 4.10946529000006, 7.594881817000044 ],
							[ 4.103072003000023, 7.591879720000065 ],
							[ 4.096600383000066, 7.594710268000028 ],
							[ 4.093590345000052, 7.601087709000069 ],
							[ 4.092311104000032, 7.609238054000059 ],
							[ 4.085175966000065, 7.617893931000026 ],
							[ 4.081765061000056, 7.619925015000035 ],
							[ 4.080688979000058, 7.619042482000054 ],
							[ 4.080248882000035, 7.618933727000069 ],
							[ 4.076674460000049, 7.619304914000054 ],
							[ 4.055775173000029, 7.643108274000042 ],
							[ 4.050001583000039, 7.650779430000057 ],
							[ 4.034234806000029, 7.655650699000034 ],
							[ 4.033317389000047, 7.656541177000065 ],
							[ 4.03246513800002, 7.657421495000051 ],
							[ 4.024917602000073, 7.664320543000031 ],
							[ 4.024728968000034, 7.66491764400007 ],
							[ 4.03903774500003, 7.684977871000058 ],
							[ 4.056281118000072, 7.69882330300004 ],
							[ 4.075469358000021, 7.708714532000045 ],
							[ 4.077789664000022, 7.710499103000075 ],
							[ 4.076629972000035, 7.71668386500005 ],
							[ 4.076208131000044, 7.718933553000056 ],
							[ 4.074849105000055, 7.729125876000069 ],
							[ 4.074169680000068, 7.735920383000064 ],
							[ 4.069413150000059, 7.74067642600005 ],
							[ 4.061939321000068, 7.748830206000036 ],
							[ 4.06125971900002, 7.754265721000024 ],
							[ 4.06601622900007, 7.75494539400006 ],
							[ 4.07083325900004, 7.759752189000039 ],
							[ 4.069413151000049, 7.770573255000045 ],
							[ 4.070092638000062, 7.784162468000034 ],
							[ 4.072131157000058, 7.796392566000065 ],
							[ 4.070667763000074, 7.800783024000054 ],
							[ 4.072491595000031, 7.808732969000062 ],
							[ 4.076208185000041, 7.824930188000053 ],
							[ 4.091146486000071, 7.850165927000035 ],
							[ 4.09454820600007, 7.849259588000052 ],
							[ 4.10248840600002, 7.843559165000045 ],
							[ 4.107737671000052, 7.843048363000037 ],
							[ 4.113542193000058, 7.844577256000036 ],
							[ 4.115553280000029, 7.846636080000053 ],
							[ 4.118146879000051, 7.848702910000043 ],
							[ 4.119795339000063, 7.849154906000024 ],
							[ 4.130718715000057, 7.848859812000057 ],
							[ 4.147110918000067, 7.849622306000072 ],
							[ 4.164646614000048, 7.854958962000069 ],
							[ 4.17417664900006, 7.861058676000027 ],
							[ 4.186375553000062, 7.864870541000073 ],
							[ 4.197104281000065, 7.865284721000023 ],
							[ 4.198610383000073, 7.864859060000072 ],
							[ 4.201809977000039, 7.865191050000021 ],
							[ 4.205584344000044, 7.866111247000049 ],
							[ 4.210818539000059, 7.866760212000031 ],
							[ 4.211397127000055, 7.867057972000055 ],
							[ 4.213416627000072, 7.868535919000067 ],
							[ 4.21717570200002, 7.870616938000069 ],
							[ 4.217164294000042, 7.871487709000064 ],
							[ 4.217141480000066, 7.87322929000004 ],
							[ 4.216540079000026, 7.874673101000042 ],
							[ 4.213018818000023, 7.876659333000021 ],
							[ 4.20625979700003, 7.881216389000031 ],
							[ 4.201554390000069, 7.884638702000075 ],
							[ 4.197304520000046, 7.888793451000026 ],
							[ 4.192093879000026, 7.900704433000044 ],
							[ 4.191331378000029, 7.910234571000046 ],
							[ 4.198193107000066, 7.916334039000049 ],
							[ 4.209629600000028, 7.919002516000035 ],
							[ 4.216110271000048, 7.921670876000064 ],
							[ 4.224877896000066, 7.927008203000071 ],
							[ 4.230215030000068, 7.92777011000004 ],
							[ 4.233576952000021, 7.930633927000031 ],
							[ 4.235589186000027, 7.932692364000047 ],
							[ 4.236099846000059, 7.938214759000061 ],
							[ 4.236920640000051, 7.942289815000038 ],
							[ 4.238305008000054, 7.947823543000027 ],
							[ 4.238804242000072, 7.954216716000076 ],
							[ 4.240786492000041, 7.958597001000044 ],
							[ 4.242376991000071, 7.960456666000027 ],
							[ 4.250800080000033, 7.965510244000029 ],
							[ 4.265286467000067, 7.970084794000059 ],
							[ 4.27653035700007, 7.971115334000046 ],
							[ 4.285871583000073, 7.965510377000044 ],
							[ 4.293877137000038, 7.95750474700003 ],
							[ 4.295395807000034, 7.954551129000038 ],
							[ 4.307110752000028, 7.944463671000051 ],
							[ 4.315987100000029, 7.943019009000068 ],
							[ 4.328186009000035, 7.935013213000047 ],
							[ 4.335429244000068, 7.923195953000061 ],
							[ 4.338097508000033, 7.916334177000067 ],
							[ 4.348390087000041, 7.904897546000029 ],
							[ 4.351439921000065, 7.896129959000064 ],
							[ 4.360207526000067, 7.882787739000037 ],
							[ 4.363257447000024, 7.872113712000044 ],
							[ 4.36564979000002, 7.859468261000075 ],
							[ 4.369737169000075, 7.865632101000074 ],
							[ 4.371668678000049, 7.869382309000059 ],
							[ 4.380739236000068, 7.882427776000043 ],
							[ 4.38225619800005, 7.884200279000027 ],
							[ 4.385303904000068, 7.886649569000042 ],
							[ 4.391185118000067, 7.891106808000075 ],
							[ 4.39246478900003, 7.892491977000077 ],
							[ 4.396442719000049, 7.89270712800004 ],
							[ 4.402827468000055, 7.892129474000058 ],
							[ 4.406573636000076, 7.891518742000073 ],
							[ 4.410091556000054, 7.891562691000047 ],
							[ 4.416270031000067, 7.889886358000069 ],
							[ 4.420032664000075, 7.887960835000058 ],
							[ 4.422693161000041, 7.886240539000028 ],
							[ 4.427788629000077, 7.88323538700007 ],
							[ 4.431086637000021, 7.883276400000057 ],
							[ 4.435022379000031, 7.885078744000054 ],
							[ 4.43631977900003, 7.886848293000071 ],
							[ 4.436946443000068, 7.889486072000068 ],
							[ 4.438200056000028, 7.894761926000058 ],
							[ 4.439456315000029, 7.899818537000044 ],
							[ 4.440281105000054, 7.904212203000043 ],
							[ 4.440237312000022, 7.907718531000057 ],
							[ 4.439985851000074, 7.912494471000059 ],
							[ 4.440129772000034, 7.91350167500002 ],
							[ 4.438126909000061, 7.918212639000046 ],
							[ 4.436106795000057, 7.921475143000066 ],
							[ 4.431615613000076, 7.928871370000024 ],
							[ 4.429592637000042, 7.932353072000069 ],
							[ 4.429122724000024, 7.934758231000046 ],
							[ 4.429317897000033, 7.936733101000073 ],
							[ 4.432157438000047, 7.938302821000036 ],
							[ 4.434793601000024, 7.938554562000036 ],
							[ 4.442701900000031, 7.93931027900004 ],
							[ 4.447094462000052, 7.939802990000032 ],
							[ 4.456319618000066, 7.940793990000031 ],
							[ 4.458179057000052, 7.94149403800003 ],
							[ 4.468853049000074, 7.942256499000052 ],
							[ 4.482468203000053, 7.941878124000027 ],
							[ 4.484008689000063, 7.942669347000049 ],
							[ 4.485743438000043, 7.944663075000051 ],
							[ 4.48704129500004, 7.94643244100007 ],
							[ 4.488330909000069, 7.948859266000056 ],
							[ 4.489628664000065, 7.950628621000021 ],
							[ 4.492688601000054, 7.952200293000033 ],
							[ 4.495104858000047, 7.952449200000046 ],
							[ 4.501262386000064, 7.952524607000043 ],
							[ 4.507061880000037, 7.951954064000063 ],
							[ 4.50945835300007, 7.95281017800005 ],
							[ 4.512088370000072, 7.953722261000053 ],
							[ 4.516097711000043, 7.955628322000052 ],
							[ 4.524885334000032, 7.960063718000072 ],
							[ 4.525482403000069, 7.960378147000029 ],
							[ 4.526573249000023, 7.960952354000028 ],
							[ 4.530896409000036, 7.963228290000075 ],
							[ 4.534893171000022, 7.966214016000038 ],
							[ 4.538890003000063, 7.969199403000061 ],
							[ 4.542896334000034, 7.971412013000077 ],
							[ 4.546908372000075, 7.973160926000048 ],
							[ 4.550748397000064, 7.976299157000028 ],
							[ 4.555672327000025, 7.979604737000045 ],
							[ 4.557550025000069, 7.982039825000072 ],
							[ 4.559962222000024, 7.994101001000047 ],
							[ 4.563011650000021, 8.008968247000041 ],
							[ 4.563011610000046, 8.019261252000035 ],
							[ 4.563011674000052, 8.024598068000046 ],
							[ 4.563011671000027, 8.034128113000065 ],
							[ 4.560343289000059, 8.041371230000038 ],
							[ 4.559199758000034, 8.048995001000037 ],
							[ 4.560724787000026, 8.053951244000075 ],
							[ 4.56899831100003, 8.054756062000024 ],
							[ 4.579022868000038, 8.057762994000029 ],
							[ 4.590458858000034, 8.062337949000039 ],
							[ 4.598083013000064, 8.063862828000026 ],
							[ 4.603038842000046, 8.068055986000047 ],
							[ 4.612950261000037, 8.073011290000068 ],
							[ 4.621718383000029, 8.075679913000045 ],
							[ 4.62896101900003, 8.074536293000051 ],
							[ 4.633916796000051, 8.071486508000021 ],
							[ 4.634137659000032, 8.068176168000036 ],
							[ 4.632392438000068, 8.061194375000071 ],
							[ 4.633916792000036, 8.057001187000026 ],
							[ 4.637347652000074, 8.053569846000073 ],
							[ 4.642684902000042, 8.053188356000021 ],
							[ 4.648403217000066, 8.053569732000028 ],
							[ 4.653358953000065, 8.050519983000072 ],
							[ 4.659458108000024, 8.045564640000066 ],
							[ 4.665557331000059, 8.043276767000066 ],
							[ 4.673562925000056, 8.045946211000057 ],
							[ 4.67508790200003, 8.049758055000041 ],
							[ 4.679662213000029, 8.052044772000045 ],
							[ 4.682712087000027, 8.054713384000024 ],
							[ 4.687286365000034, 8.057001134000075 ],
							[ 4.69224220500007, 8.056238051000037 ],
							[ 4.695673010000064, 8.052426399000069 ],
							[ 4.699608794000028, 8.049096193000025 ],
							[ 4.704440997000063, 8.048233164000067 ],
							[ 4.70558836400005, 8.051510911000037 ],
							[ 4.711684199000047, 8.059669476000067 ],
							[ 4.721214226000029, 8.064244390000056 ],
							[ 4.735319143000027, 8.063481383000067 ],
							[ 4.745611695000036, 8.064244313000074 ],
							[ 4.754760694000026, 8.065006199000038 ],
							[ 4.766197170000055, 8.068055971000035 ],
							[ 4.774718792000044, 8.068306862000043 ],
							[ 4.779158060000043, 8.068437532000075 ],
							[ 4.785638801000061, 8.068437545000052 ],
							[ 4.792447081000034, 8.06666093900003 ],
							[ 4.792956210000057, 8.066541575000031 ],
							[ 4.799491759000034, 8.065018318000057 ],
							[ 4.805271173000051, 8.063862700000072 ],
							[ 4.812895274000027, 8.059287906000066 ],
							[ 4.818613509000045, 8.053569738000022 ],
							[ 4.830050021000034, 8.055476178000049 ],
							[ 4.843392319000031, 8.061956414000065 ],
							[ 4.850381306000031, 8.066615935000073 ],
							[ 4.852541486000064, 8.068056010000021 ],
							[ 4.857497206000062, 8.072630860000061 ],
							[ 4.856734742000071, 8.079111106000028 ],
							[ 4.854828823000048, 8.08597291500007 ],
							[ 4.851778965000051, 8.091690968000023 ],
							[ 4.854066381000052, 8.095502729000032 ],
							[ 4.861309114000051, 8.09474080800004 ],
							[ 4.877701280000053, 8.091309628000033 ],
							[ 4.887231307000036, 8.090929172000074 ],
							[ 4.89540809500005, 8.089356432000045 ],
							[ 4.913153709000028, 8.085591218000047 ],
							[ 4.926877575000049, 8.079872952000073 ],
							[ 4.939457440000069, 8.068056237000064 ],
							[ 4.945175584000026, 8.06081302900003 ],
							[ 4.949749934000067, 8.05547610800005 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Oyo',
				admin1Pcod: 'NG031',
				admin1RefN: 'Oyo',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 8.624586,
				Shape_Area: 2.264918
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 4.21046018800007, 8.970032953000043 ],
							[ 4.253385500000036, 8.966966721000063 ],
							[ 4.273368431000051, 8.979264356000044 ],
							[ 4.290276449000032, 8.991561151000042 ],
							[ 4.316407144000038, 8.997709318000034 ],
							[ 4.325629678000041, 8.994634468000072 ],
							[ 4.329013373000066, 8.989558892000048 ],
							[ 4.334852185000045, 8.980800855000041 ],
							[ 4.334852221000062, 8.957744881000053 ],
							[ 4.321018258000038, 8.942373513000064 ],
							[ 4.257996989000048, 8.900871186000074 ],
							[ 4.221106449000047, 8.860906421000038 ],
							[ 4.200089657000035, 8.825055700000064 ],
							[ 4.194982455000059, 8.817972847000021 ],
							[ 4.186058465000031, 8.805596089000062 ],
							[ 4.182627296000021, 8.785983032000047 ],
							[ 4.182902261000038, 8.765195413000072 ],
							[ 4.190404072000035, 8.75385198500004 ],
							[ 4.190364355000042, 8.753309497000032 ],
							[ 4.20112417100006, 8.717955644000028 ],
							[ 4.206113358000039, 8.703819271000043 ],
							[ 4.210346702000038, 8.691824773000064 ],
							[ 4.21342082700005, 8.682601930000033 ],
							[ 4.209143677000043, 8.680658442000038 ],
							[ 4.259768564000069, 8.575006624000025 ],
							[ 4.348138820000031, 8.39119518800004 ],
							[ 4.364490252000053, 8.358498112000063 ],
							[ 4.367635834000055, 8.350172280000038 ],
							[ 4.375293417000023, 8.31818662400002 ],
							[ 4.375618189000022, 8.316829813000027 ],
							[ 4.381735117000062, 8.301432059000035 ],
							[ 4.382558415000062, 8.299359495000033 ],
							[ 4.386852023000074, 8.291888842000048 ],
							[ 4.403814142000044, 8.278723835000051 ],
							[ 4.421376163000048, 8.270464021000066 ],
							[ 4.441759587000035, 8.252991798000039 ],
							[ 4.460104991000037, 8.236005920000025 ],
							[ 4.477091393000023, 8.218340037000075 ],
							[ 4.483886264000034, 8.202712132000045 ],
							[ 4.490001147000044, 8.182328124000037 ],
							[ 4.494078223000031, 8.166700296000045 ],
							[ 4.494078202000026, 8.149713594000048 ],
							[ 4.494078150000064, 8.133406753000031 ],
							[ 4.494964129000039, 8.122775065000042 ],
							[ 4.495437203000051, 8.117099880000069 ],
							[ 4.505629043000056, 8.09195905200005 ],
							[ 4.533486792000076, 8.071575153000026 ],
							[ 4.55726807800005, 8.058665318000067 ],
							[ 4.56899831100003, 8.054756062000024 ],
							[ 4.560724787000026, 8.053951244000075 ],
							[ 4.559199758000034, 8.048995001000037 ],
							[ 4.560343289000059, 8.041371230000038 ],
							[ 4.563011671000027, 8.034128113000065 ],
							[ 4.563011674000052, 8.024598068000046 ],
							[ 4.563011610000046, 8.019261252000035 ],
							[ 4.563011650000021, 8.008968247000041 ],
							[ 4.559962222000024, 7.994101001000047 ],
							[ 4.557550025000069, 7.982039825000072 ],
							[ 4.555672327000025, 7.979604737000045 ],
							[ 4.550748397000064, 7.976299157000028 ],
							[ 4.546908372000075, 7.973160926000048 ],
							[ 4.542896334000034, 7.971412013000077 ],
							[ 4.538890003000063, 7.969199403000061 ],
							[ 4.534893171000022, 7.966214016000038 ],
							[ 4.530896409000036, 7.963228290000075 ],
							[ 4.526573249000023, 7.960952354000028 ],
							[ 4.525482403000069, 7.960378147000029 ],
							[ 4.524885334000032, 7.960063718000072 ],
							[ 4.516097711000043, 7.955628322000052 ],
							[ 4.512088370000072, 7.953722261000053 ],
							[ 4.50945835300007, 7.95281017800005 ],
							[ 4.507061880000037, 7.951954064000063 ],
							[ 4.501262386000064, 7.952524607000043 ],
							[ 4.495104858000047, 7.952449200000046 ],
							[ 4.492688601000054, 7.952200293000033 ],
							[ 4.489628664000065, 7.950628621000021 ],
							[ 4.488330909000069, 7.948859266000056 ],
							[ 4.48704129500004, 7.94643244100007 ],
							[ 4.485743438000043, 7.944663075000051 ],
							[ 4.484008689000063, 7.942669347000049 ],
							[ 4.482468203000053, 7.941878124000027 ],
							[ 4.468853049000074, 7.942256499000052 ],
							[ 4.458179057000052, 7.94149403800003 ],
							[ 4.456319618000066, 7.940793990000031 ],
							[ 4.447094462000052, 7.939802990000032 ],
							[ 4.442701900000031, 7.93931027900004 ],
							[ 4.434793601000024, 7.938554562000036 ],
							[ 4.432157438000047, 7.938302821000036 ],
							[ 4.429317897000033, 7.936733101000073 ],
							[ 4.429122724000024, 7.934758231000046 ],
							[ 4.429592637000042, 7.932353072000069 ],
							[ 4.431615613000076, 7.928871370000024 ],
							[ 4.436106795000057, 7.921475143000066 ],
							[ 4.438126909000061, 7.918212639000046 ],
							[ 4.440129772000034, 7.91350167500002 ],
							[ 4.439985851000074, 7.912494471000059 ],
							[ 4.440237312000022, 7.907718531000057 ],
							[ 4.440281105000054, 7.904212203000043 ],
							[ 4.439456315000029, 7.899818537000044 ],
							[ 4.438200056000028, 7.894761926000058 ],
							[ 4.436946443000068, 7.889486072000068 ],
							[ 4.43631977900003, 7.886848293000071 ],
							[ 4.435022379000031, 7.885078744000054 ],
							[ 4.431086637000021, 7.883276400000057 ],
							[ 4.427788629000077, 7.88323538700007 ],
							[ 4.422693161000041, 7.886240539000028 ],
							[ 4.420032664000075, 7.887960835000058 ],
							[ 4.416270031000067, 7.889886358000069 ],
							[ 4.410091556000054, 7.891562691000047 ],
							[ 4.406573636000076, 7.891518742000073 ],
							[ 4.402827468000055, 7.892129474000058 ],
							[ 4.396442719000049, 7.89270712800004 ],
							[ 4.39246478900003, 7.892491977000077 ],
							[ 4.391185118000067, 7.891106808000075 ],
							[ 4.385303904000068, 7.886649569000042 ],
							[ 4.38225619800005, 7.884200279000027 ],
							[ 4.380739236000068, 7.882427776000043 ],
							[ 4.371668678000049, 7.869382309000059 ],
							[ 4.369737169000075, 7.865632101000074 ],
							[ 4.36564979000002, 7.859468261000075 ],
							[ 4.363257447000024, 7.872113712000044 ],
							[ 4.360207526000067, 7.882787739000037 ],
							[ 4.351439921000065, 7.896129959000064 ],
							[ 4.348390087000041, 7.904897546000029 ],
							[ 4.338097508000033, 7.916334177000067 ],
							[ 4.335429244000068, 7.923195953000061 ],
							[ 4.328186009000035, 7.935013213000047 ],
							[ 4.315987100000029, 7.943019009000068 ],
							[ 4.307110752000028, 7.944463671000051 ],
							[ 4.295395807000034, 7.954551129000038 ],
							[ 4.293877137000038, 7.95750474700003 ],
							[ 4.285871583000073, 7.965510377000044 ],
							[ 4.27653035700007, 7.971115334000046 ],
							[ 4.265286467000067, 7.970084794000059 ],
							[ 4.250800080000033, 7.965510244000029 ],
							[ 4.242376991000071, 7.960456666000027 ],
							[ 4.240786492000041, 7.958597001000044 ],
							[ 4.238804242000072, 7.954216716000076 ],
							[ 4.238305008000054, 7.947823543000027 ],
							[ 4.236920640000051, 7.942289815000038 ],
							[ 4.236099846000059, 7.938214759000061 ],
							[ 4.235589186000027, 7.932692364000047 ],
							[ 4.233576952000021, 7.930633927000031 ],
							[ 4.230215030000068, 7.92777011000004 ],
							[ 4.224877896000066, 7.927008203000071 ],
							[ 4.216110271000048, 7.921670876000064 ],
							[ 4.209629600000028, 7.919002516000035 ],
							[ 4.198193107000066, 7.916334039000049 ],
							[ 4.191331378000029, 7.910234571000046 ],
							[ 4.192093879000026, 7.900704433000044 ],
							[ 4.197304520000046, 7.888793451000026 ],
							[ 4.201554390000069, 7.884638702000075 ],
							[ 4.20625979700003, 7.881216389000031 ],
							[ 4.213018818000023, 7.876659333000021 ],
							[ 4.216540079000026, 7.874673101000042 ],
							[ 4.217141480000066, 7.87322929000004 ],
							[ 4.217164294000042, 7.871487709000064 ],
							[ 4.21717570200002, 7.870616938000069 ],
							[ 4.213416627000072, 7.868535919000067 ],
							[ 4.211397127000055, 7.867057972000055 ],
							[ 4.210818539000059, 7.866760212000031 ],
							[ 4.205584344000044, 7.866111247000049 ],
							[ 4.201809977000039, 7.865191050000021 ],
							[ 4.198610383000073, 7.864859060000072 ],
							[ 4.197104281000065, 7.865284721000023 ],
							[ 4.186375553000062, 7.864870541000073 ],
							[ 4.17417664900006, 7.861058676000027 ],
							[ 4.164646614000048, 7.854958962000069 ],
							[ 4.147110918000067, 7.849622306000072 ],
							[ 4.130718715000057, 7.848859812000057 ],
							[ 4.119795339000063, 7.849154906000024 ],
							[ 4.118146879000051, 7.848702910000043 ],
							[ 4.115553280000029, 7.846636080000053 ],
							[ 4.113542193000058, 7.844577256000036 ],
							[ 4.107737671000052, 7.843048363000037 ],
							[ 4.10248840600002, 7.843559165000045 ],
							[ 4.09454820600007, 7.849259588000052 ],
							[ 4.091146486000071, 7.850165927000035 ],
							[ 4.076208185000041, 7.824930188000053 ],
							[ 4.072491595000031, 7.808732969000062 ],
							[ 4.070667763000074, 7.800783024000054 ],
							[ 4.072131157000058, 7.796392566000065 ],
							[ 4.070092638000062, 7.784162468000034 ],
							[ 4.069413151000049, 7.770573255000045 ],
							[ 4.07083325900004, 7.759752189000039 ],
							[ 4.06601622900007, 7.75494539400006 ],
							[ 4.06125971900002, 7.754265721000024 ],
							[ 4.061939321000068, 7.748830206000036 ],
							[ 4.069413150000059, 7.74067642600005 ],
							[ 4.074169680000068, 7.735920383000064 ],
							[ 4.074849105000055, 7.729125876000069 ],
							[ 4.076208131000044, 7.718933553000056 ],
							[ 4.076629972000035, 7.71668386500005 ],
							[ 4.077789664000022, 7.710499103000075 ],
							[ 4.075469358000021, 7.708714532000045 ],
							[ 4.056281118000072, 7.69882330300004 ],
							[ 4.03903774500003, 7.684977871000058 ],
							[ 4.024728968000034, 7.66491764400007 ],
							[ 4.024917602000073, 7.664320543000031 ],
							[ 4.03246513800002, 7.657421495000051 ],
							[ 4.033317389000047, 7.656541177000065 ],
							[ 4.034234806000029, 7.655650699000034 ],
							[ 4.050001583000039, 7.650779430000057 ],
							[ 4.055775173000029, 7.643108274000042 ],
							[ 4.076674460000049, 7.619304914000054 ],
							[ 4.080248882000035, 7.618933727000069 ],
							[ 4.080688979000058, 7.619042482000054 ],
							[ 4.081765061000056, 7.619925015000035 ],
							[ 4.085175966000065, 7.617893931000026 ],
							[ 4.092311104000032, 7.609238054000059 ],
							[ 4.093590345000052, 7.601087709000069 ],
							[ 4.096600383000066, 7.594710268000028 ],
							[ 4.103072003000023, 7.591879720000065 ],
							[ 4.10946529000006, 7.594881817000044 ],
							[ 4.113496323000049, 7.599602748000052 ],
							[ 4.118127825000045, 7.603164807000041 ],
							[ 4.122806134000029, 7.603226966000022 ],
							[ 4.129870119000032, 7.599820254000065 ],
							[ 4.129394188000049, 7.591646214000036 ],
							[ 4.128863845000069, 7.587555454000039 ],
							[ 4.124263718000066, 7.581660326000076 ],
							[ 4.121940276000032, 7.580462643000033 ],
							[ 4.114946624000027, 7.578619599000035 ],
							[ 4.108553433000054, 7.575617295000029 ],
							[ 4.108078010000042, 7.56744322000003 ],
							[ 4.115133772000036, 7.564620245000071 ],
							[ 4.119873906000066, 7.560015995000072 ],
							[ 4.128153182000062, 7.553125020000039 ],
							[ 4.129377009000052, 7.549057523000045 ],
							[ 4.130008357000065, 7.545565429000021 ],
							[ 4.127716363000047, 7.542034573000024 ],
							[ 4.125517648000027, 7.531503999000051 ],
							[ 4.134326455000064, 7.528703931000052 ],
							[ 4.142473079000069, 7.531728706000024 ],
							[ 4.148873397000045, 7.534146963000069 ],
							[ 4.15892642600005, 7.529891559000021 ],
							[ 4.161140899000031, 7.523248210000077 ],
							[ 4.164538350000043, 7.514415357000075 ],
							[ 4.165897398000027, 7.506261837000068 ],
							[ 4.163858862000041, 7.493351914000073 ],
							[ 4.161171588000059, 7.48822165100006 ],
							[ 4.156384912000021, 7.479083087000049 ],
							[ 4.15230796000003, 7.462776265000059 ],
							[ 4.148307190000025, 7.458775323000054 ],
							[ 4.146192533000033, 7.448507337000024 ],
							[ 4.141436537000061, 7.439674443000058 ],
							[ 4.138039096000057, 7.421328469000059 ],
							[ 4.135321221000027, 7.405701228000055 ],
							[ 4.135321129000033, 7.388034890000029 ],
							[ 4.134348398000043, 7.373439899000061 ],
							[ 4.133962675000021, 7.367650934000039 ],
							[ 4.134980631000076, 7.363748529000077 ],
							[ 4.138039131000028, 7.352023558000042 ],
							[ 4.136680177000073, 7.330280846000051 ],
							[ 4.131924214000037, 7.312614410000037 ],
							[ 4.128886655000031, 7.310589725000057 ],
							[ 4.129170009000063, 7.305490121000048 ],
							[ 4.131338091000032, 7.299527599000044 ],
							[ 4.129712108000035, 7.293565320000027 ],
							[ 4.128086045000032, 7.288145042000053 ],
							[ 4.129712119000033, 7.282182681000052 ],
							[ 4.129712121000068, 7.27676252200007 ],
							[ 4.125917955000034, 7.270257944000036 ],
							[ 4.125917884000046, 7.26483776200007 ],
							[ 4.125375785000074, 7.257791400000031 ],
							[ 4.111283307000065, 7.25128705000003 ],
							[ 4.107489100000066, 7.245866862000071 ],
							[ 4.105320949000031, 7.237194202000069 ],
							[ 4.102608700000076, 7.233126215000027 ],
							[ 4.099848746000021, 7.232263602000046 ],
							[ 4.097031578000042, 7.230698546000042 ],
							[ 4.094214431000069, 7.228194661000032 ],
							[ 4.09108451700007, 7.222247958000025 ],
							[ 4.089206619000038, 7.218491983000035 ],
							[ 4.088580614000023, 7.214423267000029 ],
							[ 4.086389979000046, 7.212232067000059 ],
							[ 4.084511840000062, 7.207849838000072 ],
							[ 4.084825103000071, 7.204407217000039 ],
							[ 4.081381858000043, 7.200964528000043 ],
							[ 4.07981684300006, 7.195643457000074 ],
							[ 4.078565074000039, 7.190948563000063 ],
							[ 4.078565068000046, 7.186879738000073 ],
							[ 4.078565123000033, 7.182184671000073 ],
							[ 4.077626270000053, 7.177803127000061 ],
							[ 4.07606129100003, 7.17529866700005 ],
							[ 4.074496239000041, 7.170604363000052 ],
							[ 4.072617985000022, 7.167474233000064 ],
							[ 4.071991917000048, 7.162779230000069 ],
							[ 4.075121829000068, 7.159962239000038 ],
							[ 4.080130143000076, 7.158084342000052 ],
							[ 4.083260024000026, 7.154641037000033 ],
							[ 4.085450709000042, 7.151198420000071 ],
							[ 4.083260062000022, 7.149007189000031 ],
							[ 4.080442886000071, 7.149946080000063 ],
							[ 4.077000115000033, 7.149946225000065 ],
							[ 4.072618073000058, 7.146816682000065 ],
							[ 4.071053046000031, 7.142121781000071 ],
							[ 4.071053048000067, 7.138991741000041 ],
							[ 4.073244039000031, 7.135548625000069 ],
							[ 4.076374092000037, 7.134922871000072 ],
							[ 4.079190789000052, 7.138365683000075 ],
							[ 4.080755711000052, 7.140243631000033 ],
							[ 4.084511710000072, 7.139930843000059 ],
							[ 4.087641761000043, 7.138052541000036 ],
							[ 4.088356089000058, 7.133446168000035 ],
							[ 4.085114932000067, 7.13187784400003 ],
							[ 4.069125224000061, 7.132258945000046 ],
							[ 4.057703487000026, 7.137208070000042 ],
							[ 4.045901760000049, 7.138349918000074 ],
							[ 4.026865985000029, 7.128451488000053 ],
							[ 4.019251343000064, 7.124644145000048 ],
							[ 4.012756801000023, 7.126267852000069 ],
							[ 4.005546086000038, 7.128451434000056 ],
							[ 3.997550919000048, 7.126167310000028 ],
							[ 3.995383008000033, 7.125019771000041 ],
							[ 3.978134370000021, 7.11588808700003 ],
							[ 3.969758685000045, 7.111319540000068 ],
							[ 3.965570913000022, 7.109796521000021 ],
							[ 3.961383127000033, 7.097994208000046 ],
							[ 3.960240826000074, 7.09228385800003 ],
							[ 3.953388242000074, 7.088095610000039 ],
							[ 3.938921004000065, 7.083908191000035 ],
							[ 3.916078086000027, 7.083527211000046 ],
							[ 3.890189658000054, 7.083908098000052 ],
							[ 3.874199637000061, 7.083146588000034 ],
							[ 3.842820781000057, 7.082940291000057 ],
							[ 3.823610312000028, 7.082812675000071 ],
							[ 3.816521385000044, 7.082765954000024 ],
							[ 3.797485824000034, 7.080862657000068 ],
							[ 3.783780061000073, 7.080101060000061 ],
							[ 3.758652906000066, 7.080862455000045 ],
							[ 3.73999810600003, 7.081623442000023 ],
							[ 3.731213091000029, 7.080647546000023 ],
							[ 3.727600573000075, 7.083577640000044 ],
							[ 3.724769577000075, 7.087715187000072 ],
							[ 3.727434392000021, 7.096852427000044 ],
							[ 3.73847507000005, 7.103324304000068 ],
							[ 3.752561545000049, 7.107892889000027 ],
							[ 3.781495798000037, 7.121218072000033 ],
							[ 3.79291728000004, 7.132258814000068 ],
							[ 3.794440035000036, 7.139492104000055 ],
							[ 3.793044552000026, 7.14484169800005 ],
							[ 3.790632939000034, 7.149771609000027 ],
							[ 3.789919347000023, 7.152704766000056 ],
							[ 3.787206682000033, 7.163858015000073 ],
							[ 3.782638102000021, 7.17223348400006 ],
							[ 3.777307965000034, 7.180609334000053 ],
							[ 3.777688726000065, 7.192030951000049 ],
							[ 3.780353773000058, 7.202690655000026 ],
							[ 3.77502366300007, 7.210685817000069 ],
							[ 3.768932360000065, 7.223629969000058 ],
							[ 3.763602279000054, 7.230482978000055 ],
							[ 3.763578881000058, 7.230654550000054 ],
							[ 3.762460272000055, 7.238858745000073 ],
							[ 3.762079516000028, 7.247614729000077 ],
							[ 3.761698703000036, 7.257893920000072 ],
							[ 3.761698710000076, 7.264366284000062 ],
							[ 3.761141055000053, 7.267154644000072 ],
							[ 3.760556446000066, 7.27007713200004 ],
							[ 3.760175933000028, 7.27502621900004 ],
							[ 3.759795195000038, 7.28111784500004 ],
							[ 3.762460201000067, 7.29215860000005 ],
							[ 3.762840751000056, 7.296727157000021 ],
							[ 3.76189314800007, 7.300967577000051 ],
							[ 3.76013972100003, 7.301191233000054 ],
							[ 3.754912850000039, 7.300626246000036 ],
							[ 3.749686033000046, 7.300062316000037 ],
							[ 3.746779654000022, 7.299490849000051 ],
							[ 3.740404358000035, 7.300087923000035 ],
							[ 3.736349583000049, 7.300678622000021 ],
							[ 3.730548362000036, 7.300694553000028 ],
							[ 3.725913517000038, 7.301286696000034 ],
							[ 3.722432824000066, 7.301296841000067 ],
							[ 3.718372106000061, 7.301308217000042 ],
							[ 3.715483189000054, 7.302474450000034 ],
							[ 3.714346630000023, 7.304794336000043 ],
							[ 3.714381963000051, 7.308269402000064 ],
							[ 3.715238097000054, 7.310486273000038 ],
							[ 3.715606908000041, 7.314637599000037 ],
							[ 3.716222547000029, 7.31811140800005 ],
							[ 3.717482587000063, 7.327954672000033 ],
							[ 3.717535718000022, 7.333167963000051 ],
							[ 3.717571024000051, 7.336643302000027 ],
							[ 3.717606094000075, 7.34011835900003 ],
							[ 3.718221945000039, 7.343592163000039 ],
							[ 3.718901828000071, 7.353436931000033 ],
							[ 3.71892549100005, 7.355754021000052 ],
							[ 3.718978376000052, 7.360966745000042 ],
							[ 3.719605416000036, 7.365598782000063 ],
							[ 3.719676057000072, 7.372549449000076 ],
							[ 3.719705374000057, 7.375445378000052 ],
							[ 3.720320933000039, 7.378919169000028 ],
							[ 3.720350253000049, 7.381815375000031 ],
							[ 3.719793806000041, 7.384133710000071 ],
							[ 3.719829120000043, 7.387609040000029 ],
							[ 3.717532349000066, 7.38993229600004 ],
							[ 3.71405745900006, 7.390520940000044 ],
							[ 3.71057079000002, 7.389951563000068 ],
							[ 3.708244805000049, 7.389379130000066 ],
							[ 3.705373734000034, 7.392282958000067 ],
							[ 3.704840399000034, 7.396918350000021 ],
							[ 3.705461754000055, 7.400971285000026 ],
							[ 3.706089062000046, 7.405603360000043 ],
							[ 3.706704616000025, 7.409077170000046 ],
							[ 3.706733964000023, 7.411973080000053 ],
							[ 3.706775229000073, 7.416027532000044 ],
							[ 3.706804499000043, 7.418923736000067 ],
							[ 3.706839768000066, 7.422399064000047 ],
							[ 3.706312668000066, 7.427613303000044 ],
							[ 3.706945864000033, 7.432824492000066 ],
							[ 3.707573172000025, 7.437456558000065 ],
							[ 3.707608445000062, 7.440932160000045 ],
							[ 3.707649495000055, 7.44498688300007 ],
							[ 3.707093001000032, 7.44730521200006 ],
							[ 3.704198331000043, 7.447892244000059 ],
							[ 3.699551349000046, 7.447325811000042 ],
							[ 3.69141836700004, 7.446190371000057 ],
							[ 3.687931782000021, 7.445620531000031 ],
							[ 3.682704672000057, 7.445056507000061 ],
							[ 3.674009130000059, 7.445659552000052 ],
							[ 3.665301555000042, 7.445104975000049 ],
							[ 3.657162406000054, 7.443389349000029 ],
							[ 3.646140313000046, 7.443420367000044 ],
							[ 3.640339388000029, 7.44343716800006 ],
							[ 3.633378070000049, 7.443456120000064 ],
							[ 3.618869538000069, 7.442917315000045 ],
							[ 3.611908208000045, 7.442937286000074 ],
							[ 3.603786733000049, 7.442960129000028 ],
							[ 3.598559862000059, 7.442395204000036 ],
							[ 3.591018676000033, 7.442416136000077 ],
							[ 3.586371687000053, 7.441850265000028 ],
							[ 3.578830441000036, 7.441870648000076 ],
							[ 3.571863207000035, 7.441311287000076 ],
							[ 3.555040158000054, 7.441358010000044 ],
							[ 3.549233246000028, 7.440794796000034 ],
							[ 3.545752558000061, 7.440804434000029 ],
							[ 3.540519717000052, 7.439660959000037 ],
							[ 3.53875586700002, 7.437348763000045 ],
							[ 3.536986103000061, 7.434457725000073 ],
							[ 3.535222300000044, 7.432145529000024 ],
							[ 3.533452515000022, 7.429254454000045 ],
							[ 3.532263024000031, 7.426362142000073 ],
							[ 3.529338845000041, 7.424053244000049 ],
							[ 3.524662493000051, 7.420590921000041 ],
							[ 3.522289280000052, 7.41538421100006 ],
							[ 3.519956806000039, 7.414232277000053 ],
							[ 3.518199181000057, 7.412499407000041 ],
							[ 3.515843145000076, 7.409030906000055 ],
							[ 3.511753075000058, 7.40614602900007 ],
							[ 3.508835085000044, 7.404416687000037 ],
							[ 3.506490937000024, 7.40210638700006 ],
							[ 3.502980972000046, 7.399219645000073 ],
							[ 3.501228791000074, 7.398066389000064 ],
							[ 3.499470679000069, 7.396333696000056 ],
							[ 3.497712809000063, 7.394600721000074 ],
							[ 3.494226191000052, 7.394031009000059 ],
							[ 3.491899928000066, 7.39345830600007 ],
							[ 3.488993390000076, 7.392887618000032 ],
							[ 3.486093069000049, 7.392895785000064 ],
							[ 3.483766547000073, 7.392323027000032 ],
							[ 3.482014690000028, 7.391169127000069 ],
							[ 3.479096441000024, 7.389439522000032 ],
							[ 3.474449628000059, 7.388873631000024 ],
							[ 3.472733042000073, 7.391195352000068 ],
							[ 3.47336604100002, 7.396406705000061 ],
							[ 3.475715896000054, 7.39929577700002 ],
							[ 3.477485659000024, 7.402186865000033 ],
							[ 3.47924374300004, 7.403920219000042 ],
							[ 3.481013333000021, 7.40681126800007 ],
							[ 3.482226640000022, 7.412020583000071 ],
							[ 3.482859587000064, 7.417231929000025 ],
							[ 3.480580530000054, 7.421293396000067 ],
							[ 3.477732629000059, 7.426514268000062 ],
							[ 3.474879515000055, 7.431156275000035 ],
							[ 3.472611872000073, 7.436375673000043 ],
							[ 3.471475128000066, 7.438695375000066 ],
							[ 3.468598138000061, 7.44102050500004 ],
							[ 3.466295509000076, 7.442764191000037 ],
							[ 3.459404926000047, 7.449734592000027 ],
							[ 3.460014320000028, 7.452629132000027 ],
							[ 3.460641362000047, 7.457261083000049 ],
							[ 3.463577287000021, 7.46072851200006 ],
							[ 3.465364677000025, 7.465356744000076 ],
							[ 3.465974288000041, 7.468251571000053 ],
							[ 3.466583995000065, 7.471145526000043 ],
							[ 3.461984132000055, 7.475213151000048 ],
							[ 3.459124846000066, 7.479276176000042 ],
							[ 3.455685362000054, 7.483340307000049 ],
							[ 3.452234286000021, 7.486245573000076 ],
							[ 3.453435633000026, 7.490297275000046 ],
							[ 3.453482648000033, 7.49493063500006 ],
							[ 3.445929542000044, 7.493793450000055 ],
							[ 3.442431207000027, 7.49206553700003 ],
							[ 3.43892697900003, 7.48975845800004 ],
							[ 3.432486982000057, 7.483984548000024 ],
							[ 3.426644830000043, 7.479945706000024 ],
							[ 3.421388378000074, 7.476485119000074 ],
							[ 3.417304020000074, 7.474179754000033 ],
							[ 3.41147378200003, 7.471299525000063 ],
							[ 3.40679740500002, 7.467837941000028 ],
							[ 3.401535249000062, 7.463797653000029 ],
							[ 3.398605365000037, 7.460909707000042 ],
							[ 3.395687116000033, 7.459179744000039 ],
							[ 3.393082837000065, 7.455832136000026 ],
							[ 3.393893709000054, 7.453972002000057 ],
							[ 3.400228050000067, 7.449320710000052 ],
							[ 3.399014734000048, 7.444110821000038 ],
							[ 3.396079059000044, 7.440643716000068 ],
							[ 3.393166516000065, 7.439493138000046 ],
							[ 3.387341971000069, 7.43719304900003 ],
							[ 3.385004087000027, 7.435461404000023 ],
							[ 3.382659899000032, 7.43315117700007 ],
							[ 3.381476193000026, 7.430837771000029 ],
							[ 3.381428976000052, 7.426203797000028 ],
							[ 3.383145814000045, 7.423881945000062 ],
							[ 3.384276630000045, 7.420982796000033 ],
							[ 3.382512788000042, 7.418670683000073 ],
							[ 3.381323093000049, 7.415778137000075 ],
							[ 3.383034008000038, 7.412877148000064 ],
							[ 3.385852313000044, 7.40476041900007 ],
							[ 3.38581683600006, 7.401284731000032 ],
							[ 3.383466973000054, 7.398395074000064 ],
							[ 3.380543032000048, 7.396086102000027 ],
							[ 3.379347317000054, 7.392614407000053 ],
							[ 3.377577533000022, 7.389723405000041 ],
							[ 3.375807781000049, 7.386832135000077 ],
							[ 3.369379524000067, 7.382215896000048 ],
							[ 3.363525388000028, 7.377019403000077 ],
							[ 3.359435359000031, 7.374135060000071 ],
							[ 3.357079732000045, 7.370666088000064 ],
							[ 3.353557812000076, 7.366621001000055 ],
							[ 3.350041879000059, 7.363155315000029 ],
							[ 3.347082395000029, 7.357371787000034 ],
							[ 3.344714902000021, 7.352744710000024 ],
							[ 3.343519473000072, 7.349272314000075 ],
							[ 3.339411529000074, 7.344649742000058 ],
							[ 3.334694180000042, 7.33713289800005 ],
							[ 3.332924582000032, 7.334242241000027 ],
							[ 3.331142907000071, 7.33019243800004 ],
							[ 3.329964902000029, 7.328458278000028 ],
							[ 3.328189366000061, 7.324987882000073 ],
							[ 3.324028480000038, 7.315152251000029 ],
							[ 3.319375973000035, 7.314007202000028 ],
							[ 3.311254486000053, 7.314029752000067 ],
							[ 3.306613726000023, 7.314042564000033 ],
							[ 3.296212890000049, 7.318126301000063 ],
							[ 3.291601460000038, 7.32103550100004 ],
							[ 3.286995904000037, 7.324523508000027 ],
							[ 3.282466890000023, 7.335541191000061 ],
							[ 3.280169966000074, 7.337864305000039 ],
							[ 3.264097470000024, 7.354707831000042 ],
							[ 3.260066534000032, 7.357614972000022 ],
							[ 3.257195214000035, 7.360519029000045 ],
							[ 3.256644527000049, 7.363416700000073 ],
							[ 3.24914431000002, 7.367492242000026 ],
							[ 3.236973767000052, 7.368684675000054 ],
							[ 3.230024319000051, 7.369862448000049 ],
							[ 3.22074273100003, 7.369888220000064 ],
							[ 3.212621210000066, 7.369910588000039 ],
							[ 3.206233980000036, 7.369350012000041 ],
							[ 3.198106759000041, 7.368792917000064 ],
							[ 3.183035868000047, 7.369993652000062 ],
							[ 3.178406493000068, 7.371165397000027 ],
							[ 3.173197279000021, 7.372337769000069 ],
							[ 3.167988329000025, 7.373511200000053 ],
							[ 3.161624697000036, 7.375266450000026 ],
							[ 3.15527297400007, 7.378179903000046 ],
							[ 3.151138546000027, 7.382362425000053 ],
							[ 3.148382411000057, 7.385150485000054 ],
							[ 3.146091213000034, 7.388053028000058 ],
							[ 3.143902570000023, 7.390937708000024 ],
							[ 3.140366550000067, 7.395598772000028 ],
							[ 3.136903755000048, 7.39734650500003 ],
							[ 3.132292264000057, 7.400255136000055 ],
							[ 3.128249164000067, 7.402004216000023 ],
							[ 3.123631979000038, 7.404334169000037 ],
							[ 3.115294470000038, 7.408086395000055 ],
							[ 3.113248827000064, 7.410155885000052 ],
							[ 3.10727978500006, 7.416192909000074 ],
							[ 3.104073046000053, 7.420607143000041 ],
							[ 3.098468670000045, 7.429409127000042 ],
							[ 3.097791907000044, 7.430471969000052 ],
							[ 3.094340808000027, 7.433377302000054 ],
							[ 3.090309367000032, 7.436284578000027 ],
							[ 3.086260329000027, 7.437454628000069 ],
							[ 3.082211250000057, 7.438624355000059 ],
							[ 3.080500574000041, 7.441525112000022 ],
							[ 3.079375741000035, 7.445003639000049 ],
							[ 3.077664618000028, 7.44790467200005 ],
							[ 3.07537960600007, 7.451386507000052 ],
							[ 3.074266669000053, 7.456023333000076 ],
							[ 3.07317684800006, 7.462977033000072 ],
							[ 3.070885906000058, 7.465879969000071 ],
							[ 3.069192628000053, 7.470518129000027 ],
							[ 3.069245835000061, 7.475731344000053 ],
							[ 3.073371179000048, 7.482091007000065 ],
							[ 3.074578280000026, 7.486722030000067 ],
							[ 3.076336403000028, 7.488454794000063 ],
							[ 3.076946018000058, 7.491348884000047 ],
							[ 3.075276119000023, 7.498303945000032 ],
							[ 3.073577171000068, 7.502363273000071 ],
							[ 3.068989285000043, 7.507589412000073 ],
							[ 3.067307945000039, 7.513386149000041 ],
							[ 3.06447197600005, 7.519765894000045 ],
							[ 3.06045843000004, 7.524411143000066 ],
							[ 3.058161261000066, 7.526734274000034 ],
							[ 3.054112677000035, 7.527903991000073 ],
							[ 3.050655598000048, 7.530230626000048 ],
							[ 3.044315569000048, 7.534303321000039 ],
							[ 3.043202412000028, 7.538940023000066 ],
							[ 3.036891703000038, 7.545908062000024 ],
							[ 3.033452287000046, 7.549972551000053 ],
							[ 3.028248787000052, 7.551724327000045 ],
							[ 3.025914657000044, 7.552198141000076 ],
							[ 3.025354410000034, 7.552311966000048 ],
							[ 3.020719276000023, 7.552903743000059 ],
							[ 3.014349923000054, 7.554080454000029 ],
							[ 3.010881164000068, 7.555248131000042 ],
							[ 3.00682617800004, 7.555838454000025 ],
							[ 3.003990405000025, 7.562218580000035 ],
							[ 3.000539299000025, 7.565124454000056 ],
							[ 2.997673978000023, 7.568607367000027 ],
							[ 2.99539470600007, 7.572668410000063 ],
							[ 2.990789190000044, 7.576156998000044 ],
							[ 2.986763722000035, 7.579643158000067 ],
							[ 2.982750163000048, 7.584288700000059 ],
							[ 2.980459238000037, 7.587190526000029 ],
							[ 2.978760002000058, 7.59124997400005 ],
							[ 2.977652546000058, 7.596466478000025 ],
							[ 2.97653389200002, 7.600524416000042 ],
							[ 2.975408819000052, 7.604002898000033 ],
							[ 2.97369790700003, 7.606903454000076 ],
							[ 2.972573047000026, 7.610381930000074 ],
							[ 2.972631903000035, 7.61617430900003 ],
							[ 2.974993458000029, 7.620222630000058 ],
							[ 2.977343345000065, 7.623112088000028 ],
							[ 2.981427692000068, 7.625417273000039 ],
							[ 2.984345905000055, 7.62714685800006 ],
							[ 2.987269896000043, 7.629455564000068 ],
							[ 2.989022018000071, 7.630609563000064 ],
							[ 2.990797534000023, 7.634079894000024 ],
							[ 2.992622380000057, 7.637996668000028 ],
							[ 2.993790379000075, 7.640318430000036 ],
							[ 2.997302525000066, 7.649600575000022 ],
							[ 2.997890226000038, 7.651919338000027 ],
							[ 2.99674796100004, 7.657706752000024 ],
							[ 2.993276597000033, 7.661167257000045 ],
							[ 2.995611188000055, 7.665231119000055 ],
							[ 3.000262046000046, 7.667567383000062 ],
							[ 3.00202181100002, 7.673367142000075 ],
							[ 2.999707442000044, 7.675673815000039 ],
							[ 2.99622848100006, 7.676818308000065 ],
							[ 2.993326449000051, 7.676805903000059 ],
							[ 2.989851232000035, 7.679108236000047 ],
							[ 2.992769950000024, 7.684332987000062 ],
							[ 2.994516832000045, 7.686078082000051 ],
							[ 2.998001110000075, 7.686671687000057 ],
							[ 3.002648377000071, 7.687849461000042 ],
							[ 3.001498472000037, 7.691319973000077 ],
							[ 2.999180552000041, 7.692468768000026 ],
							[ 2.996879110000066, 7.698830692000058 ],
							[ 3.001529926000046, 7.701166649000072 ],
							[ 3.008489363000024, 7.699457726000048 ],
							[ 3.011975531000076, 7.700630570000044 ],
							[ 3.01198314100003, 7.702947753000046 ],
							[ 3.010835185000076, 7.706997369000021 ],
							[ 3.007361889000038, 7.709878371000059 ],
							[ 3.006213931000048, 7.713928270000054 ],
							[ 3.006803754000032, 7.716826942000068 ],
							[ 3.007972012000039, 7.719148075000021 ],
							[ 3.010301136000066, 7.721474558000068 ],
							[ 3.013796312000068, 7.725543630000061 ],
							[ 3.014970282000036, 7.72960284100003 ],
							[ 3.016153121000059, 7.736558571000046 ],
							[ 3.017323249000071, 7.739459043000068 ],
							[ 3.019071802000042, 7.741783085000066 ],
							[ 3.020240311000066, 7.744105307000041 ],
							[ 3.020829909000042, 7.747003650000067 ],
							[ 3.022000098000035, 7.749904661000073 ],
							[ 3.023750573000029, 7.752807477000033 ],
							[ 3.025500995000073, 7.755710579000038 ],
							[ 3.026090613000065, 7.758609209000042 ],
							[ 3.025523204000024, 7.762661521000041 ],
							[ 3.020312061000027, 7.766693955000051 ],
							[ 3.020911214000023, 7.77248856500006 ],
							[ 3.022084945000074, 7.776547995000044 ],
							[ 3.020354743000041, 7.780015909000042 ],
							[ 3.019783744000051, 7.782908933000044 ],
							[ 3.019210591000046, 7.785223987000052 ],
							[ 3.019225380000023, 7.789857475000076 ],
							[ 3.022123816000033, 7.788710939000055 ],
							[ 3.025025846000062, 7.788723102000063 ],
							[ 3.025958563000074, 7.791801102000022 ],
							[ 3.023306872000035, 7.795666625000024 ],
							[ 3.021580448000066, 7.800292413000022 ],
							[ 3.019850013000053, 7.803760578000038 ],
							[ 3.01812196700007, 7.807807835000062 ],
							[ 3.015814780000028, 7.812431772000025 ],
							[ 3.014082917000053, 7.815320564000046 ],
							[ 3.012929456000052, 7.817632671000069 ],
							[ 3.011776222000037, 7.819944761000045 ],
							[ 3.008892297000045, 7.825724696000066 ],
							[ 3.006583688000035, 7.829769211000041 ],
							[ 3.004849695000075, 7.832078881000029 ],
							[ 2.999058253000044, 7.836109200000067 ],
							[ 2.997326350000037, 7.838997913000071 ],
							[ 2.993282089000047, 7.844773179000072 ],
							[ 2.992710589000069, 7.847666150000066 ],
							[ 2.991559038000048, 7.850557821000052 ],
							[ 2.98983266700003, 7.855184016000067 ],
							[ 2.985782862000065, 7.859221405000028 ],
							[ 2.982876775000022, 7.858051343000056 ],
							[ 2.981708529000059, 7.855729020000069 ],
							[ 2.980540541000039, 7.853407828000059 ],
							[ 2.979368448000059, 7.849927902000047 ],
							[ 2.978780491000066, 7.847608 ],
							[ 2.978187344000048, 7.843551732000037 ],
							[ 2.978179908000072, 7.841234562000068 ],
							[ 2.978168716000027, 7.837759520000077 ],
							[ 2.977579110000022, 7.834860821000063 ],
							[ 2.976989486000036, 7.831962695000072 ],
							[ 2.975817681000024, 7.828482757000074 ],
							[ 2.975220443000069, 7.823267451000049 ],
							[ 2.97404838500006, 7.819787502000054 ],
							[ 2.972876806000045, 7.816306981000025 ],
							[ 2.971124177000036, 7.812824849000037 ],
							[ 2.968205456000021, 7.807599414000038 ],
							[ 2.965284843000063, 7.801795432000063 ],
							[ 2.961781984000027, 7.795409587000051 ],
							[ 2.957120180000061, 7.78959810300006 ],
							[ 2.955369706000056, 7.786694968000063 ],
							[ 2.952445496000053, 7.779732701000057 ],
							[ 2.95011282300004, 7.776247410000053 ],
							[ 2.947201500000062, 7.773339247000024 ],
							[ 2.943709383000055, 7.770428543000037 ],
							[ 2.940800194000076, 7.768099687000074 ],
							[ 2.93730997900002, 7.765768573000059 ],
							[ 2.934983008000074, 7.764020990000063 ],
							[ 2.932071690000043, 7.761113277000049 ],
							[ 2.929162017000067, 7.75878431700005 ],
							[ 2.926832921000027, 7.75645787600007 ],
							[ 2.924507623000068, 7.755289578000031 ],
							[ 2.921019555000044, 7.753537109000035 ],
							[ 2.918692359000033, 7.75178997200004 ],
							[ 2.914625643000022, 7.750614559000041 ],
							[ 2.911731011000029, 7.75291954000005 ],
							[ 2.91174410900004, 7.756974262000028 ],
							[ 2.912331810000069, 7.759293156000069 ],
							[ 2.912919761000069, 7.761612035000041 ],
							[ 2.913509390000058, 7.764510580000035 ],
							[ 2.914100895000047, 7.767988185000036 ],
							[ 2.91469240300006, 7.771466362000069 ],
							[ 2.915285595000057, 7.775523058000033 ],
							[ 2.915294894000056, 7.778419043000042 ],
							[ 2.915888063000068, 7.782476013000064 ],
							[ 2.915317058000028, 7.785369462000062 ],
							[ 2.91243696500004, 7.792307957000048 ],
							[ 2.911287309000045, 7.795777915000031 ],
							[ 2.908972983000069, 7.79808515700006 ],
							[ 2.904337162000047, 7.800382697000032 ],
							[ 2.90027595600003, 7.800945223000042 ],
							[ 2.897379392000062, 7.802670457000033 ],
							[ 2.894482847000063, 7.804396244000031 ],
							[ 2.890425450000066, 7.80611654300003 ],
							[ 2.886950493000029, 7.808418756000037 ],
							[ 2.884053706000032, 7.810144439000055 ],
							[ 2.882317786000044, 7.811874742000043 ],
							[ 2.880011087000071, 7.816498714000033 ],
							[ 2.879439839000042, 7.81939208600005 ],
							[ 2.879450792000057, 7.822867423000048 ],
							[ 2.88004445200005, 7.826924457000075 ],
							[ 2.881217958000036, 7.830983532000062 ],
							[ 2.881232976000035, 7.83561760300006 ],
							[ 2.881824499000061, 7.839094974000034 ],
							[ 2.882414094000069, 7.841993838000064 ],
							[ 2.883007530000043, 7.846050284000057 ],
							[ 2.883020397000053, 7.850104435000048 ],
							[ 2.882456780000041, 7.855315268000027 ],
							[ 2.881308803000024, 7.859364501000073 ],
							[ 2.881319771000051, 7.862840110000036 ],
							[ 2.881909374000031, 7.86573839600004 ],
							[ 2.883085019000021, 7.870377093000059 ],
							[ 2.883676536000053, 7.873854750000021 ],
							[ 2.884268051000049, 7.877332109000065 ],
							[ 2.885441779000075, 7.881391439000026 ],
							[ 2.88719605600005, 7.88545332700005 ],
							[ 2.888369825000041, 7.889512645000025 ],
							[ 2.889541869000027, 7.892992589000073 ],
							[ 2.890713696000034, 7.896472524000046 ],
							[ 2.891305211000031, 7.899950421000028 ],
							[ 2.894806389000053, 7.905756985000039 ],
							[ 2.896553039000025, 7.907501681000042 ],
							[ 2.902950760000067, 7.911582929000076 ],
							[ 2.907021050000026, 7.913916597000025 ],
							[ 2.910516267000048, 7.917985474000034 ],
							[ 2.913422339000022, 7.919156101000056 ],
							[ 2.915747643000032, 7.920324424000057 ],
							[ 2.918653258000063, 7.921494446000054 ],
							[ 2.919821744000046, 7.92381669100007 ],
							[ 2.917507167000053, 7.926123684000061 ],
							[ 2.912865885000031, 7.926682992000053 ],
							[ 2.908222438000053, 7.926663995000069 ],
							[ 2.905320160000031, 7.926651498000069 ],
							[ 2.901837821000072, 7.926637244000062 ],
							[ 2.899516350000056, 7.926627558000064 ],
							[ 2.896612168000047, 7.926036471000032 ],
							[ 2.893127679000031, 7.925442221000026 ],
							[ 2.889643424000042, 7.924849054000049 ],
							[ 2.886160846000053, 7.924834388000022 ],
							[ 2.883839128000034, 7.924824580000063 ],
							[ 2.878615394000064, 7.924802905000035 ],
							[ 2.872236499000053, 7.926513739000029 ],
							[ 2.869334220000042, 7.926501235000046 ],
							[ 2.865853552000033, 7.927066305000039 ],
							[ 2.862374554000041, 7.928210120000074 ],
							[ 2.859474424000041, 7.92877719300003 ],
							[ 2.853672029000052, 7.929332173000034 ],
							[ 2.850770002000047, 7.929319804000045 ],
							[ 2.846128470000053, 7.929880232000073 ],
							[ 2.84206748400004, 7.930441862000066 ],
							[ 2.838586806000023, 7.931006953000065 ],
							[ 2.835107811000057, 7.932150808000074 ],
							[ 2.831629030000045, 7.933294911000075 ],
							[ 2.827567817000045, 7.933856379000076 ],
							[ 2.822933674000069, 7.936733118000063 ],
							[ 2.821199897000042, 7.939043644000037 ],
							[ 2.819471590000035, 7.943089984000039 ],
							[ 2.817737590000036, 7.945399621000035 ],
							[ 2.813107248000051, 7.949434707000023 ],
							[ 2.81020879700003, 7.950581600000021 ],
							[ 2.807890655000051, 7.951730318000045 ],
							[ 2.804409987000042, 7.952294812000048 ],
							[ 2.800349236000045, 7.952856546000021 ],
							[ 2.79686474500005, 7.952263368000047 ],
							[ 2.793960812000023, 7.951672032000033 ],
							[ 2.79163717800003, 7.951082566000025 ],
							[ 2.78466654500005, 7.949316083000042 ],
							[ 2.781178715000067, 7.947564014000022 ],
							[ 2.77710653400004, 7.944650999000032 ],
							[ 2.775932553000075, 7.940591888000029 ],
							[ 2.774176372000056, 7.935950643000069 ],
							[ 2.77300643500007, 7.933050258000037 ],
							[ 2.771252150000066, 7.928988372000049 ],
							[ 2.770655160000047, 7.923772718000066 ],
							[ 2.770063650000054, 7.920295149000026 ],
							[ 2.767714260000048, 7.91159779000003 ],
							[ 2.765381579000064, 7.908112463000066 ],
							[ 2.76247025300006, 7.90520482900007 ],
							[ 2.760142802000075, 7.903457273000072 ],
							[ 2.756649262000053, 7.899967696000033 ],
							[ 2.753159291000031, 7.897636279000039 ],
							[ 2.750253676000057, 7.896465788000057 ],
							[ 2.746767519000059, 7.895292749000021 ],
							[ 2.742697002000057, 7.892958983000028 ],
							[ 2.739208941000072, 7.891206771000043 ],
							[ 2.733389860000045, 7.886548653000034 ],
							[ 2.728735441000026, 7.883054266000045 ],
							[ 2.725826027000039, 7.88072541400004 ],
							[ 2.721179009000025, 7.879548142000033 ],
							[ 2.718275071000051, 7.878956194000068 ],
							[ 2.713631622000037, 7.878937285000063 ],
							[ 2.706076856000038, 7.876009350000061 ],
							[ 2.700265417000026, 7.873668732000056 ],
							[ 2.69561267000006, 7.870752716000027 ],
							[ 2.693283563000023, 7.868426219000071 ],
							[ 2.691155429000048, 7.867810344000077 ],
							[ 2.683916337000028, 7.867810347000045 ],
							[ 2.674867634000066, 7.880478731000039 ],
							[ 2.669438366000065, 7.886812803000055 ],
							[ 2.66853356300004, 7.890432359000044 ],
							[ 2.680296892000058, 7.898576272000071 ],
							[ 2.689345610000032, 7.918483207000065 ],
							[ 2.693870076000053, 7.926627219000068 ],
							[ 2.69748949600006, 7.940200267000023 ],
							[ 2.699299336000024, 7.950153783000076 ],
							[ 2.702918763000071, 7.962821855000072 ],
							[ 2.70744299200004, 7.98001488500006 ],
							[ 2.706538203000036, 7.992682861000048 ],
							[ 2.714682114000027, 8.027973066000072 ],
							[ 2.720111370000041, 8.045166072000029 ],
							[ 2.723730812000042, 8.057833575000075 ],
							[ 2.729160076000028, 8.06507291500003 ],
							[ 2.729160076000028, 8.072311445000025 ],
							[ 2.722826003000023, 8.085884931000066 ],
							[ 2.73096991400007, 8.10217278500005 ],
							[ 2.731874710000056, 8.116650568000068 ],
							[ 2.731874699000059, 8.130223287000035 ],
							[ 2.731874700000049, 8.137462663000065 ],
							[ 2.740018614000064, 8.150131277000071 ],
							[ 2.749067311000033, 8.165513907000047 ],
							[ 2.749067307000075, 8.179086582000025 ],
							[ 2.749972111000034, 8.196279453000045 ],
							[ 2.751486538000051, 8.206275822000066 ],
							[ 2.753182167000034, 8.210677285000031 ],
							[ 2.74552630900007, 8.21913904400003 ],
							[ 2.739079244000038, 8.225586793000048 ],
							[ 2.733035097000027, 8.237272192000034 ],
							[ 2.726990940000064, 8.245733316000042 ],
							[ 2.721349724000049, 8.251375250000024 ],
							[ 2.714902638000069, 8.259837229000027 ],
							[ 2.712485076000064, 8.272328303000052 ],
							[ 2.709261424000033, 8.280386877000069 ],
							[ 2.701202641000066, 8.300534199000026 ],
							[ 2.697576038000022, 8.312219683000023 ],
							[ 2.695561415000043, 8.321487454000021 ],
							[ 2.695964332000074, 8.331560212000056 ],
							[ 2.695964346000039, 8.345260543000052 ],
							[ 2.697978977000048, 8.356140003000064 ],
							[ 2.697978980000073, 8.35855762500006 ],
							[ 2.709261421000065, 8.369034661000057 ],
							[ 2.712485066000056, 8.381122723000033 ],
							[ 2.71409677500003, 8.389584639000077 ],
							[ 2.711276053000063, 8.409328373000051 ],
							[ 2.706843855000045, 8.41980561400004 ],
							[ 2.708858493000037, 8.425848905000066 ],
							[ 2.715708500000062, 8.431893374000026 ],
							[ 2.720543858000042, 8.431087409000042 ],
							[ 2.728602641000066, 8.431893439000021 ],
							[ 2.733840938000071, 8.436326140000062 ],
							[ 2.741093880000051, 8.445593770000073 ],
							[ 2.742705822000062, 8.452040620000048 ],
							[ 2.743108749000044, 8.459696760000043 ],
							[ 2.74472045400006, 8.464934354000036 ],
							[ 2.747540953000055, 8.465741043000037 ],
							[ 2.747755762000054, 8.469314709000059 ],
							[ 2.756561758000032, 8.482347387000061 ],
							[ 2.753169769000067, 8.485852228000056 ],
							[ 2.750030279000043, 8.489811790000033 ],
							[ 2.751430038000024, 8.494710882000049 ],
							[ 2.761693713000057, 8.505208111000059 ],
							[ 2.76099395500006, 8.508473515000048 ],
							[ 2.761927136000054, 8.522235831000046 ],
							[ 2.757781033000072, 8.525817812000071 ],
							[ 2.750095612000052, 8.533502574000067 ],
							[ 2.750030279000043, 8.537865556000042 ],
							[ 2.758129842000073, 8.561151632000076 ],
							[ 2.761227125000062, 8.570056076000071 ],
							[ 2.776226281000049, 8.590375992000077 ],
							[ 2.802357206000067, 8.610358252000026 ],
							[ 2.840784788000065, 8.610358374000043 ],
							[ 2.888435125000058, 8.608820868000066 ],
							[ 2.920714386000043, 8.611895650000065 ],
							[ 2.942233827000052, 8.638026171000035 ],
							[ 3.014477965000026, 8.710269922000066 ],
							[ 3.05136847600005, 8.72871572400004 ],
							[ 3.103630093000049, 8.75023447500007 ],
							[ 3.131298057000038, 8.777902844000039 ],
							[ 3.16357731100004, 8.808645402000025 ],
							[ 3.183559631000037, 8.81171884400004 ],
							[ 3.197393671000043, 8.796348468000076 ],
							[ 3.237358302000075, 8.782514720000052 ],
							[ 3.281934503000059, 8.804033350000054 ],
							[ 3.338807342000052, 8.825553077000052 ],
							[ 3.387994755000022, 8.842461796000066 ],
							[ 3.416131462000067, 8.860165662000043 ],
							[ 3.421287402000075, 8.86341090600007 ],
							[ 3.524797205000027, 8.928539069000067 ],
							[ 3.603774280000039, 8.960129976000076 ],
							[ 3.655451303000063, 8.98080051900007 ],
							[ 3.682179721000068, 8.994165201000044 ],
							[ 3.720009789000073, 9.013080864000074 ],
							[ 3.743066318000047, 9.046897127000022 ],
							[ 3.752289080000025, 9.086861817000056 ],
							[ 3.753826168000046, 9.120677777000026 ],
							[ 3.770734316000073, 9.146808348000036 ],
							[ 3.795327883000027, 9.165253826000026 ],
							[ 3.810251222000034, 9.173462019000056 ],
							[ 3.826070085000026, 9.182162529000038 ],
							[ 3.86296051000005, 9.188310909000052 ],
							[ 3.921370700000068, 9.172939180000071 ],
							[ 3.944427252000025, 9.148346189000051 ],
							[ 3.972095313000068, 9.077638608000029 ],
							[ 3.990540481000039, 9.048433267000064 ],
							[ 4.012060235000035, 9.034599247000074 ],
							[ 4.072007238000026, 9.01461714900006 ],
							[ 4.133491503000073, 8.994634352000048 ],
							[ 4.21046018800007, 8.970032953000043 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Plateau',
				admin1Pcod: 'NG032',
				admin1RefN: 'Plateau',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 8.575527,
				Shape_Area: 2.180996
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 8.793389218000073, 10.384650132000047 ],
							[ 8.799246585000049, 10.382980243000077 ],
							[ 8.805015539000067, 10.383230268000034 ],
							[ 8.808949470000073, 10.381480110000041 ],
							[ 8.810783336000043, 10.383489793000024 ],
							[ 8.816470080000045, 10.385966413000062 ],
							[ 8.82039844600007, 10.383919494000054 ],
							[ 8.823521653000057, 10.383281917000033 ],
							[ 8.826342805000024, 10.382704644000057 ],
							[ 8.828425361000029, 10.379380315000049 ],
							[ 8.850364959000046, 10.370559850000063 ],
							[ 8.858535705000065, 10.365847748000022 ],
							[ 8.870192706000068, 10.35763453900006 ],
							[ 8.887109840000051, 10.357919781000021 ],
							[ 8.892881330000023, 10.358066713000028 ],
							[ 8.898693359000049, 10.358214141000076 ],
							[ 8.903918332000046, 10.356951519000063 ],
							[ 8.907376296000052, 10.356115557000066 ],
							[ 8.912286022000046, 10.355179907000036 ],
							[ 8.918143506000035, 10.353509838000036 ],
							[ 8.925834709000071, 10.353850496000064 ],
							[ 8.929770620000056, 10.352089997000064 ],
							[ 8.935715652000056, 10.348500203000071 ],
							[ 8.937905292000039, 10.342800379000039 ],
							[ 8.942196740000043, 10.333339915000067 ],
							[ 8.946309868000071, 10.327730169000063 ],
							[ 8.948677864000047, 10.318180036000058 ],
							[ 8.952791281000032, 10.312580396000044 ],
							[ 8.954892287000064, 10.308810082000036 ],
							[ 8.953592049000065, 10.295240253000031 ],
							[ 8.953860483000028, 10.289460399000063 ],
							[ 8.944691530000057, 10.279400120000048 ],
							[ 8.939189946000056, 10.273359996000067 ],
							[ 8.935611886000061, 10.267410541000061 ],
							[ 8.935790066000038, 10.263560312000038 ],
							[ 8.936057040000037, 10.257779885000048 ],
							[ 8.93641395700007, 10.250069831000076 ],
							[ 8.934668310000063, 10.246129999000061 ],
							[ 8.931246593000026, 10.245111706000046 ],
							[ 8.931448054000043, 10.242140711000047 ],
							[ 8.929702091000024, 10.228540146000057 ],
							[ 8.929880313000069, 10.224690485000053 ],
							[ 8.930147352000063, 10.218910048000055 ],
							[ 8.930326709000042, 10.215049646000068 ],
							[ 8.938284751000026, 10.209610164000026 ],
							[ 8.942219016000024, 10.20786027500003 ],
							[ 8.946154385000057, 10.206100733000028 ],
							[ 8.951066820000051, 10.203115675000049 ],
							[ 8.960058885000024, 10.197070116000077 ],
							[ 8.965916456000059, 10.195400404000054 ],
							[ 8.967928615000062, 10.19355964600004 ],
							[ 8.973875815000042, 10.189959595000062 ],
							[ 8.976065617000074, 10.184269655000037 ],
							[ 8.978166798000075, 10.180499753000049 ],
							[ 8.976689575000023, 10.170780171000047 ],
							[ 8.977134678000027, 10.16114998200004 ],
							[ 8.973557664000055, 10.155199723000067 ],
							[ 8.971811510000066, 10.151260600000057 ],
							[ 8.971989829000051, 10.147400162000054 ],
							[ 8.972168123000074, 10.143549887000063 ],
							[ 8.974357672000053, 10.137860497000077 ],
							[ 8.972701866000023, 10.131990596000037 ],
							[ 8.972880165000049, 10.128140318000021 ],
							[ 8.971225006000054, 10.122269834000065 ],
							[ 8.96983622700003, 10.11063013100005 ],
							[ 8.968092029000047, 10.106690424000021 ],
							[ 8.968270365000024, 10.102840142000048 ],
							[ 8.966703428000073, 10.095050142000048 ],
							[ 8.963124956000058, 10.089099740000051 ],
							[ 8.965405216000022, 10.081480178000049 ],
							[ 8.963570881000066, 10.079459929000052 ],
							[ 8.962093319000076, 10.069749838000064 ],
							[ 8.96061585700005, 10.060030125000026 ],
							[ 8.960972619000074, 10.052319381000075 ],
							[ 8.95940605800007, 10.044529883000052 ],
							[ 8.957838985000024, 10.036740373000043 ],
							[ 8.956396882000035, 10.033757267000055 ],
							[ 8.954439100000059, 10.026929980000034 ],
							[ 8.956540173000064, 10.023170349000054 ],
							[ 8.966421342000046, 10.017810093000037 ],
							[ 8.978225939000026, 10.012550268000041 ],
							[ 8.984083309000027, 10.010880250000071 ],
							[ 8.986306452000065, 10.010419813000055 ],
							[ 8.991864312000075, 10.00928986100007 ],
							[ 8.997633159000031, 10.009549869000068 ],
							[ 9.00523448000007, 10.011819681000077 ],
							[ 9.014759054000024, 10.01416951300007 ],
							[ 9.020439124000063, 10.016349800000057 ],
							[ 9.024284349000027, 10.016519654000035 ],
							[ 9.030141552000032, 10.014849481000056 ],
							[ 9.03398790600005, 10.015020345000039 ],
							[ 9.041679464000026, 10.015359731000046 ],
							[ 9.047448423000048, 10.015620092000063 ],
							[ 9.055050182000059, 10.017890008000052 ],
							[ 9.058896, 10.018059502000028 ],
							[ 9.064575258000048, 10.020239685000035 ],
							[ 9.068331929000067, 10.022339893000037 ],
							[ 9.074099297000032, 10.022589672000038 ],
							[ 9.085548432000053, 10.025029959000051 ],
							[ 9.089393761000053, 10.025199692000058 ],
							[ 9.091227381000067, 10.027219898000055 ],
							[ 9.102586621000057, 10.031579736000026 ],
							[ 9.110187340000039, 10.033849646000021 ],
							[ 9.115867714000046, 10.036029613000039 ],
							[ 9.125455616000067, 10.039408921000074 ],
							[ 9.129121887000053, 10.039181636000023 ],
							[ 9.136665570000048, 10.04209067000005 ],
							[ 9.142361665000067, 10.042876203000048 ],
							[ 9.14460824300005, 10.042514827000048 ],
							[ 9.149939765000056, 10.041655513000023 ],
							[ 9.15405867100003, 10.043520198000067 ],
							[ 9.159826458000055, 10.043780508000054 ],
							[ 9.163582761000043, 10.045869580000044 ],
							[ 9.17127395600005, 10.046210214000041 ],
							[ 9.177041798000062, 10.046469701000035 ],
							[ 9.190502019000064, 10.047069313000065 ],
							[ 9.196271025000044, 10.047320573000036 ],
							[ 9.202039463000062, 10.047579651000035 ],
							[ 9.211742555000058, 10.046079642000052 ],
							[ 9.217510986000036, 10.046340182000051 ],
							[ 9.221446130000061, 10.04458050900007 ],
							[ 9.239286213000071, 10.033789769000066 ],
							[ 9.247065459000055, 10.032210399000064 ],
							[ 9.251268367000023, 10.024669690000053 ],
							[ 9.253369166000027, 10.02089987200003 ],
							[ 9.255470521000063, 10.01713004100003 ],
							[ 9.259761802000071, 10.007670218000044 ],
							[ 9.262039882000067, 10.000049345000036 ],
							[ 9.262218449000045, 9.99619889500002 ],
							[ 9.264319569000065, 9.992430712000044 ],
							[ 9.264497544000051, 9.988578 ],
							[ 9.264854624000066, 9.980872020000049 ],
							[ 9.265032588000054, 9.977018176000058 ],
							[ 9.26713355000004, 9.973251102000063 ],
							[ 9.267312066000045, 9.969397830000048 ],
							[ 9.271513250000055, 9.961862491000034 ],
							[ 9.275536421000027, 9.958180399000071 ],
							[ 9.277638305000039, 9.954412705000038 ],
							[ 9.279650407000076, 9.952570778000052 ],
							[ 9.27982877900007, 9.948718050000025 ],
							[ 9.282107189000044, 9.941097025000033 ],
							[ 9.284476371000039, 9.931550179000055 ],
							[ 9.28657744700007, 9.927781854000045 ],
							[ 9.286754594000058, 9.923929118000046 ],
							[ 9.287022568000054, 9.918148890000055 ],
							[ 9.289213238000059, 9.912455297000065 ],
							[ 9.291314154000077, 9.908687515000054 ],
							[ 9.291501889000074, 9.904622292000056 ],
							[ 9.291556814000046, 9.903431671000021 ],
							[ 9.291670006000061, 9.900980897000068 ],
							[ 9.290192386000058, 9.891262975000075 ],
							[ 9.28853717800007, 9.885397770000054 ],
							[ 9.286881445000063, 9.879532574000052 ],
							[ 9.279546912000058, 9.871485465000035 ],
							[ 9.277713035000033, 9.869473508000056 ],
							[ 9.27605698900004, 9.863608259000046 ],
							[ 9.274312935000069, 9.859669919000055 ],
							[ 9.274490178000065, 9.855816617000073 ],
							[ 9.272834808000027, 9.849951904000022 ],
							[ 9.271268685000052, 9.842160242000034 ],
							[ 9.267690591000076, 9.83621045600006 ],
							[ 9.266034840000032, 9.830345132000048 ],
							[ 9.260800194000069, 9.818529385000033 ],
							[ 9.255299758000035, 9.812493241000027 ],
							[ 9.253554475000044, 9.808555366000064 ],
							[ 9.244208425000068, 9.802349325000023 ],
							[ 9.236695366000049, 9.798154058000023 ],
							[ 9.234860397000034, 9.796142473000032 ],
							[ 9.229270187000054, 9.792033426000046 ],
							[ 9.223591807000048, 9.789850097000055 ],
							[ 9.219923839000046, 9.785826765000024 ],
							[ 9.212410823000027, 9.78163270400006 ],
							[ 9.206731568000066, 9.779449690000035 ],
							[ 9.201051822000068, 9.777267699000049 ],
							[ 9.195372703000032, 9.775083380000069 ],
							[ 9.187948114000051, 9.768963020000058 ],
							[ 9.18410317300004, 9.768792053000027 ],
							[ 9.174352816000066, 9.764676867000048 ],
							[ 9.168987432000051, 9.762329236000028 ],
							[ 9.165230608000059, 9.760231723000061 ],
							[ 9.159550815000046, 9.758049091000032 ],
							[ 9.155795229000034, 9.755952037000043 ],
							[ 9.152127197000027, 9.751928581000072 ],
							[ 9.150382744000069, 9.747989649000033 ],
							[ 9.152183468000032, 9.744761231000041 ],
							[ 9.160442239000076, 9.738783830000045 ],
							[ 9.168400650000024, 9.733346033000032 ],
							[ 9.173604121000039, 9.730526759000043 ],
							[ 9.178281644000037, 9.727993017000074 ],
							[ 9.182306026000049, 9.724310759000048 ],
							[ 9.186418802000048, 9.718701524000039 ],
							[ 9.19428851300006, 9.715190077000045 ],
							[ 9.198135408000041, 9.708473320000053 ],
							[ 9.202514502000042, 9.703972989000022 ],
							[ 9.210764079000057, 9.700880834000031 ],
							[ 9.211678478000067, 9.700570091000031 ],
							[ 9.217255617000035, 9.695033116000047 ],
							[ 9.226746631000026, 9.690320386000053 ],
							[ 9.233387682000057, 9.683725826000057 ],
							[ 9.24003218200005, 9.681838764000076 ],
							[ 9.243833188000053, 9.687485279000043 ],
							[ 9.248836332000053, 9.700138944000059 ],
							[ 9.250763842000026, 9.702253266000071 ],
							[ 9.25818754900007, 9.70837520200007 ],
							[ 9.261855159000049, 9.712398747000066 ],
							[ 9.26753427500006, 9.714580367000053 ],
							[ 9.271290658000055, 9.716678549000051 ],
							[ 9.27330317600007, 9.714837759000034 ],
							[ 9.279338905000031, 9.709314214000074 ],
							[ 9.283629363000045, 9.699852255000053 ],
							[ 9.287832227000024, 9.692317181000021 ],
							[ 9.290200195000068, 9.682769028000052 ],
							[ 9.290555868000069, 9.675062884000056 ],
							[ 9.294490636000035, 9.673307484000077 ],
							[ 9.291179824000039, 9.661576973000024 ],
							[ 9.29135791300007, 9.657724173000076 ],
							[ 9.287779588000035, 9.651773371000047 ],
							[ 9.284290459000033, 9.643897289000051 ],
							[ 9.284468630000049, 9.64004336000005 ],
							[ 9.284580069000071, 9.636112064000031 ],
							[ 9.287669939000068, 9.633451584000056 ],
							[ 9.290562424000029, 9.630547670000055 ],
							[ 9.295415987000069, 9.625675146000049 ],
							[ 9.297289701000068, 9.624849204000043 ],
							[ 9.304588549000073, 9.621631383000022 ],
							[ 9.314202321000039, 9.622059 ],
							[ 9.320148707000044, 9.618462306000026 ],
							[ 9.320505380000043, 9.610756084000059 ],
							[ 9.322694811000076, 9.605061633000048 ],
							[ 9.318746386000043, 9.587632553000049 ],
							[ 9.319690469000022, 9.581983253000033 ],
							[ 9.32211559600006, 9.580175173000043 ],
							[ 9.322635912000067, 9.579787653000039 ],
							[ 9.329710800000043, 9.578346110000041 ],
							[ 9.335479876000022, 9.578601976000073 ],
							[ 9.339727614000026, 9.576032654000073 ],
							[ 9.345272301000023, 9.575176519000024 ],
							[ 9.351218190000054, 9.571578739000074 ],
							[ 9.356342239000071, 9.572319725000057 ],
							[ 9.358605134000072, 9.570300214000042 ],
							[ 9.360889389000022, 9.568031476000044 ],
							[ 9.362823647000027, 9.566110582000022 ],
							[ 9.365402241000027, 9.563154403000055 ],
							[ 9.369821337000076, 9.559732438000026 ],
							[ 9.388731841000038, 9.552008779000062 ],
							[ 9.394767668000043, 9.546486105000042 ],
							[ 9.397990284000059, 9.538000863000036 ],
							[ 9.397403522000047, 9.531158447000053 ],
							[ 9.399593545000073, 9.52546406700003 ],
							[ 9.401784059000022, 9.519769672000052 ],
							[ 9.402051064000034, 9.513990353000054 ],
							[ 9.404241471000034, 9.508295958000076 ],
							[ 9.40826510900007, 9.504614002000039 ],
							[ 9.416045392000058, 9.503028755000059 ],
							[ 9.419979969000053, 9.501273127000047 ],
							[ 9.422788567000055, 9.500019970000039 ],
							[ 9.42377302400007, 9.49932042000006 ],
							[ 9.426839072000064, 9.497142466000071 ],
							[ 9.436319937000064, 9.490540883000051 ],
							[ 9.445802787000048, 9.485822470000073 ],
							[ 9.455286551000029, 9.482044650000034 ],
							[ 9.463817747000064, 9.47544354200005 ],
							[ 9.468560928000045, 9.474496139000053 ],
							[ 9.475710250000077, 9.474486996000053 ],
							[ 9.478997888000038, 9.474482746000035 ],
							[ 9.48279543600006, 9.476361201000032 ],
							[ 9.487553154000068, 9.486711110000044 ],
							[ 9.489457648000041, 9.491415694000068 ],
							[ 9.491370542000027, 9.50271073600004 ],
							[ 9.491384543000038, 9.513066865000042 ],
							[ 9.491393452000068, 9.519657346000031 ],
							[ 9.490553931000022, 9.528097299000024 ],
							[ 9.49188984500006, 9.529563040000028 ],
							[ 9.501013670000077, 9.534406459000024 ],
							[ 9.508750080000027, 9.539963577000037 ],
							[ 9.511433884000041, 9.537464489000058 ],
							[ 9.514429508000035, 9.531958752000037 ],
							[ 9.516268653000054, 9.525538621000067 ],
							[ 9.519262796000021, 9.519116280000048 ],
							[ 9.523188481000034, 9.517735340000058 ],
							[ 9.530117021000024, 9.516121213000076 ],
							[ 9.531020123000076, 9.515978079000035 ],
							[ 9.542952692000028, 9.508663327000022 ],
							[ 9.55082238600005, 9.505151583000043 ],
							[ 9.556589884000061, 9.505408515000056 ],
							[ 9.564281421000032, 9.505749449000064 ],
							[ 9.573806613000045, 9.508103576000053 ],
							[ 9.585254870000028, 9.510541997000075 ],
							[ 9.596702685000025, 9.512980620000064 ],
							[ 9.608149535000052, 9.515420036000023 ],
							[ 9.611996571000077, 9.515590549000024 ],
							[ 9.621521209000036, 9.517943446000061 ],
							[ 9.625367123000046, 9.51811495100003 ],
							[ 9.631046532000028, 9.520297207000056 ],
							[ 9.645879970000067, 9.524072832000058 ],
							[ 9.650275198000031, 9.521150447000025 ],
							[ 9.656043022000063, 9.521406974000058 ],
							[ 9.660066554000025, 9.517725106000057 ],
							[ 9.669681404000073, 9.518152502000021 ],
							[ 9.681129487000021, 9.520590784000035 ],
							[ 9.686898461000055, 9.520846252000069 ],
							[ 9.694500147000042, 9.523114368000051 ],
							[ 9.703935823000052, 9.52739447700003 ],
							[ 9.713550440000063, 9.527821305000032 ],
							[ 9.732600090000062, 9.532527817000073 ],
							[ 9.741759530000024, 9.534790736000048 ],
							[ 9.745882091000055, 9.536978886000043 ],
							[ 9.746943251000062, 9.542259068000021 ],
							[ 9.75766492200006, 9.544544999000038 ],
							[ 9.777699340000026, 9.55120458500005 ],
							[ 9.781454129000053, 9.555940470000053 ],
							[ 9.784272429000055, 9.557192925000038 ],
							[ 9.793907199000046, 9.567746033000049 ],
							[ 9.796202649000065, 9.57052860400006 ],
							[ 9.796740686000021, 9.571181239000055 ],
							[ 9.809048453000059, 9.586098460000073 ],
							[ 9.816847691000021, 9.593439124000042 ],
							[ 9.838871045000076, 9.601239214000032 ],
							[ 9.853553953000073, 9.606744981000077 ],
							[ 9.881541281000068, 9.60766247600003 ],
							[ 9.906775440000047, 9.611791413000049 ],
							[ 9.915951612000072, 9.610874264000074 ],
							[ 9.942104112000038, 9.609039221000046 ],
							[ 9.953860892000023, 9.611177237000049 ],
							[ 9.960440135000056, 9.622297240000023 ],
							[ 9.954812073000028, 9.647731674000056 ],
							[ 9.939740415000074, 9.692960320000054 ],
							[ 9.93693855600003, 9.710855336000066 ],
							[ 9.93506889300005, 9.722157540000069 ],
							[ 9.946531036000067, 9.748489465000034 ],
							[ 9.954152145000023, 9.757884486000023 ],
							[ 9.961770577000038, 9.766337952000072 ],
							[ 9.965062196000076, 9.767054353000049 ],
							[ 9.965962296000043, 9.770541305000052 ],
							[ 9.965747627000042, 9.773833397000033 ],
							[ 9.983682829000031, 9.769545801000049 ],
							[ 9.993386112000053, 9.76804558200007 ],
							[ 10.003089897000052, 9.766546235000021 ],
							[ 10.005780065000067, 9.767579774000069 ],
							[ 10.008769780000023, 9.768729460000031 ],
							[ 10.016550264000045, 9.767143443000066 ],
							[ 10.021989830000052, 9.76592540300004 ],
							[ 10.044156110000074, 9.760961445000021 ],
							[ 10.051468918000069, 9.760355871000058 ],
							[ 10.055268334000061, 9.760041023000042 ],
							[ 10.057781151000029, 9.759832122000034 ],
							[ 10.06004420000005, 9.758606769000039 ],
							[ 10.065240008000046, 9.755792469000028 ],
							[ 10.077050424000049, 9.750525672000038 ],
							[ 10.082900237000047, 9.74885461100007 ],
							[ 10.088850241000046, 9.745257398000035 ],
							[ 10.094710169000052, 9.743586693000054 ],
							[ 10.096719811000071, 9.741745940000044 ],
							[ 10.112549494000064, 9.732796497000038 ],
							[ 10.120510082000067, 9.727357915000027 ],
							[ 10.128379538000047, 9.723846350000031 ],
							[ 10.130390055000021, 9.722004853000044 ],
							[ 10.136779696000076, 9.708776678000049 ],
							[ 10.140810130000034, 9.705093548000036 ],
							[ 10.14299980800007, 9.699398759000076 ],
							[ 10.145010101000025, 9.697558309000044 ],
							[ 10.145189979000065, 9.693704527000023 ],
							[ 10.147380100000021, 9.688010262000034 ],
							[ 10.149832942000046, 9.681633134000037 ],
							[ 10.190362574000062, 9.687530689000027 ],
							[ 10.230242418000046, 9.689282862000027 ],
							[ 10.255872465000039, 9.688255210000023 ],
							[ 10.264400489000025, 9.68351849000004 ],
							[ 10.283324505000053, 9.665565236000077 ],
							[ 10.28881315700005, 9.659103710000068 ],
							[ 10.291359709000062, 9.657724512000073 ],
							[ 10.297389876000068, 9.652200687000061 ],
							[ 10.313219853000021, 9.643250457000022 ],
							[ 10.32686042000006, 9.639995484000053 ],
							[ 10.338930277000031, 9.628948204000039 ],
							[ 10.342960627000025, 9.625266230000022 ],
							[ 10.346980065000025, 9.621583677000046 ],
							[ 10.353009928000063, 9.616060056000038 ],
							[ 10.360969491000048, 9.610621330000072 ],
							[ 10.364910381000072, 9.608865797000021 ],
							[ 10.372870191000061, 9.603427939000028 ],
							[ 10.382749354000055, 9.598074846000031 ],
							[ 10.392539907000071, 9.594649290000064 ],
							[ 10.398400578000064, 9.592977680000047 ],
							[ 10.404259508000052, 9.591308817000026 ],
							[ 10.410200334000024, 9.587711484000067 ],
							[ 10.416150169000048, 9.584114039000042 ],
							[ 10.423839653000073, 9.584454598000036 ],
							[ 10.425850191000052, 9.582614858000056 ],
							[ 10.429789309000057, 9.580859190000069 ],
							[ 10.439669864000052, 9.575506241000028 ],
							[ 10.449459853000064, 9.572079743000074 ],
							[ 10.453399881000053, 9.570323819000066 ],
							[ 10.45933988400003, 9.566727395000044 ],
							[ 10.465379663000022, 9.561204131000068 ],
							[ 10.47902036000005, 9.557948005000071 ],
							[ 10.482950408000022, 9.556192377000059 ],
							[ 10.488900150000063, 9.552594877000047 ],
							[ 10.491269869000064, 9.543047898000054 ],
							[ 10.495469826000033, 9.535513089000062 ],
							[ 10.499670182000045, 9.527977097000075 ],
							[ 10.503600383000048, 9.526221243000066 ],
							[ 10.507630209000069, 9.522539126000026 ],
							[ 10.509909858000071, 9.514918172000023 ],
							[ 10.512009500000033, 9.511150106000059 ],
							[ 10.516327067000077, 9.510561109000037 ],
							[ 10.518485748000046, 9.503770691000057 ],
							[ 10.523667403000047, 9.500510295000026 ],
							[ 10.530586387000028, 9.492876822000028 ],
							[ 10.533220099000062, 9.492265772000053 ],
							[ 10.53790669600005, 9.489040576000036 ],
							[ 10.54165735600003, 9.482288594000067 ],
							[ 10.546210407000046, 9.479851007000036 ],
							[ 10.548219724000035, 9.478009461000056 ],
							[ 10.556180212000072, 9.472571530000039 ],
							[ 10.560210050000023, 9.468889398000044 ],
							[ 10.566149618000054, 9.465291799000056 ],
							[ 10.570349424000028, 9.45775724300006 ],
							[ 10.572539999000071, 9.452062606000027 ],
							[ 10.574650032000022, 9.448294678000025 ],
							[ 10.57666017400004, 9.44645409900005 ],
							[ 10.578940178000039, 9.438833250000073 ],
							[ 10.579199789000029, 9.433053034000068 ],
							[ 10.57956034700004, 9.42534713200007 ],
							[ 10.579919749000055, 9.417640676000076 ],
							[ 10.578350245000024, 9.409849219000023 ],
							[ 10.571019872000022, 9.40180091700006 ],
							[ 10.569179507000058, 9.399789704000057 ],
							[ 10.563589942000021, 9.395680387000027 ],
							[ 10.560009857000068, 9.38973050900006 ],
							[ 10.560370216000024, 9.382024035000029 ],
							[ 10.564390464000041, 9.378341874000057 ],
							[ 10.566580077000026, 9.372647269000026 ],
							[ 10.561260492000031, 9.362758768000049 ],
							[ 10.561440369000024, 9.358905823000043 ],
							[ 10.561619658000041, 9.35505173100006 ],
							[ 10.559869793000075, 9.351113139000063 ],
							[ 10.567919796000069, 9.343749301000059 ],
							[ 10.570110302000046, 9.338054639000063 ],
							[ 10.574129970000058, 9.334373492000054 ],
							[ 10.57806967700003, 9.332616776000066 ],
							[ 10.580260518000046, 9.32692262300003 ],
							[ 10.580610124000032, 9.319215590000056 ],
							[ 10.580880453000077, 9.313437547000035 ],
							[ 10.579319915000042, 9.305644882000024 ],
							[ 10.579489891000037, 9.301791957000034 ],
							[ 10.577750327000047, 9.297853394000072 ],
							[ 10.578099980000047, 9.290146926000034 ],
							[ 10.578370303000042, 9.284367727000074 ],
							[ 10.578640053000072, 9.278587418000029 ],
							[ 10.578909818000056, 9.27280823600006 ],
							[ 10.579259966000052, 9.265102309000042 ],
							[ 10.579890082000077, 9.25161545900005 ],
							[ 10.580149531000075, 9.245836311000062 ],
							[ 10.588199648000057, 9.238471957000058 ],
							[ 10.592309758000056, 9.23286323800005 ],
							[ 10.596609973000056, 9.223400861000073 ],
							[ 10.596870293000052, 9.217621687000076 ],
							[ 10.59722970100006, 9.209915122000041 ],
							[ 10.595719484000028, 9.208254636000049 ],
							[ 10.599657018000073, 9.201160386000026 ],
							[ 10.599906802000021, 9.195980807000069 ],
							[ 10.610487181000053, 9.189536803000067 ],
							[ 10.614236805000075, 9.182785085000035 ],
							[ 10.610369800000058, 9.175749646000043 ],
							[ 10.612002632000042, 9.170292757000027 ],
							[ 10.611850480000044, 9.168187323000041 ],
							[ 10.612573732000044, 9.165629379000052 ],
							[ 10.615110347000041, 9.156655353000076 ],
							[ 10.613539518000039, 9.148863940000069 ],
							[ 10.617739796000023, 9.141328115000022 ],
							[ 10.625610592000044, 9.13781648500003 ],
							[ 10.629639435000058, 9.134134084000038 ],
							[ 10.633660238000061, 9.13045222900007 ],
							[ 10.63576037100006, 9.126683917000037 ],
							[ 10.636120005000066, 9.118977825000059 ],
							[ 10.632630085000073, 9.11110119500006 ],
							[ 10.625289746000021, 9.103053330000023 ],
							[ 10.623550652000063, 9.099114366000038 ],
							[ 10.62252499300007, 9.094029644000045 ],
							[ 10.62724677500006, 9.089944667000054 ],
							[ 10.632547443000021, 9.086723368000037 ],
							[ 10.637916479000069, 9.081773641000041 ],
							[ 10.640036828000063, 9.073219398000049 ],
							[ 10.637197750000041, 9.060980742000027 ],
							[ 10.637447484000063, 9.055801670000051 ],
							[ 10.634366822000061, 9.048742021000066 ],
							[ 10.632905820000076, 9.043486886000039 ],
							[ 10.629816236000067, 9.03642723400003 ],
							[ 10.630066076000048, 9.031248160000075 ],
							[ 10.627225946000067, 9.019009407000055 ],
							[ 10.622701717000041, 9.013994323000077 ],
							[ 10.619176009000057, 9.008267299000067 ],
							[ 10.61430731400003, 9.002856931000053 ],
							[ 10.609505625000054, 8.995720966000022 ],
							[ 10.606257540000058, 8.992115126000044 ],
							[ 10.603165520000061, 8.98505582100006 ],
							[ 10.596746347000021, 8.976116127000068 ],
							[ 10.588287212000068, 8.974005865000038 ],
							[ 10.579746404000048, 8.973621556000069 ],
							[ 10.57470716000006, 8.971664151000027 ],
							[ 10.56624712200005, 8.969553943000051 ],
							[ 10.55598750300004, 8.969092185000022 ],
							[ 10.544027170000049, 8.968554773000051 ],
							[ 10.538827039000068, 8.970049720000077 ],
							[ 10.530277268000077, 8.969665451000026 ],
							[ 10.518396205000045, 8.967401620000032 ],
							[ 10.509776902000056, 8.968743532000076 ],
							[ 10.497977038000045, 8.964752915000076 ],
							[ 10.489356029000021, 8.966094913000063 ],
							[ 10.479186837000043, 8.96390711600003 ],
							[ 10.468936020000058, 8.963446862000069 ],
							[ 10.463807207000059, 8.963215782000077 ],
							[ 10.450385779000044, 8.957421197000031 ],
							[ 10.445346888000074, 8.955464283000026 ],
							[ 10.440296106000062, 8.953507330000036 ],
							[ 10.433626099000037, 8.94974718800006 ],
							[ 10.426877133000062, 8.947713583000052 ],
							[ 10.420206217000043, 8.943953757000031 ],
							[ 10.406956527000034, 8.934706445000074 ],
							[ 10.400446809000073, 8.927494013000057 ],
							[ 10.397196863000033, 8.923887447000027 ],
							[ 10.388815995000073, 8.920049905000042 ],
							[ 10.380526566000071, 8.914487123000072 ],
							[ 10.375645617000032, 8.909076475000063 ],
							[ 10.372397221000028, 8.905470812000033 ],
							[ 10.369147189000046, 8.901864016000047 ],
							[ 10.362556548000043, 8.896377582000071 ],
							[ 10.357596121000029, 8.892694489000064 ],
							[ 10.350927528000057, 8.888933952000059 ],
							[ 10.341096931000038, 8.879840681000076 ],
							[ 10.334667310000043, 8.870902110000031 ],
							[ 10.329875701000049, 8.863764684000046 ],
							[ 10.325076315000047, 8.856629519000023 ],
							[ 10.30507560500007, 8.845348583000032 ],
							[ 10.298485837000044, 8.839862120000021 ],
							[ 10.291896827000073, 8.834375554000076 ],
							[ 10.286856510000064, 8.832418161000021 ],
							[ 10.276936767000052, 8.825052112000037 ],
							[ 10.266937034000023, 8.819411320000029 ],
							[ 10.261806406000062, 8.819181403000073 ],
							[ 10.256927371000074, 8.813771418000044 ],
							[ 10.248636126000065, 8.808208494000041 ],
							[ 10.24559615000004, 8.804408306000028 ],
							[ 10.235707135000041, 8.792056269000057 ],
							[ 10.229046757000049, 8.788296897000066 ],
							[ 10.224076077000063, 8.784611455000061 ],
							[ 10.214327001000072, 8.773792064000077 ],
							[ 10.209445876000075, 8.768383134000032 ],
							[ 10.206195835000074, 8.764776014000063 ],
							[ 10.194737395000061, 8.753879749000021 ],
							[ 10.188146392000021, 8.748394135000069 ],
							[ 10.183437208000043, 8.739530400000035 ],
							[ 10.180347571000027, 8.732471428000053 ],
							[ 10.177096432000042, 8.728865734000067 ],
							[ 10.170427592000067, 8.725105322000047 ],
							[ 10.165546636000045, 8.719696079000073 ],
							[ 10.16229633100005, 8.716089120000049 ],
							[ 10.15904609200004, 8.712482411000053 ],
							[ 10.152456950000044, 8.706995867000046 ],
							[ 10.149206890000073, 8.703389069000025 ],
							[ 10.140996822000034, 8.696100174000037 ],
							[ 10.137746969000034, 8.692492439000034 ],
							[ 10.132865782000067, 8.687083330000064 ],
							[ 10.127987031000032, 8.681673852000074 ],
							[ 10.12302679000004, 8.677990965000049 ],
							[ 10.116447342000072, 8.672503464000044 ],
							[ 10.113277336000067, 8.66717056300007 ],
							[ 10.108397430000025, 8.661761398000067 ],
							[ 10.103606203000027, 8.654624956000021 ],
							[ 10.097175597000046, 8.645686115000046 ],
							[ 10.087177330000031, 8.640045206000025 ],
							[ 10.085717340000031, 8.634789523000052 ],
							[ 10.080836562000059, 8.629379138000047 ],
							[ 10.07596561400004, 8.623970084000064 ],
							[ 10.071085703000051, 8.618559862000041 ],
							[ 10.066207075000023, 8.613150699000073 ],
							[ 10.061407077000069, 8.606014274000074 ],
							[ 10.051827528000047, 8.591742580000073 ],
							[ 10.043606011000065, 8.584452646000045 ],
							[ 10.032066414000042, 8.575282119000065 ],
							[ 10.025476556000058, 8.569796668000038 ],
							[ 10.022227285000042, 8.56618985800003 ],
							[ 10.014017158000058, 8.558899800000063 ],
							[ 10.010766962000048, 8.555293168000048 ],
							[ 10.008015801000056, 8.553002418000062 ],
							[ 10.004177208000044, 8.549806480000029 ],
							[ 9.997757150000041, 8.540867837000064 ],
							[ 9.992796738000038, 8.537183736000031 ],
							[ 9.982876797000074, 8.529817605000062 ],
							[ 9.976286808000054, 8.524330987000042 ],
							[ 9.971326841000064, 8.520648018000031 ],
							[ 9.963113658000054, 8.513357081000038 ],
							[ 9.958235775000048, 8.507947872000045 ],
							[ 9.953359726000031, 8.502536908000025 ],
							[ 9.950106657000049, 8.498931999000035 ],
							[ 9.943439260000048, 8.495171688000028 ],
							[ 9.933517496000036, 8.487804499000049 ],
							[ 9.926848205000056, 8.484044169000072 ],
							[ 9.920263276000071, 8.478557638000041 ],
							[ 9.915385361000062, 8.473148429000048 ],
							[ 9.908881131000044, 8.465934808000043 ],
							[ 9.905629963000024, 8.462328942000056 ],
							[ 9.895791925000026, 8.453235542000073 ],
							[ 9.89091415200005, 8.447825428000044 ],
							[ 9.879366826000023, 8.438655740000058 ],
							[ 9.869446768000046, 8.431289639000056 ],
							[ 9.862776903000054, 8.427529272000072 ],
							[ 9.856025469000031, 8.425495087000058 ],
							[ 9.84106274100003, 8.416171902000031 ],
							[ 9.836102211000025, 8.412488803000031 ],
							[ 9.824240602000032, 8.405097903000069 ],
							[ 9.821446295000044, 8.40279115800007 ],
							[ 9.816094522000071, 8.401208034000035 ],
							[ 9.809424625000077, 8.397447531000068 ],
							[ 9.80233118700005, 8.39132313500005 ],
							[ 9.796966787000031, 8.390598161000071 ],
							[ 9.784161341000072, 8.38241967700003 ],
							[ 9.780140166000024, 8.381554563000066 ],
							[ 9.774427589000027, 8.378014622000023 ],
							[ 9.764990725000075, 8.373734408000075 ],
							[ 9.761324192000075, 8.36971078800002 ],
							[ 9.756654558000037, 8.367341871000065 ],
							[ 9.733457500000043, 8.370358495000062 ],
							[ 9.726472993000073, 8.371267191000072 ],
							[ 9.723361899000054, 8.371671725000056 ],
							[ 9.71675422900006, 8.372530905000076 ],
							[ 9.71106270100006, 8.373270945000058 ],
							[ 9.707128633000025, 8.375026695000031 ],
							[ 9.703282235000074, 8.374856055000066 ],
							[ 9.695591163000074, 8.374514652000073 ],
							[ 9.693578516000059, 8.376355060000037 ],
							[ 9.683875836000027, 8.377855215000068 ],
							[ 9.679941031000055, 8.379610971000034 ],
							[ 9.668315003000032, 8.381025267000041 ],
							[ 9.664380089000076, 8.382781116000046 ],
							[ 9.654766218000077, 8.382353886000033 ],
							[ 9.652753883000059, 8.384195198000043 ],
							[ 9.647578316000022, 8.384702778000076 ],
							[ 9.639204926000048, 8.385524670000052 ],
							[ 9.63530931400004, 8.386155011000028 ],
							[ 9.63136686200005, 8.386625197000058 ],
							[ 9.628418943000042, 8.386976372000049 ],
							[ 9.621475490000023, 8.388993169000059 ],
							[ 9.621068700000023, 8.38950557000004 ],
							[ 9.60886811000006, 8.402157311000053 ],
							[ 9.575997521000033, 8.42373073300007 ],
							[ 9.571774599000037, 8.42888348200006 ],
							[ 9.560495548000063, 8.431240109000044 ],
							[ 9.545914408000044, 8.42611585700007 ],
							[ 9.529692713000031, 8.423800277000055 ],
							[ 9.508541524000066, 8.426169385000037 ],
							[ 9.491624281000043, 8.430870612000035 ],
							[ 9.420674031000033, 8.462303432000056 ],
							[ 9.397651818000043, 8.475896135000028 ],
							[ 9.377915250000058, 8.485741321000035 ],
							[ 9.362403306000033, 8.489967543000034 ],
							[ 9.345948812000074, 8.491854726000042 ],
							[ 9.336542663000046, 8.489056255000037 ],
							[ 9.318673622000063, 8.486733180000044 ],
							[ 9.310206564000055, 8.482529804000023 ],
							[ 9.301267318000043, 8.475051808000046 ],
							[ 9.292800733000036, 8.47084830600005 ],
							[ 9.28621709600003, 8.46898212800005 ],
							[ 9.274537300000077, 8.463628699000026 ],
							[ 9.273347147000038, 8.463870887000041 ],
							[ 9.263910559000067, 8.459594830000071 ],
							[ 9.261899249000066, 8.461435403000053 ],
							[ 9.254297415000053, 8.459166454000069 ],
							[ 9.244682583000042, 8.45874010600005 ],
							[ 9.233145960000058, 8.458228252000026 ],
							[ 9.215840509000031, 8.457459516000029 ],
							[ 9.211994056000037, 8.457288835000043 ],
							[ 9.202290679000043, 8.458789006000075 ],
							[ 9.184629685000061, 8.465726799000038 ],
							[ 9.182617364000066, 8.467567513000063 ],
							[ 9.17702655100004, 8.463459072000035 ],
							[ 9.17492597100005, 8.467225953000025 ],
							[ 9.15806582700003, 8.456825134000042 ],
							[ 9.156231793000075, 8.454813013000035 ],
							[ 9.152475456000047, 8.452715762000025 ],
							[ 9.148897011000031, 8.446765804000052 ],
							[ 9.145819467000024, 8.443388819000063 ],
							[ 9.138598702000024, 8.43831354100007 ],
							[ 9.136756043000048, 8.435445723000043 ],
							[ 9.132394088000069, 8.428657547000057 ],
							[ 9.128458931000068, 8.430414159000065 ],
							[ 9.127536755000051, 8.433738739000034 ],
							[ 9.124434669000038, 8.434096373000045 ],
							[ 9.121526888000062, 8.438224911000077 ],
							[ 9.11842802700005, 8.43816375800003 ],
							[ 9.111243342000023, 8.438022748000037 ],
							[ 9.101093512000034, 8.438851296000053 ],
							[ 9.097158672000035, 8.440607017000048 ],
							[ 9.096802942000068, 8.448313769000038 ],
							[ 9.098458215000051, 8.454178887000069 ],
							[ 9.100202725000031, 8.458116655000026 ],
							[ 9.103188744000022, 8.464233407000052 ],
							[ 9.102123233000043, 8.469863029000066 ],
							[ 9.101412669000069, 8.473614694000048 ],
							[ 9.095366584000033, 8.477988262000054 ],
							[ 9.088303482000072, 8.483602660000031 ],
							[ 9.083287507000023, 8.486323411000058 ],
							[ 9.080109691000075, 8.488046571000041 ],
							[ 9.075792320000062, 8.485990494000021 ],
							[ 9.069934901000067, 8.487661259000049 ],
							[ 9.066352617000064, 8.492520434000028 ],
							[ 9.063793023000073, 8.493480719000047 ],
							[ 9.059874710000031, 8.496867215000066 ],
							[ 9.059518734000051, 8.504572779000057 ],
							[ 9.059162165000032, 8.512280341000064 ],
							[ 9.054130401000066, 8.516071277000037 ],
							[ 9.049360359000048, 8.519665751000048 ],
							[ 9.043380926000054, 8.518355453000027 ],
							[ 9.040200356000071, 8.514884078000023 ],
							[ 9.036776295000038, 8.512514947000057 ],
							[ 9.032154098000035, 8.513010980000047 ],
							[ 9.026385074000075, 8.512754307000023 ],
							[ 9.019470297000055, 8.509875242000021 ],
							[ 9.013636673000065, 8.512063041000033 ],
							[ 9.010276583000064, 8.515272106000054 ],
							[ 9.005144807000022, 8.513740490000032 ],
							[ 8.997275358000024, 8.517252935000045 ],
							[ 8.99473867200004, 8.519574083000066 ],
							[ 8.990618976000064, 8.521105723000062 ],
							[ 8.988257193000038, 8.52288545600004 ],
							[ 8.982459996000046, 8.527254054000025 ],
							[ 8.979004058000044, 8.53058431900007 ],
							[ 8.975144511000053, 8.537506065000059 ],
							[ 8.974965897000061, 8.541358947000049 ],
							[ 8.978455392000058, 8.549236184000051 ],
							[ 8.984020308000026, 8.553231184000026 ],
							[ 8.984094492000054, 8.558160882000038 ],
							[ 8.986068898000042, 8.56198024300005 ],
							[ 8.987382748000073, 8.564522687000022 ],
							[ 8.986305055000059, 8.566468304000068 ],
							[ 8.983221900000046, 8.572034754000072 ],
							[ 8.982412274000069, 8.574304444000063 ],
							[ 8.981021992000024, 8.578203314000064 ],
							[ 8.983179822000068, 8.58511351900006 ],
							[ 8.983431539000037, 8.596580632000041 ],
							[ 8.980529942000032, 8.601173380000034 ],
							[ 8.977794758000073, 8.605191115000025 ],
							[ 8.970023317000027, 8.605570873000033 ],
							[ 8.964166734000059, 8.608907578000071 ],
							[ 8.95831004300004, 8.612245579000046 ],
							[ 8.951176418000045, 8.621312075000048 ],
							[ 8.949756567000065, 8.633942516000047 ],
							[ 8.94732397100006, 8.63964857700006 ],
							[ 8.94449789600003, 8.646667584000056 ],
							[ 8.942275964000032, 8.652189151000073 ],
							[ 8.941788484000028, 8.655583365000041 ],
							[ 8.941023599000061, 8.65960506600004 ],
							[ 8.940459438000062, 8.662574867000046 ],
							[ 8.940243882000061, 8.667230721000067 ],
							[ 8.934799053000063, 8.667881068000042 ],
							[ 8.932219790000033, 8.67140401100005 ],
							[ 8.929218050000031, 8.673177586000065 ],
							[ 8.925706698000056, 8.677200416000062 ],
							[ 8.919826704000059, 8.681688358000031 ],
							[ 8.91394714300003, 8.686176204000049 ],
							[ 8.911806309000042, 8.698771513000054 ],
							[ 8.910738058000049, 8.702993408000054 ],
							[ 8.907656733000067, 8.71021943900007 ],
							[ 8.902836893000028, 8.719330727000056 ],
							[ 8.896886766000023, 8.727269067000066 ],
							[ 8.892136804000074, 8.732930286000055 ],
							[ 8.891933419000054, 8.74035365900005 ],
							[ 8.896033421000027, 8.748279530000048 ],
							[ 8.899371161000033, 8.750970892000055 ],
							[ 8.904410264000035, 8.755034163000062 ],
							[ 8.904689654000038, 8.758125326000027 ],
							[ 8.901088610000045, 8.763946602000033 ],
							[ 8.901631660000021, 8.765132883000035 ],
							[ 8.903566646000058, 8.770669675000022 ],
							[ 8.907196712000029, 8.775965590000055 ],
							[ 8.911553643000047, 8.779817804000061 ],
							[ 8.916966213000023, 8.783663428000068 ],
							[ 8.919151234000026, 8.78329547800007 ],
							[ 8.923966372000052, 8.782485008000037 ],
							[ 8.929040689000033, 8.784493260000033 ],
							[ 8.932709566000028, 8.78550830000006 ],
							[ 8.938326895000046, 8.793672696000044 ],
							[ 8.945146484000077, 8.799559835000025 ],
							[ 8.949033591000045, 8.810478074000059 ],
							[ 8.950596673000064, 8.815776646000074 ],
							[ 8.950969015000055, 8.824192132000064 ],
							[ 8.951099365000061, 8.827142518000073 ],
							[ 8.950065511000048, 8.829829415000063 ],
							[ 8.949798672000043, 8.835607511000035 ],
							[ 8.955300299000044, 8.841644015000043 ],
							[ 8.957044559000053, 8.845582937000074 ],
							[ 8.958789974000069, 8.849521869000057 ],
							[ 8.96639129600004, 8.851789243000042 ],
							[ 8.973994408000067, 8.854057590000025 ],
							[ 8.985619455000062, 8.852643225000065 ],
							[ 8.989554200000043, 8.850887030000024 ],
							[ 8.999079583000025, 8.853240809000056 ],
							[ 9.003014811000071, 8.85148504600005 ],
							[ 9.010616449000054, 8.853753192000056 ],
							[ 9.016206831000034, 8.857862373000046 ],
							[ 9.018040408000047, 8.859874039000033 ],
							[ 9.023541279000028, 8.865909516000045 ],
							[ 9.025197252000055, 8.871774539000057 ],
							[ 9.026764161000074, 8.879565963000061 ],
							[ 9.028508221000038, 8.883505113000069 ],
							[ 9.031820558000049, 8.895235094000043 ],
							[ 9.039065332000064, 8.905209469000056 ],
							[ 9.040810785000076, 8.909147982000036 ],
							[ 9.044477629000028, 8.913171603000023 ],
							[ 9.044300124000074, 8.917024443000059 ],
							[ 9.045955527000046, 8.922889912000073 ],
							[ 9.053290228000037, 8.930936991000067 ],
							[ 9.06254786900007, 8.93907064800004 ],
							[ 9.068227942000021, 8.941252590000033 ],
							[ 9.071894817000043, 8.94527706100007 ],
							[ 9.073461347000034, 8.953068830000063 ],
							[ 9.071182462000024, 8.960689552000076 ],
							[ 9.069081329000028, 8.964458562000061 ],
							[ 9.067069095000022, 8.966298317000053 ],
							[ 9.068367679000062, 8.979869902000075 ],
							[ 9.070737918000077, 8.987840651000056 ],
							[ 9.069836692000024, 8.991868795000073 ],
							[ 9.069753770000034, 8.995417602000032 ],
							[ 9.06908140400003, 8.997752121000076 ],
							[ 9.066963006000037, 9.007926994000059 ],
							[ 9.062741213000038, 9.018228793000048 ],
							[ 9.060153692000029, 9.020597519000034 ],
							[ 9.056705700000066, 9.023752977000072 ],
							[ 9.052770548000069, 9.025508724000076 ],
							[ 9.049557019000076, 9.02616411300005 ],
							[ 9.044990359000053, 9.027093710000031 ],
							[ 9.039221713000074, 9.026837262000072 ],
							[ 9.027773595000042, 9.024398792000056 ],
							[ 9.023927481000044, 9.024227835000033 ],
							[ 9.020171137000034, 9.022131020000074 ],
							[ 9.01632505200007, 9.021977500000048 ],
							[ 9.012277626000071, 9.021788341000047 ],
							[ 9.008892232000051, 9.020766193000043 ],
							[ 9.004336357000057, 9.021058990000029 ],
							[ 9.00094303700007, 9.021277144000067 ],
							[ 8.998668500000065, 9.020369610000046 ],
							[ 8.995292834000054, 9.01902295900004 ],
							[ 8.992649173000075, 9.018939293000074 ],
							[ 8.989495331000057, 9.01883863200004 ],
							[ 8.983613150000053, 9.017694665000022 ],
							[ 8.977663298000039, 9.016536490000021 ],
							[ 8.974201232000041, 9.016228846000047 ],
							[ 8.96952710000005, 9.016020544000071 ],
							[ 8.966815876000055, 9.015042135000044 ],
							[ 8.963750147000042, 9.015764025000067 ],
							[ 8.960741957000039, 9.015630914000042 ],
							[ 8.954884581000044, 9.01730178400004 ],
							[ 8.95112812800005, 9.015204264000033 ],
							[ 8.943020875000059, 9.01558114200003 ],
							[ 8.929887912000027, 9.016190307000045 ],
							[ 8.927272735000031, 9.016075089000026 ],
							[ 8.924118943000053, 9.015936002000046 ],
							[ 8.92279934000004, 9.016204705000064 ],
							[ 8.91633895700005, 9.01752108900007 ],
							[ 8.907392306000077, 9.01932329400006 ],
							[ 8.898164625000049, 9.021048342000029 ],
							[ 8.894920511000066, 9.022360707000075 ],
							[ 8.888392159000034, 9.022071720000042 ],
							[ 8.885355030000028, 9.021609106000028 ],
							[ 8.875513348000027, 9.02535983000007 ],
							[ 8.865810494000073, 9.026860129000056 ],
							[ 8.861964430000057, 9.026689743000077 ],
							[ 8.850249498000039, 9.030030511000064 ],
							[ 8.844481502000065, 9.029773830000067 ],
							[ 8.838712955000062, 9.029518189000044 ],
							[ 8.823241282000026, 9.030761655000049 ],
							[ 8.811725768000031, 9.030302180000035 ],
							[ 8.806030314000054, 9.032741551000072 ],
							[ 8.798154860000068, 9.031578333000027 ],
							[ 8.794041591000052, 9.037186847000044 ],
							[ 8.789928195000073, 9.042795869000031 ],
							[ 8.789750205000075, 9.046649133000074 ],
							[ 8.789482935000024, 9.052428157000065 ],
							[ 8.793061439000041, 9.058379453000043 ],
							[ 8.794805542000063, 9.062317968000059 ],
							[ 8.796550819000061, 9.066255363000039 ],
							[ 8.799438432000045, 9.071156268000038 ],
							[ 8.795664961000057, 9.075638759000071 ],
							[ 8.791902612000058, 9.083423586000038 ],
							[ 8.784123477000037, 9.085009843000023 ],
							[ 8.780188620000047, 9.086765508000042 ],
							[ 8.77817652300007, 9.088605934000043 ],
							[ 8.774152808000053, 9.092287881000061 ],
							[ 8.768846582000037, 9.095305561000032 ],
							[ 8.768505904000051, 9.097862931000066 ],
							[ 8.764093451000065, 9.101494786000046 ],
							[ 8.760068892000049, 9.105177133000041 ],
							[ 8.761635678000061, 9.112968475000059 ],
							[ 8.761457355000061, 9.116821118000075 ],
							[ 8.761279028000047, 9.120674354000073 ],
							[ 8.764768317000062, 9.12855053800007 ],
							[ 8.767522768000049, 9.136285513000075 ],
							[ 8.767901221000045, 9.144135392000067 ],
							[ 8.767710924000028, 9.149915548000024 ],
							[ 8.767553060000068, 9.157420375000072 ],
							[ 8.764998372000036, 9.165241261000062 ],
							[ 8.761064288000057, 9.166996716000028 ],
							[ 8.75502797200005, 9.17252043700006 ],
							[ 8.748992052000062, 9.178043486000036 ],
							[ 8.746224480000024, 9.178643979000071 ],
							[ 8.739396302000046, 9.180125392000036 ],
							[ 8.733431549000045, 9.181214582000052 ],
							[ 8.721716724000032, 9.184555185000022 ],
							[ 8.712012959000049, 9.186054260000049 ],
							[ 8.70807839500003, 9.187809722000054 ],
							[ 8.696332926000025, 9.187096715000052 ],
							[ 8.690772927000069, 9.187042360000021 ],
							[ 8.687015683000027, 9.184944409000025 ],
							[ 8.681158873000072, 9.186614883000061 ],
							[ 8.677312903000029, 9.186444276000032 ],
							[ 8.67328937700006, 9.190126217000056 ],
							[ 8.667521241000031, 9.189869605000069 ],
							[ 8.663496998000028, 9.193553141000052 ],
							[ 8.661484839000025, 9.195393495000076 ],
							[ 8.65780746400003, 9.207689198000025 ],
							[ 8.656926046000024, 9.210636428000043 ],
							[ 8.656659379000075, 9.216415306000044 ],
							[ 8.657498089000057, 9.218362804000037 ],
							[ 8.659735425000065, 9.223551796000038 ],
							[ 8.657099450000032, 9.226499106000063 ],
							[ 8.655309454000076, 9.228500094000026 ],
							[ 8.650911167000061, 9.234711888000049 ],
							[ 8.646065647000057, 9.237179530000049 ],
							[ 8.642219736000072, 9.237009114000045 ],
							[ 8.63819585300007, 9.240692366000076 ],
							[ 8.635916861000055, 9.248313114000041 ],
							[ 8.639494784000021, 9.254263022000032 ],
							[ 8.641328854000051, 9.256275369000036 ],
							[ 8.643072821000032, 9.260214003000044 ],
							[ 8.648664742000051, 9.264323018000027 ],
							[ 8.658100184000034, 9.268602215000044 ],
							[ 8.66579041500006, 9.268943801000034 ],
							[ 8.671559227000046, 9.269200439000031 ],
							[ 8.678983688000073, 9.275321749000057 ],
							[ 8.684395886000061, 9.283283359000052 ],
							[ 8.687528397000051, 9.298867061000067 ],
							[ 8.689273648000039, 9.302804912000056 ],
							[ 8.692673829000057, 9.312608537000074 ],
							[ 8.698085745000071, 9.320571059000031 ],
							[ 8.694150795000041, 9.322327489000031 ],
							[ 8.693972791000022, 9.326180649000037 ],
							[ 8.691426531000047, 9.339580568000031 ],
							[ 8.68941484100003, 9.341422170000044 ],
							[ 8.690713785000071, 9.354993122000053 ],
							[ 8.692458202000068, 9.358931501000029 ],
							[ 8.695857856000032, 9.368734528000061 ],
							[ 8.69561027900005, 9.374082814000076 ],
							[ 8.695412539000074, 9.378367641000068 ],
							[ 8.691300608000063, 9.383976037000025 ],
							[ 8.687276736000058, 9.387659238000026 ],
							[ 8.68334212700006, 9.389414974000033 ],
							[ 8.679406921000066, 9.391171241000052 ],
							[ 8.669524879000051, 9.396523434000073 ],
							[ 8.664890509000031, 9.39317681700004 ],
							[ 8.656154627000035, 9.393998878000048 ],
							[ 8.64837450400006, 9.395583953000028 ],
							[ 8.642338948000031, 9.401108025000042 ],
							[ 8.638225362000071, 9.406716377000066 ],
							[ 8.636124551000023, 9.41048453600007 ],
							[ 8.631833193000034, 9.419945925000036 ],
							[ 8.627899124000066, 9.421702197000059 ],
							[ 8.623696531000064, 9.429237211000043 ],
							[ 8.619672610000066, 9.432920241000033 ],
							[ 8.617661758000054, 9.434760871000037 ],
							[ 8.613191851000067, 9.448076257000025 ],
							[ 8.608900012000049, 9.457537915000046 ],
							[ 8.608116303000031, 9.461351367000077 ],
							[ 8.607570746000022, 9.464001569000061 ],
							[ 8.605117922000034, 9.466742409000062 ],
							[ 8.602418942000043, 9.472694295000053 ],
							[ 8.602242729000068, 9.476547362000076 ],
							[ 8.593622156000038, 9.478786427000045 ],
							[ 8.586234368000021, 9.481567448000021 ],
							[ 8.580555157000049, 9.48716715300003 ],
							[ 8.574430498000027, 9.494617475000041 ],
							[ 8.570287677000067, 9.498610024000072 ],
							[ 8.568216182000072, 9.503993926000021 ],
							[ 8.565848629000072, 9.513540940000041 ],
							[ 8.563569263000034, 9.521162257000071 ],
							[ 8.561377552000067, 9.52685619600004 ],
							[ 8.559011385000076, 9.536403711000048 ],
							[ 8.558832982000069, 9.540257315000076 ],
							[ 8.55562379600002, 9.547332727000025 ],
							[ 8.554541494000034, 9.549718870000049 ],
							[ 8.556285931000048, 9.553657293000072 ],
							[ 8.553649753000059, 9.568984022000052 ],
							[ 8.555038506000074, 9.580629615000021 ],
							[ 8.560450820000028, 9.588591773000076 ],
							[ 8.56228442500003, 9.590602789000059 ],
							[ 8.565863372000024, 9.596553849000031 ],
							[ 8.567339799000024, 9.606272024000077 ],
							[ 8.566984238000032, 9.613977506000026 ],
							[ 8.566628678000029, 9.621684113000072 ],
							[ 8.564438126000027, 9.627378614000065 ],
							[ 8.560235833000036, 9.634913395000069 ],
							[ 8.559969013000057, 9.640693751000072 ],
							[ 8.55366626700004, 9.651996673000042 ],
							[ 8.55505364000004, 9.663641097000038 ],
							[ 8.562477927000032, 9.669762330000026 ],
							[ 8.56632326600004, 9.669933566000054 ],
							[ 8.576026856000055, 9.668433257000061 ],
							[ 8.581884525000021, 9.666763379000031 ],
							[ 8.58765332300004, 9.667019055000026 ],
							[ 8.593065361000072, 9.674981823000053 ],
							[ 8.594809695000038, 9.678920016000063 ],
							[ 8.596554628000035, 9.682858219000025 ],
							[ 8.598120959000028, 9.690649983000071 ],
							[ 8.597764269000038, 9.698355455000069 ],
							[ 8.595574321000072, 9.704050116000076 ],
							[ 8.595307453000032, 9.709830473000068 ],
							[ 8.592849923000074, 9.721303782000064 ],
							[ 8.594327001000067, 9.731021777000024 ],
							[ 8.599562915000035, 9.737936025000067 ],
							[ 8.603585070000065, 9.739154690000021 ],
							[ 8.611186846000066, 9.741423611000073 ],
							[ 8.615465220000033, 9.742396615000075 ],
							[ 8.62035577100005, 9.751482889000044 ],
							[ 8.624112235000041, 9.753580297000042 ],
							[ 8.627790374000028, 9.757615783000062 ],
							[ 8.631621161000055, 9.759012519000066 ],
							[ 8.637150169000051, 9.766336183000021 ],
							[ 8.637142249000021, 9.774264083000048 ],
							[ 8.632207662000042, 9.787676158000068 ],
							[ 8.631583427000066, 9.796213133000037 ],
							[ 8.631571054000062, 9.808410087000027 ],
							[ 8.629885945000069, 9.819196082000076 ],
							[ 8.630520123000053, 9.823362391000046 ],
							[ 8.630252024000072, 9.829142751000063 ],
							[ 8.628062357000033, 9.834836986000028 ],
							[ 8.629538579000041, 9.844554756000036 ],
							[ 8.629094337000026, 9.85418810300007 ],
							[ 8.628827333000061, 9.859967322000045 ],
							[ 8.626547695000056, 9.867588319000049 ],
							[ 8.620333462000076, 9.87696432000007 ],
							[ 8.618143352000061, 9.882659036000064 ],
							[ 8.619621202000076, 9.892376838000075 ],
							[ 8.623199505000059, 9.898326659000077 ],
							[ 8.626777925000056, 9.904277591000039 ],
							[ 8.628523147000067, 9.908216700000025 ],
							[ 8.635857670000064, 9.916263714000024 ],
							[ 8.635500997000065, 9.923970266000026 ],
							[ 8.633221657000036, 9.931591259000072 ],
							[ 8.629109319000065, 9.937199362000058 ],
							[ 8.622895100000051, 9.946575923000069 ],
							[ 8.616859692000048, 9.952099986000064 ],
							[ 8.610823542000048, 9.957622800000024 ],
							[ 8.610556731000031, 9.963402545000065 ],
							[ 8.614222618000042, 9.967426233000026 ],
							[ 8.617712857000072, 9.975303421000035 ],
							[ 8.624618785000052, 9.977009628000076 ],
							[ 8.627238544000022, 9.977657578000048 ],
							[ 8.634839726000052, 9.97992540000007 ],
							[ 8.640698154000063, 9.978254043000049 ],
							[ 8.652234918000033, 9.978766282000038 ],
							[ 8.661760339000068, 9.98112012200005 ],
							[ 8.667349898000055, 9.985228670000026 ],
							[ 8.673029689000032, 9.98741261400005 ],
							[ 8.676608105000071, 9.993362457000046 ],
							[ 8.679919183000038, 10.005089764000047 ],
							[ 8.679473814000062, 10.014730428000064 ],
							[ 8.67527227000005, 10.022259571000063 ],
							[ 8.674826748000044, 10.03189003600005 ],
							[ 8.672635992000039, 10.037590114000068 ],
							[ 8.668434041000069, 10.045120299000075 ],
							[ 8.662220708000063, 10.05449972100007 ],
							[ 8.660208908000072, 10.056339980000075 ],
							[ 8.653906068000026, 10.067640375000053 ],
							[ 8.649792507000029, 10.073249773000043 ],
							[ 8.649435953000022, 10.080960211000047 ],
							[ 8.645144436000066, 10.090419707000024 ],
							[ 8.641120824000041, 10.094099984000025 ],
							[ 8.635174734000032, 10.097700373000066 ],
							[ 8.631061681000062, 10.103309555000067 ],
							[ 8.628693588000033, 10.112859469000057 ],
							[ 8.628337118000047, 10.120559685000046 ],
							[ 8.628158596000048, 10.124419966000062 ],
							[ 8.629547310000021, 10.136059819000025 ],
							[ 8.633036639000068, 10.143940168000029 ],
							[ 8.638448805000053, 10.151900352000041 ],
							[ 8.643950405000055, 10.157939904000045 ],
							[ 8.645694761000073, 10.161869835000061 ],
							[ 8.647260786000061, 10.169670219000068 ],
							[ 8.646726892000061, 10.181219965000025 ],
							[ 8.64829246100004, 10.189020335000066 ],
							[ 8.646013427000071, 10.196640175000027 ],
							[ 8.64950258600004, 10.204509598000072 ],
							[ 8.654916007000054, 10.212479678000022 ],
							[ 8.662338962000035, 10.218600393000031 ],
							[ 8.669763309000075, 10.224719829000037 ],
							[ 8.673520375000066, 10.22682007700007 ],
							[ 8.680854937000049, 10.234860389000062 ],
							[ 8.684611031000031, 10.236960504000024 ],
							[ 8.688279349000027, 10.240980002000072 ],
							[ 8.695792374000064, 10.245180079000022 ],
							[ 8.697536563000028, 10.249119908000068 ],
							[ 8.703038327000058, 10.255150082000057 ],
							[ 8.708628573000055, 10.259260175000065 ],
							[ 8.710462379000035, 10.26127998100003 ],
							[ 8.718064346000062, 10.263540402000046 ],
							[ 8.72557762100007, 10.267740423000021 ],
							[ 8.729244983000058, 10.271760080000035 ],
							[ 8.731057317000023, 10.272963822000065 ],
							[ 8.736428192000062, 10.275022786000022 ],
							[ 8.750592238000024, 10.279301554000028 ],
							[ 8.767836182000053, 10.284801104000053 ],
							[ 8.782615945000032, 10.291517787000032 ],
							[ 8.789999262000038, 10.305548280000039 ],
							[ 8.797380343000043, 10.325676625000028 ],
							[ 8.797372505000055, 10.339702246000058 ],
							[ 8.796133421000036, 10.352508379000028 ],
							[ 8.789968889000022, 10.357993236000027 ],
							[ 8.780104597000047, 10.369573804000026 ],
							[ 8.776483362000022, 10.37524075400006 ],
							[ 8.776262335000069, 10.380029958000023 ],
							[ 8.774160187000064, 10.383790206000072 ],
							[ 8.771970570000065, 10.389490177000027 ],
							[ 8.771792310000023, 10.393340297000066 ],
							[ 8.773364139000023, 10.395070290000035 ],
							[ 8.77756138500007, 10.393600340000035 ],
							[ 8.781496147000041, 10.391839736000065 ],
							[ 8.787257155000077, 10.389271001000054 ],
							[ 8.789364988000045, 10.388330271000029 ],
							[ 8.793389218000073, 10.384650132000047 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Rivers',
				admin1Pcod: 'NG033',
				admin1RefN: 'Rivers',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 5.814545,
				Shape_Area: 0.830196
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.643575156000054, 5.701710124000044 ],
							[ 6.648362530000043, 5.699899025000036 ],
							[ 6.652403696000022, 5.699925016000066 ],
							[ 6.661208439000063, 5.701823435000051 ],
							[ 6.671160460000067, 5.704226035000033 ],
							[ 6.669886308000059, 5.696277190000046 ],
							[ 6.668868104000069, 5.689925195000058 ],
							[ 6.658436437000034, 5.624850225000046 ],
							[ 6.639079972000047, 5.493445818000055 ],
							[ 6.64544999900005, 5.492535608000026 ],
							[ 6.652729391000037, 5.490715923000039 ],
							[ 6.712041921000036, 5.489194933000022 ],
							[ 6.715590567000049, 5.489103828000054 ],
							[ 6.719904348000057, 5.490441333000035 ],
							[ 6.722477793000053, 5.491321990000074 ],
							[ 6.726963130000058, 5.491978569000025 ],
							[ 6.729844060000062, 5.493106812000065 ],
							[ 6.733294901000022, 5.493944102000057 ],
							[ 6.736563781000029, 5.493826331000037 ],
							[ 6.738135717000034, 5.491862714000035 ],
							[ 6.73923665500007, 5.489770276000058 ],
							[ 6.739922656000033, 5.487087621000057 ],
							[ 6.741714413000068, 5.481472956000061 ],
							[ 6.742311076000021, 5.479713381000067 ],
							[ 6.742231036000021, 5.47912509300005 ],
							[ 6.741900046000069, 5.478535254000064 ],
							[ 6.740899856000055, 5.47785734100006 ],
							[ 6.739900444000057, 5.477095496000061 ],
							[ 6.739651800000047, 5.476674161000062 ],
							[ 6.739487599000029, 5.476169267000046 ],
							[ 6.739491730000054, 5.475497567000048 ],
							[ 6.739914900000031, 5.474744528000031 ],
							[ 6.741005161000032, 5.474331479000057 ],
							[ 6.743181110000023, 5.474344936000023 ],
							[ 6.749992576000068, 5.476081273000034 ],
							[ 6.751193907000072, 5.464835186000073 ],
							[ 6.743738217000043, 5.458882794000033 ],
							[ 6.74408184300006, 5.449101904000031 ],
							[ 6.749034900000026, 5.438793170000054 ],
							[ 6.750439862000064, 5.43705482300004 ],
							[ 6.762926784000058, 5.433324995000021 ],
							[ 6.772782394000046, 5.43186517700002 ],
							[ 6.777345051000054, 5.428852678000055 ],
							[ 6.777363432000072, 5.425812502000042 ],
							[ 6.777372623000076, 5.424292478000041 ],
							[ 6.770588480000072, 5.418930542000055 ],
							[ 6.762082025000041, 5.411522816000058 ],
							[ 6.762536054000066, 5.410212944000023 ],
							[ 6.760583954000026, 5.401392886000053 ],
							[ 6.758138119000023, 5.389788198000076 ],
							[ 6.758449063000057, 5.377213041000061 ],
							[ 6.759709970000074, 5.36695379400004 ],
							[ 6.760469999000065, 5.353443168000069 ],
							[ 6.757574211000076, 5.342773910000062 ],
							[ 6.755795060000025, 5.323452896000049 ],
							[ 6.763694831000066, 5.312908686000071 ],
							[ 6.765624107000065, 5.308510297000055 ],
							[ 6.770724653000059, 5.300767841000038 ],
							[ 6.777565837000054, 5.293721206000043 ],
							[ 6.786232124000037, 5.284793805000049 ],
							[ 6.794449860000043, 5.27726984100002 ],
							[ 6.803584090000072, 5.26927177500005 ],
							[ 6.811363326000048, 5.264082010000038 ],
							[ 6.818213014000037, 5.257966936000059 ],
							[ 6.82428223200003, 5.254179503000046 ],
							[ 6.827092750000077, 5.252425641000059 ],
							[ 6.830335692000062, 5.251515906000066 ],
							[ 6.833809237000025, 5.250541248000047 ],
							[ 6.83609494600006, 5.249899879000054 ],
							[ 6.848985212000059, 5.249797784000066 ],
							[ 6.866418848000023, 5.243133085000068 ],
							[ 6.875578970000049, 5.237932210000054 ],
							[ 6.87653203800005, 5.236809931000039 ],
							[ 6.883736118000058, 5.236426321000067 ],
							[ 6.89736664000003, 5.235745092000059 ],
							[ 6.925146091000045, 5.225679358000036 ],
							[ 6.926602856000045, 5.226238283000043 ],
							[ 6.928904953000028, 5.226479547000054 ],
							[ 6.931180977000054, 5.226303127000051 ],
							[ 6.934500353000033, 5.226929230000053 ],
							[ 6.938544869000054, 5.227691655000058 ],
							[ 6.941577779000056, 5.228263385000048 ],
							[ 6.944733546000066, 5.22885850800003 ],
							[ 6.947785029000045, 5.229433957000026 ],
							[ 6.951506570000049, 5.230127878000076 ],
							[ 6.954387718000021, 5.230665146000035 ],
							[ 6.956932031000065, 5.23113961200005 ],
							[ 6.959775788000059, 5.23166985000006 ],
							[ 6.96314342900007, 5.232950745000039 ],
							[ 6.965338732000021, 5.233785626000042 ],
							[ 6.967688246000023, 5.234678875000043 ],
							[ 6.971787899000049, 5.236238014000037 ],
							[ 6.975482910000039, 5.235740247000024 ],
							[ 6.978500750000023, 5.235333941000022 ],
							[ 6.980701466000028, 5.235037247000037 ],
							[ 6.982931196000038, 5.234736885000075 ],
							[ 6.986503087000074, 5.234255716000064 ],
							[ 6.990743595000026, 5.236010567000051 ],
							[ 6.993147528000065, 5.237004831000036 ],
							[ 6.99844039900006, 5.239194343000065 ],
							[ 7.001007560000062, 5.239575784000067 ],
							[ 7.003732795000076, 5.239980703000072 ],
							[ 7.006873609000024, 5.240621134000037 ],
							[ 7.008833507000077, 5.241290562000074 ],
							[ 7.012022862000038, 5.242380124000022 ],
							[ 7.014990380000029, 5.241863307000074 ],
							[ 7.019309931000066, 5.243878795000057 ],
							[ 7.021244426000067, 5.244569354000021 ],
							[ 7.023335405000068, 5.245316013000036 ],
							[ 7.028792517000056, 5.246750312000074 ],
							[ 7.032237935000069, 5.247610615000042 ],
							[ 7.035688006000044, 5.248759733000043 ],
							[ 7.039992260000076, 5.249618104000035 ],
							[ 7.042693641000028, 5.249077870000065 ],
							[ 7.045132174000059, 5.248737837000021 ],
							[ 7.047133893000023, 5.247860920000051 ],
							[ 7.049116151000021, 5.246992648000059 ],
							[ 7.053144809000059, 5.245570665000059 ],
							[ 7.055966359000024, 5.244066756000052 ],
							[ 7.057805054000028, 5.242176524000058 ],
							[ 7.059900414000026, 5.24002312500005 ],
							[ 7.062163324000039, 5.240469504000032 ],
							[ 7.06442082600006, 5.239266469000029 ],
							[ 7.066474064000033, 5.237781572000074 ],
							[ 7.070062588000042, 5.236259006000068 ],
							[ 7.071746378000057, 5.235067874000038 ],
							[ 7.074863508000021, 5.233847130000072 ],
							[ 7.076833824000062, 5.232649837000054 ],
							[ 7.081119591000061, 5.230340432000048 ],
							[ 7.083796085000074, 5.228972466000073 ],
							[ 7.087322357000062, 5.228674458000057 ],
							[ 7.089569972000049, 5.227182395000057 ],
							[ 7.092122492000044, 5.226262629000075 ],
							[ 7.093960554000034, 5.225473609000062 ],
							[ 7.096663113000034, 5.226342016000046 ],
							[ 7.102190809000035, 5.228224071000056 ],
							[ 7.107709305000071, 5.23195925400006 ],
							[ 7.112756525000066, 5.237545321000027 ],
							[ 7.115941732000067, 5.246364776000064 ],
							[ 7.117785208000043, 5.246837609000067 ],
							[ 7.121017680000023, 5.246390951000024 ],
							[ 7.124716738000075, 5.245020042000021 ],
							[ 7.131662045000041, 5.240422516000024 ],
							[ 7.138138836000053, 5.237212407000072 ],
							[ 7.148305050000033, 5.234484116000033 ],
							[ 7.153378510000039, 5.234973149000041 ],
							[ 7.157072414000027, 5.234528506000061 ],
							[ 7.16078716100003, 5.229914096000073 ],
							[ 7.165475828000069, 5.215111465000064 ],
							[ 7.166314635000049, 5.212044165000066 ],
							[ 7.168567634000055, 5.211415257000056 ],
							[ 7.17141435000002, 5.210251393000021 ],
							[ 7.174276386000031, 5.210244669000076 ],
							[ 7.179408451000029, 5.208785978000037 ],
							[ 7.182541891000028, 5.207621624000069 ],
							[ 7.185384870000064, 5.206168126000023 ],
							[ 7.18822392200002, 5.204425337000032 ],
							[ 7.190780631000052, 5.202972337000062 ],
							[ 7.19390978000007, 5.201518487000044 ],
							[ 7.196079678000046, 5.200654440000051 ],
							[ 7.198285954000028, 5.199775744000021 ],
							[ 7.200284087000057, 5.199445224000044 ],
							[ 7.206140409000056, 5.197731429000044 ],
							[ 7.210079932000042, 5.196578858000066 ],
							[ 7.213542942000061, 5.199133968000069 ],
							[ 7.217231941000023, 5.199615333000054 ],
							[ 7.224537683000051, 5.195079699000075 ],
							[ 7.226599089000047, 5.194924395000044 ],
							[ 7.229193720000069, 5.194728295000061 ],
							[ 7.231534133000025, 5.194551871000044 ],
							[ 7.242135034000057, 5.195867014000044 ],
							[ 7.251398108000046, 5.201854226000023 ],
							[ 7.252560635000066, 5.204719940000075 ],
							[ 7.256109198000047, 5.213470954000059 ],
							[ 7.260108958000046, 5.213327425000045 ],
							[ 7.263917422000077, 5.215610564000031 ],
							[ 7.272367580000036, 5.201780613000039 ],
							[ 7.27497949800005, 5.199582666000026 ],
							[ 7.277994398000033, 5.196783528000026 ],
							[ 7.280816690000051, 5.192376057000047 ],
							[ 7.283450996000056, 5.185355224000034 ],
							[ 7.284319109000023, 5.170890920000033 ],
							[ 7.283131473000026, 5.168072099000028 ],
							[ 7.277958929000022, 5.161617325000066 ],
							[ 7.272980053000026, 5.156570075000047 ],
							[ 7.268188123000073, 5.154336898000054 ],
							[ 7.26598989300004, 5.153723604000049 ],
							[ 7.264797044000034, 5.152110324000034 ],
							[ 7.265211399000066, 5.14909805800005 ],
							[ 7.26863177000007, 5.145095371000025 ],
							[ 7.280203562000054, 5.131552860000056 ],
							[ 7.278979186000072, 5.11954071200006 ],
							[ 7.278698861000066, 5.116788897000049 ],
							[ 7.271354097000028, 5.10350992900004 ],
							[ 7.270400856000037, 5.102309113000047 ],
							[ 7.26422229800005, 5.094525825000062 ],
							[ 7.256930871000066, 5.079692402000035 ],
							[ 7.255775846000063, 5.077343013000075 ],
							[ 7.252894020000042, 5.064311989000032 ],
							[ 7.25137617200005, 5.049406077000071 ],
							[ 7.251686063000022, 5.033086824000065 ],
							[ 7.248316772000067, 5.017261954000048 ],
							[ 7.245464843000036, 5.007494908000069 ],
							[ 7.241197141000043, 4.994009025000025 ],
							[ 7.235092176000023, 4.981004206000023 ],
							[ 7.218953196000029, 4.978333959000054 ],
							[ 7.207004955000059, 4.980760138000051 ],
							[ 7.192285994000031, 4.982275019000042 ],
							[ 7.185130754000056, 4.982331742000042 ],
							[ 7.189118438000037, 4.978364684000042 ],
							[ 7.191341890000047, 4.97395186500006 ],
							[ 7.193580190000034, 4.966442547000042 ],
							[ 7.194955196000024, 4.955389817000025 ],
							[ 7.194867654000063, 4.952993595000066 ],
							[ 7.181393214000025, 4.949262105000059 ],
							[ 7.168517167000061, 4.944752175000076 ],
							[ 7.159626095000021, 4.935448156000064 ],
							[ 7.150316264000026, 4.92433307500005 ],
							[ 7.143767811000032, 4.913196095000046 ],
							[ 7.14042908600004, 4.900634755000056 ],
							[ 7.143746796000073, 4.895613216000072 ],
							[ 7.14724123600007, 4.890325056000052 ],
							[ 7.155928217000053, 4.883728960000042 ],
							[ 7.164652734000072, 4.881329083000026 ],
							[ 7.173843050000073, 4.879391191000025 ],
							[ 7.185287879000043, 4.872307803000069 ],
							[ 7.191196027000046, 4.863869173000069 ],
							[ 7.205420049000054, 4.858627821000027 ],
							[ 7.215074901000037, 4.857152948000021 ],
							[ 7.230735835000075, 4.857962156000042 ],
							[ 7.240856242000064, 4.856948850000038 ],
							[ 7.257046244000037, 4.865211945000055 ],
							[ 7.271389996000039, 4.87302401900007 ],
							[ 7.281527865000044, 4.873876091000056 ],
							[ 7.294890932000044, 4.875168784000039 ],
							[ 7.307785975000058, 4.875533092000069 ],
							[ 7.319818782000027, 4.879619170000069 ],
							[ 7.326064473000031, 4.876126728000031 ],
							[ 7.330787195000028, 4.873486032000073 ],
							[ 7.334217052000042, 4.873313445000065 ],
							[ 7.340910983000072, 4.872939158000065 ],
							[ 7.352412161000075, 4.871914831000026 ],
							[ 7.360141155000065, 4.866603847000022 ],
							[ 7.36202012900003, 4.865313057000037 ],
							[ 7.373506930000076, 4.862891198000057 ],
							[ 7.392834273000062, 4.861804929000073 ],
							[ 7.406683996000027, 4.865890997000065 ],
							[ 7.41635997000003, 4.866746893000027 ],
							[ 7.429212, 4.862449156000025 ],
							[ 7.436501139000029, 4.85400009600005 ],
							[ 7.446667422000075, 4.845414667000057 ],
							[ 7.455207862000066, 4.845066058000043 ],
							[ 7.460362964000069, 4.845418940000059 ],
							[ 7.466188149000061, 4.845414665000021 ],
							[ 7.471591680000074, 4.842277075000027 ],
							[ 7.474380297000039, 4.836699974000055 ],
							[ 7.478563308000048, 4.833039730000053 ],
							[ 7.486754781000059, 4.827636728000073 ],
							[ 7.487278079000021, 4.824673666000024 ],
							[ 7.488712479000071, 4.821804539000027 ],
							[ 7.49006634400007, 4.819096090000073 ],
							[ 7.492273965000038, 4.815780181000036 ],
							[ 7.494011146000048, 4.814063084000054 ],
							[ 7.496282067000038, 4.812717903000021 ],
							[ 7.498609290000047, 4.810249329000044 ],
							[ 7.501562138000054, 4.807817927000031 ],
							[ 7.506598243000042, 4.804257378000045 ],
							[ 7.510680361000027, 4.800349716000028 ],
							[ 7.516999367000039, 4.793008372000031 ],
							[ 7.521463563000054, 4.788544146000049 ],
							[ 7.525195343000064, 4.779658279000046 ],
							[ 7.52519557200003, 4.769351502000063 ],
							[ 7.524229474000037, 4.763231744000052 ],
							[ 7.523907048000069, 4.75614881000007 ],
							[ 7.523907441000063, 4.753246819000026 ],
							[ 7.526483997000071, 4.748737372000051 ],
							[ 7.530993419000026, 4.743906041000059 ],
							[ 7.536468937000052, 4.739396550000038 ],
							[ 7.541322010000044, 4.736161242000037 ],
							[ 7.543748824000033, 4.734041218000073 ],
							[ 7.545487678000029, 4.732310750000067 ],
							[ 7.547098214000073, 4.726191008000058 ],
							[ 7.545165440000062, 4.719104772000037 ],
							[ 7.542005488000029, 4.711906438000028 ],
							[ 7.540467824000075, 4.708141793000038 ],
							[ 7.53799744500003, 4.705980320000037 ],
							[ 7.536983988000031, 4.701867134000054 ],
							[ 7.53414112300004, 4.699534862000064 ],
							[ 7.530728941000064, 4.69662186100004 ],
							[ 7.527896955000074, 4.693136226000036 ],
							[ 7.525622435000059, 4.690816869000059 ],
							[ 7.520742091000045, 4.684716691000062 ],
							[ 7.517953110000064, 4.676873690000036 ],
							[ 7.518476485000065, 4.669727333000026 ],
							[ 7.520742187000053, 4.662581421000027 ],
							[ 7.523182349000024, 4.657178417000068 ],
							[ 7.52318217100003, 4.649509447000071 ],
							[ 7.519870572000059, 4.640446207000025 ],
							[ 7.519696512000053, 4.631034365000062 ],
							[ 7.521090483000023, 4.626502967000022 ],
							[ 7.524324804000059, 4.622284371000035 ],
							[ 7.525202031000049, 4.618084897000074 ],
							[ 7.526377951000029, 4.615210043000047 ],
							[ 7.527553308000051, 4.612332851000076 ],
							[ 7.528150328000038, 4.610029248000046 ],
							[ 7.528746235000028, 4.607726065000065 ],
							[ 7.529347792000067, 4.604845066000053 ],
							[ 7.530517970000062, 4.602546180000047 ],
							[ 7.531687033000026, 4.600245951000034 ],
							[ 7.532856059000039, 4.597947108000028 ],
							[ 7.534026195000024, 4.59564778500004 ],
							[ 7.535758152000028, 4.59450818800002 ],
							[ 7.537507104000042, 4.591636157000039 ],
							[ 7.539244120000035, 4.58991913400007 ],
							[ 7.542139315000043, 4.587057121000043 ],
							[ 7.543303156000036, 4.585334764000038 ],
							[ 7.54447318800004, 4.583034999000063 ],
							[ 7.545081370000048, 4.579576994000035 ],
							[ 7.543962222000062, 4.57668303600002 ],
							[ 7.542843647000041, 4.573788168000021 ],
							[ 7.541727338000044, 4.570892824000055 ],
							[ 7.538910940000051, 4.565676214000064 ],
							[ 7.53663487700004, 4.563926227000024 ],
							[ 7.534931953000068, 4.562181013000043 ],
							[ 7.532568660000038, 4.562876221000067 ],
							[ 7.529918042000077, 4.561220174000027 ],
							[ 7.527268540000023, 4.560557364000033 ],
							[ 7.524617913000043, 4.556251054000029 ],
							[ 7.523956079000072, 4.550619614000027 ],
							[ 7.526474937000046, 4.547684171000071 ],
							[ 7.52764495200006, 4.545384865000074 ],
							[ 7.529382368000029, 4.543667778000042 ],
							[ 7.53113055700004, 4.540795808000041 ],
							[ 7.532874170000071, 4.538502224000069 ],
							[ 7.534617196000056, 4.536207184000034 ],
							[ 7.53635508800005, 4.534490102000063 ],
							[ 7.537518237000029, 4.53276777800005 ],
							[ 7.539834274000043, 4.530477977000032 ],
							[ 7.541653349000057, 4.528679836000038 ],
							[ 7.546547744000065, 4.520805829000039 ],
							[ 7.552378161000036, 4.514445286000068 ],
							[ 7.559268567000061, 4.510947258000044 ],
							[ 7.568478177000031, 4.507408146000046 ],
							[ 7.573728837000033, 4.505969059000051 ],
							[ 7.580324988000029, 4.502052306000053 ],
							[ 7.587333648000026, 4.494837760000053 ],
							[ 7.590631859000041, 4.490302559000042 ],
							[ 7.592693426000039, 4.484324453000056 ],
							[ 7.593930454000031, 4.477109445000053 ],
							[ 7.595991411000057, 4.467421069000068 ],
							[ 7.597398291000047, 4.464844716000073 ],
							[ 7.583636094000042, 4.45459081100006 ],
							[ 7.581543187000022, 4.451933841000027 ],
							[ 7.570678464000025, 4.449697022000066 ],
							[ 7.559175254000024, 4.451614373000041 ],
							[ 7.544796254000062, 4.452572831000055 ],
							[ 7.534890847000042, 4.45225335300006 ],
							[ 7.522747782000067, 4.452572843000041 ],
							[ 7.508688618000065, 4.452892290000023 ],
							[ 7.499765854000032, 4.450190548000023 ],
							[ 7.489161445000036, 4.448985552000067 ],
							[ 7.477592996000055, 4.446334357000069 ],
							[ 7.461727028000041, 4.443542981000064 ],
							[ 7.449394320000067, 4.439586164000048 ],
							[ 7.442863442000032, 4.439152227000022 ],
							[ 7.436620650000066, 4.437417008000068 ],
							[ 7.429123519000029, 4.437554829000021 ],
							[ 7.416616550000072, 4.433560847000024 ],
							[ 7.414206413000045, 4.429222604000074 ],
							[ 7.396849693000036, 4.43691538500002 ],
							[ 7.380653834000043, 4.439057819000027 ],
							[ 7.371727576000069, 4.440990465000027 ],
							[ 7.367691085000047, 4.440550324000071 ],
							[ 7.350579152000023, 4.441031928000029 ],
							[ 7.339733714000033, 4.441996076000066 ],
							[ 7.329610793000029, 4.446575635000045 ],
							[ 7.324562076000063, 4.447428218000027 ],
							[ 7.318283563000023, 4.437175751000041 ],
							[ 7.32345401300006, 4.425831802000062 ],
							[ 7.326147067000022, 4.422240757000054 ],
							[ 7.327044925000052, 4.418649189000064 ],
							[ 7.320311421000042, 4.415956016000052 ],
							[ 7.315822191000052, 4.414608961000056 ],
							[ 7.304150649000064, 4.411915761000046 ],
							[ 7.292030332000024, 4.406528947000027 ],
							[ 7.285011319000034, 4.404642110000054 ],
							[ 7.271271167000066, 4.400807844000042 ],
							[ 7.26072643200007, 4.397292615000026 ],
							[ 7.256567078000046, 4.396653167000068 ],
							[ 7.250282220000031, 4.394408689000045 ],
							[ 7.247588582000049, 4.394408696000028 ],
							[ 7.230979481000077, 4.386776914000052 ],
							[ 7.223565622000024, 4.384394154000063 ],
							[ 7.213472337000042, 4.381839269000068 ],
							[ 7.197777408000036, 4.379091746000029 ],
							[ 7.184280544000046, 4.379091753000068 ],
							[ 7.175845134000042, 4.37957384200007 ],
							[ 7.163794473000053, 4.380296694000037 ],
							[ 7.152225481000073, 4.382225063000021 ],
							[ 7.144995115000029, 4.382225016000064 ],
							[ 7.13895426800002, 4.381390071000055 ],
							[ 7.135113759000035, 4.382465818000071 ],
							[ 7.132703780000043, 4.385358334000045 ],
							[ 7.131771513000047, 4.392163756000059 ],
							[ 7.13222158800005, 4.402710918000025 ],
							[ 7.134873035000055, 4.407290441000043 ],
							[ 7.137524170000063, 4.412833699000032 ],
							[ 7.142585284000063, 4.418376943000055 ],
							[ 7.15062570200007, 4.424933894000048 ],
							[ 7.157359029000077, 4.433463090000032 ],
							[ 7.163643837000052, 4.441543567000053 ],
							[ 7.169657738000069, 4.451294907000033 ],
							[ 7.158036222000021, 4.462734198000021 ],
							[ 7.14972769700006, 4.453663815000027 ],
							[ 7.139402819000054, 4.451868038000043 ],
							[ 7.132669394000061, 4.459948516000054 ],
							[ 7.128629269000044, 4.46084641300007 ],
							[ 7.123063128000069, 4.462241199000061 ],
							[ 7.114868520000073, 4.462482450000039 ],
							[ 7.110672829000066, 4.458152790000042 ],
							[ 7.110672959000055, 4.452317224000069 ],
							[ 7.108120539000026, 4.44753933800007 ],
							[ 7.096069933000024, 4.450672603000044 ],
							[ 7.086429033000059, 4.454770103000044 ],
							[ 7.072338119000051, 4.455954080000026 ],
							[ 7.077903228000025, 4.451868035000075 ],
							[ 7.079228374000024, 4.448738580000054 ],
							[ 7.077454146000036, 4.446481256000027 ],
							[ 7.07148638700005, 4.441755269000055 ],
							[ 7.064979046000076, 4.440068256000075 ],
							[ 7.060640673000023, 4.438139899000021 ],
							[ 7.055820504000053, 4.437417018000076 ],
							[ 7.049795188000076, 4.437175731000025 ],
							[ 7.039190836000046, 4.434765836000054 ],
							[ 7.030755099000032, 4.433560856000042 ],
							[ 7.024483334000024, 4.430320749000032 ],
							[ 7.014607428000033, 4.425383074000024 ],
							[ 7.01236293200003, 4.41371155500002 ],
							[ 7.020892208000021, 4.409222112000066 ],
							[ 7.027176820000022, 4.401590802000044 ],
							[ 7.021790079000027, 4.396653185000048 ],
							[ 7.02134121000006, 4.393061645000046 ],
							[ 7.013260800000069, 4.385879508000073 ],
							[ 7.006527497000036, 4.380043502000035 ],
							[ 7.001140491000058, 4.377799017000029 ],
							[ 6.99440671900004, 4.375105372000064 ],
							[ 6.985877543000072, 4.377350342000057 ],
							[ 6.976899679000041, 4.384532474000025 ],
							[ 6.969922193000059, 4.383572130000061 ],
							[ 6.974655096000049, 4.378247758000043 ],
							[ 6.976451022000049, 4.373310094000033 ],
							[ 6.969268323000051, 4.37375879700005 ],
							[ 6.958494678000022, 4.374656682000023 ],
							[ 6.947271731000058, 4.376452432000065 ],
							[ 6.940089070000056, 4.380492687000071 ],
							[ 6.920786262000036, 4.38587949500004 ],
							[ 6.910461896000072, 4.389470603000063 ],
							[ 6.898341210000069, 4.392612926000027 ],
							[ 6.892954461000045, 4.393061639000052 ],
							[ 6.886221029000069, 4.391715040000065 ],
							[ 6.874549346000038, 4.395306121000033 ],
							[ 6.85815913600004, 4.396111975000053 ],
							[ 6.85659314000003, 4.383185873000059 ],
							[ 6.863348897000037, 4.375936041000045 ],
							[ 6.865815220000059, 4.375113965000025 ],
							[ 6.872304904000032, 4.370167736000042 ],
							[ 6.87622774600004, 4.366071712000064 ],
							[ 6.877049802000045, 4.361413507000066 ],
							[ 6.875679495000043, 4.359220975000028 ],
							[ 6.871569709000028, 4.357576828000049 ],
							[ 6.86280122900007, 4.358125205000022 ],
							[ 6.854306120000047, 4.357576870000059 ],
							[ 6.845819418000076, 4.358047507000038 ],
							[ 6.835943662000034, 4.357598286000041 ],
							[ 6.820026860000041, 4.354922773000055 ],
							[ 6.809009615000036, 4.352660190000051 ],
							[ 6.799271924000038, 4.350892071000033 ],
							[ 6.784052864000046, 4.348215106000055 ],
							[ 6.766620640000042, 4.347986227000035 ],
							[ 6.760044558000061, 4.347712492000028 ],
							[ 6.753742331000069, 4.348808272000042 ],
							[ 6.747509465000064, 4.349069103000033 ],
							[ 6.737027240000032, 4.350726589000033 ],
							[ 6.726287792000051, 4.355197414000031 ],
							[ 6.723094487000026, 4.357533943000021 ],
							[ 6.708608105000053, 4.34810974800007 ],
							[ 6.708529886000065, 4.345695479000028 ],
							[ 6.709153305000029, 4.343514451000033 ],
							[ 6.70697268300006, 4.340632925000023 ],
							[ 6.701130760000069, 4.339542370000061 ],
							[ 6.696691513000076, 4.339464668000062 ],
							[ 6.692252040000028, 4.338841418000072 ],
							[ 6.686644693000062, 4.338997363000033 ],
							[ 6.683918347000031, 4.338763720000031 ],
							[ 6.680569044000038, 4.338296410000055 ],
							[ 6.673181141000043, 4.337847705000058 ],
							[ 6.664138163000075, 4.337025649000054 ],
							[ 6.661768864000067, 4.33694887300004 ],
							[ 6.653688403000046, 4.333357342000056 ],
							[ 6.649648083000045, 4.32797049900006 ],
							[ 6.649199380000027, 4.321237083000028 ],
							[ 6.644409033000045, 4.318666443000041 ],
							[ 6.639487638000048, 4.317063307000069 ],
							[ 6.633936941000059, 4.31854343200007 ],
							[ 6.631692371000042, 4.322583669000039 ],
							[ 6.628100963000065, 4.326175212000066 ],
							[ 6.62577587800007, 4.32853125400004 ],
							[ 6.621939624000049, 4.332367409000028 ],
							[ 6.615980738000076, 4.337397554000063 ],
							[ 6.605761029000064, 4.335641871000064 ],
							[ 6.601829683000062, 4.334844726000028 ],
							[ 6.595960687000058, 4.346147274000032 ],
							[ 6.589958547000037, 4.357706607000068 ],
							[ 6.586623945000042, 4.359690277000027 ],
							[ 6.582429672000046, 4.362185296000064 ],
							[ 6.572655872000041, 4.365894123000032 ],
							[ 6.560587202000022, 4.376380177000044 ],
							[ 6.552222639000036, 4.39368369500005 ],
							[ 6.551259239000046, 4.400161002000061 ],
							[ 6.549867443000039, 4.409517992000076 ],
							[ 6.547496962000025, 4.427616445000069 ],
							[ 6.551163630000076, 4.440649791000055 ],
							[ 6.554091944000049, 4.451058622000062 ],
							[ 6.563738489000059, 4.466973415000041 ],
							[ 6.575680949000059, 4.476110319000043 ],
							[ 6.578666666000061, 4.478394498000057 ],
							[ 6.588290293000057, 4.498082280000062 ],
							[ 6.599307121000038, 4.534383826000067 ],
							[ 6.602916128000061, 4.556295753000029 ],
							[ 6.602881768000032, 4.561578905000033 ],
							[ 6.596800444000053, 4.573615036000035 ],
							[ 6.581659807000051, 4.593893458000025 ],
							[ 6.566477623000026, 4.620209051000074 ],
							[ 6.564756458000033, 4.62727048000005 ],
							[ 6.562620498000058, 4.636033264000048 ],
							[ 6.562200294000036, 4.640178518000027 ],
							[ 6.561014770000043, 4.651872605000051 ],
							[ 6.561381137000069, 4.657120089000045 ],
							[ 6.561700123000037, 4.661689093000064 ],
							[ 6.562684434000062, 4.67402077600002 ],
							[ 6.563086471000076, 4.679057880000073 ],
							[ 6.564538758000026, 4.681528940000021 ],
							[ 6.567539851000049, 4.686635255000056 ],
							[ 6.570113197000069, 4.691517846000067 ],
							[ 6.572729429000049, 4.696481774000063 ],
							[ 6.577980166000032, 4.702214123000033 ],
							[ 6.58316333700003, 4.707872623000071 ],
							[ 6.592882007000071, 4.713975033000054 ],
							[ 6.596590891000062, 4.720792374000041 ],
							[ 6.597178154000062, 4.724484541000038 ],
							[ 6.598032991000025, 4.729859005000037 ],
							[ 6.588996217000044, 4.73338714700003 ],
							[ 6.583541075000028, 4.734106836000024 ],
							[ 6.583541002000061, 4.73410683700007 ],
							[ 6.581958628000052, 4.733942269000067 ],
							[ 6.577718654000023, 4.73350128900006 ],
							[ 6.568485950000024, 4.737030097000059 ],
							[ 6.564707177000059, 4.740028283000072 ],
							[ 6.56110734300006, 4.744350402000066 ],
							[ 6.561107326000069, 4.744350421000036 ],
							[ 6.560520965000023, 4.747747827000069 ],
							[ 6.560866727000075, 4.752285268000037 ],
							[ 6.560086333000072, 4.756626072000074 ],
							[ 6.560086313000056, 4.756626184000027 ],
							[ 6.560086142000046, 4.75662636900006 ],
							[ 6.558354701000042, 4.758495523000022 ],
							[ 6.552611951000074, 4.758596881000074 ],
							[ 6.537438875000021, 4.755611901000066 ],
							[ 6.527311746000066, 4.753003140000033 ],
							[ 6.523535940000045, 4.75072559900002 ],
							[ 6.517582007000044, 4.747134172000074 ],
							[ 6.513673766000068, 4.743821540000056 ],
							[ 6.510599994000074, 4.741216179000048 ],
							[ 6.501528294000025, 4.734480056000052 ],
							[ 6.498985761000029, 4.73259209500003 ],
							[ 6.489644139000063, 4.722999094000045 ],
							[ 6.484190041000033, 4.718555336000065 ],
							[ 6.478478833000054, 4.713902021000024 ],
							[ 6.461059938000062, 4.713280215000054 ],
							[ 6.459428911000032, 4.723997094000026 ],
							[ 6.445941947000051, 4.730044812000074 ],
							[ 6.441421939000065, 4.745329842000046 ],
							[ 6.429982289000066, 4.76587390800006 ],
							[ 6.42286383000004, 4.777152034000039 ],
							[ 6.412812170000052, 4.802886966000074 ],
							[ 6.408864901000072, 4.812250166000069 ],
							[ 6.402275130000021, 4.827237141000069 ],
							[ 6.399278997000067, 4.838441870000054 ],
							[ 6.398606663000066, 4.851465241000028 ],
							[ 6.398394078000024, 4.864480033000063 ],
							[ 6.400884182000027, 4.875124 ],
							[ 6.406606140000065, 4.887104045000058 ],
							[ 6.414565935000041, 4.896256907000065 ],
							[ 6.420719172000076, 4.906836953000038 ],
							[ 6.425559889000056, 4.920691026000043 ],
							[ 6.433986244000039, 4.930301160000056 ],
							[ 6.440640877000021, 4.943194856000048 ],
							[ 6.440402, 4.954815865000057 ],
							[ 6.437415137000073, 4.966485961000046 ],
							[ 6.430676116000029, 4.973575129000039 ],
							[ 6.421666074000029, 4.981634116000066 ],
							[ 6.41039192300002, 4.991127041000027 ],
							[ 6.411561096000071, 5.004581920000021 ],
							[ 6.417274030000044, 5.016098009000075 ],
							[ 6.425769755000033, 5.029424203000076 ],
							[ 6.429607929000042, 5.038650064000024 ],
							[ 6.439036777000069, 5.052887901000076 ],
							[ 6.443903895000062, 5.068137169000067 ],
							[ 6.451053017000049, 5.082880021000051 ],
							[ 6.46049877400003, 5.098048190000043 ],
							[ 6.46433685300002, 5.107274080000025 ],
							[ 6.472981060000052, 5.128497111000058 ],
							[ 6.477923943000064, 5.137354643000037 ],
							[ 6.476906276000022, 5.147798629000022 ],
							[ 6.476175589000036, 5.166035563000037 ],
							[ 6.476735290000022, 5.172728119000055 ],
							[ 6.47913236200003, 5.176393121000046 ],
							[ 6.48333737300004, 5.18128667000002 ],
							[ 6.494805102000043, 5.186838139000031 ],
							[ 6.505704386000048, 5.186912822000068 ],
							[ 6.534927555000024, 5.186825317000057 ],
							[ 6.544492122000065, 5.186294112000041 ],
							[ 6.553381041000023, 5.189809867000065 ],
							[ 6.558826444000033, 5.198422927000024 ],
							[ 6.555801570000028, 5.202908291000028 ],
							[ 6.549229987000047, 5.212652128000059 ],
							[ 6.552762079000047, 5.226540550000038 ],
							[ 6.566636039000059, 5.236972756000057 ],
							[ 6.547786345000077, 5.257751878000022 ],
							[ 6.53738488700003, 5.264905889000033 ],
							[ 6.532513007000034, 5.273820874000023 ],
							[ 6.530881853000039, 5.284537818000047 ],
							[ 6.541174890000036, 5.295972834000054 ],
							[ 6.548624035000046, 5.30234719300006 ],
							[ 6.555650307000064, 5.310586968000052 ],
							[ 6.563160091000043, 5.32021287200007 ],
							[ 6.567076170000064, 5.333620115000031 ],
							[ 6.568703251000045, 5.347066905000077 ],
							[ 6.569457923000073, 5.362852983000039 ],
							[ 6.569632174000049, 5.372142817000054 ],
							[ 6.561977843000022, 5.379249111000036 ],
							[ 6.552827764000028, 5.379875148000053 ],
							[ 6.540015304000065, 5.380566169000076 ],
							[ 6.528549249000037, 5.379838874000029 ],
							[ 6.518475161000026, 5.380016805000025 ],
							[ 6.500598009000043, 5.379403115000059 ],
							[ 6.485476966000022, 5.379205287000048 ],
							[ 6.476556689000063, 5.404050860000041 ],
							[ 6.483125094000059, 5.420644217000074 ],
							[ 6.48934801300004, 5.422718520000046 ],
							[ 6.494187330000045, 5.425138013000037 ],
							[ 6.499718659000052, 5.426520825000068 ],
							[ 6.505941378000045, 5.425829342000043 ],
							[ 6.508820501000059, 5.425673928000037 ],
							[ 6.512404374000027, 5.428660405000073 ],
							[ 6.517558185000041, 5.434420059000047 ],
							[ 6.524530382000023, 5.438663960000042 ],
							[ 6.534836751000057, 5.458974368000042 ],
							[ 6.536656005000054, 5.469584009000073 ],
							[ 6.538163688000054, 5.475804358000062 ],
							[ 6.537696893000032, 5.478424987000039 ],
							[ 6.536935738000068, 5.491936270000053 ],
							[ 6.533251165000024, 5.509833761000039 ],
							[ 6.530831349000039, 5.531958621000058 ],
							[ 6.542584971000053, 5.549243462000049 ],
							[ 6.555376038000077, 5.552354768000043 ],
							[ 6.568858294000052, 5.553391468000029 ],
							[ 6.579228776000036, 5.559268538000026 ],
							[ 6.584760123000024, 5.561688428000025 ],
							[ 6.587871672000063, 5.567911110000068 ],
							[ 6.591673857000046, 5.574479108000048 ],
							[ 6.593402364000042, 5.580010427000047 ],
							[ 6.596167947000026, 5.587615466000045 ],
							[ 6.601007801000037, 5.596949593000033 ],
							[ 6.602045135000026, 5.607666030000075 ],
							[ 6.603773667000041, 5.614925921000065 ],
							[ 6.608144777000064, 5.62125486900004 ],
							[ 6.613107152000055, 5.62667936400004 ],
							[ 6.617601506000028, 5.631519319000063 ],
							[ 6.621346011000071, 5.642564747000051 ],
							[ 6.617293701000051, 5.651000048000071 ],
							[ 6.61232512600003, 5.659912115000054 ],
							[ 6.608761396000034, 5.666173423000032 ],
							[ 6.606450136000035, 5.670234126000025 ],
							[ 6.606566810000061, 5.680008817000044 ],
							[ 6.614049524000052, 5.690699538000047 ],
							[ 6.613452776000031, 5.702041206000047 ],
							[ 6.61829282900004, 5.716906120000033 ],
							[ 6.626148363000027, 5.723635495000053 ],
							[ 6.627666925000028, 5.718920954000055 ],
							[ 6.632492589000037, 5.711216274000037 ],
							[ 6.643575156000054, 5.701710124000044 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Sokoto',
				admin1Pcod: 'NG034',
				admin1RefN: 'Sokoto',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 10.503159,
				Shape_Area: 2.679547
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 5.514638016000049, 13.894419133000042 ],
							[ 5.529122014000052, 13.892298696000069 ],
							[ 5.536184838000054, 13.891264856000078 ],
							[ 5.559834093000063, 13.878127173000053 ],
							[ 5.590840463000063, 13.862360468000077 ],
							[ 5.610285370000042, 13.855003392000071 ],
							[ 5.642343092000033, 13.843441651000035 ],
							[ 5.667043268000043, 13.830303250000043 ],
							[ 5.683334726000055, 13.825572817000022 ],
							[ 5.699100846000022, 13.820318434000058 ],
							[ 5.721435891000056, 13.80717928200005 ],
							[ 5.748764142000027, 13.794566994000036 ],
							[ 5.779245292000041, 13.78510619900004 ],
							[ 5.840207666000026, 13.76303504200007 ],
							[ 5.868586612000058, 13.754625966000049 ],
							[ 5.894337576000055, 13.744641612000066 ],
							[ 5.921139717000074, 13.737809670000047 ],
							[ 5.953197612000054, 13.72467001900003 ],
							[ 5.987883027000066, 13.713109161000034 ],
							[ 6.005751150000037, 13.708378545000073 ],
							[ 6.013111752000043, 13.705434275000073 ],
							[ 6.015171494000072, 13.70590947200003 ],
							[ 6.04369057100007, 13.697195616000045 ],
							[ 6.065079659000048, 13.686897194000039 ],
							[ 6.088845652000032, 13.676598009000031 ],
							[ 6.125286716000062, 13.653625330000068 ],
							[ 6.146709203000057, 13.643282953000039 ],
							[ 6.16639630700007, 13.653696475000061 ],
							[ 6.219201595000072, 13.67374269000004 ],
							[ 6.266393742000048, 13.679705240000033 ],
							[ 6.295042800000033, 13.677106048000041 ],
							[ 6.336688140000035, 13.659159161000048 ],
							[ 6.441623518000029, 13.596733374000053 ],
							[ 6.484840434000034, 13.556323573000043 ],
							[ 6.584271219000073, 13.453116875000035 ],
							[ 6.604457174000061, 13.429386289000036 ],
							[ 6.613308163000056, 13.417150509000066 ],
							[ 6.641006868000034, 13.389823682000042 ],
							[ 6.666914075000022, 13.362653644000034 ],
							[ 6.673486635000074, 13.355759467000041 ],
							[ 6.676665295000021, 13.354073725000035 ],
							[ 6.683172600000034, 13.346752990000027 ],
							[ 6.694560633000037, 13.325604355000053 ],
							[ 6.709201827000072, 13.30445555600005 ],
							[ 6.720589178000068, 13.280054264000057 ],
							[ 6.735230450000074, 13.258905501000072 ],
							[ 6.762545497000076, 13.210149521000062 ],
							[ 6.773054127000023, 13.191391791000058 ],
							[ 6.786068508000028, 13.174310720000051 ],
							[ 6.792541934000042, 13.161023111000077 ],
							[ 6.783568327000069, 13.153952692000075 ],
							[ 6.775954274000071, 13.147481106000043 ],
							[ 6.76795899900003, 13.136059819000025 ],
							[ 6.751588351000066, 13.126922887000035 ],
							[ 6.742070676000026, 13.117023414000073 ],
							[ 6.725699815000041, 13.112075003000029 ],
							[ 6.709710122000047, 13.105602278000049 ],
							[ 6.697527032000039, 13.099130905000038 ],
							[ 6.682679117000021, 13.083901656000023 ],
							[ 6.669354517000045, 13.081617571000038 ],
							[ 6.66608915200004, 13.080586453000024 ],
							[ 6.662120908000077, 13.079333399000063 ],
							[ 6.660693262000052, 13.077429598000037 ],
							[ 6.655344200000059, 13.077429906000077 ],
							[ 6.654125652000062, 13.077429593000033 ],
							[ 6.640039578000028, 13.070196331000034 ],
							[ 6.630902151000043, 13.070196030000034 ],
							[ 6.622907234000024, 13.066769578000049 ],
							[ 6.607297928000037, 13.066769749000059 ],
							[ 6.593592038000054, 13.070576924000022 ],
							[ 6.584836031000066, 13.075145588000055 ],
							[ 6.570368736000034, 13.075906970000062 ],
							[ 6.566224439000052, 13.075907070000028 ],
							[ 6.545303453000031, 13.081118933000027 ],
							[ 6.528455071000053, 13.084762921000049 ],
							[ 6.514644173000022, 13.091430793000029 ],
							[ 6.503912568000032, 13.095114383000066 ],
							[ 6.466368719000059, 13.105376146000026 ],
							[ 6.442609147000042, 13.112725044000058 ],
							[ 6.429676462000032, 13.118123533000073 ],
							[ 6.421319015000051, 13.123496174000024 ],
							[ 6.406090687000074, 13.12539957000007 ],
							[ 6.399618504000046, 13.131490447000033 ],
							[ 6.394288570000072, 13.138343977000034 ],
							[ 6.383628495000039, 13.145196812000052 ],
							[ 6.371445709000056, 13.149765245000026 ],
							[ 6.361927948000073, 13.152430722000076 ],
							[ 6.355074940000065, 13.153571986000031 ],
							[ 6.34593779100004, 13.152811064000048 ],
							[ 6.328044290000037, 13.156618224000056 ],
							[ 6.317446193000023, 13.158230806000063 ],
							[ 6.314083137000068, 13.156839435000052 ],
							[ 6.307866691000072, 13.157760692000068 ],
							[ 6.300252466000074, 13.157760464000035 ],
							[ 6.289592182000035, 13.158902333000071 ],
							[ 6.282739658000025, 13.156237891000046 ],
							[ 6.270175916000028, 13.156237692000047 ],
							[ 6.264845815000058, 13.149384746000067 ],
							[ 6.261800337000068, 13.139486453000075 ],
							[ 6.260277138000049, 13.131490778000057 ],
							[ 6.260277341000062, 13.121644768000067 ],
							[ 6.257699961000071, 13.101098759000024 ],
							[ 6.261834133000036, 13.090074657000059 ],
							[ 6.260759405000044, 13.085417748000054 ],
							[ 6.259767133000025, 13.081116787000042 ],
							[ 6.25916612900005, 13.06970308800004 ],
							[ 6.25864585100004, 13.066005003000043 ],
							[ 6.255632959000025, 13.044597519000035 ],
							[ 6.254254722000042, 13.031505782000067 ],
							[ 6.245296992000021, 13.028060142000072 ],
							[ 6.219802346000051, 13.042529911000031 ],
							[ 6.20533806100002, 13.05532600600003 ],
							[ 6.201887750000026, 13.058378030000029 ],
							[ 6.188795679000066, 13.065957906000051 ],
							[ 6.166056988000037, 13.068714 ],
							[ 6.151587576000054, 13.074914919000037 ],
							[ 6.131605215000036, 13.072159004000071 ],
							[ 6.101976347000061, 13.059756244000027 ],
							[ 6.072347620000073, 13.039773840000066 ],
							[ 6.041340343000059, 13.02805986900006 ],
							[ 6.027902467000047, 13.021341275000054 ],
							[ 6.020317448000071, 13.008504691000041 ],
							[ 6.024677739000026, 12.992933553000057 ],
							[ 6.02467768300005, 12.979966531000059 ],
							[ 6.026084495000021, 12.970823084000074 ],
							[ 6.030761240000061, 12.960928294000041 ],
							[ 6.038268695000056, 12.942026100000021 ],
							[ 6.040136235000034, 12.938429683000038 ],
							[ 6.040195632000064, 12.93120650700007 ],
							[ 6.035675680000054, 12.922141317000069 ],
							[ 6.023812957000075, 12.912114145000032 ],
							[ 6.01009048700007, 12.904781229000037 ],
							[ 6.010120484000026, 12.901169634000041 ],
							[ 6.007987012000058, 12.885112543000048 ],
							[ 6.009625869000047, 12.875687337000045 ],
							[ 6.017944004000071, 12.836077919000047 ],
							[ 6.025865974000055, 12.813104575000068 ],
							[ 6.032203767000055, 12.786961361000067 ],
							[ 6.045502086000056, 12.754116163000049 ],
							[ 6.042685575000064, 12.750787023000044 ],
							[ 6.035686937000037, 12.742388456000072 ],
							[ 6.020989763000046, 12.736789879000071 ],
							[ 6.004193218000069, 12.742388558000073 ],
							[ 5.987396610000076, 12.754286511000032 ],
							[ 5.967101091000075, 12.761284609000029 ],
							[ 5.951004467000075, 12.76268488900007 ],
							[ 5.923010385000055, 12.771083070000032 ],
							[ 5.89501585000005, 12.787179726000033 ],
							[ 5.878219589000025, 12.803976165000051 ],
							[ 5.853024401000027, 12.819373316000053 ],
							[ 5.83762787400002, 12.834070136000037 ],
							[ 5.813132632000077, 12.839669393000065 ],
							[ 5.79423657500007, 12.835469490000037 ],
							[ 5.773864478000064, 12.831148511000038 ],
							[ 5.772380832000067, 12.830833958000028 ],
							[ 5.771073322000063, 12.830523203000041 ],
							[ 5.740876580000077, 12.823343393000073 ],
							[ 5.722620365000068, 12.814170267000065 ],
							[ 5.708949119000067, 12.805036192000046 ],
							[ 5.705156387000045, 12.801998707000052 ],
							[ 5.700647788000026, 12.793696620000048 ],
							[ 5.696916971000064, 12.783899100000042 ],
							[ 5.68944958000003, 12.765055836000045 ],
							[ 5.686766682000041, 12.734431676000042 ],
							[ 5.685915194000074, 12.732791312000074 ],
							[ 5.68508020400003, 12.73132341400003 ],
							[ 5.68626739900003, 12.729705145000025 ],
							[ 5.685353869000039, 12.727065347000064 ],
							[ 5.686052367000059, 12.724690167000063 ],
							[ 5.68597561200005, 12.72144707800004 ],
							[ 5.685791565000045, 12.713689971000065 ],
							[ 5.686458910000056, 12.708795286000054 ],
							[ 5.694857571000057, 12.687100649000058 ],
							[ 5.700456207000059, 12.668204210000056 ],
							[ 5.701625273000047, 12.664363669000068 ],
							[ 5.705355036000071, 12.652107189000048 ],
							[ 5.71235378700004, 12.636011114000041 ],
							[ 5.722151773000064, 12.605917222000073 ],
							[ 5.728450341000041, 12.590520871000024 ],
							[ 5.733349385000054, 12.560426469000049 ],
							[ 5.745246751000025, 12.531032743000026 ],
							[ 5.75277135500005, 12.506407973000023 ],
							[ 5.750875237000059, 12.48055567800003 ],
							[ 5.750971829000036, 12.469840960000056 ],
							[ 5.753985714000066, 12.46499648300005 ],
							[ 5.758085774000051, 12.449445845000071 ],
							[ 5.761169412000072, 12.436809009000058 ],
							[ 5.764753631000076, 12.423872532000075 ],
							[ 5.761343509000028, 12.367266878000066 ],
							[ 5.759012330000076, 12.343367732000047 ],
							[ 5.753468877000046, 12.339570112000047 ],
							[ 5.741840749000062, 12.332352395000044 ],
							[ 5.74379536400005, 12.325512160000073 ],
							[ 5.738948358000073, 12.308478627000056 ],
							[ 5.737215358000071, 12.304022576000023 ],
							[ 5.730214786000033, 12.286021380000022 ],
							[ 5.729150248000053, 12.283284218000063 ],
							[ 5.725650841000061, 12.27488640200005 ],
							[ 5.726082362000056, 12.271005851000041 ],
							[ 5.710318567000058, 12.267368356000077 ],
							[ 5.683008767000047, 12.277058575000069 ],
							[ 5.673317841000028, 12.28851099700006 ],
							[ 5.653055580000057, 12.292035130000045 ],
							[ 5.631031556000039, 12.288511383000071 ],
							[ 5.612531131000026, 12.274416231000032 ],
							[ 5.584339964000037, 12.272653369000068 ],
							[ 5.583301811000069, 12.271614985000042 ],
							[ 5.576212060000046, 12.272524428000054 ],
							[ 5.541054318000022, 12.272524163000071 ],
							[ 5.523977666000064, 12.261474664000048 ],
							[ 5.475760976000061, 12.252434228000027 ],
							[ 5.437589661000061, 12.253438599000049 ],
							[ 5.410204987000043, 12.259103979000031 ],
							[ 5.408458701000029, 12.259465664000061 ],
							[ 5.38234165700004, 12.262478938000072 ],
							[ 5.35019729000004, 12.266496923000034 ],
							[ 5.327093467000054, 12.272524162000025 ],
							[ 5.292940207000072, 12.278551386000061 ],
							[ 5.272849747000066, 12.281564995000053 ],
							[ 5.27124575800002, 12.281629166000073 ],
							[ 5.270397696000032, 12.281663046000062 ],
							[ 5.266189712000028, 12.28507978600004 ],
							[ 5.247388934000071, 12.295013844000039 ],
							[ 5.241751355000076, 12.297717560000024 ],
							[ 5.231401219000077, 12.30405504600003 ],
							[ 5.21453602400004, 12.307564871000068 ],
							[ 5.188390057000049, 12.30453738500006 ],
							[ 5.178175334000059, 12.297990454000058 ],
							[ 5.171729562000053, 12.288721394000049 ],
							[ 5.166921911000031, 12.284578460000034 ],
							[ 5.161890634000031, 12.284578014000033 ],
							[ 5.16196055000006, 12.283562849000077 ],
							[ 5.162033879000035, 12.282847184000047 ],
							[ 5.147286128000076, 12.281564778000075 ],
							[ 5.121233396000036, 12.278869688000043 ],
							[ 5.118155306000062, 12.278551122000067 ],
							[ 5.068934323000065, 12.277546811000036 ],
							[ 5.047839398000065, 12.284578039000053 ],
							[ 5.024735784000029, 12.290605227000071 ],
							[ 4.997614001000045, 12.300650185000052 ],
							[ 4.981541618000051, 12.300650436000069 ],
							[ 4.972501179000062, 12.29261434700004 ],
							[ 4.956428996000056, 12.270514889000026 ],
							[ 4.951128570000037, 12.249313481000058 ],
							[ 4.950401929000066, 12.246407056000066 ],
							[ 4.950401966000072, 12.231339373000026 ],
							[ 4.952410878000023, 12.218280655000058 ],
							[ 4.954419977000043, 12.19718576300005 ],
							[ 4.956448923000039, 12.172162523000054 ],
							[ 4.957433490000028, 12.16001921700007 ],
							[ 4.957433498000057, 12.13691523600005 ],
							[ 4.956428969000058, 12.107784569000046 ],
							[ 4.956428978000076, 12.07162181800004 ],
							[ 4.956428968000068, 12.029432491000023 ],
							[ 4.952410918000055, 11.991261253000062 ],
							[ 4.947388444000069, 11.936012972000071 ],
							[ 4.948392955000031, 11.896837284000071 ],
							[ 4.944374811000046, 11.880764838000061 ],
							[ 4.944374827000047, 11.862684035000029 ],
							[ 4.941484044000049, 11.842448267000066 ],
							[ 4.931583719000059, 11.823057903000063 ],
							[ 4.922216133000063, 11.800021307000065 ],
							[ 4.919141595000042, 11.788124785000036 ],
							[ 4.919266694000044, 11.777054408000026 ],
							[ 4.925942323000072, 11.754193661000045 ],
							[ 4.928499930000044, 11.740777344000037 ],
							[ 4.930175777000045, 11.740589468000053 ],
							[ 4.942946371000062, 11.732523763000074 ],
							[ 4.940972819000024, 11.725945586000023 ],
							[ 4.932535123000036, 11.719808602000057 ],
							[ 4.924864792000051, 11.720576050000034 ],
							[ 4.912208510000028, 11.719808706000038 ],
							[ 4.903770863000034, 11.713672743000075 ],
							[ 4.893799280000053, 11.711371472000053 ],
							[ 4.888430192000044, 11.719041727000047 ],
							[ 4.887663338000038, 11.725945288000048 ],
							[ 4.874623290000045, 11.729013497000039 ],
							[ 4.866952929000036, 11.726712190000057 ],
							[ 4.860049673000049, 11.720576486000027 ],
							[ 4.850078129000053, 11.714439159000051 ],
							[ 4.840106444000071, 11.710604639000053 ],
							[ 4.832435603000022, 11.716740807000065 ],
							[ 4.82399844300005, 11.722876703000054 ],
							[ 4.811725598000066, 11.725177794000047 ],
							[ 4.804822007000041, 11.727479269000071 ],
							[ 4.795617554000046, 11.725177803000065 ],
							[ 4.79101511500005, 11.716740584000036 ],
							[ 4.787179997000067, 11.710604907000061 ],
							[ 4.78564599300006, 11.703700870000034 ],
							[ 4.783344740000075, 11.698331791000044 ],
							[ 4.77874274100003, 11.694496048000076 ],
							[ 4.770305113000063, 11.692194744000062 ],
							[ 4.764935964000074, 11.689893766000068 ],
							[ 4.759566279000069, 11.680689664000056 ],
							[ 4.754197183000031, 11.675319832000071 ],
							[ 4.749594613000056, 11.674552996000045 ],
							[ 4.73885629800003, 11.675319903000059 ],
							[ 4.728117473000054, 11.676087622000068 ],
							[ 4.713543835000053, 11.668417185000067 ],
							[ 4.699737081000023, 11.659211915000071 ],
							[ 4.689765512000065, 11.656910774000039 ],
							[ 4.678259332000039, 11.658445633000042 ],
							[ 4.66982215500002, 11.665348760000029 ],
							[ 4.656782114000066, 11.669950203000042 ],
							[ 4.645276581000076, 11.672251952000067 ],
							[ 4.639907410000035, 11.670718161000025 ],
							[ 4.628401810000071, 11.666882507000025 ],
							[ 4.620730854000044, 11.656910732000028 ],
							[ 4.613060414000074, 11.656143879000069 ],
							[ 4.605390116000024, 11.656144177000044 ],
							[ 4.590049274000023, 11.659978671000033 ],
							[ 4.583145683000055, 11.661513231000072 ],
							[ 4.580895945000066, 11.661192081000024 ],
							[ 4.566545169000051, 11.647436936000076 ],
							[ 4.564107498000055, 11.642983554000068 ],
							[ 4.559712479000041, 11.635556683000061 ],
							[ 4.550801762000049, 11.63053420600005 ],
							[ 4.547040154000058, 11.629133585000034 ],
							[ 4.544026357000064, 11.620092356000043 ],
							[ 4.530986763000044, 11.607820608000054 ],
							[ 4.519481124000038, 11.598615851000034 ],
							[ 4.50567430600006, 11.585576196000034 ],
							[ 4.497236697000062, 11.581740178000075 ],
							[ 4.487577929000054, 11.583100527000056 ],
							[ 4.477373348000071, 11.589594205000026 ],
							[ 4.470544345000064, 11.593939806000037 ],
							[ 4.447907912000062, 11.61891827200003 ],
							[ 4.435418632000051, 11.629845516000046 ],
							[ 4.409659912000052, 11.656385176000072 ],
							[ 4.401854574000026, 11.668093522000049 ],
							[ 4.399512720000075, 11.676679748000026 ],
							[ 4.398732191000022, 11.67980211500003 ],
							[ 4.394048730000065, 11.679801713000074 ],
							[ 4.386243322000041, 11.68136325100005 ],
							[ 4.382340416000034, 11.688389093000069 ],
							[ 4.375315250000028, 11.693071232000023 ],
							[ 4.37219281900002, 11.701658283000029 ],
							[ 4.367091661000075, 11.706121826000071 ],
							[ 4.366566148000061, 11.709228084000074 ],
							[ 4.35991986800002, 11.714702892000048 ],
							[ 4.356079407000038, 11.721138761000077 ],
							[ 4.348658245000024, 11.727478936000068 ],
							[ 4.361458396000046, 11.741286987000024 ],
							[ 4.373366687000043, 11.745859725000059 ],
							[ 4.385780082000053, 11.749947343000031 ],
							[ 4.400281980000045, 11.746685489000072 ],
							[ 4.407897564000052, 11.735964829000068 ],
							[ 4.408489960000054, 11.728597803000071 ],
							[ 4.413120215000049, 11.717348523000055 ],
							[ 4.418659865000052, 11.712992043000042 ],
							[ 4.424196978000055, 11.710554889000036 ],
							[ 4.425177793000046, 11.710122843000022 ],
							[ 4.436599802000046, 11.713704436000057 ],
							[ 4.444495255000049, 11.720192502000032 ],
							[ 4.453917444000069, 11.724240590000022 ],
							[ 4.470737568000061, 11.734769869000047 ],
							[ 4.481145311000034, 11.739812442000073 ],
							[ 4.492051887000059, 11.744860771000049 ],
							[ 4.497425529000054, 11.75377499800004 ],
							[ 4.500306701000056, 11.76265839000007 ],
							[ 4.504476380000028, 11.769590442000037 ],
							[ 4.521280758000046, 11.776592461000064 ],
							[ 4.531428366000057, 11.792203984000025 ],
							[ 4.53203906300007, 11.79403639700007 ],
							[ 4.544680922000055, 11.803504473000032 ],
							[ 4.544565689000024, 11.81284368300004 ],
							[ 4.540972142000044, 11.821157447000076 ],
							[ 4.537586728000065, 11.827633369000068 ],
							[ 4.536891966000042, 11.86167338000007 ],
							[ 4.53512136300003, 11.863444403000074 ],
							[ 4.541299797000022, 11.87648776900005 ],
							[ 4.556973516000028, 11.898431996000056 ],
							[ 4.56115337500006, 11.929778981000027 ],
							[ 4.562198151000075, 11.957992636000029 ],
							[ 4.562198206000062, 11.977553352000029 ],
							[ 4.565921754000044, 11.984733505000065 ],
							[ 4.577673564000065, 12.054818035000039 ],
							[ 4.570682663000071, 12.091547842000068 ],
							[ 4.56484537700004, 12.106168875000037 ],
							[ 4.560077567000064, 12.118110821000073 ],
							[ 4.551384975000076, 12.141014636000023 ],
							[ 4.543614466000065, 12.164849705000051 ],
							[ 4.533984324000073, 12.183252193000044 ],
							[ 4.533652018000055, 12.184961358000066 ],
							[ 4.53498654200007, 12.201092864000032 ],
							[ 4.53898711100004, 12.21148974700003 ],
							[ 4.541342476000068, 12.231508336000047 ],
							[ 4.542383998000048, 12.240359381000076 ],
							[ 4.547474849000025, 12.253726495000024 ],
							[ 4.54771781900007, 12.264447781000058 ],
							[ 4.549430561000065, 12.277773830000058 ],
							[ 4.551866859000029, 12.29332620100007 ],
							[ 4.554069263000031, 12.297418379000021 ],
							[ 4.562933714000053, 12.309351623000055 ],
							[ 4.567024917000026, 12.313694496000039 ],
							[ 4.569976014000076, 12.316827611000065 ],
							[ 4.575182205000033, 12.320954824000069 ],
							[ 4.58782057600007, 12.331453659000033 ],
							[ 4.594664549000072, 12.337948194000035 ],
							[ 4.599901669000076, 12.341788385000029 ],
							[ 4.600828808000074, 12.343797226000049 ],
							[ 4.607132468000032, 12.357455131000052 ],
							[ 4.608868299000051, 12.365188022000041 ],
							[ 4.608868248000022, 12.383020325000075 ],
							[ 4.609872830000029, 12.400097168000059 ],
							[ 4.610407943000041, 12.404021832000069 ],
							[ 4.613597298000059, 12.41148307800006 ],
							[ 4.614544267000042, 12.415410326000028 ],
							[ 4.618439992000049, 12.42230918000007 ],
							[ 4.621838406000052, 12.429202158000066 ],
							[ 4.630773739000063, 12.430776163000075 ],
							[ 4.639732711000022, 12.430391336000071 ],
							[ 4.654304703000037, 12.41832422400006 ],
							[ 4.664743767000061, 12.418935235000049 ],
							[ 4.670788641000058, 12.412642462000065 ],
							[ 4.679296729000043, 12.40833538000004 ],
							[ 4.693310767000071, 12.401155497000047 ],
							[ 4.701303382000049, 12.398310977000051 ],
							[ 4.70338031600005, 12.390992098000027 ],
							[ 4.710962705000043, 12.380799775000071 ],
							[ 4.717163235000044, 12.377168108000035 ],
							[ 4.719978433000051, 12.375519030000021 ],
							[ 4.732995763000076, 12.368326196000055 ],
							[ 4.737980335000032, 12.367404145000023 ],
							[ 4.74496004100007, 12.366015548000064 ],
							[ 4.748441151000065, 12.366055328000073 ],
							[ 4.749909933000026, 12.368029849000038 ],
							[ 4.750748797000028, 12.381256098000051 ],
							[ 4.751142376000075, 12.390071895000062 ],
							[ 4.753010652000057, 12.400372964000042 ],
							[ 4.756713001000037, 12.423910977000048 ],
							[ 4.760890141000061, 12.449412643000073 ],
							[ 4.768716668000025, 12.460760528000037 ],
							[ 4.781095367000034, 12.465796197000031 ],
							[ 4.807678415000055, 12.49008198100006 ],
							[ 4.81556426700007, 12.496533743000043 ],
							[ 4.824869129000035, 12.509365423000077 ],
							[ 4.831166839000048, 12.524120917000062 ],
							[ 4.838980253000045, 12.536934949000056 ],
							[ 4.843339553000021, 12.547263241000053 ],
							[ 4.844283873000052, 12.551679070000034 ],
							[ 4.844187906000059, 12.559999882000056 ],
							[ 4.841767889000039, 12.568718472000057 ],
							[ 4.840325390000032, 12.570160627000064 ],
							[ 4.828876493000053, 12.579199682000024 ],
							[ 4.815017754000053, 12.587636145000033 ],
							[ 4.802363939000031, 12.598482387000047 ],
							[ 4.797593010000071, 12.605638529000032 ],
							[ 4.782730040000047, 12.620675753000057 ],
							[ 4.758058911000035, 12.65194503500004 ],
							[ 4.749921423000046, 12.682445690000065 ],
							[ 4.750014631000056, 12.684833862000062 ],
							[ 4.750667972000031, 12.701574767000068 ],
							[ 4.759282859000052, 12.713145699000052 ],
							[ 4.773120192000022, 12.724463263000075 ],
							[ 4.774692892000076, 12.72574951200005 ],
							[ 4.789086945000065, 12.74216503100007 ],
							[ 4.804477017000067, 12.756678699000076 ],
							[ 4.81225587800003, 12.756766205000076 ],
							[ 4.812808526000026, 12.756440762000068 ],
							[ 4.813992570000039, 12.758542847000058 ],
							[ 4.816334202000064, 12.774154405000047 ],
							[ 4.813211946000024, 12.788203980000048 ],
							[ 4.802284275000034, 12.806938125000045 ],
							[ 4.799415095000029, 12.809348254000042 ],
							[ 4.798919625000053, 12.813559743000042 ],
							[ 4.797543492000045, 12.819524069000067 ],
							[ 4.797156342000051, 12.824720436000064 ],
							[ 4.795902264000063, 12.828273629000023 ],
							[ 4.792849082000032, 12.836561435000021 ],
							[ 4.789359583000021, 12.843104143000062 ],
							[ 4.78019959300002, 12.850083246000054 ],
							[ 4.774092687000064, 12.857062262000056 ],
							[ 4.767549992000056, 12.857934954000029 ],
							[ 4.761006830000042, 12.861860145000037 ],
							[ 4.75271947300007, 12.864041575000044 ],
							[ 4.743123010000033, 12.870584266000037 ],
							[ 4.729601319000039, 12.877126951000037 ],
							[ 4.723494482000035, 12.879307847000064 ],
							[ 4.716515556000047, 12.889340420000053 ],
							[ 4.713233308000042, 12.890981826000029 ],
							[ 4.711922811000022, 12.893293936000077 ],
							[ 4.701990146000071, 12.910387925000066 ],
							[ 4.700846192000029, 12.924715211000034 ],
							[ 4.691953545000047, 12.936085157000036 ],
							[ 4.670413117000066, 12.947307648000049 ],
							[ 4.662181924000038, 12.951595693000058 ],
							[ 4.656321021000053, 12.959131036000031 ],
							[ 4.64934211700006, 12.969162886000049 ],
							[ 4.638437242000066, 12.984866361000059 ],
							[ 4.63930982200003, 12.994462085000066 ],
							[ 4.644544155000062, 13.003186158000062 ],
							[ 4.64934211700006, 13.008420805000071 ],
							[ 4.655448909000029, 13.015835665000054 ],
							[ 4.662864193000075, 13.022814763000042 ],
							[ 4.668098531000055, 13.027176809000025 ],
							[ 4.669232230000034, 13.028651031000038 ],
							[ 4.670072892000064, 13.028764190000061 ],
							[ 4.681040709000058, 13.037436717000048 ],
							[ 4.693441834000055, 13.058555616000035 ],
							[ 4.70199232300007, 13.07108437200003 ],
							[ 4.706336357000055, 13.083640211000045 ],
							[ 4.699030106000066, 13.076701706000051 ],
							[ 4.68557419800004, 13.067664813000022 ],
							[ 4.685764277000033, 13.068615040000054 ],
							[ 4.687944948000052, 13.084318445000065 ],
							[ 4.689689690000023, 13.09435005000006 ],
							[ 4.691870663000032, 13.106127917000038 ],
							[ 4.696668595000062, 13.117032017000042 ],
							[ 4.700594362000061, 13.124446847000058 ],
							[ 4.701903400000049, 13.134479536000072 ],
							[ 4.706701312000064, 13.144947879000028 ],
							[ 4.708420271000023, 13.156124802000022 ],
							[ 4.702822725000033, 13.166943486000037 ],
							[ 4.696907924000072, 13.16990075700005 ],
							[ 4.685199332000025, 13.169901109000023 ],
							[ 4.670368687000064, 13.165997609000044 ],
							[ 4.661782794000032, 13.156631684000047 ],
							[ 4.654757517000064, 13.147264531000076 ],
							[ 4.642268669000032, 13.123846839000066 ],
							[ 4.629779390000067, 13.098869327000045 ],
							[ 4.618851665000022, 13.091844418000051 ],
							[ 4.602459401000033, 13.09496567900004 ],
							[ 4.579042493000031, 13.104333066000038 ],
							[ 4.570456570000033, 13.105113709000022 ],
							[ 4.552503529000035, 13.115261251000049 ],
							[ 4.550161873000036, 13.119163320000041 ],
							[ 4.541575396000042, 13.128530417000036 ],
							[ 4.526744819000044, 13.140238913000076 ],
							[ 4.522875430000056, 13.14050549500007 ],
							[ 4.504108478000035, 13.141800143000069 ],
							[ 4.466641462000041, 13.151167175000069 ],
							[ 4.447127356000067, 13.156631295000068 ],
							[ 4.426052108000022, 13.16287529400006 ],
							[ 4.397951608000028, 13.165217308000024 ],
							[ 4.379998630000046, 13.169901134000042 ],
							[ 4.365168100000062, 13.173803118000023 ],
							[ 4.355020575000026, 13.183950536000054 ],
							[ 4.351898212000037, 13.199562173000061 ],
							[ 4.345653515000038, 13.213612782000041 ],
							[ 4.334725860000049, 13.221418235000044 ],
							[ 4.315211752000039, 13.229222997000022 ],
							[ 4.297258888000044, 13.230784183000026 ],
							[ 4.287891804000026, 13.230004415000053 ],
							[ 4.276964123000027, 13.243273785000042 ],
							[ 4.266816609000045, 13.251079785000059 ],
							[ 4.25354724400006, 13.260446413000068 ],
							[ 4.23247195700003, 13.269812340000044 ],
							[ 4.212957772000038, 13.267471515000068 ],
							[ 4.20202967900002, 13.259665516000041 ],
							[ 4.184076732000051, 13.251859543000023 ],
							[ 4.166904466000062, 13.251859545000059 ],
							[ 4.152073809000058, 13.252640625000026 ],
							[ 4.129437497000026, 13.262787850000052 ],
							[ 4.128392181000038, 13.264530157000024 ],
							[ 4.12974889700007, 13.269956443000069 ],
							[ 4.129748777000032, 13.279452089000074 ],
							[ 4.12974882900005, 13.295053470000028 ],
							[ 4.129070807000062, 13.31404492300004 ],
							[ 4.13008785300002, 13.367628905000061 ],
							[ 4.130087908000064, 13.377803006000022 ],
							[ 4.130087932000038, 13.396117292000042 ],
							[ 4.13144449400005, 13.410360512000068 ],
							[ 4.129409847000034, 13.432744269000068 ],
							[ 4.128053128000033, 13.449022684000056 ],
							[ 4.128731680000044, 13.461910681000063 ],
							[ 4.12940981600002, 13.472762502000023 ],
							[ 4.140262172000064, 13.474119716000075 ],
							[ 4.172819637000032, 13.47547538300006 ],
							[ 4.190454917000068, 13.475475844000073 ],
							[ 4.204020537000076, 13.476832262000073 ],
							[ 4.216229493000071, 13.487683781000044 ],
							[ 4.235221380000041, 13.503963842000076 ],
							[ 4.258961147000036, 13.522955154000044 ],
							[ 4.296944587000041, 13.555511989000024 ],
							[ 4.327467479000063, 13.586034840000025 ],
							[ 4.343067573000042, 13.598923266000043 ],
							[ 4.364772834000064, 13.619270878000066 ],
							[ 4.394956151000031, 13.649114790000056 ],
							[ 4.415304666000054, 13.663359098000058 ],
							[ 4.432261480000022, 13.679637600000035 ],
							[ 4.464818947000026, 13.702699149000068 ],
							[ 4.47838450200004, 13.707446491000042 ],
							[ 4.487880277000045, 13.709482138000055 ],
							[ 4.515011780000066, 13.715587141000071 ],
							[ 4.539429711000025, 13.718977661000054 ],
							[ 4.554351889000031, 13.721691017000069 ],
							[ 4.57741302200003, 13.723725083000033 ],
							[ 4.597083619000045, 13.730508560000033 ],
							[ 4.622179530000039, 13.737291334000076 ],
							[ 4.648632570000075, 13.740682693000053 ],
							[ 4.664911304000043, 13.744074049000062 ],
							[ 4.698146861000055, 13.748821821000035 ],
							[ 4.730026185000042, 13.752891262000048 ],
							[ 4.750374279000027, 13.758996426000067 ],
							[ 4.770044762000055, 13.76442275200003 ],
							[ 4.787033096000073, 13.769866419000039 ],
							[ 4.797544076000065, 13.772494259000041 ],
							[ 4.819616276000033, 13.774595978000036 ],
							[ 4.824352259000023, 13.775273748000075 ],
							[ 4.838010291000046, 13.777224895000074 ],
							[ 4.856403851000039, 13.780377180000073 ],
							[ 4.870593486000075, 13.781953379000072 ],
							[ 4.877951157000041, 13.773020338000038 ],
							[ 4.893191291000051, 13.760406825000075 ],
							[ 4.904227731000049, 13.751998395000044 ],
							[ 4.917366059000074, 13.74831974600005 ],
							[ 4.937336426000059, 13.747268542000029 ],
							[ 4.96624078800005, 13.745167105000064 ],
							[ 4.987788063000039, 13.740962009000043 ],
							[ 5.019846090000044, 13.746742997000069 ],
							[ 5.048224374000029, 13.749896024000066 ],
							[ 5.076603441000032, 13.753575676000025 ],
							[ 5.092369550000058, 13.755677492000075 ],
							[ 5.102354915000035, 13.753049715000031 ],
							[ 5.126004354000031, 13.74989581400007 ],
							[ 5.134412635000047, 13.750421291000066 ],
							[ 5.139668065000023, 13.749895990000027 ],
							[ 5.155894705000037, 13.745788920000052 ],
							[ 5.160426525000048, 13.744641229000024 ],
							[ 5.172323234000032, 13.745632159000024 ],
							[ 5.189330920000032, 13.744114546000048 ],
							[ 5.214031185000067, 13.743589392000047 ],
							[ 5.24030840000006, 13.747794602000056 ],
							[ 5.267635816000052, 13.757778729000051 ],
							[ 5.28865706800002, 13.770391888000063 ],
							[ 5.303372341000056, 13.790887920000046 ],
							[ 5.314408821000029, 13.806128189000049 ],
							[ 5.327547078000066, 13.828200733000074 ],
							[ 5.341210862000025, 13.839762243000052 ],
							[ 5.351721777000023, 13.848171782000065 ],
							[ 5.357502568000029, 13.848697044000062 ],
							[ 5.370640699000035, 13.855002948000049 ],
							[ 5.382202536000023, 13.861835468000038 ],
							[ 5.399545654000065, 13.867616092000048 ],
							[ 5.406902858000024, 13.870769154000072 ],
							[ 5.424771399000065, 13.876550258000066 ],
							[ 5.431707964000054, 13.878359909000039 ],
							[ 5.448946027000034, 13.882855950000021 ],
							[ 5.488886412000056, 13.893893433000073 ],
							[ 5.493939920000059, 13.893893416000026 ],
							[ 5.514638016000049, 13.894419133000042 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Taraba',
				admin1Pcod: 'NG035',
				admin1RefN: 'Taraba',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 13.696255,
				Shape_Area: 4.802474
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 10.838215826000066, 9.618465269000069 ],
							[ 10.84854578900007, 9.617201045000058 ],
							[ 10.855375985000023, 9.617508112000053 ],
							[ 10.860506888000032, 9.617737718000058 ],
							[ 10.865716683000073, 9.616242224000075 ],
							[ 10.870927015000063, 9.614747228000056 ],
							[ 10.875965961000077, 9.616703974000075 ],
							[ 10.888006442000062, 9.615514722000057 ],
							[ 10.900136935000035, 9.612600538000038 ],
							[ 10.905507269000054, 9.607652725000037 ],
							[ 10.914216097000065, 9.604584510000052 ],
							[ 10.924635962000025, 9.601591925000037 ],
							[ 10.933345622000047, 9.598523251000074 ],
							[ 10.94555652300005, 9.593882549000057 ],
							[ 10.973056620000023, 9.591659406000076 ],
							[ 10.97489368600003, 9.592890513000043 ],
							[ 10.975506617000065, 9.589634055000033 ],
							[ 10.977500165000038, 9.578269191000061 ],
							[ 10.978141060000041, 9.574613635000048 ],
							[ 10.979952616000048, 9.571175575000041 ],
							[ 10.984711680000032, 9.565485072000058 ],
							[ 10.99238200800005, 9.559283249000032 ],
							[ 11.008192816000076, 9.552108639000039 ],
							[ 11.020428432000074, 9.549492800000053 ],
							[ 11.027398142000038, 9.549074286000064 ],
							[ 11.035526005000065, 9.548683181000058 ],
							[ 11.062135961000024, 9.552200114000073 ],
							[ 11.070855903000052, 9.551242647000038 ],
							[ 11.080131606000066, 9.551458374000049 ],
							[ 11.088839413000073, 9.551079770000058 ],
							[ 11.097536894000029, 9.551281925000069 ],
							[ 11.109132970000076, 9.551550970000051 ],
							[ 11.117840759000046, 9.551173386000073 ],
							[ 11.126549682000075, 9.550794461000066 ],
							[ 11.139316503000032, 9.550511627000049 ],
							[ 11.150912030000029, 9.550780493000047 ],
							[ 11.161347099000068, 9.551022459000023 ],
							[ 11.171167639000032, 9.552990111000042 ],
							[ 11.182134589000043, 9.555564964000041 ],
							[ 11.191942331000064, 9.558113046000074 ],
							[ 11.198839092000071, 9.561172664000026 ],
							[ 11.210349203000021, 9.565500309000072 ],
							[ 11.22245195000005, 9.569261772000061 ],
							[ 11.227090087000022, 9.569368317000055 ],
							[ 11.244061246000058, 9.562221787000055 ],
							[ 11.254666246000056, 9.554347156000063 ],
							[ 11.269281115000069, 9.548886486000072 ],
							[ 11.274511279000023, 9.548427682000067 ],
							[ 11.278014462000044, 9.547348800000066 ],
							[ 11.293075707000071, 9.548277813000027 ],
							[ 11.298292984000057, 9.548399086000074 ],
							[ 11.311049639000032, 9.548694691000037 ],
							[ 11.32032487400005, 9.548910016000036 ],
							[ 11.329032991000076, 9.548532541000043 ],
							[ 11.337729440000032, 9.548734693000029 ],
							[ 11.346438669000065, 9.54835622500002 ],
							[ 11.353419083000063, 9.547357375000047 ],
							[ 11.367914470000073, 9.547694166000042 ],
							[ 11.369313781000074, 9.547484024000028 ],
							[ 11.373851973000058, 9.546802773000024 ],
							[ 11.381296876000022, 9.545684567000023 ],
							[ 11.393471852000062, 9.545966929000031 ],
							[ 11.405684331000032, 9.544509901000026 ],
							[ 11.420153772000049, 9.546006072000068 ],
							[ 11.427642005000052, 9.548499808000031 ],
							[ 11.429598748000046, 9.549625590000062 ],
							[ 11.436155983000049, 9.539826155000071 ],
							[ 11.441616342000032, 9.533151430000032 ],
							[ 11.441865765000045, 9.527972446000035 ],
							[ 11.445696677000058, 9.519493946000068 ],
							[ 11.451486461000059, 9.505914884000049 ],
							[ 11.455146597000066, 9.500888964000069 ],
							[ 11.458895748000032, 9.494136624000021 ],
							[ 11.466225752000071, 9.484086226000045 ],
							[ 11.469977379000056, 9.477333747000046 ],
							[ 11.479106998000077, 9.465633403000027 ],
							[ 11.484306430000061, 9.464138246000061 ],
							[ 11.489846894000038, 9.455735878000041 ],
							[ 11.493516828000054, 9.450711193000075 ],
							[ 11.497427261000041, 9.440506904000074 ],
							[ 11.506306639000059, 9.433984894000048 ],
							[ 11.511756136000031, 9.427309720000039 ],
							[ 11.515505781000059, 9.420557909000024 ],
							[ 11.520966312000041, 9.413883072000033 ],
							[ 11.525925795000035, 9.407815676000041 ],
							[ 11.530007368000042, 9.403909493000072 ],
							[ 11.534528533000071, 9.403206569000076 ],
							[ 11.536564818000045, 9.398085842000057 ],
							[ 11.540976686000022, 9.392592174000072 ],
							[ 11.549238437000042, 9.385470401000021 ],
							[ 11.557318930000065, 9.380875672000059 ],
							[ 11.564263194000034, 9.380859138000062 ],
							[ 11.576989044000072, 9.379686093000032 ],
							[ 11.586242964000064, 9.378521122000052 ],
							[ 11.593187185000033, 9.378504616000043 ],
							[ 11.596256451000045, 9.379207480000048 ],
							[ 11.601716033000059, 9.372533703000045 ],
							[ 11.603675586000065, 9.367430628000022 ],
							[ 11.613046770000039, 9.350551686000074 ],
							[ 11.616707102000021, 9.345526925000058 ],
							[ 11.622245569000029, 9.337124752000022 ],
							[ 11.629496696000047, 9.328800156000057 ],
							[ 11.635526385000048, 9.322432578000075 ],
							[ 11.639060262000044, 9.318730235000032 ],
							[ 11.640401659000077, 9.31492206300004 ],
							[ 11.647746827000049, 9.305400101000032 ],
							[ 11.64884756500004, 9.298032945000045 ],
							[ 11.650736120000033, 9.294888732000061 ],
							[ 11.653616663000037, 9.290092388000062 ],
							[ 11.656383650000066, 9.285502543000064 ],
							[ 11.660807653000063, 9.278209709000066 ],
							[ 11.663301304000072, 9.273080969000034 ],
							[ 11.665579082000022, 9.265067258000045 ],
							[ 11.670155390000048, 9.253614633000041 ],
							[ 11.670535408000035, 9.253109790000053 ],
							[ 11.677057192000063, 9.244445614000028 ],
							[ 11.679227010000034, 9.240657210000052 ],
							[ 11.68165022900007, 9.236426277000021 ],
							[ 11.683629174000032, 9.23406103800005 ],
							[ 11.687405643000034, 9.229547256000046 ],
							[ 11.69309058500005, 9.220095746000027 ],
							[ 11.694565, 9.218311123000035 ],
							[ 11.696290086000033, 9.215721107000036 ],
							[ 11.700067774000047, 9.210051502000056 ],
							[ 11.698845786000049, 9.203891800000065 ],
							[ 11.699087335000058, 9.198713151000049 ],
							[ 11.699506983000049, 9.190080416000058 ],
							[ 11.701660974000049, 9.180823539000073 ],
							[ 11.701876393000077, 9.176346914000021 ],
							[ 11.702615649000052, 9.160809657000073 ],
							[ 11.701156388000072, 9.155553032000057 ],
							[ 11.69978618500005, 9.148571048000065 ],
							[ 11.698466326000073, 9.14384005900007 ],
							[ 11.69686673700005, 9.138058881000063 ],
							[ 11.695397382000067, 9.132802876000028 ],
							[ 11.69573710800006, 9.125897641000051 ],
							[ 11.696066003000055, 9.118991945000062 ],
							[ 11.696306973000048, 9.113812704000054 ],
							[ 11.694935874000066, 9.106829565000055 ],
							[ 11.696887034000042, 9.101727665000055 ],
							[ 11.698927156000025, 9.094899267000073 ],
							[ 11.700886715000024, 9.089797299000054 ],
							[ 11.702846793000049, 9.084694723000041 ],
							[ 11.704806233000056, 9.079591601000061 ],
							[ 11.706756539000025, 9.074490775000072 ],
							[ 11.710347158000047, 9.071190735000073 ],
							[ 11.715636374000042, 9.067968593000046 ],
							[ 11.722637185000053, 9.064823014000069 ],
							[ 11.729636164000055, 9.061677888000077 ],
							[ 11.735176825000053, 9.053277210000033 ],
							[ 11.74208666800007, 9.051857771000073 ],
							[ 11.749007254000048, 9.050438695000025 ],
							[ 11.752534840000067, 9.049715033000041 ],
							[ 11.758300577000057, 9.042886724000027 ],
							[ 11.767516913000065, 9.033712648000062 ],
							[ 11.776749854000059, 9.027970121000067 ],
							[ 11.785987860000034, 9.023372809000023 ],
							[ 11.791758598000058, 9.01992720800007 ],
							[ 11.799850676000062, 9.017620107000027 ],
							[ 11.804469148000067, 9.015320618000032 ],
							[ 11.81932239300005, 9.012020134000068 ],
							[ 11.825280873000054, 9.01069624400003 ],
							[ 11.831057674000022, 9.008395448000044 ],
							[ 11.84377839800004, 9.006077806000064 ],
							[ 11.848407524000038, 9.006067515000041 ],
							[ 11.856493934000071, 9.002615994000053 ],
							[ 11.864547788000039, 8.992300162000049 ],
							[ 11.876047379000056, 8.976255326000057 ],
							[ 11.880640048000032, 8.968236152000031 ],
							[ 11.885216693000075, 8.956783998000049 ],
							[ 11.89326587100004, 8.945323743000074 ],
							[ 11.896684638000067, 8.933874291000052 ],
							[ 11.898961989000043, 8.925860625000041 ],
							[ 11.900078081000061, 8.916704376000041 ],
							[ 11.902323825000053, 8.901824802000021 ],
							[ 11.903432915000053, 8.891525362000039 ],
							[ 11.903384929000026, 8.881227679000062 ],
							[ 11.903332912000053, 8.869786242000032 ],
							[ 11.899802061000059, 8.857208037000021 ],
							[ 11.891641532000051, 8.844640722000065 ],
							[ 11.877647652000064, 8.821789659000046 ],
							[ 11.874137794000035, 8.813788466000062 ],
							[ 11.861358647000031, 8.803520306000053 ],
							[ 11.830975595000041, 8.740660944000069 ],
							[ 11.82859729200004, 8.726936410000064 ],
							[ 11.826250271000049, 8.720076667000058 ],
							[ 11.818069620000074, 8.702932347000058 ],
							[ 11.813370949000046, 8.68806922400006 ],
							[ 11.809860947000061, 8.680067941000061 ],
							[ 11.797056319000035, 8.664079573000038 ],
							[ 11.79120524800004, 8.650363038000023 ],
							[ 11.789455421000071, 8.643571977000022 ],
							[ 11.788848143000052, 8.641214386000058 ],
							[ 11.78416519700005, 8.629783538000027 ],
							[ 11.782981752000069, 8.624065285000029 ],
							[ 11.780635127000039, 8.617206631000045 ],
							[ 11.779806061000045, 8.609372136000047 ],
							[ 11.779424615000039, 8.605767293000042 ],
							[ 11.779387373000077, 8.597758396000074 ],
							[ 11.786241321000034, 8.57829189000006 ],
							[ 11.789677757000049, 8.570275331000062 ],
							[ 11.791955177000034, 8.562260765000076 ],
							[ 11.795369270000037, 8.549666277000028 ],
							[ 11.796404067000026, 8.546234050000066 ],
							[ 11.790654054000072, 8.531371169000067 ],
							[ 11.785981986000024, 8.522228117000054 ],
							[ 11.780157931000076, 8.51423270500004 ],
							[ 11.76622191000007, 8.503966251000065 ],
							[ 11.756920802000025, 8.494834965000052 ],
							[ 11.748786945000063, 8.487988568000048 ],
							[ 11.736002825000071, 8.476575903000025 ],
							[ 11.730189220000057, 8.470868938000024 ],
							[ 11.724391785000023, 8.468593456000065 ],
							[ 11.716274490000046, 8.465179356000021 ],
							[ 11.709323796000035, 8.464051147000021 ],
							[ 11.698891375000073, 8.460642955000026 ],
							[ 11.689617113000054, 8.457231639000042 ],
							[ 11.683814005000045, 8.453812614000071 ],
							[ 11.674528385000031, 8.448112551000065 ],
							[ 11.668709521000039, 8.441261393000048 ],
							[ 11.662896399000033, 8.43555364100007 ],
							[ 11.65593165100006, 8.430993112000067 ],
							[ 11.651279385000066, 8.426426840000033 ],
							[ 11.651231971000072, 8.426352480000048 ],
							[ 11.645450391000054, 8.417286914000044 ],
							[ 11.640783227000043, 8.409288325000034 ],
							[ 11.637285475000056, 8.403575973000045 ],
							[ 11.633764995000035, 8.393285674000026 ],
							[ 11.628797457000076, 8.384893494000039 ],
							[ 11.622112356000059, 8.376150089000021 ],
							[ 11.618623701000047, 8.372725409000054 ],
							[ 11.615135114000054, 8.369300974000055 ],
							[ 11.601167748000023, 8.352170990000047 ],
							[ 11.598826505000034, 8.346455459000026 ],
							[ 11.595327182000062, 8.340742155000044 ],
							[ 11.589499255000021, 8.331602168000074 ],
							[ 11.584815763000051, 8.320171237000068 ],
							[ 11.580138397000042, 8.309885134000069 ],
							[ 11.577797173000022, 8.304168609000044 ],
							[ 11.57313534900004, 8.297314608000022 ],
							[ 11.56613277200006, 8.284745090000058 ],
							[ 11.561450061000073, 8.273314395000057 ],
							[ 11.554442617000063, 8.259599704000038 ],
							[ 11.549776072000043, 8.251601329000039 ],
							[ 11.546277301000032, 8.245888669000067 ],
							[ 11.538127946000031, 8.235609928000031 ],
							[ 11.528815067000039, 8.224189807000073 ],
							[ 11.522986140000057, 8.215049875000034 ],
							[ 11.514842062000071, 8.205915515000072 ],
							[ 11.510191043000077, 8.201349395000022 ],
							[ 11.504372405000026, 8.194497027000068 ],
							[ 11.49623368400006, 8.186507175000031 ],
							[ 11.485775095000065, 8.177377701000069 ],
							[ 11.477636603000064, 8.169386860000031 ],
							[ 11.469508270000063, 8.163684837000062 ],
							[ 11.461368730000061, 8.155693920000033 ],
							[ 11.456718466000041, 8.15112768900002 ],
							[ 11.448584331000063, 8.144282264000026 ],
							[ 11.439288187000045, 8.136294314000054 ],
							[ 11.433480382000027, 8.131730094000034 ],
							[ 11.426498680000066, 8.123737240000025 ],
							[ 11.418364605000022, 8.116890866000062 ],
							[ 11.407900719000054, 8.106617914000026 ],
							[ 11.400930574000029, 8.100912159000075 ],
							[ 11.390476379000063, 8.092926864000049 ],
							[ 11.383499877000077, 8.08607756300006 ],
							[ 11.380013414000075, 8.082653980000032 ],
							[ 11.376518998000051, 8.078084880000063 ],
							[ 11.371857613000032, 8.071230831000037 ],
							[ 11.362566762000029, 8.06438729000007 ],
							[ 11.357895005000046, 8.055244352000045 ],
							[ 11.354401714000062, 8.050676457000066 ],
							[ 11.35207080400005, 8.047248874000047 ],
							[ 11.349739956000064, 8.043821275000028 ],
							[ 11.34625703100005, 8.041541181000071 ],
							[ 11.346236119000025, 8.036964311000077 ],
							[ 11.347382545000073, 8.034673567000027 ],
							[ 11.352012387000059, 8.034663203000036 ],
							[ 11.354316514000061, 8.032369565000067 ],
							[ 11.354289814000026, 8.026648619000071 ],
							[ 11.350823462000051, 8.027800503000037 ],
							[ 11.34736162300004, 8.03009699200004 ],
							[ 11.342721133000055, 8.027819715000021 ],
							[ 11.341537496000058, 8.022101341000052 ],
							[ 11.341521140000054, 8.018668274000049 ],
							[ 11.34613494000007, 8.01522546800004 ],
							[ 11.350749230000076, 8.011782582000023 ],
							[ 11.351863801000036, 8.002626526000029 ],
							[ 11.354151817000059, 7.996900942000025 ],
							[ 11.35645114700003, 7.993462924000028 ],
							[ 11.358754970000064, 7.991169533000061 ],
							[ 11.363352851000059, 7.984293408000042 ],
							[ 11.365630852000038, 7.976279302000023 ],
							[ 11.366777542000023, 7.973988505000023 ],
							[ 11.372522242000059, 7.964821732000075 ],
							[ 11.382875244000047, 7.951067794000039 ],
							[ 11.393233286000054, 7.938459043000023 ],
							[ 11.400109022000038, 7.923568689000035 ],
							[ 11.403544349000072, 7.915552109000032 ],
							[ 11.40813627600005, 7.907532177000064 ],
							[ 11.411566558000061, 7.898370870000065 ],
							[ 11.420772419000059, 7.886908160000075 ],
							[ 11.426511889000039, 7.876597826000022 ],
							[ 11.434565475000056, 7.866281963000063 ],
							[ 11.438022689000036, 7.862841675000027 ],
							[ 11.44376668600006, 7.853675426000052 ],
							[ 11.454130376000023, 7.842209233000062 ],
							[ 11.457582322000064, 7.837624924000067 ],
							[ 11.462184697000055, 7.831893862000072 ],
							[ 11.465640751000024, 7.828453572000058 ],
							[ 11.472526408000022, 7.815851346000045 ],
							[ 11.480612645000065, 7.81240093100007 ],
							[ 11.482190392000064, 7.810238782000056 ],
							[ 11.489813755000057, 7.799793747000024 ],
							[ 11.494437901000026, 7.798639165000054 ],
							[ 11.500230710000039, 7.799769924000032 ],
							[ 11.507206806000056, 7.806619067000042 ],
							[ 11.514193791000025, 7.815756257000032 ],
							[ 11.51655, 7.824904426000046 ],
							[ 11.525904849000028, 7.845477691000042 ],
							[ 11.528251364000027, 7.852337330000069 ],
							[ 11.529430601000058, 7.856911070000024 ],
							[ 11.532944662000034, 7.866056797000056 ],
							[ 11.535290865000036, 7.872916092000025 ],
							[ 11.537647974000038, 7.882064378000052 ],
							[ 11.538826847000053, 7.886638086000062 ],
							[ 11.541168174000063, 7.892353544000059 ],
							[ 11.544662305000031, 7.896922236000023 ],
							[ 11.549972522000076, 7.901862038000047 ],
							[ 11.551552960000038, 7.903331729000058 ],
							[ 11.555585031000021, 7.903788603000066 ],
							[ 11.563221927000029, 7.906032989000039 ],
							[ 11.570166517000075, 7.906017273000032 ],
							[ 11.578267878000077, 7.905998684000053 ],
							[ 11.595591390000038, 7.897949575000041 ],
							[ 11.601369023000075, 7.895648461000064 ],
							[ 11.611759137000035, 7.889903613000058 ],
							[ 11.624464044000035, 7.884153818000073 ],
							[ 11.636000437000064, 7.876118059000021 ],
							[ 11.646368775000042, 7.865797016000045 ],
							[ 11.650967606000052, 7.858921457000065 ],
							[ 11.656700922000027, 7.847467048000055 ],
							[ 11.671657713000059, 7.827981934000036 ],
							[ 11.676244509000071, 7.818818143000044 ],
							[ 11.679679602000022, 7.810800896000046 ],
							[ 11.683115102000045, 7.802783868000063 ],
							[ 11.687712471000054, 7.795908385000075 ],
							[ 11.703868667000052, 7.785574345000043 ],
							[ 11.711933399000031, 7.777546420000021 ],
							[ 11.714232514000059, 7.774108911000042 ],
							[ 11.734990983000046, 7.758042812000042 ],
							[ 11.73959426600004, 7.752311598000063 ],
							[ 11.747658623000063, 7.744284256000071 ],
							[ 11.754545465000035, 7.73168225400002 ],
							[ 11.757984973000077, 7.724809161000053 ],
							[ 11.764786953000055, 7.693901120000021 ],
							[ 11.769363164000026, 7.682449305000034 ],
							[ 11.771598905000076, 7.665281681000067 ],
							[ 11.772692665000022, 7.651549411000076 ],
							[ 11.769108951000021, 7.627530006000029 ],
							[ 11.766106728000068, 7.614924419000033 ],
							[ 11.766279285000053, 7.612719889000061 ],
							[ 11.766521215000068, 7.603140796000048 ],
							[ 11.768292709000036, 7.598398293000059 ],
							[ 11.768231295000021, 7.59634980200002 ],
							[ 11.768077692000077, 7.591182131000039 ],
							[ 11.767821538000021, 7.582570629000031 ],
							[ 11.768425233000073, 7.579620814000066 ],
							[ 11.766549934000068, 7.574904379000031 ],
							[ 11.765366785000026, 7.56918606000005 ],
							[ 11.764166774000046, 7.560035738000067 ],
							[ 11.762951060000034, 7.547452529000054 ],
							[ 11.762919544000056, 7.540587900000048 ],
							[ 11.76517577900006, 7.527996898000026 ],
							[ 11.766300908000062, 7.521129199000029 ],
							[ 11.767421880000029, 7.513117376000025 ],
							[ 11.771935236000047, 7.487935567000022 ],
							[ 11.776533199000028, 7.481059937000055 ],
							[ 11.785744578000049, 7.470741145000034 ],
							[ 11.791494390000025, 7.462719517000039 ],
							[ 11.796086314000036, 7.454699540000036 ],
							[ 11.80174147200006, 7.449540670000033 ],
							[ 11.804748693000022, 7.446797335000042 ],
							[ 11.809917703000053, 7.442081466000047 ],
							[ 11.816824062000023, 7.434056772000076 ],
							[ 11.822579166000025, 7.427178748000074 ],
							[ 11.831790738000052, 7.416860162000035 ],
							[ 11.844437337000045, 7.398524859000077 ],
							[ 11.850172128000054, 7.387070206000033 ],
							[ 11.853993262000074, 7.378666047000024 ],
							[ 11.844258579000041, 7.367540260000055 ],
							[ 11.82255716800006, 7.332276260000071 ],
							[ 11.814419464000025, 7.312609752000071 ],
							[ 11.804926005000027, 7.273276938000038 ],
							[ 11.802890554000044, 7.248185112000044 ],
							[ 11.805604036000034, 7.219024561000026 ],
							[ 11.810351119000075, 7.196645835000027 ],
							[ 11.811029264000069, 7.181048485000076 ],
							[ 11.817131881000023, 7.149175285000069 ],
							[ 11.834086442000057, 7.113911188000031 ],
							[ 11.842901952000034, 7.098313465000047 ],
							[ 11.846463006000022, 7.093862050000041 ],
							[ 11.844557525000027, 7.092787756000064 ],
							[ 11.845819488000075, 7.090053604000047 ],
							[ 11.848567210000056, 7.084559825000042 ],
							[ 11.843073039000046, 7.075403594000022 ],
							[ 11.835747491000063, 7.065331484000069 ],
							[ 11.81926609900006, 7.058006354000042 ],
							[ 11.797291074000043, 7.060753463000026 ],
							[ 11.788596280000036, 7.062927172000059 ],
							[ 11.785259303000032, 7.063049185000068 ],
							[ 11.771017965000055, 7.067118574000062 ],
							[ 11.762880098000039, 7.066440172000057 ],
							[ 11.759488925000028, 7.065083896000033 ],
							[ 11.752707761000067, 7.060337204000064 ],
							[ 11.746605080000052, 7.058302332000039 ],
							[ 11.742535677000035, 7.061015036000072 ],
							[ 11.73643183400003, 7.061015134000058 ],
							[ 11.728294233000042, 7.059658643000034 ],
							[ 11.718122276000031, 7.058302333000029 ],
							[ 11.715409282000053, 7.054911698000069 ],
							[ 11.715409113000021, 7.044061174000035 ],
							[ 11.715927227000066, 7.041991668000037 ],
							[ 11.715799248000053, 7.033284053000045 ],
							[ 11.705727564000028, 7.029621650000024 ],
							[ 11.697936220000031, 7.029621707000047 ],
							[ 11.69361673700007, 7.027186817000029 ],
							[ 11.686498914000026, 7.014971637000031 ],
							[ 11.680090087000053, 7.00581489700005 ],
							[ 11.67368007400006, 7.000321414000041 ],
							[ 11.661776460000056, 6.996658736000029 ],
							[ 11.663608325000041, 6.990249209000069 ],
							[ 11.66074470500007, 6.989963120000027 ],
							[ 11.654866008000056, 6.989098943000045 ],
							[ 11.649109655000075, 6.988719528000047 ],
							[ 11.642548575000035, 6.987502625000047 ],
							[ 11.638105254000038, 6.986442436000061 ],
							[ 11.63209609200004, 6.982521032000022 ],
							[ 11.629729510000061, 6.978345912000066 ],
							[ 11.62596114400003, 6.972084944000073 ],
							[ 11.625392032000036, 6.953932897000072 ],
							[ 11.622546085000067, 6.946912198000064 ],
							[ 11.621488634000059, 6.937142452000046 ],
							[ 11.619699286000071, 6.923636560000034 ],
							[ 11.614323678000062, 6.91433900800007 ],
							[ 11.603255120000028, 6.912314810000055 ],
							[ 11.589972558000056, 6.909469211000044 ],
							[ 11.579853233000051, 6.903839493000021 ],
							[ 11.576184195000053, 6.897641142000055 ],
							[ 11.571503883000048, 6.890430883000022 ],
							[ 11.570428872000036, 6.886319516000071 ],
							[ 11.567835651000053, 6.87632667400004 ],
							[ 11.559360763000029, 6.866649591000055 ],
							[ 11.557904969000049, 6.859881752000035 ],
							[ 11.559044150000034, 6.851849039000058 ],
							[ 11.574223558000028, 6.852671588000021 ],
							[ 11.57808183700007, 6.850078045000032 ],
							[ 11.581623856000022, 6.845461436000051 ],
							[ 11.585038938000025, 6.833127521000051 ],
							[ 11.583032535000029, 6.821771955000031 ],
							[ 11.583032480000043, 6.81719412700005 ],
							[ 11.593104521000043, 6.815363064000053 ],
							[ 11.598597340000026, 6.812615887000049 ],
							[ 11.598597655000049, 6.806206620000069 ],
							[ 11.599373745000037, 6.801170300000024 ],
							[ 11.59035682800004, 6.795218809000062 ],
							[ 11.583032795000065, 6.792471811000041 ],
							[ 11.576622914000041, 6.777821502000052 ],
							[ 11.575707485000066, 6.772328041000037 ],
							[ 11.585779327000068, 6.75950920400004 ],
							[ 11.589665511000021, 6.750059579000038 ],
							[ 11.590356578000069, 6.742112119000069 ],
							[ 11.58766625800007, 6.734069307000027 ],
							[ 11.58303257700004, 6.731124439000041 ],
							[ 11.572533483000029, 6.726074218000065 ],
							[ 11.564252809000038, 6.724361316000056 ],
							[ 11.557399535000059, 6.715224120000073 ],
							[ 11.552816277000034, 6.696330054000043 ],
							[ 11.555562878000046, 6.686258270000053 ],
							[ 11.556478522000077, 6.680764096000075 ],
							[ 11.554647205000038, 6.673439014000053 ],
							[ 11.554647207000073, 6.664282730000025 ],
							[ 11.550069117000021, 6.657873682000059 ],
							[ 11.542744457000026, 6.656957609000074 ],
							[ 11.537250802000074, 6.647801267000034 ],
							[ 11.530840733000048, 6.642307802000062 ],
							[ 11.523515540000062, 6.626741896000055 ],
							[ 11.525346752000075, 6.619416831000024 ],
							[ 11.521684766000021, 6.609344476000047 ],
							[ 11.50978094900006, 6.609344573000044 ],
							[ 11.506118653000044, 6.604766343000051 ],
							[ 11.497878101000026, 6.603850830000056 ],
							[ 11.490870370000039, 6.597012542000073 ],
							[ 11.483731507000073, 6.597298112000033 ],
							[ 11.472240243000044, 6.601103719000037 ],
							[ 11.465830643000061, 6.608428849000063 ],
							[ 11.463118580000071, 6.609289771000022 ],
							[ 11.457590354000047, 6.604766274000042 ],
							[ 11.451180363000049, 6.601103694000074 ],
							[ 11.440192975000059, 6.599272730000052 ],
							[ 11.433762337000076, 6.599011468000072 ],
							[ 11.43103667400004, 6.595610012000066 ],
							[ 11.426458224000044, 6.589200573000028 ],
							[ 11.431952654000042, 6.576381706000063 ],
							[ 11.432867868000073, 6.561731808000047 ],
							[ 11.429765554000028, 6.546472489000053 ],
							[ 11.426458217000061, 6.541587888000038 ],
							[ 11.424627451000049, 6.537009147000049 ],
							[ 11.42828967500003, 6.531515445000025 ],
							[ 11.424627422000071, 6.530600127000071 ],
							[ 11.40082065200005, 6.52968458600003 ],
							[ 11.393788174000065, 6.528769559000068 ],
							[ 11.373800028000062, 6.525343058000033 ],
							[ 11.363521450000064, 6.527627571000039 ],
							[ 11.356383144000063, 6.52420088100007 ],
							[ 11.353812993000076, 6.515349344000072 ],
							[ 11.354955822000022, 6.508496382000033 ],
							[ 11.353207830000031, 6.502215311000043 ],
							[ 11.339473003000023, 6.503131020000069 ],
							[ 11.324822655000048, 6.508624522000048 ],
							[ 11.316581919000043, 6.515034338000021 ],
							[ 11.304679014000044, 6.525105884000027 ],
							[ 11.284142587000076, 6.537050267000041 ],
							[ 11.273006154000029, 6.537335446000043 ],
							[ 11.262156264000055, 6.526199878000057 ],
							[ 11.252162920000046, 6.526199867000059 ],
							[ 11.246994162000021, 6.521443236000039 ],
							[ 11.24017060500006, 6.524486658000058 ],
							[ 11.234173750000025, 6.533052531000067 ],
							[ 11.220468565000033, 6.536764554000058 ],
							[ 11.212199009000074, 6.536093738000034 ],
							[ 11.192970961000071, 6.539756383000054 ],
							[ 11.183814829000028, 6.546165850000023 ],
							[ 11.163075271000025, 6.563033714000028 ],
							[ 11.154223328000057, 6.562748489000057 ],
							[ 11.149020206000046, 6.559900390000053 ],
							[ 11.142611419000048, 6.547081530000071 ],
							[ 11.130707568000048, 6.538840766000021 ],
							[ 11.10197095500007, 6.540190591000055 ],
							[ 11.098660661000054, 6.548912591000033 ],
							[ 11.100491474000023, 6.569972612000072 ],
							[ 11.099576033000062, 6.607513328000039 ],
							[ 11.100491404000024, 6.642307800000026 ],
							[ 11.103238899000075, 6.660620323000046 ],
							[ 11.105985900000064, 6.68076420500006 ],
							[ 11.09957595000003, 6.687173778000044 ],
							[ 11.087673061000032, 6.70273970300002 ],
							[ 11.076684742000054, 6.712811478000049 ],
							[ 11.067528558000049, 6.713727114000051 ],
							[ 11.049216048000062, 6.714642979000075 ],
							[ 11.039144735000036, 6.718305479000037 ],
							[ 11.031819101000053, 6.71372711500004 ],
							[ 11.013789225000039, 6.69708402200007 ],
							[ 11.001603171000056, 6.68534229200003 ],
							[ 10.996108808000031, 6.684426772000052 ],
							[ 10.988783803000047, 6.684426760000065 ],
							[ 10.98145984000007, 6.689004976000035 ],
							[ 10.975965475000066, 6.688089432000027 ],
							[ 10.958568666000076, 6.690836570000045 ],
							[ 10.952158687000065, 6.692667445000041 ],
							[ 10.95124325200004, 6.687173853000047 ],
							[ 10.948495876000038, 6.685342369000068 ],
							[ 10.926521330000071, 6.700908138000045 ],
							[ 10.918280351000021, 6.70823348700003 ],
							[ 10.915532861000031, 6.729292760000021 ],
							[ 10.91461735200005, 6.73570251700005 ],
							[ 10.917215303000035, 6.746094672000027 ],
							[ 10.916448741000067, 6.753099319000057 ],
							[ 10.913701837000076, 6.765918214000067 ],
							[ 10.908207728000036, 6.769580845000064 ],
							[ 10.904546070000038, 6.764087336000046 ],
							[ 10.898136043000022, 6.760424731000057 ],
							[ 10.889895461000037, 6.770496931000025 ],
							[ 10.886233580000066, 6.778737467000042 ],
							[ 10.882569985000032, 6.790640759000041 ],
							[ 10.87432966700004, 6.798881474000041 ],
							[ 10.859679494000034, 6.809868764000043 ],
							[ 10.850523249000048, 6.806206830000065 ],
							[ 10.839249700000039, 6.811038035000024 ],
							[ 10.83910574600003, 6.814008666000063 ],
							[ 10.838179426000067, 6.816432483000028 ],
							[ 10.835872558000062, 6.816278449000038 ],
							[ 10.828547557000036, 6.822688067000058 ],
							[ 10.823970068000051, 6.830012733000046 ],
							[ 10.821223186000054, 6.839169506000076 ],
							[ 10.820306837000032, 6.843747586000063 ],
							[ 10.820307036000031, 6.850156757000036 ],
							[ 10.818475909000028, 6.859313489000044 ],
							[ 10.817007219000061, 6.863131625000051 ],
							[ 10.82043264400005, 6.865415657000028 ],
							[ 10.824145357000077, 6.86770020900002 ],
							[ 10.82157503600007, 6.873981989000072 ],
							[ 10.824145233000024, 6.881405827000037 ],
							[ 10.827857107000057, 6.893112630000076 ],
							[ 10.834423977000029, 6.911957736000033 ],
							[ 10.840134874000057, 6.925092710000058 ],
							[ 10.841848618000029, 6.931088875000057 ],
							[ 10.839535452000064, 6.937142299000072 ],
							[ 10.834776003000059, 6.940110598000047 ],
							[ 10.822138986000027, 6.944467390000057 ],
							[ 10.810235181000053, 6.950877304000073 ],
							[ 10.806573079000032, 6.959117798000023 ],
							[ 10.799247691000062, 6.960033494000072 ],
							[ 10.790091511000071, 6.960033371000065 ],
							[ 10.784597533000067, 6.967358558000058 ],
							[ 10.779103461000034, 6.966442590000042 ],
							[ 10.774525857000071, 6.957286384000042 ],
							[ 10.768116040000052, 6.953623801000049 ],
							[ 10.764452843000072, 6.958201951000035 ],
							[ 10.758959652000044, 6.964611485000034 ],
							[ 10.749803461000056, 6.971021194000059 ],
							[ 10.74888783800003, 6.977430455000047 ],
							[ 10.736068823000039, 6.989333599000076 ],
							[ 10.716840501000036, 6.991165109000065 ],
							[ 10.712262387000067, 6.999405811000031 ],
							[ 10.706768292000049, 7.007646478000026 ],
							[ 10.697611528000039, 7.014971829000046 ],
							[ 10.685708723000062, 7.018634395000049 ],
							[ 10.683876841000028, 7.025959001000047 ],
							[ 10.684793740000032, 7.037862254000061 ],
							[ 10.677468130000022, 7.04518741000004 ],
							[ 10.662817804000042, 7.052512665000052 ],
							[ 10.660070433000044, 7.046103008000046 ],
							[ 10.652745988000049, 7.045187502000033 ],
							[ 10.631686309000031, 7.049765717000071 ],
							[ 10.62161449000007, 7.057090679000055 ],
							[ 10.602385683000023, 7.073572186000035 ],
							[ 10.604216561000044, 7.075403750000021 ],
							[ 10.603301010000052, 7.082728418000045 ],
							[ 10.60787941500007, 7.101956827000038 ],
							[ 10.607879398000023, 7.110197553000035 ],
							[ 10.604216367000049, 7.120269864000022 ],
							[ 10.604216557000029, 7.133088559000043 ],
							[ 10.599638841000058, 7.14499193100005 ],
							[ 10.592313773000058, 7.146822959000076 ],
							[ 10.584073065000041, 7.153232675000027 ],
							[ 10.578300563000028, 7.159005100000059 ],
							[ 10.576057211000034, 7.159752830000059 ],
							[ 10.571285241000055, 7.143846582000037 ],
							[ 10.568900254000027, 7.120782898000073 ],
							[ 10.568900317000043, 7.09533311000007 ],
							[ 10.566514264000034, 7.084994288000075 ],
							[ 10.56412778400005, 7.07386028600007 ],
							[ 10.563332796000054, 7.051591377000022 ],
							[ 10.565718856000046, 7.037276396000038 ],
							[ 10.560946670000021, 7.02057448100004 ],
							[ 10.556970342000056, 6.995124808000071 ],
							[ 10.553789015000064, 6.979218826000022 ],
							[ 10.548221492000039, 6.970470361000025 ],
							[ 10.544245636000028, 6.952973898000039 ],
							[ 10.545041197000046, 6.945021111000074 ],
							[ 10.537882977000038, 6.936272698000039 ],
							[ 10.530725441000072, 6.929910196000037 ],
							[ 10.529134545000034, 6.924343023000063 ],
							[ 10.510047119000035, 6.919571426000061 ],
							[ 10.500503538000032, 6.912413672000071 ],
							[ 10.496526498000037, 6.902074337000045 ],
							[ 10.490164481000022, 6.900484200000051 ],
							[ 10.47425865200006, 6.91161818300003 ],
							[ 10.462328938000041, 6.91320883800006 ],
							[ 10.433698798000023, 6.914004357000067 ],
							[ 10.426540396000064, 6.91002760300006 ],
							[ 10.359734798000034, 6.905255896000028 ],
							[ 10.315993198000058, 6.900484032000065 ],
							[ 10.227714432000028, 6.890940303000036 ],
							[ 10.21419405100005, 6.89094011800006 ],
							[ 10.203060056000027, 6.905255683000064 ],
							[ 10.176019802000042, 6.940248877000045 ],
							[ 10.175224424000021, 6.945815902000049 ],
							[ 10.179996266000046, 6.945021243000042 ],
							[ 10.181587393000029, 6.952973710000037 ],
							[ 10.183973076000029, 6.964903414000048 ],
							[ 10.183973091000041, 6.973651939000035 ],
							[ 10.181587130000025, 6.984786032000045 ],
							[ 10.180792010000062, 6.989557600000069 ],
							[ 10.175224583000045, 6.989557691000073 ],
							[ 10.172839384000042, 6.99751077600007 ],
							[ 10.174428873000068, 7.003873217000034 ],
							[ 10.177610497000046, 7.012621986000056 ],
							[ 10.179200918000049, 7.016598343000055 ],
							[ 10.173634813000035, 7.021369960000072 ],
							[ 10.168067180000037, 7.022165381000036 ],
							[ 10.167271778000043, 7.028527825000026 ],
							[ 10.167271442000072, 7.034094817000039 ],
							[ 10.161704077000024, 7.038071073000026 ],
							[ 10.155001532000028, 7.041422961000023 ],
							[ 10.14341255100004, 7.026936940000041 ],
							[ 10.110009955000066, 6.991943819000028 ],
							[ 10.062292174000049, 6.953769160000036 ],
							[ 10.032070109000074, 6.927524024000036 ],
							[ 10.017754397000033, 6.910027412000034 ],
							[ 10.012982260000058, 6.901279372000033 ],
							[ 9.998667474000058, 6.897302688000025 ],
							[ 9.970036736000054, 6.871057377000056 ],
							[ 9.955721082000025, 6.844017430000065 ],
							[ 9.92788504400005, 6.821748763000073 ],
							[ 9.895278050000059, 6.789936886000021 ],
							[ 9.884144036000066, 6.773235384000031 ],
							[ 9.873805294000022, 6.772439851000058 ],
							[ 9.861875573000077, 6.780393253000057 ],
							[ 9.845174001000032, 6.781188618000044 ],
							[ 9.820519625000031, 6.781188432000022 ],
							[ 9.816819109000051, 6.788257155000053 ],
							[ 9.813783414000056, 6.787245220000045 ],
							[ 9.806701669000063, 6.791292117000069 ],
							[ 9.804678027000023, 6.786233361000029 ],
							[ 9.798606704000065, 6.785221454000066 ],
							[ 9.793547517000036, 6.789269053000055 ],
							[ 9.785453976000042, 6.795339551000041 ],
							[ 9.779382834000046, 6.786233497000069 ],
							[ 9.778370911000025, 6.776116013000035 ],
							[ 9.774324410000077, 6.768021697000052 ],
							[ 9.771289072000059, 6.761950440000021 ],
							[ 9.775336435000042, 6.756891687000063 ],
							[ 9.775336130000028, 6.749809187000039 ],
							[ 9.765217674000041, 6.737668056000075 ],
							[ 9.760159730000055, 6.721479299000066 ],
							[ 9.755100147000064, 6.704278828000042 ],
							[ 9.761171513000022, 6.692137747000061 ],
							[ 9.757124112000042, 6.687078946000042 ],
							[ 9.762183234000076, 6.676960947000055 ],
							[ 9.760159313000031, 6.670890286000031 ],
							[ 9.758136074000049, 6.667854804000058 ],
							[ 9.75813579000004, 6.662796094000043 ],
							[ 9.757123831000058, 6.651666030000058 ],
							[ 9.748018391000073, 6.65166608800007 ],
							[ 9.745994354000061, 6.639525007000032 ],
							[ 9.741947455000059, 6.630418673000065 ],
							[ 9.740935162000028, 6.618277485000021 ],
							[ 9.732841685000039, 6.605124477000061 ],
							[ 9.725758745000064, 6.581853257000034 ],
							[ 9.722723128000041, 6.570723402000056 ],
							[ 9.717664873000047, 6.560605999000074 ],
							[ 9.717664531000025, 6.545429246000026 ],
							[ 9.710581692000062, 6.525193705000049 ],
							[ 9.706534224000052, 6.512040125000055 ],
							[ 9.696416606000071, 6.512040008000042 ],
							[ 9.692369297000027, 6.515075801000023 ],
							[ 9.688323154000045, 6.513051974000064 ],
							[ 9.679216472000064, 6.512040114000058 ],
							[ 9.672134530000051, 6.512040182000021 ],
							[ 9.665051609000045, 6.513052036000033 ],
							[ 9.661003949000076, 6.52418196800005 ],
							[ 9.655945935000034, 6.530252388000065 ],
							[ 9.652909993000037, 6.529240522000066 ],
							[ 9.648862608000059, 6.52519355000004 ],
							[ 9.643804451000051, 6.525193718000025 ],
							[ 9.637733309000055, 6.523170054000047 ],
							[ 9.631662234000032, 6.521146290000047 ],
							[ 9.620533271000056, 6.525193782000031 ],
							[ 9.612439326000072, 6.527216825000039 ],
							[ 9.608391734000065, 6.529240619000063 ],
							[ 9.599126590000026, 6.530925374000049 ],
							[ 9.602522945000032, 6.538202142000046 ],
							[ 9.606478803000073, 6.571820814000034 ],
							[ 9.622298331000025, 6.664106335000042 ],
							[ 9.636141940000073, 6.712885932000063 ],
							[ 9.646029545000033, 6.738594157000023 ],
							[ 9.648666375000062, 6.799238612000067 ],
							[ 9.643392579000022, 6.843403781000063 ],
							[ 9.641414530000077, 6.866475570000034 ],
							[ 9.645699592000028, 6.894820233000075 ],
							[ 9.664156716000036, 6.935689386000035 ],
							[ 9.689206032000072, 6.986446886000067 ],
							[ 9.69975286600004, 7.011495457000024 ],
							[ 9.731563404000042, 7.066891503000022 ],
							[ 9.73490858100007, 7.068553981000036 ],
							[ 9.751194600000076, 7.066351385000075 ],
							[ 9.766398218000063, 7.066319926000062 ],
							[ 9.771835582000051, 7.069562908000023 ],
							[ 9.79794716300006, 7.091204684000047 ],
							[ 9.815358217000039, 7.106354383000053 ],
							[ 9.819707278000067, 7.10851457900003 ],
							[ 9.819716999000036, 7.112854021000032 ],
							[ 9.819734012000026, 7.120447826000031 ],
							[ 9.821935439000072, 7.133461304000036 ],
							[ 9.823051143000043, 7.146477197000024 ],
							[ 9.823099955000032, 7.168174386000032 ],
							[ 9.822802327000034, 7.17095753500007 ],
							[ 9.824009106000062, 7.172336038000026 ],
							[ 9.834555820000048, 7.19936279500007 ],
							[ 9.835214464000046, 7.217160752000041 ],
							[ 9.828622864000067, 7.236277237000024 ],
							[ 9.825594417000048, 7.251420278000069 ],
							[ 9.824958578000064, 7.254598087000034 ],
							[ 9.82829311100005, 7.25737106400004 ],
							[ 9.840817212000047, 7.279782663000049 ],
							[ 9.855978841000024, 7.325266290000059 ],
							[ 9.856154897000067, 7.32598163800003 ],
							[ 9.86259853100006, 7.334067546000028 ],
							[ 9.868047900000022, 7.340563988000042 ],
							[ 9.866991733000077, 7.35358474700007 ],
							[ 9.873567438000066, 7.376351057000022 ],
							[ 9.880143944000054, 7.399117205000039 ],
							[ 9.887223316000075, 7.420144230000062 ],
							[ 9.890771534000066, 7.425427854000077 ],
							[ 9.892843631000062, 7.430306187000042 ],
							[ 9.894791627000075, 7.436984466000069 ],
							[ 9.901315411000041, 7.471153664000042 ],
							[ 9.907046217000072, 7.496842796000067 ],
							[ 9.908856117000028, 7.499665687000061 ],
							[ 9.911180113000057, 7.501716188000046 ],
							[ 9.92123883000005, 7.506574698000065 ],
							[ 9.925113246000024, 7.510420315000033 ],
							[ 9.925374307000027, 7.511961922000069 ],
							[ 9.924736793000022, 7.514533766000056 ],
							[ 9.912678206000066, 7.534355245000029 ],
							[ 9.911152208000033, 7.542327163000039 ],
							[ 9.905545217000054, 7.567015947000073 ],
							[ 9.904528083000059, 7.572416043000032 ],
							[ 9.906648963000066, 7.596314555000049 ],
							[ 9.905385451000029, 7.606342011000038 ],
							[ 9.905205686000045, 7.607648196000071 ],
							[ 9.907878668000023, 7.62469843100007 ],
							[ 9.912262088000034, 7.63770568800004 ],
							[ 9.911978703000045, 7.63813194100004 ],
							[ 9.905759961000058, 7.647485288000041 ],
							[ 9.905215989000055, 7.64748663000006 ],
							[ 9.880253320000065, 7.670328898000037 ],
							[ 9.863971941000045, 7.685555437000062 ],
							[ 9.863546855000038, 7.686014207000028 ],
							[ 9.844970077000028, 7.700787526000056 ],
							[ 9.79824771400007, 7.726928138000062 ],
							[ 9.786305708000043, 7.738886883000021 ],
							[ 9.763514426000029, 7.766056052000067 ],
							[ 9.729833930000041, 7.791075751000051 ],
							[ 9.658112833000075, 7.845452627000043 ],
							[ 9.624419647000025, 7.871547333000024 ],
							[ 9.583093283000039, 7.892227009000067 ],
							[ 9.57004166300004, 7.898756596000055 ],
							[ 9.551536215000056, 7.897699787000022 ],
							[ 9.541734892000022, 7.894460084000059 ],
							[ 9.503637, 7.893429235000042 ],
							[ 9.487304469000037, 7.88911193000007 ],
							[ 9.458993965000047, 7.880469763000065 ],
							[ 9.442655408000064, 7.870726425000043 ],
							[ 9.433934047000037, 7.858803648000048 ],
							[ 9.41558550700006, 7.850990525000043 ],
							[ 9.411067563000074, 7.849066547000064 ],
							[ 9.397112453000034, 7.844736357000045 ],
							[ 9.396453449000035, 7.844531926000059 ],
							[ 9.386640395000029, 7.844702598000026 ],
							[ 9.37807194100003, 7.843384307000065 ],
							[ 9.314789852000047, 7.834815056000025 ],
							[ 9.272602082000049, 7.822949485000038 ],
							[ 9.225141492000034, 7.818335170000069 ],
							[ 9.19020481900003, 7.810424781000052 ],
							[ 9.156585667000058, 7.797900769000023 ],
							[ 9.130218667000065, 7.78801287400006 ],
							[ 9.12033103300007, 7.793286321000039 ],
							[ 9.115717033000067, 7.808447318000049 ],
							[ 9.124285592000035, 7.826904642000045 ],
							[ 9.140106358000025, 7.848657692000074 ],
							[ 9.149994024000023, 7.859863892000021 ],
							[ 9.162518478000038, 7.86909242400003 ],
							[ 9.170429488000025, 7.882276024000021 ],
							[ 9.172170548000054, 7.886630903000025 ],
							[ 9.174623688000054, 7.887175952000064 ],
							[ 9.178231092000033, 7.888528811000072 ],
							[ 9.181838287000062, 7.891684953000038 ],
							[ 9.183191536000038, 7.89574301600004 ],
							[ 9.180937096000036, 7.903859024000042 ],
							[ 9.179125792000036, 7.908083360000035 ],
							[ 9.186791677000031, 7.911508424000033 ],
							[ 9.209753932000069, 7.921768581000038 ],
							[ 9.220373356000039, 7.926513076000049 ],
							[ 9.250726435000047, 7.944725045000041 ],
							[ 9.281080473000031, 7.955854941000041 ],
							[ 9.305362942000045, 7.969008002000066 ],
							[ 9.314585523000062, 7.974054672000022 ],
							[ 9.319115787000044, 7.976533354000026 ],
							[ 9.335518035000064, 7.985507863000066 ],
							[ 9.33932298700006, 7.990242065000075 ],
							[ 9.346026618000053, 8.001879574000043 ],
							[ 9.351713268000026, 8.006593796000061 ],
							[ 9.359693282000023, 8.012503744000071 ],
							[ 9.368829581000057, 8.018436358000031 ],
							[ 9.379050327000073, 8.027843431000065 ],
							[ 9.387053299000058, 8.032602446000055 ],
							[ 9.396162853000021, 8.039684437000062 ],
							[ 9.404143196000064, 8.045594246000064 ],
							[ 9.415376428000059, 8.06192500700007 ],
							[ 9.414026503000059, 8.071104061000028 ],
							[ 9.403423478000036, 8.080102937000049 ],
							[ 9.398653037000031, 8.086914160000049 ],
							[ 9.395036955000023, 8.093748080000069 ],
							[ 9.392556123000077, 8.101754226000025 ],
							[ 9.392219410000052, 8.117857920000063 ],
							[ 9.391909803000033, 8.132811544000049 ],
							[ 9.386946785000021, 8.148825743000032 ],
							[ 9.391430075000073, 8.155817137000042 ],
							[ 9.400613899000064, 8.159449711000036 ],
							[ 9.40627661700006, 8.165313663000063 ],
							[ 9.411823419000029, 8.176929396000048 ],
							[ 9.405823696000027, 8.187169185000073 ],
							[ 9.397533645000067, 8.196212756000023 ],
							[ 9.390469302000042, 8.201827967000042 ],
							[ 9.384363256000029, 8.204757733000065 ],
							[ 9.382139199000051, 8.205823874000032 ],
							[ 9.380124020000039, 8.20858656300004 ],
							[ 9.37483304400007, 8.211809183000071 ],
							[ 9.371249134000038, 8.215108005000047 ],
							[ 9.364250215000027, 8.218253103000052 ],
							[ 9.358958381000036, 8.22147549400006 ],
							[ 9.353749341000025, 8.222971030000053 ],
							[ 9.346748426000033, 8.226116072000025 ],
							[ 9.341457459000026, 8.229338784000049 ],
							[ 9.336165264000044, 8.232561142000066 ],
							[ 9.329566937000038, 8.236310146000051 ],
							[ 9.325903046000064, 8.245444233000057 ],
							[ 9.323423626000022, 8.253451275000032 ],
							[ 9.32551933600007, 8.263849221000044 ],
							[ 9.330030185000055, 8.269691594000051 ],
							[ 9.335693461000062, 8.275555731000054 ],
							[ 9.342466535000028, 8.283742785000072 ],
							[ 9.346856817000059, 8.295336783000039 ],
							[ 9.35018365600007, 8.302306275000035 ],
							[ 9.354546560000074, 8.315049066000029 ],
							[ 9.356623615000046, 8.326597098000036 ],
							[ 9.359949792000066, 8.333567622000032 ],
							[ 9.36327667200004, 8.34053698300005 ],
							[ 9.367830490000074, 8.344077984000023 ],
							[ 9.376966690000074, 8.35001088100006 ],
							[ 9.382753106000052, 8.350123292000035 ],
							[ 9.389716940000028, 8.349109536000071 ],
							[ 9.40140605800002, 8.343584010000029 ],
							[ 9.408443628000043, 8.33911895500006 ],
							[ 9.420156359000032, 8.33244310300006 ],
							[ 9.431823709000071, 8.328068630000075 ],
							[ 9.443512653000028, 8.322544019000077 ],
							[ 9.452839675000064, 8.319273951000071 ],
							[ 9.466866544000027, 8.312644043000034 ],
							[ 9.475036727000031, 8.309351969000033 ],
							[ 9.483206759000041, 8.306058879000034 ],
							[ 9.492509578000067, 8.303939797000055 ],
							[ 9.498297012000023, 8.304052369000033 ],
							[ 9.504057080000052, 8.305316037000068 ],
							[ 9.512203396000075, 8.303174039000055 ],
							[ 9.518010193000066, 8.302137405000053 ],
							[ 9.524930066000024, 8.303422832000024 ],
							[ 9.535340061000056, 8.303627102000064 ],
							[ 9.54343964100002, 8.303785314000038 ],
							[ 9.546886581000024, 8.305003111000076 ],
							[ 9.561973597000076, 8.302996550000046 ],
							[ 9.56665050600003, 8.300786854000023 ],
							[ 9.584077011000034, 8.297676067000054 ],
							[ 9.592200154000068, 8.296684316000039 ],
							[ 9.600370560000044, 8.29339115700003 ],
							[ 9.609673514000065, 8.291272220000053 ],
							[ 9.615387130000045, 8.294835929000044 ],
							[ 9.617530084000066, 8.302932677000058 ],
							[ 9.613750697000057, 8.31781871000004 ],
							[ 9.612330567000072, 8.330449071000032 ],
							[ 9.61211290500006, 8.340802291000045 ],
							[ 9.611966946000052, 8.350235397000063 ],
							[ 9.619939005000049, 8.349229993000051 ],
							[ 9.622226369000032, 8.349551357000053 ],
							[ 9.623699554000041, 8.351499914000044 ],
							[ 9.624685319000037, 8.354587421000076 ],
							[ 9.624523762000024, 8.355725841000037 ],
							[ 9.624042147000068, 8.360604708000039 ],
							[ 9.621118977000037, 8.370690381000031 ],
							[ 9.618302376000031, 8.377766649000023 ],
							[ 9.622797155000058, 8.383587919000036 ],
							[ 9.620701731000054, 8.38540942000003 ],
							[ 9.618506508000053, 8.387319517000037 ],
							[ 9.621475490000023, 8.388993169000059 ],
							[ 9.628418943000042, 8.386976372000049 ],
							[ 9.63136686200005, 8.386625197000058 ],
							[ 9.63530931400004, 8.386155011000028 ],
							[ 9.639204926000048, 8.385524670000052 ],
							[ 9.647578316000022, 8.384702778000076 ],
							[ 9.652753883000059, 8.384195198000043 ],
							[ 9.654766218000077, 8.382353886000033 ],
							[ 9.664380089000076, 8.382781116000046 ],
							[ 9.668315003000032, 8.381025267000041 ],
							[ 9.679941031000055, 8.379610971000034 ],
							[ 9.683875836000027, 8.377855215000068 ],
							[ 9.693578516000059, 8.376355060000037 ],
							[ 9.695591163000074, 8.374514652000073 ],
							[ 9.703282235000074, 8.374856055000066 ],
							[ 9.707128633000025, 8.375026695000031 ],
							[ 9.71106270100006, 8.373270945000058 ],
							[ 9.71675422900006, 8.372530905000076 ],
							[ 9.723361899000054, 8.371671725000056 ],
							[ 9.726472993000073, 8.371267191000072 ],
							[ 9.733457500000043, 8.370358495000062 ],
							[ 9.756654558000037, 8.367341871000065 ],
							[ 9.761324192000075, 8.36971078800002 ],
							[ 9.764990725000075, 8.373734408000075 ],
							[ 9.774427589000027, 8.378014622000023 ],
							[ 9.780140166000024, 8.381554563000066 ],
							[ 9.784161341000072, 8.38241967700003 ],
							[ 9.796966787000031, 8.390598161000071 ],
							[ 9.80233118700005, 8.39132313500005 ],
							[ 9.809424625000077, 8.397447531000068 ],
							[ 9.816094522000071, 8.401208034000035 ],
							[ 9.821446295000044, 8.40279115800007 ],
							[ 9.824240602000032, 8.405097903000069 ],
							[ 9.836102211000025, 8.412488803000031 ],
							[ 9.84106274100003, 8.416171902000031 ],
							[ 9.856025469000031, 8.425495087000058 ],
							[ 9.862776903000054, 8.427529272000072 ],
							[ 9.869446768000046, 8.431289639000056 ],
							[ 9.879366826000023, 8.438655740000058 ],
							[ 9.89091415200005, 8.447825428000044 ],
							[ 9.895791925000026, 8.453235542000073 ],
							[ 9.905629963000024, 8.462328942000056 ],
							[ 9.908881131000044, 8.465934808000043 ],
							[ 9.915385361000062, 8.473148429000048 ],
							[ 9.920263276000071, 8.478557638000041 ],
							[ 9.926848205000056, 8.484044169000072 ],
							[ 9.933517496000036, 8.487804499000049 ],
							[ 9.943439260000048, 8.495171688000028 ],
							[ 9.950106657000049, 8.498931999000035 ],
							[ 9.953359726000031, 8.502536908000025 ],
							[ 9.958235775000048, 8.507947872000045 ],
							[ 9.963113658000054, 8.513357081000038 ],
							[ 9.971326841000064, 8.520648018000031 ],
							[ 9.976286808000054, 8.524330987000042 ],
							[ 9.982876797000074, 8.529817605000062 ],
							[ 9.992796738000038, 8.537183736000031 ],
							[ 9.997757150000041, 8.540867837000064 ],
							[ 10.004177208000044, 8.549806480000029 ],
							[ 10.008015801000056, 8.553002418000062 ],
							[ 10.010766962000048, 8.555293168000048 ],
							[ 10.014017158000058, 8.558899800000063 ],
							[ 10.022227285000042, 8.56618985800003 ],
							[ 10.025476556000058, 8.569796668000038 ],
							[ 10.032066414000042, 8.575282119000065 ],
							[ 10.043606011000065, 8.584452646000045 ],
							[ 10.051827528000047, 8.591742580000073 ],
							[ 10.061407077000069, 8.606014274000074 ],
							[ 10.066207075000023, 8.613150699000073 ],
							[ 10.071085703000051, 8.618559862000041 ],
							[ 10.07596561400004, 8.623970084000064 ],
							[ 10.080836562000059, 8.629379138000047 ],
							[ 10.085717340000031, 8.634789523000052 ],
							[ 10.087177330000031, 8.640045206000025 ],
							[ 10.097175597000046, 8.645686115000046 ],
							[ 10.103606203000027, 8.654624956000021 ],
							[ 10.108397430000025, 8.661761398000067 ],
							[ 10.113277336000067, 8.66717056300007 ],
							[ 10.116447342000072, 8.672503464000044 ],
							[ 10.12302679000004, 8.677990965000049 ],
							[ 10.127987031000032, 8.681673852000074 ],
							[ 10.132865782000067, 8.687083330000064 ],
							[ 10.137746969000034, 8.692492439000034 ],
							[ 10.140996822000034, 8.696100174000037 ],
							[ 10.149206890000073, 8.703389069000025 ],
							[ 10.152456950000044, 8.706995867000046 ],
							[ 10.15904609200004, 8.712482411000053 ],
							[ 10.16229633100005, 8.716089120000049 ],
							[ 10.165546636000045, 8.719696079000073 ],
							[ 10.170427592000067, 8.725105322000047 ],
							[ 10.177096432000042, 8.728865734000067 ],
							[ 10.180347571000027, 8.732471428000053 ],
							[ 10.183437208000043, 8.739530400000035 ],
							[ 10.188146392000021, 8.748394135000069 ],
							[ 10.194737395000061, 8.753879749000021 ],
							[ 10.206195835000074, 8.764776014000063 ],
							[ 10.209445876000075, 8.768383134000032 ],
							[ 10.214327001000072, 8.773792064000077 ],
							[ 10.224076077000063, 8.784611455000061 ],
							[ 10.229046757000049, 8.788296897000066 ],
							[ 10.235707135000041, 8.792056269000057 ],
							[ 10.24559615000004, 8.804408306000028 ],
							[ 10.248636126000065, 8.808208494000041 ],
							[ 10.256927371000074, 8.813771418000044 ],
							[ 10.261806406000062, 8.819181403000073 ],
							[ 10.266937034000023, 8.819411320000029 ],
							[ 10.276936767000052, 8.825052112000037 ],
							[ 10.286856510000064, 8.832418161000021 ],
							[ 10.291896827000073, 8.834375554000076 ],
							[ 10.298485837000044, 8.839862120000021 ],
							[ 10.30507560500007, 8.845348583000032 ],
							[ 10.325076315000047, 8.856629519000023 ],
							[ 10.329875701000049, 8.863764684000046 ],
							[ 10.334667310000043, 8.870902110000031 ],
							[ 10.341096931000038, 8.879840681000076 ],
							[ 10.350927528000057, 8.888933952000059 ],
							[ 10.357596121000029, 8.892694489000064 ],
							[ 10.362556548000043, 8.896377582000071 ],
							[ 10.369147189000046, 8.901864016000047 ],
							[ 10.372397221000028, 8.905470812000033 ],
							[ 10.375645617000032, 8.909076475000063 ],
							[ 10.380526566000071, 8.914487123000072 ],
							[ 10.388815995000073, 8.920049905000042 ],
							[ 10.397196863000033, 8.923887447000027 ],
							[ 10.400446809000073, 8.927494013000057 ],
							[ 10.406956527000034, 8.934706445000074 ],
							[ 10.420206217000043, 8.943953757000031 ],
							[ 10.426877133000062, 8.947713583000052 ],
							[ 10.433626099000037, 8.94974718800006 ],
							[ 10.440296106000062, 8.953507330000036 ],
							[ 10.445346888000074, 8.955464283000026 ],
							[ 10.450385779000044, 8.957421197000031 ],
							[ 10.463807207000059, 8.963215782000077 ],
							[ 10.468936020000058, 8.963446862000069 ],
							[ 10.479186837000043, 8.96390711600003 ],
							[ 10.489356029000021, 8.966094913000063 ],
							[ 10.497977038000045, 8.964752915000076 ],
							[ 10.509776902000056, 8.968743532000076 ],
							[ 10.518396205000045, 8.967401620000032 ],
							[ 10.530277268000077, 8.969665451000026 ],
							[ 10.538827039000068, 8.970049720000077 ],
							[ 10.544027170000049, 8.968554773000051 ],
							[ 10.55598750300004, 8.969092185000022 ],
							[ 10.56624712200005, 8.969553943000051 ],
							[ 10.57470716000006, 8.971664151000027 ],
							[ 10.579746404000048, 8.973621556000069 ],
							[ 10.588287212000068, 8.974005865000038 ],
							[ 10.596746347000021, 8.976116127000068 ],
							[ 10.603165520000061, 8.98505582100006 ],
							[ 10.606257540000058, 8.992115126000044 ],
							[ 10.609505625000054, 8.995720966000022 ],
							[ 10.61430731400003, 9.002856931000053 ],
							[ 10.619176009000057, 9.008267299000067 ],
							[ 10.622701717000041, 9.013994323000077 ],
							[ 10.627225946000067, 9.019009407000055 ],
							[ 10.630066076000048, 9.031248160000075 ],
							[ 10.629816236000067, 9.03642723400003 ],
							[ 10.632905820000076, 9.043486886000039 ],
							[ 10.634366822000061, 9.048742021000066 ],
							[ 10.637447484000063, 9.055801670000051 ],
							[ 10.637197750000041, 9.060980742000027 ],
							[ 10.640036828000063, 9.073219398000049 ],
							[ 10.637916479000069, 9.081773641000041 ],
							[ 10.632547443000021, 9.086723368000037 ],
							[ 10.62724677500006, 9.089944667000054 ],
							[ 10.62252499300007, 9.094029644000045 ],
							[ 10.623550652000063, 9.099114366000038 ],
							[ 10.625289746000021, 9.103053330000023 ],
							[ 10.632630085000073, 9.11110119500006 ],
							[ 10.636120005000066, 9.118977825000059 ],
							[ 10.63576037100006, 9.126683917000037 ],
							[ 10.633660238000061, 9.13045222900007 ],
							[ 10.629639435000058, 9.134134084000038 ],
							[ 10.625610592000044, 9.13781648500003 ],
							[ 10.617739796000023, 9.141328115000022 ],
							[ 10.613539518000039, 9.148863940000069 ],
							[ 10.615110347000041, 9.156655353000076 ],
							[ 10.612573732000044, 9.165629379000052 ],
							[ 10.611850480000044, 9.168187323000041 ],
							[ 10.612002632000042, 9.170292757000027 ],
							[ 10.610369800000058, 9.175749646000043 ],
							[ 10.614236805000075, 9.182785085000035 ],
							[ 10.610487181000053, 9.189536803000067 ],
							[ 10.599906802000021, 9.195980807000069 ],
							[ 10.599657018000073, 9.201160386000026 ],
							[ 10.595719484000028, 9.208254636000049 ],
							[ 10.59722970100006, 9.209915122000041 ],
							[ 10.596870293000052, 9.217621687000076 ],
							[ 10.596609973000056, 9.223400861000073 ],
							[ 10.592309758000056, 9.23286323800005 ],
							[ 10.588199648000057, 9.238471957000058 ],
							[ 10.580149531000075, 9.245836311000062 ],
							[ 10.579890082000077, 9.25161545900005 ],
							[ 10.579259966000052, 9.265102309000042 ],
							[ 10.578909818000056, 9.27280823600006 ],
							[ 10.578640053000072, 9.278587418000029 ],
							[ 10.578370303000042, 9.284367727000074 ],
							[ 10.578099980000047, 9.290146926000034 ],
							[ 10.577750327000047, 9.297853394000072 ],
							[ 10.579489891000037, 9.301791957000034 ],
							[ 10.579319915000042, 9.305644882000024 ],
							[ 10.580880453000077, 9.313437547000035 ],
							[ 10.580610124000032, 9.319215590000056 ],
							[ 10.580260518000046, 9.32692262300003 ],
							[ 10.57806967700003, 9.332616776000066 ],
							[ 10.574129970000058, 9.334373492000054 ],
							[ 10.570110302000046, 9.338054639000063 ],
							[ 10.567919796000069, 9.343749301000059 ],
							[ 10.559869793000075, 9.351113139000063 ],
							[ 10.561619658000041, 9.35505173100006 ],
							[ 10.561440369000024, 9.358905823000043 ],
							[ 10.561260492000031, 9.362758768000049 ],
							[ 10.566580077000026, 9.372647269000026 ],
							[ 10.564390464000041, 9.378341874000057 ],
							[ 10.560370216000024, 9.382024035000029 ],
							[ 10.560009857000068, 9.38973050900006 ],
							[ 10.563589942000021, 9.395680387000027 ],
							[ 10.569179507000058, 9.399789704000057 ],
							[ 10.571019872000022, 9.40180091700006 ],
							[ 10.578350245000024, 9.409849219000023 ],
							[ 10.579919749000055, 9.417640676000076 ],
							[ 10.57956034700004, 9.42534713200007 ],
							[ 10.579199789000029, 9.433053034000068 ],
							[ 10.578940178000039, 9.438833250000073 ],
							[ 10.57666017400004, 9.44645409900005 ],
							[ 10.574650032000022, 9.448294678000025 ],
							[ 10.572539999000071, 9.452062606000027 ],
							[ 10.570349424000028, 9.45775724300006 ],
							[ 10.566149618000054, 9.465291799000056 ],
							[ 10.560210050000023, 9.468889398000044 ],
							[ 10.556180212000072, 9.472571530000039 ],
							[ 10.548219724000035, 9.478009461000056 ],
							[ 10.546210407000046, 9.479851007000036 ],
							[ 10.54165735600003, 9.482288594000067 ],
							[ 10.53790669600005, 9.489040576000036 ],
							[ 10.533220099000062, 9.492265772000053 ],
							[ 10.530586387000028, 9.492876822000028 ],
							[ 10.523667403000047, 9.500510295000026 ],
							[ 10.518485748000046, 9.503770691000057 ],
							[ 10.516327067000077, 9.510561109000037 ],
							[ 10.521126876000039, 9.51769753700006 ],
							[ 10.529505852000057, 9.521533736000038 ],
							[ 10.534465789000024, 9.525217246000068 ],
							[ 10.536248355000055, 9.527194976000033 ],
							[ 10.540967255000055, 9.532430551000061 ],
							[ 10.547556759000031, 9.537917054000047 ],
							[ 10.552517103000071, 9.541600318000064 ],
							[ 10.557556154000054, 9.543556887000022 ],
							[ 10.562516122000034, 9.547241165000059 ],
							[ 10.569106204000036, 9.552727836000031 ],
							[ 10.575776167000072, 9.556488286000047 ],
							[ 10.580735912000023, 9.560171191000052 ],
							[ 10.589106816000026, 9.564007755000034 ],
							[ 10.602865906000034, 9.562895532000027 ],
							[ 10.608065710000062, 9.561400343000059 ],
							[ 10.61336717000006, 9.55817885700003 ],
							[ 10.618577106000032, 9.556682327000033 ],
							[ 10.625486518000059, 9.555263588000059 ],
							[ 10.634196419000034, 9.552194617000055 ],
							[ 10.639406191000035, 9.55069949500006 ],
							[ 10.646407259000057, 9.547554176000062 ],
							[ 10.651616914000044, 9.546058883000057 ],
							[ 10.656827100000044, 9.544562383000027 ],
							[ 10.663746941000056, 9.543143416000021 ],
							[ 10.672445990000028, 9.540075289000072 ],
							[ 10.677576817000045, 9.540306303000023 ],
							[ 10.684326263000059, 9.542339250000055 ],
							[ 10.704417489000036, 9.551892989000066 ],
							[ 10.709377346000053, 9.555576431000077 ],
							[ 10.716047131000039, 9.559337762000041 ],
							[ 10.722797132000039, 9.561371134000069 ],
							[ 10.73117755100003, 9.565208383000027 ],
							[ 10.73434624500004, 9.570541448000029 ],
							[ 10.739227190000065, 9.575950600000056 ],
							[ 10.744187166000074, 9.579632998000022 ],
							[ 10.750766933000023, 9.585120442000061 ],
							[ 10.754016629000034, 9.588727190000043 ],
							[ 10.757276638000064, 9.592333860000053 ],
							[ 10.762146252000036, 9.597742760000074 ],
							[ 10.770366441000021, 9.605033086000049 ],
							[ 10.778736987000059, 9.608870449000051 ],
							[ 10.783786753000072, 9.610826696000061 ],
							[ 10.78890726800006, 9.611057345000063 ],
							[ 10.80078719200003, 9.61332208400006 ],
							[ 10.809247073000051, 9.615433692000067 ],
							[ 10.81949624300006, 9.61589417600004 ],
							[ 10.829747160000068, 9.616354941000054 ],
							[ 10.838215826000066, 9.618465269000069 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Yobe',
				admin1Pcod: 'NG036',
				admin1RefN: 'Yobe',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 11.096937,
				Shape_Area: 3.726688
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 11.221810269000059, 13.373996890000058 ],
							[ 11.255178563000072, 13.373996775000023 ],
							[ 11.279924579000067, 13.374472415000071 ],
							[ 11.297735036000063, 13.374963736000041 ],
							[ 11.340290990000028, 13.373512989000062 ],
							[ 11.377044479000062, 13.37206274600004 ],
							[ 11.404609850000043, 13.369643955000072 ],
							[ 11.416551797000068, 13.365663666000046 ],
							[ 11.420180309000045, 13.365940144000035 ],
							[ 11.423669657000062, 13.365380250000044 ],
							[ 11.427169863000074, 13.364810072000068 ],
							[ 11.43280997100004, 13.362739576000024 ],
							[ 11.439410494000072, 13.359350350000057 ],
							[ 11.443100189000063, 13.359909809000044 ],
							[ 11.446800256000074, 13.360469718000047 ],
							[ 11.454380263000076, 13.36271927100006 ],
							[ 11.460410361000072, 13.362899883000068 ],
							[ 11.462513143000024, 13.362557763000041 ],
							[ 11.467688740000028, 13.363875698000072 ],
							[ 11.475032739000028, 13.367929846000038 ],
							[ 11.485469723000051, 13.365809112000022 ],
							[ 11.50838344500005, 13.354373187000022 ],
							[ 11.508639348000031, 13.354153266000026 ],
							[ 11.520914883000046, 13.352234580000072 ],
							[ 11.543643857000063, 13.346915122000041 ],
							[ 11.566856251000047, 13.335308776000034 ],
							[ 11.592971092000028, 13.324186453000038 ],
							[ 11.604576783000027, 13.317899727000054 ],
							[ 11.620052530000066, 13.313546992000056 ],
							[ 11.644715317000077, 13.304359346000069 ],
							[ 11.657289552000066, 13.301457166000034 ],
							[ 11.666477051000072, 13.296621225000024 ],
							[ 11.679051110000046, 13.29275225300006 ],
							[ 11.708067193000034, 13.285014912000065 ],
							[ 11.730312325000057, 13.280179038000028 ],
							[ 11.741435291000073, 13.274859302000038 ],
							[ 11.749172158000022, 13.276310222000063 ],
							[ 11.76368031800007, 13.273892422000074 ],
							[ 11.772385375000056, 13.271474840000053 ],
							[ 11.783508335000022, 13.268572961000075 ],
							[ 11.802367942000046, 13.266155065000078 ],
							[ 11.848310380000044, 13.255516112000066 ],
							[ 11.87926010800004, 13.248744938000073 ],
							[ 11.890866043000074, 13.241974654000046 ],
							[ 11.927136648000044, 13.224081793000039 ],
							[ 11.946964450000053, 13.208123433000026 ],
							[ 11.979365398000027, 13.185394510000037 ],
							[ 11.99870893800005, 13.168951881000055 ],
							[ 12.01128221700003, 13.15782944800003 ],
							[ 12.014183874000025, 13.151059307000025 ],
							[ 12.031109729000036, 13.137518 ],
							[ 12.042716177000045, 13.130747968000037 ],
							[ 12.046101429000032, 13.128329446000066 ],
							[ 12.054805572000078, 13.121558985000036 ],
							[ 12.066896401000065, 13.116723168000021 ],
							[ 12.071427761000052, 13.11350733200004 ],
							[ 12.073828641000034, 13.11320429400007 ],
							[ 12.085272661000033, 13.107981180000024 ],
							[ 12.096734026000036, 13.10480902200004 ],
							[ 12.106124238000064, 13.103705215000048 ],
							[ 12.118638481000062, 13.101549338000041 ],
							[ 12.129063946000031, 13.099410549000027 ],
							[ 12.130998130000023, 13.098631820000037 ],
							[ 12.131568182000024, 13.100910454000029 ],
							[ 12.13468380300003, 13.104155405000029 ],
							[ 12.139486095000052, 13.099352961000022 ],
							[ 12.142537235000077, 13.09896399400003 ],
							[ 12.149287365000077, 13.096886761000064 ],
							[ 12.151039943000058, 13.098508659000061 ],
							[ 12.149027577000027, 13.105323820000024 ],
							[ 12.150910606000025, 13.108569433000071 ],
							[ 12.157595738000055, 13.108115156000054 ],
							[ 12.161750039000026, 13.103247858000032 ],
							[ 12.163632328000062, 13.102144356000053 ],
							[ 12.170382214000028, 13.10370169600003 ],
							[ 12.172848441000042, 13.100845203000063 ],
							[ 12.177586821000034, 13.098314510000023 ],
							[ 12.180313344000069, 13.103896248000069 ],
							[ 12.18738836700004, 13.106557842000029 ],
							[ 12.17933916800007, 13.115060830000061 ],
							[ 12.176873379000028, 13.120382293000034 ],
							[ 12.178495528000042, 13.125185878000025 ],
							[ 12.183753129000024, 13.121031522000067 ],
							[ 12.188426686000071, 13.120317614000044 ],
							[ 12.194852574000038, 13.122005520000073 ],
							[ 12.197967715000061, 13.12252402200005 ],
							[ 12.202641331000052, 13.122719516000075 ],
							[ 12.206925342000034, 13.120512699000074 ],
							[ 12.211014633000048, 13.114800235000075 ],
							[ 12.218283106000058, 13.114087320000067 ],
							[ 12.226916821000032, 13.109607721000032 ],
							[ 12.230162230000076, 13.107661036000025 ],
							[ 12.233667232000073, 13.107336315000055 ],
							[ 12.235678681000024, 13.10993294900004 ],
							[ 12.238210306000042, 13.113437452000028 ],
							[ 12.24113053700006, 13.114605859000051 ],
							[ 12.251126974000044, 13.114411526000026 ],
							[ 12.257033240000055, 13.114346431000058 ],
							[ 12.265341985000077, 13.109607785000037 ],
							[ 12.270275241000036, 13.103636978000054 ],
							[ 12.272986024000033, 13.095564761000048 ],
							[ 12.272445052000023, 13.088834593000058 ],
							[ 12.274605187000077, 13.083351872000037 ],
							[ 12.277554040000041, 13.080984206000039 ],
							[ 12.287067441000033, 13.080028739000056 ],
							[ 12.287980684000047, 13.082271501000037 ],
							[ 12.282331121000027, 13.083683964000045 ],
							[ 12.280462138000075, 13.08613482800007 ],
							[ 12.281957190000071, 13.087464586000067 ],
							[ 12.283453399000052, 13.088793773000077 ],
							[ 12.286859621000076, 13.091909485000031 ],
							[ 12.289476432000072, 13.093114085000025 ],
							[ 12.293543761000024, 13.095797353000023 ],
							[ 12.297452445000033, 13.096229467000057 ],
							[ 12.302070194000066, 13.096073373000024 ],
							[ 12.306196749000037, 13.095797264000055 ],
							[ 12.31197168500006, 13.092496940000046 ],
							[ 12.31912359100005, 13.087820997000051 ],
							[ 12.323100019000037, 13.085379419000049 ],
							[ 12.327100057000052, 13.085621152000044 ],
							[ 12.33358901400004, 13.083680190000052 ],
							[ 12.33315114100003, 13.077644067000051 ],
							[ 12.336176874000046, 13.072693922000042 ],
							[ 12.339201555000045, 13.072968537000065 ],
							[ 12.340789533000077, 13.083669432000022 ],
							[ 12.343603490000021, 13.08589558500006 ],
							[ 12.347728860000075, 13.082045643000072 ],
							[ 12.352405059000034, 13.079295209000065 ],
							[ 12.355705373000035, 13.079019773000027 ],
							[ 12.361480714000038, 13.080394857000044 ],
							[ 12.362307096000052, 13.087821216000066 ],
							[ 12.363405649000072, 13.090022009000052 ],
							[ 12.368632370000057, 13.086721209000075 ],
							[ 12.368769518000022, 13.079130245000044 ],
							[ 12.370252077000032, 13.077128175000041 ],
							[ 12.374877603000073, 13.075866599000051 ],
							[ 12.390429801000039, 13.076809709000031 ],
							[ 12.393673616000058, 13.077064008000036 ],
							[ 12.394129994000025, 13.077099768000039 ],
							[ 12.394037180000055, 13.076184138000031 ],
							[ 12.395780095000077, 13.071965931000022 ],
							[ 12.400743839000029, 13.052540409000073 ],
							[ 12.405579463000038, 13.019634001000043 ],
							[ 12.405407808000064, 13.00149489000006 ],
							[ 12.405260545000033, 12.985931896000068 ],
							[ 12.400011140000061, 12.968621297000027 ],
							[ 12.388442347000023, 12.930471685000043 ],
							[ 12.378820247000021, 12.910335902000043 ],
							[ 12.375256203000049, 12.896043460000044 ],
							[ 12.377654477000021, 12.877483832000053 ],
							[ 12.378149475000043, 12.87706643100006 ],
							[ 12.391755681000063, 12.865593165000064 ],
							[ 12.393817107000075, 12.863854893000052 ],
							[ 12.406485595000049, 12.843516100000045 ],
							[ 12.446236996000039, 12.786693907000029 ],
							[ 12.447862541000063, 12.777409828000032 ],
							[ 12.45192195900006, 12.753778284000077 ],
							[ 12.470517394000069, 12.726640972000041 ],
							[ 12.477544796000075, 12.717757619000054 ],
							[ 12.489991435000036, 12.702022360000058 ],
							[ 12.494128991000025, 12.686815926000065 ],
							[ 12.490464609000071, 12.662413492000042 ],
							[ 12.481911546000049, 12.640411999000037 ],
							[ 12.479040878000035, 12.633027629000026 ],
							[ 12.469037915000058, 12.61753187000005 ],
							[ 12.466435284000056, 12.614185506000069 ],
							[ 12.462302316000034, 12.613118107000048 ],
							[ 12.456987045000062, 12.611745362000022 ],
							[ 12.443605731000048, 12.609584092000034 ],
							[ 12.443526678000069, 12.609368345000064 ],
							[ 12.443159958000024, 12.609735857000032 ],
							[ 12.437950751000074, 12.607742165000047 ],
							[ 12.434464990000038, 12.605051904000049 ],
							[ 12.431328510000071, 12.602698705000023 ],
							[ 12.430274386000065, 12.601006899000026 ],
							[ 12.429918701000076, 12.599989270000037 ],
							[ 12.429886307000061, 12.596586537000064 ],
							[ 12.431518914000037, 12.586362436000059 ],
							[ 12.433809833000055, 12.581413497000028 ],
							[ 12.433841425000026, 12.581362997000042 ],
							[ 12.433867659000043, 12.581324970000026 ],
							[ 12.433897945000069, 12.581307213000059 ],
							[ 12.433873793000032, 12.581275334000054 ],
							[ 12.431511495000052, 12.578156277000062 ],
							[ 12.429726929000026, 12.574008774000049 ],
							[ 12.428620310000042, 12.571440923000068 ],
							[ 12.427169480000032, 12.568070566000074 ],
							[ 12.42443578700005, 12.563282038000068 ],
							[ 12.422634381000023, 12.560127328000021 ],
							[ 12.420829534000063, 12.556967549000035 ],
							[ 12.419352966000076, 12.554381591000038 ],
							[ 12.415560172000028, 12.551879601000053 ],
							[ 12.41223997700007, 12.546629670000073 ],
							[ 12.409320249000075, 12.543629767000027 ],
							[ 12.406200421000051, 12.539509622000026 ],
							[ 12.404440332000036, 12.536319970000022 ],
							[ 12.402480077000064, 12.531999352000071 ],
							[ 12.400530288000027, 12.527689902000077 ],
							[ 12.398770302000059, 12.524499666000054 ],
							[ 12.398110742000028, 12.519479556000022 ],
							[ 12.395082994000063, 12.515708749000055 ],
							[ 12.388899880000054, 12.508010042000024 ],
							[ 12.385995445000049, 12.506662942000048 ],
							[ 12.385880821000057, 12.50660977800004 ],
							[ 12.376749588000052, 12.498780020000027 ],
							[ 12.370510010000032, 12.493200228000035 ],
							[ 12.368009005000033, 12.49203016000007 ],
							[ 12.363799802000074, 12.487379926000074 ],
							[ 12.360122163000028, 12.485161086000062 ],
							[ 12.356523092000032, 12.482988397000042 ],
							[ 12.352919468000039, 12.480815158000041 ],
							[ 12.350848105000068, 12.480628138000043 ],
							[ 12.345399752000048, 12.479909873000054 ],
							[ 12.34320305500006, 12.47661086100004 ],
							[ 12.340530052000076, 12.472599707000029 ],
							[ 12.337643034000052, 12.471017063000033 ],
							[ 12.334659051000074, 12.469380467000065 ],
							[ 12.331842394000034, 12.468075705000047 ],
							[ 12.325610545000075, 12.465190075000066 ],
							[ 12.322039149000034, 12.462860310000053 ],
							[ 12.318400764000046, 12.460733369000025 ],
							[ 12.315559148000034, 12.459072190000029 ],
							[ 12.311920150000049, 12.459870134000028 ],
							[ 12.308029868000062, 12.45818997300006 ],
							[ 12.304140664000045, 12.456500173000052 ],
							[ 12.300439947000029, 12.455949912000051 ],
							[ 12.297720468000023, 12.454069999000069 ],
							[ 12.291099508000059, 12.450519435000047 ],
							[ 12.287010767000027, 12.447710260000065 ],
							[ 12.283153609000067, 12.44283284200003 ],
							[ 12.280616441000063, 12.441782863000071 ],
							[ 12.278630265000061, 12.440959657000064 ],
							[ 12.275952707000044, 12.438990498000067 ],
							[ 12.272006059000034, 12.435992345000045 ],
							[ 12.26937422900005, 12.43399335600003 ],
							[ 12.265500535000058, 12.431050422000055 ],
							[ 12.265380361000041, 12.426910668000062 ],
							[ 12.261244632000057, 12.423103891000039 ],
							[ 12.261099281000043, 12.422970101000033 ],
							[ 12.260620118000077, 12.420270236000022 ],
							[ 12.262880686000074, 12.413620275000028 ],
							[ 12.264630260000047, 12.40811999400006 ],
							[ 12.267810378000036, 12.399689546000047 ],
							[ 12.269160679000038, 12.393100048000065 ],
							[ 12.270359424000048, 12.388415336000037 ],
							[ 12.272461815000042, 12.386264585000049 ],
							[ 12.274535483000022, 12.379438186000073 ],
							[ 12.276667409000027, 12.377513144000034 ],
							[ 12.277580346000036, 12.368180465000023 ],
							[ 12.281549852000069, 12.36190964900004 ],
							[ 12.285840342000029, 12.353079931000025 ],
							[ 12.286887339000032, 12.350925242000073 ],
							[ 12.290840582000044, 12.342789589000063 ],
							[ 12.298390787000073, 12.329159489000062 ],
							[ 12.301569417000053, 12.320730388000072 ],
							[ 12.304030024000042, 12.313759664000031 ],
							[ 12.304269391000048, 12.307560104000061 ],
							[ 12.305780055000071, 12.291149921000056 ],
							[ 12.305930637000074, 12.287249093000071 ],
							[ 12.297891554000046, 12.261956697000073 ],
							[ 12.299778251000077, 12.240398201000062 ],
							[ 12.304099157000053, 12.230548088000035 ],
							[ 12.304050571000062, 12.228179870000076 ],
							[ 12.303169866000076, 12.222369901000036 ],
							[ 12.303090629000053, 12.218729733000032 ],
							[ 12.300630067000043, 12.208579877000034 ],
							[ 12.299359712000069, 12.201689672000043 ],
							[ 12.296509570000069, 12.190449965000028 ],
							[ 12.291589452000039, 12.17016026400006 ],
							[ 12.288809789000027, 12.16256975500005 ],
							[ 12.283889251000062, 12.142270331000077 ],
							[ 12.274200873000041, 12.108960091000029 ],
							[ 12.271739598000067, 12.098809983000024 ],
							[ 12.269680597000047, 12.08974 ],
							[ 12.268410108000069, 12.082850196000038 ],
							[ 12.264839881000057, 12.073089457000037 ],
							[ 12.262780078000048, 12.064020004000042 ],
							[ 12.257540166000069, 12.046280122000042 ],
							[ 12.255372213000044, 12.034514107000064 ],
							[ 12.253729910000061, 12.025600333000057 ],
							[ 12.252060131000064, 12.017620105000049 ],
							[ 12.251205789000039, 12.012550420000025 ],
							[ 12.250635071000033, 12.009166484000048 ],
							[ 12.249920086000031, 12.004919919000031 ],
							[ 12.249359540000057, 11.996549709000021 ],
							[ 12.248760097000059, 11.990586028000052 ],
							[ 12.248409675000062, 11.987099777000026 ],
							[ 12.248180648000073, 11.976180159000023 ],
							[ 12.248810171000059, 11.97107036500006 ],
							[ 12.24786038600007, 11.961620415000027 ],
							[ 12.245560171000022, 11.941639718000033 ],
							[ 12.244690280000043, 11.935829966000028 ],
							[ 12.24658990000006, 11.924671384000021 ],
							[ 12.246519644000045, 11.916859583000075 ],
							[ 12.245960469000067, 11.908490434000043 ],
							[ 12.246199501000035, 11.902299776000064 ],
							[ 12.245650689000058, 11.893930528000055 ],
							[ 12.245889716000022, 11.887739864000025 ],
							[ 12.24645074700004, 11.878979539000056 ],
							[ 12.246290122000062, 11.871700178000026 ],
							[ 12.247640091000051, 11.865120105000074 ],
							[ 12.248199989000057, 11.856369937000068 ],
							[ 12.249150318000034, 11.84871021500004 ],
							[ 12.250500058000057, 11.842119957000023 ],
							[ 12.251460611000027, 11.834460132000061 ],
							[ 12.252409571000044, 11.826789663000056 ],
							[ 12.252169069000047, 11.815870024000048 ],
							[ 12.248440144000028, 11.798830131000045 ],
							[ 12.243359589000022, 11.788370301000043 ],
							[ 12.240660035000076, 11.784410487000059 ],
							[ 12.239672773000052, 11.783271459000048 ],
							[ 12.241294287000073, 11.773720167000022 ],
							[ 12.236518280000041, 11.760000308000031 ],
							[ 12.236484634000021, 11.759903654000027 ],
							[ 12.233292173000052, 11.752232308000032 ],
							[ 12.222346324000057, 11.750787566000042 ],
							[ 12.220317468000076, 11.749259787000028 ],
							[ 12.209923915000047, 11.741433186000052 ],
							[ 12.209859939000069, 11.741319560000022 ],
							[ 12.207579204000069, 11.73372000300003 ],
							[ 12.202869837000037, 11.729080014000033 ],
							[ 12.198980209000069, 11.721870555000066 ],
							[ 12.196040676000052, 11.706999714000062 ],
							[ 12.193659512000067, 11.700490262000073 ],
							[ 12.191280119000055, 11.693980221000061 ],
							[ 12.188420256000029, 11.682749594000029 ],
							[ 12.186840054000072, 11.678409675000069 ],
							[ 12.185211010000046, 11.674910956000076 ],
							[ 12.180959478000034, 11.665779889000021 ],
							[ 12.178979675000051, 11.660350050000034 ],
							[ 12.178245921000041, 11.658351503000063 ],
							[ 12.192381452000063, 11.645032348000029 ],
							[ 12.223719519000042, 11.632855024000037 ],
							[ 12.241261707000035, 11.652309516000059 ],
							[ 12.274844400000063, 11.694021494000026 ],
							[ 12.282671917000073, 11.680417843000043 ],
							[ 12.291369860000032, 11.663127082000074 ],
							[ 12.301113649000058, 11.644909419000044 ],
							[ 12.30075484300005, 11.616162576000022 ],
							[ 12.299958763000063, 11.608619084000054 ],
							[ 12.313554043000067, 11.605638998000074 ],
							[ 12.323346448000052, 11.598188643000071 ],
							[ 12.329906409000046, 11.593625203000045 ],
							[ 12.348465619000024, 11.583078106000073 ],
							[ 12.348556922000057, 11.582952419000037 ],
							[ 12.362555857000075, 11.563681067000061 ],
							[ 12.362556301000041, 11.563680456000043 ],
							[ 12.373544407000054, 11.549756497000033 ],
							[ 12.350685498000075, 11.505044574000067 ],
							[ 12.34652081400003, 11.498998654000047 ],
							[ 12.338264995000031, 11.482850141000029 ],
							[ 12.33322951100007, 11.489439910000044 ],
							[ 12.330318818000023, 11.492897798000058 ],
							[ 12.330255743000066, 11.492758984000034 ],
							[ 12.325977678000072, 11.483345082000028 ],
							[ 12.32097335900005, 11.465866647000041 ],
							[ 12.316013380000072, 11.453166820000035 ],
							[ 12.304669543000045, 11.448488903000055 ],
							[ 12.268996780000066, 11.431281486000046 ],
							[ 12.244626353000058, 11.41397205100003 ],
							[ 12.22497252900007, 11.382282828000029 ],
							[ 12.211904931000049, 11.364872833000049 ],
							[ 12.208791390000044, 11.357381112000041 ],
							[ 12.205308049000053, 11.348999589000073 ],
							[ 12.195301202000053, 11.312446167000076 ],
							[ 12.194389765000039, 11.312069827000073 ],
							[ 12.194199730000037, 11.311550153000042 ],
							[ 12.191499768000028, 11.307600057000059 ],
							[ 12.189200343000039, 11.304730238000047 ],
							[ 12.186900368000067, 11.301859855000032 ],
							[ 12.186899950000054, 11.301859745000058 ],
							[ 12.18315229500007, 11.300872697000045 ],
							[ 12.18211460200007, 11.299046515000043 ],
							[ 12.182114518000049, 11.299046367000074 ],
							[ 12.178560207000032, 11.296199665000074 ],
							[ 12.174927140000023, 11.294007906000047 ],
							[ 12.172129879000067, 11.292320372000063 ],
							[ 12.167222168000023, 11.289153658000032 ],
							[ 12.167200240000057, 11.289139509000051 ],
							[ 12.167199549000031, 11.289139431000024 ],
							[ 12.162030148000042, 11.288559810000038 ],
							[ 12.159497980000026, 11.286197492000042 ],
							[ 12.153229736000071, 11.281760484000074 ],
							[ 12.150937007000039, 11.280281149000075 ],
							[ 12.148300413000072, 11.278579942000022 ],
							[ 12.143779606000066, 11.276490355000021 ],
							[ 12.13773997100003, 11.273690305000059 ],
							[ 12.136293232000071, 11.272338397000055 ],
							[ 12.133929953000063, 11.270130026000061 ],
							[ 12.130412798000066, 11.269265605000044 ],
							[ 12.125670240000034, 11.268100014000026 ],
							[ 12.121860522000077, 11.264540256000032 ],
							[ 12.121859837000045, 11.264539616000036 ],
							[ 12.121858881000037, 11.264539382000066 ],
							[ 12.117730383000037, 11.26352958700005 ],
							[ 12.112706793000029, 11.259874887000024 ],
							[ 12.110899895000045, 11.258560355000043 ],
							[ 12.109385975000066, 11.257459186000062 ],
							[ 12.107489313000031, 11.256079625000041 ],
							[ 12.104789592000031, 11.252129465000053 ],
							[ 12.102410182000028, 11.245619805000047 ],
							[ 12.102116358000046, 11.241232416000059 ],
							[ 12.101849662000063, 11.237250102000075 ],
							[ 12.102453879000052, 11.232431070000075 ],
							[ 12.102810051000063, 11.229590356000074 ],
							[ 12.103934696000067, 11.226596076000021 ],
							[ 12.105980060000036, 11.221150458000068 ],
							[ 12.106698976000075, 11.215407244000062 ],
							[ 12.106940227000052, 11.213479955000025 ],
							[ 12.107419920000041, 11.211975174000031 ],
							[ 12.108690463000073, 11.207989520000069 ],
							[ 12.109720171000049, 11.203960645000052 ],
							[ 12.110750422000024, 11.199930076000044 ],
							[ 12.111789916000077, 11.19591071900004 ],
							[ 12.112798387000055, 11.191963023000028 ],
							[ 12.112819558000069, 11.191880147000063 ],
							[ 12.113680608000038, 11.187684281000031 ],
							[ 12.114169958000048, 11.185299692000058 ],
							[ 12.114244983000049, 11.183362921000025 ],
							[ 12.114410126000053, 11.179099781000048 ],
							[ 12.113929485000028, 11.17438009600005 ],
							[ 12.114412731000073, 11.170523280000054 ],
							[ 12.114569815000038, 11.169269587000031 ],
							[ 12.115013394000073, 11.165726482000025 ],
							[ 12.115209550000031, 11.164159673000029 ],
							[ 12.115159012000049, 11.163403763000076 ],
							[ 12.114650007000023, 11.155790488000036 ],
							[ 12.11711037300006, 11.148830583000063 ],
							[ 12.120513888000062, 11.140180264000037 ],
							[ 12.121009842000035, 11.138919761000068 ],
							[ 12.122495572000048, 11.138975988000027 ],
							[ 12.127360230000022, 11.139160091000065 ],
							[ 12.12989970700005, 11.135829814000033 ],
							[ 12.130834407000066, 11.13390841000006 ],
							[ 12.133479800000032, 11.128470444000072 ],
							[ 12.134110619000069, 11.123359407000066 ],
							[ 12.135150196000041, 11.119339960000048 ],
							[ 12.135070023000026, 11.115699521000067 ],
							[ 12.136019574000045, 11.108030102000043 ],
							[ 12.137049846000025, 11.104000003000067 ],
							[ 12.139120117000061, 11.095949857000051 ],
							[ 12.141659837000077, 11.092630206000024 ],
							[ 12.145320103000074, 11.088910010000063 ],
							[ 12.149680083000021, 11.081069792000051 ],
							[ 12.148587063000036, 11.077669036000032 ],
							[ 12.146573040000021, 11.075771202000055 ],
							[ 12.146030361000044, 11.070329935000075 ],
							[ 12.143729937000046, 11.067459814000074 ],
							[ 12.139129784000033, 11.061720078000064 ],
							[ 12.128560333000053, 11.056830258000048 ],
							[ 12.124084433000064, 11.055700046000027 ],
							[ 12.116570509000042, 11.054880110000056 ],
							[ 12.112440032000052, 11.053870037000024 ],
							[ 12.106809553000062, 11.052160537000077 ],
							[ 12.098940079000045, 11.051220040000032 ],
							[ 12.094099856000071, 11.051679718000059 ],
							[ 12.088859727000056, 11.051059482000028 ],
							[ 12.082899930000053, 11.051900125000031 ],
							[ 12.076230254000052, 11.054220213000065 ],
							[ 12.06995975500007, 11.057620040000074 ],
							[ 12.06439970200006, 11.059550458000047 ],
							[ 12.057720034000056, 11.061869700000045 ],
							[ 12.054389818000061, 11.063019893000046 ],
							[ 12.047320469000056, 11.064249940000025 ],
							[ 12.040969824000058, 11.064020120000066 ],
							[ 12.035730108000052, 11.063389519000054 ],
							[ 12.028980257000057, 11.062069973000064 ],
							[ 12.022150208000028, 11.057100226000045 ],
							[ 12.019450041000027, 11.053149973000075 ],
							[ 12.015949943000066, 11.047030234000033 ],
							[ 12.012860470000021, 11.041990324000039 ],
							[ 12.009369746000061, 11.03586931500007 ],
							[ 12.007828950000032, 11.033951060000049 ],
							[ 12.004759551000063, 11.030130280000037 ],
							[ 12.00166008900004, 11.025090336000062 ],
							[ 11.991020046000074, 11.016559422000057 ],
							[ 11.986419631000047, 11.010829686000022 ],
							[ 11.979909858000042, 11.003310480000039 ],
							[ 11.975299974000052, 10.997569942000041 ],
							[ 11.968469681000045, 10.992609923000032 ],
							[ 11.964659673000028, 10.989040527000043 ],
							[ 11.955929590000039, 10.982290163000073 ],
							[ 11.948699818000023, 10.976240171000029 ],
							[ 11.936550043000068, 10.967020163000029 ],
							[ 11.933529621000048, 10.965620170000022 ],
							[ 11.930520071000046, 10.964220067000042 ],
							[ 11.926389462000031, 10.963210188000062 ],
							[ 11.920749842000077, 10.961500202000025 ],
							[ 11.915109715000028, 10.959790136000038 ],
							[ 11.910980324000036, 10.958780076000039 ],
							[ 11.905340289000037, 10.957070415000032 ],
							[ 11.902395802000058, 10.956716320000055 ],
							[ 11.900099509000029, 10.956440175000068 ],
							[ 11.895970216000023, 10.955429948000074 ],
							[ 11.890330286000051, 10.953720077000071 ],
							[ 11.885089666000056, 10.953100363000033 ],
							[ 11.879449842000042, 10.951390308000043 ],
							[ 11.876032283000029, 10.950161523000077 ],
							[ 11.870799746000046, 10.948280161000071 ],
							[ 11.865879763000066, 10.945099742000025 ],
							[ 11.860240160000046, 10.94338996700003 ],
							[ 11.85460002800005, 10.941680112000029 ],
							[ 11.848250455000027, 10.941439649000074 ],
							[ 11.841500175000021, 10.940119520000053 ],
							[ 11.834951265000029, 10.938829783000074 ],
							[ 11.834750417000066, 10.938790228000073 ],
							[ 11.829109920000064, 10.937080543000036 ],
							[ 11.822359768000069, 10.935759510000025 ],
							[ 11.816320346000055, 10.932959319000076 ],
							[ 11.809570331000032, 10.931639756000038 ],
							[ 11.803540242000054, 10.928839842000059 ],
							[ 11.79901036900003, 10.926750066000068 ],
							[ 11.789239882000061, 10.924030135000066 ],
							[ 11.780590089000043, 10.920920144000036 ],
							[ 11.780486905000032, 10.920885369000075 ],
							[ 11.770223428000065, 10.920962211000074 ],
							[ 11.768068222000068, 10.920371071000034 ],
							[ 11.767497396000067, 10.920214502000022 ],
							[ 11.76684313100003, 10.920035047000056 ],
							[ 11.762973077000026, 10.917400675000067 ],
							[ 11.761999107000065, 10.916737687000023 ],
							[ 11.761045306000028, 10.915676683000072 ],
							[ 11.758539619000032, 10.912889371000063 ],
							[ 11.756130158000076, 10.912300009000035 ],
							[ 11.748979668000061, 10.909890085000029 ],
							[ 11.744850203000055, 10.90888043800004 ],
							[ 11.741829874000075, 10.907480017000069 ],
							[ 11.736200582000038, 10.905770542000027 ],
							[ 11.733598717000064, 10.904567027000041 ],
							[ 11.727140466000037, 10.901579707000053 ],
							[ 11.717629096000053, 10.897904066000024 ],
							[ 11.715470476000064, 10.897069874000067 ],
							[ 11.706322551000028, 10.89452338500007 ],
							[ 11.705699710000033, 10.894350006000025 ],
							[ 11.699669970000059, 10.891559717000064 ],
							[ 11.694029717000035, 10.889849683000023 ],
							[ 11.688389498000049, 10.888139565000074 ],
							[ 11.685766299000022, 10.886923700000068 ],
							[ 11.684665679000034, 10.885399621000033 ],
							[ 11.683185091000041, 10.881314908000036 ],
							[ 11.681222456000057, 10.875900300000069 ],
							[ 11.681180140000038, 10.870186043000047 ],
							[ 11.685973348000061, 10.866817849000029 ],
							[ 11.691273031000037, 10.866779404000056 ],
							[ 11.700391486000058, 10.861950943000068 ],
							[ 11.709481209000046, 10.853312753000068 ],
							[ 11.718534748000025, 10.839912471000048 ],
							[ 11.719937070000071, 10.834187619000033 ],
							[ 11.722313586000041, 10.829884236000055 ],
							[ 11.724125086000072, 10.824998996000033 ],
							[ 11.725146677000055, 10.822243978000074 ],
							[ 11.730884321000076, 10.816487152000036 ],
							[ 11.734213222000051, 10.810748032000049 ],
							[ 11.735939196000061, 10.803245899000046 ],
							[ 11.736209975000065, 10.80307007600004 ],
							[ 11.738360665000073, 10.798660375000054 ],
							[ 11.739710119000051, 10.792080035000026 ],
							[ 11.738120335000076, 10.787739776000024 ],
							[ 11.737250527000072, 10.781930172000045 ],
							[ 11.73525999900005, 10.776510238000071 ],
							[ 11.732559921000075, 10.772560122000073 ],
							[ 11.730179527000075, 10.76605022900003 ],
							[ 11.725410450000027, 10.759718947000067 ],
							[ 11.722880209000039, 10.756360244000064 ],
							[ 11.722399443000029, 10.751629803000071 ],
							[ 11.721130368000047, 10.744739313000025 ],
							[ 11.726070492000076, 10.743459883000071 ],
							[ 11.728440449000061, 10.737310517000026 ],
							[ 11.728519344000063, 10.737250390000042 ],
							[ 11.734319791000075, 10.732829847000062 ],
							[ 11.739800443000036, 10.727259780000054 ],
							[ 11.741119713000046, 10.726801049000073 ],
							[ 11.746470219000059, 10.724940592000053 ],
							[ 11.750519671000063, 10.722310186000072 ],
							[ 11.753780514000027, 10.717510148000031 ],
							[ 11.756319943000051, 10.714189676000046 ],
							[ 11.757349869000052, 10.71015998200005 ],
							[ 11.759499774000062, 10.70575070600006 ],
							[ 11.766010573000074, 10.696149952000042 ],
							[ 11.768469910000022, 10.689189911000028 ],
							[ 11.768280009000023, 10.680339807000053 ],
							[ 11.768277742000066, 10.680221972000027 ],
							[ 11.767201337000074, 10.679664548000062 ],
							[ 11.763964, 10.677509427000075 ],
							[ 11.761410495000064, 10.676103545000046 ],
							[ 11.758772274000023, 10.674698277000061 ],
							[ 11.756390232000058, 10.673542544000043 ],
							[ 11.754313895000053, 10.672289372000023 ],
							[ 11.753920903000051, 10.672052182000073 ],
							[ 11.750169479000022, 10.669146381000076 ],
							[ 11.748805800000071, 10.668150677000028 ],
							[ 11.747950015000072, 10.667067382000027 ],
							[ 11.747930905000032, 10.667040973000042 ],
							[ 11.747830301000022, 10.66701048300007 ],
							[ 11.740626419000023, 10.660897422000062 ],
							[ 11.73146999100004, 10.653129476000061 ],
							[ 11.727259526000068, 10.648480171000074 ],
							[ 11.723050325000031, 10.643830235000053 ],
							[ 11.720749689000058, 10.64095973600007 ],
							[ 11.716940108000074, 10.637389996000024 ],
							[ 11.713529548000054, 10.634909551000021 ],
							[ 11.710109906000071, 10.632430271000032 ],
							[ 11.706385211000054, 10.630182331000071 ],
							[ 11.703679863000048, 10.628549591000024 ],
							[ 11.697649998000031, 10.625759810000034 ],
							[ 11.688589857000068, 10.621560104000025 ],
							[ 11.685569913000052, 10.62016938000005 ],
							[ 11.678819876000034, 10.618840086000034 ],
							[ 11.67469963800005, 10.617830172000026 ],
							[ 11.669449984000039, 10.61721033200007 ],
							[ 11.666439798000056, 10.615810327000077 ],
							[ 11.661190113000032, 10.615180206000048 ],
							[ 11.658594500000049, 10.614391869000031 ],
							[ 11.655559477000054, 10.613470075000066 ],
							[ 11.652540311000052, 10.612079650000055 ],
							[ 11.649519957000052, 10.610679600000026 ],
							[ 11.645789970000067, 10.610750331000077 ],
							[ 11.640150340000048, 10.609040031000063 ],
							[ 11.632864258000041, 10.609520222000071 ],
							[ 11.628817532000028, 10.608846595000045 ],
							[ 11.624216172000047, 10.606999294000047 ],
							[ 11.613828400000045, 10.604437917000041 ],
							[ 11.602257801000064, 10.603010148000067 ],
							[ 11.593669889000068, 10.597590496000066 ],
							[ 11.583307471000069, 10.593869156000039 ],
							[ 11.572894895000047, 10.592467423000073 ],
							[ 11.561311795000051, 10.591618764000032 ],
							[ 11.551528658000052, 10.58791173000003 ],
							[ 11.548360134000063, 10.585259163000046 ],
							[ 11.54499456700006, 10.582441209000024 ],
							[ 11.537276427000052, 10.575980247000075 ],
							[ 11.532735685000034, 10.571234935000064 ],
							[ 11.527589543000033, 10.567634525000074 ],
							[ 11.52187374500005, 10.568999122000037 ],
							[ 11.516513733000068, 10.570278134000034 ],
							[ 11.514617893000036, 10.577775135000024 ],
							[ 11.512720824000041, 10.585271001000024 ],
							[ 11.513373105000028, 10.594977584000048 ],
							[ 11.512397958000065, 10.599837426000022 ],
							[ 11.512864398000033, 10.603256226000042 ],
							[ 11.513419941000052, 10.60732930100005 ],
							[ 11.51474842600004, 10.612222664000058 ],
							[ 11.516113313000062, 10.617252529000041 ],
							[ 11.51730137100003, 10.622234431000038 ],
							[ 11.518379395000068, 10.626752038000063 ],
							[ 11.519313864000026, 10.630667694000067 ],
							[ 11.521450924000021, 10.639417655000045 ],
							[ 11.524185346000024, 10.660006421000048 ],
							[ 11.522719195000036, 10.661479898000039 ],
							[ 11.521214717000021, 10.666885240000056 ],
							[ 11.516460688000052, 10.68396567800005 ],
							[ 11.515070768000044, 10.694954009000071 ],
							[ 11.515468379000026, 10.70366380300004 ],
							[ 11.516856902000029, 10.720517103000077 ],
							[ 11.516615182000066, 10.732112956000037 ],
							[ 11.518564348000041, 10.739901315000054 ],
							[ 11.519827022000072, 10.744948210000075 ],
							[ 11.52216732200003, 10.749465092000037 ],
							[ 11.526517905000048, 10.757864260000076 ],
							[ 11.526962344000026, 10.773483056000032 ],
							[ 11.52840139500006, 10.778790315000037 ],
							[ 11.528509040000074, 10.782501940000031 ],
							[ 11.528603661000034, 10.785740141000076 ],
							[ 11.528739060000021, 10.790397454000072 ],
							[ 11.530296496000062, 10.799135407000051 ],
							[ 11.532677794000051, 10.804135053000039 ],
							[ 11.532619221000061, 10.807447172000025 ],
							[ 11.534076581000022, 10.812563861000058 ],
							[ 11.534790924000049, 10.816131759000029 ],
							[ 11.536177524000038, 10.82305311500005 ],
							[ 11.536439973000029, 10.828774642000042 ],
							[ 11.534410315000059, 10.833539977000044 ],
							[ 11.538146092000034, 10.839919762000022 ],
							[ 11.539074697000046, 10.850962823000032 ],
							[ 11.536488286000065, 10.86366349900004 ],
							[ 11.534164584000052, 10.867039703000046 ],
							[ 11.531390343000055, 10.871069893000026 ],
							[ 11.528674367000065, 10.876823606000073 ],
							[ 11.528417442000034, 10.879737594000062 ],
							[ 11.52812885000003, 10.883029805000035 ],
							[ 11.527787205000038, 10.88691340400004 ],
							[ 11.52737731700006, 10.891585144000032 ],
							[ 11.528905069000075, 10.893400943000074 ],
							[ 11.529787963000047, 10.897920330000034 ],
							[ 11.531004092000046, 10.904140476000066 ],
							[ 11.53187342700005, 10.91808031100004 ],
							[ 11.53150548900004, 10.923272169000029 ],
							[ 11.531051581000042, 10.929661917000033 ],
							[ 11.530698038000025, 10.932335839000075 ],
							[ 11.530362358000048, 10.934866683000052 ],
							[ 11.528694886000039, 10.938942969000038 ],
							[ 11.527306791000058, 10.942335889000049 ],
							[ 11.521278538000047, 10.953218357000026 ],
							[ 11.519599155000037, 10.956843202000073 ],
							[ 11.517026810000061, 10.962398628000074 ],
							[ 11.513415341000041, 10.968695615000058 ],
							[ 11.509260318000031, 10.973240114000021 ],
							[ 11.504500679000046, 10.978930591000051 ],
							[ 11.494970038000076, 10.990889472000049 ],
							[ 11.488483711000072, 10.99595907500003 ],
							[ 11.482541069000035, 11.002781620000064 ],
							[ 11.475329181000063, 11.014795054000047 ],
							[ 11.470545482000034, 11.021645815000056 ],
							[ 11.459810512000047, 11.033257979000041 ],
							[ 11.456897013000059, 11.036409345000038 ],
							[ 11.452182768000057, 11.042205055000068 ],
							[ 11.448561447000031, 11.04665658600004 ],
							[ 11.443258972000024, 11.050594259000036 ],
							[ 11.439234526000064, 11.053138561000026 ],
							[ 11.434853477000047, 11.05590832200005 ],
							[ 11.429089404000024, 11.062446851000061 ],
							[ 11.427465625000025, 11.064084215000037 ],
							[ 11.424053236000077, 11.06752500500005 ],
							[ 11.420797625000034, 11.070808214000067 ],
							[ 11.417252650000023, 11.073772339000072 ],
							[ 11.415101015000062, 11.076471388000073 ],
							[ 11.412119974000063, 11.080209846000059 ],
							[ 11.407191456000021, 11.083399846000077 ],
							[ 11.402456535000056, 11.087930474000075 ],
							[ 11.400138878000064, 11.089087412000026 ],
							[ 11.39873969100006, 11.092800242000067 ],
							[ 11.395439879000037, 11.094490123000071 ],
							[ 11.392329989000075, 11.097309923000068 ],
							[ 11.389229730000068, 11.100119487000029 ],
							[ 11.38592987800007, 11.101820003000057 ],
							[ 11.383990188000041, 11.104450272000065 ],
							[ 11.382049837000068, 11.107069796000076 ],
							[ 11.380120400000067, 11.109699978000037 ],
							[ 11.378379706000032, 11.113459575000036 ],
							[ 11.376439899000047, 11.116089783000064 ],
							[ 11.37333019700003, 11.11890040000003 ],
							[ 11.37140004500003, 11.121529409000061 ],
							[ 11.368289739000033, 11.124349576000043 ],
							[ 11.365189694000037, 11.127169651000031 ],
							[ 11.364609960000053, 11.130729660000043 ],
							[ 11.364040596000052, 11.134300322000058 ],
							[ 11.362099448000038, 11.136930479000057 ],
							[ 11.360360079000031, 11.140680364000048 ],
							[ 11.358619592000025, 11.144439838000039 ],
							[ 11.355509509000058, 11.147250299000063 ],
							[ 11.353580227000066, 11.149880333000056 ],
							[ 11.352230176000035, 11.155890634000059 ],
							[ 11.349519666000049, 11.160960325000076 ],
							[ 11.348939724000047, 11.164520284000048 ],
							[ 11.345840328000065, 11.167340183000022 ],
							[ 11.343899992000047, 11.169969675000061 ],
							[ 11.343330291000029, 11.173529558000041 ],
							[ 11.34177982600005, 11.178409336000072 ],
							[ 11.340040117000058, 11.182169854000051 ],
							[ 11.338299740000025, 11.185919628000022 ],
							[ 11.336560538000072, 11.189680105000036 ],
							[ 11.334620600000051, 11.192310097000075 ],
							[ 11.332690299000035, 11.194929854000065 ],
							[ 11.330949833000034, 11.198690328000055 ],
							[ 11.329199551000045, 11.202440098000068 ],
							[ 11.328630301000032, 11.206010129000049 ],
							[ 11.326689636000026, 11.208640081000055 ],
							[ 11.326120268000068, 11.212199942000041 ],
							[ 11.323209526000028, 11.216150212000059 ],
							[ 11.321470517000023, 11.219899888000043 ],
							[ 11.320890269000074, 11.223469971000043 ],
							[ 11.319150075000039, 11.227219623000053 ],
							[ 11.317219525000041, 11.229850031000069 ],
							[ 11.315469531000076, 11.23359974300007 ],
							[ 11.313730426000063, 11.237360097000021 ],
							[ 11.31083013500006, 11.241299457000025 ],
							[ 11.309089820000054, 11.245059813000069 ],
							[ 11.305789914000059, 11.246749980000061 ],
							[ 11.303850030000035, 11.249380371000029 ],
							[ 11.300550638000061, 11.251069932000064 ],
							[ 11.296079796000072, 11.252949769000054 ],
							[ 11.292579802000034, 11.253520059000039 ],
							[ 11.286559812000064, 11.253339676000053 ],
							[ 11.283829540000056, 11.251460265000048 ],
							[ 11.279740522000054, 11.248649541000077 ],
							[ 11.275850395000077, 11.246970435000037 ],
							[ 11.273119703000077, 11.245099982000056 ],
							[ 11.270199953000031, 11.242099488000065 ],
							[ 11.262809977000074, 11.240980125000021 ],
							[ 11.259110700000065, 11.240420119000021 ],
							[ 11.25444968100004, 11.241180173000032 ],
							[ 11.250949675000072, 11.241740463000042 ],
							[ 11.247260142000073, 11.241180279000048 ],
							[ 11.242589914000064, 11.241939684000045 ],
							[ 11.237930547000076, 11.242699521000077 ],
							[ 11.233460638000054, 11.244580320000068 ],
							[ 11.230159823000065, 11.246270391000053 ],
							[ 11.226860118000047, 11.247959852000065 ],
							[ 11.223560405000057, 11.249649856000076 ],
							[ 11.22045987100006, 11.252469817000076 ],
							[ 11.216959769000027, 11.253040523000038 ],
							[ 11.212490198000069, 11.254919916000063 ],
							[ 11.210550158000046, 11.257550370000047 ],
							[ 11.207249681000064, 11.25924023400006 ],
							[ 11.20259001900007, 11.260000199000046 ],
							[ 11.197930276000022, 11.260749923000049 ],
							[ 11.193059968000057, 11.260379841000031 ],
							[ 11.186269473000038, 11.262639885000056 ],
							[ 11.181799608000063, 11.264519481000036 ],
							[ 11.178500160000056, 11.266219818000025 ],
							[ 11.175000379000039, 11.266779942000028 ],
							[ 11.170140331000027, 11.266410054000062 ],
							[ 11.166640015000041, 11.266980285000045 ],
							[ 11.163140269000053, 11.267550461000042 ],
							[ 11.159649602000059, 11.268110405000073 ],
							[ 11.15497943500003, 11.268870388000039 ],
							[ 11.150509915000043, 11.270750159000045 ],
							[ 11.147410341000068, 11.273570069000073 ],
							[ 11.144109445000026, 11.275259923000021 ],
							[ 11.141010382000047, 11.278079775000037 ],
							[ 11.13770024400003, 11.279769625000029 ],
							[ 11.133040179000034, 11.280529846000036 ],
							[ 11.130319659000065, 11.278650385000049 ],
							[ 11.121580141000038, 11.283539879000045 ],
							[ 11.118469955000023, 11.286359603000051 ],
							[ 11.113809693000064, 11.287110529000074 ],
							[ 11.110509716000024, 11.288809703000027 ],
							[ 11.107210237000061, 11.290499791000059 ],
							[ 11.101770233000025, 11.293689689000075 ],
							[ 11.098469565000073, 11.295389870000065 ],
							[ 11.09653012900003, 11.298010102000035 ],
							[ 11.092069741000046, 11.299899849000042 ],
							[ 11.088959691000071, 11.302709711000034 ],
							[ 11.085860530000048, 11.30552964900005 ],
							[ 11.080620084000032, 11.30984985300006 ],
							[ 11.078679937000061, 11.312480157000039 ],
							[ 11.074790062000034, 11.310799523000071 ],
							[ 11.071490203000053, 11.312490421000064 ],
							[ 11.066430308000065, 11.310990248000053 ],
							[ 11.062740421000058, 11.310440100000051 ],
							[ 11.058850028000052, 11.30875024900007 ],
							[ 11.054949992000047, 11.307070019000037 ],
							[ 11.051260109000054, 11.306510167000056 ],
							[ 11.041729838000037, 11.306889303000048 ],
							[ 11.036869984000077, 11.306520482000053 ],
							[ 11.03297979000007, 11.304839987000037 ],
							[ 11.030059856000037, 11.301839883000071 ],
							[ 11.028300236000064, 11.298649787000045 ],
							[ 11.019749671000056, 11.297730239000032 ],
							[ 11.017020440000067, 11.29585 ],
							[ 11.012749626000073, 11.298859321000066 ],
							[ 11.007315551000033, 11.295865316000061 ],
							[ 11.002829319000057, 11.296990232000041 ],
							[ 10.999139676000027, 11.296440007000058 ],
							[ 10.995439694000027, 11.295880210000064 ],
							[ 10.991746167000031, 11.295036141000026 ],
							[ 10.986970732000032, 11.295096216000047 ],
							[ 10.984379831000069, 11.296590990000027 ],
							[ 10.978130551000049, 11.292889568000021 ],
							[ 10.973469992000048, 11.293649468000069 ],
							[ 10.965700189000074, 11.297220408000044 ],
							[ 10.956959745000063, 11.302109952000023 ],
							[ 10.953470217000074, 11.302679815000033 ],
							[ 10.949970364000023, 11.303250234000075 ],
							[ 10.946669428000064, 11.304940026000054 ],
							[ 10.943559414000049, 11.307760529000063 ],
							[ 10.94163024900007, 11.310379890000036 ],
							[ 10.938130303000037, 11.310950216000037 ],
							[ 10.934430271000053, 11.310390367000025 ],
							[ 10.931130344000053, 11.312080587000025 ],
							[ 10.926670069000068, 11.313969467000049 ],
							[ 10.922516935000033, 11.317763074000027 ],
							[ 10.91439635200004, 11.318954309000048 ],
							[ 10.911330214000031, 11.322239879000051 ],
							[ 10.908029509000073, 11.323929895000049 ],
							[ 10.903559753000025, 11.325810637000075 ],
							[ 10.899089945000071, 11.327690178000068 ],
							[ 10.894820071000026, 11.330699827000046 ],
							[ 10.890359915000033, 11.332579803000044 ],
							[ 10.887249785000051, 11.335399809000023 ],
							[ 10.879480510000064, 11.338970052000036 ],
							[ 10.875189983000041, 11.339598587000069 ],
							[ 10.870425159000035, 11.344041625000045 ],
							[ 10.866080480000051, 11.344619487000045 ],
							[ 10.861809507000032, 11.347620276000043 ],
							[ 10.85851004500006, 11.349309841000036 ],
							[ 10.855609714000025, 11.353260262000049 ],
							[ 10.850370417000022, 11.357579991000023 ],
							[ 10.850380159000053, 11.364520049000021 ],
							[ 10.853309419000027, 11.367520373000048 ],
							[ 10.857199420000029, 11.369199948000073 ],
							[ 10.861263991000044, 11.369753631000037 ],
							[ 10.863545216000034, 11.372065550000059 ],
							[ 10.866485406000038, 11.375045922000027 ],
							[ 10.869177596000043, 11.377773851000029 ],
							[ 10.875068546000023, 11.378988868000022 ],
							[ 10.889864341000077, 11.380797031000043 ],
							[ 10.902288269000053, 11.384970071000055 ],
							[ 10.909942478000062, 11.389167823000037 ],
							[ 10.914262457000063, 11.394323960000065 ],
							[ 10.920556316000045, 11.411709790000032 ],
							[ 10.926375785000062, 11.42956803900006 ],
							[ 10.929817467000021, 11.44328570700003 ],
							[ 10.930074453000032, 11.446376686000065 ],
							[ 10.931162097000026, 11.459398011000076 ],
							[ 10.933429666000052, 11.462180218000071 ],
							[ 10.934209620000047, 11.466679319000036 ],
							[ 10.934800284000062, 11.470049935000077 ],
							[ 10.93559005000003, 11.474559719000069 ],
							[ 10.935020433000034, 11.478120022000041 ],
							[ 10.935610046000022, 11.481499693000046 ],
							[ 10.933675829000038, 11.486357594000026 ],
							[ 10.934170581000046, 11.49095365200003 ],
							[ 10.934673136000072, 11.495612968000046 ],
							[ 10.930600041000048, 11.500829464000049 ],
							[ 10.930029771000022, 11.504389757000069 ],
							[ 10.929650482000056, 11.509080189000031 ],
							[ 10.929270055000075, 11.513770089000047 ],
							[ 10.929660099000046, 11.516030365000063 ],
							[ 10.928890232000072, 11.518469574000051 ],
							[ 10.928319897000051, 11.522029861000021 ],
							[ 10.92773988600004, 11.525600359000066 ],
							[ 10.928330579000033, 11.528969841000048 ],
							[ 10.929119780000065, 11.533470015000034 ],
							[ 10.929909616000032, 11.537980366000056 ],
							[ 10.931670036000071, 11.54115956000004 ],
							[ 10.931090608000034, 11.544730057000038 ],
							[ 10.931879856000023, 11.549229665000041 ],
							[ 10.931300398000076, 11.552800160000061 ],
							[ 10.930930252000053, 11.557490549000022 ],
							[ 10.931719522000037, 11.561989579000056 ],
							[ 10.932499681000024, 11.56649033900004 ],
							[ 10.932130097000027, 11.571180166000033 ],
							[ 10.930780631000061, 11.577190413000039 ],
							[ 10.930199337000033, 11.580750745000046 ],
							[ 10.928660457000035, 11.585630245000061 ],
							[ 10.928279932000066, 11.590319545000057 ],
							[ 10.927900532000024, 11.595009967000067 ],
							[ 10.927330140000038, 11.598580397000035 ],
							[ 10.927149800000052, 11.604399797000042 ],
							[ 10.926969405000023, 11.610210160000065 ],
							[ 10.927559725000037, 11.61359036500005 ],
							[ 10.926210530000048, 11.619589838000024 ],
							[ 10.92564006300006, 11.623160259000031 ],
							[ 10.92642027200003, 11.627659901000072 ],
							[ 10.925849796000023, 11.631230321000032 ],
							[ 10.925470332000032, 11.635919597000054 ],
							[ 10.925089701000047, 11.640610008000067 ],
							[ 10.924519716000077, 11.644169681000051 ],
							[ 10.925110067000048, 11.647549882000021 ],
							[ 10.924539565000032, 11.651120294000066 ],
							[ 10.922989987000051, 11.65600037400003 ],
							[ 10.921050216000026, 11.658619611000063 ],
							[ 10.920479659000023, 11.662190588000044 ],
							[ 10.919510903000059, 11.664040344000057 ],
							[ 10.918576102000031, 11.664844096000024 ],
							[ 10.916192045000059, 11.668294457000059 ],
							[ 10.913360357000045, 11.674428639000041 ],
							[ 10.912917414000049, 11.681021725000051 ],
							[ 10.912482512000054, 11.689026688000069 ],
							[ 10.913949851000041, 11.69560937500006 ],
							[ 10.914939368000034, 11.702195052000036 ],
							[ 10.915690556000072, 11.708781397000052 ],
							[ 10.916277746000048, 11.729491446000054 ],
							[ 10.917349883000043, 11.751611452000077 ],
							[ 10.916664088000061, 11.75726389600004 ],
							[ 10.911916630000064, 11.762466594000045 ],
							[ 10.905268833000036, 11.769562148000034 ],
							[ 10.90243616600003, 11.775696203000052 ],
							[ 10.902470881000056, 11.782286209000063 ],
							[ 10.90538556000007, 11.791686465000055 ],
							[ 10.905938091000053, 11.805805842000041 ],
							[ 10.904074932000071, 11.814759906000063 ],
							[ 10.900754507000045, 11.819013855000037 ],
							[ 10.894555758000024, 11.820928078000065 ],
							[ 10.890842156000076, 11.822047051000027 ],
							[ 10.880251224000062, 11.825237672000071 ],
							[ 10.867870843000048, 11.832361456000058 ],
							[ 10.856430973000045, 11.836655144000076 ],
							[ 10.849763858000074, 11.840454669000053 ],
							[ 10.84594581500005, 11.840944293000064 ],
							[ 10.838789016000021, 11.84239171300004 ],
							[ 10.832587715000045, 11.843834841000046 ],
							[ 10.825449864000063, 11.849048169000071 ],
							[ 10.821662572000037, 11.855656893000059 ],
							[ 10.819298203000073, 11.860376049000024 ],
							[ 10.818371055000057, 11.866029441000023 ],
							[ 10.822249361000047, 11.877308707000054 ],
							[ 10.825635516000034, 11.885765354000057 ],
							[ 10.827925277000077, 11.892110323000054 ],
							[ 10.828867463000051, 11.893291036000051 ],
							[ 10.826160680000044, 11.895177886000056 ],
							[ 10.824400440000034, 11.897124722000058 ],
							[ 10.821686220000061, 11.898002295000026 ],
							[ 10.820453334000035, 11.898570397000071 ],
							[ 10.820075959000064, 11.899134324000045 ],
							[ 10.819983735000051, 11.899696836000032 ],
							[ 10.820084932000043, 11.90091416000007 ],
							[ 10.820440719000032, 11.902789644000052 ],
							[ 10.820359942000039, 11.902950832000045 ],
							[ 10.818574967000075, 11.906512737000071 ],
							[ 10.815753959000062, 11.915470410000069 ],
							[ 10.814746338000077, 11.916733491000059 ],
							[ 10.811398706000034, 11.920929748000049 ],
							[ 10.809905350000065, 11.922802081000043 ],
							[ 10.799755082000047, 11.952554271000054 ],
							[ 10.799883514000044, 11.978310580000027 ],
							[ 10.803920473000062, 12.001472113000034 ],
							[ 10.811809019000066, 12.010448766000025 ],
							[ 10.813161278000052, 12.01945719500003 ],
							[ 10.81068239800004, 12.046513708000077 ],
							[ 10.803121842000053, 12.103215492000061 ],
							[ 10.801942587000042, 12.128977861000067 ],
							[ 10.800815192000073, 12.165042444000051 ],
							[ 10.789224056000023, 12.201157972000033 ],
							[ 10.775008693000075, 12.235996847000024 ],
							[ 10.769991295000068, 12.279806824000048 ],
							[ 10.771476931000052, 12.315858470000023 ],
							[ 10.772905311000045, 12.340320340000062 ],
							[ 10.759832844000073, 12.369649358000061 ],
							[ 10.757374030000051, 12.375164975000075 ],
							[ 10.756147286000044, 12.391912148000074 ],
							[ 10.75125057200006, 12.461476795000067 ],
							[ 10.742107331000057, 12.465382989000034 ],
							[ 10.731636716000025, 12.465431834000071 ],
							[ 10.705496445000051, 12.473279048000052 ],
							[ 10.678076339000029, 12.487569746000077 ],
							[ 10.66239291100004, 12.492790944000035 ],
							[ 10.642988742000057, 12.498229039000023 ],
							[ 10.641519648000042, 12.500220256000034 ],
							[ 10.639590534000035, 12.502840045000028 ],
							[ 10.636480418000076, 12.505659938000065 ],
							[ 10.634939946000031, 12.510540147000029 ],
							[ 10.633199660000059, 12.514300302000038 ],
							[ 10.62872980700007, 12.516179762000036 ],
							[ 10.625229688000047, 12.51673968700004 ],
							[ 10.621929974000068, 12.518440510000062 ],
							[ 10.618430383000032, 12.518999794000024 ],
							[ 10.613969601000065, 12.520879611000055 ],
							[ 10.610470025000041, 12.521450131000051 ],
							[ 10.604439589000037, 12.521269948000054 ],
							[ 10.600750079000022, 12.520710111000028 ],
							[ 10.597049646000073, 12.520150303000037 ],
							[ 10.594050542000048, 12.520130385000073 ],
							[ 10.590250103000074, 12.522410468000032 ],
							[ 10.589679471000068, 12.525980122000021 ],
							[ 10.590470068000059, 12.530480104000048 ],
							[ 10.592419409000058, 12.534789834000037 ],
							[ 10.594180050000034, 12.537980035000032 ],
							[ 10.594969564000053, 12.542480583000042 ],
							[ 10.590534448000028, 12.545846996000023 ],
							[ 10.588959618000047, 12.549240132000023 ],
							[ 10.588579988000049, 12.553930416000071 ],
							[ 10.591041517000065, 12.558058868000046 ],
							[ 10.593459922000022, 12.561249644000043 ],
							[ 10.595210327000075, 12.564430251000033 ],
							[ 10.595999907000021, 12.568929658000059 ],
							[ 10.595430431000068, 12.572500432000027 ],
							[ 10.598549815000069, 12.576620365000053 ],
							[ 10.599329759000057, 12.581129966000049 ],
							[ 10.598959980000075, 12.585820225000077 ],
							[ 10.598770358000024, 12.591630032000069 ],
							[ 10.598399977000042, 12.596320271000025 ],
							[ 10.597820143000035, 12.599889957000073 ],
							[ 10.597250005000035, 12.603449991000048 ],
							[ 10.598230254000043, 12.609080195000047 ],
							[ 10.597660148000045, 12.61264983500007 ],
							[ 10.597080237000057, 12.616209907000041 ],
							[ 10.59566423800004, 12.61881143100004 ],
							[ 10.596130483000024, 12.624469828000031 ],
							[ 10.595749699000066, 12.629160097000067 ],
							[ 10.596646431000067, 12.632135312000059 ],
							[ 10.597281402000021, 12.634244989000024 ],
							[ 10.598649892000026, 12.638789907000046 ],
							[ 10.598620377000032, 12.642239720000077 ],
							[ 10.597389986000053, 12.647979951000025 ],
							[ 10.596150346000059, 12.653719643000045 ],
							[ 10.595099703000074, 12.657253467000032 ],
							[ 10.59408013500007, 12.660679816000027 ],
							[ 10.596040020000032, 12.664090022000039 ],
							[ 10.59480951200004, 12.669830236000053 ],
							[ 10.595492601000046, 12.674362278000046 ],
							[ 10.595999922000033, 12.677730677000056 ],
							[ 10.596434029000022, 12.680613633000064 ],
							[ 10.596061245000044, 12.683340021000049 ],
							[ 10.595688075000055, 12.684331624000038 ],
							[ 10.593936433000067, 12.688986085000067 ],
							[ 10.592534418000071, 12.696038249000026 ],
							[ 10.593288417000053, 12.704490449000048 ],
							[ 10.596170525000048, 12.708001202000048 ],
							[ 10.596543328000052, 12.71505200200005 ],
							[ 10.595569524000041, 12.717149918000075 ],
							[ 10.593830127000047, 12.720909839000058 ],
							[ 10.590720156000032, 12.725050171000021 ],
							[ 10.590689530000077, 12.727350256000022 ],
							[ 10.588209822000067, 12.729920668000034 ],
							[ 10.586270510000077, 12.732549781000046 ],
							[ 10.585700047000046, 12.736109761000023 ],
							[ 10.583959308000033, 12.739870191000023 ],
							[ 10.582019916000036, 12.742500428000028 ],
							[ 10.580079906000037, 12.745129507000058 ],
							[ 10.578140415000064, 12.747750096000061 ],
							[ 10.576399539000022, 12.751509932000033 ],
							[ 10.574460028000033, 12.754140108000058 ],
							[ 10.574279696000076, 12.759949769000059 ],
							[ 10.573909972000024, 12.764639939000062 ],
							[ 10.57333019400005, 12.768210668000052 ],
							[ 10.575289804000022, 12.772519798000076 ],
							[ 10.57587987200003, 12.775899716000026 ],
							[ 10.577230407000059, 12.782449781000025 ],
							[ 10.576868319000027, 12.786256864000052 ],
							[ 10.576677076000067, 12.788257621000071 ],
							[ 10.577120061000073, 12.792820052000025 ],
							[ 10.572819907000053, 12.799539762000052 ],
							[ 10.570880112000054, 12.802169910000032 ],
							[ 10.577699950000067, 12.806850577000034 ],
							[ 10.574580103000073, 12.812359674000049 ],
							[ 10.569840278000072, 12.816910068000027 ],
							[ 10.566310056000077, 12.819170044000032 ],
							[ 10.56190024600005, 12.821267171000045 ],
							[ 10.561335349000046, 12.821312265000074 ],
							[ 10.551379802000042, 12.822006385000066 ],
							[ 10.541427030000023, 12.823352774000057 ],
							[ 10.536774195000021, 12.822066895000034 ],
							[ 10.530790851000063, 12.820133859000066 ],
							[ 10.524154740000029, 12.820813603000033 ],
							[ 10.521515726000075, 12.824739703000034 ],
							[ 10.517399414000067, 12.828004018000058 ],
							[ 10.515870247000066, 12.82777048500003 ],
							[ 10.51352036000003, 12.827739919000066 ],
							[ 10.508919463000041, 12.82494001200007 ],
							[ 10.506509575000052, 12.826499996000052 ],
							[ 10.504180191000046, 12.825320163000072 ],
							[ 10.501850255000022, 12.824139761000026 ],
							[ 10.49600993200005, 12.822919785000067 ],
							[ 10.490169669000068, 12.821700272000044 ],
							[ 10.48549009900006, 12.821639868000034 ],
							[ 10.481969974000037, 12.821600214000057 ],
							[ 10.478460168000026, 12.821559912000055 ],
							[ 10.474940139000068, 12.822669871000073 ],
							[ 10.470250238000062, 12.822609871000054 ],
							[ 10.464400357000045, 12.822540247000063 ],
							[ 10.460648246000062, 12.821568571000057 ],
							[ 10.458379702000059, 12.823869483000067 ],
							[ 10.455079818000058, 12.825569905000066 ],
							[ 10.451970049000067, 12.828379691000066 ],
							[ 10.44556999100007, 12.832889750000049 ],
							[ 10.442460126000071, 12.835709619000056 ],
							[ 10.435660559000041, 12.837969714000053 ],
							[ 10.432169717000022, 12.838540183000077 ],
							[ 10.427499481000041, 12.839289712000038 ],
							[ 10.422840191000034, 12.840049876000023 ],
							[ 10.419540534000021, 12.84173981400005 ],
							[ 10.415839973000061, 12.84117989300006 ],
							[ 10.412340442000072, 12.841750209000054 ],
							[ 10.410288001000026, 12.840339391000043 ],
							[ 10.408201354000028, 12.840026942000065 ],
							[ 10.405630227000074, 12.84370999600003 ],
							[ 10.403269775000069, 12.844829580000066 ],
							[ 10.398549931000048, 12.848230262000072 ],
							[ 10.393839709000076, 12.85161955500007 ],
							[ 10.391480379000029, 12.852749816000028 ],
							[ 10.382080303000066, 12.856089755000028 ],
							[ 10.379719720000026, 12.857210337000026 ],
							[ 10.376199627000062, 12.858320488000061 ],
							[ 10.366800413000021, 12.861660055000073 ],
							[ 10.364460100000031, 12.861630216000037 ],
							[ 10.359769699000026, 12.861570511000025 ],
							[ 10.355090259000065, 12.861519759000032 ],
							[ 10.349230017000025, 12.861450067000021 ],
							[ 10.341049897000062, 12.860199973000022 ],
							[ 10.334460375000049, 12.858780155000034 ],
							[ 10.330539664000071, 12.857769917000041 ],
							[ 10.32703964500007, 12.856569998000055 ],
							[ 10.32236980600004, 12.855360092000069 ],
							[ 10.31886985400007, 12.854169705000061 ],
							[ 10.306039542000065, 12.849409951000041 ],
							[ 10.299039873000027, 12.847020357000076 ],
							[ 10.295520090000025, 12.84698006900004 ],
							[ 10.292012195000041, 12.846247639000069 ],
							[ 10.289679543000034, 12.845760252000048 ],
							[ 10.287349599000038, 12.844579643000031 ],
							[ 10.281510267000044, 12.843359693000025 ],
							[ 10.278010537000057, 12.842160442000022 ],
							[ 10.274499995000042, 12.842119925000077 ],
							[ 10.272170128000027, 12.840940350000039 ],
							[ 10.268649844000038, 12.840900366000028 ],
							[ 10.261609838000027, 12.843119686000023 ],
							[ 10.259260446000042, 12.843089773000031 ],
							[ 10.255740123000066, 12.844199916000036 ],
							[ 10.252229530000022, 12.844149581000067 ],
							[ 10.249860011000067, 12.846429784000065 ],
							[ 10.247469671000033, 12.851010520000045 ],
							[ 10.247440238000024, 12.854459663000057 ],
							[ 10.248709167000072, 12.857031431000053 ],
							[ 10.253423405000035, 12.860975315000076 ],
							[ 10.259405937000054, 12.862912796000046 ],
							[ 10.274029427000073, 12.867430938000041 ],
							[ 10.276028283000073, 12.869381747000034 ],
							[ 10.276713104000066, 12.875252322000051 ],
							[ 10.273425446000033, 12.884399213000052 ],
							[ 10.271606578000046, 12.894295272000022 ],
							[ 10.271590457000059, 12.896200358000044 ],
							[ 10.271550363000074, 12.899660264000033 ],
							[ 10.272788798000022, 12.90488917600004 ],
							[ 10.273779823000041, 12.910050581000064 ],
							[ 10.274889721000022, 12.915820017000044 ],
							[ 10.276009373000022, 12.920430139000075 ],
							[ 10.279470428000025, 12.926230490000023 ],
							[ 10.281729971000061, 12.933170483000026 ],
							[ 10.281689412000048, 12.937769888000048 ],
							[ 10.281649412000036, 12.941229766000049 ],
							[ 10.281600246000039, 12.945830326000078 ],
							[ 10.281579717000056, 12.948139913000034 ],
							[ 10.281550547000052, 12.95044047600004 ],
							[ 10.280360410000071, 12.952730367000072 ],
							[ 10.280320357000051, 12.956179504000033 ],
							[ 10.279100420000077, 12.960769397000035 ],
							[ 10.277879889000076, 12.965359823000028 ],
							[ 10.27668968200004, 12.967650254000034 ],
							[ 10.27429945800003, 12.972229989000027 ],
							[ 10.265979921000053, 12.983639770000025 ],
							[ 10.263600363000023, 12.987070305000032 ],
							[ 10.262389886000051, 12.99050984400003 ],
							[ 10.257600032000028, 13.000809774000061 ],
							[ 10.257599524000057, 13.000869218000048 ],
							[ 10.255130283000028, 13.00228474100004 ],
							[ 10.253785476000076, 13.004147759000034 ],
							[ 10.25352169100006, 13.006007157000056 ],
							[ 10.254337096000029, 13.007331923000038 ],
							[ 10.255154358000027, 13.00918768400004 ],
							[ 10.255466847000037, 13.009947575000069 ],
							[ 10.255150048000075, 13.011139603000061 ],
							[ 10.254797588000031, 13.012141113000041 ],
							[ 10.251654829000074, 13.013181970000062 ],
							[ 10.245713605000049, 13.014529371000037 ],
							[ 10.240038029000061, 13.014548288000071 ],
							[ 10.234892961000071, 13.011644846000024 ],
							[ 10.230835379000041, 13.010596254000063 ],
							[ 10.230024591000074, 13.010598929000025 ],
							[ 10.228677810000022, 13.011930876000065 ],
							[ 10.226251789000059, 13.013797372000056 ],
							[ 10.222208635000072, 13.016996661000064 ],
							[ 10.217893341000035, 13.019665793000058 ],
							[ 10.212491530000023, 13.020745401000056 ],
							[ 10.208979800000066, 13.021287804000053 ],
							[ 10.205194232000053, 13.020769055000073 ],
							[ 10.200594341000055, 13.019190886000047 ],
							[ 10.194908110000029, 13.016023134000022 ],
							[ 10.184349357000031, 13.01048129000003 ],
							[ 10.177581496000073, 13.007051205000039 ],
							[ 10.166744428000072, 12.998854743000038 ],
							[ 10.156859249000036, 12.992380752000031 ],
							[ 10.150629108000032, 12.987886500000059 ],
							[ 10.147113374000071, 12.987100827000063 ],
							[ 10.134960714000044, 12.989793032000023 ],
							[ 10.129021105000049, 12.99166957500006 ],
							[ 10.117137600000035, 12.994094881000024 ],
							[ 10.107409399000062, 12.994389400000045 ],
							[ 10.084418104000065, 12.987819433000027 ],
							[ 10.077516898000056, 12.984520669000062 ],
							[ 10.070477954000069, 12.980292917000043 ],
							[ 10.064530312000045, 12.979513438000026 ],
							[ 10.051280814000052, 12.976896031000024 ],
							[ 10.036409347000074, 12.974017144000072 ],
							[ 10.021532090000051, 12.969013666000023 ],
							[ 10.019636706000028, 12.967691344000059 ],
							[ 10.014505313000029, 12.968767349000075 ],
							[ 10.008288497000024, 12.968253209000068 ],
							[ 9.993947192000064, 12.961388666000062 ],
							[ 9.99123908100006, 12.959271859000069 ],
							[ 9.986634345000027, 12.955301542000029 ],
							[ 9.981763838000063, 12.952924898000049 ],
							[ 9.977706213000033, 12.951342548000071 ],
							[ 9.975271368000051, 12.950286917000028 ],
							[ 9.974190515000032, 12.950289743000042 ],
							[ 9.969869890000041, 12.951363015000027 ],
							[ 9.964736447000064, 12.951641835000032 ],
							[ 9.957438416000059, 12.950864141000068 ],
							[ 9.95284048700006, 12.949282908000043 ],
							[ 9.949321380000072, 12.946902366000074 ],
							[ 9.944177538000076, 12.94319839700006 ],
							[ 9.939843044000042, 12.938961311000071 ],
							[ 9.936853464000023, 12.93233127700006 ],
							[ 9.933594483000036, 12.925967410000055 ],
							[ 9.930882188000055, 12.921991654000067 ],
							[ 9.925725235000073, 12.912977402000024 ],
							[ 9.920239031000051, 12.901818147000029 ],
							[ 9.920269825000048, 12.898860254000056 ],
							[ 9.917964204000043, 12.895380187000058 ],
							[ 9.916830287000039, 12.891909665000071 ],
							[ 9.914524745000051, 12.888429594000058 ],
							[ 9.912207788000046, 12.886099760000036 ],
							[ 9.911061363000044, 12.883779492000031 ],
							[ 9.909926403000043, 12.880310079000026 ],
							[ 9.908780020000052, 12.878000522000036 ],
							[ 9.906487270000071, 12.873370123000029 ],
							[ 9.903022970000052, 12.868719944000077 ],
							[ 9.900717729000064, 12.865240337000046 ],
							[ 9.897241511000061, 12.861740376000057 ],
							[ 9.894936351000069, 12.858260175000055 ],
							[ 9.89263181900003, 12.854779940000071 ],
							[ 9.892655402000059, 12.852470358000062 ],
							[ 9.888009014000033, 12.848960112000043 ],
							[ 9.885679227000026, 12.847780385000021 ],
							[ 9.881903474000069, 12.844362351000029 ],
							[ 9.874080847000073, 12.83728045600003 ],
							[ 9.869422093000026, 12.834919678000063 ],
							[ 9.864750078000043, 12.833709694000049 ],
							[ 9.858919972000024, 12.831339634000074 ],
							[ 9.856578759000058, 12.831309997000062 ],
							[ 9.850748747000068, 12.828940383000031 ],
							[ 9.848431454000036, 12.826609588000053 ],
							[ 9.846090274000062, 12.826579867000021 ],
							[ 9.833259748000046, 12.821820233000039 ],
							[ 9.830918598000039, 12.82178987900005 ],
							[ 9.827406021000058, 12.821749672000067 ],
							[ 9.82274721400006, 12.819390568000074 ],
							[ 9.820417246000034, 12.818209876000026 ],
							[ 9.815733849000026, 12.818149531000074 ],
							[ 9.812233653000021, 12.816960609000034 ],
							[ 9.799769413000035, 12.814500074000023 ],
							[ 9.79586723400007, 12.814459833000058 ],
							[ 9.788853479000068, 12.813219993000075 ],
							[ 9.786512391000031, 12.813190471000041 ],
							[ 9.780670108000038, 12.811970033000023 ],
							[ 9.773657620000051, 12.810729887000036 ],
							[ 9.770145158000048, 12.810689714000034 ],
							[ 9.764302992000069, 12.809470149000049 ],
							[ 9.758449462000044, 12.809399616000064 ],
							[ 9.758126700000048, 12.809395793000022 ],
							[ 9.752594808000026, 12.809330142000022 ],
							[ 9.747912688000042, 12.809270071000071 ],
							[ 9.744399094000073, 12.809229640000069 ],
							[ 9.740875231000075, 12.810340018000034 ],
							[ 9.73619195200007, 12.810279821000051 ],
							[ 9.726465483000027, 12.812100533000034 ],
							[ 9.723300891000065, 12.811280165000028 ],
							[ 9.716275968000048, 12.811189786000057 ],
							[ 9.711592684000038, 12.811139999000034 ],
							[ 9.702213992000054, 12.812170174000073 ],
							[ 9.700415011000075, 12.812148734000061 ],
							[ 9.696359868000059, 12.812100370000053 ],
							[ 9.691677177000031, 12.812049777000027 ],
							[ 9.68934712500004, 12.81086995000004 ],
							[ 9.682323350000047, 12.810779483000033 ],
							[ 9.678810318000046, 12.810740710000061 ],
							[ 9.670626825000056, 12.809490037000046 ],
							[ 9.665942986000061, 12.809430070000076 ],
							[ 9.658108450000043, 12.809121391000076 ],
							[ 9.692076422000071, 12.839449784000067 ],
							[ 9.707670120000046, 12.856274699000039 ],
							[ 9.718944455000042, 12.867548186000022 ],
							[ 9.724449736000054, 12.873053858000048 ],
							[ 9.724457568000048, 12.876945885000055 ],
							[ 9.725515750000056, 12.884123797000029 ],
							[ 9.729692766000028, 12.885141354000041 ],
							[ 9.736551216000066, 12.885609221000038 ],
							[ 9.740706689000035, 12.888786117000052 ],
							[ 9.744639570000061, 12.892719377000049 ],
							[ 9.74778562900002, 12.895603363000077 ],
							[ 9.749882626000044, 12.897700180000072 ],
							[ 9.752096922000021, 12.898969697000041 ],
							[ 9.754864772000076, 12.903206870000076 ],
							[ 9.758011037000074, 12.905828615000075 ],
							[ 9.760865964000061, 12.907960001000049 ],
							[ 9.763789460000055, 12.910960115000023 ],
							[ 9.765743027000042, 12.915270071000066 ],
							[ 9.770028917000047, 12.919210217000057 ],
							[ 9.774708988000043, 12.925390103000041 ],
							[ 9.777435402000037, 12.927269657000068 ],
							[ 9.781720995000057, 12.931200077000028 ],
							[ 9.786006684000029, 12.935139499000059 ],
							[ 9.788732645000039, 12.937009936000038 ],
							[ 9.791852848000076, 12.94112969300005 ],
							[ 9.794578324000042, 12.943010251000032 ],
							[ 9.797501819000047, 12.946009538000055 ],
							[ 9.800227923000023, 12.947879890000024 ],
							[ 9.804335489000039, 12.952720541000076 ],
							[ 9.808980803000054, 12.962939435000067 ],
							[ 9.820103802000062, 12.973578495000027 ],
							[ 9.827841644000046, 12.99147130700004 ],
							[ 9.842832558000055, 13.010332327000071 ],
							[ 9.843140478000066, 13.010640246000037 ],
							[ 9.843765450000035, 13.01516532100004 ],
							[ 9.844819194000024, 13.019265735000033 ],
							[ 9.844836051000073, 13.026445612000032 ],
							[ 9.854252863000056, 13.034629752000058 ],
							[ 9.865746152000042, 13.037680343000034 ],
							[ 9.905100850000053, 13.064234 ],
							[ 9.905866343000071, 13.065019662000054 ],
							[ 9.908593347000021, 13.066889779000064 ],
							[ 9.911712610000052, 13.071020366000027 ],
							[ 9.914635827000041, 13.074020396000037 ],
							[ 9.917362359000037, 13.07588987400004 ],
							[ 9.919119067000054, 13.079079726000032 ],
							[ 9.926131246000068, 13.084890334000022 ],
							[ 9.930416877000027, 13.088820690000034 ],
							[ 9.932371089000071, 13.093130273000043 ],
							[ 9.935096736000048, 13.095009787000038 ],
							[ 9.937823537000043, 13.096880242000054 ],
							[ 9.939579963000028, 13.100069993000034 ],
							[ 9.945228833000044, 13.104940202000023 ],
							[ 9.948151435000057, 13.107939983000051 ],
							[ 9.952635007000026, 13.113000027000055 ],
							[ 9.955951457000026, 13.118249999000057 ],
							[ 9.957905569000047, 13.122559440000032 ],
							[ 9.96082900600004, 13.125559671000076 ],
							[ 9.963555588000077, 13.127429936000055 ],
							[ 9.966281093000021, 13.129309773000045 ],
							[ 9.969006601000046, 13.13118 ],
							[ 9.973293253000065, 13.135109868000029 ],
							[ 9.976610188000052, 13.140359665000062 ],
							[ 9.978653997000038, 13.141769473000068 ],
							[ 9.980657493000024, 13.148640655000065 ],
							[ 9.992265, 13.159763562000023 ],
							[ 10.000002158000029, 13.167501189000063 ],
							[ 10.015960538000058, 13.174754835000044 ],
							[ 10.035788650000029, 13.183943818000046 ],
							[ 10.045943359000034, 13.19409848600003 ],
							[ 10.064320438000038, 13.204254386000059 ],
							[ 10.085599192000075, 13.214409959000022 ],
							[ 10.107844157000045, 13.224565409000036 ],
							[ 10.120901231000062, 13.229401821000067 ],
							[ 10.125508508000053, 13.230798036000067 ],
							[ 10.12825983700003, 13.232689595000068 ],
							[ 10.13645062300003, 13.235600715000032 ],
							[ 10.14556486400005, 13.242941866000024 ],
							[ 10.176514685000029, 13.255999544000076 ],
							[ 10.198276660000033, 13.264220161000026 ],
							[ 10.201159711000059, 13.266241821000051 ],
							[ 10.208432180000045, 13.267605819000039 ],
							[ 10.233096108000041, 13.26808952500005 ],
							[ 10.252439487000061, 13.270023271000071 ],
							[ 10.267431185000021, 13.271474814000044 ],
							[ 10.281180660000075, 13.270557309000026 ],
							[ 10.289193162000061, 13.270023529000071 ],
							[ 10.327880868000022, 13.272925530000066 ],
							[ 10.365601801000025, 13.275826506000044 ],
							[ 10.398969532000024, 13.276794368000026 ],
							[ 10.441526640000063, 13.281629821000024 ],
							[ 10.454099543000041, 13.280662451000069 ],
							[ 10.46570613800003, 13.282113005000042 ],
							[ 10.481664425000076, 13.288884014000075 ],
							[ 10.492369707000023, 13.294809345000033 ],
							[ 10.497969806000071, 13.296629722000034 ],
							[ 10.501859445000036, 13.298310030000039 ],
							[ 10.505559663000042, 13.298870228000055 ],
							[ 10.509449946000075, 13.300550454000074 ],
							[ 10.513340286000073, 13.302240229000063 ],
							[ 10.525019500000042, 13.307290097000077 ],
							[ 10.528710077000028, 13.307850105000057 ],
							[ 10.532409866000023, 13.308409483000048 ],
							[ 10.536299777000067, 13.310089986000037 ],
							[ 10.540189743000042, 13.311770448000061 ],
							[ 10.54524966200006, 13.313269776000027 ],
							[ 10.549139704000027, 13.314950145000068 ],
							[ 10.552840167000056, 13.315509873000053 ],
							[ 10.559060328000044, 13.316820012000051 ],
							[ 10.563150496000048, 13.319629839000072 ],
							[ 10.565870191000045, 13.321499680000045 ],
							[ 10.57112980200003, 13.324119764000045 ],
							[ 10.574819478000052, 13.324680448000038 ],
							[ 10.578520081000022, 13.325239922000037 ],
							[ 10.583379698000044, 13.325610269000038 ],
							[ 10.586109900000054, 13.327480473000037 ],
							[ 10.590000329000077, 13.329170040000065 ],
							[ 10.592720240000062, 13.33104023900006 ],
							[ 10.596220235000033, 13.330469953000033 ],
							[ 10.598950585000068, 13.332349670000042 ],
							[ 10.604399796000052, 13.336089878000053 ],
							[ 10.608289822000074, 13.337779792000049 ],
							[ 10.613549983000041, 13.340399866000041 ],
							[ 10.617440075000047, 13.342080077000048 ],
							[ 10.620160267000074, 13.343959682000047 ],
							[ 10.625220375000026, 13.345450576000076 ],
							[ 10.628919555000039, 13.346010130000025 ],
							[ 10.632809758000064, 13.347689625000044 ],
							[ 10.636500341000044, 13.348250252000071 ],
							[ 10.641559463000021, 13.349749407000047 ],
							[ 10.650320148000048, 13.351799902000039 ],
							[ 10.654009647000066, 13.352359806000038 ],
							[ 10.656740442000057, 13.354230063000045 ],
							[ 10.660819716000049, 13.357040549000033 ],
							[ 10.664520225000047, 13.357600301000048 ],
							[ 10.66820980600005, 13.358160063000071 ],
							[ 10.673270308000042, 13.359649749000027 ],
							[ 10.676969719000056, 13.360210511000048 ],
							[ 10.682019957000023, 13.361709713000039 ],
							[ 10.68688938300005, 13.362079799000071 ],
							[ 10.690580200000056, 13.362640465000027 ],
							[ 10.695440424000026, 13.36301048100006 ],
							[ 10.700499391000051, 13.364499805000037 ],
							[ 10.704200041000036, 13.365059718000055 ],
							[ 10.70769038800006, 13.364489580000054 ],
							[ 10.71138933800006, 13.365050557000075 ],
							[ 10.71877975600006, 13.366170199000067 ],
							[ 10.723440065000034, 13.365409777000025 ],
							[ 10.729470338000056, 13.365590348000069 ],
							[ 10.732970507000061, 13.365030091000051 ],
							[ 10.737629625000068, 13.364270054000031 ],
							[ 10.742289353000047, 13.363519562000022 ],
							[ 10.745789420000051, 13.362950152000053 ],
							[ 10.75084980400004, 13.364450635000026 ],
							[ 10.756880068000044, 13.36462963300005 ],
							[ 10.761540283000045, 13.363869866000073 ],
							[ 10.767569398000035, 13.364049826000041 ],
							[ 10.772230218000061, 13.363300103000029 ],
							[ 10.775930354000025, 13.36384969900007 ],
							[ 10.780790121000052, 13.364219737000042 ],
							[ 10.78544974600004, 13.363469854000073 ],
							[ 10.789149958000053, 13.364030056000047 ],
							[ 10.792639597000061, 13.363459683000031 ],
							[ 10.797499376000076, 13.363829503000034 ],
							[ 10.803729906000058, 13.365140034000035 ],
							[ 10.807419738000021, 13.365689960000054 ],
							[ 10.811320254000066, 13.367379968000023 ],
							[ 10.815010172000029, 13.367939982000053 ],
							[ 10.819870038000033, 13.368310085000076 ],
							[ 10.823570353000036, 13.368869945000029 ],
							[ 10.830559969000035, 13.36773015600005 ],
							[ 10.833860235000031, 13.36604006500005 ],
							[ 10.837360201000024, 13.365469779000023 ],
							[ 10.840860190000058, 13.364910194000061 ],
							[ 10.846890521000034, 13.365090571000053 ],
							[ 10.851940367000054, 13.366580130000045 ],
							[ 10.858169411000063, 13.367890335000027 ],
							[ 10.862830045000067, 13.367130429000042 ],
							[ 10.866530414000067, 13.367689864000056 ],
							[ 10.870220424000024, 13.368249888000037 ],
							[ 10.873920235000071, 13.368809826000074 ],
							[ 10.877610271000037, 13.369370351000043 ],
							[ 10.881310671000051, 13.369930213000032 ],
							[ 10.885000160000061, 13.370490093000058 ],
							[ 10.888500072000056, 13.36991990100006 ],
							[ 10.891999994000059, 13.369350230000066 ],
							[ 10.895489578000024, 13.368789632000073 ],
							[ 10.90035984900004, 13.369150155000057 ],
							[ 10.904049926000027, 13.369709845000045 ],
							[ 10.907750373000056, 13.37027 ],
							[ 10.911439908000034, 13.370829619000062 ],
							[ 10.915140382000061, 13.371390275000067 ],
							[ 10.921359968000047, 13.372699475000047 ],
							[ 10.926019386000064, 13.371939915000041 ],
							[ 10.930879425000057, 13.372309702000052 ],
							[ 10.935740629000065, 13.372679973000061 ],
							[ 10.940210314000069, 13.370799831000056 ],
							[ 10.96429418300005, 13.37254606700003 ],
							[ 10.987990352000054, 13.373996792000071 ],
							[ 11.020874972000058, 13.373996482000052 ],
							[ 11.038768533000052, 13.373996907000048 ],
							[ 11.057628666000028, 13.37399681100004 ],
							[ 11.065850309000041, 13.374480174000041 ],
							[ 11.086160519000032, 13.374963802000025 ],
							[ 11.221810269000059, 13.373996890000058 ]
						]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: {
				name: 'Zamfara',
				admin1Pcod: 'NG037',
				admin1RefN: 'Zamfara',
				admin1AltN: null,
				admin1Al_1: null,
				admin0Name: 'Nigeria',
				admin0Pcod: 'NG',
				date: '2016/11/29',
				validOn: '2016/12/15',
				validTo: null,
				Shape_Leng: 9.466144,
				Shape_Area: 2.782855
			},
			geometry: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[ 6.848701061000043, 13.082395727000062 ],
							[ 6.873103248000064, 13.053113057000076 ],
							[ 6.91052011000005, 13.013255961000027 ],
							[ 6.92760187600004, 12.990480287000025 ],
							[ 6.935735781000062, 12.990480217000027 ],
							[ 6.978846164000061, 12.992107291000025 ],
							[ 7.013822917000027, 12.99129414500004 ],
							[ 7.031717772000036, 12.99698827900005 ],
							[ 7.038205557000026, 12.997434887000054 ],
							[ 7.047684777000029, 12.999111687000038 ],
							[ 7.049950823000074, 12.972053865000078 ],
							[ 7.043148520000045, 12.942550243000028 ],
							[ 7.039062152000042, 12.929247824000072 ],
							[ 7.039038613000059, 12.928661263000038 ],
							[ 7.038596589000065, 12.925420630000076 ],
							[ 7.037804640000047, 12.919612106000045 ],
							[ 7.036598067000057, 12.910766459000058 ],
							[ 7.036237653000057, 12.908121128000062 ],
							[ 7.035118239000042, 12.899912073000053 ],
							[ 7.034158347000073, 12.892871618000072 ],
							[ 7.032017275000044, 12.887271768000062 ],
							[ 7.030583881000041, 12.883523043000025 ],
							[ 7.028871104000075, 12.879043495000076 ],
							[ 7.027736066000045, 12.86911570500007 ],
							[ 7.027179835000027, 12.864246205000029 ],
							[ 7.02685124900006, 12.861372227000061 ],
							[ 7.025973328000021, 12.853690370000038 ],
							[ 7.025617042000022, 12.850574431000041 ],
							[ 7.024695284000074, 12.843507560000035 ],
							[ 7.023717006000027, 12.836007060000043 ],
							[ 7.023177278000048, 12.831866450000064 ],
							[ 7.02887097200005, 12.817224599000042 ],
							[ 7.028547247000063, 12.810107214000027 ],
							[ 7.02818099700005, 12.802049510000074 ],
							[ 7.028057653000076, 12.799329705000048 ],
							[ 7.028261175000068, 12.792810577000068 ],
							[ 7.028411754000047, 12.787983202000021 ],
							[ 7.028871151000033, 12.77330117300005 ],
							[ 7.031000223000035, 12.753426314000023 ],
							[ 7.031310897000026, 12.750525758000038 ],
							[ 7.032249792000073, 12.746234161000075 ],
							[ 7.033329820000063, 12.74129700800006 ],
							[ 7.033925977000024, 12.738571380000053 ],
							[ 7.034697460000075, 12.73504329900004 ],
							[ 7.037004977000038, 12.724495731000047 ],
							[ 7.037489022000045, 12.721348617000046 ],
							[ 7.038873749000061, 12.712348109000061 ],
							[ 7.039511727000047, 12.708200686000055 ],
							[ 7.040604167000026, 12.701101995000045 ],
							[ 7.041885409000031, 12.692773802000033 ],
							[ 7.042027527000073, 12.688086485000042 ],
							[ 7.042132928000058, 12.684604581000031 ],
							[ 7.042272583000056, 12.679998592000061 ],
							[ 7.042412238000054, 12.675392029000022 ],
							[ 7.042586862000064, 12.66963650100007 ],
							[ 7.04269892700006, 12.665930919000061 ],
							[ 7.043740790000072, 12.661762993000025 ],
							[ 7.045152606000045, 12.656115645000057 ],
							[ 7.045952458000045, 12.652917048000063 ],
							[ 7.046272889000022, 12.650032875000022 ],
							[ 7.046776632000046, 12.645497151000029 ],
							[ 7.04735947000006, 12.639825114000075 ],
							[ 7.047209720000069, 12.637671229000034 ],
							[ 7.046957391000035, 12.63403887000004 ],
							[ 7.045952386000067, 12.619567036000035 ],
							[ 7.046849227000052, 12.614409610000052 ],
							[ 7.047660360000066, 12.609744969000076 ],
							[ 7.048277872000028, 12.606196247000071 ],
							[ 7.049206359000038, 12.600858771000048 ],
							[ 7.049877201000072, 12.597056661000067 ],
							[ 7.051230394000072, 12.589386940000054 ],
							[ 7.05164637200005, 12.587030368000057 ],
							[ 7.050019217000056, 12.57320211900003 ],
							[ 7.050656294000021, 12.568359155000053 ],
							[ 7.052052837000076, 12.557747841000037 ],
							[ 7.052052978000063, 12.553363103000038 ],
							[ 7.052052950000075, 12.548800397000036 ],
							[ 7.051743141000031, 12.54539029700004 ],
							[ 7.05123951500002, 12.539853207000021 ],
							[ 7.052393970000026, 12.534851910000043 ],
							[ 7.053679847000069, 12.52927896500006 ],
							[ 7.053680077000024, 12.516263713000058 ],
							[ 7.054828049000037, 12.510686074000034 ],
							[ 7.056012260000045, 12.504935189000037 ],
							[ 7.057652856000061, 12.496967466000058 ],
							[ 7.058836959000075, 12.491216566000048 ],
							[ 7.059373860000051, 12.488608617000068 ],
							[ 7.059929725000075, 12.480822793000073 ],
							[ 7.060186863000069, 12.477220732000035 ],
							[ 7.060515786000053, 12.474998711000069 ],
							[ 7.06152189900007, 12.468209005000062 ],
							[ 7.06256761800006, 12.461150004000046 ],
							[ 7.063440770000057, 12.455258479000065 ],
							[ 7.063898952000045, 12.450829752000061 ],
							[ 7.064253249000046, 12.447405596000067 ],
							[ 7.06520830900007, 12.438171519000036 ],
							[ 7.065880823000043, 12.431669317000058 ],
							[ 7.066891724000072, 12.423247389000039 ],
							[ 7.068321183000023, 12.411333807000062 ],
							[ 7.070996234000063, 12.406206944000076 ],
							[ 7.07530027100006, 12.397957967000025 ],
							[ 7.078082148000021, 12.392625958000053 ],
							[ 7.083239025000069, 12.382311768000022 ],
							[ 7.085402487000067, 12.377985003000049 ],
							[ 7.087827265000044, 12.372057840000025 ],
							[ 7.089445006000062, 12.368102841000052 ],
							[ 7.092723386000046, 12.360089048000077 ],
							[ 7.093268866000074, 12.357838711000056 ],
							[ 7.094387969000024, 12.353222912000035 ],
							[ 7.098339534000047, 12.336923705000061 ],
							[ 7.09923083700005, 12.333247417000052 ],
							[ 7.100283281000031, 12.325457765000067 ],
							[ 7.100743186000045, 12.322052154000062 ],
							[ 7.10166265600003, 12.315250515000059 ],
							[ 7.102447136000023, 12.309444271000075 ],
							[ 7.103297570000052, 12.303151331000038 ],
							[ 7.103837382000052, 12.300093575000062 ],
							[ 7.104324252000026, 12.297334426000077 ],
							[ 7.104723569000043, 12.295072580000067 ],
							[ 7.105737797000074, 12.289322846000061 ],
							[ 7.108170596000036, 12.285847592000039 ],
							[ 7.111431670000059, 12.281188933000067 ],
							[ 7.114380253000036, 12.276134308000053 ],
							[ 7.117474655000024, 12.270830097000044 ],
							[ 7.123377755000035, 12.26071001400004 ],
							[ 7.128513284000064, 12.251906326000039 ],
							[ 7.129437044000042, 12.250058969000065 ],
							[ 7.133769608000023, 12.241394107000076 ],
							[ 7.135386823000033, 12.238159210000049 ],
							[ 7.137232179000023, 12.234468370000059 ],
							[ 7.141527544000041, 12.225877814000057 ],
							[ 7.144321430000048, 12.215584762000049 ],
							[ 7.14693354000002, 12.205961961000071 ],
							[ 7.147886883000069, 12.202449697000077 ],
							[ 7.149255154000059, 12.197408933000077 ],
							[ 7.151547088000029, 12.188242130000049 ],
							[ 7.152508642000043, 12.184393723000028 ],
							[ 7.152087717000029, 12.180041233000054 ],
							[ 7.151186433000021, 12.170726934000072 ],
							[ 7.150991934000047, 12.168714609000062 ],
							[ 7.150643484000057, 12.165113360000021 ],
							[ 7.150068630000021, 12.15917756500005 ],
							[ 7.145618560000059, 12.147162448000074 ],
							[ 7.141934354000057, 12.137215535000053 ],
							[ 7.138508385000023, 12.132076237000035 ],
							[ 7.136552336000022, 12.129142640000055 ],
							[ 7.128158720000044, 12.116551547000029 ],
							[ 7.126100958000052, 12.113465383000062 ],
							[ 7.12369577000004, 12.107033853000075 ],
							[ 7.121599311000068, 12.101425939000023 ],
							[ 7.119086390000064, 12.097154343000057 ],
							[ 7.115662091000047, 12.091333451000025 ],
							[ 7.115156054000067, 12.090473227000075 ],
							[ 7.113465424000026, 12.087597902000027 ],
							[ 7.11134241600007, 12.085965041000065 ],
							[ 7.106711960000041, 12.082402961000071 ],
							[ 7.102891107000062, 12.079464194000025 ],
							[ 7.099094783000055, 12.074824316000047 ],
							[ 7.09557011000004, 12.070516312000052 ],
							[ 7.090689728000029, 12.06400971100004 ],
							[ 7.093943634000027, 12.060755676000042 ],
							[ 7.100131902000044, 12.05291758900006 ],
							[ 7.103155486000048, 12.049087687000053 ],
							[ 7.106144364000045, 12.045301664000021 ],
							[ 7.113035705000073, 12.039845551000042 ],
							[ 7.125666253000077, 12.029846354000028 ],
							[ 7.143561298000066, 12.015204528000027 ],
							[ 7.151047227000049, 12.01083770300005 ],
							[ 7.153322186000025, 12.009511099000065 ],
							[ 7.157708167000067, 12.007561612000075 ],
							[ 7.168660701000022, 12.002694060000067 ],
							[ 7.173856663000038, 12.000384503000078 ],
							[ 7.178195599000048, 11.998456 ],
							[ 7.18992578700005, 11.99324303700007 ],
							[ 7.200499885000056, 11.988362538000047 ],
							[ 7.202923703000067, 11.987480995000055 ],
							[ 7.209447361000059, 11.985108428000046 ],
							[ 7.213514417000056, 11.97778816500005 ],
							[ 7.213514299000053, 11.96640016200007 ],
							[ 7.216767659000027, 11.958266301000037 ],
							[ 7.222461581000061, 11.953386043000023 ],
							[ 7.224144500000023, 11.950357241000063 ],
							[ 7.226529145000029, 11.946065052000051 ],
							[ 7.232506738000041, 11.946064711000076 ],
							[ 7.234662987000036, 11.946064792000072 ],
							[ 7.236446040000033, 11.944282791000035 ],
							[ 7.239543412000046, 11.941184904000067 ],
							[ 7.241856941000037, 11.933309664000035 ],
							[ 7.235048793000033, 11.927378848000046 ],
							[ 7.23241881000007, 11.925087160000032 ],
							[ 7.23057600900006, 11.918425583000044 ],
							[ 7.218699791000063, 11.911144826000054 ],
							[ 7.209920218000036, 11.911102652000068 ],
							[ 7.20428997700003, 11.908409844000062 ],
							[ 7.199686493000058, 11.906207820000077 ],
							[ 7.195137937000027, 11.906621948000065 ],
							[ 7.190739081000061, 11.907021494000048 ],
							[ 7.182542757000022, 11.909471450000069 ],
							[ 7.171418290000076, 11.910560437000072 ],
							[ 7.164710031000027, 11.911902582000039 ],
							[ 7.154949108000039, 11.917595762000076 ],
							[ 7.143561205000026, 11.911089063000077 ],
							[ 7.143201004000048, 11.90532089900006 ],
							[ 7.142747819000022, 11.898074253000061 ],
							[ 7.142747923000059, 11.886686176000069 ],
							[ 7.141120928000021, 11.878552244000048 ],
							[ 7.139681873000029, 11.876740445000053 ],
							[ 7.137064, 11.873513051000032 ],
							[ 7.133032239000045, 11.868394846000058 ],
							[ 7.13014019600007, 11.86472420900003 ],
							[ 7.125672451000071, 11.861745634000044 ],
							[ 7.122819482000068, 11.859844136000049 ],
							[ 7.121176295000055, 11.858017678000067 ],
							[ 7.117769985000052, 11.854233590000035 ],
							[ 7.113694743000053, 11.840194173000043 ],
							[ 7.106413045000068, 11.831127317000039 ],
							[ 7.093611955000029, 11.826546958000051 ],
							[ 7.085381595000058, 11.823795469000061 ],
							[ 7.076295360000074, 11.819258492000074 ],
							[ 7.074828232000073, 11.817546853000067 ],
							[ 7.072388063000062, 11.808599280000067 ],
							[ 7.06991574400007, 11.807569440000066 ],
							[ 7.067096847000073, 11.806394852000039 ],
							[ 7.062627228000053, 11.804531938000025 ],
							[ 7.054534421000028, 11.800853868000047 ],
							[ 7.052335794000044, 11.801223721000042 ],
							[ 7.046242216000053, 11.801010372000064 ],
							[ 7.03757960300004, 11.804514635000032 ],
							[ 7.033870210000032, 11.806538636000028 ],
							[ 7.02948718500005, 11.807271958000058 ],
							[ 7.026009990000034, 11.808853018000036 ],
							[ 7.021888314000023, 11.809108535000064 ],
							[ 7.01948268600006, 11.81271736900004 ],
							[ 7.016262951000044, 11.817546781000033 ],
							[ 7.015747575000034, 11.81766833100005 ],
							[ 7.011215133000064, 11.817085316000032 ],
							[ 6.999316357000055, 11.815215136000063 ],
							[ 6.991087274000051, 11.812461576000032 ],
							[ 6.984680032000028, 11.811523995000073 ],
							[ 6.970939332000057, 11.811449227000026 ],
							[ 6.949891275000027, 11.807721668000056 ],
							[ 6.937113332000024, 11.799524517000066 ],
							[ 6.926131676000068, 11.797657093000055 ],
							[ 6.916048201000024, 11.802556884000069 ],
							[ 6.908893028000023, 11.801279326000042 ],
							[ 6.89669217900007, 11.794771129000026 ],
							[ 6.895235048000075, 11.791310161000069 ],
							[ 6.892148147000057, 11.783979252000051 ],
							[ 6.890184892000036, 11.779316994000055 ],
							[ 6.890184895000061, 11.771125564000045 ],
							[ 6.890184857000065, 11.763773743000058 ],
							[ 6.890184912000052, 11.759495530000038 ],
							[ 6.890184988000044, 11.756541112000036 ],
							[ 6.889949323000053, 11.747831361000067 ],
							[ 6.889676998000027, 11.737748985000053 ],
							[ 6.889583213000037, 11.734282704000066 ],
							[ 6.889426798000045, 11.72849108500003 ],
							[ 6.889371416000074, 11.726445443000046 ],
							[ 6.888473404000024, 11.724150940000072 ],
							[ 6.882051009000065, 11.707737102000067 ],
							[ 6.876567284000032, 11.695399391000024 ],
							[ 6.875543729000071, 11.693095080000035 ],
							[ 6.871317499000043, 11.683587247000048 ],
							[ 6.869036084000072, 11.678454627000065 ],
							[ 6.865782692000039, 11.662999355000068 ],
							[ 6.855208499000071, 11.641850630000022 ],
							[ 6.851954362000072, 11.62070157200003 ],
							[ 6.852428562000057, 11.616435111000044 ],
							[ 6.853581514000041, 11.606060823000064 ],
							[ 6.854380584000069, 11.595219495000038 ],
							[ 6.856834968000044, 11.588979556000027 ],
							[ 6.86277350000006, 11.582584500000053 ],
							[ 6.867409276000046, 11.577592143000061 ],
							[ 6.876124302000051, 11.574422650000031 ],
							[ 6.886117812000066, 11.567017566000061 ],
							[ 6.902339317000042, 11.554482649000022 ],
							[ 6.904012721000072, 11.553189170000053 ],
							[ 6.904489077000051, 11.54961759300005 ],
							[ 6.905639649000022, 11.540987866000023 ],
							[ 6.906153638000035, 11.537390501000061 ],
							[ 6.906637771000021, 11.534001457000045 ],
							[ 6.907266635000042, 11.52960015900004 ],
							[ 6.904760858000031, 11.523586152000064 ],
							[ 6.90319929900005, 11.519839193000053 ],
							[ 6.902236846000051, 11.517914984000072 ],
							[ 6.900621374000025, 11.51468357400006 ],
							[ 6.895878723000067, 11.505198395000036 ],
							[ 6.903199205000021, 11.48649034400006 ],
							[ 6.907266648000075, 11.475102244000027 ],
							[ 6.90210778900007, 11.462204891000056 ],
							[ 6.900759090000065, 11.458833729000048 ],
							[ 6.893438377000052, 11.445820050000066 ],
							[ 6.891482011000051, 11.441417644000069 ],
							[ 6.886931336000032, 11.431177940000055 ],
							[ 6.886460285000055, 11.423166147000074 ],
							[ 6.886117842000033, 11.417349588000036 ],
							[ 6.886931369000024, 11.396202194000068 ],
							[ 6.887447246000022, 11.391299074000074 ],
							[ 6.888219481000021, 11.383961619000047 ],
							[ 6.888557998000067, 11.380746882000039 ],
							[ 6.885452352000073, 11.377198402000033 ],
							[ 6.882863974000031, 11.374239888000034 ],
							[ 6.874730193000062, 11.364479012000061 ],
							[ 6.869036103000042, 11.351464472000032 ],
							[ 6.864155664000066, 11.340076672000066 ],
							[ 6.855208376000064, 11.336822526000049 ],
							[ 6.851141375000054, 11.331942634000029 ],
							[ 6.84788754300007, 11.325435642000059 ],
							[ 6.838940161000039, 11.318114078000065 ],
							[ 6.828365914000074, 11.30754010000004 ],
							[ 6.823485423000022, 11.309167033000051 ],
							[ 6.816164862000051, 11.309980607000057 ],
							[ 6.808613193000042, 11.300234069000055 ],
							[ 6.799117683000077, 11.294807339000045 ],
							[ 6.789621292000049, 11.288024839000059 ],
							[ 6.783516950000035, 11.281241484000077 ],
							[ 6.773342658000047, 11.275815100000045 ],
							[ 6.76113358300006, 11.269032529000071 ],
							[ 6.759637865000059, 11.267686089000051 ],
							[ 6.754351126000074, 11.262928263000049 ],
							[ 6.752994597000054, 11.254110179000065 ],
							[ 6.749602791000029, 11.248005988000045 ],
							[ 6.745533370000032, 11.243258242000024 ],
							[ 6.746889994000071, 11.237832064000031 ],
							[ 6.746890166000071, 11.233761610000045 ],
							[ 6.744854970000063, 11.22833630100007 ],
							[ 6.744855051000059, 11.222909783000034 ],
							[ 6.743498268000053, 11.216805462000025 ],
							[ 6.736715848000074, 11.209343654000065 ],
							[ 6.732985039000027, 11.201883417000033 ],
							[ 6.734341627000049, 11.195100059000026 ],
							[ 6.738411356000029, 11.190352675000042 ],
							[ 6.73976804800003, 11.179499414000077 ],
							[ 6.742463699000041, 11.174532835000036 ],
							[ 6.73908952000005, 11.169325884000045 ],
							[ 6.733663427000067, 11.165255645000059 ],
							[ 6.731628318000048, 11.161186301000043 ],
							[ 6.735020113000076, 11.153725479000059 ],
							[ 6.733663500000034, 11.147619945000031 ],
							[ 6.729593726000076, 11.143550590000075 ],
							[ 6.722132582000029, 11.140837904000023 ],
							[ 6.71806299900004, 11.136090367000065 ],
							[ 6.713992967000024, 11.131341651000071 ],
							[ 6.712281159000042, 11.123435928000049 ],
							[ 6.709988223000039, 11.122801935000041 ],
							[ 6.706531976000065, 11.121846020000021 ],
							[ 6.706532042000049, 11.117098070000054 ],
							[ 6.707888482000044, 11.114384684000072 ],
							[ 6.713993114000061, 11.113706480000076 ],
							[ 6.716028234000021, 11.110315269000068 ],
							[ 6.716028164000022, 11.106245906000026 ],
							[ 6.711280334000037, 11.102175528000032 ],
							[ 6.706532050000021, 11.095392939000021 ],
							[ 6.702462795000031, 11.094715156000063 ],
							[ 6.697036285000024, 11.096749510000052 ],
							[ 6.694323136000037, 11.094714929000077 ],
							[ 6.68889699500005, 11.09471505700003 ],
							[ 6.686861926000063, 11.090644869000073 ],
							[ 6.688218573000029, 11.085219404000043 ],
							[ 6.688897022000049, 11.08047115100004 ],
							[ 6.690253810000058, 11.076401770000075 ],
							[ 6.691609960000051, 11.073009269000067 ],
							[ 6.689575313000034, 11.068262202000028 ],
							[ 6.682114223000042, 11.062835960000029 ],
							[ 6.676009559000022, 11.057409033000056 ],
							[ 6.673296546000074, 11.058087575000059 ],
							[ 6.670583357000055, 11.060122747000037 ],
							[ 6.667870436000044, 11.062157881000076 ],
							[ 6.663800784000045, 11.061479667000071 ],
							[ 6.663122170000065, 11.05673136200005 ],
							[ 6.657696330000022, 11.054696341000067 ],
							[ 6.653626377000023, 11.054696051000064 ],
							[ 6.645487291000052, 11.048591806000047 ],
							[ 6.638026173000071, 11.049948464000067 ],
							[ 6.621747375000041, 11.047913707000021 ],
							[ 6.618053521000036, 11.047745914000075 ],
							[ 6.606825348000029, 11.047235722000039 ],
							[ 6.595294336000052, 11.041131067000038 ],
							[ 6.59161384500004, 11.037204483000039 ],
							[ 6.585120306000022, 11.030278438000039 ],
							[ 6.575624541000025, 11.023495942000068 ],
							[ 6.563415466000038, 11.024851668000053 ],
							[ 6.557310585000039, 11.025530795000066 ],
							[ 6.548492932000045, 11.028242962000036 ],
							[ 6.534927344000039, 11.027564821000055 ],
							[ 6.527466353000023, 11.027564878000021 ],
							[ 6.51729251200004, 11.026208617000066 ],
							[ 6.51661395800005, 11.020782552000071 ],
							[ 6.512356174000047, 11.017003867000028 ],
							[ 6.50779639600006, 11.013320842000041 ],
							[ 6.498978599000054, 11.014678025000023 ],
							[ 6.49219620100007, 11.016034070000046 ],
							[ 6.490576664000059, 11.017249240000069 ],
							[ 6.486769547000051, 11.020104576000051 ],
							[ 6.478630517000056, 11.022138827000049 ],
							[ 6.471847551000053, 11.021460369000067 ],
							[ 6.46777769800002, 11.016713405000075 ],
							[ 6.466420994000032, 11.009251866000056 ],
							[ 6.463708269000051, 11.001112018000072 ],
							[ 6.458282051000026, 10.999078019000024 ],
							[ 6.452177474000052, 11.002469294000036 ],
							[ 6.446751128000074, 11.003825344000063 ],
							[ 6.442681806000053, 10.999077544000045 ],
							[ 6.439290122000045, 10.998398697000027 ],
							[ 6.433864022000023, 11.003147058000025 ],
							[ 6.428437799000051, 11.007895337000036 ],
							[ 6.419996281000067, 11.014126685000065 ],
							[ 6.418263570000022, 11.018747376000022 ],
							[ 6.417585427000063, 11.024173795000024 ],
							[ 6.411480931000028, 11.030278277000036 ],
							[ 6.404697919000057, 11.030278319000047 ],
							[ 6.401984588000062, 11.030277962000071 ],
							[ 6.398593473000062, 11.032313182000053 ],
							[ 6.392488995000065, 11.033669347000057 ],
							[ 6.387741199000061, 11.033669258000032 ],
							[ 6.382992836000028, 11.033669678000024 ],
							[ 6.374853603000076, 11.030956480000043 ],
							[ 6.36942713600007, 11.026208989000054 ],
							[ 6.363869657000066, 11.020033780000063 ],
							[ 6.357218236000051, 11.01942647900006 ],
							[ 6.350523993000024, 11.021077214000059 ],
							[ 6.345687424000062, 11.023495440000033 ],
							[ 6.339848652000057, 11.022035354000025 ],
							[ 6.337548277000053, 11.021460593000029 ],
							[ 6.333197707000068, 11.021442326000056 ],
							[ 6.32805255300002, 11.026208679000035 ],
							[ 6.323304163000046, 11.025530844000059 ],
							[ 6.32330410700007, 11.020782274000055 ],
							[ 6.322626009000032, 11.017391098000076 ],
							[ 6.318026660000044, 11.013941622000061 ],
							[ 6.314486997000074, 11.011286880000057 ],
							[ 6.309060614000032, 11.009251822000067 ],
							[ 6.300920978000022, 11.001791204000028 ],
							[ 6.296851637000032, 10.995007650000048 ],
							[ 6.290747276000047, 10.986868883000056 ],
							[ 6.282607502000076, 10.97737201800004 ],
							[ 6.277181739000071, 10.965842102000067 ],
							[ 6.271755339000038, 10.963128993000055 ],
							[ 6.263615505000075, 10.96584222000007 ],
							[ 6.256833056000062, 10.960415742000066 ],
							[ 6.251406619000022, 10.95566761200007 ],
							[ 6.249372093000034, 10.951598358000069 ],
							[ 6.245302189000029, 10.948206704000029 ],
							[ 6.237162995000062, 10.946171867000032 ],
							[ 6.233771310000066, 10.945493946000056 ],
							[ 6.233093398000051, 10.940745357000026 ],
							[ 6.229701872000021, 10.933962694000058 ],
							[ 6.229701988000045, 10.927858524000044 ],
							[ 6.228345465000075, 10.919040489000054 ],
							[ 6.223597559000041, 10.910901104000061 ],
							[ 6.223597482000059, 10.904796368000063 ],
							[ 6.224183527000037, 10.902745125000024 ],
							[ 6.226310299000033, 10.895300778000035 ],
							[ 6.221562389000042, 10.891231366000056 ],
							[ 6.217492494000055, 10.888518568000052 ],
							[ 6.211388148000026, 10.879021751000039 ],
							[ 6.207996864000052, 10.873596074000034 ],
							[ 6.20799689100005, 10.86884800100006 ],
							[ 6.205962088000035, 10.863421222000056 ],
							[ 6.201214218000075, 10.863421559000074 ],
							[ 6.196465907000061, 10.868169928000043 ],
							[ 6.191718152000021, 10.868847625000058 ],
							[ 6.189004974000056, 10.871561322000048 ],
							[ 6.181543874000056, 10.872239222000076 ],
							[ 6.174082630000044, 10.868170006000071 ],
							[ 6.167300241000021, 10.866134933000069 ],
							[ 6.161300640000036, 10.866134800000054 ],
							[ 6.162834301000032, 10.868894363000038 ],
							[ 6.164440178000063, 10.872910558000058 ],
							[ 6.164440167000066, 10.881745616000046 ],
							[ 6.163637145000052, 10.890580172000057 ],
							[ 6.160424154000054, 10.902627758000051 ],
							[ 6.160424353000053, 10.909054006000076 ],
							[ 6.161227587000042, 10.917085501000031 ],
							[ 6.159621347000041, 10.925920654000038 ],
							[ 6.152392404000068, 10.929936332000068 ],
							[ 6.149983039000062, 10.939574982000067 ],
							[ 6.148376348000056, 10.950015926000049 ],
							[ 6.14596685500004, 10.957244638000077 ],
							[ 6.140344710000022, 10.963670476000061 ],
							[ 6.13472220400007, 10.97170267000007 ],
							[ 6.131509705000042, 10.97893070300006 ],
							[ 6.119461974000046, 10.995798165000053 ],
							[ 6.114642674000038, 11.001420150000058 ],
							[ 6.106610655000054, 11.007845943000063 ],
							[ 6.094562859000064, 11.012664764000021 ],
							[ 6.076089997000054, 11.025515504000055 ],
							[ 6.064845209000055, 11.03194159700007 ],
							[ 6.052797202000022, 11.043186414000047 ],
							[ 6.046371976000046, 11.054431025000042 ],
							[ 6.039946076000035, 11.066479001000062 ],
							[ 6.03271778800007, 11.075313825000023 ],
							[ 6.031111139000075, 11.084148375000041 ],
							[ 6.026292390000037, 11.101015175000043 ],
							[ 6.026292321000028, 11.113866979000022 ],
							[ 6.021472864000032, 11.125914480000063 ],
							[ 6.015850398000055, 11.137962247000075 ],
							[ 6.008521716000075, 11.141016 ],
							[ 6.01772174000007, 11.181905526000037 ],
							[ 6.022911367000063, 11.19770718500007 ],
							[ 6.030761623000046, 11.213530073000072 ],
							[ 6.036017126000047, 11.221452069000065 ],
							[ 6.054487662000042, 11.23998537500006 ],
							[ 6.062446580000028, 11.242675311000028 ],
							[ 6.128868010000076, 11.253702015000044 ],
							[ 6.155036971000072, 11.309062467000047 ],
							[ 6.14942562400006, 11.345792054000071 ],
							[ 6.13056995900007, 11.374539473000027 ],
							[ 6.06633974600004, 11.41868977300004 ],
							[ 6.029852418000075, 11.454118900000026 ],
							[ 6.021117331000028, 11.46940518100007 ],
							[ 6.00914739500007, 11.480396427000073 ],
							[ 5.992237546000069, 11.486990968000043 ],
							[ 5.966528496000024, 11.489949349000028 ],
							[ 5.954884312000047, 11.49064618400007 ],
							[ 5.936017056000026, 11.491283138000028 ],
							[ 5.917564391000042, 11.490338230000077 ],
							[ 5.869080212000028, 11.481617629000027 ],
							[ 5.856256271000063, 11.479529403000072 ],
							[ 5.840205075000029, 11.479393145000074 ],
							[ 5.826959414000044, 11.479676267000059 ],
							[ 5.824129821000042, 11.482027450000032 ],
							[ 5.819998415000043, 11.495454981000023 ],
							[ 5.816627728000071, 11.513639724000029 ],
							[ 5.817231580000055, 11.536214284000039 ],
							[ 5.820299384000066, 11.552474860000075 ],
							[ 5.823399072000029, 11.565172064000024 ],
							[ 5.840436454000042, 11.590658141000063 ],
							[ 5.843938426000022, 11.603358554000067 ],
							[ 5.84289558200004, 11.610139549000053 ],
							[ 5.839256172000034, 11.633806746000062 ],
							[ 5.827904403000048, 11.646380559000022 ],
							[ 5.820657088000075, 11.648694522000028 ],
							[ 5.820699956000055, 11.649293244000035 ],
							[ 5.819306907000055, 11.649385277000022 ],
							[ 5.807159907000027, 11.658062061000066 ],
							[ 5.782230767000044, 11.676279046000047 ],
							[ 5.765931129000023, 11.693537874000071 ],
							[ 5.757301744000074, 11.687784193000027 ],
							[ 5.757301824000024, 11.682031731000052 ],
							[ 5.744837373000053, 11.68107291900003 ],
							[ 5.737166849000062, 11.688743579000061 ],
							[ 5.730454809000037, 11.694496369000035 ],
							[ 5.724702380000053, 11.691620039000043 ],
							[ 5.724702217000072, 11.684908145000065 ],
							[ 5.718949381000073, 11.684907666000072 ],
							[ 5.711278823000043, 11.682031692000066 ],
							[ 5.70552635100006, 11.676279090000037 ],
							[ 5.698814277000054, 11.672443465000072 ],
							[ 5.692102823000027, 11.667649441000037 ],
							[ 5.679638511000064, 11.663814760000037 ],
							[ 5.671968124000045, 11.659979109000062 ],
							[ 5.658544469000049, 11.668608534000043 ],
							[ 5.646079970000073, 11.662856158000068 ],
							[ 5.639368608000041, 11.662855952000029 ],
							[ 5.617315775000066, 11.66764945400007 ],
							[ 5.611563199000045, 11.667649015000052 ],
							[ 5.604851319000034, 11.668608723000034 ],
							[ 5.593345662000047, 11.669567333000032 ],
							[ 5.590469465000069, 11.670526352000024 ],
							[ 5.582799009000041, 11.675319584000022 ],
							[ 5.574169563000055, 11.678196860000071 ],
							[ 5.565540398000053, 11.682991077000054 ],
							[ 5.559787377000021, 11.685867497000061 ],
							[ 5.556911114000059, 11.682031455000072 ],
							[ 5.554993133000039, 11.675319837000075 ],
							[ 5.54540546800007, 11.672443542000053 ],
							[ 5.541570342000057, 11.665732103000039 ],
							[ 5.524311638000029, 11.662856078000061 ],
							[ 5.511846916000025, 11.668608722000045 ],
							[ 5.502258716000028, 11.66956723800007 ],
							[ 5.486917835000042, 11.668608844000062 ],
							[ 5.476371150000034, 11.668608395000035 ],
							[ 5.469659387000036, 11.668608509000023 ],
							[ 5.461813522000057, 11.665992953000057 ],
							[ 5.457961973000067, 11.672251761000041 ],
							[ 5.454893664000053, 11.680689948000065 ],
							[ 5.450291758000049, 11.697563979000051 ],
							[ 5.443387893000022, 11.702934034000066 ],
							[ 5.431115154000054, 11.704467907000037 ],
							[ 5.42421187900004, 11.70293416100003 ],
							[ 5.408103818000029, 11.702934146000075 ],
							[ 5.398899477000043, 11.704467801000021 ],
							[ 5.388927919000025, 11.707535716000052 ],
							[ 5.377422279000029, 11.708303450000074 ],
							[ 5.372819942000035, 11.708303740000076 ],
							[ 5.37237020200007, 11.705756164000036 ],
							[ 5.362848314000075, 11.709836917000075 ],
							[ 5.352876645000038, 11.704468030000044 ],
							[ 5.35249445200003, 11.704892580000035 ],
							[ 5.345973131000051, 11.712138211000024 ],
							[ 5.345973149000031, 11.72057630300003 ],
							[ 5.341371159000062, 11.729013167000062 ],
							[ 5.330632254000022, 11.738985031000027 ],
							[ 5.330632258000037, 11.746655232000023 ],
							[ 5.322194503000048, 11.761996405000048 ],
							[ 5.318359255000075, 11.766598965000071 ],
							[ 5.310688867000067, 11.770433410000066 ],
							[ 5.308387862000075, 11.77580370000004 ],
							[ 5.306853631000024, 11.783473897000022 ],
							[ 5.304690233000031, 11.784260943000049 ],
							[ 5.298416576000022, 11.786542219000069 ],
							[ 5.291512972000021, 11.781172892000029 ],
							[ 5.287677760000065, 11.773502267000026 ],
							[ 5.280007239000042, 11.770433191000052 ],
							[ 5.260064126000032, 11.77043358700007 ],
							[ 5.234751839000069, 11.769666598000072 ],
							[ 5.229381966000062, 11.76122943300004 ],
							[ 5.226314021000064, 11.757393988000047 ],
							[ 5.221711608000021, 11.75432601600005 ],
							[ 5.209438809000062, 11.76046205800003 ],
							[ 5.204836732000047, 11.770433560000072 ],
							[ 5.193331155000067, 11.775803379000024 ],
							[ 5.182592408000062, 11.781172834000074 ],
							[ 5.172620643000073, 11.783473861000061 ],
							[ 5.165717670000049, 11.778871767000055 ],
							[ 5.163416336000068, 11.772734440000022 ],
							[ 5.169552752000072, 11.764297491000036 ],
							[ 5.174922029000072, 11.75816181700003 ],
							[ 5.175689094000063, 11.749723715000073 ],
							[ 5.171086832000071, 11.741286227000046 ],
							[ 5.164183179000077, 11.738984855000069 ],
							[ 5.148842420000051, 11.733616055000027 ],
							[ 5.138870644000065, 11.72210971800007 ],
							[ 5.117393554000046, 11.719808861000047 ],
							[ 5.09975139900007, 11.72824640500005 ],
							[ 5.086711464000075, 11.725945233000061 ],
							[ 5.075643779000075, 11.723731665000059 ],
							[ 5.061500374000047, 11.725481056000035 ],
							[ 5.034153753000055, 11.731519061000029 ],
							[ 5.027753152000059, 11.729868605000036 ],
							[ 5.015673188000051, 11.728944245000037 ],
							[ 5.010774206000065, 11.735149396000054 ],
							[ 5.003870513000038, 11.738985200000059 ],
							[ 4.992364842000029, 11.741286391000074 ],
							[ 4.97702412600006, 11.741286144000071 ],
							[ 4.969353614000056, 11.736683911000057 ],
							[ 4.958614803000046, 11.735149587000024 ],
							[ 4.943274077000069, 11.733615594000071 ],
							[ 4.942946371000062, 11.732523763000074 ],
							[ 4.930175777000045, 11.740589468000053 ],
							[ 4.928499930000044, 11.740777344000037 ],
							[ 4.925942323000072, 11.754193661000045 ],
							[ 4.919266694000044, 11.777054408000026 ],
							[ 4.919141595000042, 11.788124785000036 ],
							[ 4.922216133000063, 11.800021307000065 ],
							[ 4.931583719000059, 11.823057903000063 ],
							[ 4.941484044000049, 11.842448267000066 ],
							[ 4.944374827000047, 11.862684035000029 ],
							[ 4.944374811000046, 11.880764838000061 ],
							[ 4.948392955000031, 11.896837284000071 ],
							[ 4.947388444000069, 11.936012972000071 ],
							[ 4.952410918000055, 11.991261253000062 ],
							[ 4.956428968000068, 12.029432491000023 ],
							[ 4.956428978000076, 12.07162181800004 ],
							[ 4.956428969000058, 12.107784569000046 ],
							[ 4.957433498000057, 12.13691523600005 ],
							[ 4.957433490000028, 12.16001921700007 ],
							[ 4.956448923000039, 12.172162523000054 ],
							[ 4.954419977000043, 12.19718576300005 ],
							[ 4.952410878000023, 12.218280655000058 ],
							[ 4.950401966000072, 12.231339373000026 ],
							[ 4.950401929000066, 12.246407056000066 ],
							[ 4.951128570000037, 12.249313481000058 ],
							[ 4.956428996000056, 12.270514889000026 ],
							[ 4.972501179000062, 12.29261434700004 ],
							[ 4.981541618000051, 12.300650436000069 ],
							[ 4.997614001000045, 12.300650185000052 ],
							[ 5.024735784000029, 12.290605227000071 ],
							[ 5.047839398000065, 12.284578039000053 ],
							[ 5.068934323000065, 12.277546811000036 ],
							[ 5.118155306000062, 12.278551122000067 ],
							[ 5.121233396000036, 12.278869688000043 ],
							[ 5.147286128000076, 12.281564778000075 ],
							[ 5.162033879000035, 12.282847184000047 ],
							[ 5.16196055000006, 12.283562849000077 ],
							[ 5.161890634000031, 12.284578014000033 ],
							[ 5.166921911000031, 12.284578460000034 ],
							[ 5.171729562000053, 12.288721394000049 ],
							[ 5.178175334000059, 12.297990454000058 ],
							[ 5.188390057000049, 12.30453738500006 ],
							[ 5.21453602400004, 12.307564871000068 ],
							[ 5.231401219000077, 12.30405504600003 ],
							[ 5.241751355000076, 12.297717560000024 ],
							[ 5.247388934000071, 12.295013844000039 ],
							[ 5.266189712000028, 12.28507978600004 ],
							[ 5.270397696000032, 12.281663046000062 ],
							[ 5.27124575800002, 12.281629166000073 ],
							[ 5.272849747000066, 12.281564995000053 ],
							[ 5.292940207000072, 12.278551386000061 ],
							[ 5.327093467000054, 12.272524162000025 ],
							[ 5.35019729000004, 12.266496923000034 ],
							[ 5.38234165700004, 12.262478938000072 ],
							[ 5.408458701000029, 12.259465664000061 ],
							[ 5.410204987000043, 12.259103979000031 ],
							[ 5.437589661000061, 12.253438599000049 ],
							[ 5.475760976000061, 12.252434228000027 ],
							[ 5.523977666000064, 12.261474664000048 ],
							[ 5.541054318000022, 12.272524163000071 ],
							[ 5.576212060000046, 12.272524428000054 ],
							[ 5.583301811000069, 12.271614985000042 ],
							[ 5.584339964000037, 12.272653369000068 ],
							[ 5.612531131000026, 12.274416231000032 ],
							[ 5.631031556000039, 12.288511383000071 ],
							[ 5.653055580000057, 12.292035130000045 ],
							[ 5.673317841000028, 12.28851099700006 ],
							[ 5.683008767000047, 12.277058575000069 ],
							[ 5.710318567000058, 12.267368356000077 ],
							[ 5.726082362000056, 12.271005851000041 ],
							[ 5.725650841000061, 12.27488640200005 ],
							[ 5.729150248000053, 12.283284218000063 ],
							[ 5.730214786000033, 12.286021380000022 ],
							[ 5.737215358000071, 12.304022576000023 ],
							[ 5.738948358000073, 12.308478627000056 ],
							[ 5.74379536400005, 12.325512160000073 ],
							[ 5.741840749000062, 12.332352395000044 ],
							[ 5.753468877000046, 12.339570112000047 ],
							[ 5.759012330000076, 12.343367732000047 ],
							[ 5.761343509000028, 12.367266878000066 ],
							[ 5.764753631000076, 12.423872532000075 ],
							[ 5.761169412000072, 12.436809009000058 ],
							[ 5.758085774000051, 12.449445845000071 ],
							[ 5.753985714000066, 12.46499648300005 ],
							[ 5.750971829000036, 12.469840960000056 ],
							[ 5.750875237000059, 12.48055567800003 ],
							[ 5.75277135500005, 12.506407973000023 ],
							[ 5.745246751000025, 12.531032743000026 ],
							[ 5.733349385000054, 12.560426469000049 ],
							[ 5.728450341000041, 12.590520871000024 ],
							[ 5.722151773000064, 12.605917222000073 ],
							[ 5.71235378700004, 12.636011114000041 ],
							[ 5.705355036000071, 12.652107189000048 ],
							[ 5.701625273000047, 12.664363669000068 ],
							[ 5.700456207000059, 12.668204210000056 ],
							[ 5.694857571000057, 12.687100649000058 ],
							[ 5.686458910000056, 12.708795286000054 ],
							[ 5.685791565000045, 12.713689971000065 ],
							[ 5.68597561200005, 12.72144707800004 ],
							[ 5.686052367000059, 12.724690167000063 ],
							[ 5.685353869000039, 12.727065347000064 ],
							[ 5.68626739900003, 12.729705145000025 ],
							[ 5.68508020400003, 12.73132341400003 ],
							[ 5.685915194000074, 12.732791312000074 ],
							[ 5.686766682000041, 12.734431676000042 ],
							[ 5.68944958000003, 12.765055836000045 ],
							[ 5.696916971000064, 12.783899100000042 ],
							[ 5.700647788000026, 12.793696620000048 ],
							[ 5.705156387000045, 12.801998707000052 ],
							[ 5.708949119000067, 12.805036192000046 ],
							[ 5.722620365000068, 12.814170267000065 ],
							[ 5.740876580000077, 12.823343393000073 ],
							[ 5.771073322000063, 12.830523203000041 ],
							[ 5.772380832000067, 12.830833958000028 ],
							[ 5.773864478000064, 12.831148511000038 ],
							[ 5.79423657500007, 12.835469490000037 ],
							[ 5.813132632000077, 12.839669393000065 ],
							[ 5.83762787400002, 12.834070136000037 ],
							[ 5.853024401000027, 12.819373316000053 ],
							[ 5.878219589000025, 12.803976165000051 ],
							[ 5.89501585000005, 12.787179726000033 ],
							[ 5.923010385000055, 12.771083070000032 ],
							[ 5.951004467000075, 12.76268488900007 ],
							[ 5.967101091000075, 12.761284609000029 ],
							[ 5.987396610000076, 12.754286511000032 ],
							[ 6.004193218000069, 12.742388558000073 ],
							[ 6.020989763000046, 12.736789879000071 ],
							[ 6.035686937000037, 12.742388456000072 ],
							[ 6.042685575000064, 12.750787023000044 ],
							[ 6.045502086000056, 12.754116163000049 ],
							[ 6.032203767000055, 12.786961361000067 ],
							[ 6.025865974000055, 12.813104575000068 ],
							[ 6.017944004000071, 12.836077919000047 ],
							[ 6.009625869000047, 12.875687337000045 ],
							[ 6.007987012000058, 12.885112543000048 ],
							[ 6.010120484000026, 12.901169634000041 ],
							[ 6.01009048700007, 12.904781229000037 ],
							[ 6.023812957000075, 12.912114145000032 ],
							[ 6.035675680000054, 12.922141317000069 ],
							[ 6.040195632000064, 12.93120650700007 ],
							[ 6.040136235000034, 12.938429683000038 ],
							[ 6.038268695000056, 12.942026100000021 ],
							[ 6.030761240000061, 12.960928294000041 ],
							[ 6.026084495000021, 12.970823084000074 ],
							[ 6.02467768300005, 12.979966531000059 ],
							[ 6.024677739000026, 12.992933553000057 ],
							[ 6.020317448000071, 13.008504691000041 ],
							[ 6.027902467000047, 13.021341275000054 ],
							[ 6.041340343000059, 13.02805986900006 ],
							[ 6.072347620000073, 13.039773840000066 ],
							[ 6.101976347000061, 13.059756244000027 ],
							[ 6.131605215000036, 13.072159004000071 ],
							[ 6.151587576000054, 13.074914919000037 ],
							[ 6.166056988000037, 13.068714 ],
							[ 6.188795679000066, 13.065957906000051 ],
							[ 6.201887750000026, 13.058378030000029 ],
							[ 6.20533806100002, 13.05532600600003 ],
							[ 6.219802346000051, 13.042529911000031 ],
							[ 6.245296992000021, 13.028060142000072 ],
							[ 6.254254722000042, 13.031505782000067 ],
							[ 6.255632959000025, 13.044597519000035 ],
							[ 6.25864585100004, 13.066005003000043 ],
							[ 6.25916612900005, 13.06970308800004 ],
							[ 6.259767133000025, 13.081116787000042 ],
							[ 6.260759405000044, 13.085417748000054 ],
							[ 6.261834133000036, 13.090074657000059 ],
							[ 6.257699961000071, 13.101098759000024 ],
							[ 6.260277341000062, 13.121644768000067 ],
							[ 6.260277138000049, 13.131490778000057 ],
							[ 6.261800337000068, 13.139486453000075 ],
							[ 6.264845815000058, 13.149384746000067 ],
							[ 6.270175916000028, 13.156237692000047 ],
							[ 6.282739658000025, 13.156237891000046 ],
							[ 6.289592182000035, 13.158902333000071 ],
							[ 6.300252466000074, 13.157760464000035 ],
							[ 6.307866691000072, 13.157760692000068 ],
							[ 6.314083137000068, 13.156839435000052 ],
							[ 6.317446193000023, 13.158230806000063 ],
							[ 6.328044290000037, 13.156618224000056 ],
							[ 6.34593779100004, 13.152811064000048 ],
							[ 6.355074940000065, 13.153571986000031 ],
							[ 6.361927948000073, 13.152430722000076 ],
							[ 6.371445709000056, 13.149765245000026 ],
							[ 6.383628495000039, 13.145196812000052 ],
							[ 6.394288570000072, 13.138343977000034 ],
							[ 6.399618504000046, 13.131490447000033 ],
							[ 6.406090687000074, 13.12539957000007 ],
							[ 6.421319015000051, 13.123496174000024 ],
							[ 6.429676462000032, 13.118123533000073 ],
							[ 6.442609147000042, 13.112725044000058 ],
							[ 6.466368719000059, 13.105376146000026 ],
							[ 6.503912568000032, 13.095114383000066 ],
							[ 6.514644173000022, 13.091430793000029 ],
							[ 6.528455071000053, 13.084762921000049 ],
							[ 6.545303453000031, 13.081118933000027 ],
							[ 6.566224439000052, 13.075907070000028 ],
							[ 6.570368736000034, 13.075906970000062 ],
							[ 6.584836031000066, 13.075145588000055 ],
							[ 6.593592038000054, 13.070576924000022 ],
							[ 6.607297928000037, 13.066769749000059 ],
							[ 6.622907234000024, 13.066769578000049 ],
							[ 6.630902151000043, 13.070196030000034 ],
							[ 6.640039578000028, 13.070196331000034 ],
							[ 6.654125652000062, 13.077429593000033 ],
							[ 6.655344200000059, 13.077429906000077 ],
							[ 6.660693262000052, 13.077429598000037 ],
							[ 6.662120908000077, 13.079333399000063 ],
							[ 6.66608915200004, 13.080586453000024 ],
							[ 6.669354517000045, 13.081617571000038 ],
							[ 6.682679117000021, 13.083901656000023 ],
							[ 6.697527032000039, 13.099130905000038 ],
							[ 6.709710122000047, 13.105602278000049 ],
							[ 6.725699815000041, 13.112075003000029 ],
							[ 6.742070676000026, 13.117023414000073 ],
							[ 6.751588351000066, 13.126922887000035 ],
							[ 6.76795899900003, 13.136059819000025 ],
							[ 6.775954274000071, 13.147481106000043 ],
							[ 6.783568327000069, 13.153952692000075 ],
							[ 6.792541934000042, 13.161023111000077 ],
							[ 6.801523333000034, 13.14258768600007 ],
							[ 6.821858282000051, 13.114931850000062 ],
							[ 6.848701061000043, 13.082395727000062 ]
						]
					]
				]
			}
		}
	]
};
