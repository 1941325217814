import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: [ './privacy.component.scss' ]
})
export class PrivacyComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Privacy | Nigeria INGO Forum');
	}

	ngOnInit(): void {}
}
