import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/services';
import { AppFilter } from 'src/app/models';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: [ './register.component.scss' ]
})
export class RegisterComponent implements OnInit {
	message: boolean;
	formSubmitAttempt: boolean;
	pending: boolean;
	success: any;
	error: any;
	registerForm: FormGroup;
	activationForm: FormGroup;
	types = [];
	activation: boolean;
	activationMedium: string = 'Email';
	resendCode: boolean;
	title: string = '';
	selectedRequestType: string = '';
	constructor(
		private optionSvc: PublicService,
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private authSvc: AuthenticationService,
		private tit: Title
	) {
		this.tit.setTitle(`Join| Nigeria INGO Forum`);
		this.registerForm = fb.group({
			first_name: [ '', Validators.required ],
			last_name: [ '', Validators.required ],
			email: [ '', Validators.required ],
			mobile: [ '', Validators.required ],
			type: [ 'GUESS', Validators.required ],
			role: [ 'GUESS', Validators.required ],
			comment: [ '' ],
			organization_id: [ '' ],
			organization_name: [ '' ],
			is_org: [ true ],
			organization: fb.group({
				name: [ '', Validators.required ],
				acronym: [ '' ],
				type: [ '' ],
				address: [ '' ],
				// mobile: [ '', Validators.required ],
				website: [ '' ],
				email: [ '' ],
				contact_email: [ '' ],
				contact_phone: [ '' ],
				contact_name: [ '' ]
			})
		});
		// this.route.queryParamMap.subscribe((res: any) => {
		// 	if (res.params['type']) {
		// 		this.title = registration[res.params['type']];
		// 		this.registerForm.patchValue({ type: res.params['type'], is_org: true });
		// 	}
		// });

		this.getOptionTypes();
	}
	backToForm() {
		// this.registerForm.reset();
		this.success = null;
	}
	isFieldValidOrg(field: string) {
		return (
			(!this.registerForm.get('organization').get(field).valid &&
				this.registerForm.get('organization').get(field).touched) ||
			(this.registerForm.get('organization').get(field).untouched && this.formSubmitAttempt)
		);
	}

	displayFieldCssOrg(field: string) {
		return {
			error: this.isFieldValidOrg(field)
		};
	}

	displayFormCssOrg(field: string) {
		return {
			loading: field
		};
	}

	getOptionTypes() {
		let filter = new AppFilter({ sidx: '-createdat', records: '', sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'organization';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.optionSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.types = res.options;
		});
	}

	isFieldValidA(field: string) {
		return (
			(!this.activationForm.get(field).valid && this.activationForm.get(field).touched) ||
			(this.activationForm.get(field).untouched && this.formSubmitAttempt)
		);
	}

	displayFieldCssA(field: string) {
		return {
			error: this.isFieldValidA(field)
		};
	}

	displayFormCssA(field: string) {
		return {
			loading: field
		};
	}

	isFieldValid(field: string) {
		return (
			(!this.registerForm.get(field).valid && this.registerForm.get(field).touched) ||
			(this.registerForm.get(field).untouched && this.formSubmitAttempt)
		);
	}

	displayFieldCss(field: string) {
		return {
			error: this.isFieldValid(field)
		};
	}

	displayFormCss(field: string) {
		return {
			loading: field
		};
	}
	submit() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		this.formSubmitAttempt = false;
		this.nullify();
		console.log(this.registerForm.value);
		if (this.registerForm.valid) {
			this.pending = true;
			this.registerForm.get('organization').patchValue({
				contact_name: `${this.registerForm.value.first_name} ${this.registerForm.value.last_name}`,
				contact_email: this.registerForm.value.email,
				contact_phone: this.registerForm.value.mobile
			});
			this.selectedRequestType = this.registerForm.value.type;
			this.authSvc.register({ body: this.registerForm.value }).subscribe(
				(res) => {
					this.pending = false;
					this.success = 'true';
					// this.activation = true;
				},
				(error) => {
					this.pending = false;
					this.error = error.error.split('=').pop();
				}
			);
		} else {
			this.formSubmitAttempt = true;
		}
	}

	nullify() {
		this.success = null;
		this.error = null;
	}
	ngOnInit(): void {}
}
