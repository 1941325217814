<section class="border-top">
	<div class="container">
		<div class="row ">
			    <div class="col-lg-12 col-sm-12" style="margin-bottom:1rem; margin-top:1rem;">
                    <div class="d-flex justify-content-center">
                            <div class="spinner-grow"  *ngIf="pending"  style="width: 5rem; height: 5rem;"role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
							<button *ngIf="template" (click)="print()" class="btn btn-small btn-main rounded">Print Invoice</button>
								</div>
				</div>
						</div>
				<div class="row ">


			<div class="col-lg-9 invoice"  *ngIf="template" >
				<div class="error-content" id="print-portal">
					<div [innerHtml]="template.invoice_path | safeHtml">
		</div>
				</div>
			</div>
		</div>
	</div>
</section>