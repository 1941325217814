import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-publications',
	templateUrl: './publications.component.html',
	styleUrls: [ './publications.component.scss' ]
})
export class PublicationsComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Publications | Nigeria INGO Forum');
		// window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	ngOnInit(): void {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
