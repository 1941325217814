// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	postType: '69082187-c86e-4c06-b59e-edaff4133eca',
	production: false,
	API: 'https://api.ingoforum.ng',
	// API_SERVER: 'http://localhost:8080'
	// API: 'http://api.ingoforum.ng',
	API_SERVER: 'https://api.ingoforum.ng'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
