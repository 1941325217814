export const Teams = [
	{
		Name: 'Camilla Corradin',
		Position: 'Director',
		Location: 'Abuja',
		Team: 'Country Office',
		Skype:"https://skype.com/camilla.corradin",
		LinkedIn:"https://linkedin.com/in/camillacorradin",
		Twiiter:"https://www.twitter/CamiCorradin",
		IMAGE: '/assets/images/team/camilla.png'
	},
	{
		Name: 'Yasmine Chawaf',
		Position: 'Deputy Director, North-East',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/yasmine.png'
	},
	{
		Name: 'Upon Recruitment ',
		Position: 'Policy Advisor',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/volunteer-img1.1.jpg'
	},
	{
		Name: 'Elise Baudot Queguiner',
		Position: 'Senior Consultant PLRCAP',
		Location: 'Remote',
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/elise.png'
	},
	{
		Name: 'Abubakar Abdullahi Suleiman',
		Position: 'Government Liaison Advisor',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/abubakar.png'
	},
	{
		Name: 'Ademilola Adesanya',
		Position: 'Media & Communication Coordinator',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/ademilola.png'
	},
	{
		Name: 'Mohammed Moukine Billah',
		Position: 'Access Advisor',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/mohammed.png'
	},
	{
		Name: 'Oni Oluwashina Abayomi',
		Position: 'Information Management Specialist',
		Location: 'Abuja',
		Team: 'Country Office',
		LinkedIn:"https://www.linkedin.com/in/oluwashina-abayomi-oni-a0aa211a5/",
		Skype:"https://skype.com/Shina Oni",
		IMAGE: '/assets/images/team/Oni_Oluwashina_Abayomi.jpg'
	},
	{
		Name: 'Confidence Obayuwana',
		Position: 'Government Liaison Focal Point',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/confidence.png'
	},
	{
		Name: 'Upon Recruitment',
		Position: 'Senior Programme Officer',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/volunteer-img1.1.jpg'
	},
	{
		Name: 'Upon Recruitment',
		Position: 'Communication and Partnership Coordinator, PLRCAP',
		Location: 'Abuja',
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/volunteer-img1.1.jpg'
	},
	{
		Name: 'Ada James Emmanuel',
		Position: 'Project Manager, PLRCAP',
		Location: 'Abuja',
		LinkedIn:"https://www.linkedin.com/in/ada-james-787265136/",
		Twitter:"https://www.twitter.com/JEA_approach",
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/James_Ada.jpeg'
	},
	{
		Name: 'Mustapha Gajerima Lawan',
		Position: 'Access Officer',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/mustaha.png'
	},
	{
		Name: 'Upon Recruitment',
		Position: 'Administrative & Logistics Technical Assistant',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/volunteer-img1.1.jpg'
	},
	{
		Name: 'Rejoice Aikohi',
		Position: 'Programme Assistant',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/rejoice.png'
	},
	{
		Name: 'Miebaka Peters',
		Position: 'Administrative Technical Assistant',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/miebaka.png'
	},
	{
		Name: 'Theola Ehinomhen Amiokhabhor',
		Position: 'Communication Intern',
		Location: 'Abuja',
		LinkedIn:"https://ng.linkedin.com/in/theola-monday",
		Skype:"https://skype.com/Theola Monday",
		Twitter:"https://www.twitter.com/thetheolamonday",
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/Theola.jpg'
	},

	{
		Name: 'Delores-Joan Idumesaro',
		Position: 'PLRCAP Intern',
		Location: 'Abuja',
		Team: 'Localisation & Development',
		IMAGE: '/assets/images/team/Delores-Joan_Idumesaro.jpg'
	},
	{
		Name: 'Iliasu Akande',
		Position: 'Driver',
		Location: 'Abuja',
		Team: 'Country Office',
		IMAGE: '/assets/images/team/iliasu_akande.jpeg'
	},
	{
		Name: 'Hassan Adamu',
		Position: 'Driver',
		Location: 'Maiduguri',
		Team: 'North-East',
		IMAGE: '/assets/images/team/volunteer-img1.1.jpg'
	}
];
