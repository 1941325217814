import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Teams } from './mock';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
declare var $: any;
@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: [ './about.component.scss' ]
})
export class AboutComponent implements OnInit, AfterViewInit {
	teams = Teams;
	private fragment: string;
	donors: Array<any> = [
		{ src: '/assets/images/donors/ECHO-Logo.png', href: 'https://ec.europa.eu/echo/' },
		{ src: '/assets/images/donors/SDC-Logo.png', href: 'https://www.eda.admin.ch/sdc' },
		{
			src: '/assets/images/donors/UKAID-Logo.png',
			href: 'https://www.gov.uk/government/organisations/foreign-commonwealth-development-office'
		},
		// {
		// 	src: '/assets/images/donors/USAID-Logo.png',
		// 	href: 'https://www.usaid.gov/who-we-are/organization/bureaus/bureau-humanitarian-assistance'
		// },
		{ src: '/assets/images/donors/NIF-Contributions-Logo.png', href: '/members' }
	];
	committerTeam = [
		// {
		// 	Name: 'Alejandro Navarro Marin',
		// 	Position: '',
		// 	Extra: 'Host',
		// 	Location: '(standing seat as host of NIF)',
		// 	Team: 'Localisation & Development',
		// 	src: '/assets/images/members/3is.png',
		// 	alt: '3is',
		// 	link: 'https://3is.org/',
		// 	twitter: 'https://www.twitter.com',
		// 	facebook: 'https://www.facebook.com'
		// },
		{
			Name: 'Dr Chris Agoha',
			Position: 'Member',
			Extra: 'Member',
			Location: 'Steering Committee Chair',
			Team: 'North-East',
			src: '/assets/images/members/hd-centre.png',
			alt: 'HD',
			link: 'https://drc.ngo/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Thomas Hill',
			Position: 'Member',
			Extra: 'Member',
			Location: 'Northeast Nigeria',
			Team: 'Country Office',
			src: '/assets/images/members/nrc.png',
			alt: 'NRC',
			link: 'https://www.actionagainsthunger.org/who-we-are/about/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Margaret McLoughin',
			Position: 'Chair',
			Extra: 'Chair',
			Location: 'Abuja',
			Team: 'Country Office',
			src: '/assets/images/members/mcn.png',
			alt: 'INTERSOS',
			link: 'https://www.mercycorps.org/who-we-are',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Enkas Chau',
			Position: 'Member',
			Extra: 'Member',
			Location: 'Abuja',
			Team: 'Country Office',
			src: '/assets/images/members/intersos.png',
			alt: 'Action Against Hunger',
			link: 'https://plan-international.org/about/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		},
		{
			Name: 'Thierno Samba Diallo',
			Position: 'Member',
			Extra: 'Member',
			Location: 'Abuja',
			Team: 'Country Office',
			src: '/assets/images/members/acf.png',
			alt: 'Plan International',
			link: 'https://plan-international.org/about/',
			twitter: 'https://www.twitter.com',
			facebook: 'https://www.facebook.com'
		}
	];

	constructor(private activatedRoute: ActivatedRoute, private title: Title) {
		this.title.setTitle('About | Nigeria INGO Forum');
	}

	ngOnInit(): void {
		// this.activatedRoute.fragment.subscribe((fragment) => fragment);
		// this.activatedRoute.fragment.subscribe((res) => {
		// 	console.log(res);
		// 	if (res) {
		// 		var element = document.getElementById(`${res.split('/')[0]}`);
		// 		console.log(element);
		// 		element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
		// 	}
		// });
	}

	ngAfterViewInit(): void {
		this.activatedRoute.fragment.subscribe((res) => {
			if (res) {
				console.log(res);
				var element = document.getElementById(`${res.split('/')[0]}`);
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		});
		// try {
		// 	document.querySelector('#' + this.fragment).scrollIntoView();
		// } catch (e) {}

		$('.team-slider').slick({
			slidesToShow: 4,
			// slidesToScroll: 2,
			// infinite: true,
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 3000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: true
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

		$('.commitee-slider').slick({
			slidesToShow: 4,
			// slidesToScroll: 2,
			// infinite: true,s
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 3000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						// slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: true
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2
						// slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1
						// slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1
						// slidesToScroll: 1
					}
				}
			]
		});
	}
}
