import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $: any;
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-media-contacts',
	templateUrl: './media-contacts.component.html',
	styleUrls: [ './media-contacts.component.scss' ]
})
export class MediaContactsComponent implements OnInit, AfterViewInit {
	constructor(private title: Title) {
		this.title.setTitle('Media Contacts | Nigeria INGO Forum');
	}
	ngAfterViewInit() {
		setTimeout(() => {
			$('#d-table').DataTable();
		}, 2000);
	}

	ngOnInit(): void {}

	mediaContacts = [
		{
			id: 1,
			name: 'Nihinlola Ayanda',
			position: 'AAN - ActionAid Nigeria',
			department: 'Media & Communications Manager',
			email: 'Nihinlola.Ayanda@actionaid.org'
		},
		{
			id: 2,
			name: 'Wilson Ikwebe',
			position: 'ACF/AAH - Action Against Hunger',
			department: 'HoD Advocacy and Communications',
			email: 'hodaac@ng-actionagainsthunger.org'
		},
		{
			id: 3,
			name: 'Adebola Fatilewa',
			position: 'CAID - Christian Aid',
			department: 'Communication Officer',
			email: 'AFatilewa@christian-aid.org'
		},
		{
			id: 4,
			name: 'Habeeb Sulaiman',
			position: 'CARE - Cooperative for Assistance and Relief Everywhere',
			department: 'Communication and Knowledge Sharing',
			email: 'Habeeb.Sulaiman@care.org'
		},
		{
			id: 5,
			name: 'Julius Nasamu Gaiya',
			position: 'CIVIC - Centre for Civilians in Conflict',
			department: 'Communication and Advocacy Officer',
			email: 'Julius@civiliansinconflict.org'
		},
		{
			id: 6,
			name: 'Daniel Ayodeji',
			position: 'COOPI - Cooperazione Internazionale',
			department: 'Communication Officer',
			email: 'communication.yobe@coopi.org'
		},
		{
			id: 7,
			name: 'Veronika Tymova',
			position: 'DRC - Danish Refugee Council',
			department: 'Interim Head of Programmes',
			email: 'veronika.tymova@drc.ngo'
		},
		{
			id: 8,
			name: 'Fatima Ibrahim',
			position: 'EAI - Equal Access International',
			department: 'Communication Officer',
			email: 'Fibrahim@equalaccess.org'
		},
		{
			id: 9,
			name: 'Favour  Oriaku',
			position: 'eHealth Africa ',
			department: 'Communication Coordinator ',
			email: 'favour.oriaku@ehealthafrica.org'
		},
		{
			id: 10,
			name: 'Archibong Utin',
			position: 'FHI 360',
			department: 'Media and Communication Officer',
			email: 'eutin@fhi360.org'
		},
		{
			id: 11,
			name: 'Kolo Kenneth Kadiri',
			position: 'IA - International Alert',
			department: 'Senior Progarmme Officer, Communication & Advocacy',
			email: 'Kkenneth@international-alert.org'
		},
		{
			id: 12,
			name: 'Omo  Olubunmi',
			position: 'iMMAP - Information Management and Mine Action Programs',
			department: 'Communication  Officer',
			email: 'oolubunmi@immap.org'
		},
		{
			id: 13,
			name: 'Nneka Egbuna',
			position: 'INTERSOS - INTERSOS Humanitarian Aid Organisation',
			department: 'Communications Specialist',
			email: 'Communication.Maiduguri.nigeria@intersos.org'
		},
		{
			id: 14,
			name: 'Uchenna Okoro',
			position: 'JRS - Jesuit Refugee Service',
			department: 'Communication Officer',
			email: 'uchenna.okoro@jrs.net'
		},
		{
			id: 15,
			name: 'Grace Oguntade',
			position: 'MCN - Mercy Corps Nigeria',
			department: 'Communications Specialist',
			email: 'goguntade@mercycorps.org'
		},
		{
			id: 16,
			name: 'Wisdom Osakwe',
			position: "MSION - Marie Stopes Nigeria",
			department: 'Strategic Communications Manager',
			email: 'wisdom.Osakwe@mariestopes.org.ng'
		},
		{
			id: 17,
			name: 'Samuel Jegede',
			position: 'NRC - Norwegian Refugee Council',
			department: 'Media & Communications Coordinator',
			email: 'samuel.jegede@nrc.no'
		},
		{
			id: 18,
			name: 'Deborah Benneth',
			position: 'Nutrition International',
			department: 'Program Officer (CHN)',
			email: 'dbenneth@nutritionintl.org'
		},
		{
			id: 19,
			name: 'Yunus Abdulhamid',
			position: 'Plan International',
			department: 'Communications Advisor',
			email: 'Yunus.Abdulhamid@plan-international.org'
		},
		{
			id: 20,
			name: 'Zainab Mohammed',
			position: 'RHDI - Reconnect Health Development Initiative',
			department: 'Program Communication Officer',
			email: 'zainab.mohammed@reconnecthdi.org'
		},
		{
			id: 21,
			name: 'Thomas Guyatt',
			position: 'SC - Street Child',
			department: 'Programme Support Officer',
			email: 'thomas.guyatt@street-child.org'
		},
		{
			id: 22,
			name: 'Kunle Olawoyin',
			position: 'SCI - Save the Children International',
			department: 'Media & Communications Coordinator',
			email: 'Kunle.Olawoyin@savethechildren.org'
		},
		{
			id: 23,
			name: 'Temisan Etietsola',
			position: 'SFCG - Search for Common Ground',
			department: 'Media Manager',
			email: 'tetietsola@sfcg.org'
		},
		{
			id: 24,
			name: 'Shahid Akbar',
			position: 'SI - Solidarites International',
			department: 'Country Director',
			email: 'hom@solidarites-nigeria.org'
		},
		{
			id: 25,
			name: 'Ifeoma Abasiama',
			position: 'SOS CV - SOS Children\'s Villages',
			department: 'Brand and Communications Coordinator',
			email: 'ifeoma.jukpor@sos-nigeria.org'
		},
		{
			id: 26,
			name: 'Promise Salawu',
			position: 'TearFund',
			department: 'EES Project Officer/Media/Comms lead',
			email: 'promise.salawu@tearfund.org'
		},
		{
			id: 27,
			name: 'Onome Oraka',
			position: 'WAN - WaterAid Nigeria',
			department: 'Communications Assistant',
			email: 'OnomeOraka@wateraid.org'
		},
		{
			id: 28,
			name: 'Monica Agene',
			position: 'WfWI - Women for Women International',
			department: 'HR & Admin Manager/Information Focal Point',
			email: 'magene@womenforwomen.org'
		}
		
	];
}
