<section class="section donation">
	<div class="container">
		<div class="row">
					<div class="col-lg-8"  *ngIf="success">
				<div class="confirmation-content text-center">
									<div class="success-content text-center">
										<i  class="icofont-check text-lg"></i>
										<p  class="mt-4" style="font-weight: 700;">{{selectedRequestType == 'GUESS'?'Enquiry about NIF Membership is recieved by NIF. We’ll reach out to you soon' :'Your request is being processed by NIF. We’ll reach out to you on next steps'}}.</p>

										    <a (click)="backToForm()" class="btn btn-main-2 rounded text-white" style="cursor:pointer">Back to form</a>
									</div>
				</div>
			</div>
			<div class="col-lg-8 alert alert-danger text-center" role="alert"  *ngIf="error">
                       <h3>
                            {{error}}</h3>
            </div>
			<div class="col-lg-8" *ngIf="!success">
				<div class="donation-wrap">
					    <div class="d-flex justify-content-center" *ngIf="pending">
							<div class="spinner-grow"   style="width: 5rem; height: 5rem;"role="status">
							<span class="sr-only">Loading...</span>
							</div>
						</div>

					<h2 class="text-md mb-4">Membership Request Form</h2>
					<p class="mb-5 lead">Please let us know you before we proceed with the registration.</p>

					<form class="donation-form" [formGroup]="registerForm">



					  <h3 class="mb-5 mt-5 border-bottom pb-3">Your Details</h3>

					  <div class="form-group row">
					    <label class="col-sm-3 col-form-label">First Name</label>
					    <div class="col-sm-9">
					      <input type="text" class="form-control" id="inputname" formControlName="first_name" placeholder="First Name">
					    </div>
					  </div>

					  	<div class="form-group row">
					    <label class="col-sm-3 col-form-label">Last Name</label>
					    <div class="col-sm-9">
					      <input type="text" class="form-control" id="inputname" formControlName="last_name" placeholder="Last Name">
					    </div>
					  </div>

					  <div class="form-group row">
					    <label class="col-sm-3 col-form-label">Email</label>
					    <div class="col-sm-9">
					      <input type="email" class="form-control" id="inputEmail3" formControlName="email" placeholder="Email">
					    </div>
					  </div>


					  <div class="form-group row">
					    <label class="col-sm-3 col-form-label">Phone</label>
					    <div class="col-sm-9">
					      <input type="text" class="form-control" id="inputphone" formControlName="mobile" placeholder="Phone">
					    </div>
					  </div>

					   <fieldset class="form-group" formGroupName="organization">
									<h3 class="mb-4 mt-5 border-bottom pb-3">Your Organisation Details</h3>

									<div class="form-group row">
											<label class="col-sm-3  col-form-label ">Your Organisation Name</label>
												    <div class="col-sm-9">
											<input type="text" class="form-control" id="paycardname" formControlName="name" placeholder="Your Organisation Name">
											</div>
									</div>
											<div class="form-group row">
											<label class="col-sm-3  col-form-label ">Orgainsation Acronym</label>
												    <div class="col-sm-9">
											<input type="text" class="form-control" id="paycardname" formControlName="acronym" placeholder="Organisation Acronyn">
											</div>
									</div>
									<div class="form-group row">
											<label class="col-sm-3  col-form-label ">Organisation Type</label>
												    <div class="col-sm-9">
														<select id="inputmonth" placeholder="Select Orgnisation Type" formControlName="type" class="form-control">
															<option *ngFor="let org of types" [value]="org.id"> {{org.name}}</option>
										</select>
														</div>
									</div>
											<div class="form-group row">
														<label class="col-sm-3  col-form-label ">Organisation Address</label>
															    <div class="col-sm-9">
										<textarea cols="30" rows="12" formControlName="address" class="form-control" placeholder="Organisation Address"></textarea>
										</div>
									</div>
					 </fieldset>
												<h3 class="mb-4 mt-5 border-bottom pb-3">Request Type</h3>
								 <div class="form-group">
						    <!-- <label class="col-form-label mb-2">Request Type</label> -->
						          <select id="inputmonth"  placeholder="Select Request Type" formControlName="type" class="form-control">
						        <option value="MEMBER">I want to apply for membership</option>
										<option value="OBSERVER">I want to apply to be an observer</option>
											<option value="GUEST">I have a question to ask</option>
						      </select>
						 </div>
						 <h3 class="mb-4 mt-5 border-bottom pb-3">Comments (Optional)</h3>

						 	<div class="form-group">
							<textarea cols="30" rows="6" formControlName="comment"  class="form-control" placeholder="Your Comment"></textarea>
						</div>


					  <div class="form-group row mt-4">
					    <div class="col-sm-10">
					      <a (click)="submit()" class="btn btn-main-2 rounded text-white" style="cursor:pointer">Send Request</a>
					    </div>
					  </div>
					</form>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="offline-donation mt-5 mt-lg-0">
					<h4 class="mb-5">How to reach us:</h4>

					<ul class="list-unstyled">
						<li> Address:

							<address>
							No. 2 Yabo Close, Off Jere Street,
                             Garki II, Abuja - FCT, Nigeria
							</address>
						</li>
						<li> Email us : <span>info@ingoforum.ng</span></li>
						<li> Contact us : <span>+234 908 737 4910</span></li>
					</ul>

					<!-- <p>All contributions will be gratefully acknowledged and are tax deductible.</p> -->
				</div>
			</div>
		</div>
	</div>
</section>

