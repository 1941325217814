<!-- Slider Start -->
<!-- <section class="">
	<video id="videoId"  oncanplay="this.play()" onloadedmetadata="this.muted = true" muted autoplay loop playsinline  style="width: 100%;">
		<source src="/assets/slider.webm" type="video/webm">
	</video>
</section> -->
<!-- Section Intro Start -->

<section class="page-title bg-about">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="block text-center">
          <!-- <span class="text-white"
            >The Nigeria INGO Forum (NIF) is an alliance of international NGOs
            providing humanitarian and development assistance in Nigeria.</span
          > -->
          <h1 class="mb-4 text-md">
            The Nigeria INGO Forum (NIF) is an alliance of international NGOs
            providing humanitarian and development assistance in Nigeria.
          </h1>
          <a class="btn btn-success" routerLink="/about-us">Discover more</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section intro">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="section-title">
          <!-- <span class="content-title">What we do</span> -->
          <!-- <h2 class="mt-2 text-color">What we do</h2> -->
          <h2 class="mb-5 text-center">What We Do</h2>
        </div>
      </div>
      <!-- <div class="col-lg-6">
        <p>
          We provide a collective platform for international NGOs to coordinate
          actions, exchange information and advocate for a principled and
          effective delivery of much needed assistance to affected population in
          Nigeria.
        </p>
      </div> -->
    </div>
    <div class="row justify-content-center text-center">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="intro-item mb-5 mb-lg-0">
          <img
            src="/assets/images/functions/coordination.png"
            alt=""
            class="img-fluid w-30"
          />
          <h4 class="mt-4 mb-3">Coordination</h4>
          <p>
            We facilitate and ensure productive relationships and mutual
            understanding among members, and with external stakeholders
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="intro-item mb-5 mb-lg-0">
          <img
            src="/assets/images/functions/representation.png"
            alt=""
            class="img-fluid w-30"
          />
          <h4 class="mt-4 mb-3">Representation</h4>
          <p>
            We represent and act in members' interests in external meetings and
            coordination mechanisms
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="intro-item">
          <img
            src="/assets/images/functions/information-sharing.png"
            alt=""
            class="img-fluid w-30"
          />
          <h4 class="mt-4 mb-3">Information Sharing</h4>
          <p>
            We keep our members and external stakeholders informed through
            transparent flow of relevant and critical information
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center text-center">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="intro-item mb-5 mb-lg-0">
          <img
            src="/assets/images/functions/advocacy.png"
            alt=""
            class="img-fluid w-30"
          />
          <h4 class="mt-4 mb-3">Policy & Advocacy</h4>
          <p>
            We mobilise members on collective positioning and joint action on
            critical issues
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="intro-item mb-5 mb-lg-0">
          <img
            src="/assets/images/functions/access.png"
            alt=""
            class="img-fluid w-30"
          />
          <h4 class="mt-4 mb-3">Access & Community Engagement</h4>
          <p>
            We navigate relevant apparatus, including engaging directly with
            affected communities to improve humanitarian access and
            accountability to affected population
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="intro-item">
          <img
            src="/assets/images/functions/partnership.png"
            alt=""
            class="img-fluid w-10"
          />
          <h4 class="mt-4 mb-3">Partnership & Capacity Building</h4>
          <p>
            We commit to strengthening collaborative efforts and continues
            capacity development
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Section Intro END -->
<section class="section section-section-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <div class="mb-12 mb-lg-0">
          <h2 class="mt-3 mb-12 position-relative text-black">
            Key Membership Figures
          </h2>
        </div>
      </div>
    </div>
    <div class="counter-section">
      <div class="row">
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="counter-item-2 mb-5 mb-lg-0 pt-5">
            <span class="counter-stat text-color">{{
              keyMembershipFigure?.members
            }}</span>
            <p>Members</p>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="counter-item-2 mb-5 mb-lg-0 pt-5">
            <span class="counter-stat font-weight-bold text-color">{{
              keyMembershipFigure?.observers
            }}</span>
            <p>Observers</p>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="counter-item-2 mb-5 mb-lg-0 pt-5">
            <span class="counter-stat text-color">{{
              keyMembershipFigure?.reporting_members
            }}</span>
            <p>Reporting Members</p>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="counter-item-2 pt-5">
            <span class="counter-stat text-color">{{
              keyMembershipFigure?.projects
            }}</span>
            <p>Projects</p>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="counter-item-2 mb-5 mb-lg-0 pt-5">
            <span class="counter-stat text-color">{{
              keyMembershipFigure?.states
            }}</span>
            <p>States</p>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="counter-item-2 pt-5">
            <span class="counter-stat text-color">{{
              keyMembershipFigure?.lgas
            }}</span>
            <p>Local Gov't Areas</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section blog-wrap">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <!-- <div class="row">
					<div class="col-lg-4 col-md-6 mb-3" *ngFor="let post of posts">
						<div class="card blog-item">
							<img *ngIf="post?.image" src="{{baseUrl}}/public/assets?media={{post?.image}}" alt=""
								class="img-fluid">

							<div class="card-body mt-2">
								<span class="text-sm text-color text-uppercase font-weight-bold">{{post?.published_at |
									date}}</span>
								<h5 class="mb-3"><a routerLink="/news/{{post?.slug}}" class="">{{post?.name}}</a></h5>
	
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-5">
					<div class="col-lg-12">
						<a routerLink="/news" class="btn btn-main animated fadeInUp rounded">More News</a>
					</div>
				</div> -->

        <div class="row">
          <div class="col-lg-12">
            <h2 class="mt-3 mb-4 position-relative content-title">
              Latest Publications
            </h2>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6 mb-3 mb-lg-0"
            *ngFor="let pub of publications"
          >
            <div class="card blog-item">
              <img
                *ngIf="pub.image"
                src="{{ baseUrl }}/public/assets?media={{ pub?.image }}"
                alt=""
                class="img-fluid"
              />
              <div class="card-body mt-2">
                <span
                  class="text-sm text-color text-uppercase font-weight-bold"
                  >{{ pub?.published_at | date }}</span
                >
                <h5 class="mb-3">
                  <a routerLink="/publications/{{ pub?.slug }}" class="">{{
                    pub?.name
                  }}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-lg-12">
            <a
              routerLink="/publications"
              class="btn btn-main animated fadeInUp rounded"
              >More Publications</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="sidebar-widget" id="">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="mt-3 mb-4 position-relative content-title">
                Connect with Us
              </h2>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-lg-12">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2FNigeriaINGOForum&tabs=timeline,events&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=2163606587276855&adapt_container_width=true"
                data-adapt-container-width="true"
                width="100%"
                height="500"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>
            <div class="col-lg-12 mt-3">
              <a
                class="twitter-timeline"
                href="https://twitter.com/INGOforum_ng?ref_src=twsrc%5Etfw"
                >Tweets by INGOforum_ng</a
              >
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charset="utf-8"
              ></script>
            </div>
          </div>
        </div>
        <!-- <div class="sidebar-wrap mt-5 mt-lg-0 text-center">

					<div class="sidebar-widget  p-4 mb-3 " id="community">
						<div class="row">
							<div class="col-lg-12">
								<h2 class="mt-3 mb-4 position-relative content-title">Join the virtual <br />NGO
									community</h2>
							</div>
						</div> 
						<div class="row mb-5">
							<div class="col-lg-12">
								<a href="https://community.ngoworld.ng/login" 	target="_blank">Register</a> &nbsp; &nbsp; |&nbsp; &nbsp; <a href="https://community.ngoworld.ng/login"
									target="_blank">Login</a>
							</div>
						</div>
						
					</div>
				</div> -->
      </div>
    </div>
  </div>
</section>

<!--Multi media  -->
<div class="section section-section-bg">
  <div class="container">
    <div class="gallery-wrap">
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="gallery-item">
            <div
              class="gallery-popup"
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
            >
              <iframe
                width="887"
                height="499"
                src="https://www.youtube.com/embed/cUVPLa3nWis"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border: 0;
                "
                title="Making a Difference: INGOs in North-East"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="gallery-item">
            <div
              class="gallery-popup"
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
            >
              <iframe
                src="https://www.youtube.com/embed/UIBwtI0K7UQ"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border: 0;
                "
                allowfullscreen=""
                title="YouTube Video"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="gallery-item">
            <div
              class="gallery-popup"
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
            >
              <iframe
                src="https://www.youtube.com/embed/KzmJa4wm5ZM"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border: 0;
                "
                allowfullscreen=""
                title="YouTube Video"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="row mt-5">
        <div class="col-lg-12 text-center">
          <a
            routerLink="/multimedia"
            class="btn btn-main animated fadeInUp rounded"
            >More Multimedia</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-divider"></div>
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center" *ngIf="isMember">
      <h2 class="mb-5">Our Members</h2>
      <div class="col-lg-12 member-slider">
        <div *ngFor="let member of members">
          <div class="members-item-wrap" *ngIf="member.logo !== ''">
            <div class="team-item position-relative">
              <a
                href="{{ member.website }}"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="{{ baseUrl }}/public/assets?media={{ member.logo }}"
                  alt="{{ member.alt }}"
                  class="img-fluid w-100"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section section-section-bg">
  <div class="container">
    <div class="row justify-content-center">
      <h2 class="mb-5">Our Support</h2>
      <div class="col-lg-12 team-slider">
        <div *ngFor="let support of donors">
          <div class="members-item-wrap">
            <div class="team-item position-relative">
              <a
                href="{{ support.link }}"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="{{ support.src }}"
                  alt="{{ support.alt }}"
                  class="img-fluid w-100"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section>
	<div class="container">
		<div class="row">
			<div class="col-lg-12 my-5 text-center">
				<a target="_blank"  href="https://cdn.forms-content.sg-form.com/8de3acb8-ebbe-11eb-9e7a-766141293174" class="btn btn-main animated fadeInUp rounded" >
					MAILING LIST SUBSCRIPTION
				</a>
				<bautton type="button" class="btn btn-main animated fadeInUp rounded" data-toggle="modal" data-target="#exampleModal">
					MAILING LIST SUBSCRIPTION
				</button>
				 <div class="section-divider"></div>
			</div>
		</div>
	</div>
</section> -->

<!--TODO: REMOVE THIS SECTION-->
<!-- <div class="volunteer section ">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-12 col-sm-12">
				<div class="volunteer-content"> -->
<!-- <img src="/assets/images/bg/image-5.jpg" alt="" class="img-fluid">
					<h2 class="text-lg mb-5 mt-3">We can’t help everyone, but everyone can help someone</h2>
					<p>Assumenda reiciendis delectus dolore incidunt molestias omnis quo quaerat voluptate, eligendi perspiciatis ipsa laudantium nesciunt officia, odit nemo quidem hic itaque. Fugiat.</p> -->

<!-- <h2 class="mt-5 mb-4">Our Members</h2>
					<div class="clients-wrap">
						<a href="#" *ngFor="let member of donors">
							<img src="{{member.src}}" alt="" class="img-fluid">
						</a>
					</div> -->

<!-- </div>
			</div>

			<div class="col-lg-5 col-md-12 col-sm-12">
				<div class="volunteer-form-wrap"> -->
<!-- <span class="text-white">Subscribe</span>
					<h2 class="mb-5 text-lg text-white">Receive Weekly Update</h2> -->
<!-- <h2 class="mt-5 mb-4 text-white">Our Supporter</h2>
					<div class="clients-wrap" style="background: #fff">
						<a href="#" *ngFor="let member of donors">
							<img src="{{member?.src}}" alt="" class="img-fluid">
						</a>
					</div> -->
<!-- <form action="#" class="volunteer-form">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="First Name">
						</div>
								<div class="form-group">
							<input type="text" class="form-control" placeholder="Last Name">
						</div>
						<div class="form-group">
							<input type="email" class="form-control" placeholder="Emaill Address">
						</div>
						<div class="form-group">
							<input type="text" class="form-control" placeholder="Phone Number">
						</div>
						<a href="#" class="btn btn-main rounded mt-3">Submit</a>
					</form> -->
<!-- </div>
			</div>
		</div>
	</div>
</div> -->

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Newsletter Subscription
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          class="alert alert-warning text-center"
          role="alert"
          *ngIf="subSuccess"
        >
          <h3>
            <!-- <i class="icofont-error "></i> -->
            {{ subSuccess }}
          </h3>
        </div>
        <div class="d-flex justify-content-center" *ngIf="subPending">
          <div
            class="spinner-grow"
            style="width: 5rem; height: 5rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <form
          *ngIf="!subSuccess"
          [formGroup]="subForm"
          action="#"
          class="volunteer-form"
        >
          <div class="form-group">
            <input
              formControlName="first_name"
              type="text"
              class="form-control"
              placeholder="First Name"
            />
          </div>
          <div class="form-group">
            <input
              formControlName="last_name"
              type="text"
              class="form-control"
              placeholder="Last Name"
            />
          </div>
          <div class="form-group">
            <input
              formControlName="email"
              type="email"
              class="form-control"
              placeholder="Emaill Address"
            />
          </div>
          <div class="form-group">
            <input
              formControlName="organization_name"
              type="text"
              class="form-control"
              placeholder="Organization Name"
            />
          </div>
          <div class="form-group">
            <select
              [(ngModel)]="seletedValue"
              formControlName="organization_type"
              class="form-control"
              placeholder="Organization Type"
            >
              <option [ngValue]="null">Select Organisation Type</option>
              <option
                *ngFor="let organizationType of organizationTypes"
                [ngValue]="organizationType.id"
              >
                {{ organizationType.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <input
              formControlName="job_title"
              type="text"
              class="form-control"
              placeholder="Job Title"
            />
          </div>
          <div class="form-group">
            <input
              formControlName="country"
              type="text"
              class="form-control"
              placeholder="Country"
            />
          </div>
        </form>
      </div>
      <div class="modal-footer text-center">
        <a
          href="#"
          *ngIf="!subPending && !subSuccess"
          class="btn btn-main rounded mt-3"
          (click)="subscribe()"
          >Submit</a
        >

        <button
          class="btn btn-main rounded mt-3"
          type="button"
          *ngIf="subPending"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    </div>
  </div>
</div>
