<!-- footer Start -->

<footer class="section-section-bg section ingoforum-footer" id="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="widget footer-widget">
          <h4 class="text-capitalize mb-4 content-title text-white">
            Quick Links
          </h4>

          <ul class="list-unstyled footer-menu content-body">
            <li>
              <a routerLink="/about-us" fragment="who-we-are/">Who we are</a>
            </li>
            <li><a routerLink="/about-us" fragment="our-team/">Our Team</a></li>
            <li><a routerLink="/our-members">Our Members</a></li>
            <li>
              <a
                routerLink="our-work"
                fragment="coordination-for-better-delivery/"
                >Coordination</a
              >
            </li>
            <li><a routerLink="/publications/newsletter">Newsletter</a></li>
            <!-- <li><a href="https://blog.ingoforum.ng">Blogs</a></li> -->
            <!-- <li><a routerLink="/faq">FAQs</a></li> -->
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="widget footer-widget">
          <h4 class="text-capitalize mb-4 content-title text-white">
            Maps/Infographics
          </h4>
          <ul class="list-unstyled footer-menu content-body">
            <!-- <li><a  target="_blank" href="https://resources.ngoworld.ng">Food Insecurity/Malnutrition Maps</a></li> -->
            <li>
              <a
                target="_blank"
                href="https://ingoforumng-my.sharepoint.com/:b:/g/personal/info_ingoforum_ng/EbpzjGuQs4JLlw0dHMBmc-EBUUMIC0vhrVE-vGfZEjT4gg?e=PUV59b"
                >INGO Flood Response in Nigeria</a
              >
            </li>
            <li>
              <a target="_blank" href="https://interactivemaps.ingoforum.ng/"
                >Interactive Map</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="footer-widget widget">
          <h4 class="text-capitalize mb-4 content-title text-white">
            Dashboards
          </h4>
          <ul class="list-unstyled footer-menu content-body">
            <li>
              <a
                href="https://app.powerbi.com/view?r=eyJrIjoiZTRmNGVkZGItYThlNS00YzBlLTljZTktMmFlNDdhYTU0MjM4IiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9"
                target="_blank"
              >
                INGOs Quarterly Report (Adamawa)</a
              >
            </li>
            <li>
              <a
                href="https://app.powerbi.com/view?r=eyJrIjoiMGMzYmY3ZGUtOWZjOC00NzNjLWE4YjItZDU4ZTY1OWU5OTNiIiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9"
                target="_blank"
              >
                INGOs Quarterly Report (Yobe)</a
              >
            </li>
            <li>
              <a
                href="https://app.powerbi.com/view?r=eyJrIjoiODk5NDkwNTctZmFjZi00N2E3LThmOWItMTU1MTNhODFlOGMwIiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9&pageName=ReportSection4a1f4a02068a4a203203"
                target="_blank"
                >Project Information Matrix</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="footer-widget widget">
          <h4 class="text-capitalize mb-4 content-title text-white">
            Connect With Us
          </h4>
          <p>
            No. 2 Yabo Close, Off Jere Street, <br />
            Garki II, Abuja - FCT, Nigeria
          </p>

          <ul class="list-unstyled footer-menu content-body mt-4">
            <!-- <li><a href="#"><i class="icofont-phone"></i>+234 912 017 7947</a></li> -->
            <li>
              <a href="#"><i class="icofont-email"></i>info@ingoforum.ng</a>
            </li>
          </ul>
          <ul class="list-inline content-body footer-socials">
            <li class="list-inline-item">
              <a
                target="_blank"
                href="https://www.facebook.com/NigeriaINGOForum"
                ><i class="icofont-facebook"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a target="_blank" href="https://twitter.com/INGOforum_ng"
                ><i class="icofont-twitter"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCn6-HlqYBJgJnzTAOfGYA4g"
                ><i class="icofont-youtube-play"></i
              ></a>
            </li>
            <!-- <li class="list-inline-item"><a href="https://www.pinterest.com/ingoforum/"><i class="icofont-linkedin"></i></a></li> -->
          </ul>
          <!-- <h4 class="text-capitalize mb-4 text-white">Subscribe Us</h4>
					<p class="mb-4">Subscribe to get latest news article and resources  </p>

					<form action="#" class="sub-form">
						<input type="text" class="form-control mb-4" placeholder="Subscribe Now ...">
						<a href="#" class="btn btn-main btn-small rounded">subscribe</a>
					</form> -->
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="intro">
  <div class="container">
    <div class="row justify-content-center">
      <!-- <div class="col-lg-6">
				<h3 class="text-capitalize my-4 ">Hosted By</h3>
				<div class="clients-wrap">
					<a href="https://immap.org/nigeria/" target="_blank" rel="noopener noreferrer">
						<img src="/assets/images/members/immap.png" alt="iMMAP" class="img-fluid">
					</a>
				</div>
			</div> -->
      <div class="col-lg-12">
        <h3 class="text-capitalize my-4">Funded By</h3>
        <div class="clients-wrap funded-by">
          <a href="#" *ngFor="let member of donors">
            <div class="members-item-wrap">
              <div class="team-item position-relative">
                <a
                  href="{{ member.link }}"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="{{ member?.src }}" alt="" class="img-fluid" />
                </a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="intro reserved" style="background-color: rgba(0, 0, 0, 0.05)">
  <div class="container">
    <div class="text-center p-4">
      <a routerLink="/terms-and-conditions">Terms of Use</a> |
      <a routerLink="/privacy"> Privacy Notice</a> <br />
      Nigeria INGO Forum. All rights reserved © {{ currentYear }} Copyright
    </div>
  </div>
</div>
