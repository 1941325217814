<section class="page-title bg-publication">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Publications</h1>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col">
                        <div class="card h-100 bg-report" routerLink="/publications/reports">
                            <img src="/assets/images/publication/Icons/Reports.png"
                                class="card-img-top" alt="...">
                                <h3 class="card-title text-green"> Reports</h3>
                            <!-- <div class="card-body">
                                <h5 class="card-title"><a routerLink="/publications/reports">Reports</a></h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100  bg-newsletter" routerLink="/publications/newsletters">
                            <img src="/assets/images/publication/Icons/Newsletter.png"
                                class="card-img-top" alt="...">
                                <h3 class="card-title"> Newsletters</h3>
                            <!-- <div class="card-body">
                                <h5 class="card-title"><a routerLink="/publications/newsletters">Newsletters</a></h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 bg-snapshots"  routerLink="/publications/snapshots">
                            <img src="/assets/images/publication/Icons/Snapshot.png"
                                class="card-img-top" alt="...">
                                <h3 class="card-title"> Snapshots</h3>
                            <!-- <div class="card-body">
                                <h5 class="card-title"><a routerLink="/publications/snapshots">Snapshots</a></h5>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-12">
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col">
                        <div class="card h-100  bg-leaflet" routerLink="/publications/leaflets">
                            <img src="/assets/images/publication/Icons/Leaflet.png"
                                class="card-img-top" alt="...">
                                <h3 class="card-title"> Leaflets</h3>
                            <!-- <div class="card-body">
                                 <h5 class="card-title"><a routerLink="/publications/leaflets">Leaflets</a></h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col">

                    </div>
                    <div class="col">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>