import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PublicService } from 'src/app/services';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: [ './contact.component.scss' ]
})
export class ContactComponent implements OnInit {
	contactForm: FormGroup;
	pending: boolean;
	error: any;
	success: any;
	constructor(private fb: FormBuilder, private pubSvc: PublicService, private title: Title) {
		this.title.setTitle('Contact | Nigeria INGO Forum');
		window.scrollTo({ top: 0, behavior: 'smooth' });
		this.contactForm = fb.group({
			name: [ '', Validators.required ],
			email: [ '', Validators.required ],
			phone: [ '', Validators.required ],
			subject: [ '', Validators.required ],
			message: [ '', Validators.required ]
		});
	}

	submitForm() {
		if (this.contactForm.valid) {
			this.pending = true;
			this.pubSvc.contactUs({ body: this.contactForm.value }).subscribe(
				(res) => {
					this.pending = false;
					this.success = 'Message sent successfully';
				},
				(error) => {
					this.pending = false;
					this.error = error.error ? error.error : error;
				}
			);
		} else {
		}
	}

	ngOnInit(): void {}
}
