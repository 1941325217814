import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent implements OnInit {
	// display year in the copy right
	currentYear = new Date().getFullYear();

	donors: Array<any> = [
		{
			src: '/assets/images/donors/ECHO-Logo.png',
			alt: 'ECHO',
			link: 'https://ec.europa.eu/echo/'
		},
		// { src: '/assets/images/donors/NIF-Contributions-Logo.png' },
		{
			src: '/assets/images/donors/SDC-Logo.png',
			alt: 'SDC',
			link: 'https://www.eda.admin.ch/sdc'
		},
		{
			src: '/assets/images/donors/UKAID-Logo.png',
			alt: 'FCDO (UKAid)',
			link: 'https://www.gov.uk/government/organisations/foreign-commonwealth-development-office'
		},
		// {
		// 	src: '/assets/images/donors/USAID-Logo.png',
		// 	alt: 'OFDA (USAID)',
		// 	link: 'https://www.usaid.gov/who-we-are/organization/bureaus/bureau-humanitarian-assistance'
		// }
	];
	constructor() {}

	ngOnInit(): void {}
}
