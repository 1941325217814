import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppFilter } from '../../models';
import { PublicService, OptionService } from '../../services';
import { BlogService } from 'src/app/services';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-careers',
	templateUrl: './careers.component.html',
	styleUrls: [ './careers.component.scss' ]
})
export class CareersComponent implements OnInit {
	baseUrl = environment.API_SERVER;
	searchable: boolean;
	postTypes = [];
	jobTypes = [];
	// jobTypes = [];
	jobFamilyTypes = [];
	themeTypes = [];
	jobLevelTypes = [];
	careers = [];
	organizations = [];
	totalItems: number = 0;
	rows: number = 3;
	maxSize: number = 3;
	currentPage: number;
	schedules = [ 1 ];
	pending: boolean;
	error: any;
	success: any;
	filter: AppFilter;
	searchFilter = {
		groupOp: 'AND',
		rules: [
			{
				field: 'name',
				op: 'cn',
				data: ''
			}
		]
	};
	filterForm: FormGroup;
	search = new FormControl('');
	summary: Array<any> = [];
	topEmployers: Array<any> = [];
	popularOpportunity: Array<any> = [];
	constructor(
		private fb: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private postSvc: PublicService,
		private title: Title
	) {
		this.title.setTitle('Careers | Nigeria INGO Forum');
		this.filterForm = fb.group({
			organization_type_id: [ [] ],
			post_type_id: [ [] ],
			job_type_id: [ [] ],
			theme_id: [ [] ],
			job_family_id: [ [] ],
			job_level_id: [ [] ],
			organization_id: [ [] ],
			content_course_type_id: [ [] ],
			image: [ [] ],
			attachment: [ [] ]
		});
		this.filter = new AppFilter({ sidx: 'name', records: '' });
		let filter = this.filterComposer(null);
		this.filter.search = false;
		this.filter.filters = JSON.stringify(filter);
		// this.getPage(1);
		this.searchRecords('');
		this.getJobFamilyTypes();
		this.getJobLevelTypes();
		this.getJobTypes();
		this.getPostTypes();
		this.getThemeTypes();
		this.getOrganization();
		this.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchText) => {
			this.searchRecords(searchText);
		});
		this.filterForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchText) => {
			this.searchRecords(null);
		});
	}

	getOrganization() {
		let filter = new AppFilter({ sidx: 'name', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		filter.search = false;
		this.postSvc.getOrganization({ param: filter }).subscribe((res) => {
			this.organizations = res ? res : [];
		});
	}

	getPostTypes() {
		let filter = new AppFilter({ sidx: 'order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'post_type';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.postSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.postTypes = res.options;
		});
	}

	ngOnInit(): void {}
	view(post) {
		this.router.navigate([ '/opportunity/detail', post.id ]);
	}
	getPage(page) {
		this.filter.page = page;
		this.pending = true;
		this.postSvc
			.getAllOpportunity({ param: this.filter })
			.pipe(
				tap((res: any) => {
					this.totalItems = res.count;
					this.currentPage = page;
					this.rows = this.filter.rows;
				})
			)
			.subscribe(
				(res) => {
					this.pending = false;
					this.careers = res['opportunitys'] ? res['opportunitys'] : [];
				},
				(error) => {
					this.pending = false;
					this.error = error.error.split('=').pop();
				}
			);
	}

	//handle option search
	searchRecords(search) {
		let filter = this.filterComposer(search);
		this.filter.filters = JSON.stringify(filter);
		this.getPage(1);
	}

	//handle sorting records
	sortRecords(search) {
		this.filter.sord = search;
		this.getPage(1);
	}

	//handles rows display
	displayRecords(row) {
		this.filter['rows'] = row;
		this.filter.page = 1;
		this.getPage(1);
	}

	nextPage(page: number) {
		//get and assign current page
		this.currentPage = page;
		this.getPage(this.currentPage);
	}

	//group query
	filterComposer(query) {
		let mongofilter = [];
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let jobType: { [id: string]: any } = {};
		let themeType: { [id: string]: any } = {};
		let jobFamilyType: { [id: string]: any } = {};
		let jobLevelType: { [id: string]: any } = {};
		let postType: { [id: string]: any } = {};
		let approvalStatus: { [id: string]: any } = {};
		let obj = this.filterForm.value;
		let lookup: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		let unwind: { [id: string]: any } = {};
		let facet: { [id: string]: any } = {};
		let sort: { [id: string]: any } = {};
		for (const key in obj) {
			switch (key) {
				case 'post_type_id':
					postType['posttypeid'] = { $in: obj[key] };
					break;
				case 'job_type_id':
					jobType['jobtypeid'] = { $in: obj[key] };
					break;
				case 'theme_id':
					themeType['themeid'] = { $in: obj[key] };
					break;
				case 'job_family_id':
					jobFamilyType['jobfamilyid'] = { $in: obj[key] };
					break;
				case 'job_level_id':
					jobLevelType['joblevelid'] = { $in: obj[key] };
					break;
			}
		}
		let oroperator: { [id: string]: any }[] = [];
		let approval: { [id: string]: any }[] = [];
		let andoperator: { [id: string]: any }[] = [];
		approvalStatus['status'] = { $eq: 'APPROVED' };
		oroperator = [ postType, jobType, themeType, jobFamilyType, jobLevelType ];
		match['$or'] = oroperator;
		match['status'] = 'APPROVED';
		match['deadline'] = { $gte: new Date() };

		if (query !== null) {
			let c1: { [id: string]: any } = {};
			let c2: { [id: string]: any } = {};
			//name
			name['$regex'] = query;
			name['$options'] = 'i';
			c1['name'] = name;

			description['$regex'] = query;
			description['$options'] = 'i';
			c2['title'] = description;

			let oroperator: { [id: string]: any }[] = [];
			oroperator = [ c1, c2 ];
			match['$or'] = oroperator;
		}
		return match;
	}

	//group query
	filterSummaryComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let jobType: { [id: string]: any } = {};
		let themeType: { [id: string]: any } = {};
		let jobFamilyType: { [id: string]: any } = {};
		let jobLevelType: { [id: string]: any } = {};
		let postType: { [id: string]: any } = {};
		let approvalStatus: { [id: string]: any } = {};
		let obj = this.filterForm.value;
		let lookup: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		let unwind: { [id: string]: any } = {};
		let facet: { [id: string]: any } = {};
		let sort: { [id: string]: any } = {};
		let oroperator: { [id: string]: any }[] = [];
		let approval: { [id: string]: any }[] = [];
		let datefilter: { [id: string]: any } = {};
		approvalStatus['status'] = { $eq: 'APPROVED' };
		let date = new Date();
		datefilter['$gte'] = date;
		match['deadline'] = datefilter;
		match['status'] = 'APPROVED';
		mongofilter['$match'] = match;
		return mongofilter;
	}

	//group query
	filterPopularComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let approvalStatus: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		let unwind: { [id: string]: any } = {};
		let approval: { [id: string]: any }[] = [];
		let datefilter: { [id: string]: any } = {};
		approvalStatus['status'] = { $eq: 'APPROVED' };
		let date = new Date();
		datefilter['$gte'] = date.setHours(0, 0, 0, 0);
		// match['deadline'] = datefilter;
		match['status'] = 'APPROVED';
		// mongofilter['$match'] = match;
		return match;
	}
	filterTopComposer(query) {
		let mongofilter: { [id: string]: any } = {};
		let name: { [id: string]: any } = {};
		let description: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		let unwind: { [id: string]: any } = {};
		let sort: { [id: string]: any } = {};
		match['status'] = 'APPROVED';
		mongofilter['$match'] = match;
		return mongofilter;
	}

	getJobLevelTypes() {
		let filter = new AppFilter({ sidx: 'order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'job_level';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.postSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.jobLevelTypes = res.options;
		});
	}
	getThemeTypes() {
		let filter = new AppFilter({ sidx: 'order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'theme';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.postSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.themeTypes = res.options;
		});
	}
	getJobTypes() {
		let filter = new AppFilter({ sidx: 'order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'job_type';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.postSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.jobTypes = res.options;
		});
	}

	getJobFamilyTypes() {
		let filter = new AppFilter({ sidx: 'order', rows: 1000, sord: 'des' });
		let mongofilter: { [id: string]: any } = {};
		let type: { [id: string]: any } = {};
		type['$eq'] = 'job_family';
		mongofilter['optiontypeid'] = type;
		filter.search = false;
		filter.filters = JSON.stringify(mongofilter);
		this.postSvc.getAllOption({ param: filter }).subscribe((res) => {
			this.jobFamilyTypes = res.options;
		});
	}
}
