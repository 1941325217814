import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: [ './terms.component.scss' ]
})
export class TermsComponent implements OnInit {
	constructor(private title: Title) {
		this.title.setTitle('Terms | Nigeria INGO Forum');
	}

	ngOnInit(): void {}
}
