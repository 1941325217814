

<section class="page-title bg-publication">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <span class="text-white">Publication details</span>
          <h1 class="text-capitalize mb-4 text-lg">{{publication?.name}}</h1>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="section blog-wrap">
    <div class="container">
        <div class="row">

						<div class="col-lg-8">
							<div class="row">
								<div class="col-lg-12 mb-5">
									<div class="single-blog-item">

										<div class="blog-item-content mt-3">
											<h2 class="mt-3"><a >{{publication?.name}}</a></h2>

											<div class="blog-item-meta py-1 px-2 ">
												<span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-folder"></i> CATEGORY:</span> {{publication?.category_id?.name}}</span>
												<span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-calendar mr-1"></i> POSTED ON:</span> {{publication?.published_at | date}}</span>
												 <span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-eye-alt"></i> VIEWS:</span> {{publication?.view_count}}</span>
											 <span class="text-black mr-3"><span class="text-muted mr-1"><i class="icofont-download"></i> DOWNLOADS:</span> {{publication?.download_count}}</span>
												<!-- <span class="text-muted mr-3"><i class="icofont-comment mr-2"></i>5 Comments</span> -->
											</div>


										<div class="mt-5" [innerHtml]="publication?.description"></div>

											<div class="row mt-3 mb-3"  *ngFor="let file of publication?.attachments">
										<div class="col-lg-1 file-name">
												<i class="icofont-file-pdf big" *ngIf="file.extension == '.pdf'" ></i>
												<i class="icofont-file-pdf big" *ngIf="file.extension == '.txt'" ></i>
												<i class="icofont-image big" *ngIf="file.extension == '.jpg'" ></i>
												<i class="icofont-image big" *ngIf="file.extension == '.jpeg'" ></i>
												<i class="icofont-image big" *ngIf="file.extension == '.png'" ></i>
											</div>
												<div class="col-lg-5 file-name">
														{{file?.name}}
													</div>
														<div class="col-lg-4" (click)="downloadFile(file)">
										 	<button class="btn btn-main animated fadeInUp rounded">	Download</button>
													</div>
										</div>

											<div class="share-option mt-5 clearfix">
												<ul class="list-inline">
													<li class="list-inline-item"> <strong>Share:</strong> </li>
													<li class="list-inline-item"><a href="https://www.facebook.com/sharer.php?u=https://www.ingoforum.ng/publications/{{publication?.slug}}" target="_blank"><i class="icofont-facebook mr-2" ></i>Facebook</a></li>
													<li class="list-inline-item"><a href="https://www.twitter.com/share?url=https://www.ingoforum.ng/publications/{{publication?.slug}}" target="_blank"><i class="icofont-twitter mr-2"></i>Twitter</a></li>
													<!-- <li class="list-inline-item"><a href="https://blog.ingoforum.ng/blog/{{blog?.slug}}" target="_blank"><i class="icofont-pinterest mr-2"></i>Pinterest</a></li> -->
													<li class="list-inline-item"><a href="https://www.linkedin.com/shareArticle?mini=true&?url=https://www.ingoforum.ng/publications/{{publication?.slug}}" target="_blank"><i class="icofont-linkedin mr-2"></i>Linkedin</a></li>
												</ul>
											</div>
										</div>
									</div>


								</div>
			</div>
            </div>
            <div class="col-lg-4">
                <div class="sidebar-wrap mt-5 mt-lg-0">
				      <img src="{{baseUrl}}/public/assets?media={{publication?.image}}" alt="" class="img-fluid w-100">
					</div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="fileViewerModal" tabindex="-1" aria-labelledby="fileViewerModalLabel">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<!-- <h5 class="modal-title" id="exampleModalLabel">Newsletter Subscription</h5> -->
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
	    <ngx-imageviewer [src]="fileSrc"></ngx-imageviewer>
			</div>
		</div>
	</div>
</div>

