import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-our-work',
	templateUrl: './our-work.component.html',
	styleUrls: [ './our-work.component.scss' ]
})
export class OurWorkComponent implements OnInit, AfterViewInit {
	serviceProvided = [
		{
			title: 'Coordination',
			descriptions:
				'NIF develops, strengthens and maintains productive relationships and mutual understanding among its members, as well as with relevant authorities, United Nations, Nigerian civil society organisations, International organisations and donors. We ensure that INGOs are effectively coordinating and sharing lessons learned, best practice, and identifying common interests and priorities.',
			image: '/assets/images/NIF-Functions/Images/Coordination.jpg'
		},
		{
			title: 'Representation',
			descriptions:
				'We represent the interests of our members in external coordination mechanisms, with key stakeholders including government, UN, donors and others, and ensure INGO participation in critical decision-making platforms to guide effective and principled responses.',
			image: '/assets/images/NIF-Functions/Images/Representation.jpg'
		},
		{
			title: 'Information Sharing',
			descriptions:
				'We facilitate predictable and efficient flow of information to both members and external stakeholders on needs, priorities and actions. We ensure that members and relevant stakeholders are kept informed on regular basis and project to the public the  positive impact of INGOs.',
			image: '/assets/images/NIF-Functions/Images/Info-Sharing.jpg'
		},
		{
			title: 'Evidenced Based Policy and Advocacy',
			descriptions:
				'We mobilise members on collective positioning and engage directly with policy makers at country, regional and international levels to promote respect for rights of crisis-affected populations and ensure evidence and people’s interests drive policy processes.',
			image: '/assets/images/NIF-Functions/Images/Advocacy.jpg'
		},
		{
			title: 'Humanitarian Access and Community Engagement',
			descriptions: `We co-chair the Access Working Group and navigate relevant authorities, including engaging directly with affected communities, to:
        <ul>
          <li>Facilitate INGOs' ability to reach populations in need</li>
		  <li>Facilitate affected populations' access to assistance and services; and</li>
		       <li>Ensure INGOs' accountablity to affected populations</li>
        </ul>`,
			image: '/assets/images/NIF-Functions/Images/Access.jpg'
		},
		{
			title: 'Partnerships and Capacity Development',
			descriptions: `We commit to strengthening collaborative efforts among members and national CSOs, and the capacity development of NGOs (international and national) to be able to operate in a principled and effective manner in Nigeria through dedicated training support on commonly identified needs and gaps.`,
			image: '/assets/images/NIF-Functions/Images/Partnerships.jpg'
		}
	];
	constructor(private activatedRoute: ActivatedRoute, private title: Title) {
		this.title.setTitle('Our Work | Nigeria INGO Forum');
	}
	ngAfterViewInit(): void {
		this.activatedRoute.fragment.subscribe((res) => {
			if (res) {
				var element = document.getElementById(`${res.split('/')[0]}`);
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		});
	}
	ngOnInit(): void {}
}
