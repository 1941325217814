import { Component, OnInit } from '@angular/core';
import { PublicService, OptionService } from '../../services';
import { AppFilter } from '../../models';
// import { Memebers, Supporter } from './our-members-mock';
import { Memebers } from '../home/our-members-mock';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
@Component({
	selector: 'app-members',
	templateUrl: './members.component.html',
	styleUrls: [ './members.component.scss' ]
})
export class MembersComponent implements OnInit {
	baseUrl = environment.API_SERVER;
	filter: AppFilter;
	members: Array<any> = [];
	pending: boolean;
	// members: Array<any> = Memebers.sort(function(a, b) {
	// 	var nameA = a.alt.toUpperCase(); // ignore upper and lowercase
	// 	var nameB = b.alt.toUpperCase(); // ignore upper and lowercase
	// 	if (nameA < nameB) {
	// 		return -1; //nameA comes first
	// 	}
	// 	if (nameA > nameB) {
	// 		return 1; // nameB comes first
	// 	}
	// 	return 0; // names must be equal
	// });

	constructor(private publicSvc: PublicService, private title: Title) {
		this.title.setTitle('Members | Nigeria INGO Forum');
		this.getLatests();
	}
	getLatests() {
		let filter = new AppFilter({ sidx: 'name', rows: 3000 });
		let sear = this.filterComposer(null);
		filter.search = false;
		filter.filters = JSON.stringify(sear);
		this.pending = true;
		this.publicSvc.getOrganization({ param: filter }).subscribe(
			(res) => {
				this.members = res
					.filter((org) =>  org.logo != '')
					.sort(function(a, b) {
						var nameA = a.name.toUpperCase(); // ignore upper and lowercase
						var nameB = b.name.toUpperCase(); // ignore upper and lowercase
						if (nameA < nameB) {
							return -1; //nameA comes first
						}
						if (nameA > nameB) {
							return 1; // nameB comes first
						}
						return 0; // names must be equal
					});
				this.pending = false;
			},
			(error) => {
				this.pending = false;
				// this.error = error.error.split('=').pop();
			}
		);
	}
	ngOnInit(): void {}

	filterComposer(query) {
		let mongofilter = [];
		let name: { [id: string]: any } = {};
		let lookup: { [id: string]: any } = {};
		let match: { [id: string]: any } = {};
		let project: { [id: string]: any } = {};
		match['status'] = 'APPROVED';
		match['type'] = '0f96de31-66c4-4d42-a29a-6ed2a6c560cf';

		return match;
	}
}
