import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
declare var $: any;
@Component({
	selector: 'app-membership',
	templateUrl: './membership.component.html',
	styleUrls: [ './membership.component.scss' ]
})
export class MembershipComponent implements OnInit, AfterViewInit {
	constructor(private activatedRoute: ActivatedRoute, private title: Title) {
		this.title.setTitle('Membership | Nigeria INGO Forum');
	}
	members: Array<any> = [
		{ src: '/assets/images/members/acf.png' },
		{ src: '/assets/images/members/acted.png' },
		{ src: '/assets/images/members/aan.png' },
		{ src: '/assets/images/members/alert.png' },
		{ src: '/assets/images/members/alima.png' },
		{ src: '/assets/images/members/bbc-media.png' },
		{ src: '/assets/images/members/cafod.png' },
		{ src: '/assets/images/members/caid.png' },
		{ src: '/assets/images/members/care.png' },
		{ src: '/assets/images/members/cbm.png' },
		{ src: '/assets/images/members/civic.png' },
		{ src: '/assets/images/members/coopi.png' },
		{ src: '/assets/images/members/crs.png' },
		{ src: '/assets/images/members/mdm.png' },
		{ src: '/assets/images/members/vso.png' }
	];
	ngOnInit(): void {}
	ngAfterViewInit(): void {
		this.activatedRoute.fragment.subscribe((res) => {
			if (res) {
				var element = document.getElementById(`${res.split('/')[0]}`);
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		});
		$('.clients-wrap').slick({
			slidesToShow: 6,
			// slidesToScroll: 2,
			infinite: true,
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
						arrows: true
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}
}
