<section class="page-title bg-career">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block text-center">
          <!-- <h4 class="text-white">Our Work</h4> -->
          <h1 class="text-capitalize mb-4 text-lg">Media Contacts</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section pb-0">
  <div class="container">
    <div class="row justify-content-start mb-5">
      <div class="col-lg-12 col-md-6 col-sm-12">
        <p>
          Contact the INGO Media Focal Persons for enquiries, interviews and
          information about our work in Nigeria.
        </p>
      </div>
    </div>

    <div class="row justify-content-start mb-4">
      <div class="col-lg-12 col-md-6 col-sm-12">
        <!-- <i class="icofont-home"></i> -->
        <h3>NIF Secretariat</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 text-start feature-inner">
        <h4>Lynn Van Beek</h4>

        <p>Policy Advisor</p>
        <p>
          Email:
          <a href="mailto:lvanbeek@ingoforum.ng">lvanbeek@ingoforum.ng</a>
        </p>
        <p>Mobile: <a href="tel:+234 908 722 7657">+234 908 722 7657</a></p>
      </div>
      <div class="col-lg-6 text-start feature-inner">
        <h4>Ademilola Adesanya</h4>

        <p>Media and Communication Adviser</p>
        <p>
          Email:
          <a href="mailto:aadesanya@ingoforum.ng">aadesanya@ingoforum.ng</a>
        </p>
        <p>Mobile: <a href="tel:+234 809 861 6443">+234 809 861 6443</a></p>
      </div>
    </div>
  </div>
</section>

<!-- <section class="section">
    <div class="container">
        <div class="row mb-3">
            <div class="col-12">
                <h3>NIF Members</h3>
            </div>
        </div>


        <div class="row mb-5">
            <div class="col-12">
            <table datatable class="row-border hover"> 
             <table id="d-table" class=" table-bordered row-border hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Position</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let mediaContact of mediaContacts">
                        <td>{{ mediaContact.id }}</td>
                        <td> {{ mediaContact.position }}</td>
                        <td> {{ mediaContact.name }} </td>
                        <td> {{ mediaContact.department }}</td>
                        <td><a href="mailto:{{ mediaContact.email }}">{{ mediaContact.email }}</a></td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </div>
</section> -->
