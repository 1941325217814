<section class="page-title bg-our-work">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="block text-center">
                    <!-- <span class="text-white">Our Work</span> -->
                    <h1 class="text-capitalize mb-4 text-lg">Our Work</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sections">
    <div class="container">
        <div class="row no-gutters">
        <div class="col-lg-8 text-start">
            <div class="sections section-top">
                <h3  id="coordination-for-better-delivery" class="mt-5 content-title ">
                    Coordination for Better Delivery
                </h3>
            </div>
        </div>
    </div>
        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    In fulfilment of our primary objectives, NIF
                    provides a coordinated approach through which INGOs,
                    the Government of Nigeria (GoN), the UN, donors and
                    other external stakeholders can exchange information,
                    shared expertise, and establish guidelines for a more
                    coordinated, efficient and effective use of aid
                    resources in Nigeria. This is achieved through the
                    following key functions, which also constitute the
                    core services provided to members:
                </p>
            </div>
        </div>
    </div>
</section>

<div class="container">
           <div class="row no-gutters">
    <div class="col-lg-12 col-md-6 col-sm-12">
        <div *ngFor="let s of serviceProvided" class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
            <div class="col col-lg-4 sections">
                <img src="{{ s.image }}" class="img-thumbnail" alt="...">
            </div>

            <div class="col-sm">
                <h3 class="primary">{{ s.title }}</h3>
                    <p>
                <span [innerHtml]="s.descriptions"></span>
                    </p>
            </div>
        </div>
    </div>
     </div>
</div>

<!-- <section class="sections">
    <div class="container"  id="our-initiative" >
               <div class="row no-gutters mt-5">
                        <div class="col-lg-8 text-start">
                            <div class="sections section-top">
                                <h3 id="our-team"class="mt-5 content-title ">
                                    Our Initiatives
                                </h3>
                            </div>
                        </div>
               </div>

        <div class="row justify-content-start">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <p>
                    To complement our six (6) core functions outlined above,
                    NIF from time to time comes up with Initiatives based on identified
                    needs and gaps.
                </p>
            </div>
        </div>
  <div class="row no-gutters">
        <div class="col-lg-12 text-start">
            <div class="sections section-top">
                <span class="our-work-title">
                    Promoting Local Responder Capacity and Partnership (PLRCAP)
                </span>

                <p>
                    Local and National responders are everyday providing lifesaving assistance
                    to the most vulnerable of Nigeria. Their access, commitment and local knowledge ensure effective response,
                    acceptance and reach. Reviews have shown, however, that a large majority of Nigerian non-governmental organisations
                    working in humanitarian response require focused capacity support to deliver effectively and at scale.
                    <a class="text-color" routerLink="/initiatives/plrcap"
                            rel="noopener noreferrer">Read more <i class="icofont-ui-next"></i> </a>
                </p>


            </div>
        </div>
    </div>

    </div>
</section> -->